<template>
  <div>
    <b-row>
      <b-col>
        <b-form-group label="* Nombre">
          <b-input
            v-model="name"
            placeholder="Nombre de la tabla de tallas"
            :state="!!name && !!name.length"
            @input="emitChange"
          >
          </b-input>
          <b-form-invalid-feedback :state="!!name && !!name.length">
            Este campo es obligatorio
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        md="6"
        sm="6"
        v-for="attribute of filterAttributes"
        :key="attribute.id"
      >
        <b-form-group
          :label="completeLabel(attribute)"
          :label-for="'a' + attribute.id"
        >
          <v-select
            :id="'a' + attribute.id"
            v-if="attribute.values && attribute.values.length"
            :multiple="attribute.multivalued === true"
            :options="getOptions(attribute)"
            :reduce="x => x.value"
            v-model="filterAttributeValues[attribute.id]"
            @input="emitChange"
            :disabled="editMode"
          ></v-select>
          <b-input
            :id="'a' + attribute.id"
            v-else
            v-model="filterAttributeValues[attribute.id]"
            @input="emitChange"
            :state="
              !requiredAttribute(attribute)
                ? null
                : !!filterAttributeValues[attribute.id] &&
                  !!filterAttributeValues[attribute.id].length
            "
            :disabled="editMode"
          ></b-input>
          <b-form-invalid-feedback
            :state="
              !!filterAttributeValues[attribute.id] &&
                !!filterAttributeValues[attribute.id].length
            "
            v-if="
              requiredAttribute(attribute) &&
                (!filterAttributeValues[attribute.id] ||
                  !filterAttributeValues[attribute.id].length)
            "
            >Este atributo es obligatorio</b-form-invalid-feedback
          >
        </b-form-group>
      </b-col>
      <b-col sm="6" md="6">
        <b-form-group
          label="* Columna principal"
          description="Esta columna será el título de cada talla de la tabla de talles"
        >
          <v-select
            :options="
              mainAttributeCandidates.map(x => {
                return { label: x.name, value: x.id };
              })
            "
            :disabled="editMode"
            :reduce="x => x.value"
            v-model="mainAttribute"
            @input="emitChange"
          ></v-select>
          <b-form-invalid-feedback
            :state="!!mainAttribute && !!mainAttribute.length"
            v-if="!mainAttribute || !mainAttribute.length"
            >Este atributo es obligatorio</b-form-invalid-feedback
          >
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="4">
        <b-form-group
          label="* Tipo de medidas a especificar"
          v-if="haveMeasureType"
        >
          <v-select
            placeholder="Seleccione un tipo"
            v-model="measureType"
            :options="measureTypes"
            :reduce="x => x.value"
            :clearable="false"
            :disabled="editMode"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <size-chart-mercado-libre-form-attributes-rows
      :attributes="columns"
      :main-attribute="mainAttribute"
      v-model="rows"
      @change="emitChange"
      :oldRows="oldRows"
      :editMode="editMode"
    ></size-chart-mercado-libre-form-attributes-rows>
  </div>
</template>
<script>
import SizeChartMercadoLibreFormAttributesRows from "./SizeChartMercadoLibreFormAttributesRows.vue";
export default {
  components: { SizeChartMercadoLibreFormAttributesRows },
  name: "SizeChartMercadoLibreFormAttributes",
  model: {
    prop: "_value",
    event: "change"
  },
  props: {
    attributes: {
      type: Array,
      default: null
    },
    completeAttributes: {
      type: Array,
      default() {
        return [];
      }
    },
    _value: {
      type: Object,
      default: null
    },
    domainId: {
      type: String,
      required: true
    },
    editMode: {
      type: Boolean,
      required: true
    },
    oldRows: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      filterAttributeValues: this._value.filter_attributes_values || {},
      mainAttribute: this._value.mainAttributeId || null,
      rows: this._value.rows || [],
      name: this._value.name || "",
      sizeChartBody: null,
      measureType: this._value.measureType || "BODY_MEASURE",
      measureTypes: [
        { label: "Medidas corporales", value: "BODY_MEASURE" },
        { label: "Medidas de prenda", value: "CLOTHING_MEASURE" }
      ]
    };
  },
  computed: {
    filterAttributes() {
      if (!this.attributes) return [];
      return this.attributes.filter(
        attribute =>
          this.$ifNull(attribute.tags, []).find(t => t == "grid_filter") &&
          !this.$ifNull(attribute.tags, []).find(
            t => t == "grid_template_required"
          )
      );
    },
    mainAttributeCandidates() {
      if (!this.attributes) return [];
      return this.attributes.filter(attribute =>
        this.$ifNull(attribute.tags, []).find(
          t => t == "main_attribute_candidate"
        )
      );
    },
    columns() {
      if (!this.attributes) return [];
      return this.attributes.filter(
        attribute =>
          !this.$ifNull(attribute.tags, []).find(t => t == "grid_filter") &&
          !this.$ifNull(attribute.tags, []).find(
            t => t == "grid_template_required"
          ) &&
          this.allowedByMeasureType(attribute)
      );
    },
    haveMeasureType() {
      if (!this.attributes) return false;
      return !!this.attributes.filter(
        attribute =>
          this.$ifNull(attribute.tags, []).find(t => t == "BODY_MEASURE") ||
          this.$ifNull(attribute.tags, []).find(t => t == "CLOTHING_MEASURE")
      ).length;
    }
  },
  methods: {
    allowedByMeasureType(attribute) {
      if (!this.haveMeasureType) return true;
      if (this.measureType === "BODY_MEASURE") {
        return !this.$ifNull(attribute.tags, []).find(
          t => t == "CLOTHING_MEASURE"
        );
      } else {
        return !this.$ifNull(attribute.tags, []).find(t => t == "BODY_MEASURE");
      }
    },
    /**
     * Construye objeto con informacion de la GDT actual
     * @returns {Object}
     */
    getSizeChartBody() {
      let body = {};
      body.domain_id = this.domainId;
      body.name = this.name;
      if (this.haveMeasureType && !this.editMode) {
        body.measure_type = this.measureType;
      }
      body.main_attribute = this.getMainAttribute();
      body.attributes = this.getAttributes();
      body.rows = this.getRowsForBody();
      if (this._value && this._value.id) {
        body.id = this._value.id;
      }
      return body;
    },
    /**
     * Construye objeto con atributo principal de GDT
     * @returns {Object}
     */
    getMainAttribute() {
      return {
        attributes: [
          {
            id: this.mainAttribute
          }
        ]
      };
    },
    /**
     * Construye objeto con todos los atributos de GDT
     * @returns {Array<Object>}
     */
    getAttributes() {
      return this.getCompleteAttributes().concat(
        this.getFilterAttributesForBody()
      );
    },
    /**
     * Construye filas para tabla de la GDT
     * @param {Object} value
     * @returns {Array<Object>}
     */
    getRows(value) {
      if (!value) return [];
      if (!this.columns || !this.columns.length || !value.rows) return [];
      return value.rows.map(row => {
        const newRow = {
          id: row.id
        };
        this.$ifNull(row.attributes, []).forEach(attribute => {
          if (this.attributeIdIsMultiple(attribute.id)) {
            newRow[attribute.id] = this.$ifNull(
              attribute.values,
              []
            ).map(value => this.$ifNull(value.id, value.name));
          } else {
            newRow[attribute.id] = this.$ifNull(
              this.$dig(attribute, "values", 0, "id"),
              this.$dig(attribute, "values", 0, "name")
            );
          }
        });
        return newRow;
      });
    },
    /**
     * Verifica si el atributo admite multivalores
     * @param {String} attributeId
     * @returns {Boolean}
     */
    attributeIdIsMultiple(attributeId) {
      if (!this.attributes) return false;
      return (
        this.$ifNull(
          this.attributes.find(attribute => attribute.id == attributeId),
          {}
        ).multivalued === true
      );
    },
    /**
     * Valida todo el formulario de GDT
     * @returns {Boolean}
     */
    validate() {
      return (
        !!this.domainId &&
        !!this.domainId.length && //dominio
        !!this.mainAttribute &&
        !!this.mainAttribute.length && //atributo principal
        !!this.name &&
        !!this.name.length && //nombre
        this.validateAttributes() && //atributos
        !!this.rows &&
        !!this.rows.length &&
        this.validateRows()
      ); //filas
    },
    /**
     * Valida filas GDT
     * @returns {Boolean}
     */
    validateRows() {
      let valid = true;
      this.rows.forEach(row => {
        if (!this.validateRow(row)) valid = false;
      });
      return valid;
    },
    /**
     * Valida una fila especifica
     * Basta con que tenga algun atributo obligatorio
     * o el atributo corresponda al principal de la GDT
     * @param {Object} row
     * @returns {Object}
     */
    validateRow(row) {
      let valid = true;
      this.columns.forEach(attribute => {
        if (
          this.requiredAttribute(attribute) ||
          attribute.id == this.mainAttribute
        ) {
          if (!row[attribute.id]) {
            valid = false;
          }
        }
      });
      return valid;
    },
    /**
     * Valida atributos de GDT
     * @returns {Boolean}
     */
    validateAttributes() {
      let valid = true;
      this.filterAttributes.forEach(attribute => {
        if (this.requiredAttribute(attribute)) {
          if (
            !this.filterAttributeValues[attribute.id] ||
            !this.filterAttributeValues[attribute.id].length
          ) {
            valid = false;
          }
        }
      });
      return valid;
    },
    /**
     * Emite cambio de GDT actual
     * Construye objecto con informacion de la GDT
     */
    emitChange() {
      this.sizeChartBody = this.getSizeChartBody();
      if (this.validate()) {
        let aux = this.sizeChartBody;
        aux.invalid = false;
        this.$emit("change", aux);
      } else {
        if (this.sizeChartBody) {
          this.sizeChartBody.invalid = true;
          this.$emit("change", this.sizeChartBody);
        } else {
          this.$emit("change", { invalid: true });
        }
      }
    },
    /**
     * Construye arreglo con los valores de los atributos que se entregan en attributeValues
     * @param {Array<Object>} attributeValues
     * @returns {Array<Object>}
     */
    getAttributesValuesFromBasic(attributeValues) {
      return attributeValues.map(attributeValue => {
        const isSelectable = this.isSelectable(attributeValue.id);
        return {
          id: attributeValue.id,
          values: this.attributeIdIsMultiple(attributeValue.id)
            ? attributeValue.valueId
            : [
                {
                  id: isSelectable ? attributeValue.valueId : null,
                  name: isSelectable
                    ? attributeValue.valueName
                    : attributeValue.valueId
                }
              ]
        };
      });
    },
    /**
     * Construye arreglo con los valores de los atributos requeridos de la ficha tecnica
     * @returns {Array<Object>}
     */
    getCompleteAttributes() {
      return this.getAttributesValuesFromBasic(this.completeAttributes);
    },
    /**
     * Construye arreglo con los valores de los atributos de la ficha tecnica
     * @returns {Array<Object>}
     */
    getFilterAttributesForBody() {
      return Object.keys(this.filterAttributeValues).map(attributeId => {
        return {
          id: attributeId,
          values: this.attributeIdIsMultiple(attributeId)
            ? this.allSelected(attributeId)
            : [
                {
                  id: this.selectedAttributeId(attributeId),
                  name: this.selectedAttributeName(attributeId)
                }
              ]
        };
      });
    },
    /**
     * Verifica si un atributo es de tipo seleccion
     * @param {String} attributeId
     * @returns {Boolean}
     */
    isSelectable(attributeId) {
      if (!this.attributes) return false;
      const attribute = this.attributes.find(att => att.id === attributeId);
      return !!attribute && !!attribute.values && attribute.values.length;
    },
    /**
     * Si el atributo es de opcion multiple, esta funcion retorna todos los valores seleccionados
     * @param {String} attributeId
     * @returns {Array<Object>}
     */
    allSelected(attributeId) {
      const isSelectable = this.isSelectable(attributeId);
      if (this.filterAttributeValues[attributeId]) {
        return this.filterAttributeValues[attributeId].map(attv => {
          return {
            id: isSelectable ? attv : null,
            name: isSelectable ? null : attv
          };
        });
      }
    },
    /**
     * Entrega el valor de un atributo
     * @param {String} attributeId
     * @returns {String}
     */
    selectedAttributeId(attributeId) {
      if (this.isSelectable(attributeId)) {
        return this.filterAttributeValues[attributeId];
      }
      return null;
    },
    /**
     * Entrega el label asociado de un valor de atributo
     * @param {String} attributeId
     * @returns {String}
     */
    selectedAttributeName(attributeId) {
      if (!this.isSelectable(attributeId)) {
        return this.filterAttributeValues[attributeId];
      }
      return null;
    },
    /**
     * Construye informacion de las filas de la GDT actual
     * @returns {Array<Object>}
     */
    getRowsForBody() {
      if (!this.rows) {
        return [];
      }
      return this.rows.map(row => {
        const newRow = {
          attributes: this.getAttributesValuesFromBasic(
            Object.keys(row)
              .filter(k => k !== "id")
              .map(attributeId => {
                return {
                  id: attributeId,
                  valueId: row[attributeId]
                };
              })
          )
        };
        if (row.id) {
          newRow.id = row.id;
        }
        return newRow;
      });
    },
    /**
     * Retorna label completo para atributo
     * @param {*} attribute
     * @returns {String}
     */
    completeLabel(attribute) {
      return (this.requiredAttribute(attribute) ? "* " : "") + attribute.name;
    },
    /**
     * Verifica si un atributo es obligatorio o no
     * @param {Object} attribute
     * @returns {Object}
     */
    requiredAttribute(attribute) {
      return !!this.$ifNull(attribute.tags, []).find(x => x == "required");
    },
    /**
     * Guarda en el estado informacion relevante de la GDT actual
     */
    completeAllAttributes() {
      this.name = this.$dig(this.$ifNull(this._value, { name: "" }), "name");
      let filterAttributeValues = {};
      this.filterAttributes.forEach(attribute => {
        filterAttributeValues[attribute.id] = this.attributeValue(attribute);
      });
      this.filterAttributeValues = filterAttributeValues;
      const mainAttribute = this.$dig(this._value, "main_attribute");
      if (
        mainAttribute &&
        mainAttribute.attributes &&
        mainAttribute.attributes.length
      ) {
        this.mainAttribute = mainAttribute.attributes[0].id;
      }
      this.rows = this.getRows(this._value);
    },
    /**
     * Retorna valor actual de un atributo
     * @param {Object} attribute
     * @returns {String}
     */
    attributeValue(attribute) {
      const attrVal = this.$ifNull(
        this.$dig(this._value, "attributes"),
        []
      ).find(a => a.id === attribute.id);
      if (!attrVal) return null;
      if (attribute.multivalued) {
        return this.$ifNull(attrVal.values, []).map(v =>
          this.$ifNull(v.id, v.name)
        );
      }
      if (!attrVal || !attrVal.values || !attrVal.values.length) return "";
      return this.$ifNull(
        this.$ifNull(attrVal.values[0].id, attrVal.values[0].name),
        ""
      );
    },
    /**
     * Entrega arreglo con opciones para un atributo de tipo selector
     * @param {Object} attribute
     * @returns {Array<Object>}
     */
    getOptions(attribute) {
      return attribute.values.map(x => {
        return { label: x.name, value: x.id };
      });
    }
  },
  watch: {
    filterAttributes() {
      this.completeAllAttributes();
      this.emitChange();
    },
    _value(newVal) {
      this.completeAllAttributes();
      if (newVal !== this.sizeChartBody) this.emitChange();
    }
  }
};
</script>
