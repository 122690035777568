<template>
  <div v-if="!!this.product.imageQuality" class="text-center">
    <base-help-modal
      id="imageQuality"
      tooltip="¿Qué es esto?"
      link-class="text-center"
      title="Calidad de imagen"
    >
      <template v-slot:link>
        <div class="text-center">
          <h5>Calidad de imagen</h5>
          <progress-bar
            type="circle"
            ref="circle"
            :options="options"
          ></progress-bar>
        </div>
      </template>
      <div v-if="goodQuality">
        ¡Excelente! la integración <strong>{{ integrationLabel }}</strong> ha
        notificado que la imagen de portada de este producto es de buena
        calidad, por lo que tu producto es más visible que otros que no tengan
        una buena fotografía.
      </div>
      <div v-else>
        La integración <strong>{{ integrationLabel }}</strong> ha notificado que
        la imagen de portada de este producto no es de buena calidad, por lo que
        podrías estar perdiendo exposición. Para mejorar la calidad de tus
        fotografías, sigue los siguientes consejos:
        <div v-if="imageQuality && imageQuality.feedbacks">
          <ul v-for="(feedback, idx) of imageQuality.feedbacks" :key="idx">
            <li>{{ feedback }}</li>
          </ul>
        </div>
      </div>
    </base-help-modal>
  </div>
</template>
<script>
import BaseHelpModal from "./BaseHelpModal";
export default {
  name: "ProductDetailPageImageQuality",
  components: { BaseHelpModal },
  props: {
    product: Object
  },
  data() {
    const imageQuality =
      this.product && this.product.imageQuality
        ? this.product.imageQuality
        : { quality: "good", score: 1 };
    return {
      options: {
        color: imageQuality.quality === "good" ? "green" : "#99000f",
        strokeWidth: 10,
        svgStyle: {
          width: "50px"
        },
        text: {
          value: imageQuality.score * 100 + "%"
        }
      },
      imageQuality
    };
  },
  mounted: function() {
    if (this.product.imageQuality) {
      this.$refs.circle.animate(this.product.imageQuality.score);
    }
  },
  computed: {
    goodQuality() {
      return !!this.imageQuality && this.imageQuality.quality === "good";
    },
    integrationLabel() {
      return this.imageQuality ? this.imageQuality.integrationConfig.label : "";
    }
  }
};
</script>
