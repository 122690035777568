<template>
  <b-card no-body>
    <template #header>
      <b-row align-v="center">
        <b-col sm="12" md="4">
          <b-row>
            <b-col cols="12" md="auto">
              <base-pretty-check-box
                v-model="selected"
                @change="value => $emit('change', value)"
                :disabled="disableOrderSelection"
              ></base-pretty-check-box>
            </b-col>
            <b-col>
              <h4 class="mb-0">
                <router-link
                  :to="{
                    name: 'Order',
                    params: { orderId: order.id }
                  }"
                >
                  {{ order.origin }}
                  -
                  {{ order.numberOrigin ? order.numberOrigin : order.idOrigin }}
                </router-link>
              </h4>
              <h5 class="mb-0">
                {{ order.status | translateState }}
              </h5>
            </b-col>
          </b-row>
        </b-col>
        <b-col sm="12" md="4">
          Comprado el:
          {{
            $timezoneDate(currentUser, order.createdAtOrigin) | formatDateTime
          }}
        </b-col>
        <b-col sm="12" md="4" v-if="order.dateForDelivery">
          Para entregar el: {{ order.dateForDelivery | formatDateTime }}
        </b-col>
        <b-col sm="12" md="4" v-else>
          Sin fecha de compromiso de entrega
        </b-col>
      </b-row>
    </template>

    <b-card-body>
      <!-- <b-card-title>Card Title</b-card-title>
      <b-card-sub-title class="mb-2">Card Sub Title</b-card-sub-title>
      <b-card-text>
        Some quick example text to build on the card title and make up the bulk
        of the card's content.
      </b-card-text> -->
      <b-row>
        <b-col sm="12" md="6">
          <table>
            <tbody>
              <tr>
                <td>Artículos</td>
                <td class="pl-2">+</td>
                <td class="text-right">
                  {{ order.totaAmount || 0 | toCurrency }}
                </td>
              </tr>
              <tr>
                <td>Despacho</td>
                <td class="pl-2">+</td>
                <td class="text-right">
                  {{ order.shippingAmount || 0 | toCurrency }}
                </td>
              </tr>
              <tr>
                <td>Descuentos</td>
                <td class="pl-2">-</td>
                <td class="text-right">{{ discounts | toCurrency }}</td>
              </tr>
            </tbody>
            <tfoot style="border-top: 1px solid">
              <tr>
                <th>Total</th>
                <th class="pl-2">=</th>
                <th class="text-right">{{ order.paidAmount | toCurrency }}</th>
              </tr>
            </tfoot>
          </table>
        </b-col>
        <b-col sm="12" md="3">
          {{ order.buyerFirstName }} {{ order.buyerLastName }} <br />
          {{ order.buyerDni }} <br />
          {{ order.buyerEmail }} <br />
          {{ order.buyerPhone || order.buyerMobilehone }} <br />
        </b-col>
        <b-col sm="12" md="3">
          <order-shipping-label-button
            :order="order"
          ></order-shipping-label-button>
        </b-col>
      </b-row>
    </b-card-body>
    <hr class="m-0" />
    <b-card-body>
      <b-row v-for="item of order.items" :key="item.id">
        <b-col sm="12" md="4">{{ item.name }}</b-col>
        <b-col sm="6" md="2">{{ item.paidPrice | toCurrency }}</b-col>
        <b-col sm="6" md="2"
          >{{ item.quantity - item.quantityRestocked }} u.</b-col
        >
        <b-col sm="12" md="4" v-if="item.variant">{{ item.variant.sku }}</b-col>
        <b-col sm="12" md="4" v-else>
          {{ item.sku }}
          <p class="text-muted">
            No registrado en Centry
          </p>
        </b-col>
      </b-row>
    </b-card-body>
    <b-card-footer>
      <order-notes-form :order="order"></order-notes-form>
    </b-card-footer>
  </b-card>
</template>
<script>
// import OrdersTableRowActions from "./OrdersTableRowActions"
import { mapState, mapMutations } from "vuex";
import BasePrettyCheckBox from "@/components/BasePrettyCheckBox";
import OrderShippingLabelButton from "@/components/order/OrderShippingLabelButton";
import OrderNotesForm from "../components/OrderNotesForm.vue";
export default {
  name: "OrdersListItem",
  components: { BasePrettyCheckBox, OrderShippingLabelButton, OrderNotesForm },
  created() {
    this.setSelectedOrder({
      orderId: this.order.id,
      selected: false,
      order: this.order
    });
  },
  destroyed() {
    this.unsetSelectedOrder(this.order.id);
  },
  data() {
    return {
      selected: false
    };
  },
  computed: {
    ...mapState(["selectedOrders", "disableOrderSelection", "currentUser"]),
    discounts() {
      return (
        (this.order.discountAmount || 0) + (this.order.shippingDiscount || 0)
      );
    }
  },
  props: {
    order: Object
  },
  methods: {
    ...mapMutations(["setSelectedOrder", "unsetSelectedOrder"]),
    showDetails() {
      this.$router.push({ name: "Order", params: { orderId: this.order.id } });
    }
  },
  watch: {
    selected(val) {
      if (val !== this.selectedOrders[this.order.id].selected)
        this.setSelectedOrder({
          orderId: this.order.id,
          selected: val,
          order: this.order
        });
    },
    selectedOrders(hash) {
      const current = hash[this.order.id];
      this.selected = current.selected;
    }
  }
};
</script>

<style scoped>
td {
  cursor: pointer;
}
</style>
