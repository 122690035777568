<template>
  <div>
    <base-header title="Suscripción" title-link="#"></base-header>
    <b-container fluid="">
      <b-row v-if="isSubscriptionValid">
        La suscripción de {{ currentUser.company.name }} está activa por los
        siguientes {{ subscriptionExpiredIn }} días.
      </b-row>
      <b-row v-else>
        La suscripción de {{ currentUser.company.name }} está vencida hace
        {{ subscriptionExpiredIn * -1 }} días.
        <b-link :href="`${centryUrl}/nueva_cotizacion`"
          >Realizar nueva cotización</b-link
        >
      </b-row>
    </b-container>
  </div>
</template>

<script>
import BaseHeader from "../components/BaseHeader.vue";
import { mapState } from "vuex";
import { centryUrl } from "../main";

export default {
  name: "Subscription",
  components: {
    BaseHeader
  },
  data() {
    return {
      centryUrl
    };
  },
  computed: {
    ...mapState(["currentUser"]),
    subscriptionExpiredIn() {
      let expirationDate = this.currentUser.company.mostRecentSubscription
        .expiration;
      const diffTime = new Date(expirationDate) - new Date();
      return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    },
    isSubscriptionValid() {
      return this.subscriptionExpiredIn >= 0;
    }
  }
};
</script>

<style></style>
