<template>
  <div :style="parentDivStyle">
    <v-select
      v-if="attributeInfo.value == 'SIZE_GRID_ROW_ID'"
      style="width: 100%"
      :id="'attribute-' + attributeInfo.value"
      :multiple="attributeInfo.multivalued === true"
      :options="mercadoLibreSizeChartRows"
      :reduce="x => x.value"
      v-model="attribute.value"
      @input="emitInput"
      :disabled="attributeNotApplied || disabled"
    ></v-select>
    <v-select
      v-else-if="attributeInfo.options && attributeInfo.options.length"
      style="width: 100%"
      :id="'attribute-' + attributeInfo.value"
      :multiple="attributeInfo.multivalued === true"
      :options="optionsFor(attributeInfo)"
      :reduce="x => x.value"
      v-model="attribute.value"
      @input="emitInput"
      :disabled="attributeNotApplied || disabled"
    ></v-select>
    <b-input
      style="width: 100%"
      :id="'attribute-' + attributeInfo.value"
      v-else
      v-model="attribute.value"
      @input="emitInput"
      :disabled="attributeNotApplied || disabled"
    ></b-input>
    <b-button
      v-if="showNotApplyButton"
      :id="'not-apply-button-' + attributeInfo.value"
      @click="handleNotApplyButton"
      :variant="notApplyButtonVariant"
      :disabled="disabled"
    >
      N/A
    </b-button>
    <b-tooltip
      :target="'not-apply-button-' + attributeInfo.value"
      triggers="hover"
    >
      Permite marcar/desmarcar como "No Aplica"
    </b-tooltip>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ProductEditTabsMercadoLibreAttributesInput",
  model: {
    prop: "_value"
  },
  props: {
    _value: Object,
    attributeInfo: Object,
    disabled: {
      type: Boolean,
      default: false
    },
    showNotApplyButton: {
      type: Boolean,
      default: true
    },
    inputAndButtonOnSameLine: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      attribute: this.$dup(this._value)
    };
  },
  methods: {
    /**
     * Emite un evento indicando el cambio de value
     * @method
     */
    emitInput() {
      this.$emit("input", this.attribute);
    },
    /**
     * Realiza los cambios en el valor de un atributo para activar o desactivar
     * el estado "No Aplica" del atributo. Emite el cambio.
     */
    handleNotApplyButton() {
      if (this.attributeNotApplied) {
        this.attribute.value_type = this.attributeValueType;
        this.attribute.value = "";
      } else {
        this.attribute.value_type = "not_apply";
        this.attribute.value = "No Aplica";
      }
      this.emitInput();
    },
    /**
     * Construye objeto con opciones para selector
     * @param {Object} attribute
     * @returns {Array<Object>}
     */
    optionsFor(attribute) {
      return attribute.options.map(x => {
        return { label: x.name, value: x.id };
      });
    },
    /**
     * Entrega objeto con valores actuales de una fila de GDT
     * @param {Object} row
     * @return {Object}
     */
    optionFromRow(row) {
      return {
        value: row.id,
        label: row.attributes
          .map(attr => attr.id + ": " + attr.values[0].name)
          .join("; ")
      };
    }
  },
  computed: {
    ...mapState(["mercadoLibreSizeChart"]),
    mercadoLibreSizeChartRows() {
      if (Object.keys(this.mercadoLibreSizeChart).length == 0) {
        return [];
      }
      return this.mercadoLibreSizeChart.rows.map(row =>
        this.optionFromRow(row)
      );
    },
    /**
     * Define si un atributo está marcado como "No Aplica".
     * @returns {Boolean}
     */
    attributeNotApplied() {
      return this.attribute.value_type === "not_apply";
    },
    /**
     * Define la variante del botón "No Aplica" para un atributo.
     * @returns {String}
     */
    notApplyButtonVariant() {
      return this.attributeNotApplied ? "dark" : "light";
    },
    /**
     * Devuelve el tipo de valor del atributo.
     * @returns {String}
     */
    attributeValueType() {
      if (this.attributeInfo.options && this.attributeInfo.options.length) {
        return "value_id";
      }
      return "value_name";
    },
    parentDivStyle() {
      if (this.inputAndButtonOnSameLine) {
        return { display: "flex" };
      }
      return {};
    }
  },
  watch: {
    _value(newValue) {
      this.attribute = this.$dup(newValue);
    }
  }
};
</script>
