<template>
  <div>
    <ApolloQuery
      :query="require('@/graphql/AllCategoryAttributes.gql')"
      :variables="queryVariables('')"
    >
      <template slot-scope="{ result: { error, data }, query, isLoading }">
        <!-- Loading -->
        <b-spinner v-if="isLoading" label="Spinning"></b-spinner>
        <!-- Error -->
        <b-alert v-else-if="error" show="" variant="danger">
          Ha ocurrido un error
        </b-alert>
        <!-- Result -->
        <b-table-simple v-if="data && data.allCategoryAttributes" hover>
          <b-thead>
            <b-tr>
              <b-th>Atributo</b-th>
              <b-th>Homologaciones</b-th>
              <b-th></b-th>
            </b-tr>
            <b-tr>
              <b-th
                ><b-form-group>
                  <b-form-input
                    v-model="categoryNameSelected"
                    placeholder="Atributo"
                    size="sm"
                  ></b-form-input></b-form-group
              ></b-th>
              <b-th></b-th>
              <b-th></b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <vtex-homologation-attributes-row
              v-for="spec of data.allCategoryAttributes.edges"
              :key="spec.node.id"
              :spec="spec.node"
            ></vtex-homologation-attributes-row
          ></b-tbody>
        </b-table-simple>
        <b-button
          v-if="$dig(data, 'allCategoryAttributes', 'pageInfo', 'hasNextPage')"
          class="m-2 float-right"
          @click="seeMore(query, data.allCategoryAttributes.pageInfo.endCursor)"
        >
          Ver más
        </b-button>
        <b-spinner
          v-if="seeMoreLoading"
          label="Spinning"
          class="m-2 float-right"
        ></b-spinner>
      </template>
    </ApolloQuery>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import VtexHomologationAttributesRow from "@/components/VtexHomologation/VtexHomologationAttributes/VtexHomologationAttributesRow";

export default {
  name: "VtexHomologationAttributes",
  components: { VtexHomologationAttributesRow },
  computed: {
    ...mapState(["filters"])
  },
  data() {
    return {
      centryInfo: [],
      seeMoreLoading: false,
      categoryNameSelected: null
    };
  },
  methods: {
    ...mapMutations(["setFilter"]),
    /**
     * Retorna un objeto que contiene los filtros para
     * la query de los atributos de categoría.
     * @param {String} cursor - Desde donde se consultará la lista
     */
    queryVariables(cursor) {
      const variables = {};
      variables.limit = 50;
      variables.order = "asc";
      if (cursor) {
        variables.cursor = cursor;
      }
      if (this.filters.name) {
        variables.name = this.filters.name;
      }
      return variables;
    },
    /**
     * Obtiene los siguientes atributos del listado y los carga en la vista
     * @param {Object} query
     * @param {String} cursor
     */
    async seeMore(query, cursor) {
      this.seeMoreLoading = true;
      await query.fetchMore({
        variables: this.queryVariables(cursor),
        updateQuery: (prev, { fetchMoreResult }) => {
          if (
            this.$ifNull(
              this.$dig(fetchMoreResult, "allCategoryAttributes", "edges"),
              []
            ).length === 0
          ) {
            return prev;
          }
          const updated = Object.assign({}, this.$dup(prev));
          updated.allCategoryAttributes.pageInfo =
            fetchMoreResult.allCategoryAttributes.pageInfo;
          updated.allCategoryAttributes.edges.push(
            ...fetchMoreResult.allCategoryAttributes.edges
          );
          this.seeMoreLoading = false;
          return updated;
        }
      });
    }
  },
  watch: {
    categoryNameSelected(newValue) {
      this.setFilter({ name: newValue });
    }
  }
};
</script>
