<template>
  <b-form-group
    :label-for="label"
    :invalid-feedback="invalidFeedback"
    :state="loading ? null : state"
  >
    <template #label>
      <slot name="label">
        {{ label }}
      </slot>
    </template>
    <b-input-group v-if="!loading" size="sm">
      <b-input-group-prepend>
        <slot name="prepend"> </slot>
      </b-input-group-prepend>
      <b-form-input
        :id="label"
        :readonly="readonly"
        v-model="value"
        @input="emitChange"
        :type="type"
        :number="'number' === type"
        :autocomplete="autocomplete"
        :disabled="disabled"
      />
    </b-input-group>
    <b-skeleton v-else :animation="animation" type="input" />
  </b-form-group>
</template>
<script>
export default {
  name: "InputWithSkeleton",
  model: {
    prop: "_value",
    event: "change"
  },
  props: {
    _value: {
      type: [String, Number],
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    animation: {
      type: String,
      default: "wave"
    },
    label: {
      type: String,
      default: ""
    },
    readonly: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      defualt: "text"
    },
    invalidFeedback: {
      type: String,
      default: ""
    },
    state: {
      type: Boolean,
      default: null
    },
    autocomplete: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      value: this._value
    };
  },
  methods: {
    /**
     * Emite un evento indicando el cambio de value
     * @method
     */
    emitChange() {
      this.$emit("change", this.value);
    }
  },
  watch: {
    _value(newVal) {
      this.value = newVal;
    }
  }
};
</script>
