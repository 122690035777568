var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-header',{attrs:{"title":"Seleccionar grupo","title-link":"#","map":[
      'Mercado Libre',
      'Atributos',
      'Integraciones',
      _vm.integrationConfigLabel,
      'Categorías'
    ],"title-size":8},scopedSlots:_vm._u([{key:"filters",fn:function(){return [_c('mercado-libre-attributes-group-integration-filters'),_c('span',{staticClass:"font-weight-bold text-muted float-right"},[_vm._v(" "+_vm._s(_vm.filterCaption)+" "),(_vm.filterCaption.length)?_c('b-button',{attrs:{"size":"sm"},on:{"click":_vm.cleanFilters}},[_vm._v("Limpiar filtros")]):_vm._e()],1)]},proxy:true}])}),_c('ApolloQuery',{attrs:{"query":require('../graphql/AllIntegrationsMercadoLibreAttributeGroupIC.gql'),"variables":{
      cursor: '',
      integrationConfigId: _vm.integrationConfigId,
      quality: _vm.filters.quality
    },"fetchPolicy":'cache-and-network',"clientId":"apolloClientCached"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var ref_result = ref.result;
    var error = ref_result.error;
    var data = ref_result.data;
    var query = ref.query;
    var isLoading = ref.isLoading;
return [_c('b-row',{attrs:{"align-h":"end"}},[(data && isLoading)?_c('span',{staticClass:"m-2 float-right"},[_vm._v(" Actualizando grupos de atributos... ")]):_vm._e()]),(error)?_c('div',[_vm._v(" Error al obtener los Grupos de Atributos ")]):(
          data &&
            data.allIntegrationsMercadoLibreAttributeGroupIntegrationConfigs &&
            data.allIntegrationsMercadoLibreAttributeGroupIntegrationConfigs
              .edges.length
        )?_c('b-list-group',_vm._l((data
            .allIntegrationsMercadoLibreAttributeGroupIntegrationConfigs
            .edges),function(group){return _c('b-list-group-item',{key:group.id,staticClass:"d-flex justify-content-between align-items-center",attrs:{"to":{
            name: 'MercadoLibreAttribute',
            params: {
              attributeGroupIntegrationConfigId: group.node.id
            }
          }}},[_vm._v(" "+_vm._s(group.node.attributeGroup.name)+" "),_c('b-badge',{attrs:{"variant":"primary","pill":""}},[_vm._v("Calidad minima: "+_vm._s(_vm.formatQuality(group.node.minQuality)))])],1)}),1):_vm._e(),(isLoading)?_c('b-spinner',{staticClass:"m-2 float-right",attrs:{"label":"Spinning"}}):_c('div',[(_vm.hasNextPage(data))?_c('b-button',{staticClass:"m-2 float-right",on:{"click":function($event){return _vm.seeMore(
              query,
              data.allIntegrationsMercadoLibreAttributeGroupIntegrationConfigs
                .pageInfo.endCursor
            )}}},[_vm._v(" Ver más ")]):_c('b-alert',{staticClass:"text-center",attrs:{"show":"","variant":"light"}},[_vm._v(" No hay más datos para mostrar. ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }