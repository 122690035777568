<template>
  <b-modal id="purposeCategoryAttribute" cancel-disabled="" size="lg">
    <template v-slot:modal-title>
      <h4>{{ title }}</h4>
    </template>
    <template>
      <b-spinner
        label="Spinning"
        v-if="!allPublicApplicationInformations || !allOptionLists"
      ></b-spinner>
      <div v-else>
        <b-form-group label="Tipo de dato" label-for="data_type">
          <v-select
            id="data_type"
            :options="allDataTypes"
            placeholder="Seleccionar"
            v-model="dataType"
            :reduce="cond => cond.value"
          ></v-select>
        </b-form-group>
        <b-form-group label="Ruta" label-for="path">
          <b-form-input v-model="path" id="path"></b-form-input>
        </b-form-group>
        <b-form-group
          label="Tipo de integración"
          label-for="public_integration_information_id"
        >
          <v-select
            id="public_integration_information_id"
            :options="allPublicApplicationInformations"
            placeholder="Seleccionar"
            v-model="publicIntegrationInformationId"
            :reduce="cond => cond.value"
          ></v-select>
        </b-form-group>
        <b-form-group label="Listado de opciones" label-for="option_list_id">
          <v-select
            id="option_list_id"
            :options="allOptionLists"
            placeholder="Seleccionar"
            v-model="optionListId"
            :reduce="cond => cond.value"
          ></v-select>
          <b-link to="/admin/option_list"
            >Administrar listado de opciones</b-link
          >
        </b-form-group>
      </div>
    </template>
    <template v-slot:modal-footer="{ ok, cancel }">
      <b-button variant="info" @click="() => save(ok)">
        Guardar
      </b-button>
      <b-button variant="outline-info" @click="cancel">
        Cancelar
      </b-button>
    </template>
    <slot></slot>
  </b-modal>
</template>
<script>
import ALL_PUBLIC_APPLICATION_INFORMATION from "@/graphql/AllPublicApplicationInformations.gql";
import ALL_OPTION_LISTS from "@/graphql/AllHomologationOptionList.gql";
import CREATE_PURPOSE_CATEGORY_ATTRIBUTE from "@/graphql/CreatePurposeCategoryAttribute.gql";
import UPDATE_PURPOSE_CATEGORY_ATTRIBUTE from "@/graphql/UpdatePurposeCategoryAttribute.gql";

export default {
  name: "CategoryAttributeFormPurposeModal",
  props: {
    purpose: Object,
    categoryAttributeId: String
  },
  mounted() {
    this.getAllPII();
    this.getAllOL();
  },
  data() {
    return {
      title: this.purpose.id ? "Actualizar Homologacion" : "Crear Homologacion",
      dataType: this.purpose.dataType ? this.purpose.dataType : "string",
      path: this.purpose.path,
      publicIntegrationInformationId: this.purpose
        .publicIntegrationInformationId,
      optionListId: this.purpose.optionListId,
      allDataTypes: [
        { value: "string", label: "Texto" },
        { value: "integer", label: "Entero" },
        { value: "float", label: "Decimal" },
        { value: "boolean", label: "Booleano" }
      ],
      allPublicApplicationInformations: null,
      allOptionLists: null
    };
  },
  computed: {
    purposeData() {
      return {
        dataType: this.dataType,
        path: this.path,
        publicIntegrationInformationId: this.publicIntegrationInformationId,
        optionListId: this.optionListId
      };
    }
  },
  methods: {
    save(closeFunc) {
      if (this.purpose.id) {
        this.updatePurpose(closeFunc);
      } else {
        this.createPurpose(closeFunc);
      }
    },
    getAllPII() {
      this.$getAllPages(
        ALL_PUBLIC_APPLICATION_INFORMATION,
        {},
        "allPublicApplicationInformations"
      ).then(all => {
        this.allPublicApplicationInformations = all.map(elem => {
          return { label: elem.node.name, value: elem.node.id };
        });
      });
    },
    getAllOL() {
      this.$getAllPages(ALL_OPTION_LISTS, {}, "allHomologationOptionList").then(
        all => {
          this.allOptionLists = all.map(elem => {
            return { label: elem.node.name, value: elem.node.id };
          });
        }
      );
    },
    createPurpose(closeFunction) {
      this.$apollo
        .mutate({
          mutation: CREATE_PURPOSE_CATEGORY_ATTRIBUTE,
          variables: {
            create: Object.assign(
              { categoryAttributeId: this.categoryAttributeId },
              this.purposeData
            )
          }
        })
        .then(({ data }) => {
          const error = this.$dig(
            data,
            "createPurposeCategoryAttribute",
            "error"
          );
          if (error) {
            this.$swal.fire({
              title: "Creación fallida",
              text: `La homologación no pudo crearse: ${error}`,
              icon: "error"
            });
            return;
          }
          this.$swal
            .fire({
              title: "Creación exitosa",
              text: "La homologación ha sido creada",
              icon: "success"
            })
            .then(() => {
              this.$emit(
                "save",
                Object.assign(
                  {},
                  this.purposeData,
                  data.createPurposeCategoryAttribute
                    .homologationsPurposeCategoryAttribute
                )
              );
              closeFunction();
            });
        })
        .catch(error => {
          this.$swal.fire({
            title: "Creación fallida",
            text: `La homologación no pudo crearse: ${error}`,
            icon: "error"
          });
        });
    },
    updatePurpose(closeFunction) {
      this.$apollo
        .mutate({
          mutation: UPDATE_PURPOSE_CATEGORY_ATTRIBUTE,
          variables: {
            id: this.purpose.id,
            patch: this.purposeData
          }
        })
        .then(({ data }) => {
          const error = this.$dig(
            data,
            "updatePurposeCategoryAttribute",
            "error"
          );
          if (error) {
            this.$swal.fire({
              title: "Creación fallida",
              text: `La homologación no pudo actualizarse: ${error}`,
              icon: "error"
            });
            return;
          }
          this.$swal
            .fire({
              title: "Actualización exitosa",
              text: "La homologación ha sido actualizada",
              icon: "success"
            })
            .then(() => {
              this.$emit(
                "save",
                Object.assign(
                  {},
                  this.purposeData,
                  data.updatePurposeCategoryAttribute
                    .homologationsPurposeCategoryAttribute
                )
              );
              closeFunction();
            });
        })
        .catch(error => {
          this.$swal.fire({
            title: "Actualización fallida",
            text: `La homologación no pudo actualizarse: ${error}`,
            icon: "error"
          });
        });
    }
  },
  watch: {
    purpose(purpose) {
      this.title = purpose.id
        ? "Actualizar Homologacion"
        : "Crear Homologacion";
      this.dataType = purpose.dataType ? purpose.dataType : "string";
      this.path = purpose.path;
      this.publicIntegrationInformationId = purpose.publicIntegrationInformation
        ? purpose.publicIntegrationInformation.id
        : purpose.publicIntegrationInformationId;
      this.optionListId = purpose.optionListId;
    }
  }
};
</script>
