<script>
import { Line } from "vue-chartjs";

export default {
  extends: Line,
  props: {
    data: {
      type: Object,
      default() {
        return {};
      }
    },
    options: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      }
    };
  },
  mounted() {
    this.renderChart(this.data, this.mergeOptions());
  },
  methods: {
    mergeOptions() {
      return this.$deepMerge(
        Object.assign({}, this.chartOptions),
        this.options
      );
    }
  },
  watch: {
    data(newVal) {
      this.renderChart(newVal, this.mergeOptions());
    }
  }
};
</script>
