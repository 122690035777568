<template>
  <stacked-bar :data="chartData" />
</template>
<script>
import StackedBar from "../charts/StackedBar.vue";
import { mapState } from "vuex";
export default {
  name: "DashboardBarChartFromTableData",
  components: {
    StackedBar
  },
  props: {
    allData: Array,
    labelColumn: String
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      }
    };
  },
  computed: {
    ...mapState(["allColors"]),
    chartData() {
      return {
        labels: this.labels,
        datasets: this.datasets
      };
    },
    labels() {
      return this.allData.map(x => x[this.labelColumn]);
    },
    datasets() {
      const keys = Object.keys(this.allData[0]).filter(
        x => x !== this.labelColumn
      );
      return keys.map((key, index) => {
        return {
          label: key,
          backgroundColor: this.allColors[index],
          data: this.allData.map(x => x[key])
        };
      });
    }
  }
};
</script>
