<template>
  <xlsx-workbook>
    <xlsx-sheet :collection="data" :sheet-name="widget.name" />
    <xlsx-download :filename="fileName">
      <b-button variant="outline"><b-icon-download></b-icon-download></b-button>
    </xlsx-download>
  </xlsx-workbook>
</template>
<script>
export default {
  name: "ExportXlsxDashboard",
  props: {
    data: Array,
    widget: Object
  },
  computed: {
    fileName() {
      return (
        this.$moment().format("YYYY_MM_DD_h_mm_ss") +
        this.clean(this.widget.name) +
        ".xlsx"
      );
    }
  },
  methods: {
    clean(name) {
      return name.replace(/[\sáéíóúÁÉÍÓÚ]+/g, "_");
    }
  }
};
</script>
