<template>
  <div>
    <ApolloQuery
      :query="require('../graphql/AdminNotSynchronizedError.gql')"
      :variables="{
        cursor: '',
        createdAtLte: this.filters.createdAtLte,
        createdAtGte: this.filters.createdAtGte,
        publicApplicationInformationIds: this.filters
          .publicApplicationInformationIds,
        companyIds: this.filters.companyIds,
        origins: this.filters.origins
      }"
    >
      <template slot-scope="{ result: { error, data }, query, isLoading }">
        <b-table-simple v-if="data && data.adminNotSynchronizedErrors" hover="">
          <b-thead>
            <b-tr>
              <b-th>Fecha</b-th>
              <b-th>Empresa</b-th>
              <b-th>Integración</b-th>
              <b-th>Tipo de integración</b-th>
              <b-th>SKU Producto</b-th>
              <b-th>Origen</b-th>
              <b-th>Fecha inicio sincronización en curso</b-th>
              <b-th>Progreso sincronización en curso</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr
              v-for="edge of data.adminNotSynchronizedErrors.edges"
              :key="edge.node.id"
            >
              <b-td>{{ edge.node.createdAt }}</b-td>
              <b-td>{{ $dig(edge, "node", "company", "name") }}</b-td>
              <b-td>{{
                $dig(edge, "node", "integrationConfig", "label")
              }}</b-td>
              <b-td>{{
                $dig(edge, "node", "publicApplicationInformation", "name")
              }}</b-td>
              <b-td>{{ $dig(edge, "node", "product", "sku") }}</b-td>
              <b-td>{{ edge.node.origin }}</b-td>
              <b-td>{{ edge.node.asyncTaskLogStartDate }}</b-td>
              <b-td>{{ edge.node.asyncTaskLogProgress }}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
        <b-alert v-if="error" show="" variant="danger">
          Ha ocurrido un error
        </b-alert>
        <b-spinner
          v-if="isLoading"
          label="Spinning"
          class="m-2 float-right"
        ></b-spinner>
        <div v-else>
          <b-button
            v-if="hasNextPage(data)"
            class="m-2 float-right"
            @click="
              seeMore(query, data.adminNotSynchronizedErrors.pageInfo.endCursor)
            "
          >
            Ver más
          </b-button>
          <b-alert v-else show variant="light" class="text-center">
            No hay más datos para mostrar.
          </b-alert>
        </div>
      </template>
    </ApolloQuery>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "SynchronizationMonitoringTable",
  computed: {
    ...mapState(["filters"])
  },
  methods: {
    ...mapMutations(["setFilter"]),
    /**
     * Indica si hay una página siguiente en la query de GraphQl basado
     * exclusivamente en la existencia de un cursor.
     * @return {boolean}
     */
    hasNextPage(data) {
      return data?.adminNotSynchronizedErrors?.pageInfo?.endCursor != null;
    },
    /**
     * Trae más resultados de una query avanzando en su cursor.
     * @param {Object} query
     * @param {String} cursor
     */
    async seeMore(query, cursor) {
      await query.fetchMore({
        variables: {
          cursor: cursor,
          createdAtLte: this.filters.createdAtLte,
          createdAtGte: this.filters.createdAtGte,
          publicApplicationInformationIds: this.filters
            .publicApplicationInformationIds,
          companyIds: this.filters.companyIds,
          origins: this.filters.origins
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          const updated = Object.assign({}, this.$dup(prev));
          updated.adminNotSynchronizedErrors.pageInfo =
            fetchMoreResult.adminNotSynchronizedErrors.pageInfo;
          if (
            fetchMoreResult?.adminNotSynchronizedErrors?.edges?.length !== 0
          ) {
            updated.adminNotSynchronizedErrors.edges.push(
              ...fetchMoreResult.adminNotSynchronizedErrors.edges
            );
          }
          return updated;
        }
      });
    }
  }
};
</script>
