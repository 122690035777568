<template>
  <div>
    <b-alert
      :show="errorMessage ? true : false"
      variant="danger"
      dismissible
      @dismissed="errorMessage = null"
      style="position: fixed !important"
    >
      {{ errorMessage }}
    </b-alert>
    <ApolloQuery
      :query="require('../../../graphql/AllVtexCategories.gql')"
      :variables="{ ic: vtexIntegrationConfigId, limit: 100, offset: 0 }"
      fetchPolicy="no-cache"
    >
      <template slot-scope="{ result: { loading, error, data }, isLoading }"
        ><b-row>
          <b-spinner
            v-if="isLoading || loading || !data"
            label="Spinning"
          ></b-spinner>
          <b-alert v-else-if="error" show="" variant="danger">
            Ha ocurrido un error
          </b-alert>
          <b-col v-else md="4"
            ><base-category-tree
              :categories="processCategories(data.allVtexCategories)"
              children-path="children"
              id-path="id"
              @category="updateCategory"
            ></base-category-tree
          ></b-col>
          <b-col v-if="category" md="8">
            <b-table-simple responsive>
              <b-thead>
                <b-tr>
                  <b-th>Id</b-th>
                  <b-th>Nombre</b-th>
                  <b-th>Ruta</b-th>
                  <b-th>¿Es categoría hoja?</b-th>
                  <b-th></b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <vtex-categories-maintainer-table-row
                  :key="category.id"
                  :category="category"
                  :integrationConfigId="vtexIntegrationConfigId"
                >
                </vtex-categories-maintainer-table-row>
              </b-tbody>
            </b-table-simple>
          </b-col>
        </b-row>
      </template>
    </ApolloQuery>
  </div>
</template>
<script>
import { mapState } from "vuex";
import VtexCategoriesMaintainerTableRow from "@/components/VtexMaintainers/Categories/VtexCategoriesMaintainerTableRow";
import BaseCategoryTree from "@/components/BaseCategoryTree";
export default {
  name: "PendingCategoryAttributesTable",
  components: {
    BaseCategoryTree,
    VtexCategoriesMaintainerTableRow
  },
  props: {
    vtexIntegrationConfigId: String
  },
  data() {
    return {
      seeMoreLoading: false,
      errorMessage: null,
      category: ""
    };
  },
  computed: {
    ...mapState(["filters"])
  },
  beforeMount() {},
  methods: {
    /**
     * Guarda los datos de la categoria informada por el evento 'category'
     * @param {Object} category
     */
    updateCategory(category) {
      this.category = category;
    },
    /**
     * Emite y retorna las categorias obtenidas por el ApolloQuery de la vista.
     * @param {Array<Object>} categories
     * @return {Array<Object>}
     */
    processCategories(categories) {
      this.emitCategories(categories);
      return categories;
    },
    /**
     * Emite el evento 'emitCategories' informando las categorias obtenidas
     * @param {Array<Object>} categories
     */
    emitCategories(categories) {
      this.$emit("emitCategories", categories);
    }
  },
  watch: {}
};
</script>

<style scoped>
.btn-sm {
  margin: 5px;
}
</style>
