<template>
  <b-tr>
    <b-td>
      <p
        :class="{
          'mb-0': isMainWarehouse
        }"
      >
        {{ warehouse.name }}
      </p>
      <p v-if="isMainWarehouse" class="text-secondary">
        Bodega principal
      </p>
    </b-td>
    <b-td>
      <p class="mb-0">
        {{ warehouse.address }}
      </p>
      <p class="text-secondary">
        {{ address }}
      </p>
    </b-td>
    <b-td>
      <b-row align-h="end" no-gutters>
        <div>
          <span v-if="deleting"> Eliminando <b-spinner small /> </span>
        </div>
        <b-button
          v-if="$permissionToUpdate(currentUser, 'warehouses')"
          size="lg"
          variant="outlined"
          class="p-0 focus-btn"
          v-b-tooltip.hover="'Editar bodega'"
          :to="`warehouse/${warehouse.id}/edit`"
        >
          <b-icon stacked icon="pencil" scale="0.60"></b-icon>
        </b-button>
        <b-button
          v-if="
            !isMainWarehouse && $permissionToDelete(currentUser, 'warehouses')
          "
          size="lg"
          variant="outlined"
          class="p-0 focus-btn"
          v-b-tooltip.hover="'Eliminar bodega'"
          @click="swalDeleteWarehouse"
        >
          <b-icon stacked icon="x" scale="0.75"></b-icon>
        </b-button>
      </b-row>
    </b-td>
  </b-tr>
</template>

<script>
import DELETE_WAREHOUSE from "../../graphql/Warehouses/DeleteWarehouse.gql";
import { mapState } from "vuex";
export default {
  name: "WarehousesDetail",
  props: {
    warehouse: Object
  },
  data() {
    return {
      deleting: false
    };
  },
  computed: {
    ...mapState(["currentUser"]),
    address() {
      let tempName = [
        this.warehouse?.commune?.name,
        this.warehouse?.region?.name
      ];

      return tempName
        .filter(element => {
          return element !== undefined;
        })
        .join(", ");
    },
    isMainWarehouse() {
      return this.currentUser.company.mainWarehouseId === this.warehouse.id;
    }
  },
  methods: {
    /**
     * Se encarga de enviar la mutacion para borrar una bodega
     */
    deleteWarehouse() {
      this.deleting = true;
      this.$apollo
        .mutate({
          mutation: DELETE_WAREHOUSE,
          variables: {
            warehouseId: this.warehouse.id
          }
        })
        .then(({ data }) => {
          if (data.deleteWarehouse.result) {
            this.$emit("delete-variant");
            this.deleting = false;
          } else {
            this.$emit("delete-variant", data.deleteWarehouse.error);
            this.deleting = false;
          }
        });
    },
    /**
     * Lanza un swal para confirmar la eliminacion de la bodega
     */
    swalDeleteWarehouse() {
      this.$swal
        .fire({
          title: "Eliminar bodega",
          text: "La bodega se eliminará definitivamente. ¿Estás seguro?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si",
          cancelButtonText: "No"
        })
        .then(result => {
          if (result.value) {
            this.deleteWarehouse();
          }
        });
    }
  }
};
</script>

<style>
.focus-btn {
  color: #aab1b5;
  display: inline-block;
  background-color: white;
  width: 34px;
  height: 34px;
  text-align: center;
  border-radius: 50%;
  font-size: 14px;
  border: 2px solid #e0e2e4;
  padding: 5px 0;
}

.focus-btn:hover {
  border-color: #aab1b5;
  color: black;
}
</style>
