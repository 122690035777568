<template>
  <b-form-group :label="label" :key="attribute.FeedName">
    <base-date-time
      v-if="attribute.InputType === 'datefield'"
      v-model="value"
      @change="emitInput"
      type="datetime"
      value-type="format"
      :disabled="!allowSave"
    ></base-date-time>
    <v-select
      v-else-if="attribute.InputType === 'dropdown'"
      v-model="value"
      :options="options"
      @input="emitInput"
      :reduce="x => x.value"
    ></v-select>
    <b-form-checkbox
      v-else-if="attribute.InputType === 'checkbox'"
      v-model="value"
      value="1"
      unchecked-value=""
      @input="emitInput"
    ></b-form-checkbox>
    <v-select
      v-else-if="attribute.InputType === 'multiselect'"
      multiple=""
      v-model="value"
      :options="
        attribute.Options.Option.map(x => {
          return { label: x.Name, value: x.Name };
        })
      "
      @input="emitInput"
      :reduce="x => x.value"
    >
    </v-select>
    <b-textarea
      v-else-if="attribute.InputType === 'textarea'"
      v-model="value"
      @input="emitInput"
    ></b-textarea>
    <b-form-input
      v-else
      v-model="value"
      @input="emitInput"
      :type="inputType(attribute)"
    ></b-form-input>
  </b-form-group>
</template>
<script>
export default {
  name: "ProductEditTabsRocketInternetAttributeInput",
  model: {
    prop: "_value",
    event: "input"
  },
  props: {
    _value: {
      type: [String, Number]
    },
    attribute: Object,
    showLabel: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      value: this._value
    };
  },
  computed: {
    /**
     * Entrega un listado de opciones no repetidas
     */
    options() {
      if (!this.attribute.Options) return [];

      const alreadyIncluded = {};
      const allOptions = [];
      this.attribute.Options.Option.forEach(x => {
        if (!alreadyIncluded[x.Name]) {
          allOptions.push({ label: x.Name, value: x.Name });
          alreadyIncluded[x.Name] = true;
        }
      });
      return allOptions;
    },
    /**
     * Etiqueta de nombre de atributo
     */
    label() {
      if (!this.showLabel) return "";
      if (this.attribute.isMandatory === "1") {
        return `* ${this.attribute.Label}`;
      }
      return this.attribute.Label;
    }
  },
  methods: {
    /**
     * Entrega el tipo de input según el atributo
     * @param {Object} attribute
     * @returns {String}
     */
    inputType(attribute) {
      if (attribute.InputType === "numberfield") {
        return "number";
      }
      return "text";
    },
    /**
     * Emite el evento input
     */
    emitInput() {
      this.$emit("input", this.value);
    }
  }
};
</script>
