<template>
  <div>
    <b-row
      v-if="
        ['regular', 'progressive', 'buyAndWin'].indexOf(promotion.type) != -1
      "
    >
      <b-col v-if="['regular', 'progressive'].indexOf(promotion.type) != -1">
        <b-form-group>
          <b-form-checkbox v-model="activeZipCodeRanges">
            Código postal de entrega
          </b-form-checkbox>

          <b-row v-for="(zipCodeRange, index) in zipCodeRanges" :key="index">
            <b-col>
              <v-select
                v-model="zipCodeRange.inclusive"
                :options="zipCodeRangesOptions"
                :disabled="!activeZipCodeRanges"
              >
              </v-select>
            </b-col>

            <b-col>
              <b-form-group>
                <b-form-input
                  placeholder="desde"
                  v-model="zipCodeRange.zipCodeFrom"
                  :disabled="!activeZipCodeRanges"
                >
                </b-form-input>
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group>
                <b-form-input
                  placeholder="hasta"
                  v-model="zipCodeRange.zipCodeTo"
                  :disabled="!activeZipCodeRanges"
                >
                </b-form-input>
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group>
                <b-button
                  variant="outline-info"
                  @click="deleteZipCodeRange(index)"
                  :disabled="!activeZipCodeRanges"
                >
                  <b-icon-x-circle></b-icon-x-circle>
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>

          <b-button
            v-show="activeZipCodeRanges"
            variant="outline-info"
            @click="addZipCodeRange"
          >
            <b-icon-plus></b-icon-plus> Agregar Rango
          </b-button>
        </b-form-group>
      </b-col>

      <b-col v-if="['regular', 'buyAndWin'].indexOf(promotion.type) != -1">
        <b-form-group>
          <b-form-checkbox v-model="activeSlas">
            Tipo de entrega
          </b-form-checkbox>

          <b-spinner v-if="loadingSlas" label="Spinning"></b-spinner>
          <v-select
            v-else
            multiple
            v-model="slasIds"
            :options="slasOptions"
            :disabled="!activeSlas"
          ></v-select>

          <b-form-checkbox
            v-show="shippingDiscount"
            v-model="forAllShippings"
            :disabled="activeSlas"
            :value="false"
            :unchecked-value="true"
          >
            Aplique el descuento solo para la opción de envio mas barata
          </b-form-checkbox>

          <b-form-checkbox v-model="isSlaSelected" :disabled="!activeSlas">
            Aplique el descuento solo cuando el cliente seleccione uno de los
            transportistas anteriores
          </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import INTEGRATION_CONFIG_VTEX_ALL_SHIPPING_POLICIES from "@/graphql/IntegrationConfigVtexAllShippingPolicies.gql";

const zipCodeRangesOptions = [
  { value: true, label: "Incluir" },
  { value: false, label: "Excluir" }
];

export default {
  name: "VtexBasePromotionZipCodeAndShippingType",
  props: {
    value: {
      type: Object,
      required: true
    },
    vtexIntegrationConfigId: {
      type: String,
      required: true
    },
    shippingDiscount: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      promotion: this.value,
      activeZipCodeRanges: this.value.zipCodeRanges.length > 0,
      zipCodeRanges: this.value.zipCodeRanges.map(zipCodeRange => {
        return {
          inclusive: this.zipCodeRangeInclusiveByValue(zipCodeRange.inclusive),
          zipCodeFrom: zipCodeRange.zipCodeFrom,
          zipCodeTo: zipCodeRange.zipCodeTo
        };
      }),
      zipCodeRangesOptions: zipCodeRangesOptions,
      activeSlas: this.value.slasIds.length > 0,
      slasIds: [],
      loadingSlas: true,
      isSlaSelected: this.value.isSlaSelected,
      forAllShippings: this.value.applyToAllShippings
    };
  },
  /**
   * Se obtienen los datos de envios disponibles en Vtex.
   * Los datos se guardan en los selectores correspondientes de la vista.
   * Tambien se asignan los valores seleccionados de la promoción a los de la vista.
   */
  async mounted() {
    this.getShippingPolicies().then(() => {
      this.setSelectedShippingPolicies();
    });
  },
  methods: {
    /**
     * Revisa que opción del selector de inclusión de rango de codigo postal utilizar.
     * @param {String} value
     */
    zipCodeRangeInclusiveByValue(value) {
      return zipCodeRangesOptions.find(option => option.value === value);
    },
    /**
     * Añade un nuevo rango de codigo postal a las condiciones la promoción.
     */
    addZipCodeRange() {
      this.zipCodeRanges.push({
        zipCodeFrom: "",
        zipCodeTo: "",
        inclusive: { value: true, label: "Incluir" }
      });
    },
    /**
     * Elimina un rango de codigo postal de las condiciones la promoción.
     * @param {Number} index
     */
    deleteZipCodeRange(index) {
      this.zipCodeRanges.splice(index, 1);
    },
    /**
     * Obtiene las politicas de envio disponibles en Vtex.
     */
    async getShippingPolicies() {
      await this.$apollo
        .query({
          query: INTEGRATION_CONFIG_VTEX_ALL_SHIPPING_POLICIES,
          variables: {
            id: this.vtexIntegrationConfigId
          }
        })
        .then(({ data }) => {
          this.slasOptions = data.integrationConfigVtex.allShippingPolicies.map(
            policy => ({
              label: policy.shippingMethod,
              value: policy.shippingMethod
            })
          );
          this.loadingSlas = false;
        });
    },
    /**
     * Revisa y selecciona las politicas de envio seleccionadas en la promoción.
     */
    setSelectedShippingPolicies() {
      if (this.value.slasIds) {
        this.slasIds = this.value.slasIds.map(sla => {
          return this.slasOptions.find(option => option.value === sla);
        });
      }
    }
  },
  watch: {
    value(newValue) {
      this.promotion = newValue;
    },
    promotion: {
      handler(newValue) {
        this.$emit("input", newValue);
      },
      deep: true
    },
    activeZipCodeRanges(newValue) {
      if (newValue) {
        this.promotion.zipCodeRanges = this.zipCodeRanges.map(zipCodeRange => {
          return {
            inclusive: zipCodeRange.inclusive.value,
            zipCodeFrom: zipCodeRange.zipCodeFrom,
            zipCodeTo: zipCodeRange.zipCodeTo
          };
        });
      } else {
        this.promotion.zipCodeRanges = [];
      }
    },
    zipCodeRanges: {
      handler(newValue) {
        this.promotion.zipCodeRanges = newValue.map(zipCodeRange => {
          return {
            inclusive: zipCodeRange.inclusive.value,
            zipCodeFrom: zipCodeRange.zipCodeFrom,
            zipCodeTo: zipCodeRange.zipCodeTo
          };
        });
      },
      deep: true
    },
    activeSlas(newValue) {
      if (newValue) {
        this.promotion.slasIds = this.slasIds.map(sla => {
          return sla.value;
        });
        this.promotion.isSlaSelected = this.isSlaSelected;
      } else {
        this.promotion.slasIds = [];
        this.promotion.isSlaSelected = false;
      }
    },
    slasIds(newValue) {
      this.promotion.slasIds = newValue.map(sla => {
        return sla.value;
      });
    },
    isSlaSelected(newValue) {
      this.promotion.isSlaSelected = newValue;
    },
    forAllShippings(newValue) {
      this.promotion.applyToAllShippings = newValue;
    },
    shippingDiscount(newValue) {
      if (!newValue) {
        this.promotion.applyToAllShippings = false;
      } else {
        this.promotion.applyToAllShippings = this.forAllShippings;
      }
    }
  }
};
</script>
