<template>
  <div>
    <b-alert
      v-if="error || success"
      show=""
      :variant="error ? 'danger' : 'success'"
      dismissible
    >
      {{
        error
          ? "Ha ocurrido un error guardando los datos: " + errorMessage
          : "Guardado exitosamente"
      }}
    </b-alert>
    <b-row v-if="loading">
      <b-col v-for="i in 6" :key="i" cols="4" class="mb-5">
        <b-skeleton
          animation="wave"
          type="input"
          :width="`${80 - Math.floor(Math.random() * 20)}%`"
        />
      </b-col>
    </b-row>
    <div v-else align-h="between">
      <b-row>
        <b-col xl="10" cols="12">
          <b-row>
            <b-col xl="4" cols="12">
              <b-form-group label-for="priceFactor">
                <template #label>
                  <b-row align-h="between" class="px-3">
                    <span>Factor de precio</span>
                    <base-help-modal
                      id="helpPriceFactor"
                      tooltip="Click aquí para ver un ejemplo"
                      title="Factor de precio"
                      size="md"
                    >
                      <help-price-factor-modal :ic-name="ic.label" />
                    </base-help-modal>
                  </b-row>
                </template>
                <b-form-input
                  id="priceFactor"
                  type="number"
                  number
                  min="0"
                  step="0.01"
                  v-model="values.priceFactor"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col xl="4" sm="6" xs="12">
              <b-form-group label-for="priceRoundFormat">
                <template #label>
                  <b-row align-h="between" class="px-3">
                    <span>Redondeo del precio</span>
                    <base-help-modal
                      id="helpPriceRoundFormat"
                      tooltip="Click aquí para ver un ejemplo"
                      title="Redondeo del Precio"
                      size="lg"
                    >
                      <help-price-round-format-modal />
                    </base-help-modal>
                  </b-row>
                </template>
                <b-form-input
                  id="priceRoundFormat"
                  v-model="values.priceRoundFormat"
                  placeholder="Ejemplos: 990 ó #.##"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col xl="4" sm="6" xs="12">
              <b-form-group label-for="priceRoundApproximation">
                <template #label>
                  <b-row
                    align-h="between"
                    class="px-3"
                    style="min-height: 26px"
                  >
                    <span>Sentido de la aproximación</span>
                  </b-row>
                </template>
                <v-select
                  id="priceRoundApproximation"
                  :clearable="false"
                  :options="priceRoundApproximationOptions"
                  v-model="values.priceRoundApproximation"
                  :reduce="val => val.value"
                ></v-select>
              </b-form-group>
            </b-col>

            <b-col xl="4" sm="6" xs="12">
              <b-form-group label-for="skuPrefix">
                <template #label>
                  <b-row
                    align-h="between"
                    class="px-3"
                    style="min-height: 26px"
                  >
                    <span>Prefijo del SKU</span>
                    <base-help-modal
                      id="helpSkuPrefix"
                      tooltip="Click aquí para ver un ejemplo"
                      title="Prefijo del SKU"
                      :scrollable="true"
                      size="xl"
                    >
                      <help-sku-prefix-modal :ic-name="ic.label" />
                    </base-help-modal>
                  </b-row>
                </template>
                <b-form-input
                  id="skuPrefix"
                  placeholder="Prefijo del SKU"
                  v-model="values.skuPrefix"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col xl="4" sm="6" xs="12">
              <b-form-group label-for="deleteStatus">
                <template #label>
                  <b-row
                    align-h="between"
                    class="px-3"
                    style="min-height: 26px"
                  >
                    <span
                      >Qué hacer en el marketplace cuando se borra un producto
                      en Centry</span
                    >
                  </b-row>
                </template>
                <v-select
                  id="deleteStatus"
                  :clearable="false"
                  :options="deleteStatusOptions"
                  v-model="values.deleteStatus"
                  :reduce="val => val.value"
                ></v-select>
              </b-form-group>
            </b-col>

            <b-col xl="4" sm="6" xs="12" v-if="showProductSpentBehavior">
              <b-form-group label-for="productSpentBehavior">
                <template #label>
                  <b-row
                    align-h="between"
                    class="px-3"
                    style="min-height: 26px"
                  >
                    <span
                      >Qué hacer en el marketplace cuando se agota un producto
                      en Centry
                    </span>
                  </b-row>
                </template>
                <v-select
                  id="productSpentBehavior"
                  :clearable="false"
                  :options="productSpentBehaviorOptions"
                  v-model="values.productSpentBehavior"
                  :reduce="val => val.value"
                ></v-select>
              </b-form-group>
            </b-col>

            <b-col xl="4" sm="6" xs="12">
              <b-form-group label-for="daysBackStockDiscount">
                <template #label>
                  <b-row
                    align-h="between"
                    class="px-3"
                    style="min-height: 26px"
                  >
                    <span
                      >No descontar stock de pedidos creados hace más de (en
                      días, -1 para descontar stock siempre):</span
                    >
                  </b-row>
                </template>
                <b-form-input
                  id="daysBackStockDiscount"
                  type="number"
                  number
                  placeholder="2"
                  v-model="values.daysBackStockDiscount"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col xl="2" cols="12">
          <b-button
            variant="info"
            :disabled="!changed || saving"
            :title="changed ? '' : 'No hay cambios'"
            @click="save"
            class="mb-3 w-100"
          >
            <span v-if="saving"><b-spinner label="Spinning"></b-spinner></span>
            <span v-else>Guardar</span>
          </b-button>
          <b-button
            variant="outline-info"
            class="w-100 px-0"
            :disabled="!changed || saving"
            @click="resetData()"
          >
            <span>Restablecer</span>
          </b-button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import BaseHelpModal from "../BaseHelpModal";
import INTEGRATION_CONFIG_VALUE_TRANSFORMATIONS from "../../graphql/IntegrationConfig/ValueTransformations.gql";
import UPDATE_INTEGRATION_CONFIG from "../../graphql/IntegrationConfig/UpdateIntegrationConfig.gql";
import HelpPriceFactorModal from "./HelpPriceFactorModal.vue";
import HelpPriceRoundFormatModal from "./HelpPriceRoundFormatModal.vue";
import HelpSkuPrefixModal from "./HelpSkuPrefixModal.vue";
import { mapMutations } from "vuex";
export default {
  name: "ValueTransformation",
  components: {
    BaseHelpModal,
    HelpPriceFactorModal,
    HelpPriceRoundFormatModal,
    HelpSkuPrefixModal
  },
  props: {
    ic: {
      type: Object,
      default() {
        return {};
      }
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      originalValues: {},
      values: {},
      loading: false,
      saving: false,
      errorMessage: null,
      error: false,
      success: false,
      showProductSpentBehavior: false,
      priceRoundApproximationOptions: [
        {
          value: "near",
          label: "Número más cercano"
        },
        {
          value: "up",
          label: "Número superior"
        },
        {
          value: "down",
          label: "Número inferior"
        }
      ],
      deleteStatusOptions: [
        {
          value: "delete",
          label: "Eliminar"
        },
        {
          value: "unpublish",
          label: "Despublicar (pausar)"
        }
      ],
      productSpentBehaviorOptions: [
        {
          value: "spent",
          label: "Publicar como agotado"
        },
        {
          value: "unpublish",
          label: "Despublicar (pausar)"
        }
      ]
    };
  },
  async mounted() {
    await this.getValueTransformations();
  },
  computed: {
    changed() {
      return !this.$objSimpleCompare(this.originalValues, this.values);
    }
  },
  methods: {
    ...mapMutations(["setIntegrationConfigChanged"]),
    /**
     * Se encarga de obtener el valor actual de
     * los campos para la transformacion de valores
     */
    async getValueTransformations() {
      this.loading = true;
      await this.$apollo
        .query({
          query: INTEGRATION_CONFIG_VALUE_TRANSFORMATIONS,
          variables: { integrationConfigId: this.ic.id }
        })
        .then(({ data }) => {
          let values = this.$dup(data.integrationConfig);
          this.showProductSpentBehavior = values?.selects?.productSpentBehavior;
          delete values.selects;
          delete values.id;
          delete values.__typename;
          let tempValues = {};
          Object.keys(values).forEach(val => {
            tempValues[val] = values[val];
          });
          this.values = this.$dup(tempValues);
          this.originalValues = this.$dup(this.values);
          this.loading = false;
        });
    },
    /**
     * Envia la mutacion para guardar los nuevos
     * valores de los campos para la sinncronizacion
     */
    save() {
      this.saving = true;
      this.error = false;
      this.success = false;
      this.errorMessage = null;
      this.$apollo
        .mutate({
          mutation: UPDATE_INTEGRATION_CONFIG,
          variables: {
            integrationConfigId: this.ic.id,
            patch: this.values
          }
        })
        .then(({ data }) => {
          this.saving = false;
          if (data?.updateIntegrationConfig?.result) {
            this.errorMessage = null;
            this.error = false;
            this.success = true;
            this.originalValues = this.$dup(this.values);
          } else {
            this.errorMessage = data?.updateIntegrationConfig?.error;
            this.error = true;
          }
        });
    },
    /**
     * Se encarga de resetear los datos
     */
    resetData() {
      this.loading = true;
      this.values = this.$dup(this.originalValues);
      this.errorMessage = null;
      this.error = false;
      this.success = false;
      this.loading = false;
    }
  },
  watch: {
    changed(val) {
      this.setIntegrationConfigChanged(val);
    },
    visible(val) {
      if (val) {
        this.resetData();
      }
    }
  }
};
</script>

<style></style>
