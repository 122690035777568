var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('b-spinner',{attrs:{"label":"Spinning"}}):_c('div',[(_vm.item)?_c('base-header',{attrs:{"title":"Productos","title-link":"/productos","map":['Mercado Libre', _vm.item.title, 'Fulfillment', 'Operaciones']},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('b-container',[_c('b-dropdown',{attrs:{"variant":"outline-info","text":"Ver más"}},[(_vm.$route.meta.action !== 'Stock de fulfillment')?_c('b-dropdown-item',{attrs:{"to":{
                name: 'MercadoLibreItemFulfillmentStock',
                params: {
                  integrationConfigId: _vm.integrationConfigId,
                  itemId: _vm.itemId
                }
              }}},[_vm._v(" Stock ")]):_vm._e(),(_vm.$route.meta.action !== 'Operaciones de fulfillment')?_c('b-dropdown-item',{attrs:{"to":{
                name: 'MercadoLibreItemFulfillmentOperations',
                params: {
                  integrationConfigId: _vm.integrationConfigId,
                  itemId: _vm.itemId
                }
              }}},[_vm._v(" Operaciones ")]):_vm._e()],1)],1)]},proxy:true}],null,false,3755946691)}):_vm._e(),_c('b-container',{attrs:{"fluid":""}},[(_vm.operations.length == 0)?_c('b-alert',{attrs:{"show":"","variant":"info"}},[_vm._v(" Este producto no tiene operaciones de fulfillment ")]):_c('div',[_c('b-table-simple',{attrs:{"small":"","responsive":""}},[_c('b-thead',[_c('b-tr',[_c('b-th',[_vm._v("Fecha")]),_c('b-th',[_vm._v("Tipo")]),_c('b-th',{attrs:{"rowspan":"2"}},[_vm._v("Movimiento")]),_c('b-th',{attrs:{"colspan":"3"}},[_vm._v("Stock")])],1),_c('b-tr',[_c('b-th',[_c('date-picker',{attrs:{"valueType":"format","range":true},on:{"change":function($event){return _vm.listOperations(null)}},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}})],1),_c('b-th',[_c('v-select',{attrs:{"options":_vm.typesList,"reduce":function (x) { return x.value; }},on:{"input":function($event){return _vm.listOperations(null)}},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1),_c('b-th',[_vm._v("Disponible")]),_c('b-th',[_vm._v("No Disponible")]),_c('b-th',[_vm._v("Total")])],1)],1),_c('b-tbody',_vm._l((_vm.operations),function(operation){return _c('b-tr',{key:operation.id},[_c('b-th',[_vm._v(_vm._s(operation.date_created))]),_c('b-th',[_vm._v(_vm._s(operation.type))]),_c('b-th',{class:{
                  'text-success': operation.detail.available_quantity > 0,
                  'text-danger': operation.detail.available_quantity < 0
                }},[_vm._v(" "+_vm._s(operation.detail.available_quantity > 0 ? "+" : "")+_vm._s(operation.detail.available_quantity)+" ")]),_c('b-th',[_vm._v(_vm._s(operation.result.available_quantity))]),_c('b-th',[_vm._v(_vm._s(operation.result.not_available_quantity))]),_c('b-th',[_vm._v(_vm._s(operation.result.total))])],1)}),1)],1),(_vm.scroll)?_c('b-button',{staticClass:"m-2 float-right",on:{"click":function($event){return _vm.listOperations(_vm.scroll)}}},[_vm._v(" Ver más ")]):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }