<template>
  <!-- Spinner -->
  <b-skeleton-table
    v-if="isLoading"
    :rows="10"
    :columns="fields.length"
    :table-props="{ bordered: true, striped: true }"
  ></b-skeleton-table>
  <!-- Mensaje de Error en caso de que falle la Query -->
  <div v-else-if="error">
    <p>
      Hubo un error al tratar de obtener las razones
      <b-link @click="awaitForReasons">aqui.</b-link>
    </p>
  </div>
  <!-- Tabla de Recomendaciones -->
  <div v-else>
    <b-button
      class="m-2 float-right"
      size="sm"
      @click="restartReasons"
      title="Recargar Razones"
    >
      <b-icon-arrow-repeat />
    </b-button>
    <span v-html="'Motivo: ' + reason"></span>
    <b-table
      :items="conditions"
      :fields="fields"
      responsive="sm"
      style="background-color: #f7f8f8"
    >
    </b-table>
  </div>
</template>

<script>
import GET_REASON_BY_ITEM from "@/graphql/MercadoLibre/Recommendations/RecommendationReasonByItem.gql";
export default {
  name: "RecommendationsReasons",
  components: {},
  props: {
    integrationConfigId: {
      type: String
    },
    itemId: {
      type: String
    }
  },
  data() {
    return {
      fields: [{ key: "message", label: "Condiciones" }],
      conditions: [],
      reason: "",
      error: false,
      isLoading: true
    };
  },
  async created() {
    await this.seeFirst();
  },
  methods: {
    /**
     * Se encarga de pedir la primera consulta de las recomendaciones
     * Se reciben ultimos 10 registros
     */
    async seeFirst() {
      this.isLoading = true;
      await this.awaitForReasons();
    },
    /**
     *
     */
    async awaitForReasons() {
      this.error = false;
      this.isLoading = true;
      await this.$apollo
        .query({
          query: GET_REASON_BY_ITEM,
          variables: this.queryReasonVariables(),
          fetchPolicy: "no-cache"
        })
        .then(({ data }) => {
          if (
            this.$dig(
              data,
              "integrationConfigMercadoLibre",
              "recommendationReasonByItem"
            )
          ) {
            this.conditions = this.$dig(
              data,
              "integrationConfigMercadoLibre",
              "recommendationReasonByItem",
              "conditions"
            );
            this.reason = this.$dig(
              data,
              "integrationConfigMercadoLibre",
              "recommendationReasonByItem",
              "reason"
            );
          }
          this.isLoading = false;
        })
        .catch(() => {
          this.error = true;
          this.isLoading = false;
        });
    },
    /**
     * Costruye objeto con variables para consulta de las razones de las recomendaciones
     * El objeto debe incluir la id de la integration config y el itemid
     */
    queryReasonVariables() {
      const variables = {};
      variables.id = this.integrationConfigId;
      variables.itemId = this.itemId;
      return variables;
    },
    /**
     * Refresca las recomendaciones de moda
     * para esto hay que borrar listados y reiniciar offset a 0
     */
    async restartReasons() {
      this.reasons = [];
      await this.seeFirst();
    }
  }
};
</script>

<style scoped>
.table-actions {
  list-style: none;
  padding-left: 0;
}
.table-actions li {
  display: inline-block;
  padding: 0px 7px;
}
.table-actions li a {
  color: #aab1b5;
  display: inline-block;
  background-color: white;
  width: 34px;
  height: 34px;
  text-align: center;
  border-radius: 50%;
  font-size: 14px;
  border: 2px solid #e0e2e4;
  padding: 5px 0;
  -webkit-transition: color 0.1s ease;
  -o-transition: color 0.1s ease;
  transition: color 0.1s ease;
  -webkit-transition: border-color 0.1s ease;
  -o-transition: border-color 0.1s ease;
  transition: border-color 0.1s ease;
}
.table-actions li a:hover {
  border-color: #aab1b5;
  color: black;
}
</style>
