<template>
  <b-card title="Notas">
    <order-notes-form :order="order"></order-notes-form>
  </b-card>
</template>

<script>
import OrderNotesForm from "../components/OrderNotesForm.vue";
export default {
  name: "OrderNotesCard",
  components: { OrderNotesForm },
  props: {
    order: Object
  }
};
</script>
