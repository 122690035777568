<template>
  <div>
    <b-button variant="outline-primary" v-b-toggle="variantImagesBlockId()">
      {{ visible ? "Ocultar" : "Ver" }} imágenes de variantes ({{
        product.variants.length
      }})
    </b-button>

    <b-collapse
      style="background: rgba(46, 150, 178, 0.15)!important;"
      class="pt-2"
      :id="variantImagesBlockId()"
      v-model="visible"
      @show="showCollapse"
    >
      <div v-for="(variant, position) of product.variants" :key="position">
        <product-image-manager-variant-view
          v-if="show"
          :variant="variant"
          :product-assets="productAssets"
          :product-id="product.id"
          :original-product-assets="originalProductAssets"
          class="my-4"
        />
      </div>
    </b-collapse>
  </div>
</template>

<script>
import ProductImageManagerVariantView from "./ProductImageManagerVariantView.vue";
export default {
  name: "ProductImageManagerVariants",
  components: { ProductImageManagerVariantView },
  props: {
    product: {
      type: Object,
      default() {
        return {};
      }
    },
    productAssets: {
      type: Array,
      default() {
        return [];
      }
    },
    originalProductAssets: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      show: false,
      visible: false
    };
  },
  mounted() {},
  computed: {},
  methods: {
    /**
     * Permite mostrar u ocultar las variantes
     */
    showCollapse() {
      this.show = true;
    },
    /**
     * Genera un texto que no contiene espacio, útil para ser usado como
     * identificador de tags HTML.
     * @returns {String}
     */
    variantImagesBlockId() {
      const skuNoSpace = this.product.sku.replace(/ +/g, "_");
      return `variants-images-${skuNoSpace}`;
    }
  },
  watch: {
    product() {
      this.visible = false;
    }
  }
};
</script>
<style scoped></style>
