<template>
  <div>
    <base-header
      title="Mantenedor de Vtex"
      title-link="/vtex_maintainer"
      :titleSize="12"
    >
    </base-header>
    <b-spinner v-if="loading" label="Spinning"></b-spinner>
    <b-alert v-else-if="error" show="" variant="danger">
      Ha ocurrido un error
    </b-alert>
    <b-alert v-else-if="!available" show="" variant="danger">
      La integración no está disponible
    </b-alert>
    <b-card v-else no-body>
      <b-tabs card @activate-tab="changeTab" v-model="selected">
        <b-tab title="Categorías" :key="0">
          <vtex-categories-maintainer-tab
            :vtexIntegrationConfigId="integrationConfigId"
            @setCategories="setCategories"
          >
          </vtex-categories-maintainer-tab>
        </b-tab>
        <b-tab title="Marcas" :key="1">
          <vtex-brands-maintainer-tab
            :vtexIntegrationConfigId="integrationConfigId"
          >
          </vtex-brands-maintainer-tab>
        </b-tab>
        <b-tab title="Especificaciones" :key="2">
          <vtex-specifications-maintainer-tab
            :vtexIntegrationConfigId="integrationConfigId"
            :categories="categories"
          >
          </vtex-specifications-maintainer-tab>
        </b-tab>
        <b-tab title="Promociones" :key="3">
          <vtex-promotions-maintainer-tab
            :vtexIntegrationConfigId="integrationConfigId"
            :promotionSaved="promotionSaved"
          >
          </vtex-promotions-maintainer-tab>
        </b-tab>
      </b-tabs>
    </b-card>
    <br />
  </div>
</template>

<script>
import BaseHeader from "@/components/BaseHeader.vue";
import VtexBrandsMaintainerTab from "@/components/VtexMaintainers/Brands/VtexBrandsMaintainerTab.vue";
import VtexSpecificationsMaintainerTab from "@/components/VtexMaintainers/Specifications/VtexSpecificationsMaintainerTab.vue";
import VtexCategoriesMaintainerTab from "@/components/VtexMaintainers/Categories/VtexCategoriesMaintainerTab";
import VtexPromotionsMaintainerTab from "@/components/VtexMaintainers/Promotions/VtexPromotionsMaintainerTab.vue";
import INTEGRATION_CONFIG_VTEX from "@/graphql/IntegrationConfigVtex.gql";

export default {
  name: "VtexMaintainer",
  props: {
    integrationConfigId: String,
    tabIndex: {
      type: Number,
      default: 0
    },
    promotionSaved: {
      type: Boolean,
      default: null
    }
  },
  components: {
    BaseHeader,
    VtexBrandsMaintainerTab,
    VtexSpecificationsMaintainerTab,
    VtexCategoriesMaintainerTab,
    VtexPromotionsMaintainerTab
  },
  data() {
    return {
      selected: this.tabIndex,
      loading: true,
      error: false,
      available: false,
      categories: []
    };
  },
  mounted: function() {
    this.getVtexIntegrationConfig();
  },
  methods: {
    /**
     * Cambia la pestaña seleccionada en la vista.
     * @param {Integer} newTabIndex
     */
    changeTab(newTabIndex) {
      this.selected = newTabIndex;
    },
    /**
     * Realiza la petición para obtener la configuración de la integración Vtex.
     */
    getVtexIntegrationConfig() {
      this.$apollo
        .query({
          query: INTEGRATION_CONFIG_VTEX,
          variables: {
            id: this.integrationConfigId
          }
        })
        .then(({ data }) => {
          this.available = this.availableVtexIntegrationConfig(data);
          this.loading = false;
        });
    },
    /**
     * Revisa si la integración de Vtex esta disponible.
     * @param {Object} data
     * @return {Boolean}
     */
    availableVtexIntegrationConfig(data) {
      if (!data || !data.integrationConfigVtex) {
        this.error = true;
        return false;
      }
      const available = data.integrationConfigVtex.available;
      this.error = false;
      return available;
    },
    /**
     * Guarda los datos de categorias entregados.
     * @param {Array<Object>} categories
     */
    setCategories(categories) {
      this.categories = categories;
    }
  }
};
</script>
