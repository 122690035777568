<template>
  <b-tr>
    <b-th>{{ formatedDate }}</b-th>

    <b-th>
      <span v-if="$dig(internalHistory.template, 'name')">
        {{ internalHistory.template.name }}
      </span>
      <span v-else>
        "Template eliminado"
      </span>
    </b-th>
    <b-th>
      <span v-if="$dig(internalHistory.template, 'name')">
        {{ internalHistory.responsible.name }}
      </span>
      <span v-else>
        "---"
      </span>
    </b-th>
    <b-th>
      {{ internalHistory.progress | toPercentage }}
    </b-th>
    <b-th>
      <b-button
        v-if="
          internalHistory.progress == 1 && internalHistory.error.length == 0
        "
        size="lg"
        variant="outlined"
        class="p-0 focus-btn"
        :href="internalHistory.originalUrl"
        title="Descargar archivo"
        v-b-tooltip.hover
      >
        <b-icon stacked icon="download" scale="0.60"></b-icon>
      </b-button>
    </b-th>
    <b-th>
      {{ internalHistory.error }}
    </b-th>
  </b-tr>
</template>
<script>
import { mapState } from "vuex";
import BULK_UPLOAD_TEMPLATE_DOWNLOAD_BY_ID from "../../../graphql/BulkUpload/TemplateDownload/BulkUploadTemplateDownloadById.gql";
export default {
  name: "DownloadsTableRow",
  props: {
    history: Object
  },
  mounted() {
    setTimeout(this.updateProgress, 2000);
  },
  beforeDestroy() {
    this.mounted = false;
  },
  data() {
    return {
      mounted: true,
      internalHistory: this.$dup(this.history)
    };
  },
  computed: {
    ...mapState(["currentUser"]),
    /**
     * Formatea la fecha de creacion de la planilla
     * @return {Date}
     */
    formatedDate() {
      const date = this.$timezoneDate(
        this.currentUser,
        this.internalHistory.createdAt
      );
      return this.$moment(date).format("DD/MM/YYYY HH:mm");
    },
    /**
     * Revisa si la planilla cumple con las condiciones para actualizar el progreso o no
     * @return {Boolean}
     */
    isUpdatableInternalHistory() {
      return (
        this.isPending &&
        new Date() - new Date(this.internalHistory.updatedAt) < 60 * 60 * 1000
      );
    },
    /**
     * Revisa si la planilla llego al 100% o no
     * @return {Boolean}
     */
    isPending() {
      return this.progress < 100;
    },
    /**
     * Indica el porcentaje de descarga
     * @return {Number}
     */
    progress() {
      return Math.round(this.internalHistory.progress * 100);
    }
  },
  methods: {
    /**
     * Se encarga de actualizar el progreso de genracion de las difertenes planillas, solo si se debe
     */
    async updateProgress() {
      if (this.isUpdatableInternalHistory) {
        await this.$apollo
          .query({
            query: BULK_UPLOAD_TEMPLATE_DOWNLOAD_BY_ID,
            variables: {
              id: this.history.id
            },
            fetchPolicy: "no-cache"
          })
          .then(({ data }) => {
            if (!data || !data.bulkUploadTemplateDownload) return;
            Object.assign(
              this.internalHistory,
              data.bulkUploadTemplateDownload
            );
          });
        if (this.mounted) setTimeout(this.updateProgress, 2000);
      }
    }
  }
};
</script>

<style scoped>
.focus-btn {
  color: #aab1b5;
  display: inline-block;
  background-color: white;
  width: 34px;
  height: 34px;
  text-align: center;
  border-radius: 50%;
  font-size: 14px;
  border: 2px solid #e0e2e4;
  padding: 5px 0;
}

.focus-btn:hover {
  border-color: #aab1b5;
  color: black;
}
</style>
