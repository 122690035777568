<template>
  <b-spinner v-if="loading || loadingAttributes" label="Spinning" />
  <div v-else>
    <h4 class="font-weight-bold m-3">Variantes</h4>
    <b-table-simple>
      <b-thead>
        <b-tr>
          <b-th colspan="3">Datos de Centry</b-th>
          <b-th :colspan="1 + attributes.length"
            >Datos en {{ integrationConfig.label }}</b-th
          >
        </b-tr>
        <b-tr>
          <b-th>Color</b-th>
          <b-th>Talla</b-th>
          <b-th>Sku Centry</b-th>
          <b-th>Sku</b-th>
          <b-th v-for="attribute of attributes" :key="attribute.id">{{
            attributeName(attribute)
          }}</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="(variant, idx) of product.variants" :key="idx">
          <b-td>{{ variant.color ? variant.color.name : "" }}</b-td>
          <b-td>{{ variant.size ? variant.size.name : "" }}</b-td>
          <b-td>{{ variant.sku }}</b-td>
          <b-td>
            <b-form-input
              v-model="values[idx].sku"
              @input="emitChange"
            ></b-form-input>
          </b-td>
          <b-td v-for="attribute of attributes" :key="attribute.id">
            <v-select
              v-if="attribute.attributeOptions.length"
              v-model="values[idx][attribute.id]"
              :options="attributeOptions(attribute)"
              :reduce="elem => elem.value"
              @input="emitChange"
            />
            <b-form-input
              v-else
              v-model="values[idx][attribute.id]"
              :id="attribute.id + integrationConfig.fullLabel"
              @input="emitChange"
            ></b-form-input>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <hr />
  </div>
</template>

<script>
import ALL_VARIANT_ATTRIBUTES from "../../../graphql/Paris/AllVariantAttributes.gql";
export default {
  name: "ProductEditTabsParisVariants",
  props: {
    familyId: String,
    _values: Array,
    integrationConfig: Object,
    allAttributes: Object,
    loadingAttributes: Boolean,
    product: Object
  },
  model: {
    prop: "_values",
    event: "change"
  },
  data() {
    return {
      values: this._values.map(elem => {
        return { ...elem };
      }),
      attributes: [],
      loading: true
    };
  },
  mounted() {
    this.getAttributes();
  },
  methods: {
    /**
     * Obtiene los atributos de producto a partir de una query, y los
     * almacena en la variable attributes
     */
    getAttributes() {
      this.loading = true;
      if (!this.familyId) {
        this.attributes = [];
        this.loading = false;
        return;
      }
      this.$apollo
        .query({
          query: ALL_VARIANT_ATTRIBUTES,
          variables: {
            integrationConfigId: this.integrationConfig.id,
            familyId: this.familyId
          }
        })
        .then(({ data }) => {
          this.attributes = this.$dig(data, "allParisVariantAttributes");
          this.loading = false;
        });
    },
    /**
     * Entrega el listado de opciones del atributo, formateado
     * para v-select
     * @param {Object} attribute
     * @return {Array}
     */
    attributeOptions(attribute) {
      return attribute.attributeOptions.map(option => {
        return {
          value: option.id,
          label: option.name
        };
      });
    },
    /**
     * Emite evento "change"
     */
    emitChange() {
      this.$emit("change", this.values);
    },
    /**
     * Entrega el nombre del atributo, y le agrega el '*' si es obligatorio
     * @param {Object}
     * @return {String}
     */
    attributeName(attribute) {
      let name = attribute.name;
      if (this.allAttributes[attribute.id].attributeValidation.isRequired) {
        name += " *";
      }
      return name;
    }
  },
  watch: {
    familyId() {
      this.getAttributes();
    }
  }
};
</script>
