<template>
  <div>
    <ApolloQuery
      :query="require('@/graphql/Messages/AllMessagesScheduled.gql')"
      :variables="queryVariables('')"
      fetchPolicy="cache-and-network"
    >
      <template slot-scope="{ result: { error, data }, query, isLoading }">
        <b-spinner v-if="isLoading" label="Spinning" class="m-2 float-right" />
        <!-- Error -->
        <b-alert v-else-if="error" show="" variant="danger">
          Ha ocurrido un error
        </b-alert>
        <!-- Result -->
        <div v-else-if="data && data.allMessagesScheduled">
          <list-item
            v-for="messageScheduled of data.allMessagesScheduled.edges"
            :key="messageScheduled.node.id"
            :messageScheduled="messageScheduled.node"
            class="mb-3"
            @deleted="() => query.refetch()"
          />
          <b-button
            v-if="hasNextPage(data)"
            class="m-2 float-right"
            @click="
              seeMore(query, data.allMessagesScheduled.pageInfo.endCursor)
            "
          >
            Ver más
          </b-button>
          <b-alert v-else show variant="light" class="text-center">
            No hay más datos para mostrar.
          </b-alert>
        </div>
      </template>
    </ApolloQuery>
  </div>
</template>
<script>
import ListItem from "./ListItem";

export default {
  name: "MessagesScheduledList",
  components: {
    ListItem
  },
  methods: {
    /**
     * Entrega las variables para la query que permite obtener los mensajes
     * programados.
     *
     * @param {String} cursor
     */
    queryVariables(cursor) {
      const variables = {};
      if (cursor) {
        variables.cursor = cursor;
      }
      return variables;
    },
    /**
     * Indica si hay una página siguiente en la query de GraphQl basado
     * exclusivamente en la existencia de un cursor.
     *
     * @return {boolean}
     */
    hasNextPage(data) {
      return data?.allMessagesScheduled?.pageInfo?.endCursor != null;
    },
    /**
     * Trae más resultados de una query avanzando en su cursor.
     *
     * @param {Object} query
     * @param {String} cursor
     */
    async seeMore(query, cursor) {
      await query.fetchMore({
        variables: this.queryVariables(cursor),
        updateQuery: (prev, { fetchMoreResult }) => {
          const updated = Object.assign({}, this.$dup(prev));
          updated.allMessagesScheduled.pageInfo =
            fetchMoreResult.allMessagesScheduled.pageInfo;
          if (fetchMoreResult?.allMessagesScheduled?.edges?.length !== 0) {
            updated.allMessagesScheduled.edges.push(
              ...fetchMoreResult.allMessagesScheduled.edges
            );
          }
          return updated;
        }
      });
    }
  }
};
</script>
