var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ApolloQuery',{attrs:{"query":require('../graphql/AdminNotSynchronizedError.gql'),"variables":{
      cursor: '',
      createdAtLte: this.filters.createdAtLte,
      createdAtGte: this.filters.createdAtGte,
      publicApplicationInformationIds: this.filters
        .publicApplicationInformationIds,
      companyIds: this.filters.companyIds,
      origins: this.filters.origins
    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var ref_result = ref.result;
    var error = ref_result.error;
    var data = ref_result.data;
    var query = ref.query;
    var isLoading = ref.isLoading;
return [(data && data.adminNotSynchronizedErrors)?_c('b-table-simple',{attrs:{"hover":""}},[_c('b-thead',[_c('b-tr',[_c('b-th',[_vm._v("Fecha")]),_c('b-th',[_vm._v("Empresa")]),_c('b-th',[_vm._v("Integración")]),_c('b-th',[_vm._v("Tipo de integración")]),_c('b-th',[_vm._v("SKU Producto")]),_c('b-th',[_vm._v("Origen")]),_c('b-th',[_vm._v("Fecha inicio sincronización en curso")]),_c('b-th',[_vm._v("Progreso sincronización en curso")])],1)],1),_c('b-tbody',_vm._l((data.adminNotSynchronizedErrors.edges),function(edge){return _c('b-tr',{key:edge.node.id},[_c('b-td',[_vm._v(_vm._s(edge.node.createdAt))]),_c('b-td',[_vm._v(_vm._s(_vm.$dig(edge, "node", "company", "name")))]),_c('b-td',[_vm._v(_vm._s(_vm.$dig(edge, "node", "integrationConfig", "label")))]),_c('b-td',[_vm._v(_vm._s(_vm.$dig(edge, "node", "publicApplicationInformation", "name")))]),_c('b-td',[_vm._v(_vm._s(_vm.$dig(edge, "node", "product", "sku")))]),_c('b-td',[_vm._v(_vm._s(edge.node.origin))]),_c('b-td',[_vm._v(_vm._s(edge.node.asyncTaskLogStartDate))]),_c('b-td',[_vm._v(_vm._s(edge.node.asyncTaskLogProgress))])],1)}),1)],1):_vm._e(),(error)?_c('b-alert',{attrs:{"show":"","variant":"danger"}},[_vm._v(" Ha ocurrido un error ")]):_vm._e(),(isLoading)?_c('b-spinner',{staticClass:"m-2 float-right",attrs:{"label":"Spinning"}}):_c('div',[(_vm.hasNextPage(data))?_c('b-button',{staticClass:"m-2 float-right",on:{"click":function($event){return _vm.seeMore(query, data.adminNotSynchronizedErrors.pageInfo.endCursor)}}},[_vm._v(" Ver más ")]):_c('b-alert',{staticClass:"text-center",attrs:{"show":"","variant":"light"}},[_vm._v(" No hay más datos para mostrar. ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }