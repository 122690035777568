<template>
  <b-col cols="12" sm="6" md="7" lg="5" xl="8">
    <b-row align-v="center">
      <b-col cols="5">
        <b-form-group label="Filtrar por integración" label-for="pii">
          <v-select
            id="pii"
            v-model="piiSelected"
            :options="pii"
            :multiple="true"
            @input="$emit('update:piiSelected', piiSelected)"
          >
          </v-select>
        </b-form-group>
      </b-col>
      <b-col cols="7">
        <b-form-group class="d-inline m-0 p-0 float-right w-100">
          <b-form-tags
            class="filter-text w-100"
            size="md"
            v-model="keyword"
            separator=","
            remove-on-delete=""
            placeholder="Nombre Atributo"
            :tag-validator="tagValidator"
            invalid-tag-text="Tag inválido:"
            duplicate-tag-text="Tag duplicado:"
            add-button-text="Agregar"
          ></b-form-tags>
          <template #description v-if="keyword.length == 0">
            <div class="float-right m-0 p-0">
              Ingresa tags de busqueda separados por tecla enter o comas (,)
            </div>
          </template>
        </b-form-group>
        <b-button
          class="float-right"
          size="sm"
          v-if="keyword.length > 0"
          @click="cleanTags"
        >
          Limpiar
        </b-button>
      </b-col>
    </b-row>
  </b-col>
</template>
<script>
export default {
  name: "SearchCategoryAttrributeInput",
  model: {
    prop: "_value",
    event: "change"
  },
  props: {
    _value: {
      type: Array,
      default() {
        return [];
      }
    },
    pii: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      keyword: this._value,
      piiSelected: []
    };
  },
  computed: {},
  methods: {
    /**
     * Validación completa de tag ingresado
     * @param {String} tag
     * @returns {Boolean}
     */
    tagValidator(tag) {
      return !this.containsSpecialChars(tag);
    },
    /**
     * Validación que verifica si tag incluye caracteres especiales
     * y por lo tanto no puede ser ingresado
     * @param {String} tag
     * @returns {Boolean}
     */
    containsSpecialChars(tag) {
      const specialChars = /[`!@#$%^&*()_+\-=[\]{};'´:"\\|,.<>/?~]/;
      return specialChars.test(tag);
    },
    /**
     * Elimina acentos de tag ingresado
     * @param {String} tag
     * @returns {String}
     */
    removeAccentDiacritics(tag) {
      return tag.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    },
    /**
     * Limpia conjunto de tags
     */
    cleanTags() {
      this.keyword = [];
      this.$emit("change", []);
    }
  },
  watch: {
    keyword(newValue) {
      if (newValue.length === 0) {
        this.$emit("change", []);
        return;
      }
      const last_tag = newValue[newValue.length - 1];
      this.keyword[newValue.length - 1] = this.removeAccentDiacritics(last_tag);
      this.$emit("change", this.keyword);
    }
  }
};
</script>
