<template>
  <div>
    <base-header
      title="Sugerencia para catálogo de Mercado Libre"
      :title-link="link"
      :map="[
        !integrationConfigLabel ? 'loading' : integrationConfigLabel,
        'Sugerencias de catálogo',
        $route.meta.action
      ]"
      :title-size="12"
    />
    <div>
      <b-container fluid="">
        <router-view :integration-config-id="integrationConfigId" />
      </b-container>
    </div>
  </div>
</template>

<script>
import BaseHeader from "../../../components/BaseHeader.vue";
import INTEGRATION_CONFIG_LABEL from "../../../graphql/Base/IntegrationConfigLabel.gql";

export default {
  name: "MercadoLibreCatalogSuggestionsList",
  components: {
    BaseHeader
  },
  props: {
    integrationConfigId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      integrationConfigLabel: null
    };
  },
  computed: {
    /**
     * Link para título
     * @returns {String}
     */
    link() {
      return `/mercadolibre/catalog_suggestions/${this.integrationConfigId}/list`;
    }
  },
  mounted() {
    this.getIntegrationConfigLabel();
  },
  methods: {
    /**
     * Obtiene la etiqueta del integration_config
     * y lo guarda en variable interna
     */
    getIntegrationConfigLabel() {
      this.$apollo
        .query({
          query: INTEGRATION_CONFIG_LABEL,
          variables: {
            id: this.integrationConfigId
          }
        })
        .then(({ data }) => {
          const label = this.$dig(data, "integrationConfig", "label");
          if (label) {
            this.integrationConfigLabel = label;
          } else {
            this.integrationConfigLabel = "MercadoLibre";
          }
        })
        .catch(() => (this.integrationConfigLabel = "MercadoLibre"));
    }
  }
};
</script>
