<template>
  <div>
    <b-button-group size="sm" class="mb-2">
      <b-button variant="outline-info" @click="cancel">
        <b-icon-x-circle></b-icon-x-circle> Cancelar
      </b-button>
      <b-button
        variant="info"
        :disabled="disabledSaveButton"
        :title="titleSaveButton"
        v-b-tooltip.hover=""
        @click="save"
      >
        <b-skeleton v-if="saving || loading" type="button"></b-skeleton>
        <span v-else><b-icon-cloud-upload></b-icon-cloud-upload>Guardar</span>
      </b-button>
    </b-button-group>
    <b-alert v-model="saved" :variant="variantAlert" dismissible>
      {{ alertMessage }}
    </b-alert>
    <b-alert
      v-model="validate"
      variant="danger"
      v-if="
        !saving &&
          disabledSaveButton &&
          (!this.validCategoryAttributes.required ||
            !this.validCategoryAttributes.validValues)
      "
    >
      Tus datos estan guardados, pero:
      <ul>
        <li v-if="!validCategoryAttributes.required">
          Existen atributos obligatorios que no han sido completados.
        </li>
        <li v-if="!validCategoryAttributes.validValues">
          Existen atributos con valores inválidos.
        </li>
      </ul>
    </b-alert>
    <b-row v-if="loading">
      <b-col> <b-skeleton type="input"></b-skeleton> </b-col>
      <b-col> <b-skeleton type="input"></b-skeleton> </b-col>
      <b-col> <b-skeleton type="input"></b-skeleton> </b-col>
    </b-row>
    <product-edit-tabs-centry-category-attributes
      v-else
      v-model="values"
      :category-attributes="categoryAttributes"
      :ic-ids="icIds"
      :category-id="categoryId"
      :piiByCategoryAttributes="publicIntegrationInformations"
      @change="change"
      @valid="updateCategoryAttributeValidation"
    >
    </product-edit-tabs-centry-category-attributes>
  </div>
</template>
<script>
import { mapState } from "vuex";
import ProductEditTabsCentryCategoryAttributes from "./CategoryAttributes/Form.vue";
import ALL_CATEGORY_ATTRIBUTES from "../../graphql/AllCategoryAttributes/productsView.gql";

import { updateProduct } from "../../main";
export default {
  name: "ProductEditTabsCategoryAttributes",
  components: {
    ProductEditTabsCentryCategoryAttributes
  },
  model: {
    prop: "_changed",
    event: "change"
  },
  props: {
    _changed: {
      type: Boolean,
      default: false
    },
    product: Object,
    categoryId: String,
    icIds: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      changed: this._changed,
      values: {},
      oldValues: {},
      categoryAttributes: {},
      saving: false,
      validate: false,
      saved: false,
      errorSaving: null,
      formErrors: null,
      validCategoryAttributes: { required: true, validValues: true },
      loading: true,
      showAlert: false,
      publicIntegrationInformations: []
    };
  },
  mounted() {
    this.updateCategoryAttributes();
  },
  computed: {
    ...mapState(["currentUser"]),
    variantAlert() {
      return this.errorSaving ? "danger" : "success";
    },
    alertMessage() {
      if (this.errorSaving) {
        return this.errorSaving;
      }
      return "El producto ha sido actualizado correctamente";
    },
    disabledSaveButton() {
      return !this.changed || this.saving || this.loading;
    },
    titleSaveButton() {
      if (!this.changed) {
        return "No hay cambios en el formulario";
      }
      return "";
    }
  },
  methods: {
    /**
     * Recalcula el campo changed y
     * emite su resultado
     */
    change() {
      this.changed = this.calculateChanged();
      this.$emit("change", this.changed);
    },
    /**
     * Indica si ha habido o no algún
     * cambio en los valores de atributos de categoría
     * @returns {Boolean}
     */
    calculateChanged() {
      return !this.$objDeepCompare(this.oldValues, this.values);
    },
    /**
     * Valor actual que tiene el producto en un atributo de categoría
     * @param {Object}
     * @returns {Number | Array | String}
     */
    initialCategoryAttributeValue(categoryAttribute) {
      const currentValue = this.product.categoryAttributeValues.find(
        x => x.categoryAttribute?.id === categoryAttribute?.id
      );
      if (!currentValue) return null;
      if (categoryAttribute?.valueType === "select_many") {
        return currentValue.valueSelectedIds;
      }
      if (categoryAttribute?.valueType === "select_one") {
        return currentValue.valueSelectedIds.length
          ? currentValue.valueSelectedIds[0]
          : null;
      }
      return currentValue.valueFilled;
    },
    /**
     * Actualiza el campo de atributos válidos
     * @param {Boolean} valid
     */
    updateCategoryAttributeValidation(valid) {
      this.validCategoryAttributes = valid;
    },
    /**
     * Actualiza el listado de los atributos de categoría, según
     * la categoría del producto
     */
    async updateCategoryAttributes() {
      if (this.categoryId === null) return;
      this.loading = true;
      this.$getAllPages(
        ALL_CATEGORY_ATTRIBUTES,
        { categoryId: this.categoryId, companyId: this.currentUser.company.id },
        "allCategoryAttributes"
      ).then(async array => {
        this.updateCategoryAttributesFromArray(array);
        this.getPiiByCategoryAttributes(array);
        this.loading = false;
      });
    },
    /**
     * Actualiza los valores de los atributos basados
     * en el arreglo de atributos,
     * los guarda en las variables del componente
     * @param {Array<Object>}
     */
    updateCategoryAttributesFromArray(array) {
      const values = {};
      array.forEach(x => {
        values[x.node.id] = this.initialCategoryAttributeValue(x.node);
      });
      this.values = values;
      this.oldValues = { ...values };
      const categoryAttributesMap = {};
      array.forEach(ca => {
        let colors = [];
        let piis = [];
        ca.node.purposes?.forEach(purpose => {
          let pii = purpose.publicIntegrationInformation;
          let color = { name: pii.name, color: pii.color };
          colors.push(color);
          piis.push(pii.name);
        });
        ca.node.colors = [...colors];
        ca.node.piis = [...piis];
        delete ca.node.purposes;
        categoryAttributesMap[ca.node.id] = ca.node;
      });
      this.categoryAttributes = categoryAttributesMap;
    },
    /**
     * Cancela los cambios y redirige al listado
     * de productos
     */
    cancel() {
      if (this.changed) {
        this.$swal
          .fire({
            title: "Cancelar",
            text: "Si cancelas, perderás tus cambios. ¿Estás seguro?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "No"
          })
          .then(result => {
            if (result.value) {
              this.$router.push({ name: "Products" });
            }
          });
      } else {
        this.$router.push({ name: "Products" });
      }
    },
    /**
     * Actualiza los atributos guardados en
     * el producto
     * @param {Object} product
     */
    updateProductCache(product) {
      this.product.categoryAttributeValues = product.categoryAttributeValues;
      this.updateCategoryAttributes();
    },
    /**
     * Entrega los datos que cambiaron en los valores de atributos
     * de categoría
     * @returns {Object}
     */
    updatedProductData() {
      if (!this.changed) return null;
      const updated = {};
      updated.categoryAttributeValues = Object.keys(this.values).map(cAttrId =>
        this.valueToSend(cAttrId)
      );
      return updated;
    },
    /**
     * Entrega el valor de un atributo de categoría,
     * recibe el id del atributo
     * @param {String} cAttrId
     * @returns {String | Number | Array}
     */
    valueToSend(cAttrId) {
      const cav = {
        categoryAttributeId: cAttrId
      };
      if (
        this.categoryAttributes[cAttrId].valueType === "select_many" ||
        this.categoryAttributes[cAttrId].valueType === "select_one"
      ) {
        cav.valueSelectedIds = this.values[cAttrId];
      } else {
        cav.valueFilled = this.values[cAttrId];
      }
      return cav;
    },
    /**
     * Actualiza el producto
     * con los datos del formulario
     * @returns {Promise}
     */
    async updateProduct() {
      const updateProductData = this.updatedProductData();
      if (!updateProductData) {
        this.errorSaving = null;
        return;
      }
      return updateProduct(this.$apollo, this.product.id, updateProductData)
        .then(async ({ data }) => {
          const newProduct = this.$dig(data, "updateProduct", "product");
          if (newProduct) {
            this.errorSaving = null;
            this.updateProductCache(newProduct);
          }
        })
        .catch(err => {
          this.errorSaving = `Ha ocurrido un error al guardar: ${err.message}`;
        });
    },
    /**
     * Maneja el evento Guardar del botón.
     */
    async save() {
      if (
        !this.validCategoryAttributes.required ||
        !this.validCategoryAttributes.validValues
      ) {
        this.validate = true;
      }
      this.saving = true;
      this.saved = false;
      this.updateProduct().then(() => {
        this.saving = false;
        this.saved = true;
        this.showAlert = true;
        if (!this.errorSaving) {
          this.changed = false;
          this.$emit("change", false);
        }
      });
    },
    /**
     * Obtiene todas las integraciones de los atributos de categoria
     * @param {Array<Object>}
     */
    getPiiByCategoryAttributes(categoryAttributes) {
      let pii = new Set();
      categoryAttributes.forEach(ca => {
        ca.node.colors?.forEach(c => {
          pii.add(c.name);
        });
      });
      this.publicIntegrationInformations = Array.from(pii);
    }
  },
  watch: {
    categoryId() {
      this.updateCategoryAttributes();
    }
  }
};
</script>
