<template>
  <div>
    <base-alert-with-count-down
      v-model="showAlert"
      :alert-variant="alertVariant"
      :dismiss-secs="error ? 10 : 5"
    >
      {{ alertMessage }}</base-alert-with-count-down
    >
    <b-row>
      <b-col xl="10" cols="12">
        <b-row>
          <b-col md="4" sm="6" cols="12">
            <input-with-skeleton
              :loading="isLoadingBanner"
              label="Nombre del Evento"
              :invalid-feedback="validation.eventName.invalidFeedback"
              :state="validation.eventName.valid"
              v-model="eventName"
            />
          </b-col>
          <b-col md="4" sm="6" cols="12">
            Fecha de Inicio Evento:
            <b-form-datepicker v-model="eventDateAt" />
          </b-col>
          <b-col md="4" sm="6" cols="12">
            Fecha de Termino Evento:
            <b-form-datepicker v-model="eventDateTo" />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4" sm="6" cols="12">
            <input-with-skeleton
              :loading="isLoadingBanner"
              label="Mensaje (Opcional)"
              v-model="eventMessage"
            />
          </b-col>
          <b-col md="4" sm="6" cols="12">
            Estado:
            {{ status ? "Activado" : "Desactivado" }}
            <b-form-checkbox class="flex" v-model="status" switch />
          </b-col>
          <b-col md="4" sm="6" cols="12">
            <input-with-skeleton
              :loading="isLoadingBanner"
              label="URL de redirección"
              v-model="redirectUrl"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6" sm="6" cols="12">
            Color de la izquierda:
            <b-input type="color" v-model="colorPickLeft" />
          </b-col>
          <b-col md="6" sm="6" cols="12">
            Color de la derecha:
            <b-input type="color" v-model="colorPickRight" />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6" sm="6" cols="12">
            Selecciona Imagen
            <b-form-file
              accept="image/jpg, image/jpeg, image/png, image/gif"
              class="w-auto flex-grow-1"
              ref="pickImage"
              v-show="true"
              v-model="image"
              :placeholder="'Cargar una nueva imagen'"
            ></b-form-file>
          </b-col>
          <b-col md="6" sm="6" cols="12">
            <b-img
              v-if="hasImage"
              :src="imageSrc || imageUrl"
              fluid
              block
              rounded
            />
          </b-col>
        </b-row>
      </b-col>
      <b-col xl="2" cols="12">
        <b-button
          variant="info"
          :disabled="!changed || saving"
          :title="changed ? '' : 'No hay cambios'"
          @click="save"
          class="mb-3 w-100"
        >
          <span v-if="saving">
            Guardando
            <b-spinner label="Spinning" />
          </span>
          <span v-else>Guardar</span>
        </b-button>
        <b-button
          variant="outline-info"
          class="w-100 px-0"
          :disabled="!changed || saving"
          @click="resetData()"
        >
          <span>Restablecer</span>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import InputWithSkeleton from "../components/Base/InputWithSkeleton.vue";
import BaseAlertWithCountDown from "../components/Base/BaseAlertWithCountDown.vue";
import CREATE_BANNER from "../graphql/Banners/CreateBanner.gql";
import UPDATE_BANNER from "../graphql/Banners/UpdateBanner.gql";

const base64Encode = data =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
export default {
  name: "BannerEdit",
  props: {
    banner: {
      type: Object,
      default() {
        return {};
      }
    },
    isLoadingBanner: Boolean
  },
  components: {
    InputWithSkeleton,
    BaseAlertWithCountDown
  },
  data() {
    return {
      eventName: this.banner?.eventName !== null ? this.banner.eventName : "",
      eventDateAt:
        this.banner?.eventDateAt !== null ? this.banner.eventDateAt : null,
      eventDateTo:
        this.banner?.eventDateTo !== null ? this.banner.eventDateTo : null,
      eventMessage:
        this.banner?.eventMessage !== null ? this.banner.eventMessage : null,
      status: this.banner?.status || false,
      redirectUrl:
        this.banner?.redirectUrl !== null ? this.banner.redirectUrl : null,
      selectedFile: null,
      image: null,
      imageSrc: null,
      imageUrl: this.banner?.imageUrl !== null ? this.banner.imageUrl : null,
      colorPickLeft:
        this.banner?.colorPickLeft !== null
          ? this.banner.colorPickLeft
          : "#Ffffff",
      colorPickRight:
        this.banner?.colorPickRight !== null
          ? this.banner.colorPickRight
          : "#Ffffff",
      saving: false,
      error: false,
      errorMessage: null,
      showAlert: false
    };
  },
  mounted() {
    if (!this.isConsolidated()) {
      this.eventName = null;
      this.eventDateAt = null;
      this.eventDateTo = null;
      this.status = false;
      this.eventMessage = null;
      this.redirectUrl = null;
      this.colorPickLeft = null;
      this.colorPickRight = null;
      this.imageUrl = null;
    }
  },
  computed: {
    validation() {
      return {
        eventName: {
          valid: this.eventName?.length > 0,
          invalidFeedback: "El nombre del evento es obligatorio"
        },
        eventDateAt: {
          valid: this.eventDateAt?.length > 0,
          invalidFeedback: "La fecha de inicio del evento es obligatoria"
        },
        eventDateTo: {
          valid: this.eventDateTo?.length > 0,
          invalidFeedback: "La fecha de término del evento es obligatoria"
        },
        image: {
          valid: this.imageUrl?.length > 0 || this.imageSrc?.length > 0,
          invalidFeedback: "El banner debe incluir una imagen"
        }
      };
    },
    valid() {
      let valid = true;
      Object.keys(this.validation).forEach(x => {
        valid = valid && this.validation[x].valid;
      });
      return valid;
    },
    changed() {
      return (
        (this.banner?.eventName || "") != this.eventName ||
        (this.banner?.eventDateAt || "") != this.eventDateAt ||
        (this.banner?.eventDateTo || "") != this.eventDateTo ||
        (this.banner?.eventMessage || "") != this.eventMessage ||
        (this.banner?.redirectUrl || "") != this.redirectUrl ||
        (this.banner?.colorPickLeft || "#Ffffff") != this.colorPickLeft ||
        (this.banner?.colorPickRight || "#Ffffff") != this.colorPickRight ||
        this.imageSrc != null ||
        this.banner?.status != this.status
      );
    },
    alertVariant() {
      return this.error ? "danger" : "success";
    },
    alertMessage() {
      return this.error
        ? "Ha ocurrido un error guardando los datos: " + this.errorMessage
        : "Banner guardado exitosamente";
    },
    hasImage() {
      return !!this.image || this.imageUrl != null;
    }
  },
  methods: {
    /**
     * Indica si es creacion o no
     * @return {Boolean}
     */
    isConsolidated() {
      if (this.$route.name == "BannerNew") {
        return false;
      } else {
        return true;
      }
    },
    async save() {
      this.error = false;
      this.errorMessage = null;
      if (!this.valid) {
        this.error = true;
        this.errorMessage = "Hay campos que se deben rellenar";
        this.showAlert = true;
      } else {
        this.saving = true;
        let action = "updated";
        if (this.isConsolidated()) {
          await this.updateBanner();
        } else {
          await this.createBanner();
          action = "created";
        }
        if (!this.error) {
          this.$router.push({
            name: "Banners",
            params: {
              action: action
            }
          });
        }
      }
    },
    /**
     * Envia la mutacion para actualizar un banner
     */
    async updateBanner() {
      await this.$apollo
        .mutate({
          mutation: UPDATE_BANNER,
          variables: {
            banner: this.getVariables()
          }
        })
        .then(({ data }) => {
          if (!data.updateBanner.result) {
            this.error = true;
            this.errorMessage = data.updateBanner.error;
            this.showAlert = true;
          } else {
            this.banner.eventName = this.eventName;
            this.banner.eventDateAt = this.eventDateAt;
            this.banner.eventDateTo = this.eventDateTo;
            this.banner.status = this.status;
            this.banner.eventMessage = this.eventMessage;
            this.banner.redirectUrl = this.redirectUrl;
            this.banner.colorPickLeft = this.colorPickLeft;
            this.banner.colorPickRight = this.colorPickRight;
            this.banner.image = this.imageSrc;
          }
          this.saving = false;
        })
        .catch(e => {
          this.error = true;
          this.errorMessage = e.message;
          this.showAlert = true;
          this.saving = false;
        });
    },
    /**
     * Envia la mutacion para crear un banner
     */
    async createBanner() {
      await this.$apollo
        .mutate({
          mutation: CREATE_BANNER,
          variables: {
            banner: this.getVariables()
          }
        })
        .then(({ data }) => {
          if (!data.createBanner.result) {
            this.error = true;
            this.errorMessage = data.createBanner.error;
          }
          this.showAlert = true;
          this.saving = false;
        })
        .catch(e => {
          this.error = true;
          this.errorMessage = e.message;
          this.showAlert = true;
          this.saving = false;
        });
    },
    /**
     * Se encarga de resetear los valores del formulario
     */
    resetData() {
      this.eventName = this.banner.eventName || "";
      this.eventDateAt = this.banner.eventDateAt || "";
      this.eventDateTo = this.banner.eventDateTo || "";
      this.status = this.banner.status || null;
      this.eventMessage = this.banner.eventMessage || "";
      this.redirectUrl = this.banner.redirectUrl || "";
      this.colorPickLeft = this.banner.colorPickLeft || "#Ffffff";
      this.colorPickRight = this.banner.colorPickRight || "#Ffffff";
      this.imageSrc = null;
    },
    getVariables() {
      let toUpdate = {};
      if (this.isConsolidated()) {
        toUpdate["id"] = this.banner.id;
      }
      toUpdate["eventName"] = this.eventName;
      toUpdate["eventDateAt"] = this.eventDateAt;
      toUpdate["eventDateTo"] = this.eventDateTo;
      toUpdate["status"] = this.status;
      if (this.banner?.eventMessage != this.eventMessage) {
        toUpdate["eventMessage"] = this.eventMessage;
      }
      if (this.banner?.redirectUrl != this.redirectUrl) {
        toUpdate["redirectUrl"] = this.redirectUrl;
      }
      if (this.imageSrc != null && this.image?.name != null) {
        toUpdate["image"] = this.imageSrc;
        toUpdate["imageFilename"] = this.image.name;
      }
      if (this.banner?.colorPickLeft != this.colorPickLeft) {
        toUpdate["colorPickLeft"] = this.colorPickLeft;
      }
      if (this.banner?.colorPickRight != this.colorPickRight) {
        toUpdate["colorPickRight"] = this.colorPickRight;
      }
      return toUpdate;
    }
  },
  watch: {
    banner: {
      handler(val) {
        if (!val) {
          val = {};
        }
        this.eventName = val.eventName || null;
        this.eventDateAt = val.eventDateAt || null;
        this.eventDateTo = val.eventDateTo || null;
        this.status = val.status || false;
        this.eventMessage = val.eventMessage || null;
        this.redirectUrl = val.redirectUrl || null;
        this.imageUrl = val.imageUrl || null;
        this.imageSrc = val.imageUrl || null;
        this.colorPickLeft = val.colorPickLeft || "#Ffffff";
        this.colorPickRight = val.colorPickRight || "#Ffffff";
      },
      deep: true
    },
    image(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then(value => {
              this.imageSrc = value;
            })
            .catch(() => {
              this.imageSrc = null;
            });
        } else {
          this.imageSrc = null;
        }
      }
    }
  }
};
</script>

<style></style>
