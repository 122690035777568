<template>
  <base-table-row-with-details
    :fields="fields"
    :sub_fields="sub_fields"
    :query="query"
    :queryVariables="queryVariables"
    :offset="offset"
    :baseOffset="0"
    :limit="limit"
    @setOffset="setOffset"
    :extractData="extractData"
    :extractDataRow="extractDataRow"
    errorMessage="Hubo un error al tratar de obtener el historial de modificaciones."
    titleButtonRestart="Recargar Historial de Modificaciones"
  >
    <template scope="{ row }">
      <b-table
        :fields="sub_fields"
        :items="row.item.changes"
        style="border: none; padding: 0 !important; margin: 0; height: 0; background-color: #ffffff"
      >
        <template #cell(label)="data">
          <span>{{ data.value }} </span>
        </template>
        <template #cell()="data">
          <span v-html="data.value"></span>
        </template>
      </b-table>
    </template>
  </base-table-row-with-details>
</template>

<script>
import GET_MODIFICATION_HISTORIAL from "../../graphql/IntegrationConfig/ModificationHistorial.gql";
import BaseTableRowWithDetails from "../Base/BaseTableRowWithDetails.vue";

export default {
  name: "ModificationHistory",
  components: {
    BaseTableRowWithDetails
  },
  props: {
    ic: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      fields: [
        { key: "version", label: "versión", sortable: true },
        { key: "createdAt", label: "fecha", sortable: true },
        { key: "createdById", label: "responsable" },
        { key: "action", label: "acción" },
        { key: "show_details", label: "" }
      ],
      sub_fields: [
        { key: "label", label: "Campo", sortable: true },
        { key: "old_value", label: "Valor Antiguo" },
        { key: "new_value", label: "Valor Nuevo" }
      ],
      offset: 0,
      limit: 10,
      query: GET_MODIFICATION_HISTORIAL
    };
  },
  methods: {
    /**
     * Costruye objeto con variables para consulta de historial de modificaciones
     * El objeto debe incluir la id de la integration config, offset y limit
     */
    queryVariables() {
      const variables = {};
      variables.ic_id = this.ic.id;
      variables.offset = this.offset;
      variables.limit = this.limit;
      return variables;
    },
    /**
     * Modifica el valor actual del offset
     * @param {Integer} newValue
     */
    setOffset(newValue) {
      this.offset = newValue;
    },
    /**
     * Extracción de datos segun query
     */
    extractData(data) {
      return this.$dig(data, "integrationConfigModificationHistorial");
    },
    /**
     * Transforma un record para que sea legible en la tabla
     * @param {Object} row
     * @returns {Object}
     */
    extractDataRow(row) {
      return row;
    }
  }
};
</script>
