<template>
  <b-spinner v-if="loading || loadingDomains"></b-spinner>
  <span
    v-else-if="
      !technicalSpecs ||
        !gridTemplateRequiredAttributes ||
        gridTemplateRequiredAttributes.length === 0
    "
  >
    El dominio no permite guías de tallas. Elige otro
  </span>
  <span v-else-if="!isActive">
    El dominio no esta habilitado para crear guia de tallas.
  </span>
  <div v-else>
    <b-row>
      <b-col>
        <span class="font-weight-bold"> Dominio: {{ domain.domainName }} </span>
      </b-col>
    </b-row>
    <b-row v-if="!requiredComplete" class="mt-2 mb-2">
      <b-col>
        <span>
          Complete todos los atributos
        </span>
      </b-col>
    </b-row>
    <b-row
      v-for="attribute of gridTemplateRequiredAttributes"
      :key="attribute.id"
    >
      <b-col>
        <b-form-group
          :label="'* ' + attribute.name"
          :label-for="'a' + attribute.id"
        >
          <v-select
            :id="'a' + attribute.id"
            class="overflow-visible"
            v-if="attribute.values && attribute.values.length"
            :multiple="attribute.multivalued === true"
            :options="getOptions(attribute)"
            @input="reviewAttributes"
            :reduce="x => x.value"
            v-model="requiredAttributes[attribute.id]"
            :disabled="editMode"
          ></v-select>
          <b-input
            :id="'a' + attribute.id"
            v-else
            v-model="requiredAttributes[attribute.id]"
            @input="reviewAttributes"
            :disabled="editMode"
            :state="
              !!requiredAttributes[attribute.id] &&
                !!requiredAttributes[attribute.id].length
            "
          ></b-input>
          <b-form-invalid-feedback
            :state="
              !!requiredAttributes[attribute.id] &&
                !!requiredAttributes[attribute.id].length
            "
            v-if="
              !requiredAttributes[attribute.id] ||
                !requiredAttributes[attribute.id].length
            "
          >
            Este atributo es obligatorio
          </b-form-invalid-feedback>
        </b-form-group></b-col
      >
    </b-row>
    <b-row v-if="requiredComplete">
      <b-col>
        <b-spinner v-if="loadingAllAttributes"></b-spinner>
        <size-chart-mercado-libre-form-attributes
          v-else
          :attributes="allAttributes"
          :complete-attributes="attributesForTechnicalSpecsQuery"
          :editMode="editMode"
          v-model="sizeChart"
          @change="emitChange"
          :domain-id="this.domain.domainId"
          :oldRows="oldRows"
        ></size-chart-mercado-libre-form-attributes>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import INTEGRATION_CONFIG_MERCADO_LIBRE_TECHNICAL_SPECS from "../../../graphql/IntegrationConfigMercadoLibreTechnicalSpecs.gql";
import INTEGRATION_CONFIG_MERCADO_LIBRE_TECHNICAL_SPECS_FROM_SIZE_CHART_ATTRIBUTES from "../../../graphql/IntegrationConfigMercadoLibreTechnicalSpecsFromSizeChartAttributes.gql";
import SizeChartMercadoLibreFormAttributes from "./SizeChartMercadoLibreFormAttributes.vue";
import MERCADO_LIBRE_SITE_DOMAINS from "@/graphql/MercadoLibre/Domains/siteDomains.gql";
export default {
  components: { SizeChartMercadoLibreFormAttributes },
  name: "SizeChartMercadoLibreForm",
  model: {
    prop: "_value",
    event: "change"
  },
  props: {
    domain: {
      type: Object,
      required: true
    },
    integrationConfigId: {
      type: String,
      required: true
    },
    editMode: {
      type: Boolean,
      required: true
    },
    _value: {
      type: Object,
      default: null
    },
    oldRows: {
      type: Array,
      default: null
    }
  },
  mounted() {
    this.getTechnicalSpecsBySpecificDomain();
    this.isActiveDomain();
  },
  data() {
    return {
      technicalSpecs: null,
      attributes: null,
      requiredAttributes: {},
      loading: true,
      loadingAllAttributes: false,
      requiredComplete: false,
      allAttributes: null,
      sizeChart: this._value ? this.$dup(this._value) : this.defaultSizeChart(),
      variable: "",
      isActive: null,
      loadingDomains: true
    };
  },
  computed: {
    gridTemplateRequiredAttributes() {
      const required = [];
      const all = this.extractAttributesFromTechnicalSpecs(this.technicalSpecs);
      if (!all) return null;

      all.forEach(attribute => {
        if (
          this.$ifNull(attribute.tags, []).find(
            t => t == "grid_template_required"
          )
        ) {
          required.push(attribute);
        }
      });
      return required;
    },
    gridTemplateAttributes() {
      const required = [];
      const all = this.extractAttributesFromTechnicalSpecs(this.technicalSpecs);
      if (!all) return null;

      all.forEach(attribute => {
        if (
          this.$ifNull(attribute.tags, []).find(
            t => t == "grid_id" || t == "grid_row_id"
          )
        ) {
          required.push(attribute);
        }
      });
      return required;
    },
    attributesForTechnicalSpecsQuery() {
      if (!this.gridTemplateRequiredAttributes) return [];

      return this.gridTemplateRequiredAttributes.map(attribute => {
        let valueId = this.requiredAttributes[attribute.id];
        return {
          id: attribute.id,
          valueId: valueId,
          valueName: this.tryGetNameAttribute(attribute, valueId)
        };
      });
    }
  },
  methods: {
    /**
     * Intenta obtener el label de un atributo
     * @param {Object} attribute
     * @param {String} valueId
     * @returns {String}
     */
    tryGetNameAttribute(attribute, valueId) {
      let ans = null;
      if (attribute.values && attribute.values.length) {
        attribute.values.forEach(val => {
          if (val.id === valueId) {
            ans = val.name;
          }
        });
      }
      return ans;
    },
    /**
     * Consultar atributos para la guía en la ficha técnica del dominio
     */
    getTechnicalSpecsBySpecificDomain() {
      this.loading = true;
      this.$apollo
        .query({
          query: INTEGRATION_CONFIG_MERCADO_LIBRE_TECHNICAL_SPECS,
          variables: {
            domainId: this.domain.domainId,
            integrationConfigId: this.integrationConfigId
          }
        })
        .then(({ data }) => {
          this.technicalSpecs =
            data.integrationConfigMercadoLibre.technicalSpecs;
          this.loading = false;
          this.completeRequiredAttributeValues();
          this.reviewAttributes();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    /**
     * Extraer todos los atributos del dominio para completas ficha tecnica
     * @param {Object} technicalSpecs
     * @returns {Array<Object>}
     */
    extractAttributesFromTechnicalSpecs(technicalSpecs) {
      const groups = this.$dig(technicalSpecs, "input", "groups");
      if (!groups) return null;
      let attributes = [];

      groups.forEach(group => {
        group.components.forEach(component => {
          component.attributes.forEach(attribute => {
            attributes.push(attribute);
          });
        });
      });
      return attributes;
    },
    /**
     * Extraer todos los atributos de la ficha tecnica para crear GDT
     * @param {Object} technicalSpecs
     * @param {Array<Object>}
     */
    extractAttributesFromDataSheet(technicalSpecs) {
      const groups = this.$dig(technicalSpecs, "input", "groups");
      if (!groups) return null;
      let attributes = [];

      groups.forEach(group => {
        group.components.forEach(component => {
          let grid = component.components;
          grid.forEach(component_grid => {
            component_grid.attributes.forEach(attr => {
              attributes.push(attr);
            });
          });
        });
      });
      return attributes;
    },
    /**
     * Revisa si los atributos requeridos para la ficha tecnica estan completos
     * En caso afirmativo, se debe consultar por la ficha tecnica, la cual se ocupa
     * como base para la creacion de GDT
     */
    reviewAttributes() {
      let complete = this.reviewRequiredAttributes();
      if (complete && this.gridTemplateRequiredAttributes.length > 0) {
        this.requiredComplete = complete;
        this.getDataSheet();
      } else {
        this.requiredComplete = false;
        this.loadingAllAttributes = false;
      }
    },
    /** Revisa si los atributos requeridos para la ficha tecnica estan completos
     * @returns {Boolean}
     */
    reviewRequiredAttributes() {
      let complete = true;
      if (this.gridTemplateRequiredAttributes) {
        this.gridTemplateRequiredAttributes.forEach(attribute => {
          if (
            !this.requiredAttributes[attribute.id] ||
            !this.requiredAttributes[attribute.id].length
          ) {
            complete = false;
          }
        });
      }
      return complete;
    },
    /**
     * Para un atributo con selector, busca el label asociado al valor
     * @param {String} attr_id
     * @param {String} value_id
     * @returns {String}
     */
    searchLabel(attr_id, value_id) {
      let ans = null;
      this.gridTemplateRequiredAttributes.forEach(attr => {
        if (attr_id == attr.id) {
          attr.values.forEach(value => {
            if (value.id == value_id) {
              ans = value.name;
            }
          });
        }
      });
      return ans;
    },
    /**
     * Consulta por ficha tecnica para guia de talles
     */
    getDataSheet() {
      this.loadingAllAttributes = true;
      this.$apollo
        .query({
          query: INTEGRATION_CONFIG_MERCADO_LIBRE_TECHNICAL_SPECS_FROM_SIZE_CHART_ATTRIBUTES,
          variables: {
            attributes: this.attributesForTechnicalSpecsQuery,
            integrationConfigId: this.integrationConfigId,
            domainId: this.domain.domainId
          }
        })
        .then(({ data }) => {
          this.allAttributes = this.extractAttributesFromDataSheet(
            data.integrationConfigMercadoLibre
              .technicalSpecsFromSizeChartAttributes
          );
          this.loadingAllAttributes = false;
        })
        .catch(() => {
          this.loadingAllAttributes = false;
        });
    },
    /**
     * Emite cambios del formulario de GDT
     */
    emitChange(newVal) {
      this.$emit("change", newVal);
    },
    /**
     * Obtiene valor actual de atributo
     * @param {Object} attribute
     * @returns {String}
     */
    attributeValue(attribute) {
      const attrVal = this.$ifNull(
        this.$dig(this._value, "attributes"),
        []
      ).find(a => a.id === attribute.id);
      if (!attrVal) return null;
      if (attribute.multivalued) {
        return this.$ifNull(attrVal.values, []).map(v =>
          this.$ifNull(v.id, v.name)
        );
      }
      return this.$ifNull(
        this.$ifNull(
          this.$dig(attrVal, "values", 0, "id"),
          this.$dig(attrVal, "values", 0, "name")
        ),
        ""
      );
    },
    /**
     * Retorna un diccionario con los valores actuales para los atributos requeridos
     * de la ficha tecnica
     * @returns {Object}
     */
    completeRequiredAttributeValues() {
      let requiredAttributes = {};
      if (this.gridTemplateRequiredAttributes) {
        this.gridTemplateRequiredAttributes.forEach(attribute => {
          let attrValue = this.attributeValue(attribute);
          requiredAttributes[attribute.id] = attrValue;
        });
      }
      this.requiredAttributes = this.$dup(requiredAttributes);
    },
    /**
     * Entrega arreglo con opciones para un atributo de tipo selector
     * @param {Object} attribute
     * @returns {Array<Object>}
     */
    getOptions(attribute) {
      return attribute.values.map(x => {
        return { label: x.name, value: x.id };
      });
    },
    /**
     * Construye estructura por default para una GDT
     * @returns {Object}
     */
    defaultSizeChart() {
      return {
        domain_id: null,
        name: null,
        main_attribute: null,
        attributes: [],
        rows: []
      };
    },
    /**
     * Obtiene los dominios disponibles para el pais y el tipo,
     * y despues revisa que el dominio seleccionada esté
     * disponible para crear guias de tallas.
     */
    isActiveDomain() {
      this.loadingDomains = true;
      if (!this.domain) {
        this.loadingDomains = false;
        return;
      }
      return this.$apollo
        .query({
          query: MERCADO_LIBRE_SITE_DOMAINS,
          variables: {
            integrationConfigId: this.integrationConfigId
          }
        })
        .then(({ data }) => {
          let result = this.$dig(
            data,
            "integrationConfigMercadoLibre",
            "siteDomains",
            "domains"
          );
          let isActive = false;
          result?.every(domain => {
            if (
              domain.domainId.split("-").slice(-1)[0] ==
              this.domain.domainId.split("-").slice(-1)[0]
            ) {
              isActive = true;
              return false;
            }
            return true;
          });
          this.isActive = isActive;
          this.loadingDomains = false;
        });
    }
  },
  watch: {
    gridTemplateRequiredAttributes() {
      this.completeRequiredAttributeValues();
    },
    _value() {
      //this.completeRequiredAttributeValues();
    },
    domain() {
      this.getTechnicalSpecsBySpecificDomain();
      this.isActiveDomain();
    }
  }
};
</script>
