<template>
  <div>
    <b-alert v-if="params && !existsKey" variant="danger">
      ERROR: Debe haber al menos una columna que identifique al producto o
      variante (ID o SKU). Por favor, cargue
      <b-link to="/bulk_upload">una nueva planilla</b-link>
    </b-alert>
    <div
      v-else-if="
        loadingProducts ||
          !params ||
          !requiredOnCreateParams ||
          !blockages ||
          !categoryAttributes
      "
      class="text-center"
    >
      <b-spinner label="Spinning"></b-spinner> {{ progress }}% Obteniendo
      información de los productos
    </div>
    <bulk-upload-tabs-load
      v-else-if="valid"
      :data="Object.assign({}, data, validatedData)"
    >
    </bulk-upload-tabs-load>
    <div v-else>
      <bulk-upload-tabs-preparation
        @valid="validate"
        :data="data"
        :params="params"
        :integration-configs="data.integrationConfigs"
        :required-on-create-params="requiredOnCreateParams"
        :products-id-hash="productsIdHash"
        :variants-id-hash="variantsIdHash"
        :products-sku-hash="productsSkuHash"
        :variants-sku-hash="variantsSkuHash"
        :category-attributes="categoryAttributes"
        :blockages="blockages"
        :id-product-column="idProductColumn"
        :sku-product-column="skuProductColumn"
        :id-variant-column="idVariantColumn"
        :sku-variant-column="skuVariantColumn"
        :category-id-column="categoryIdColumn"
      >
      </bulk-upload-tabs-preparation>
    </div>
  </div>
</template>
<script>
import BulkUploadTabsPreparation from "@/components/BulkUploadTabsPreparation";
import BulkUploadTabsLoad from "@/components/BulkUploadTabsLoad";
import ALL_PARAMS_FOR_LOAD from "@/graphql/AllParamsForLoad.gql";
import ALL_PRODUCTS_FOR_LOAD from "@/graphql/AllProductsForLoad.gql";
import ALL_VARIANTS_FOR_LOAD from "@/graphql/AllVariantsForLoad.gql";
import ALL_CATEGORY_ATTRIBUTES from "@/graphql/AllCategoryAttributes/index.gql";
import ALL_BLOCKAGES from "@/graphql/AllBlockages.gql";
import { mapState } from "vuex";

export default {
  name: "BulkUploadTabsPerformLoad",
  components: { BulkUploadTabsLoad, BulkUploadTabsPreparation },
  props: {
    data: Object
  },
  created() {
    this.updateParams().then(() => {
      this.validateExistsKey();
      this.updateRequiredOnCreateParams().then(() =>
        this.updateCategoryAttributes()
      );
      this.updateBlockages();
      Promise.all([this.updateProducts(), this.updateVariants()])
        .then(() => this.markFinishedProductsAndVariantsProgress())
        .then(() => this.updateVariantProducts())
        .then(() => this.updateProductVariants())
        .then(() => {
          this.loadingProducts = false;
        });
    });
  },
  data() {
    const parallelPages = 10;
    const parallelArray = [];
    for (let i = 0; i < parallelPages; i++)
      parallelArray.push({ currentPage: 0 });
    return {
      valid: false,
      validatedData: null,
      params: null,
      existsKey: true,
      requiredOnCreateParams: null,
      loadingProducts: true,
      productsIdHash: {},
      variantsIdHash: {},
      productsSkuHash: {},
      variantsSkuHash: {},
      categoryAttributes: null,
      blockages: null,
      parallelPages,
      graphqlPageSize: 100,
      pageInfoUpdateProducts: this.$dup(parallelArray),
      pageInfoUpdateVariants: this.$dup(parallelArray),
      pageInfoUpdateProductVariants: this.$dup(parallelArray),
      pageInfoUpdateVariantProducts: this.$dup(parallelArray),
      forceFinishProgress: false
    };
  },
  computed: {
    ...mapState(["currentUser"]),
    progressUpdateProducts() {
      if (this.forceFinishProgress) return 1;
      return this.calculateProgress(this.pageInfoUpdateProducts);
    },
    progressUpdateVariants() {
      if (this.forceFinishProgress) return 1;
      return this.calculateProgress(this.pageInfoUpdateVariants);
    },
    progressUpdateProductVariants() {
      return this.calculateProgress(this.pageInfoUpdateProductVariants);
    },
    progressUpdateVariantProducts() {
      return this.calculateProgress(this.pageInfoUpdateVariantProducts);
    },
    progress() {
      return (
        Math.round(
          ((this.progressUpdateProducts +
            this.progressUpdateVariants +
            this.progressUpdateProductVariants +
            this.progressUpdateVariantProducts) /
            4) *
            10000
        ) / 100
      );
    },
    idProductColumn() {
      if (!this.params) return -1;
      return this.getColumnIndex("product", ["id", "_id"]);
    },
    idVariantColumn() {
      if (!this.params) return -1;
      return this.getColumnIndex("variant", ["id", "_id"]);
    },
    skuProductColumn() {
      if (!this.params) return -1;
      return this.getColumnIndex("product", ["sku"]);
    },
    skuVariantColumn() {
      if (!this.params) return -1;
      return this.getColumnIndex("variant", ["sku"]);
    },
    categoryIdColumn() {
      if (!this.params) return -1;
      return this.getColumnIndex("product", ["category_id"]);
    }
  },
  methods: {
    calculateProgress(array) {
      let totalPages = array
        .map(x => this.$ifNull(x.totalPages, 0))
        .reduce((x, y) => x + y);
      if (!totalPages) totalPages = 1;
      const currentPage = array
        .map(x => this.$ifNull(x.currentPage, 0))
        .reduce((x, y) => x + y);
      return currentPage / totalPages;
    },
    validate(data) {
      this.validatedData = data;
      this.valid = true;
    },
    /**
     * Verifica que exista alguna columna con la cual identificar al producto o
     * la variante y el booleano resultante es almacenado en el param
     * `existsKey`.
     */
    validateExistsKey() {
      this.existsKey =
        this.idProductColumn >= 0 ||
        this.skuProductColumn >= 0 ||
        this.idVariantColumn >= 0 ||
        this.skuVariantColumn >= 0;
    },
    async updateParams() {
      const paramIds = this.$ifNull(this.$dig(this.data, "columnParams"), [])
        .filter(x => !!x)
        .map(x => x.split("_")[0]);
      return this.$getAllPages(
        ALL_PARAMS_FOR_LOAD,
        { paramIds },
        "allParams"
      ).then(array => {
        const params = {};
        array.forEach(paramNode => {
          params[paramNode.node.id] = paramNode.node;
        });
        this.params = params;
      });
    },
    /**
     * Obtiene los parámetros requeridos al crear un producto o variante. Estos
     * parámetros son almacenados en el array `requiredOnCreateParams`.
     */
    async updateRequiredOnCreateParams() {
      return this.$getAllPages(
        ALL_PARAMS_FOR_LOAD,
        { required: ["on_create"] },
        "allParams",
        null,
        90000
      ).then(array => {
        this.requiredOnCreateParams = array.map(x => x.node);
      });
    },
    itemsPerPage(filter) {
      return Math.ceil(filter.length / this.parallelPages);
    },
    /**
     * Establece el total de páginas de un array de objetos.
     * @param {Array} array
     * @param {Array} filter
     * @param {int} factor
     */
    setTotalPages(array, filter, factor = 1) {
      const pages = Math.ceil(
        (this.itemsPerPage(filter) * factor) / this.graphqlPageSize
      );
      array.forEach(elem => (elem.totalPages = pages));
    },
    async addProducts(key, filter, pageInfoObject = {}) {
      this.setTotalPages(pageInfoObject, filter);
      return this.concurrentQuery(
        key,
        filter,
        this.addProductsPage,
        {},
        pageInfoObject
      );
    },
    async concurrentQuery(
      key,
      filter,
      toExcec,
      otherFilters = {},
      pageObject = {}
    ) {
      const itemsPerPages = this.itemsPerPage(filter);
      const processes = [];
      for (let i = 0; i < this.parallelPages; i++) {
        const filterPage = filter.slice(
          i * itemsPerPages,
          (i + 1) * itemsPerPages
        );
        if (filterPage.length) {
          const filters = {};
          filters[key] = filterPage;
          processes.push(
            toExcec(Object.assign(filters, otherFilters), pageObject, i)
          );
        } else {
          pageObject[i].currentPage = 1;
          pageObject[i].totalPages = 1;
        }
      }
      return Promise.all(processes);
    },
    async addProductsPage(variables, pageObject = {}, page = 0) {
      return this.$getAllPages(
        ALL_PRODUCTS_FOR_LOAD,
        variables,
        "allProducts",
        pageObject[page],
        30000,
        "no-cache"
      ).then(array => {
        array.forEach(productNode => {
          this.removeJsons(productNode.node);
          if (productNode.node.sku && productNode.node.sku.length) {
            this.productsSkuHash[productNode.node.sku] = productNode.node;
          }
          this.productsIdHash[productNode.node.id] = productNode.node;
          pageObject.currentPage = pageObject.totalPages;
        });
      });
    },
    extractColumnData(index) {
      const array = {};
      this.data.data
        .slice(this.data.startRow - 1, this.data.data.length)
        .forEach(row => {
          if (row[index] !== null && row[index].toString().length)
            array[row[index]] = true;
        });
      return Object.keys(array);
    },
    async updateProducts() {
      if (this.idProductColumn >= 0) {
        return this.addProducts(
          "ids",
          this.extractColumnData(this.idProductColumn),
          this.pageInfoUpdateProducts
        );
      } else if (this.skuProductColumn >= 0) {
        return this.addProducts(
          "skus",
          this.extractColumnData(this.skuProductColumn),
          this.pageInfoUpdateProducts
        );
      }
    },
    async addVariants(key, filter, otherFilters = {}) {
      this.setTotalPages(this.pageInfoUpdateVariants, filter);
      return this.concurrentQuery(
        key,
        filter,
        this.addVariantsPage,
        otherFilters,
        this.pageInfoUpdateVariants
      );
    },
    async updateVariants() {
      if (this.idVariantColumn >= 0) {
        return this.addVariants(
          "ids",
          this.extractColumnData(this.idVariantColumn)
        );
      } else if (this.skuVariantColumn >= 0) {
        return this.addVariants(
          "skus",
          this.extractColumnData(this.skuVariantColumn)
        );
      }
    },
    async updateProductVariants() {
      const filter = Object.keys(this.productsIdHash);
      this.setTotalPages(this.pageInfoUpdateProductVariants, filter, 10);
      return this.concurrentQuery(
        "productIds",
        filter,
        this.putVariantsToProducts,
        { excludeIds: Object.keys(this.variantsIdHash) },
        this.pageInfoUpdateProductVariants
      );
    },
    async updateVariantProducts() {
      const filter = Object.values(this.variantsIdHash)
        .map(variant => variant.productId)
        .filter(productId => !this.productsIdHash[productId]);
      this.setTotalPages(this.pageInfoUpdateVariantProducts, filter);
      return this.addProducts(
        "ids",
        filter,
        this.pageInfoUpdateVariantProducts
      );
    },
    async putVariantsToProducts(variables, pageObject = {}, page = 0) {
      return this.$getAllPages(
        ALL_VARIANTS_FOR_LOAD,
        variables,
        "allVariants",
        pageObject[page],
        600000,
        "no-cache"
      ).then(array => {
        array.forEach(variantNode => {
          const product = this.productsIdHash[variantNode.node.productId];
          if (product) {
            if (!product.variants) product.variants = [];
            product.variants.push(variantNode.node);
          }
        });
        pageObject[page].currentPage = pageObject[page].totalPages;
      });
    },
    async addVariantsPage(variables, pageObject = {}, page = 0) {
      return this.$getAllPages(
        ALL_VARIANTS_FOR_LOAD,
        variables,
        "allVariants",
        pageObject[page],
        600000,
        "no-cache"
      ).then(array => {
        array.forEach(variantNode => {
          if (variantNode.node.sku && variantNode.node.sku.length) {
            this.variantsSkuHash[variantNode.node.sku] = variantNode.node;
          }
          this.variantsIdHash[variantNode.node.id] = variantNode.node;
        });
        pageObject.currentPage = pageObject.totalPages;
      });
    },
    async updateCategoryAttributes() {
      const names = {};
      Object.keys(this.params)
        .filter(x => this.params[x].model === "category_attribute")
        .forEach(x => (names[this.params[x].path] = true));
      this.requiredOnCreateParams
        .filter(x => x.model === "category_attribute")
        .forEach(x => (names[x.path] = true));
      if (!Object.keys(names).length) {
        this.categoryAttributes = {};
        return;
      }
      return this.$getAllPages(
        ALL_CATEGORY_ATTRIBUTES,
        { names: Object.keys(names) },
        "allCategoryAttributes",
        null,
        20000,
        "no-cache"
      ).then(array => {
        const categoryAttributes = {};
        array.forEach(caNode => {
          categoryAttributes[caNode.node.name] = caNode.node;
        });
        this.categoryAttributes = categoryAttributes;
      });
    },
    async updateBlockages() {
      return this.$getAllPages(
        ALL_BLOCKAGES,
        {},
        "allBlockages",
        null,
        20000,
        "no-cache"
      ).then(array => {
        this.blockages = array.map(blockage => blockage.node);
      });
    },
    getColumnIndex(model, paths) {
      const idParam = Object.keys(this.params).find(idParam => {
        return (
          this.params[idParam].model === model &&
          paths.includes(this.params[idParam].path) &&
          !this.params[idParam].publicApplicationInformationId &&
          !this.params[idParam].internalApplication
        );
      });
      if (!idParam) return -1;
      return this.data.columnParams
        .map((x, idx) => [x, idx])
        .filter(x => !!x[0])
        .find(elem => elem[0].startsWith(idParam))[1];
    },
    markFinishedProductsAndVariantsProgress() {
      this.forceFinishProgress = true;
    },
    /**
     * Elimina datos innecesarios de categoryAttributeValuesJson y assetsJson
     * @param {Object} productNode
     */
    removeJsons(productNode) {
      productNode.categoryAttributeValues = productNode.categoryAttributeValuesJson?.map(
        val => {
          return {
            categoryAttributeId: val.category_attribute_id,
            valueFilled: val.value_filled,
            valueSelectedIds: val.value_selected_ids
          };
        }
      );
      productNode.assets = productNode.assetsJson?.map(val => {
        return {
          originalUrl: val.original_url,
          position: val.position
        };
      });
      delete productNode.categoryAttributeValuesJson;
      delete productNode.assetsJson;
    }
  }
};
</script>
