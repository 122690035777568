<template>
  <b-spinner label="Spinning" v-if="loadingTable"> </b-spinner>
  <div v-else>
    <base-homologation-table
      :key="id"
      :columns="columns"
      :rows="rows"
      :purposes-index-rev="purposesIndexRev"
      :homologations-ids-rev="valuesIdsRev"
      :homologation-index="homologationIndex"
      :mutation="mutation"
      mutation-name="saveVtexHomologation"
      model-name="Products::CategoryAttributeOption"
      :all-values-father="this.allValues"
    ></base-homologation-table>
  </div>
</template>

<script>
import GET_SPECIFICATION_VALUES from "@/graphql/IntegrationConfigVtexGetSpecificationValues.gql";
import SAVE_VTEX_HOMOLOGATION from "@/graphql/SaveVtexHomologation.gql";
import BaseHomologationTable from "@/components/BaseHomologationTable";
import { mapState } from "vuex";

export default {
  name: "VtexHomologationAttributesValuesTable",
  components: { BaseHomologationTable },
  computed: {
    ...mapState(["currentUser"])
  },
  props: {
    purposes: Array,
    options: Array
  },
  data() {
    return {
      rows: [],
      columns: [
        {
          type: "text",
          title: "Valor Centry",
          width: 200,
          readOnly: true
        }
      ],
      mutation: SAVE_VTEX_HOMOLOGATION,
      purposesIndex: {},
      purposesIndexRev: {},
      homologationIndex: {},
      homologationValues: {},
      loadingTable: true,
      attributesIds: {},
      attributesIdsRev: {},
      valuesIds: {},
      valuesIdsRev: {},
      available: false,
      allValues: false,
      id: 0
    };
  },
  async mounted() {
    await this.prepareColumns();
    await this.prepareRows();
    this.loadingTable = false;
  },
  methods: {
    /**
     * Prepara las columnas de la tabla trayendo las
     * opciones desde Vtex si corresponde.
     */
    async prepareColumns() {
      let indexes = {};
      let indexesRev = {};
      let i = 0;
      for (let p of this.purposes) {
        let icId = p.node.integrationConfig.id;
        let options = await this.getValuesFromVtex(
          icId,
          p.node.specId,
          p.node.id
        );
        this.homologationValues[p.node.id] = {};
        p.node.vtexHomologations.forEach(hv => {
          this.homologationValues[p.node.id][hv.vtexHomologableId] = hv.v;
        });
        let column = {
          type: p.node.dataType,
          title: p.node.integrationConfig.label + ": " + p.node.specName,
          width: 200,
          source: options
        };
        this.columns.push(column);
        indexes[p.node.id] = i + 1;
        indexesRev[i + 1] = p.node.id;
        i += 1;
      }
      this.purposesIndex = indexes;
      this.purposesIndexRev = indexesRev;
    },
    /**
     * Prepara las filas obteniendo los datos ya rellenados
     * para las columnas correspondientes.
     */
    async prepareRows() {
      const indexes = this.purposesIndex;
      const purposesLength = Object.keys(indexes).length;
      let rows = [];
      this.options.forEach(cao => {
        let row = [];
        row[0] = cao.name;
        this.homologationIndex[cao.name] = cao.id;
        for (let i = 1; i <= purposesLength; i++) {
          row[i] = "";
        }
        this.purposes.forEach(purpose => {
          let index = indexes[purpose.node.id];
          let value = this.homologationValues[purpose.node.id][cao.id];
          if (value) {
            row[index] = this.valuesIds[purpose.node.id][value] || value;
          }
        });
        rows.push(row);
      });
      this.rows = rows;
    },
    /**
     * Obtiene las opciones de una especificación
     * en Vtex
     */
    async getValuesFromVtex(icId, specId, purposeId) {
      return await this.$apollo
        .query({
          query: GET_SPECIFICATION_VALUES,
          variables: {
            id: icId,
            specificationId: specId
          }
        })
        .then(({ data }) => {
          let options = [];
          this.valuesIdsRev[purposeId] = {};
          this.valuesIds[purposeId] = {};
          let values = data.integrationConfigVtex.specificationValuesList;
          values.forEach(v => {
            if (v.isActive) {
              options.push(v.value);
              this.valuesIdsRev[purposeId][v.value] = v.fieldValueId;
              this.valuesIds[purposeId][v.fieldValueId] = v.value;
            }
          });
          return options;
        });
    }
  }
};
</script>
