<template>
  <div>
    <a :href="`/integrations/webhook/${widget.webhook.id}`"
      ><h4>Estado del webhook: {{ widget.webhook.callbackUrl }}</h4></a
    >
    <ApolloQuery
      :query="
        gql => gql`
          query($id: ID!) {
            webhookSuccessPercentage(webhookId: $id)
          }
        `
      "
      :variables="{ id: widget.webhook.id }"
    >
      <template v-slot="{ result: { data, loading } }">
        <div>
          <b-skeleton-img
            v-if="loading || !data"
            height="100px"
          ></b-skeleton-img>
          <summary-message-sales-report
            v-else
            :title="widget.name"
            currency=""
            :amount="data.webhookSuccessPercentage + '%'"
          />
          <b-alert variant="warning" v-if="webhookFailed" show
            >Advertencia: Tu webhook lleva {{ webhookHoursFailed }} horas sin
            responder. Si pasan 2 días, será eliminado</b-alert
          >
        </div>
      </template>
    </ApolloQuery>
  </div>
</template>
<script>
import SummaryMessageSalesReport from "../../components/SummaryMessageSalesReport.vue";
export default {
  name: "DashboardWebhooks",
  props: {
    widget: Object
  },
  components: {
    SummaryMessageSalesReport
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    webhookFailed() {
      return this.webhookHoursFailed >= 12;
    },
    webhookHoursFailed() {
      if (!this.widget.webhook.lastFailedNotificationDate) {
        return 0;
      }
      return Math.floor(
        (new Date() -
          new Date(this.widget.webhook.lastFailedNotificationDate)) /
          3600000
      );
    }
  }
};
</script>
