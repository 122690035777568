<template>
  <div>
    <h4 class="font-weight-bold m-3">
      Más por menos
    </h4>

    <b-row>
      <b-col md="2"> El cliente compra* </b-col>
      <b-col md="2">
        <b-form-input type="number" min="0" v-model="skusQuantity">
        </b-form-input>
        <span v-if="requiredMessage.skusQuantity" class="text-danger">
          Cantidad requerida
        </span>
      </b-col>
      <b-col md="2"> de los siguientes SKUs:</b-col>

      <b-col>
        <b-form-group>
          <v-select
            multiple
            placeholder="Seleccione SKUs"
            v-model="selectedSkus"
            :options="skus"
          ></v-select>
          <span v-if="requiredMessage.selectedProduct" class="text-danger">
            Algun SKU o colección es requerido
          </span>
        </b-form-group>

        <b-form-group>
          <b-button variant="outline-info" @click="showModal = !showModal">
            <b-icon-cloud-download></b-icon-cloud-download> Obtener SKUs
          </b-button>
          <vtex-base-promotion-products-and-skus-data-modal
            v-model="showModal"
            :vtexIntegrationConfigId="vtexIntegrationConfigId"
            @skus="setSkus"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <br />

    <b-row>
      <b-col md="2"> y lleva* </b-col>
      <b-col md="2">
        <b-form-input
          type="number"
          min="0"
          v-model="collectionProductsQuantity"
        >
        </b-form-input>
        <span
          v-if="requiredMessage.collectionProductsQuantity"
          class="text-danger"
        >
          Cantidad requerida
        </span>
      </b-col>
      <b-col md="2"> de los productos en las colecciones: </b-col>
      <b-spinner v-if="loadingCollections" label="Spinning"></b-spinner>
      <b-col v-else>
        <v-select
          multiple
          placeholder="Seleccione colecciones"
          v-model="selectedCollections"
          :options="collections"
        ></v-select>
        <span v-if="requiredMessage.selectedProduct" class="text-danger">
          Algun SKU o colección es requerido
        </span>
      </b-col>
    </b-row>

    <br />

    <b-row>
      <b-col>
        <v-select
          v-model="discountType"
          :options="discountsOptions"
          :clearable="false"
        ></v-select>
      </b-col>

      <b-col>
        <b-input-group v-show="showPercentualDiscount" append="%">
          <b-form-input
            type="number"
            min="0"
            max="100"
            v-model="percentualDiscount"
          >
          </b-form-input>
        </b-input-group>
        <span v-if="requiredMessage.percentualDiscount" class="text-danger">
          El valor del descuento es requerido
        </span>

        <b-input-group v-show="showMaxItemsPriceDiscount" prepend="$">
          <b-form-input type="number" min="0" v-model="maxItemsPriceDiscount">
          </b-form-input>
        </b-input-group>
        <span v-if="requiredMessage.maxItemsPriceDiscount" class="text-danger">
          El valor del descuento es requerido
        </span>
      </b-col>
    </b-row>

    <br />

    <b-row>
      <b-col>
        <b-form-group label="Aplicar descuento considerando">
          <b-form-checkbox
            v-model="sameSku"
            :value="false"
            :unchecked-value="true"
          >
            Cualquier producto
          </b-form-checkbox>
          <b-form-checkbox
            v-model="sameSku"
            :value="true"
            :unchecked-value="false"
          >
            Solo productos iguales
          </b-form-checkbox>
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group label="Aplicar descuento en productos de">
          <b-form-checkbox
            v-model="mostExpensive"
            :value="false"
            :unchecked-value="true"
          >
            Menor precio
          </b-form-checkbox>
          <b-form-checkbox
            v-model="mostExpensive"
            :value="true"
            :unchecked-value="false"
          >
            Mayor precio
          </b-form-checkbox>
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group label="Descuento debe distribuirse entre">
          <b-form-checkbox
            v-model="amongMatchedItems"
            :value="false"
            :unchecked-value="true"
          >
            Los {{ collectionProductsQuantity }} productos de la colección
          </b-form-checkbox>
          <b-form-checkbox
            v-model="amongMatchedItems"
            :value="true"
            :unchecked-value="false"
          >
            Los {{ skusQuantity }} SKUs seleccionados
          </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>

    <hr />
  </div>
</template>

<script>
import VtexBasePromotionProductsAndSkusDataModal from "../VtexBasePromotionProductsAndSkusDataModal.vue";

import INTEGRATION_CONFIG_VTEX_ALL_COLLECTIONS from "@/graphql/IntegrationConfigVtexAllCollections.gql";

const discountsOptions = [
  { value: "percentualDiscount", label: "con un descuento de" },
  { value: "maxItemsPriceDiscount", label: "con un precio máximo por item de" }
];

export default {
  name: "VtexPromotionForThePriceOfItems",
  components: {
    VtexBasePromotionProductsAndSkusDataModal
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    vtexIntegrationConfigId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      promotion: this.value,
      selectedSkus: [],
      skus: [],
      loadingCollections: true,
      selectedCollections: [],
      collections: [],
      skusQuantity: this.value.minimumQuantityBuyTogether,
      collectionProductsQuantity: this.value.quantityToAffectBuyTogether,
      discountType: null,
      discountsOptions: discountsOptions,
      percentualDiscount: this.value.percentualDiscountValue,
      maxItemsPriceDiscount: this.value.maximumUnitPriceDiscount,
      sameSku: this.value.enableBuyTogetherPerSku,
      mostExpensive: this.value.isAppliedToMostExpensive,
      amongMatchedItems: this.value.shouldDistributeDiscountAmongMatchedItems,
      filledRequiredFields: false,
      showModal: false
    };
  },
  /**
   * Se obtienen los datos de skus y colecciones. Los datos se guardan en los selectores
   * correspondientes de la vista. Tambien se guardan los valores seleccionados en la promoción.
   * Se revisa el tipo de descuento correspondiente.
   */
  async mounted() {
    this.setSelectedSkus();
    this.getVtexCollections().then(() => {
      this.setSelectedCollections();
    });
    this.setDiscountType();
  },
  methods: {
    /**
     * Revisa y selecciona los SKUs de la promoción.
     */
    setSelectedSkus() {
      if (this.promotion.listSku1BuyTogether) {
        this.selectedSkus = this.promotion.listSku1BuyTogether.map(sku => ({
          value: sku.id,
          label: sku.name + " (SKU " + sku.id + ")"
        }));
      }
    },
    /**
     * Obtiene las coleccoiones de Vtex y las guarda para el selector correspondiente.
     */
    async getVtexCollections() {
      return this.$apollo
        .query({
          query: INTEGRATION_CONFIG_VTEX_ALL_COLLECTIONS,
          variables: {
            id: this.vtexIntegrationConfigId
          }
        })
        .then(({ data }) => {
          this.collections = data.integrationConfigVtex.allCollections.map(
            collection => ({
              value: collection.id,
              label: collection.name
            })
          );
          this.loadingCollections = false;
        });
    },
    /**
     * Revisa y selecciona las colecciones de la promoción.
     */
    setSelectedCollections() {
      if (this.promotion.collections1BuyTogether) {
        this.selectedCollections = this.promotion.collections1BuyTogether.map(
          selected => {
            return this.collections.find(
              collection => collection.value === selected.id
            );
          }
        );
      }
    },
    /**
     * Selecciona el tipo de descuento correspondiente a la promoción.
     */
    setDiscountType() {
      let discountType = null;
      if (this.promotion.percentualDiscountValue > 0) {
        discountType = "percentualDiscount";
      } else if (this.promotion.maximumUnitPriceDiscount > 0) {
        discountType = "maxItemsPriceDiscount";
      }
      if (!discountType) {
        discountType = "percentualDiscount";
      }

      this.setDiscountTypeOption(discountType);
    },
    /**
     * Selecciona el objeto del selector al tipo de descuento correspondiente
     * en la promoción.
     * @param {String} discountType
     */
    setDiscountTypeOption(discountType) {
      this.discountType = this.discountsOptions.find(
        option => option.value === discountType
      );
    },
    /**
     * Emite si los datos obligatorios han sido completados.
     * @param {Boolean} value - Valor a emitir.
     */
    emitFilledRequiredFields(value) {
      this.$emit("filledRequiredFields", value);
    },
    setSkus(skus) {
      this.skus = skus;
    }
  },
  computed: {
    /**
     * Decide si mostrar descuento percuentual de la promoción en la vista.
     * @returns {Boolean}
     */
    showPercentualDiscount() {
      return this.discountType?.value === "percentualDiscount";
    },
    /**
     * Decide si mostrar descuento de precio maximo de un item de la promoción en la vista.
     * @returns {Boolean}
     */
    showMaxItemsPriceDiscount() {
      return this.discountType?.value === "maxItemsPriceDiscount";
    },
    /**
     * Revisa si los atributos obligatorios de la promoción en la vista estan completos
     * para saber donde mostrar los mensajes de requerido.
     * @returns {Object} - Objeto con los atributos obligatorios y si requieren mensaje.
     */
    requiredMessage() {
      let required = {};
      required.skusQuantity =
        !this.skusQuantity || parseInt(this.skusQuantity) === 0;
      required.collectionProductsQuantity =
        !this.collectionProductsQuantity ||
        parseInt(this.collectionProductsQuantity) === 0;
      required.selectedProduct =
        this.selectedSkus.length === 0 && this.selectedCollections.length === 0;
      required.percentualDiscount =
        this.showPercentualDiscount && !this.percentualDiscount;
      required.maxItemsPriceDiscount =
        this.showMaxItemsPriceDiscount && !this.maxItemsPriceDiscount;
      return required;
    }
  },
  /**
   * En general, el objetivo de los watches es cambiar el valor de una propiedad
   * de la promocion cuando se cambia su correspondiente en la vista
   */
  watch: {
    value(newValue) {
      this.promotion = newValue;
    },
    promotion: {
      handler(newValue) {
        this.$emit("input", newValue);
      },
      deep: true
    },
    selectedSkus(newValue) {
      this.promotion.listSku1BuyTogether = newValue.map(selectedSku => {
        return {
          id: selectedSku.value,
          name: selectedSku.label
        };
      });
    },
    selectedCollections(newValue) {
      this.promotion.collections1BuyTogether = newValue.map(
        selectedCollection => {
          return {
            id: selectedCollection.value,
            name: selectedCollection.label
          };
        }
      );
    },
    skusQuantity(newValue) {
      this.promotion.minimumQuantityBuyTogether = parseInt(newValue);
    },
    collectionProductsQuantity(newValue) {
      this.promotion.quantityToAffectBuyTogether = parseInt(newValue);
    },
    /**
     * Si cambia el tipo de descuento, se actualiza el descuento correspondiente
     * con el valor ingresado y se elimina el resto de descuentos.
     * @param {String} newValue
     */
    discountType(newValue) {
      if (!newValue) return;
      if (newValue.value === "percentualDiscount") {
        this.promotion.maximumUnitPriceDiscount = 0;
        this.promotion.percentualDiscountValue = parseFloat(
          this.percentualDiscount
        );
      } else if (newValue.value === "maxItemsPriceDiscount") {
        this.promotion.percentualDiscountValue = 0;
        this.promotion.maximumUnitPriceDiscount = parseFloat(
          this.maxItemsPriceDiscount
        );
      }
    },
    percentualDiscount(newValue) {
      this.promotion.percentualDiscountValue = parseFloat(newValue);
    },
    maxItemsPriceDiscount(newValue) {
      this.promotion.maximumUnitPriceDiscount = parseFloat(newValue);
    },
    sameSku(newValue) {
      this.promotion.enableBuyTogetherPerSku = newValue;
    },
    mostExpensive(newValue) {
      this.promotion.isAppliedToMostExpensive = newValue;
    },
    amongMatchedItems(newValue) {
      this.promotion.shouldDistributeDiscountAmongMatchedItems = newValue;
    },
    /**
     * Si cambia el valor de requiredMessage, se revisa si no se necesita
     * ningun mensaje y se guarda este valor en filledRequiredFields.
     */
    requiredMessage: function(newValue) {
      this.filledRequiredFields = !Object.values(newValue).includes(true);
    },
    /**
     * Si cambia el valor de filledRequiredFields, se emite este valor al padre.
     * @param {Boolean} value - Valor a emitir.
     */
    filledRequiredFields: function(newValue) {
      this.emitFilledRequiredFields(newValue);
    }
  }
};
</script>
