<template>
  <div>
    <b-alert :show="showErrors && !valid" variant="danger" dismissible>
      No se puede guardar el descuento de stock debido a:
      <ul>
        <li v-for="(error, index) of errorList" :key="index">{{ error }}</li>
      </ul>
    </b-alert>
    <b-card no-body class="my-2">
      <b-card-body>
        <b-row style="overflow-x: auto; flex-wrap: nowrap;">
          <b-col xl="4" cols="12" class="mt-2">
            <b-form-group
              label="Descuento del stock publicado"
              label-for="stockDiscount"
              :invalid-feedback="validation.stockDiscount.invalidFeedback"
              :state="validation.stockDiscount.valid"
            >
              <b-form-input
                id="stockDiscount"
                type="number"
                number
                v-model="newStockDiscount.stockDiscount"
              />
            </b-form-group>
            <b-form-group
              label="Clase de descuento"
              label-for="stockDiscountType"
              :invalid-feedback="validation.stockDiscountType.invalidFeedback"
              :state="validation.stockDiscountType.valid"
            >
              <v-select
                id="stockDiscountType"
                :clearable="false"
                :options="stockDiscountTypeOptions"
                v-model="newStockDiscount.stockDiscountType"
                :reduce="val => val.value"
              ></v-select>
            </b-form-group>
            <b-form-group
              label="¿A qué afecta el descuento?"
              label-for="criterion"
              :invalid-feedback="validation.criterion.invalidFeedback"
              :state="validation.criterion.valid"
            >
              <v-select
                id="criterion"
                :clearable="false"
                :options="criterionTypeOptions"
                v-model="newStockDiscount.criterion"
                :reduce="val => val.value"
                :appendToBody="true"
                @input="changeCriterion"
              ></v-select>
            </b-form-group>
          </b-col>
          <b-col
            v-if="loadBrands"
            class="my-3"
            xl="4"
            cols="12"
            style="min-width: fit-content"
          >
            <b-spinner v-if="allBrands == null" />
            <div v-else class="ml-3">
              <b-form-group
                :invalid-feedback="validation.brands.invalidFeedback"
                :state="validation.brands.valid"
              >
                <vMultiselectListbox
                  ref="brandSelector"
                  style="width:100%"
                  v-model="newStockDiscount.brandIds"
                  :highlightDiff="true"
                  :options="allBrands"
                  :reduce-display-property="option => option.name"
                  :reduce-value-property="option => option.id"
                  search-options-placeholder="Buscar marcas"
                  selected-options-placeholder="Buscar marcas seleccionadas"
                  no-options-text="No hay marcas"
                  selected-no-options-text="No hay marcas seleccionadas"
                  no-options-found-text="No se encontró la marca"
                  no-selected-options-found-text=" No se encontró la marca seleccionada "
                  all-options-label="Todas las marcas"
                  selected-options-label="Marcas seleccionadas"
                />
              </b-form-group>
            </div>
          </b-col>
          <b-col v-if="loadCategories" class="my-3" xl="4" cols="12">
            <b-spinner v-if="allCategories == null" />
            <div v-else class="ml-3">
              <b-form-group
                :invalid-feedback="validation.categories.invalidFeedback"
                :state="validation.categories.valid"
              >
                <vMultiselectListbox
                  ref="categorySelector"
                  style="width:100%"
                  v-model="newStockDiscount.categoryIds"
                  :highlightDiff="true"
                  :options="allCategories"
                  :reduce-display-property="option => option.name"
                  :reduce-value-property="option => option.id"
                  search-options-placeholder="Buscar categorias"
                  selected-options-placeholder="Buscar categorias seleccionadas"
                  no-options-text="No hay categorias"
                  selected-no-options-text="No hay categorias seleccionadas"
                  no-options-found-text="No se encontró la categoria"
                  no-selected-options-found-text="No se encontró la categoria seleccionada"
                  all-options-label="Todas las categorias"
                  selected-options-label="Categorias seleccionadas"
                />
              </b-form-group>
            </div>
          </b-col>
        </b-row>
        <b-row align-h="end" class="mt-3 mr-1">
          <b-button @click="deleteStockDiscount" variant="danger">
            Eliminar
          </b-button>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import vMultiselectListbox from "vue-multiselect-listbox";
import "vue-multiselect-listbox/dist/vue-multi-select-listbox.css";
export default {
  components: {
    vMultiselectListbox
  },
  name: "StockDiscountDetail",
  props: {
    stockDiscount: {
      type: Object,
      default() {
        return null;
      }
    },
    allBrands: {
      type: Array,
      default() {
        return null;
      }
    },
    allCategories: {
      type: Array,
      default() {
        return null;
      }
    },
    index: Number,
    reset: {
      type: Number,
      default: 0
    },
    saved: {
      type: Number,
      default: 0
    },
    showErrors: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      newStockDiscount: this.$dup(this.stockDiscount),
      originalStockDiscount: this.$dup(this.stockDiscount),
      loading: false,
      saving: false,
      errorMessage: null,
      error: false,
      success: false,
      stockDiscountTypeOptions: [
        {
          value: "unit",
          label: "Unidad"
        },
        {
          value: "percentage",
          label: "Porcentaje"
        }
      ],
      criterionTypeOptions: [
        {
          value: "all_existences",
          label: "Para todo el inventario"
        },
        {
          value: "just_brand",
          label: "Sólo considerar marca"
        },
        {
          value: "just_category",
          label: "Sólo considerar categoría"
        },
        {
          value: "brand_category",
          label: "Considerar marca y categoría"
        }
      ]
    };
  },
  computed: {
    loadBrands() {
      return (
        this.newStockDiscount.criterion != null &&
        this.newStockDiscount.criterion.includes("brand")
      );
    },
    loadCategories() {
      return (
        this.newStockDiscount.criterion != null &&
        this.newStockDiscount.criterion.includes("category")
      );
    },
    changed() {
      return !this.$objDeepCompare(
        this.newStockDiscount,
        this.originalStockDiscount
      );
    },
    validation() {
      return {
        brands: {
          valid: this.newStockDiscount.brandIds.length != 0,
          invalidFeedback: "Se debe ingresar al menos una marca"
        },
        categories: {
          valid: this.newStockDiscount.categoryIds.length != 0,
          invalidFeedback: "Se debe ingresar al menos una categoría"
        },
        stockDiscount: {
          valid:
            this.newStockDiscount.stockDiscount !== null &&
            this.newStockDiscount.stockDiscount !== "",
          invalidFeedback: "Se debe ingresar un descuento"
        },
        stockDiscountType: {
          valid: this.newStockDiscount.stockDiscountType?.length > 0,
          invalidFeedback: "Se debe ingresar una clase de descuento"
        },
        criterion: {
          valid: this.newStockDiscount.criterion?.length > 0,
          invalidFeedback: "Se debe ingresar un tipo de criterio"
        }
      };
    },
    valid() {
      let tempValidations = this.$dup(this.validation);
      delete tempValidations.brands;
      delete tempValidations.categories;
      let valid = true;
      Object.keys(tempValidations).forEach(x => {
        valid &&= tempValidations[x].valid;
      });
      if (this.loadBrands) {
        valid &&= this.validation.brands.valid;
      }
      if (this.loadCategories) {
        valid &&= this.validation.categories.valid;
      }
      return valid;
    },
    errorList() {
      let tempValidations = this.$dup(this.validation);
      delete tempValidations.brands;
      delete tempValidations.categories;
      let tempList = Object.keys(tempValidations).map(key => {
        return tempValidations[key].valid
          ? null
          : tempValidations[key].invalidFeedback;
      });
      if (this.loadBrands) {
        tempList.push(this.validation.brands.invalidFeedback);
      }
      if (this.loadCategories) {
        tempList.push(this.validation.categories.invalidFeedback);
      }
      return tempList.filter(x => x !== null);
    }
  },
  created() {
    if (this.newStockDiscount.type == "new") {
      this.$emit("changed", true);
      this.$emit("valid", false);
    }
  },
  methods: {
    /**
     * Emite un evento indicando que se elimino el descuento de stock
     */
    deleteStockDiscount() {
      this.$emit("delete", this.index);
    },
    /**
     * Limpia los selectores de marcas o categorías
     * si no se deben enviar según el criterio
     */
    changeCriterion() {
      if (!this.loadBrands) {
        this.newStockDiscount.brandIds = [];
      }
      if (!this.loadCategories) {
        this.newStockDiscount.categoryIds = [];
      }
    }
  },
  watch: {
    newStockDiscount: {
      handler(val) {
        this.$emit("updateStockDiscount", val);
      },
      deep: true
    },
    changed(val) {
      this.$emit("changed", val);
    },
    loadBrands(val) {
      if (val) {
        this.$emit("getBrands");
      }
    },
    loadCategories(val) {
      if (val) {
        this.$emit("getCategories");
      }
    },
    reset() {
      this.newStockDiscount = this.$dup(this.originalStockDiscount);
    },
    saved() {
      delete this.newStockDiscount.type;
      this.originalStockDiscount = this.$dup(this.newStockDiscount);
      if (this.loadBrands) this.$refs.brandSelector.resetOriginalCopy();
      if (this.loadCategories) this.$refs.categorySelector.resetOriginalCopy();
    },
    valid(val) {
      this.$emit("valid", val);
    }
  }
};
</script>
