var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-header',{attrs:{"title":"Moderaciones","title-link":"/moderations","map":['Detalle', _vm.productData.name]}}),_c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('a',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.productData.name)+" ("+_vm._s(_vm.productData.sku)+") ")]),_c('b-button',{staticStyle:{"margin-left":"10px"},attrs:{"to":("/product/" + _vm.productId + "/edit"),"variant":"outline-info","size":"sm"}},[_vm._v(" Editar ")]),(!_vm.unpublishStatus)?_c('b-button',{staticStyle:{"margin-left":"10px"},attrs:{"variant":"outline-info","size":"sm"},on:{"click":_vm.unpublish}},[_vm._v(" Despublicar ")]):_vm._e(),(_vm.isLoadingUnpublish)?_c('b-spinner',{staticStyle:{"margin-left":"5px"},attrs:{"label":"Spinning"}}):_vm._e(),_c('b-dropdown',{staticStyle:{"margin-left":"10px"},attrs:{"variant":"outline-info","text":"Politicas","size":"sm"}},[_c('b-dropdown-item',{attrs:{"disabled":""}},[_vm._v(" Politicas MercadoLibre ")]),_c('b-dropdown-item',{attrs:{"href":"https://www.mercadolibre.cl/ayuda/1022"}},[_vm._v(" Publicación en la categoría apropiada ")]),_c('b-dropdown-item',{attrs:{"href":"https://www.mercadolibre.cl/ayuda/Crear-variantes-de-tusproductos_679"}},[_vm._v(" Crear variantes de tus productos ")])],1),_c('ApolloQuery',{attrs:{"query":require('../graphql/AllModerations.gql'),"variables":{
            cursor: '',
            productId: this.productId
          }},on:{"result":_vm.showButtonUnpublish},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var data = ref.result.data;
          var query = ref.query;
          var isLoading = ref.isLoading;
return [_c('b-table-simple',{attrs:{"hover":""}},[_c('b-thead',[_c('b-tr',[_c('b-th',[_vm._v("Error")]),_c('b-th',[_vm._v("Solución")]),_c('b-th',[_vm._v("Integración")]),_c('b-th',[_vm._v("Id Publicación")]),_c('b-th',[_vm._v("Severidad")])],1)],1),_c('b-tbody',_vm._l((data.allModerations.edges),function(moderation){return _c('b-tr',{key:moderation.node.id},[_c('b-td',[_vm._v(_vm._s(moderation.node.reason))]),_c('b-td',[_vm._v(_vm._s(moderation.node.remedy))]),_c('b-td',[_vm._v(_vm._s(_vm.$dig(moderation, "node", "integrationConfig", "label")))]),_c('b-td',[_vm._v(_vm._s(moderation.node.publicationId))]),_c('b-td',[_vm._v(_vm._s(_vm._f("translateSeverity")(moderation.node.severity)))])],1)}),1)],1),(isLoading)?_c('b-spinner',{staticClass:"m-2 float-right",attrs:{"label":"Spinning"}}):_c('div',[(_vm.hasNextPage(data))?_c('b-button',{staticClass:"m-2 float-right",on:{"click":function($event){return _vm.seeMore(query, data.allModerations.pageInfo.endCursor)}}},[_vm._v(" Ver más ")]):_c('b-alert',{staticClass:"text-center",attrs:{"show":"","variant":"light"}},[_vm._v(" No hay más datos para mostrar. ")])],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }