<template>
  <div>
    <b-row>
      <b-col md="12" class="mb-4">
        <b-button-group>
          <b-button variant="outline-info" @click="cancel">
            <b-icon-x-circle></b-icon-x-circle> Cancelar
          </b-button>
          <b-button
            variant="info"
            :disabled="!changed || saving"
            :title="changed ? '' : 'No hay cambios'"
            v-b-tooltip.hover=""
            @click="save"
          >
            <span v-if="saving"><b-spinner label="Spinning"></b-spinner></span>
            <span v-else>
              <b-icon-cloud-upload></b-icon-cloud-upload>Guardar
            </span>
          </b-button>
        </b-button-group>
        <b-alert
          v-model="saved"
          :variant="error ? 'danger' : 'success'"
          dismissible
        >
          {{ error ? "Error: " + error : "Cambios guardados con éxito" }}
        </b-alert>
      </b-col>
    </b-row>
    <br />
    <b-row>
      <b-col md="6"
        ><b-form-group label="Tabla de tallas" label-for="SizeConvertion">
          <b-input id="SizeConvertion" v-model="actualSizeConvertion"></b-input>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import UPDATE_SIZE_CHART from "../../graphql/SizeChart/UpdateSizeChart.gql";

export default {
  name: "SizeChartTabsRocketInternet",
  props: {
    integrationConfig: Object,
    sizeChart: Object
  },
  data() {
    const original = this.sizeChart.homologations[
      this.integrationConfig.fullLabel
    ];

    return {
      changed: false,
      saving: false,
      saved: false,
      error: null,
      actualSizeConvertion: original ? original.SizeConvertion : "",
      oldSizeConvertion: original ? original.SizeConvertion : ""
    };
  },
  methods: {
    /**
     * Cancela formulario
     */
    cancel() {
      if (this.changed) {
        this.$swal
          .fire({
            title: "Cancelar",
            text: "Si cancelas, perderás tus cambios. ¿Estás seguro?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "No"
          })
          .then(result => {
            if (result.value) {
              this.$router.push("/size_charts/");
            }
          });
      } else {
        this.$router.push("/size_charts/");
      }
    },
    /**
     * Guarda codigo de guia de tallas
     */
    save() {
      this.saving = true;
      const updatedHomologations = this.calculateUpdatedHomologations();
      if (updatedHomologations) {
        this.updateSizeChart(updatedHomologations);
      }
      this.changed = false;
    },
    /**
     * calcula homologaciones que se enviaran a guardar a centry
     * @returns {Object}
     */
    calculateUpdatedHomologations() {
      let original = this.sizeChart.homologations[
        this.integrationConfig.fullLabel
      ];
      if (!original) original = {};

      const homologations = {};
      homologations[this.integrationConfig.fullLabel] = {};

      let changed = false;
      if (original.SizeConvertion !== this.actualSizeConvertion) {
        changed = true;
        homologations[this.integrationConfig.fullLabel][
          "SizeConvertion"
        ] = this.actualSizeConvertion;
      }

      if (changed) return homologations;
      return null;
    },
    /**
     * Actualiza GDT
     * @param {*} updatedHomologations
     */
    updateSizeChart(updatedHomologations) {
      let sizeChartInput = {
        homologations: updatedHomologations
      };
      this.$apollo
        .mutate({
          mutation: UPDATE_SIZE_CHART,
          variables: { id: this.sizeChart.id, patch: sizeChartInput }
        })
        .then(response => {
          this.setSaved(null);
          let sizeChart = response.data.updateSizeChart.sizeChart;
          this.$emit("change", sizeChart);
        })
        .catch(error => {
          this.setSaved(error);
        });
    },
    /**
     * Setea estado de error en la peticion de actualziar GDT
     * @param {String} error
     */
    setSaved(error) {
      this.error = error;
      this.saving = false;
      this.saved = true;
      this.oldSizeConvertion = this.actualSizeConvertion;
    }
  },
  watch: {
    actualSizeConvertion(newValue) {
      this.changed = newValue != this.oldSizeConvertion;
    }
  }
};
</script>
