<template>
  <div>
    <b-row align-v="center">
      <b-col md="6">
        <b-form-group label="Seleccione un archivo">
          <b-form-file
            placeholder="Elige o arrastra una planilla acá..."
            drop-placeholder="Suelta el archivo aquí..."
            v-model="file"
            accept=".xlsx, .csv, .ods"
            browse-text="Seleccionar"
          ></b-form-file>
          <small>Límite: 10.000 filas</small>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <xlsx-read :file="file" :options="{ dateNF: 'FMT 22' }">
          <template #default="{loading}">
            <span v-if="loading">
              <b-spinner label="Spinning"></b-spinner> Cargando planilla. Esto
              puede tardar unos minutos
            </span>
            <xlsx-sheets>
              <template #default="{sheets}">
                <b-form-group
                  v-if="file && sheets && !loading"
                  label="Hoja de trabajo"
                >
                  <v-select
                    placeholder="Seleccione una hoja"
                    v-model="selectedSheet"
                    :options="sheets"
                  ></v-select>
                </b-form-group>
              </template>
            </xlsx-sheets>
            <xlsx-json
              :sheet="selectedSheet"
              :options="{ header: 1, defval: null }"
              @parsed="setData"
            ></xlsx-json>
          </template>
        </xlsx-read>
      </b-col>
      <b-col md="3">
        <b-button
          :clearable="false"
          variant="primary"
          v-if="!!file"
          :disabled="!selectedSheet"
          @click="emitReady"
          >Continuar</b-button
        >
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  name: "BulkUploadTabsLoadFile",
  data() {
    return {
      file: null,
      selectedSheet: null,
      data: null
    };
  },
  methods: {
    setData(data) {
      this.data = data;
    },
    emitReady() {
      this.$emit(
        "ready",
        Object.assign(
          { data: this.data },
          {
            file: this.file,
            selectedSheet: this.selectedSheet
          }
        )
      );
    }
  }
};
</script>
