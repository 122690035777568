<template>
  <b-row>
    <b-col md="4">
      <base-category-tree
        :categories="categories"
        children-path="children"
        id-path="id"
        @category="emitCategory"
      >
      </base-category-tree>
    </b-col>
    <b-col md="8">
      <b-table-simple responsive>
        <b-thead>
          <b-tr>
            <b-th class="col-md-1">Id</b-th>
            <b-th class="col-md-3">Nombre</b-th>
            <b-th class="col-md-6">Ruta</b-th>
            <b-th class="col-md-2">Acciones</b-th>
          </b-tr>
        </b-thead>
        <b-tbody v-if="category">
          <b-tr>
            <b-th>{{ category.id }}</b-th>
            <b-th>{{ category.name }}</b-th>
            <b-th>{{ category.path.join(" / ") }}</b-th>
            <b-th>
              <b-dropdown variant="white" no-caret="" offset="-110">
                <template v-slot:button-content>
                  <b-icon-three-dots-vertical></b-icon-three-dots-vertical>
                </template>
                <b-dropdown-item
                  @click="emitChangePage('spectificationsGroupsMaintainer')"
                >
                  Detalles grupos de especificaciones
                </b-dropdown-item>
                <b-dropdown-item
                  @click="emitChangePage('specificationsMaintainer')"
                >
                  Detalles especificaciones
                </b-dropdown-item>
              </b-dropdown>
              <br />
            </b-th>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-col>
  </b-row>
</template>

<script>
import BaseCategoryTree from "@/components/BaseCategoryTree";

export default {
  name: "VtexSpecificationsMaintainerCategorySelector",
  components: {
    BaseCategoryTree
  },
  props: {
    category: {
      required: true,
      validator: prop => {
        let isNull = prop === null;
        let isObject = typeof prop === "object";

        if (!isNull && !isObject) return false;
        if (isNull) return true;
        if (isObject) {
          return (
            Object.hasOwnProperty.call(prop, "id") &&
            Object.hasOwnProperty.call(prop, "name")
          );
        }
      }
    },
    categories: {
      required: true,
      type: Array,
      default: () => []
    }
  },
  data() {
    return {};
  },
  methods: {
    /**
     * Emite el evento 'category' informando la categoria seleccionada.
     * @param {Object} category
     */
    emitCategory(category) {
      this.$emit("category", category);
    },
    /**
     * Emite el evento 'changePage' informando que sección de la página mostrar.
     * @param {String} page
     */
    emitChangePage(page) {
      this.$emit("changePage", page);
    }
  }
};
</script>
