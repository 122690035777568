<template>
  <div>
    <b-row>
      <b-col>
        <b-button variant="outline-info" @click="createVtexCategory">
          <b-icon-plus></b-icon-plus> Crear
        </b-button>
      </b-col>
    </b-row>
    <br />
    <b-row>
      <b-col md="12">
        <vtex-categories-maintainer-table
          :vtexIntegrationConfigId="vtexIntegrationConfigId"
          @emitCategories="emitCategories"
        >
        </vtex-categories-maintainer-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import VtexCategoriesMaintainerTable from "@/components/VtexMaintainers/Categories/VtexCategoriesMaintainerTable";

export default {
  name: "VtexCategoriesMaintainerTab",
  components: {
    VtexCategoriesMaintainerTable
  },
  props: {
    vtexIntegrationConfigId: String
  },
  data() {
    return {};
  },
  methods: {
    /**
     * Redirecciona a la vista de creación de una nueva categoria
     */
    createVtexCategory() {
      this.$router.push({
        name: "VtexCategoriesMaintainerNew",
        params: {
          integrationConfigId: this.vtexIntegrationConfigId
        }
      });
    },
    /**
     * Emite el evento 'setCategories' informando las categorias en el
     * listado de categorisa de la tabla
     * @param {Object} categories
     */
    emitCategories(categories) {
      this.$emit("setCategories", categories);
    }
  }
};
</script>
