<template>
  <div>
    <h4>
      {{ title }}
    </h4>
    <hr />
    <b-row align-h="start" no-gutters>
      <div v-for="option in options" :key="option.value" class="mr-5">
        <b-form-checkbox
          v-model="selected"
          :value="option.value"
          @change="changeConfigButtons(option.value)"
        >
          {{ option.text }}
        </b-form-checkbox>
      </div>
    </b-row>

    <br /><br />
  </div>
</template>
<script>
export default {
  name: "BaseLocalStorageOptions",
  props: {
    keyLabel: {
      type: String,
      default: "ConfigButtons"
    },
    configButtons: {
      type: Object,
      default() {
        return {};
      }
    },
    options: {
      type: Array,
      default() {
        return [];
      }
    },
    title: {
      type: String,
      default: "Modificar campos"
    }
  },
  data() {
    return {
      selected: []
    };
  },
  computed: {
    copyConfig() {
      return this.$dup(this.configButtons);
    }
  },
  mounted() {
    this.setSelected(this.copyConfig);
  },
  methods: {
    /**
     * Añade a la lista selected las llaves
     * del objeto que tienen valor true.
     * @method
     * @param {object} val - Objeto con las configuraciones.
     */
    setSelected(val) {
      this.selected = [];
      Object.keys(val).forEach(objectKey => {
        if (val[objectKey]) {
          this.selected.push(objectKey);
        }
      });
    },
    /**
     * Actualiza el valor de la key ingresada
     * y actualiza el valor del localStorage
     * y se envia un evento indicando la llave que cambio
     * @method
     * @param {string} key - Tipo de boton que cambio de estado.
     */
    changeConfigButtons(key) {
      this.copyConfig[key] = !this.copyConfig[key];
      localStorage[this.keyLabel] = JSON.stringify(this.copyConfig);
      this.$emit("change", key);
    }
  },
  watch: {
    copyConfig(val) {
      this.setSelected(val);
    }
  }
};
</script>
