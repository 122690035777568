<template>
  <div>
    <base-alert-with-count-down
      v-model="showAlert"
      :alert-variant="alertVariant"
    >
      {{ alertMessage }}
    </base-alert-with-count-down>
    <ApolloQuery
      :query="require('../../graphql/SizeChart/AllSizeCharts.gql')"
      :fetchPolicy="'cache-and-network'"
      clientId="apolloClientCached"
      :variables="{
        name: filters.name
      }"
    >
      <template slot-scope="{ result: { loading, error, data }, query }">
        <b-table-simple
          v-if="data && data.allSizeCharts && data.allSizeCharts.edges.length"
        >
          <b-thead>
            <b-tr>
              <b-th>Nombre</b-th>
              <b-th> </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr
              v-for="sizeChart of data.allSizeCharts.edges"
              :key="sizeChart.node.id"
            >
              <b-td> {{ sizeChart.node.name }}</b-td>
              <b-td>
                <b-row align-h="end" no-gutters>
                  <b-button
                    size="lg"
                    variant="outlined"
                    class="p-0 focus-btn"
                    v-b-tooltip.hover="'Editar Guía de Tallas'"
                    :to="'/size_chart/' + sizeChart.node.id + '/edit'"
                  >
                    <b-icon stacked icon="pencil" scale="0.60"></b-icon>
                  </b-button>
                  <ApolloMutation
                    :mutation="
                      require('../../graphql/SizeChart/DeleteSizeChart.gql')
                    "
                    :variables="{ id: sizeChart.node.id }"
                    fetchPolicy="no-cache-and-network"
                    clientId="apolloClientCached"
                    :refetchQueries="
                      () => [
                        {
                          query: require('../../graphql/SizeChart/AllSizeCharts.gql'),
                          variables: {
                            name: filters.name
                          }
                        }
                      ]
                    "
                    @done="onDone"
                  >
                    <template slot-scope="{ mutate }">
                      <b-button
                        @click="deleteIfAccept(sizeChart.node, mutate)"
                        size="lg"
                        variant="outlined"
                        class="p-0 focus-btn"
                        v-b-tooltip.hover="'Eliminar Guía de Tallas'"
                      >
                        <b-icon stacked icon="x" scale="0.75"></b-icon>
                      </b-button>
                    </template>
                  </ApolloMutation>
                </b-row>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
        <div v-else>
          No se encontraron registros.
        </div>
        <b-spinner v-if="loading" label="Spinning"></b-spinner>
        <b-alert v-if="error" show="" variant="danger">
          Ha ocurrido un error
        </b-alert>
        <b-button
          v-if="
            data &&
              data.allSizeCharts &&
              data.allSizeCharts.pageInfo.hasNextPage
          "
          class="m-2 float-right"
          @click="seeMore(query, data.allSizeCharts.pageInfo.endCursor)"
        >
          Ver más
        </b-button>
        <b-spinner
          v-if="seeMoreLoading"
          label="Spinning"
          class="m-2 float-right"
        ></b-spinner>
      </template>
    </ApolloQuery>
  </div>
</template>

<script>
import { mapState } from "vuex";
import BaseAlertWithCountDown from "../Base/BaseAlertWithCountDown.vue";

export default {
  name: "SizeCharts",
  components: {
    BaseAlertWithCountDown
  },
  data() {
    return {
      seeMoreLoading: false,
      showAlert: false,
      errorDelete: false,
      errorMsg: ""
    };
  },
  computed: {
    ...mapState(["filters"]),
    alertVariant() {
      return this.errorDelete ? "danger" : "success";
    },
    alertMessage() {
      if (!this.errorDelete) {
        return "La Guía de Tallas ha sido eliminada correctamente";
      } else {
        return `No fue posible eliminar la Guía de Tallas. ${this.errorMsg}`;
      }
    }
  },
  methods: {
    /**
     * Realiza consulta para obtener siguiente pagina de elementos
     * @param {Object} query
     * @param {String} cursor
     */
    async seeMore(query, cursor) {
      this.seeMoreLoading = true;
      await query.fetchMore({
        variables: {
          cursor: cursor,
          name: this.filters.name
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (
            !fetchMoreResult ||
            !fetchMoreResult.allSizeCharts ||
            fetchMoreResult.allSizeCharts.edges.length === 0
          ) {
            return prev;
          }
          const updated = Object.assign({}, prev);
          updated.allSizeCharts.pageInfo =
            fetchMoreResult.allSizeCharts.pageInfo;
          updated.allSizeCharts.edges.push(
            ...fetchMoreResult.allSizeCharts.edges
          );
          this.seeMoreLoading = false;
          return updated;
        }
      });
    },
    /**
     * Abre modal y maneja eliminacion de GDT en centry
     * @param {Object} sizeChart
     * @param {Function} mutate
     */
    deleteIfAccept(sizeChart, mutate) {
      this.$swal
        .fire({
          title: "Eliminar",
          text:
            'Se eliminará la tabla de tallas "' +
            sizeChart.name +
            '" permanentemente ¿Está seguro?',
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: "Si"
        })
        .then(result => {
          if (result.value) {
            mutate();
          }
        });
    },
    /**
     * Verifica si la eliminacion fue exitosa o no
     * @param {Object} response
     */
    onDone(response) {
      let data = this.$dig(response, "data", "deleteSizeChart");
      if (data.result) {
        this.errorDelete = false;
        this.errorMsg = null;
      } else {
        this.errorDelete = true;
        this.errorMsg = data.error;
      }
      this.showAlert = true;
    }
  }
};
</script>
