<template>
  <div>
    <b-row>
      <b-col md="12">
        <ApolloQuery
          :query="require('../graphql/MagentoApiAttributeSets.gql')"
          :variables="{
            ic_magento_id: this.$route.params.integration_config_id
          }"
        >
          <template slot-scope="{ result: { data } }">
            <b-list-group
              v-for="(as, index) of getAttributeSets(data)"
              :key="index"
            >
              <b-list-group-item
                :to="{
                  name: 'MagentoAttributes',
                  params: {
                    integration_config_id: $route.params.integration_config_id,
                    attribute_set_id: as.id,
                    attribute_set_name: as.name
                  }
                }"
                class="flex-column align-items-start"
              >
                <div class="d-flex w-100 justify-content-between">
                  <h5 class="mb-1">
                    {{ as.name }}
                  </h5>
                </div>
              </b-list-group-item>
            </b-list-group>
          </template>
        </ApolloQuery>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "MagentoComp",
  components: {},
  methods: {
    getAttributeSets(data) {
      if (!data || !data.magentoApiAttributeSets) {
        return [];
      }
      return data.magentoApiAttributeSets;
    }
  }
};
</script>

<style scoped></style>
