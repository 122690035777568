<template>
  <div>
    <h4 class="font-weight-bold m-3">Detalles producto</h4>
    <b-row>
      <b-col md="4">
        <b-form-group
          label="ID"
          :label-for="'ID' + integrationConfig.fullLabel"
        >
          <b-form-input
            v-model="values.id"
            :id="'ID' + integrationConfig.fullLabel"
            @input="emitChange"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group
          label="SKU"
          :label-for="'SKU' + integrationConfig.fullLabel"
        >
          <b-form-input
            v-model="values.sku"
            :id="'SKU' + integrationConfig.fullLabel"
            @input="emitChange"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group
          label="Título"
          :label-for="'name' + integrationConfig.fullLabel"
        >
          <b-form-input
            v-model="values.name"
            :id="'name' + integrationConfig.fullLabel"
            @input="emitChange"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="4">
        <b-form-group label="Estado">
          <base-boolean-selector
            v-model="values.status"
            :allow-null="true"
            default-text="Como en la configuración general"
            @change="emitChange"
          />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group label="Familia">
          <b-spinner label="Spinning" v-if="loadingFamilies" />
          <v-select
            v-model="values.familyId"
            :options="families"
            :reduce="elem => elem.value"
            @input="changeFamily"
            :disabled="!!values.id"
            v-else
          />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group label="Categoría">
          <b-spinner label="Spinning" v-if="loadingCategories" />
          <v-select
            v-else
            :disabled="!categories.length"
            v-model="values.category"
            :options="categories"
            :reduce="elem => elem.value"
            @input="emitChange"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="4">
        <b-form-group label="Sincronización normal">
          <v-select
            v-model="values.sync"
            :options="syncOptions"
            :reduce="elem => elem.value"
            @input="emitChange"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3">
        <b-form-group
          label="Precio"
          :label-for="'Precio' + integrationConfig.fullLabel"
        >
          <b-form-input
            v-model="values.price"
            :id="'Precio' + integrationConfig.fullLabel"
            @input="emitChange"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group
          label="Precio de oferta"
          :label-for="'offerPrice' + integrationConfig.fullLabel"
        >
          <b-form-input
            v-model="values.offerPrice"
            :id="'offerPrice' + integrationConfig.fullLabel"
            @input="emitChange"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group label="Inicio oferta" label-for="showFrom">
          <base-date-time
            v-model="values.showFrom"
            type="datetime"
            @change="emitChange"
          ></base-date-time>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group label="Término oferta" label-for="showTo">
          <base-date-time
            v-model="values.showTo"
            type="datetime"
            @change="emitChange"
          ></base-date-time>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import BaseBooleanSelector from "../../BaseBooleanSelector.vue";
import BaseDateTime from "../../BaseDateTime.vue";
import ALL_FAMILIES from "../../../graphql/Paris/AllFamilies.gql";
import ALL_CATEGORIES from "../../../graphql/Paris/AllCategories.gql";

export default {
  name: "ProductEditTabsParisBaseForm",
  model: {
    prop: "_values",
    event: "change"
  },
  components: {
    BaseBooleanSelector,
    BaseDateTime
  },
  props: {
    _values: Object,
    integrationConfig: Object,
    familyId: String,
    loadingFamilyId: Boolean
  },
  data() {
    return {
      values: { ...this._values },
      loadingFamilies: true,
      loadingCategories: true,
      families: [],
      categories: [],
      syncOptions: [
        { value: "enabled", label: "Habilitada" },
        { value: "disabled", label: "Deshabilitada" }
      ]
    };
  },
  mounted() {
    this.getFamilies();
    if (!this.loadingFamilyId) {
      this.getCategories();
    }
  },
  methods: {
    /**
     * Indica el ID de la familia seleccionada, dandole prioridad
     * a la seleccionada en separador.
     * @return {String}
     */
    selectedFamilyId() {
      if (this.values.familyId) {
        return this.values.familyId;
      }
      return this.familyId;
    },
    /**
     * Emite el cambio de la familia: evento "family"
     * Además, emite change y gatilla una nueva descarga de categorías
     */
    changeFamily() {
      this.$emit("family", this.values.familyId);
      this.emitChange();
      this.values.category = null;
      this.getCategories();
    },
    /**
     * Emite evento "change"
     */
    emitChange() {
      this.$emit("change", this.values);
    },
    /**
     * Obtiene las categorías a partir de la familia,
     * a través de una query, y las almacena en variable categories
     */
    getCategories() {
      this.loadingCategories = true;
      if (!this.selectedFamilyId()) {
        this.categories = [];
        this.loadingCategories = false;
        return;
      }
      this.$apollo
        .query({
          query: ALL_CATEGORIES,
          variables: {
            integrationConfigId: this.integrationConfig.id,
            familyId: this.selectedFamilyId()
          }
        })
        .then(({ data }) => {
          this.categories = this.$dig(data, "allParisCategories").map(elem => {
            return {
              value: elem.id,
              label: elem.path
            };
          });
          this.loadingCategories = false;
        });
    },
    /**
     * Obtiene todo el listado de familias a través de una query, y las
     * almacena en la variable "families"
     */
    getFamilies() {
      this.$apollo
        .query({
          query: ALL_FAMILIES,
          variables: {
            integrationConfigId: this.integrationConfig.id
          }
        })
        .then(({ data }) => {
          this.families = this.$dig(data, "allParisFamilies").map(elem => {
            return {
              value: elem.id,
              label: elem.name
            };
          });
          this.families.push({
            value: "d744ceb0-6236-4e46-aa63-42597a855a40",
            label: "Familia Centry"
          });
          this.loadingFamilies = false;
        });
    }
  },
  watch: {
    loadingFamilyId(newVal) {
      if (!newVal) {
        this.getCategories();
      }
    }
  }
};
</script>
