<template>
  <div>
    <product-edit-tabs :product="product"></product-edit-tabs>
  </div>
</template>
<script>
import ProductEditTabs from "../components/ProductEditTabs.vue";
export default {
  name: "ProductEdit",
  props: {
    product: Object
  },
  components: {
    ProductEditTabs
  }
};
</script>
