<template>
  <div producto="product-for-packs-centry">
    <vue-bootstrap4-table
      :rows="rows"
      :columns="columns"
      :actions="actions"
      :config="config"
      ref="myvuetable"
    >
      <template slot="paginataion-previous-button">
        Anterior
      </template>
      <template slot="paginataion-next-button">
        Siguiente
      </template>
      <template slot="pagination-info" slot-scope="props">
        | Total: {{ props.originalRowsLength }} <br />
        | Filtrado: {{ props.filteredRowsLength }} <br />
      </template>
      <template slot="selected-rows-info" slot-scope="props">
        Seleccionados: {{ props.selectedItemsCount }}
      </template>

      <template slot="sort-asc-icon">
        <b-icon icon="arrow-up"></b-icon>
      </template>
      <template slot="sort-desc-icon">
        <b-icon icon="arrow-down"></b-icon>
      </template>
      <template slot="no-sort-icon">
        <b-icon icon="arrow-up"></b-icon>
        <b-icon icon="arrow-down"></b-icon>
      </template>
    </vue-bootstrap4-table>
    <template>
      <b-button
        variant="info"
        :disabled="selected_items.length == 0"
        @click="addItems()"
        class="float-right"
      >
        Agregar
      </b-button>
    </template>
  </div>
</template>

<script>
import "lodash";
import "bootstrap";
import VueBootstrap4Table from "vue-bootstrap4-table";
import { mapMutations } from "vuex";

export default {
  name: "ProductsForPacksCentryTable",
  data: function() {
    return {
      selected_items: [],
      columns: [
        {
          label: "Sku",
          name: "sku",
          filter: {
            type: "simple",
            placeholder: "Sku..."
          },
          sort: true
        },
        {
          label: "Producto",
          name: "producto",
          filter: {
            type: "simple",
            placeholder: "Producto..."
          },
          sort: true
        },
        {
          label: "Marca",
          name: "marca",
          filter: {
            type: "simple",
            placeholder: "Marca..."
          },
          sort: true
        },
        {
          label: "Categoria",
          name: "categoria",
          filter: {
            type: "simple",
            placeholder: "Categoría..."
          },
          sort: true
        },
        {
          label: "Precio original",
          name: "original_price",
          filter: {
            type: "simple",
            placeholder: "Precio..."
          },
          sort: true
        },
        {
          label: "Color",
          name: "color",
          filter: {
            type: "simple",
            placeholder: "Color"
          },
          sort: true
        },
        {
          label: "Size",
          name: "size",
          filter: {
            type: "simple",
            placeholder: "Talla"
          },
          sort: true
        }
      ],
      actions: [],
      config: {
        pagination: true,
        pagination_info: true,
        num_of_visibile_pagination_buttons: 7,
        per_page: 5,
        checkbox_rows: true,
        highlight_row_hover: true,
        rows_selectable: true,
        multi_column_sort: false,
        highlight_row_hover_color: "lightblue",
        card_mode: false,
        selected_rows_info: true,
        per_page_options: [5, 10, 20, 30],
        global_search: { visibility: false },
        show_reset_button: false,
        show_refresh_button: false,

        classes: {
          table: "table-bordered table-striped"
        }
      }
    };
  },
  components: {
    VueBootstrap4Table
  },
  props: {
    products: {
      type: Array,
      default: null
    },
    selectedProducts: Array
  },
  mounted() {
    this.$watch(
      () => {
        return this.$refs.myvuetable.selected_items;
      },
      val => {
        this.selected_items = val;
      }
    );
  },
  computed: {
    rows() {
      return this.products ? this.products : [];
    }
  },
  methods: {
    ...mapMutations(["setSelectedProducts"]),
    addItems() {
      this.$emit("add-selected-products", this.selected_items);
      this.$refs.myvuetable.unSelectAllItems();
    }
  },
  watch: {}
};
</script>

<style>
.table td {
  height: 0;
}
.table-active,
.table-active > td,
.table-active > th {
  background-color: rgba(0, 0, 0, 0);
}
</style>
