<template>
  <div>
    <h4 class="font-weight-bold m-3">Atributos de categoría</h4>
    <b-spinner v-if="loading || loadingAttributes" label="Spinning" />
    <b-row v-else>
      <b-col md="3" v-for="attribute of attributes" :key="attribute.id">
        <b-form-group
          :label="attributeName(attribute)"
          :label-for="attribute.id + integrationConfig.fullLabel"
        >
          <v-select
            v-if="attribute.attributeOptions.length"
            v-model="values[attribute.id]"
            :options="attributeOptions(attribute)"
            :reduce="elem => elem.value"
            @input="emitChange"
          />
          <b-form-input
            v-else
            v-model="values[attribute.id]"
            :id="attribute.id + integrationConfig.fullLabel"
            @input="emitChange"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import ALL_PRODUCT_ATTRIBUTES from "../../../graphql/Paris/AllProductAttributes.gql";
export default {
  name: "ProductEditTabsParisCategoryAttributes",
  props: {
    familyId: String,
    _values: Object,
    integrationConfig: Object,
    allAttributes: Object,
    loadingAttributes: Boolean
  },
  model: {
    prop: "_values",
    event: "change"
  },
  data() {
    return {
      values: { ...this._values },
      attributes: [],
      loading: true
    };
  },
  mounted() {
    this.getAttributes();
  },
  methods: {
    /**
     * Emite evento "change"
     */
    emitChange() {
      this.$emit("change", this.values);
    },
    /**
     * Entrega el listado de opciones del atributo, formateado
     * para v-select
     * @param {Object} attribute
     * @return {Array}
     */
    attributeOptions(attribute) {
      return attribute.attributeOptions.map(option => {
        return {
          value: option.id,
          label: option.name
        };
      });
    },
    /**
     * Obtiene los atributos de producto a partir de una query, y los
     * almacena en la variable attributes
     */
    getAttributes() {
      this.loading = true;
      if (!this.familyId) {
        this.attributes = [];
        this.loading = false;
        return;
      }
      this.$apollo
        .query({
          query: ALL_PRODUCT_ATTRIBUTES,
          variables: {
            integrationConfigId: this.integrationConfig.id,
            familyId: this.familyId
          }
        })
        .then(({ data }) => {
          this.attributes = this.$dig(data, "allParisProductAttributes");
          this.loading = false;
        });
    },
    /**
     * Entrega el nombre del atributo, y le agrega el '*' si es obligatorio
     * @param {Object}
     * @return {String}
     */
    attributeName(attribute) {
      let name = attribute.name;
      if (this.allAttributes[attribute.id].attributeValidation.isRequired) {
        name += " *";
      }
      return name;
    }
  },
  watch: {
    familyId() {
      this.values = {};
      this.$emit("change", this.values);
      this.getAttributes();
    }
  }
};
</script>
