<template>
  <error-dashboard
    v-if="!!error || !!timeoutError"
    :error="!!error"
    :timeout-error="!!timeoutError"
    @retry="loadAll"
  ></error-dashboard>
  <div v-else>
    <b-row no-gutters align-h="between" class="mt-2">
      <h4>{{ widget.name }}</h4>
      <export-xlsx-dashboard
        v-if="!loading && !!xlsxData && !!xlsxData.length"
        :data="xlsxData"
        :widget="widget"
      ></export-xlsx-dashboard>
    </b-row>
    <b-skeleton-table :rows="10" :columns="3" v-if="loading"></b-skeleton-table>
    <simple-table-with-pagination
      v-else
      :perPage="10"
      :fields="fieldsTable"
      :items="itemsTable"
      table-class="table-hover"
      :html="true"
    />
  </div>
</template>

<script>
import SimpleTableWithPagination from "@/components/SimpleTableWithPagination.vue";
import DASHBOARD_TABLE_WIDGET from "@/graphql/DashboardTableWidget.gql";
import BaseWidget from "@/components/dashboard/BaseWidget";
import ErrorDashboard from "@/components/dashboard/ErrorDashboard";
import ExportXlsxDashboard from "@/components/dashboard/ExportXlsxDashboard";
export default {
  name: "DashboardTable",
  extends: BaseWidget,
  components: {
    ExportXlsxDashboard,
    SimpleTableWithPagination,
    ErrorDashboard
  },
  computed: {
    fieldsTable() {
      if (!this.infoWidget) return [];
      const fields = [];
      const widgets = this.orderWidgets(this.infoWidget.columnTableWidgets);
      widgets.map(widget => {
        const container = {};
        container.key = widget.name;
        container.label = widget.name;
        fields[widget.position - 1] = container;
      });
      return fields;
    },
    itemsTable() {
      if (!this.data || !this.infoWidget) return [];
      const data = this.unsortedData;
      const sorter = this.infoWidget.sortColumn;
      if (sorter) {
        const asc = this.infoWidget.sort;
        data.sort(function(a, b) {
          const dataA = !isNaN(a[sorter]) ? parseFloat(a[sorter]) : a[sorter];
          const dataB = !isNaN(b[sorter]) ? parseFloat(b[sorter]) : b[sorter];
          if (asc === "asc") {
            return dataA < dataB ? -1 : dataA > dataB ? 1 : 0;
          } else {
            return dataA > dataB ? -1 : dataA < dataB ? 1 : 0;
          }
        });
      }
      return data;
    },
    unsortedData() {
      const items = [];
      this.groupedData.forEach(array => {
        const row = {};
        this.infoWidget.columnTableWidgets.forEach(column => {
          const f = eval(column.aggregationFormula);
          row[column.name] = f(array);
        });
        items.push(row);
      });
      return items;
    },
    groupedData() {
      const separatorColumn = this.$toCamelCase(
        this.infoWidget.separatorColumn
      );
      const separated = {};
      this.data.forEach(elem => {
        if (!separated[elem[separatorColumn]])
          separated[elem[separatorColumn]] = [];
        separated[elem[separatorColumn]].push(elem);
      });
      return Object.values(separated);
    },
    xlsxData() {
      return this.itemsTable;
    }
  },
  methods: {
    getWidget() {
      return this.$apollo
        .query({
          query: DASHBOARD_TABLE_WIDGET,
          variables: {
            id: this.widget.id
          }
        })
        .then(result => {
          this.infoWidget = result.data.dashboardTableWidget;
        });
    },
    orderWidgets(widgets) {
      let sortArray = widgets.map(x => this.$dup(x));
      return sortArray.sort((a, b) => a.position - b.position);
    }
  }
};
</script>
