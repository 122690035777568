<template>
  <!-- Spinner -->
  <b-skeleton-table
    v-if="isLoading"
    :rows="10"
    :columns="fields.length"
    :table-props="{ bordered: true, striped: true }"
  ></b-skeleton-table>
  <!-- Mensaje de Error en caso de que falle la Query -->
  <div v-else-if="error">
    <p>
      {{ errorMessage }}
      Reintentar <b-link @click="awaitForDataQuery">aquí.</b-link>
    </p>
  </div>
  <!-- Tabla de Historial de Modificaciones -->
  <div v-else>
    <b-button
      class="m-2 float-right"
      size="sm"
      @click="restartDataQuery"
      :title="titleButtonRestart"
    >
      <b-icon-arrow-repeat />
    </b-button>
    <b-table
      :items="allData"
      :fields="fields"
      responsive="sm"
      style="background-color: #f7f8f8"
    >
      <template #cell(show_details)="row">
        <ul class="table-actions">
          <li>
            <b-link
              size="sm"
              @click="row.toggleDetails"
              class="mr-2"
              role="button"
            >
              <b-icon-eye-fill></b-icon-eye-fill>
            </b-link>
          </li>
        </ul>
      </template>

      <template #row-details="row">
        <slot :row="row"></slot>
      </template>
    </b-table>
    <b-spinner
      v-if="isLoadingMore"
      label="Spinning"
      class="m-2 float-right"
    ></b-spinner>
    <div v-else>
      <b-button v-if="hasNextPage()" class="m-2 float-right" @click="seeMore()">
        Ver más
      </b-button>
      <b-alert v-else show variant="light" class="text-center">
        No hay más datos para mostrar.
      </b-alert>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseTableRowWithDetails",
  components: {},
  props: {
    fields: {
      type: Array,
      default() {
        return [];
      }
    },
    sub_fields: {
      type: Array,
      default() {
        return [];
      }
    },
    extractData: {
      type: Function,
      required: true
    },
    extractDataRow: {
      type: Function,
      required: true
    },
    query: {
      type: Object
    },
    queryVariables: {
      type: Function
    },
    errorMessage: {
      type: String,
      default: ""
    },
    titleButtonRestart: {
      type: String,
      default: ""
    },
    offset: {
      type: [Number, String],
      required: true
    },
    baseOffset: {
      type: [Number, String],
      required: true
    },
    limit: {
      type: Number,
      default: 10
    },
    getCursorQuery: {
      type: Function,
      default: () => {
        return false;
      }
    }
  },
  data() {
    return {
      currentQueryData: [],
      allData: [],
      error: false,
      isLoading: true,
      isLoadingMore: false
    };
  },
  async created() {
    await this.seeFirst();
  },
  methods: {
    /**
     * Se encarga de pedir la primera consulta de datos
     */
    async seeFirst() {
      this.isLoading = true;
      await this.awaitForDataQuery();
    },
    /**
     * Se encarga de realizar consulta de de datos
     */
    async awaitForDataQuery() {
      this.error = false;
      await this.$apollo
        .query({
          query: this.query,
          variables: this.queryVariables(),
          fetchPolicy: "no-cache"
        })
        .then(({ data }) => {
          if (data && this.extractData(data)) {
            let rows = this.extractData(data).map(x => this.extractDataRow(x));
            this.currentQueryData = rows;
            this.allData = this.allData.concat(rows);
            let cursor = this.getCursorQuery(data);
            if (cursor) {
              this.$emit("setOffset", cursor);
            } else {
              this.$emit("setOffset", this.allData.length);
            }
          }
          this.isLoading = false;
          this.isLoadingMore = false;
        })
        .catch(err => {
          console.error(err);
          console.log(this.query, this.queryVariables());
          this.error = true;
          this.isLoading = false;
          this.isLoadingMore = false;
        });
    },
    /**
     * Verifica si es posible continuar con el paginamiento
     * Si la ultima consulta fue de menos que "limit" elementos no se puede continuar
     */
    hasNextPage() {
      return this.currentQueryData.length === this.limit;
    },
    /**
     * Realiza la siguiente consulta de datos
     * se aumenta el offset para tal proposito
     */
    async seeMore() {
      this.isLoadingMore = true;
      await this.awaitForDataQuery();
    },
    /**
     * Reinicia la consulta de datos
     * para esto se borra el listado actual y se reinicia offset a 0
     */
    async restartDataQuery() {
      this.$emit("setOffset", this.baseOffset);
      this.currentQueryData = [];
      this.allData = [];
      await this.seeFirst();
    }
  }
};
</script>

<style scoped>
.table-actions {
  list-style: none;
  padding-left: 0;
}
.table-actions li {
  display: inline-block;
  padding: 0px 7px;
}
.table-actions li a {
  color: #aab1b5;
  display: inline-block;
  background-color: white;
  width: 34px;
  height: 34px;
  text-align: center;
  border-radius: 50%;
  font-size: 14px;
  border: 2px solid #e0e2e4;
  padding: 5px 0;
  -webkit-transition: color 0.1s ease;
  -o-transition: color 0.1s ease;
  transition: color 0.1s ease;
  -webkit-transition: border-color 0.1s ease;
  -o-transition: border-color 0.1s ease;
  transition: border-color 0.1s ease;
}
.table-actions li a:hover {
  border-color: #aab1b5;
  color: black;
}
</style>
