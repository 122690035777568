<template>
  <div>
    <b-alert show v-if="!categoryId">Seleccione una categoría</b-alert>
    <b-spinner label="Spinning" v-else-if="loading"></b-spinner>
    <b-alert show v-else-if="!highlights.length"
      >Esta categoría no tiene registro de productos más vendidos</b-alert
    >
    <b-row v-else>
      <mercado-libre-highlights-card
        v-for="elem of highlights"
        :key="elem.id"
        :object-type="elem.objectType"
        :id="elem.id"
        :position="elem.position"
      >
      </mercado-libre-highlights-card>
    </b-row>
  </div>
</template>
<script>
import MERCADO_LIBRE_HIGHLIGHT_BY_CATEGORY from "../graphql/MercadoLibreHighlightsByCategory.gql";
import MercadoLibreHighlightsCard from "./MercadoLibreHighlightsCard";
export default {
  name: "MercadoLibreCategoryHighlightsTable",
  components: { MercadoLibreHighlightsCard },
  props: {
    categoryId: String,
    siteId: String
  },
  mounted() {
    this.$apollo
      .query({
        query: MERCADO_LIBRE_HIGHLIGHT_BY_CATEGORY,
        variables: {
          categoryId: this.categoryId,
          siteId: this.siteId.toUpperCase()
        }
      })
      .then(({ data }) => {
        this.highlights = this.$dig(data, "mercadoLibreHighlightByCategory");
        this.loading = false;
      });
  },
  data() {
    return {
      loading: true,
      highlights: []
    };
  }
};
</script>
