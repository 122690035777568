<template>
  <b-button
    :variant="variant"
    @click="doAction"
    :disabled="disabled"
    :title="title"
  >
    <b-spinner label="Spinning" v-if="loading"></b-spinner>
    {{ text }}
  </b-button>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "MassiveActionsButton",
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    },
    variant: {
      type: String,
      default: "outline-info"
    },
    text: String,
    action: {
      type: Function,
      default() {
        return () => {};
      }
    },
    dialogTitle: {
      type: String,
      default: "Ejecutar"
    },
    dialogText: {
      type: String,
      default: "¿Está seguro?"
    }
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    ...mapState(["selectedHomologations"]),
    selectedIds() {
      return Object.keys(this.selectedHomologations).filter(id => {
        return this.selectedHomologations[id];
      });
    }
  },
  methods: {
    /**
     * Ejecuta la función entregada
     * como prop "action", le pasa como
     * argumentos el listado de ids de homologaciones seleccionados,
     * almacenados en vuex
     */
    async doAction() {
      this.loading = true;
      this.$swal
        .fire({
          title: this.dialogTitle,
          text: this.dialogText,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si",
          cancelButtonText: "No"
        })
        .then(result => {
          if (result.value) {
            this.action(this.selectedIds)
              .then(() => {
                this.loading = false;
              })
              .catch(error => {
                this.$swal.fire({
                  title: "Ha ocurrido un error",
                  text: error,
                  icon: "danger"
                });
              });
          }
        });
    }
  }
};
</script>
