<template>
  <promotion-detail-page
    v-if="loadingConditions"
    :promotion="promotion"
    :integrationConfig="integrationConfig"
    :title="'Edición Promoción'"
    :errorPromotion="errorPromotion"
    @errorIntegrationConfig="errorIntegrationConfig"
    :loadingCategories="loadingCategories"
    @changeLoadingCategories="changeLoadingCategories"
    :loadingProducts="loadingProducts"
    @changeLoadingProducts="changeLoadingProducts"
    :loadingCustomersCategories="loadingCustomersCategories"
    @changeLoadingCustomersCategories="changeLoadingCustomersCategories"
  ></promotion-detail-page>
  <div class="mt-3" v-else-if="errorPromotion">
    {{ msgErrorPromotion }}
    <a
      :href="centryUrl + '/conexion/jumpseller/redirect'"
      v-if="not_authorized"
    >
      link.
    </a>
  </div>
  <b-spinner v-else label="Spinning"></b-spinner>
</template>
<script>
import PromotionDetailPage from "../../components/jumpseller/promotions/PromotionDetailPage.vue";
import GET_INFO_PROMOTION from "../../graphql/integrations/jumpseller/GetPromotionInfo.gql";
import { centryUrl } from "../../main";

export default {
  name: "PromotionDetail",
  data() {
    return {
      centryUrl,
      loadingPromotion: true,
      loadingCategories: true,
      loadingProducts: true,
      loadingCustomersCategories: true,
      errorPromotion: false,
      not_authorized: false,
      promotion: { name: "", coupons: [] }
    };
  },
  computed: {
    loadingConditions() {
      return !this.loadingPromotion && !this.errorPromotion;
    }
  },
  props: {
    integrationConfig: String
  },
  components: {
    PromotionDetailPage
  },
  async created() {
    await this.awaitForPromotion();
  },
  methods: {
    /**
     * Entrega las variables para la query que permite obtener el producto
     */
    queryVariables() {
      let variables = {};
      variables.ic_id = this.integrationConfig;
      variables.promotionId = "new";
      if (this.$route.params.promotionId != "new") {
        variables.promotionId = this.$route.params.id;
      }
      return variables;
    },
    async awaitForPromotion() {
      let variables = this.queryVariables();
      if (variables.promotionId === "new") {
        this.promotion = { name: "", coupons: [] };
      } else {
        await this.$apollo
          .query({
            query: GET_INFO_PROMOTION,
            variables: variables
          })
          .then(({ data }) => {
            if (data && data.jumpsellerGetPromotion) {
              this.promotion = data.jumpsellerGetPromotion;
              this.loadingPromotion = false;
            }
          })
          .catch(error => this.diplayError(this.formatMsgError(String(error))));
      }
    },
    diplayError(msg) {
      this.errorPromotion = true;
      this.msgErrorPromotion = msg;
    },
    formatMsgError(error) {
      if (error.includes("get_integration_service")) {
        return `Integración con id ${this.integrationConfig} no existe`;
      }
      if (error.includes(" id ")) {
        return "Promoción no existe";
      }
      if (error.includes("Missing OAuth scope authorization")) {
        this.not_authorized = true;
        return "Faltan permisos de la aplicación. Vuelve a dar permisos en este ";
      }
      return String(error);
    },
    errorIntegrationConfig(error) {
      this.msgErrorPromotion = this.formatMsgError(String(error));
      this.errorPromotion = true;
    },
    changeLoadingCategories() {
      this.loadingCategories = false;
    },
    changeLoadingProducts() {
      this.loadingProducts = false;
    },
    changeLoadingCustomersCategories() {
      this.loadingCustomersCategories = false;
    }
  }
};
</script>
