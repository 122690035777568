<template>
  <div>
    <base-header title="Mensajes programados" title-link="#" :title-size="12">
      <template v-slot:actions>
        <b-button variant="outline-info" to="/admin/message_scheduled/new">
          <b-icon-plus-circle /> Crear
        </b-button>
      </template>
    </base-header>
    <b-container fluid="">
      <b-row>
        <b-col md="12">
          <messages-scheduled-list />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import BaseHeader from "@/components/BaseHeader";
import MessagesScheduledList from "@/components/Messages/Scheduled/List.vue";

export default {
  name: "MessagesScheduled",
  components: {
    BaseHeader,
    MessagesScheduledList
  }
};
</script>
