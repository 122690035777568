<template>
  <div>
    <slot name="body"></slot>
    <b-overlay :show="show" rounded="sm" no-wrap>
      <template #overlay>
        <slot name="overlay"></slot>
      </template>
    </b-overlay>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    show: {
      default: false,
      type: Boolean
    }
  }
};
</script>
