var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-tr',[_c('b-td',[_c('b-row',[_c('b-col',{staticStyle:{"display":"none"},attrs:{"md":"6"}},[_c('b-form-group',{staticStyle:{"margin-top":"18px"}},[_c('b-form-checkbox',{attrs:{"size":"sm","switch":"","disabled":""},model:{value:(_vm.promotion.enabled),callback:function ($$v) {_vm.$set(_vm.promotion, "enabled", $$v)},expression:"promotion.enabled"}})],1)],1),_c('b-col',{staticClass:"text-left",staticStyle:{"margin-left":"15px"}},[_c('b-badge',{class:_vm.promotion.status == 'active' ? 'text-success' : 'text-danger',attrs:{"pill":"","id":'activate_status_' + _vm.promotion.id,"variant":_vm.promotion.status == 'active' ? 'success' : 'danger'}},[_vm._v(".")]),(_vm.promotion.status != 'active')?[(
              _vm.promotion.beginsAt == null ||
                _vm.inDate(_vm.promotion.beginsAt, _vm.promotion.expiresAt)
            )?_c('b-tooltip',{attrs:{"target":'activate_status_' + _vm.promotion.id,"triggers":"hover"}},[_vm._v(" La promoción no está activa actualmente. ")]):_c('b-tooltip',{attrs:{"target":'activate_status_' + _vm.promotion.id,"triggers":"hover"}},[_vm._v(" "+_vm._s(_vm.formatTooltip( _vm.$options.filters.formatDate(_vm.promotion.beginsAt), _vm.$options.filters.formatDate(_vm.promotion.expiresAt) ))+" ")])]:_vm._e()],2)],1)],1),_c('b-td',[(_vm.promotion.discountTarget == 'shipping')?_c('a',{attrs:{"target":"_blank","href":_vm.jumpsellerPromotionForm()}},[_vm._v(" "+_vm._s(_vm.promotion.name)+" ")]):_c('router-link',{attrs:{"to":{
        name: 'PromotionEdit',
        params: { id: _vm.promotion.id, integration_id: _vm.ic.id }
      }}},[_vm._v(" "+_vm._s(_vm.promotion.name)+" ")])],1),_c('b-td',[_vm._v(" "+_vm._s(_vm.printCouponsInfo(_vm.promotion.coupons))+" ")]),_c('b-td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.printTimesUsed(_vm.promotion.timesUsed, _vm.promotion.maxTimesUsed))+" ")]),_c('b-td',[(_vm.promotion.cumulative)?_c('b-icon-check',{attrs:{"font-scale":"3","variant":"success"}}):_vm._e()],1),_c('b-td',{staticClass:"text-left"},[(_vm.promotion.discountAmountFix > 0)?_c('span',[_vm._v(" "+_vm._s(_vm.printAmountFix(_vm.promotion.discountAmountFix))+" ")]):(_vm.promotion.discountAmountPercent > 0)?_c('span',[_vm._v(" "+_vm._s(((_vm.promotion.discountAmountPercent) + "%"))+" ")]):_vm._e(),(_vm.promotion.discountTarget == 'shipping')?_c('b-icon-truck'):(_vm.promotion.discountTarget == 'order')?_c('b-icon-cart3'):_c('b-icon-archive')],1),_c('b-td',[_c('b-dropdown',{attrs:{"right":"","no-caret":"","variant":"white"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('b-icon-three-dots-vertical')]},proxy:true}])},[(_vm.promotion.discountTarget == 'shipping')?_c('b-dropdown-item',{attrs:{"target":"_blank","href":_vm.jumpsellerPromotionForm()}},[_vm._v(" Editar en Jumpseller "),_c('b-icon-box-arrow-up-right')],1):_c('b-dropdown-item',{attrs:{"to":{
          name: 'PromotionEdit',
          params: { id: _vm.promotion.id, integration_id: _vm.ic.id }
        }}},[_vm._v(" Editar ")]),_c('b-dropdown-item',{on:{"click":function($event){return _vm.showModal(_vm.promotion.id)}}},[_vm._v(" Eliminar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }