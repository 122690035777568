<template>
  <div>
    <b-button v-b-modal.products-filters-modal="" class="float-right">
      <b-icon-gear-fill></b-icon-gear-fill>
      Más filtros
    </b-button>
    <b-modal
      id="products-filters-modal"
      size="lg"
      @ok="processFilters"
      @cancel="revertFilters"
      @hidden="revertFilters"
    >
      <template v-slot:modal-title>
        <h4>Filtrar productos</h4>
      </template>
      <template v-slot:modal-cancel>Cancelar</template>
      <template v-slot:modal-ok>Filtrar</template>
      <b-form-group>
        <div>
          <b-row>
            <b-col md="3">
              <strong>Categorías</strong>
            </b-col>
            <b-col md="9">
              <b-form-group>
                <base-live-select
                  id="category"
                  v-model="categoriesSelectedObject"
                  placeholder="Seleccionar"
                  :clearable="false"
                  :multiple="true"
                  @search="updateCategories"
                  @change="
                    categoriesSelected = categoriesSelectedObject.map(
                      x => x.value
                    )
                  "
                ></base-live-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="3">
              <strong>Marcas</strong>
            </b-col>
            <b-col md="9">
              <b-form-group>
                <base-live-select
                  id="brand"
                  v-model="brandsSelectedObject"
                  placeholder="Seleccionar"
                  :clearable="false"
                  :multiple="true"
                  @search="updateBrands"
                  @change="
                    brandsSelected = brandsSelectedObject.map(x => x.value)
                  "
                  :min-search-characters="1"
                ></base-live-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="4">
              <b-form-checkbox
                v-model="actives"
                name="actives"
                :value="true"
                :unchecked-value="false"
                >Activos</b-form-checkbox
              >
            </b-col>
            <b-col md="4">
              <b-form-checkbox
                v-model="inactives"
                name="inactives"
                :value="true"
                :unchecked-value="false"
                >Inactivos</b-form-checkbox
              >
            </b-col>
            <b-col md="4">
              <b-form-checkbox
                v-model="noStock"
                name="noStock"
                :value="true"
                :unchecked-value="false"
                >Solo sin stock</b-form-checkbox
              >
            </b-col>
          </b-row>
        </div>
      </b-form-group>
    </b-modal>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import BaseLiveSelect from "./BaseLiveSelect";
import ALL_CATEGORIES from "../graphql/AllCategories.gql";
import ALL_BRANDS from "../graphql/AllBrands.gql";
export default {
  name: "ProductsFilters",
  data() {
    return {
      categoriesSelectedObject: null,
      categoriesSelected: [],
      brandsSelected: [],
      brandsSelectedObject: null,
      actives: true,
      inactives: true,
      noStock: false
    };
  },
  computed: {
    ...mapState(["currentUser", "filters"])
  },
  components: {
    BaseLiveSelect
  },
  methods: {
    ...mapMutations(["setFilter"]),
    async updateCategories(search, loading, setOptions) {
      loading(true);
      this.$apollo
        .query({
          query: ALL_CATEGORIES,
          variables: { companyId: this.currentUser.company.id, name: search }
        })
        .then(async result => {
          if (
            result.data &&
            result.data.allCategories &&
            result.data.allCategories.edges
          ) {
            setOptions(
              result.data.allCategories.edges.map(x => {
                return this.itemForSelect(x.node);
              })
            );
          }
        });
      loading(false);
    },
    async updateBrands(search, loading, setOptions) {
      loading(true);
      this.$apollo
        .query({
          query: ALL_BRANDS,
          variables: { companyId: this.currentUser.company.id, name: search }
        })
        .then(async result => {
          if (
            result.data &&
            result.data.allBrands &&
            result.data.allBrands.edges
          ) {
            setOptions(
              result.data.allBrands.edges.map(x => {
                return this.itemForSelect(x.node);
              })
            );
          }
        });
      loading(false);
    },
    itemForSelect(item) {
      if (item) {
        return { value: item.id, label: item.name };
      }
      return null;
    },
    processFilters() {
      let status = null;
      if (this.actives && !this.inactives) {
        status = true;
      } else if (this.inactives && !this.actives) {
        status = false;
      }
      this.setFilter({
        categoryId: this.categoriesSelected.join(","),
        brandId: this.brandsSelected.join(","),
        noStock: this.noStock,
        status
      });
    },
    revertFilters() {
      this.categoriesSelected = this.filters.categoryId.length
        ? this.filters.categoryId.split(",")
        : [];
      this.categoriesSelectedObject = this.categoriesSelectedObject?.filter(x =>
        this.categoriesSelected.includes(x.value)
      );
      this.brandsSelected = this.filters.brandId.length
        ? this.filters.brandId.split(",")
        : [];
      this.brandsSelectedObject = this.brandsSelectedObject?.filter(x =>
        this.brandsSelected.includes(x.value)
      );
      this.noStock = this.filters.noStock;
      if (this.filters.status === null) {
        this.actives = true;
        this.inactives = false;
      } else if (this.filters.status) {
        this.actives = true;
        this.inactives = false;
      } else {
        this.actives = false;
        this.inactives = true;
      }
    }
  },
  watch: {
    filters(newValue, oldValue) {
      //category
      const oldCategory = oldValue.categoryId;
      const newCategory = newValue.categoryId;
      if (
        oldCategory !== newCategory &&
        this.categoriesSelected.join(",") !== newCategory
      ) {
        if (newCategory.length === 0) {
          this.categoriesSelected = [];
        } else {
          this.categoriesSelected = newCategory.split(",");
        }
        this.categoriesSelectedObject = this.categoriesSelectedObject.filter(
          x => this.categoriesSelected.includes(x.value)
        );
      }
      //brand
      const oldBrand = oldValue.brandId;
      const newBrand = newValue.brandId;
      if (oldBrand !== newBrand && this.brandsSelected.join(",") !== newBrand) {
        if (newBrand.length === 0) {
          this.brandsSelected = [];
        } else {
          this.brandsSelected = newBrand.split(",");
        }
        this.brandsSelectedObject = this.brandsSelectedObject.filter(x =>
          this.brandsSelected.includes(x.value)
        );
      }
      //status
      const oldStatus = oldValue.status;
      const newStatus = newValue.status;
      if (oldStatus !== newStatus) {
        if (newStatus === null && this.actives !== this.inactives) {
          this.actives = true;
          this.inactives = true;
        } else if (newStatus && !(this.actives && !this.inactives)) {
          this.actives = true;
          this.inactives = false;
        } else if (!newStatus && !(this.inactives && !this.actives)) {
          this.actives = false;
          this.inactives = true;
        }
      }
      //noStock
      const oldNoStock = oldValue.noStock;
      const newNoStock = newValue.noStock;
      if (oldNoStock !== newNoStock && newNoStock !== this.noStock) {
        this.noStock = newNoStock;
      }
    }
  }
};
</script>
<style scoped>
.btn {
  margin: -25px 15px 0 0 !important;
}
</style>
