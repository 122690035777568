<template>
  <div>
    <base-header
      title="Crear atributo de categoría"
      :title-link="centryUrl + '/admin/category_attributes'"
      :map="['Editar']"
    >
    </base-header>
    <b-container fluid="">
      <b-row>
        <b-col md="12">
          <category-attribute-form
            :category-attribute="{}"
            @change="createCategoryAttribute"
          ></category-attribute-form>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import BaseHeader from "@/components/BaseHeader";
import CategoryAttributeForm from "@/components/CategoryAttributeForm";
import { centryUrl } from "@/main";
import CREATE_CATEGORY_ATTRIBUTE from "@/graphql/CreateCategoryAttribute.gql";

export default {
  name: "CategoryAttributeNew",
  components: {
    CategoryAttributeForm,
    BaseHeader
  },
  data() {
    return {
      centryUrl
    };
  },
  methods: {
    createCategoryAttribute(categoryAttributeData) {
      this.$apollo
        .mutate({
          mutation: CREATE_CATEGORY_ATTRIBUTE,
          variables: { create: categoryAttributeData }
        })
        .then(({ data }) => {
          if (data && data.createCategoryAttribute) {
            this.$swal
              .fire({
                title: "Creación exitosa",
                text: "El atributo ha sido creado con éxito",
                icon: "success",
                showCancelButton: false
              })
              .then(() => {
                this.$router.push(
                  "/admin/category_attribute/" +
                    data.createCategoryAttribute.categoryAttribute.id +
                    "/edit"
                );
              });
          }
        })
        .catch(error => {
          this.$swal.fire({
            title: "Error",
            text: "El atributo no ha podido crearse por: " + error,
            icon: "error",
            showCancelButton: false
          });
        });
    }
  }
};
</script>
