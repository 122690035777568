<template>
  <b-alert
    v-if="showAlert"
    :variant="alertVariant"
    :show="dismissCountDown"
    :dismissible="dismissible"
    @dismiss-count-down="countDownChanged"
    @dismissed="resetData"
  >
    <slot></slot>
  </b-alert>
</template>
<script>
export default {
  name: "BaseAlertWithCountDown",
  model: {
    prop: "_value",
    event: "change"
  },
  props: {
    _value: Boolean,
    dismissSecs: {
      type: Number,
      default: 5
    },
    alertVariant: {
      type: String,
      default: "success"
    },
    dismissible: {
      type: Boolean,
      default: true
    }
  },
  data() {
    let dismiss = null;
    if (this._value) {
      dismiss = this.dismissSecs;
    }
    return {
      showAlert: this._value,
      dismissCountDown: dismiss
    };
  },
  methods: {
    /**
     * Resetea los valores de exito y error
     */
    resetData() {
      this.$emit("change", false);
    },
    /**
     * Actualiza el valor actual del contador de la alerta
     * @param {Number} dismissCountDown - segundo que le quedan a la alerta
     */
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    }
  },
  watch: {
    _value(newVal) {
      this.showAlert = newVal;
      if (newVal) {
        this.dismissCountDown = this.dismissSecs;
      }
    }
  }
};
</script>
<style></style>
