<template>
  <b-col md="3">
    <b-card
      v-if="!loading"
      :title="item.title ? item.title : item.name"
      :img-src="item.pictures[0].url"
      img-alt="Image"
      img-top
      tag="article"
      style="max-width: 20rem;"
      class="mb-2"
    >
      <b-card-text>
        <b-badge variant="dark">#{{ position }} más vendido</b-badge><br />
        <b-badge variant="warning" v-if="objectType === 'PRODUCT'"
          >CATÁLOGO</b-badge
        >
        <span class="text-info">{{ price }}</span
        ><br />
        <b-badge variant="info" v-if="!!brand">Marca: {{ brand }}</b-badge
        ><br v-if="!!brand" />
        <b-badge variant="light">{{ shippingMode }}</b-badge> <br />
        <b-badge variant="light">{{ listingType }}</b-badge>
      </b-card-text>

      <b-link :href="item.permalink" target="_blank" variant="primary"
        >Ver en MercadoLibre</b-link
      >
    </b-card>
    <b-card no-body img-top v-else>
      <b-skeleton-img card-img="top" aspect="3:1"></b-skeleton-img>
      <b-card-body>
        <b-skeleton width="30%"></b-skeleton>
        <b-badge variant="dark">#{{ position }} más vendido</b-badge><br />
        <b-skeleton width="33%"></b-skeleton>
        <b-skeleton width="33%"></b-skeleton>
        <b-skeleton width="50%"></b-skeleton>
        <b-skeleton width="50%"></b-skeleton>
      </b-card-body>
    </b-card>
  </b-col>
</template>
<script>
export default {
  name: "MercadoLibreHighlightsCard",
  props: {
    objectType: String,
    id: String,
    position: Number
  },
  mounted() {
    const url =
      "https://api.mercadolibre.com/" +
      (this.objectType === "ITEM" ? "/items/" : "/products/") +
      "/" +
      this.id;
    console.log(url);
    fetch(url)
      .then(response => response.json())
      .then(response => {
        this.item = response;
        this.loading = false;
      });
  },
  data() {
    return {
      loading: true,
      item: null
    };
  },
  computed: {
    price() {
      if (this.item.price) {
        return "$" + Intl.NumberFormat("de-DE").format(this.item.price);
      } else if (this.$dig(this.item, "buy_box_winner", "price")) {
        return (
          "$" +
          Intl.NumberFormat("de-DE").format(
            this.$dig(this.item, "buy_box_winner", "price")
          )
        );
      }
      return "";
    },
    brand() {
      const attr = this.item.attributes.find(attr => attr.id === "BRAND");
      if (attr) {
        return attr.value_name;
      }
      return null;
    },
    shippingMode() {
      if (this.item) {
        let mode = this.$dig(this.item, "shipping", "mode");
        if (!mode) {
          mode = this.$dig(this.item, "buy_box_winner", "shipping", "mode");
        }
        let free = this.$dig(this.item, "shipping", "free_shipping");
        if (!free) {
          free = this.$dig(
            this.item,
            "buy_box_winner",
            "shipping",
            "free_shipping"
          );
        }
        let shipping = "";
        if (mode === "me2" || mode === "me1") {
          shipping += "Mercado Envíos";
        } else if (mode === "custom") {
          shipping += "Envío personalizado";
        } else {
          shipping += "A acordar con el vendedor";
        }
        if (free) {
          shipping += " - Envío gratis";
        }
        return shipping;
      }
      return "";
    },
    listingType() {
      if (this.item) {
        let lt = this.item.listing_type_id;
        if (!lt) {
          lt = this.$dig(this.item, "buy_box_winner", "listing_type_id");
        }
        if (lt === "gold_pro") {
          return "hasta 12 cuotas sin interés";
        } else {
          return "hasta 6 cuotas sin interés";
        }
      } else {
        return "";
      }
    }
  }
};
</script>
<style scoped>
.card-img,
.card-img-top {
  max-height: 200px !important;
}
</style>
