<template>
  <div class="mb-3">
    <h4 class="font-weight-bold my-auto">
      Procesos y sincronizaciones de la última semana.
    </h4>
    <hr />
    <b-form-group label="Origen">
      <v-select
        :options="originOptions"
        v-model="origin"
        :reduce="x => x.value"
        :clearable="false"
      ></v-select>
    </b-form-group>

    <synchronization-table :origin="origin" :re-render="reRender" />
  </div>
</template>

<script>
import SynchronizationTable from "./SynchronizationTable.vue";

export default {
  name: "SynchronizationList",
  components: {
    SynchronizationTable
  },
  props: {
    company: Object,
    reRender: Number
  },
  data() {
    return {
      origin: "manual",
      originOptions: [
        { value: "auto", label: "Tareas automáticas" },
        { value: "manual", label: "Tareas manuales" }
      ]
    };
  },
  methods: {}
};
</script>

<style></style>
