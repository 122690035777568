<template>
  <b-modal
    v-model="showModal"
    size="lg"
    @hidden="handleHidden"
    @ok="handleAction"
    @cancel="handleCancel"
  >
    <template #modal-title>
      <base-header
        v-if="!loading"
        :title="modalTitle"
        :titleSize="12"
        :map="mapHeader"
      >
      </base-header>
    </template>

    <center v-if="loading">
      <b-spinner label="Spinning"></b-spinner>
    </center>
    <b-container v-else>
      <b-row>
        <b-col>
          <b-form-group label="Nombre*" label-for="name">
            <b-form-input id="name" v-model="brand.name" placeholder="Nombre">
            </b-form-input>
            <span v-if="disabledOkButton" class="text-danger">
              El nombre es requerido
            </span>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Estado" label-for="status">
            <base-boolean-selector
              v-model="brand.active"
              trueText="Activo"
              falseText="Inactivo"
            >
            </base-boolean-selector>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group label="Descripción" label-for="text">
            <b-form-textarea
              id="text"
              v-model="brand.text"
              placeholder="Descripción"
            >
            </b-form-textarea>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group
            label="Palabras claves (separadas por coma)"
            label-for="keywords"
          >
            <b-form-input
              id="keywords"
              v-model="brand.keywords"
              placeholder="keywords"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group label="Titulo en el sitio" label-for="siteTitle">
            <b-form-input
              id="siteTitle"
              v-model="brand.siteTitle"
              placeholder="siteTitle"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group>
            <b-form-checkbox v-model="brand.menuHome">
              Mostrar en menu
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>

    <template #modal-footer="{ cancel, ok }">
      <b-button-group>
        <b-button variant="outline-info" @click="cancel">
          <b-icon-x-circle></b-icon-x-circle> Cancelar
        </b-button>
        <b-button variant="info" @click="ok" :disabled="disabledOkButton">
          <b-icon-cloud-upload></b-icon-cloud-upload> {{ actionTraduction }}
        </b-button>
      </b-button-group>
    </template>
  </b-modal>
</template>

<script>
import INTEGRATION_CONFIG_VTEX_GET_BRAND from "@/graphql/IntegrationConfigVtexGetBrand.gql";
import VTEX_CREATE_BRAND from "@/graphql/VtexCreateBrand.gql";
import VTEX_UPDATE_BRAND from "@/graphql/VtexUpdateBrand.gql";

import BaseHeader from "@/components/BaseHeader.vue";
import BaseBooleanSelector from "@/components/BaseBooleanSelector";

export default {
  name: "VtexBrandsMaintainerBrandModal",
  components: {
    BaseHeader,
    BaseBooleanSelector
  },
  props: {
    value: Boolean,
    action: String,
    brandId: String,
    vtexIntegrationConfigId: String
  },
  data() {
    return {
      loading: false,
      showModal: null,
      brand: {
        name: null,
        text: null,
        keywords: null,
        siteTitle: null,
        active: false,
        menuHome: null,
        score: null
      },
      originalBrand: {
        name: null,
        text: null,
        keywords: null,
        siteTitle: null,
        active: false,
        menuHome: null,
        score: null
      }
    };
  },
  methods: {
    /**
     * Realiza la acción correspondiente al presionar Crear/Editar.
     */
    async handleAction() {
      if (this.action === "create") {
        await this.createBrand();
      } else if (this.action === "update") {
        await this.updateBrand();
        this.originalBrand = Object.assign({}, this.brand);
      }
      this.$emit("scrollToTop");
    },
    /**
     * Al cancelar los cambios realizados, se dejan registrados en la vista
     * los valores originales de la marca, antes de cualquier cambio hecho.
     */
    handleCancel() {
      this.brand = Object.assign({}, this.originalBrand);
    },
    /**
     * Emite el evento 'input' con el valor necesario para cerrar el modal.
     */
    handleHidden() {
      this.$emit("input", false);
    },
    /**
     * Realiza la petición para crear una marca en Vtex
     */
    async createBrand() {
      await this.$apollo
        .mutate({
          mutation: VTEX_CREATE_BRAND,
          variables: {
            integrationConfigId: this.vtexIntegrationConfigId,
            brand: this.brand
          }
        })
        .then(({ data }) => {
          if (this.$dig(data, "vtexCreateBrand", "errors")) {
            this.emitErrorMessage(
              "No se pudo crear la marca." + data.vtexCreateBrand.errors
            );
          } else {
            this.emitSuccessfulMessage(
              "Marca creada con éxito. Puede tardar unos minutos en reflejarse en el listado."
            );
          }
        })
        .catch(error => {
          this.emitErrorMessage("No se pudo crear la marca. " + error);
        });
      this.handleHidden();
    },
    /**
     * Realiza la petición para obtener una marca en Vtex.
     * @param {String} brandId
     */
    async getBrand(brandId) {
      await this.$apollo
        .query({
          query: INTEGRATION_CONFIG_VTEX_GET_BRAND,
          variables: {
            id: this.vtexIntegrationConfigId,
            brandId: brandId
          }
        })
        .then(({ data }) => {
          if (!this.$dig(data, "integrationConfigVtex", "getBrand")) {
            this.handleGetBrandError();
          } else {
            this.brand = data.integrationConfigVtex.getBrand;
            this.originalBrand = Object.assign({}, this.brand);
          }
        })
        .catch(() => {
          this.handleGetBrandError();
        });
      this.loading = false;
    },
    /**
     * Se encarga de manejar los casos de error al pedir una maca en Vtex.
     */
    handleGetBrandError() {
      this.emitErrorMessage("No se pudo obtener la marca para poder editarla");
      this.handleHidden();
    },
    /**
     * Realiza la petición para actualizar una marca en Vtex.
     */
    async updateBrand() {
      await this.$apollo
        .mutate({
          mutation: VTEX_UPDATE_BRAND,
          variables: {
            integrationConfigId: this.vtexIntegrationConfigId,
            brandId: this.brand.id,
            brand: this.brand
          }
        })
        .then(({ data }) => {
          if (this.$dig(data, "vtexUpdateBrand", "errors")) {
            this.emitErrorMessage(
              "No se pudo actualizar la marca. " + data.vtexUpdateBrand.errors
            );
          } else {
            this.emitSuccessfulMessage(
              "Marca actualizada con éxito. Puede tardar unos minutos en reflejarse en el listado."
            );
            this.brand = data.vtexUpdateBrand.brand;
            this.originalBrand = Object.assign({}, this.brand);
          }
        })
        .catch(error => {
          this.emitErrorMessage("No se pudo actualizar la marca." + error);
        });
      this.handleHidden();
    },
    /**
     * Emite el evento 'errorMessage' con el mensaje de error correspondiente
     *      * @param {String} errorMessage
     */
    emitErrorMessage(errorMessage = null) {
      this.$emit("errorMessage", errorMessage);
    },
    /**
     * Emite el evento 'succesfulMessage' con el mensaje de exito correspondiente
     *      * @param {String} message
     */
    emitSuccessfulMessage(message) {
      this.$emit("successfulMessage", message);
    },
    /**
     * Se encarga de seleccionar ciertos atributos del objeto de marca recibido.
     * @return {Object}
     */
    selectKeysFromBrand({
      id,
      name,
      text,
      keywords,
      siteTitle,
      active,
      menuHome,
      score
    }) {
      return {
        id,
        name,
        text,
        keywords,
        siteTitle,
        active,
        menuHome,
        score
      };
    }
  },
  computed: {
    actionTraduction() {
      let translatedAction = "";
      if (this.action === "create") {
        translatedAction = "Crear";
      } else if (this.action === "update") {
        translatedAction = "Guardar";
      }
      return translatedAction;
    },
    modalTitle() {
      let action = "";
      if (this.action === "create") {
        action = "Crear";
      } else if (this.action === "update") {
        action = "Editar";
      }
      return `${action} Marca`;
    },
    mapHeader() {
      let map = [];
      if (this.action === "update") {
        map.push(this.brand.name);
      }
      return map;
    },
    disabledOkButton() {
      return this.brand.name === null || this.brand.name === "";
    }
  },
  watch: {
    value: function(newValue) {
      this.showModal = newValue;
    },
    action: function(newAction) {
      if (newAction === "create") {
        this.loading = false;
      }
    },
    brandId: async function(newBrandId) {
      if (newBrandId !== null) {
        this.loading = true;
        await this.getBrand(newBrandId);
      } else {
        this.brand = {
          name: null,
          text: null,
          keywords: null,
          siteTitle: null,
          active: false,
          menuHome: null,
          score: null
        };
      }
    },
    brand: function(newBrand) {
      let acceptedKeys = [
        "id",
        "name",
        "text",
        "keywords",
        "siteTitle",
        "active",
        "menuHome",
        "score"
      ];
      if (Object.keys(newBrand).some(key => !acceptedKeys.includes(key))) {
        this.brand = this.selectKeysFromBrand(newBrand);
      }
    }
  }
};
</script>
