<template>
  <div>
    <b-spinner label="Spinning" v-if="loading"></b-spinner>
    <div v-else>
      <base-header
        title="Más Vendidos"
        title-link="/mercadolibre/highlights"
        :map="
          ['Mercado Libre', 'Productos más vendidos', siteId].concat(
            categoryId ? [categoryId] : []
          )
        "
        :title-size="9"
      >
        <template v-slot:actions>
          <b-container>
            <span style="padding-right: 5px;" v-if="!!categoryId">
              <b-link
                :to="{
                  name: 'MercadoLibreHighlightsBySite',
                  params: {
                    siteId: siteId
                  }
                }"
              >
                {{ siteId.toUpperCase() }}</b-link
              ></span
            >
            <span
              v-for="(elem, i) of parentCategories ? parentCategories : []"
              :key="i"
              style="padding-right: 5px;"
            >
              >
              <b-link
                :to="{
                  name: 'MercadoLibreHighlightsByCategory',
                  params: {
                    siteId: siteId,
                    categoryId: elem.id
                  }
                }"
                >{{ elem.name }}</b-link
              > </span
            ><br />

            <b-dropdown
              variant="outline-info"
              :text="categoryName"
              v-if="childrenCategories.length"
            >
              <b-dropdown-item
                v-for="child of childrenCategories"
                :key="child.id"
                :to="{
                  name: 'MercadoLibreHighlightsByCategory',
                  params: {
                    siteId: siteId,
                    categoryId: child.id.toUpperCase()
                  }
                }"
              >
                {{ child.name }}
              </b-dropdown-item>
            </b-dropdown>
            <b-button variant="outline-info" v-else>{{
              categoryName
            }}</b-button>
          </b-container>
        </template>
      </base-header>
      <mercado-libre-category-highlights-table
        :category-id="categoryId"
        :site-id="siteId"
      ></mercado-libre-category-highlights-table>
    </div>
  </div>
</template>

<script>
import BaseHeader from "@/components/BaseHeader.vue";
import MercadoLibreCategoryHighlightsTable from "../components/MercadoLibreCategoryHighlightsTable";
export default {
  name: "MercadoLibreHighlihtsByCategory",
  components: {
    MercadoLibreCategoryHighlightsTable,
    BaseHeader
  },
  props: {
    siteId: String,
    categoryId: String
  },
  mounted() {
    this.loadCategory();
  },
  data() {
    return {
      loading: true,
      parentCategories: [],
      categoryName: null,
      childrenCategories: [],
      error: null
    };
  },
  methods: {
    loadCategory() {
      if (this.categoryId) {
        return fetch(
          "https://api.mercadolibre.com/categories/" +
            this.categoryId.toUpperCase()
        )
          .then(response => response.json())
          .then(async response => {
            this.categoryName = response.name;
            this.loading = false;
            this.parentCategories = response.path_from_root.length
              ? response.path_from_root.slice(
                  0,
                  response.path_from_root.length - 1
                )
              : [];
            this.childrenCategories = response.children_categories;
          })
          .catch(() => (this.error = "Categoría no encontrada"));
      } else {
        this.categoryName = "Categorías";
        this.loading = false;
        return fetch(
          "https://api.mercadolibre.com/sites/" +
            this.siteId.toUpperCase() +
            "/categories"
        )
          .then(response => response.json())
          .then(async response => {
            this.childrenCategories = response;
          })
          .catch(() => (this.error = "Sitio no encontrado"));
      }
    }
  }
};
</script>
