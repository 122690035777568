<template>
  <div>
    <base-header title="Webhooks" title-link="#">
      <template v-slot:filters>
        <div style="display: inline-grid">
          <b-button
            v-if="$permissionToCreate(currentUser, 'webhooks')"
            :to="'/integrations/webhook/new'"
            variant="outline-info"
            class="mb-2"
          >
            <b-icon-plus></b-icon-plus>
            Webhook nuevo
          </b-button>
        </div>
      </template>
    </base-header>
    <b-container fluid="">
      <b-row>
        <b-col md="12">
          <base-alert-with-count-down
            v-model="showAlert"
            alert-variant="success"
            :dismiss-secs="5"
          >
            {{ alertMessage }}</base-alert-with-count-down
          >
          <webhooks-list :current-user="currentUser" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import BaseHeader from "../components/BaseHeader.vue";
import WebhooksList from "../components/Webhooks/WebhooksList.vue";
import BaseAlertWithCountDown from "../components/Base/BaseAlertWithCountDown.vue";
import { centryUrl } from "../main";
import { mapState } from "vuex";
export default {
  name: "Webhooks",
  components: {
    BaseHeader,
    WebhooksList,
    BaseAlertWithCountDown
  },
  props: {
    action: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      showAlert: this.action ? true : false,
      centryUrl
    };
  },
  created() {
    this.$redirectToProfile(this.currentUser, "webhooks", "read");
  },
  computed: {
    ...mapState(["currentUser"]),
    alertMessage() {
      if (this.action == "created") {
        return "Webhook creado correctamente";
      } else {
        return "Webhook actualizado exitosamente";
      }
    }
  },
  watch: {
    action(val) {
      this.showAlert = val ? true : false;
    }
  }
};
</script>

<style></style>
