<template>
  <div>
    <company-show-page :company="company" />
  </div>
</template>
<script>
import CompanyShowPage from "../../components/Company/Show/ShowPage.vue";
export default {
  name: "CompanyShow",
  components: {
    CompanyShowPage
  },
  props: {
    company: Object
  }
};
</script>
