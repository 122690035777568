<template>
  <div>
    <b-modal
      v-model="showModal"
      @ok="emitModalFilters"
      @cancel="resetModalFilters"
    >
      <template v-slot:modal-title>
        <h4>Filtrar Promociones</h4>
      </template>

      <b-row>
        <b-col>
          <b-form-group label="Filtrar por tipo de la promoción">
            <b-form-radio v-model="modalFilters.type" :value="null">
              No filtrar
            </b-form-radio>
            <b-form-radio v-model="modalFilters.type" value="regular">
              {{ translatePromotionType("regular") }}
            </b-form-radio>
            <b-form-radio v-model="modalFilters.type" value="combo">
              {{ translatePromotionType("combo") }}
            </b-form-radio>
            <b-form-radio v-model="modalFilters.type" value="forThePriceOf">
              {{ translatePromotionType("forThePriceOf") }}
            </b-form-radio>
            <b-form-radio v-model="modalFilters.type" value="progressive">
              {{ translatePromotionType("progressive") }}
            </b-form-radio>
            <b-form-radio v-model="modalFilters.type" value="buyAndWin">
              {{ translatePromotionType("buyAndWin") }}
            </b-form-radio>
            <b-form-radio v-model="modalFilters.type" value="campaign">
              {{ translatePromotionType("campaign") }}
            </b-form-radio>
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group label="Filtrar por estado de la promoción">
            <b-form-radio v-model="modalFilters.active" :value="null">
              No filtrar
            </b-form-radio>
            <b-form-radio v-model="modalFilters.active" :value="true">
              Activo
            </b-form-radio>
            <b-form-radio v-model="modalFilters.active" :value="false">
              Inactivo
            </b-form-radio>
          </b-form-group>
        </b-col>
      </b-row>

      <template v-slot:modal-cancel>Cancelar</template>
      <template v-slot:modal-ok>Filtrar</template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "VtexPromotionsTableFiltersModal",
  props: {
    value: {
      type: Boolean,
      required: true
    },
    actualFilters: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showModal: this.value,
      modalFilters: { ...this.actualFilters }
    };
  },
  methods: {
    emitModalFilters() {
      this.$emit("setModalFilters", this.modalFilters);
    },
    resetModalFilters() {
      this.modalFilters = { ...this.actualFilters };
    },
    /**
     * Traduce el nombre del tipo de la promoción para mostrarlo en español.
     * @param {String} type - tipo de la promoción.
     * @returns {String} - nombre del tipo de la promoción en español.
     */
    translatePromotionType(type) {
      switch (type) {
        case "regular":
          return "Regular";
        case "combo":
          return "Comprar Juntos";
        case "forThePriceOf":
          return "Mas por Menos";
        case "progressive":
          return "Descuento Progresivo";
        case "buyAndWin":
          return "Comprar y Obtener";
        case "campaign":
          return "Campaña";
      }
    }
  },
  watch: {
    value(newValue) {
      this.showModal = newValue;
    },
    showModal(newValue) {
      this.$emit("input", newValue);
    },
    actualFilters(newValue) {
      this.modalFilters = { ...newValue };
    }
  }
};
</script>
