<template>
  <div>
    <base-header title="Documentos" title-link="#" />
    <b-container fluid="">
      <b-row>
        <b-col md="12">
          <documents-tabs></documents-tabs>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import BaseHeader from "../components/BaseHeader.vue";
import DocumentsTabs from "../components/order/documents/DocumentsTabs.vue";

import { centryUrl } from "../main";

export default {
  name: "Orders",
  components: {
    BaseHeader,
    DocumentsTabs
  },
  data() {
    return {
      centryUrl,
      changed: false,
      download: false,
      success: null,
      params: {
        number: null,
        ids: [],
        status: [],
        origin: [],
        create_from: null,
        create_to: null,
        sku: null,
        shipping_label_print: null,
        buyers: null
      }
    };
  },
  methods: {
    change() {
      this.changed = true;
    }
  }
};
</script>
