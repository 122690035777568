<template>
  <div>
    <base-header title="Bodegas" title-link="#">
      <template v-slot:filters>
        <div style="display: inline-grid">
          <b-button
            v-if="$permissionToCreate(currentUser, 'warehouses')"
            :to="'/warehouse/new'"
            variant="outline-info"
            class="mb-2"
          >
            <b-icon-plus></b-icon-plus>
            Bodega nueva
          </b-button>
          <b-link
            :href="`${centryUrl}/empresas/${currentUser.company.id}/edit`"
            v-if="$permissionToUpdate(currentUser, 'warehouses')"
          >
            Cambiar bodega principal
          </b-link>
        </div>
      </template>
    </base-header>
    <b-container fluid="">
      <b-row>
        <b-col md="12">
          <base-alert-with-count-down
            v-model="showAlert"
            alert-variant="success"
            :dismiss-secs="5"
          >
            {{ alertMessage }}</base-alert-with-count-down
          >
          <warehouses-list />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import BaseHeader from "../components/BaseHeader.vue";
import WarehousesList from "../components/Warehouses/WarehousesList.vue";
import BaseAlertWithCountDown from "../components/Base/BaseAlertWithCountDown.vue";
import { centryUrl } from "../main";
import { mapState } from "vuex";
export default {
  name: "Warehouses",
  components: {
    BaseHeader,
    WarehousesList,
    BaseAlertWithCountDown
  },
  props: {
    action: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      showAlert: this.action ? true : false,
      centryUrl
    };
  },
  computed: {
    ...mapState(["currentUser"]),
    userGroup() {
      return this.$userGroup(this.currentUser);
    },
    alertMessage() {
      if (this.action == "created") {
        return "Bodega creada correctamente";
      } else {
        return "Bodega actualizada exitosamente";
      }
    }
  },
  watch: {
    action(val) {
      this.showAlert = val ? true : false;
    }
  }
};
</script>

<style></style>
