<template>
  <div :id="id" ref="spreadsheet" class="spreadsheet"></div>
</template>
<script>
import jexcelStyle from "jexcel/dist/jexcel.css"; // eslint-disable-line no-unused-vars
import jexcel from "jexcel"; // eslint-disable-line no-unused-vars

export default {
  name: "BaseJExcelTable",
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    id: {
      type: String,
      default: "spreadsheet"
    },
    value: {
      type: Array,
      default() {
        return [];
      }
    },
    columns: {
      type: Array,
      default() {
        return [];
      }
    },
    rows: {
      type: Array,
      default() {
        return [];
      }
    },
    defaultColWidth: {
      type: Number,
      default: 120
    },
    validationFunction: {
      type: Function,
      default: () => {}
    },
    allowInsertRow: {
      type: Boolean,
      default: true
    },
    allowInsertColumn: {
      type: Boolean,
      default: true
    },
    allowDeleteRow: {
      type: Boolean,
      default: false
    },
    allowDeleteColumn: {
      type: Boolean,
      default: false
    },
    allowRenameColumn: {
      type: Boolean,
      default: false
    },
    validateRowOnCellValidation: {
      type: Boolean,
      default: false
    },
    showColumns: {
      type: [String, Array],
      default: "all"
    },
    onSelection: {
      type: Function,
      default: () => {}
    },
    filters: {
      type: Boolean,
      default: false
    },
    customOnChange: {
      type: Function,
      default: () => {}
    },
    afterMounted: {
      type: Function,
      default: () => {}
    },
    skipValidation: {
      type: Boolean,
      default: false
    },
    allowSearch: {
      type: Boolean,
      default: false
    },
    setStyleOnlyToCellWithError: {
      type: Boolean,
      default: false
    },
    pagination: {
      type: Number,
      default: null
    },
    columnSorting: {
      type: Boolean,
      default: true
    },
    colorCellFunction: {
      type: Function,

      /**
       * La función recibe el nro de fila, columnas
       * y si la celda y fila son válidas
       * @param {Number} y nro de fila
       * @param {Number} x nro de columna
       * @param {Boolean} validCell si la celda es o no válida
       * @param {Boolean} validRow si la fila no tiene errores
       * @returns {String} color para la celda
       */
      default: (y, x, validCell, validRow) => {
        if (!validCell) {
          return "#ff3838";
        } else if (!validRow) {
          return "#ffdf6c";
        }
        return "#fff";
      }
    },
    validateTableOnDeleteRow: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.jExcelObj = jexcel(this.$refs["spreadsheet"], this.jExcelOptions);
    this.initializeJExcel();
  },
  data() {
    return {
      data: [],
      jExcelObj: null
    };
  },
  computed: {
    jExcelOptions() {
      return {
        columns: this.columns,
        rows: this.rows,
        defaultColWith: this.defaultColWidth,
        allowInsertRow: this.allowInsertRow,
        allowManualInsertRow: this.allowInsertRow,
        allowInsertColumn: this.allowInsertColumn,
        allowManualInsertColumn: this.allowInsertColumn,
        allowDeleteRow: this.allowDeleteRow,
        allowDeleteColumn: this.allowDeleteColumn,
        allowRenameColumn: this.allowRenameColumn,
        onchange: this.onChange,
        allowComments: true,
        tableOverflow: true,
        tableWidth: "100%",
        tableHeight: "100%",
        onselection: this.onSelection,
        filters: this.filters,
        search: this.allowSearch,
        pagination: this.pagination,
        oninsertrow: this.onInsertRow,
        ondeleterow: this.onDeleteRow,
        onundo: this.onUndo,
        onredo: this.onRedo,
        columnSorting: this.columnSorting,
        text: {
          showingPage: "Mostrando página {0} de {1} páginas",
          search: "Buscar",
          noRecordsFound: "No se encontró información"
        }
      };
    }
  },
  methods: {
    domTable() {
      const content = this.$refs.spreadsheet.getElementsByClassName(
        "jexcel_content"
      )[0];
      return content.getElementsByClassName("jexcel")[0];
    },
    domTheadTds() {
      const thead = this.domTable().getElementsByTagName("thead")[0];
      const trThead = thead.getElementsByTagName("tr")[0];
      return trThead.getElementsByTagName("td");
    },
    domTbodyTrs() {
      const tbody = this.domTable().getElementsByTagName("tbody")[0];
      return tbody.getElementsByTagName("tr");
    },
    setDisplayToColumnTds(index, tds, display) {
      [...tds].forEach(td => {
        if (td.dataset.x == index) {
          td.style.display = display;
        }
      });
    },
    setDisplayToColumn(index, display) {
      this.setDisplayToColumnTds(index, this.domTheadTds(), display);
      [...this.domTbodyTrs()].forEach(tr => {
        const tds = tr.getElementsByTagName("td");
        this.setDisplayToColumnTds(index, tds, display);
      });
      return index;
    },
    showColumn(index) {
      this.setDisplayToColumn(index, "table-cell");
    },
    hideColumn(index) {
      this.setDisplayToColumn(index, "none");
    },
    updateShowColumns() {
      if (this.showColumns === "all") {
        this.columns.forEach((_col, index) => {
          this.showColumn(index);
        });
      } else {
        for (let i = 0; i < this.columns.length; i++) {
          if (this.showColumns[i]) {
            this.showColumn(i);
          } else {
            this.hideColumn(i);
          }
        }
      }
    },
    setValidRowStyle(y) {
      const row = this.data[y];
      for (let x = 0; x < row.length; x++) {
        const color = this.colorCellFunction(y, x, true, true);
        this.setColor(y, x, color);
      }
    },
    validateRow(y) {
      let validRow = true;
      const row = this.data[y];
      for (let x = 0; x < row.length; x++) {
        if (!this.validateCell(x, y, this.data[y][x], true)) {
          validRow = false;
        } else if (!this.setStyleOnlyToCellWithError) {
          const color = this.colorCellFunction(y, x, true, false);
          this.setColor(y, x, color);
        }
      }
      if (validRow) {
        this.setValidRowStyle(y);
      }
    },
    /**
     * Setea el color de una celda
     * @param {Number} y
     * @param {Number} x
     * @param {String} color
     */
    setColor(y, x, color) {
      const cellName = jexcel.getColumnNameFromId([x, y]);
      this.jExcelObj.setStyle(cellName, "background-color", color, true, true);
    },
    reset() {
      this.jExcelObj.setData(this.data);
    },
    validateCell(x, y, value, rowValidation = false) {
      if (this.validateRowOnCellValidation && !rowValidation)
        return this.validateRow(y);
      const cellName = jexcel.getColumnNameFromId([x, y]);
      const error = this.validationFunction(y, x, value);
      this.jExcelObj.setComments(cellName, error);
      if (error && error.length) {
        const color = this.colorCellFunction(y, x, false, false);
        this.setColor(y, x, color);
        return false;
      } else {
        const color = this.colorCellFunction(y, x, true, true);
        this.setColor(y, x, color);
        return true;
      }
    },
    onChange(instance, cell, x, y, value) {
      this.customOnChange(instance, cell, x, y, value, this.jExcelObj);
      //force update of data
      this.$set(this.data, 0, this.data[0]);
      this.$emit("change", this.data);
      if (!this.skipValidation) this.validateCell(x, y, this.data[y][x]);
    },
    /**
     * Evento "deshacer", emite change
     * @param instance
     * @param cell
     * @param {Number} x
     * @param {Number} y
     */
    onUndo(instance, cell, x, y) {
      this.$set(this.data, 0, this.data[0]);
      this.$emit("change", this.data);
      if (!this.skipValidation) this.validateCell(x, y, this.data[y][x]);
    },
    initializeJExcel() {
      this.data = this.$dup(this.value);
      this.jExcelObj.setData(this.data);
      if (!this.skipValidation)
        this.data.forEach((_row, rowNum) => {
          this.validateRow(rowNum);
        });
      this.updateShowColumns();
      this.afterMounted(this.jExcelObj);
    },
    /**
     * Valida la fila si es necesario
     * @param instance - instancia del jExcel
     * @param {Integer} y
     * @param {Integer} numOfRows
     */
    onInsertRow(instance, y, numOfRows) {
      this.$emit("change", this.data);
      if (this.skipValidation) return;
      for (let i = y; i <= y + numOfRows; i++) this.validateRow(i);
    },
    /**
     * Ataja eliminacion de filas en la tabla, valida la tabla si es necesario
     */
    onDeleteRow(instance, y) {
      this.$emit("change", this.data);
      this.$emit("deleterow", y);
      if (!this.validateTableOnDeleteRow) {
        return;
      }
      this.data.forEach((_row, rowNum) => {
        this.validateRow(rowNum);
      });
    },
    /**
     * Evento "deshacer", emite change
     * @param instance
     * @param cell
     * @param {Number} x
     * @param {Number} y
     */
    onRedo(instance, cell, x, y) {
      this.$set(this.data, 0, this.data[0]);
      this.$emit("change", this.data);
      if (!this.skipValidation) this.validateCell(x, y, this.data[y][x]);
    }
  },
  watch: {
    value(newVal) {
      if (newVal !== this.data) this.initializeJExcel();
    },
    rows() {
      this.jExcelObj.destroy();
      this.jExcelObj = jexcel(this.$refs["spreadsheet"], this.jExcelOptions);
      this.initializeJExcel();
    },
    columns() {
      this.jExcelObj.destroy();
      this.jExcelObj = jexcel(this.$refs["spreadsheet"], this.jExcelOptions);
      this.initializeJExcel();
    },
    showColumns() {
      this.updateShowColumns();
    }
  }
};
</script>
<style scoped>
.spreadsheet {
  width: 100%;
  height: 100%;
}
</style>
