<template>
  <b-tr>
    <b-td
      ><span :class="classByStatus">{{ formatedCreatedDate }} </span></b-td
    >
    <b-td
      ><span :class="classByStatus"> {{ formatedUpdatedDate }} </span>
    </b-td>
    <b-td>
      <span :class="classByStatus"
        >{{ internalSynchronization.title }}
      </span></b-td
    >
    <b-td>
      <span :class="classByStatus"
        >{{ internalSynchronization.origin }}
      </span></b-td
    >
    <b-td>
      <span :class="classByStatus">
        {{ internalSynchronization.progress | toPercentage }}
      </span></b-td
    >
    <b-td>
      <span :class="classByStatus" v-if="errors.length == 0">
        0
      </span>
      <errors-modal
        v-else
        :errors="errors"
        :span-class="classByStatus"
        :id="internalSynchronization.id"
      />
    </b-td>
    <b-td>
      <span :class="classByStatus">{{ status }} </span></b-td
    >
    <b-td>
      <span :class="classByStatus">
        {{ internalSynchronization.progress == 1 ? "" : msToTime }}
      </span></b-td
    >
  </b-tr>
</template>

<script>
import ASYNC_TASK_LOG_BY_ID from "../../../graphql/Company/AsyncTaskLog.gql";
import ErrorsModal from "./ErrorsModal.vue";
export default {
  name: "SynchronizationTableRow",
  components: {
    ErrorsModal
  },
  props: {
    synchronization: Object,
    currentUser: Object
  },
  data() {
    return {
      internalSynchronization: this.$dup(this.synchronization),
      mounted: true
    };
  },
  mounted() {
    setTimeout(this.updateInternalSynchronization, 1000);
  },
  beforeDestroy() {
    this.mounted = false;
  },
  computed: {
    formatedCreatedDate() {
      const date = this.$timezoneDate(
        this.currentUser,
        this.internalSynchronization.createdAt
      );
      return this.$moment(date).format("DD/MM/YYYY HH:mm");
    },
    formatedUpdatedDate() {
      const date = this.$timezoneDate(
        this.currentUser,
        this.internalSynchronization.updatedAt
      );
      return this.$moment(date).format("DD/MM/YYYY HH:mm");
    },
    status() {
      switch (this.internalSynchronization.status) {
        case "pending":
          return "Pendiente";
        case "running":
          return "Ejecutándose";
        case "cancelled":
          return "Cancelada";
        case "finished":
          return "Terminada";
        case "failed":
          return "Fallida";
        default:
          return "";
      }
    },
    isUpdatableInternalSynchronization() {
      return (
        this.isPending &&
        new Date() - new Date(this.internalSynchronization.updatedAt) <
          60 * 60 * 1000
      );
    },
    isPending() {
      return ["pending", "running"].includes(
        this.internalSynchronization.status
      );
    },
    msToTime() {
      let data = this.internalSynchronization;
      let start = new Date(data.createdAt);
      let end = new Date(data.updatedAt);
      let timeElapsed = end.getTime() - start.getTime();
      let s = data.progress
        ? ((1 - data.progress) * timeElapsed) / data.progress
        : 0;
      // Pad to 2 or 3 digits, default is 2
      function pad(n, z) {
        z = z || 2;
        return ("00" + n).slice(-z);
      }

      let ms = s % 1000;
      s = (s - ms) / 1000;
      let secs = s % 60;
      s = (s - secs) / 60;
      let mins = s % 60;
      let hrs = (s - mins) / 60;

      return pad(hrs) + ":" + pad(mins) + ":" + pad(secs); // + '.' + pad(ms, 3);
    },
    classByStatus() {
      let status = this.internalSynchronization.status;
      switch (status) {
        case "pending":
          return "text-muted";
        case "running":
          return "text-info";
        case "cancelled":
          return "text-warning";
        case "finished":
          return "text-success";
        case "failed":
          return "text-danger";
        default:
          return "";
      }
    },
    errors() {
      return this.$dig(this.internalSynchronization, "reports", "errors") || [];
    }
  },
  methods: {
    /**
     * Actualiza el progreso de una tarea si es que puede
     */
    async updateInternalSynchronization() {
      if (this.isUpdatableInternalSynchronization) {
        await this.$apollo
          .query({
            query: ASYNC_TASK_LOG_BY_ID,
            variables: {
              id: this.synchronization.id
            },
            fetchPolicy: "no-cache"
          })
          .then(({ data }) => {
            if (!data?.asyncTaskLog) return;
            Object.assign(this.internalSynchronization, data.asyncTaskLog);
          });
        if (this.mounted) setTimeout(this.updateInternalSynchronization, 1000);
      }
    }
  }
};
</script>

<style scoped>
.focus-btn {
  color: #aab1b5;
  display: inline-block;
  background-color: white;
  width: 34px;
  height: 34px;
  text-align: center;
  border-radius: 50%;
  font-size: 14px;
  border: 2px solid #e0e2e4;
  padding: 5px 0;
}

.focus-btn:hover {
  border-color: #aab1b5;
  color: black;
}
</style>
