var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-alert-with-count-down',{attrs:{"alert-variant":_vm.alertVariant},model:{value:(_vm.showAlert),callback:function ($$v) {_vm.showAlert=$$v},expression:"showAlert"}},[_vm._v(" "+_vm._s(_vm.alertMessage)+" ")]),_c('ApolloQuery',{attrs:{"query":require('../../graphql/SizeChart/AllSizeCharts.gql'),"fetchPolicy":'cache-and-network',"clientId":"apolloClientCached","variables":{
      name: _vm.filters.name
    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var ref_result = ref.result;
    var loading = ref_result.loading;
    var error = ref_result.error;
    var data = ref_result.data;
    var query = ref.query;
return [(data && data.allSizeCharts && data.allSizeCharts.edges.length)?_c('b-table-simple',[_c('b-thead',[_c('b-tr',[_c('b-th',[_vm._v("Nombre")]),_c('b-th')],1)],1),_c('b-tbody',_vm._l((data.allSizeCharts.edges),function(sizeChart){return _c('b-tr',{key:sizeChart.node.id},[_c('b-td',[_vm._v(" "+_vm._s(sizeChart.node.name))]),_c('b-td',[_c('b-row',{attrs:{"align-h":"end","no-gutters":""}},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('Editar Guía de Tallas'),expression:"'Editar Guía de Tallas'",modifiers:{"hover":true}}],staticClass:"p-0 focus-btn",attrs:{"size":"lg","variant":"outlined","to":'/size_chart/' + sizeChart.node.id + '/edit'}},[_c('b-icon',{attrs:{"stacked":"","icon":"pencil","scale":"0.60"}})],1),_c('ApolloMutation',{attrs:{"mutation":require('../../graphql/SizeChart/DeleteSizeChart.gql'),"variables":{ id: sizeChart.node.id },"fetchPolicy":"no-cache-and-network","clientId":"apolloClientCached","refetchQueries":function () { return [
                      {
                        query: require('../../graphql/SizeChart/AllSizeCharts.gql'),
                        variables: {
                          name: _vm.filters.name
                        }
                      }
                    ]; }},on:{"done":_vm.onDone},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var mutate = ref.mutate;
return [_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('Eliminar Guía de Tallas'),expression:"'Eliminar Guía de Tallas'",modifiers:{"hover":true}}],staticClass:"p-0 focus-btn",attrs:{"size":"lg","variant":"outlined"},on:{"click":function($event){return _vm.deleteIfAccept(sizeChart.node, mutate)}}},[_c('b-icon',{attrs:{"stacked":"","icon":"x","scale":"0.75"}})],1)]}}],null,true)})],1)],1)],1)}),1)],1):_c('div',[_vm._v(" No se encontraron registros. ")]),(loading)?_c('b-spinner',{attrs:{"label":"Spinning"}}):_vm._e(),(error)?_c('b-alert',{attrs:{"show":"","variant":"danger"}},[_vm._v(" Ha ocurrido un error ")]):_vm._e(),(
          data &&
            data.allSizeCharts &&
            data.allSizeCharts.pageInfo.hasNextPage
        )?_c('b-button',{staticClass:"m-2 float-right",on:{"click":function($event){return _vm.seeMore(query, data.allSizeCharts.pageInfo.endCursor)}}},[_vm._v(" Ver más ")]):_vm._e(),(_vm.seeMoreLoading)?_c('b-spinner',{staticClass:"m-2 float-right",attrs:{"label":"Spinning"}}):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }