<template>
  <b-tr>
    <b-td>
      {{ userCopy.name }}
    </b-td>
    <b-td>
      <b-row align-v="center">
        {{ userCopy.status ? "Activo" : "Inactivo" }}
        <b-form-checkbox
          v-if="$permissionToUpdate(currentUser, 'users')"
          class="ml-2"
          v-model="checked"
          v-b-tooltip.hover="
            (userCopy.status ? 'Desactivar' : 'Activar') + ' usuario'
          "
          switch
          @change="setStatus"
          inline
        />
      </b-row>
    </b-td>
    <b-td>
      {{ userCopy.email }}
    </b-td>
    <b-td>
      {{ userCopy.groupName }}
    </b-td>
    <b-td>
      <b-row align-h="end" no-gutters>
        <div class="mr-2">
          <span v-if="deleting"> Eliminando <b-spinner small /> </span>
          <span v-else-if="updating">
            Actualizando estado <b-spinner small />
          </span>
        </div>
        <b-button
          v-if="$permissionToUpdate(currentUser, 'users')"
          size="lg"
          variant="outlined"
          class="p-0 focus-btn"
          v-b-tooltip.hover="'Editar usuario'"
          :to="`user/${userCopy.id}/edit`"
        >
          <b-icon stacked icon="pencil" scale="0.60"></b-icon>
        </b-button>
        <b-button
          v-if="$permissionToDelete(currentUser, 'users')"
          size="lg"
          variant="outlined"
          class="p-0 focus-btn"
          v-b-tooltip.hover="'Eliminar usuario'"
          @click="swalDeleteWarehouse"
        >
          <b-icon stacked icon="x" scale="0.75"></b-icon>
        </b-button>
      </b-row>
    </b-td>
  </b-tr>
</template>

<script>
import DELETE_USER from "../../graphql/Users/DeleteUser.gql";
import UPDATE_USER from "../../graphql/Users/UpdateUser.gql";
import { mapState } from "vuex";
export default {
  name: "UsersDetail",
  props: {
    user: Object
  },
  data() {
    return {
      userCopy: {},
      deleting: false,
      updating: false,
      checked: false
    };
  },
  computed: {
    ...mapState(["currentUser"])
  },
  created() {
    this.checked = this.user.status == 1;
    this.userCopy = this.$dup(this.user);
  },
  methods: {
    /**
     * Se encarga de enviar la mutacion para borrar un usuario
     */
    deleteUser() {
      this.deleting = true;
      this.$apollo
        .mutate({
          mutation: DELETE_USER,
          variables: {
            userId: this.user.id
          }
        })
        .then(({ data }) => {
          if (data.deleteUser.result) {
            this.$emit("delete-user");
            this.deleting = false;
          } else {
            this.$emit("delete-user", data.deleteUser.error);
            this.deleting = false;
          }
        });
    },
    /**
     * Lanza un swal para confirmar la eliminacion del usuario
     */
    swalDeleteWarehouse() {
      this.$swal
        .fire({
          title: "Eliminar usuario",
          text: "El usuario se eliminará definitivamente. ¿Estás seguro?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si",
          cancelButtonText: "No"
        })
        .then(result => {
          if (result.value) {
            this.deleteUser();
          }
        });
    },
    /**
     * Se encarga de enviar una mutacion para
     * actualizar el estado del usuario
     */
    async setStatus() {
      this.updating = true;
      await this.$apollo
        .mutate({
          mutation: UPDATE_USER,
          variables: {
            user: {
              id: this.user.id,
              status: this.checked ? 1 : 0
            }
          }
        })
        .then(({ data }) => {
          if (!data.updateUser.result) {
            this.$emit("error-updating", data.updateUser.error);
          } else {
            this.userCopy.status = this.checked ? 1 : 0;
            this.user.status = this.checked ? 1 : 0;
          }
          this.updating = false;
        })
        .catch(e => {
          this.$emit("errorUpdating", e.message);
          this.updating = false;
        });
    }
  },
  watch: {
    user: {
      handler(val) {
        this.checked = val.status == 1;
        this.userCopy = this.$dup(val);
      },
      deep: true
    }
  }
};
</script>

<style>
.focus-btn {
  color: #aab1b5;
  display: inline-block;
  background-color: white;
  width: 34px;
  height: 34px;
  text-align: center;
  border-radius: 50%;
  font-size: 14px;
  border: 2px solid #e0e2e4;
  padding: 5px 0;
}

.focus-btn:hover {
  border-color: #aab1b5;
  color: black;
}
</style>
