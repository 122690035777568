<template>
  <div>
    <b-button variant="outline-info" @click="openModal" size="xl">
      <b-icon-plus></b-icon-plus>
      Agregar homologación
    </b-button>
    <b-modal id="new-attribute-homologation" cancel-disabled="" size="xl">
      <template v-slot:modal-title>
        <h4>Nueva Homologación</h4>
        <p v-if="badSpec" style="color: red">
          Atributos de categoría deben estar homologados a una especificación de
          producto.
        </p>
      </template>
      <vtex-homologation-specification-select
        @spec-change="changeSpecification"
      ></vtex-homologation-specification-select>
      <template v-slot:modal-footer>
        <h4>
          {{ specMessage }}
        </h4>
        <b-button
          variant="info"
          @click="createHomologation"
          :disabled="!specificationSelected.name || badSpec"
        >
          Crear
        </b-button>
      </template>
      <div class="modal-body"></div>
    </b-modal>
  </div>
</template>
<script>
import GET_VTEX_CATEGORIES from "@/graphql/AllVtexCategories.gql";
import ALL_VTEX_INTEGRATIONS from "@/graphql/AllVtexIntegrations.gql";
import GET_VTEX_SPECIFICATION from "@/graphql/IntegrationConfigVtexGetSpecification.gql";
import CREATE_VTEX_PURPOSE from "@/graphql/CreateVtexPurpose.gql";
import VtexHomologationSpecificationSelect from "@/components/VtexHomologation/VtexHomologationAttributes/VtexHomologationSpecificationSelect";
export default {
  name: "VtexHomologationAttributeNewModal",
  components: { VtexHomologationSpecificationSelect },
  props: {
    categoryAttribute: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      categories: [],
      categorySelected: null,
      specificationSelected: {},
      integrationConfigId: null,
      allVtexIntegrations: [],
      loadingCategories: false,
      loadingSpecifications: false,
      allSpecifications: [],
      badSpec: false
    };
  },
  computed: {
    specMessage() {
      if (Object.keys(this.specificationSelected).length) {
        return (
          "Especificación seleccionada:" +
          this.specificationSelected.name +
          " (Especificación de " +
          (this.specificationSelected.isStockKeepingUnit ? "Sku)" : "Producto)")
        );
      }
      return "";
    }
  },
  methods: {
    /**
     * Abre el modal para crear un purpose
     */
    openModal() {
      this.getIntegrationConfigs();
      this.$bvModal.show("new-attribute-homologation");
    },
    /**
     * Obtiene las categorías que tiene Vtex e indica
     * que esta parte ya cargó
     */
    getVtexCategories() {
      this.loadingCategories = true;
      if (!this.integrationConfigId) {
        this.categories = [];
        return;
      }
      this.$apollo
        .query({
          query: GET_VTEX_CATEGORIES,
          variables: {
            ic: this.integrationConfigId,
            limit: 100,
            offset: 0
          }
        })
        .then(({ data }) => {
          this.categories = data.allVtexCategories;
          this.loadingCategories = false;
        });
    },
    /**
     * Obtiene los integration configs y los formatea
     * para el selector
     */
    getIntegrationConfigs() {
      return this.$apollo
        .query({
          query: ALL_VTEX_INTEGRATIONS
        })
        .then(({ data }) => {
          if (data && data.allVtexIntegrationConfigs) {
            this.allVtexIntegrations = data.allVtexIntegrationConfigs.map(
              ic => {
                return { value: ic.id, label: ic.label };
              }
            );
          }
        });
    },
    /**
     * Obtiene una especificación en Vtex para obtener
     * el tipo de dato que tiene esta.
     */
    getSpecification() {
      return this.$apollo
        .query({
          query: GET_VTEX_SPECIFICATION,
          variables: {
            id: this.integrationConfigId,
            specificationId: this.specificationSelected.fieldId
          }
        })
        .then(({ data }) => {
          if (this.$dig(data, "integrationConfigVtex", "getSpecification")) {
            this.specificationSelected["fieldTypeId"] =
              data.integrationConfigVtex.getSpecification.fieldTypeId;
          }
          this.loadingSpecifications = false;
        });
    },
    /**
     * Obtiene las especificaciones de la categoría a la que cambio.
     * @param {Object} category - categoria de vtex seleccionada
     */
    changeCategory(category) {
      this.loadingSpecifications = true;
      this.categorySelected = category.id;
      this.getSpecifications();
    },
    /**
     * Actualiza la variable de la especificación y el id del
     * integration config seleccionado
     * @param {Object} spec - Especificación seleccionada
     */
    changeSpecification(spec) {
      this.specificationSelected = spec.specification;
      this.integrationConfigId = spec.integrationConfigId;
      this.validateSpecType();
    },
    /**
     * Manda a crear el purpose y muestra una alerta si la creación
     * fue exitosa o fallida.
     */
    async createHomologation() {
      await this.getSpecification();
      let dataType = this.$options.filters.VtexDataTypeTranslate(
        this.specificationSelected.fieldTypeId
      );
      let create = {
        model: "category_attribute",
        dataType: dataType,
        specId: this.specificationSelected.fieldId.toString(),
        specName: this.specificationSelected.name,
        integrationConfigId: this.integrationConfigId,
        categoryAttributeId: this.categoryAttribute.id
      };

      this.$apollo
        .mutate({
          mutation: CREATE_VTEX_PURPOSE,
          variables: {
            create: create
          }
        })
        .then(({ data }) => {
          let message = {};
          if (!data?.createVtexPurpose?.vtexPurpose) {
            message["title"] = "Creación fallida";
            message["text"] = "Hubo un error al crear la homologación";
            message["icon"] = "error";
          } else {
            message["title"] = "Creación exitosa";
            message["text"] = "La homologación fue creada exitosamente";
            message["icon"] = "success";
            this.$bvModal.hide("new-attribute-homologation");
          }
          this.$swal.fire({
            title: message["title"],
            text: message["text"],
            icon: message["icon"],
            showCancelButton: false
          });
          this.$emit("new-purpose");
        });
    },
    /**
     * Valida si el modelo del purpose puede ser
     * homologado con el tipo de especificación de Vtex:
     * Atributos de categoría solo pueden ser homologados con
     * especificaciones de producto.
     */
    validateSpecType() {
      if (Object.keys(this.specificationSelected).length) {
        this.badSpec = this.specificationSelected.isStockKeepingUnit;
      }
    }
  },
  watch: {
    integrationConfigId() {
      this.allSpecifications = [];
      this.categories = [];
      this.getVtexCategories();
    }
  }
};
</script>
