<template>
  <div>
    <b-row align-v="center">
      <b-col md="6">
        <b-form-group :label="inputLabel">
          <b-form-file
            placeholder="Elige o arrastra un archivo acá..."
            drop-placeholder="Suelta el archivo aquí..."
            v-model="file"
            :accept="acceptedFiles"
            browse-text="Seleccionar"
          ></b-form-file>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>
<script>
var XLSX = require("xlsx");

export default {
  name: "BaseCsvReader",
  props: {
    acceptedFiles: {
      type: String,
      default: ".csv"
    },
    inputLabel: {
      type: String,
      default: "Seleccione un archivo"
    }
  },
  data() {
    return {
      file: null
    };
  },
  methods: {
    /**
     * Parsea el dato de la celda a partir de UTF-8
     * @param {String} value
     * @returns {String}
     */
    parseCell(value) {
      if (!value || !value.length) return value;
      return decodeURIComponent(escape(value));
    }
  },
  watch: {
    async file(x) {
      if (!x) return;
      const data = await x.arrayBuffer();
      const workbook = XLSX.read(data, { raw: true, dense: true });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const array = sheet.map(row => row.map(elem => this.parseCell(elem.v)));
      this.$emit("ready", array);
    }
  }
};
</script>
