<template>
  <div>
    <b-dropdown variant="white" no-caret="" offset="-110">
      <template v-slot:button-content>
        <b-icon-three-dots-vertical></b-icon-three-dots-vertical>
      </template>
      <b-dropdown-item
        :to="{
          name: 'ProductHistories',
          params: { id: stockDestination.variant.product.id }
        }"
      >
        Sincronizaciones
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
export default {
  name: "StockDestinationsTableRowActions",
  props: {
    stockDestination: Object
  }
};
</script>
