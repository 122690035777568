<template>
  <b-modal id="change-quantities" size="md" @hide="resetData" scrollable>
    <template v-slot:modal-title>
      <h4>Stock en bodegas para variante</h4>
    </template>
    <div v-if="newVariantWarehouses && newVariantWarehouses.length">
      <b-table-simple>
        <b-thead>
          <b-tr>
            <b-th>
              Bodega
            </b-th>
            <b-th>
              Stock
            </b-th>
          </b-tr>
        </b-thead>

        <b-tbody>
          <b-tr v-for="(vw, index) in newVariantWarehouses" :key="index">
            <b-td>
              {{ vw.warehouse.name }}
            </b-td>

            <b-td>
              <b-input v-model="vw.quantity" number type="number" />
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
    <template #modal-footer>
      <b-row>
        <b-button
          class="mx-2"
          variant="info"
          :disabled="!changes"
          @click="updateVariantWarehouses"
        >
          <span v-if="saving"> <b-spinner /></span>
          <span v-else> Guardar </span>
        </b-button>
        <b-button
          class="mx-2"
          variant="outline-info"
          :disabled="!changes"
          @click="resetData"
        >
          Restablecer
        </b-button>
      </b-row>
    </template>
  </b-modal>
</template>
<script>
import { updateVariantWarehouse } from "../../../main";
export default {
  name: "QuantityModal",
  props: {
    variantWarehouses: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  mounted() {
    if (this.variantWarehouses) {
      this.newVariantWarehouses = this.$dup(this.variantWarehouses);
    }
  },
  data() {
    return {
      newVariantWarehouses: [],
      saving: false
    };
  },
  computed: {
    changes() {
      let changes = false;
      this.variantWarehouses?.forEach((_vw, i) => {
        changes ||=
          this.variantWarehouses[i].quantity !=
          this.newVariantWarehouses[i].quantity;
      });
      return changes;
    }
  },
  methods: {
    /**
     * Resetea los stock
     */
    resetData() {
      this.newVariantWarehouses = this.$dup(this.variantWarehouses);
    },
    /**
     * Manda la mutacion para actualizar los variantWarehouses
     */
    updateVariantWarehouses() {
      this.saving = true;
      let newVariantWarehouses = [];
      newVariantWarehouses = this.newVariantWarehouses.map(async vw => {
        let patch = { quantity: vw.quantity };
        let data = await updateVariantWarehouse(this.$apollo, vw.id, patch);
        return data.data.updateVariantWarehouse.variantWarehouse;
      });
      Promise.all(newVariantWarehouses).then(val => {
        this.$emit("change", val);
        this.saving = false;
      });
    }
  },
  watch: {
    variantWarehouses: {
      handler(val) {
        this.newVariantWarehouses = this.$dup(val);
      },
      deep: true
    }
  }
};
</script>
