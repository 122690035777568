<template>
  <b-spinner
    label="Spinning"
    v-if="loadingPurposes || loadingCategories || loadingTable"
  ></b-spinner>

  <div v-else>
    <base-homologation-table
      :key="id"
      :columns="columns"
      :rows="rows"
      :purposes-index-rev="purposesIndexRev"
      :homologations-ids-rev="categoriesIdsRev"
      :homologation-index="homologationIndex"
      :mutation="mutation"
      mutation-name="saveVtexHomologation"
      model-name="Products::Category"
      :all-values-father="this.allValues"
      @all-values="allValuesChange"
    ></base-homologation-table>
  </div>
</template>

<script>
import ALL_CATEGORIES from "@/graphql/AllCategories.gql";
import ALL_VTEX_PURPOSES from "@/graphql/AllVtexPurposes.gql";
import ALL_VTEX_CATEGORIES from "@/graphql/AllVtexCategories.gql";
import SAVE_VTEX_HOMOLOGATION from "@/graphql/SaveVtexHomologation.gql";
import BaseHomologationTable from "@/components/BaseHomologationTable";
import { mapState } from "vuex";

export default {
  name: "VtexHomologationCategories",
  components: { BaseHomologationTable },
  computed: {
    ...mapState(["currentUser"])
  },
  data() {
    return {
      purposes: [],
      categories: [],
      rows: [],
      columns: [
        {
          type: "text",
          title: "Categoría Centry",
          width: 300,
          readOnly: true
        }
      ],
      mutation: SAVE_VTEX_HOMOLOGATION,
      purposesIndex: {},
      purposesIndexRev: {},
      homologationIndex: {},
      homologationValues: {},
      loadingCategories: true,
      loadingPurposes: true,
      loadingTable: true,
      categoriesIds: {},
      categoriesIdsRev: {},
      available: false,
      allValues: false,
      id: 0
    };
  },
  async mounted() {
    Promise.all([this.getCategories(), this.getVtexPurposes()])
      .then(() => this.prepareColumns())
      .then(() => this.prepareRows())
      .then(() => (this.loadingTable = false));
  },
  methods: {
    /**
     * Obtiene las categorías de Centry usadas por la empresa del
     * usuario o todas las existentes en Centry.
     */
    async getCategories() {
      this.loadingCategories = true;
      await this.$getAllPages(
        ALL_CATEGORIES,
        {
          companyId: this.allValues ? "" : this.currentUser.company.id
        },
        "allCategories"
      ).then(array => {
        this.categories = array;
        this.loadingCategories = false;
      });
    },
    /**
     * Obtiene los purposes de Vtex que sean de categoría
     * e indica que ya cargó esta parte.
     */
    async getVtexPurposes() {
      await this.$apollo
        .query({
          query: ALL_VTEX_PURPOSES,
          variables: {
            model: "product_category"
          }
        })
        .then(({ data }) => {
          this.purposes = data.allVtexPurposes.edges;
          this.purposes.forEach(purpose => {
            this.homologationValues[purpose.node.id] = {};
            purpose.node.vtexHomologations.forEach(hv => {
              this.homologationValues[purpose.node.id][hv.vtexHomologableId] =
                hv.v;
            });
          });
          this.loadingPurposes = false;
        });
    },
    /**
     * Prepara las columnas de la tabla trayendo las
     * opciones desde Vtex si corresponde.
     */
    async prepareColumns() {
      let indexes = {};
      let indexesRev = {};
      let i = 0;
      for (let p of this.purposes) {
        let icId = p.node.integrationConfig.id;
        let options = await this.getCategoriesFromVtex(icId, p.node.id);
        let column = {
          type: p.node.dataType,
          title: p.node.integrationConfig.label + ": " + p.node.specName,
          width: 300,
          source: options
        };
        this.columns.push(column);
        indexes[p.node.id] = i + 1;
        indexesRev[i + 1] = p.node.id;
        i += 1;
      }
      this.purposesIndex = indexes;
      this.purposesIndexRev = indexesRev;
    },
    /**
     * Prepara las filas obteniendo los datos ya rellenados
     * para las columnas correspondientes.
     */
    prepareRows() {
      const indexes = this.purposesIndex;
      const purposesLength = Object.keys(indexes).length;
      let rows = [];
      this.categories.forEach(category => {
        let row = [];
        row[0] = category.node.name;
        this.homologationIndex[category.node.name] = category.node.id;
        for (let i = 1; i <= purposesLength; i++) {
          row[i] = "";
        }
        this.purposes.forEach(purpose => {
          let index = indexes[purpose.node.id];
          let value = this.homologationValues[purpose.node.id][
            category.node.id
          ];
          if (value) {
            row[index] = this.categoriesIds[purpose.node.id][value] || value;
          }
        });
        rows.push(row);
      });
      this.rows = rows;
    },
    /**
     * Obtiene las opciones de una especificación
     * en Vtex
     */
    async getCategoriesFromVtex(icId, purposeId) {
      return await this.$apollo
        .query({
          query: ALL_VTEX_CATEGORIES,
          variables: {
            ic: icId,
            limit: 100,
            offset: 0
          }
        })
        .then(({ data }) => {
          let tree = data.allVtexCategories;
          this.categoriesIds[purposeId] = {};
          this.categoriesIdsRev[purposeId] = {};
          return this.treeNames(tree, [], "", purposeId);
        });
    },
    /**
     * Obtiene el nombre de las categorías para
     * ocuparlos como opciones.
     */
    treeNames(tree, options, fatherName, icId) {
      let scope = this;
      tree.forEach(c => {
        let name;
        if (fatherName) {
          name = fatherName + " / " + c.name;
        } else {
          name = c.name;
        }
        options.push(name);
        scope.categoriesIds[icId][c.id] = name;
        scope.categoriesIdsRev[icId][name] = c.id;
        if (c.children) {
          this.treeNames(c.children, options, name, icId);
        }
      });
      return options;
    },
    /**
     * Actualiza los valores de la tabla cuando
     * hay un cambio.
     */
    async allValuesChange(value) {
      this.allValues = value;
      await this.getCategories();
      await this.prepareRows();
      this.id = this.id += 1;
    }
  }
};
</script>
