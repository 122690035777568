<template>
  <div>
    <b-button-group size="sm">
      <b-button variant="outline-info" @click="cancel">
        <b-icon-x-circle></b-icon-x-circle> Cancelar
      </b-button>
      <b-button
        variant="info"
        :disabled="!changed || saving || !validChangesPrices"
        :title="changed ? '' : 'No hay cambios'"
        v-b-tooltip.hover=""
        @click="save"
      >
        <span v-if="saving"><b-spinner label="Spinning"></b-spinner></span>
        <span v-else><b-icon-cloud-upload></b-icon-cloud-upload>Guardar</span>
      </b-button>
    </b-button-group>

    <b-alert
      v-model="saved"
      :variant="errorSaving ? 'danger' : 'success'"
      dismissible
    >
      {{
        errorSaving
          ? "Ha ocurrido un error"
          : "El producto ha sido actualizado exitosamente"
      }}
    </b-alert>

    <div>
      <h4 class="font-weight-bold m-3">Detalles producto</h4>

      <b-row>
        <b-col md="6">
          <b-form-group label="Estado">
            <base-boolean-selector
              v-model="status"
              allow-null
              true-text="Activo"
              false-text="Inactivo"
              default-text="Como en la configuración general"
            ></base-boolean-selector>
          </b-form-group>

          <b-form-group
            label="Nombre"
            :label-for="'name-' + integrationConfig.fullLabel"
          >
            <b-form-input
              :id="'name-' + integrationConfig.fullLabel"
              v-model="name"
            />
          </b-form-group>

          <b-form-group
            label="SKU"
            :label-for="'sku-' + integrationConfig.fullLabel"
          >
            <b-form-input
              :id="'sku-' + integrationConfig.fullLabel"
              v-model="sku"
            />
          </b-form-group>

          <span v-if="loadingCategory"> <b-spinner label="Spinning" /> </span>
          <b-form-group v-else label="Categoria">
            <base-live-select
              placeholder="Categoria"
              v-model="category"
              @search="getCategories"
            />
          </b-form-group>

          <span v-if="loadingBrand"> <b-spinner label="Spinning" /> </span>
          <b-form-group v-else label="Marca">
            <base-live-select
              placeholder="Marca"
              v-model="brand"
              @search="getBrands"
            />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group
            label="Descripción"
            :label-for="'description-' + integrationConfig.fullLabel"
          >
            <base-html-text-area
              :id="'description-' + integrationConfig.fullLabel"
              v-model="description"
            ></base-html-text-area>
          </b-form-group>

          <b-form-group
            label="Descripción Corta"
            :label-for="'descriptionShort-' + integrationConfig.fullLabel"
          >
            <base-html-text-area
              :id="'descriptionShort-' + integrationConfig.fullLabel"
              v-model="descriptionShort"
            ></base-html-text-area>
          </b-form-group>
        </b-col>
      </b-row>
    </div>

    <hr />

    <div>
      <h4 class="font-weight-bold m-3">Precios</h4>

      <b-row>
        <b-col md="3">
          <b-form-group label="Precio" label-for="priceCompare">
            <b-form-input
              id="priceCompare"
              v-model="price"
              :state="$validFormPrice(price)"
              :formatter="$formatFormPrice"
            />
          </b-form-group>
        </b-col>

        <b-col md="3">
          <b-form-group label="Precio Oferta" label-for="price">
            <b-input
              id="price"
              v-model="sale_price"
              :state="$validFormPrice(sale_price)"
              :formatter="$formatFormPrice"
            />
          </b-form-group>
        </b-col>

        <b-col md="3">
          <b-form-group
            label="Inicio oferta"
            label-for="salestartdate"
            format="DD/MM/YYYY HH:mm"
          >
            <base-date-time
              v-model="salestartdate"
              type="datetime"
            ></base-date-time>
          </b-form-group>
        </b-col>

        <b-col md="3">
          <b-form-group
            label="Término oferta"
            label-for="saleenddate"
            format="DD/MM/YYYY HH:mm"
          >
            <base-date-time
              v-model="saleenddate"
              type="datetime"
            ></base-date-time>
          </b-form-group>
        </b-col>
      </b-row>
    </div>

    <div>
      <b-row>
        <h4 class="font-weight-bold m-3">Variantes</h4>

        <p v-if="!product.variants || product.variants.length === 0">
          Este producto no tiene variantes
        </p>

        <b-table-simple v-else>
          <b-thead>
            <b-tr>
              <b-th colspan="3">Centry</b-th>
              <b-th>{{ integrationConfig.label }}</b-th>
            </b-tr>
            <b-tr>
              <b-th>Color</b-th>
              <b-th>Talla</b-th>
              <b-th>SKU</b-th>
              <b-th>SKU</b-th>
            </b-tr>
          </b-thead>

          <b-tbody>
            <b-tr v-for="(variant, index) of product.variants" :key="index">
              <b-td>{{ variant.color ? variant.color.name : "---" }}</b-td>
              <b-td>{{ variant.size ? variant.size.name : "---" }}</b-td>
              <b-td>{{ variant.sku }}</b-td>
              <b-td>
                <b-form-input v-model="variants[index].sku" />
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-row>
    </div>

    <hr />

    <div>
      <h4 class="font-weight-bold m-3">SEO</h4>

      <b-row>
        <b-col md="6">
          <b-form-group label="Título">
            <b-input v-model="title" />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group label="Meta descripción">
            <b-form-textarea v-model="metaDescription" />
          </b-form-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import BaseHtmlTextArea from "./BaseHtmlTextArea";
import BaseBooleanSelector from "./BaseBooleanSelector";
import BaseDateTime from "./BaseDateTime";
import BaseLiveSelect from "./BaseLiveSelect";

import { updateProduct, updateVariant } from "../main";

import GET_VTEX_CATEGORY from "@/graphql/GetVtexCategory.gql";
import GET_VTEX_CATEGORIES from "@/graphql/AllVtexCategories.gql";
import INTEGRATION_CONFIG_VTEX_GET_BRAND from "@/graphql/IntegrationConfigVtexGetBrand.gql";
import INTEGRATION_CONFIG_VTEX_BRANDS_LIST from "@/graphql/IntegrationConfigVtexBrandsList.gql";

export default {
  name: "ProductEditTabsVtex",
  components: {
    BaseHtmlTextArea,
    BaseBooleanSelector,
    BaseDateTime,
    BaseLiveSelect
  },
  props: {
    integrationConfig: Object,
    product: Object
  },
  data() {
    const original = this.originalProductIntegrationHash();
    return {
      changed: false,
      saving: false,
      errorSaving: false,
      saved: false,
      status: this.calculateStatus(original),
      name: original.Name,
      sku: original.RefId,
      category: null,
      loadingCategory: true,
      brand: null,
      loadingBrand: true,
      description: original.Description,
      descriptionShort: original.DescriptionShort,
      price: original.price,
      sale_price: original.sale_price,
      salestartdate: this.parseDate(original.salestartdate),
      saleenddate: this.parseDate(original.saleenddate),
      variants: this.variantsUpdatableData(),
      title: original.Title,
      metaDescription: original.MetaTagDescription
    };
  },
  /**
   * Se obtiene la marca y cateogria del producto, tambien se agregan los watch
   * correspondientes para detectar cambios en los datos
   */
  async mounted() {
    this.getAndWatchBrand();
    this.getAndWatchCategory();
    this.$watch(
      vm => [
        vm.status,
        vm.name,
        vm.sku,
        vm.description,
        vm.descriptionShort,
        vm.price,
        vm.sale_price,
        vm.salestartdate,
        vm.saleenddate,
        vm.variants,
        vm.title,
        vm.metaDescription
      ],
      () => {
        this.setAndEmitChanged(true);
      },
      { deep: true }
    );
  },
  computed: {
    /**
     * Indica si los precios son validos para habilitar el botón
     * de guardar
     * @returns {Boolean}
     */
    validChangesPrices() {
      return (
        this.changed &&
        (this.$validFormPrice(this.price) ||
          this.$validFormPrice(this.price) == null) &&
        (this.$validFormPrice(this.sale_price) ||
          this.$validFormPrice(this.sale_price) == null)
      );
    }
  },
  methods: {
    /**
     * Obtiene el hash de integracion del producto
     * @return {Object}
     */
    originalProductIntegrationHash() {
      return this.$ifNull(
        this.$dig(
          this.product,
          "integrations",
          this.integrationConfig.fullLabel
        ),
        {}
      );
    },
    /**
     * Obtiene el hash de integracion de la variante entregada
     * @param {Object} variant
     * @return {Object}
     */
    originalVariantIntegrationHash(variant) {
      return this.$ifNull(
        this.$dig(variant, "integrations", this.integrationConfig.fullLabel),
        {}
      );
    },
    /**
     * Calcula el estado del producto
     * @param {Object} original Hash de integracion original
     * @return {Boolean} Estado del producto
     */
    calculateStatus(original) {
      let status = original.IsActive;
      if (status === true || status === "true") {
        return true;
      } else if (status === false || status === "false") {
        return false;
      }
      return null;
    },
    /**
     * Obtiene la categoria del producto desde Vtex y se agrega un listener para
     * detectar cambios en la categoria seleccionada
     */
    async getAndWatchCategory() {
      const original = this.originalProductIntegrationHash();
      await this.getCategory(original.CategoryId).then(() => {
        this.$watch(
          "category",
          () => {
            this.setAndEmitChanged(true);
          },
          { deep: true }
        );
        this.loadingCategory = false;
      });
    },
    /**
     * Obtiene la categoria del producto mediante su ID
     * @param {String} categoryId
     */
    async getCategory(categoryId) {
      if (categoryId) {
        await this.$apollo
          .query({
            query: GET_VTEX_CATEGORY,
            variables: {
              ic: this.integrationConfig.id,
              id: categoryId
            }
          })
          .then(({ data }) => {
            if (data?.getVtexCategory) {
              let category = data.getVtexCategory;
              this.category = { value: category.id, label: category.name };
            }
          });
      }
    },
    /**
     * Obtiene la marca del producto desde Vtex y se agrega un listener para
     * detectar cambios en la marca seleccionada
     */
    async getAndWatchBrand() {
      const original = this.originalProductIntegrationHash();
      await this.getBrand(original.BrandId).then(() => {
        this.$watch(
          "brand",
          () => {
            this.setAndEmitChanged(true);
          },
          { deep: true }
        );
        this.loadingBrand = false;
      });
    },
    /**
     * Obtiene la marca del producto mediante su ID
     * @param {String} brandId
     */
    async getBrand(brandId) {
      if (brandId) {
        await this.$apollo
          .query({
            query: INTEGRATION_CONFIG_VTEX_GET_BRAND,
            variables: {
              id: this.integrationConfig.id,
              brandId: brandId
            }
          })
          .then(({ data }) => {
            if (data?.integrationConfigVtex?.getBrand) {
              let brand = data.integrationConfigVtex.getBrand;
              this.brand = { value: brand.id, label: brand.name };
            }
          });
      }
    },
    /**
     * Parsea una fecha en formato string a un objeto Date
     * @param {String} dateString
     * @return {Date}
     */
    parseDate(dateString) {
      if (dateString) return new Date(dateString);
      return null;
    },
    /**
     * Obtiene los datos actualzables de las variantes
     * @return {Array}
     */
    variantsUpdatableData() {
      if (this.product.variants) {
        return this.product.variants.map(variant => {
          const original = this.originalVariantIntegrationHash(variant);
          return {
            sku: original.RefId
          };
        });
      }
      return null;
    },
    /**
     * Cambia el estado propio de si se han realizado cambios y lo emite al padre
     * @param {Boolean} changed Estado de si se han realizado cambios
     */
    setAndEmitChanged(changed) {
      this.changed = changed;
      this.$emit("input", changed);
    },
    /**
     * Obtiene el listado de categorias desde Vtex para mostrarlas en el selector
     * @param {String} search Texto de busqueda
     * @param {Function} loading Setea el estado de cargando
     * @param {Function} setOptions Setea las opciones en el selector
     */
    async getCategories(search, loading, setOptions) {
      loading(true);
      await this.$apollo
        .query({
          query: GET_VTEX_CATEGORIES,
          variables: {
            ic: this.integrationConfig.id,
            limit: 100,
            offset: 0,
            keywords: [search],
            flatten: true
          }
        })
        .then(({ data }) => {
          if (data?.allVtexCategories) {
            let categories = data.allVtexCategories;
            let options = categories.map(category => {
              return { value: category.id, label: category.name };
            });
            setOptions(options);
          }
        });
      loading(false);
    },
    /**
     * Obtiene el listado de marcas desde Vtex para mostrarlas en el selector
     * @param {String} search Texto de busqueda
     * @param {Function} loading Setea el estado de cargando
     * @param {Function} setOptions Setea las opciones en el selector
     */
    async getBrands(search, loading, setOptions) {
      loading(true);
      await this.$apollo
        .query({
          query: INTEGRATION_CONFIG_VTEX_BRANDS_LIST,
          variables: {
            id: this.integrationConfig.id,
            keywords: [search]
          }
        })
        .then(({ data }) => {
          if (data?.integrationConfigVtex?.brandsList) {
            let brandList = data.integrationConfigVtex.brandsList;
            let options = brandList.map(brand => {
              return { value: brand.id, label: brand.name };
            });
            setOptions(options);
          }
        });
      loading(false);
    },
    /**
     * Cancela los cambios realizados y vuelve a la vista del listado de productos
     */
    cancel() {
      if (this.changed) {
        this.cancelWithChanges();
      } else {
        this.goToProducts();
      }
    },
    /**
     * Avisa al usuario que los cambios se van a cancelar, si acepta se
     * devuelve a la vista del listado de productos
     */
    cancelWithChanges() {
      this.$swal
        .fire({
          title: "Cancelar",
          text: "Si cancelas, perderás tus cambios. ¿Estás seguro?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si",
          cancelButtonText: "No"
        })
        .then(result => {
          if (result.value) {
            this.goToProducts();
          }
        });
    },
    /**
     * Redirige al usuario a la vista del listado de productos
     */
    goToProducts() {
      this.$router.push({ name: "Products" });
    },
    /**
     * Comienza el proceso de guardado de los cambios
     */
    async save() {
      this.saving = true;
      this.errorSaving = false;
      await this.updateProduct();
      for (
        let position = 0;
        position < this.product.variants.length;
        position++
      ) {
        await this.updateVariant(position);
      }
      this.saving = false;
      this.saved = true;
      if (!this.errorSaving) this.setAndEmitChanged(false);
    },
    /**
     * Actualiza el hash de integración del producto
     */
    async updateProduct() {
      const updated = this.updatedProductData();
      if (!updated) return;

      await updateProduct(this.$apollo, this.product.id, updated)
        .then(({ data }) => {
          if (data?.updateProduct?.product) {
            this.errorSaving = false;
            const product = data.updateProduct.product;
            if (product) {
              this.product.integrations = product.integrations;
            }
          }
        })
        .catch(() => {
          this.errorSaving = true;
        });
    },
    /**
     * Calcula los datos del producto que fueron actualizados
     * @return {Object} Hash de integración con los cambios realizados
     */
    updatedProductData() {
      const data = this.calculateCurrentAndOriginalProductDataArray();
      const updated = this.calculateHashWithChanges(data);
      if (Object.keys(updated).length > 0) {
        return this.updatedIntegrationHash(updated);
      }

      return null;
    },
    /**
     * Calcula, para el producto, el arreglo de datos para comparar los datos
     * actuales con los originales
     * @return {Array} Arreglo de datos a comparar
     */
    calculateCurrentAndOriginalProductDataArray() {
      const original = this.originalProductIntegrationHash();
      return [
        {
          key: "IsActive",
          current: this.status,
          original: original ? original.IsActive : ""
        },
        {
          key: "Name",
          current: this.name,
          original: original ? original.Name : ""
        },
        {
          key: "RefId",
          current: this.sku,
          original: original ? original.RefId : ""
        },
        {
          key: "CategoryId",
          current: this.category ? this.category.value : "",
          original: original ? original.CategoryId : ""
        },
        {
          key: "BrandId",
          current: this.brand ? this.brand.value : "",
          original: original ? original.BrandId : ""
        },
        {
          key: "Description",
          current: this.description,
          original: original ? original.Description : ""
        },
        {
          key: "DescriptionShort",
          current: this.descriptionShort,
          original: original ? original.DescriptionShort : ""
        },
        {
          key: "price",
          current: this.price,
          original: original ? original.price : ""
        },
        {
          key: "sale_price",
          current: this.sale_price,
          original: original ? original.sale_price : ""
        },
        {
          key: "salestartdate",
          current: this.salestartdate,
          original: original ? original.salestartdate : ""
        },
        {
          key: "saleenddate",
          current: this.saleenddate,
          original: original ? original.saleenddate : ""
        },
        {
          key: "Title",
          current: this.title,
          original: original ? original.Title : ""
        },
        {
          key: "MetaTagDescription",
          current: this.metaDescription,
          original: original ? original.MetaTagDescription : ""
        }
      ];
    },
    /**
     * Calcula el hash con los cambios realizados
     * @param {Array} currentAndOriginalData Arreglo de datos a comparar
     * @return {Object} Hash con los cambios realizados
     */
    calculateHashWithChanges(currentAndOriginalData) {
      let updated = {};
      currentAndOriginalData.forEach(x => {
        if (x.original !== x.current) {
          updated[x.key] = x.current;
        }
      });
      return updated;
    },
    /**
     * Retorna el objeto que se debe enviar para actualizar el hash de integración
     * @param {Object} updated Hash con los cambios realizados
     * @return {Object} Hash de integración con los cambios realizados
     */
    updatedIntegrationHash(updated) {
      const toSend = { integrations: {} };
      toSend.integrations[this.integrationConfig.fullLabel] = updated;
      return toSend;
    },
    /**
     * Actualiza el hash de integración de la variante
     */
    async updateVariant(position) {
      const updated = this.updatedVariantData(position);
      if (!updated) return;

      await updateVariant(
        this.$apollo,
        this.product.variants[position].id,
        updated
      )
        .then(({ data }) => {
          if (data?.updateVariant?.variant) {
            this.errorSaving = false;
            const variant = data.updateVariant.variant;
            this.product.variants[position] = variant;
          }
        })
        .catch(() => {
          this.errorSaving = true;
        });
    },
    /**
     * Calcula los datos de la variante que fueron actualizados
     * @params {Number} position Posición de la variante en el arreglo
     * @return {Object} Hash de integración con los cambios realizados
     */
    updatedVariantData(position) {
      const data = this.calculateCurrentAndOriginalVariantDataArray(position);
      const updated = this.calculateHashWithChanges(data);
      if (Object.keys(updated).length > 0) {
        return this.updatedIntegrationHash(updated);
      }
      return null;
    },
    /**
     * Calcula, para una variante, el arreglo de datos para comparar los
     * datos actuales con los originales
     * @return {Array} Arreglo de datos a comparar
     */
    calculateCurrentAndOriginalVariantDataArray(position) {
      const variant = this.product.variants[position];
      const original = this.originalVariantIntegrationHash(variant);
      return [
        {
          key: "RefId",
          current: this.variants[position].sku,
          original: original ? original.RefId : ""
        }
      ];
    }
  }
};
</script>
