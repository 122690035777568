<template>
  <product-detail-page :product="product"></product-detail-page>
</template>
<script>
import ProductDetailPage from "../components/ProductDetailPage";
export default {
  name: "ProductDetail",
  props: {
    product: Object
  },
  components: {
    ProductDetailPage
  }
};
</script>
