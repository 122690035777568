<template>
  <b-row align-h="between">
    <div>
      <span v-if="deleting"> Eliminando <b-spinner small /> </span>
    </div>
    <div>
      <b-button
        size="lg"
        variant="outlined"
        class="p-auto mx-1 focus-btn"
        title="Realizar venta"
        :href="
          `${centryUrl}/productos/${productId}/ventas/new?variant_id=${variant.id}`
        "
        v-b-tooltip.hover
        v-if="!isPack"
      >
        <dollar-icon />
      </b-button>
      <b-button
        size="lg"
        variant="outlined"
        class="p-0 mx-1 focus-btn"
        title="Mover stock"
        v-b-tooltip.hover
        @click="showTransaction"
        v-if="!isPack"
      >
        <b-icon stacked icon="truck" scale="0.75" flip-h></b-icon>
      </b-button>
      <b-button
        size="lg"
        variant="outlined"
        class="p-0 mx-1 focus-btn"
        title="Ver código QR"
        v-b-tooltip.hover
        @click="showQr"
      >
        <qr-code-icon />
      </b-button>
      <b-button
        size="lg"
        variant="outlined"
        class="p-0 mx-1 focus-btn"
        title="Eliminar"
        v-b-tooltip.hover
        @click="swalDeleteVariant"
      >
        <b-icon stacked icon="x" scale="0.75"></b-icon>
      </b-button>
      <qr-modal :variant-id="variant.id" />
      <transaction-modal
        :variant-warehouses="variant.variantWarehouses"
        :variant-id="variant.id"
        :variant-sku="variant.sku"
        @change="changeVariantWarehouses"
      />
    </div>
  </b-row>
</template>
<script>
import QrModal from "./QrModal.vue";
import TransactionModal from "./TransactionModal.vue";
import QrCodeIcon from "../../Icons/QrCode.vue";
import DollarIcon from "../../Icons/Dollar.vue";
import DELETE_VARIANT from "../../../graphql/Product/Inventory/DeleteVariant.gql";
import { centryUrl } from "../../../main";
export default {
  name: "VariantsTableActions",
  components: {
    QrModal,
    QrCodeIcon,
    DollarIcon,
    TransactionModal
  },
  props: {
    variant: {
      type: Object,
      defualt() {
        return {};
      }
    },
    isPack: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      centryUrl,
      productId: this.$route.params.id,
      deleting: false
    };
  },
  methods: {
    /**
     * Lanza un swal para confirmar la eliminacion de la variante
     */
    swalDeleteVariant() {
      this.$swal
        .fire({
          title: "Eliminar variante",
          text: "La variante se eliminará definitivamente. ¿Estás seguro?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si",
          cancelButtonText: "No"
        })
        .then(result => {
          if (result.value) {
            this.deleteVariant();
          }
        });
    },
    /**
     * Se encarga de mostrar el modal con el codigo qr para una variante
     */
    showQr() {
      this.$bvModal.show(`qr-modal-${this.variant.id}`);
    },
    /**
     * Se encarga de mostrar el modal para realizar una transaccion en una variante
     */
    showTransaction() {
      this.$bvModal.show(`create-transaction-${this.variant.id}`);
    },
    /**
     * Elimina una variante
     */
    deleteVariant() {
      this.deleting = true;
      this.$apollo
        .mutate({
          mutation: DELETE_VARIANT,
          variables: { variantId: this.variant.id }
        })
        .then(({ data }) => {
          if (data.deleteVariant.result) {
            this.$emit("delete-variant");
            this.deleting = false;
          } else {
            this.$emit("delete-variant", data.deleteVariant.error);
            this.deleting = false;
          }
        });
    },
    changeVariantWarehouses(newVariantWarehouses) {
      this.$emit("change", newVariantWarehouses);
    }
  }
};
</script>
<style scoped>
.focus-btn {
  color: #aab1b5;
  display: inline-block;
  background-color: white;
  width: 34px;
  height: 34px;
  text-align: center;
  border-radius: 50%;
  font-size: 14px;
  border: 2px solid #e0e2e4;
  padding: 5px 0;
}

.focus-btn:hover {
  border-color: #aab1b5;
  color: black;
}
</style>
