<template>
  <div>
    <b-row align-v="center">
      <b-col md="3">
        <b-form-checkbox
          v-model="synchronize"
          :value="true"
          :unchecked-value="false"
        >
          ¿Sincronizar después de la carga?
        </b-form-checkbox>
      </b-col>
      <b-col md="3">
        <b-form-checkbox
          v-model="unpublishExcluded"
          :value="true"
          :unchecked-value="false"
        >
          ¿Despublicar excluídos?
        </b-form-checkbox>
      </b-col>
      <b-col md="3">
        <b-button :clearable="false" variant="primary" @click="emitReady"
          >Continuar</b-button
        >
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  name: "BulkUploadTabsSynchronization",
  props: {
    data: Object
  },
  data() {
    return {
      synchronize: true,
      unpublishExcluded: true
    };
  },
  methods: {
    emitReady() {
      this.$emit(
        "ready",
        Object.assign(this.data, {
          synchronize: this.synchronize,
          unpublishExcluded: this.unpublishExcluded
        })
      );
    }
  }
};
</script>
