<template>
  <div>
    <b-button v-b-modal.modal-1 variant="link" v-if="!!lastMirrorHistory"
      >Ultima actualizacion {{ lmhDate }}. {{ lmhPercent }}%
      completado</b-button
    >

    <b-modal
      id="modal-1"
      title="Productos con errores"
      v-if="!!lastMirrorHistory"
    >
      <p v-if="lmhErrorProducts.length === 0">
        No hay productos actualizados con errores
      </p>
      <b-list-group v-else>
        <b-list-group-item
          v-for="lmhProd of lmhErrorProducts"
          v-bind:key="lmhProd.product.id"
          :to="{
            name: 'ProductHistories',
            params: { id: lmhProd.product.id }
          }"
          >{{ lmhProd.product.name }} ({{
            lmhProd.product.sku
          }})</b-list-group-item
        >
      </b-list-group>
    </b-modal>
  </div>
</template>
<script>
import ALL_MIRROR_HISTORIES from "@/graphql/AllMirrorHistories.gql";
import { centryUrl } from "@/main";
import { mapActions } from "vuex";
import moment from "moment-timezone";
export default {
  name: "MercadoLibreAttributesTableHistory",
  props: {
    attributeGroupIntegrationConfigId: String
  },
  mounted() {
    this.updateLMH();
  },
  beforeDestroy() {
    this.mounted = false;
  },
  data() {
    return {
      lastMirrorHistory: null,
      lmhDate: null,
      mounted: true,
      centryUrl
    };
  },
  computed: {
    lmhPercent() {
      const complete = this.lastMirrorHistory.mirrorHistoryProducts.filter(
        mhp => mhp.success !== null
      );
      return (complete.length / this.lastMirrorHistory.productsCount) * 100;
    },
    lmhErrorProducts() {
      return this.lastMirrorHistory.mirrorHistoryProducts.filter(
        mhp => mhp.success === false
      );
    }
  },
  methods: {
    ...mapActions(["timezoneDate"]),
    updateLMH() {
      if (!this.mounted) return;
      this.$apollo
        .query({
          query: ALL_MIRROR_HISTORIES,
          variables: {
            attributeGroupIntegrationConfigId: this
              .attributeGroupIntegrationConfigId,
            first: 1
          },
          fetchPolicy: "no-cache"
        })
        .then(async ({ data }) => {
          if (
            data &&
            data.allMirrorHistories &&
            data.allMirrorHistories.edges &&
            data.allMirrorHistories.edges.length
          ) {
            const lmh = data.allMirrorHistories.edges[0].node;
            const date = await this.timezoneDate(lmh.createdAt);
            this.lmhDate =
              moment(date).format("DD/MM/YYYY") +
              " a las " +
              moment(date).format("HH:mm");
            this.lastMirrorHistory = lmh;
          }
          setTimeout(this.updateLMH, 5000);
        });
    }
  }
};
</script>
<style scoped>
.btn-link {
  float: right;
}
</style>
