<template>
  <div>
    <h4>Últimos movimientos</h4>
    <div class="text-center">
      <apollo-query
        :query="require('../graphql/TransactionsByProduct.gql')"
        :variables="{ productId: this.product.id, first: 10 }"
        :fetchPolicy="'cache-and-network'"
        clientId="apolloClientCached"
      >
        <template slot-scope="{ result: { error, data }, isLoading }">
          <span v-if="error">-- error al obtener los movimientos --</span>
          <b-spinner
            label="Spinning"
            v-else-if="isLoading && !data"
          ></b-spinner>
          <span v-else-if="data.allTransactions.edges.length === 0">
            -- no hay movimientos registrados --
          </span>
          <span v-else-if="data && isLoading" class="m-2 float-right">
            Actualizando lista de movimientos...
          </span>
          <transactions-table
            v-if="data"
            :transactions="data.allTransactions.edges.map(x => x.node)"
          ></transactions-table>
        </template>
      </apollo-query>
    </div>
  </div>
</template>
<script>
import TransactionsTable from "./TransactionsTable";
export default {
  name: "ProductDetailPageTransactions",
  components: { TransactionsTable },
  props: {
    product: Object
  }
};
</script>
