<template>
  <b-row
    v-if="
      !this.loadingCategories &&
        !this.loadingProducts &&
        !this.loadingCustomersCategories
    "
  >
    <b-col md="12">
      <b-card no-body>
        <h4 class="font-weight-bold m-3">{{ title }}</h4>
        <b-alert
          v-model="saved"
          :variant="errorSaving ? 'danger' : 'success'"
          dismissible
        >
          {{ messageAlert() }}
        </b-alert>
        <b-alert :show="formErrors && !valid" variant="danger" class="m-4">
          No se pudo crear la promoción debido a:
          <ul>
            <li v-for="(error, index) of errorList" :key="index">
              {{ error }}
            </li>
          </ul>
        </b-alert>
        <b-row class="m-3">
          <menu-buttons-form-promotion
            :changed="changed"
            :saving="saving"
            :save="save"
            :nothing_change="nothing_change"
          />
          <b-col md="12">
            <b-row>
              <b-col md="12">
                <!-- Seccion Nombre y Cupones -->
                <b-card no-body>
                  <b-form-group
                    class="m-3"
                    label="* Nombre del descuento"
                    label-for="name"
                    :invalid-feedback="validation.name.invalidFeedback"
                    :state="validation.name.valid"
                  >
                    <b-form-input
                      id="name"
                      v-model="name"
                      placeholder="Ej: Super Descuento"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="m-3"
                    :invalid-feedback="validation.coupons.invalidFeedback"
                    :state="validation.coupons.valid"
                  >
                    <b-form-checkbox
                      size="sm"
                      v-model="checked_coupons"
                      id="active_cupons"
                      switch
                    >
                      Descuento con cupón
                    </b-form-checkbox>
                    <promotion-edit-jumpseller-coupons
                      v-if="checked_coupons"
                      v-model="coupons"
                      @change="change"
                      @valid="updateCouponsValidation"
                      ref="packChanges"
                    ></promotion-edit-jumpseller-coupons>
                    <small class="form-text text-muted" v-else>
                      Opcionalmente establece cupones para esta promoción. Esto
                      no es necesario para que la promoción funcione.
                    </small>
                  </b-form-group>
                </b-card>
                <!-- Seccion Tipo de Promocion-->
                <b-card no-body class="mt-2">
                  <div class="m-3">
                    <p class="h5 mb-2">Aplicar a</p>
                    <b-form-group v-slot="{ ariaDescribedby }">
                      <b-form-radio-group
                        id="radio-group-2"
                        v-model="selected_discount"
                        :aria-describedby="ariaDescribedby"
                        name="radio-sub-component"
                        stacked
                      >
                        <b-form-radio value="order"
                          >Subtotal<small class="form-text text-muted"
                            >Aplicado al subtotal del pedido. La promoción no se
                            muestra en las páginas del producto.</small
                          ></b-form-radio
                        >
                        <b-form-radio value="second" disabled
                          >Envío<small class="form-text text-muted"
                            >Deshabilitado. Este tipo de promoción solo se puede
                            crear desde la aplicación de Jumpseller.</small
                          ></b-form-radio
                        >
                        <b-form-radio value="categories"
                          >Productos Seleccionados<small
                            class="form-text text-muted"
                            >Se aplica al costo de los productos especificados.
                            El descuento se muestra en la página del producto y
                            la lista de productos.</small
                          ></b-form-radio
                        >
                        <b-form-radio value="buy_x_get_y"
                          >Compra X, recibe Y<small class="form-text text-muted"
                            >Si el cliente compra el producto X, el descuento se
                            aplica al producto Y.</small
                          ></b-form-radio
                        >
                      </b-form-radio-group>
                    </b-form-group>
                  </div>
                </b-card>
                <!-- Seccion Tipo Descuento -->
                <b-card no-body class="mt-2">
                  <div class="m-3" v-if="selected_discount != 'buy_x_get_y'">
                    <p class="mb-2 h5">Descuento</p>
                    <div class="form-group mb-0">
                      <b-row>
                        <b-col md="6">
                          <b-form-group
                            label="Tipo"
                            label-for="discount_type_"
                            :state="validation.discount_type.valid"
                            :invalid-feedback="
                              validation.discount_type.invalidFeedback
                            "
                          >
                            <v-select
                              id="discount_type_"
                              :options="discount_types"
                              placeholder="Seleccionar"
                              v-model="discount_type"
                              :reduce="cond => cond.value"
                            ></v-select>
                          </b-form-group>
                        </b-col>
                        <b-col md="6" v-if="discount_type == 'fix'">
                          <b-form-group
                            label="* Monto"
                            label-for="amount"
                            :state="validation.amount.valid"
                            :invalid-feedback="
                              validation.amount.invalidFeedback
                            "
                          >
                            <b-input
                              id="amount"
                              v-model="amount"
                              type="number"
                              placeholder="0"
                              min="0"
                            ></b-input>
                          </b-form-group>
                        </b-col>
                        <b-col
                          md="6"
                          v-if="
                            discount_type == 'percentage' &&
                              selected_discount != 'buy_x_get_y'
                          "
                        >
                          <b-form-group
                            label="* Porcentaje"
                            label-for="percentage"
                            :state="validation.percentage.valid"
                            :invalid-feedback="
                              validation.percentage.invalidFeedback
                            "
                          >
                            <b-input
                              id="percentage"
                              v-model="percentage"
                              type="number"
                              placeholder="0"
                              min="0"
                            ></b-input>
                          </b-form-group>
                        </b-col>
                        <b-col md="12">
                          <b-form-group>
                            <b-form-checkbox size="sm" v-model="acumulative"
                              >Acumulativa</b-form-checkbox
                            >
                            <small class="form-text text-muted">
                              El descuento será acumulado sobre cualquier otro
                              descuento activo acumulable.
                            </small>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </div>
                  </div>

                  <div class="m-3" v-else>
                    <p class="mb-2 h4">Reglas</p>
                    <p class="mb-2 h5">Si compras:</p>
                    <div class="form-group mb-0">
                      <b-row>
                        <b-col md="6">
                          <b-form-group
                            label="Cantidad"
                            label-for="amount"
                            :state="validation.quantity_x.valid"
                            :invalid-feedback="
                              validation.quantity_x.invalidFeedback
                            "
                          >
                            <b-input
                              id="quantity_x"
                              type="number"
                              min="1"
                              v-model="quantity_x"
                              placeholder="1"
                            ></b-input>
                          </b-form-group>
                        </b-col>
                        <b-col md="6">
                          <b-form-group
                            label="de cualquiera de estos productos"
                            label-for="products_lst"
                          >
                            <b-spinner
                              label="Spinning"
                              v-if="loadingCategories"
                            ></b-spinner>
                            <base-live-select
                              id="products_lst"
                              v-model="products_x"
                              @search="updateProducts"
                              placeholder="Seleccionar"
                              :clearable="false"
                              :multiple="true"
                              v-else
                            >
                            </base-live-select>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </div>
                    <p class="mb-2 h5">Obtiene:</p>
                    <b-row>
                      <b-col md="6">
                        <b-form-group
                          label="Tipo de Descuento"
                          label-for="discount_type"
                          :state="validation.discount_type.valid"
                          :invalid-feedback="
                            validation.discount_type.invalidFeedback
                          "
                        >
                          <v-select
                            id="discount_type"
                            :options="discount_types"
                            placeholder="Seleccionar"
                            v-model="discount_type"
                            :reduce="cond => cond.value"
                          ></v-select>
                        </b-form-group>
                      </b-col>
                      <b-col md="6" v-if="discount_type == 'fix'">
                        <b-form-group
                          label="* Monto de Descuento"
                          label-for="amount"
                          :state="validation.amount.valid"
                          :invalid-feedback="validation.amount.invalidFeedback"
                        >
                          <b-input
                            id="amount"
                            v-model="amount"
                            type="number"
                            placeholder="0"
                          ></b-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="6" v-if="discount_type == 'percentage'">
                        <b-form-group
                          label="* Porcentaje"
                          label-for="percentage"
                          :state="validation.percentage.valid"
                          :invalid-feedback="
                            validation.percentage.invalidFeedback
                          "
                        >
                          <b-input
                            id="amount"
                            type="number"
                            v-model="percentage"
                            placeholder="0"
                          ></b-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group
                          label="En (Cantidad)"
                          label-for="quantity_products_Y"
                        >
                          <b-input
                            id="quantity_products_Y"
                            v-model="quantity_products_Y"
                            type="number"
                            min="1"
                            placeholder="1"
                          ></b-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="8">
                        <b-spinner
                          label="Spinning"
                          v-if="loadingCategories"
                        ></b-spinner>
                        <b-form-group label="de estos productos" v-else>
                          <base-live-select
                            v-model="products_y"
                            placeholder="Seleccionar"
                            @search="updateProducts"
                            :reduce="x => x.value"
                            :multiple="true"
                          ></base-live-select>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-col md="12">
                      <b-form-group>
                        <b-form-checkbox size="sm" v-model="acumulative"
                          >Acumulativa</b-form-checkbox
                        >
                        <small class="form-text text-muted">
                          El descuento será acumulado sobre cualquier otro
                          descuento descuento activo acumulable.
                        </small>
                      </b-form-group>
                    </b-col>
                  </div>
                </b-card>
                <template v-if="selected_discount !== 'buy_x_get_y'">
                  <b-card no-body class="mt-2">
                    <div class="m-3">
                      <p class="mb-2 h5">Configuración</p>
                      <b-row>
                        <b-col md="12" v-if="selected_discount === 'order'">
                          <b-form-group>
                            <b-form-checkbox
                              size="sm"
                              v-model="checked_condition_min_amount"
                              switch
                            >
                              Monto Mínimo
                            </b-form-checkbox>
                            <b-col md="12" v-if="checked_condition_min_amount">
                              <b-row>
                                <b-col md="6">
                                  <b-form-group
                                    v-if="buys_at_least === 'price'"
                                    label-for="condition_price"
                                    label="Monto Mínimo"
                                    :state="validation.condition_price.valid"
                                    :invalid-feedback="
                                      validation.condition_price.invalidFeedback
                                    "
                                  >
                                    <b-input
                                      size="sm"
                                      id="condition_price"
                                      v-model="condition_price"
                                      type="number"
                                      min="1"
                                    ></b-input>
                                  </b-form-group>
                                  <b-form-group
                                    v-if="buys_at_least === 'qty'"
                                    label-for="condition_qty"
                                    label="Cantidad"
                                    :state="validation.condition_qty.valid"
                                    :invalid-feedback="
                                      validation.condition_qty.invalidFeedback
                                    "
                                  >
                                    <b-input
                                      size="sm"
                                      id="condition_qty"
                                      v-model="condition_qty"
                                      type="number"
                                      min="1"
                                    ></b-input>
                                  </b-form-group>
                                </b-col>
                                <b-col md="6">
                                  <b-form-group
                                    label="Tipo"
                                    label-for="buys_at_least_type"
                                    :state="validation.buys_at_least_type.valid"
                                    :invalid-feedback="
                                      validation.buys_at_least_type
                                        .invalidFeedback
                                    "
                                  >
                                    <v-select
                                      id="buys_at_least_type"
                                      :options="buys_at_least_types"
                                      placeholder="Seleccionar"
                                      v-model="buys_at_least_type"
                                      :reduce="cond => cond.value"
                                    ></v-select>
                                  </b-form-group>
                                </b-col>
                              </b-row>
                            </b-col>
                            <small class="form-text text-muted">
                              Subtotal mínimo del pedido o cantidad de artículos
                              para la aplicar la promoción.
                            </small>
                          </b-form-group>
                        </b-col>
                        <b-col
                          md="12"
                          v-if="selected_discount === 'categories'"
                        >
                          <template>
                            <b-spinner
                              label="Spinning"
                              v-if="loadingCategories"
                            ></b-spinner>

                            <b-form-group label="Categorías" v-else>
                              <b-row>
                                <b-col md="12">
                                  <v-select
                                    v-model="categories"
                                    :options="allCategories"
                                    :reduce="x => x.value"
                                    placeholder="Seleccionar"
                                    :multiple="true"
                                  ></v-select>
                                </b-col>
                                <b-col md="6">
                                  <span
                                    v-if="categories.length > 0"
                                    class="small text-muted mt-1"
                                    >{{ categories.length }} Seleccionado
                                  </span>
                                </b-col>
                                <b-col md="6">
                                  <span
                                    v-if="categories.length > 0"
                                    class="small text-muted mt-1 float-right"
                                    style="cursor: pointer;"
                                    @click="removeAllCategories"
                                    >Borrar selección
                                  </span>
                                </b-col>
                              </b-row>
                            </b-form-group>
                          </template>
                          <template>
                            <b-spinner
                              label="Spinning"
                              v-if="loadingCategories"
                            ></b-spinner>

                            <b-form-group
                              label="Productos Seleccionados"
                              v-else
                            >
                              <b-row>
                                <b-col md="12">
                                  <base-live-select
                                    v-model="products"
                                    placeholder="Seleccionar"
                                    :clearable="false"
                                    @search="updateProducts"
                                    :multiple="true"
                                    :reduce="x => x.value"
                                  >
                                  </base-live-select>
                                </b-col>
                                <b-col md="6">
                                  <span
                                    v-if="products.length > 0"
                                    class="small text-muted mt-1"
                                    >{{ products.length }} Seleccionado
                                  </span>
                                </b-col>
                                <b-col md="6">
                                  <span
                                    v-if="products.length > 0"
                                    class="small text-muted mt-1 float-right"
                                    style="cursor: pointer;"
                                    @click="removeAllProducts"
                                    >Borrar selección
                                  </span>
                                </b-col>
                              </b-row>
                            </b-form-group>
                          </template>
                        </b-col>
                      </b-row>
                    </div>
                  </b-card>
                </template>
                <b-card no-body class="mt-2">
                  <div class="m-3">
                    <p class="mb-2 h5">Clientes</p>
                    <b-row>
                      <b-col md="12">
                        <b-form-group
                          label="Aplicar a:"
                          label-for="season"
                          :invalid-feedback="
                            validation.customers.invalidFeedback
                          "
                          :state="validation.customers.valid"
                        >
                          <v-select
                            id="season"
                            :options="clients_types"
                            placeholder="Seleccionar"
                            v-model="customers"
                            :reduce="cond => cond.value"
                          ></v-select>
                        </b-form-group>
                        <b-spinner
                          label="Spinning"
                          v-if="loadingCategories && customers == 'categories'"
                        ></b-spinner>
                        <template v-else>
                          <b-row>
                            <b-col md="12">
                              <b-form-group
                                label="Categorías de Clientes"
                                v-if="customers == 'categories'"
                              >
                                <v-select
                                  v-model="customer_categories"
                                  :options="allCustomerCategories"
                                  :reduce="x => x.value"
                                  :multiple="true"
                                  placeholder="Seleccionar"
                                ></v-select>
                              </b-form-group>
                            </b-col>
                            <b-col md="6">
                              <span
                                v-if="
                                  customer_categories.length > 0 &&
                                    customers == 'categories'
                                "
                                class="small text-muted mt-1"
                                >{{ customer_categories.length }} Seleccionado
                              </span>
                            </b-col>
                            <b-col md="6">
                              <span
                                v-if="
                                  customer_categories.length > 0 &&
                                    customers == 'categories'
                                "
                                class="small text-muted mt-1 float-right"
                                style="cursor: pointer;"
                                @click="removeAllCustomerCategories"
                                >Borrar selección
                              </span>
                            </b-col>
                          </b-row>
                        </template>
                      </b-col>
                    </b-row>
                  </div>
                </b-card>
                <b-card no-body class="mt-2">
                  <div class="m-3">
                    <p class="mb-2 h5">Límites</p>
                    <b-row>
                      <b-col md="12">
                        <b-form-group>
                          <b-form-checkbox
                            size="sm"
                            v-model="checked_date_limit"
                            switch
                          >
                            Fecha Límite
                          </b-form-checkbox>
                          <b-col md="12" v-if="checked_date_limit">
                            <b-form-group>
                              <date-picker
                                v-model="dateSelected"
                                type="date"
                                value-type="date"
                                range
                                :clearable="false"
                              >
                              </date-picker>
                            </b-form-group>
                          </b-col>
                          <small class="form-text text-muted">
                            El descuento puede ser válido para siempre o puede
                            establecer las fechas específicas de duración.
                          </small>
                          <small
                            class="form-text text-muted"
                            v-if="checked_date_limit"
                          >
                            La promoción estará activa desde 00:00:00
                            {{ this.dateSelected[0] | formatDate }}
                            hasta 23:59:59
                            {{ this.dateSelected[1] | formatDate }}
                            (America/Santiago).
                          </small>
                        </b-form-group>
                        <b-form-group>
                          <b-form-checkbox
                            size="sm"
                            v-model="checked_used_limit"
                            switch
                          >
                            Límite de uso
                          </b-form-checkbox>
                          <b-col md="6" v-if="checked_used_limit">
                            <b-form-group
                              label-for="max_times_used"
                              :state="validation.max_times_used.valid"
                              :invalid-feedback="
                                validation.max_times_used.invalidFeedback
                              "
                            >
                              <b-input
                                id="max_times_used"
                                v-model="max_times_used"
                                type="number"
                                min="1"
                                placeholder="1"
                              ></b-input>
                            </b-form-group>
                          </b-col>
                          <small class="form-text text-muted">
                            Especifique cuántas veces se puede utilizar el
                            descuento.
                          </small>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </div>
                </b-card>
              </b-col>
              <menu-buttons-form-promotion
                :changed="changed"
                :saving="saving"
                :save="save"
                :nothing_change="nothing_change"
              />
            </b-row>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
  <b-spinner v-else label="Spinning"></b-spinner>
</template>
<script>
import { mapState } from "vuex";
import BaseLiveSelect from "../../BaseLiveSelect";
import PromotionEditJumpsellerCoupons from "./PromotionEditJumpsellerCoupons";
import MenuButtonsFormPromotion from "./MenuButtonsFormPromotion";
import INTEGRATION_CONFIG_JUMPSELLER from "../../../graphql/IntegrationConfigJumpseller.gql";
import JUMPSELLER_GET_PRODUCTS_BY_FILTER from "../../../graphql/integrations/jumpseller/jumpsellerGetProductsByFilter.gql";
import JUMPSELLER_GET_CUSTOMER_CATEGORIES from "../../../graphql/integrations/jumpseller/jumpsellerGetCustomerCategories.gql";
import { createPromotion, updatePromotion } from "../../../main.js";

export default {
  name: "PromotionDetailPage",
  components: {
    BaseLiveSelect,
    PromotionEditJumpsellerCoupons,
    MenuButtonsFormPromotion
  },
  data() {
    return {
      name: this.promotion.name !== null ? this.promotion.name : "",
      enabled: this.promotion.enabled ? this.promotion.enabled : true,
      checked_coupons: this.promotion.coupons.length > 0,
      coupons: this.initialCouponsArray(),
      selected_discount: this.promotion.discountTarget
        ? this.promotion.discountTarget
        : "order",
      discount_types: [
        { label: "CLP", value: "fix" },
        { label: "Porcentaje", value: "percentage" }
      ],
      clients_types: [
        { label: "Todos", value: "all" },
        { label: "No registrado", value: "guests" },
        { label: "Clientes Registrados", value: "loggedin" },
        {
          label: "Clientes sin compras (Se aplica solo a la primera compra)",
          value: "nopurchases"
        },
        { label: "Clientes de Categorías Seleccionadas", value: "categories" }
      ],
      buys_at_least_types: [
        { label: "CLP", value: "price" },
        { label: "Artículos", value: "qty" }
      ],
      buys_at_least: this.getBuysAtLeast(),
      buys_at_least_type: this.getBuysAtLeastType(),
      customers: this.promotion.customers ? this.promotion.customers : "all",
      customer_categories: this.getInitialCustomerCategories(),
      discount_type: this.getDiscountType(),
      lasts: this.getLasts(),
      amount:
        this.promotion.discountAmountFix != null
          ? this.promotion.discountAmountFix
          : 0,
      percentage:
        this.promotion.discountAmountPercent != null
          ? this.promotion.discountAmountPercent
          : 0,
      categories: this.getInitialCategories(),
      products: this.getInitialProducts(),
      quantity_products_Y: this.getQuantityProductsY(),
      acumulative: this.promotion.cumulative
        ? this.promotion.cumulative
        : false,
      checked_used_limit: this.getCheckedUsedLimit(),
      checked_date_limit: this.getCheckedDateLimit(),
      max_times_used:
        this.promotion.maxTimesUsed != null ? this.promotion.maxTimesUsed : 1,
      validCoupons: true,
      checked_condition_min_amount: this.getConditionMinAmount(),
      condition_qty: this.getConditionQty(),
      condition_price: this.getConditionPrice(),
      allProducts: [], //query
      allCategories: [], //query
      allCustomerCategories: [], //query
      products_x: [],
      quantity_x: this.getQuantityX(),
      products_y: [],
      formErrors: false,
      errorSaving: false,
      msg_error: "",
      saved: false,
      saving: false,
      changed: true,
      nothing_change: true,
      consolidated: this.isConsolidated(),
      dateSelected: this.getDateSelected()
    };
  },
  async created() {
    await this.awaitForCategories();
    await this.awaitForCustomerCategories();
    this.awaitForProducts();
  },
  props: {
    promotion: Object,
    integrationConfig: String,
    title: String,
    changePromotion: Function,
    errorIntegrationConfig: Function,
    loadingCategories: Boolean,
    changeLoadingCategories: Function,
    loadingProducts: Boolean,
    changeLoadingProducts: Function,
    loadingCustomersCategories: Boolean,
    changeLoadingCustomersCategories1: Function
  },
  methods: {
    // Permite volver a Guardar/editar si algun campo del formulario
    //es modificado (boton guardar se bloquea si no hay cambios)
    change() {
      this.changed = true;
      //this.$emit("change", true);
    },
    // Indica si se trata de una creación o una edición de promoción
    isConsolidated() {
      if (this.$route.name == "PromotionDetail") {
        return false;
      } else {
        return true;
      }
    },
    //A partir de un objeto con propiedades id y name,
    //construye la estructura para una opcion de un selector cualquiera
    itemForSelect(item) {
      if (item) {
        return { value: item.id, label: item.name };
      }
      return null;
    },
    // Actualiza listado de productos según filtros ingresados por el usuario
    // Realiza query según filtro y la id de la integración
    async updateProducts(search, loading, setOptions) {
      loading(true);
      this.$apollo
        .query({
          query: JUMPSELLER_GET_PRODUCTS_BY_FILTER,
          variables: { filter: search, ic_id: this.integrationConfig }
        })
        .then(async result => {
          if (this.$dig(result, "data", "jumpsellerGetProductsByFilter")) {
            setOptions(
              result.data.jumpsellerGetProductsByFilter.map(x => {
                return this.itemForSelect(x);
              })
            );
          }
          loading(false);
        });
    },
    //Mensaje de Respuesta para el usuario de la creación/edición promoción
    messageAlert() {
      if (this.errorSaving && this.msg_error == "") {
        return "Ha ocurrido un error";
      } else if (this.msg_error != "") {
        return this.msg_error;
      } else if (this.consolidated) {
        return "La promoción ha sido guardada exitosamente";
      } else {
        return "La promoción ha sido creada exitosamente";
      }
    },

    //Función que consulta por el conjunto de categorías de producto completo (no hay paginamiento)
    async awaitForCategories() {
      await this.$apollo
        .query({
          query: INTEGRATION_CONFIG_JUMPSELLER,
          variables: {
            id: this.integrationConfig
          }
        })
        .then(({ data }) => {
          if (this.$dig(data, "integrationConfigJumpseller", "categories")) {
            const categoryHash = {};
            data.integrationConfigJumpseller.categories.forEach(x => {
              categoryHash[x.category.id] = x;
            });
            this.allCategories = data.integrationConfigJumpseller.categories.map(
              x => {
                let name = x.category.name;
                let parentId = x.category.parent_id;
                while (parentId) {
                  name = categoryHash[parentId].category.name + " > " + name;
                  parentId = categoryHash[parentId].category.parent_id;
                }
                return { label: name, value: x.category.id };
              }
            );
            this.$watch("categories", this.change);
          }
          this.categories = this.getInitialCategories();
          this.$emit("changeLoadingCategories");
        })
        .catch(error => {
          this.$emit("changeLoadingCategories");
          this.$emit("errorIntegrationConfig", String(error));
        });
    },
    //Función que consulta por el conjunto de categorías de clientes
    //PENDIENTE: se debería recorrer todo el paginamiento
    async awaitForCustomerCategories() {
      await this.$apollo
        .query({
          query: JUMPSELLER_GET_CUSTOMER_CATEGORIES,
          variables: {
            ic_id: this.integrationConfig,
            limit: 100,
            offset: 1
          }
        })
        .then(({ data }) => {
          if (this.$dig(data, "jumpsellerGetCustomerCategories")) {
            this.allCustomerCategories = data.jumpsellerGetCustomerCategories.map(
              x => {
                let name = x.name;
                return { label: name, value: x.id };
              }
            );
            this.$watch("customer_categories", this.change);
          }
          this.customer_categories = this.getInitialCustomerCategories();
          this.$emit("changeLoadingCustomersCategories");
        })
        .catch(error => {
          this.$emit("changeLoadingCustomersCategories");
          this.$emit("errorIntegrationConfig", String(error));
        });
    },
    /**
     * Transforma la información de productos que viene en la promoción de una
     * manera que sea útil para los selectores
     */
    awaitForProducts() {
      this.allProducts = this.promotion.products.map(prod => ({
        label: prod.name,
        value: prod.id
      }));
      this.products = this.allProducts;
      this.$emit("changeLoadingProducts");
    },
    //Elimina rapidamente el conjunto de categorias de producto seleccionadas por el usuario
    removeAllCategories() {
      this.categories = [];
    },
    //Elimina rapidamente el conjunto de categorias de clientes seleccionadas por el usuario
    removeAllCustomerCategories() {
      this.customer_categories = [];
    },
    //Elimina rapidamente el conjunto de productos seleccionadas por el usuario
    removeAllProducts() {
      this.products = [];
    },
    //Determina conjunto inicial de cupones asociados a la promocion actual para mostrar en formulario
    initialCouponsArray() {
      return this.promotion.coupons.map(c => {
        return {
          code: c.code,
          times_used: c.timesUsed,
          unlimited: c.usageLimit == 0,
          usage_limit: c.usageLimit
        };
      });
    },
    //Determina conjunto inicial de productos seleccionado asociados a la promocion actual
    getInitialProducts() {
      return [];
    },
    //Determina conjunto inicial de categorías de producto seleccionadas asociadas a la promocion actual
    getInitialCategories() {
      return this.getInitialCategoriesByType(
        this.allCategories,
        this.promotion.categories
      );
    },
    //Determina conjunto inicial de categorías de cliente seleccionadas asociadas a la promocion actual
    getInitialCustomerCategories() {
      return this.getInitialCategoriesByType(
        this.allCustomerCategories,
        this.promotion.customerCategories
      );
    },
    //Determina conjunto inicial de elementos a la promocion actual
    //records: conjunto total de elementos
    //field: campo de la promocion que indica que elementos de records estan asociados a la promocion
    getInitialCategoriesByType(records, field) {
      if (!records || !field) {
        return [];
      }
      const categoryIds = field.map(c => c.id);
      return records
        .filter(c => categoryIds.includes(c.value))
        .map(c => c.value);
    },
    //Determina tipo de descuento de la promocion actual
    //Por defecto es "fix" (dscto en monto)
    getDiscountType() {
      if (this.promotion.discountAmountFix) {
        if (this.promotion.discountAmountFix != 0) {
          return "fix";
        }
      }
      if (this.promotion.discountAmountPercent) {
        if (this.promotion.discountAmountPercent != 0) {
          return "percentage";
        }
      }
      return "fix";
    },
    //Determina condiciones de límites actuales de la promocion
    //por defecto no hay condiciones de limites ("none")
    // Se puede tener limites temporal (rango fechas) o por cantidad de usos de la promocion
    getLasts() {
      const date_cond = this.promotion.beginsAt;
      const qty_cond = this.promotion.maxTimesUsed;
      if (date_cond && qty_cond) return "both";
      if (date_cond) return "date";
      if (qty_cond) return "max_times_used";
      return "none";
    },
    //Entrega verdadero si existe alguna condicion de monto minimo
    getConditionMinAmount() {
      if (this.promotion.conditionPrice > 0 || this.promotion.conditionQty > 0)
        return true;
      return false;
    },
    getConditionQty() {
      return this.promotion.conditionQty ? this.promotion.conditionQty : 0;
    },
    getConditionPrice() {
      return this.promotion.conditionPrice ? this.promotion.conditionPrice : 0;
    },
    //Retorna el tipo de condicion de monto minimo actual
    getBuysAtLeast() {
      if (this.promotion.conditionPrice > 0) return "price";
      if (this.promotion.conditionQty > 0) return "qty";
      return "none";
    },
    getBuysAtLeastType() {
      if (this.promotion.conditionPrice > 0) return "price";
      if (this.promotion.conditionQty > 0) return "qty";
      return "price";
    },
    //Determina si hay limite temporal para la promocion actual
    getCheckedDateLimit() {
      if (this.promotion.beginsAt) return true;
      return false;
    },
    //Determina si hay limite de usos para la promocion actual
    getCheckedUsedLimit() {
      if (this.promotion.maxTimesUsed) return this.promotion.maxTimesUsed > 0;
      return false;
    },
    //Determina cantidad X de productos asociados a la promocion actual, si es de tipo "lleve X , recibe Y"
    getQuantityX() {
      return this.promotion.quantityX ? this.promotion.quantityX : 1;
    },
    //Determina cantidad Y de productos asociados a la promocion actual, si es de tipo "lleve X, recibe Y"
    getQuantityProductsY() {
      if (!this.promotion.discountTarget || !this.promotion.conditionQty) {
        return 1;
      }
      if (this.promotion.discountTarget == "buy_x_get_y") {
        return this.promotion.conditionQty;
      }
      return 1;
    },
    //Determina fechas asociadas en que la promoción es válida
    //Por defecto, se considera la fecha actual
    getDateSelected() {
      if (this.promotion.beginsAt) {
        return [
          new Date(this.promotion.beginsAt),
          new Date(this.promotion.expiresAt)
        ];
      }
      return [
        new Date(),
        this.$moment(new Date())
          .add(1, "week")
          .toDate()
      ];
    },
    //Chequea la validez del conjunto de cupones asociadas a la promocion actual
    //Se chequea si el checkbox esta activo y no hay cupones asociados
    //Se chequea si los codigos de cupon son todos distintos entre ellos
    //Se chequea si los codigos de cupon estan vacios
    updateCouponsValidation() {
      if (!this.checked_coupons) return true;
      if (this.checked_coupons && this.coupons.length == 0) {
        return false;
      }
      const empty_codes = this.coupons.filter(
        coupon => coupon.code.trim().length !== 0
      );
      const unique_codes = [
        ...new Set(
          this.coupons.map(coupon => {
            return coupon.code;
          })
        )
      ];
      return (
        empty_codes.length === this.coupons.length &&
        unique_codes.length === this.coupons.length
      );
    },
    //Mensaje feedback segun chequeo de cupones asociados a la promocion
    messageFeedbackCoupons() {
      var ans = [];
      if (this.checked_coupons && this.coupons.length == 0) {
        ans = ans.concat("Debe contar con al menos con un cupón.");
      }
      const empty_codes = this.coupons.filter(
        coupon => coupon.code.trim().length !== 0
      );
      const unique_codes = [
        ...new Set(
          this.coupons.map(coupon => {
            return coupon.code;
          })
        )
      ];
      if (empty_codes.length !== this.coupons.length)
        ans = ans.concat("Los códigos de cupón no pueden ser vacíos.");
      if (unique_codes.length !== this.coupons.length)
        ans = ans.concat("Los códigos de cupón no pueden repetirse.");

      return ans.join(" ");
    },
    //Registrar valor actual de condicion de promocion ("none", "price", "qty")
    check_buys_at_least(val) {
      if (!val) {
        this.buys_at_least = "none";
      } else {
        this.buys_at_least = this.buys_at_least_type;
      }
    },
    //Validar todos los campos del formulario
    validate() {
      if (!this.valid) {
        this.formErrors = true;
        return false;
      }
      this.formErrors = false;
      return true;
    },
    //Valida y si no hay problemas crea/edita promocion de acuerdo a informacion del formulario
    async save() {
      if (!this.validate()) return;
      this.saving = true;
      this.saved = false;
      this.errorSaving = false;
      this.msg_error = "";
      this.errorsSaving = {};
      if (this.consolidated) {
        await this.updatePromotion();
      } else {
        await this.createPromotion();
      }
      this.saving = false;
      this.saved = true;
      if (!this.errorSaving) {
        this.changed = false;
        this.nothing_change = false;
        //this.$emit("change", false);
      }
      //Scrollear al tope la pagina web,
      //esto para visualizar el mensaje de respuesta
      //en caso de que se haya usado el menu de botones inferior del formulario
      window.scrollTo(0, 0);
    },
    //Realiza servicio completo de actualizacion de promocion
    async updatePromotion() {
      const updatePromotiontData = this.updatedPromotionData();
      if (updatePromotiontData === null) {
        this.errorSaving = false;
        this.msg_error = "";
        return;
      }
      await updatePromotion(
        this.$apollo,
        this.integrationConfig,
        this.promotion.id,
        updatePromotiontData
      )
        .then(async ({ data }) => {
          if (this.$dig(data, "jumpsellerUpdatePromotion", "result")) {
            this.errorSaving = false;
            this.msg_error = "";
            this.consolidated = true;
            this.$emit(
              "changePromotion",
              data.jumpsellerUpdatePromotion.promotion
            );
          } else {
            this.errorSaving = true;
            this.msg_error = data.jumpsellerUpdatePromotion.error;
          }
        })
        .catch(error => {
          this.errorSaving = true;
          this.msg_error = error;
        });
    },
    //Realiza servicio completo de actualizacion de promocion
    async createPromotion() {
      const createPromotionData = this.updatedPromotionData();
      if (createPromotionData === null) {
        this.errorSaving = false;
        this.msg_error = "";
        return;
      }
      await createPromotion(
        this.$apollo,
        this.integrationConfig,
        createPromotionData
      )
        .then(async ({ data }) => {
          if (
            data &&
            data.jumpsellerCreatePromotion &&
            data.jumpsellerCreatePromotion.result
          ) {
            this.errorSaving = false;
            this.msg_error = "";
            this.consolidated = true;
            this.$emit(
              "changePromotion",
              data.jumpsellerCreatePromotion.promotion
            );
          } else {
            this.errorSaving = true;
            this.msg_error = data.jumpsellerCreatePromotion.error;
          }
        })
        .catch(error => {
          this.errorSaving = true;
          this.msg_error = error;
        });
    },
    //Construye objeto promocion para peticiones de creacion y actualizacion
    updatedPromotionData() {
      const changes = {};
      changes.name = this.name;
      changes.enabled = this.enabled;
      changes.discountTarget = this.selected_discount;
      changes.buysAtLeast =
        this.selected_discount !== "buy_x_get_y" ? this.buys_at_least : "qty";
      changes.conditionPrice = Number(this.condition_price);
      changes.conditionQty =
        this.selected_discount !== "buy_x_get_y"
          ? Number(this.condition_qty)
          : Number(this.quantity_products_Y);
      changes.quantityX = Number(this.quantity_x);
      changes.type = this.discount_type;
      changes.discountAmountFix =
        this.discount_type == "fix" ? Number(this.amount) : 0;
      changes.discountAmountPercent =
        this.discount_type == "percentage" ? Number(this.percentage) : 0;
      changes.lasts = this.lasts;
      changes.beginsAt = this.$options.filters.formatDate(this.dateSelected[0]);
      changes.expiresAt = this.$options.filters.formatDate(
        this.dateSelected[1]
      );
      changes.maxTimesUsed = Number(this.max_times_used);
      changes.cumulative = this.acumulative;
      changes.customers = this.customers;
      changes.categories = this.buildCategoriesCreation;
      changes.customerCategories = this.buildCustomerCategoriesCreation;
      changes.products = this.buildProductsCreation;
      changes.productsX = this.buildProductsXCreation;
      changes.coupons = this.buildCouponsFormatCreation;
      return changes;
    },
    //Determina cual es el valor actual para los limites asociados a la promocion
    checked_lasts(checked_date_limit, checked_used_limit) {
      if (checked_date_limit && checked_used_limit) return "both";
      if (checked_date_limit && !checked_used_limit) return "date";
      if (!checked_date_limit && checked_used_limit) return "max_times_used";
      return "none";
    },
    //Construye arreglo con objetos con propiedad id para envio de informacion a peticiones
    buildIdsArrayByField(field) {
      return field.map(id => {
        return {
          id: String(id)
        };
      });
    }
  },
  computed: {
    ...mapState(["currentUser"]),
    //Validaciones explicitas de cada campo del formulario
    validation() {
      return {
        name: {
          valid: this.name.length > 0,
          invalidFeedback: "El nombre es obligatorio"
        },
        amount: {
          valid:
            (this.amount > 0 && this.discount_type == "fix") ||
            this.discount_type != "fix",
          invalidFeedback: "El monto de descuento debe ser mayor a cero"
        },
        percentage: {
          valid:
            (this.percentage > 0 &&
              this.percentage <= 100 &&
              this.discount_type == "percentage") ||
            this.discount_type != "percentage",
          invalidFeedback:
            "El porcentaje de descuento debe ser mayor a cero y menor a 100"
        },
        quantity_x: {
          valid: this.quantity_x >= 1,
          invalidFeedback:
            "La cantidad de productos a comprar debe ser mínimo 1"
        },
        customers: {
          valid: this.customers !== null,
          invalidFeedback: "Debe seleccionar una opción"
        },
        max_times_used: {
          valid: this.max_times_used > 0 || !this.checked_used_limit,
          invalidFeedback: "El Límite de uso debe ser mayor a cero"
        },
        coupons: {
          valid: this.updateCouponsValidation(),
          invalidFeedback:
            "Revisa información de cupones. " + this.messageFeedbackCoupons()
        },
        condition_price: {
          valid:
            (this.condition_price > 0 && this.buys_at_least === "price") ||
            !this.checked_condition_min_amount ||
            this.buys_at_least !== "price",
          invalidFeedback: "El monto mínimo debe ser mayor a cero"
        },
        condition_qty: {
          valid:
            (this.condition_qty > 0 && this.buys_at_least === "qty") ||
            !this.checked_condition_min_amount ||
            this.buys_at_least !== "qty",
          invalidFeedback: "La cantidad de artículos debe ser mayor a cero"
        },
        buys_at_least_type: {
          valid:
            this.buys_at_least_type == "price" ||
            this.buys_at_least_type == "qty",
          invalidFeedback: "Debe seleccionar una opción"
        },
        discount_type: {
          valid: this.discount_type !== null,
          invalidFeedback: "Debe seleccionar un tipo de descuento"
        }
      };
    },
    //Valida el conjunto de validaciones completo
    valid() {
      let valid = true;
      Object.keys(this.validation).forEach(x => {
        valid = valid && this.validation[x].valid;
      });
      return valid;
    },
    //Almacena arreglo con errores encontrados en el proceso de validacion
    errorList() {
      return Object.keys(this.validation)
        .map(key => {
          return this.validation[key].valid
            ? null
            : this.validation[key].invalidFeedback;
        })
        .filter(x => x !== null);
    },
    //arreglo con informacion de cupones para enviarse a la peticion
    buildCouponsFormatCreation() {
      if (!this.checked_coupons) return [];
      return this.coupons.map(obj => {
        return {
          code: obj.code.trim(), //delete blank space
          usageLimit: obj.unlimited ? 0 : Number(obj.usage_limit)
        };
      });
    },
    //arreglo con ids de categorias de producto para enviarse a la peticion
    buildCustomerCategoriesCreation() {
      if (this.customers !== "categories") return [];
      return this.buildIdsArrayByField(this.customer_categories);
    },
    //arreglo con ids de categorias de cliente para enviarse a la peticion
    buildCategoriesCreation() {
      if (this.selected_discount !== "categories") return [];
      return this.buildIdsArrayByField(this.categories);
    },
    //arreglo con ids de productos para enviarse a la peticion
    buildProductsCreation() {
      if (this.selected_discount !== "categories") return [];
      return this.products.map(obj => {
        return {
          id: String(obj.value)
        };
      });
    },
    //arreglo con ids de productos X para enviarse a la peticion
    buildProductsXCreation() {
      return this.products_x.map(obj => {
        return {
          id: String(obj.value)
        };
      });
    }
  },
  watch: {
    name() {
      this.change();
    },
    checked_coupons() {
      this.change();
    },
    coupons: {
      handler() {
        this.change();
      },
      deep: true
    },
    amount() {
      this.change();
    },
    percentage() {
      this.change();
    },
    selected_discount() {
      this.change();
    },
    customers() {
      this.change();
    },
    checked_condition_min_amount(val) {
      this.check_buys_at_least(val);
      this.change();
    },
    buys_at_least_type(val) {
      this.buys_at_least = val;
      this.change();
    },
    checked_date_limit(val) {
      this.lasts = this.checked_lasts(val, this.checked_used_limit);
      this.change();
    },
    checked_used_limit(val) {
      this.lasts = this.checked_lasts(this.checked_date_limit, val);
      this.change();
    },
    dateSelected() {
      this.change();
    },
    max_times_used() {
      this.change();
    }
  }
};
</script>
<style scoped>
.text-muted {
  font-size: 12px !important;
  font-weight: bold;
  color: #aab1b5 !important;
}
.prices {
  margin-top: 15px;
}
.main-price {
  font-weight: bold;
}
.compare-price {
  font-weight: bold;
  color: #aab1b5;
  text-decoration-line: line-through;
  margin-left: 10px;
}
.centry-no-image {
  background-color: #dddddd;
  padding-top: 100%;
  position: relative;
}

.centry-no-image svg {
  position: absolute;
  top: 50%;
  left: 50%;
  bottom: 0;
  right: 0;
}
</style>
