<template>
  <div>
    <base-header
      :titleSize="8"
      title="Promociones de Mercado Libre"
      title-link="/mercadolibre/promotions"
      :map="['Mercado Libre', 'Integraciones', 'Promociones']"
    >
    </base-header>

    <b-container fluid>
      <b-row v-if="Object.keys(errorResponse).length === 0">
        <b-col md="12">
          <b-table-simple v-if="promotions.length > 0">
            <b-thead>
              <b-tr>
                <b-th>Tipo</b-th>
                <b-th>Nombre</b-th>
                <b-th>Fecha Inicio</b-th>
                <b-th>Fecha Termino</b-th>
                <b-th>Estado</b-th>
                <b-th></b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="promotion of promotions" :key="promotion.id">
                <b-td>{{ promotion.type | meliDealToCentry }}</b-td>
                <b-td>{{ promotion.name }}</b-td>
                <b-td>{{
                  new Date(promotion.start_date).toLocaleString()
                }}</b-td>
                <b-td>{{
                  promotion.finish_date
                    ? new Date(promotion.finish_date).toLocaleString()
                    : ""
                }}</b-td>
                <b-td>{{ promotion.status | meliDealStatusToCentry }}</b-td>
                <b-td class="actioncell">
                  <b-dropdown
                    variant="white"
                    no-caret
                    offset="-110"
                    v-if="promotion.type !== 'PRE_NEGOTIATED'"
                  >
                    <template v-slot:button-content>
                      <b-icon-three-dots-vertical></b-icon-three-dots-vertical>
                    </template>
                    <b-dropdown-item
                      v-if="promotion.type === 'DEAL'"
                      :to="$route.path + '/deals/' + promotion.id"
                      >Detalle</b-dropdown-item
                    >
                    <b-dropdown-item
                      v-if="promotion.type === 'MARKETPLACE_CAMPAIGN'"
                      :to="
                        $route.path + '/marketplace_campaign/' + promotion.id
                      "
                      >Detalle</b-dropdown-item
                    >
                    <b-dropdown-item
                      v-if="promotion.type === 'DOD'"
                      :to="$route.path + '/deal_of_the_day/' + promotion.id"
                      >Detalle</b-dropdown-item
                    >
                    <b-dropdown-item
                      v-if="promotion.type === 'LIGHTNING'"
                      :to="$route.path + '/lightning/' + promotion.id"
                      >Detalle</b-dropdown-item
                    >
                    <b-dropdown-item
                      v-if="promotion.type === 'VOLUME'"
                      :to="$route.path + '/volume/' + promotion.id"
                      >Detalle</b-dropdown-item
                    >
                    <b-dropdown-item
                      v-if="promotion.type === 'SMART'"
                      :to="$route.path + '/smart/' + promotion.id"
                      >Detalle</b-dropdown-item
                    >
                  </b-dropdown>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
          <div v-else>
            No hay promociones registradas para la integración consultada
          </div>
        </b-col>
      </b-row>
      <b-row v-else>
        <p>Hubo un error:</p>
        <p>{{ errorResponse }}</p>
        <p v-if="checkUnauthorizedError">
          Tu cuenta de Mercado Libre no esta autorizada para acceder a este
          recurso.
        </p></b-row
      >
    </b-container>
  </div>
</template>

<script>
import BaseHeader from "../components/BaseHeader.vue";
import INTEGRATION_CONFIG_MERCADO_LIBRE from "../graphql/IntegrationConfigMercadoLibre.gql";
import MERCADO_LIBRE_GET_DEALS from "../graphql/MercadoLibreGetDeals.gql";
export default {
  name: "MercadoLibrePromotionsIndex",
  props: {
    integrationConfigId: {
      type: String,
      required: true
    }
  },
  components: {
    BaseHeader
  },
  data() {
    return {
      integrationConfigMeli: {},
      promotions: [],
      errorResponse: {}
    };
  },
  beforeMount() {
    this.getIntegrationConfig();
  },
  methods: {
    getIntegrationConfig() {
      this.$apollo
        .query({
          query: INTEGRATION_CONFIG_MERCADO_LIBRE,
          variables: { id: this.integrationConfigId }
        })
        .then(result => {
          this.integrationConfigMeli =
            result.data.integrationConfigMercadoLibre;
          this.getPromotions();
        });
    },
    async getPromotions() {
      this.$apollo
        .mutate({
          mutation: MERCADO_LIBRE_GET_DEALS,
          variables: { integrationConfigId: this.integrationConfigId }
        })
        .then(result => {
          const { error } = result.data.mercadoLibreGetDeals.result;
          if (error != null) {
            this.errorResponse = result.data.mercadoLibreGetDeals.result;
          } else {
            let promotions = result.data.mercadoLibreGetDeals.result;
            this.promotions = promotions.results;
            this.filterPromotions();
          }
        });
    },
    filterPromotions() {},
    checkUnauthorizedError() {
      return (
        this.errorResponse.status == 401 &&
        this.errorResponse.error == "unauthorized_scopes"
      );
    }
  }
};
</script>

<style scoped>
.modal-body h2 {
  text-align: center;
}

input {
  margin-top: 10px;
  margin-bottom: 10px;
}
.b-form-datepicker {
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
