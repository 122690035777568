<template>
  <div>
    <b-row>
      <b-col v-if="['regular', 'forThePriceOf'].indexOf(promotion.type) != -1">
        <b-row>
          <b-col>
            <b-form-checkbox v-model="activeValueFloor">
              Valor minimo del pedido
            </b-form-checkbox>
          </b-col>

          <b-col>
            <b-input-group>
              <b-input-group-prepend is-text>
                $
              </b-input-group-prepend>
              <b-form-input
                type="number"
                min="0"
                v-model="totalValueFloor"
                :disabled="!activeValueFloor"
              >
              </b-form-input>
            </b-input-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-checkbox v-model="activeValueCeling">
              Valor maximo del pedido
            </b-form-checkbox>
          </b-col>

          <b-col>
            <b-input-group>
              <b-input-group-prepend is-text>
                $
              </b-input-group-prepend>
              <b-form-input
                type="number"
                min="0"
                v-model="totalValueCeling"
                :disabled="!activeValueCeling"
              >
              </b-form-input>
            </b-input-group>
          </b-col>
        </b-row>

        <b-row v-show="activeValueFloor || activeValueCeling">
          <b-col>
            <b-input-group size="sm">
              <b-input-group-prepend is-text>
                Artículos a considerar
              </b-input-group-prepend>
              <v-select
                v-model="totalValueMode"
                :options="totalValueModeOptions"
                :clearable="false"
              ></v-select>
            </b-input-group>
          </b-col>
        </b-row>
      </b-col>

      <b-col v-if="['regular'].indexOf(promotion.type) != -1">
        <b-row>
          <b-col>
            <b-form-checkbox v-model="activeValuePurchase">
              Valor acumulado de las compras
            </b-form-checkbox>
          </b-col>

          <b-col>
            <b-input-group>
              <b-input-group-prepend is-text>
                $
              </b-input-group-prepend>
              <b-form-input
                type="number"
                min="0"
                v-model="totalValuePurchase"
                :disabled="!activeValuePurchase"
              >
              </b-form-input>
            </b-input-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-checkbox v-model="activeItemBetweenPrice">
              Valor del artículo entre
            </b-form-checkbox>
          </b-col>

          <b-col>
            <b-input-group>
              <b-input-group-prepend is-text>
                $
              </b-input-group-prepend>
              <b-form-input
                type="number"
                min="0"
                v-model="itemMinPrice"
                :disabled="!activeItemBetweenPrice"
              >
              </b-form-input>
            </b-input-group>
            <b-input-group>
              <b-input-group-prepend is-text>
                $
              </b-input-group-prepend>
              <b-form-input
                type="number"
                min="0"
                v-model="itemMaxPrice"
                :disabled="!activeItemBetweenPrice"
              >
              </b-form-input>
            </b-input-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
const totalValueModeOptions = [
  {
    value: "IncludeMatchedItems",
    label: "Artículos que son parte de la promoción"
  },
  {
    value: "ExcludeMatchedItems",
    label: "Artículos que no son parte de la promoción"
  },
  { value: "AllItems", label: "Todos los artículos" }
];

export default {
  name: "VtexBasePromotionConditions",
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      promotion: this.value,
      activeValueFloor: this.value.totalValueFloor > 0,
      totalValueFloor: this.value.totalValueFloor,
      activeValueCeling: this.value.totalValueCeling > 0,
      totalValueCeling: this.value.totalValueCeling,
      totalValueMode: this.totalValueModeByValue(this.value.totalValueMode),
      totalValueModeOptions: totalValueModeOptions,
      activeValuePurchase: this.value.totalValuePurchase > 0,
      totalValuePurchase: this.value.totalValuePurchase,
      activeItemBetweenPrice:
        this.value.itemMinPrice > 0 || this.value.itemMaxPrice > 0,
      itemMinPrice: this.value.itemMinPrice,
      itemMaxPrice: this.value.itemMaxPrice
    };
  },
  methods: {
    /**
     * Revisa que opción del selector de productos utilizar al activar
     * la condición de monto minimo/máximo del pedido para la promoción.
     * @param {String} value
     */
    totalValueModeByValue(value) {
      return totalValueModeOptions.find(option => option.value === value).label;
    }
  },
  watch: {
    value(newValue) {
      this.promotion = newValue;
    },
    promotion: {
      handler(newValue) {
        this.$emit("input", newValue);
      },
      deep: true
    },
    activeValueFloor(newValue) {
      if (newValue) {
        this.promotion.totalValueFloor = parseFloat(this.totalValueFloor);
      } else {
        this.promotion.totalValueFloor = 0;
      }
      if (!newValue && !this.activeValueCeling) {
        this.totalValueMode = this.totalValueModeByValue("IncludeMatchedItems");
      }
    },
    totalValueFloor(newValue) {
      this.promotion.totalValueFloor = parseFloat(newValue);
    },
    activeValueCeling(newValue) {
      if (newValue) {
        this.promotion.totalValueCeling = parseFloat(this.totalValueCeling);
      } else {
        this.promotion.totalValueCeling = 0;
      }
      if (!newValue && !this.activeValueFloor) {
        this.totalValueMode = this.totalValueModeByValue("IncludeMatchedItems");
      }
    },
    totalValueCeling(newValue) {
      this.promotion.totalValueCeling = parseFloat(newValue);
    },
    totalValueMode(newValue) {
      if (typeof newValue === "object")
        this.promotion.totalValueMode = newValue.value;
    },
    activeValuePurchase(newValue) {
      if (newValue) {
        this.promotion.totalValuePurchase = parseFloat(this.totalValuePurchase);
      } else {
        this.promotion.totalValuePurchase = 0;
      }
    },
    totalValuePurchase(newValue) {
      this.promotion.totalValuePurchase = parseFloat(newValue);
    },
    activeItemBetweenPrice(newValue) {
      if (newValue) {
        this.promotion.itemMinPrice = parseFloat(this.itemMinPrice);
        this.promotion.itemMaxPrice = parseFloat(this.itemMaxPrice);
      } else {
        this.promotion.itemMinPrice = 0;
        this.promotion.itemMaxPrice = 0;
      }
    },
    itemMinPrice(newValue) {
      this.promotion.itemMinPrice = parseFloat(newValue);
    },
    itemMaxPrice(newValue) {
      this.promotion.itemMaxPrice = parseFloat(newValue);
    }
  }
};
</script>
