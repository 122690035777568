<template>
  <div>
    <ApolloQuery
      :query="require('../../graphql/Company/CompanyInfo.gql')"
      :variables="queryVariables()"
      :fetchPolicy="'cache-and-network'"
      notifyOnNetworkStatusChange
    >
      <template slot-scope="{ result: { data }, isLoading }">
        <base-header
          title="Mi Empresa"
          :title-link="`/company/${$route.params.id}`"
        >
          <template #filters>
            <b-button
              v-if="$route.meta.action == 'Información'"
              :to="`/company/${$route.params.id}/edit`"
              id="btn-edit"
              variant="primary"
            >
              <b-icon-pencil scale="0.8" />
              Editar
            </b-button>
          </template>
        </base-header>
        <b-spinner
          v-if="isLoading"
          label="Spinning"
          class="m-2 float-right"
        ></b-spinner>
        <b-container fluid="">
          <router-view
            v-if="data && !isLoading"
            :company="data.company"
          ></router-view>
        </b-container>
      </template>
    </ApolloQuery>
    <v-tour
      name="myTour"
      :steps="steps"
      :options="options"
      :callbacks="{
        onFinish: onFinishTour
      }"
    ></v-tour>
  </div>
</template>

<script>
import BaseHeader from "../../components/BaseHeader.vue";
import UPDATE_PROFILE from "../../graphql/UpdateProfile.gql";
import { mapState } from "vuex";
export default {
  name: "Company",
  components: {
    BaseHeader
  },
  data() {
    return {
      steps: [
        {
          target: "#btn-edit", // We're using document.querySelector() under the hood
          header: { title: "Edita los datos de tu empresa" },
          content:
            "Selecciona la opción 'Editar' para modificar los datos de tu empresa"
        },
        {
          target: "#btn-new-integrations", // We're using document.querySelector() under the hood
          header: { title: "Añade nuevas integraciones" },
          content:
            "Selecciona la opción 'Vincular nuevas integraciones' para vincular tu cuenta de Centry con los marketplaces"
        }
      ],
      options: {
        labels: {
          buttonSkip: "Saltar tour",
          buttonPrevious: "Anterior",
          buttonNext: "Siguiente",
          buttonStop: "Finalizar"
        }
      }
    };
  },
  computed: {
    ...mapState(["currentUser"])
  },
  mounted() {
    if (this.currentUser.showWizard) {
      this.$tours["myTour"].start();
    }
  },
  methods: {
    /**
     * Entrega las variables para la query que permite obtener el producto
     */
    queryVariables() {
      let variables = {};
      if (this.$route.params.id) {
        variables.id = this.$route.params.id;
      }
      return variables;
    },
    /**
     * Envia la mutacion para no mostrar mas el tour cuando finaliza
     */
    onFinishTour() {
      this.$apollo.mutate({
        mutation: UPDATE_PROFILE,
        variables: {
          input: {
            showWizard: false
          }
        }
      });
    }
  }
};
</script>
