<template>
  <div>
    <base-header title="Monitoreo de cargas masivas" title-link="/">
    </base-header>
    <b-container fluid="">
      <b-row>
        <b-col md="12">
          <pending-bulk-upload-table></pending-bulk-upload-table>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import BaseHeader from "@/components/BaseHeader";
import PendingBulkUploadTable from "../../components/PendingBulkUploadTable.vue";

export default {
  name: "PendingBulkUpload",
  components: {
    BaseHeader,
    PendingBulkUploadTable
  }
};
</script>
