<template>
  <div>
    <ApolloQuery
      :query="
        require('../../graphql/QuarantinedAttributeHomologations/AllPurposeCategoryAttributes.gql')
      "
      :variables="queryVariables('')"
      :fetchPolicy="'cache-and-network'"
      clientId="apolloClientCached"
    >
      <template slot-scope="{ result: { error, data }, query, isLoading }">
        <!-- Error -->
        <b-alert v-if="error" show="" variant="danger">
          Ha ocurrido un error
        </b-alert>
        <span v-if="data && isLoading" class="m-2 float-right">
          Actualizando lista...
        </span>
        <!-- Result -->
        <b-table-simple v-if="data && data.allPurposeCategoryAttributes" hover>
          <b-thead>
            <b-tr>
              <b-th></b-th>
              <b-th>Integración</b-th>
              <b-th>Path</b-th>
              <b-th>Nombre</b-th>
              <b-th></b-th>
            </b-tr>
            <b-tr>
              <b-th>
                <base-pretty-check-box
                  v-model="allSelected"
                  @change="selectAll"
                ></base-pretty-check-box>
              </b-th>
              <b-th>
                <b-form-group>
                  <b-spinner
                    label="Spinning"
                    v-if="loadingPublicIntegrationInformation"
                  ></b-spinner>
                  <v-select
                    v-else
                    v-model="publicIntegrationInformationId"
                    placeholder="Todos"
                    :options="publicIntegrationInformations"
                    :reduce="elem => elem.value"
                  ></v-select>
                </b-form-group>
              </b-th>
              <b-th>
                <b-form-group>
                  <b-input
                    v-model="path"
                    placeholder="path"
                    size="sm"
                    lazy=""
                  ></b-input>
                </b-form-group>
              </b-th>
              <b-th>
                <b-form-group>
                  <b-input
                    v-model="name"
                    placeholder="nombre"
                    size="sm"
                    lazy=""
                  ></b-input>
                </b-form-group>
              </b-th>
              <b-th></b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <homologations-table-row
              v-for="homologation of data.allPurposeCategoryAttributes.edges"
              :homologation="homologation.node"
              :key="homologation.node.id"
              v-model="selectedRows[homologation.node.id]"
              @change="updateSelectedHomologations"
            ></homologations-table-row>
          </b-tbody>
        </b-table-simple>
        <b-spinner
          v-if="isLoading"
          label="Spinning"
          class="m-2 float-right"
        ></b-spinner>
        <div v-else>
          <b-button
            v-if="hasNextPage(data)"
            class="m-2 float-right"
            @click="
              seeMore(
                query,
                data.allPurposeCategoryAttributes.pageInfo.endCursor
              )
            "
          >
            Ver más
          </b-button>
          <b-alert v-else show variant="light" class="text-center">
            No hay más datos para mostrar.
          </b-alert>
        </div>
      </template>
    </ApolloQuery>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
import HomologationsTableRow from "@/components/QuarantinedAttributeHomologations/HomologationsTableRow.vue";
import ALL_PUBLIC_INTEGRATION_INFORMATIONS from "@/graphql/AllPublicIntegrationInformation.gql";
import BasePrettyCheckBox from "@/components/BasePrettyCheckBox.vue";
export default {
  name: "HomologationsTable",
  components: { HomologationsTableRow, BasePrettyCheckBox },
  model: {
    prop: "params",
    event: "change"
  },
  props: {
    params: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  mounted() {
    this.loadPublicIntegrationInformation();
  },
  data() {
    return {
      loadingPublicIntegrationInformation: true,
      publicIntegrationInformationId: null,
      publicIntegrationInformations: [],
      path: "",
      name: "",
      allSelected: false,
      selectedRows: {}
    };
  },
  methods: {
    ...mapMutations(["setSelectedHomologations"]),
    /**
     * Selecciona o des-selecciona todas las homologaciones
     * según el valor de "allSelected", modificando
     * el arreglo selectedRows
     */
    selectAll() {
      const val = this.allSelected ? true : false;
      Object.keys(this.selectedRows).forEach(id => {
        this.$set(this.selectedRows, id, val);
      });
    },
    /**
     * Obtiene las variables para
     * la query de graphql que obtiene el listado de
     * homologaciones
     * @param {String} cursor (cursor de query paginada)
     * @return {Object}
     */
    queryVariables(cursor) {
      const variables = { deleteTryGte: 5 };
      if (cursor) {
        variables.cursor = cursor;
      }
      if (this.publicIntegrationInformationId) {
        variables.publicIntegrationInformationId = this.publicIntegrationInformationId;
      }
      if (this.name && this.name.length) {
        variables.name = this.name;
      }
      if (this.path && this.path.length) {
        variables.path = this.path;
      }
      return variables;
    },
    /**
     * Carga el listado de PublicIntegrationInformations
     * para el filtro
     */
    loadPublicIntegrationInformation() {
      this.$apollo
        .query({ query: ALL_PUBLIC_INTEGRATION_INFORMATIONS })
        .then(({ data }) => {
          this.publicIntegrationInformations = data.allPublicIntegrationInformation.edges.map(
            x => {
              return { value: x.node.id, label: x.node.name };
            }
          );
          this.loadingPublicIntegrationInformation = false;
        });
    },
    /**
     * Actualiza las homologaciones seleccionadas
     * en vuex
     */
    updateSelectedHomologations() {
      this.setSelectedHomologations(this.selectedRows);
    },
    /**
     * Indica si hay una página siguiente en la query de GraphQl basado
     * exclusivamente en la existencia de un cursor.
     * @return {boolean}
     */
    hasNextPage(data) {
      return data?.allPurposeCategoryAttributes?.pageInfo?.endCursor != null;
    },
    /**
     * Carga una página adicional
     * de la query, después del cursor
     * @param {Object} query
     * @param {String} cursor
     */
    async seeMore(query, cursor) {
      await query.fetchMore({
        variables: this.queryVariables(cursor),
        updateQuery: (prev, { fetchMoreResult }) => {
          const updated = Object.assign({}, this.$dup(prev));
          updated.allPurposeCategoryAttributes.pageInfo =
            fetchMoreResult.allPurposeCategoryAttributes.pageInfo;
          if (
            fetchMoreResult?.allPurposeCategoryAttributes?.edges?.length !== 0
          ) {
            updated.allPurposeCategoryAttributes.edges.push(
              ...fetchMoreResult.allPurposeCategoryAttributes.edges
            );
          }
          return updated;
        }
      });
    }
  }
};
</script>
