<template>
  <div>
    <product-histories-page :product="product"></product-histories-page>
  </div>
</template>
<script>
import ProductHistoriesPage from "../components/ProductHistoriesPage.vue";
export default {
  name: "ProductHistories",
  props: {
    product: Object
  },
  components: {
    ProductHistoriesPage
  }
};
</script>
