<template>
  <div>
    <base-exclusions
      style="min-width: 100%"
      :ic="ic"
      :visible="visible"
      search-options-placeholder="Buscar categorías"
      selected-options-placeholder="Buscar categorías seleccionadas"
      no-options-text="No hay categorías"
      selected-no-options-text="No hay categorías seleccionadas"
      no-options-found-text="No se encontró la categoría"
      no-selected-options-found-text="
        No se encontró la categoría seleccionada
      "
      all-options-label="Todas las categorías"
      selected-options-label="Categorías seleccionadas"
      :base-options="categories"
      :base-selected-options="selectedCategories"
      field-to-exclude="excludedCategoryIds"
      field-to-include="includedCategoryIds"
      field-excluded-products-without="excludeProductsWithoutCategories"
      label-excluded-products-without="Excluir productos sin categoría"
      :base-excluded-products-without="excludedProductsWithout"
    >
    </base-exclusions>
  </div>
</template>

<script>
import ALL_CATEGORIES from "../../graphql/AllCategories.gql";
import EXCLUDED_CATEGORY_IDS from "../../graphql/IntegrationConfig/ExcludedCategoryIds.gql";
import BaseExclusions from "./BaseExclusions.vue";
import { mapState } from "vuex";

export default {
  name: "ExclusionByCategory",
  components: {
    BaseExclusions
  },
  props: {
    ic: {
      type: Object,
      default() {
        return {};
      }
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      categories: [],
      selectedCategories: [],
      excludedProductsWithout: null
    };
  },
  async mounted() {
    await this.getCategories();
    await this.getExcludedCategories();
  },
  computed: {
    ...mapState(["currentUser"])
  },
  methods: {
    /**
     * Obtiene todas las categorias
     */
    async getCategories() {
      await this.$getAllPages(
        ALL_CATEGORIES,
        { companyId: this.currentUser.company.id },
        "allCategories"
      ).then(result => {
        this.categories = result.map(x => x.node);
      });
    },
    /**
     * Obtiene el id de todas las marcas excluidas
     */
    async getExcludedCategories() {
      this.$apollo
        .query({
          query: EXCLUDED_CATEGORY_IDS,
          variables: { integrationConfigId: this.ic.id }
        })
        .then(({ data }) => {
          this.selectedCategories = this.$dup(
            data.integrationConfig.excludedCategoryIds
          );
          this.excludedProductsWithout =
            data.integrationConfig.excludeProductsWithoutCategories;
        });
    }
  }
};
</script>

<style scoped></style>
