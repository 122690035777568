<template>
  <div>
    <base-alert-with-count-down
      v-model="showAlert"
      :alert-variant="alertVariant"
      :dismiss-secs="error ? 10 : 5"
    >
      {{ alertMessage }}</base-alert-with-count-down
    >
    <b-row>
      <b-col xl="10" cols="12">
        <b-row>
          <b-col md="6" cols="12">
            <input-with-skeleton
              :loading="isLoading"
              label="Nombre de la bodega"
              :invalid-feedback="validation.name.invalidFeedback"
              :state="validation.name.valid"
              v-model="name"
            />
          </b-col>
          <b-col md="6" cols="12">
            <input-with-skeleton
              :loading="isLoading"
              label="Dirección"
              :invalid-feedback="validation.address.invalidFeedback"
              :state="validation.address.valid"
              v-model="address"
            />
          </b-col>
        </b-row>
        <ApolloQuery
          :query="require('../graphql/AllRegions.gql')"
          :fetchPolicy="'cache-first'"
          clientId="apolloClientCached"
          @result="setRegions"
        >
          <template v-slot="{ result: { data }, isLoading }">
            <b-row>
              <b-col sm="6" cols="12">
                <b-form-group
                  label="Region"
                  label-for="region"
                  :invalid-feedback="validation.region.invalidFeedback"
                  :state="!regionsLoaded ? null : validation.region.valid"
                >
                  <v-select
                    v-if="!isLoading && regionsLoaded"
                    id="region"
                    :options="data.allRegions.edges.map(r => r.node)"
                    label="name"
                    placeholder="Seleccionar"
                    v-model="region"
                    :reduce="cond => cond.id"
                  ></v-select>
                  <b-skeleton v-else type="input" />
                </b-form-group>
              </b-col>
              <b-col sm="6" cols="12">
                <b-form-group
                  label="Comuna"
                  label-for="commune"
                  :invalid-feedback="validation.commune.invalidFeedback"
                  :state="!communesLoaded ? null : validation.commune.valid"
                >
                  <v-select
                    v-if="!isLoading && communesLoaded"
                    :disabled="!region"
                    id="commune"
                    :options="communesOptions"
                    label="name"
                    placeholder="Seleccionar"
                    v-model="commune"
                    :reduce="cond => cond.id"
                  ></v-select>
                  <b-skeleton v-else type="input" />
                </b-form-group>
              </b-col>
            </b-row>
          </template>
        </ApolloQuery>
      </b-col>
      <b-col xl="2" cols="12">
        <b-button
          variant="info"
          :disabled="!changed || saving"
          :title="changed ? '' : 'No hay cambios'"
          @click="save"
          class="mb-3 w-100"
        >
          <span v-if="saving">
            Guardando
            <b-spinner label="Spinning" />
          </span>
          <span v-else>Guardar</span>
        </b-button>
        <b-button
          variant="outline-info"
          class="w-100 px-0"
          :disabled="!changed || saving"
          @click="resetData()"
        >
          <span>Restablecer</span>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import InputWithSkeleton from "../components/Base/InputWithSkeleton.vue";
import BaseAlertWithCountDown from "../components/Base/BaseAlertWithCountDown.vue";
import UPDATE_WAREHOUSE from "../graphql/Warehouses/UpdateWarehouse.gql";
import CREATE_WAREHOUSE from "../graphql/Warehouses/CreateWarehouse.gql";
export default {
  name: "WarehouseEdit",
  props: {
    warehouse: {
      type: Object,
      default() {
        return {};
      }
    },
    isLoading: Boolean
  },
  components: {
    InputWithSkeleton,
    BaseAlertWithCountDown
  },
  data() {
    return {
      name: this.warehouse?.name || "",
      address: this.warehouse?.address || "",
      region: this.warehouse?.regionId || null,
      commune: this.warehouse?.communeId || null,
      communesOptions: [],
      regions: [],
      saving: false,
      error: false,
      showAlert: false,
      loadingRegions: true,
      loadingCommunes: true,
      errorMessage: null
    };
  },
  mounted() {
    this.loadingRegions = true;
    if (this.isConsolidated() && this.warehouse?.id) {
      this.changeCommunes();
    }
    if (!this.isConsolidated()) {
      this.name = "";
      this.address = "";
      this.region = null;
      this.commune = null;
      this.loadingCommunes = false;
    }
  },
  computed: {
    validation() {
      return {
        name: {
          valid: this.name?.length > 0,
          invalidFeedback: "El nombre es obligatorio"
        },
        address: {
          valid: this.address?.length > 0,
          invalidFeedback: "La dirección es obligatoria"
        },
        region: {
          valid: this.region != null,
          invalidFeedback: "La region es obligatoria"
        },
        commune: {
          valid: this.region != null && this.commune != null,
          invalidFeedback: this.region
            ? "La comuna es obligatoria"
            : "Se debe seleccionar una region"
        }
      };
    },
    valid() {
      let valid = true;
      Object.keys(this.validation).forEach(x => {
        valid = valid && this.validation[x].valid;
      });
      return valid;
    },
    changed() {
      return (
        (this.warehouse?.name || "") != this.name ||
        (this.warehouse?.address || "") != this.address ||
        (this.warehouse?.regionId || null) != this.region ||
        (this.warehouse?.communeId || null) != this.commune
      );
    },
    alertVariant() {
      return this.error ? "danger" : "success";
    },
    alertMessage() {
      return this.error
        ? "Ha ocurrido un error guardando los datos: " + this.errorMessage
        : "Bodega guardada exitosamente";
    },
    regionsLoaded() {
      if (this.isConsolidated()) {
        return !this.loadingRegions && this.warehouse?.id;
      } else {
        return !this.loadingRegions;
      }
    },
    communesLoaded() {
      return !this.loadingCommunes && this.regionsLoaded;
    }
  },
  methods: {
    /**
     * Se encarga de actualizar el listado de
     * comunas segun la region seleccionada
     */
    changeCommunes() {
      this.loadingCommunes = true;
      let tempCommunes = [];
      if (this.region && this.regions) {
        this.regions.forEach(r => {
          if (r.id === this.region) {
            tempCommunes = this.$dup(r.communes);
          }
        });
      }
      tempCommunes.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      );
      this.communesOptions = tempCommunes;
      this.loadingCommunes = false;
    },
    /**
     * Guarda en una variables las regiones que responde la query
     * @param {Object} data - respues de query
     */
    setRegions(data) {
      if (data?.data?.allRegions) {
        this.regions = this.$dup(data.data.allRegions.edges.map(r => r.node));
        this.loadingRegions = false;
      }
    },
    /**
     * Indica si es creacion o no
     * @return {Boolean}
     */
    isConsolidated() {
      if (this.$route.name == "WarehouseNew") {
        return false;
      } else {
        return true;
      }
    },
    /**
     * Valida si se puede guardar la bodega y redirecciona a la
     * lista de bodegas si se puede guardar
     */
    async save() {
      this.error = false;
      this.errorMessage = null;
      if (!this.valid) {
        this.error = true;
        this.errorMessage = "Hay campos que se deben rellenar";
        this.showAlert = true;
      } else {
        this.saving = true;
        let action = "updated";
        if (this.isConsolidated()) {
          await this.updateWarehouse();
        } else {
          await this.createWarehouse();
          action = "created";
        }
        if (!this.error) {
          this.$router.push({
            name: "Warehouses",
            params: {
              action: action
            }
          });
        }
      }
    },
    /**
     * Envia la mutacion para actualizar una bodega
     */
    async updateWarehouse() {
      await this.$apollo
        .mutate({
          mutation: UPDATE_WAREHOUSE,
          variables: {
            warehouse: {
              id: this.warehouse.id,
              name: this.name,
              address: this.address,
              regionId: this.region,
              communeId: this.commune
            }
          }
        })
        .then(({ data }) => {
          if (!data.updateWarehouse.result) {
            this.error = true;
            this.errorMessage = data.updateWarehouse.error;
            this.showAlert = true;
          } else {
            this.warehouse.name = this.name;
            this.warehouse.address = this.address;
            this.warehouse.regionId = this.region;
            this.warehouse.communeId = this.commune;
          }
          this.saving = false;
        })
        .catch(e => {
          this.error = true;
          this.errorMessage = e.message;
          this.showAlert = true;
          this.saving = false;
        });
    },
    /**
     * Envia la mutacion para crear una bodega
     */
    async createWarehouse() {
      await this.$apollo
        .mutate({
          mutation: CREATE_WAREHOUSE,
          variables: {
            warehouse: {
              name: this.name,
              address: this.address,
              regionId: this.region,
              communeId: this.commune
            }
          }
        })
        .then(({ data }) => {
          if (!data.createWarehouse.result) {
            this.error = true;
            this.errorMessage = data.createWarehouse.error;
          } else {
            this.warehouse.name = this.name;
            this.warehouse.address = this.address;
            this.warehouse.regionId = this.region;
            this.warehouse.communeId = this.commune;
          }
          this.showAlert = true;
          this.saving = false;
        })
        .catch(e => {
          this.error = true;
          this.errorMessage = e.message;
          this.showAlert = true;
          this.saving = false;
        });
    },
    /**
     * Se encarga de resetear los valores del formulario
     */
    resetData() {
      this.name = this.warehouse.name || "";
      this.address = this.warehouse.address || "";
      this.region = this.warehouse?.regionId || null;
      this.commune = this.warehouse?.communeId || null;
    }
  },
  watch: {
    warehouse: {
      handler(val) {
        this.name = val.name !== null ? val.name : "";
        this.address = val.address !== null ? val.address : "";
        this.region = val.regionId || null;
        this.commune = val.communeId || null;
        if (this.isConsolidated()) {
          this.changeCommunes();
        }
      },
      deep: true
    },
    region(_newVal, prevVal) {
      if (prevVal) {
        this.commune = null;
      }
      this.changeCommunes();
    }
  }
};
</script>

<style></style>
