<template>
  <div>
    <b-table-simple>
      <b-thead>
        <tr>
          <th :style="{ minWidth: '150px' }">Producto</th>
          <th :style="{ minWidth: '150px' }">Talla</th>
          <th :style="{ minWidth: '150px' }">Color</th>
          <th>* SKU variante</th>
          <th>Código de barra</th>
          <th :style="{ minWidth: '50px' }">Unidades por pack</th>
          <th v-for="warehouse of warehouses" :key="warehouse.id">
            Stock {{ warehouse.name }}
          </th>
          <th></th>
        </tr>
      </b-thead>

      <b-tr v-for="(_variant, id) of array" :key="id">
        <b-td>
          <b-form-group>
            {{ array[id].producto }}
          </b-form-group>
        </b-td>
        <b-td>
          <b-form-group>
            {{ array[id].size }}
          </b-form-group>
        </b-td>
        <b-td>
          <b-form-group>
            {{ array[id].color }}
          </b-form-group>
        </b-td>
        <b-td>
          <b-form-group>
            {{ array[id].sku }}
          </b-form-group>
        </b-td>
        <b-td>
          <b-form-group>
            {{ array[id].barcode }}
          </b-form-group>
        </b-td>
        <b-td>
          <b-form-group>
            <b-form-input v-model="array[id].quantity" @change="emitChange">
            </b-form-input>
          </b-form-group>
        </b-td>
        <b-td v-for="warehouse of warehouses" :key="warehouse.id">
          <div :key="forceRender">
            <b-form-group v-if="array[id].variantWarehouseMap.length == 0">
              <b-spinner />
            </b-form-group>
            <b-form-group v-else>
              {{ showStock(array[id].variantWarehouseMap, warehouse.id) }}
            </b-form-group>
          </div>
        </b-td>
        <b-td>
          <p class="h4 mb-2">
            <b-icon-x-circle @click="deleteVariant(id)"></b-icon-x-circle>
          </p>
        </b-td>
      </b-tr>
    </b-table-simple>
    <div>
      <b-button
        v-b-modal.add-product-modal
        id="productsToUse"
        @click="openModal()"
        >Agregar</b-button
      >
    </div>
    <products-for-packs-centry
      id="productsToUse"
      title="Importar Productos"
      :selectedProducts="selectedProducts"
      @add-new-items="addNewItems"
    >
    </products-for-packs-centry>
  </div>
</template>
<script>
import { mapState } from "vuex";
import ProductsForPacksCentry from "../../ProductsForPacksCentry.vue";
import VARIANT_PACK_QUANTITY_SEARCH from "../../../graphql/VariantPackQuantitySearch.gql";
import ALL_VARIANT_WAREHOUSES_BY_VARIANTS from "../../../graphql/AllVariantWarehousesByVariantsId.gql";

export default {
  name: "ProductEditTabsPacks",
  components: {
    ProductsForPacksCentry
  },
  model: {
    prop: "_value",
    event: ["change", "packVariantsChange"]
  },
  props: {
    _value: {
      type: Array,
      default() {
        return [];
      }
    },
    variants: {
      type: Array
    },
    isPack: {
      type: Boolean
    }
  },
  data() {
    return {
      array: this._value,
      selectedProducts: [],
      productsToUse: false,
      items: [],
      originalItems: [],
      integrationConfigId: "",
      variantPackQuantitySearch:
        this.$route.name == "ProductNew"
          ? []
          : this.initialVariantPackArray(this.variants),
      forceRender: 0
    };
  },
  beforeMount() {
    this.originalItems = this.items;
  },
  computed: {
    ...mapState(["currentUser"]),
    warehouses() {
      if (
        this.currentUser &&
        this.currentUser.company &&
        this.currentUser.company.warehouses
      ) {
        return this.currentUser.company.warehouses;
      }
      return [];
    }
  },
  methods: {
    emitChange() {
      this.$emit("change", this.array);
      let valid = true;
      this.array.forEach(x => {
        if (x.sku === null || x.sku.length === 0) {
          valid = false;
        }
        if (x.quantity === null || x.quantity <= 0) {
          valid = false;
        }
      });
      this.$emit("valid", valid);
      this.$emit("packVariantsChange");
    },
    itemForSelect(item) {
      if (item) {
        return { value: item.id, label: item.name };
      }
      return null;
    },
    closeModal() {
      this.productsToUse = false;
    },
    openModal() {
      this.productsToUse = true;
      this.$bvModal.show("productsToUse");
    },
    /**
     * Añade nuevos items al array que contiene los packs
     * y ademas llama a la funcion para obtener las cantidades
     * @param {Array} itemsArray - elementos para agregar
     */
    addNewItems(itemsArray) {
      let i = this.array.length;
      let variantIdsAndPosition = {};
      itemsArray.prods.forEach(item => {
        item["variantWarehouseMap"] = [];
        variantIdsAndPosition[item.id] = i;
        this.array.push(item);
        i += 1;
      });
      this.getVariantWarehouses(variantIdsAndPosition);
    },
    initialVariantPackArray(variants) {
      // loading(true);
      if (variants[0] == null || this.isPack === false) {
        return;
      }
      const variantId = variants[0].id;
      this.$apollo
        .query({
          query: VARIANT_PACK_QUANTITY_SEARCH,
          variables: { Id: variantId }
        })
        .then(async result => {
          if (result.data) {
            for (const variant in result.data.variantPackQuantitySearch.edges) {
              var variantObject =
                result.data.variantPackQuantitySearch.edges[variant].node;
              var warehouses = [];
              for (const warehouseVariant in variantObject.variant
                .variantWarehouses) {
                warehouses.push({
                  id:
                    variantObject.variant.variantWarehouses[warehouseVariant]
                      .warehouse.id,
                  quantity:
                    variantObject.variant.variantWarehouses[warehouseVariant]
                      .quantity
                });
              }
              this.array.push({
                id: variantObject.variant.id,
                producto: variantObject.variant.product.name,
                size: variantObject.variant.sizeName,
                color: variantObject.variant.colorName,
                sku: variantObject.variant.sku,
                barcode: variantObject.variant.barcode,
                quantity: variantObject.quantity,
                variantWarehouseMap: warehouses
              });
            }
          }
        });
      // loading(false);
    },
    deleteVariant(id) {
      this.array.splice(id, 1);
      this.$emit("packVariantsChange");
    },
    showStock(array, warehouseid) {
      const stock = array.find(element => element.id === warehouseid);
      if (!stock) {
        return 0;
      } else {
        return stock.quantity;
      }
    },
    /**
     * Se encarga de obtener las cantidades de cada elemento añadido
     * a la posicion del arreglo que corresponde y fuerza la
     * renderizacion de los stock
     * @param {Object} variantIdsAndPosition - Objecto con los elementos para agregar,
     *                                         la llave es el Id de la variante y el
     *                                         valor la posicion del arreglo en
     *                                         la que se agrego
     */
    getVariantWarehouses(variantIdsAndPosition) {
      let variantIds = Object.keys(variantIdsAndPosition);
      let vwByPosition = {};
      this.$getAllPages(
        ALL_VARIANT_WAREHOUSES_BY_VARIANTS,
        { variantIds: variantIds },
        "allVariantWarehouses"
      ).then(vws => {
        vws.forEach(vw => {
          let position = variantIdsAndPosition[vw.node.variantId];
          if (position in vwByPosition) {
            vwByPosition[position].push({
              id: vw.node.warehouseId,
              quantity: vw.node.quantity
            });
          } else {
            vwByPosition[position] = [
              { id: vw.node.warehouseId, quantity: vw.node.quantity }
            ];
          }
        });
        Object.keys(vwByPosition).forEach(position => {
          this.array[position].variantWarehouseMap = vwByPosition[position];
        });
        this.forceRender += 1;
      });
    }
  },
  watch: {
    selectedProducts(newVal) {
      for (let j = 0; j < newVal.length; j++) {
        var found = false;
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i][this.ITEM_ID] == newVal[j].sku) {
            found = true;
          }
        }
        if (!found) {
          this.addNewItems([newVal[j]]);
        }
      }
    }
  }
};
</script>
