<template>
  <b-spinner label="Spinning" v-if="loading"></b-spinner>
  <div v-else>
    <mercado-libre-attributes-table-history
      :attribute-group-integration-config-id="
        attributeGroupIntegrationConfig.id
      "
    ></mercado-libre-attributes-table-history>
    <base-j-excel-table
      v-model="products"
      :columns="columns"
      :on-selection="this.openModalIfNeeded"
      :custom-on-change="this.onChange"
      :filters="true"
      :after-mounted="this.setTableStyle"
      :allow-insert-row="false"
      :allow-insert-column="false"
      :skip-validation="true"
    >
    </base-j-excel-table>
    <b-alert v-if="saveStatus" v-model="saved" variant="success" dismissible>
      Los productos han sido enviados a actualizarse. Puedes ver el progreso en
      la parte superior de la tabla
    </b-alert>
    <b-alert v-else v-model="saved" variant="danger" dismissible>
      No se pudo actualizar los productos debido a:
      <ul>
        <li v-for="(error, index) of errorsOnSave" :key="index">{{ error }}</li>
      </ul>
    </b-alert>
    <b-button-group>
      <b-button @click="cancel">Cancelar</b-button>
      <b-button variant="primary" @click="save" :disabled="saving || !changed"
        ><b-spinner variant="Spinning" v-if="saving"></b-spinner
        >Guardar</b-button
      >
    </b-button-group>
    <mercado-libre-attributes-optin-modal
      v-if="!!optinItemId"
      :productTitle="optinItemTitle"
      :itemId="optinItemId"
      :variationId="optinVariationId"
      :integrationConfig="attributeGroupIntegrationConfig.integrationConfig"
    ></mercado-libre-attributes-optin-modal>

    <price-to-win-modal
      v-if="!!currentPriceToWin"
      id="priceToWin"
      tooltip="Puedes ver la competencia del catálogo y mejorar las condiciones de tu publicación"
      link-class="text-center"
      title="Iphone 8 64 Gb Plata"
      :modelToCompare="currentPriceToWin"
    >
      <template v-slot:link>
        <b-button variant="success">Ver competencia</b-button>
      </template>
    </price-to-win-modal>
  </div>
</template>
<script>
import BaseJExcelTable from "@/components/BaseJExcelTable";
import MercadoLibreAttributesOptinModal from "@/components/MercadoLibreAttributesOptinModal";
import PriceToWinModal from "@/components/MercadoLibreAttributesPriceToWinModal";
import ALL_INTEGRATIONS_MERCADO_LIBRE_PRODUCT_DATAS from "@/graphql/AllIntegrationsMercadoLibreProductDatas.gql";
import MERCADO_LIBRE_PRICE_TO_WIN from "@/graphql/MercadoLibrePriceToWin.gql";
import MERCADO_LIBRE_UPDATE_ATTRIBUTES from "@/graphql/MercadoLibreUpdateAttributes.gql";
import CREATE_MIRROR_HISTORY from "@/graphql/CreateMirrorHistory.gql";
import jexcel from "jexcel";
import MercadoLibreAttributesTableHistory from "@/components/MercadoLibreAttributesTableHistory"; // eslint-disable-line no-unused-vars

export default {
  name: "MercadoLibreAttributesTable",
  components: {
    MercadoLibreAttributesTableHistory,
    BaseJExcelTable,
    MercadoLibreAttributesOptinModal,
    PriceToWinModal
  },
  props: {
    attributeGroupIntegrationConfig: {
      type: Object,
      required: true
    }
  },
  beforeMount() {
    this.loadProductsFromCentry()
      .then(() => this.loadItemsFromMercadoLibre())
      .then(() => {
        this.loading = false;
      });
  },
  data() {
    return {
      columns: [
        { type: "text", title: "ID", width: 120, readOnly: true },
        { type: "text", title: "ID Variante", width: 120, readOnly: true },
        { type: "text", title: "Titulo", width: 120 },
        {
          type: "numeric",
          title: "Precio",
          width: 120,
          mask: "$ #.##0,0",
          decimal: ","
        },
        { type: "text", title: "Catalogo", width: 160, readOnly: true },
        {
          type: "text",
          title: "Calidad",
          width: 120,
          decimal: ",",
          readOnly: true
        },
        { type: "text", title: "ID Catalogo", width: 160, readOnly: true }
      ],
      productDatas: null,
      products: null,
      currentProducts: null,
      integrationConfig: this.attributeGroupIntegrationConfig.integrationConfig,
      loading: true,
      items: {},
      optinItemTitle: null,
      optinItemId: null,
      optinVariationId: null,
      priceToWinHash: {},
      currentPriceToWin: null,
      attributes: null,
      rowItemHash: [],
      mercadoLibreCentryIdHash: {},
      mercadoLibreIdColorCellHash: {},
      //texto sin variantes
      NO_VARIANTS: "-- Sin Variantes --",
      //indices de atributos
      ID_PRODUCT: 0,
      ID_VARIANT: 1,
      TITLE: 2,
      PRICE: 3,
      CATALOG: 4,
      QUALITY: 5,
      CATALOG_ID_PRODUCT: 6,
      START_ATTRIBUTES: 7,
      saving: false,
      changed: false,
      errorsOnSave: [],
      saveStatus: null,
      saved: false
    };
  },
  computed: {
    mercadoLibreHeaders() {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + this.integrationConfig.accessToken
      );
      return myHeaders;
    }
  },
  methods: {
    onChange(instance, cell, x, y, value, jExcelInstance) {
      const oldValue = this.currentProducts[y][x];
      if (value !== oldValue)
        jExcelInstance.setStyle(
          jexcel.getColumnNameFromId([x, y]),
          "font-weight",
          "bold",
          true
        );
      else
        jExcelInstance.setStyle(
          jexcel.getColumnNameFromId([x, y]),
          "font-weight",
          "inherit",
          true
        );
      if (!this.changed) this.changed = true;
      const item = this.rowItemHash[y];
      if (this.isProductAttributeColumn(parseInt(x))) {
        this.itemRows(item.id, parseInt(y)).forEach(row => {
          if (this.products[row][x] !== value) {
            this.products[row][x] = value;
            jExcelInstance.setValue(
              jexcel.getColumnNameFromId([x, row]),
              value
            );
          }
        });
      }
    },
    itemRows(itemId, referenceRow) {
      const rows = [];
      let currentRow = referenceRow - 1;
      while (currentRow >= 0 && this.rowItemHash[currentRow].id === itemId) {
        rows.push(currentRow);
        currentRow--;
      }
      currentRow = referenceRow + 1;
      while (
        currentRow < this.products.length &&
        this.rowItemHash[currentRow].id === itemId
      ) {
        rows.push(currentRow);
        currentRow++;
      }
      return rows;
    },
    isProductAttributeColumn(column) {
      if ([this.TITLE, this.PRICE].includes(column)) {
        return true;
      } else if (column < this.START_ATTRIBUTES) {
        return false;
      }

      const attribute = this.attributes[column - this.START_ATTRIBUTES];
      return !(
        attribute.tags.allow_variations || attribute.tags.variation_attribute
      );
    },
    openModalIfNeeded(instance, x1, y1, x2, y2) {
      if (x1 === x2 && y1 === y2 && x1 === this.CATALOG) {
        if (this.products[y1][this.CATALOG] === "Elegible") {
          this.optinItemId = this.products[y1][this.ID_PRODUCT];
          if (this.products[y1][this.ID_VARIANT] === this.NO_VARIANTS) {
            this.optinVariationId = null;
          } else {
            this.optinVariationId = this.products[y1][this.ID_VARIANT];
          }
          this.optinItemTitle = this.products[y1][this.TITLE];
          this.$bvModal.show(
            "mercado_libre_optin_" +
              this.optinItemId +
              "_" +
              this.optinVariationId
          );
        } else if (
          this.products[y1][this.CATALOG] === "Ganando" ||
          this.products[y1][this.CATALOG] === "Perdiendo" ||
          this.products[y1][this.CATALOG].startsWith(
            "Compartiendo el primer lugar con"
          )
        ) {
          this.currentPriceToWin = this.priceToWinHash[
            this.products[y1][this.CATALOG_ID_PRODUCT]
          ];
          this.$bvModal.show("priceToWin");
        }
      }
    },
    loadProductsFromCentry() {
      return this.$apollo
        .query({
          query: ALL_INTEGRATIONS_MERCADO_LIBRE_PRODUCT_DATAS,
          variables: {
            idGroup: parseInt(this.attributeGroupIntegrationConfig.id),
            onlyWithId: true
          }
        })
        .then(({ data }) => {
          if (data && data.allIntegrationsMercadoLibreProductDatas) {
            this.products = data.allIntegrationsMercadoLibreProductDatas.edges.map(
              elem => {
                this.mercadoLibreCentryIdHash[elem.node.mercadoLibreId] =
                  elem.node.productId;
                return [
                  elem.node.mercadoLibreId,
                  "",
                  "",
                  "",
                  "",
                  elem.node.quality !== null
                    ? elem.node.quality * 100 + "%"
                    : ""
                ];
              }
            );
          }
        });
    },
    getItem(id) {
      const requestOptions = {
        method: "GET",
        headers: this.mercadoLibreHeaders,
        redirect: "follow"
      };
      return fetch("https://api.mercadolibre.com/items/" + id, requestOptions)
        .then(response => response.json())
        .catch(error => console.log("error", error));
    },

    loadItemsFromMercadoLibre() {
      const itemPromises = [];
      this.products.forEach(row => {
        itemPromises.push(
          this.getItem(row[0]).then(item => {
            if (item) {
              this.items[item.id] = item;
            }
          })
        );
      });
      return Promise.all(itemPromises).then(() => {
        const products = [];
        const toSetPriceToWin = {};
        let indexCounter = 0;
        let attributesPromise = null;
        const promises = [];
        this.products.forEach(row => {
          const item = this.items[row[0]];
          if (item) {
            if (indexCounter === 0) {
              attributesPromise = this.loadAttributes(item.category_id);
            }
            if (item.variations && item.variations.length) {
              item.variations.forEach(variation => {
                const item_id =
                  this.getItemRelation(item, variation)?.id || item.id;
                products.push([
                  item.id,
                  variation.id,
                  item.title,
                  item.price,
                  this.catalogInfo(item),
                  row[this.QUALITY],
                  item_id
                ]);
                if (this.isOnCatalog(item)) {
                  if (!toSetPriceToWin[item_id]) {
                    toSetPriceToWin[item_id] = [];
                  }
                  toSetPriceToWin[item_id].push(indexCounter);
                }
                this.rowItemHash.push(item);
                indexCounter += 1;
              });
              return;
            }
            const item_id = this.getItemRelation(item, null)?.id || item.id;
            products.push([
              item.id,
              this.NO_VARIANTS,
              item.title,
              item.price,
              this.catalogInfo(item),
              row[this.QUALITY],
              item_id
            ]);
            if (this.isOnCatalog(item)) {
              if (!toSetPriceToWin[item_id]) {
                toSetPriceToWin[item_id] = [];
              }
              toSetPriceToWin[item_id].push(indexCounter);
            }
            this.rowItemHash.push(item);
            indexCounter += 1;
          }
        });
        if (attributesPromise)
          promises.push(attributesPromise.then(() => this.setAttributes()));
        this.products = products;
        promises.push(this.updatePriceToWin(toSetPriceToWin));
        return Promise.all(promises);
      });
    },
    loadAttributes(categoryId) {
      const requestOptions = {
        method: "GET",
        headers: this.mercadoLibreHeaders,
        redirect: "follow"
      };
      return fetch(
        "https://api.mercadolibre.com/categories/" + categoryId + "/attributes",
        requestOptions
      )
        .then(response => response.json())
        .then(response => {
          this.attributes = response.filter(attr => !attr.tags.read_only);
        })
        .catch(error => console.log("error", error));
    },
    setAttributes() {
      this.attributes.forEach(attribute => {
        const column = {
          type: "string",
          width: 120,
          title: attribute.name
        };
        if (attribute.values) {
          column.type = "autocomplete";
          column.source = attribute.values;
        }
        this.columns.push(column);
        this.setAttribute(attribute);
      });
      this.products = this.$dup(this.products);
      this.currentProducts = this.$dup(this.products);
    },
    setAttribute(attribute) {
      this.products.forEach(row => {
        const item = this.items[row[this.ID_PRODUCT]];
        let attributeValue;
        const variation = item.variations.find(
          variation => variation.id === parseInt(row[this.ID_VARIANT])
        );
        if (attribute.tags.allow_variations && variation) {
          attributeValue = this.$ifNull(
            variation.attribute_combinations,
            []
          ).find(attrValue => attrValue.id === attribute.id);
        } else if (attribute.tags.variation_attribute && variation) {
          attributeValue = this.$ifNull(variation.attributes, []).find(
            attrValue => attrValue.id === attribute.id
          );
          if (!attributeValue) {
            attributeValue = this.$ifNull(item.attributes, []).find(
              attrValue => attrValue.id === attribute.id
            );
          }
        } else {
          attributeValue = this.$ifNull(item.attributes, []).find(
            attrValue => attrValue.id === attribute.id
          );
        }
        if (
          attributeValue &&
          attributeValue.value_id &&
          attribute.values &&
          attribute.values.find(x => x.id === attributeValue.value_id)
        ) {
          row.push(attributeValue.value_id);
        } else if (attributeValue && attributeValue.value_name) {
          row.push(attributeValue.value_name);
        } else {
          row.push("");
        }
      });
    },
    updatePriceToWin(hash) {
      const promises = [];
      Object.keys(hash).forEach(productId => {
        promises.push(
          this.$apollo
            .query({
              query: MERCADO_LIBRE_PRICE_TO_WIN,
              variables: {
                itemId: productId,
                integrationConfigId: this.attributeGroupIntegrationConfig
                  .integrationConfig.id
              }
            })
            .then(({ data }) => {
              if (data && data.integrationConfigMercadoLibre) {
                const info =
                  data.integrationConfigMercadoLibre.productPriceToWin;
                let status = "En catálogo, pendiente...";
                if (info.status === "competing") {
                  status = "Compitiendo";
                } else if (info.status === "winning") {
                  status = "Ganando";
                } else if (info.status === "sharing_first_place") {
                  status = `Compartiendo el primer lugar con otros ${info.competitorsSharingFirstPlace -
                    1} vendedores`;
                } else if (info.status === "listed") {
                  status = "Perdiendo";
                }
                this.priceToWinHash[productId] = info;
                hash[productId].forEach(index => {
                  this.products[index][this.CATALOG] = status;
                });
              }
            })
        );
      });
      return Promise.all(promises);
    },
    catalogInfo(item) {
      if (this.isElegible(item)) {
        return "Elegible";
      }
      if (item.under_review) {
        return "En revision...";
      }
      if (this.isOnCatalog(item)) {
        return "Cargando...";
      }
      return "No elegible";
    },
    isElegible(item) {
      return item.tags.includes("catalog_listing_eligible");
    },
    isOnCatalog(item) {
      return (
        !this.isElegible(item) &&
        (item.catalog_listing ||
          item.item_relations?.at(0) ||
          item.variations.find(variation => variation?.item_relations?.at(0)))
      );
    },
    getItemRelation(item, variation) {
      return variation?.item_relations?.at(0) || item.item_relations?.at(0);
    },
    setTableStyle(jExcelInstance) {
      const colors = ["#edf3ff", "white"];
      let currentColorIndex = 0;
      let currentProductId = "";
      this.products.forEach((row, rowNum) => {
        if (row[this.ID_PRODUCT] !== currentProductId) {
          currentColorIndex = (currentColorIndex + 1) % 2;
          currentProductId = row[this.ID_PRODUCT];
        }
        this.mercadoLibreIdColorCellHash[currentProductId] =
          colors[currentColorIndex];
        row.forEach((col, colNum) => {
          jExcelInstance.setStyle(
            jexcel.getColumnNameFromId([colNum, rowNum]),
            "background-color",
            colors[currentColorIndex]
          );
        });
      });
    },
    cleanPrice(strPrice) {
      if (strPrice.length) {
        return parseFloat(strPrice.replace(/[.$\s]/g, "").replace(",", "."));
      }
      return null;
    },
    updateAll(mirrorHistoryId, allProducts) {
      let current = 0;
      const bucketSize = 500;
      const promises = [];
      while (current < allProducts.length) {
        const currentProducts = allProducts.slice(
          current,
          current + bucketSize
        );
        promises.push(
          this.$apollo
            .mutate({
              mutation: MERCADO_LIBRE_UPDATE_ATTRIBUTES,
              variables: {
                integrationConfigId: this.integrationConfig.id,
                products: currentProducts,
                mirrorHistoryId: mirrorHistoryId
              }
            })
            .then(({ data }) => {
              if (data) {
                this.errorsOnSave = this.errorsOnSave.concat(
                  this.$ifNull(data.mercadoLibreUpdateAttributes.errors, [])
                );
              }
            })
            .catch(error => {
              this.errorsOnSave = this.errorsOnSave.concat([error]);
            })
        );
        current += bucketSize;
      }
      Promise.all(promises).then(() => {
        this.saveStatus = this.errorsOnSave.length === 0;
        this.saving = false;
        this.saved = true;
        if (this.saveStatus) {
          this.currentProducts = this.$dup(this.products);
          this.products = this.$dup(this.products);
        }
      });
    },
    save() {
      this.saving = true;
      this.errorsOnSave = [];
      const allProducts = this.getProductsToSend();
      const productIds = {};
      allProducts.forEach(prod => (productIds[prod.centryProductId] = true));
      this.$apollo
        .mutate({
          mutation: CREATE_MIRROR_HISTORY,
          variables: {
            attributeGroupIntegrationConfigId: this
              .attributeGroupIntegrationConfig.id,
            productIds: Object.keys(productIds)
          }
        })
        .then(({ data }) => {
          if (data.createMirrorHistory) {
            return this.updateAll(data.createMirrorHistory.id, allProducts);
          }
        })
        .catch(error => {
          this.errorsOnSave = this.errorsOnSave.concat([error]);
          this.saved = true;
          this.saving = false;
          this.saveStatus = false;
        });
    },
    getProductsToSend() {
      let row = 0;
      const productsToUpdate = [];
      while (row < this.products.length) {
        const productRows = [];
        const oldProductRows = [];
        const id = this.products[row][this.ID_PRODUCT];
        while (
          row < this.products.length &&
          this.products[row][this.ID_PRODUCT] === id
        ) {
          productRows.push(this.products[row]);
          oldProductRows.push(this.currentProducts[row]);
          row++;
        }
        const productToUpdate = this.productToUpdate(
          productRows,
          oldProductRows
        );
        if (productToUpdate) {
          productsToUpdate.push(productToUpdate);
        }
      }
      return productsToUpdate;
    },
    productToUpdate(productRows, oldProductRows) {
      const product = productRows[0];
      const oldProduct = oldProductRows[0];
      const productToUpdate = {};
      if (product[this.TITLE] !== oldProduct[this.TITLE])
        productToUpdate.title = product[this.TITLE];
      if (product[this.PRICE] !== oldProduct[this.PRICE])
        productToUpdate.price = this.cleanPrice(product[this.PRICE] + "");
      const attributes = this.getProductAttributes(product, oldProduct);
      if (attributes) productToUpdate.attributes = attributes;
      const variations = this.getProductVariations(productRows, oldProductRows);
      if (variations) {
        productToUpdate.variations = variations;
      }
      if (Object.keys(productToUpdate).length === 0) return null;
      productToUpdate.id = product[this.ID_PRODUCT];
      productToUpdate.centryProductId = this.mercadoLibreCentryIdHash[
        product[this.ID_PRODUCT]
      ];
      return productToUpdate;
    },
    getProductAttributes(product, oldProduct) {
      const attributes = [];
      for (let i = this.START_ATTRIBUTES; i < product.length; i++) {
        const attribute = this.attributes[i - this.START_ATTRIBUTES];
        const value = product[i];
        const oldValue = oldProduct[i];
        if (
          oldValue !== value &&
          ((!attribute.tags.variation_attribute &&
            !attribute.tags.allow_variations) ||
            product[this.ID_VARIANT] === this.NO_VARIANTS)
        ) {
          attributes.push(this.attributeValue(value, attribute));
        }
      }
      if (attributes.length === 0) return null;
      return attributes;
    },
    attributeValue(value, attribute) {
      if (!value.length) {
        return { id: attribute.id, value_id: null, value_name: null };
      } else if (attribute.values) {
        return { id: attribute.id, value_id: value };
      } else {
        return { id: attribute.id, value_name: value };
      }
    },
    getProductVariations(productRows, oldProductRows) {
      if (
        productRows.length === 1 &&
        productRows[0][this.ID_VARIANT] === this.NO_VARIANTS
      ) {
        return null;
      }
      let hasVariationsChanged = false;
      const variations = productRows.map((variation, index) => {
        const oldVariation = oldProductRows[index];
        const attributes = [];
        const attributeCombinations = [];
        const variationToUpdate = {};
        for (let i = this.START_ATTRIBUTES; i < variation.length; i++) {
          if (variation[i] === oldVariation[i]) continue;
          const attribute = this.attributes[i - this.START_ATTRIBUTES];
          if (attribute.tags.variation_attribute) {
            attributes.push(this.attributeValue(variation[i], attribute));
          } else if (attribute.tags.allow_variations) {
            attributeCombinations.push(
              this.attributeValue(variation[i], attribute)
            );
          }
        }
        if (attributes.length) variationToUpdate.attributes = attributes;
        if (attributeCombinations.length)
          variationToUpdate.attributeCombinations = attributeCombinations;
        if (Object.keys(variationToUpdate).length > 0)
          hasVariationsChanged = true;
        variationToUpdate.id = "" + variation[this.ID_VARIANT];
        return variationToUpdate;
      });
      if (hasVariationsChanged) {
        return variations;
      }
      return null;
    },
    cancel() {
      if (this.changed) {
        this.$swal
          .fire({
            title: "Cancelar",
            text: "Si cancelas, perderás tus cambios. ¿Estás seguro?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "No"
          })
          .then(result => {
            if (result.value) {
              this.$router.push(
                "/mercadolibre/attributes/" +
                  this.integrationConfig.id +
                  "/groups"
              );
            }
          });
      } else {
        this.$router.push(
          "/mercadolibre/attributes/" + this.integrationConfig.id + "/groups"
        );
      }
    }
  }
};
</script>
