<template>
  <div>
    <base-header title="Pedidos" title-link="#">
      <template v-slot:actions>
        <b-container>
          <b-button v-on:click="ordersToExcel">
            <b-icon-file-earmark-arrow-down></b-icon-file-earmark-arrow-down>
            Exportar
          </b-button>
        </b-container>
      </template>
      <template v-slot:filters>
        <orders-masive-actions></orders-masive-actions>
      </template>
    </base-header>
    <b-container fluid="">
      <orders-filters class="mb-3" />
      <b-row>
        <b-col md="12">
          <orders-list v-model="params" @change="change"></orders-list>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import BaseHeader from "../components/BaseHeader.vue";
import OrdersList from "../components/OrdersList.vue";
import OrdersFilters from "../components/OrdersFilters.vue";
//import ProductsSimpleFilter from "../components/ProductsSimpleFilter.vue";
import ORDERS_TO_EXCEL from "../graphql/OrdersToExcel.gql";
import { centryUrl } from "../main";
import OrdersMasiveActions from "@/components/order/OrderMasiveActions";

export default {
  name: "Orders",
  components: {
    OrdersMasiveActions,
    BaseHeader,
    OrdersList,
    OrdersFilters
  },
  data() {
    return {
      centryUrl,
      sucess: false,
      changed: false,
      params: {
        number: null,
        ids: [],
        status: [],
        origin: [],
        create_from: null,
        create_to: null,
        sku: null,
        shipping_label_print: null,
        buyer: null
      }
    };
  },
  methods: {
    change() {
      this.changed = true;
    },
    ordersToExcel() {
      var numbersString = "";
      var skus = "";
      var buyer = "";
      if (this.params.numbers.length != 0) {
        for (var number in this.params.numbers) {
          numbersString += String(this.params.numbers[number]);
          numbersString += ",";
        }
      }
      if (this.params.skus.length != 0) {
        for (var sku in this.params.skus) {
          skus += this.params.skus[sku];
          skus += ",";
        }
      }
      if (this.params.buyers.length != 0) {
        for (var client in this.params.buyers) {
          buyer += this.params.buyers[client];
          buyer += ",";
        }
      }
      this.$apollo
        .mutate({
          mutation: ORDERS_TO_EXCEL,
          variables: {
            params: {
              ids: this.params.ids,
              numbers: numbersString,
              status: this.params.status,
              origin: this.params.origin,
              createFrom: this.params.createFrom,
              createTo: this.params.createTo,
              sku: skus,
              shippingLabelPrint: this.params.shippingLabelPrint,
              buyers: buyer,
              mode: "custom"
            }
          }
        })
        .then(result => {
          this.sucess = result.data.ordersToExcel.result;
        });
    }
  }
};
</script>
