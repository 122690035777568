<template>
  <div>
    <b-modal
      v-model="showModal"
      size="lg"
      @hidden="handleHidden"
      @ok="handleAction"
      @cancel="handleCancel"
    >
      <template #modal-title>
        <base-header
          v-if="!loading"
          :title="modalTitle"
          :titleSize="12"
          :map="mapHeader"
        >
        </base-header>
      </template>

      <b-spinner v-if="loading" label="Spinning"></b-spinner>
      <b-container v-else>
        <b-row>
          <b-col>
            <b-form-group label="Nombre*" label-for="name">
              <b-form-input
                id="name"
                v-model="specification.name"
                placeholder="Nombre"
              >
              </b-form-input>
              <span v-if="requiredMessage.name" class="text-danger">
                El nombre es requerido
              </span>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label="Estado" label-for="status">
              <base-boolean-selector
                v-model="specification.isActive"
                trueText="Activo"
                falseText="Inactivo"
              >
              </base-boolean-selector>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group label="Tipo de campo*" label-for="fieldType">
              <v-select
                id="fieldType"
                placeholder="Seleccione tipo de campo"
                v-model="specification.fieldTypeId"
                :options="fieldTypes"
                :disabled="action === 'update'"
              ></v-select>
              <span v-if="requiredMessage.fieldTypeId" class="text-danger">
                El tipo de campo es requerido
              </span>
              <span v-if="badFieldTypeCombination" class="text-danger">
                El tipo de campo no es compatible para un campo de SKU.
                Seleccione Combo o Radio.
              </span>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group
              label="Grupo de la especificación*"
              label-for="fieldGroup"
            >
              <v-select
                id="fieldGroup"
                placeholder="Seleccione tipo de campo"
                v-model="specification.fieldGroupId"
                :options="fieldGroups"
                :disabled="action === 'update'"
              ></v-select>
              <span v-if="requiredMessage.fieldGroupId" class="text-danger">
                El grupo de la especificación es requerido
              </span>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group label="Descripción" label-for="description">
              <b-form-textarea
                id="description"
                v-model="specification.description"
                placeholder="Descripción"
              >
              </b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group label="Valor por defecto" label-for="defaultValue">
              <b-form-input
                id="defaultValue"
                v-model="specification.defaultValue"
                placeholder="Valor por defecto"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group label="Posición" label-for="position">
              <b-form-input
                id="position"
                type="number"
                v-model="specification.position"
                placeholder="Posición"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group label="Otras opciones">
              <b-form-checkbox inline v-model="specification.isFilter">
                Es filtro
              </b-form-checkbox>
              <b-form-checkbox inline v-model="specification.isRequired">
                Es requerido
              </b-form-checkbox>
              <b-form-checkbox
                inline
                v-model="specification.isOnProductDetails"
              >
                Es campo del producto
              </b-form-checkbox>
              <b-form-checkbox
                inline
                v-model="specification.isStockKeepingUnit"
                :disabled="action === 'update'"
              >
                Es campo de SKU
              </b-form-checkbox>
              <b-form-checkbox
                inline
                v-model="specification.isTopMenuLinkActive"
              >
                Esta activo en menú superior
              </b-form-checkbox>
              <b-form-checkbox
                inline
                v-model="specification.isSideMenuLinkActive"
              >
                Esta activo en menú lateral
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>

      <template #modal-footer="{ cancel, ok }">
        <b-button-group>
          <b-button variant="outline-info" @click="cancel">
            <b-icon-x-circle></b-icon-x-circle> Cancelar
          </b-button>
          <b-button variant="info" @click="ok" :disabled="disabledOkButton">
            <b-icon-cloud-upload></b-icon-cloud-upload> {{ actionTraduction }}
          </b-button>
        </b-button-group>
      </template>
    </b-modal>
  </div>
</template>

<script>
import VTEX_CREATE_SPECIFICATION from "@/graphql/VtexCreateSpecification.gql";
import INTEGRATION_CONFIG_VTEX_GET_SPECIFICATION from "@/graphql/IntegrationConfigVtexGetSpecification.gql";
import VTEX_UPDATE_SPECIFICATION from "@/graphql/VtexUpdateSpecification.gql";

import BaseBooleanSelector from "@/components/BaseBooleanSelector";
import BaseHeader from "@/components/BaseHeader.vue";

const EMPTY_SPECIFICATION = {
  id: null,
  fieldTypeId: null,
  categoryId: null,
  fieldGroupId: null,
  name: null,
  description: null,
  position: null,
  isFilter: null,
  isRequired: null,
  isOnProductDetails: null,
  isStockKeepingUnit: null,
  isWizard: null,
  isActive: false,
  isTopMenuLinkActive: null,
  isSideMenuLinkActive: null,
  defaultValue: null
};
const ACCEPTED_KEYS = Object.keys(EMPTY_SPECIFICATION);
const COMBO_FIELD_TYPE_ID = 5;
const RADIO_FIELD_TYPE_ID = 6;

export default {
  name: "VtexSpecificationModal",
  components: {
    BaseBooleanSelector,
    BaseHeader
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    vtexIntegrationConfigId: {
      type: String,
      required: true
    },
    action: {
      type: String,
      default: "create"
    },
    specificationId: {
      type: Number,
      default: null
    },
    fieldGroups: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      loading: false,
      showModal: false,
      specification: Object.assign({}, EMPTY_SPECIFICATION),
      originalSpecification: Object.assign({}, EMPTY_SPECIFICATION),
      fieldTypes: [
        { value: 1, label: "Texto" },
        { value: 2, label: "Texto Multi-Linea" },
        { value: 4, label: "Número" },
        { value: 5, label: "Combo" },
        { value: 6, label: "Radio" },
        { value: 7, label: "CheckBox" },
        { value: 8, label: "Texto Indexado" },
        { value: 9, label: "Texto Indexado Multi-Linea" }
      ]
    };
  },
  methods: {
    /**
     * Realiza la acción correspondiente al presionar Crear/Editar.
     */
    async handleAction() {
      this.action === "create"
        ? await this.createSpecification()
        : await this.updateSpecification();
    },
    /**
     * Al cancelar los cambios realizados, se dejan registrados en la vista
     * los valores originales de la especificación.
     */
    handleCancel() {
      this.specification = Object.assign({}, this.originalSpecification);
    },
    /**
     * Emite el evento 'input' con el valor necesario para cerrar el modal.
     */
    handleHidden() {
      this.$emit("input", false);
    },
    /**
     * Realiza la petición para crear una especificación en Vtex
     */
    async createSpecification() {
      await this.$apollo
        .mutate({
          mutation: VTEX_CREATE_SPECIFICATION,
          variables: {
            integrationConfigId: this.vtexIntegrationConfigId,
            specification: this.specificationTransformToVtex(this.specification)
          }
        })
        .then(({ data }) => {
          if (!data?.vtexCreateSpecification?.specification) {
            this.emitErrorMessage("No se pudo crear la especificación.");
          } else {
            this.emitSuccessfulMessage(
              "Especificación creada con éxito. Puede tardar unos minutos en reflejarse en el listado."
            );
            this.specification = null;
          }
        })
        .catch(() => {
          this.emitErrorMessage("No se pudo crear la especificación.");
        });
    },
    /**
     * Realiza la petición para obtener una especificación en Vtex.
     * @param {String} specificationId
     */
    async getSpecification(specificationId) {
      this.loading = true;
      await this.$apollo
        .query({
          query: INTEGRATION_CONFIG_VTEX_GET_SPECIFICATION,
          variables: {
            id: this.vtexIntegrationConfigId,
            specificationId: specificationId
          }
        })
        .then(({ data }) => {
          if (!data?.integrationConfigVtex?.getSpecification) {
            this.emitErrorMessage(
              "No se pudo obtener la especificación para poder editarla."
            );
            this.handleHidden();
          } else {
            this.specification = data.integrationConfigVtex.getSpecification;
          }
        });
      this.loading = false;
    },
    /**
     * Realiza la petición para actualizar una especificación en Vtex.
     */
    async updateSpecification() {
      await this.$apollo
        .mutate({
          mutation: VTEX_UPDATE_SPECIFICATION,
          variables: {
            integrationConfigId: this.vtexIntegrationConfigId,
            specificationId: this.specificationId,
            specification: this.specificationTransformToVtex(this.specification)
          }
        })
        .then(({ data }) => {
          if (!data?.vtexUpdateSpecification?.specification) {
            this.emitErrorMessage("No se pudo actualizar la especificación.");
          } else {
            this.emitSuccessfulMessage(
              "Especificación actualizada con éxito. Puede tardar unos minutos en reflejarse en el listado."
            );
            this.specification = data.vtexUpdateSpecification.specification;
          }
        });
    },
    /**
     * Se encarga de transformar los valores seleccionados en la vista a
     * aquellos que deben ser enviados como datos a Vtex.
     * @param {Object} specification
     * @return {Object}
     */
    specificationTransformToVtex(specification) {
      let newSpecification = Object.assign({}, specification);
      if (
        specification.fieldTypeId !== null &&
        typeof specification.fieldTypeId === "object"
      ) {
        newSpecification.fieldTypeId = specification.fieldTypeId.value;
      }
      if (typeof specification.fieldGroupId === "object") {
        newSpecification.categoryId =
          specification.fieldGroupId.value.categoryId;
        newSpecification.fieldGroupId =
          specification.fieldGroupId.value.fieldGroupId;
      }
      if (specification.position !== null) {
        newSpecification.position = parseInt(specification.position);
      }
      return newSpecification;
    },
    /**
     * Se encarga de transformar los valores provenientes de Vtex a
     * aquellos que se utilizan en la vista.
     */
    specificationTransformToVue() {
      if (typeof this.specification.fieldTypeId === "string") {
        this.specification.fieldTypeId = this.searchForFieldType(
          this.specification.fieldTypeId
        );
      }
      if (typeof this.specification.fieldGroupId === "string") {
        this.specification.fieldGroupId = this.searchForFieldGroup(
          this.specification.fieldGroupId
        );
      }
    },
    /**
     * Entrega la opción del selector de tipos correspondiente al id informado.
     * @param {Integer} fieldTypeId
     * @param {Object}
     */
    searchForFieldType(fieldTypeId) {
      return this.fieldTypes.find(fieldType => fieldType.value == fieldTypeId);
    },
    /**
     * Entrega el grupo de especificaciones correspondiente al id informado.
     * @param {Integer} fieldTypeId
     * @param {Object}
     */
    searchForFieldGroup(fieldGroupId) {
      return this.fieldGroups.find(
        fieldGroup => fieldGroup.value.fieldGroupId == fieldGroupId
      );
    },
    /**
     * Emite el evento 'errorMessage' con el mensaje de error correspondiente
     * @param {String} errorMessage
     */
    emitErrorMessage(errorMessage) {
      this.$emit("errorMessage", errorMessage);
    },
    /**
     * Emite el evento 'succesfulMessage' con el mensaje de exito correspondiente
     * @param {String} message
     */
    emitSuccessfulMessage(message) {
      this.$emit("successfulMessage", message);
    },
    /**
     * Se encarga de seleccionar ciertos atributos de la especificación recibida.
     * @param {Object} specification
     * @return {Object}
     */
    selectKeysFromSpecification(specification) {
      return Object.fromEntries(
        ACCEPTED_KEYS.filter(key => key in specification).map(key => [
          key,
          specification[key]
        ])
      );
    },
    /**
     * Revisa que el valor del tipo de dato seleccionado sea combo o radio
     * cuando la especificación es de SKU
     * @return {Boolean}
     */
    correctFieldTypeCombination() {
      if (
        this.specification.isStockKeepingUnit &&
        this.specification.fieldTypeId
      ) {
        let fieldTypeId = this.specification.fieldTypeId.value;
        return (
          fieldTypeId == COMBO_FIELD_TYPE_ID ||
          fieldTypeId == RADIO_FIELD_TYPE_ID
        );
      }
      return true;
    }
  },
  computed: {
    actionTraduction() {
      return this.action === "create" ? "Crear" : "Guardar";
    },
    modalTitle() {
      let title = "grupo de especificaciones";
      return this.action === "create" ? `Crear ${title}` : `Editar ${title}`;
    },
    mapHeader() {
      return this.action === "update" ? [this.specification.name] : [];
    },
    disabledOkButton() {
      let requiredCompleted =
        !!this.specification.name ||
        !!this.specification.fieldTypeId ||
        !!this.specification.fieldGroupId;
      let correctFieldType = this.correctFieldTypeCombination();
      return !requiredCompleted || !correctFieldType;
    },
    requiredMessage() {
      let required = {};
      required.name = !this.specification.name;
      required.fieldTypeId = !this.specification.fieldTypeId;
      required.fieldGroupId = !this.specification.fieldGroupId;
      return required;
    },
    badFieldTypeCombination() {
      return !this.correctFieldTypeCombination();
    }
  },
  watch: {
    value: function(newValue) {
      this.showModal = newValue;
    },
    specification: function(newSpecification) {
      if (newSpecification === null) {
        this.specification = Object.assign({}, EMPTY_SPECIFICATION);
      } else if (
        Object.keys(newSpecification).some(key => !ACCEPTED_KEYS.includes(key))
      ) {
        this.specification = this.selectKeysFromSpecification(newSpecification);
      } else {
        this.specification = newSpecification;
      }
      this.specificationTransformToVue();
      this.originalSpecification = Object.assign({}, this.specification);
    },
    specificationId: function(newSpecificationId) {
      newSpecificationId !== null
        ? this.getSpecification(newSpecificationId)
        : (this.specification = null);
    }
  }
};
</script>
