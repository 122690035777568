<template>
  <b-skeleton type="button" v-if="loading"></b-skeleton>
  <div v-else>
    <span id="button-create" class="d-inline-block" tabindex="0">
      <b-button
        variant="outline-info"
        :disabled="quota <= 0"
        :title="createTitle"
        v-b-tooltip.hover
        :to="link"
        ><b-icon-plus /> Crear</b-button
      >
    </span>
    <b-tooltip target="button-create">
      Crear sugerencia de catalogo
    </b-tooltip>
    <span class="ml-2 text-secondary">{{ quotaText }}</span>
  </div>
</template>
<script>
import MERCADO_LIBRE_SUGGESTION_QUOTA from "../../../graphql/MercadoLibre/CatalogSuggestions/Quota.gql";
export default {
  name: "MercadoLibreCatalogSuggestionsCreate",
  props: {
    integrationConfigId: String
  },
  mounted() {
    this.getQuota();
  },
  data() {
    return {
      quota: 0,
      loading: true,
      error: false
    };
  },
  computed: {
    link() {
      return `/mercadolibre/catalog_suggestions/${this.integrationConfigId}/new`;
    },
    /**
     * Entrega el valor de la quota en texto legible
     * @returns {String}
     */
    quotaText() {
      if (this.quota === 0 && this.error) {
        return "Tu usuario no puede crear sugerencias";
      } else if (this.quota === 0) {
        return "No puedes crear más sugerencias";
      } else if (this.quota === 1) {
        return "Solo puedes crear una sugerencia más";
      }
      return `Puedes crear hasta ${this.quota} sugerencias más`;
    },
    /**
     * Tooltip del botón crear
     * @returns {String}
     */
    createTitle() {
      if (this.quota === 0) {
        return this.quotaText;
      }
      return "";
    }
  },
  methods: {
    /**
     * Obtiene la cuota, y la
     * almacena en el data
     */
    getQuota() {
      this.$apollo
        .query({
          query: MERCADO_LIBRE_SUGGESTION_QUOTA,
          variables: {
            integrationConfigId: this.integrationConfigId
          },
          fetchPolicy: "no-cache"
        })
        .then(({ data }) => {
          const quotas = data.allMercadoLibreCatalogSuggestionsQuota;
          if (quotas) {
            this.quota = quotas.reduce(
              (acum, quota) => acum + quota.available,
              0
            );
          }
        })
        .catch(() => {
          this.error = true;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
