<template>
  <b-tr>
    <b-th>{{ formatedDate }}</b-th>
    <b-th>
      <span v-if="internalHistory.responsible"
        >{{ internalHistory.responsible.firstName }}
        {{ internalHistory.responsible.lastName }}
      </span>
    </b-th>
    <b-th>
      <span v-if="withoutProductsToUpsert" class="text-danger">
        Carga sin productos, intente nuevamente
      </span>
      <span v-else-if="isPendingProgress" class="text-primary">
        Cargando ({{ progress }}%)
      </span>
      <span v-else-if="hasErrors" class="text-danger">
        Carga finalizada con errores
      </span>
      <span v-else-if="hasWarnings" class="text-warning">
        Carga exitosa con advertencias
      </span>
      <span v-else class="text-success">Carga exitosa</span>
      <br />
      <span v-if="!withoutProductsToUpsert" :class="synchronizeStatus[0]">{{
        synchronizeStatus[1]
      }}</span>
    </b-th>
    <b-th>
      <bulk-upload-histories-table-result-modal
        :history="internalHistory"
        :date="formatedDate"
      >
      </bulk-upload-histories-table-result-modal>
    </b-th>
    <b-th>
      <a :href="internalHistory.originalUrl">
        Descargar
      </a>
    </b-th>
    <b-th
      ><span v-if="JSON.parse(internalHistory.sheetErrors).length > 0"
        ><bulk-upload-histories-table-errors
          :errors="reportCorrectedErrors"
          fileName="errores-corregidos.xlsx"
          sheetName="Errores Corregidos"
        >
        </bulk-upload-histories-table-errors></span
    ></b-th>
    <b-th
      ><span v-if="internalHistory.results.length > 0"
        ><bulk-upload-histories-table-errors
          :errors="reportErrors"
          fileName="errores-y-warnings.xlsx"
          sheetName="Errores y Advertencias"
        >
        </bulk-upload-histories-table-errors></span
    ></b-th>
  </b-tr>
</template>
<script>
import BulkUploadHistoriesTableErrors from "@/components/BulkUploadHistoriesTableErrors";
import BulkUploadHistoriesTableResultModal from "@/components/BulkUploadHistoriesTableResultModal";
import { centryUrl } from "@/main";
import { mapState } from "vuex";
import V3_BULK_UPLOAD_PROGRESS_BY_ID from "@/graphql/V3BulkUploadProgressById.gql";
export default {
  name: "BulkUploadHistoriesTableRow",
  components: {
    BulkUploadHistoriesTableErrors,
    BulkUploadHistoriesTableResultModal
  },
  props: {
    history: Object
  },
  mounted() {
    setTimeout(this.updateInternalHistory, 10000);
  },
  beforeDestroy() {
    this.mounted = false;
  },
  data() {
    return {
      translation: {
        action: "Accion",
        level: "Tipo",
        message: "Mensaje",
        sku: "Sku / Id"
      },
      hasErrors: null,
      hasWarnings: null,
      mounted: true,
      internalHistory: this.$dup(this.history),
      centryUrl
    };
  },
  computed: {
    ...mapState(["currentUser"]),
    formatedDate() {
      const date = this.$timezoneDate(
        this.currentUser,
        this.internalHistory.createdAt
      );
      return this.$moment(date).format("DD/MM/YYYY HH:mm");
    },
    reportErrors() {
      let errorsArray = [];
      const keys = Object.keys(this.internalHistory.results[0]);
      keys.splice(4, 1);
      const transKeys = keys.map(key => {
        return this.translation[key];
      });
      errorsArray.push(transKeys);
      errorsArray = errorsArray.concat(
        this.internalHistory.results.map(error => {
          return keys.map(key => {
            if (error[key] === "error") {
              this.hasErrors = true;
            }
            if (error[key] === "warning") {
              this.hasWarnings = true;
            }
            return error[key];
          });
        })
      );
      return errorsArray;
    },
    reportCorrectedErrors() {
      let correctedErrorsArray = [];
      let headerArray = ["Fila"];
      headerArray = headerArray.concat(
        JSON.parse(this.internalHistory.columnsName)
      );
      correctedErrorsArray.push(headerArray.filter(x => x && x.length));
      correctedErrorsArray = correctedErrorsArray.concat(
        JSON.parse(this.internalHistory.sheetErrors)
      );
      return correctedErrorsArray;
    },
    isUpdatableInternalHistory() {
      return (
        this.isPending &&
        new Date() - new Date(this.internalHistory.updatedAt) < 60 * 60 * 1000
      );
    },
    /**
     * Revisa si la carga masiva carece de productos que procesar
     * @return {Boolean}
     */
    withoutProductsToUpsert() {
      return (
        this.internalHistory.productsCreateTotal === 0 &&
        this.internalHistory.productsUpdateTotal === 0
      );
    },
    isPending() {
      return (
        (this.isPendingProgress || this.isPendingSynchronize) &&
        !this.withoutProductsToUpsert
      );
    },
    isPendingProgress() {
      return (
        !this.internalHistory.finished && this.internalHistory.progress < 100
      );
    },
    isPendingSynchronize() {
      return this.internalHistory.syncStatus === "pending_sync";
    },
    progress() {
      return Math.round(this.internalHistory.progress * 100) / 100;
    },
    synchronizeStatus() {
      let status = this.internalHistory.syncStatus;
      let classStatus;
      let messageStatus;
      switch (status) {
        case "successful_sync":
          classStatus = "text-success";
          messageStatus = "Sincronizado";
          break;
        case "pending_sync":
          classStatus = "text-warning";
          messageStatus = "Pendiente por sincronizar";
          break;
        case "not_required_sync":
          classStatus = "";
          messageStatus = "";
          break;
        case "failed_sync":
          classStatus = "text-danger";
          messageStatus = "Error al sincronizar";
      }
      return [classStatus, messageStatus];
    }
  },
  methods: {
    async updateInternalHistory() {
      if (this.isUpdatableInternalHistory) {
        await this.$apollo
          .query({
            query: V3_BULK_UPLOAD_PROGRESS_BY_ID,
            variables: {
              id: this.history.id
            },
            fetchPolicy: "no-cache"
          })
          .then(({ data }) => {
            if (!data || !data.v3BulkUpload) return;
            Object.assign(this.internalHistory, data.v3BulkUpload);
          });
        if (this.mounted) setTimeout(this.updateInternalHistory, 10000);
      }
    }
  }
};
</script>
