<template>
  <div>
    <b-alert
      v-if="error || success"
      show=""
      :variant="alertVariant"
      dismissible
    >
      {{ alertMessage }}
    </b-alert>

    <div align-h="between">
      <b-row>
        <b-col xl="10" cols="12">
          <b-row>
            <b-col cols="12">
              <products-now-excluded-form
                :invalid-state="invalidState"
                v-model="previousProductsNowExcluded"
              />
            </b-col>
            <b-col cols="3" align-self="end">
              <b-table-simple borderless small class="mb-0">
                <b-tr>
                  <b-th style="padding-block: 0px !important">
                    <span class="font-weight-bold text-muted">
                      Cantidad actual de productos excluidos:
                    </span>
                  </b-th>
                  <b-td
                    style="padding-block: 0px !important; padding-left: 0.3rem !important;"
                  >
                    {{ originalExcludedProducts.length }}</b-td
                  >
                </b-tr>
                <b-tr v-if="productsToExclude.length != 0">
                  <b-th style="padding-block: 0px !important">
                    <span class="font-weight-bold text-muted">
                      Cantidad que se excluirán:
                    </span>
                  </b-th>
                  <b-td
                    style="padding-block: 0px !important; padding-left: 0.3rem !important;"
                  >
                    {{ productsToExclude.length }}</b-td
                  >
                </b-tr>
                <b-tr v-if="productsToInclude.length != 0">
                  <b-th style="padding-block: 0px !important">
                    <span class="font-weight-bold text-muted">
                      Cantidad que se dejaran de excluir:
                    </span>
                  </b-th>
                  <b-td
                    style="padding-block: 0px !important; padding-left: 0.3rem !important;"
                  >
                    {{ productsToInclude.length }}</b-td
                  >
                </b-tr>
              </b-table-simple>
            </b-col>
            <b-col class="text-right pr-0">
              <b-form-tags
                class="filter-text"
                v-model="keyword"
                separator=","
                remove-on-delete
                placeholder="Nombre o SKU"
                @keydown.native.enter="updateKeyword"
                @keydown.native.delete="updateSearch"
                @input="updateSearch"
              />
            </b-col>
          </b-row>
          <b-row align-h="between" class="mx-1">
            <b-col class="pr-0">
              <span class="font-weight-bold text-muted float-right">
                {{ filterCaption }}
                <b-button
                  v-if="filterCaption.length"
                  size="sm"
                  @click="cleanFilters"
                >
                  Limpiar filtros
                </b-button>
              </span>
            </b-col>
          </b-row>
          <b-table-simple hover="">
            <b-thead>
              <b-tr>
                <b-th>Nombre</b-th>
                <b-th>SKU</b-th>
                <b-th>Excluido</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-if="!products.length && !loading">
                <b-td colspan="7">No hay productos para mostrar</b-td>
              </b-tr>
              <b-tr v-for="(product, i) of products" :key="i">
                <b-td>{{ product.name }}</b-td>
                <b-td>{{ product.sku }}</b-td>
                <b-td>
                  <b-form-checkbox
                    :checked="excludedProducts.includes(product.id)"
                    @change="evt => handleChange(evt, product.id)"
                    switch
                    :state="false"
                  >
                  </b-form-checkbox>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
          <b-spinner
            v-if="loading"
            label="Spinning"
            class="m-2 float-right"
          ></b-spinner>
          <div v-else>
            <b-button
              v-if="hasNextPage()"
              class="m-2 float-right"
              @click="getProducts()"
            >
              Ver más
            </b-button>
            <b-alert
              v-else-if="!loading"
              show
              variant="light"
              class="text-center"
            >
              No hay más datos para mostrar.
            </b-alert>
          </div>
        </b-col>

        <b-col xl="2" cols="12">
          <b-button
            variant="info"
            :disabled="!changed || saving"
            :title="changed ? '' : 'No hay cambios'"
            @click="save"
            class="mb-3 w-100"
          >
            <span v-if="saving"><b-spinner label="Spinning"></b-spinner></span>
            <span v-else>Guardar</span>
          </b-button>
          <b-button
            variant="outline-info"
            class="w-100 px-0"
            :disabled="!changed || saving"
            @click="resetData()"
          >
            <span>Restablecer</span>
          </b-button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import ALL_PRODUCTS from "../../graphql/IntegrationConfig/AllProductsForExclusion.gql";
import EXCLUDED_PRODUCT_IDS from "../../graphql/IntegrationConfig/ExcludedProductIds.gql";
import UPDATE_INTEGRATION_CONFIG_EXCLUSION from "../../graphql/IntegrationConfig/UpdateIntegrationConfigExclusion.gql";
import ProductsNowExcludedForm from "./ProductsNowExcludedForm.vue";
import { mapMutations, mapState } from "vuex";

export default {
  name: "ExclusionBySpecificProduct",
  components: {
    ProductsNowExcludedForm
  },
  props: {
    ic: {
      type: Object,
      default() {
        return {};
      }
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState(["currentUser"]),
    changed() {
      return (
        this.productsToExclude.length !== 0 ||
        this.productsToInclude.length !== 0
      );
    },
    filterCaption() {
      let caption = [];
      if (this.filters.keyword.length) {
        caption.push(this.filters.keyword);
      }
      return caption.length ? "Buscando por: " + caption.join(" - ") : "";
    },
    invalidState() {
      return (
        this.productsToExclude.length !== 0 && !this.previousProductsNowExcluded
      );
    },
    alertVariant() {
      return this.error ? "danger" : "success";
    },

    alertMessage() {
      return this.error
        ? "Ha ocurrido un error guardando los datos: " + this.errorMessage
        : "Guardado exitosamente";
    }
  },
  data() {
    return {
      products: [],
      excludedProducts: [],
      originalExcludedProducts: [],
      productsToExclude: [],
      productsToInclude: [],
      filters: {
        keyword: "",
        cursor: null,
        first: 15
      },
      loading: false,
      saving: false,
      errorMessage: null,
      error: false,
      success: false,
      previousProductsNowExcluded: null,
      keyword: [],
      newProductsCount: 0
    };
  },
  async mounted() {
    await this.getExcludedProducts();
    this.getProducts();
  },
  methods: {
    ...mapMutations(["setIntegrationConfigChanged"]),
    /**
     * Obtiene los productos segun los keyword y cursor
     */
    async getProducts() {
      this.loading = true;
      await this.$apollo
        .query({ query: ALL_PRODUCTS, variables: this.filters })
        .then(({ data }) => {
          if (data.allProducts.edges.length) {
            this.products = this.products.concat(
              data.allProducts.edges.map(x => x.node)
            );
          }
          this.newProductsCount = data.allProducts.edges.length;
          this.filters.cursor = data.allProducts.pageInfo.endCursor;
          this.loading = false;
        });
    },
    /**
     * Obtiene el id de todos los productos excluidos
     */
    async getExcludedProducts() {
      this.$apollo
        .query({
          query: EXCLUDED_PRODUCT_IDS,
          variables: { integrationConfigId: this.ic.id }
        })
        .then(({ data }) => {
          this.originalExcludedProducts =
            data.integrationConfig.excludedProductIds;
          this.excludedProducts = this.$dup(this.originalExcludedProducts);
        });
    },
    /**
     * Envia la mutacion para guardar los nuevos productos excluidos
     */
    save() {
      this.saving = true;
      this.sofResetData();
      if (!this.invalidState) {
        this.$apollo
          .mutate({
            mutation: UPDATE_INTEGRATION_CONFIG_EXCLUSION,
            variables: {
              patch: {
                id: this.ic.id,
                excludedProductIds: this.productsToExclude,
                includedProductIds: this.productsToInclude
              },
              previousProductsNowExcluded: this.previousProductsNowExcluded
            }
          })
          .then(({ data }) => {
            let response = data.updateIntegrationConfigExclusions;
            if (response.result) {
              this.saving = false;
              this.productsToExclude = [];
              this.productsToInclude = [];
              this.originalExcludedProducts = this.$dup(this.excludedProducts);
              this.previousProductsNowExcluded = null;
              this.success = true;
            } else {
              this.saving = false;
              this.errorMessage = response.errors;
              this.error = true;
            }
          });
      } else {
        this.saving = false;
        this.errorMessage = "Hay campos que se deben rellenar";
        this.error = true;
      }
    },
    /**
     * Se encarga de resetear los datos
     */
    resetData() {
      this.loading = true;
      this.excludedProducts = this.$dup(this.originalExcludedProducts);
      this.previousProductsNowExcluded = null;
      this.productsToExclude = [];
      this.productsToInclude = [];
      this.cleanFilters();
      this.sofResetData();
      this.loading = false;
    },
    /**
     * Se encarga de resetear solo las
     * variables de exito y error
     */
    sofResetData() {
      this.error = false;
      this.success = false;
      this.errorMessage = null;
    },
    /**
     * Consulta si se pueden consultar mas datos o no
     */
    hasNextPage() {
      return this.newProductsCount == this.filters.first;
    },
    /**
     * Limpia los filtros
     */
    cleanFilters() {
      this.keyword = [];
      this.filters.keyword = "";
      this.filters.cursor = null;
    },
    /**
     * Agrega el id del producto a "para incluir" o
     * "para excluir" según el valor del checkbox
     * @param {Boolean} evt
     * @param {String} id
     */
    handleChange(evt, id) {
      if (evt) {
        this.addToExclude(id);
      } else {
        this.addToInclude(id);
      }
    },
    /**
     * Cambia el keyword al formato interesado
     */
    updateKeyword() {
      this.filters.keyword = this.keyword.join(",");
    },
    /**
     * El valor del filtro keyword solo si se ingresa un keyword
     */
    updateSearch() {
      if (!this.keyword.length) {
        this.updateKeyword();
      }
    },
    /**
     * Añade el id de un producto a la lista de excluidos
     * @param {String} id - product_id
     */
    addToExclude(id) {
      this.excludedProducts.push(id);
      let i = this.productsToInclude.findIndex(i => i === id);
      if (i > -1) {
        this.productsToInclude.splice(i, 1);
      } else {
        this.productsToExclude.push(id);
      }
    },
    /**
     * Añade el id de un producto a la lista de incluidos
     * @param {String} id - product_id
     */
    addToInclude(id) {
      let i = this.excludedProducts.findIndex(i => i === id);
      this.excludedProducts.splice(i, 1);
      i = this.productsToExclude.findIndex(i => i === id);
      if (i > -1) {
        this.productsToExclude.splice(i, 1);
      } else {
        this.productsToInclude.push(id);
      }
    }
  },
  watch: {
    "filters.keyword"() {
      this.products = [];
      this.filters.cursor = null;
      this.getProducts();
    },
    changed(val) {
      if (!val) {
        this.previousProductsNowExcluded = null;
      }
      this.setIntegrationConfigChanged(val);
    },
    visible(val) {
      if (val) {
        this.resetData();
      }
    }
  }
};
</script>

<style scoped></style>
