import Vue from "vue";
import Vuex from "vuex";
import moment from "moment-timezone";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentUser: undefined,
    baseUrl: "http://localhost:3000",
    filters: {
      //productos
      keyword: "",
      categoryId: "",
      brandId: "",
      status: null,
      noStock: null,
      //transacciones
      source: null,
      operation: null,
      variantId: null,
      warehouseId: null,
      //monitoreo de sincronizaciones
      createdAtLte: null,
      createdAtGte: null,
      publicApplicationInformationIds: [],
      companyIds: [],
      origins: [], // y pedidos
      //listado de optionces
      name: "",
      quality: null,
      // reporte de ventas
      integrationId: "",
      granularity: "day",
      startDate: moment(new Date())
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .toDate(),
      endDate: moment(new Date())
        .set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
        .toDate(),
      compareDate: null,
      brandIds: [],
      // pedidos
      numbers: [],
      skus: [],
      statuses: [],
      // origins: [], Compartido con monitoreo de sincronizaciones
      createFrom: null,
      createTo: null,
      updateFrom: null,
      updateTo: null,
      shippingLabelPrint: null,
      buyers: [],
      folios: [],
      // pendings
      reHomologate: null,
      valueType: null,
      required: null,
      priorityShipping: null
    },
    allColors: [
      "#FAAF40",
      "#87D6DA",
      "#6C747C",
      "#3CBCC4",
      "#DFE3E8",
      "#E91E63",
      "#455A64",
      "#2b8254",
      "#6610f2",
      "#dc3545",
      "#28a745",
      "#ffc107",
      "#6f42c1",
      "#343a40",
      "#fd7e14",
      "#17a2b8",
      "#6c757d",
      "#007bff",
      "#20c997"
    ],
    mercadoLibreSizeChart: {},
    byLabelColors: {},
    selectedProducts: [],
    // Para vista de pedidos
    selectedOrders: {},
    selectedHomologations: {},
    selectedPendings: {},
    disableOrderSelection: false,
    disablePendingSelection: false,
    // Para vista de integrationConfig
    integrationConfigChanged: false
  },
  mutations: {
    /**
     * Se actualiza el estado "modificado" de la
     * vista de edición de integrationConfig
     * @param {Object} state
     * @param {Boolean} val
     */
    setIntegrationConfigChanged(state, val) {
      state.integrationConfigChanged = val;
    },
    setSelectedHomologations(state, val) {
      state.selectedHomologations = val;
    },
    setLabelColor(state, label) {
      if (!state.byLabelColors[label]) {
        const index = Object.keys(state.byLabelColors).length;
        if (index >= state.allColors.length) {
          state.byLabelColors[label] =
            "#" + ((Math.random() * 0xffffff) << 0).toString(16);
        } else {
          state.byLabelColors[label] = state.allColors[index];
        }
      }
    },
    setDisableOrderSelection(state, val) {
      state.disableOrderSelection = val;
    },
    setSelectedOrder(state, payload) {
      const current = {};
      current[payload.orderId] = {
        order: payload.order,
        selected: payload.selected
      };
      state.selectedOrders = Object.assign({}, state.selectedOrders, current);
    },
    unsetSelectedOrder(state, orderId) {
      delete state.selectedOrders[orderId];
    },
    selectAllsOrders(state, payload) {
      const hash = Object.assign({}, state.selectedOrders);
      payload.forEach(extraOrder => {
        const current = hash[extraOrder.id];
        if (current) {
          current.selected = true;
        } else {
          hash[extraOrder.id] = {
            order: extraOrder,
            extra: true,
            selected: true
          };
        }
      });
      state.selectedOrders = hash;
    },
    unselectExtraOrders(state) {
      const hash = Object.assign({}, state.selectedOrders);
      Object.keys(hash).forEach(oid => {
        const current = hash[oid];
        if (current.extra) {
          current.selected = false;
        }
      });
      state.selectedOrders = hash;
    },
    selectAllOrders(state) {
      const hash = {};
      Object.keys(state.selectedOrders).forEach(oid => {
        hash[oid] = Object.assign(state.selectedOrders[oid], {
          selected: !state.selectedOrders[oid].extra
        });
      });
      state.selectedOrders = hash;
    },
    unselectAllOrders(state) {
      const hash = {};
      Object.keys(state.selectedOrders).forEach(oid => {
        hash[oid] = Object.assign(state.selectedOrders[oid], {
          selected: false
        });
      });
      state.selectedOrders = hash;
    },
    setDisablePendingSelection(state, val) {
      state.disablePendingSelection = val;
    },
    setSelectedPending(state, payload) {
      const current = {};
      current[payload.pendingId] = {
        pending: payload.pending,
        selected: payload.selected
      };
      state.selectedPendings = Object.assign(
        {},
        state.selectedPendings,
        current
      );
    },
    selectAllPendings(state) {
      const hash = {};
      Object.keys(state.selectedPendings).forEach(oid => {
        hash[oid] = Object.assign(state.selectedPendings[oid], {
          selected: !state.selectedPendings[oid].extra
        });
      });
      state.selectedPendings = hash;
    },
    unselectAllPendings(state) {
      const hash = {};
      Object.keys(state.selectedPendings).forEach(oid => {
        hash[oid] = Object.assign(state.selectedPendings[oid], {
          selected: false
        });
      });
      state.selectedPendings = hash;
    },
    setCurrentUser(state, newUser) {
      state.currentUser = newUser;
      try {
        let expirationDate = newUser.company.mostRecentSubscription.expiration;
        if (
          newUser.role != 0 &&
          expirationDate &&
          new Date(expirationDate) < new Date()
        ) {
          if (window.location.pathname != "/subscription") {
            window.location = "/subscription";
          }
        }
      } catch {
        /* continue regardless of error */
      }
    },
    setFilter(state, payload) {
      state.filters = Object.assign({}, state.filters, payload);
    },
    setSelectedProducts(state, payload) {
      state.selectedProducts = payload;
    },
    setMercadoLibreSizeChart(state, sizeChart) {
      state.mercadoLibreSizeChart = sizeChart;
    }
  },
  actions: {
    timezoneDate({ state }, string) {
      let date = new Date(string);
      if (!state.currentUser) return date;
      const dateWithTz = new Date(string).toLocaleString("en-US", {
        timeZone: state.currentUser.company.timeZoneName
      });
      return dateWithTz == "Invalid Date" ? null : dateWithTz;
    }
  }
});
