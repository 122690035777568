<template>
  <div>
    <base-header
      title="Listado de opciones"
      title-link="/admin/option_list"
      :map="['Nuevo']"
    >
    </base-header>
    <b-container fluid="">
      <b-row>
        <b-col md="12">
          <option-list-create-form></option-list-create-form>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import OptionListCreateForm from "@/components/OptionListForm.vue";
import BaseHeader from "@/components/BaseHeader";

export default {
  name: "OptionList",
  components: {
    OptionListCreateForm,
    BaseHeader
  }
};
</script>
