<template>
  <b-col md="12">
    <div class="m-1">
      <b-form-group
        v-if="attribute.id != 'GTIN'"
        :label="attribute.name + ' ' + prettyRequired"
        :label-for="attribute.id"
        :state="validation"
        :invalid-feedback="attribute.name + ' es un atributo obligatorio'"
      >
        <v-select
          v-if="hasValues"
          :id="attribute.id"
          :multiple="attribute.tags.includes('multivalued') || false"
          :options="
            attribute.values.map(x => {
              return { label: x.name, value: x.id };
            })
          "
          v-model="attributeValue"
          :reduce="x => x.value"
        ></v-select>
        <b-input :id="attribute.id" v-model="attributeValue" v-else></b-input>
      </b-form-group>
    </div>
  </b-col>
</template>

<script>
export default {
  name: "MercadoLibreCatalogSuggestionFormAttributeInput",
  components: {},
  props: {
    attribute: {
      type: Object,
      required: true
    },
    previous: [Array, String]
  },
  data() {
    return {
      attributeValue: this.previous || ""
    };
  },
  computed: {
    prettyRequired() {
      if (this.isRequire) return "(Obligatorio)";
      return "";
    },
    isRequire() {
      return this.attribute.tags.includes("required");
    },
    validation() {
      return this.validValue();
    },
    hasValues() {
      if (!this.attribute.values) return false;
      return this.attribute.values && this.attribute.values.length;
    }
  },
  mounted() {},
  methods: {
    /**
     * Emite un evento indicando el cambio de value
     * @method
     */
    emitInput() {
      this.$emit("input", this.attribute, this.attributeValue);
    },
    /**
     * Valida si el atributo actual es obligatorio o no
     * @return {Boolean}
     */
    validValue() {
      return (
        !this.attribute ||
        !this.isRequire ||
        (this.attributeValue !== null && this.attributeValue.length > 0)
      );
    }
  },
  watch: {
    attributeValue() {
      this.$emit("input", this.attribute, this.attributeValue);
    }
  }
};
</script>
