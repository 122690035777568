<template>
  <div>
    <image-gallery-component
      title="Galería"
      styleCard="height: 600px;"
      :productsWithErrors="productsWithErrors"
      :imagesWithErrors="imagesWithErrors"
      :loading="loading"
      v-model="defaultImages"
      :mutation="mutation"
      :getKeyDataMutation="getKeyDataMutation"
      @change="setDefaultImages"
      @updateModalShow="updateModalShow"
      @deleteImage="deleteImage"
      @setProductsWithErrors="setProductsWithErrors"
      @reload="reloadProducts"
    />
    <image-gallery-errors-modal
      v-model="modalShow"
      :productsWithErrors="productsWithErrors"
      :imagesWithErrors="imagesWithErrors"
    />
  </div>
</template>

<script>
import ALL_DEFAULT_IMAGES from "@/graphql/AllDefaultImages.gql";
import CREATE_DEFAULT_IMAGES from "@/graphql/CreateDefaultImages.gql";
import DELETE_DEFAULT_IMAGE from "@/graphql/DeleteDefaultImage.gql";
import ImageGalleryErrorsModal from "./ImageGalleryErrorsModal.vue";
import ImageGalleryComponent from "./ImageGalleryComponent.vue";

export default {
  name: "ImagesGallery",
  components: {
    ImageGalleryComponent,
    ImageGalleryErrorsModal
  },
  props: {},
  data() {
    return {
      products: [this.product],
      defaultImages: [],
      originalDefaultImages: [],
      productsWithErrors: {},
      imagesWithErrors: {},
      loading: true,
      modalShow: false
    };
  },
  mounted() {
    this.getDefaultImages();
  },
  computed: {
    mutation() {
      return CREATE_DEFAULT_IMAGES;
    }
  },
  methods: {
    /**
     * Se encarga de buscar las DefaultImages para una compañia
     */
    async getDefaultImages() {
      this.loading = true;
      await this.$retryQueryWithTimeout(ALL_DEFAULT_IMAGES, {}).then(
        ({ data }) => {
          this.originalDefaultImages = data.allDefaultImages.edges.map(edge => {
            let node = edge.node;
            node["defaultImage"] = true;
            node["originalId"] = node.id;
            return node;
          });
          this.defaultImages = this.$dup(this.originalDefaultImages);
          this.loading = false;
        }
      );
    },
    /**
     * Elimina una imagen dada una posicion y envia la mutacion para eliminarla
     * @param {Number} position - posicion del arreglo a eliminar
     */
    deleteImage(position) {
      let image = this.defaultImages.splice(position, 1);
      if (image[0].originalId) {
        this.$retryMutationWithTimeout(DELETE_DEFAULT_IMAGE, {
          id: image[0].originalId
        }).then(() => {
          this.originalDefaultImages = this.defaultImages;
        });
      }
    },
    /**
     * Modifica la visibilidad del modal de errores
     */
    updateModalShow() {
      this.modalShow = !this.modalShow;
    },
    /**
     * Setea los Productos con errores en vacio
     */
    setProductsWithErrors() {
      this.productsWithErrors = {};
    },
    /**
     * Define cual es la llave que se debe utilizar
     * @param {Object} data
     * @return {Array<Object>}
     */
    getKeyDataMutation(data) {
      return data.createDefaultImages;
    },
    /**
     * Sigue flujo de evento para carga info de productos
     */
    reloadProducts(productsToReload) {
      this.$emit("reload", productsToReload);
    },
    /**
     * Setea valor actual de imagenes
     * @param {Array<Object>} data
     */
    setDefaultImages(data) {
      this.defaultImages = data;
    }
  },
  watch: {}
};
</script>
<style scoped>
.focus-btn {
  color: #aab1b5;
  display: inline-block;
  background-color: white;
  width: 34px;
  height: 34px;
  text-align: center;
  border-radius: 50%;
  font-size: 14px;
  border: 2px solid #e0e2e4;
  padding: 5px 0;
}

.focus-btn:hover {
  border-color: #aab1b5;
  color: black;
}
</style>
