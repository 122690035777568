var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ApolloQuery',{attrs:{"query":require('../graphql/AllProducts.gql'),"variables":{
      cursor: '',
      keyword: _vm.filters.keyword,
      categoryId: _vm.filters.categoryId,
      brandId: _vm.filters.brandId,
      status: _vm.filters.status,
      noStock: _vm.filters.noStock
    },"fetchPolicy":'cache-and-network',"clientId":"apolloClientCached","notifyOnNetworkStatusChange":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var ref_result = ref.result;
    var error = ref_result.error;
    var data = ref_result.data;
    var query = ref.query;
    var isLoading = ref.isLoading;
return [_c('span',{staticClass:"font-weight-bold text-muted float-right"},[_vm._v(" "+_vm._s(_vm.filterCaption)+" "),(_vm.filterCaption.length)?_c('b-button',{attrs:{"size":"sm"},on:{"click":_vm.cleanFilters}},[_vm._v(" Limpiar filtros ")]):_vm._e()],1),(data && isLoading)?_c('span',{staticClass:"m-2 float-right"},[_vm._v(" Actualizando lista de productos... ")]):_vm._e(),(data && data.allProducts)?_c('b-table-simple',{attrs:{"hover":""}},[_c('b-thead',[_c('b-tr',[_c('b-th',{attrs:{"colspan":"3"}},[_vm._v("Producto")]),_c('b-th',[_vm._v("SKU")]),_c('b-th',[_vm._v("Categoría")]),_c('b-th',[_vm._v("Precio")]),_c('b-th')],1)],1),_c('b-tbody',[(!data.allProducts.edges.length)?_c('b-tr',[_c('b-td',{attrs:{"colspan":"7"}},[_vm._v("No tienes productos en tu catálogo")])],1):_vm._e(),_vm._l((data.allProducts.edges),function(product){return _c('products-table-row',{key:product.node.id,attrs:{"product":product.node},on:{"deleting":function($event){return query.refetch()}}})})],2)],1):_vm._e(),(error)?_c('b-alert',{attrs:{"show":"","variant":"danger"}},[_vm._v(" Ha ocurrido un error ")]):_vm._e(),(isLoading)?_c('b-spinner',{staticClass:"m-2 float-right",attrs:{"label":"Spinning"}}):_c('div',[(_vm.hasNextPage(data))?_c('b-button',{staticClass:"m-2 float-right",on:{"click":function($event){return _vm.seeMore(query, data.allProducts.pageInfo.endCursor)}}},[_vm._v(" Ver más ")]):_c('b-alert',{staticClass:"text-center",attrs:{"show":"","variant":"light"}},[_vm._v(" No hay más datos para mostrar. ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }