<template>
  <div>
    <product-inventory-page :product="product"></product-inventory-page>
  </div>
</template>
<script>
import ProductInventoryPage from "@/components/Product/Inventory/ProductInventoryPage.vue";
export default {
  name: "ProductInventory",
  props: {
    product: Object
  },
  components: {
    ProductInventoryPage
  }
};
</script>
