<template>
  <div>
    <b-modal :id="id" cancel-disabled="" size="xl">
      <template v-slot:modal-title>
        <h4>{{ createOrEdit }} tabla de tallas en MercadoLibre</h4>
      </template>

      <template id="content">
        <mercado-libre-grid-chart-selector
          :integrationConfigId="integrationConfigId"
          :loading="loading"
          :sizeChart="sizeChart"
          :showForm="showForm"
          :editMode="editMode"
          v-model="domain"
          id="modal"
          @change="setDomain"
          @hideForm="hideForm"
        >
          <b-spinner v-if="loading"></b-spinner>
          <b-alert show variant="danger" v-if="error">
            Ocurrio un error al tratar de crear la guía de tallas.
            <br />
            {{ errorMessage }}
            <ul>
              <li v-for="(error, i) of details" :key="i">
                {{ error.message }}
              </li>
            </ul>
          </b-alert>
          <b-spinner v-if="loading"></b-spinner>
          <size-chart-mercado-libre-form
            v-else-if="domain"
            :domain="domain"
            :editMode="editMode"
            v-model="internalSizeChart"
            :integration-config-id="integrationConfigId"
            :oldRows="oldRows"
            @change="updateInvalid"
            @changeAttributeRequired="updateAttribute"
          ></size-chart-mercado-libre-form>
        </mercado-libre-grid-chart-selector>
      </template>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button variant="secondary" @click="cancelSizeChart(cancel)">
          Cancelar
        </b-button>
        <b-spinner v-if="saving"></b-spinner>
        <div v-else>
          <b-button
            class="m-2"
            :disabled="invalid || notChange"
            variant="outline-primary"
            @click="() => saveSizeChart(ok, 'refresh')"
          >
            Guardar
          </b-button>
          <b-button
            class="m-2"
            v-if="editMode"
            :disabled="invalid || notChange"
            variant="primary"
            @click="() => saveAndSelectSizeChart(ok)"
          >
            Guardar y Seleccionar
          </b-button>
        </div>
      </template>
      <slot></slot>
    </b-modal>
  </div>
</template>

<script>
import INTEGRATION_CONFIG_MERCADO_LIBRE_DOMAIN from "../../../graphql/IntegrationConfigMercadoLibreDomain.gql";
import MERCADO_LIBRE_CREATE_SIZE_CHART from "../../../graphql/MercadoLibreCreateNewSizeChart.gql";
import MERCADO_LIBRE_UPDATE_SIZE_CHART from "../../../graphql/MercadoLibreUpdateNewSizeChart.gql";
import MercadoLibreGridChartSelector from "./MercadoLibreGridChartSelector.vue";
import SizeChartMercadoLibreForm from "./SizeChartMercadoLibreForm.vue";

export default {
  components: {
    MercadoLibreGridChartSelector,
    SizeChartMercadoLibreForm
  },
  name: "SizeChartMercadoLibreFormModal",
  props: {
    id: {
      type: String,
      required: true
    },
    integrationConfigId: {
      type: String,
      required: true
    },
    editMode: {
      type: Boolean,
      required: true
    },
    sizeChart: {
      type: Object,
      default: null
    },
    oldRows: {
      type: Array,
      default: null
    }
  },
  mounted() {
    this.getDomain();
  },
  data() {
    const internalSizeChart = this.sizeChart
      ? this.$dup(this.sizeChart)
      : this.defaultSizeChart();
    return {
      domain: null,
      showForm: false,
      internalSizeChart: internalSizeChart,
      loading: true,
      saving: false,
      error: false,
      invalid: !internalSizeChart || internalSizeChart.invalid,
      errorMessage: "",
      notChange: true,
      details: []
    };
  },
  computed: {
    createOrEdit() {
      return this.editMode ? "Editar" : "Crear";
    }
  },
  methods: {
    /**
     * Se obtiene dominio asociado a la GDT actual si es que existe GDT
     */
    getDomain() {
      if (!this.sizeChart) {
        this.loading = false;
        return;
      }
      this.loading = true;
      return this.$apollo
        .query({
          query: INTEGRATION_CONFIG_MERCADO_LIBRE_DOMAIN,
          variables: {
            domainId: `${this.sizeChart.siteId}-${this.sizeChart.domainId}`,
            integrationConfigId: this.integrationConfigId
          }
        })
        .then(({ data }) => {
          const domain = data.integrationConfigMercadoLibre.domain;
          domain.domainId = domain.id;
          domain.domainName = domain.name;
          this.domain = domain;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    /**
     * Despliega feedback en pantalla sobre creacion/edicion exitosa/fallida
     * @param {Boolean} success
     */
    savedMessage(success) {
      this.saving = false;
      if (!success) {
        this.error = true;
        return;
      }
      const message = success
        ? "La tabla de tallas se ha guardado exitosamente"
        : "Ha ocurrido un error";
      const icon = success ? "success" : "error";
      return this.$swal.fire({
        title: this.internalSizeChart.id
          ? "Actualización de tabla de tallas"
          : "Creación de tabla de tallas",
        text: message,
        icon: icon,
        showCancelButton: false,
        confirmButtonText: "Aceptar"
      });
    },
    /**
     * Peticion para crear una guia de talla personalizada para mercado libre
     */
    async createSizeChart() {
      const tempBody = this.$dup(this.internalSizeChart);
      delete tempBody.invalid;
      return await this.$apollo
        .mutate({
          mutation: MERCADO_LIBRE_CREATE_SIZE_CHART,
          variables: {
            sizeChart: tempBody,
            integrationConfigId: this.integrationConfigId
          }
        })
        .then(({ data }) => {
          let body = this.$dig(data, "mercadoLibreCreateNewSizeChart");
          const code = parseInt(body.result);
          if (code >= 200 && code < 300) {
            this.errorMessage = "";
            this.details = [];
            return true;
          }
          this.errorMessage = body.errors;
          this.details = body.details;
          return false;
        });
    },
    /**
     * Manda ediciobn de GDT a meli
     * @returns {Boolean}
     */
    async updateSizeChart() {
      const tempBody = this.$dup(this.internalSizeChart);
      const id = tempBody.id;
      delete tempBody.id;
      delete tempBody.attributes;
      delete tempBody.invalid;
      this.reviewMainAttributeInRows(tempBody);
      return await this.$apollo
        .mutate({
          mutation: MERCADO_LIBRE_UPDATE_SIZE_CHART,
          variables: {
            patch: tempBody,
            sizeChartId: id,
            integrationConfigId: this.integrationConfigId
          }
        })
        .then(({ data }) => {
          let body = this.$dig(data, "mercadoLibreUpdateNewSizeChart");
          let result = body.result;
          const code = parseInt(result);
          if (code >= 200 && code < 300) {
            this.errorMessage = "";
            this.details = [];
            return true;
          }
          this.errorMessage = body.errors;
          this.details = body.details;
          return false;
        });
    },
    /**
     * Elimina el atributo principal si es una edicion
     * @param {Object} sizeChartForUpdate
     */
    reviewMainAttributeInRows(sizeChartForUpdate) {
      let mainAttribute = sizeChartForUpdate.main_attribute.attributes[0].id;
      sizeChartForUpdate.rows.map(row => {
        if (!row.id) return row.attributes;
        return this.deleteMainAttributeForEdit(row.attributes, mainAttribute);
      });
    },
    /**
     * Elimina el atributo principal de la fila de GDT
     * En la edicion no se puede enviar/modificar el atributo principal de la GDT
     * el atributo SIZE tampoco se debe enviar
     * @param {Array<Object>} attrs
     * @param {String} mainAttribute
     * @returns {Array<Object>}
     */
    deleteMainAttributeForEdit(attrs, mainAttribute) {
      attrs.forEach((attr, idx) => {
        if (attr.id === mainAttribute || attr.id === "SIZE") {
          attrs.splice(idx, 1);
        }
      });
      return attrs;
    },
    /**
     * Inicia un guardado, identificando si hay que crear o actualizar la GDT
     * @returns {Boolean}
     */
    performSaveSizeChart() {
      if (this.internalSizeChart.id) {
        return this.updateSizeChart();
      }
      return this.createSizeChart();
    },
    /**
     * Intenta guardar una nueva GDT personalizada
     * @param {Function} closeFunction
     * @param {String} event
     */
    saveSizeChart(closeFunction, event) {
      this.saving = true;
      this.error = false;
      this.performSaveSizeChart()
        .then(success => {
          if (success) this.$emit(event, this.internalSizeChart.id);
          this.savedMessage(success).then(() => closeFunction());
        })
        .catch(() => {
          this.savedMessage(false);
        });
    },
    /**
     * Envía evento de guardar y seleccionar una GDT recien editada
     * @param {Function} closeFunction
     */
    saveAndSelectSizeChart(closeFunction) {
      this.saveSizeChart(closeFunction, "select");
    },
    /**
     * Cancela formulario de creacion de GDT
     * @param {Function} cancelFunction
     */
    cancelSizeChart(cancelFunction) {
      this.$emit("cancelForm");
      this.domain = null;
      this.showForm = false;
      cancelFunction();
    },
    /**
     * Actualiza el GDT actual para manda a crear/actualizar
     * @param {Object} internalSizeChart
     */
    updateInvalid(internalSizeChart) {
      this.invalid = !this.internalSizeChart || this.internalSizeChart.invalid;
      this.notChange = !this.internalSizeChart;
      this.internalSizeChart = this.$dup(internalSizeChart);
    },
    /**
     * Actualiza atributo del GDT actual
     * @param {String} key
     * @param {Object} value
     */
    updateAttribute(key, value) {
      this.internalSizeChart.attributes[key] = value;
    },
    /**
     * Construye un GDT por defecto
     * @returns {Object}
     */
    defaultSizeChart() {
      return {
        domainId: null,
        name: null,
        mainAttribute: null,
        attributes: [],
        rows: []
      };
    },
    /**
     * Setea un nuevo dominio seleccionado
     * @param {Object}
     */
    setDomain(newValue) {
      this.domain = newValue;
    },
    /**
     * Esconde modal de formulario de creacion
     */
    hideForm() {
      this.showForm = false;
    }
  },
  watch: {
    domain(newVal) {
      if (newVal) this.showForm = true;
      this.getDomain();
    },
    sizeChart(newVal) {
      this.internalSizeChart = newVal ? this.$dup(newVal) : null;
      this.getDomain();
    }
  }
};
</script>
<style scoped>
.card {
  overflow: visible !important;
}
.modal-content {
  max-height: 700px !important;
  overflow-y: auto !important;
}
</style>
