<template>
  <line-chart :data="chartData" :options="options" />
</template>

<script>
import LineChart from "../../components/charts/LineChart.vue";
import { mapState, mapMutations } from "vuex";

export default {
  name: "QueueLatencyChart",
  components: {
    LineChart
  },
  props: {
    sidekiqQueueStats: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  computed: {
    ...mapState(["byLabelColors"]),
    /**
     * Genera los datos para el gráfico. Esto es, un array de objetos con la
     * siguiente forma:
     * {
     *   labels: ["2019-01-01", "2019-01-02", "2019-01-03"],
     *   datasets: [
     *     {
     *       label: "3 - light_high"
     *       backgroundColor: "#ff0000"
     *       data: [1, 2, 3, 4, 5]      // Latencias de la cola para cada fecha.
     *     }
     *   ]
     * }
     * @returns {Object}
     * @see https://www.chartjs.org/docs/latest/charts/bar.html#example-dataset-configuration
     */
    chartData() {
      return {
        labels: this.chartLabels,
        datasets: this.chartDataset
      };
    },
    /**
     * Genera las labels para el gráfico. Esto es, todas las fechas distintas
     * entre los registros de estadísticas de Sidekiq.
     * @returns {Array}
     */
    chartLabels() {
      return this.sidekiqQueueStats
        .map(s => s.node.time)
        .filter((value, index, array) => array.indexOf(value) === index);
    },
    /**
     * Genera los datasets para el gráfico. Cada uno de los elementos del array
     * tiene la siguiente forma:
     * {
     *   label: "redisIndexGroup - queue", // Ej: "3 - light_high"
     *   backgroundColor: "color",         // Ej: "#ff0000"
     *   data: [1, 2, 3, 4, 5]             // Latencia de la cola para cada fecha.
     * }
     * @returns {Array}
     */
    chartDataset() {
      const indexedDataset = {};
      this.sidekiqQueueStats.forEach(s => {
        const label = `${s.node.redisIndexGroup} - ${s.node.queue}`;
        if (!indexedDataset[label]) {
          indexedDataset[label] = {
            label,
            backgroundColor: this.colorFor(label),
            data: Array(this.chartLabels.length).fill(0)
          };
        }
        const index = this.chartLabels.indexOf(s.node.time);
        indexedDataset[label].data[index] = s.node.latency;
      });
      return Object.values(indexedDataset);
    },
    options() {
      return {
        scales: {
          xAxes: [
            {
              ticks: {
                callback: value =>
                  new Date(value).toLocaleString(undefined, {
                    dateStyle: "short",
                    timeStyle: "short"
                  })
              }
            }
          ],
          yAxes: [
            {
              stacked: false,
              ticks: {
                callback: value => this.secondsToTime(value)
              }
            }
          ]
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              let label = data.datasets[tooltipItem.datasetIndex].label;
              let value =
                data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
              return `${label}: ${this.secondsToTime(value)}`;
            }
          }
        }
      };
    }
  },
  methods: {
    ...mapMutations(["setLabelColor"]),
    /**
     * Elige un color para la label y lo guarda en el store.
     * @param {String} label
     */
    colorFor(label) {
      if (!this.byLabelColors[label]) {
        this.setLabelColor(label);
      }
      return this.byLabelColors[label];
    },
    /**
     * Formatea los segundos a un formato legible. Ej: 3635 => "01:00:35"
     * @param {int} seconds
     */
    secondsToTime(seconds) {
      return new Date(seconds * 1000).toISOString().slice(11, 19);
    }
  }
};
</script>
