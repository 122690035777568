<template>
  <b-tr>
    <b-td
      ><b-img
        thumbnail
        :src="imageUrl"
        class="centry-thumb transparent-bg"
        alt="image"
        loading="lazy"
        v-if="imageUrl"
        width="80px"
      ></b-img>
      <div v-else class="centry-thumb centry-no-image img-thumbnail">
        <b-icon icon="image" variant="secondary" font-scale="2"></b-icon>
      </div>
    </b-td>
    <b-td>{{ product.title }}</b-td>
    <b-td>{{ product.price }}</b-td>
    <b-td>{{ brand }}</b-td>
    <b-td>{{ model }}</b-td>
    <b-td
      ><b-button variant="outline-info" @click="emitSelected"
        >Seleccionar</b-button
      ></b-td
    >
  </b-tr>
</template>
<script>
export default {
  name: "MercadoLibreItemRow",
  props: {
    product: Object
  },
  computed: {
    /**
     * Entrega la url de la imagen
     * @returns {String}
     */
    imageUrl() {
      return this.$dig(this.product, "pictures", 0, "url");
    },
    brand() {
      return this.valueName("BRAND");
    },
    model() {
      return this.valueName("MODEL");
    }
  },
  methods: {
    /**
     * Emite que se presionó el botón seleccionar
     */
    emitSelected() {
      this.$emit("select", this.product);
    },
    /**
     * Entrega el value_name de un attribute
     * @param {String}
     * @returns {String}
     */
    valueName(attrId) {
      const attributes = this.$dig(this.product, "attributes");
      if (!attributes) return "";

      const attribute = attributes.find(attr => attr.id === attrId);
      if (!attribute) return "";

      const values = attribute.values;
      if (!values || !values.length) return "";

      return this.$ifNull(values[0].name, "");
    }
  }
};
</script>
