<template>
  <b-button-group>
    <b-button
      :variant="value == 'active' ? 'success' : 'outline-secondary'"
      @click="change('active')"
    >
      {{ trueText }}
    </b-button>
    <b-button
      :variant="value == 'paused' ? 'danger' : 'outline-secondary'"
      @click="change('paused')"
    >
      {{ falseText }}
    </b-button>
    <b-button
      v-if="allowNull"
      :variant="value == '' ? 'secondary' : 'outline-secondary'"
      @click="change('')"
    >
      {{ defaultText }}
    </b-button>
  </b-button-group>
</template>
<script>
export default {
  name: "ProductEditTabsMercadoLibreBooleanSelectorStatus",
  model: {
    prop: "_value",
    event: "change"
  },
  props: {
    _value: String,
    allowNull: {
      type: Boolean,
      default: false
    },
    trueText: {
      type: String,
      default: "Activo"
    },
    falseText: {
      type: String,
      default: "Pausado"
    },
    defaultText: {
      type: String,
      default: "Por defecto"
    }
  },
  data() {
    return {
      value: this._value
    };
  },
  methods: {
    change(value) {
      this.value = value;
      this.$emit("change", value);
    }
  }
};
</script>
