<template>
  <b-card :style="styleCard">
    <b-card-title>
      <b-row align-h="between" class="w-100 mx-0">
        <div>
          {{ title }}
        </div>
        <b-form-file
          accept="image/jpg, image/jpeg, image/png, image/gif"
          ref="pickImage"
          v-show="false"
          @change="e => uploadImages(e, true)"
          multiple
        ></b-form-file>
        <div>
          <b-spinner v-if="addingImages" small class="m-1" />
          <b-button
            v-if="
              Object.keys(productsWithErrors) != 0 ||
                Object.keys(imagesWithErrors) != 0
            "
            size="lg"
            variant="outlined"
            class="p-0"
            v-b-tooltip.hover="'Hubo problemas con algunas imágenes'"
            @click="updateModalShow"
          >
            <b-icon
              icon="exclamation-circle"
              variant="light"
              class="rounded-circle bg-danger m-1"
            ></b-icon>
          </b-button>
          <b-button
            size="lg"
            variant="outlined"
            class="p-0 focus-btn"
            v-b-tooltip.hover="'Cargar archivos a la galería'"
            @click="openSelectorFiles"
          >
            <b-icon stacked icon="cloud-arrow-up" scale="0.75" />
          </b-button>
        </div>
      </b-row>
    </b-card-title>
    <b-card-body class="p-0" style="height: 100%; overflow: auto">
      <base-skeleton-image-row v-if="loading" />
      <b-row
        class="mx-0"
        @dragover.prevent
        @drop="onDrop"
        style="height: 100%"
        v-else
      >
        <b-col cols="12">
          <b-row>
            <b-alert
              class="w-100 text-center"
              v-model="showAlert"
              dismissible
              variant="danger"
              style="white-space: break-spaces;"
            >
              Algunos archivos ingresados no son imágenes, por favor ingrese
              solo imágenes.
            </b-alert>
          </b-row>
          <b-row style="height: 100%">
            <draggable
              id="gallery-drag"
              v-model="defaultImages"
              tag="tbody"
              v-bind="dragOptions"
              handle=".handle"
              style="display: flex; flex-wrap: wrap; width: 100%"
            >
              <div
                style="width: 150px !important; height: 200px"
                class="px-0 mr-1 mb-2"
                v-for="(asset, position) of defaultImages"
                :key="position"
              >
                <product-image-container
                  :asset="asset"
                  :position="position"
                  @delete-image="deleteImage"
                  no-copies
                />
              </div>
              <b-col
                v-if="defaultImages.length == 0"
                style="width: 100%; height: 100%"
              >
                <b-alert
                  show
                  variant="light"
                  class="text-center"
                  style="white-space: break-spaces;"
                >
                  <h4>
                    Arrastra tus imágenes aquí o selecciona el botón para
                    agregar imágenes.
                  </h4>
                  <p class="h1 mb-2"><b-icon icon="cloud-upload"></b-icon></p>
                </b-alert>
              </b-col>
            </draggable>
          </b-row>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import BaseSkeletonImageRow from "./../BaseSkeletonImageRow.vue";
import ProductImageContainer from "./ProductImageContainer.vue";
import DELETE_DEFAULT_IMAGE from "@/graphql/DeleteDefaultImage.gql";

const emptyDefaultImagesData = {
  countFiles: 0,
  count: 0,
  newAssets: []
};

export default {
  name: "ImageGalleryComponent",
  components: {
    BaseSkeletonImageRow,
    ProductImageContainer
  },
  model: {
    prop: "_defaultImages",
    event: "change"
  },
  props: {
    title: {
      type: String,
      required: true
    },
    styleCard: {
      type: String,
      required: true
    },
    productsWithErrors: {
      type: Object,
      required: true
    },
    imagesWithErrors: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    _defaultImages: {
      type: Array,
      required: true
    },
    getKeyDataMutation: {
      type: Function,
      required: true
    },
    mutation: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      defaultImages: this._defaultImages,
      createDefaultImagesData: this.$dup(emptyDefaultImagesData),
      showAlert: false,
      addingImages: false,
      parallelBatches: 1,
      maxImagesPerBatch: 5,
      maxBytesPerBatch: 500000
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },
  mounted() {},
  methods: {
    /**
     * Obtiene la url de un archivo para poder ver la
     * imagen mientras se guarda en la base de datos
     * @param {Object} files - objeto con los archivos subidos
     * @param {Boolean} isEvent - indica si los archivos vienen de un evento o no
     */
    uploadImages(files, isEvent) {
      this.showAlert = false;
      if (isEvent) {
        files = files.currentTarget.files;
      }
      let areImages = this.$checkFileType(files);
      if (areImages) {
        this.createDefaultImagesData = this.$dup(emptyDefaultImagesData);
        this.createDefaultImagesData.countFiles = files.length;
        Object.keys(files).forEach(i => {
          this.readFile(files[i]);
        });
      } else {
        this.showAlert = true;
      }
    },
    /**
     * Lee y procesa la imagen a partir del archivo recibido
     * @param {File} file
     */
    readFile(file) {
      const reader = new FileReader();
      reader.onload = e => {
        this.processOnLoadEventFile(e, file);
      };
      reader.readAsDataURL(file);
    },
    /**
     * Ataja y emite evento para motrar modal
     */
    updateModalShow() {
      this.$emit("updateModalShow");
    },
    /**
     * Procesa el evento ejecutado al leer el archivo de la imagen. Inicia la
     * creación de las imágenes si ya todas fueron leidas.
     * @param {Object} event
     * @param {File} file
     */
    processOnLoadEventFile(event, file) {
      let newAsset = this.newAssetFromOnLoadEventFile(event, file);
      this.createDefaultImagesData.newAssets.push(newAsset);
      this.defaultImages.push(newAsset);
      this.createDefaultImagesData.count += 1;
      if (
        this.createDefaultImagesData.count ==
        this.createDefaultImagesData.countFiles
      ) {
        this.createImages(this.createDefaultImagesData.newAssets);
      }
    },
    /**
     * Se encarga de hacer la mutación que crea las defaultImages
     * @param {Array<Object>} images - lista de imágenes a crear
     */
    async createImages(images) {
      this.addingImages = true;
      this.$emit("setProductsWithErrors", {});
      this.$limitedDataExecution(
        this.splitImagesInBatches(images),
        this.parallelBatches,
        this.executeCreateDefaultImagesMutation
      ).then(() => {
        this.addingImages = false;
      });
    },
    /**
     * Ejecuta la mutación para crear las imágenes recibidasm luego actualiza la
     * vista con el resultado obtenido.
     * @param {<Array<Object>>} imagesData
     */
    async executeCreateDefaultImagesMutation(imagesData) {
      await this.$retryMutationWithTimeout(this.mutation, {
        patch: imagesData
      })
        .then(({ data }) => {
          if (this.defaultImagesFatalError(data)) {
            this.addBatchToErrors(
              imagesData,
              this.getKeyDataMutation(data).errors
            );
          } else {
            this.updateDefaultImages(
              this.getKeyDataMutation(data).defaultImages
            );
            this.removeDefaultImages(
              this.getKeyDataMutation(data).productsAssets
            );
            this.failedDefaultImages(
              this.getKeyDataMutation(data).imagesCantSave
            );
            this.errorsDefaultImages(this.getKeyDataMutation(data).errors);
            this.$emit("change", this.defaultImages);
          }
        })
        .catch(error => {
          this.addBatchToErrors(imagesData, [error]);
        });
    },
    /**
     * Identifica las imagenes que no se pudieron crear en los
     * productos debido a que ya existia esa imagen en el producto
     * @param {Array<Object>} images - lista de imagenes fallidas
     */
    failedDefaultImages(images) {
      if (images === undefined) return;
      let indexs_failed = [];
      images.forEach(image => {
        this.defaultImages.forEach((asset, index) => {
          if (image.original_filename == asset.originalId) {
            image["originalUrl"] = asset.originalUrl;
            indexs_failed.push(index);
            if (image.product_sku in this.productsWithErrors) {
              this.productsWithErrors[image.product_sku].push(image);
            } else {
              this.productsWithErrors[image.product_sku] = [image];
            }
          }
        });
      });
      indexs_failed.sort((a, b) => b - a);
      indexs_failed.forEach(i => {
        this.defaultImages.splice(i, 1);
      });
    },
    /**
     * Elimina las imagenes que se añadieron a los productos
     * @param {Array<Object>} images - lista de imagenes a borrar
     */
    removeDefaultImages(images) {
      if (images === undefined) return;
      let indexs_create = [];
      let productsToReload = [];
      images.forEach(image => {
        this.defaultImages.forEach((asset, index) => {
          if (image.original_filename == asset.originalId) {
            indexs_create.push(index);
            productsToReload.push(image.product_id);
          }
        });
      });
      this.$emit("reload", productsToReload);
      indexs_create.sort((a, b) => b - a);
      indexs_create.forEach(i => {
        this.defaultImages.splice(i, 1);
      });
    },
    /**
     * Define si ocurrio un error en el flujo de la creación de imágenes en
     * el servidor
     * @param {Object} data
     * @return {Boolean}
     */
    defaultImagesFatalError(data) {
      return (
        this.getKeyDataMutation(data).defaultImages == null &&
        this.getKeyDataMutation(data).productsAssets == null &&
        this.getKeyDataMutation(data).imagesCantSave == null &&
        this.getKeyDataMutation(data).errors.length > 0
      );
    },
    /**
     * Si se genera un error del lado del servidor mientras se procesa un batch
     * se agrega el batch completo a los errores de la galería
     */
    addBatchToErrors(imagesData, errors) {
      let imagesErrors = imagesData.map(image => {
        return {
          original_filename: image.originalFilename,
          messages: errors
        };
      });
      this.errorsDefaultImages(imagesErrors);
    },
    /**
     * Identifica las imagenes que presentaron errores durante su creación
     * @param {Array<Object>} images
     */
    errorsDefaultImages(images) {
      let indexs_failed = [];
      images.forEach(image => {
        this.defaultImages.forEach((asset, index) => {
          if (image.original_filename == asset.originalId) {
            image["originalUrl"] = asset.originalUrl;
            indexs_failed.push(index);
            this.imagesWithErrors[image.original_filename] = image;
          }
        });
      });
      indexs_failed.sort((a, b) => b - a);
      indexs_failed.forEach(i => {
        this.defaultImages.splice(i, 1);
      });
    },
    /**
     * Actualiza las imagenes temporales con el
     * id y url de la imagen creada
     * @param {Array<Object>} images - lista de imagenes creadas
     */
    updateDefaultImages(images) {
      images.forEach(image => {
        const newDefaultImages = this.defaultImages.map(asset => {
          let newFilename = this.expectedFilename(asset.originalId);
          if (asset.id == "" && newFilename == image.imageFileName) {
            asset.id = image.id;
            asset.originalId = image.id;
            asset.originalUrl = image.originalUrl;
            asset.defaultImage = true;
            delete asset.file;
          }
          return asset;
        });
        this.defaultImages = newDefaultImages;
      });
    },
    /**
     * Transforma el nombre de la imagen al formato informado desde el servidor
     * @param {String} originalFilename
     * @return {String}
     */
    expectedFilename(originalFilename) {
      let extension = originalFilename.split(".")[1];
      return (
        originalFilename
          .split(".")[0]
          .substring(0, 20)
          .replaceAll(" ", "_")
          .replaceAll(",", "_") +
        "." +
        extension
      );
    },
    /**
     * Divide los datos de las imágenes por crear en grupos de máximo
     * <this.maxImagesPerBatch> imágenes o de máximo <this.maxBytesPerBatch>
     * bytes. El listado retornado comienza enviando la mutación para el grupo
     * de menor tamaño en bytes hasta el de mayor tamaño.
     * @param {Array<Object>} images
     * @return {Array<Array<Object>>}
     */
    splitImagesInBatches(images) {
      images.sort((a, b) => b.size - a.size);
      let batchesToProcess = [];
      while (images.length > 0) {
        batchesToProcess.push(
          this.dataToCreateImages(this.createImagesBatch(images))
        );
      }
      return batchesToProcess.reverse();
    },
    /**
     * Se queda con los datos necesarios de las imágenes para su creación
     * @param {Array<Object>} images
     * @return {Array<Object>}
     */
    dataToCreateImages(images) {
      return images.map(image => {
        return {
          image: image.originalUrl,
          originalFilename: image.file.name
        };
      });
    },
    /**
     * Genera un batch de imágenes. El batch siempre comienza tomando la imagen
     * de mayor tamaño disponible y luego, sin superar los límites de cantidad y
     * de tamaño por batch, agrega la imagen de mayor tamaño que se pueda.
     * @param {Array<Object>} images
     * @return {Array<Object>}
     */
    createImagesBatch(images) {
      let batch = [];
      let batchBytes = 0;
      while (
        batch.length < this.maxImagesPerBatch &&
        batchBytes < this.maxBytesPerBatch &&
        images.length > 0
      ) {
        if (
          batch.length == 0 ||
          images[0].size + batchBytes < this.maxBytesPerBatch
        ) {
          batchBytes += images[0].size;
          batch.push(images.splice(0, 1)[0]);
        } else {
          let imageIndex = this.binarySearchByImageSize(
            images,
            this.maxBytesPerBatch - batchBytes
          );
          if (imageIndex >= 0) {
            batchBytes += images[imageIndex].size;
            batch.push(images.splice(imageIndex, 1)[0]);
          } else {
            return batch;
          }
        }
      }
      return batch;
    },
    /**
     * Busca el índice de la imagen de mayor peso que no supere el
     * limite de bytes disponibles para el batch.
     * @param {Array<Object>} images
     * @param {Integer} sizeLimit
     * @return {Integer}
     */
    binarySearchByImageSize(images, sizeLimit) {
      let start = 0;
      let end = images.length - 1;
      let lastValidIndex = -1;

      while (start <= end) {
        let mid = Math.ceil((start + end) / 2);
        if (images[mid].size > sizeLimit) {
          start = mid + 1;
        } else {
          end = mid - 1;
          lastValidIndex = mid;
        }
      }
      return lastValidIndex;
    },
    /**
     * Genera el objeto utilizado para manejar el asset a partir del evento de
     * lectura del archivo y del archivo en si
     * @param {Object} event
     * @param {File} file
     * @return {Object}
     */
    newAssetFromOnLoadEventFile(event, file) {
      let asset = {};
      asset["originalUrl"] = event.target.result;
      asset["id"] = "";
      asset["originalId"] = file.name;
      asset["file"] = file;
      asset["size"] = file.size;
      return asset;
    },
    /**
     * Maneja los archivos cuando se hace un drop
     * @param {Object} e - evento que llama la funcion
     */
    onDrop(e) {
      e.stopPropagation();
      e.preventDefault();
      var files = e.dataTransfer.files;
      this.uploadImages(files, false);
    },
    /**
     * Captura y emite evento de eliminación de imagen
     * @param {Integer} position
     */
    deleteImage(position) {
      let image = this.defaultImages.splice(position, 1);
      if (image[0].originalId) {
        this.$retryMutationWithTimeout(DELETE_DEFAULT_IMAGE, {
          id: image[0].originalId
        }).then(() => {
          this.$emit("change", this.defaultImages);
        });
      }
    },
    /**
     * Abre el selector de archivos local
     */
    openSelectorFiles() {
      this.$refs.pickImage.$el.childNodes[0].value = null;
      this.$refs.pickImage.$el.childNodes[0].click();
    }
  },
  watch: {
    _defaultImages(newValue) {
      this.defaultImages = newValue;
    }
  }
};
</script>
<style scoped>
.focus-btn {
  color: #aab1b5;
  display: inline-block;
  background-color: white;
  width: 34px;
  height: 34px;
  text-align: center;
  border-radius: 50%;
  font-size: 14px;
  border: 2px solid #e0e2e4;
  padding: 5px 0;
}

.focus-btn:hover {
  border-color: #aab1b5;
  color: black;
}
</style>
