<template>
  <div>
    <b-table-simple>
      <b-thead>
        <b-th>Integración</b-th>
        <b-th>Path</b-th>
        <b-th>Tipo</b-th>
        <b-th></b-th>
      </b-thead>
      <b-tbody>
        <b-tr v-if="!purposes.length">
          <b-td colspan="4">No tienes homologaciones.</b-td>
        </b-tr>
        <b-tr v-for="purpose of purposes" :key="purpose.id">
          <b-td>{{ purpose.node.integrationConfig.label }}</b-td>
          <b-td>{{ purpose.node.specName }}</b-td>
          <b-td>{{ purpose.node.dataType | ColumnTypeTranslate }}</b-td>
          <b-td>
            <b-dropdown variant="white" no-caret="" offset="-110">
              <template v-slot:button-content>
                <b-icon-three-dots-vertical></b-icon-three-dots-vertical>
              </template>
              <b-dropdown-item @click="() => deleteIfAccept(purpose)">
                Eliminar
              </b-dropdown-item>
            </b-dropdown>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>
<script>
import DELETE_VTEX_PURPOSE from "@/graphql/DeleteVtexPurpose.gql";

export default {
  name: "VtexHomologationAttributesTable",
  components: {},
  props: {
    purposes: Array
  },
  methods: {
    /**
     * Elimina el purpose si se acepta el cuadro de texto.
     * @param {Object} purpose - Purpose que se quiere eliminar
     */
    deleteIfAccept(purpose) {
      this.$swal
        .fire({
          title: "Eliminar",
          text:
            'Se eliminará la homologación "' +
            purpose.node.specName +
            '" permanentemente ¿Está seguro?',
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si"
        })
        .then(result => {
          if (result.value) {
            this.deletePurpose(purpose.node.id);
          }
        });
    },
    /**
     * Envia la mutación para eliminar el purpose
     * @param {String} id - identificador del purpose
     */
    deletePurpose(id) {
      this.$apollo
        .mutate({
          mutation: DELETE_VTEX_PURPOSE,
          variables: {
            id: id
          }
        })
        .then(({ data }) => {
          let result = data.deleteVtexPurpose.result;
          if (result) {
            this.$emit("change-purposes");
          }
          this.errorMessageDeleting(result);
        });
    },
    /**
     * Muestra un mensaje de exito o error dependiendo del
     * resultado que tuvo la mutación
     * @param {Boolean} result - Resultado de la mutación
     */
    errorMessageDeleting(result) {
      let message;
      if (result) {
        message = "El destino fue eliminado correctamente.";
      } else {
        message = "Ha ocurrido un error al eliminar la homologación";
      }
      this.$swal.fire({
        icon: result ? "success" : "error",
        text: message
      });
    }
  }
};
</script>
