<template>
  <div>
    <b-spinner label="Spinning" v-if="!homologations || !usedSizes"></b-spinner>
    <div v-else>
      <b-checkbox v-model="usedSizesOnly" name="usedSizesOnly">
        Homologar solo tallas en uso
      </b-checkbox>
      <base-j-excel-table
        v-model="homologations"
        :columns="columns"
        :allow-search="true"
        :pagination="50"
        :allow-insert-row="false"
        :allow-insert-column="false"
      ></base-j-excel-table>
    </div>
  </div>
</template>
<script>
import BaseJExcelTable from "../../BaseJExcelTable.vue";
import ALL_SIZES from "../../../graphql/AllSizes.gql";
import { mapState } from "vuex";
export default {
  components: { BaseJExcelTable },
  name: "SizeChartMercadoLibreHomologator",
  model: {
    prop: "_value",
    event: "change"
  },
  props: {
    sizeChart: {
      type: Object,
      required: true
    },
    centrySizes: {
      type: Array,
      required: true
    },
    _value: {
      type: Array
    }
  },
  mounted() {
    this.setColumns();
    this.getUsedSizes();
  },
  data() {
    return {
      columns: null,
      homologations: this.$dup(this._value),
      usedSizes: null,
      usedSizesOnly: false,
      notVisible: []
    };
  },
  computed: {
    ...mapState(["currentUser"]),
    allHomologations() {
      return this.homologations.concat(this.notVisible);
    }
  },
  methods: {
    /**
     * Construye opcion segun fila GDT de meli
     * @param {Object} row
     * @returns {Object}
     */
    optionFromRow(row) {
      return {
        id: row.id,
        name: row.attributes
          .map(attr => attr.id + ": " + attr.values[0].name)
          .join("; ")
      };
    },
    /**
     * Setea las columnas para homologaciones de tallas entre centry y meli
     */
    setColumns() {
      this.columns = [
        {
          type: "autocomplete",
          title: "Tallas Centry",
          source: this.centrySizes,
          readOnly: true,
          width: 120
        },
        {
          type: "autocomplete",
          title: "Tallas Mercado Libre",
          source: this.sizeChart.rows.map(row => this.optionFromRow(row)),
          width: 500
        }
      ];
    },
    /**
     * Consulta por tallas que son utilizadas
     */
    getUsedSizes() {
      this.$getAllPages(
        ALL_SIZES,
        { companyId: this.currentUser.company.id },
        "allSizes"
      ).then(sizes => {
        this.usedSizes = sizes.map(size => size.node);
      });
    },
    /**
     * Emite cambio en las homologaciones
     * Se envian homologaciones dado el filtro
     */
    emitChangeHomologations() {
      let currentHomologations = this.usedSizesOnly
        ? this.allHomologations
        : this.homologations;
      this.$emit("change", currentHomologations);
    }
  },
  watch: {
    _value(newValue) {
      if (!this.usedSizesOnly) this.homologations = newValue;
    },
    usedSizesOnly(newValue) {
      const usedSizesHash = {};
      this.usedSizes.forEach(size => {
        usedSizesHash[size.id] = true;
      });
      if (newValue) {
        this.notVisible = this.homologations.filter(
          homologation => !usedSizesHash[homologation[0]]
        );
        this.homologations = this.homologations.filter(
          homologation => !!usedSizesHash[homologation[0]]
        );
      } else {
        this.homologations = this.homologations.concat(this.notVisible);
        this.notVisible = [];
      }
    },
    sizeChart() {
      this.setColumns();
    },
    homologations() {
      this.emitChangeHomologations();
    }
  }
};
</script>
