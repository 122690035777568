<template>
  <div>
    <b-card no-body>
      <b-tabs pills card v-model="step" @activate-tab="preventIfNeeded">
        <b-tab title="Cargar archivo" active>
          <bulk-upload-tabs-load-file @ready="x => goToStep(1, x)">
          </bulk-upload-tabs-load-file>
        </b-tab>
        <b-tab title="Seleccionar columnas" lazy>
          <bulk-upload-tabs-select-columns
            @ready="x => goToStep(2, x)"
            :data="data"
          >
          </bulk-upload-tabs-select-columns>
        </b-tab>
        <b-tab title="Configurar sincronizaciones" lazy>
          <bulk-upload-tabs-synchronization
            @ready="x => goToStep(3, x)"
            :data="data"
          >
          </bulk-upload-tabs-synchronization>
        </b-tab>
        <b-tab title="Resultado" lazy>
          <bulk-upload-tabs-perform-load :data="data">
          </bulk-upload-tabs-perform-load>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>
<script>
import BulkUploadTabsLoadFile from "@/components/BulkUploadTabsLoadFile";
import BulkUploadTabsSelectColumns from "@/components/BulkUploadTabsSelectColumns";
import BulkUploadTabsSynchronization from "@/components/BulkUploadTabsSynchronization";
import BulkUploadTabsPerformLoad from "@/components/BulkUploadTabsPerformLoad";
export default {
  name: "BulkUploadTabs",
  components: {
    BulkUploadTabsPerformLoad,
    BulkUploadTabsSynchronization,
    BulkUploadTabsSelectColumns,
    BulkUploadTabsLoadFile
  },
  data() {
    return {
      //STEPS
      // 0 => carga de archivo
      // 1 => selección de columnas
      // 2 => sincronización
      // 3 => resultado
      step: 0,
      automaticStep: 0,
      data: null,
      info: {},
      disabled: [false, true, true, true]
    };
  },
  methods: {
    goToStep(step, data) {
      this.data = data;
      this.automaticStep = step;
      this.step = step;
    },
    preventIfNeeded(newTab, prevTab, evt) {
      if (newTab === this.automaticStep) return;
      evt.preventDefault();
      if (prevTab === 3) return;
      if (newTab < this.step) {
        this.$swal
          .fire({
            title: "Cambiar de pestaña",
            text:
              "Si cambia de pestaña, los cambios realizados en este paso se perderán. ¿Está seguro?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "No"
          })
          .then(result => {
            if (result.value) {
              this.automaticStep = newTab;
              this.step = newTab;
            }
          });
      }
    }
  }
};
</script>
