<template>
  <div>
    <b-alert
      v-if="error || success"
      :variant="alertVariant"
      :show="error ? true : dismissCountDown"
      dismissible
      @dismissed="softReset"
      @dismiss-count-down="countDownChanged"
    >
      {{ alertMessage }}</b-alert
    >
    <b-table-simple>
      <b-thead>
        <b-tr>
          <b-th>Talla</b-th>
          <b-th>Color</b-th>
          <b-th>Cantidad</b-th>
          <b-th>SKU</b-th>
          <b-th>Código de barra (ISBN, UPC, ETC.)</b-th>
          <b-th></b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="(variant, index) of newVariants" :key="index">
          <b-td>{{ $dig(variant, "size", "name") || "--Sin talla--" }}</b-td>
          <b-td>{{ $dig(variant, "color", "name") || "--Sin color--" }}</b-td>
          <b-td>
            <span v-if="loadingQuantity[variant.id]"> <b-spinner /> </span>
            <b-link
              v-else
              @click="showModal(variant, index)"
              v-b-tooltip.hover="'Click aquí para modificar el stock'"
              >{{ quantity[variant.id] }}</b-link
            >
          </b-td>
          <b-td>{{ variant.sku }}</b-td>
          <b-td>{{ variant.barcode }}</b-td>
          <b-td>
            <variants-table-actions
              :variant="variant"
              @delete-variant="error => deleteVariant(index, error)"
              @change="
                variantWarehouses =>
                  changeVariantWarehouses(variantWarehouses, index)
              "
            />
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <quantity-modal
      :variant-warehouses="variantWarehousesSelected"
      @change="changeQuantity"
    />
  </div>
</template>
<script>
import VariantsTableActions from "./VariantsTableActions.vue";
import QuantityModal from "./QuantityModal.vue";
export default {
  name: "VariantsTable",
  components: {
    VariantsTableActions,
    QuantityModal
  },
  props: {
    variants: {
      type: Array,
      default() {
        return [];
      }
    },
    numberSuccess: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      quantity: {},
      newVariants: null,
      variantWarehousesSelected: null,
      indexSelected: null,
      loadingQuantity: {},
      error: false,
      errorMessage: null,
      success: false,
      dismissSecs: 3,
      dismissCountDown: 0
    };
  },
  created() {
    this.newVariants = this.$dup(this.variants);
    this.getQuantities();
  },
  computed: {
    alertVariant() {
      return this.error ? "danger" : "success";
    },
    alertMessage() {
      return this.error
        ? "Ha ocurrido un error guardando los datos: " + this.errorMessage
        : "Variante eliminada exitosamente";
    }
  },
  methods: {
    /**
     * Obtiene la cantidad de una variante segun sus variantWarehouses
     */
    getQuantities() {
      this.newVariants.forEach(variant => {
        this.loadingQuantity[variant.id] = true;
        let qt = 0;
        variant.variantWarehouses.forEach(vw => {
          qt += vw.quantity;
        });
        this.quantity[variant.id] = qt;
        this.loadingQuantity[variant.id] = false;
      });
    },
    /**
     * Se encarga de mostrar el modal para cambiar el stock
     * @param {Object} variant - variante a cambiar
     * @param {Number} index - indice de la variante seleccionada
     */
    showModal(variant, index) {
      this.variantWarehousesSelected = variant.variantWarehouses;
      this.indexSelected = index;
      this.$bvModal.show("change-quantities");
    },
    /**
     * Emite el evento de eliminacion de una variante
     * solo si no hay algun error, si lo hay lo muestra
     * @param {Number} index - indice de la variante
     * @param {String} error - mensaje de error
     */
    deleteVariant(index, error = null) {
      this.softReset();
      if (error) {
        this.error = true;
        this.errorMessage = error;
      } else {
        this.newVariants.splice(index, 1);
        this.$emit("delete-variants", this.newVariants);
      }
    },
    /**
     * Maneja los cambios de cantidad por el modal quantity
     */
    changeQuantity(variantWarehouses) {
      this.changeVariantWarehouses(variantWarehouses, this.indexSelected);
    },
    /**
     * Emite el evento indicando se cambio los variantWrehouses de una variante
     * @param {Array} variantWarehouses - lista de nuevos variantWarehouses
     * @param {Number} index - indice de la variante a actualizar
     */
    changeVariantWarehouses(variantWarehouses, index) {
      this.$emit("change-variants", variantWarehouses, index);
    },
    /**
     * Se encarga de resetear las variables que indican el error o exito
     */
    softReset() {
      this.error = false;
      this.errorMessage = null;
      this.success = false;
    },
    /**
     * Actualiza el valor actual del contador de la alerta
     * @param {Number} dismissCountDown - segundo que le quedan a la alerta
     */
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    }
  },
  watch: {
    variants: {
      handler(val) {
        this.newVariants = this.$dup(val);
        this.getQuantities();
      },
      deep: true
    },
    numberSuccess() {
      this.success = true;
      this.dismissCountDown = this.dismissSecs;
    }
  }
};
</script>
