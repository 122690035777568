<template>
  <div>
    <base-header
      title="Promociones de Mercado Libre"
      :titleSize="12"
      :title-link="titleLink"
      :map="['Mercado Libre', 'Integraciones', 'Promociones', 'Oferta']"
    ></base-header>
    <base-alert-with-count-down
      v-for="(message, idx) of alertController"
      v-model="message.showAlert"
      :alert-variant="alertVariant(message.error)"
      :dismiss-secs="message.error ? 10 : 5"
      :key="idx"
    >
      {{ prettyMessageItem(message.indexItem) }}
      <ul>
        <li>{{ message.alertMessage }}</li>
      </ul>
    </base-alert-with-count-down>
    <mercado-libre-promotion-items
      v-model="meliItems"
      :integration-config-id="integrationConfigId"
      :deal-id="dealId"
      deal-type="DEAL"
      @loading="value => (loadingItems = value)"
      @cursor="value => (cursor = value)"
      ref="items"
    />
    <div v-if="!loadingItems || items.length">
      <b-container fluid>
        <b-row>
          <b-col cols="2">
            <b-button
              v-b-modal.add-product-modal
              id="productsToUse"
              @click="openModal()"
            >
              <b-icon-plus></b-icon-plus>
              Agregar
            </b-button>
            <div class="mt-2">
              Seleccionar todo
              <input type="checkbox" id="checkall" @change="checkAll($event)" />
            </div>
          </b-col>
          <b-col cols="3"></b-col>
          <b-col cols="3"></b-col>
          <b-col cols="2"></b-col>
          <b-col cols="2">
            <span id="tooltip">
              <b-spinner v-if="saving"></b-spinner>
              <b-button
                @click="sendProducts()"
                :disabled="this.disabledButton || saving"
              >
                <b-icon-check-circle></b-icon-check-circle>
                Guardar
              </b-button>
            </span>
            <b-tooltip target="tooltip" :disabled="!this.disabledButton">
              {{ this.tooltipMessage }}
            </b-tooltip>
          </b-col>
        </b-row>
        <br />
      </b-container>

      <b-row>
        <b-col cols="12">
          <base-j-excel-table
            v-model="items"
            :columns="columns"
            :on-selection="this.onSelection"
            :allow-insert-row="false"
            :allow-insert-column="false"
            :custom-on-change="this.onChange"
            :allow-search="true"
            :validation-function="this.jexcelValidateFunction"
            :set-style-only-to-cell-with-error="true"
          ></base-j-excel-table>
          <b-button v-if="!!cursor" :disabled="!!loadingItems" @click="seeMore"
            >Ver más</b-button
          >
        </b-col>
      </b-row>
    </div>

    <products-to-use-modal
      v-if="this.integrationConfigMeli"
      id="productsToUse"
      title="Importar Productos"
      :selectedProducts="selectedProducts"
      :integrationConfigId="this.integrationConfigId"
      :fulllabel="this.integrationConfigMeli.fullLabel"
    >
      <template v-slot:link>
        <b-button variant="success">Usar</b-button>
      </template>
    </products-to-use-modal>
  </div>
</template>

<script>
import INTEGRATION_CONFIG_MERCADO_LIBRE from "../graphql/IntegrationConfigMercadoLibre.gql";
import MERCADO_LIBRE_DELETE_PROMOTION_ITEMS from "../graphql/MercadoLibreDeletePromotionItems.gql";
import CREATE_MERCADO_LIBRE_DEAL_PROMOTION from "../graphql/MercadoLibreSetPromotionItems.gql";
import BaseHeader from "../components/BaseHeader.vue";
import ProductsToUseModal from "../components/ProductsToUseModal";
import BaseJExcelTable from "../components/BaseJExcelTable";
import MercadoLibrePromotionItems from "../components/MercadoLibrePromotionItems";
import BaseAlertWithCountDown from "../components/Base/BaseAlertWithCountDown.vue";

import { mapState } from "vuex";
import jexcel from "jexcel";

export default {
  name: "MercadoLibrePromotionsDealIndex",
  components: {
    BaseHeader,
    ProductsToUseModal,
    BaseJExcelTable,
    MercadoLibrePromotionItems,
    BaseAlertWithCountDown
  },
  computed: {
    ...mapState(["selectedProducts"]),
    titleLink: {
      get: function() {
        return `/mercadolibre/promotions/${this.integrationConfigId}`;
      }
    },
    /**
     * Decide si deshabilitar o no el botón de guardado
     * @returns {Boolean}
     */
    disabledButton() {
      return !this.someItemActive || !this.validPrices;
    },
    /**
     * Revisa si existen items con el checbox de "envio?" activado
     * @returns {Boolean}
     */
    someItemActive() {
      for (let index = 0; index < this.items.length; index++) {
        if (this.items[index][this.ACTIVE]) {
          return true;
        }
      }
      return false;
    },
    /**
     * Revisa que todos los productos a ser enviados tengan un precio base y
     * oferta validos
     * @returns {Boolean}
     */
    validPrices() {
      for (let index = 0; index < this.items.length; index++) {
        const row = this.items[index];
        if (row[this.ACTIVE]) {
          let errorBasePrice = this.validatePrice(row[this.BASE_PRICE]);
          let errorOfferPrice = this.validatePrice(row[this.OFFER_PRICE]);
          if (errorBasePrice.length > 0 || errorOfferPrice.length > 0) {
            return false;
          }
        }
      }
      return true;
    },
    tooltipMessage() {
      if (!this.someItemActive) {
        return "Debes seleccionar algún producto a ser enviado";
      }
      return "Los precios de los productos seleccionados deben ser válidos";
    }
  },
  props: {
    integrationConfigId: {
      type: String,
      required: true
    },
    dealId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      cursor: true,
      productsToUse: false,
      integrationConfigMeli: {},
      items: [],
      loadingItems: true,
      originalItems: [],
      meliItems: [],
      columns: [
        { type: "checkbox", title: "Enviar?" },
        { type: "text", title: "Producto", width: 240, readOnly: true },
        {
          type: "text",
          title: "Código MercadoLibre",
          width: 150,
          readOnly: true
        },
        {
          type: "numeric",
          title: "Precio Base",
          width: 120,
          mask: "$ #.##0,0",
          decimal: ",",
          readOnly: true
        },
        {
          type: "numeric",
          title: "Precio Oferta",
          width: 120,
          mask: "$ #.##0,0",
          decimal: ","
        },
        { type: "text", title: "Estado", width: 120, readOnly: true },
        { type: "html", title: "Acciones", width: 120 }
      ],
      ACTIVE: 0,
      ITEM: 1,
      ITEM_ID: 2,
      BASE_PRICE: 3,
      OFFER_PRICE: 4,
      STATUS: 5,
      ACTIONS: 6,
      alertController: [],
      error: false,
      showAlert: false,
      alertMessage: "",
      saving: false
    };
  },
  beforeMount() {
    this.getIntegrationConfig();
  },
  methods: {
    /**
     * Captura cambios en la tabla
     * @param instance
     * @param cell
     * @param {Integer} x
     * @param {Integer} y
     * @param {String} value
     * @param {Object} jExcelInstance
     */
    onChange(instance, cell, x, y, value, jExcelInstance) {
      jExcelInstance.ingoreEvents = false;
      const oldValue = this.originalItems[y] ? this.originalItems[y][x] : null;
      if (value !== oldValue && x != 0) {
        jExcelInstance.setStyle(
          jexcel.getColumnNameFromId([x, y]),
          "font-weight",
          "bold",
          true
        );
        jExcelInstance.ignoreEvents = true;
        jExcelInstance.ignoreHistory = true;
        jExcelInstance.setValueFromCoords(0, y, 1);
        jExcelInstance.ignoreEvents = false;
        jExcelInstance.ignoreHistory = false;
      } else
        jExcelInstance.setStyle(
          jexcel.getColumnNameFromId([x, y]),
          "font-weight",
          "inherit",
          true
        );
    },
    getIntegrationConfig() {
      this.$apollo
        .query({
          query: INTEGRATION_CONFIG_MERCADO_LIBRE,
          variables: { id: this.integrationConfigId }
        })
        .then(result => {
          this.integrationConfigMeli =
            result.data.integrationConfigMercadoLibre;
        });
    },
    async deleteItem(itemId, itemIndex) {
      if (this.items[itemIndex][this.ACTIONS] == "Cancelar") {
        this.items.splice(itemIndex, 1);
        return;
      }
      this.$apollo
        .mutate({
          mutation: MERCADO_LIBRE_DELETE_PROMOTION_ITEMS,
          variables: {
            items: itemId,
            deal_id: this.dealId,
            integration_config_id: this.integrationConfigId
          }
        })
        .then(result => {
          if (result.data.mercadoLibreDeletePromotionItems.result == "200") {
            this.items.splice(itemIndex, 1);
          }
        });
    },
    closeModal() {
      this.productsToUse = false;
    },
    openModal() {
      this.productsToUse = true;
      this.$bvModal.show("productsToUse");
    },
    addNewItems(itemsArray) {
      this.items = [];
      itemsArray.forEach(item => {
        this.items.push([
          1,
          item.producto,
          item.sku,
          item.original_price,
          item.price,
          this.$options.filters.meliToCentry(item.status),
          "Cancelar"
        ]);
      });
    },
    async onSelection(instance, x1, y1, x2, y2) {
      if (x1 === x2 && y1 === y2 && x1 === this.ACTIONS) {
        if (confirm("El item sera eliminado")) {
          await this.deleteItem(this.items[y1][this.ITEM_ID], y1);
        }
      }
    },
    /**
     * Envia productos para agregar promociones a Mercado Libre
     */
    sendProducts() {
      this.saving = true;
      for (let i = 0; i < this.$data.items.length; i++) {
        if (this.$data.items[i][this.ACTIVE] == 1) {
          this.$apollo
            .mutate({
              mutation: CREATE_MERCADO_LIBRE_DEAL_PROMOTION,
              variables: {
                items: this.$data.items[i][this.ITEM_ID],
                price:
                  typeof this.items[i][this.BASE_PRICE] === "number"
                    ? this.items[i][this.BASE_PRICE]
                    : parseInt(
                        this.items[i][this.BASE_PRICE].replace(/\D/g, "")
                      ),
                deal_price:
                  typeof this.items[i][this.OFFER_PRICE] === "number"
                    ? this.items[i][this.OFFER_PRICE]
                    : parseInt(
                        this.items[i][this.OFFER_PRICE].replace(/\D/g, "")
                      ),
                deal_id: this.dealId,
                integration_config_id: this.integrationConfigId
              }
            })
            .then(result => {
              const item_result = this.$dig(
                result,
                "data",
                "mercadoLibreSetPromotionItems",
                "result"
              );

              if (item_result.status >= 400) {
                this.pushNewAlert(true, item_result.message, i);
              } else {
                if (item_result.original_price != null) {
                  result.data.mercadoLibreSetPromotionItems.result;
                  this.items[i][this.STATUS] = "Aprobado";
                  this.items[i][this.OFFER_PRICE] = item_result.price;
                  this.items[i][this.BASE_PRICE] = item_result.original_price;
                  var spreadsheet = document.getElementById("spreadsheet");
                  spreadsheet.jexcel.refresh();
                  this.savedStatus(i);
                } else {
                  this.pushNewAlert(
                    true,
                    "Hubo un error al tratar de guardar las promociones.",
                    i
                  );
                }
              }
              this.saving = false;
            });
        }
      }
    },
    /**
     * Agrega un nuevo mensaje al finalizar una peticion a Meli
     * @param {Boolean} error
     * @param {String} message
     * @param {Number} index
     */
    pushNewAlert(error, message, index) {
      let alert = {};
      alert.error = error;
      alert.alertMessage = message;
      alert.showAlert = true;
      alert.indexItem = index;
      this.alertController.push(alert);
    },
    /**
     * Define clase para mensaje de alerta
     * @param {Boolean} error
     * @return {String}
     */
    alertVariant(error) {
      return error ? "danger" : "success";
    },
    /** Entrega feedback al usuario de que el guardado de ofertas fue exitoso
     * @param {Number} index
     */
    savedStatus(index) {
      this.pushNewAlert(false, `Se guardo la promoción exitosamente!`, index);
    },
    checkAll: function(e) {
      for (let i = 0; i < this.items.length; i++) {
        this.items[i][this.ACTIVE] = e.target.checked;
      }
      var spreadsheet = document.getElementById("spreadsheet");
      spreadsheet.jexcel.refresh();
    },
    /**
     * Valida que el valor entregado sea un precio mayor a cero
     * @param {String|Integer} value
     * @returns {String}
     */
    validatePrice(value) {
      if (
        !value ||
        value.length === 0 ||
        (typeof value !== "number" && value.replace(/\D/g, "").length === 0)
      ) {
        return "Hay que indicar un valor para el precio";
      }
      if (
        value === 0 ||
        (typeof value !== "number" && parseInt(value.replace(/\D/g, "")) === 0)
      ) {
        return "El valor del precio debe ser mayor a cero";
      }
      return "";
    },
    /**
     * Valida que el valor de las celdas que contienen precio sea correcto
     * @param {String} rowIndex
     * @param {String} colIndex
     * @param {String|Integer} value
     * @returns {String}
     */
    jexcelValidateFunction(_, colIndex, value) {
      if (![this.BASE_PRICE, this.OFFER_PRICE].includes(parseInt(colIndex))) {
        return "";
      }
      return this.validatePrice(value);
    },
    /**
     * Recibe los items de la promoción y realiza la transformación de datos
     * para que se muestren en la tabla
     * @param {Array<Object>} meliItems
     */
    addItemsToJExcel(meliItems) {
      this.items = [];
      meliItems.forEach(item => {
        let itemData = [
          0,
          item.productTitle,
          item.id,
          item.originalPrice,
          item.price,
          this.$options.filters.meliToCentry(item.status),
          "Eliminar"
        ];
        this.items.push(itemData);
        this.originalItems.push(itemData);
      });
    },
    /**
     * Entrega parte del mensaje de la alerta para usuario con la info
     * referente al producto y fila
     * @param {Number} index
     * @return {String}
     */
    prettyMessageItem(index) {
      let product_name = this.items[index][this.ITEM];
      return `Para el producto ${product_name}, en la fila ${index + 1}:`;
    },
    /**
     * Carga una página más de ítemes
     */
    seeMore() {
      this.$refs.items.getItems();
    }
  },
  watch: {
    selectedProducts(newVal) {
      for (let j = 0; j < newVal.length; j++) {
        var found = false;
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i][this.ITEM_ID] == newVal[j].sku) {
            found = true;
          }
        }
        if (!found) {
          this.addNewItems([newVal[j]]);
        }
      }
    },
    meliItems(newVal) {
      this.addItemsToJExcel(newVal);
    }
  }
};
</script>

<style>
.jexcel_filter {
  display: block;
}
</style>
