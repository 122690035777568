<template>
  <div>
    <b-button-group size="sm">
      <b-button variant="outline-info" @click="cancel">
        <b-icon-x-circle></b-icon-x-circle> Cancelar
      </b-button>
      <b-button
        variant="info"
        :disabled="
          !changed || saving || loadingIntegrationConfig || !validChangesPrices
        "
        :title="changed ? '' : 'No hay cambios'"
        @click="save"
      >
        <span v-if="saving"><b-spinner label="Spinning"></b-spinner></span>
        <span v-else><b-icon-cloud-upload></b-icon-cloud-upload>Guardar</span>
      </b-button>
    </b-button-group>
    <b-alert
      v-model="saved"
      :variant="errorSaving ? 'danger' : 'success'"
      dismissible
    >
      {{
        errorSaving
          ? "Ha ocurrido un error"
          : "El producto ha sido actualizado exitosamente"
      }}
    </b-alert>
    <h4 class="font-weight-bold m-3">Detalles producto</h4>
    <b-row>
      <b-col md="4">
        <b-form-group
          label="Descripción"
          :label-for="'descripcion-' + integrationConfig.fullLabel"
        >
          <base-html-text-area
            :id="'descripcion-' + integrationConfig.fullLabel"
            v-model="descripcion"
            rows="8"
          >
          </base-html-text-area>
        </b-form-group>
      </b-col>
      <b-col md="8">
        <b-row>
          <b-col md="4">
            <b-form-group
              label="Título"
              :label-for="'titulo-' + integrationConfig.fullLabel"
            >
              <b-form-input
                :id="'titulo-' + integrationConfig.fullLabel"
                v-model="titulo"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Alto 1 cuerpo cm"
              :label-for="'alto_1_cuerpo_cm-' + integrationConfig.fullLabel"
            >
              <b-form-input
                :id="'alto_1_cuerpo_cm-' + integrationConfig.fullLabel"
                v-model="alto1CuerpoCm"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Ancho cm"
              :label-for="'ancho_cm-' + integrationConfig.fullLabel"
            >
              <b-form-input
                :id="'ancho_cm-' + integrationConfig.fullLabel"
                v-model="anchoCm"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group
              label="Largo cm"
              :label-for="'largo_cm-' + integrationConfig.fullLabel"
            >
              <b-form-input
                :id="'largo_cm-' + integrationConfig.fullLabel"
                v-model="largoCm"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Capacidad lt"
              :label-for="'capacidad_lt-' + integrationConfig.fullLabel"
            >
              <b-form-input
                :id="'capacidad_lt-' + integrationConfig.fullLabel"
                v-model="capacidadLt"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Peso kg"
              :label-for="'peso_kg-' + integrationConfig.fullLabel"
            >
              <b-form-input
                :id="'peso_kg-' + integrationConfig.fullLabel"
                v-model="pesoKg"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group
              label="Garantía"
              :label-for="'garantia-' + integrationConfig.fullLabel"
            >
              <b-form-input
                :id="'garantia-' + integrationConfig.fullLabel"
                v-model="garantia"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="8" v-if="loadingIntegrationConfig" class="text-center">
            <b-spinner label="Spinning"></b-spinner>
          </b-col>
          <b-col md="4" v-if="!loadingIntegrationConfig">
            <b-form-group label="Marca">
              <base-live-select
                @search="searchBrand"
                v-model="marca"
                :min-search-characters="1"
              ></base-live-select>
            </b-form-group>
          </b-col>
          <b-col md="4" v-if="!loadingIntegrationConfig">
            <b-form-group label="Clase logística">
              <v-select
                :options="logisticClasses"
                v-model="logisticClass"
                :reduce="x => x.value"
              ></v-select>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-form-group label="Estado de la publicación">
          <base-boolean-selector
            v-model="status"
            allow-null=""
            true-text="Activa"
            false-text="Pausada"
            default-text="Como en la configuración general"
          ></base-boolean-selector>
        </b-form-group>
      </b-col>
    </b-row>
    <hr />
    <h4 class="font-weight-bold m-3">Precios</h4>
    <b-row>
      <b-col md="3">
        <b-form-group
          label="Precio normal"
          :label-for="'price-' + integrationConfig.fullLabel"
          :state="validation.price.valid"
          :invalid-feedback="validation.price.invalidFeedback"
        >
          <b-form-input
            v-model="price"
            :id="'price-' + integrationConfig.fullLabel"
            :formatter="$formatFormPrice"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group
          label="Precio oferta"
          :label-for="'preciooferta-' + integrationConfig.fullLabel"
          :state="validation.preciooferta.valid"
          :invalid-feedback="validation.preciooferta.invalidFeedback"
        >
          <b-form-input
            v-model="preciooferta"
            :id="'preciooferta-' + integrationConfig.fullLabel"
            :formatter="$formatFormPrice"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group
          label="Fecha inicio oferta"
          :label-for="'salestartdate-' + integrationConfig.fullLabel"
        >
          <base-date-time
            v-model="salestartdate"
            @change="change"
          ></base-date-time>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group
          label="Fecha término oferta"
          :label-for="'saleenddate-' + integrationConfig.fullLabel"
        >
          <base-date-time
            v-model="saleenddate"
            @change="change"
          ></base-date-time>
        </b-form-group>
      </b-col>
    </b-row>
    <hr />
    <div v-if="isFulfillmentAvailable">
      <h4 class="font-weight-bold m-3">Fulfillment</h4>

      <b-row>
        <b-col md="3">
          <b-form-group>
            <b-form-checkbox
              v-model="fulfillmentStatus"
              :id="'fulfillment-status-' + integrationConfig.fullLabel"
            >
              Activar Fulfillment
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="isFulfillmentActive">
        <b-col md="3">
          <b-form-group
            label="Precio normal"
            :label-for="'fulfillment-price-' + integrationConfig.fullLabel"
            :state="validation.fulfillmentPrice.valid"
            :invalid-feedback="validation.fulfillmentPrice.invalidFeedback"
          >
            <b-form-input
              v-model="fulfillmentPrice"
              :id="'fulfillment-price-' + integrationConfig.fullLabel"
              :formatter="$formatFormPrice"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Precio oferta"
            :label-for="
              'fulfillment-preciooferta-' + integrationConfig.fullLabel
            "
            :state="validation.fulfillmentPreciooferta.valid"
            :invalid-feedback="
              validation.fulfillmentPreciooferta.invalidFeedback
            "
          >
            <b-form-input
              v-model="fulfillmentPreciooferta"
              :id="'fulfillment-preciooferta-' + integrationConfig.fullLabel"
              :formatter="$formatFormPrice"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Fecha inicio oferta"
            :label-for="
              'fulfillment-salestartdate-' + integrationConfig.fullLabel
            "
          >
            <base-date-time
              v-model="fulfillmentSalestartdate"
              @change="change"
            ></base-date-time>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Fecha término oferta"
            :label-for="
              'fulfillment-saleenddate-' + integrationConfig.fullLabel
            "
          >
            <base-date-time
              v-model="fulfillmentSaleenddate"
              @change="change"
            ></base-date-time>
          </b-form-group>
        </b-col>
      </b-row>
      <hr />
    </div>
    <h4 class="font-weight-bold m-3">Variantes</h4>
    <p v-if="!product.variants || product.variants.length === 0">
      Este producto no tiene variantes
    </p>
    <b-table-simple v-else>
      <b-thead>
        <b-tr>
          <b-th colspan="3">Centry</b-th>
          <b-th>{{ integrationConfig.label }}</b-th>
        </b-tr>
        <b-tr>
          <b-th>Color</b-th>
          <b-th>Talla</b-th>
          <b-th>SKU</b-th>
          <b-th>SKU Oferta</b-th>
          <b-th v-if="isFulfillmentActive">SKU Fulfillment</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="(variant, index) of product.variants" :key="index">
          <b-td>{{ variant.color ? variant.color.name : "---" }}</b-td>
          <b-td>{{ variant.size ? variant.size.name : "---" }}</b-td>
          <b-td>{{ variant.sku }}</b-td>
          <b-td
            ><b-form-input
              v-model="variantSkus[index]"
              @input="change"
            ></b-form-input>
          </b-td>
          <b-td v-if="isFulfillmentActive">
            <b-form-input
              v-model="fulfillmentVariantSkus[index]"
              @input="change"
            ></b-form-input>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>
<script>
import BaseHtmlTextArea from "./BaseHtmlTextArea";
import BaseBooleanSelector from "./BaseBooleanSelector";
import BaseDateTime from "./BaseDateTime";
import { mapActions } from "vuex";
import INTEGRATION_CONFIG_MIRAKL from "../graphql/IntergationConfigMirakl.gql";
import BaseLiveSelect from "./BaseLiveSelect";
import { updateProduct, updateVariant } from "@/main";
export default {
  name: "ProductEditTabsMirakl",
  components: {
    BaseLiveSelect,
    BaseDateTime,
    BaseBooleanSelector,
    BaseHtmlTextArea
  },
  model: {
    prop: "_changed",
    event: "change"
  },
  props: {
    integrationConfig: Object,
    product: Object
  },
  data() {
    const original = this.product.integrations[
      this.integrationConfig.fullLabel
    ];
    return {
      changed: false,
      saving: false,
      errorSaving: false,
      descripcion: original ? original.descripcion : "",
      titulo: original ? original.titulo : "",
      alto1CuerpoCm: original ? original.alto_1_cuerpo_cm : "",
      anchoCm: original ? original.ancho_cm : "",
      largoCm: original ? original.largo_cm : "",
      capacidadLt: original ? original.capacidad_lt : "",
      pesoKg: original ? original.peso_kg : "",
      garantia: original ? original.Garantia : "",
      marca: null,
      logisticClass: null,
      status: original?.status?.length ? original.status === "active" : null,
      price: original?.price != null ? original.price : "",
      preciooferta: original?.preciooferta != null ? original.preciooferta : "",
      salestartdate: null,
      saleenddate: null,
      variantSkus: this.initialVariantSkus(),
      fulfillmentStatus: original ? original.fulfillment_status : false,
      fulfillmentPrice:
        original?.fulfillment_price != null ? original.fulfillment_price : "",
      fulfillmentPreciooferta:
        original?.fulfillment_preciooferta != null
          ? original.fulfillment_preciooferta
          : "",
      fulfillmentSalestartdate: null,
      fulfillmentSaleenddate: null,
      fulfillmentVariantSkus: this.initialFulfillmentVariantSkus(),
      brandList: [],
      logisticClasses: [],
      categoryAttributes: [],
      loadingIntegrationConfig: true,
      saved: false
    };
  },
  async created() {
    const original = this.product.integrations[
      this.integrationConfig.fullLabel
    ];
    if (original) {
      this.salestartdate = await this.toDate(original.salestartdate);
      this.saleenddate = await this.toDate(original.saleenddate);
      this.fulfillmentSalestartdate = await this.toDate(
        original.fulfillment_salestartdate
      );
      this.fulfillmentSaleenddate = await this.toDate(
        original.fulfillment_saleenddate
      );
    }
    const categoryIdCentry = this.product.category
      ? this.product.category.id
      : null;
    this.$apollo
      .query({
        query: INTEGRATION_CONFIG_MIRAKL,
        variables: {
          id: this.integrationConfig.id,
          categoryIdCentry,
          categoryIdMirakl: null
        }
      })
      .then(result => {
        if (result.data && result.data.integrationConfigMirakl) {
          const brandList = result.data.integrationConfigMirakl.brandList;
          if (
            brandList &&
            brandList.values_lists &&
            brandList.values_lists[0] &&
            brandList.values_lists[0].values
          ) {
            this.brandList = brandList.values_lists[0].values.map(x => {
              return { label: x.code, value: x.code };
            });
          }
          const original = this.product.integrations[
            this.integrationConfig.fullLabel
          ];
          this.marca = original
            ? original.marca && original.marca.length
              ? { label: original.marca, value: original.marca }
              : null
            : null;
          const logisticClasses =
            result.data.integrationConfigMirakl.logisticClasses;
          if (logisticClasses && logisticClasses.logistic_classes) {
            this.logisticClasses = logisticClasses.logistic_classes.map(x => {
              return { value: x.code, label: x.label };
            });
          }
          this.logisticClass = original
            ? original.logistic_class && original.logistic_class.length
              ? original.logistic_class
              : null
            : null;
          this.categoryAttributes =
            result.data.integrationConfigMirakl.categoryAttributes;
          this.loadingIntegrationConfig = false;
          this.$watch("marca", () => this.change());
          this.$watch("logisticClass", () => this.change());
        }
      });
  },
  computed: {
    /**
     * Indica si los precios son validos para habilitar el botón
     * de guardar
     * @returns {Boolean}
     */
    validChangesPrices() {
      return (
        this.changed &&
        (this.$validFormPrice(this.price) ||
          this.$validFormPrice(this.price) == null) &&
        (this.$validFormPrice(this.preciooferta) ||
          this.$validFormPrice(this.preciooferta) == null)
      );
    },
    validation() {
      return {
        preciooferta: {
          valid:
            this.preciooferta.length === 0 ||
            this.price.lenght === 0 ||
            (this.$validFormPrice(this.preciooferta) &&
              this.$validFormPrice(this.price) &&
              Number(this.price) > Number(this.preciooferta)),
          invalidFeedback:
            "El precio oferta debe ser numérico y menor al precio normal"
        },
        price: {
          valid:
            this.price.length === 0 ||
            (this.$validFormPrice(this.price) &&
              this.$validFormPrice(this.preciooferta) &&
              Number(this.price) > Number(this.preciooferta)),
          invalidFeedback:
            "El precio normal debe ser numérico y mayor al precio de oferta"
        },
        fulfillmentPreciooferta: {
          valid:
            this.fulfillmentPreciooferta.length === 0 ||
            this.fulfillmentPrice.lenght === 0 ||
            (this.$validFormPrice(this.fulfillmentPreciooferta) &&
              this.$validFormPrice(this.fulfillmentPrice) &&
              Number(this.fulfillmentPrice) >
                Number(this.fulfillmentPreciooferta)),
          invalidFeedback:
            "El precio oferta debe ser numérico y menor al precio normal"
        },
        fulfillmentPrice: {
          valid:
            this.fulfillmentPrice.length === 0 ||
            (this.$validFormPrice(this.fulfillmentPrice) &&
              this.$validFormPrice(this.fulfillmentPreciooferta) &&
              Number(this.fulfillmentPrice) >
                Number(this.fulfillmentPreciooferta)),
          invalidFeedback:
            "El precio normal debe ser numérico y mayor al precio de oferta"
        }
      };
    },
    valid() {
      let valid = true;
      Object.keys(this.validation).forEach(x => {
        valid = valid && this.validation[x].valid;
      });
      return valid;
    },
    errorList() {
      return Object.keys(this.validation)
        .map(key => {
          return this.validation[key].valid
            ? null
            : this.validation[key].invalidFeedback;
        })
        .filter(x => x !== null);
    },
    /**
     * Indica si el producto pertenece a una categoría que tiene disponible la
     * opción de fulfillment.
     * @returns {Boolean}
     */
    isFulfillmentAvailable() {
      if (!this.categoryAttributes) {
        return false;
      }
      return this.categoryAttributes.some(x => x.code === "info_ff");
    },
    /**
     * Indica si, además de disponible la opción, el producto tiene activa la
     * configuración de fulfillment.
     * @returns {Boolean}
     */
    isFulfillmentActive() {
      return this.isFulfillmentAvailable && this.fulfillmentStatus;
    }
  },
  methods: {
    ...mapActions(["timezoneDate"]),
    async toDate(string) {
      if (string && string.length > 0) {
        const dateString = await this.timezoneDate(string);
        return new Date(dateString);
      }
      return null;
    },
    initialVariantSkus() {
      if (!this.product.variants) {
        return [];
      }
      return this.product.variants.map(x => {
        let original = x.integrations[this.integrationConfig.fullLabel];
        return original ? original.sku_offer : "";
      });
    },
    initialFulfillmentVariantSkus() {
      if (!this.product.variants) {
        return [];
      }
      return this.product.variants.map(x => {
        let original = x.integrations[this.integrationConfig.fullLabel];
        return original ? original.fulfillment_sku_offer : "";
      });
    },
    updatedProductData() {
      const updated = { integrations: {} };
      updated.integrations[this.integrationConfig.fullLabel] = {};
      let original = this.product.integrations[
        this.integrationConfig.fullLabel
      ];
      if (!original) original = {};
      const changes = [
        {
          key: "descripcion",
          original: original.descripcion,
          current: this.descripcion
        },
        { key: "titulo", original: original.titulo, current: this.titulo },
        {
          key: "alto_1_cuerpo_cm",
          original: original.alto_1_cuerpo_cm,
          current: this.alto1CuerpoCm
        },
        { key: "ancho_cm", original: original.ancho_cm, current: this.anchoCm },
        { key: "largo_cm", original: original.largo_cm, current: this.largoCm },
        {
          key: "capacidad_lt",
          original: original.capacidad_lt,
          current: this.capacidadLt
        },
        { key: "peso_kg", original: original.peso_kg, current: this.pesoKg },
        {
          key: "Garantia",
          original: original.Garantia,
          current: this.garantia
        },
        {
          key: "marca",
          original: original.marca,
          current: this.marca ? this.marca.value : null
        },
        {
          key: "logistic_class",
          original: original.logistic_class,
          current: this.logisticClass
        },
        {
          key: "status",
          original: original.status,
          current: this.status === null ? "" : this.status ? "active" : "paused"
        },
        {
          key: "price",
          original: original.price,
          current: this.price
        },
        {
          key: "preciooferta",
          original: original.preciooferta,
          current: this.preciooferta
        },
        {
          key: "salestartdate",
          original: original.salestartdate,
          current: this.salestartdate ? this.salestartdate.toISOString() : ""
        },
        {
          key: "saleenddate",
          original: original.saleenddate,
          current: this.saleenddate ? this.saleenddate.toISOString() : ""
        },
        {
          key: "fulfillment_status",
          original: original.fulfillment_status,
          current: this.fulfillmentStatus
        },
        {
          key: "fulfillment_price",
          original: original.fulfillment_price,
          current: this.fulfillmentPrice
        },
        {
          key: "fulfillment_preciooferta",
          original: original.fulfillment_preciooferta,
          current: this.fulfillmentPreciooferta
        },
        {
          key: "fulfillment_salestartdate",
          original: original.fulfillment_salestartdate,
          current: this.fulfillmentSalestartdate
            ? this.fulfillmentSalestartdate.toISOString()
            : ""
        },
        {
          key: "fulfillment_saleenddate",
          original: original.fulfillment_saleenddate,
          current: this.fulfillmentSaleenddate
            ? this.fulfillmentSaleenddate.toISOString()
            : ""
        }
      ];
      let changed = false;
      changes.forEach(x => {
        if (x.original !== x.current) {
          changed = true;
          updated.integrations[this.integrationConfig.fullLabel][x.key] =
            x.current;
        }
      });
      if (changed) {
        return updated;
      }
      return null;
    },
    updateProductCache(product) {
      if (product) {
        this.product.integrations = product.integrations;
      }
    },
    async updateProduct() {
      const updated = this.updatedProductData();
      if (!updated) {
        return;
      }
      await updateProduct(this.$apollo, this.product.id, updated)
        .then(async ({ data }) => {
          if (data && data.updateProduct && data.updateProduct.product) {
            this.errorSaving = false;
            const product = data.updateProduct.product;
            this.updateProductCache(product);
          }
        })
        .catch(() => {
          this.errorSaving = true;
        });
    },
    updatedVariantData(position) {
      const variant = this.product.variants[position];
      const original = variant.integrations[this.integrationConfig.fullLabel];
      const originalSkuOffer = original ? original.sku_offer : "";
      const originalSkuOfferFulfillment = original
        ? original.fulfillment_sku_offer
        : "";
      const updated = { integrations: {} };
      updated.integrations[this.integrationConfig.fullLabel] = {};
      if (originalSkuOffer !== this.variantSkus[position]) {
        updated.integrations[
          this.integrationConfig.fullLabel
        ].sku_offer = this.variantSkus[position];
        return updated;
      }
      if (this.isFulfillmentActive) {
        if (
          originalSkuOfferFulfillment !== this.fulfillmentVariantSkus[position]
        ) {
          updated.integrations[
            this.integrationConfig.fullLabel
          ].fulfillment_sku_offer = this.fulfillmentVariantSkus[position];
          return updated;
        }
      }
      return null;
    },
    updateVariantCache(position, variant) {
      this.product.variants[position] = variant;
    },
    async updateVariant(position) {
      const updated = this.updatedVariantData(position);
      if (!updated) {
        return;
      }
      await updateVariant(
        this.$apollo,
        this.product.variants[position].id,
        updated
      )
        .then(({ data }) => {
          if (data && data.updateVariant && data.updateVariant.variant) {
            const variant = data.updateVariant.variant;
            this.updateVariantCache(position, variant);
          }
        })
        .catch(() => {
          this.errorSaving = true;
        });
    },
    async save() {
      this.saving = true;
      this.errorSaving = false;
      await this.updateProduct();
      for (
        let position = 0;
        position < this.product.variants.length;
        position++
      ) {
        await this.updateVariant(position);
      }
      this.saving = false;
      this.saved = true;
      if (!this.errorSaving) {
        this.changed = false;
        this.$emit("change", false);
      }
    },
    cancel() {
      if (this.changed) {
        this.$swal
          .fire({
            title: "Cancelar",
            text: "Si cancelas, perderás tus cambios. ¿Estás seguro?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "No"
          })
          .then(result => {
            if (result.value) {
              this.$router.push({ name: "Products" });
            }
          });
      } else {
        this.$router.push({ name: "Products" });
      }
    },
    async filterOptions(search) {
      return this.brandList.filter(x =>
        x.label.toLowerCase().includes(search.toLowerCase())
      );
    },
    searchBrand(search, loading, setOptions) {
      this.filterOptions(search).then(options => {
        setOptions(options);
        loading(false);
      });
    },
    change() {
      this.changed = true;
      this.$emit("change", true);
    }
  },
  watch: {
    descripcion() {
      this.change();
    },
    titulo() {
      this.change();
    },
    alto1CuerpoCm() {
      this.change();
    },
    anchoCm() {
      this.change();
    },
    largoCm() {
      this.change();
    },
    capacidadLt() {
      this.change();
    },
    pesoKg() {
      this.change();
    },
    garantia() {
      this.change();
    },
    status() {
      this.change();
    },
    price() {
      this.change();
    },
    preciooferta() {
      this.change();
    },
    salestartdate() {
      this.change();
    },
    saleenddate() {
      this.change();
    },
    fulfillmentStatus() {
      this.change();
    },
    fulfillmentPrice() {
      this.change();
    },
    fulfillmentPreciooferta() {
      this.change();
    },
    fulfillmentSalestartdate() {
      this.change();
    },
    fulfillmentSaleenddate() {
      this.change();
    }
  }
};
</script>
