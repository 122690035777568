<template>
  <promotion-detail-page
    :promotion="promotion"
    :integrationConfig="integrationConfig"
    :title="'Creación Promoción'"
    v-if="!errorPromotion"
    @changePromotion="change"
    @errorIntegrationConfig="errorIntegrationConfig"
    :loadingCategories="loadingCategories"
    @changeLoadingCategories="changeLoadingCategories"
    :loadingProducts="loadingProducts"
    @changeLoadingProducts="changeLoadingProducts"
    :loadingCustomersCategories="loadingCustomersCategories"
    @changeLoadingCustomersCategories="changeLoadingCustomersCategories"
  ></promotion-detail-page>
  <div class="mt-3" v-else>
    {{ msgErrorPromotion }}
    <a
      :href="centryUrl + '/conexion/jumpseller/redirect'"
      v-if="not_authorized"
    >
      link.
    </a>
  </div>
</template>
<script>
import PromotionDetailPage from "../../components/jumpseller/promotions/PromotionDetailPage.vue";
import { centryUrl } from "../../main";

export default {
  name: "PromotionDetail",
  data() {
    return {
      centryUrl,
      loadingPromotion: true,
      loadingCategories: true,
      loadingProducts: true,
      loadingCustomersCategories: true,
      promotion: { name: "", coupons: [], products: [] },
      errorPromotion: false,
      not_authorized: false,
      msgErrorPromotion: ""
    };
  },
  props: {
    integrationConfig: String
  },
  methods: {
    change(val) {
      this.promotion = val;
    },
    errorIntegrationConfig(error) {
      this.msgErrorPromotion = this.formatMsgError(error);
      this.errorPromotion = true;
    },
    formatMsgError(error) {
      if (error.includes("get_integration_service")) {
        return `Integración con id ${this.integrationConfig} no existe`;
      }
      if (
        error.includes("Not Authorized: Missing OAuth scope authorization.")
      ) {
        this.not_authorized = true;
        return "Faltan permisos de la aplicación. Vuelve a dar permisos en este ";
      }
      return String(error);
    },
    changeLoadingCategories() {
      this.loadingCategories = false;
    },
    changeLoadingProducts() {
      this.loadingProducts = false;
    },
    changeLoadingCustomersCategories() {
      this.loadingCustomersCategories = false;
    }
  },
  components: {
    PromotionDetailPage
  }
};
</script>
