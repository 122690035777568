<template>
  <b-col v-if="loading">
    <b-spinner label="Spinning"></b-spinner>
  </b-col>
  <b-col v-else>
    <ApolloMutation
      :mutation="require('../graphql/UpdateErrorTranslation.gql')"
      :variables="variables"
      @done="notifySave"
    >
      <template slot-scope="{ mutate, error }">
        <b-button-group size="sm">
          <b-button variant="outline-info" @click="cancel">
            <b-icon-x-circle></b-icon-x-circle> Cancelar
          </b-button>
          <b-button
            variant="info"
            :disabled="saving || !translation || !translation.length"
            :title="saving ? '' : 'Debe ingresar una traducción'"
            @click="
              saving = true;
              mutate();
            "
          >
            <span v-if="saving && !error"
              ><b-spinner label="Spinning"></b-spinner
            ></span>
            <span v-else
              ><b-icon-cloud-upload></b-icon-cloud-upload>Guardar</span
            >
          </b-button>
        </b-button-group>
        <b-alert v-if="error" variant="danger" show="">
          {{ error }}
          Ha ocurrido un error
        </b-alert>
        <b-row>
          <b-col sm="4" xs="12">
            <b-form-group label="Categoría">
              <v-select
                :options="categories"
                v-model="category"
                :reduce="x => x.value"
                :clearable="false"
              ></v-select>
            </b-form-group>
            <b-form-group label="Traducción">
              <b-textarea v-model="translation"></b-textarea>
            </b-form-group>
            <b-form-group label="Parámetros de carga masiva a corregir">
              <v-select
                placeholder="Seleccione parámetros"
                v-model="paramIds"
                :multiple="true"
                :options="allParams"
                :reduce="x => x.value"
              ></v-select>
            </b-form-group>
          </b-col>
          <b-col sm="8" xs="12">
            <div>
              <p>
                Error de <b-badge variant="primary">{{ marketplace }}</b-badge>
              </p>
            </div>
            <h4>Mensaje original:</h4>
            <p :key="i" v-for="(message, i) of originalMsg">{{ message }}</p>
          </b-col>
        </b-row>
      </template>
    </ApolloMutation>
  </b-col>
</template>
<script>
import ADMIN_ERROR_TRANSLATION from "../graphql/AdminErrorTranslation.gql";
import ALL_PARAMS_FOR_MATCH from "../graphql/AllParamsForMatch.gql";
import { centryUrl } from "@/main";
export default {
  name: "ErrorTranslationForm",
  props: {
    errorTranslationId: {
      type: String
    }
  },
  data() {
    return {
      loading: true,
      saving: false,
      category: null,
      translation: null,
      originalMsg: null,
      paramIds: null,
      allParams: null,
      marketplace: null,
      categories: [
        { value: "undefined", label: "Sin Categoría" },
        { value: "image", label: "Imágenes" },
        { value: "brand", label: "Marcas" },
        { value: "price", label: "Precio" },
        { value: "content", label: "Contenido" },
        { value: "category", label: "Categorías" },
        { value: "stock", label: "Stock" }
      ]
    };
  },
  computed: {
    variables() {
      return {
        id: this.errorTranslationId,
        patch: {
          translation: this.translation,
          category: this.category,
          paramIds: this.paramIds,
          state: true
        }
      };
    }
  },
  mounted() {
    Promise.all([this.getErrorTranslation(), this.getAllParams()]).then(
      () => (this.loading = false)
    );
  },
  methods: {
    getErrorTranslation() {
      return this.$apollo
        .query({
          query: ADMIN_ERROR_TRANSLATION,
          variables: { id: this.errorTranslationId }
        })
        .then(({ data }) => {
          if (data && data.adminErrorTranslation) {
            this.translation = data.adminErrorTranslation.translation;
            this.category = data.adminErrorTranslation.category;
            this.paramIds = data.adminErrorTranslation.paramIds;
            this.originalMsg = data.adminErrorTranslation.originalMsg;
            this.marketplace = data.adminErrorTranslation.marketplace;
          }
        });
    },
    getAllParams() {
      return this.$getAllPages(ALL_PARAMS_FOR_MATCH, {}, "allParams").then(
        array => {
          this.allParams = array.map(paramNode => {
            return { value: paramNode.node.id, label: paramNode.node.name };
          });
        }
      );
    },
    cancel() {
      window.location = centryUrl + "/admin/error_translations";
    },
    notifySave() {
      this.$swal
        .fire({
          title: "Actualización exitosa",
          text: "El listado de opciones ha sido guardado con éxito",
          icon: "success",
          confirmButtonText: "Aceptar"
        })
        .then(() => {
          window.location = centryUrl + "/admin/error_translations";
        });
    }
  }
};
</script>
