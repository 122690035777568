<template>
  <div>
    <b-button
      @click="$bvModal.show(id)"
      pill=""
      variant="outline"
      :class="linkClass"
      :title="tooltip"
      v-b-tooltip.hover=""
      :hidden="hideButton"
    >
      <slot name="link">
        <b-icon-question-circle></b-icon-question-circle>
      </slot>
    </b-button>
    <b-modal
      :id="id"
      cancel-disabled=""
      :size="size"
      @show="show"
      @hide="hide"
      :scrollable="scrollable"
    >
      <template v-slot:modal-title>
        <h4>{{ title }}</h4>
      </template>
      <template v-slot:modal-footer="{ ok }">
        <b-button variant="info" @click="ok">
          Entendido
        </b-button>
      </template>
      <slot></slot>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: "BaseHelpModal",
  props: {
    id: String,
    title: String,
    tooltip: String,
    linkClass: {
      type: String,
      default: "float-right p-0"
    },
    hideButton: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: "sm"
    },
    scrollable: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    /**
     * Se encarga de emitir el evento indicando que se muestra el modal
     */
    show() {
      this.$emit("show", this);
    },
    /**
     * Se encarga de emitir el evento indicando que se oculto el modal
     */
    hide() {
      this.$emit("hide", this);
    }
  }
};
</script>
