<template>
  <b-card
    class="shadow-sm"
    :class="{ handle: handle, 'cursor-grab': !noDrag }"
    body-class="p-1"
    style="background-color: rgba(0,0,0,.04); width: 145px; height: 100%"
  >
    <b-card-header class="p-0" style="background: rgb(0, 0, 0,0); height: 25%">
      <b-row align-h="center" v-if="loadingAsset">
        <b-spinner />
      </b-row>
      <b-row align-h="center" v-else-if="asset.errors">
        <b-button
          size="lg"
          variant="outlined"
          class="p-0"
          v-b-tooltip.hover="'Hubieron problemas al guardar la imagen'"
          v-b-modal="`image-with-errors-${this._uid}`"
        >
          <b-icon
            icon="exclamation-circle"
            variant="light"
            class="rounded-circle bg-danger m-1"
          />
        </b-button>
      </b-row>
      <b-row align-h="between" v-else>
        <span
          v-if="!noCopies"
          v-b-tooltip.hover="
            processing
              ? 'Aun no es posible realizar copias de esta imagen'
              : 'Crear copias de la imagen'
          "
        >
          <b-button
            @click="createCopies"
            :disabled="processing"
            variant="outline"
            squared
          >
            <b-icon-files />
          </b-button>
        </span>
        <div v-else />
        <b-button
          @click="deleteImage"
          variant="outline"
          squared
          v-b-tooltip.hover="'Eliminar imagen'"
        >
          <b-icon-x></b-icon-x>
        </b-button>
      </b-row>
    </b-card-header>
    <div style="height: 75%">
      <b-card-img-lazy
        :src="asset.originalUrl"
        alt="cover"
        class="cover cover-rectangle transparent-bg"
        style="max-height: 100%; max-width: 100%"
        v-if="asset.originalUrl"
      ></b-card-img-lazy>
    </div>
    <b-modal
      :id="`image-with-errors-${this._uid}`"
      size="xl"
      scrollable
      ok-only
    >
      <template #modal-title>
        <h2>Errores {{ asset.name }}</h2>
      </template>
      <div>
        <ul>
          <li v-for="(message, index) of asset.errors" :key="index">
            {{ message }}
          </li>
        </ul>
      </div>
    </b-modal>
  </b-card>
</template>

<script>
export default {
  name: "ProductImageContainer",
  components: {},
  props: {
    asset: {
      type: Object,
      default() {
        return {};
      }
    },
    position: Number,
    noCopies: {
      type: Boolean,
      default: false
    },
    noDrag: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {
    loadingAsset() {
      return (
        (this.asset.id == "copy" || this.asset.id == "") && !this.asset.errors
      );
    },
    handle() {
      return (
        this.asset.id != "copy" &&
        this.asset.id != "" &&
        !this.noDrag &&
        this.asset.imageProcessing != true
      );
    },
    processing() {
      return this.asset.imageProcessing ? true : false;
    }
  },
  methods: {
    /**
     * Lanza un mensaje de confirmación para eliminación de una imagen
     * y emite un evento indicando la posición de la imagen a eliminar
     */
    deleteImage() {
      this.$swal
        .fire({
          title: "Eliminar",
          text: "¿Desea eliminar la imagen seleccionada?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si",
          cancelButtonText: "No"
        })
        .then(result => {
          if (result.value) {
            this.$emit("delete-image", this.position);
          }
        });
    },
    /**
     * Lanza un mensaje preguntando por la cantidad de copias a crear y
     * emite un evento indicando la posición de la imagen a copiar y la cantidad
     */
    createCopies() {
      this.$swal
        .fire({
          title: "Crear copias",
          text: "Ingrese cuantas copias desea:",
          input: "number",
          inputValue: 1,
          inputAttributes: {
            min: 1,
            max: 3
          },
          showCancelButton: true,
          confirmButtonText: "Continuar",
          cancelButtonText: "Cancelar"
        })
        .then(result => {
          if (result.value) {
            this.$emit("create-copies", this.position, result.value);
          }
        });
    }
  },
  watch: {}
};
</script>
<style scoped>
.cover {
  width: 100% !important;
  height: auto !important;
  border-radius: 50%;
}
.cover-rectangle {
  border-radius: 0px;
  height: auto;
}

.transparent-bg {
  background-color: #ffffff;
  background-image: linear-gradient(45deg, #cacaca 25%, transparent 25%),
    linear-gradient(-45deg, #cacaca 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, #cacaca 75%),
    linear-gradient(-45deg, transparent 75%, #cacaca 75%);
  background-size: 20px 20px;
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
}

.cursor-grab {
  cursor: grab;
}
</style>
