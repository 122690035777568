<template>
  <div>
    <base-header title="Diferencias de Stock" title-link="#">
      <template v-slot:filters>
        <products-simple-filter></products-simple-filter>
      </template>
    </base-header>
    <b-container fluid="">
      <b-row>
        <b-col md="12">
          <stock-destinations-table
            :integration-config-id="$route.params.integrationConfigId"
          ></stock-destinations-table>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import BaseHeader from "../components/BaseHeader.vue";
import ProductsSimpleFilter from "../components/ProductsSimpleFilter.vue";
import StockDestinationsTable from "../components/StockDestinationsTable.vue";

export default {
  name: "StockDestinations",
  components: {
    BaseHeader,
    ProductsSimpleFilter,
    StockDestinationsTable
  },
  data() {
    return {};
  }
};
</script>
