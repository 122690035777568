<template>
  <div>
    <h4 class="font-weight-bold m-3">Restricciones de la promoción</h4>

    <b-row>
      <b-col>
        <div>
          <b-row>
            <b-col>
              <span>
                Limite de usos de la promoción en la tienda
              </span>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-form-checkbox v-model="unlimitedMaxUsage">
                Ilimitado
              </b-form-checkbox>
            </b-col>

            <b-col>
              <b-form-input
                type="number"
                min="0"
                v-model="maxUsage"
                :disabled="unlimitedMaxUsage"
              >
              </b-form-input>
            </b-col>
          </b-row>
        </div>

        <br />

        <div>
          <b-row>
            <b-col>
              <span>
                Limite de usos de la promoción en la tienda por cliente
              </span>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-form-checkbox v-model="unlimitedMaxUsagePerClient">
                Ilimitado
              </b-form-checkbox>
            </b-col>

            <b-col>
              <b-form-input
                type="number"
                min="0"
                v-model="maxUsagePerClient"
                :disabled="unlimitedMaxUsagePerClient"
              >
              </b-form-input>
            </b-col>
          </b-row>
        </div>

        <br />

        <div
          v-if="
            [
              'regular',
              'forThePriceOf',
              'progressive',
              'buyAndWin',
              'campaign'
            ].indexOf(promotion.type) != -1
          "
        >
          <b-row>
            <b-col>
              <span>
                Limite de articulos afectados por carrito
              </span>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-form-checkbox v-model="unlimitedMaxNumberOfAffectedItems">
                Ilimitado
              </b-form-checkbox>
            </b-col>

            <b-col>
              <b-form-input
                type="number"
                min="0"
                v-model="maxNumberOfAffectedItems"
                :disabled="unlimitedMaxNumberOfAffectedItems"
              >
              </b-form-input>
            </b-col>
          </b-row>
        </div>
      </b-col>

      <b-col>
        <b-form-checkbox
          v-if="['buyAndWin'].indexOf(promotion.type) != -1"
          v-model="sameSkus"
        >
          Solo para los mismos artículos
        </b-form-checkbox>

        <b-form-checkbox v-model="cumulative" :disabled="!allowOtherPromotions">
          Permite que las promociones se acumulen
        </b-form-checkbox>

        <b-form-checkbox
          v-model="accumulateWithManualPrice"
          :disabled="!allowManualPrice"
        >
          Permite que se acumule con precios manuales
        </b-form-checkbox>
      </b-col>
    </b-row>

    <hr />
  </div>
</template>

<script>
export default {
  name: "VtexBasePromotionRestrictions",
  props: {
    value: {
      type: Object,
      required: true
    },
    allowManualPrice: {
      type: Boolean,
      default: false
    },
    allowOtherPromotions: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      promotion: this.value,
      unlimitedMaxUsage: this.value.maxUsage === 0,
      maxUsage: this.value.maxUsage,
      unlimitedMaxUsagePerClient: this.value.maxUsagePerClient === 0,
      maxUsagePerClient: this.value.maxUsagePerClient,
      unlimitedMaxNumberOfAffectedItems:
        this.value.maxNumberOfAffectedItems === 0,
      maxNumberOfAffectedItems: this.value.maxNumberOfAffectedItems,
      cumulative: this.value.cumulative,
      accumulateWithManualPrice: this.value.accumulateWithManualPrice,
      sameSkus: this.value.enableBuyTogetherPerSku
    };
  },
  /**
   * En general, el objetivo de los watches es cambiar el valor de una propiedad
   * de la promocion cuando se cambia su correspondiente en la vista
   */
  watch: {
    value(newValue) {
      this.promotion = newValue;
    },
    promotion: {
      handler(newValue) {
        this.$emit("input", newValue);
      },
      deep: true
    },
    unlimitedMaxUsage(newValue) {
      if (newValue) {
        this.promotion.maxUsage = 0;
      } else {
        this.promotion.maxUsage = parseInt(this.maxUsage);
      }
    },
    maxUsage(newValue) {
      this.promotion.maxUsage = parseInt(newValue);
    },
    unlimitedMaxUsagePerClient(newValue) {
      if (newValue) {
        this.promotion.maxUsagePerClient = 0;
      } else {
        this.promotion.maxUsagePerClient = parseInt(this.maxUsagePerClient);
      }
    },
    maxUsagePerClient(newValue) {
      this.promotion.maxUsagePerClient = parseInt(newValue);
    },
    unlimitedMaxNumberOfAffectedItems(newValue) {
      if (newValue) {
        this.promotion.maxNumberOfAffectedItems = 0;
      } else {
        this.promotion.maxNumberOfAffectedItems = parseInt(
          this.maxNumberOfAffectedItems
        );
      }
    },
    maxNumberOfAffectedItems(newValue) {
      this.promotion.maxNumberOfAffectedItems = parseInt(newValue);
    },
    cumulative(newValue) {
      this.promotion.cumulative = newValue;
    },
    allowOtherPromotions(newValue) {
      if (!newValue) {
        this.cumulative = false;
      }
    },
    accumulateWithManualPrice(newValue) {
      this.promotion.accumulateWithManualPrice = newValue;
    },
    allowManualPrice(newValue) {
      if (!newValue) {
        this.accumulateWithManualPrice = false;
      }
    },
    sameSkus(newValue) {
      this.promotion.enableBuyTogetherPerSku = newValue;
    }
  }
};
</script>
