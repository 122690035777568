var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('b-spinner',{attrs:{"label":"Spinning"}}):_c('div',[_c('base-header',{attrs:{"title":"Más Vendidos","title-link":"/mercadolibre/highlights","map":['Mercado Libre', 'Productos más vendidos', _vm.siteId].concat(
          _vm.categoryId ? [_vm.categoryId] : []
        ),"title-size":9},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('b-container',[(!!_vm.categoryId)?_c('span',{staticStyle:{"padding-right":"5px"}},[_c('b-link',{attrs:{"to":{
                name: 'MercadoLibreHighlightsBySite',
                params: {
                  siteId: _vm.siteId
                }
              }}},[_vm._v(" "+_vm._s(_vm.siteId.toUpperCase()))])],1):_vm._e(),_vm._l((_vm.parentCategories ? _vm.parentCategories : []),function(elem,i){return _c('span',{key:i,staticStyle:{"padding-right":"5px"}},[_vm._v(" > "),_c('b-link',{attrs:{"to":{
                name: 'MercadoLibreHighlightsByCategory',
                params: {
                  siteId: _vm.siteId,
                  categoryId: elem.id
                }
              }}},[_vm._v(_vm._s(elem.name))])],1)}),_c('br'),(_vm.childrenCategories.length)?_c('b-dropdown',{attrs:{"variant":"outline-info","text":_vm.categoryName}},_vm._l((_vm.childrenCategories),function(child){return _c('b-dropdown-item',{key:child.id,attrs:{"to":{
                name: 'MercadoLibreHighlightsByCategory',
                params: {
                  siteId: _vm.siteId,
                  categoryId: child.id.toUpperCase()
                }
              }}},[_vm._v(" "+_vm._s(child.name)+" ")])}),1):_c('b-button',{attrs:{"variant":"outline-info"}},[_vm._v(_vm._s(_vm.categoryName))])],2)]},proxy:true}])}),_c('mercado-libre-category-highlights-table',{attrs:{"category-id":_vm.categoryId,"site-id":_vm.siteId}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }