<template>
  <b-button-group class="w-100">
    <b-button
      :variant="value === trueValue ? 'success' : 'outline-secondary'"
      @click="change(trueValue)"
      :disabled="disabled"
    >
      {{ trueText }}
    </b-button>
    <b-button
      :variant="value === falseValue ? 'danger' : 'outline-secondary'"
      @click="change(falseValue)"
      :disabled="disabled"
    >
      {{ falseText }}
    </b-button>
    <b-button
      v-if="allowNull"
      :variant="value === null ? 'secondary' : 'outline-secondary'"
      @click="change(null)"
      :disabled="disabled"
    >
      {{ defaultText }}
    </b-button>
  </b-button-group>
</template>
<script>
export default {
  name: "BaseBooleanSelector",
  model: {
    prop: "_value",
    event: "change"
  },
  props: {
    _value: [String, Boolean],
    allowNull: {
      type: Boolean,
      default: false
    },
    trueText: {
      type: String,
      default: "Activo"
    },
    trueValue: {
      type: [String, Boolean],
      default: true
    },
    falseValue: {
      type: [String, Boolean],
      default: false
    },
    falseText: {
      type: String,
      default: "Pausado"
    },
    defaultText: {
      type: String,
      default: "Por defecto"
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      value: this._value
    };
  },
  methods: {
    change(value) {
      this.value = value;
      this.$emit("change", value);
    }
  },
  watch: {
    _value(newVal) {
      this.value = newVal;
    }
  }
};
</script>
