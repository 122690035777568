<template>
  <div>
    <b-row>
      <b-col lg="4" sm="6" cols="12" class="mb-3">
        <b-form-group
          label="Documento para Notas de Débito"
          label-for="typeDebit"
          class="mb-3"
          :description="typeDebitDescription"
        >
          <b-spinner v-if="loading" />
          <v-select
            v-else
            id="typeDebit"
            v-model="value.typeDebit"
            :reduce="x => x.value"
            :options="typeDebitOptions"
            append-to-body=""
            @input="emitChange"
            placeholder="Selecciona una opción"
            :disabled="!allowSave"
          />
        </b-form-group>
      </b-col>
      <b-col lg="4" sm="6" cols="12" class="mb-3">
        <b-form-group
          label="Documento de Devolución"
          label-for="typeReturn"
          class="mb-3"
          :description="typeReturnDescription"
        >
          <b-spinner v-if="loading" />
          <v-select
            v-else
            id="typeReturn"
            v-model="value.typeReturn"
            :reduce="x => x.value"
            :options="typeReturnOptions"
            append-to-body=""
            @input="emitChange"
            placeholder="Selecciona una opción"
            :disabled="!allowSave"
          />
        </b-form-group>
      </b-col>
      <b-col lg="4" sm="6" cols="12" class="mb-3">
        <b-form-group
          label="Documento de Anulacion"
          label-for="typeCancel"
          class="mb-3"
          :description="typeCancelDescription"
        >
          <b-spinner v-if="loading" />
          <v-select
            v-else
            id="typeCancel"
            v-model="value.typeCancel"
            :reduce="x => x.value"
            :options="typeReturnOptions"
            append-to-body=""
            @input="emitChange"
            placeholder="Selecciona una opción"
            :disabled="!allowSave || value.typeReturn === null"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  name: "IntegrationConfigsBSaleOtherData",
  props: {
    _value: Object,
    originalData: Object,
    allowSave: Boolean,
    loading: {
      type: Boolean,
      default: true
    },
    typeDebitOptions: {
      type: Array,
      default() {
        return [];
      }
    },
    typeReturnOptions: {
      type: Array,
      default() {
        return [];
      }
    },
    errorLoading: {
      type: String,
      default: ""
    }
  },
  model: {
    event: "change",
    prop: "_value"
  },
  data() {
    return {
      value: this.$dup(this._value)
    };
  },
  computed: {
    typeDebitDescription() {
      return this.getHelpIfErrorOrEmpty(
        this.value.typeDebit,
        "Si no seleccionas un documento, Centry no podrá emitir notas de débito cuando corresponda."
      );
    },
    typeReturnDescription() {
      return this.getHelpIfErrorOrEmpty(
        this.value.typeReturn,
        "Si no seleccionas un documento, Centry no podrá emitir documentos de devolución."
      );
    },
    typeCancelDescription() {
      if (this.value.typeReturn === null) return "";
      return this.getHelpIfErrorOrEmpty(
        this.value.typeCancel,
        "Si no seleccionas un documento, Centry no podrá emitir documentos de anulación."
      );
    }
  },
  methods: {
    /**
     * Entrega el mensaje de error (cuando existe error) o emptyMessage
     * cuando el campo está vacío
     * @param {Integer} data
     * @param {String} emptyMessage
     * @returns {String}
     */
    getHelpIfErrorOrEmpty(data, emptyMessage) {
      if (this.errorLoading && this.errorLoading.length) {
        return this.errorLoading;
      }
      if (data === null) {
        return emptyMessage;
      }
      return "";
    },
    /**
     * Emite el valor para actualizar el modelo
     */
    emitChange() {
      this.$emit("change", this.value);
    },

    /**
     * Setea el valor en value
     * si es que es un objeto distinto
     * @param {Object} val
     */
    setValue(val) {
      if (val !== this.value) this.value = val;
    }
  },
  watch: {
    value() {
      this.emitChange();
    },
    _value(val) {
      this.setValue(val);
    }
  }
};
</script>
