<template>
  <div>
    <base-header title="Guías de tallas" title-link="#">
      <template v-slot:actions>
        <b-container>
          <b-button :to="'/size_chart/new'" variant="outline-info">
            <b-icon-plus></b-icon-plus>
            Nuevo
          </b-button>
        </b-container>
      </template>
      <template v-slot:filters>
        <size-charts-filter></size-charts-filter>
      </template>
    </base-header>
    <b-container fluid="">
      <b-row>
        <b-col>
          <size-charts-table></size-charts-table>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import BaseHeader from "../components/BaseHeader.vue";
import SizeChartsTable from "../components/SizeChart/SizeChartsTable.vue";
import SizeChartsFilter from "../components/SizeChart/SizeChartsFilter.vue";

export default {
  name: "SizeCharts",
  components: {
    BaseHeader,
    SizeChartsTable,
    SizeChartsFilter
  },
  data() {
    return {};
  }
};
</script>
