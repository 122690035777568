<template>
  <div>
    <b-row>
      <b-col>
        <b-dropdown variant="outline-info" text="Crear Promoción">
          <b-dropdown-item @click="createPromotion('regular')">
            Regular
          </b-dropdown-item>
          <b-dropdown-item @click="createPromotion('combo')">
            Comprar Juntos
          </b-dropdown-item>
          <b-dropdown-item @click="createPromotion('forThePriceOf')">
            Mas por Menos
          </b-dropdown-item>
          <b-dropdown-item @click="createPromotion('progressive')">
            Descuento Progresivo
          </b-dropdown-item>
          <b-dropdown-item @click="createPromotion('buyAndWin')">
            Comprar y Obtener
          </b-dropdown-item>
          <b-dropdown-item @click="createPromotion('campaign')">
            Campaña
          </b-dropdown-item>
        </b-dropdown>
      </b-col>
    </b-row>

    <br />

    <div v-if="promotionSaved !== null">
      <b-row>
        <b-col>
          <b-alert v-if="!promotionSaved" show="" variant="danger" dismissible>
            Ha ocurrido un error al guardar la promoción.
          </b-alert>
          <b-alert v-else show="" variant="success" dismissible>
            La promoción ha sido guardada con éxito. Los cambios pueden tardar
            unos minutos en reflejarse.
          </b-alert>
        </b-col>
      </b-row>

      <br />
    </div>

    <b-row>
      <b-col>
        <vtex-promotions-table
          :vtexIntegrationConfigId="vtexIntegrationConfigId"
        >
        </vtex-promotions-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import VtexPromotionsTable from "@/components/VtexMaintainers/Promotions/VtexPromotionsTable.vue";

export default {
  name: "VtexPromotionsMaintainerTab",
  props: {
    vtexIntegrationConfigId: {
      type: String,
      required: true
    },
    promotionSaved: {
      type: Boolean,
      default: null
    }
  },
  components: {
    VtexPromotionsTable
  },
  data() {
    return {};
  },
  methods: {
    /**
     * Se encarga de retornar la vista de edición de una promoción para una
     * nueva promoción.
     * @param {string} promotionType - Tipo de promoción
     */
    createPromotion(type) {
      this.$router.push({
        name: "VtexPromotionEdit",
        params: {
          vtexIntegrationConfigId: this.vtexIntegrationConfigId,
          _promotionType: type
        }
      });
    }
  }
};
</script>
