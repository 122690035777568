<template>
  <error-dashboard
    v-if="!!error || !!timeoutError"
    :error="!!error"
    :timeout-error="!!timeoutError"
    @retry="loadAll"
  ></error-dashboard>
  <div v-else>
    <b-row no-gutters align-h="between" class="mt-2">
      <h4>{{ widget.name }}</h4>
      <export-xlsx-dashboard
        v-if="!loading && !!xlsxData && !!xlsxData.length"
        :data="xlsxData"
        :widget="widget"
      ></export-xlsx-dashboard>
    </b-row>
    <b-skeleton-img aspect="1:1" v-if="loading"></b-skeleton-img>
    <pie-chart
      v-else
      :data="pieChartData"
      legend-position="bottom"
      :options="options"
    />
  </div>
</template>

<script>
import PieChart from "../../components/charts/PieChart.vue";
import DASHBOARD_PIE_WIDGET from "../../graphql/DashboardPieWidget.gql";
import BaseWidget from "@/components/dashboard/BaseWidget";
import ErrorDashboard from "@/components/dashboard/ErrorDashboard";
import ExportXlsxDashboard from "@/components/dashboard/ExportXlsxDashboard";
export default {
  name: "DashboardPie",
  extends: BaseWidget,
  components: {
    ExportXlsxDashboard,
    PieChart,
    ErrorDashboard
  },
  data() {
    return {
      formatting: null
    };
  },
  computed: {
    options() {
      let formatting = this.formatting;
      if (!formatting || !formatting.length) {
        formatting = "value => value";
      }
      return {
        tooltips: {
          callbacks: {
            label: function(tooltipItem, data) {
              let label = data.labels[tooltipItem.index];
              // eslint-disable-next-line no-unused-vars
              let value =
                data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
              let formatValue = eval(formatting);
              let valueFormatted = formatValue(value);
              return label + ": " + valueFormatted;
            }
          }
        }
      };
    },
    xlsxData() {
      const allData = this.pieChartData;
      const labels = allData.labels;
      const datasets = allData.datasets;
      if (!labels || !datasets || !datasets.length) return [];
      const data = datasets[0].data;
      const separator = this.infoWidget.separatorColumn;
      const dataColumn = this.infoWidget.dataColumn;
      return labels.map((x, index) => {
        const elem = {};
        elem[separator] = x;
        elem[dataColumn] = data[index];
        return elem;
      });
    },
    pieChartData() {
      let dataset = this.splitedData(this.data);
      let pieDataSet = {};
      let labels = [];
      let datasets = [];
      let datas = [];
      let backgroundColor = [];
      let container = {};

      dataset.map(data => {
        let label = data.separator;
        let amount = data.accumulator;
        backgroundColor.push(this.colorFor(label));
        labels.push(label);
        datas.push(amount);
      });

      pieDataSet.labels = labels;
      container.label = "Data One";
      container.backgroundColor = backgroundColor;
      container.data = datas;
      datasets.push(container);
      pieDataSet.datasets = datasets;
      return pieDataSet;
    }
  },
  methods: {
    getWidget() {
      return this.$apollo
        .query({
          query: DASHBOARD_PIE_WIDGET,
          variables: {
            id: this.widget.id
          }
        })
        .then(result => {
          this.infoWidget = result.data.dashboardPieWidget;
          this.formatting = this.infoWidget.formatter;
        });
    },
    splitedData(reports) {
      let sales = [];
      let separator = this.infoWidget.separatorColumn;
      let accumulator = this.infoWidget.dataColumn;
      reports.map(report => {
        let container = {};
        container.separator = report[separator];
        container.accumulator = Number.parseFloat(report[accumulator]);

        let sale = sales.find(sale => sale.separator === container.separator);
        if (sale) {
          sale.accumulator += container.accumulator;
        } else {
          sales.push(container);
        }
      });
      return sales;
    }
  }
};
</script>
