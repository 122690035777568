var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ApolloQuery',{attrs:{"query":require('../../graphql/IntegrationConfigMiraklForSizeCharts.gql'),"variables":{
    id: _vm.IntegrationConfigId,
    keywords: _vm.filters.keyword.split(',')
  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var ref_result = ref.result;
  var loading = ref_result.loading;
  var error = ref_result.error;
  var data = ref_result.data;
return [(data && data.integrationConfigMirakl)?_c('v-select',{attrs:{"clearable":true,"options":_vm.optionsForSelect(data.integrationConfigMirakl.chartsQuery),"placeholder":"Escoja una tabla de tallas"},model:{value:(_vm.selectedSizeChart),callback:function ($$v) {_vm.selectedSizeChart=$$v},expression:"selectedSizeChart"}}):_vm._e(),(loading)?_c('b-spinner',{attrs:{"label":"Spinning"}}):_vm._e(),(error)?_c('b-alert',{attrs:{"show":"","variant":"danger"}},[_vm._v(" Ha ocurrido un error ")]):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }