import { render, staticRenderFns } from "./PromotionsIndexJumpsellerRow.vue?vue&type=template&id=75ea4956&scoped=true&"
import script from "./PromotionsIndexJumpsellerRow.vue?vue&type=script&lang=js&"
export * from "./PromotionsIndexJumpsellerRow.vue?vue&type=script&lang=js&"
import style0 from "./PromotionsIndexJumpsellerRow.vue?vue&type=style&index=0&id=75ea4956&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75ea4956",
  null
  
)

export default component.exports