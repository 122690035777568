<template>
  <div>
    <div v-if="categoryAttributes && categoryAttributes.length">
      <h4 class="font-weight-bold m-3">
        Atributos específicos de la categoría primaria
      </h4>
    </div>
    <b-row>
      <b-col md="3">
        <product-edit-tabs-rocket-internet-attribute-input
          v-for="attr of highAttributes"
          :key="attr.FeedName"
          :attribute="attr"
          v-model="value[attr.FeedName]"
          @input="emitChange"
        />
      </b-col>
      <b-col md="9">
        <b-row>
          <b-col md="4" v-for="attr of lowAttributes" :key="attr.FeedName">
            <product-edit-tabs-rocket-internet-attribute-input
              :attribute="attr"
              v-model="value[attr.FeedName]"
              @input="emitChange"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import ProductEditTabsRocketInternetAttributeInput from "./AttributeInput.vue";
export default {
  name: "ProductEditTabsRocketInternetCategoryAttributes",
  components: {
    ProductEditTabsRocketInternetAttributeInput
  },
  model: {
    prop: "_value",
    event: "change"
  },
  props: {
    _value: {
      type: Object,
      default() {
        return {};
      }
    },
    categoryAttributes: Array,
    isDafiti: {
      type: Boolean,
      default: false
    }
  },
  created() {
    this.value = this.currentCategoryAttributeValues(this._value);
  },
  data() {
    const bannedAttributes = [
      "SkuSupplierConfig",
      "Color",
      "ColorBasico",
      "ColorFamily",
      "ColorNameBrand",
      "SellerSku",
      "ParentSku",
      "Status",
      "Name",
      "Variation",
      "Size",
      "Talla",
      "PrimaryCategory",
      "Categories",
      "BrowseNodes",
      "Description",
      "Brand",
      "Price",
      "SalePrice",
      "SaleStartDate",
      "SaleEndDate",
      "TaxClass",
      "ShipmentType",
      "ProductId",
      "Condition",
      "Quantity",
      "VolumetricWeight",
      "ProductGroup",
      "QuantityFalabella",
      "PriceFalabella",
      "SalePriceFalabella",
      "SaleStartDateFalabella",
      "SaleEndDateFalabella"
    ];
    if (this.isDafiti) {
      bannedAttributes.push("IsExchangeable");
    }
    return {
      bannedAttributes,
      value: {},
      loadingDates: true
    };
  },
  computed: {
    lowAttributes() {
      return this.categoryAttributes.filter(x => {
        return (
          !this.bannedAttributes.includes(x.FeedName) &&
          ["multiselect", "textarea"].includes(x.InputType)
        );
      });
    },
    highAttributes() {
      return this.categoryAttributes.filter(x => {
        return (
          !this.bannedAttributes.includes(x.FeedName) &&
          !["multiselect", "textarea"].includes(x.InputType)
        );
      });
    },
    allowedAttributes() {
      return [].concat(this.lowAttributes, this.highAttributes);
    }
  },
  methods: {
    /**
     * Entrega el tipo de input según el atributo
     * @param {Object} attribute
     * @returns {String}
     */
    inputType(attribute) {
      if (attribute.InputType === "numberfield") {
        return "number";
      }
      return "text";
    },
    currentCategoryAttributeValues(values) {
      const current = {};
      if (!this.allowedAttributes) return current;
      this.allowedAttributes.forEach(x => {
        if (values[x.FeedName]) {
          if (x.InputType === "datefield") {
            current[x.FeedName] = null;
          } else {
            current[x.FeedName] = values[x.FeedName];
          }
        } else {
          if (["dropdown", "multiselect", "datefield"].includes(x.InputType)) {
            current[x.FeedName] = null;
          } else {
            current[x.FeedName] = "";
          }
        }
      });
      return current;
    },
    emitChange() {
      this.$emit("change", this.value);
    }
  },
  watch: {
    async categoryAttributes() {
      this.value = this.currentCategoryAttributeValues(this._value);
    }
  }
};
</script>
