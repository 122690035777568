<template>
  <div>
    <base-header title="Homologador de Vtex" title-link="/vtex_homologation">
      <template v-slot:filters>
        <b-button variant="info" to="/vtex_homologation/vtex_purposes">
          Destinos de Homologación Vtex
        </b-button></template
      >
    </base-header>
    <b-card no-body>
      <b-tabs card @activate-tab="changeTab" v-model="selected">
        <b-tab title="Categorías" :key="0">
          <vtex-homologation-categories></vtex-homologation-categories>
        </b-tab>
        <b-tab title="Marcas" :key="1">
          <vtex-homologation-brands></vtex-homologation-brands
        ></b-tab>
        <b-tab title="Colores" :key="2">
          <vtex-homologation-colors></vtex-homologation-colors
        ></b-tab>
        <b-tab title="Tallas" :key="3">
          <vtex-homologation-sizes></vtex-homologation-sizes
        ></b-tab>
        <b-tab title="Géneros" :key="4">
          <vtex-homologation-genders></vtex-homologation-genders
        ></b-tab>
        <b-tab title="Garantías" :key="5">
          <vtex-homologation-warranties></vtex-homologation-warranties
        ></b-tab>
        <b-tab title="Atributos" :key="6">
          <vtex-homologation-specifications></vtex-homologation-specifications
        ></b-tab>
      </b-tabs>
    </b-card>
    <br />
  </div>
</template>

<script>
import BaseHeader from "@/components/BaseHeader.vue";
import VtexHomologationCategories from "@/components/VtexHomologation/VtexHomologationCategories";
import VtexHomologationBrands from "@/components/VtexHomologation/VtexHomologationBrands";
import VtexHomologationColors from "@/components/VtexHomologation/VtexHomologationColors";
import VtexHomologationSizes from "@/components/VtexHomologation/VtexHomologationSizes";
import VtexHomologationGenders from "@/components/VtexHomologation/VtexHomologationGenders";
import VtexHomologationWarranties from "@/components/VtexHomologation/VtexHomologationWarranties";
import VtexHomologationSpecifications from "@/components/VtexHomologation/VtexHomologationAttributes/VtexHomologationAttributes";

export default {
  name: "VtexHomologation",
  components: {
    VtexHomologationBrands,
    VtexHomologationCategories,
    VtexHomologationColors,
    VtexHomologationSizes,
    VtexHomologationGenders,
    VtexHomologationWarranties,
    VtexHomologationSpecifications,
    BaseHeader
  },
  data() {
    return {
      selected: 0
    };
  },
  methods: {
    /**
     * Cambia la pestaña a ser mostrada en la vista segun su índice
     * @param {Integer} newTabIndex
     */
    changeTab(newTabIndex) {
      this.selected = newTabIndex;
    }
  }
};
</script>
