<template>
  <div>
    <base-alert-with-count-down
      v-model="showAlert"
      :alert-variant="alertVariant"
      :dismiss-secs="error ? 10 : 5"
    >
      {{ alertMessage }}</base-alert-with-count-down
    >
    <b-row>
      <b-col xl="10" cols="12">
        <b-row>
          <b-col md="4" sm="6" cols="12">
            <input-with-skeleton
              :loading="isLoadingUser"
              label="Correo electronico"
              :invalid-feedback="validation.email.invalidFeedback"
              :state="validation.email.valid"
              v-model="email"
            />
          </b-col>
          <b-col md="4" sm="6" cols="12">
            <input-with-skeleton
              :loading="isLoadingUser"
              label="Nombre"
              :invalid-feedback="validation.firstName.invalidFeedback"
              :state="validation.firstName.valid"
              v-model="firstName"
            />
          </b-col>
          <b-col md="4" sm="6" cols="12">
            <input-with-skeleton
              :loading="isLoadingUser"
              label="Apellido"
              :invalid-feedback="validation.lastName.invalidFeedback"
              :state="validation.lastName.valid"
              v-model="lastName"
            />
          </b-col>
          <b-col md="4" sm="6" cols="12">
            <input-with-skeleton
              :loading="isLoadingUser"
              label="Contraseña"
              :invalid-feedback="validation.password.invalidFeedback"
              :state="validation.password.valid"
              type="password"
              :autocomplete="'new-password'"
              v-model="password"
            />
          </b-col>
          <b-col md="4" sm="6" cols="12">
            <input-with-skeleton
              :loading="isLoadingUser"
              label="Confirmar contraseña"
              :invalid-feedback="
                validation.passwordConfirmation.invalidFeedback
              "
              :state="validation.passwordConfirmation.valid"
              type="password"
              :autocomplete="'new-password'"
              v-model="passwordConfirmation"
            />
          </b-col>
          <b-col md="4" sm="6" cols="12">
            <ApolloQuery
              :query="require('../graphql/Users/AllUserGroups.gql')"
              :fetchPolicy="'network-only'"
              clientId="apolloClientCached"
            >
              <template v-slot="{ result: { data }, isLoading }">
                <b-form-group label="Grupo" label-for="userGroupId">
                  <v-select
                    v-if="!isLoadingUser && !isLoading"
                    id="userGroupId"
                    :options="data.allUserGroups.edges.map(r => r.node)"
                    label="name"
                    placeholder="Seleccionar"
                    v-model="userGroupId"
                    :reduce="cond => cond.id"
                  ></v-select>
                  <b-skeleton v-else type="input" />
                </b-form-group>
              </template>
            </ApolloQuery>
          </b-col>
        </b-row>
      </b-col>
      <b-col xl="2" cols="12">
        <b-button
          variant="info"
          :disabled="!changed || saving"
          :title="changed ? '' : 'No hay cambios'"
          @click="save"
          class="mb-3 w-100"
        >
          <span v-if="saving">
            Guardando
            <b-spinner label="Spinning" />
          </span>
          <span v-else>Guardar</span>
        </b-button>
        <b-button
          variant="outline-info"
          class="w-100 px-0"
          :disabled="!changed || saving"
          @click="resetData()"
        >
          <span>Restablecer</span>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import InputWithSkeleton from "../components/Base/InputWithSkeleton.vue";
import BaseAlertWithCountDown from "../components/Base/BaseAlertWithCountDown.vue";
import CREATE_USER from "../graphql/Users/CreateUser.gql";
import UPDATE_USER from "../graphql/Users/UpdateUser.gql";
export default {
  name: "UserEdit",
  props: {
    user: {
      type: Object,
      default() {
        return {};
      }
    },
    isLoadingUser: Boolean
  },
  components: {
    InputWithSkeleton,
    BaseAlertWithCountDown
  },
  data() {
    return {
      email: this.user?.email || "",
      firstName: this.user?.firstName || "",
      lastName: this.user?.lastName || "",
      userGroupId: this.user?.userGroup?.id || null,
      password: null,
      passwordConfirmation: null,
      showAlert: false,
      saving: false,
      error: false,
      errorMessage: null
    };
  },
  mounted() {
    if (!this.isConsolidated()) {
      this.email = "";
      this.firstName = "";
      this.lastName = "";
      this.userGroupId = null;
    }
  },
  computed: {
    validation() {
      return {
        email: {
          valid: this.email?.length > 0,
          invalidFeedback: "El correo es obligatorio"
        },
        firstName: {
          valid: this.firstName?.length > 0,
          invalidFeedback: "El nombre es obligatorio"
        },
        lastName: {
          valid: this.lastName?.length > 0,
          invalidFeedback: "El apellido es obligatorio"
        },
        password: {
          valid: this.isConsolidated() ? true : this.password?.length > 0,
          invalidFeedback: "La contraseña es obligatoria"
        },
        passwordConfirmation: {
          valid:
            !this.password ||
            (this.password?.length > 0 &&
              this.passwordConfirmation != null &&
              this.password == this.passwordConfirmation),
          invalidFeedback:
            this.password?.length > 0 &&
            this.passwordConfirmation != null &&
            this.password != this.passwordConfirmation
              ? "Las contraseñas deben coincidir"
              : "La confirmacion de contraseña es obligatoria"
        }
      };
    },
    valid() {
      let valid = true;
      Object.keys(this.validation).forEach(x => {
        valid = valid && this.validation[x].valid;
      });
      return valid;
    },
    changed() {
      return (
        (this.user?.email || "") != this.email ||
        (this.user?.firstName || "") != this.firstName ||
        (this.user?.lastName || "") != this.lastName ||
        (this.user?.userGroup?.id || null) != this.userGroupId ||
        this.password != null
      );
    },
    alertVariant() {
      return this.error ? "danger" : "success";
    },
    alertMessage() {
      return this.error
        ? "Ha ocurrido un error guardando los datos: " + this.errorMessage
        : "Bodega guardada exitosamente";
    }
  },
  methods: {
    /**
     * Indica si es creacion o no
     * @return {Boolean}
     */
    isConsolidated() {
      if (this.$route.name == "UserNew") {
        return false;
      } else {
        return true;
      }
    },
    async save() {
      this.error = false;
      this.errorMessage = null;
      if (!this.valid) {
        this.error = true;
        this.errorMessage = "Hay campos que se deben rellenar";
        this.showAlert = true;
      } else {
        this.saving = true;
        let action = "updated";
        if (this.isConsolidated()) {
          await this.updateUser();
        } else {
          await this.createUser();
          action = "created";
        }
        if (!this.error) {
          this.$router.push({
            name: "Users",
            params: {
              action: action
            }
          });
        }
      }
    },
    /**
     * Envia la mutacion para actualizar un usuario
     */
    async updateUser() {
      await this.$apollo
        .mutate({
          mutation: UPDATE_USER,
          variables: {
            user: this.getVariables()
          }
        })
        .then(({ data }) => {
          if (!data.updateUser.result) {
            this.error = true;
            this.errorMessage = data.updateUser.error;
            this.showAlert = true;
          } else {
            this.user.firstName = this.firstName;
            this.user.lastName = this.lastName;
            this.user.email = this.firstName + " " + this.lastName;
            this.user.name = this.name;
            if (!this.user.userGroup) {
              this.user.userGroup = {};
            }
            this.user.userGroup.id = this.userGroupId;
          }
          this.saving = false;
        })
        .catch(e => {
          this.error = true;
          this.errorMessage = e.message;
          this.showAlert = true;
          this.saving = false;
        });
    },
    /**
     * Envia la mutacion para crear un usuairo
     */
    async createUser() {
      await this.$apollo
        .mutate({
          mutation: CREATE_USER,
          variables: {
            user: this.getVariables()
          }
        })
        .then(({ data }) => {
          if (!data.createUser.result) {
            this.error = true;
            this.errorMessage = data.createUser.error;
          } else {
            this.user.firstName = this.firstName;
            this.user.lastName = this.lastName;
            this.user.email = this.firstName + " " + this.lastName;
            this.user.name = this.name;
            this.user.userGroup = {};
            this.user.userGroup.id = this.userGroupId;
          }
          this.showAlert = true;
          this.saving = false;
        })
        .catch(e => {
          this.error = true;
          this.errorMessage = e.message;
          this.showAlert = true;
          this.saving = false;
        });
    },
    /**
     * Se encarga de resetear los valores del formulario
     */
    resetData() {
      this.email = this.user.email || "";
      this.firstName = this.user.firstName || "";
      this.lastName = this.user.lastName || "";
      this.userGroupId = this.user.userGroup?.id || null;
      this.password = null;
      this.passwordConfirmation = null;
    },
    getVariables() {
      let toUpdate = {};
      if (this.isConsolidated()) {
        toUpdate["id"] = this.user.id;
      }
      if (this.password) {
        toUpdate["password"] = this.password;
      }
      if (this.user?.firstName != this.firstName) {
        toUpdate["firstName"] = this.firstName;
      }
      if (this.user?.lastName != this.lastName) {
        toUpdate["lastName"] = this.lastName;
      }
      if (this.user?.email != this.email) {
        toUpdate["email"] = this.email;
      }
      toUpdate["userGroupId"] = this.userGroupId;
      return toUpdate;
    }
  },
  watch: {
    user: {
      handler(val) {
        this.email = val.email || "";
        this.firstName = val.firstName || "";
        this.lastName = val.lastName || "";
        this.userGroupId = val.userGroup?.id || null;
      },
      deep: true
    }
  }
};
</script>

<style></style>
