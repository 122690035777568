<template>
  <b-modal
    :id="`create-transaction-${variantId}`"
    size="lg"
    scrollable
    body-class="overflow-visible h-75"
    content-class="overflow-visible "
    @hide="resetData"
  >
    <template v-slot:modal-title>
      <h4>Mover stock entre bodegas. (Variante: {{ variantSku }})</h4>
    </template>
    <b-alert v-if="error" variant="danger" :show="true" dismissible>
      {{ alertMessage }}</b-alert
    >
    <div
      v-if="newVariantWarehouses && newVariantWarehouses.length"
      style="height: 100%"
    >
      <b-row style="height: 100%">
        <b-col style="height: 100%; overflow-y: scroll">
          <h4 class="mb-2 font-weight-bold">Stock disponible</h4>
          <b-table-simple>
            <b-thead>
              <b-tr>
                <b-th>
                  Bodega
                </b-th>
                <b-th>
                  Stock
                </b-th>
              </b-tr>
            </b-thead>

            <b-tbody>
              <b-tr v-for="(vw, index) in newVariantWarehouses" :key="index">
                <b-th>
                  {{ vw.warehouse.name }}
                </b-th>
                <b-td>
                  {{ vw.quantity }}
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
        <b-col>
          <h4 class="font-weight-bold">Mover stock</h4>
          <hr style="margin-top: 0.5rem" />
          <b-form-group label-for="quantity" label="Cantidad">
            <b-form-input
              id="quantity"
              type="number"
              number
              :state="quantity > 0 ? true : false"
              v-model="quantity"
            ></b-form-input>
            <b-form-invalid-feedback>
              Se debe ingresar un numero mayor a 0
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label-for="fromWarehouse" label="Desde bodega">
            <v-select
              id="fromWarehouse"
              :options="warehousesOptions"
              v-model="fromWarehouse"
              :reduce="val => val.id"
              label="name"
            ></v-select>
          </b-form-group>
          <b-form-group label-for="toWarehouse" label="A bodega">
            <v-select
              id="toWarehouse"
              :options="warehousesOptions"
              v-model="toWarehouse"
              :reduce="val => val.id"
              label="name"
            ></v-select>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <template #modal-footer>
      <b-row>
        <b-button
          class="mx-2"
          variant="info"
          :disabled="!changes"
          @click="createTransaction"
        >
          <span v-if="saving"> <b-spinner /></span>
          <span v-else> ¡Realizar movimiento! </span>
        </b-button>
        <b-button class="mx-2" variant="outline-info" @click="closeModal">
          Cerrar
        </b-button>
      </b-row>
    </template>
  </b-modal>
</template>
<script>
import CREATE_TRANSACTION from "../../../graphql/Product/Inventory/CreateTransaction.gql";
export default {
  name: "TransactionModal",
  props: {
    variantWarehouses: {
      type: Array,
      default() {
        return [];
      }
    },
    variantSku: {
      type: String,
      default: ""
    },
    variantId: {
      type: String,
      default: ""
    }
  },
  mounted() {
    if (this.variantWarehouses) {
      this.newVariantWarehouses = this.$dup(this.variantWarehouses);
      this.warehousesOptions = this.newVariantWarehouses.map(
        vw => vw.warehouse
      );
    }
  },
  data() {
    return {
      newVariantWarehouses: [],
      warehousesOptions: [],
      saving: false,
      quantity: null,
      fromWarehouse: null,
      toWarehouse: null,
      error: false,
      errorMessage: null,
      success: false
    };
  },
  computed: {
    changes() {
      return (
        this.quantity &&
        this.quantity > 0 &&
        this.fromWarehouse &&
        this.toWarehouse
      );
    },
    alertMessage() {
      return "Ha ocurrido un error guardando los datos: " + this.errorMessage;
    }
  },
  methods: {
    /**
     * Se encarga de cerrar el modal de la transacccion
     */
    closeModal() {
      this.$bvModal.hide(`create-transaction-${this.variantId}`);
    },
    /**
     * Resetea las variables de la transaccion
     */
    variablesReset() {
      this.quantity = null;
      this.toWarehouse = null;
      this.fromWarehouse = null;
    },
    /**
     * Resetea las variables de exito o error
     */
    softReset() {
      this.success = false;
      this.error = false;
      this.errorMessage = null;
    },
    /**
     *Resetea todas la variables
     */
    resetData() {
      this.variablesReset();
      this.softReset();
      this.saving = false;
    },
    /**
     * Se encarga de mandar la mutacion que crea una transaccion
     */
    createTransaction() {
      if (!this.transactionAvailable()) {
        return;
      }
      this.softReset();
      this.saving = true;
      let transaction = {
        variantId: this.variantId,
        fromWarehouseId: this.fromWarehouse,
        toWarehouseId: this.toWarehouse,
        quantity: this.quantity
      };
      this.$apollo
        .mutate({
          mutation: CREATE_TRANSACTION,
          variables: { transaction: transaction }
        })
        .then(async ({ data }) => {
          if (data.createTransaction.result) {
            let updatedVariantWarehouses = await this.updateNewVariantWarehouses();
            this.variablesReset();
            this.success = true;
            this.saving = false;
            this.$emit("change", updatedVariantWarehouses);
          } else {
            this.error = true;
            this.errorMessage = data.createTransaction.error;
            this.saving = false;
          }
        })
        .catch(e => {
          this.errorMessage = e;
          this.error = true;
          this.saving = false;
        });
    },
    /**
     * Indica si una transaccion se puede realizar o no
     * @return {Boolean} - indica si la transaccion de puede realizar o no
     */
    transactionAvailable() {
      if (this.toWarehouse == this.fromWarehouse) {
        this.error = true;
        this.errorMessage = "Bodega de origen y destino deben ser distintas";
        return false;
      } else if (!this.moveStockAvailable()) {
        this.error = true;
        this.errorMessage =
          "La cantidad ingresada es mayor al stock disponible en bodega origen";
        return false;
      }
      return true;
    },
    /** Indica si el warehouse tiene una cantidad suficiente para mover
     * @return {Boolean} - indica si se tiene stock suficiente
     */
    moveStockAvailable() {
      let available = true;
      this.newVariantWarehouses.forEach(vw => {
        if (
          vw.warehouse.id == this.fromWarehouse &&
          vw.quantity < this.quantity
        ) {
          available = false;
        }
      });
      return available;
    },
    /**
     * Actualiza el valor de los variantWarehouses despues de realizar la transaccion
     */
    updateNewVariantWarehouses() {
      let tempVw = [];
      this.newVariantWarehouses.forEach(vw => {
        let newvw = this.$dup(vw);
        if (vw.warehouse.id == this.fromWarehouse) {
          newvw.quantity -= this.quantity;
        }
        if (vw.warehouse.id == this.toWarehouse) {
          newvw.quantity += this.quantity;
        }
        tempVw.push(newvw);
      });
      return tempVw;
    }
  },
  watch: {
    variantWarehouses: {
      handler(val) {
        this.newVariantWarehouses = this.$dup(val);
        this.warehousesOptions = this.newVariantWarehouses.map(
          vw => vw.warehouse
        );
      },
      deep: true
    }
  }
};
</script>

<style scoped>
.table thead th {
  padding: 5px !important;
  text-transform: none !important;
  font-size: 15px;
}
.table tbody th {
  padding: 5px !important;
}
.table td {
  padding: 5px !important;
  font-weight: 500;
}
</style>
