<template>
  <div>
    <b-icon-search class="filter-icon"></b-icon-search>
    <b-form-tags
      class="filter-text"
      v-model="keyword"
      separator=","
      remove-on-delete=""
      placeholder="Nombre o SKU"
      @keydown.native.enter="updateKeyword"
      @keydown.native.delete="cleanSearch"
      @input="cleanSearch"
    ></b-form-tags>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";

export default {
  name: "ProductsSimpleFilter",
  data() {
    return {
      keyword: []
    };
  },
  computed: {
    ...mapState(["filters"])
  },
  methods: {
    ...mapMutations(["setFilter"]),
    updateKeyword() {
      this.setFilter({ keyword: this.keyword.join(",") });
    },
    cleanSearch() {
      if (!this.keyword.length) {
        this.updateKeyword();
      }
    }
  },
  watch: {
    filters(newValue, oldValue) {
      const oldKeyword = oldValue.keyword;
      const newKeyword = newValue.keyword;
      if (oldKeyword !== newKeyword && this.keyword.join(",") !== newKeyword) {
        this.keyword = newKeyword.split(",");
      }
    }
  }
};
</script>
