<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-button-group class="mb-4">
          <b-button variant="outline-info" @click="cancel">
            <b-icon-x-circle></b-icon-x-circle> Cancelar
          </b-button>
          <b-button
            variant="info"
            :disabled="!changed || saving"
            :title="changed ? '' : 'No hay cambios'"
            v-b-tooltip.hover=""
            @click="save"
          >
            <span> <b-icon-cloud-upload></b-icon-cloud-upload> Guardar </span>
          </b-button>
        </b-button-group>
        <base-alert-with-count-down
          v-model="saved"
          :alert-variant="error ? 'danger' : 'success'"
        >
          {{ error ? "Error: " + error : "Cambios guardados con éxito" }}
        </base-alert-with-count-down>
        <b-alert :show="formErrors && !valid" variant="danger" class="m-4">
          No se pudo crear la promoción debido a:
          <ul>
            <li v-for="(error, index) of errorList" :key="index">
              {{ error }}
            </li>
          </ul>
        </b-alert>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label="* Nombre" label-for="sizeChartName">
          <b-form-input
            id="sizeChartName"
            v-model="sizeChartName"
            placeholder="Ej: Tabla de tallas MiMarca, Polerones, Mujer"
            @input="setChanged"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row
      v-for="(filter, index) of filters"
      :key="index"
      class="border rounded m-1"
    >
      <b-col md="4" class="mt-2">
        <size-chart-filter-multi-select-option
          title="Categorías"
          :index="index"
          @set="setNewValueFilterCategories"
          :model="filter.categories"
          :options="allCategories"
          :setChanged="setChanged"
          overlayTitle="Cargando categorias"
          searchOptionsPlaceholder="Buscar categorias"
          selectedOptionsPlaceholder="Buscar categorias seleccionadas"
          noOptionsText="No hay categorias para elegir"
          selectedNoOptionsText="No hay categorias seleccionadas"
          noOptionsFoundText="No se encontró la categoria"
          noSelectedOptionsFoundText="No se encontró la categoria seleccionada"
        />
      </b-col>
      <b-col md="4" class="mt-2">
        <size-chart-filter-multi-select-option
          title="Marcas"
          :index="index"
          @set="setNewValueFilterBrands"
          :model="filter.brands"
          :options="allBrands"
          :setChanged="setChanged"
          overlayTitle="Cargando marcas"
          searchOptionsPlaceholder="Buscar Marcas"
          selectedOptionsPlaceholder="Buscar marcas seleccionadas"
          noOptionsText="No hay marcas para elegir"
          selectedNoOptionsText="No hay marcas seleccionadas"
          noOptionsFoundText="No se encontró la marca"
          noSelectedOptionsFoundText="No se encontró la marca seleccionada"
        />
      </b-col>
      <b-col md="4" class="mt-2">
        <size-chart-filter-multi-select-option
          title="Géneros"
          :index="index"
          @set="setNewValueFilterGenders"
          :model="filter.genders"
          :options="allGenders"
          :setChanged="setChanged"
          overlayTitle="Cargando géneros"
          searchOptionsPlaceholder="Buscar géneros"
          selectedOptionsPlaceholder="Buscar géneros seleccionadas"
          noOptionsText="No hay géneros para elegir"
          selectedNoOptionsText="No hay géneros seleccionados"
          noOptionsFoundText="No se encontró el género"
          noSelectedOptionsFoundText="No se encontró el género seleccionado"
        />
      </b-col>
      <hr />
      <b-col md="12" class="text-right mb-3">
        <b-link class="center h6" @click="() => deleteFilter(index)">
          Eliminar
        </b-link>
      </b-col>
      <hr />
    </b-row>
    <span
      >* Si hay más de una guía de tallas que apliquen a un producto se elegirá
      una guía al azar.</span
    >
    <b-row>
      <b-col>
        <b-button
          variant="outline-info"
          @click="addFilter"
          title="Agregar filtro"
          class="mt-2 mb-2"
        >
          <span><b-icon-plus></b-icon-plus> Agregar filtro </span>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ALL_CATEGORIES from "../../graphql/AllCategories.gql";
import ALL_BRANDS from "../../graphql/AllBrands.gql";
import ALL_GENDERS from "../../graphql/AllGenders.gql";
import CREATE_SIZE_CHART from "../../graphql/SizeChart/CreateSizeChart.gql";
import UPDATE_SIZE_CHART from "../../graphql/SizeChart/UpdateSizeChart.gql";
import SizeChartFilterMultiSelectOption from "./SizeChartFilterMultiSelectOption.vue";
import BaseAlertWithCountDown from "../Base/BaseAlertWithCountDown.vue";

export default {
  name: "SizeChartTabsCentry",
  props: {
    sizeChart: {
      type: Object
    }
  },
  components: {
    SizeChartFilterMultiSelectOption,
    BaseAlertWithCountDown
  },
  data() {
    return {
      changed: false,
      saving: false,
      allCategories: null,
      allBrands: null,
      allGenders: null,
      filters: null,
      deletedFilters: [],
      sizeChartName: "",
      rows: null,
      action: null,
      saved: false,
      error: null,
      setted: {
        name: null,
        filters: null
      },
      formErrors: false
    };
  },
  mounted() {
    if (this.$route.meta.action === "Crear") {
      this.action = "crear";
    } else {
      this.action = "editar";
    }
    this.processSizeChart(this.sizeChart);
    this.loadDataCentry();
  },
  computed: {
    //Validaciones explicitas de cada campo del formulario
    validation() {
      return {
        name: {
          valid: this.sizeChartName.length > 0,
          invalidFeedback: "El nombre es obligatorio"
        },
        filters: {
          valid: this.validateFilters,
          invalidFeedback:
            "Hay filtros que estan vacios (ni categoria, ni marca, ni genero asociado)"
        }
      };
    },
    //Valida el conjunto de validaciones completo
    valid() {
      let valid = true;
      Object.keys(this.validation).forEach(x => {
        valid = valid && this.validation[x].valid;
      });
      return valid;
    },
    errorList() {
      return Object.keys(this.validation)
        .map(key => {
          return this.validation[key].valid
            ? null
            : this.validation[key].invalidFeedback;
        })
        .filter(x => x !== null);
    },
    validateFilters() {
      let ans = true;
      if (this.filters instanceof Array) {
        this.filters.forEach(filter => {
          ans = ans && this.validateSpecificFilter(filter);
        });
      }
      return ans;
    }
  },
  methods: {
    /**
     * Carga todas las categorias definidas en centry
     */
    loadAllCategories() {
      this.$getAllPages(ALL_CATEGORIES, {}, "allCategories").then(data => {
        this.allCategories = data.map(x => {
          return { value: x.node.id, label: x.node.name };
        });
      });
    },
    /**
     * Carga todas las marcas definidas en centry
     */
    loadAllBrands() {
      this.$getAllPages(ALL_BRANDS, {}, "allBrands").then(data => {
        this.allBrands = data.map(x => {
          return { value: x.node.id, label: x.node.name };
        });
      });
    },
    /**
     * Carga todos los generos definidos en centry
     */
    loadAllGenders() {
      this.$getAllPages(ALL_GENDERS, {}, "allGenders").then(data => {
        this.allGenders = data.map(x => {
          return { value: x.node.id, label: x.node.name };
        });
      });
    },
    /**
     * Agrega un nuevo filtro a la guia de talles
     */
    addFilter() {
      if (this.filters === null) {
        this.filters = [];
      }
      this.filters.push({ categories: [], brands: [], genders: [] });
      this.loadDataCentry();
    },
    /**
     * Si no se ha cargado la información de Centry y hay filtros en la guia de talles,
     * se consultan las categorias, marcas y generos
     */
    loadDataCentry() {
      if (this.filters && this.filters.length > 0) {
        if (!this.allCategories) this.loadAllCategories();
        if (!this.allBrands) this.loadAllBrands();
        if (!this.allGenders) this.loadAllGenders();
      }
    },
    /**
     * Elimina un filtro de la guia de talles
     * a partir de su posicion en el listado de filtros
     * @param {Number} index
     */
    deleteFilter(index) {
      this.deletedFilters.push(this.filters[index].id);
      this.filters.splice(index, 1);
      this.$set(this, "filters", this.filters);
    },
    /**
     * Levanta un modal para cancelar la creacion/edicion si hay cambios
     * si se cancela, se redirige al listado de guias de talles
     */
    cancel() {
      if (this.changed) {
        this.$swal
          .fire({
            title: "Cancelar",
            text: "Si cancelas, perderás tus cambios. ¿Estás seguro?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "No"
          })
          .then(result => {
            if (result.value) {
              this.$router.push("/size_charts/");
            }
          });
      } else {
        this.$router.push("/size_charts/");
      }
    },
    /**
     * Guarda informacion de la guia de talles
     * puede ser una creación o edición de guia de talles
     */
    save() {
      if (!this.validate()) return;
      this.saving = true;
      this.changed = false;
      if (this.action === "crear") {
        this.createSizeChart();
      } else {
        this.updateSizeChart();
      }
    },
    //Validar todos los campos del formulario
    validate() {
      if (!this.valid) {
        this.formErrors = true;
        return false;
      }
      this.formErrors = false;
      return true;
    },
    /**
     * Valida que un filtro especifico de la GDT contiene
     * al menos una categoria, marca o genero
     * @param {Object} filter
     * @returns {Boolean}
     */
    validateSpecificFilter(filter) {
      if (filter.categories.length > 0) return true;
      if (filter.brands.length > 0) return true;
      if (filter.genders.length > 0) return true;
      return false;
    },
    /**
     * Crea una guia de talles
     */
    createSizeChart() {
      let sizeChartInput = {
        name: this.sizeChartName,
        filters: this.getFiltersForGraphQL()
      };
      this.$apollo
        .mutate({
          mutation: CREATE_SIZE_CHART,
          variables: { create: sizeChartInput }
        })
        .then(({ data }) => {
          this.setSaved(null);
          this.$router.push(
            "/size_chart/" + data.createSizeChart.sizeChart.id + "/edit"
          );
        })
        .catch(error => {
          this.setSaved(error);
        });
    },
    /**
     * Actualiza informacion de guia de talles
     */
    updateSizeChart() {
      let sizeChartInput = {
        name: this.sizeChartName,
        filters: this.getFiltersForGraphQL()
      };
      this.$apollo
        .mutate({
          mutation: UPDATE_SIZE_CHART,
          variables: { id: this.sizeChart.id, patch: sizeChartInput }
        })
        .then(response => {
          this.setSaved(null);
          let sizeChart = response.data.updateSizeChart.sizeChart;
          this.$emit("change", sizeChart);
        })
        .catch(error => {
          this.setSaved(error);
        });
    },
    /**
     * Modifica estado al obtener un error de la peticiones de creación/edicion
     * de la guia de talles
     * @param {String} error
     */
    setSaved(error) {
      this.error = error;
      this.saving = false;
      this.saved = true;
    },
    /**
     * Construye arreglo con valores actuales de filtros para enviar en peticiones graphql
     * @returns {Array<Object>}
     */
    getFiltersForGraphQL() {
      let filtersForGraphQL = [];
      if (this.filters instanceof Array) {
        this.filters.forEach(filter => {
          filtersForGraphQL.push(this.getFilterForGraphQL(filter));
        });
        this.deletedFilters.forEach(filterId => {
          filtersForGraphQL.push({
            id: filterId
          });
        });
      }
      return filtersForGraphQL;
    },
    /**
     * Construye informacion para enviar de un filtro particular
     * @param {Object} filter
     * @param {Object}
     */
    getFilterForGraphQL(filter) {
      let filterForGraphQL = {
        id: filter.id,
        categories: filter.categories,
        brands: filter.brands,
        genders: filter.genders
      };
      return filterForGraphQL;
    },
    /**
     * Modifica estado si hay algun cambio en nombre o filtros de la guia de talles
     */
    setChanged() {
      this.changed = this.reviewValues();
    },
    /**
     * Revisa si existe algun cambio en el formulario
     * @returns {Boolean}
     */
    reviewValues() {
      if (this.sizeChartName != this.setted.name) return true;
      if (this.filters.length != this.setted.filters.length) return true;
      if (this.reviewValuesFilters()) return true;
      return false;
    },
    /**
     * Revisa si existe algun cambio en los filtros de la GDT
     * @returns {Boolean}
     */
    reviewValuesFilters() {
      let ans = false;
      this.filters.forEach((filter, idx) => {
        ans = ans || this.reviewValueFilter(filter, idx);
      });
      return ans;
    },
    /**
     * Revisa si existe algun cambio en un filtro especifico de la GDT
     * @param {Object} filter
     * @param {Number} index
     * @returns {Boolean}
     */
    reviewValueFilter(filter, index) {
      let oldFilter = this.setted.filters[index];
      if (this.reviewSpecificColumnFilter(filter, oldFilter, "categories"))
        return true;
      if (this.reviewSpecificColumnFilter(filter, oldFilter, "brands"))
        return true;
      if (this.reviewSpecificColumnFilter(filter, oldFilter, "genders"))
        return true;
      return false;
    },
    /**
     * Revisa si existe algun cambio en una columna de un filtro especifico de la GDT
     * @param {Object} filter
     * @param {Object} oldFilter
     * @param {String} column
     * @returns {Boolean}
     */
    reviewSpecificColumnFilter(filter, oldFilter, column) {
      if (filter[column].length != oldFilter[column].length) return true;
      let intersection = filter[column].filter(cat =>
        oldFilter[column].includes(cat)
      );
      return intersection.length != filter[column].length;
    },
    /**
     * Procesa informacion de la guia de talles
     * guarda nombre y filtros actuales
     * @param {Object} sizeChart
     */
    processSizeChart(sizeChart) {
      if (!sizeChart) return;
      this.sizeChartName = this.sizeChart.name;
      this.setted.name = this.sizeChart.name;

      let filters = [];
      if (sizeChart.filters) {
        sizeChart.filters.forEach(filter => {
          filters.push({
            categories: filter.categories.map(x => {
              return x.id;
            }),
            brands: filter.brands.map(x => {
              return x.id;
            }),
            genders: filter.genders.map(x => {
              return x.id;
            }),
            id: filter.id
          });
        });
      }
      this.filters = filters;
      this.setted.filters = this.$dup(filters);
    },
    /**
     * Entrega nuevo valores para un filtro especifico para marcas
     * @param {Array<String>} newValue
     * @param {Integer} index
     */
    setNewValueFilterBrands(newValue, index) {
      this.filters[index].brands = newValue;
    },
    /**
     * Entrega nuevo valores para un filtro especifico para generos
     * @param {Array<String>} newValue
     * @param {Integer} index
     */
    setNewValueFilterGenders(newValue, index) {
      this.filters[index].genders = newValue;
    },
    /**
     * Entrega nuevo valores para un filtro especifico para categorias
     * @param {Array<String>} newValue
     * @param {Integer} index
     */
    setNewValueFilterCategories(newValue, index) {
      this.filters[index].categories = newValue;
    }
  },
  watch: {
    sizeChart: function(sizeChart) {
      Object.keys(this.setted).forEach(v => (this.setted[v] = null));
      this.processSizeChart(sizeChart);
    },
    filters: {
      handler: function() {
        this.setChanged();
      },
      deep: true
    }
  }
};
</script>
