<template>
  <div>
    <ApolloQuery
      :query="require('../graphql/allDocumentInvoiceRequestResponse.gql')"
      :variables="{
        cursor: '',
        documentId: this.documentId
      }"
      :fetchPolicy="'cache-and-network'"
      clientId="apolloClientCached"
      notifyOnNetworkStatusChange
    >
      <template v-slot="{ result: { error, data }, isLoading }">
        <span v-if="data && isLoading" class="m-2 float-right">
          Actualizando lista de Invoice Request Responses...
        </span>

        <invoice-request-response-row
          v-if="data.allDocumentInvoiceRequestResponse.edges != null"
          :irr="data.allDocumentInvoiceRequestResponse.edges.map(x => x.node)"
        />

        <b-alert v-if="error" show="" variant="danger">
          {{ error.message ? error.message : "Ha ocurrido un error" }}
        </b-alert>
        <b-spinner
          v-if="isLoading"
          label="Spinning"
          class="m-2 float-right"
        ></b-spinner>
        <div v-else>
          <b-button
            v-if="hasNextPage(data)"
            class="m-2 float-right"
            @click="
              seeMore(
                query,
                data.allDocumentInvoiceRequestResponse.pageInfo.endCursor
              )
            "
          >
            Ver más
          </b-button>
          <b-alert v-else show variant="light" class="text-center">
            No hay más datos para mostrar.
          </b-alert>
        </div>
      </template>
    </ApolloQuery>
  </div>
</template>

<script>
import InvoiceRequestResponseRow from "./InvoiceRequestResponseRow.vue";
export default {
  name: "InvoiceRequestResponseList",
  props: {
    documentId: String
  },
  components: {
    InvoiceRequestResponseRow
  },
  data() {
    return {
      forceRenderEditor: 0
    };
  },
  computed: {
    alertVariant() {
      return this.error ? "danger" : "success";
    },
    alertMessage() {
      return this.error
        ? "Ha ocurrido un error guardando los datos: " + this.errorMessage
        : "Banner eliminado exitosamente";
    }
  },
  methods: {
    /**
     * Indica si hay mas elementos para cargar o no
     * @return {Boolean}
     */
    hasNextPage(data) {
      if (data?.allDocumentInvoiceRequestResponse?.edges?.length < 50) {
        return false;
      }
      return (
        data?.allDocumentInvoiceRequestResponse?.pageInfo?.endCursor != null
      );
    },
    /**
     * Trae más resultados de una query avanzando en su cursor.
     * @param {Object} query
     * @param {String} cursor
     */
    async seeMore(query, cursor) {
      await query.fetchMore({
        variables: {
          cursor: cursor
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          const updated = Object.assign({}, this.$dup(prev));
          updated.allDocumentInvoiceRequestResponse.pageInfo =
            fetchMoreResult.allDocumentInvoiceRequestResponse.pageInfo;
          if (
            fetchMoreResult?.allDocumentInvoiceRequestResponse?.edges
              ?.length !== 0
          ) {
            updated.allDocumentInvoiceRequestResponse.edges.push(
              ...fetchMoreResult.allDocumentInvoiceRequestResponse.edges
            );
          }
          return updated;
        }
      });
    }
  }
};
</script>
