<template>
  <div>
    <b-button
      @click="$bvModal.show('result-' + history.id)"
      pill=""
      variant="outline"
      v-b-tooltip.hover=""
    >
      <b-icon-eye></b-icon-eye>
    </b-button>
    <b-modal :id="'result-' + history.id" ok-disabled="" size="lg">
      <template v-slot:modal-title>
        <h4>Formulario de carga masiva</h4>
      </template>
      <template v-slot:modal-footer="{ cancel }">
        <b-button variant="info" @click="cancel">
          Cerrar
        </b-button>
      </template>
      <b-table-simple>
        <b-tr>
          <b-th>Fecha:</b-th>
          <b-td style="width: 50%">{{ date }}</b-td>
        </b-tr>
        <b-tr>
          <b-th>Planilla:</b-th>
          <b-td>{{ history.originalName }}</b-td>
        </b-tr>
        <b-tr>
          <b-th>Hoja:</b-th>
          <b-td>{{ history.sheet }}</b-td>
        </b-tr>
        <b-tr>
          <b-th>A contar de la fila:</b-th>
          <b-td>{{ history.fromRow }}</b-td>
        </b-tr>
        <b-tr>
          <b-th>¿Sincronizar después de la carga?:</b-th>
          <b-td>{{ history.synchronize === true ? "Si" : "No" }}</b-td>
        </b-tr>
        <b-tr>
          <b-th>¿Despublicar productos excluidos de las integraciones?:</b-th>
          <b-td>{{ history.unpublish === true ? "Si" : "No" }}</b-td>
        </b-tr>
        <b-tr>
          <b-th :rowspan="columns.length">Columnas cargadas:</b-th>
          <b-td>1. {{ columns[0] ? columns[0] : "-Columna Ignorada-" }}</b-td>
        </b-tr>
        <b-tr v-for="(column, index) of columnsWithoutFirst" :key="column">
          <b-td
            >{{ index + 2 }}. {{ column ? column : "-Columna Ignorada-" }}</b-td
          >
        </b-tr>
      </b-table-simple>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: "BulkUploadHistoriesTableResultModal",
  props: {
    history: Object,
    date: String
  },
  computed: {
    columns() {
      return JSON.parse(this.history.columnsName);
    },
    columnsWithoutFirst() {
      return this.columns.slice(1, this.columns.length);
    }
  }
};
</script>
<style scoped>
.table {
  margin-bottom: 0 !important;
}
.table td {
  padding: 8px 8px !important;
  height: 10px !important;
}
</style>
