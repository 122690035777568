<template>
  <div>
    <h4>Cargando datos</h4>
    <br />
    <span class="text-dark">
      <b-icon-exclamation-circle-fill></b-icon-exclamation-circle-fill>
      Advertencia: NO cierre la página. Se están enviando los datos
    </span>
    <br />
    <b-list-group>
      <b-list-group-item>
        <b-spinner label="Spinning" v-if="!sentLoad"> </b-spinner>
        <b-icon-check v-else> </b-icon-check>
        Inicio de carga
      </b-list-group-item>
      <b-list-group-item v-if="progressUpsertProducts < 1">
        <b-spinner label="Spinning"> </b-spinner>
        {{ Math.floor(progressUpsertProducts * 10000) / 100 }}% Subida de
        productos
      </b-list-group-item>
      <b-list-group-item v-else>
        <b-icon-check> </b-icon-check>
        Subida de productos
      </b-list-group-item>
      <bulk-upload-tabs-load-integration-configs
        :bulk-upload-id="bulkId"
        v-model="sentIntegrationConfigsToUpdate"
        @error="err => this.updateBulkUpload([err], {})"
        :integration-configs-to-update="this.data.integrationConfigsToUpdate"
      ></bulk-upload-tabs-load-integration-configs>
    </b-list-group>
  </div>
</template>

<script>
import UPLOAD_V3_BULK_UPLOAD from "@/graphql/UploadV3BulkUpload.gql";
import UPDATE_V3_BULK_UPLOAD from "@/graphql/UpdateV3BulkUpload.gql";
import ENQUEUE_V3_BULK_UPLOAD from "@/graphql/EnqueueV3BulkUpload.gql";
import UPSERT_PRODUCTS from "@/graphql/UpsertProducts.gql";
import BulkUploadTabsLoadIntegrationConfigs from "./BulkUploadTabsLoadIntegrationConfigs.vue";

export default {
  components: { BulkUploadTabsLoadIntegrationConfigs },
  name: "BulkUploadTabsLoad",
  props: {
    data: Object
  },
  mounted() {
    this.load();
  },
  data() {
    return {
      errors: {
        productCreate: [],
        variantCreate: []
      },
      productIds: [],
      productSkus: {},
      selectedKey: "",
      limit: 100,
      maxConcurrentRequests: 2,
      bulkId: "",
      progressUpsertProducts: 0,
      sentLoad: false,
      sentIntegrationConfigsToUpdate: false
    };
  },
  computed: {
    loading() {
      return (
        !this.sentLoad ||
        this.progressUpsertProducts < 1 ||
        !this.sentIntegrationConfigsToUpdate
      );
    },
    humanProgressUpsertProducts() {
      return (
        Math.floor(this.$ifNull(this.progressUpsertProducts, 0) * 10000) / 100
      );
    },
    productsToUpsert() {
      return this.productsToCreateArray.concat(this.productsToUpdateArray);
    },
    productsToCreateArray() {
      //7
      const productsToUpsert = [];
      this.data.productsToCreate.forEach(product => {
        product.variants = this.productSkuVariantsHash[product.sku];
        product.variants.forEach(variant => {
          variant.variantWarehouses = this.variantSkuVariantWarehouseHash[
            variant.sku
          ];
        });
        productsToUpsert.push(product);
      });
      return productsToUpsert;
    },
    /**
     * Construye un hash donde cada llave es la id de producto y
     * el valor es la informacion asociada al producto disponible
     */
    pureProductsToUpdate() {
      //6
      const idHash = {};
      this.data.productsToUpdate.forEach(prod => {
        idHash[prod.id] = prod;
      });
      this.data.variantsToUpdate.forEach(variant => {
        const prevVariant = this.data.variantsIdHash[variant.id];
        if (!idHash[prevVariant.productId]) {
          idHash[prevVariant.productId] = { id: prevVariant.productId };
        }
      });
      return Object.values(idHash);
    },
    productsToUpdateArray() {
      //5
      const productsToUpsert = [];
      this.pureProductsToUpdate.forEach(product => {
        const variants = this.productIdVariantsHash[product.id];
        if (variants) {
          product.variants = variants;
          product.variants.forEach(variant => {
            if (
              variant.id &&
              this.variantIdVariantWarehouseHash[variant.id] &&
              this.variantIdVariantWarehouseHash[variant.id].length
            ) {
              variant.variantWarehouses = this.variantIdVariantWarehouseHash[
                variant.id
              ];
              this.addOldVariantWarehouses(variant);
            } else if (
              this.variantSkuVariantWarehouseHash[variant.sku] &&
              this.variantSkuVariantWarehouseHash[variant.sku].length
            ) {
              variant.variantWarehouses = this.variantSkuVariantWarehouseHash[
                variant.sku
              ];
            }
          });
        }
        this.addOldVariants(product);
        productsToUpsert.push(product);
      });
      return productsToUpsert;
    },
    variantWarehousesHash() {
      //4
      const variantIdHash = {};
      const variantSkuHash = {};
      this.data.variantWarehousesToUpsert.forEach(variantWarehouse => {
        if (variantWarehouse.id) {
          const originalVarianWarehouse = this.variantWarehousesIdHash[
            variantWarehouse.id
          ];
          if (!variantIdHash[originalVarianWarehouse.variantId])
            variantIdHash[originalVarianWarehouse.variantId] = [];
          variantIdHash[originalVarianWarehouse.variantId].push(
            variantWarehouse
          );
        }
        if (variantWarehouse.variantSku) {
          const copy = this.$dup(variantWarehouse);
          if (!variantSkuHash[variantWarehouse.variantSku])
            variantSkuHash[variantWarehouse.variantSku] = [];
          variantSkuHash[variantWarehouse.variantSku].push(copy);
          delete copy.variantSku;
        }

        if (variantWarehouse.warehouseId) {
          const copy = this.$dup(variantWarehouse);
          if (!variantIdHash[variantWarehouse.variantId])
            variantIdHash[variantWarehouse.variantId] = [];
          variantIdHash[variantWarehouse.variantId].push(copy);
        }
      });
      return [variantIdHash, variantSkuHash];
    },
    variantIdVariantWarehouseHash() {
      return this.variantWarehousesHash[0];
    },
    variantSkuVariantWarehouseHash() {
      return this.variantWarehousesHash[1];
    },
    pureVariantsToUpdate() {
      //3
      const idHash = {};
      this.data.variantsToUpdate.forEach(variant => {
        idHash[variant.id] = variant;
      });
      this.data.variantWarehousesToUpsert.forEach(variantWarehouse => {
        if (variantWarehouse.variantId && !idHash[variantWarehouse.variantId]) {
          idHash[variantWarehouse.variantId] = {
            id: variantWarehouse.variantId
          };
        }
      });
      return Object.values(idHash);
    },
    variants() {
      return this.data.variantsToCreate.concat(this.pureVariantsToUpdate);
    },
    productVariantsHashes() {
      //2
      const productIdHash = {};
      const productSkuHash = {};
      this.variants.forEach(variant => {
        if (variant.productId) {
          if (!productIdHash[variant.productId])
            productIdHash[variant.productId] = [];
          productIdHash[variant.productId].push(variant);
        } else if (variant.id) {
          const originalVariant = this.data.variantsIdHash[variant.id];
          if (!productIdHash[originalVariant.productId])
            productIdHash[originalVariant.productId] = [];
          productIdHash[originalVariant.productId].push(variant);
        }
        if (variant.productSku) {
          const copy = this.$dup(variant);
          if (!productSkuHash[variant.productSku])
            productSkuHash[variant.productSku] = [];
          productSkuHash[variant.productSku].push(copy);
          delete copy.productSku;
        }
      });
      return [productIdHash, productSkuHash];
    },
    productIdVariantsHash() {
      return this.productVariantsHashes[0];
    },
    productSkuVariantsHash() {
      return this.productVariantsHashes[1];
    },
    variantWarehousesIdHash() {
      //1
      const hash = {};
      Object.values(this.data.variantsIdHash).forEach(variant => {
        if (variant.variantWarehouses) {
          variant.variantWarehouses.forEach(variantWarehouse => {
            hash[variantWarehouse.id] = variantWarehouse;
          });
        }
      });
      return hash;
    }
  },
  methods: {
    /**
     * Carga y procesa un archivo subido en carga masiva a centry
     */
    async load() {
      return this.uploadFile()
        .then(() => this.sendToUpsert())
        .then(() => (this.progressUpsertProducts = 1));
    },
    /**
     * Si es necesario, agrega al arreglo de varian warehouses de una variante,
     * aquellas variant warehouses que no estan siendo consideradas para la carga masiva
     * @param {Object} variant
     */
    addOldVariantWarehouses(variant) {
      if (!variant.variantWarehouses) return;
      const oldVariantWarehouses = this.data.variantsIdHash[variant.id]
        .variantWarehouses;
      if (!oldVariantWarehouses) return;
      oldVariantWarehouses.forEach(variantWarehouse => {
        if (
          !variant.variantWarehouses.find(x => x.id === variantWarehouse.id)
        ) {
          variant.variantWarehouses.push({ id: variantWarehouse.id });
        }
      });
    },
    /**
     * Si es necesario, agrega al arreglo de variantes de un producto,
     * aquellas variantes que no estan siendo consideradas para la carga masiva
     * @param {Object} product
     */
    addOldVariants(product) {
      if (!product.variants) return;
      const oldVariants = this.data.productsIdHash[product.id].variants;
      if (!oldVariants) return;
      oldVariants.forEach(variant => {
        if (!product.variants.find(x => x.id === variant.id)) {
          product.variants.push({ id: variant.id });
        }
      });
    },
    /**
     * Genera los Promise para mandar a crear la data de los productos,
     * separandolos en arreglos de un tamaño predefinido.
     */
    async sendToUpsert() {
      if (this.productsToUpsert.length !== 0) {
        let processing = [];
        const array = this.sliceData(this.limit, this.productsToUpsert);
        for await (const arrData of array) {
          const request = this.sendUpsertProducts(arrData, array.length, 0);
          processing.push(request);
          processing = await this.concurrentVacancy(processing);
        }
        return Promise.all(processing);
      }
    },
    /**
     * Manda a crear la data para actualizar los prods, si llegase a fallar
     * lo vuelve a reintentar hasta un maximo de 3 veces, esperando 5
     * segundos entre cada uno. Si falla todos los intentos se manda a crear
     * un reporte.
     * @param {Array[Object]} productsData - arreglo de data de prods.
     * @param {Int} array_length - total de particiones que tuvo el arreglo original.
     */
    async sendUpsertProducts(productsData, array_length) {
      return this.$retryMutationWithTimeout(UPSERT_PRODUCTS, {
        upsert: productsData,
        v3BulkUploadId: this.bulkId
      })
        .then(({ data }) => {
          this.progressUpsertProducts +=
            array_length > 0 ? 1 / array_length : 0;
          if (data.upsertProducts.errors.length != 0) {
            let errors = [];
            data.upsertProducts.errors.forEach(error => {
              errors.push({
                message: "Products | " + error.error,
                level: "error",
                action: "bulk_upload",
                sku: error.id
              });
            });
            this.updateBulkUpload(errors, {});
          }
        })
        .catch(async e => {
          let results = [
            {
              message: "Products | " + e.message,
              level: "error",
              action: "bulk_upload",
              sku: productsData.map(p => p.sku || p.id).join(", ")
            }
          ];
          this.updateBulkUpload(results, {});
        });
    },
    /**
     * Define cuantos procesos pueden correr en paralelo
     * @param {Array[Promise]} processing
     * @return {Array[Promise]}
     */
    async concurrentVacancy(processing) {
      return this.$concurrentVacancy(processing, this.maxConcurrentRequests);
    },
    /**
     * Divide un arreglo en varios arreglos de tamaño +limit+
     * @param {Integer} limit
     * @param {Array} data
     * @return {Array<Array>}
     */
    sliceData(limit, data) {
      let init = 0;
      const arrayData = [];
      do {
        arrayData.push(data.slice(init, init + limit));
        init = limit + init;
      } while (data.length > init);
      return arrayData;
    },
    /**
     * Sube un archivo de carga masiva a centry
     * @returns {Object}
     */
    async uploadFile() {
      const file = this.data.file;
      let fileContents = "";
      if (this.data.file !== null) {
        const readFile = file => {
          var read = new FileReader();
          return new Promise(resolve => {
            read.onload = () => {
              resolve(read.result);
            };
            read.readAsDataURL(file);
          });
        };
        const uploadFileData = async file => {
          fileContents = await readFile(file);
        };
        await uploadFileData(file);
        let bulkUploadData = {
          sheetErrors: JSON.stringify(this.data.correctedErrors),
          original: fileContents,
          originalName: this.data.file.name,
          columns: JSON.stringify(this.data.columnParams),
          fromRow: this.data.startRow,
          sheet: this.data.selectedSheet,
          synchronize: this.data.synchronize,
          unpublish: this.data.unpublishExcluded,
          productsCreateTotal: 0,
          productsUpdateTotal: 0,
          integrationConfigsUpdateTotal: this.data.integrationConfigsToUpdate
            .length
        };
        return this.$retryMutationWithTimeout(
          UPLOAD_V3_BULK_UPLOAD,
          {
            patch: bulkUploadData
          },
          this.timeoutForUpload(this.data.file)
        ).then(({ data }) => {
          if (data.uploadV3BulkUpload.errors.length === 0) {
            this.bulkId = data.uploadV3BulkUpload.bulkUpload.id;
            this.sentLoad = true;
          }
        });
      }
    },
    /**
     * Determina la cantidad de segundos que esperara la mutación
     * uploadV3BulkUpload en función del tamaño del archivo a subirse.
     * Se agregan 10 segundos extras por cada 500KB hasta un máximo
     * de 120 segundos.
     * @param {File} file
     * @return {Int}
     */
    timeoutForUpload(file) {
      const KilobyteSize = file.size / 1000;
      if (KilobyteSize >= 5500) {
        return 12 * 10000;
      }
      return (Math.trunc(KilobyteSize / 500) + 1) * 10000;
    },
    /**
     * Se manda a actualizar el progreso de la carga masiva
     * @param {Array} results
     * @param {Object} progress
     */
    updateBulkUpload(results, progress) {
      this.$retryMutationWithTimeout(UPDATE_V3_BULK_UPLOAD, {
        bulkUploadId: this.bulkId,
        body: null,
        results: results,
        progress: progress
      });
    },
    /**
     * Se manda a encolar v3BulkUploadWorker despues de enviar toda la data de la carga masiva
     */
    enqueueBulkUpload() {
      return this.$retryMutationWithTimeout(ENQUEUE_V3_BULK_UPLOAD, {
        v3BulkUploadId: this.bulkId
      });
    }
  },
  watch: {
    loading(newVal) {
      if (!newVal) {
        this.enqueueBulkUpload().then(() =>
          this.$router.push("/bulk_upload/history")
        );
      }
    }
  }
};
</script>
