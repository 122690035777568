<template>
  <b-tr v-if="product.deleting">
    <b-td colspan="3">
      <div class="bottom">{{ product.name }}</div>
      <div class="bottom">{{ brandName }}</div>
    </b-td>
    <b-td>
      <div class="bottom">{{ product.sku }}</div>
    </b-td>
    <b-td colspan="3">
      <div class="bottom">
        Este producto se está eliminando. Pronto dejará de aparecer en el
        catálogo
      </div>
    </b-td>
  </b-tr>
  <b-tr v-else>
    <b-td class="stateTd"
      ><b-icon-circle-fill
        :variant="product.status ? 'success' : 'danger'"
      ></b-icon-circle-fill
    ></b-td>
    <b-td class="centry-thumb-td">
      <b-img
        thumbnail
        :src="coverUrl"
        class="centry-thumb transparent-bg"
        alt="image"
        loading="lazy"
        v-if="coverUrl"
      ></b-img>
      <div v-else class="centry-thumb centry-no-image img-thumbnail">
        <b-icon icon="image" variant="secondary" font-scale="2"></b-icon>
      </div>
    </b-td>
    <b-td>
      <div class="top">{{ product.name }}</div>
      <div class="bottom">{{ brandName }}</div>
    </b-td>
    <b-td>
      <div class="top">{{ product.sku }}</div>
    </b-td>
    <b-td>
      <div class="bottom">{{ categoryName }}</div>
    </b-td>
    <b-td>
      <div class="top" v-if="price">{{ price }}</div>
      <div class="bottom" v-if="price">{{ priceCompare }}</div>
      <div class="top" v-else>{{ priceCompare }}</div>
    </b-td>
    <b-td class="actioncell">
      <products-table-row-actions
        :product="product"
        @deleting="$emit('deleting')"
      ></products-table-row-actions>
    </b-td>
  </b-tr>
</template>
<script>
import ProductsTableRowActions from "./ProductsTableRowActions";
export default {
  name: "ProductsTableRow",
  components: {
    ProductsTableRowActions
  },
  props: {
    product: Object
  },
  computed: {
    price() {
      if (this.product.price) {
        return "$ " + Intl.NumberFormat("de-DE").format(this.product.price);
      }
      return null;
    },
    priceCompare() {
      if (this.product.priceCompare) {
        return (
          "$ " + Intl.NumberFormat("de-DE").format(this.product.priceCompare)
        );
      }
      return null;
    },
    coverUrl() {
      if (
        this.product.assets &&
        this.product.assets.length &&
        this.product.assets[0].thumbUrl
      ) {
        return this.product.assets[0].thumbUrl;
      }
      return null;
    },
    brandName() {
      return this.product.brandName || "";
    },
    categoryName() {
      return this.product.categoryName || "";
    }
  }
};
</script>
<style scoped>
.table td {
  max-width: 100px;
}
.table .actioncell {
  max-width: 10px;
}
.centry-thumb {
  width: 70px;
  height: 70px;
  padding: 0;
}
.centry-no-image {
  text-align: center;
  background-color: #dddddd;
  padding: 23px;
}
.centry-thumb-td {
  width: 80px;
}
.stateTd {
  width: 10px;
}
</style>
