var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-header',{attrs:{"title":"Seleccionar país ","title-link":"/highlights","map":['Mercado Libre', 'Productos más vendidos'],"title-size":9}}),_c('ApolloQuery',{attrs:{"query":require('../graphql/MercadoLibreGetCountries.gql')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.result.data;
return [(!data)?_c('b-spinner',{attrs:{"label":"Spinning"}}):_c('b-row',[(_vm.redirect(data['mercadoLibreGetCountries']))?_c('b-col',{staticClass:"border-right",attrs:{"md":"12"}},[_c('h4',[_vm._v("Seleccionar país de Mercado Libre")]),_c('td'),_c('td'),_vm._l((data['mercadoLibreGetCountries']),function(label,id){return _c('b-list-group',{key:id,attrs:{"title":label,"align":"center"}},[_c('b-list-group-item',{staticClass:"flex-column col-md-2",attrs:{"to":{
                name: 'MercadoLibreHighlightsBySite',
                params: {
                  siteId: label[0]
                }
              }}},[_c('div',{staticClass:"card-image"},[_c('img',{staticStyle:{"width":"100px"},attrs:{"src":_vm.getImageUrl(label[1])}})]),_c('td'),_c('div',{staticClass:"w-100"},[_c('h3',{staticClass:"mb-1"},[_vm._v(_vm._s(label[1]))])])]),_c('td'),_c('td')],1)})],2):_c('b-col',{staticClass:"border-right",attrs:{"md":"12"}},[_c('h4',[_vm._v("No posee integraciones de Mercadolibre")])])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }