<template>
  <div
    class="shadow mb-3"
    v-observe-visibility="!isVisible ? visibilityChanged : false"
  >
    <b-card>
      <b-card-title>
        <b-row align-h="between" class="w-100 mx-0">
          <div>
            {{ product.name }}
          </div>
          <div>
            <b-spinner
              v-if="creatingCopies || addingImages || savingAssetOrder"
              small
              class="mx-1"
            />
            <b-badge variant="info">
              <div class="font-weight-bold">SKU: {{ product.sku }}</div>
            </b-badge>
          </div>
        </b-row>
      </b-card-title>
      <b-card-sub-title class="mb-2">{{
        `${$dig(product, "brand", "name") || ""} - ${$dig(
          product,
          "category",
          "name"
        ) || ""}`
      }}</b-card-sub-title>
      <b-card-body class="p-0">
        <base-skeleton-image-row v-if="loading" />
        <b-row class="mx-0" @dragover.prevent @drop="onDrop" v-else>
          <b-col cols="10">
            <b-row>
              <b-alert
                class="w-100"
                v-model="showAlert"
                dismissible
                variant="danger"
              >
                {{ alertMessage }}</b-alert
              >
            </b-row>
            <b-row>
              <draggable
                v-model="productAssets"
                tag="tbody"
                v-bind="dragOptions"
                :move="moveHandler"
                @change="handleChange"
                handle=".handle"
                style="display: flex; flex-wrap: wrap; width: 100%"
              >
                <div
                  style="width: 150px !important; height: 200px"
                  class="px-0 mr-1 mb-2"
                  v-for="(asset, position) of productAssets"
                  :key="position"
                >
                  <product-image-container
                    :asset="asset"
                    :position="position"
                    :no-drag="
                      creatingCopies || addingImages || savingAssetOrder
                    "
                    @delete-image="deleteImage"
                    @create-copies="createCopies"
                    :key="forceUpdate"
                  />
                </div>
                <b-col
                  v-if="!productAssets || productAssets.length == 0"
                  style="width: 100vw !important; height: 100px"
                  class="px-0 mr-1 mb-2"
                >
                  <b-alert
                    show
                    variant="light"
                    class="text-center"
                    style="white-space: break-spaces;"
                  >
                    <h4>
                      Arrastra tus imágenes aquí o selecciona el botón para
                      agregar imágenes.
                    </h4>
                    <p class="h1 mb-2"><b-icon icon="cloud-upload"></b-icon></p>
                  </b-alert>
                </b-col>
              </draggable>
            </b-row>
          </b-col>
          <b-col cols="2">
            <b-row align-v="center" style="height: 80%" align-h="end">
              <b-form-file
                accept="image/jpg, image/jpeg, image/png, image/gif"
                ref="pickImage"
                v-show="false"
                @change="e => uploadImages(e, true)"
                multiple
              ></b-form-file>
              <b-button
                size="lg"
                variant="outlined"
                class="p-0 focus-btn"
                v-b-tooltip.hover="'Cargar archivos al producto'"
                @click="$refs.pickImage.$el.childNodes[0].click()"
                :disabled="addingImages"
              >
                <b-icon stacked icon="cloud-arrow-up" scale="0.75" />
              </b-button>
            </b-row>
            <b-row align-h="end">
              <b-button
                size="lg"
                variant="outlined"
                class="p-0 focus-btn"
                v-b-tooltip.hover="'Ver producto'"
                :to="{
                  name: 'ProductDetail',
                  params: { id: product.id }
                }"
              >
                <b-icon stacked icon="eye" scale="0.75"></b-icon>
              </b-button>
              <b-button
                size="lg"
                variant="outlined"
                class="p-0 focus-btn"
                v-b-tooltip.hover="'Editar producto'"
                :to="{
                  name: 'ProductEdit',
                  params: { id: product.id }
                }"
              >
                <b-icon stacked icon="pencil" scale="0.60"></b-icon>
              </b-button>
              <b-button
                v-if="currentUser.role === 0"
                size="lg"
                variant="outlined"
                class="p-0 focus-btn"
                v-b-tooltip.hover="'Historial de sincronizaciones'"
                :to="{
                  name: 'ProductHistories',
                  params: { id: product.id }
                }"
              >
                <b-icon
                  stacked
                  icon="arrow-counterclockwise"
                  scale="0.60"
                ></b-icon>
              </b-button>
            </b-row>
          </b-col>
        </b-row>
      </b-card-body>
      <hr />
      <product-image-manager-variants
        v.if="($dig(product,'variants')||[]).length > 0"
        :product="product"
        :productAssets="productAssets"
        :originalProductAssets="originalProductAssets"
      />
    </b-card>
  </div>
</template>

<script>
import { centryUrl } from "../../main";
import ALL_ASSETS_BY_PRODUCT from "@/graphql/AllAssetsByProduct.gql";
import CREATE_COPIES_PRODUCT_ASSET from "@/graphql/CreateCopiesProductAsset.gql";
import CREATE_PRODUCT_ASSETS from "@/graphql/CreateProductAssets.gql";
import DELETE_PRODUCT_ASSET from "@/graphql/DeleteProductAsset.gql";
import SAVE_PRODUCT_ASSETS from "@/graphql/SaveProductAssets.gql";
import ASSET_PROCESSING from "@/graphql/AssetProcessing.gql";
import ProductImageContainer from "./ProductImageContainer.vue";
import ProductImageManagerVariants from "./ProductImageManagerVariants.vue";
import BaseSkeletonImageRow from "@/components/BaseSkeletonImageRow.vue";
import { mapState } from "vuex";
export default {
  name: "ProductImageManagerProductView",
  components: {
    ProductImageContainer,
    ProductImageManagerVariants,
    BaseSkeletonImageRow
  },
  props: {
    product: {
      type: Object,
      default() {
        return {};
      }
    },
    productToReload: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      originalProductAssets: [],
      productAssets: null,
      loading: true,
      isVisible: false,
      addingImages: false,
      savingAssetOrder: false,
      creatingCopies: false,
      showAlert: false,
      alertMessage: null,
      forceUpdate: 0,
      centryUrl
    };
  },
  async mounted() {},
  computed: {
    ...mapState(["currentUser"]),
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },
  methods: {
    /**
     * Obtiene las imagenes del prodcuto si es que el componente
     * es visible en el viewport (Solo se hace una vez)
     */
    visibilityChanged(isVisible) {
      if (isVisible) {
        this.isVisible = true;
        this.getAssetsByProduct();
      }
    },
    /**
     * Elimina una imagen dada una posicion y envia la mutacion para eliminarla
     * @param {Number} position - posicion del arreglo a eliminar
     */
    deleteImage(position) {
      this.savingAssetOrder = true;
      let asset = this.productAssets.splice(position, 1);
      this.$retryMutationWithTimeout(DELETE_PRODUCT_ASSET, {
        productId: asset[0].productId,
        assetId: asset[0].id
      }).then(({ data }) => {
        if (data.deleteProductAsset.result) {
          this.originalProductAssets = this.$dup(this.productAssets);
          this.savingAssetOrder = false;
        }
      });
    },
    /**
     * Crea copias de una imagen y envia la mutacion para crear las copias
     * @param {Number} position - posicion del arregl oa copiar
     * @param {Number} quantity - cantidadde copias a crear
     */
    createCopies(position, quantity) {
      this.creatingCopies = true;
      let elem = this.$dup(this.productAssets[position]);
      for (let i = 0; i < quantity; i++) {
        elem = this.$dup(elem);
        elem.id = "copy";
        this.productAssets.push(elem);
      }
      this.$retryMutationWithTimeout(CREATE_COPIES_PRODUCT_ASSET, {
        productId: elem.productId,
        assetId: elem.originalId,
        quantity: parseInt(quantity)
      })
        .then(({ data }) => {
          let newAssets = data.createCopiesProductAsset?.assets.map(
            asset => asset
          );
          this.productAssets = this.productAssets.map(asset => {
            if (asset.id == "copy" && asset.originalId == elem.originalId) {
              let tempAsset = newAssets.pop();
              this.copyValuesFromAsset(asset, tempAsset);
            }
            return asset;
          });
          this.creatingCopies = false;
          this.originalProductAssets = this.$dup(this.productAssets);
        })
        .catch(error => {
          let indexes = this.productAssets
            .map((asset, index) => {
              if (asset.id == "copy" && asset.originalId == elem.originalId) {
                return index;
              }
              return -1;
            })
            .filter(index => index != -1);
          indexes.forEach(index => {
            this.$set(this.productAssets[index], "errors", [error]);
          });
          this.creatingCopies = false;
        });
    },
    /**
     * Obtiene la url de un archivo para poder ver la
     * imagen mientras se guarda en la base de datos
     * @param {Object} files - objeto con los arvhicos subidos
     * @param {Boolean} isEvent - indica si los archivos vienen de un evento o no
     */
    uploadImages(files, isEvent) {
      this.addingImages = true;
      this.showAlert = false;
      if (isEvent) {
        files = files.currentTarget.files;
      }
      let areImages = this.$checkFileType(files);
      if (areImages) {
        let countFiles = files.length;
        let count = 0;
        let newAssets = [];
        Object.keys(files).forEach(async i => {
          let elem = {};
          const file = files[i];
          const reader = new FileReader();
          reader.onload = e => {
            elem["originalUrl"] = e.target.result;
            elem["id"] = "";
            elem["originalId"] = file.name;
            elem["file"] = file;
            newAssets.push(elem);
            this.productAssets.push(elem);
            count += 1;
            if (count == countFiles) {
              this.createImages(newAssets);
            }
          };
          reader.readAsDataURL(file);
        });
      } else {
        this.addingImages = false;
        this.showAlert = true;
        this.alertMessage =
          "Algunos archivos ingresados no son imágenes, por favor ingrese solo imágenes.";
      }
    },
    /**
     * Obtiene las imagenes de un producto por id
     */
    async getAssetsByProduct() {
      this.loading = true;
      this.$apollo
        .query({
          query: ALL_ASSETS_BY_PRODUCT,
          variables: {
            id: this.product.id
          },
          // Sin cache para poder actualiuzar los assets cuando cambian
          fetchPolicy: "no-cache"
        })
        .then(({ data }) => {
          this.originalProductAssets = data.product.assets.map(asset => {
            asset["originalId"] = asset.id;
            asset["productId"] = this.product.id;
            return asset;
          });
          this.productAssets = this.$dup(this.originalProductAssets);
          this.productAssets.forEach(asset => {
            this.getStatusOfImageProcessing(asset);
          });
          this.loading = false;
        });
    },
    /**
     * Maneja los archivos cuando se hace un drop
     * @param {Object} e - evento que llama la funcion
     */
    onDrop(e) {
      e.stopPropagation();
      e.preventDefault();
      if (this.addingImages) {
        this.showAlert = true;
        this.alertMessage =
          "Espere a que se terminen de subir las imágenes antes de subir otras";
      } else {
        var files = e.dataTransfer.files;
        if (files.length !== 0) {
          this.uploadImages(files, false);
        }
      }
    },
    /**
     * Se encarga de hacer la mutacion que crea las imagenes de un producto
     * @param {Array<Object>} images - lista de iamges a crear
     */
    createImages(images) {
      this.addingImages = true;
      let createImagesData = [];
      images.forEach(image => {
        createImagesData.push({
          productId: this.product.id,
          image: image.originalUrl,
          originalFilename: image.file.name
        });
      });
      this.$limitedDataExecution(
        createImagesData.map(image => [image]),
        1,
        this.executeCreateProductAssetsMutation
      ).then(() => {
        this.addingImages = false;
      });
    },
    /**
     * Ejecuta la mutación para la creación de imágenes directo al producto.
     * Se actualiza la vista dependiendo de la respuesta obtenida.
     * @param {Array<Object>} createImagesData
     */
    async executeCreateProductAssetsMutation(createImagesData) {
      await this.$retryMutationWithTimeout(
        CREATE_PRODUCT_ASSETS,
        {
          patch: createImagesData
        },
        400000
      )
        .then(({ data }) => {
          this.updateProductAssets(data.createProductAssets.productAssets);
          this.errorsProductAssets(data.createProductAssets.errors);
          this.originalProductAssets = this.productAssets;
          this.forceUpdate += 1;
        })
        .catch(error => {
          this.errorsProductAssets(
            createImagesData.map(image => {
              return {
                name: image.originalFilename,
                messages: [error]
              };
            })
          );
          this.forceUpdate += 1;
        });
    },
    /**
     * Actualiza los valores de las imágenes correctamente creadas
     * @param {Array<Object>} images
     */
    updateProductAssets(images) {
      images.forEach(image => {
        let asset = this.findProductAsset(image);
        if (asset) {
          this.copyValuesFromAsset(asset, image);
          this.getStatusOfImageProcessing(asset);
        }
      });
    },
    /**
     * Actualiza los valores de las imágenes con errores durante su creación
     * @param {Array<Object>} images
     */
    errorsProductAssets(images) {
      images.forEach(image => {
        let asset = this.findProductAsset(image);
        if (asset) {
          asset.errors = image.messages;
        }
      });
    },
    /**
     * Encuentra el asset correspondiente a la imagen entregada entre los assets
     * del producto
     * @param {Object} image
     * @return {Object}
     */
    findProductAsset(image) {
      return this.productAssets.find(asset => {
        return asset.id == "" && asset.originalId == image.name;
      });
    },
    /**
     * Guarda los cambios de las iamgenes de un producto cuando se
     * elimina una imagen, se añade una nueva (mediante drag and drop
     * desde otro producto o la galeria) o se cambian de posicion
     */
    handleChange() {
      this.savingAssetOrder = true;
      this.$retryMutationWithTimeout(SAVE_PRODUCT_ASSETS, {
        assets: this.productAssets,
        productId: this.product.id
      }).then(({ data }) => {
        this.originalProductAssets = data.saveProductAssets.newAssets.map(
          asset => {
            asset["originalId"] = asset.id;
            asset["productId"] = this.product.id;
            return asset;
          }
        );
        this.productAssets = this.$dup(this.originalProductAssets);
        this.originalProductAssets = this.productAssets;
        this.savingAssetOrder = false;
      });
    },
    /**
     * Evita que als imagenes de los productos se muevan a la galeria
     * @param {Object} evt - eventop que indica el movimiento de una imagen
     * @returns {Boolean}
     */
    moveHandler(evt) {
      return evt.to.id !== "gallery-drag";
    },
    /**
     * Se encarga de copiar ciertos valores de una imagen a otra
     * @param {Object} originalAsset - imagen a modificar
     * @param {Object} newAsset - imagen con nuevos valores
     */
    copyValuesFromAsset(originalAsset, newAsset) {
      originalAsset.id = newAsset.id;
      originalAsset.originalId = newAsset.id;
      originalAsset.originalUrl = newAsset.originalUrl;
      originalAsset.productId = this.product.id;
      originalAsset.position = newAsset.position;
      originalAsset.imageProcessing = newAsset.imageProcessing;
      originalAsset.file = null;
    },
    /**
     * Obtiene la información del proceso de guardado de imagen en aws para un
     * asset. Si se trata de duplicar una imagen que todavia no se termina de
     * procesar el servidor retorna un error.
     * @param {Object} asset
     */
    getStatusOfImageProcessing(asset) {
      this.$apollo
        .query({
          query: ASSET_PROCESSING,
          variables: {
            productId: this.product.id,
            assetId: asset.id
          },
          fetchPolicy: "no-cache"
        })
        .then(({ data }) => {
          let imageProcessing = data.asset.imageProcessing;
          if (imageProcessing) {
            setTimeout(this.getStatusOfImageProcessing, 2000, asset);
          } else {
            asset.imageProcessing = false;
          }
        });
    }
  },
  watch: {
    product() {
      this.getAssetsByProduct();
    },
    productToReload(val) {
      if (val.includes(this.product.id)) {
        this.getAssetsByProduct();
      }
    }
  }
};
</script>
<style scoped>
.focus-btn {
  color: #aab1b5;
  display: inline-block;
  background-color: white;
  width: 34px;
  height: 34px;
  text-align: center;
  border-radius: 50%;
  font-size: 14px;
  border: 2px solid #e0e2e4;
  padding: 5px 0;
}

.focus-btn:hover {
  border-color: #aab1b5;
  color: black;
}
</style>
