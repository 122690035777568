<template>
  <b-card b-col-md="12" class="m-2">
    <b-card-body>
      <b-badge
        v-for="tag of notice.tags"
        :key="tag.tag"
        variant="primary"
        pill
        class="mr-2"
        v-b-tooltip.hover.bottom="tagsTraduction[tag.type][tag.tag]"
        style="font-size: small"
        >{{ typeTraduction[tag.type] }}
      </b-badge>
      <b-card-title class="mt-2" title-tag="h2">
        <b-icon-star-fill
          v-if="notice.highlighted"
          v-b-tooltip.hover.bottom="'Importante'"
          class="text-warning"
        ></b-icon-star-fill>
        {{ notice.label }}
      </b-card-title>
      <b-card-sub-title class="my-2" title-tag="p">
        Publicado el
        {{ notice.from_date | formatDateTime }}
      </b-card-sub-title>
      <b-card-text text-tag="h4" class="my-2">
        {{ notice.description }}
      </b-card-text>
      <a
        v-for="action of notice.actions"
        :key="action.link"
        :href="action.link"
        target="_blank"
        class="card-link"
        >{{ action.text }}</a
      >
    </b-card-body>
  </b-card>
</template>

<script>
export default {
  name: "MercadoLibreNew",
  components: {},
  props: {
    notice: {
      type: Object,
      required: true
    },
    typeTraduction: {
      type: Object,
      required: true
    },
    tagsTraduction: {
      type: Object,
      required: true
    }
  },
  data() {
    return {};
  },
  methods: {}
};
</script>

<style scoped></style>
