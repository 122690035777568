<template>
  <div>
    <b-row>
      <b-col md="6">
        <b-form-datepicker
          v-model="startDate"
          :value-as-date="true"
          placeholder="Desde"
          locale="es"
        ></b-form-datepicker>
      </b-col>
      <b-col md="6">
        <b-form-datepicker
          v-model="endDate"
          :value-as-date="true"
          placeholder="Hasta"
          locale="es"
        ></b-form-datepicker>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "ChartsFilter",
  props: {
    initialStartDate: Date,
    initialEndDate: Date
  },
  data() {
    return {
      startDate: this.initialStartDate,
      endDate: this.initialEndDate
    };
  },
  watch: {
    startDate() {
      this.$emit("start-date-changed", this.startDate);
    },
    endDate() {
      this.endDate.setHours(23, 59, 59, 999);
      this.$emit("end-date-changed", this.endDate);
    }
  }
};
</script>
