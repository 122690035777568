<template>
  <b-card title="Items">
    <b-table-simple responsive>
      <b-thead>
        <b-tr>
          <b-th>Nombre</b-th>
          <b-th>Precio unitario</b-th>
          <b-th>Descuento aplicado</b-th>
          <b-th>Monto pagado</b-th>
          <b-th>Cantidad</b-th>
          <b-th>Cantidad repuesta</b-th>
          <b-th>SKU</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="item of order.items" :key="item.id">
          <b-td>{{ item.name }}</b-td>
          <b-td>{{ item.unitPrice | toCurrency }}</b-td>
          <b-td>{{ item.discountAmount | toCurrency }}</b-td>
          <b-td>{{ item.paidPrice | toCurrency }}</b-td>
          <b-td>{{ item.quantity }}</b-td>
          <b-td>{{ item.quantityRestocked }}</b-td>
          <b-td>
            <router-link
              v-if="item.variant"
              :to="{
                name: 'ProductEdit',
                params: { id: item.variant.productId }
              }"
            >
              {{ item.variant.sku }}
            </router-link>
            <p v-else class="text-muted">
              --- Artículo no registrado en Centry ---
            </p>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </b-card>
</template>

<script>
export default {
  name: "OrderItemsCard",
  props: {
    order: Object
  }
};
</script>
