<template>
  <b-modal
    v-model="showModal"
    size="lg"
    @hidden="handleHidden"
    @ok.prevent="handleOk"
  >
    <template #modal-title>
      <base-header :title="headerTitle" :titleSize="12"> </base-header>
    </template>

    <b-container fluid>
      <b-row>
        <b-spinner v-if="loadingCategories" label="Spinning"></b-spinner>
        <b-col cols="6" v-else>
          <b-form-group label="Seleccione categorias a las que pedir los datos">
            <vtex-base-promotion-products-and-skus-data-item
              v-for="category in categories"
              :key="category.id"
              :category="category"
              v-model="selectedCategories"
            />
          </b-form-group>
        </b-col>

        <b-col cols="6">
          <b-form-group
            label="Seleccione datos a obtener de las categorias escogidas"
          >
            <b-form-radio-group v-model="selectedProductsAndSkus" stacked>
              <b-form-radio
                v-show="hasProductsListener"
                :value="{
                  allProducts: true,
                  allSkus: false
                }"
              >
                Solo los productos
              </b-form-radio>

              <b-form-radio
                v-show="hasSkusListener"
                :value="{
                  allProducts: false,
                  allSkus: true
                }"
              >
                Solo los SKUs
              </b-form-radio>

              <b-form-radio
                v-show="hasProductsListener && hasSkusListener"
                :value="{
                  allProducts: true,
                  allSkus: true
                }"
              >
                Ambos
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-col>
      </b-row>

      <hr />

      <b-row>
        <b-col cols="12">
          <b-alert show="" variant="danger">
            Opciones no recomendadas. Las siguientes opciones obtienenen los
            datos desde todas las categorias de la cuenta, por lo tanto, pueden
            demorar hasta varios minutos dependiendo de la cantidad de productos
            y SKUs disponibles. Utilizar solo si es absolutamente necesario.
          </b-alert>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Seleccione datos a obtener desde todas las categorias de la cuenta"
          >
            <b-form-radio-group v-model="selectedAllProductsAndSkus" stacked>
              <b-form-radio
                v-show="hasProductsListener"
                :value="{
                  allProducts: true,
                  allSkus: false
                }"
              >
                Todos los productos
              </b-form-radio>

              <b-form-radio
                v-show="hasSkusListener"
                :value="{
                  allProducts: false,
                  allSkus: true
                }"
              >
                Todos los SKUs
              </b-form-radio>

              <b-form-radio
                v-show="hasProductsListener && hasSkusListener"
                :value="{
                  allProducts: true,
                  allSkus: true
                }"
              >
                Ambos
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>

    <template #modal-footer="{ cancel, ok }">
      <b-button-group>
        <b-button variant="outline-info" @click="cancel">
          <b-icon-x-circle></b-icon-x-circle> Cancelar
        </b-button>

        <b-button variant="info" @click="ok" :disabled="!hasDataSelected">
          <span v-if="loadingProducts || loadingSkus"
            ><b-spinner label="Spinning"></b-spinner
          ></span>
          <span v-else
            ><b-icon-cloud-download></b-icon-cloud-download>Obtener</span
          >
        </b-button>
      </b-button-group>
    </template>
  </b-modal>
</template>

<script>
import INTEGRATION_CONFIG_VTEX_ALL_PRODUCTS_AND_SKUS from "@/graphql/IntegrationConfigVtexAllProductsAndSkus.gql";
import GET_VTEX_CATEGORIES from "@/graphql/AllVtexCategories.gql";

import VtexBasePromotionProductsAndSkusDataItem from "./VtexBasePromotionProductsAndSkusDataItem";
import BaseHeader from "@/components/BaseHeader.vue";

const emptySelected = {
  allProducts: false,
  allSkus: false
};

export default {
  name: "VtexBasePromotionProductsAndSkusDataModal",
  components: {
    VtexBasePromotionProductsAndSkusDataItem,
    BaseHeader
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    vtexIntegrationConfigId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      categories: [],
      loadingCategories: true,
      selectedCategories: [],
      selectedProductsAndSkus: emptySelected,
      selectedAllProductsAndSkus: emptySelected,
      showModal: null,
      loadingProducts: false,
      loadingSkus: false
    };
  },
  async mounted() {
    this.getVtexCategories();
  },
  methods: {
    /**
     * Se encarga de manejar el cierre del modal
     */
    handleHidden() {
      this.selectedCategories = [];
      this.selectedProductsAndSkus = emptySelected;
      this.selectedAllProductsAndSkus = emptySelected;
      this.$emit("input", false);
    },
    /**
     * Se encarga de manejar la acción de obtener los datos al presionar el botón
     */
    async handleOk() {
      this.loadingProducts = true;
      this.loadingSkus = true;
      await this.getAllProductsAndSkus();
      this.handleHidden();
    },
    /**
     * Obtiene las categorias disponibles en la cuenta
     */
    async getVtexCategories() {
      return this.$apollo
        .query({
          query: GET_VTEX_CATEGORIES,
          variables: {
            ic: this.vtexIntegrationConfigId,
            limit: 100,
            offset: 0
          }
        })
        .then(({ data }) => {
          this.categories = data.allVtexCategories;
          this.loadingCategories = false;
        });
    },
    /**
     * Obtiene los productos y skus de Vtex, luego emite el resultado
     * al componente padre si corresponde.
     */
    async getAllProductsAndSkus() {
      return this.$apollo
        .query({
          query: INTEGRATION_CONFIG_VTEX_ALL_PRODUCTS_AND_SKUS,
          variables: {
            id: this.vtexIntegrationConfigId,
            allProducts:
              this.selectedProductsAndSkus.allProducts ||
              this.selectedAllProductsAndSkus.allProducts,
            allSkus:
              this.selectedProductsAndSkus.allSkus ||
              this.selectedAllProductsAndSkus.allSkus,
            categories: this.cleanedSelectedCategories
          }
        })
        .then(({ data }) => {
          this.emitProductsAndSkusData(data);
        })
        .then(() => {
          this.loadingProducts = false;
          this.loadingSkus = false;
        });
    },
    /**
     * A partir de los datos obtenidos, se emite al componente padre los products
     * y skus en formato de objetos para un selector
     * @param {Object} data
     */
    emitProductsAndSkusData(data) {
      let products = data?.integrationConfigVtex?.allProductsAndSkusByCategories?.products?.map(
        product => ({
          value: product.id,
          label: product.name + " (Producto " + product.id + ")"
        })
      );
      if (this.hasProductsListener) {
        this.$emit("products", products);
      }
      let skus = data?.integrationConfigVtex?.allProductsAndSkusByCategories?.skus?.map(
        sku => ({
          value: sku.id,
          label: sku.name + " (SKU " + sku.id + ")"
        })
      );
      if (this.hasSkusListener) {
        this.$emit("skus", skus);
      }
    },
    /**
     * Se limpian las categorias seleccionadas antes de hacer la consulta de
     * productos y skus. Si existe el id de una categoria padre, se eliminan
     * todas los ids de las categorias hijas, para no duplicar los datos traslapados.
     * @returns {Array} - Lista de ids de categorias
     */
    cleanSelectedCategories() {
      let idsChildrensPerCategory = this.getIdsChildrenForAllCategories();
      let selectedCategories = [...this.selectedCategories];
      Object.entries(idsChildrensPerCategory).forEach(([id, childrens]) => {
        if (selectedCategories.includes(id)) {
          selectedCategories = selectedCategories.filter(id => {
            return !childrens.includes(id);
          });
        }
      });
      return selectedCategories;
    },
    /**
     * Se obtiene un objeto con los ids de las categorias hijas para cada categoria.
     * El listado para cada categoria incluye los ids de sus propias categorias
     * hijas y de las hijas de sus hijas (recursivo)
     * @returns {Object} - Objeto con los ids de las categorias hijas
     */
    getIdsChildrenForAllCategories() {
      let idsChildrenDeepPerCategory = {};
      let actualCategories = this.categories;
      while (actualCategories.length > 0) {
        actualCategories.forEach(category => {
          idsChildrenDeepPerCategory[
            category.id
          ] = this.getIdsChildrenCategoriesDeep(category);
        });
        actualCategories = this.calculateNextCategories(actualCategories);
      }
      return idsChildrenDeepPerCategory;
    },
    /**
     * Se obtiene una lista de ids de categorias hijas para una categoria
     * @param {Object} category - Categoria a la que se le obtiene los ids
     * @returns {Array} - Lista de ids de categorias hijas (recursivo)
     */
    getIdsChildrenCategoriesDeep(category) {
      let ids = [];
      if (category.children.length > 0) {
        category.children.forEach(child => {
          ids.push(child.id);
          ids = ids.concat(this.getIdsChildrenCategoriesDeep(child));
        });
      }
      return ids;
    },
    /**
     * Obtiene el listado de todas las categorias hijas de una lista de categorias
     * @param {Array} categories - Lista de categorias
     * @returns {Array} - Lista de categorias hijas las categorias entregadas
     */
    calculateNextCategories(actualCategories) {
      return actualCategories
        .map(category => {
          if (category.children.length > 0) {
            return category.children;
          }
          return [];
        })
        .flat();
    }
  },
  computed: {
    /**
     * Calcula las categorias seleccionadas correspondientes segun
     * las opciones utilizadas en el componente
     * @returns {Array} - Lista de ids de categorias seleccionadas
     */
    cleanedSelectedCategories() {
      if (
        Object.values(this.selectedProductsAndSkus).every(
          value => value === false
        )
      ) {
        return [];
      }
      return this.cleanSelectedCategories();
    },
    /**
     * Verifica si existe el evento de escuchar productos
     * @returns {Boolean} - True si existe el evento, false si no
     */
    hasProductsListener() {
      return this.$listeners && this.$listeners.products;
    },
    /**
     * Verifica si existe el evento de escuchar skus
     * @returns {Boolean} - True si existe el evento, false si no
     */
    hasSkusListener() {
      return this.$listeners && this.$listeners.skus;
    },
    /**
     * Calcula el titulo del modal segun los eventos recibidos
     * @returns {String} - Titulo del modal
     */
    headerTitle() {
      if (this.hasProductsListener && !this.hasSkusListener) {
        return "Selección de productos";
      }
      if (this.hasSkusListener && !this.hasProductsListener) {
        return "Selección de SKUs";
      }
      return "Selección de productos y/o SKUs";
    },
    hasDataSelected() {
      if (
        this.selectedAllProductsAndSkus.allProducts ||
        this.selectedAllProductsAndSkus.allSkus
      ) {
        return true;
      }
      if (
        this.selectedProductsAndSkus.allProducts ||
        this.selectedProductsAndSkus.allSkus
      ) {
        return true;
      }
      return false;
    }
  },
  watch: {
    value(newValue) {
      this.showModal = newValue;
    },
    /**
     * Si se selecciono alguna opción de selectedProductsAndSkus se borran
     * las selecciones de selectedAllProductsAndSkus
     */
    selectedProductsAndSkus(newSelected) {
      if (Object.values(newSelected).some(value => value === true)) {
        this.selectedAllProductsAndSkus = emptySelected;
      }
    },
    /**
     * Si se selecciono alguna opción de selectedAllProductsAndSkus se borran
     * las selecciones de selectedProductsAndSkus
     */
    selectedAllProductsAndSkus(newSelected) {
      if (Object.values(newSelected).some(value => value === true)) {
        this.selectedProductsAndSkus = emptySelected;
      }
    }
  }
};
</script>
