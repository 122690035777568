<!-- eslint-disable prettier/prettier -->
<template>
  <div>
    <base-header>
      <template v-slot:actions>
        <b-container>
           <b-spinner label="Spinning" v-if="loadingIntegrationConfigInfo || !show_filters"></b-spinner>
          <b-button-group v-else>
            <b-button :to="`/jumpseller/${ic.id}/promotion/new`" variant="outline-info">
              <b-icon-plus></b-icon-plus>
              Nuevo
            </b-button>
            <b-dropdown right variant="outline-info">
              <b-dropdown-item target="_blank" :href="jumpsellerPromotionForm()">
                Promoción de envío
                <b-icon-box-arrow-up-right></b-icon-box-arrow-up-right>
              </b-dropdown-item>
            </b-dropdown>
          </b-button-group>
        </b-container>
      </template>
      <template v-slot:filters v-if="false">
        <products-simple-filter></products-simple-filter>
        <products-filters></products-filters>
      </template>
    </base-header>
    <b-container fluid="">
      <b-row>
        <b-col md="12">
          <b-card class="mb-2" no-body>
            <promotions-index-jumpseller :ic="ic" @change="showAllFilters" :store_code="store_code"></promotions-index-jumpseller>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import BaseHeader from "../../components/BaseHeader.vue";
import PromotionsIndexJumpseller from "../../components/jumpseller/promotions/index/PromotionsIndexJumpseller.vue";
import ProductsFilters from "../../components/jumpseller/promotions/PromotionsFilters.vue";
import ProductsSimpleFilter from "../../components/jumpseller/promotions/PromotionsSimpleFilter.vue";
import INTEGRATION_CONFIG_JUMPSELLER from "../../graphql/IntegrationConfigJumpseller.gql";

import { centryUrl } from "../../main";

export default {
  name: "Promotions",
  components: {
    BaseHeader,
    PromotionsIndexJumpseller,
    ProductsFilters,
    ProductsSimpleFilter
  },
  data() {
    return {
      centryUrl,
      show_filters: false,
      store_code: "",
      loadingIntegrationConfigInfo: true
    };
  },
  props: {
    ic: Object
  },
  async created() {
    await this.awaitForStoreCode();
  },
  methods: {
    change() {
      this.changed = true;
    },
    showAllFilters() {
      this.show_filters = true;
    },
    //Función que consulta por el store code de la integración actual
    async awaitForStoreCode() {
      await this.$apollo
        .query({
          query: INTEGRATION_CONFIG_JUMPSELLER,
          variables: {
            id: this.ic.id
          }
        })
        .then(({ data }) => {
          if (
            data &&
            data.integrationConfigJumpseller &&
            data.integrationConfigJumpseller.storeCode
          ) {
            this.store_code = data.integrationConfigJumpseller.storeCode;
          }
          this.loadingIntegrationConfigInfo = false;
        });
    },
    jumpsellerPromotionForm() {
      return `https://${this.store_code}.jumpseller.com/admin/cl/promotions/edit/0`;
    }
  }
};
</script>
