<template>
  <div>
    <base-header
      title="Editar atributo de categoría"
      :title-link="centryUrl + '/admin/category_attributes'"
      :map="['Editar']"
    >
    </base-header>
    <b-container fluid="">
      <b-row>
        <b-col md="12">
          <ApolloQuery
            :query="require('../../graphql/AdminCategoryAttribute.gql')"
            :variables="{ id: $route.params.id }"
          >
            <template slot-scope="{ result: { data } }">
              <category-attribute-form
                v-if="data"
                :category-attribute="data.adminCategoryAttribute"
                @change="updateCategoryAttribute"
              ></category-attribute-form>
              <b-spinner label="Spinning" v-else></b-spinner>
            </template>
          </ApolloQuery>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import BaseHeader from "@/components/BaseHeader";
import CategoryAttributeForm from "@/components/CategoryAttributeForm";
import { centryUrl } from "@/main";
import UPDATE_CATEGORY_ATTRIBUTE from "@/graphql/UpdateCategoryAttribute.gql";

export default {
  name: "CategoryAttributeEdit",
  components: {
    CategoryAttributeForm,
    BaseHeader
  },
  data() {
    return {
      centryUrl
    };
  },
  methods: {
    updateCategoryAttribute(categoryAttributeData) {
      const id = categoryAttributeData.id;
      const attr = Object.assign({}, categoryAttributeData);
      delete attr.id;
      this.$apollo
        .mutate({
          mutation: UPDATE_CATEGORY_ATTRIBUTE,
          variables: {
            id: id,
            patch: attr
          }
        })
        .then(({ data }) => {
          if (data && data.updateCategoryAttribute) {
            this.$swal.fire({
              title: "Actualización exitosa",
              text: "El atributo ha sido actualizado con éxito",
              icon: "success",
              showCancelButton: false
            });
          }
        })
        .catch(error => {
          this.$swal.fire({
            title: "Error",
            text: "El atributo no ha podido actualizarse por: " + error,
            icon: "error",
            showCancelButton: false
          });
        });
    }
  }
};
</script>
