<template>
  <div>
    <base-header
      title="Pedidos Mercado Envios 1"
      :map="['Mercado Libre', 'Integraciones']"
      :title-size="6"
    >
      <template v-slot:filters>
        <b-button @click="updateTracking()" variant="info"
          >Actualizar estado de Pedidos</b-button
        >
      </template>
    </base-header>
    <b-container fluid="">
      <b-row>
        <b-col md="12">
          <template>
            <div>
              <b-skeleton-table
                v-if="label == ''"
                :rows="10"
                :columns="4"
                :table-props="{ hover: true }"
              ></b-skeleton-table>
              <ApolloQuery
                v-else
                :query="
                  require('../../../graphql/MercadoLibre/Me1/AllOrdersMe1.gql')
                "
                :variables="queryVariables('')"
                :fetchPolicy="'cache-and-network'"
                clientId="apolloClientCached"
              >
                <template
                  slot-scope="{ result: { error, data }, query, isLoading }"
                >
                  <!-- Loading -->
                  <b-spinner
                    v-if="!data && (isLoading || error)"
                    label="Spinning"
                  ></b-spinner>
                  <!-- Error -->
                  <b-alert v-else-if="error" show="" variant="danger">
                    Ha ocurrido un error
                  </b-alert>
                  <span v-else-if="data && isLoading" class="m-2 float-right">
                    Actualizando lista de pedidos...
                  </span>
                  <!-- Result -->
                  <b-table-simple v-if="data && data.allOrders" hover>
                    <b-thead>
                      <b-tr>
                        <b-th></b-th>
                        <b-th>N° de Orden</b-th>
                        <b-th>Codigo de Seguimiento</b-th>
                        <b-th>Estado</b-th>
                        <b-th>Comentario</b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr v-if="!data.allOrders.edges.length">
                        <b-td colspan="11">No tienes pedidos registrados</b-td>
                      </b-tr>
                      <me1-table-row
                        v-for="order of data.allOrders.edges"
                        :key="order.node.id"
                        :order="order.node"
                        ref="row"
                      ></me1-table-row>
                    </b-tbody>
                  </b-table-simple>
                  <b-spinner
                    v-if="isLoading"
                    label="Spinning"
                    class="m-2 float-right"
                  ></b-spinner>
                  <div v-else>
                    <b-button
                      v-if="hasNextPage(data)"
                      class="m-2 float-right"
                      @click="seeMore(query, data.allOrders.pageInfo.endCursor)"
                    >
                      Ver más
                    </b-button>
                    <b-alert v-else show variant="light" class="text-center">
                      No hay más datos para mostrar.
                    </b-alert>
                  </div>
                </template>
              </ApolloQuery>
            </div>
          </template>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import BaseHeader from "../../../components/BaseHeader.vue";
import INTEGRATION_CONFIG_MERCADO_LIBRE from "../../../graphql/IntegrationConfigMercadoLibre.gql";
import Me1TableRow from "../../../views/MercadoLibre/Me1/Row.vue";
export default {
  components: {
    BaseHeader,
    Me1TableRow
  },
  props: {
    integrationConfigId: {
      type: String
    }
  },
  data() {
    return {
      label: ""
    };
  },
  mounted() {
    this.queryLabel();
  },
  methods: {
    /**
     * Actualiza el tracking de cada una de las ordenes listadas
     */
    updateTracking() {
      this.$refs["row"].forEach(async r => {
        await r.updateTracking();
      });
    },
    /**
     * Consulta el label del Integration Config id
     * @method
     */
    queryLabel() {
      let label = this.labelIntegrationConfig(this.integrationConfigId);
      Promise.resolve(label).then(label => {
        this.label = label;
      });
    },
    /**
     * Se encarga de crear un objeto con
     * las variables para la query
     * @method
     * @param {String} cursor
     * @param {Object} variables
     */ queryVariables(cursor, variables = {}) {
      if (cursor) {
        variables.cursor = cursor;
      }
      variables.ownShipment = true;
      variables.statuses = ["pending"];
      variables.origins = [this.label];

      return variables;
    },
    /**
     * Consulta el label de la integracion
     * @param {String} ic_id
     * @return {Boolean}
     */
    async labelIntegrationConfig(ic_id) {
      let result = await this.$apollo.query({
        query: INTEGRATION_CONFIG_MERCADO_LIBRE,
        variables: { id: ic_id }
      });
      result = await result.data.integrationConfigMercadoLibre.label;
      return result;
    },
    /**
     * Indica si hay una página siguiente en la query de GraphQl basado
     * exclusivamente en la existencia de un cursor.
     * @return {boolean}
     */ hasNextPage(data) {
      return data?.allOrders?.pageInfo?.endCursor != null;
    },
    /**
     * Trae más resultados de una query avanzando en su cursor.
     * @param {Object} query
     * @param {String} cursor
     */
    async seeMore(query, cursor) {
      await query.fetchMore({
        variables: this.queryVariables(cursor),
        updateQuery: (prev, { fetchMoreResult }) => {
          const updated = Object.assign({}, this.$dup(prev));
          updated.allOrders.pageInfo = fetchMoreResult.allOrders.pageInfo;
          if (fetchMoreResult?.allOrders?.edges?.length !== 0) {
            updated.allOrders.edges.push(...fetchMoreResult.allOrders.edges);
          }
          return updated;
        }
      });
    }
  }
};
</script>
