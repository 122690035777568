<template>
  <div>
    <b-row>
      <b-col v-if="['regular', 'forThePriceOf'].indexOf(promotion.type) != -1">
        <b-form-checkbox
          v-model="isDifferentListPriceAndPrice"
          :value="false"
          :unchecked-value="true"
        >
          Precio "de" y "a" son los mismos
        </b-form-checkbox>
        <b-form-checkbox
          v-model="isDifferentListPriceAndPrice"
          :value="true"
          :unchecked-value="false"
        >
          Precio "de" y "a" son diferentes
        </b-form-checkbox>
      </b-col>

      <b-col>
        <b-form-checkbox v-model="restrictionBIN">
          Restricción de promoción por BIN
        </b-form-checkbox>

        <div v-for="(bin, index) in restrictionsBINs" :key="index">
          <b-row>
            <b-col>
              <b-form-group label="Valor">
                <b-form-input
                  v-model="restrictionsBINs[index]"
                  placeholder="6 dígitos (Ej: 123456)"
                  :disabled="!restrictionBIN"
                >
                </b-form-input>
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group label="Eliminar">
                <b-button
                  variant="outline-info"
                  @click="deleteRestrictionBIN(index)"
                  :disabled="!restrictionBIN"
                >
                  <b-icon-x-circle></b-icon-x-circle>
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </div>

        <b-button
          v-show="restrictionBIN"
          variant="outline-info"
          @click="addRestrictionBIN"
        >
          <b-icon-plus></b-icon-plus> Agregar BIN
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "VtexBasePromotionPriceListAndBin",
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      promotion: this.value,
      isDifferentListPriceAndPrice: this.value.isDifferentListPriceAndPrice,
      restrictionBIN: this.value.restrictionsBins.length > 0,
      restrictionsBINs: this.value.restrictionsBins
    };
  },
  methods: {
    /**
     * Añade una nuevo BIN a la lista de BINs de la promoción.
     */
    addRestrictionBIN() {
      this.restrictionsBINs.push("");
    },
    /**
     * Elimina un BIN de la lista de BINs de la promoción.
     * @param {Number} index
     */
    deleteRestrictionBIN(index) {
      this.restrictionsBINs.splice(index, 1);
    }
  },
  watch: {
    value(newValue) {
      this.promotion = newValue;
    },
    promotion: {
      handler(newValue) {
        this.$emit("input", newValue);
      },
      deep: true
    },
    isDifferentListPriceAndPrice(newValue) {
      this.promotion.isDifferentListPriceAndPrice = newValue;
    },
    restrictionBIN() {
      if (this.restrictionBIN) {
        this.promotion.restrictionsBins = this.restrictionsBINs;
      } else {
        this.promotion.restrictionsBins = [];
      }
    },
    restrictionsBINs() {
      this.promotion.restrictionsBins = this.restrictionsBINs;
    }
  }
};
</script>
