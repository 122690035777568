<template>
  <div>
    <!-- Exito -->
    <b-alert v-if="success" show="" variant="success" class="m-2" dismissible>
      {{ success_msg }}
    </b-alert>
    <!-- Error -->
    <b-alert v-if="error" show="" variant="danger" class="m-2">
      {{ error_msg }}
    </b-alert>
    <!-- Result -->
    <b-table-simple hover>
      <b-thead>
        <b-tr>
          <b-th class="text-left">Estado</b-th>
          <b-th>Nombre</b-th>
          <b-th>cupón(es)</b-th>
          <b-th>Veces Usado</b-th>
          <b-th>Acumulativa</b-th>
          <b-th>Descuento</b-th>
          <b-th> </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-if="allPromotions && !allPromotions.length && !isLoading">
          <b-td colspan="11">No tienes promociones registradas</b-td>
        </b-tr>

        <promotions-tabs-jumpseller-row
          v-else-if="allPromotions"
          v-for="promotion of allPromotions"
          :ic="ic"
          :key="promotion.id"
          :promotion="promotion"
          :store_code="store_code"
          :today="new Date()"
          @showModal="showModal"
        />
        <b-modal
          class="w-50"
          ref="my-modal"
          centered
          hide-header-close
          no-close-on-backdrop
        >
          <template #modal-title>
            <h3>Eliminar Promoción</h3>
          </template>
          ¿Estás seguro que quieres borrar la promoción seleccionada?
          <template #modal-footer>
            <b-button
              class="mt-3"
              variant="danger"
              @click="deletePromotion"
              v-if="!isLoading"
            >
              Eliminar
            </b-button>
            <div class="m-4" v-else>
              <b-spinner></b-spinner>
            </div>
            <b-button
              class="mt-3"
              variant="outline-secondary"
              @click="hideModal"
              :disabled="isLoading"
            >
              Cerrar
            </b-button>
          </template>
        </b-modal>
      </b-tbody>
    </b-table-simple>
    <b-spinner
      v-if="isLoading"
      label="Spinning"
      class="m-2 float-right"
    ></b-spinner>
    <div v-else>
      <b-button v-if="hasNextPage()" class="m-2 float-right" @click="seeMore()">
        Ver más
      </b-button>
      <b-alert v-else show variant="light" class="text-center">
        No hay más datos para mostrar.
      </b-alert>
    </div>
  </div>
</template>
<script>
import PromotionsTabsJumpsellerRow from "./PromotionsIndexJumpsellerRow.vue";
import GET_ALL_PROMOTIONS from "../../../../graphql/AllPromotionsJumpseller.gql";
import DELETE_PROMOTION from "../../../../graphql/integrations/jumpseller/DeletePromotion.gql";
export default {
  name: "PromotionsIndexJumpseller",
  components: { PromotionsTabsJumpsellerRow },
  model: {
    event: "change"
  },
  props: {
    ic: Object,
    params: {
      type: Object,
      default() {
        return {};
      }
    },
    store_code: String
  },
  data() {
    return {
      page: 1,
      limit: 25,
      allPromotions: [],
      error_msg: "",
      error: false,
      isLoading: true,
      currentPromotionId: null,
      success: false,
      success_msg: ""
    };
  },
  async created() {
    await this.awaitForPromotions();
  },
  computed: {},
  methods: {
    /**
     * Permite mostrar la ventana para confirmar o no la eliminacion
     * de la promocion seleccionada en el listado
     * se aprovecha de guardar en el estado la id de la promoción candidata a ser eliminada
     * @param {Integer}
     */
    showModal(id) {
      this.currentPromotionId = id;
      this.$refs["my-modal"].show();
    },
    /**
     * Esconde la ventana de eliminación
     * la promocion seleccionada pasa a ser nula
     */
    hideModal() {
      this.currentPromotionId = null;
      this.$refs["my-modal"].hide();
    },
    /**
     * Método que permite eliminar una promoción en Jumpseller
     */
    async deletePromotion() {
      this.error = false;
      this.isLoading = true;
      this.success = false;
      this.success_msg = "";
      try {
        await this.$apollo
          .mutate({
            mutation: DELETE_PROMOTION,
            variables: {
              ic_id: this.ic.id,
              promotion_id: this.currentPromotionId
            }
          })
          .then(({ data }) => {
            if (this.$dig(data, "jumpsellerDeletePromotion", "message")) {
              if (data.jumpsellerDeletePromotion.message == "OK") {
                this.success = true;
                this.success_msg = "Promoción eliminada exitosamente";
                this.isLoading = false;
                this.allPromotions = this.allPromotions.filter(
                  promotion => promotion.id !== this.currentPromotionId
                );
              } else {
                this.error = true;
                this.isLoading = false;
              }
            } else {
              this.error = true;
              this.isLoading = false;
            }
          });
      } catch (error) {
        this.error_msg = error;
        this.error = true;
      }
      this.hideModal();
    },
    /**
     * Construye formato de variables para consultar por el listado de promociones
     * @return {object} objeto con variables
     */
    queryVariables() {
      const variables = {};
      variables.ic_id = this.ic.id;
      variables.page = this.page;
      variables.limit = this.limit;

      return variables;
    },
    /**
     * Método que consulta por el listado de promociones según variables
     */
    async awaitForPromotions() {
      this.error = false;
      try {
        await this.$apollo
          .query({
            query: GET_ALL_PROMOTIONS,
            variables: this.queryVariables()
          })
          .then(({ data }) => {
            if (this.$dig(data, "jumpsellerGetPromotions")) {
              this.currentPromotions = data.jumpsellerGetPromotions;
              this.allPromotions = data.jumpsellerGetPromotions;
              this.isLoading = false;
            }
            this.loadingPromotions = false;
            this.$emit("change");
          });
      } catch (error) {
        this.error_msg = error;
        this.error = true;
      }
    },
    /**
     * Indica si es posible continuar con la consulta sobre el listado de promociones
     * se verifica que a que el tamaño de la consulta no sea menor al limit señalado
     * @return {boolean}
     */
    hasNextPage() {
      const not_minus_that_limit = this.currentPromotions.length === this.limit;
      return not_minus_that_limit;
    },
    /**
     * Permite continuar con el paginamiento en el listado de promociones
     */
    async seeMore() {
      this.page += 1;
      this.error = false;
      this.isLoading = true;
      try {
        await this.$apollo
          .query({
            query: GET_ALL_PROMOTIONS,
            variables: this.queryVariables()
          })
          .then(({ data }) => {
            if (this.$dig(data, "jumpsellerGetPromotions")) {
              this.currentPromotions = data.jumpsellerGetPromotions;
              this.allPromotions = this.allPromotions.concat(
                data.jumpsellerGetPromotions
              );
              this.isLoading = false;
            }
            this.loadingPromotions = false;
          });
      } catch (error) {
        this.error_msg = error;
        this.error = true;
      }
    }
  },
  watch: {}
};
</script>

<style scoped>
.btn-sm {
  margin: 5px;
}
</style>
