<template>
  <div>
    <b-row>
      <b-col md="12" class="ml-auto">
        <b-button-group v-if="isActive">
          <b-button variant="outline-info" @click="cancel">
            <b-icon-x-circle></b-icon-x-circle> Cancelar
          </b-button>
          <b-button
            variant="info"
            :disabled="!changed || saving"
            :title="changed ? '' : 'Debe completar todos los pasos'"
            v-b-tooltip.hover=""
            @click="save"
          >
            <span v-if="saving"><b-spinner label="Spinning"></b-spinner></span>
            <span v-else>
              <b-icon-cloud-upload></b-icon-cloud-upload>Guardar
            </span>
          </b-button>
        </b-button-group>
        <b-alert
          v-model="saved"
          :variant="error ? 'danger' : 'success'"
          dismissible
        >
          {{ error ? "Error: " + error : "Cambios guardados con éxito" }}
        </b-alert>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-spinner label="Spinning" v-if="loading"></b-spinner>
        <p v-else-if="!loading && !isActive">
          Guia de tallas no está disponible para este país
        </p>
        <base-accordion v-else id="mainAccordionMercadoLibre" class="mt-2">
          <base-collapse-card
            id="accordion-1"
            accordion="mainAccordionMercadoLibre"
            :visible="true"
            buttonVariant="primary"
            class="overflow-visible"
            @show="hideHomologating"
          >
            <template slot="title">
              Selección Tabla de tallas
            </template>
            <slot>
              <span class="font-weight-bold" v-if="sizeChartMercadoLibre">
                Seleccionada ahora: {{ sizeChartName }}
              </span>
              <br />
              <span class="font-weight-bold" v-if="!!sizeChartMercadoLibre">
                Número de filas en la GDT: {{ numberOfRows }}
              </span>
              <br />
              <b-card
                :title="sizeChartMercadoLibre ? 'Modificar' : 'Seleccionar'"
              >
                <size-chart-mercado-libre-selector
                  v-if="siteCode"
                  v-model="sizeChartMercadoLibre"
                  :site-code="siteCode"
                  @change="setSizeChartMercadoLibre"
                  :integration-config-id="integrationConfig.id"
                ></size-chart-mercado-libre-selector>
              </b-card>
            </slot>
          </base-collapse-card>
          <base-collapse-card
            id="accordion-2"
            accordion="mainAccordionMercadoLibre"
            :visible="homologating"
            buttonVariant="primary"
            class="overflow-visible"
            :disabled="!sizeChartMercadoLibre"
          >
            <template slot="title">
              Homologación de tallas
            </template>
            <slot>
              <span class="font-weight-bold" v-if="!!sizeChartMercadoLibre">
                Seleccionada ahora: {{ sizeChartName }}
              </span>
              <br />
              <span class="font-weight-bold" v-if="!!sizeChartMercadoLibre">
                Número de filas en la GDT: {{ numberOfRows }}
              </span>
              <br />
              <size-chart-mercado-libre-homologator
                :size-chart="sizeChartMercadoLibre"
                v-if="!loading && !!sizeChartMercadoLibre"
                v-model="homologations"
                @change="setNewHomologations"
                :centry-sizes="centrySizes"
              ></size-chart-mercado-libre-homologator>
            </slot>
          </base-collapse-card>
        </base-accordion>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import SizeChartMercadoLibreHomologator from "./SizeChartMercadoLibreHomologator.vue";
import SizeChartMercadoLibreSelector from "./SizeChartMercadoLibreSelector.vue";
import UPDATE_SIZE_CHART from "../../../graphql/SizeChart/UpdateSizeChart.gql";
import INTEGRATION_CONFIG_MERCADO_LIBRE_SIZE_CHART from "../../../graphql/IntegrationConfigMercadoLibreSizeChart.gql";
import INTEGRATION_CONFIG_MERCADO_LIBRE_SITE_CODE from "../../../graphql/IntegrationConfigMercadoLibreOnlySiteCode.gql";
import ALL_SIZES from "../../../graphql/AllSizes.gql";
import BaseAccordion from "../../BaseAccordion.vue";
import BaseCollapseCard from "../../Base/CollapseCard.vue";
import MERCADO_LIBRE_SITE_DOMAINS from "@/graphql/MercadoLibre/Domains/siteDomains.gql";

export default {
  components: {
    SizeChartMercadoLibreSelector,
    SizeChartMercadoLibreHomologator,
    BaseAccordion,
    BaseCollapseCard
  },
  name: "SizeChartTabsMercadoLibre",
  props: {
    integrationConfig: Object,
    sizeChart: Object
  },
  mounted() {
    this.loading = true;
    this.isSizeChartActive()
      .then(() => this.getSiteCodeMercadoLibre())
      .then(() => this.getSizes())
      .then(() => this.getMercadoLibreSizeChart())
      .then(() => (this.loading = false));
  },
  data() {
    return {
      changed: false,
      saving: false,
      sizeChartMercadoLibre: null,
      homologating: false,
      homologations: null,
      centrySizes: null,
      error: null,
      loading: true,
      saved: false,
      siteCode: null,
      rowsUnit: {},
      isActive: null
    };
  },
  computed: {
    homologationHash() {
      const homologationHash = {};
      homologationHash[this.integrationConfig.fullLabel] = {
        id: this.$dig(this.sizeChartMercadoLibre, "id"),
        name: this.sizeChartName
      };
      return homologationHash;
    },
    sizeChartName() {
      if (!this.siteCode) return "Nombre no disponible";
      let ans = this.$dig(this.sizeChartMercadoLibre, "names", this.siteCode);
      if (!ans) return "Nombre no disponible para esta integración";
      return ans;
    },
    numberOfRows() {
      return this.$dig(this.sizeChartMercadoLibre, "rows").length;
    }
  },
  methods: {
    /**
     * Obtiene el site code de la integracion actual
     */
    getSiteCodeMercadoLibre() {
      if (this.integrationConfig) {
        this.$apollo
          .query({
            query: INTEGRATION_CONFIG_MERCADO_LIBRE_SITE_CODE,
            variables: {
              id: this.integrationConfig.id
            }
          })
          .then(({ data }) => {
            const siteCode = this.$dig(
              data,
              "integrationConfigMercadoLibre",
              "siteCode"
            );
            this.siteCode = siteCode;
          });
      }
    },
    /**
     * Cancela una edicion/creacion de guia de talles
     * redirije al listado de guia de talles
     */
    cancel() {
      if (this.changed) {
        this.$swal
          .fire({
            title: "Cancelar",
            text: "Si cancelas, perderás tus cambios. ¿Estás seguro?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "No"
          })
          .then(result => {
            if (result.value) {
              this.$router.push("/size_charts/");
            }
          });
      } else {
        this.$router.push("/size_charts/");
      }
    },
    /**
     * Guarda informacion asociada a la guia de talles
     */
    save() {
      this.saving = true;
      this.$apollo
        .mutate({
          mutation: UPDATE_SIZE_CHART,
          variables: {
            id: this.sizeChart.id,
            patch: {
              homologations: this.homologationHash,
              rows: this.getRows()
            }
          }
        })
        .then(() => {
          this.setSaved(null);
        })
        .catch(error => {
          this.setSaved(error);
        });
    },
    /**
     * Setea estado despues de guardar. Se agrega el errorm si es necesario
     * @param {String} error
     */
    setSaved(error) {
      this.error = error;
      this.saving = false;
      this.changed = false;
      this.saved = true;
    },
    /**
     * Esconde seccion de homologación de tallas
     */
    hideHomologating() {
      this.homologating = false;
    },
    /**
     * Obtiene todas las tallas registradas en centry
     * @return {Object}
     */
    getSizes() {
      return this.$getAllPages(ALL_SIZES, {}, "allSizes").then(data => {
        const centrySizes = data.map(size => {
          return { id: size.node.id, name: size.node.name };
        });
        this.centrySizes = centrySizes;
        return centrySizes;
      });
    },
    /**
     * Obtiene una guia de talles de mercado libre dado una id y una integracion
     * @returns {Object}
     */
    getMercadoLibreSizeChart() {
      const prevSizeChartId = this.$dig(
        this.sizeChart,
        "homologations",
        this.integrationConfig.fullLabel,
        "id"
      );
      if (!prevSizeChartId) return;
      return this.$apollo
        .query({
          query: INTEGRATION_CONFIG_MERCADO_LIBRE_SIZE_CHART,
          variables: {
            integrationConfigId: this.integrationConfig.id,
            sizeChartId: prevSizeChartId
          },
          fetchPolicy: "no-cache"
        })
        .then(({ data }) => {
          const sizeChartMercadoLibre =
            data.integrationConfigMercadoLibre.sizeChart;
          this.sizeChartMercadoLibre = sizeChartMercadoLibre;
          this.unitForRows(sizeChartMercadoLibre.rows);
          return sizeChartMercadoLibre;
        });
    },
    /**
     * Guarda en una variable la unidad para cada fila de la GDT de meli
     * @param {Array<Object>} rows - filas
     */
    unitForRows(rows) {
      this.rowsUnit = {};
      rows.forEach(row => {
        let unit = null;
        row.attributes.forEach(attribute => {
          if (attribute.id == "SIZE") {
            unit = this.$dig(attribute, "values", 0, "struct", "unit");
          }
          if (unit) {
            this.rowsUnit[row.id] = unit;
          } else {
            this.rowsUnit[row.id] = "";
          }
        });
      });
    },
    /**
     * Contruye un diccionario donde cada llave
     * es la id de la fila y el valor es la fila de la guia de talles
     * @param {Array<Object>} rows
     * @return {Object}
     */
    sizeToHomologationsHash(rows) {
      const hashSizeToHomologation = {};
      this.$ifNull(rows, []).forEach(row => {
        delete row.__typename;
        hashSizeToHomologation[row.sizeId] = row;
      });
      return hashSizeToHomologation;
    },
    /**
     * Verifica si es necesario eliminar una fila de homologacion o no
     * @param {Object} previous
     * @param {Array} homologation
     * @returns {Boolean}
     */
    needDeleteRow(previous, homologation) {
      return (
        previous &&
        !homologation[1] &&
        this.$dig(
          previous,
          "homologations",
          this.integrationConfig.fullLabel,
          "id"
        )
      );
    },
    /**
     * Elimina una fila de homologacion
     * @param {Object} hashSizeToHomologation
     * @param {Object} previous
     */
    deleteRow(hashSizeToHomologation, previous) {
      delete previous.homologations[this.integrationConfig.fullLabel];
      if (Object.keys(previous.homologations).length === 0) {
        delete hashSizeToHomologation[previous.sizeId];
      }
    },
    /**
     * Verifica si es necesario mezclar o no una homologacion
     * @param {Object} previous
     * @param {Array<String>} homologation
     * @returns {Boolean}
     */
    needMerge(previous, homologation) {
      return previous && homologation[1];
    },
    /**
     * Agrega un valor de homologacion de meli
     * @param {Object} previous
     * @param {Array<String>} homologation
     */
    mergeRows(previous, homologation) {
      this.cleanToSend(previous);
      this.$deepSet(
        previous,
        homologation[1],
        "homologations",
        this.integrationConfig.fullLabel,
        "id"
      );
      this.$deepSet(
        previous,
        this.rowsUnit[homologation[1]],
        "homologations",
        this.integrationConfig.fullLabel,
        "unit"
      );
    },
    /**
     * Limpia filas con solo la informacion que se quiere mandar
     * @param {Object} rowHash
     */
    cleanToSend(rowHash) {
      const allowed = ["id", "sizeId"];
      Object.keys(rowHash).forEach(key => {
        if (!allowed.includes(key)) delete rowHash[key];
      });
    },
    /**
     * Inserta un valor de homologacion
     * @param {Object} hashSizeToHomologation
     * @param {Array<String>} homologation
     */
    insertRow(hashSizeToHomologation, homologation) {
      hashSizeToHomologation[homologation[0]] = { sizeId: homologation[0] };
      this.$deepSet(
        hashSizeToHomologation[homologation[0]],
        homologation[1],
        "homologations",
        this.integrationConfig.fullLabel,
        "id"
      );
      this.$deepSet(
        hashSizeToHomologation[homologation[0]],
        this.rowsUnit[homologation[1]],
        "homologations",
        this.integrationConfig.fullLabel,
        "unit"
      );
    },
    /**
     * Obtiene filas de guia de talles
     * @return {Array<Object>}
     */
    getRows() {
      const hashSizeToHomologation = this.sizeToHomologationsHash(
        this.sizeChart.rows
      );
      this.homologations.forEach(homologation => {
        const prev = hashSizeToHomologation[homologation[0]];
        if (this.needDeleteRow(prev, homologation)) {
          this.deleteRow(hashSizeToHomologation, prev);
        } else if (this.needMerge(prev, homologation)) {
          this.mergeRows(prev, homologation);
        } else if (homologation[1]) {
          this.insertRow(hashSizeToHomologation, homologation);
        }
      });
      return Object.values(hashSizeToHomologation);
    },
    /**
     * Obtiene para cada talla centry el id asociado en meli
     * @param {Array<Object>} rows
     * @returns {Object}
     */
    sizeIdToHomologatedHash(rows) {
      const hash = {};
      rows.forEach(row => {
        hash[row.sizeId] = this.$dig(
          row,
          "homologations",
          this.integrationConfig.fullLabel,
          "id"
        );
      });
      return hash;
    },
    /**
     * Verifica si es necesario setear la homologacion de manera previa
     * @returns {Boolean}
     */
    needSetPreviousHomologation() {
      const homologatedId = this.$dig(
        this.sizeChart,
        "homologations",
        this.integrationConfig.fullLabel,
        "id"
      );
      return (
        homologatedId &&
        homologatedId === this.$dig(this.sizeChartMercadoLibre, "id") &&
        this.sizeChart.rows &&
        this.sizeChart.rows.length
      );
    },
    /**
     * Verifica si es necesario estimas homologaciones
     * @returns {Boolean}
     */
    needSetEstimatedHomologation() {
      return this.sizeChartMercadoLibre && this.sizeChartMercadoLibre.rows;
    },
    /**
     * Controla el seteo de valores de homologacion
     */
    setHomologations() {
      if (this.needSetPreviousHomologation()) {
        this.setHomologationsFromRows();
        this.changed = false;
      } else if (this.needSetEstimatedHomologation()) {
        this.setEstimatedHomologations();
        this.changed = true;
      }
    },
    /**
     * Setea homologaciones a partir de las filas del GDT
     */
    setHomologationsFromRows() {
      const sizeIdToHomologatedHash = this.sizeIdToHomologatedHash(
        this.sizeChart.rows
      );
      this.homologations = this.centrySizes.map(size => [
        size.id,
        sizeIdToHomologatedHash[size.id]
      ]);
    },
    /**
     * Estima valores de homologacion
     */
    setEstimatedHomologations() {
      this.homologations = this.centrySizes.map(size => [
        size.id,
        this.calculateHomologated(size.name, this.sizeChartMercadoLibre.rows)
      ]);
    },
    /**
     * Calcula si existe homologacion entre centry y meli de una talla centry especifica
     * @param {String} sizeName
     * @param {Object} mercadoLibreSizeChartRows
     * @returns {String}
     */
    calculateHomologated(sizeName, mercadoLibreSizeChartRows) {
      const homologated = mercadoLibreSizeChartRows.find(row => {
        return !!row.attributes.find(attr => {
          const value = attr.values[0].name;
          return (
            sizeName.replace(".", ",").toLowerCase() ===
            value.replace(".", ",").toLowerCase()
          );
        });
      });
      return homologated ? homologated.id : null;
    },
    /**
     * Recibe nuevo GDT seleccioando
     * @param {Object} newValue
     */
    setSizeChartMercadoLibre(newValue) {
      this.homologating = true;
      this.sizeChartMercadoLibre = newValue;
      this.unitForRows(newValue.rows);
    },
    /**
     * Recibe cambio en las homologaciones
     * @param {Array} newValue
     */
    setNewHomologations(newValue) {
      this.homologations = newValue;
      this.changed = true;
    },
    /**
     * Obtiene los dominios desde el sitio y verifica
     * si la guia de tallas está activa para utilizarse
     */
    isSizeChartActive() {
      this.loading = true;
      return this.$apollo
        .query({
          query: MERCADO_LIBRE_SITE_DOMAINS,
          variables: {
            integrationConfigId: this.integrationConfig.id
          }
        })
        .then(({ data }) => {
          let result = this.$dig(
            data,
            "integrationConfigMercadoLibre",
            "siteDomains",
            "domains"
          );
          let isActive = false;
          result?.every(domain => {
            if (!domain.domainId.includes("TEST")) {
              isActive = true;
              return false;
            }
            return true;
          });
          this.isActive = isActive;
        });
    }
  },
  watch: {
    sizeChartMercadoLibre() {
      this.changed = false;
      this.setHomologations();
    }
  }
};
</script>
<style scoped>
.card {
  overflow: visible;
}
</style>
