<template>
  <div>
    <h4 class="font-weight-bold m-3">Otros Datos</h4>

    <b-row>
      <b-col>
        <b-form-group label="Información adicional">
          <div v-for="(generalValue, index) in generalValues" :key="index">
            <b-row>
              <b-col>
                <b-form-group label="Nombre">
                  <b-form-input v-model="generalValue.key"> </b-form-input>
                </b-form-group>
              </b-col>

              <b-col>
                <b-form-group label="Valor">
                  <b-form-input v-model="generalValue.value"> </b-form-input>
                </b-form-group>
              </b-col>

              <b-col>
                <b-form-group label="Eliminar">
                  <b-button
                    variant="outline-info"
                    @click="deleteGeneralValue(index)"
                  >
                    <b-icon-x-circle></b-icon-x-circle>
                  </b-button>
                </b-form-group>
              </b-col>
            </b-row>
          </div>

          <b-button variant="outline-info" @click="addGeneralValue">
            <b-icon-plus></b-icon-plus> Agregar Información
          </b-button>
          <br />
        </b-form-group>

        <b-form-checkbox
          v-if="promotionType !== 'combo'"
          v-model="promotion.isFeatured"
        >
          Destacar promoción en el producto
        </b-form-checkbox>
      </b-col>

      <b-col>
        <b-form-group label="Restringir promoción a los productos del vendedor">
          <b-spinner v-if="loadingSellers" label="Spinning"></b-spinner>
          <div v-else>
            <v-select
              v-model="equalSeller"
              :options="equalOptions"
              :clearable="false"
            ></v-select>
            <v-select
              placeholder="Seleccione un vendedor"
              v-model="selectedSeller"
              :options="sellers"
            ></v-select>
          </div>
        </b-form-group>

        <b-form-group label="Politica Comercial">
          <b-spinner v-if="loadingSalesChannels" label="Spinning"></b-spinner>
          <div v-else>
            <v-select
              v-model="exclusiveSalesChannel"
              :options="exclusiveOptions"
              :clearable="false"
            ></v-select>

            <b-form-checkbox-group v-model="selectedSalesChannel" stacked>
              <b-form-checkbox
                v-for="saleChannel in salesChannelOptions"
                :value="saleChannel.value"
                :key="saleChannel.value"
              >
                {{ saleChannel.label }}
              </b-form-checkbox>
            </b-form-checkbox-group>
          </div>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import INTEGRATION_CONFIG_VTEX_ALL_SELLERS from "@/graphql/IntegrationConfigVtexAllSellers.gql";
import INTEGRATION_CONFIG_VTEX_ALL_SALES_CHANNELS from "@/graphql/IntegrationConfigVtexAllSalesChannels.gql";

const equalOptions = [
  { value: true, label: "Es igual a" },
  { value: false, label: "Diferente de" }
];

const exclusiveOptions = [
  { value: false, label: "Es igual a" },
  { value: true, label: "Diferente de" }
];

export default {
  name: "VtexBasePromotionOtherData",
  props: {
    value: {
      type: Object,
      required: true
    },
    vtexIntegrationConfigId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      promotion: this.value,
      generalValues:
        (!!this.value.generalValues &&
          Object.entries(this.value.generalValues).map(entry => {
            return { key: entry[0], value: entry[1] };
          })) ||
        null,
      equalSeller: null,
      sellers: [],
      loadingSellers: true,
      selectedSeller: null,
      equalOptions: equalOptions,
      exclusiveOptions: exclusiveOptions,
      exclusiveSalesChannel: this.exclusiveOptionByValue(
        this.value.areSalesChannelIdsExclusive
      ),
      selectedSalesChannel: [],
      loadingSalesChannels: true
    };
  },
  async mounted() {
    this.equalSeller = this.equalSellerOptionByValue(
      this.promotion.idSellerIsInclusive
    );
    this.getSellers().then(() => {
      this.setSelectedsSeller();
    });
    this.getSalesChannels().then(() => {
      this.setSelectedSalesChannels();
    });
  },
  methods: {
    /**
     * Se agrega un nuevo objeto al arreglo de valores generales de la promoción
     */
    addGeneralValue() {
      if (this.generalValues === null) {
        this.generalValues = [];
      }
      this.generalValues.push({
        key: "",
        value: ""
      });
    },
    /**
     * Se elimina un valor general de la promoción.
     * @param {Number} index - Indice de las horas a eliminar.
     */
    deleteGeneralValue(index) {
      this.generalValues.splice(index, 1);
    },
    /**
     * Retorna el objeto del selector equalSeller equivalente al valor seleccionado.
     * @param {Boolean} idSellerIsInclusive - Valor seleciconado.
     */
    equalSellerOptionByValue(idSellerIsInclusive) {
      return this.equalOptions.find(
        option => option.value === idSellerIsInclusive
      );
    },
    /**
     * Retorna el objeto del selector exclusiveOptions equivalente al valor seleccionado.
     * @param {Boolean} idSellerIsInclusive - Valor seleciconado.
     */
    exclusiveOptionByValue(value) {
      return exclusiveOptions.find(option => option.value === value);
    },
    /**
     * Obtiene los vendedores de Vtex y las guarda para el selector correspondiente.
     */
    async getSellers() {
      await this.$apollo
        .query({
          query: INTEGRATION_CONFIG_VTEX_ALL_SELLERS,
          variables: {
            id: this.vtexIntegrationConfigId
          }
        })
        .then(({ data }) => {
          this.sellers = data.integrationConfigVtex.allSellers.map(seller => ({
            label: seller.name,
            value: seller.sellerId
          }));
          this.loadingSellers = false;
        });
    },
    /**
     * Revisa y selecciona los vendedores de la promoción.
     */
    setSelectedsSeller() {
      if (this.promotion.idSeller) {
        this.selectedSeller = this.sellers.find(
          seller => seller.value === this.promotion.idSeller
        );
      }
    },
    /**
     * Obtiene los canales de ventea de Vtex y las guarda para el selector correspondiente.
     */
    async getSalesChannels() {
      await this.$apollo
        .query({
          query: INTEGRATION_CONFIG_VTEX_ALL_SALES_CHANNELS,
          variables: {
            id: this.vtexIntegrationConfigId
          }
        })
        .then(({ data }) => {
          this.salesChannelOptions = data.integrationConfigVtex.allSalesChannels.map(
            saleChannel => ({
              label: saleChannel.name,
              value: saleChannel.id
            })
          );
          this.loadingSalesChannels = false;
        });
    },
    /**
     * Revisa y selecciona los canales de venta de la promoción.
     */
    setSelectedSalesChannels() {
      if (this.promotion.idsSalesChannel) {
        this.selectedSalesChannel = this.promotion.idsSalesChannel.map(
          saleChannelId => {
            return this.salesChannelOptions.find(
              option => option.value === saleChannelId
            ).value;
          }
        );
      }
    }
  },
  computed: {
    /**
     * Retorna el tipo de promoción.
     */
    promotionType() {
      return this.promotion.type;
    }
  },
  watch: {
    value: {
      handler(newValue) {
        this.promotion = newValue;
      },
      deep: true
    },
    promotion: {
      handler(newValue) {
        this.$emit("input", newValue);
      },
      deep: true
    },
    /**
     * Asigna los valores generales creados a la promoción.
     * @param {Array<Object>} newGeneralValues - valores generales.
     */
    generalValues: {
      deep: true,
      handler(newGeneralValues) {
        if (newGeneralValues === null || newGeneralValues.length === 0) {
          this.promotion.generalValues = null;
        } else {
          let generalValues = newGeneralValues.reduce(
            (generalValueReducer, generalValue) => {
              generalValueReducer[generalValue.key] = generalValue.value;
              return generalValueReducer;
            },
            {}
          );
          this.promotion.generalValues = generalValues;
        }
      }
    },
    selectedSeller: function(newSelectedSeller) {
      if (newSelectedSeller) {
        this.promotion.idSeller = newSelectedSeller.value;
      } else {
        this.promotion.idSeller = null;
      }
    },
    equalSeller: function(newEqualSeller) {
      this.promotion.idSellerIsInclusive = newEqualSeller.value;
    },
    exclusiveSalesChannel: function(newExclusiveSalesChannel) {
      this.promotion.areSalesChannelIdsExclusive =
        newExclusiveSalesChannel.value;
    },
    selectedSalesChannel: function(newSelectedSalesChannel) {
      this.promotion.idsSalesChannel = newSelectedSalesChannel;
    }
  }
};
</script>
