<template>
  <div>
    <base-header :titleSize="0" title="Correos enviados"></base-header>
    <user-inbox-notification-table />
  </div>
</template>

<script>
import BaseHeader from "../../components/BaseHeader.vue";
import UserInboxNotificationTable from "./UserInboxNotificationTable.vue";
export default {
  name: "UserInboxNotificationIndex",
  components: {
    BaseHeader,
    UserInboxNotificationTable
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {},
  methods: {}
};
</script>

<style scoped>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
