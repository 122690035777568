<template>
  <div>
    <h4>Cantidad de ventas por canal</h4>
    <div v-if="loading" class="text-center">
      <b-spinner label="Spinning"></b-spinner>
    </div>
    <b-tabs card v-else-if="Object.keys(chartData).length">
      <b-tab
        v-for="(int, idx) of integrations"
        :title="int"
        :active="idx === 0"
        :key="idx"
        lazy
      >
        <base-bar-chart
          :chart-data="chartData[int]"
          :options="chartOptions"
        ></base-bar-chart>
      </b-tab>
    </b-tabs>
    <div class="text-center" v-else>
      -- No hay registro de ventas --
    </div>
  </div>
</template>
<script>
import BaseBarChart from "./BaseBarChart";
import PRODUCT_SALES_REPORT from "../graphql/ProductSalesReport.gql";
export default {
  name: "ProductDetailPageSales",
  components: { BaseBarChart },
  props: {
    product: Object
  },
  created() {
    this.$apollo
      .query({
        query: PRODUCT_SALES_REPORT,
        variables: {
          productId: this.product.id
        }
      })
      .then(({ data }) => {
        if (data && data.statistics) {
          if (data.statistics.productSalesReport) {
            const report = data.statistics.productSalesReport;
            const allData = {};
            const currentYear = new Date().getFullYear();
            const lastYear = currentYear - 1;
            const monthsNumber = new Date().getMonth() + 1;
            const labels = this.months.slice(0, monthsNumber);
            Object.keys(report).forEach(x => {
              const integrationData = report[x];
              allData[x] = {
                labels,
                datasets: [
                  {
                    label: lastYear.toString(),
                    backgroundColor: "#BDBDBD",
                    data: this.months
                      .map(x => {
                        if (integrationData[lastYear.toString()]) {
                          return integrationData[lastYear.toString()][x];
                        }
                        return null;
                      })
                      .slice(0, monthsNumber)
                  },
                  {
                    label: currentYear.toString(),
                    backgroundColor: "#0174DF",
                    data: this.months
                      .map(x => {
                        if (integrationData[currentYear.toString()]) {
                          return integrationData[currentYear.toString()][x];
                        }
                        return null;
                      })
                      .slice(0, monthsNumber)
                  }
                ]
              };
            });
            this.chartData = allData;
          }
          this.loading = false;
        }
      });
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true
              }
            }
          ]
        }
      },
      chartData: {},
      loading: true,
      months: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre"
      ]
    };
  },
  computed: {
    integrations() {
      return Object.keys(this.chartData);
    }
  }
};
</script>
