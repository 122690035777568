<template>
  <div>
    <base-header
      :titleSize="8"
      title="Central de Ofertas"
      title-link="#"
      :map="['Mercado Libre', 'Integraciones']"
    ></base-header>
    <mercado-libre-select-integration url-name="MercadoLibrePromotionsIndex" />
  </div>
</template>

<script>
import BaseHeader from "../components/BaseHeader.vue";
import MercadoLibreSelectIntegration from "../components/MercadoLibre/SelectIntegration.vue";
export default {
  name: "MercadoLibrePromotions",
  components: {
    BaseHeader,
    MercadoLibreSelectIntegration
  }
};
</script>
