var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h4',{staticClass:"font-weight-bold m-3"},[_vm._v("Restricciones de la promoción")]),_c('b-row',[_c('b-col',[_c('div',[_c('b-row',[_c('b-col',[_c('span',[_vm._v(" Limite de usos de la promoción en la tienda ")])])],1),_c('b-row',[_c('b-col',[_c('b-form-checkbox',{model:{value:(_vm.unlimitedMaxUsage),callback:function ($$v) {_vm.unlimitedMaxUsage=$$v},expression:"unlimitedMaxUsage"}},[_vm._v(" Ilimitado ")])],1),_c('b-col',[_c('b-form-input',{attrs:{"type":"number","min":"0","disabled":_vm.unlimitedMaxUsage},model:{value:(_vm.maxUsage),callback:function ($$v) {_vm.maxUsage=$$v},expression:"maxUsage"}})],1)],1)],1),_c('br'),_c('div',[_c('b-row',[_c('b-col',[_c('span',[_vm._v(" Limite de usos de la promoción en la tienda por cliente ")])])],1),_c('b-row',[_c('b-col',[_c('b-form-checkbox',{model:{value:(_vm.unlimitedMaxUsagePerClient),callback:function ($$v) {_vm.unlimitedMaxUsagePerClient=$$v},expression:"unlimitedMaxUsagePerClient"}},[_vm._v(" Ilimitado ")])],1),_c('b-col',[_c('b-form-input',{attrs:{"type":"number","min":"0","disabled":_vm.unlimitedMaxUsagePerClient},model:{value:(_vm.maxUsagePerClient),callback:function ($$v) {_vm.maxUsagePerClient=$$v},expression:"maxUsagePerClient"}})],1)],1)],1),_c('br'),(
          [
            'regular',
            'forThePriceOf',
            'progressive',
            'buyAndWin',
            'campaign'
          ].indexOf(_vm.promotion.type) != -1
        )?_c('div',[_c('b-row',[_c('b-col',[_c('span',[_vm._v(" Limite de articulos afectados por carrito ")])])],1),_c('b-row',[_c('b-col',[_c('b-form-checkbox',{model:{value:(_vm.unlimitedMaxNumberOfAffectedItems),callback:function ($$v) {_vm.unlimitedMaxNumberOfAffectedItems=$$v},expression:"unlimitedMaxNumberOfAffectedItems"}},[_vm._v(" Ilimitado ")])],1),_c('b-col',[_c('b-form-input',{attrs:{"type":"number","min":"0","disabled":_vm.unlimitedMaxNumberOfAffectedItems},model:{value:(_vm.maxNumberOfAffectedItems),callback:function ($$v) {_vm.maxNumberOfAffectedItems=$$v},expression:"maxNumberOfAffectedItems"}})],1)],1)],1):_vm._e()]),_c('b-col',[(['buyAndWin'].indexOf(_vm.promotion.type) != -1)?_c('b-form-checkbox',{model:{value:(_vm.sameSkus),callback:function ($$v) {_vm.sameSkus=$$v},expression:"sameSkus"}},[_vm._v(" Solo para los mismos artículos ")]):_vm._e(),_c('b-form-checkbox',{attrs:{"disabled":!_vm.allowOtherPromotions},model:{value:(_vm.cumulative),callback:function ($$v) {_vm.cumulative=$$v},expression:"cumulative"}},[_vm._v(" Permite que las promociones se acumulen ")]),_c('b-form-checkbox',{attrs:{"disabled":!_vm.allowManualPrice},model:{value:(_vm.accumulateWithManualPrice),callback:function ($$v) {_vm.accumulateWithManualPrice=$$v},expression:"accumulateWithManualPrice"}},[_vm._v(" Permite que se acumule con precios manuales ")])],1)],1),_c('hr')],1)}
var staticRenderFns = []

export { render, staticRenderFns }