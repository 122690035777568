<template>
  <div>
    <vtex-base-promotion-all-items
      v-if="['regular', 'campaign'].includes(promotion.type)"
      v-model="promotion"
      :vtexIntegrationConfigId="vtexIntegrationConfigId"
      @filledRequiredFields="setItemsRequiredFields"
    />
    <vtex-promotion-combo-items
      v-else-if="promotion.type === 'combo'"
      v-model="promotion"
      :vtexIntegrationConfigId="vtexIntegrationConfigId"
      @filledRequiredFields="setItemsRequiredFields"
    />
    <vtex-promotion-for-the-price-of-items
      v-else-if="promotion.type === 'forThePriceOf'"
      v-model="promotion"
      :vtexIntegrationConfigId="vtexIntegrationConfigId"
      @filledRequiredFields="setItemsRequiredFields"
    />
    <vtex-promotion-progressive-items
      v-else-if="promotion.type === 'progressive'"
      v-model="promotion"
      :vtexIntegrationConfigId="vtexIntegrationConfigId"
      @filledRequiredFields="setItemsRequiredFields"
    />
    <vtex-promotion-buy-and-win-items
      v-else-if="promotion.type === 'buyAndWin'"
      v-model="promotion"
      :vtexIntegrationConfigId="vtexIntegrationConfigId"
      @filledRequiredFields="setItemsRequiredFields"
    />
  </div>
</template>

<script>
import VtexBasePromotionAllItems from "./VtexPromotionItems/VtexBasePromotionAllItems.vue";
import VtexPromotionComboItems from "./VtexPromotionItems/VtexPromotionComboItems.vue";
import VtexPromotionForThePriceOfItems from "./VtexPromotionItems/VtexPromotionForThePriceOfItems.vue";
import VtexPromotionProgressiveItems from "./VtexPromotionItems/VtexPromotionProgressiveItems.vue";
import VtexPromotionBuyAndWinItems from "./VtexPromotionItems/VtexPromotionBuyAndWinItems.vue";

export default {
  name: "VtexBasePromotionItems",
  components: {
    VtexBasePromotionAllItems,
    VtexPromotionComboItems,
    VtexPromotionForThePriceOfItems,
    VtexPromotionProgressiveItems,
    VtexPromotionBuyAndWinItems
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    vtexIntegrationConfigId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      promotion: this.value,
      filledRequiredFields: false,
      itemsRequiredFields: false
    };
  },
  methods: {
    /**
     * Emite si los datos obligatorios han sido completados.
     * @param {Boolean} value - Valor a emitir.
     */
    emitFilledRequiredFields(value) {
      this.$emit("filledRequiredFields", value);
    },
    /**
     * Guarda el valor emitido, correspondiente a si los campos obligatorios
     * de los items de la promoción han sido completados.
     * @param {Boolean} value - Valor a emitir.
     */
    setItemsRequiredFields(value) {
      this.itemsRequiredFields = value;
    }
  },
  watch: {
    value(newValue) {
      this.promotion = newValue;
    },
    promotion: {
      handler(newValue) {
        this.$emit("input", newValue);
      },
      deep: true
    },
    /**
     * Si cambia el valor de filledRequiredFields, se emite este valor al padre.
     * @param {Boolean} value - Valor a emitir.
     */
    filledRequiredFields(newValue) {
      this.emitFilledRequiredFields(newValue);
    },
    /**
     * Si cambia el valor de itemsRequiredFields, se guarda este valor en filledRequiredFields.
     * @param {Boolean} value - Valor a emitir.
     */
    itemsRequiredFields(newValue) {
      this.filledRequiredFields = newValue;
    }
  }
};
</script>
