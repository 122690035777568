<template>
  <div>
    <base-header
      title="Listado de Opciones"
      title-link="/admin/option_list"
      :map="['Nuevo']"
    >
    </base-header>
    <b-container fluid="">
      <b-row>
        <b-col md="12">
          <ApolloQuery
            :query="require('../../graphql/HomologationRequestOptionList.gql')"
            :variables="{ id: $route.params.id }"
          >
            <template slot-scope="{ result: { data } }">
              <option-list-create-form
                v-if="data"
                :option-list="data.homologationRequestOptionList"
              ></option-list-create-form>
              <b-spinner label="Spinning" v-else></b-spinner>
            </template>
          </ApolloQuery>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import OptionListCreateForm from "@/components/OptionListForm.vue";
import BaseHeader from "@/components/BaseHeader";

export default {
  name: "OptionListRequestUpdate",
  components: {
    OptionListCreateForm,
    BaseHeader
  }
};
</script>
