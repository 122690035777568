<template>
  <base-landing-template>
    <b-container>
      <login-form></login-form>
    </b-container>
  </base-landing-template>
</template>

<script>
import BaseLandingTemplate from "../components/BaseLandingTemplate";
import LoginForm from "../components/LoginForm";
export default {
  name: "Login",
  components: {
    BaseLandingTemplate,
    LoginForm
  }
};
</script>
