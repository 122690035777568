<template>
  <base-overlay :show="!options" style="min-height: 330px;">
    <template v-if="options" slot="body">
      <b-form-group :label="title" :label-for="index + 'brand'">
        <vMultiselectListbox
          style="min-width: 100%"
          v-model="myModel"
          :options="options"
          @change="setChanged"
          :reduce-display-property="option => option.label"
          :reduce-value-property="option => option.value"
          :search-options-placeholder="searchOptionsPlaceholder"
          :selected-options-placeholder="selectedOptionsPlaceholder"
          :no-options-text="noOptionsText"
          :selected-no-options-text="selectedNoOptionsText"
          :no-options-found-text="noOptionsFoundText"
          :no-selected-options-found-text="noSelectedOptionsFoundText"
          :highlightDiff="true"
        />
      </b-form-group>
    </template>
    <template slot="overlay">
      <div class="text-center">
        <b-icon icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
        <p id="cancel-label">{{ overlayTitle }}</p>
      </div>
    </template>
  </base-overlay>
</template>

<script>
import vMultiselectListbox from "vue-multiselect-listbox";
import "vue-multiselect-listbox/dist/vue-multi-select-listbox.css";
import BaseOverlay from "../Base/BaseOverlay.vue";

export default {
  name: "SizeChartFilterMultiSelectOption",
  props: {
    title: {
      type: String
    },
    show: {
      type: Boolean
    },
    overlayTitle: {
      type: String
    },
    index: {
      type: Number
    },
    options: {
      type: Array
    },
    model: {
      type: Array
    },
    searchOptionsPlaceholder: {
      type: String
    },
    selectedOptionsPlaceholder: {
      type: String
    },
    noOptionsText: {
      type: String
    },
    selectedNoOptionsText: {
      type: String
    },
    noOptionsFoundText: {
      type: String
    },
    noSelectedOptionsFoundText: {
      type: String
    },
    setChanged: {
      type: Function
    }
  },
  components: {
    vMultiselectListbox,
    BaseOverlay
  },
  data() {
    return {
      myModel: this.model
    };
  },
  watch: {
    myModel: function() {
      this.$emit("set", this.myModel, this.index);
    }
  }
};
</script>
