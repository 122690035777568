<template>
  <h6 v-if="!!timeoutError">
    <b-icon-exclamation-circle></b-icon-exclamation-circle> Ha ocurrido un
    error: el gráfico ha tardado demasiado tiempo en cargar.
    <b-button variant="outline-info" @click="$emit('retry')"
      >¡Intentalo de nuevo!</b-button
    >
  </h6>
  <h3 class="text-danger text-center" v-else-if="!!error">
    <b-icon-exclamation-circle></b-icon-exclamation-circle> Ha ocurrido un
    error, no podemos mostrar este gráfico.
  </h3>
</template>
<script>
export default {
  name: "ErrorDashboard",
  props: {
    error: Boolean,
    timeoutError: Boolean
  }
};
</script>
