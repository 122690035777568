<template>
  <div>
    <b-row>
      <b-card
        id="product-view"
        class="base-transition"
        :class="{ 'in-card': show }"
      >
        <div v-if="loading && !cursor">
          <div v-for="i in 3" :key="i" class="shadow mb-3">
            <b-card style="width:100%">
              <b-skeleton animation="wave" width="35%"></b-skeleton>
              <b-skeleton animation="wave" width="55%"></b-skeleton>
              <base-skeleton-image-row />
            </b-card>
          </div>
        </div>
        <div v-else-if="products.length > 0">
          <product-image-manager-product-view
            v-for="(product, index) of products"
            :key="index"
            :product="product"
            :product-to-reload="productsReload"
          />
          <b-spinner
            v-if="loading && cursor"
            label="Spinning"
            class="m-2 float-right"
          />
          <div v-if="!loading && !productIdCopy">
            <b-button
              v-if="cursor"
              class="m-2 float-right"
              @click="getProducts()"
            >
              Ver más
            </b-button>
            <b-alert v-else show variant="light" class="text-center">
              No hay más datos para mostrar.
            </b-alert>
          </div>
        </div>
        <div v-else>
          No hay productos para mostrar
        </div>
      </b-card>
      <div
        id="button-gallery"
        :class="{ 'in-button': show }"
        style="height: 100%"
      >
        <hr class="hr-vertical mt-0" style="min-height: 200px; height: 35%" />
        <b-button
          size="lg"
          variant="outlined"
          class="p-0 mx-2 focus-btn"
          v-b-tooltip.hover="(show ? 'Ocultar' : 'Mostrar') + ' Galería'"
          @click="openGallery"
        >
          <b-icon
            :icon="show ? 'chevron-compact-right' : 'chevron-compact-left'"
            scale="0.75"
          ></b-icon>
        </b-button>
        <hr class="hr-vertical mb-0" style="min-height: 200px; height: 35%" />
      </div>
      <div id="gallery" class="base-transition" :class="{ in: show }">
        <images-gallery v-if="chargeGallery" @reload="updateReload" />
      </div>
    </b-row>
  </div>
</template>

<script>
import ProductImageManagerProductView from "./ProductImageManagerProductView.vue";
import BaseSkeletonImageRow from "@/components/BaseSkeletonImageRow.vue";
import ImagesGallery from "./ImagesGallery.vue";
import ALL_PRODUCTS_FOR_IMAGES from "@/graphql/AllProductsForImages.gql";
export default {
  name: "ProductImageManagerPage",
  components: {
    ProductImageManagerProductView,
    ImagesGallery,
    BaseSkeletonImageRow
  },
  props: {
    productId: String,
    filters: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      products: [],
      show: false,
      loading: false,
      chargeGallery: false,
      productIdCopy: this.productId,
      cursor: "",
      productsReload: [],
      reload: 0
    };
  },
  mounted() {
    this.getProducts(this.productIdCopy ? true : false);
  },
  computed: {},
  methods: {
    /**
     * Obtiene las imagenes ciertos productos, ya sea con
     * el id del producto o una keyword del producto
     */
    getProducts(isProduct = false) {
      this.loading = true;
      let variables;
      if (isProduct) {
        variables = { id: this.productIdCopy, cursor: this.cursor };
      } else {
        if (this.productIdCopy) {
          // Se elimina productIdCopy de url
          let query = Object.assign({}, this.$route.query);
          delete query.productId;
          this.$router.replace({ query });
          this.productIdCopy = "";
        }
        variables = {
          keyword: this.filters.phrases.join(","),
          cursor: this.cursor
        };
      }
      this.$retryQueryWithTimeout(ALL_PRODUCTS_FOR_IMAGES, variables).then(
        ({ data }) => {
          if (this.cursor) {
            this.products = this.products.concat(
              data.allProducts.edges.map(node => node.node)
            );
          } else {
            this.products = data.allProducts.edges.map(node => node.node);
          }
          this.cursor = data.allProducts.pageInfo.endCursor;
          this.loading = false;
        }
      );
    },
    /**
     * Se encarga de abrir la galeria de imagenes
     */
    openGallery() {
      this.show = !this.show;
      this.chargeGallery = true;
    },
    /**
     * Actualiza la lista de id de los productos a actualizar
     * @param {Array<String>} ids - lista de ids
     */
    updateReload(ids) {
      this.productsReload = ids;
    }
  },
  watch: {
    filters: {
      handler() {
        this.cursor = "";
        this.getProducts();
      },
      deep: true
    },
    productId(val) {
      if (val) {
        this.productIdCopy = val;
      }
    }
  }
};
</script>
<style scoped>
.base-transition {
  -webkit-transition: width 1s;
  -moz-transition: width 1s;
  -o-transition: width 1s;
  transition: width 1s;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  vertical-align: middle;
}

#gallery {
  margin-right: 15px;
  right: 0;
  position: fixed;
  width: 0px;
}
#gallery.in {
  width: 20%;
}

#product-view {
  width: 95%;
}
#product-view.in-card {
  width: 75%;
}

#button-gallery {
  -webkit-transition: right 1s;
  -moz-transition: right 1s;
  -o-transition: right 1s;
  transition: right 1s;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  vertical-align: middle;
  position: fixed;
  right: 2%;
}
#button-gallery.in-button {
  right: 22%;
}

.hr-vertical {
  border: none;
  border-left: 1px solid hsla(200, 10%, 50%, 100);
  width: 1px;
}

.focus-btn {
  color: #aab1b5;
  display: inline-block;
  background-color: white;
  width: 34px;
  height: 34px;
  text-align: center;
  border-radius: 50%;
  font-size: 14px;
  border: 2px solid #e0e2e4;
}

.focus-btn:hover {
  border-color: #aab1b5;
  color: black;
}
</style>
