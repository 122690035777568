<template>
  <div>
    <base-header
      :title="`Perfil de ${currentUser.firstName}`"
      title-link="#"
      :titleSize="5"
    />
    <b-alert
      v-if="showFlushAlert"
      :show="true"
      :variant="success ? 'success' : 'warning'"
      dismissible
    >
      {{ alertFlushMessage }}
    </b-alert>
    <base-alert-with-count-down
      v-model="showAlert"
      :alert-variant="alertVariant"
      :dismiss-secs="error ? 10 : 5"
    >
      {{ alertMessage }}</base-alert-with-count-down
    >
    <b-row>
      <b-col xl="10" cols="12">
        <div class="mb-3">
          <h4 class="font-weight-bold my-auto">Datos de la cuenta</h4>
          <hr />
          <b-row>
            <b-col sm="6" md="4" cols="12">
              <input-with-skeleton
                v-model="email"
                :loading="isLoading"
                label="Usuario"
                readonly
              >
                <template v-slot:prepend>
                  <b-input-group-text>
                    <b-icon icon="at" />
                  </b-input-group-text>
                </template>
              </input-with-skeleton>
            </b-col>
            <b-col sm="6" md="4" cols="12">
              <input-with-skeleton
                :loading="isLoading"
                label="Nombres"
                :invalid-feedback="validation.firstName.invalidFeedback"
                :state="validation.firstName.valid"
                v-model="firstName"
              >
                <template v-slot:prepend>
                  <b-input-group-text>
                    <b-icon icon="person-fill" />
                  </b-input-group-text>
                </template>
              </input-with-skeleton>
            </b-col>
            <b-col sm="6" md="4" cols="12">
              <input-with-skeleton
                :loading="isLoading"
                label="Apellidos"
                :invalid-feedback="validation.lastName.invalidFeedback"
                :state="validation.lastName.valid"
                v-model="lastName"
              >
                <template v-slot:prepend>
                  <b-input-group-text>
                    <b-icon icon="person-fill" />
                  </b-input-group-text>
                </template>
              </input-with-skeleton>
            </b-col>
          </b-row>
        </div>

        <div class="mb-3">
          <h4 class="font-weight-bold my-auto">Contraseña</h4>
          <hr />
          <b-row>
            <b-col sm="6" md="4" cols="12">
              <input-with-skeleton
                :loading="isLoading"
                label="Contraseña"
                v-model="password"
                type="password"
                :autocomplete="'new-password'"
              />
            </b-col>
            <b-col sm="6" md="4" cols="12">
              <input-with-skeleton
                :loading="isLoading"
                label="Confirmar contraseña"
                :invalid-feedback="
                  validation.passwordConfirmation.invalidFeedback
                "
                :state="validation.passwordConfirmation.valid"
                :disabled="!password"
                v-model="passwordConfirmation"
                type="password"
              />
            </b-col>
          </b-row>
        </div>

        <div class="mb-3" v-if="currentUser.role == 0">
          <h4 class="font-weight-bold my-auto">Cambio de empresa</h4>
          <hr />
          <b-row>
            <b-col sm="6" md="4" cols="12">
              <b-form-group label="Empresa">
                <base-live-select
                  v-if="company"
                  id="company"
                  v-model="company"
                  placeholder="Seleccionar"
                  :clearable="false"
                  @search="updateCompanies"
                  :min-search-characters="2"
                  :debounce="750"
                ></base-live-select>
                <b-skeleton v-else type="input" />
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </b-col>
      <b-col xl="2" cols="12">
        <b-button
          variant="info"
          :disabled="!changed || saving || !valid || isLoading"
          :title="changed ? '' : 'No hay cambios'"
          @click="save"
          class="mb-3 w-100"
        >
          <span v-if="saving">
            Guardando
            <b-spinner label="Spinning" />
          </span>
          <span v-else>Guardar</span>
        </b-button>
        <b-button
          variant="outline-info"
          class="w-100 px-0"
          :disabled="!changed || saving || isLoading"
          @click="resetData"
        >
          <span>Restablecer</span>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import BaseHeader from "../components/BaseHeader.vue";
import InputWithSkeleton from "../components/Base/InputWithSkeleton.vue";
import BaseLiveSelect from "../components/BaseLiveSelect.vue";
import BaseAlertWithCountDown from "../components/Base/BaseAlertWithCountDown.vue";
import USER_INFO from "../graphql/Profile/UserInfo.gql";
import ADMIN_COMPANIES from "../graphql/AdminCompanies.gql";
import UPDATE_PROFILE from "../graphql/UpdateProfile.gql";
import { mapState, mapMutations } from "vuex";
export default {
  name: "Profile",
  components: {
    BaseHeader,
    InputWithSkeleton,
    BaseAlertWithCountDown,
    BaseLiveSelect
  },
  data() {
    return {
      email: null,
      originalFirstName: null,
      originalLastName: null,
      firstName: null,
      lastName: null,
      password: null,
      passwordConfirmation: null,
      isLoading: false,
      showAlert: false,
      error: false,
      errorMessage: null,
      saving: false,
      allCompanies: [],
      company: null,
      success: false,
      showFlushAlert: false
    };
  },
  created() {
    let params = this.$route.query;
    if (Object.keys(params).length != 0) {
      params = Object.keys(params);
      if (params.includes("warning")) {
        this.showFlushAlert = true;
      } else if (params.includes("success")) {
        this.success = true;
        this.showFlushAlert = true;
      }
    }
    this.isLoading = true;
    this.getUserInfo();
    if (this.currentUser.role == 0) {
      this.company = {
        value: this.currentUser.company.id,
        label: this.currentUser.company.name
      };
    }
  },
  computed: {
    ...mapState(["currentUser"]),
    validation() {
      return {
        firstName: {
          valid: this.firstName?.length > 0,
          invalidFeedback: "El nombre es obligatorio"
        },
        lastName: {
          valid: this.lastName?.length > 0,
          invalidFeedback: "El apellido es obligatorio"
        },
        passwordConfirmation: {
          valid:
            !this.password ||
            (this.password?.length > 0 &&
              this.passwordConfirmation != null &&
              this.password == this.passwordConfirmation),
          invalidFeedback:
            this.password?.length > 0 &&
            this.passwordConfirmation != null &&
            this.password != this.passwordConfirmation
              ? "Las contraseñas deben coincidir"
              : "La confirmacion de contraseña es obligatoria"
        }
      };
    },
    valid() {
      let valid = true;
      Object.keys(this.validation).forEach(x => {
        valid = valid && this.validation[x].valid;
      });
      return valid;
    },
    changed() {
      return (
        this.originalFirstName != this.firstName ||
        this.originalLastName != this.lastName ||
        this.password ||
        (this.company?.value &&
          this.company.value != this.currentUser.company.id)
      );
    },
    alertVariant() {
      return this.error ? "danger" : "success";
    },
    alertMessage() {
      return this.error
        ? "Ha ocurrido un error guardando los datos: " +
            (this.errorMessage || "")
        : "Perfil actualizado exitosamente";
    },
    alertFlushMessage() {
      return this.success
        ? "Autorización exitosa de acceso a Google."
        : "No tienes permiso para revisar esta sección. Comunicate con el administrador de esta empresa para mayor información.";
    }
  },
  methods: {
    ...mapMutations(["setCurrentUser"]),
    /**
     * Obtiene la info de un usuario
     */
    getUserInfo() {
      this.$apollo.query({ query: USER_INFO }).then(({ data }) => {
        let user = data.currentUser;
        this.email = user.email;
        this.originalFirstName = user.firstName;
        this.originalLastName = user.lastName;
        this.firstName = user.firstName;
        this.lastName = user.lastName;
        this.isLoading = false;
      });
    },
    /**
     * Se encarga de filtrar las empresas a mostrar como opciones del select
     * solicitando al servidor un subconjunto que contenga en su nombre el
     * término ingresado por el usuario.
     * @param {String} search término a ser buscado
     * @param {Boolean} loading indica si el request está en curso
     * @param {Function} setOptions función del componente BaseLiveSelect que
     *                              espera recibir un arreglo de opciones.
     */
    async updateCompanies(search, loading, setOptions) {
      loading(true);
      this.$apollo
        .query({
          query: ADMIN_COMPANIES,
          variables: { name: search }
        })
        .then(async result => {
          if (result?.data?.adminCompanies?.edges) {
            setOptions(
              result.data.adminCompanies.edges.map(x => {
                return this.itemForSelect(x.node);
              })
            );
          }
          loading(false);
        })
        .catch(() => {
          loading(false);
        });
    },
    /**
     * Transforma un elemento obtenido desde Centry para que sea un item dentro
     * del listado de opciones que maneja el componente BaseLiveSelect.
     *
     * El resultado es un objeto con la siguiente estructura:
     * `{ value: "id", label: "nombre" }`
     *
     * @param {Object} item un objeto de Centry. Se espera que tenga `id` y
     *                      `name`.
     */
    itemForSelect(item) {
      if (item) {
        return { value: item.id, label: item.name };
      }
      return null;
    },
    /**
     * Se encarga de enviar la mutacion para
     * actualizar la info del usuario
     */
    save() {
      if (!this.valid) {
        return;
      }
      this.saving = true;
      let toUpdate = {};
      if (this.password) {
        toUpdate["password"] = this.password;
      }
      if (this.originalFirstName != this.firstName) {
        toUpdate["firstName"] = this.firstName;
      }
      if (this.originalLastName != this.lastName) {
        toUpdate["lastName"] = this.lastName;
      }
      if (
        this.company?.value &&
        this.company.value != this.currentUser.company.id
      ) {
        toUpdate["companyId"] = this.company.value;
      }
      this.$apollo
        .mutate({ mutation: UPDATE_PROFILE, variables: { input: toUpdate } })
        .then(async ({ data }) => {
          if (data.updateProfile.result) {
            let newUser = data.updateProfile.user;
            await this.setCurrentUser(newUser);
            localStorage["current-user"] = JSON.stringify(newUser);
            if (toUpdate["companyId"]) {
              localStorage.companyId = toUpdate["companyId"];
            }
          } else {
            this.error = true;
          }
          this.saving = false;
          this.showAlert = true;
        })
        .catch(e => {
          this.error = true;
          this.errorMessage = e.message;
          this.saving = false;
          this.showAlert = true;
        });
    },
    /**
     * Resetea la informacion del formulario
     */
    resetData() {
      this.firstName = this.originalFirstName;
      this.lastName = this.originalLastName;
      this.password = null;
      this.passwordConfirmation = null;
      this.company = {
        value: this.currentUser.company.id,
        label: this.currentUser.company.name
      };
    }
  },
  watch: {
    currentUser: {
      handler(val, oldVal) {
        if (val.role == 0 && val.company.id != oldVal.company.id) {
          this.company = { value: val.company.id, label: val.company.name };
        }
      },
      deep: true
    }
  }
};
</script>

<style></style>
