<template>
  <div>
    <integration-config-checkboxes
      :integrationConfigs.sync="icsToSync"
      :showInvalidFeedback="false"
    ></integration-config-checkboxes>
    <b-alert
      v-model="sync"
      :variant="errorSyncing ? 'danger' : 'success'"
      dismissible
    >
      {{
        errorSyncing
          ? "Ha ocurrido un error"
          : "Se están sincronizando tus productos."
      }}
    </b-alert>
    <b-row align-h="between" align-v="end" class="mx-0 my-1">
      <b-col>
        <b-row>
          <b-button @click="syncIntegrations('normal')" class="mr-1 mb-3"
            >Sincronización completa
            <b-spinner label="Spinning" small v-if="syncing['normal']"
          /></b-button>
          <b-button @click="syncIntegrations('crucial')" class="mr-1 mb-3"
            >Sincronizar datos críticos
            <b-spinner label="Spinning" small v-if="syncing['crucial']" />
          </b-button>
        </b-row>
      </b-col>
      <b-col>
        <b-row align-h="end" align-v="end">
          <b-form-group
            style="max-width: 200px"
            class="mx-2"
            label="Filtrar por fecha"
          >
            <date-picker
              v-model="dateSelected"
              type="date"
              value-type="date"
              range
            >
            </date-picker>
          </b-form-group>

          <b-button v-b-toggle.collapse-1 class="mr-1 mb-3" variant="link">
            Configuraciones avanzadas
          </b-button>
        </b-row>
      </b-col>
    </b-row>
    <b-collapse id="collapse-1" class="mt-2">
      <b-card>
        <b-form-group
          label-cols-sm="4"
          label-cols-md="3"
          label-cols-lg="2"
          label-cols-xl="1"
          content-cols-sm
          content-cols-md="6"
          content-cols-lg="5"
          content-cols-xl="4"
          label="Tamaño del lector de detalles"
          label-for="editors-height-px"
        >
          <b-input-group append="px">
            <b-form-input
              id="editors-height-px"
              v-model="editorsHeightPx"
              type="number"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-card>
    </b-collapse>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { synchronizeProduct } from "../main";
import IntegrationConfigCheckboxes from "../components/IntegrationConfigCheckboxes.vue";

export default {
  name: "HistoriesActions",
  components: {
    IntegrationConfigCheckboxes
  },
  props: {
    product: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      icsToSync: [],
      syncing: {
        normal: false,
        crucial: false
      },
      errorSyncing: false,
      sync: false,
      editorsHeightPx: 200,
      dataReady: false,
      dateSelected: null
    };
  },
  async mounted() {
    if (localStorage.editorsHeightPx) {
      this.editorsHeightPx = localStorage.editorsHeightPx;
    }
    this.dataReady = true;
  },
  computed: {
    ...mapState(["currentUser"])
  },
  methods: {
    /**
     * Ejecuta la mutación encargada de encolar los trabajos de sincronización
     * del producto para las integraciones seleccionadas.
     *
     * @param {String} type Los valores aceptados son `"normal"` y `"crucial"`,
     *                 para indicar si las sincronizaciones serán normales o
     *                 críticas.
     */
    async syncIntegrations(type) {
      this.errorSyncing = false;
      this.syncing[type] = true;
      await synchronizeProduct(
        this.$apollo,
        this.product.id,
        this.icsToSync.map(ic => ic.id),
        type
      )
        .then(async ({ data }) => {
          if (data?.synchronizeProduct?.result) {
            this.syncing[type] = false;
            this.sync = true;
          }
        })
        .catch(() => {
          this.errorSyncing = true;
          this.syncing[type] = false;
          this.sync = true;
        });
    }
  },
  watch: {
    editorsHeightPx(height) {
      localStorage.editorsHeightPx = height;
      this.$emit("update:editorHeight", height);
    },
    dateSelected(newValue) {
      newValue[1]?.setHours(23, 59, 59);
      let rangeDate = { createFrom: newValue[0], createTo: newValue[1] };
      this.$emit("update:rangeDate", rangeDate);
    }
  }
};
</script>
