<template>
  <div>
    <h4 class="font-weight-bold m-3">Audiencia</h4>

    <b-form-checkbox v-model="activeCampaigns" :disabled="true">
      Aplicar audiencia de campaña
    </b-form-checkbox>

    <v-select
      multiple
      v-model="campaigns"
      :options="campaignsOptions"
      :disabled="true"
    ></v-select>
  </div>
</template>

<script>
export default {
  name: "VtexBasePromotionAudience",
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      promotion: this.value,
      activeCampaigns:
        !!this.value.campaigns &&
        Object.keys(this.value.campaigns).length !== 0,
      campaigns: this.value.campaigns.map(campaign => {
        return {
          label: campaign.name,
          value: campaign
        };
      }),
      campaignsOptions: []
    };
  },
  watch: {
    value: {
      handler(newValue) {
        this.promotion = newValue;
      },
      deep: true
    },
    promotion: {
      handler(newValue) {
        this.$emit("input", newValue);
      },
      deep: true
    }
  }
};
</script>
