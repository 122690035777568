<template>
  <div>
    <b-row>
      <b-col xl="4" md="6" xs="12">
        <b-form-group label="Nombre" label-for="name">
          <b-input-group size="sm">
            <b-input-group-prepend>
              <b-input-group-text>
                <b-icon icon="house-door-fill" />
              </b-input-group-text>
            </b-input-group-prepend>
            <b-form-input
              :disabled="!edit"
              v-model="name"
              id="name"
              size="sm"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col xl="4" md="6" xs="12">
        <b-form-group label="Bodega principal" label-for="warehouse">
          <b-input-group size="sm">
            <b-input-group-prepend>
              <b-input-group-text>
                <b-icon icon="geo-alt-fill" />
              </b-input-group-text>
            </b-input-group-prepend>
            <b-form-select
              v-if="edit"
              v-model="warehouse"
              text-field="name"
              value-field="id"
              :options="warehouseOptions"
              id="warehouse"
              size="sm"
            ></b-form-select>
            <b-form-input
              v-else
              :disabled="!edit"
              v-model="warehouse.name"
              id="warehouse"
              size="sm"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col xl="4" md="6" xs="12">
        <b-form-group label="Zona horaria" label-for="timeZone">
          <b-input-group size="sm">
            <b-input-group-prepend>
              <b-input-group-text>
                <b-icon icon="globe" />
              </b-input-group-text>
            </b-input-group-prepend>
            <b-form-select
              v-if="edit"
              v-model="timeZone"
              text-field="name"
              :options="timezoneOptions"
              id="timeZone"
              size="sm"
            ></b-form-select>
            <b-form-input
              v-else
              :disabled="!edit"
              v-model="timeZone"
              id="timeZone"
              size="sm"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ALL_TIME_ZONES from "../../graphql/AllTimeZones.gql";
import { mapState } from "vuex";
export default {
  name: "CompanyBasicInfoForm",
  props: {
    company: Object,
    edit: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      name: null,
      warehouse: {},
      timeZone: null,
      warehouseOptions: [],
      timezoneOptions: []
    };
  },
  computed: {
    ...mapState(["currentUser"])
  },
  mounted() {
    this.setValues();
    if (this.edit) {
      this.getAllTimezones();
      this.getAllWarehouses();
    }
  },
  methods: {
    /**
     * Obtiene todas las zonas horarias para el selector
     */
    getAllTimezones() {
      this.$apollo
        .query({
          query: ALL_TIME_ZONES,
          variables: {
            priorityZones: "/America/"
          }
        })
        .then(({ data }) => {
          let tempTimezones = [];
          tempTimezones = tempTimezones.concat(data.allTimeZones.priority);
          if (data.allTimeZones.other) {
            tempTimezones.push({
              value: null,
              name: "---------",
              disabled: true
            });
            tempTimezones = tempTimezones.concat(data.allTimeZones.other);
          }
          this.timezoneOptions = this.$dup(tempTimezones);
        });
    },
    /**
     * Obtiene las warehouses de la empresa actual
     */
    getAllWarehouses() {
      this.warehouseOptions = this.$dup(this.currentUser.company.warehouses);
    },
    /**
     * Setea los valores por defecto para el form
     */
    setValues() {
      this.name = this.company.name;
      if (this.edit) {
        this.warehouse = this.company.mainWarehouse.id;
      } else {
        this.warehouse = this.company.mainWarehouse;
      }
      this.timeZone = this.company.timeZone;
    }
  },
  watch: {
    name(val) {
      if (this.edit) {
        this.$emit("change", "name", val);
      }
    },
    warehouse(val) {
      if (this.edit) {
        this.$emit("change", "mainWarehouseId", val);
      }
    },
    timeZone(val) {
      if (this.edit) {
        this.$emit("change", "timeZone", val);
      }
    }
  }
};
</script>
