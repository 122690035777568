<template>
  <div>
    <b-form-group label="Colecciones">
      <b-spinner v-if="loadingCollections" label="Spinning"></b-spinner>
      <v-select
        v-else
        multiple
        placeholder="Seleccione colecciones"
        v-model="selectedCollections"
        :options="collections"
      ></v-select>
      <span v-if="requiredMessage.selectedProduct" class="text-danger">
        Algun SKU o colección es requerido
      </span>
    </b-form-group>

    <b-form-group label="SKUs">
      <v-select
        multiple
        placeholder="Seleccione SKUs"
        v-model="selectedSkus"
        :options="skus"
      ></v-select>
      <span v-if="requiredMessage.selectedProduct" class="text-danger">
        Algun SKU o colección es requerido
      </span>
    </b-form-group>

    <b-form-group>
      <b-button variant="outline-info" @click="showModal = !showModal">
        <b-icon-cloud-download></b-icon-cloud-download> Obtener SKUs
      </b-button>
      <vtex-base-promotion-products-and-skus-data-modal
        v-model="showModal"
        :vtexIntegrationConfigId="vtexIntegrationConfigId"
        @skus="setSkus"
      />
    </b-form-group>

    <slot> </slot>
    <hr />
  </div>
</template>

<script>
import INTEGRATION_CONFIG_VTEX_ALL_COLLECTIONS from "@/graphql/IntegrationConfigVtexAllCollections.gql";
import VtexBasePromotionProductsAndSkusDataModal from "../VtexBasePromotionProductsAndSkusDataModal.vue";

export default {
  name: "VtexBasePromotionCollectionsAndSkus",
  components: {
    VtexBasePromotionProductsAndSkusDataModal
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    vtexIntegrationConfigId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      promotion: this.value,
      loadingCollections: true,
      selectedCollections: [],
      collections: [],
      selectedSkus: [],
      skus: [],
      filledRequiredFields: false,
      showModal: false
    };
  },
  /**
   * Se obtienen los datos de colecciones y se guardan en el selector
   * correspondiente de la vista. Luego, se asignan los valores seleccionados
   * de la promoción a los de la vista.
   */
  mounted() {
    this.getVtexCollections().then(() => {
      this.setSelectedCollections();
    });
    this.setSelectedSkus();
  },
  methods: {
    /**
     * Obtiene las colecciones de Vtex y las guarda para el selector correspondiente.
     */
    async getVtexCollections() {
      return this.$apollo
        .query({
          query: INTEGRATION_CONFIG_VTEX_ALL_COLLECTIONS,
          variables: {
            id: this.vtexIntegrationConfigId
          }
        })
        .then(({ data }) => {
          this.collections = data.integrationConfigVtex.allCollections.map(
            collection => ({
              value: collection.id,
              label: collection.name
            })
          );
          this.loadingCollections = false;
        });
    },
    /**
     * Revisa y selecciona las colecciones de la promoción.
     */
    setSelectedCollections() {
      if (this.promotion.collections1BuyTogether) {
        this.selectedCollections = this.promotion.collections1BuyTogether.map(
          selected => {
            return this.collections.find(
              collection => collection.value === selected.id
            );
          }
        );
      }
    },
    /**
     * Revisa y selecciona los SKUs de la promoción.
     */
    setSelectedSkus() {
      if (this.promotion.listSku1BuyTogether) {
        this.selectedSkus = this.promotion.listSku1BuyTogether.map(sku => ({
          value: sku.id,
          label: sku.name + " (SKU " + sku.id + ")"
        }));
      }
    },
    /**
     * Emite si los datos obligatorios han sido completados.
     * @param {Boolean} value - Valor a emitir.
     */
    emitFilledRequiredFields(value) {
      this.$emit("filledRequiredFields", value);
    },
    setSkus(skus) {
      this.skus = skus;
    }
  },
  computed: {
    /**
     * Revisa si los atributos obligatorios de la promoción en la vista estan completos
     * para saber donde mostrar los mensajes de requerido.
     * @returns {Object} - Objeto con los atributos obligatorios y si requieren mensaje.
     */
    requiredMessage() {
      let required = {};
      required.selectedProduct =
        this.selectedSkus.length === 0 && this.selectedCollections.length === 0;
      return required;
    }
  },
  /**
   * En general, el objetivo de los watches es cambiar el valor de una propiedad
   * de la promocion cuando se cambia su correspondiente en la vista
   */
  watch: {
    value(newValue) {
      this.promotion = newValue;
    },
    promotion: {
      handler(newValue) {
        this.$emit("input", newValue);
      },
      deep: true
    },
    selectedCollections(newValue) {
      this.promotion.collections1BuyTogether = newValue.map(
        selectedCollection => {
          return {
            id: selectedCollection.value,
            name: selectedCollection.label
          };
        }
      );
    },
    selectedSkus(newValue) {
      this.promotion.listSku1BuyTogether = newValue.map(selectedSku => {
        return {
          id: selectedSku.value,
          name: selectedSku.label
        };
      });
    },
    /**
     * Si cambia el valor de requiredMessage, se revisa si no se necesita
     * ningun mensaje y se guarda este valor en filledRequiredFields.
     */
    requiredMessage: function(newValue) {
      this.filledRequiredFields = !Object.values(newValue).includes(true);
    },
    /**
     * Si cambia el valor de filledRequiredFields, se emite este valor al padre.
     * @param {Boolean} value - Valor a emitir.
     */
    filledRequiredFields: function(value) {
      this.emitFilledRequiredFields(value);
    }
  }
};
</script>
