<template>
  <tr>
    <td>
      <base-pretty-check-box
        v-model="internalSelected"
        @change="value => $emit('change', value)"
      ></base-pretty-check-box>
    </td>
    <td>
      {{ this.$dig(homologation, "publicIntegrationInformation", "name") }}
    </td>
    <td>{{ homologation.path }}</td>
    <td>{{ this.$dig(homologation, "categoryAttribute", "name") }}</td>
    <td>
      <b-link :to="categoryAttributeUrl">Ver Atributo</b-link>
    </td>
  </tr>
</template>
<script>
import BasePrettyCheckBox from "@/components/BasePrettyCheckBox.vue";
export default {
  name: "HomologationsTableRow",
  components: {
    BasePrettyCheckBox
  },
  created() {
    this.$emit("change", false);
  },
  model: {
    prop: "selected",
    event: "change"
  },
  props: {
    selected: Boolean,
    homologation: Object
  },
  data() {
    return {
      internalSelected: this.selected
    };
  },
  computed: {
    categoryAttributeUrl() {
      return (
        "/admin/category_attribute/" +
        this.homologation.categoryAttribute.id +
        "/edit"
      );
    }
  },
  watch: {
    selected(newVal) {
      console.log(newVal);
      this.internalSelected = newVal;
    }
  }
};
</script>
