var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-alert-with-count-down',{attrs:{"alert-variant":_vm.alertVariant,"dismiss-secs":_vm.error ? 10 : 5},model:{value:(_vm.showAlert),callback:function ($$v) {_vm.showAlert=$$v},expression:"showAlert"}},[_vm._v(" "+_vm._s(_vm.alertMessage))]),_c('ApolloQuery',{attrs:{"query":require('../../graphql/Users/AllUsers.gql'),"variables":{
      cursor: ''
    },"fetchPolicy":'cache-and-network',"clientId":"apolloClientCached","notifyOnNetworkStatusChange":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var ref_result = ref.result;
    var error = ref_result.error;
    var data = ref_result.data;
    var query = ref.query;
    var isLoading = ref.isLoading;
return [(data && isLoading)?_c('span',{staticClass:"m-2 float-right"},[_vm._v(" Actualizando lista de usuarios... ")]):_vm._e(),(data && data.allUsers)?_c('b-table-simple',{attrs:{"hover":"","responsive":""}},[_c('b-thead',[_c('b-tr',[_c('b-th',[_vm._v("Nombre")]),_c('b-th',[_vm._v("Estado")]),_c('b-th',[_vm._v("Correo electrónico")]),_c('b-th',[_vm._v("Grupo")]),_c('b-th')],1)],1),_c('b-tbody',[(!data.allUsers.edges.length)?_c('b-tr',[_c('b-td',{attrs:{"colspan":"7"}},[_vm._v("No tienes usuarios registrados")])],1):_vm._e(),_vm._l((data.allUsers.edges),function(user){return _c('users-detail',{key:user.node.id,attrs:{"user":user.node},on:{"delete-user":function (error) { return _vm.deleteUser(query, error); },"error-updating":_vm.errorUpdating}})})],2)],1):_vm._e(),(error)?_c('b-alert',{attrs:{"show":"","variant":"danger"}},[_vm._v(" "+_vm._s(error.message ? error.message : "Ha ocurrido un error")+" ")]):_vm._e(),(isLoading)?_c('b-spinner',{staticClass:"m-2 float-right",attrs:{"label":"Spinning"}}):_c('div',[(_vm.hasNextPage(data))?_c('b-button',{staticClass:"m-2 float-right",on:{"click":function($event){return _vm.seeMore(query, data.allUsers.pageInfo.endCursor)}}},[_vm._v(" Ver más ")]):_c('b-alert',{staticClass:"text-center",attrs:{"show":"","variant":"light"}},[_vm._v(" No hay más datos para mostrar. ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }