import MercadoLibreItemFulfillmentStock from "../views/MercadoLibreItemFulfillmentStock.vue";
import MercadoLibreItemFulfillmentOperations from "../views/MercadoLibreItemFulfillmentOperations.vue";
import MercadoLibreAttributeIntegrationsSelector from "../views/MercadoLibreAttributeIntegrationsSelector.vue";
import MercadoLibreAttributeGroups from "../views/MercadoLibreAttributeGroups.vue";
import MercadoLibreAttributes from "../views/MercadoLibreAttributes.vue";
import MercadoLibreCatalogSuggestionsIntegrations from "../views/MercadoLibre/CatalogSuggestions/Integrations.vue";
import MercadoLibreCatalogSuggestionsList from "../views/MercadoLibre/CatalogSuggestions/List.vue";
import MercadoLibreCatalogSuggestionCreation from "../views/MercadoLibre/CatalogSuggestions/New.vue";
import MercadoLibreCatalogSuggestionEdit from "../views/MercadoLibre/CatalogSuggestions/Edit.vue";
import MercadoLibreCatalogSuggestion from "../views/MercadoLibre/CatalogSuggestions/Suggestion.vue";
import MercadoLibrePromotions from "../views/MercadoLibrePromotions.vue";
import MercadoLibrePromotionsIndex from "../views/MercadoLibrePromotionsIndex.vue";
import MercadoLibrePromotionsDealIndex from "../views/MercadoLibrePromotionsDealIndex.vue";
import MercadoLibrePromotionsCampaignIndex from "../views/MercadoLibrePromotionsCampaignIndex.vue";
import MercadoLibrePromotionsDodIndex from "../views/MercadoLibrePromotionsDodIndex.vue";
import MercadoLibrePromotionsLightningIndex from "../views/MercadoLibrePromotionsLightningIndex.vue";
import MercadoLibrePromotionsSmartIndex from "../views/MercadoLibrePromotionsSmartIndex.vue";
import MercadoLibreHighlights from "../views/MercadoLibreHighlights";
import MercadoLibreHighlightsByCategory from "../views/MercadoLibreHighlightsByCategory";
import MercadoLibreNewsIndex from "../views/MercadoLibreNewsIndex.vue";
import MercadoLibreQuotations from "../views/MercadoLibre/DynamicShipping/Quotations.vue";
import MercadoLibreCatalogDestinationPricesList from "../views/MercadoLibre/DynamicShipping/List.vue";
import MercadoLibreRecommendations from "../views/MercadoLibre/Recommendations/Selector.vue";
import MercadoLibreRecommendationsList from "../views/MercadoLibre/Recommendations/List.vue";
import NotWorkingDaysSelector from "../views/MercadoLibre/NotWorkingDays/Selector.vue";
import NotWorkingDaysList from "../views/MercadoLibre/NotWorkingDays/List.vue";
import MercadoLibreMe1 from "../views/MercadoLibre/Me1/Selector.vue";
import MercadoLibreMe1List from "../views/MercadoLibre/Me1/List.vue";

export default [
  {
    path: "/mercadolibre/:integrationConfigId/items/:itemId/fulfillment/stock",
    name: "MercadoLibreItemFulfillmentStock",
    component: MercadoLibreItemFulfillmentStock,
    meta: {
      requireAuth: true,
      action: "Stock de fulfillment",
      title: "Stock | Fulfillment"
    },
    props: true
  },
  {
    path:
      "/mercadolibre/:integrationConfigId/items/:itemId/fulfillment/operations",
    name: "MercadoLibreItemFulfillmentOperations",
    component: MercadoLibreItemFulfillmentOperations,
    meta: {
      requireAuth: true,
      action: "Operaciones de fulfillment",
      title: "Operaciones | Fulfillment"
    },
    props: true
  },
  {
    path: "/mercadolibre/attributes/integrations",
    name: "MercadoLibreAttributesIntegrations",
    component: MercadoLibreAttributeIntegrationsSelector,
    meta: {
      requireAuth: true,
      action: "Seleccionar IntegrationConfig",
      title: "Selecionar Integración"
    }
  },
  {
    path: "/mercadolibre/attributes/:integrationConfigId/groups",
    name: "MercadoLibreAttributeGroups",
    component: MercadoLibreAttributeGroups,
    meta: {
      requireAuth: true,
      action: "Categorias agrupadas"
    },
    props: true
  },
  {
    path: "/mercadolibre/attributes/:attributeGroupIntegrationConfigId",
    name: "MercadoLibreAttribute",
    component: MercadoLibreAttributes,
    meta: {
      requireAuth: true,
      action: "Categorias agrupadas"
    },
    props: true
  },
  {
    path: "/mercadolibre/catalog_suggestions/integrations",
    name: "MercadoLibreCatalogSuggestionsIntegrations",
    component: MercadoLibreCatalogSuggestionsIntegrations,
    meta: {
      requireAuth: true,
      action: "Seleccionar IntegrationConfig",
      title: "Selecionar Integración"
    }
  },
  {
    path: "/mercadolibre/catalog_suggestions/:integrationConfigId/list",
    name: "MercadoLibreCatalogSuggestionsList",
    component: MercadoLibreCatalogSuggestionsList,
    meta: {
      requireAuth: true,
      action: "Seleccionar IntegrationConfig",
      title: "Selecionar Integración"
    },
    props: true
  },
  {
    path: "/mercadolibre/catalog_suggestions/:integrationConfigId",
    name: "MercadoLibreCatalogSuggestion",
    component: MercadoLibreCatalogSuggestion,
    children: [
      {
        path: "new",
        name: "MercadoLibreCatalogSuggestionCreation",
        component: MercadoLibreCatalogSuggestionCreation,
        meta: {
          requireAuth: true,
          action: "Crear Sugerencia"
        },
        props: true
      },
      {
        path: ":catalogSuggestionId/edit",
        name: "MercadoLibreCatalogSuggestionEdit",
        component: MercadoLibreCatalogSuggestionEdit,
        meta: {
          requireAuth: true,
          action: "Editar Sugerencia"
        },
        props: true
      }
    ],
    props: true
  },
  {
    path: "/mercadolibre/highlights",
    name: "MercadoLibreHighlights",
    component: MercadoLibreHighlights,
    meta: {
      requireAuth: true,
      action: "Productos más vendidos"
    },
    props: true
  },
  {
    path: "/mercadolibre/highlights/:siteId",
    name: "MercadoLibreHighlightsBySite",
    component: MercadoLibreHighlightsByCategory,
    meta: {
      requireAuth: true,
      action: "Productos más vendidos por sitio"
    },
    props: true
  },
  {
    path: "/mercadolibre/highlights/:siteId/:categoryId",
    name: "MercadoLibreHighlightsByCategory",
    component: MercadoLibreHighlightsByCategory,
    meta: {
      requireAuth: true,
      action: "Productos más vendidos por categoría"
    },
    props: true
  },
  {
    path: "/mercadolibre/promotions/",
    name: "MercadoLibrePromotions",
    component: MercadoLibrePromotions,
    meta: {
      requireAuth: true,
      action: "Seleccionar IntegrationConfig",
      title: "Selecionar Integración"
    }
  },
  {
    path: "/mercadolibre/promotions/:integrationConfigId",
    name: "MercadoLibrePromotionsIndex",
    component: MercadoLibrePromotionsIndex,
    meta: {
      requireAuth: true
      // action: "Seleccionar IntegrationConfig",
    },
    props: true
  },
  {
    path: "/mercadolibre/promotions/:integrationConfigId/deals/:dealId",
    name: "MercadoLibrePromotionsDealIndex",
    component: MercadoLibrePromotionsDealIndex,
    meta: {
      requireAuth: true
      // action: "Seleccionar IntegrationConfig",
    },
    props: true
  },
  {
    path:
      "/mercadolibre/promotions/:integrationConfigId/marketplace_campaign/:campaignId",
    name: "MercadoLibrePromotionsCampaignIndex",
    component: MercadoLibrePromotionsCampaignIndex,
    meta: {
      requireAuth: true
      // action: "Seleccionar IntegrationConfig",
    },
    props: true
  },
  {
    path:
      "/mercadolibre/promotions/:integrationConfigId/deal_of_the_day/:dodId",
    name: "MercadoLibrePromotionsDodIndex",
    component: MercadoLibrePromotionsDodIndex,
    meta: {
      requireAuth: true
    },
    props: true
  },
  {
    path:
      "/mercadolibre/promotions/:integrationConfigId/lightning/:lightningId",
    name: "MercadoLibrePromotionsLightningIndex",
    component: MercadoLibrePromotionsLightningIndex,
    meta: {
      requireAuth: true
    },
    props: true
  },
  {
    path: "/mercadolibre/promotions/:integrationConfigId/smart/:smartId",
    name: "MercadoLibrePromotionsLightningIndex",
    component: MercadoLibrePromotionsSmartIndex,
    meta: {
      requireAuth: true
    },
    props: true
  },
  {
    path: "/mercadolibre/quotations/",
    name: "MercadoLibreQuotations",
    component: MercadoLibreQuotations,
    meta: {
      requireAuth: true,
      action: "Seleccionar IntegrationConfig",
      title: "Selecionar Integración"
    }
  },
  {
    path: "/mercadolibre/dynamic_shipping/:integrationConfigId/list",
    name: "MercadoLibreCatalogDestinationPricesList",
    component: MercadoLibreCatalogDestinationPricesList,
    meta: {
      requireAuth: true,
      action: "Listado de Tarifas para Flete Dinámico Mercado Libre"
    },
    props: true
  },
  {
    path: "/mercadolibre/news/:integrationConfigId",
    name: "MercadoLibreNewsIndex",
    component: MercadoLibreNewsIndex,
    meta: {
      requireAuth: true
    },
    props: true
  },
  {
    path: "/mercadolibre/me1/",
    name: "MercadoLibreMe1",
    component: MercadoLibreMe1,
    meta: {
      requireAuth: true,
      action: "Seleccionar IntegrationConfig"
    }
  },
  {
    path: "/mercadolibre/me1/:integrationConfigId/list",
    name: "MercadoLibreMe1List",
    component: MercadoLibreMe1List,
    meta: {
      requireAuth: true,
      action: "Listado de Recomendaciones de Moda Mercado Libre"
    },
    props: true
  },
  {
    path: "/mercadolibre/recommendations/",
    name: "MercadoLibreRecommendations",
    component: MercadoLibreRecommendations,
    meta: {
      requireAuth: true,
      action: "Seleccionar IntegrationConfig"
    }
  },
  {
    path: "/mercadolibre/recommendations/:integrationConfigId/list",
    name: "MercadoLibreRecommendationsList",
    component: MercadoLibreRecommendationsList,
    meta: {
      requireAuth: true,
      action: "Listado de Recomendaciones de Moda Mercado Libre"
    },
    props: true
  },
  {
    path: "/mercadolibre/not_working_days/",
    name: "NotWorkingDaysSelector",
    component: NotWorkingDaysSelector,
    meta: {
      requireAuth: true,
      action: "Seleccionar IntegrationConfig"
    }
  },
  {
    path: "/mercadolibre/not_working_days/:integrationConfigId/list",
    name: "NotWorkingDaysList",
    component: NotWorkingDaysList,
    meta: {
      requireAuth: true,
      action: "Listado de días no laborales Mercado Libre"
    },
    props: true
  }
];
