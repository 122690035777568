<template>
  <b-tr v-if="product.deleting">
    <b-td colspan="2">
      <div class="bottom">Eliminando...</div>
    </b-td>
    <b-td colspan="2">
      <div class="bottom">
        Este mapeo se está eliminando. Pronto dejará de aparecer en el catálogo
      </div>
    </b-td>
  </b-tr>
  <b-tr v-else>
    <b-td>
      <div class="top">{{ product.integrationConfig.label }}</div>
    </b-td>
    <b-td>
      <div class="top">{{ product.modelType }}</div>
    </b-td>
    <b-td>
      <div class="top">{{ product.homologationMaps.length }}</div>
    </b-td>
    <b-td>
      <button
        type="button"
        class="btn btn-primary btn-sm"
        @click="edit(product.id)"
      >
        Editar
      </button>
      <!--      <button type="button" class="btn btn-danger btn-sm" @click="cancel()">Borrar</button>-->
    </b-td>
  </b-tr>
</template>

<script>
export default {
  name: "TranslationCentryIntegrationsTableRow",
  props: {
    product: Object
    // integrationConfigId: this.$route.params.integrationConfigId
  },
  methods: {
    edit(translation_id) {
      this.$router.push(
        "/integracion/" +
          this.$route.params.integrationConfigId +
          "/translation_map/" +
          translation_id +
          "/edit"
      );
    }
  }
};
</script>

<style scoped></style>
