<template>
  <b-row>
    <b-col md="6" offset-md="6">
      <b-form-tags placeholder="Nombre" v-model="name"></b-form-tags>
    </b-col>
  </b-row>
</template>
<script>
import { mapMutations } from "vuex";
export default {
  name: "OptionListFilter",
  data() {
    return {
      name: []
    };
  },
  methods: {
    ...mapMutations(["setFilter"])
  },
  watch: {
    name() {
      this.setFilter({ name: this.name.join(",") });
    }
  }
};
</script>
