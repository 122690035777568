<template>
  <div id="landing-container">
    <b-navbar toggleable="lg" type="dark">
      <b-navbar-brand :href="centryUrl">
        <img src="../assets/images/logo-centry-532x128-24.png" alt="Centry" />
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item
            :href="centryUrl"
            class="font-weight-bolder"
            link-classes="text-white"
            >INICIO</b-nav-item
          >
          <b-nav-item
            :href="centryUrl + '/pages/product'"
            class="font-weight-bolder"
            link-classes="text-white"
            >SERVICIO</b-nav-item
          >
          <b-nav-item
            :href="centryUrl + '/pages/integrations'"
            class="font-weight-bolder"
            link-classes="text-white"
            >INTEGRACIONES</b-nav-item
          >
          <b-nav-item
            :href="centryUrl + '/pages/contact'"
            class="font-weight-bolder"
            link-classes="text-white"
            >COTIZACIÓN</b-nav-item
          >
          <b-nav-item :href="centryUrl + '/registro'" class="font-weight-bolder"
            >REGÍSTRATE</b-nav-item
          >
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <div id="landing-body">
      <slot></slot>
    </div>
    <base-landing-template-footer></base-landing-template-footer>
  </div>
</template>
<script>
import BaseLandingTemplateFooter from "./BaseLandingTemplateFooter";
import { centryUrl } from "../main";
export default {
  name: "BaseLandingTemplate",
  data() {
    return {
      centryUrl
    };
  },
  components: {
    BaseLandingTemplateFooter
  }
};
</script>

<style scoped>
#landing-container {
  background-image: url(".././assets/images/bground-2000x1333-54.jpg");
  padding-top: 10px;
  padding-left: 15px;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  width: 100%;
}
img {
  height: 3.125rem;
}
#landing-container a {
  color: #f1c050;
  text-decoration: none;
}
#landing-container a:focus,
#loglandingin-container a:hover {
  color: #e2a312 !important;
  text-decoration: underline !important;
}
</style>
