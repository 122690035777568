<template>
  <div>
    <ApolloQuery
      :query="require('../graphql/Banners/Banner.gql')"
      :variables="queryVariables()"
      fetchPolicy="no-cache"
    >
      <template slot-scope="{ result: { data }, isLoading }">
        <base-header
          title="Banners"
          title-link="/admin/banners"
          :titleSize="0"
          :map="
            $dig(data, 'banner', 'eventName')
              ? [$route.meta.action, $dig(data, 'banner', 'eventName')]
              : [$route.meta.action]
          "
        >
        </base-header>
        <b-container fluid="">
          <router-view
            :is-loading-banner="isLoading == 1"
            :banner="$dig(data, 'banner')"
          ></router-view>
        </b-container>
      </template>
    </ApolloQuery>
  </div>
</template>

<script>
import BaseHeader from "../components/BaseHeader.vue";

export default {
  name: "Banner",
  components: {
    BaseHeader
  },
  methods: {
    /**
     * Entrega las variables para la query que permite obtener el banner
     */
    queryVariables() {
      let variables = {};
      variables.id = "new";
      if (this.$route.params.id) {
        variables.id = this.$route.params.id;
      }
      return variables;
    }
  }
};
</script>

<style></style>
