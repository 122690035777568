<template>
  <b-tr>
    <b-td>
      <router-link :to="{ name: 'ProductEdit', params: { id: product.id } }">{{
        product.name
      }}</router-link>
    </b-td>
    <b-td v-for="sync of sortedSynchronizations" :key="sync.id">
      <b-icon
        font-scale="2.5"
        icon="check"
        variant="success"
        v-if="sync.status == 'ok'"
        v-b-tooltip="'Sincronización correcta'"
      ></b-icon>
      <b-icon
        font-scale="2"
        icon="exclamation-triangle-fill"
        variant="danger"
        v-if="sync.status == 'failed'"
        v-b-tooltip="'Sincronización con errores'"
        v-b-modal="`modal-sync-errors-${sync.id}`"
      ></b-icon>
      <b-icon
        font-scale="2"
        icon="three-dots"
        variant="secondary"
        v-if="!sync.status || sync.status == 'not_synched'"
        v-b-tooltip="'Sin sincronizar'"
      ></b-icon>
      <b-icon
        font-scale="2"
        icon="dash-circle-fill"
        variant="secondary"
        v-if="sync.status == 'excluded'"
        v-b-tooltip="'Producto excluido'"
      ></b-icon>
      <b-modal
        :id="`modal-sync-errors-${sync.id}`"
        title="Error de sincronización"
        :hide-footer="true"
        size="lg"
      >
        <b-table
          striped
          hover
          :items="sync.errorTranslations"
          :fields="errorTableFields"
        ></b-table>
      </b-modal>
    </b-td>
  </b-tr>
</template>

<script>
export default {
  name: "LastSynchronizationStatusesListItem",
  computed: {
    /**
     * Entrega el listado de sincronizaciones del producto filtradas y ordenadas
     * según la selección que se tenga de integraciones. De esta manera el
     * resultado de sincronizaciones se puede mostrar en la columna que
     * corresponde.
     *
     * @return {Array}
     */
    sortedSynchronizations() {
      return this.integrationsSelected
        .map(integration =>
          this.product.lastSynchronizationStatuses.find(
            sync => sync.integrationConfigId == integration.id
          )
        )
        .map(s =>
          s
            ? Object.assign(this.$dup(s), {
                id: `${this.product.id}-${s.integrationConfigId}`
              })
            : {}
        );
    }
  },
  props: {
    product: Object,
    integrationsSelected: Array
  },
  data() {
    return {
      errorTableFields: [
        {
          key: "originalMessage",
          label: "Mensaje original",
          sortable: true
        },
        {
          key: "errorTranslation.translation",
          label: "Traducción de Centry",
          sortable: true
        }
      ]
    };
  }
};
</script>
