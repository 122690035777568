<template>
  <div>
    <integration-config-tabs
      :title-integration-data="'Datos para Falabella'"
      :ic="ic"
      :tempView="true"
    >
    </integration-config-tabs>
  </div>
</template>

<script>
import IntegrationConfigTabs from "../../components/IntegrationConfigs/Tabs.vue";

export default {
  name: "IntegrationConfigFalabella",
  components: {
    IntegrationConfigTabs
  },
  props: {
    ic: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {};
  },
  methods: {}
};
</script>

<style scoped></style>
