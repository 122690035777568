<template>
  <b-container>
    <b-row>
      <b-col>
        <b-alert v-if="errorMessage" show="" variant="danger" dismissible>
          {{ errorMessage }}
        </b-alert>
        <b-alert v-if="successfulMessage" show="" variant="success" dismissible>
          {{ successfulMessage }}
        </b-alert>
        <b-alert v-if="warningMessage" show="" variant="warning" dismissible>
          <b-icon-info-circle></b-icon-info-circle> {{ warningMessage }}
        </b-alert>
      </b-col>
    </b-row>

    <div v-if="!hideButtonAndTable">
      <b-row>
        <b-col>
          <b-button
            variant="outline-info"
            @click="createSpecificationValuesModal"
          >
            <b-icon-plus></b-icon-plus> Crear valor de especificacion
          </b-button>

          <vtex-specification-values-modal
            v-model="showSpecificationValuesModal"
            :vtexIntegrationConfigId="vtexIntegrationConfigId"
            :action="specificationValuesModalAction"
            :specificationValueId="specificationValueId"
            :specification="specification"
            @errorMessage="setErrorMessage"
            @successfulMessage="setSuccessfulMessage"
          >
          </vtex-specification-values-modal>
        </b-col>
      </b-row>

      <br />

      <vtex-specification-values-table
        :vtexIntegrationConfigId="vtexIntegrationConfigId"
        :specificationId="specification.id"
        @openSpecificationValuesModal="updateSpecificationValuesModal"
        @errorMessage="setErrorMessage"
        @warningMessage="setWarningMessage"
        @setHideButtonAndTable="setHideButtonAndTable"
      >
      </vtex-specification-values-table>
    </div>
  </b-container>
</template>

<script>
import VtexSpecificationValuesModal from "./VtexSpecificationValuesModal.vue";
import VtexSpecificationValuesTable from "./VtexSpecificationValuesTable.vue";

export default {
  name: "VtexSpecificationValuesMaintainer",
  components: {
    VtexSpecificationValuesModal,
    VtexSpecificationValuesTable
  },
  props: {
    vtexIntegrationConfigId: {
      type: String,
      required: true
    },
    specification: {
      required: true,
      validator: prop => typeof prop === "object" || prop === null
    }
  },
  data() {
    return {
      showSpecificationValuesModal: false,
      specificationValuesModalAction: null,
      specificationValueId: null,
      errorMessage: null,
      successfulMessage: null,
      warningMessage: null,
      hideButtonAndTable: false
    };
  },
  methods: {
    /**
     * Abre el modal de edición de valor de especificacion para crear uno nuevo.
     */
    createSpecificationValuesModal() {
      this.specificationValuesModalAction = "create";
      this.specificationValueId = null;
      this.showSpecificationValuesModal = true;
    },
    /**
     * Abre el modal de edición de valor de especificacion para editar uno.
     * @param {String} specificationValueId
     */
    updateSpecificationValuesModal(specificationValueId) {
      this.specificationValuesModalAction = "update";
      this.specificationValueId = specificationValueId;
      this.showSpecificationValuesModal = true;
    },
    /**
     * Guarda el mensaje de error en la data y vuelve al inicio de la página
     * @param {String} errorMessage
     */
    setErrorMessage(errorMessage) {
      this.errorMessage = errorMessage;
      this.scrollToTop();
    },
    /**
     * Guarda el mensaje de éxito en la data y vuelve al inicio de la página
     * @param {String} successfulMessage
     */
    setSuccessfulMessage(message) {
      this.successfulMessage = message;
      this.scrollToTop();
    },
    /**
     * Guarda el mensaje de la adverterncia en la data y vuelve al inicio de la página
     * @param {String} message
     */
    setWarningMessage(message) {
      this.warningMessage = message;
      this.scrollToTop();
    },
    /**
     * Decide si ocultar o no los componentes de la vista.
     * @param {Boolean} hide
     */
    setHideButtonAndTable(hide) {
      this.hideButtonAndTable = hide;
    },
    /**
     * Emite el evento 'scrollToTop' para volver al inicio de la página
     */
    scrollToTop() {
      this.$emit("scrollToTop");
    }
  },
  watch: {
    errorMessage: function(newErrorMessage) {
      if (newErrorMessage !== null) {
        this.successfulMessage = null;
        this.warningMessage = null;
      }
    },
    successfulMessage: function(newMessage) {
      if (newMessage !== null) {
        this.errorMessage = null;
        this.warningMessage = null;
      }
    },
    warningMessage: function(newMessage) {
      if (newMessage !== null) {
        this.errorMessage = null;
        this.successfulMessage = null;
      }
    },
    specification: function() {
      this.hideButtonAndTable = false;
      this.errorMessage = null;
      this.successfulMessage = null;
      this.warningMessage = null;
    }
  }
};
</script>
