<template>
  <div>
    <base-header
      title="Atributos de Mercado Libre"
      title-link="#"
      :map="['Mercado Libre', 'Atributos', 'Integraciones']"
      :title-size="12"
    >
    </base-header>

    <b-row>
      <b-col cols="12">
        <mercado-libre-select-integration
          url-name="MercadoLibreAttributeGroups"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import BaseHeader from "@/components/BaseHeader.vue";
import MercadoLibreSelectIntegration from "../components/MercadoLibre/SelectIntegration.vue";

export default {
  name: "MercadoLibreAttributeIntegrationsSelector",
  components: { MercadoLibreSelectIntegration, BaseHeader },
  data() {
    return {};
  }
};
</script>
