var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-header',{attrs:{"title":"Moderaciones","title-link":"/moderations","map":[]}}),_c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-button',{staticStyle:{"margin-left":"80%"},attrs:{"variant":"outline-info","size":"sm","disabled":_vm.disabled},on:{"click":_vm.unpublish}},[_vm._v(" Despublicar ")]),_c('b-col',{attrs:{"md":"12"}},[_c('ApolloQuery',{attrs:{"query":require('../graphql/ModerationListCount.gql')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.result.data;
var isLoading = ref.isLoading;
return [(isLoading)?_c('b-spinner',{attrs:{"label":"Spinning"}}):_vm._e(),_c('span',{staticClass:"text-secondary"},[_vm._v("Total de productos con problemas: "+_vm._s(data.moderationListCount))])]}}])}),_c('ApolloQuery',{attrs:{"query":require('../graphql/AllModerationList.gql')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.result.data;
var isLoading = ref.isLoading;
return [(isLoading)?_c('b-spinner',{attrs:{"label":"Spinning"}}):_c('b-table-simple',{attrs:{"hover":""}},[_c('b-thead',[_c('b-tr',[_c('b-th'),_c('b-th',[_vm._v("Nombre")]),_c('b-th',[_vm._v("SKU")]),_c('b-th',[_vm._v("Problemas")]),_c('b-th')],1)],1),_c('b-tbody',_vm._l((data.allModerationList),function(productModeration){return _c('b-tr',{key:productModeration.product.id},[_c('b-td',[(!productModeration.unpublished)?_c('input',{staticClass:"mx-2 mb-2",attrs:{"type":"checkbox","id":'checkbox-' + productModeration.product.id},on:{"change":function($event){return _vm.checkStatus($event, productModeration.product.id)}}}):_vm._e()]),_c('b-td',[_vm._v(_vm._s(productModeration.product.name))]),_c('b-td',[_vm._v(_vm._s(productModeration.product.sku))]),_c('b-td',[_vm._v(_vm._s(_vm.problemString(productModeration.moderationCount))+" en "+_vm._s(_vm.integrationString( productModeration.integrationConfigCount )))]),_c('b-td',{staticClass:"actioncell"},[_c('b-dropdown',{attrs:{"variant":"white","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('b-icon-three-dots-vertical')]},proxy:true}],null,true)},[_c('b-dropdown-item',{attrs:{"to":'/moderations/' +
                            productModeration.product.id +
                            '/details'}},[_vm._v("Detalle")])],1)],1)],1)}),1)],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }