<template>
  <div class="accordion" role="tablist" :id="id">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "BaseAccordion",
  props: {
    id: {
      type: String,
      default: "accordion"
    }
  }
};
</script>
