<template>
  <div>
    <b-alert
      v-if="!allowSave || error || success"
      show=""
      :variant="alertVariant"
      dismissible
    >
      {{ alertMessage }}
    </b-alert>
    <b-row v-if="loading">
      <b-col v-for="i in 6" :key="i" cols="3" class="mb-5">
        <b-skeleton
          animation="wave"
          type="input"
          :width="`${80 - Math.floor(Math.random() * 20)}%`"
        />
      </b-col>
    </b-row>
    <div v-else>
      <b-row align-h="between">
        <b-col xl="10" cols="12">
          <b-row>
            <b-col v-if="allowSave" lg="4" sm="6" cols="12">
              <base-with-show-password-input
                id="accessToken"
                v-model="accessToken"
                placeholder=""
                description="Ojo: Este dato es el enlace con tu cuenta de BSale. Solo modificalo cuando estés seguro."
              >
                <template #label>
                  Access token
                  <b-spinner
                    v-if="loadingAvailable"
                    small
                    label="Spinning"
                  ></b-spinner>
                  <b-icon
                    v-else
                    :icon="availableIcon"
                    :variant="availableVariant"
                    :title="availableMessage"
                  ></b-icon>
                </template>
                <template #input v-if="!changeAccesToken">
                  <b-button
                    @click="confirmChangeAccessToken"
                    size="sm"
                    variant="warning"
                  >
                    Modificar Access Token</b-button
                  >
                </template>
              </base-with-show-password-input>
            </b-col>
            <b-col lg="4" sm="6" cols="12">
              <b-form-group label-for="use" label="Tipo de integración">
                <v-select
                  id="use"
                  v-model="use"
                  :disabled="!allowSave"
                  :options="uses"
                  :clearable="false"
                  :reduce="cond => cond.value"
                ></v-select>
              </b-form-group>
            </b-col>
            <b-col lg="4" sm="6" cols="12">
              <b-form-group label="Estado de la integración">
                <base-boolean-selector
                  v-model="status"
                  :disabled="!allowSave"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" class="my-2">
              <h4 class="font-weight-bold my-auto">
                Información de BSale
                <b-spinner
                  v-if="loadingInfo"
                  small
                  label="Spinning"
                ></b-spinner>
                <b-icon
                  v-if="clientInfoError !== null"
                  icon="exclamation-circle-fill"
                  variant="danger"
                  :title="clientInfoError"
                ></b-icon>
              </h4>
            </b-col>
            <b-col lg="4" sm="6" cols="12">
              <base-input-with-skeleton
                label="Nombre de la compañia"
                readonly
                v-model="bsaleInfo.name"
                :loading="loadingInfo"
              />
            </b-col>
            <b-col lg="4" sm="6" cols="12">
              <base-input-with-skeleton
                label="Rut"
                readonly
                v-model="bsaleInfo.code"
                :loading="loadingInfo"
              />
            </b-col>
            <b-col lg="4" sm="6" cols="12">
              <base-input-with-skeleton
                label="ID de la compañia"
                readonly
                v-model="bsaleInfo.id"
                :loading="loadingInfo"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col xl="2" cols="12">
          <b-button
            variant="info"
            :disabled="!allowSave || !changed || saving"
            :title="titleSave"
            @click="save"
            class="mb-3 w-100"
          >
            <span v-if="saving"><b-spinner label="Spinning"></b-spinner></span>
            <span v-else>Guardar</span>
          </b-button>
          <b-button
            variant="outline-info"
            class="w-100 px-0 mb-3"
            :disabled="!changed || saving"
            @click="resetData()"
            v-if="allowSave"
          >
            <span>Restablecer</span>
          </b-button>

          <b-button
            variant="danger"
            class="w-100 px-0"
            @click="swalDeleteIntegration"
            v-if="allowDelete"
          >
            <span>Eliminar integración</span>
          </b-button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import BaseWithShowPasswordInput from "../../Base/WithShowPasswordInput.vue";
import BaseInputWithSkeleton from "../../Base/InputWithSkeleton.vue";
import BaseBooleanSelector from "../../BaseBooleanSelector.vue";
import LINKING_INFORMATION from "../../../graphql/IntegrationConfig/BSale/LinkingInformation.gql";
import BSALE_INFO from "../../../graphql/IntegrationConfig/BSale/BSaleInfo.gql";
import BAD_CONNECTION_INFORMATION from "../../../graphql/IntegrationConfig/BadConnectionInformation.gql";
import UPDATE_INTEGRATION_CONFIG_BSALE from "../../../graphql/IntegrationConfig/BSale/UpdateIntegrationConfigBSale.gql";
import DELETE_INTEGRATION_CONFIG from "../../../graphql/IntegrationConfig/DeleteIntegrationConfig.gql";
import { mapState, mapMutations } from "vuex";
export default {
  components: {
    BaseBooleanSelector,
    BaseWithShowPasswordInput,
    BaseInputWithSkeleton
  },
  name: "IntegrationConfigBSaleLinkingInformation",
  props: {
    ic: {
      type: Object,
      default() {
        return {};
      }
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      originalValues: {},
      loading: false,
      saving: false,
      errorMessage: null,
      warningMessage: null,
      error: false,
      success: false,
      uses: [
        { label: "Esclavo", value: "slave" },
        { label: "Maestro", value: "master" },
        { label: "Híbrido", value: "hybrid" }
      ],
      status: null,
      use: null,
      accessToken: null,
      badConnection: null,
      bsaleInfo: {},
      loadingAvailable: false,
      loadingInfo: false,
      changeAccesToken: false,
      clientInfoError: null
    };
  },
  async mounted() {
    await this.getLinkingData().then(() => {
      this.getBadConnectionData();
      this.getBSaleInfo();
    });
  },
  computed: {
    ...mapState(["currentUser"]),
    available() {
      return this.badConnection === null || this.badConnection.length === 0;
    },
    availableMessage() {
      if (this.available) {
        return "Cuenta correctamente conectada";
      }
      return this.badConnection;
    },
    availableIcon() {
      if (this.available) {
        return "check-circle";
      }
      return "exclamation-circle-fill";
    },
    availableVariant() {
      if (this.available) {
        return "success";
      }
      return "danger";
    },
    titleSave() {
      if (!this.allowSave) {
        return "No tiene permitido modificar estos datos";
      }
      return this.changed ? "" : "No hay cambios";
    },
    values() {
      return {
        status: this.status,
        use: this.use
      };
    },
    /**
     * Indica si tiene o no permiso para modificar
     * los datos de la integración
     * @return {Boolean}
     */
    allowSave() {
      const companyUserGroup = this.$userGroup(this.currentUser);
      return (
        this.currentUser.role === 0 ||
        this.$ifNull(this.$dig(companyUserGroup, "integrations"), 0) & 4
      );
    },
    /**
     * Indica si tiene o no permiso para eliminar la integracion
     * @return {Boolean}
     */
    allowDelete() {
      const companyUserGroup = this.$userGroup(this.currentUser);
      return (
        this.currentUser.role === 0 ||
        this.$ifNull(this.$dig(companyUserGroup, "integrations"), 0) & 8
      );
    },
    changed() {
      if (this.accessToken !== null && this.accessToken !== "") {
        return true;
      }
      return !this.$objSimpleCompare(this.originalValues, this.values);
    },
    alertVariant() {
      if (!this.allowSave || (this.success && this.warningMessage?.length)) {
        return "warning";
      }
      return this.error ? "danger" : "success";
    },
    alertMessage() {
      if (!this.allowSave) {
        return "No tienes permisos para modificar este recurso, el formulario es de solo lectura";
      }
      if (this.success && this.warningMessage?.length) {
        return this.warningMessage;
      }
      return this.error
        ? "Ha ocurrido un error guardando los datos: " + this.errorMessage
        : "Guardado exitosamente";
    }
  },
  methods: {
    ...mapMutations(["setIntegrationConfigChanged"]),
    /**
     * Se encarga de obtener el valor actual de
     * los campos para la vinculación de la integración
     */
    async getLinkingData() {
      this.loading = true;
      await this.$apollo
        .query({
          query: LINKING_INFORMATION,
          variables: { integrationConfigId: this.ic.id }
        })
        .then(({ data }) => {
          this.originalValues = {
            status: data.integrationConfigBSale.status,
            use: data.integrationConfigBSale.use
          };
          this.status = data.integrationConfigBSale.status;
          this.use = data.integrationConfigBSale.use;
          this.loading = false;
        });
    },
    /**
     * Obtiene la información de errores de
     * vinculación/conexión de BSale
     */
    async getBadConnectionData() {
      this.loadingAvailable = true;
      return this.$apollo
        .query({
          query: BAD_CONNECTION_INFORMATION,
          variables: { integrationConfigId: this.ic.id },
          fetchPolicy: "no-cache"
        })
        .then(({ data }) => {
          this.badConnection = data.integrationConfig.badConnection;
          this.loadingAvailable = false;
          if (this.badConnection) this.changeAccesToken = true;
        });
    },
    /**
     * Se encarga de obtener la informacin de bsale
     */
    async getBSaleInfo() {
      this.loadingInfo = true;
      await this.$apollo
        .query({
          query: BSALE_INFO,
          variables: { integrationConfigId: this.ic.id },
          fetchPolicy: "no-cache"
        })
        .then(({ data }) => {
          this.bsaleInfo = data.integrationConfigBSale.bsaleInfo;
          this.clientInfoError = this.bsaleInfo.error;
          this.loadingInfo = false;
        });
    },
    /**
     * Envia la mutacion para guardar los nuevos
     * valores de vinculación de la cuenta
     */
    save() {
      this.saving = true;
      this.error = false;
      this.success = false;
      this.errorMessage = null;
      this.warningMessage = null;
      this.clientInfoError = null;
      const toSend = Object.assign({}, this.values);
      if (this.accessToken !== null && this.accessToken.length) {
        toSend.accessToken = this.accessToken;
      }
      this.$apollo
        .mutate({
          mutation: UPDATE_INTEGRATION_CONFIG_BSALE,
          variables: {
            integrationConfigId: this.ic.id,
            patch: toSend
          }
        })
        .then(({ data }) => {
          this.saving = false;
          if (data?.updateIntegrationConfigBSale?.result) {
            if (data?.updateIntegrationConfigBSale?.warning?.length) {
              this.warningMessage =
                "Guardado exitosamente pero " +
                data?.updateIntegrationConfigBSale?.warning;
            }
            this.updateTabs();
            this.error = false;
            this.success = true;
            this.originalValues = this.$dup(this.values);
            this.accessToken = null;
            this.bsaleInfo = {};
            this.getBadConnectionData();
            this.getBSaleInfo();
            this.changeAccesToken = false;
          } else {
            this.errorMessage = data?.updateIntegrationConfigBSale?.error;
            this.error = true;
          }
        })
        .catch(e => {
          this.saving = false;
          this.errorMessage = e;
          this.error = true;
        });
    },
    /**
     * Si se cambia el tipo de reintegración,
     * hay que reiniciar la vista de datos de la integracion
     */
    updateTabs() {
      if (this.use !== this.originalValues.use) {
        this.$emit("onUpdateTabs");
      }
    },
    /**
     * Se encarga de lanzar un swal para la
     * confirmacion de la eliminacion de la integracion
     */
    swalDeleteIntegration() {
      this.$swal
        .fire({
          title: "Eliminar integración",
          text: "Se eliminará la integración de BSale. ¿Estás seguro?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si",
          cancelButtonText: "No"
        })
        .then(result => {
          if (result.value) {
            this.deleteIntegration();
          }
        });
    },
    /**
     * Envia la mutacion para eliminar la integracion
     */
    deleteIntegration() {
      this.saving = true;
      this.error = false;
      this.success = false;
      this.errorMessage = null;
      this.warningMessage = null;
      this.$apollo
        .mutate({
          mutation: DELETE_INTEGRATION_CONFIG,
          variables: {
            integrationConfigId: this.ic.id
          }
        })
        .then(({ data }) => {
          this.saving = false;
          if (data?.deleteIntegrationConfig?.result) {
            this.$router.push(`/company/${this.currentUser.company.id}`);
            this.success = true;
            this.warningMessage =
              "Redireccionando a Mi Empresa, espere por favor.";
          } else {
            this.errorMessage = data?.deleteIntegrationConfig?.error;
            this.error = true;
          }
        })
        .catch(e => {
          this.saving = false;
          this.errorMessage = e;
          this.error = true;
        });
    },
    /**
     * Se encarga de resetear los datos
     */
    resetData() {
      this.loading = true;
      this.use = this.originalValues.use;
      this.accessToken = null;
      this.status = this.originalValues.status;
      this.errorMessage = null;
      this.warningMessage = null;
      this.error = false;
      this.changeAccesToken = false;
      this.success = false;
      this.clientInfoError = null;
      this.loading = false;
    },
    /**
     * Se encarga de lanzar una swal de confirmacion
     * para la modificacion ddel access token
     */
    confirmChangeAccessToken() {
      this.$swal
        .fire({
          title: "Cambiar access token",
          text: "Se cambiará el enlace con tu cuenta de BSale. ¿Estás seguro?",
          footer: "Por seguridad no se mostrará el access token anterior",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si",
          cancelButtonText: "No"
        })
        .then(result => {
          if (result.value) {
            this.changeAccesToken = true;
          }
        });
    }
  },
  watch: {
    changed(val) {
      this.setIntegrationConfigChanged(val);
    },
    visible(val) {
      if (val) {
        this.resetData();
      }
    }
  }
};
</script>
