<template>
  <b-row>
    <mercado-libre-attribute-input
      :attribute="attribute"
      @input="input"
      v-for="attribute of component.attributes"
      :key="attribute.id"
      :previous="$dig(previous, attribute.id, 'value')"
    />
  </b-row>
</template>

<script>
import MercadoLibreAttributeInput from "./AttributeInput.vue";

export default {
  name: "MercadoLibreCatalogSuggestionFormAttributeInput",
  components: { MercadoLibreAttributeInput },
  props: {
    component: {
      type: Object,
      required: true
    },
    input: {
      type: Function,
      required: true
    },
    previous: {
      type: Object,
      required: false
    }
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {}
};
</script>
