<template>
  <div>
    <h4 v-if="loading || !url">{{ widget.name }}</h4>
    <a v-else :href="url" target="_BLANK"
      ><h4>{{ widget.name }}</h4></a
    >
    <div class="m-2">
      <b-skeleton-table
        :rows="10"
        :columns="3"
        v-if="loading"
      ></b-skeleton-table>
      <dashboard-bar-chart-from-table-data
        v-if="chart"
        :all-data="items"
        :labelColumn="labelColumn"
      />
      <simple-table-with-pagination
        v-else
        :perPage="5"
        :fields="null"
        :items="items"
        table-class="table-hover"
        :html="true"
      />
    </div>
  </div>
</template>
<script>
import DASHBOARD_TABLE_WITH_CENTRY_DATA_WIDGET from "@/graphql/DashboardTableWithCentryDataWidget.gql";
import SimpleTableWithPagination from "@/components/SimpleTableWithPagination.vue";
import DashboardBarChartFromTableData from "./BarChartFromTableData.vue";
export default {
  name: "DashboardTableWithCentryData",
  props: {
    widget: Object
  },
  data() {
    return {
      items: [],
      url: null,
      chart: false,
      loading: true,
      labelColumn: null
    };
  },
  components: {
    SimpleTableWithPagination,
    DashboardBarChartFromTableData
  },
  mounted() {
    this.loadWidgetData();
  },
  methods: {
    /**
     * Carga los datos del widget
     */
    async loadWidgetData() {
      this.loading = true;
      const response = await this.$apollo.query({
        query: DASHBOARD_TABLE_WITH_CENTRY_DATA_WIDGET,
        variables: {
          id: this.widget.id
        }
      });
      this.items = response.data.dashboardTableWithCentryDataWidget.tableData;
      this.url = response.data.dashboardTableWithCentryDataWidget.url;
      this.chart = response.data.dashboardTableWithCentryDataWidget.chart;
      this.labelColumn =
        response.data.dashboardTableWithCentryDataWidget.labelColumn;
      this.loading = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.table {
  border: none;
  padding: 0;
  thead {
    th {
      height: 25px;
    }
  }

  td {
    height: 30px;
    padding: 0 10px 0 10px !important;
  }
}
</style>
