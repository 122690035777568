<template>
  <div>
    <b-spinner label="Spinning" v-if="loading"></b-spinner>
    <b-table-simple v-else responsive>
      <b-thead>
        <b-th></b-th>
        <b-th v-for="ic of integrationConfigs" :key="ic.id">{{
          ic.label
        }}</b-th>
      </b-thead>
      <b-tbody>
        <b-tr>
          <b-td>Estado por integraciones</b-td>
          <b-td
            v-for="ic of integrationConfigs"
            :key="'s' + ic.id"
            @click="() => showModal(syncStatuses[ic.id])"
          >
            <span :title="syncStatuses[ic.id].tooltip" v-b-tooltip.hover="">
              <b-icon
                :icon="syncStatuses[ic.id].icon"
                :variant="syncStatuses[ic.id].iconVariant"
                font-scale="2"
              >
              </b-icon>
            </span>
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>Publicaciones</b-td>
          <b-td
            v-for="ic of integrationConfigs"
            :key="'s' + ic.id"
            @click="() => showModal(syncStatuses[ic.id])"
          >
            <ApolloQuery
              :query="require('../graphql/IntegrationProductLinks.gql')"
              :variables="{ integrationConfigId: ic.id, productId: product.id }"
              :fetchPolicy="'cache-and-network'"
              clientId="apolloClientCached"
            >
              <template slot-scope="{ result: { error, data }, isLoading }">
                <b-spinner
                  label="Spinning"
                  v-if="isLoading && !data"
                ></b-spinner>
                <span v-else-if="error">-- error al obtener el enlace --</span>
                <span v-else-if="thereAreLinks(data)">
                  <span v-if="data && isLoading">
                    Actualizando link..
                  </span>
                  <br />
                  <b-link
                    v-for="(link, idx) of links(data)"
                    :key="idx"
                    :href="link.href"
                    >{{ link.text }}</b-link
                  >
                </span>
                <span v-else>---</span>
              </template>
            </ApolloQuery>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <b-modal ref="integration-status-modal" :title="modalTitle">
      <div v-if="modalBody">{{ modalBody }}</div>
      <div v-if="errors.length">
        <b-table-simple>
          <b-thead>
            <b-th>Mensaje original</b-th>
            <b-th>Traducción de Centry</b-th>
          </b-thead>
          <b-tbody>
            <b-tr v-for="(err, idx) of errors" :key="idx">
              <b-td>{{ err.error }}</b-td>
              <b-td>{{ err.translation }}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </div>
    </b-modal>
  </div>
</template>
<script>
import INTEGRATION_CONFIGS_FOR_TABS from "../graphql/IntegrationConfigsForTabs.gql";
import PRODUCT_LAST_SYNCHRONIZATION_STATUSES from "../graphql/ProductLastSynchronizationStatuses.gql";

export default {
  name: "ProductDetailPageIntegrations",
  props: {
    product: Object
  },
  created() {
    this.$apollo
      .query({
        query: INTEGRATION_CONFIGS_FOR_TABS
      })
      .then(({ data }) => {
        if (data.allIntegrationConfigs) {
          this.integrationConfigs = data.allIntegrationConfigs.filter(
            x => x.identifier !== "application" && x.available
          );
        }
      })
      .then(() => {
        this.$apollo
          .query({
            query: PRODUCT_LAST_SYNCHRONIZATION_STATUSES,
            variables: {
              productIds: [this.product.id]
            }
          })
          .then(({ data }) => {
            let integrationStatuses = [];
            if (data?.allProductLastSynchronizationStatus?.edges) {
              integrationStatuses =
                data.allProductLastSynchronizationStatus.edges[0].node
                  .lastSynchronizationStatuses;
            }
            this.integrationConfigs.forEach(ic => {
              const integrationStatus = integrationStatuses.find(
                x => x.integrationConfigId === ic.id
              );
              this.syncStatuses[ic.id] = this.integrationStatus(
                integrationStatus
              );
            });
            this.loading = false;
          });
      });
  },
  data() {
    return {
      loading: true,
      integrationConfigs: [],
      syncStatuses: {},
      modalTitle: "",
      modalBody: "",
      errors: [],
      errorCategories: [
        { category: "image", title: "Imagen", icon: "image" },
        { category: "brand", title: "Marca", icon: "tag" },
        { category: "price", title: "Precio", icon: "credit-card" },
        { category: "content", title: "Contenido", icon: "file-earmark" },
        { category: "category", title: "Categoría", icon: "view-stacked" },
        { category: "stock", title: "Stock", icon: "hash" },
        {
          category: "seller_account",
          title: "Problemas con la cuenta",
          icon: "person"
        },
        { category: "undefined", title: "No definido", icon: "question" }
      ]
    };
  },
  methods: {
    showModal(iStatus) {
      if (iStatus.displayModal) {
        this.modalTitle = iStatus.modalTitle;
        this.modalBody = iStatus.modalBody;
        this.errors = iStatus.errors;
        this.$refs["integration-status-modal"].show();
      }
    },
    thereAreLinks(data) {
      return (
        data &&
        data.integrationConfig &&
        data.integrationConfig.publishedProductLinks &&
        data.integrationConfig.publishedProductLinks.length
      );
    },
    links(data) {
      let links = data.integrationConfig.publishedProductLinks;
      const hash = {};
      links.forEach(x => (hash[x] = true));
      links = Object.keys(hash);
      const finalLinkArray = [];
      if (links.length > 1) {
        links.forEach((x, idx) => {
          finalLinkArray.push({
            text: "Link " + (idx + 1),
            href: x
          });
        });
      } else {
        finalLinkArray.push({ text: "Link", href: links[0] });
      }
      return finalLinkArray;
    },
    /**
     * Genera la estructura necesaria para la celda de un "LastSynchronization"
     * dependiendo de su estado.
     * @param {Object} integrationStatus
     * @return {Object}
     */
    integrationStatus(integrationStatus) {
      switch (integrationStatus?.status) {
        case "ok":
          return this.integrationStatusOk();
        case "not_synched":
          return this.integrationStatusNotSynched();
        case "failed":
          return this.integrationStatusFailed(integrationStatus);
        case "excluded":
          return this.integrationStatusExcluded(integrationStatus);
        default:
          return this.integrationStatusEmpty();
      }
    },
    /**
     * Genera la estructura necesaria para la celda de un "LastSynchronization"
     * con estado "exitoso".
     * @return {Object}
     */
    integrationStatusOk() {
      return {
        icon: "check",
        iconVariant: "success",
        tooltip: "Sincronización correcta",
        displayModal: false,
        modalTitle: null,
        modalBody: null,
        errors: []
      };
    },
    /**
     * Genera la estructura necesaria para la celda de un "LastSynchronization"
     * con estado "sin sincronizar".
     * @return {Object}
     */
    integrationStatusNotSynched() {
      return {
        icon: "three-dots",
        iconVariant: "secondary",
        tooltip: "Sin sincronizar",
        displayModal: false,
        modalTitle: null,
        modalBody: null,
        errors: []
      };
    },
    /**
     * Genera la estructura necesaria para la celda de un "LastSynchronization"
     * sin estado.
     * @return {Object}
     */
    integrationStatusEmpty() {
      return {
        icon: "question",
        iconVariant: "secondary",
        tooltip: "Sin información"
      };
    },
    /**
     * Genera la estructura necesaria para la celda de un "LastSynchronization"
     * con estado "fallido".
     * @param {Object} integrationStatus
     * @return {Object}
     */
    integrationStatusFailed(integrationStatus) {
      return {
        icon: "exclamation-triangle-fill",
        iconVariant: "danger",
        tooltip: "Errores de sincronización",
        displayModal: true,
        modalTitle: "Error de sincronización",
        modalBody: null,
        errors: integrationStatus.errorTranslations.map(err => {
          return {
            error: err.originalMessage,
            translation: err.errorTranslation?.translation
          };
        })
      };
    },
    /**
     * Genera la estructura necesaria para la celda de un "LastSynchronization"
     * con estado "excluido".
     * @param {Object} integrationStatus
     * @return {Object}
     */
    integrationStatusExcluded(integrationStatus) {
      const exclusionReasonsMap = {
        product: "producto",
        brand: "marca",
        category: "categoría",
        gender: "género",
        price: "precio",
        status: "estado"
      };
      return {
        icon: "dash-circle-fill",
        iconVariant: "secondary",
        tooltip: "Producto excluido",
        displayModal: true,
        modalTitle: "Razones de exclusión",
        modalBody:
          "Excluido por: " +
          integrationStatus.exclusionReasons
            .map(x => exclusionReasonsMap[x])
            .join(", "),
        errors: []
      };
    }
  }
};
</script>
