<template>
  <div>
    <base-exclusions
      style="min-width: 100%"
      :ic="ic"
      :visible="visible"
      search-options-placeholder="Buscar géneros"
      selected-options-placeholder="Buscar géneros seleccionados"
      no-options-text="No hay géneros"
      selected-no-options-text="No hay géneros seleccionados"
      no-options-found-text="No se encontró el género"
      no-selected-options-found-text="
        No se encontró el género seleccionado
      "
      all-options-label="Todos los géneros"
      selected-options-label="Géneros seleccionados"
      :base-options="genders"
      :base-selected-options="selectedGenders"
      field-to-exclude="excludedGenderIds"
      field-to-include="includedGenderIds"
      field-excluded-products-without="excludeProductsWithoutGender"
      label-excluded-products-without="Excluir productos sin género"
      :base-excluded-products-without="excludedProductsWithout"
    >
    </base-exclusions>
  </div>
</template>

<script>
import ALL_GENDERS from "../../graphql/AllGenders.gql";
import EXCLUDED_GENDER_IDS from "../../graphql/IntegrationConfig/ExcludedGenderIds.gql";
import BaseExclusions from "./BaseExclusions.vue";
import { mapState } from "vuex";

export default {
  name: "ExclusionByGender",
  components: {
    BaseExclusions
  },
  props: {
    ic: {
      type: Object,
      default() {
        return {};
      }
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      genders: [],
      selectedGenders: [],
      excludedProductsWithout: null
    };
  },
  async mounted() {
    await this.getGenders();
    await this.getExcludedGenders();
  },
  computed: {
    ...mapState(["currentUser"])
  },
  methods: {
    /**
     * Obtiene todos los generos
     */
    async getGenders() {
      await this.$getAllPages(
        ALL_GENDERS,
        { companyId: this.currentUser.company.id },
        "allGenders"
      ).then(result => {
        this.genders = result.map(x => x.node);
      });
    },
    /**
     * Obtiene el id de todos los generos excluidos
     */
    async getExcludedGenders() {
      this.$apollo
        .query({
          query: EXCLUDED_GENDER_IDS,
          variables: { integrationConfigId: this.ic.id }
        })
        .then(({ data }) => {
          this.selectedGenders = this.$dup(
            data.integrationConfig.excludedGenderIds
          );
          this.excludedProductsWithout =
            data.integrationConfig.excludeProductsWithoutGender;
        });
    }
  }
};
</script>

<style scoped></style>
