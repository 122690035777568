<template>
  <div>
    <b-button-group size="sm">
      <b-button variant="outline-info" @click="cancel">
        <b-icon-x-circle></b-icon-x-circle> Cancelar
      </b-button>
      <b-button
        variant="info"
        :disabled="!changed || saving || !validChangesPrices"
        :title="changed ? '' : 'No hay cambios'"
        v-b-tooltip.hover=""
        @click="save"
      >
        <span v-if="saving"><b-spinner label="Spinning"></b-spinner></span>
        <span v-else><b-icon-cloud-upload></b-icon-cloud-upload>Guardar</span>
      </b-button>
    </b-button-group>
    <b-alert
      v-model="saved"
      :variant="errorSaving ? 'danger' : 'success'"
      dismissible
    >
      {{
        errorSaving
          ? "Ha ocurrido un error"
          : "El producto ha sido actualizado exitosamente"
      }}
    </b-alert>
    <b-row>
      <b-col md="4">
        <b-form-group
          label="Nombre"
          :label-for="'name-' + integrationConfig.fullLabel"
        >
          <b-form-input
            v-model="name"
            :id="'name-' + integrationConfig.fullLabel"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Precio"
          :label-for="'priceCompare-' + integrationConfig.fullLabel"
        >
          <b-form-input
            v-model="priceCompare"
            :id="'priceCompare-' + integrationConfig.fullLabel"
            :state="$validFormPrice(priceCompare)"
            :formatter="$formatFormPrice"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Precio de oferta"
          :label-for="'price-' + integrationConfig.fullLabel"
        >
          <b-form-input
            v-model="price"
            :id="'price' + integrationConfig.fullLabel"
            :state="$validFormPrice(price)"
            :formatter="$formatFormPrice"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Fecha inicio oferta"
          :label-for="'salestartdate-' + integrationConfig.fullLabel"
        >
          <base-date-time
            v-model="salestartdate"
            :id="'salestartdate-' + integrationConfig.fullLabel"
            @change="change"
          ></base-date-time>
        </b-form-group>
        <b-form-group
          label="Fecha término oferta"
          :label-for="'saleenddate-' + integrationConfig.fullLabel"
        >
          <base-date-time
            v-model="saleenddate"
            :id="'saleenddate-' + integrationConfig.fullLabel"
            @change="change"
          ></base-date-time>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group
          label="Descripción"
          :label-for="'description-' + integrationConfig.fullLabel"
        >
          <base-html-text-area
            :id="'description-' + integrationConfig.fullLabel"
            v-model="description"
          ></base-html-text-area>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group
          label="Listado de características"
          :label-for="'shortdescription-' + integrationConfig.fullLabel"
        >
          <base-html-text-area
            :id="'shortdescription-' + integrationConfig.fullLabel"
            v-model="shortdescription"
          ></base-html-text-area>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import BaseDateTime from "./BaseDateTime";
import { mapActions } from "vuex";
import BaseHtmlTextArea from "./BaseHtmlTextArea";
import { updateProduct } from "../main";

export default {
  name: "ProductEditTabsApplication",
  components: {
    BaseHtmlTextArea,
    BaseDateTime
  },
  model: {
    prop: "_changed",
    event: "change"
  },
  props: {
    integrationConfig: Object,
    product: Object,
    _changed: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const original = this.product.integrations[this.integrationConfig.id];
    return {
      name: original ? original.name : "",
      priceCompare: original ? original.price_compare : "",
      price: original ? original.price : "",
      salestartdate: null,
      saleenddate: null,
      description: original ? original.description : "",
      shortdescription: original ? original.shortdescription : "",
      changed: false,
      saving: false,
      saved: false,
      errorSaving: false
    };
  },
  async created() {
    const original = this.product.integrations[this.integrationConfig.id];
    if (!original) return;
    this.salestartdate = await this.toDate(original.salestartdate);
    this.saleenddate = await this.toDate(original.saleenddate);
  },
  computed: {
    /**
     * Indica si los precios son validos para habilitar el botón
     * de guardar
     * @returns {Boolean}
     */
    validChangesPrices() {
      return (
        this.changed &&
        (this.$validFormPrice(this.priceCompare) ||
          this.$validFormPrice(this.priceCompare) == null) &&
        (this.$validFormPrice(this.price) ||
          this.$validFormPrice(this.price) == null)
      );
    }
  },
  methods: {
    ...mapActions(["timezoneDate"]),
    async toDate(string) {
      if (string && string.length > 0) {
        const dateString = await this.timezoneDate(string);
        return new Date(dateString);
      }
      return null;
    },
    change() {
      this.changed = true;
      this.$emit("change", true);
    },
    notifySave() {
      this.saved = true;
      this.changed = false;
      this.$emit("change", false);
    },
    updatedData() {
      const updated = {
        integrations: {}
      };
      updated.integrations[this.integrationConfig.id] = {};
      let change = false;
      const original = this.product.integrations[this.integrationConfig.id];
      const changes = [
        {
          key: "name",
          original: original ? original.name : "",
          current: this.name
        },
        {
          key: "price_compare",
          original: original ? original.price_compare : "",
          current: this.priceCompare
        },
        {
          key: "price",
          original: original ? original.price : "",
          current: this.price
        },
        {
          key: "salestartdate",
          original: original ? original.salestartdate : "",
          current: this.salestartdate ? this.salestartdate.toISOString() : ""
        },
        {
          key: "saleenddate",
          original: original ? original.saleenddate : "",
          current: this.saleenddate ? this.saleenddate.toISOString() : ""
        },
        {
          key: "description",
          original: original ? original.description : "",
          current: this.description
        },
        {
          key: "shortdescription",
          original: original ? original.shortdescription : "",
          current: this.shortdescription
        }
      ];
      changes.forEach(x => {
        if (x.original !== x.current) {
          change = true;
          updated.integrations[this.integrationConfig.id][x.key] = x.current;
        }
      });
      if (!change) return null;
      return updated;
    },
    updateCache(data) {
      this.product.integrations[this.integrationConfig.id] =
        data.integrations[this.integrationConfig.id];
    },
    async save() {
      const data = this.updatedData();
      if (!data) {
        this.notifySave();
        return;
      }
      await updateProduct(this.$apollo, this.product.id, data)
        .then(({ data }) => {
          if (data && data.updateProduct && data.updateProduct.product) {
            this.errorSaving = false;
            const product = data.updateProduct.product;
            this.updateCache(product);
            this.notifySave();
          }
        })
        .catch(() => {
          this.errorSaving = true;
        });
    },
    cancel() {
      if (this.changed) {
        this.$swal
          .fire({
            title: "Cancelar",
            text: "Si cancelas, perderás tus cambios. ¿Estás seguro?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "No"
          })
          .then(result => {
            if (result.value) {
              this.$router.push({ name: "Products" });
            }
          });
      } else {
        this.$router.push({ name: "Products" });
      }
    }
  },
  watch: {
    name() {
      this.change();
    },
    price() {
      this.change();
    },
    priceCompare() {
      this.change();
    },
    description() {
      this.change();
    },
    shortdescription() {
      this.change();
    }
  }
};
</script>
