var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ApolloQuery',{attrs:{"query":require('../../graphql/AllTranslationCentryIntegrations.gql'),"variables":{
      integrationConfigId: _vm.integrationConfigId
    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var ref_result = ref.result;
    var loading = ref_result.loading;
    var error = ref_result.error;
    var data = ref_result.data;
return [(data && data.allTranslationCentryIntegrations)?_c('b-table-simple',{attrs:{"hover":""}},[_c('b-thead',[_c('b-tr',[_c('b-th',{attrs:{"colspan":"1"}},[_vm._v("Integration Config")]),_c('b-th',[_vm._v("Tipo")]),_c('b-th',[_vm._v("# Campos")]),_c('b-th',[_vm._v("Acciones")])],1)],1),_c('b-tbody',[(!data.allTranslationCentryIntegrations.edges.length)?_c('b-tr',[_c('b-td',{attrs:{"colspan":"3"}},[_vm._v("Aún no has creado ninguno")])],1):_vm._e(),_vm._l((data.allTranslationCentryIntegrations.edges),function(product){return _c('translation-centry-integrations-table-row',{key:product.node.id,attrs:{"product":product.node}})})],2)],1):_vm._e(),(loading)?_c('b-spinner',{attrs:{"label":"Spinning"}}):_vm._e(),(error)?_c('b-alert',{attrs:{"show":"","variant":"danger"}},[_vm._v(" Ha ocurrido un error ")]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }