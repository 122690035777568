<template>
  <div>
    <base-header
      v-if="!loading"
      title="Promoción"
      :titleSize="12"
      :map="mapHeader"
    >
    </base-header>

    <b-button-group>
      <b-button variant="outline-info" @click="cancel">
        <b-icon-x-circle></b-icon-x-circle> Cancelar
      </b-button>

      <b-button variant="info" @click="save" :disabled="!filledRequiredFields">
        <span v-if="saving"><b-spinner label="Spinning"></b-spinner></span>
        <b-icon-cloud-upload></b-icon-cloud-upload>Guardar
      </b-button>
    </b-button-group>

    <b-alert v-if="errorGettingPromotion" show="" variant="danger" dismissible>
      Ha ocurrido un error al tratar de obtener la promoción.
    </b-alert>

    <b-alert v-model="errorSaving" show="" variant="danger" dismissible>
      {{ errorMessage }}
    </b-alert>

    <hr />

    <b-spinner
      v-if="loading || (!promotion && !errorGettingPromotion)"
      label="Spinning"
    ></b-spinner>
    <div v-else>
      <vtex-base-promotion-overview
        v-model="promotion"
        :vtexIntegrationConfigId="integrationConfigId"
        @filledRequiredFields="setOverviewRequiredFields"
        @shippingDiscount="setShippingDiscount"
        @allowManualPrice="setAllowManualPrice"
        @allowOtherPromotions="setAllowOtherPromotions"
      />

      <vtex-base-promotion-items
        v-model="promotion"
        :vtexIntegrationConfigId="integrationConfigId"
        @filledRequiredFields="setItemsRequiredFields"
      />

      <vtex-base-promotion-conditions
        v-if="promotionType !== 'campaign'"
        v-model="promotion"
        :vtexIntegrationConfigId="integrationConfigId"
        :shippingDiscount="shippingDiscount"
      />

      <vtex-base-promotion-restrictions
        v-model="promotion"
        :allowManualPrice="allowManualPrice"
        :allowOtherPromotions="allowOtherPromotions"
      />
    </div>
  </div>
</template>

<script>
import INTEGRATION_CONFIG_VTEX_PROMOTION from "@/graphql/IntegrationConfigVtexPromotion.gql";
import VTEX_CREATE_OR_UPDATE_PROMOTION from "@/graphql/VtexCreateOrUpdatePromotion.gql";

import BaseHeader from "@/components/BaseHeader.vue";
import VtexBasePromotionOverview from "./VtexBasePromotionOverview.vue";
import VtexBasePromotionConditions from "./VtexBasePromotionConditions.vue";
import VtexBasePromotionRestrictions from "./VtexBasePromotionRestrictions.vue";
import VtexBasePromotionItems from "./VtexBasePromotionItems.vue";

const EMPTY_PROMOTION = {
  type: null,
  origin: "Marketplace",
  name: "",
  isActive: true,
  description: null,
  beginDateUtc: null,
  endDateUtc: null,
  activeDaysOfWeek: [],
  recurrencyCrons: null,
  generalValues: null,
  isFeatured: false,
  idSeller: null,
  idSellerIsInclusive: true,
  nominalDiscountValue: 0,
  nominalShippingDiscountValue: 0,
  percentualDiscountValue: 0,
  maximumUnitPriceDiscount: 0,
  percentualShippingDiscountValue: 0,
  absoluteShippingDiscountValue: 0,
  skusGift: {
    quantitySelectable: 1
  },
  nominalRewardValue: 0,
  percentualRewardValue: 0,
  orderStatusRewardValue: "invoiced",
  compareListPriceAndPrice: null,
  categories: [],
  categoriesAreInclusive: true,
  brands: [],
  brandsAreInclusive: true,
  products: [],
  productsAreInclusive: true,
  collections: [],
  collectionsIsInclusive: true,
  skus: [],
  skusAreInclusive: true,
  totalValueFloor: 0,
  totalValueCeling: 0,
  totalValueMode: "IncludeMatchedItems",
  totalValuePurchase: 0,
  itemMinPrice: 0,
  itemMaxPrice: 0,
  isDifferentListPriceAndPrice: false,
  restrictionsBins: [],
  marketingTagsAreNotInclusive: false,
  marketingTags: [],
  affiliates: [],
  clusterOperator: "all",
  clusterExpressions: [],
  countries: [],
  zipCodeRanges: [],
  slasIds: [],
  isSlaSelected: false,
  paymentsMethods: [],
  isMinMaxInstallments: false,
  minInstallment: 0,
  maxInstallment: 0,
  isFirstBuy: false,
  firstBuyIsProfileOptimistic: true,
  subscriptions: null,
  maxUsage: 0,
  maxUsagePerClient: 0,
  maxNumberOfAffectedItems: 0,
  cumulative: false,
  accumulateWithManualPrice: false,
  minimumQuantityBuyTogether: 0,
  quantityToAffectBuyTogether: 0,
  enableBuyTogetherPerSku: false,
  isAppliedToMostExpensive: false,
  shouldDistributeDiscountAmongMatchedItems: false,
  percentualDiscountValueList: [],
  campaigns: [],
  activateGiftsMultiplier: false,
  discountExpression: null,
  priceTableName: null,
  applyToAllShippings: false,
  utmSource: null,
  utmCampaign: null,
  utmiCampaign: null
};

export default {
  name: "VtexPromotionEdit",
  components: {
    BaseHeader,
    VtexBasePromotionOverview,
    VtexBasePromotionItems,
    VtexBasePromotionConditions,
    VtexBasePromotionRestrictions
  },
  props: {
    integrationConfigId: {
      type: String,
      required: true
    },
    id: {
      type: String,
      default: null
    },
    _promotionType: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      saving: false,
      errorSaving: false,
      loading: false,
      errorGettingPromotion: false,
      promotion: null,
      shippingDiscount: false,
      allowManualPrice: false,
      allowOtherPromotions: false,
      filledRequiredFields: false,
      overviewRequiredFields: false,
      itemsRequiredFields: false,
      errorMessage: ""
    };
  },
  /**
   * Se encarga de obtener la promoción a partir de su id o de crear una nueva.
   */
  mounted() {
    if (this.id) {
      this.getPromotion();
    } else {
      this.promotion = EMPTY_PROMOTION;
      this.promotion.type = this.promotionType;
    }
  },
  methods: {
    /**
     * Obtiene una promoción a partir de su id.
     */
    async getPromotion() {
      this.loading = true;
      this.$apollo
        .query({
          query: INTEGRATION_CONFIG_VTEX_PROMOTION,
          variables: {
            id: this.integrationConfigId,
            promotionId: this.id
          }
        })
        .then(({ data }) => {
          if (!data?.integrationConfigVtex?.promotion) {
            this.errorGettingPromotion = true;
          } else {
            this.promotion = data.integrationConfigVtex.promotion;
          }
          this.loading = false;
        })
        .catch(() => {
          this.errorGettingPromotion = true;
          this.loading = false;
        });
    },
    /**
     * Cancela la edición/creación de la promoción. Retorna al listado de promociones.
     */
    cancel() {
      this.goToMaintainerPromotionTab(null);
    },
    /**
     * Guarda la promoción. Retorna al listado de promociones.
     */
    async save() {
      await this.createOrUpdatePromotion();
      if (!this.errorSaving) {
        this.goToMaintainerPromotionTab(true);
      }
    },
    /**
     * Se encarga de retornar al listado de promociones y mostrar un mensaje de éxito o error.
     * @param {Boolean} promotionSaved - Indica si la promoción fue guardada o no.
     */
    goToMaintainerPromotionTab(promotionSaved) {
      this.$router.push({
        name: "VtexMaintainer",
        params: {
          vtexIntegrationConfigId: this.integrationConfigId,
          tabIndex: 3,
          promotionSaved: promotionSaved
        }
      });
    },
    /**
     * Se encarga de madnar a crear o actualizar la promoción a Vtex.
     */
    async createOrUpdatePromotion() {
      let promotion = this.promotion;
      delete promotion.__typename;
      return this.$apollo
        .mutate({
          mutation: VTEX_CREATE_OR_UPDATE_PROMOTION,
          variables: {
            integrationConfigId: this.integrationConfigId,
            promotion: promotion
          }
        })
        .then(({ data }) => {
          if (data.vtexCreateOrUpdatePromotion.errors) {
            this.errorSaving = true;
            this.errorMessage =
              "Hubo un error al guardar la promoción. " +
              data.vtexCreateOrUpdatePromotion.errors;
          }
        });
    },
    /**
     * Recibe si los obligatorios de la vista overview de la promoción han sido completados.
     * @param {Boolean} value - valor emitido.
     */
    setOverviewRequiredFields(value) {
      this.overviewRequiredFields = value;
    },
    /**
     * Recibe si los obligatorios de la vista items de la promoción han sido completados.
     * @param {Boolean} value - valor emitido.
     */
    setItemsRequiredFields(value) {
      this.itemsRequiredFields = value;
    },
    /**
     * Recibe si la promoción aplica un descuento a los envíos.
     * @param {Boolean} value - valor emitido.
     */
    setShippingDiscount(value) {
      this.shippingDiscount = value;
    },
    /**
     * Recibe si la promoción permite acumularse con precios manules.
     * @param {Boolean} value - valor emitido.
     */
    setAllowManualPrice(value) {
      this.allowManualPrice = value;
    },
    /**
     * Recibe si la promoción permite acumularse con otras promociones.
     * @param {Boolean} value - valor emitido.
     */
    setAllowOtherPromotions(value) {
      this.allowOtherPromotions = value;
    },
    /**
     * Traduce el nombre del tipo de la promoción para mostrarlo en español.
     * @param {String} type - tipo de la promoción.
     * @returns {String} - nombre del tipo de la promoción en español.
     */
    translatePromotionTypeName(type) {
      switch (type) {
        case "regular":
          return "Regular";
        case "combo":
          return "Comprar Juntos";
        case "forThePriceOf":
          return "Mas por Menos";
        case "progressive":
          return "Descuento Progresivo";
        case "buyAndWin":
          return "Comprar y Obtener";
        case "campaign":
          return "Campaña";
      }
    }
  },
  computed: {
    /**
     * Retorna el tipo de la promoción.
     * @returns {String} - tipo de la promoción.
     */
    promotionType() {
      return this._promotionType || this.promotion?.type;
    },
    /**
     * Retorna listado de palabras a motrar en el header de la vista.
     * @returns {Array} - listado de palabras.
     */
    mapHeader() {
      let map = [this.translatePromotionTypeName(this.promotionType)];
      if (this.promotion?.name) {
        map.push(this.promotion.name);
      }
      return map;
    }
  },
  watch: {
    /**
     * Si cambia overviewRequiredFields se revisa si ya se cumplen todos
     * los campos requeridos y se asigna este valor en filledRequiredFields.
     * @param {Boolean} newValue - Si los campos obligatorios de overview estan completos.
     */
    overviewRequiredFields(newValue) {
      if (newValue && this.itemsRequiredFields) {
        this.filledRequiredFields = true;
      } else {
        this.filledRequiredFields = false;
      }
    },
    /**
     * Si cambia itemsRequiredFields se revisa si ya se cumplen todos
     * los campos requeridos y se asigna este valor en filledRequiredFields.
     * @param {Boolean} newValue - Si los campos obligatorios de items estan completos.
     */
    itemsRequiredFields(newValue) {
      if (newValue && this.overviewRequiredFields) {
        this.filledRequiredFields = true;
      } else {
        this.filledRequiredFields = false;
      }
    },
    /**
     * Si cambia promotionType se activan acumulaciones manuales u otras promociones,
     * cuando corresponde.
     * @param {String} newValue - nuevo tipo de promoción.
     */
    promotionType: {
      handler(newValue) {
        if (newValue === null) {
          return this.cancel();
        }
        if (!["regular", "campaign"].includes(newValue)) {
          this.allowManualPrice = true;
          this.allowOtherPromotions = true;
        }
      },
      immediate: true
    }
  }
};
</script>
