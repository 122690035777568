<template>
  <div>
    <b-button v-b-modal.products-filters-modal="" class="float-right">
      <b-icon-gear-fill></b-icon-gear-fill>
      Más filtros
    </b-button>
    <b-modal
      id="products-filters-modal"
      size="lg"
      @ok="processFilters"
      @cancel="revertFilters"
      @hidden="revertFilters"
    >
      <template v-slot:modal-title>
        <h4>Filtrar movimientos</h4>
      </template>
      <template v-slot:modal-cancel>Cancelar</template>
      <template v-slot:modal-ok>Filtrar</template>
      <b-form-group>
        <div>
          <b-form-group>
            <b-row>
              <b-col md="3">
                <strong>Evento</strong>
              </b-col>
              <b-col md="9">
                <v-select
                  :options="operationOptions"
                  :reduce="x => x.value"
                  v-model="operation"
                  placeholder="Seleccione evento"
                ></v-select>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group>
            <b-row>
              <b-col md="3">
                <strong>Variante</strong>
              </b-col>
              <b-col md="9">
                <v-select
                  :options="variantOptions"
                  :reduce="x => x.value"
                  v-model="variantId"
                  placeholder="Seleccione variante"
                ></v-select>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group>
            <b-row>
              <b-col md="3">
                <strong>Bodega</strong>
              </b-col>
              <b-col md="9">
                <v-select
                  :options="warehouseOptions"
                  :reduce="x => x.value"
                  v-model="warehouseId"
                  placeholder="Seleccione bodega"
                ></v-select>
              </b-col>
            </b-row>
          </b-form-group>
        </div>
      </b-form-group>
    </b-modal>
  </div>
</template>
<script>
import { mapMutations, mapState } from "vuex";
export default {
  name: "TransactionsMoreFilters",
  props: {
    operationOptions: Array,
    variantOptions: Array,
    warehouseOptions: Array
  },
  data() {
    return {
      operation: null,
      variantId: null,
      warehouseId: null
    };
  },
  computed: {
    ...mapState(["filters"])
  },
  methods: {
    ...mapMutations(["setFilter"]),
    processFilters() {
      this.setFilter({
        operation: this.operation,
        variantId: this.variantId,
        warehouseId: this.warehouseId
      });
    },
    revertFilters() {
      this.operation = this.filters.operation;
      this.variantId = this.filters.variantId;
      this.warehouseId = this.filters.warehouseId;
    }
  },
  watch: {
    filters(newValue) {
      if (newValue.operation !== this.operation) {
        this.operation = newValue.operation;
      }
      if (newValue.variantId !== this.variantId) {
        this.variantId = newValue.variantId;
      }
      if (newValue.warehouseId !== this.warehouseId) {
        this.warehouseId = newValue.warehouseId;
      }
    }
  }
};
</script>
