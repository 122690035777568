<template>
  <div id="app">
    <ApolloQuery
      :query="require('./graphql/CurrentUser.gql')"
      :fetchPolicy="'cache-and-network'"
      clientId="apolloClientCached"
      @result="setUser"
    >
      <template slot-scope="{ isLoading }">
        <div v-if="typeof currentUser === 'undefined' && !user && isLoading">
          <base-loading-page></base-loading-page>
        </div>
        <div v-else>
          <the-menu v-if="user" :temp-user="user"></the-menu>
          <upper-banner />
          <refresh-page v-if="updateExists" :click="refreshApp" />
          <b-container fluid="">
            <ApolloQuery
              :query="require('@/graphql/SectionVideoTutorials.gql')"
              :variables="{ action: 'vue', controller: $route.name }"
              :fetchPolicy="'cache-and-network'"
              clientId="apolloClientCached"
            >
              <template slot-scope="{ result: { data } }">
                <div>
                  <video-tutorial-modal
                    v-if="user && data && data.sectionVideoTutorials"
                    :section-video-tutorials="data.sectionVideoTutorials"
                  ></video-tutorial-modal>
                </div>
              </template>
            </ApolloQuery>
            <router-view :key="forceRender"></router-view>
          </b-container>
          <b-overlay :show="show" rounded="sm" no-wrap fixed>
            <template #overlay>
              <div class="text-center">
                <p id="cancel-label">
                  La vista de esta página no coincide con la actual empresa de
                  la cuenta.
                </p>
                <b-button
                  variant="outline-danger"
                  size="sm"
                  aria-describedby="refresh-label"
                  @click="refreshApp"
                >
                  Actualizar vista
                </b-button>
              </div>
            </template>
          </b-overlay>
        </div>
      </template>
    </ApolloQuery>
    <freshdesk-chat v-if="currentUser" :user="currentUser"></freshdesk-chat>
  </div>
</template>

<script>
import TheMenu from "@/components/TheMenu.vue";
import UpperBanner from "@/components/UpperBanner.vue";
import { mapState, mapMutations } from "vuex";
import BaseLoadingPage from "./components/BaseLoadingPage.vue";
import VideoTutorialModal from "./components/VideoTutorialModal.vue";
import RefreshPage from "./components/RefreshPage.vue";
import FreshdeskChat from "./components/FreshdeskChat.vue";
import update from "./mixins/update";

export default {
  name: "App",
  components: {
    TheMenu,
    UpperBanner,
    BaseLoadingPage,
    VideoTutorialModal,
    RefreshPage,
    FreshdeskChat
  },
  computed: {
    ...mapState(["currentUser"])
  },
  data() {
    return {
      show: false,
      user: null,
      forceRender: 0
    };
  },
  mixins: [update],
  methods: {
    ...mapMutations(["setCurrentUser"]),
    /**
     * Se encarga de leer el id de la compañía en el localStorage, si no
     * coincide con el del currentUser muestra el overlay.
     */
    readCompanyId() {
      if (
        localStorage.companyId != "undefined" &&
        localStorage.companyId != this.currentUser.company.id
      ) {
        this.show = true;
      } else {
        this.show = false;
      }
    },
    /**
     * Se encarga de actualizar el valor del currentUser cuando responde la
     * query.
     * @param {Object} - result
     */
    async setUser(result) {
      let newUser = result.data.currentUser;
      if (!newUser && result.error) return;

      await this.setCurrentUser(newUser);
      localStorage["current-user"] = JSON.stringify(newUser);
      if (localStorage.companyId != newUser?.company?.id) {
        await localStorage.removeItem("apollo-cache-persist");
      }
      localStorage.companyId = newUser?.company?.id;
      setInterval(this.readCompanyId, 1000);
      if (this.$route.meta.requireAuth && !newUser) {
        await this.$router.push("/login");
      } else {
        if (!this.$route.meta.requireAuth && newUser) {
          await this.$router.push("/products");
        }
        this.loadNps();
      }
    },
    /**
     * Se encarga de actualizar el currentUser cuando la empresa actual no
     * coincide con la de la pestaña.
     */
    async refreshApp() {
      this.user = await JSON.parse(localStorage["current-user"]);
      await this.setCurrentUser(this.user);
    },
    /**
     * Se encarga de realizar los cambios necesarios en las vistas al cambiar de
     * currentUser
     * @param {Object} user - currentUser
     */
    manageChangeUser(user) {
      if (
        ["CompanyShow", "CompanyEdit"].includes(this.$route.name) &&
        user.company?.id != this.$route.params.id
      ) {
        this.$router.replace({
          name: this.$route.name,
          params: { id: user.company.id }
        });
      } else if (this.$route.name != "Profile") {
        this.forceRender += 1;
      }
    },
    /**
     * Se encarga de cargar el script de Tracksale que permite medir el NPS.
     */
    loadNps() {
      let recaptchaScript = document.createElement("script");
      recaptchaScript.innerText = `
        (function(f,b,g){
          var a={
            key:"bcac91e830b75ced",
            campaign:"6014bc99",
            name:"${this.currentUser.firstName} ${this.currentUser.lastName}",
            email:"${this.currentUser.email}",
            tags:{
                "NM_EMPRESA": "${this.currentUser.company.name}",
                "SETOR": "",
                "SEGMENTO": "",
                "ORIGEM_INTEGRACAO": "",
                "ERP": "",
                "PLATAFORMA": "Centry",
                "TEL_USUARIO": "",
                "CNPJ": "",
                "PAIS": "Chile"
            },
            salute:"Hola %name.first%",
            thanks:"Muchas gracias, %name.first%!"
          };var e;var c=f.getElementsByTagName(b)[0];if(f.getElementById(g)){return}e=f.createElement(b);e.id=g;e.src="https://cdn.tracksale.co/tracksale-js/tracksale.js";e.type="text/javascript";e.async=true;e.onload=e.onreadystatechange=function(){var h=this.readyState;if(h&&h!="complete"&&h!="loaded"){return}try{var d=new Tracksale();d.init(a)}catch(i){}};c.parentNode.insertBefore(e,c)}(document,"script","tracksale-js"));
      `;
      document.body.appendChild(recaptchaScript);
    }
  },
  async created() {
    if (
      typeof localStorage["current-user"] != "undefined" &&
      localStorage["current-user"] &&
      localStorage["current-user"] != "undefined"
    ) {
      this.user = await JSON.parse(localStorage["current-user"]);
      await this.setCurrentUser(this.user);
    }
  },
  watch: {
    currentUser(val) {
      this.user = val;
      this.manageChangeUser(val);
    },
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || "Centry";
      }
    }
  }
};
</script>

<style lang="scss">
@import "./assets/styles/variants.scss";
::placeholder {
  color: #bec5cd !important;
  font-style: italic;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
  margin: 0;
}

h1 {
  font-size: $fontSizeH1;
}

h2 {
  font-size: $fontSizeH2;
}

h3 {
  font-size: $fontSizeH3;
}

h4 {
  font-size: $fontSizeH4;
}

h5 {
  font-size: $fontSizeH5;
}

h6 {
  font-size: $fontSizeH6;
}

body {
  overflow-x: hidden;
  color: $textColor;
  font-family: "Open Sans", sans-serif;
  font-size: $fontSizeBase;
  line-height: 1.42857143;
  -webkit-font-smoothing: antialiased;
  font-weight: $fontWeightBase;
  a {
    color: $linkColor;
    &:hover {
      color: $linkHoverColor;
      text-decoration: none;
    }
  }
}
ul {
  margin: 0;
}
.table {
  border: none;
  thead {
    border-top: 2px solid $borderColor;

    th {
      padding-bottom: 15px !important;
      padding-top: 15px !important;
      text-transform: uppercase;
      font-size: 10px;
      border-bottom: 0 !important;
      color: $textColorLight;
    }
  }

  td {
    color: $textColor;
    font-size: 12px;
    font-weight: 600;
    border-top-color: $borderColor !important;
    vertical-align: middle !important;
    padding: 20px 8px !important;
    height: 110px;
    position: relative;

    .top {
      margin-bottom: 3px;
    }

    .bottom {
      color: $textColorLight;
    }
  }

  tbody > tr:last-child > td {
    border-bottom: 1px solid $borderColor;
  }
}
.filter-text {
  width: 200px;
  float: right;
  margin: 15px;
}

.filter-text-small {
  font-size: 15px;
}

.filter-icon {
  margin-top: 25px;
  margin-right: -10px;
}
.mx-datepicker {
  width: 100% !important;
}
.transparent-bg {
  background-color: #ffffff;
  background-image: linear-gradient(45deg, #cacaca 25%, transparent 25%),
    linear-gradient(-45deg, #cacaca 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, #cacaca 75%),
    linear-gradient(-45deg, transparent 75%, #cacaca 75%);
  background-size: 20px 20px;
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
}
/* Se ocupa para las tabs de los integration_config */
.tabs-wrapper-ic {
  padding-right: 0px !important;
}
/* Se ocupa para la tab activa de los integration_config */
.active-tab-ic {
  border-color: #dee2e6 transparent #dee2e6 #dee2e6 !important;
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.overflow-visible {
  overflow: visible !important;
}
#app {
  margin-bottom: 90px;
}
</style>
