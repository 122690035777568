<template>
  <div>
    <b-row v-if="['regular'].indexOf(promotion.type) != -1">
      <b-col>
        <b-form-checkbox v-model="isFirstBuy">
          Aplicar solo en la primera compra
        </b-form-checkbox>
        <b-form-checkbox
          v-show="isFirstBuy"
          v-model="firstBuyIsProfileOptimistic"
        >
          Aplicar descuento si el usuario no esta conectado
        </b-form-checkbox>
      </b-col>

      <b-col>
        <b-form-checkbox v-model="activeSubscriptions">
          Es un pedido de suscripción
        </b-form-checkbox>

        <div v-show="activeSubscriptions">
          <span>Tipo de pedido al que se aplica el descuento</span>
          <b-form-checkbox v-model="activeOriginalOrder">
            Pedido Original
          </b-form-checkbox>
          <b-form-checkbox v-model="activeRecurrentOrder">
            Pedido Recurrente
          </b-form-checkbox>

          <span>Filtrar por</span>
          <b-row>
            <b-col>
              <b-form-checkbox
                v-model="activeFrequency"
                :disabled="!activeOriginalOrder && !activeRecurrentOrder"
              >
                Frecuencia
              </b-form-checkbox>
            </b-col>
          </b-row>

          <b-row v-if="activeFrequency">
            <b-col> Cada </b-col>

            <b-col>
              <b-form-input type="number" min="0" v-model="frecuencyInterval">
              </b-form-input>
            </b-col>

            <b-col>
              <v-select
                v-model="frequencyPeriodicity"
                :options="frequencyPeriodicityOptions"
              ></v-select>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-form-checkbox
                v-model="activeCycle"
                :disabled="!activeRecurrentOrder"
              >
                Número de ciclo
              </b-form-checkbox>
            </b-col>
          </b-row>

          <b-row v-if="activeCycle">
            <b-col>
              <v-select
                v-model="cycleType"
                :options="cycleOptions"
                :clearable="false"
              ></v-select>
            </b-col>

            <b-col>
              <b-form-input
                v-show="showMinCountCycle"
                type="number"
                min="0"
                v-model="minCount"
                :disabled="!cycleType"
              >
              </b-form-input>
              <b-form-input
                v-show="showMaxCountCycle"
                type="number"
                min="0"
                v-model="maxCount"
                :disabled="!cycleType"
              >
              </b-form-input>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
const frequencyPeriodicityOptions = [
  { value: "day", label: "Día" },
  { value: "week", label: "Semana" },
  { value: "month", label: "Mes" },
  { value: "year", label: "Año" }
];

const cycleOptions = [
  { value: { keys: ["minCount", "maxCount"], slots: 1 }, label: "Es igual a" },
  { value: { keys: ["maxCount"], slots: 1 }, label: "Es menor que" },
  { value: { keys: ["minCount"], slots: 1 }, label: "Es mayor que" },
  { value: { keys: ["minCount", "maxCount"], slots: 2 }, label: "Esta entre" }
];

export default {
  name: "VtexBasePromotionFirstBuyAndSubscription",
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      promotion: this.value,
      isFirstBuy: this.value.isFirstBuy,
      firstBuyIsProfileOptimistic: this.value.firstBuyIsProfileOptimistic,
      activeSubscriptions:
        !!this.value.subscriptions &&
        Object.keys(this.value.subscriptions).length !== 0,
      subscriptions: this.value.subscriptions || {
        execution: { firstOrders: false }
      },
      activeOriginalOrder:
        !!this.value.subscriptions &&
        Object.hasOwnProperty.call(this.value.subscriptions, "execution") &&
        this.value.subscriptions.execution.firstOrders === true,
      activeRecurrentOrder:
        !!this.value.subscriptions &&
        Object.hasOwnProperty.call(this.value.subscriptions, "execution") &&
        Object.hasOwnProperty.call(
          this.value.subscriptions.execution,
          "recurrentOrders"
        ),
      activeFrequency:
        !!this.value.subscriptions &&
        Object.hasOwnProperty.call(this.value.subscriptions, "plan"),
      frecuencyInterval: this.value.subscriptions?.plan?.frequency?.interval,
      frequencyPeriodicity: this.frequencyPeriodicityOptionsByValue(
        !!this.value.subscriptions &&
          this.value.subscriptions?.plan?.frequency?.periodicity
      ),
      frequencyPeriodicityOptions: frequencyPeriodicityOptions,
      activeCycle:
        !!this.value.subscriptions &&
        Object.hasOwnProperty.call(this.value.subscriptions, "execution") &&
        Object.hasOwnProperty.call(
          this.value.subscriptions.execution,
          "recurrentOrders"
        ) &&
        Object.keys(this.value.subscriptions.execution.recurrentOrders)
          .length !== 0,
      cycleType: this.cycleTypeByValue(
        this.value.subscriptions?.execution?.recurrentOrders
      ),
      cycleOptions: cycleOptions,
      minCount: this.value.subscriptions?.execution?.recurrentOrders?.minCount,
      maxCount: this.value.subscriptions?.execution?.recurrentOrders?.maxCount
    };
  },
  methods: {
    /**
     * Revisa que opción del selector de frecuencia de la suscripción.
     * @param {String} value
     */
    frequencyPeriodicityOptionsByValue(value) {
      if (!value) {
        return null;
      }
      return frequencyPeriodicityOptions.find(option => option.value === value);
    },
    /**
     * Revisa que configuraciones de numero de ciclo de suscripción utilizar.
     * @param {Object} value
     */
    cycleTypeByValue(value) {
      if (!value) return null;
      let keys = Object.keys(value);
      if (keys.length === 0) return null;
      let searchValue = { keys: keys, slots: keys.length };
      if (keys.length === 2 && value.minCount === value.maxCount) {
        searchValue.slots = 1;
      }
      return cycleOptions.find(option => {
        return (
          option.value.slots === searchValue.slots &&
          option.value.keys.every(key => searchValue.keys.includes(key))
        );
      });
    },
    /**
     * Entrega el valor actual del valor recurrentOrders de la promoción en la vista.
     * @returns {Object}
     */
    actualRecurrentOrders() {
      let actualRecurrentOrder = Object.fromEntries(
        this.cycleType.value.keys.map(key => {
          return [key, this[key]];
        })
      );

      if (
        this.cycleType.value.keys.length === 2 &&
        this.cycleType.value.slots === 1
      ) {
        actualRecurrentOrder.maxCount = actualRecurrentOrder.minCount;
      }

      return actualRecurrentOrder;
    },
    /**
     * Agrega la frecuencia del plan al hash de subscripción de la promoción
     */
    setSubscriptionPlanFrecuency() {
      let frecuency = {
        periodicity: this.frequencyPeriodicity,
        interval: this.frecuencyInterval
      };
      this.$set(this.subscriptions, "plan", {
        frequency: frecuency
      });
    },
    /**
     * Agrega el número de ciclo a la ejecución del hash de subscripción
     * de la promoción
     */
    setSubscriptionExecutionRecurrentOrders() {
      this.$set(
        this.subscriptions.execution,
        "recurrentOrders",
        this.actualRecurrentOrders()
      );
    }
  },
  computed: {
    /**
     * Decide si mostrar el campo de valor minimo del ciclo de suscripcion de la promoción.
     * @returns {Boolean}
     */
    showMinCountCycle() {
      if (!this.cycleType) return true;
      return !!this.cycleType?.value?.keys?.find(key => key === "minCount");
    },
    /**
     * Decide si mostrar el campo de valor maximo del ciclo de suscripcion de la promoción.
     * @returns {Boolean}
     */
    showMaxCountCycle() {
      if (
        !this.cycleType ||
        (this.cycleType.value.keys.length === 2 &&
          this.cycleType.value.slots === 1)
      ) {
        return false;
      }
      return !!this.cycleType?.value?.keys?.find(key => key === "maxCount");
    }
  },
  watch: {
    value(newValue) {
      this.promotion = newValue;
    },
    promotion: {
      handler(newValue) {
        this.$emit("input", newValue);
      },
      deep: true
    },
    isFirstBuy(newValue) {
      this.promotion.isFirstBuy = newValue;
      if (newValue) {
        this.promotion.firstBuyIsProfileOptimistic = this.firstBuyIsProfileOptimistic;
      } else {
        this.promotion.firstBuyIsProfileOptimistic = true;
      }
    },
    firstBuyIsProfileOptimistic(newValue) {
      this.promotion.firstBuyIsProfileOptimistic = newValue;
    },
    activeSubscriptions(newValue) {
      if (newValue) {
        this.promotion.subscriptions = this.subscriptions;
      } else {
        this.promotion.subscriptions = null;
      }
    },
    subscriptions: {
      handler(newValue) {
        this.promotion.subscriptions = newValue;
      },
      deep: true
    },
    activeOriginalOrder(newValue) {
      if (!this.subscriptions) {
        this.subscriptions = { execution: { firstOrders: false } };
      }
      this.$set(this.subscriptions.execution, "firstOrders", newValue);

      if (newValue && this.activeFrequency) {
        this.setSubscriptionPlanFrecuency();
      } else if (!this.activeRecurrentOrder) {
        this.$delete(this.subscriptions, "plan");
      }
    },
    activeRecurrentOrder(newValue) {
      if (!this.subscriptions)
        this.subscriptions = { execution: { firstOrders: false } };
      if (newValue) {
        this.$set(this.subscriptions.execution, "recurrentOrders", {});
        if (this.activeCycle && !!this.cycleType) {
          this.setSubscriptionExecutionRecurrentOrders();
        }
        if (this.activeFrequency) {
          this.setSubscriptionPlanFrecuency();
        }
      } else {
        this.$delete(this.subscriptions.execution, "recurrentOrders");
        if (!this.activeOriginalOrder) {
          this.$delete(this.subscriptions, "plan");
        }
      }
    },
    activeFrequency(newValue) {
      if (newValue) {
        this.setSubscriptionPlanFrecuency();
      } else {
        this.$delete(this.subscriptions, "plan");
      }
    },
    frecuencyInterval(newValue) {
      if (this.subscriptions) {
        this.$set(
          this.subscriptions.plan.frequency,
          "interval",
          parseInt(newValue)
        );
      }
    },
    frequencyPeriodicity(newValue) {
      if (!!newValue && typeof newValue === "object") {
        this.$set(
          this.subscriptions.plan.frequency,
          "periodicity",
          newValue.value
        );
      }
    },
    activeCycle(newValue) {
      if (newValue && !!this.cycleType) {
        this.setSubscriptionExecutionRecurrentOrders();
      } else if (!newValue) {
        this.$set(this.subscriptions.execution, "recurrentOrders", {});
      }
    },
    cycleType(newValue) {
      if (typeof newValue === "object") {
        this.setSubscriptionExecutionRecurrentOrders();
      }
    },
    minCount(newValue) {
      this.$set(
        this.subscriptions.execution.recurrentOrders,
        "minCount",
        newValue
      );
      if (
        this.cycleType.value.keys.length === 2 &&
        this.cycleType.value.slots === 1
      ) {
        this.maxCount = newValue;
      }
    },
    maxCount(newValue) {
      this.$set(
        this.subscriptions.execution.recurrentOrders,
        "maxCount",
        newValue
      );
    }
  }
};
</script>
