<template>
  <div>
    <b-table-simple>
      <b-thead>
        <tr>
          <th>Nombre</th>
          <th>Límite de Uso</th>
          <th>Veces Usado</th>
          <th>Activo</th>
          <th></th>
          <th></th>
        </tr>
      </b-thead>

      <draggable
        v-model="array"
        tag="tbody"
        v-bind="dragOptions"
        @update="emitChange"
        handle=".handle"
      >
        <b-tr v-for="(_coupon, position) of array" :key="position">
          <b-td>
            <b-form-group>
              <b-form-input
                :id="'code_' + position"
                v-model="array[position].code"
                placeholder="BD96A5"
              ></b-form-input>
            </b-form-group>
          </b-td>
          <b-td>
            <b-input-group class="mb-2">
              <b-input-group-prepend is-text>
                <input type="checkbox" v-model="array[position].unlimited" />
              </b-input-group-prepend>
              <b-form-input
                v-if="array[position].unlimited"
                value="∞"
                disabled
              ></b-form-input>
              <b-form-input
                v-else
                type="number"
                min="1"
                v-model="array[position].usage_limit"
                aria-label="Text input with checkbox"
              ></b-form-input>
            </b-input-group>
          </b-td>
          <b-td class="text-center"> {{ array[position].times_used }} </b-td>
          <b-td class="text-center">
            {{ isActiveCoupon(array[position]) ? "Sí" : "No" }}
          </b-td>
          <b-td>
            <b-button variant="outline-danger" @click="removeCoupon(position)">
              <b-icon-trash></b-icon-trash></b-button
          ></b-td>
          <b-td>
            <b-icon-arrows-move
              class="handle"
              style="cursor: pointer"
              title="Arrastra para modificar el orden de tus cupones"
              v-b-tooltip.hover=""
              @change="emitChange"
            ></b-icon-arrows-move>
          </b-td>
        </b-tr>
      </draggable>
    </b-table-simple>
    <b-row>
      <b-col md="6">
        <p class="form-text text-muted">
          Tienes {{ array.length }}
          {{ array.length === 1 ? "cupón" : "cupones" }}.
        </p>
      </b-col>
      <b-col md="6"
        ><b-button variant="outline-info float-right" @click="addCoupon"
          >Agregar</b-button
        ></b-col
      >
    </b-row>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "ProductEditTabsCentryVariants",
  components: {},
  model: {
    prop: "_value",
    event: "change"
  },
  props: {
    _value: {
      type: Array,
      default() {
        return [{ code: "" }];
      }
    },
    isPack: {
      type: Boolean
    },
    packVariants: {
      type: Array
    }
  },
  data() {
    return {
      array: this._value,
      maxStockTotal: [],
      checkedWarehouses: [],
      totalStock: 0,
      characters: "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
    };
  },
  computed: {
    ...mapState(["currentUser"]),
    warehouses() {
      if (this.$dig(this.currentUser, "company", "warehouses")) {
        return this.currentUser.company.warehouses;
      }
      return [];
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },
  methods: {
    emitChange() {
      this.$emit("change", this.array);
      this.$emit("valid", true);
    },
    isActiveCoupon(coupon) {
      return coupon.times_used <= coupon.usage_limit;
    },
    addCoupon() {
      const newCoupon = {
        code: this.randomCode(6),
        unlimited: true,
        active: true,
        times_used: 0,
        usage_limit: 0
      };
      this.array.push(newCoupon);
    },
    randomCode(length) {
      let result = "";
      const charactersLength = this.characters.length;
      for (let i = 0; i < length; i++) {
        result += this.characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }

      return result;
    },
    removeCoupon(index) {
      this.array.splice(index, 1);
    },
    itemForSelect(item) {
      if (item) {
        return { value: item.id, label: item.name };
      }
      return null;
    }
  },
  watch: {
    array() {
      this.emitChange();
    }
  }
};
</script>
