<template>
  <div>
    <ApolloQuery
      :query="require('../graphql/Order.gql')"
      :variables="{ id: $route.params.orderId }"
      :fetchPolicy="'cache-and-network'"
      clientId="apolloClientCached"
    >
      <template slot-scope="{ result: { data }, isLoading }">
        <base-header
          title="Pedidos"
          title-link="/orders"
          v-if="data"
          :map="[$route.meta.action, data.order.origin]"
        >
        </base-header>
        <b-container fluid="">
          <router-view v-if="data" :order="data.order"></router-view>
          <b-row align-h="end">
            <span v-if="data && isLoading" class="m-2 float-right">
              Actualizando orden...
            </span>
          </b-row>
          <b-row v-if="data">
            <b-col lg="8">
              <order-general-info-card
                :order="data.order"
              ></order-general-info-card>
              <order-items-card :order="data.order"></order-items-card>
              <order-address-card
                :orderAddress="data.order.addressShipping"
                title="Despacho"
              ></order-address-card>
              <order-address-card
                :orderAddress="data.order.addressBilling"
                title="Facturación"
              ></order-address-card>
            </b-col>

            <b-col>
              <order-actions-card :order="data.order"></order-actions-card>
              <order-notes-card :order="data.order"></order-notes-card>
              <order-documents-card :order="data.order"></order-documents-card>
            </b-col>
          </b-row>
        </b-container>
      </template>

      <b-container> </b-container>
    </ApolloQuery>
  </div>
</template>

<script>
import BaseHeader from "../components/BaseHeader.vue";
import OrderGeneralInfoCard from "../components/OrderGeneralInfoCard.vue";
import OrderItemsCard from "../components/OrderItemsCard.vue";
import OrderActionsCard from "../components/OrderActionsCard.vue";
import OrderAddressCard from "../components/OrderAddressCard.vue";
import OrderNotesCard from "../components/OrderNotesCard.vue";
import OrderDocumentsCard from "../components/OrderDocumentsCard.vue";
import { centryUrl } from "../main";

export default {
  name: "Order",
  components: {
    BaseHeader,
    OrderGeneralInfoCard,
    OrderItemsCard,
    OrderActionsCard,
    OrderAddressCard,
    OrderNotesCard,
    OrderDocumentsCard
  },
  data() {
    return {
      centryUrl
    };
  }
};
</script>

<style scoped>
.card {
  margin-bottom: 10px;
}
</style>
