<template>
  <div>
    <ApolloQuery
      :query="require('../graphql/AllProducts.gql')"
      :variables="{
        cursor: '',
        keyword: filters.keyword,
        categoryId: filters.categoryId,
        brandId: filters.brandId,
        status: filters.status,
        noStock: filters.noStock
      }"
      :fetchPolicy="'cache-and-network'"
      clientId="apolloClientCached"
      notifyOnNetworkStatusChange
    >
      <template v-slot="{ result: { error, data }, query, isLoading }">
        <span class="font-weight-bold text-muted float-right">
          {{ filterCaption }}
          <b-button v-if="filterCaption.length" size="sm" @click="cleanFilters">
            Limpiar filtros
          </b-button>
        </span>
        <span v-if="data && isLoading" class="m-2 float-right">
          Actualizando lista de productos...
        </span>
        <b-table-simple v-if="data && data.allProducts" hover="">
          <b-thead>
            <b-tr>
              <b-th colspan="3">Producto</b-th>
              <b-th>SKU</b-th>
              <b-th>Categoría</b-th>
              <b-th>Precio</b-th>
              <b-th></b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-if="!data.allProducts.edges.length">
              <b-td colspan="7">No tienes productos en tu catálogo</b-td>
            </b-tr>
            <products-table-row
              v-for="product of data.allProducts.edges"
              :key="product.node.id"
              :product="product.node"
              @deleting="query.refetch()"
            ></products-table-row>
          </b-tbody>
        </b-table-simple>
        <b-alert v-if="error" show="" variant="danger">
          Ha ocurrido un error
        </b-alert>
        <b-spinner
          v-if="isLoading"
          label="Spinning"
          class="m-2 float-right"
        ></b-spinner>
        <div v-else>
          <b-button
            v-if="hasNextPage(data)"
            class="m-2 float-right"
            @click="seeMore(query, data.allProducts.pageInfo.endCursor)"
          >
            Ver más
          </b-button>
          <b-alert v-else show variant="light" class="text-center">
            No hay más datos para mostrar.
          </b-alert>
        </div>
      </template>
    </ApolloQuery>
  </div>
</template>
<script>
import ProductsTableRow from "./ProductsTableRow";
import { mapState, mapMutations } from "vuex";
export default {
  name: "ProductsTable",
  components: {
    ProductsTableRow
  },
  computed: {
    ...mapState(["filters"]),
    filterCaption() {
      let caption = [];
      if (this.filters.keyword.length) {
        caption.push(this.filters.keyword);
      }
      if (this.filters.categoryId.length) {
        caption.push(
          this.filters.categoryId.split(",").length + " categoría(s)"
        );
      }
      if (this.filters.brandId.length) {
        caption.push(this.filters.brandId.split(",").length + " marca(s)");
      }
      if (this.filters.status !== null) {
        caption.push(this.filters.status ? "activos" : "inactivos");
      }
      if (this.filters.noStock) {
        caption.push("sin stock");
      }
      return caption.length ? "Buscando por: " + caption.join(" - ") : "";
    }
  },
  methods: {
    ...mapMutations(["setFilter"]),
    cleanFilters() {
      this.setFilter({
        keyword: "",
        categoryId: "",
        brandId: "",
        status: null,
        noStock: false
      });
    },
    hasNextPage(data) {
      return data?.allProducts?.pageInfo?.endCursor != null;
    },
    /**
     * Trae más resultados de una query avanzando en su cursor.
     * @param {Object} query
     * @param {String} cursor
     */
    async seeMore(query, cursor) {
      await query.fetchMore({
        variables: {
          cursor: cursor,
          keyword: this.filters.keyword,
          categoryId: this.filters.categoryId,
          brandId: this.filters.brandId,
          status: this.filters.status,
          noStock: this.filters.noStock
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          const updated = Object.assign({}, this.$dup(prev));
          updated.allProducts.pageInfo = fetchMoreResult.allProducts.pageInfo;
          if (fetchMoreResult?.allProducts?.edges?.length !== 0) {
            updated.allProducts.edges.push(
              ...fetchMoreResult.allProducts.edges
            );
          }
          return updated;
        }
      });
    }
  }
};
</script>

<style scoped>
.btn-sm {
  margin: 5px;
}
</style>
