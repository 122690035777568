<template>
  <div>
    <b-button-group size="sm">
      <b-button variant="outline-info" @click="cancel">
        <b-icon-x-circle></b-icon-x-circle> Cancelar
      </b-button>
      <b-button
        variant="info"
        :disabled="!changed || saving || loadingIntegrationConfig"
        :title="changed ? '' : 'No hay cambios'"
        v-b-tooltip.hover=""
        @click="save"
      >
        <span v-if="saving"><b-spinner label="Spinning"></b-spinner></span>
        <span v-else><b-icon-cloud-upload></b-icon-cloud-upload>Guardar</span>
      </b-button>
    </b-button-group>
    <b-alert
      v-model="saved"
      :variant="errorSaving ? 'danger' : 'success'"
      dismissible
    >
      {{
        errorSaving
          ? "Ha ocurrido un error"
          : "El producto ha sido actualizado exitosamente"
      }}
    </b-alert>
    <h4 class="font-weight-bold m-3">Detalles producto</h4>
    <b-row v-if="integrationConfigBSale.productBehavior == 'as_product'">
      <b-col md="4">
        <b-form-group
          label="Título"
          :label-for="'titulo-' + integrationConfig.fullLabel"
        >
          <b-form-input
            :id="'titulo-' + integrationConfig.fullLabel"
            v-model="titulo"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Id de prestashop"
          :label-for="'titulo-' + integrationConfig.fullLabel"
        >
          <b-form-input
            :id="'titulo-' + integrationConfig.fullLabel"
            v-model="prestashopId"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Id de atributo de prestashop"
          :label-for="'titulo-' + integrationConfig.fullLabel"
        >
          <b-form-input
            :id="'titulo-' + integrationConfig.fullLabel"
            v-model="prestashopAttributeId"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-col md="11" v-if="!loadingIntegrationConfig">
          <b-form-group label="Tipo de producto">
            <v-select
              :options="typesList"
              placeholder="Seleccionar"
              v-model="productType"
              :reduce="x => x.value"
            ></v-select>
          </b-form-group>
        </b-col>
        <b-col md="11" v-if="!loadingIntegrationConfig">
          <b-form-group label="Impuestos">
            <v-select
              :options="taxesList"
              placeholder="Seleccionar"
              v-model="productTaxes"
              :reduce="x => x.value"
            ></v-select>
          </b-form-group>
        </b-col>
      </b-col>
      <b-col md="4">
        <b-form-group label="Admite decimales">
          <product-edit-tabs-b-sale-selector
            v-model="allowDecimal"
            allow-null=""
            true-text="Si"
            false-text="No"
            default-text="Como en la configuración general"
          ></product-edit-tabs-b-sale-selector>
        </b-form-group>
        <b-form-group label="Controla stock">
          <product-edit-tabs-b-sale-selector
            v-model="stockControl"
            allow-null=""
            true-text="Si"
            false-text="No"
            default-text="Como en la configuración general"
          ></product-edit-tabs-b-sale-selector>
        </b-form-group>
        <b-form-group label="Estado">
          <product-edit-tabs-b-sale-selector
            v-model="status"
            allow-null=""
            true-text="Activo"
            false-text="Pausado"
            default-text="Como en la configuración general"
          ></product-edit-tabs-b-sale-selector>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col md="6" v-if="loadingIntegrationConfig">
        <span><b-spinner label="Spinning"></b-spinner></span>
      </b-col>
      <b-col md="6" v-else>
        <b-form-group label="Producto (categoría)">
          <base-live-select
            @search="searchProduct"
            v-model="categoryId"
          ></base-live-select>
        </b-form-group>
      </b-col>
    </b-row>
    <hr />
    <h4 class="font-weight-bold m-3">Variantes</h4>
    <p v-if="!product.variants || product.variants.length === 0">
      Este producto no tiene variantes
    </p>
    <b-table-simple v-else>
      <b-thead>
        <b-tr>
          <b-th colspan="3">Centry</b-th>
          <b-th>{{ integrationConfig.label }}</b-th>
        </b-tr>
        <b-tr>
          <b-th>Color</b-th>
          <b-th>Talla</b-th>
          <b-th>SKU</b-th>
          <b-th>SKU</b-th>
          <b-th>Nombre</b-th>
          <b-th>C.Barras</b-th>
          <b-th>Nº Serie</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="(variant, index) of product.variants" :key="index">
          <b-td>{{ variant.color ? variant.color.name : "---" }}</b-td>
          <b-td>{{ variant.size ? variant.size.name : "---" }}</b-td>
          <b-td>{{ variant.sku }}</b-td>
          <b-td
            ><b-form-input
              v-model="
                productVariants[index].integrations[integrationConfig.fullLabel]
                  .code
              "
              @input="change"
            ></b-form-input
          ></b-td>
          <b-td
            ><b-form-input
              v-model="
                productVariants[index].integrations[integrationConfig.fullLabel]
                  .description
              "
              @input="change"
            ></b-form-input
          ></b-td>
          <b-td
            ><b-form-input
              v-model="
                productVariants[index].integrations[integrationConfig.fullLabel]
                  .barCode
              "
              @input="change"
            ></b-form-input
          ></b-td>
          <b-td
            ><b-form-input
              v-model="
                productVariants[index].integrations[integrationConfig.fullLabel]
                  .serialNumber
              "
              @input="change"
            ></b-form-input
          ></b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>
<script>
import ProductEditTabsBSaleSelector from "./ProductEditTabsBSaleSelector.vue";
import BaseLiveSelect from "./BaseLiveSelect";
import INTEGRATION_CONFIG_B_SALE from "../graphql/IntegrationConfigBSale.gql";
import { updateProduct, updateVariant } from "../main";
export default {
  name: "ProductEditTabsBSale",
  components: { ProductEditTabsBSaleSelector, BaseLiveSelect },
  props: {
    integrationConfig: Object,
    product: Object
  },
  data() {
    const original = this.$ifNull(
      this.$dig(this.product, "integrations", this.integrationConfig.fullLabel),
      {}
    );
    return {
      changed: false,
      saving: false,
      errorSaving: false,
      loadingIntegrationConfig: true,
      saved: false,
      titulo: original.name,
      prestashopId: original.prestashopProductId,
      prestashopAttributeId: original.prestashopAttributeId,
      status: original.state,
      allowDecimal: original.allowDecimal,
      stockControl: original.stockControl,
      productType: original.product_type,
      productTaxes: original.product_taxes,
      categoryId: null,
      productVariants: this.initialVariantSkus(),
      integrationConfigBSale: "",
      typesList: [],
      taxesList: [],
      productList: []
    };
  },
  created() {
    this.getIntegrationConfig();
  },
  methods: {
    change() {
      this.changed = true;
      this.$emit("change", true);
    },
    getIntegrationConfig() {
      this.$apollo
        .query({
          query: INTEGRATION_CONFIG_B_SALE,
          variables: { id: this.integrationConfig.id }
        })
        .then(result => {
          this.integrationConfigBSale = result.data.integrationConfigBSale;
          this.typesList = this.itemOptions(
            result.data.integrationConfigBSale.productTypes
          );
          this.taxesList = this.itemOptions(
            result.data.integrationConfigBSale.productTaxes
          );
          this.productOptions(result.data.integrationConfigBSale.productsList);
          this.loadingIntegrationConfig = false;
          this.$watch("categoryId", () => this.change());
        });
    },
    productOptions(json) {
      if (this.integrationConfigBSale.productBehavior == "as_product") {
        return;
      }
      var list = [];
      const original = this.product.integrations[
        this.integrationConfig.fullLabel
      ];
      json.forEach(x => {
        list.push({ value: x[1], label: x[0] ? x[0] : "" });
        if (x[1] == original.category_id) {
          this.categoryId = { value: x[1], label: x[0] ? x[0] : "" };
        }
      });
      this.productList = list;
    },
    itemOptions(json) {
      return json.items.map(x => {
        return { value: x.id, label: x.name };
      });
    },
    searchProduct(search, loading, setOptions) {
      setOptions(
        this.productList.filter(x =>
          x.label.toLowerCase().includes(search.toLowerCase())
        )
      );
    },
    initialVariantSkus() {
      if (!this.product.variants) {
        return [];
      }
      const variants = JSON.parse(JSON.stringify(this.product.variants));
      variants.forEach(variant => {
        const hash = this.$ifNull(
          this.$ifNull(variant.integrations)[this.integrationConfig.fullLabel],
          {}
        );
        hash.code = this.$ifNull(hash.code, "");
        hash.description = this.$ifNull(hash.description, "");
        hash.barCode = this.$ifNull(hash.barCode, "");
        hash.serialNumber = this.$ifNull(hash.serialNumber, "");
        variant.integrations = this.$ifNull(variant.integrations, {});
        variant.integrations[this.integrationConfig.fullLabel] = hash;
      });
      return variants;
    },
    updatedProductData() {
      const updated = { integrations: {} };
      updated.integrations[this.integrationConfig.fullLabel] = {};
      let original = this.product.integrations[
        this.integrationConfig.fullLabel
      ];
      if (!original) original = {};
      const changes = [
        { key: "name", original: original.name, current: this.titulo },
        {
          key: "prestashopProductId",
          original: original.prestashopProductId,
          current: this.prestashopId
        },
        {
          key: "prestashopAttributeId",
          original: original.prestashopAttributeId,
          current: this.prestashopAttributeId
        },
        { key: "state", original: original.state, current: this.status },
        {
          key: "allowDecimal",
          original: original.allowDecimal,
          current: this.allowDecimal
        },
        {
          key: "stockControl",
          original: original.stockControl,
          current: this.stockControl
        },
        {
          key: "product_type",
          original: original.product_type,
          current: this.productType
        },
        {
          key: "product_taxes",
          original: original.product_taxes,
          current: this.productTaxes
        },
        {
          key: "category_id",
          original: original.category_id,
          current:
            this.integrationConfigBSale.productBehavior == "as_product"
              ? original.category_id
              : this.categoryId
        }
      ];
      let changed = false;
      changes.forEach(x => {
        if (x.original !== x.current) {
          changed = true;
          updated.integrations[this.integrationConfig.fullLabel][x.key] =
            x.current;
        }
      });
      if (changed) {
        return updated;
      }
      return null;
    },
    updateProductCache(product) {
      if (product) {
        this.product.integrations = product.integrations;
      }
    },
    async updateProduct() {
      const updated = this.updatedProductData();
      if (!updated) {
        return;
      }
      await updateProduct(this.$apollo, this.product.id, updated)
        .then(async ({ data }) => {
          if (data && data.updateProduct && data.updateProduct.product) {
            this.errorSaving = false;
            const product = data.updateProduct.product;
            this.updateProductCache(product);
          }
        })
        .catch(() => {
          this.errorSaving = true;
        });
    },
    updatedVariantData(position) {
      const variant = this.product.variants[position];
      const original = this.$ifNull(
        this.$dig(variant, "integrations", this.integrationConfig.fullLabel),
        {}
      );
      const current = this.productVariants[position].integrations[
        this.integrationConfig.fullLabel
      ];
      const updated = { integrations: {} };
      updated.integrations[this.integrationConfig.fullLabel] = {};
      const changes = [
        {
          key: "description",
          original: original.description,
          current: current.description
        },
        { key: "code", original: original.code, current: current.code },
        {
          key: "barCode",
          original: original.barCode,
          current: current.barCode
        },
        {
          key: "serialNumber",
          original: original.serialNumber,
          current: current.serialNumber
        }
      ];
      let changed = false;
      changes.forEach(x => {
        if (x.original !== x.current) {
          changed = true;
          updated.integrations[this.integrationConfig.fullLabel][x.key] =
            x.current;
        }
      });
      if (changed) {
        return updated;
      }
      return null;
    },
    updateVariantCache(position, variant) {
      this.product.variants[position] = variant;
    },
    async updateVariant(position) {
      const updated = this.updatedVariantData(position);
      if (!updated) {
        return;
      }
      await updateVariant(
        this.$apollo,
        this.product.variants[position].id,
        updated
      )
        .then(async ({ data }) => {
          if (data && data.updateVariant && data.updateVariant.variant) {
            const variant = data.updateVariant.variant;
            this.updateVariantCache(position, variant);
          }
        })
        .catch(() => {
          this.errorSaving = true;
        });
    },
    async save() {
      this.saving = true;
      this.errorSaving = false;
      await this.updateProduct();
      for (
        let position = 0;
        position < this.product.variants.length;
        position++
      ) {
        await this.updateVariant(position);
      }
      this.saving = false;
      this.saved = true;
      if (!this.errorSaving) {
        this.changed = false;
        this.$emit("change", false);
      }
    },
    cancel() {
      if (this.changed) {
        this.$swal
          .fire({
            title: "Cancelar",
            text: "Si cancelas, perderás tus cambios. ¿Estás seguro?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "No"
          })
          .then(result => {
            if (result.value) {
              this.$router.push({ name: "Products" });
            }
          });
      } else {
        this.$router.push({ name: "Products" });
      }
    }
  },
  watch: {
    titulo() {
      this.change();
    },
    prestashopId() {
      this.change();
    },
    prestashopAttributeId() {
      this.change();
    },
    status() {
      this.change();
    },
    allowDecimal() {
      this.change();
    },
    stockControl() {
      this.change();
    },
    productType() {
      this.change();
    },
    productTaxes() {
      this.change();
    }
  }
};
</script>
