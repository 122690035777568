<template
  ><div>
    <base-header title="Categorías Vtex" title-link="#" :title-size="9">
    </base-header>
    <div class="container">
      <b-alert
        :show="!!errorMessage"
        variant="danger"
        dismissible
        @dismissed="errorMessage = null"
        style="!important"
      >
        {{ errorMessage }}
      </b-alert>
      <b-button-group size="sm">
        <b-button variant="outline-info" @click="cancel">
          <b-icon-x-circle></b-icon-x-circle> Cancelar
        </b-button>
        <b-button variant="info" @click="save">
          <span v-if="saving"><b-spinner label="Spinning"></b-spinner></span>
          <b-icon-cloud-upload></b-icon-cloud-upload>Guardar
        </b-button>
      </b-button-group>
      <b-row v-if="vtexCategory">
        <b-col>
          <b-form-group label="Nombre" label-for="name">
            <b-form-input v-model="vtexCategory.name" id="name"></b-form-input>
          </b-form-group>
          <b-form-group label="Descripción" label-for="description">
            <b-form-input
              v-model="vtexCategory.description"
              id="description"
            ></b-form-input>
          </b-form-group>
          <b-form-group label="Categoría Padre">
            <vtex-categories-maintainer-category-selector
              :categories="categories"
              @father="updateFather"
            ></vtex-categories-maintainer-category-selector>
            <a>Categoría Seleccionada: {{ fatherCategoryName }}</a>
          </b-form-group>
          <b-form-group label-for="showInStoreFront">
            <b-form-checkbox
              id="showInStoreFront"
              v-model="vtexCategory.showInStoreFront"
            >
              ¿Mostrar en la tienda?
            </b-form-checkbox>
          </b-form-group>
          <b-form-group label-for="isActive">
            <b-form-checkbox id="isActive" v-model="vtexCategory.isActive"
              >¿Activo?</b-form-checkbox
            >
          </b-form-group>
          <b-form-group label-for="activeStoreFrontLink">
            <b-form-checkbox
              id="activeStoreFrontLink"
              v-model="vtexCategory.activeStoreFrontLink"
              >¿Menú con Link activo?</b-form-checkbox
            >
          </b-form-group>
          <b-form-group label-for="showBrandFilter">
            <b-form-checkbox
              id="showBrandFilter"
              v-model="vtexCategory.showBrandFilter"
              >¿Filtro de marca?</b-form-checkbox
            >
          </b-form-group>
        </b-col>
      </b-row>
      <span v-else><b-spinner label="Spinning"></b-spinner></span>
    </div>
  </div>
</template>

<script>
import "vue-multiselect-listbox/dist/vue-multi-select-listbox.css";
import BaseHeader from "@/components/BaseHeader.vue";
import { centryUrl } from "@/main";
import GET_VTEX_CATEGORY from "@/graphql/GetVtexCategory.gql";
import GET_VTEX_CATEGORIES from "@/graphql/AllVtexCategories.gql";
import SAVE_VTEX_CATEGORY from "@/graphql/SaveVtexCategory.gql";
import VtexCategoriesMaintainerCategorySelector from "@/components/VtexMaintainers/Categories/VtexCategoriesMaintainerCategorySelector";
export default {
  name: "VtexCategoriesMaintainerNew",
  components: { VtexCategoriesMaintainerCategorySelector, BaseHeader },
  props: {
    id: {
      type: String,
      default() {
        return "";
      }
    },
    integrationConfigId: String
  },
  mounted() {
    this.getVtexCategory();
    this.getVtexCategories();
  },
  data() {
    return {
      centryUrl,
      vtexCategory: {},
      fatherCategoryName: "",
      loading: false,
      saving: false,
      errorMessage: null,
      categories: []
    };
  },
  computed: {
    category() {
      return this.vtexCategory;
    }
  },
  methods: {
    /**
     * Obtiene los datos en Vtex de la categoria que se esta editando
     */
    getVtexCategory() {
      if (!this.id) {
        return {};
      }
      return this.$apollo
        .query({
          query: GET_VTEX_CATEGORY,
          variables: {
            ic: this.integrationConfigId,
            id: this.id
          }
        })
        .then(({ data }) => {
          this.vtexCategory = data.getVtexCategory;
          this.getFatherName();
        });
    },
    /**
     * Obtiene el listado de las categorias existentes en Vtex
     */
    getVtexCategories() {
      return this.$apollo
        .query({
          query: GET_VTEX_CATEGORIES,
          variables: {
            ic: this.integrationConfigId,
            limit: 100,
            offset: 0
          }
        })
        .then(({ data }) => {
          this.categories = data.allVtexCategories;
        });
    },
    /**
     * Genera la petición a Vtex para guardar los datos de la categoria
     */
    save() {
      this.saving = true;
      let body = {
        Name: this.vtexCategory.name,
        FatherCategoryId: this.$ifNull(
          this.vtexCategory.fatherCategoryId,
          ""
        ).toString(),
        Description: this.vtexCategory.description,
        IsActive: this.$ifNull(this.vtexCategory.isActive, false),
        ShowInStoreFront: this.$ifNull(
          this.vtexCategory.showInStoreFront,
          false
        ),
        ShowBrandFilter: this.$ifNull(this.vtexCategory.showBrandFilter, false),
        ActiveStoreFrontLink: this.$ifNull(
          this.vtexCategory.activeStoreFrontLink,
          false
        ),
        StockKeepingUnitSelectionMode: "SPECIFICATION"
      };
      return this.$apollo
        .mutate({
          mutation: SAVE_VTEX_CATEGORY,
          variables: {
            ic: this.integrationConfigId,
            body: body,
            id: this.id
          }
        })
        .then(({ data }) => {
          this.saving = false;
          if (this.$dig(data, "saveVtexCategory", "errors")) {
            this.errorMessage =
              "Ha ocurrido un error. " + data.saveVtexCategory.errors;
          } else {
            this.$swal.fire({
              title: this.id ? "Actualización exitosa" : "Creación exitosa",
              text:
                "Se ha " +
                (this.id ? "actualizado" : "creado") +
                " la categoría de forma exitosa",
              icon: "success",
              confirmButtonText: "Aceptar",
              onClose: () => {
                this.$router.push(
                  "/vtex_maintainer/" + this.integrationConfigId
                );
              }
            });
          }
        });
    },
    /**
     * Redirecciona a la vista del mantenedor de Vtex al presionar 'cancelar'
     */
    cancel() {
      this.$router.push("/vtex_maintainer/" + this.integrationConfigId);
    },
    /**
     * Genera una petición a Vtex para obtener los datos de la categoria padre
     * y guarda el nombre
     */
    getFatherName() {
      if (!this.vtexCategory.fatherCategoryId) {
        return {};
      }
      return this.$apollo
        .query({
          query: GET_VTEX_CATEGORY,
          variables: {
            ic: this.integrationConfigId,
            id: this.vtexCategory.fatherCategoryId.toString()
          }
        })
        .then(({ data }) => {
          this.fatherCategoryName = data.getVtexCategory.name;
        });
    },
    /**
     * Actualiza los datos de la categoria padre de la categoria editada
     * @param {Object} father
     */
    updateFather(father) {
      this.fatherCategoryName = father.path;
      this.vtexCategory.fatherCategoryId = father.id;
    }
  }
};
</script>
