<template>
  <div>
    <b-button
      @click="openModal"
      variant="outline-secondary"
      class="mr-1 mb-1"
      v-b-tooltip.hover
      title="Reagendar entrega"
    >
      <b-icon-calendar3 />
    </b-button>
    <b-modal
      :ref="'confirmation-' + order.id"
      :id="'confirmation-' + order.id"
      ok-disabled=""
      size="lg"
      title="Reagendar Entrega"
    >
      <b-container fluid>
        <b-form-datepicker
          type="date"
          :locale="locale"
          v-model="valueDate"
          @input="emitChange"
          :editable="true"
        ></b-form-datepicker>
      </b-container>
      <template v-slot:modal-footer>
        <b-button variant="info" @click="confirmReSquedule">
          Reagendar
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import CREATE_ORDER_RESCHEDULE from "../../graphql/CreateOrderReschedule.gql";
export default {
  name: "OrderConfirmationModal",
  props: {
    order: Object
  },

  data() {
    return {
      valueDate: this.order.dateForDelivery
    };
  },
  methods: {
    emitChange() {
      this.$emit("change", this.valueDate);
    },
    openModal() {
      this.$refs["confirmation-" + this.order.id].show();
    },
    confirmReSquedule() {
      this.$refs["confirmation-" + this.order.id].hide();
      let create = {
        orderId: this.order.id,
        date: this.valueDate
      };
      this.$apollo
        .mutate({
          mutation: CREATE_ORDER_RESCHEDULE,
          variables: {
            create: create
          }
        })
        .then(result => {
          let valid = result.data.createOrderReschedule.result;
          if (valid) {
            this.$swal.fire({
              title: "Agendamiento exitoso",
              text: "Se ha actualizado la fecha de entrega",
              icon: "success",
              confirmButtonText: "Aceptar"
            });
          } else {
            this.$swal.fire({
              title: "Agendamiento fallido",
              text: "No se ha podido actualizadar la fecha de entrega",
              icon: "error",
              confirmButtonText: "Aceptar"
            });
          }
        });
    }
  }
};
</script>
<style scoped>
.table {
  margin-bottom: 0 !important;
}
.table td {
  padding: 8px 8px !important;
  height: 10px !important;
}
</style>
