<template>
  <div>
    <base-header title="Listado de opciones" title-link="/admin/option_list">
      <template v-slot:actions>
        <b-container>
          <b-button
            to="/admin/option_list/create"
            exact=""
            variant="outline-info"
          >
            <b-icon-plus></b-icon-plus>
            Nuevo
          </b-button>
        </b-container>
      </template>
      <template v-slot:filters>
        <option-list-filter></option-list-filter>
      </template>
    </base-header>
    <b-container fluid="">
      <b-row>
        <b-col md="12">
          <option-list-table></option-list-table>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import OptionListTable from "../../components/OptionListTable.vue";
import OptionListFilter from "../../components/OptionListFilter.vue";
import BaseHeader from "../../components/BaseHeader";

export default {
  name: "OptionList",
  components: {
    OptionListTable,
    OptionListFilter,
    BaseHeader
  }
};
</script>
