<template>
  <div>
    <b-button-group size="sm">
      <b-button variant="outline-info" @click="cancel">
        <b-icon-x-circle></b-icon-x-circle> Cancelar
      </b-button>
      <b-button
        variant="info"
        :disabled="!changed || saving"
        :title="changed ? '' : 'No hay cambios'"
        v-b-tooltip.hover=""
        @click="save"
      >
        <span v-if="saving"><b-spinner label="Spinning"></b-spinner></span>
        <span v-else><b-icon-cloud-upload></b-icon-cloud-upload>Guardar</span>
      </b-button>
    </b-button-group>
    <b-alert
      v-model="saved"
      :variant="errorSaving ? 'danger' : 'success'"
      dismissible
    >
      {{
        errorSaving
          ? "Ha ocurrido un error"
          : "El producto ha sido actualizado exitosamente"
      }}
    </b-alert>
    <product-edit-tabs-paris-base-form
      v-model="baseInfo"
      @change="emitChange"
      :integration-config="integrationConfig"
      :family-id="familyId"
      :loading-family-id="loadingFamilyId"
      @family="newFamilyId => (specificFamilyId = newFamilyId)"
    />
    <product-edit-tabs-paris-category-attributes
      :family-id="currentFamilyId"
      v-model="productAttributeValues"
      :integration-config="integrationConfig"
      :all-attributes="allAttributes"
      :loading-attributes="loadingAttributes"
      @change="emitChange"
    />
    <product-edit-tabs-paris-variants
      :family-id="currentFamilyId"
      v-model="variants"
      :integration-config="integrationConfig"
      :all-attributes="allAttributes"
      :loading-attributes="loadingAttributes"
      :product="product"
      @change="emitChange"
    />
  </div>
</template>
<script>
import { updateProduct, updateVariant } from "../../main";
import ProductEditTabsParisBaseForm from "./Paris/BaseForm.vue";
import ProductEditTabsParisCategoryAttributes from "./Paris/CategoryAttributes.vue";
import ProductEditTabsParisVariants from "./Paris/Variants.vue";
import FAMILY_ID_BY_CATEGORY_ID from "../../graphql/Paris/FamilyIdByCategoryId.gql";
import ALL_FAMILY_ATTRIBUTES from "../../graphql/Paris/AllFamilyAttributes.gql";

export default {
  name: "ProductEditTabsParis",
  model: {
    prop: "_changed",
    event: "change"
  },
  components: {
    ProductEditTabsParisBaseForm,
    ProductEditTabsParisCategoryAttributes,
    ProductEditTabsParisVariants
  },
  props: {
    integrationConfig: Object,
    product: Object,
    _changed: Boolean
  },
  data() {
    const initialBaseInfo = this.initialBaseInfo();
    return {
      changed: false,
      saving: false,
      errorSaving: false,
      saved: false,
      isLoading: true,
      familyId: null,
      specificFamilyId: initialBaseInfo.familyId,
      loadingFamilyId: true,
      baseInfo: initialBaseInfo,
      variants: this.initialVariantArray(),
      productAttributeValues: this.initialProductAttributeValues(),
      allAttributes: null,
      loadingAttributes: true
    };
  },
  computed: {
    currentFamilyId() {
      if (this.specificFamilyId) return this.specificFamilyId;
      return this.familyId;
    }
  },
  mounted() {
    this.getFamilyId().then(() => this.getAttributes());
  },
  methods: {
    /**
     * Obtiene el hash de datos base inicial (ya rellenado)
     * @return {Object}
     */
    initialBaseInfo() {
      const original = this.$ifNull(
        this.product.integrations[this.integrationConfig.fullLabel],
        {}
      );
      return {
        id: original.id,
        sku: original.sku,
        name: original.name,
        status: original.status === undefined ? null : original.status,
        familyId: original.familyId,
        category: original.category,
        price: original.price,
        offerPrice: original.offerPrice,
        showFrom: original.showFrom ? new Date(original.showFrom) : null,
        showTo: original.showTo ? new Date(original.showTo) : null,
        sync: original.sync
      };
    },
    /**
     * Entrega una copia de las variantes solo con los datos
     * que serán utiliados en la pestaña.
     * @return {Array}
     */
    initialVariantArray() {
      if (this.product.variants) {
        return this.product.variants.map(x => {
          const original = this.$ifNull(
            this.$ifNull(x.integrations, {})[this.integrationConfig.fullLabel],
            {}
          );
          return this.$dup(original);
        });
      }
      return null;
    },
    /**
     * Entrega un hash con los valores de los atributos actuales
     * @return {Object}
     */
    initialProductAttributeValues() {
      let original = this.product.integrations[
        this.integrationConfig.fullLabel
      ];
      if (!original) original = {};
      return Object.keys(original)
        .filter(
          key =>
            ![
              "id",
              "sku",
              "name",
              "status",
              "familyId",
              "category",
              "price",
              "offerPrice",
              "showFrom",
              "showTo",
              "sync"
            ].includes(key)
        )
        .reduce((cur, key) => {
          return Object.assign(cur, { [key]: original[key] });
        }, {});
    },
    /**
     * Emite el evento "change" con valor true,
     * indicando al padre que ha cambiado
     */
    emitChange() {
      this.changed = true;
      this.$emit("change", true);
    },
    /**
     * Obtiene el familyId a partir de la categoría homologada del producto
     * y la almacena en la variable familyId
     */
    getFamilyId() {
      let categoryId = this.product.category.id;
      if (!categoryId) {
        this.loadingFamilyId = false;
        return;
      }
      return this.$apollo
        .query({
          query: FAMILY_ID_BY_CATEGORY_ID,
          variables: {
            integrationConfigId: this.integrationConfig.id,
            categoryId
          }
        })
        .then(({ data }) => {
          this.familyId = data.parisFamilyIdByCategoryId;
          this.loadingFamilyId = false;
        });
    },
    /**
     * Obtiene el listado de todos los atributos de la familia
     * y los almacena en allAttributes
     */
    getAttributes() {
      this.loadingAttributes = true;
      if (!this.currentFamilyId) {
        this.allAttributes = {};
        this.loadingAttributes = false;
        return;
      }
      return this.$apollo
        .query({
          query: ALL_FAMILY_ATTRIBUTES,
          variables: {
            integrationConfigId: this.integrationConfig.id,
            familyId: this.currentFamilyId
          }
        })
        .then(({ data }) => {
          this.allAttributes = data.allParisFamilyAttributes.reduce(
            (cur, elem) => {
              return Object.assign(cur, { [elem.attribute.id]: elem });
            },
            {}
          );
          this.loadingAttributes = false;
        });
    },
    /**
     * Lanza un alert, preguntando si quiere cancelas. Si se marca cancelar,
     * se redirige al listado de productos
     */
    cancel() {
      if (this.changed) {
        this.$swal
          .fire({
            title: "Cancelar",
            text: "Si cancelas, perderás tus cambios. ¿Estás seguro?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "No"
          })
          .then(result => {
            if (result.value) {
              this.$router.push({ name: "Products" });
            }
          });
      } else {
        this.$router.push({ name: "Products" });
      }
    },
    /**
     * Entrega un arreglo con cada dato del separador del producto:
     * key: llave
     * original: Valor antes de los cambios}
     * current: Valor actual
     */
    productChangesArray() {
      const original = this.$ifNull(
        this.$dig(
          this.product,
          "integrations",
          this.integrationConfig.fullLabel
        ),
        {}
      );
      const changes = [
        { key: "id", original: original.id, current: this.baseInfo.id },
        { key: "sku", original: original.sku, current: this.baseInfo.sku },
        { key: "name", original: original.name, current: this.baseInfo.name },
        {
          key: "status",
          original: original.status,
          current: this.baseInfo.status
        },
        {
          key: "familyId",
          original: original.familyId,
          current: this.baseInfo.familyId
        },
        {
          key: "category",
          original: original.category,
          current: this.baseInfo.category
        },
        {
          key: "price",
          original: original.price,
          current: this.baseInfo.price
        },
        {
          key: "offerPrice",
          original: original.offerPrice,
          current: this.baseInfo.offerPrice
        },
        {
          key: "showFrom",
          original: original.showFrom,
          current: this.baseInfo.showFrom
            ? this.baseInfo.showFrom.toISOString()
            : ""
        },
        {
          key: "showTo",
          original: original.showTo,
          current: this.baseInfo.showTo
            ? this.baseInfo.showTo.toISOString()
            : ""
        },
        {
          key: "sync",
          original: original.sync,
          current: this.baseInfo.sync
        }
      ];
      Object.keys(this.productAttributeValues).forEach(key => {
        changes.push({
          key: key,
          original: original[key] ? original[key] : "",
          current: this.productAttributeValues[key]
            ? this.productAttributeValues[key]
            : ""
        });
      });
      return changes;
    },
    /**
     * Entrega el body para actualiar el producto. Entrega null
     * si no hay cambios
     * @return {Object}
     */
    updatedProductData() {
      const changes = this.productChangesArray();
      const toUpdate = { integrations: {} };
      toUpdate.integrations[this.integrationConfig.fullLabel] = changes.reduce(
        (cur, elem) => {
          if (elem.original === undefined) elem.original = null;
          if (elem.current === undefined) elem.current = null;
          if (elem.original !== elem.current)
            return Object.assign(cur, { [elem.key]: elem.current });
          return cur;
        },
        {}
      );
      if (
        Object.keys(toUpdate.integrations[this.integrationConfig.fullLabel])
          .length
      ) {
        return toUpdate;
      }
      return null;
    },
    /**
     * Actualiza el producto en memoria
     * @param {Object} product
     */
    updateProductCache(product) {
      if (product) {
        this.product.integrations = product.integrations;
      }
    },
    /**
     * Actualiza el producto ejecutando la mutación correspondiente
     */
    updateProduct() {
      const updatedProductData = this.updatedProductData();
      if (updatedProductData === null) {
        this.errorSaving = false;
        return;
      }
      return updateProduct(this.$apollo, this.product.id, updatedProductData)
        .then(async ({ data }) => {
          if (data && data.product) {
            this.errorSaving = false;
            const newProduct = data.product;
            this.updateProductCache(newProduct);
          }
        })
        .catch(() => {
          this.errorSaving = true;
        });
    },
    /**
     * Obtiene los cambios que tuvo la variante en la vista.
     * @param {Int} position - posición de la variante en el producto
     */
    updatedVariantData(position) {
      const variant = this.product.variants[position];
      const original = this.$ifNull(
        variant.integrations[this.integrationConfig.fullLabel],
        {}
      );
      const current = this.variants[position];
      const changes = [];
      Object.keys(current).forEach(key => {
        changes.push({
          key: key,
          original: original[key] ? original[key] : "",
          current: current[key] ? current[key] : ""
        });
      });
      let updated = changes.reduce((cur, x) => {
        if (x.original !== x.current) {
          cur[x.key] = x.current;
        }
        return cur;
      }, {});
      if (Object.keys(updated).length) {
        const toSend = { integrations: {} };
        toSend.integrations[this.integrationConfig.fullLabel] = updated;
        return toSend;
      }
      return null;
    },
    /**
     * Actualiza el caché de la variante para tener la variable
     * actualizada después de guardar un cambio.
     * @param {Int} position - posición de la variante en el prod
     * @param {Products::Variant} variant - variante del producto
     */
    updateVariantCache(position, variant) {
      this.product.variants[position] = variant;
    },
    /**
     * Manda a actualizar la variante enviando una mutacion.
     */
    async updateVariant(position) {
      const updated = this.updatedVariantData(position);
      if (!updated) {
        return;
      }
      await updateVariant(
        this.$apollo,
        this.product.variants[position].id,
        updated
      )
        .then(async ({ data }) => {
          const variant = this.$dig(data, "updateVariant", "variant");
          if (variant) {
            this.updateVariantCache(position, variant);
          }
        })
        .catch(() => {
          this.errorSaving = true;
        });
    },
    /**
     * Guarda los cambios y maneja las variables del proceso
     */
    async save() {
      this.saving = true;
      this.errorSaving = false;
      await this.updateProduct();
      for (let i = 0; i < this.variants.length; i++) {
        await this.updateVariant(i);
      }
      this.saved = true;
      this.saving = false;
      if (!this.errorSaving) {
        this.changed = false;
        this.$emit("change", false);
      }
    }
  },
  watch: {
    currentFamilyId() {
      this.getAttributes();
    }
  }
};
</script>
