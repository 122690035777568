<template>
  <div>
    <base-header title="Estados por integración" title-link="#" :titleSize="6">
      <template v-slot:filters>
        <b-button @click="exportResult" variant="outline-info">
          <b-icon-file-earmark-arrow-down></b-icon-file-earmark-arrow-down>
          Exportar resultado actual y sugerencias
        </b-button>
      </template>
    </base-header>
    <b-container fluid="">
      <b-row>
        <b-col md="12">
          <last-synchronization-statuses-list
            @change="onFiltersChanges($event)"
          ></last-synchronization-statuses-list>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import BaseHeader from "../components/BaseHeader.vue";
import LastSynchronizationStatusesList from "../components/LastSynchronizationStatusesList.vue";
import gql from "graphql-tag";

const GENERATE_REPORT_MUTATION = gql`
  mutation generateReportLastSynchronizationStatus(
    $params: ProductsSyncDashboardWorkerInput!
  ) {
    generateReportLastSynchronizationStatus(params: $params) {
      result
      pid
      __typename
    }
  }
`;

export default {
  name: "LastSynchronizationStatuses",
  components: {
    BaseHeader,
    LastSynchronizationStatusesList
  },
  data() {
    return {
      filters: {}
    };
  },
  methods: {
    /**
     * Registra los filtros que se han aplicado en el componente
     * `LastSynchronizationStatusesList`.
     *
     * @param {Object} filters filtros aplicados en el componente
     *                         `LastSynchronizationStatusesList`
     */
    onFiltersChanges(filters) {
      this.filters = filters;
    },
    /**
     * Basado en los filtros aplicados en el componente
     * `LastSynchronizationStatusesList`, solicita la generación de un reporte
     * ejecutando la mutación de GraphQl dedicada al caso.
     */
    exportResult() {
      this.$apollo
        .mutate({
          mutation: GENERATE_REPORT_MUTATION,
          variables: this.mutationVariables()
        })
        .then(() => {
          this.$swal.fire({
            text: "Recibirás la planilla en tu correo.",
            icon: "success"
          });
        })
        .catch(() => {
          this.$swal.fire({
            title: "Solicitud fallida",
            text:
              "Hubo un error al solicitar el reporte. Por favor vuele a intentarlo.",
            icon: "error"
          });
        });
    },
    /**
     * Prepara las variables que necesita la mutación
     * `generateReportLastSynchronizationStatus` tomando los filtros notificados
     * por el componente `LastSynchronizationStatusesList`.
     *
     * @return {Object}
     */
    mutationVariables() {
      return {
        params: {
          categoryIds: this.filters.productFilters.categoriesSelected.map(
            c => c.value
          ),
          brandIds: this.filters.productFilters.brandsSelected.map(
            b => b.value
          ),
          phrases: this.filters.productFilters.phrases,
          statuses: this.filters.statusesSelected,
          integrationConfigIds: this.filters.integrationsSelected.map(i => i.id)
        }
      };
    }
  }
};
</script>
