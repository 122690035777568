<template>
  <mercado-libre-suggestion-form-validation-component
    error-loading-title="Ha ocurrido un error intentando validar el formulario:"
    :do-query="doQuery"
    :load-errors="loadErrors"
    ref="validation-component"
    v-if="!!body"
    @valid="emitValid"
  />
  <div v-else />
</template>

<script>
import PREVIOUS_VALIDATION from "../../../../graphql/MercadoLibre/CatalogSuggestions/PreviousValidation.gql";
import MercadoLibreSuggestionFormValidationComponent from "./ValidationComponent.vue";
export default {
  name: "MercadoLibreCatalogSuggestionFormPreviousValidation",
  components: {
    MercadoLibreSuggestionFormValidationComponent
  },
  props: {
    integrationConfigId: {
      type: String,
      required: true
    },
    body: Object
  },
  methods: {
    /**
     * Ejecuta la query para validar el body de
     * creación / edición de sugerencias
     * @returns {Promise}
     */
    doQuery() {
      if (!this.body) {
        return;
      }
      this.loading = true;
      const body = this.$dup(this.body);
      const domainId = body.domainId;
      delete body.domainId;
      return this.$apollo.query({
        query: PREVIOUS_VALIDATION,
        variables: {
          integrationConfigId: this.integrationConfigId,
          domainId,
          patch: body
        }
      });
    },
    /**
     * Indica si tiene o no errores
     * @param {Object} data
     * @returns {Boolean}
     */
    withErrors(data) {
      const status = this.$dig(
        data,
        "previousValidationMercadoLibreSuggestion",
        "status"
      );
      return status < 200 || status >= 300;
    },
    /**
     * Carga la información de error en el arreglo errors
     * @param {Object} data
     */
    loadErrors(data) {
      if (!this.withErrors(data)) {
        return [];
      }
      const errors = this.$dig(
        data,
        "previousValidationMercadoLibreSuggestion",
        "cause"
      );
      return errors.map(error => error.message);
    },
    /**
     * Emite evento de resultado de validacion previa exitosa o no
     * @param {Boolean} valid
     */
    emitValid(valid) {
      this.$emit("valid", valid);
    }
  },
  watch: {
    body() {
      if (!this.$refs["validation-component"]) {
        return;
      }
      this.$refs["validation-component"].validate();
    }
  }
};
</script>
