<template>
  <div class="container">
    <b-row class="align-items-center mb-3">
      <b-col md="5">
        <div class="font-weight-bold">Atributo Magento:</div>
      </b-col>
      <b-col md="7">
        <v-select
          return-object
          v-model="selectedAttribute"
          :options="magentoAttributes"
          :label="magentoAttributes.attributeLabel"
        ></v-select>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="d-flex">
        <span class="flex-fill text-justify">
          Para cada opción de Centry selecciona una opción de Magento para
          homologar:
        </span>
      </b-col>
    </b-row>
    <b-row v-if="loadingAttribute">
      <b-col>
        <b-spinner label="Spinning"></b-spinner>
      </b-col>
    </b-row>
    <div v-else>
      <div class="d-none d-md-block">
        <b-row class="mt-3">
          <b-col class="d-flex" md="5">
            <span class="flex-fill text-right font-weight-bold">Centry</span>
          </b-col>
          <b-col md="7">
            <span class="text-center font-weight-bold">Magento</span>
          </b-col>
        </b-row>
      </div>
      <div class="options-selectors container mt-3">
        <div v-for="attribute in attributes" :key="attribute.value">
          <b-row class="option-selector d-flex align-items-center my-3">
            <b-col class="text-md-right" md="5">
              <span>{{ attribute.label }}:</span>
            </b-col>
            <b-col md="7">
              <v-select
                ref="optionsSelect"
                return-object
                v-model="attribute.magentoOption"
                :options="magentoOptions"
                :disabled="selectedAttribute === null"
              ></v-select>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <b-row class="my-4">
      <b-col md="12">
        <b-button
          class="float-right"
          @click="saveAttributeOptions"
          :disabled="selectedAttribute === null"
          >Guardar</b-button
        >
      </b-col>
    </b-row>
  </div>
</template>

<script>
import MagentoApiAttributeOptions from "../graphql/MagentoApiAttributeOptions.gql";
import MagentoAttributeWithOptions from "../graphql/MagentoAttributeWithOptions.gql";
import { addOptionToMagentoAttribute, saveMagentoAttribute } from "@/main";

export default {
  name: "MagentoAttributesHomologation",
  mounted() {
    this.getCentryOptions();
    this.getActiveAttribute();
  },
  props: {
    queryImport: Object,
    attributeSet: Object,
    magentoAttributes: Array,
    centryModel: String
  },
  data() {
    return {
      activeAttribute: null,
      attributes: [],
      centryAttribute: null,
      homologatedOptions: {},
      loadingAttribute: true,
      loadingOptions: true,
      magentoOptions: [{ value: 0, label: "Cargando opciones..." }],
      selectedAttribute: null,
      saving: false
    };
  },
  watch: {
    selectedAttribute: function(newVal) {
      if (newVal !== null) {
        this.magentoOptions = [{ value: 0, label: "Cargando opciones..." }];
        this.getMagentoOptions(newVal.value);
      }
      if (this.$refs.optionsSelect) {
        this.$refs.optionsSelect.forEach(select => select.clearSelection());
      }
    }
  },
  methods: {
    async getCentryOptions() {
      this.loadingAttribute = true;
      let centryAttributes = [];
      let cursor = "";
      while (cursor !== null) {
        await this.$retryQueryWithTimeout(this.queryImport, { cursor }).then(
          ({ data }) => {
            let allAttributes = this.getQueryKey(Object.keys(data));
            if (data && data[allAttributes] && data[allAttributes].edges) {
              centryAttributes = centryAttributes.concat(
                data[allAttributes].edges.map(c => {
                  return {
                    value: c.node.id,
                    label: c.node.name,
                    magentoOption: null
                  };
                })
              );
              cursor = data[allAttributes].pageInfo.endCursor;
            }
          }
        );
      }
      this.attributes.push(this.generateDefaultAttribute());
      this.attributes = this.attributes.concat(centryAttributes);
      this.loadingAttribute = false;
      this.updateActiveAttributeSelector();
    },
    async getMagentoOptions(attribute_code) {
      let options = [];
      await this.$apollo
        .query({
          query: MagentoApiAttributeOptions,
          variables: {
            ic_magento_id: this.$route.params.integration_config_id,
            attribute_code: attribute_code
          }
        })
        .then(({ data }) => {
          if (data && data.magentoApiAttributeOptions) {
            options = options.concat(data.magentoApiAttributeOptions);
          }
        });
      this.magentoOptions = options;
      await this.getHomologatedOptions(attribute_code);
      await this.updateHomologations();
    },
    async getHomologatedOptions(selectedAttribute) {
      await this.$apollo
        .query({
          query: MagentoAttributeWithOptions,
          variables: {
            apiMagentoAttributeSetId: this.attributeSet.id,
            attributeCode: selectedAttribute,
            centryModel: this.centryModel
          }
        })
        .then(({ data }) => {
          if (
            data &&
            data.allMagentoAttributes &&
            data.allMagentoAttributes.edges &&
            data.allMagentoAttributes.edges.length > 0
          ) {
            let attribute = data.allMagentoAttributes.edges[0].node;
            this.homologatedOptions = {};
            attribute.apiMagentoAttributeOptions.forEach(option => {
              this.homologatedOptions[option.centryId] = option.optionValue;
            });
          }
        });
    },
    async getActiveAttribute() {
      let activeAttribute = null;
      await this.$apollo
        .query({
          query: MagentoAttributeWithOptions,
          variables: {
            apiMagentoAttributeSetId: this.attributeSet.id,
            active: true,
            centryModel: this.centryModel
          }
        })
        .then(({ data }) => {
          if (
            data &&
            data.allMagentoAttributes &&
            data.allMagentoAttributes.edges &&
            data.allMagentoAttributes.edges.length > 0
          ) {
            activeAttribute = data.allMagentoAttributes.edges[0].node;
            this.activeAttribute = activeAttribute;
          }
        });
    },
    updateActiveAttributeSelector() {
      if (this.magentoAttributes && this.activeAttribute) {
        for (let attribute of this.magentoAttributes) {
          if (attribute.value === this.activeAttribute.attributeCode) {
            this.selectedAttribute = attribute;
            break;
          }
        }
      }
    },
    async updateHomologations() {
      if (this.magentoOptions[0] && this.magentoOptions[0].value === 0) {
        return false;
      }
      let notHomologatedOptions = this.homologatedOptions;
      this.attributes.forEach(attribute => {
        if (Object.keys(notHomologatedOptions).length <= 0) {
          return false;
        }
        if (notHomologatedOptions[attribute.value]) {
          let searchValue = notHomologatedOptions[attribute.value];
          this.magentoOptions.forEach(mOption => {
            if (mOption.value === searchValue) {
              attribute.magentoOption = {
                label: mOption.label,
                value: searchValue
              };
              delete notHomologatedOptions[attribute.value];
            }
          });
        }
      });
    },
    getQueryKey(keys) {
      return keys.find(function(key) {
        return key.startsWith("all");
      });
    },
    async saveAttributeOptions() {
      this.saving = true;
      await this.getOrCreateAttribute();
      await this.saveOptionsToAttribute();
      this.$bvToast.toast("Homologación de atributos guardada exitosamente", {
        title: "Homologación guardada",
        variant: "success",
        solid: true
      });
      this.saving = false;
    },
    async getOrCreateAttribute() {
      await saveMagentoAttribute(
        this.$apollo,
        this.attributeSet.id,
        this.selectedAttribute.attributeId,
        this.centryModel,
        this.selectedAttribute.value
      ).then(({ data }) => {
        let attribute = null;
        if (data && data.saveMagentoAttribute) {
          attribute = {
            id: data.saveMagentoAttribute.id,
            attributeCode: data.saveMagentoAttribute.attributeCode,
            attributeId: data.saveMagentoAttribute.attributeId,
            centryModel: data.saveMagentoAttribute.centryModel,
            apiMagentoAttributeSetId:
              data.saveMagentoAttribute.apiMagentoAttributeSetId
          };
          this.centryAttribute = attribute;
        }
      });
    },
    async saveOptionsToAttribute() {
      await addOptionToMagentoAttribute(
        this.$apollo,
        this.centryAttribute.id,
        this.attributes
      );
    },
    generateDefaultAttribute() {
      let attributeModel = this.centryModel.split("::")[1];
      let translatedAttributeModel;
      switch (attributeModel) {
        case "Size":
          translatedAttributeModel = "Talla";
          break;
        case "Color":
          translatedAttributeModel = "Color";
          break;
        default:
          translatedAttributeModel = "Atributo";
          break;
      }
      return {
        value: "sin_" + translatedAttributeModel.toLowerCase(),
        label: "Sin " + translatedAttributeModel,
        magentoOption: null
      };
    }
  }
};
</script>

<style scoped>
.options-selectors {
  overflow-y: auto;
  height: 500px;
}
</style>
