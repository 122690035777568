<template>
  <b-spinner v-if="loading"></b-spinner>
  <div v-else>
    <base-header title="Reglas para secciones" title-link="#"> </base-header>
    <b-container fluid>
      <b-button-group size="sm">
        <b-button variant="outline-info" :href="centryUrl + '/admin/sections'">
          <b-icon-x-circle></b-icon-x-circle> Cancelar
        </b-button>
        <b-button
          variant="info"
          :disabled="saving || missingRequiredData"
          @click="save"
        >
          <span v-if="saving"><b-spinner label="Spinning"></b-spinner></span>
          <p v-else><b-icon-cloud-upload></b-icon-cloud-upload>Guardar</p>
        </b-button>
      </b-button-group>
      <b-row>
        <b-col md="5">
          <b-alert
            v-model="saved"
            :variant="!!errorMessage ? 'danger' : 'success'"
            dismissible
          >
            {{
              errorMessage
                ? errorMessage
                : "La sección ha sido guardada exitosamente"
            }}
          </b-alert>
          <b-form-group label="Nombre">
            <b-form-input v-model="name" type="text" placeholder="Nombre" />
            <span v-if="emptyRequiredData.name" class="text-danger">
              El nombre es requerido
            </span>
          </b-form-group>
          <b-form-group label="Descripción">
            <b-form-textarea
              v-model="description"
              rows="3"
              placeholder="Descripción"
            />
            <span v-if="emptyRequiredData.description" class="text-danger">
              La descripción es requerida
            </span>
          </b-form-group>
          <b-form-group label="Vista">
            <v-select
              :options="views"
              v-model="view"
              placeholder="Seleccione la vista"
            />
            <span v-if="emptyRequiredData.view" class="text-danger">
              La vista es requerida
            </span>
          </b-form-group>
          <b-checkbox v-model="active">Activo</b-checkbox>
        </b-col>
        <b-col md="7">
          <b-form-group label="Videos">
            <vMultiselectListbox
              style="min-width: 100%"
              v-model="resourcesIds"
              :options="allResources"
              :reduce-display-property="option => option.label"
              :reduce-value-property="option => option.value"
              search-options-placeholder="Buscar recursos..."
              selected-options-placeholder="Buscar recursos seleccionados"
              no-options-text="No hay recursos"
              selected-no-options-text="No hay recursos seleccionados"
              no-options-found-text="No se encontró el recurso"
              no-selected-options-found-text="No se encontró el recurso seleccionado"
            >
            </vMultiselectListbox>
            <span v-if="emptyRequiredData.videos" class="text-danger">
              Se debe seleccionar por lo menos un video
            </span>
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import vMultiselectListbox from "vue-multiselect-listbox";
import { centryUrl } from "@/main";
import GET_SECTION from "@/graphql/GetSection.gql";
import GET_RESOURCES from "@/graphql/AllResources.gql";
import UPDATE_SECTION from "@/graphql/UpdateSection.gql";
import CREATE_SECTION from "@/graphql/CreateSection.gql";
import BaseHeader from "@/components/BaseHeader.vue";

export default {
  name: "Section",
  components: {
    vMultiselectListbox,
    BaseHeader
  },
  props: {
    sectionId: {
      type: String,
      default: null
    }
  },
  created() {
    Promise.all([this.getSection(), this.getResources()]).then(() => {
      this.loading = false;
    });
  },
  data() {
    return {
      centryUrl,
      name: null,
      description: null,
      view: null,
      active: true,
      resourcesIds: [],
      allResources: [],
      views: this.getRoutes(),
      loading: true,
      saving: false,
      saved: false,
      errorMessage: null
    };
  },
  computed: {
    body() {
      return {
        name: this.name,
        description: this.description,
        controller: this.view,
        action: "vue",
        active: this.active,
        rules: [
          {
            resourcesIds: this.$ifNull(this.resourcesIds, [])
          }
        ]
      };
    },
    /**
     * Retorna un objeto informando aquellos datos de la regla que estan vacíos
     * @return {Object}
     */
    emptyRequiredData() {
      return {
        name: !this.name || this.name.length === 0,
        description: !this.description || this.description.length === 0,
        view: !this.view || this.view.length === 0,
        videos: this.$ifNull(this.resourcesIds, []).length === 0
      };
    },
    /**
     * Revisa si algun dato requerido esta vacío
     * @return {Boolean}
     */
    missingRequiredData() {
      return Object.values(this.emptyRequiredData).some(value => !!value);
    }
  },
  methods: {
    getRoutes() {
      const routes = [];
      this.$router.options.routes.forEach(route => {
        if (route.children) {
          route.children.forEach(child => {
            routes.push(child.name);
          });
        } else {
          routes.push(route.name);
        }
      });
      return routes;
    },
    getSection() {
      if (this.sectionId) {
        return this.$apollo
          .query({
            query: GET_SECTION,
            variables: {
              id: this.sectionId
            }
          })
          .then(({ data }) => {
            const section = this.$dig(data, "getSection");
            if (section) {
              this.name = section.name;
              this.active = section.active;
              this.view = section.controller;
              this.description = section.description;
              this.resourcesIds = this.$ifNull(section.resources, []).map(
                resource => resource.id
              );
            }
          });
      }
    },
    getResources() {
      return this.$getAllPages(GET_RESOURCES, {}, "allResources").then(
        array => {
          this.allResources = array.map(elem => {
            return { value: elem.node.id, label: elem.node.name };
          });
        }
      );
    },
    createSection() {
      return this.$apollo
        .mutate({
          mutation: CREATE_SECTION,
          variables: {
            create: this.body
          }
        })
        .then(() => {
          window.location = centryUrl + "/admin/sections";
        })
        .catch(error => {
          this.errorMessage = error;
          this.saving = false;
        });
    },
    updateSection() {
      return this.$apollo
        .mutate({
          mutation: UPDATE_SECTION,
          variables: {
            patch: this.body,
            id: this.sectionId
          }
        })
        .then(() => {
          this.saved = true;
          this.saving = false;
          this.errorMessage = null;
        })
        .catch(error => {
          this.errorMessage = error;
          this.saving = false;
        });
    },
    save() {
      this.saving = true;
      this.errorMessage = null;
      if (this.sectionId) {
        return this.updateSection();
      }
      this.createSection();
    }
  }
};
</script>
