<template>
  <div>
    {{ sizeChartPresent ? "Seleccionado: " + sizeChartName : "" }}
    <b-link @click="deleteSizeChart" v-if="sizeChartPresent"
      ><b-icon-x-circle></b-icon-x-circle></b-link
    ><br />
    <b-button
      @click="
        $bvModal.show('sizeChartModal');
        loading = true;
      "
      variant="outline-info"
      title="Seleccionar guía de talle"
      v-b-tooltip.hover=""
    >
      {{ sizeChartPresent ? "Cambiar" : "Seleccionar" }}
    </b-button>
    <b-modal
      :static="true"
      id="sizeChartModal"
      ok-only
      ok-variant="secondary"
      ok-title="Cancelar"
    >
      <size-chart-mercado-libre-selector
        :integrationConfigId="integrationConfig.id"
        v-model="sizeChart"
        :site-code="integrationConfig.siteCode"
      ></size-chart-mercado-libre-selector>
    </b-modal>
  </div>
</template>
<script>
import SizeChartMercadoLibreSelector from "./MercadoLibre/SizeChart/SizeChartMercadoLibreSelector.vue";
import INTEGRATION_CONFIG_MERCADO_LIBRE_SIZE_CHART from "@/graphql/IntegrationConfigMercadoLibreSizeChart.gql";
import { mapMutations } from "vuex";

export default {
  components: { SizeChartMercadoLibreSelector },
  name: "ProductEditTabsMercadoLibreSizeChartSelector",
  model: {
    prop: "_value",
    event: "change"
  },
  props: {
    _value: {
      type: String,
      default: null
    },
    integrationConfig: {
      type: Object,
      default: null
    }
  },
  mounted() {
    this.loadExternalSizeChart();
  },
  data() {
    return {
      sizeChart: {},
      loading: true
    };
  },
  computed: {
    sizeChartName() {
      let siteCode = this.integrationConfig.siteCode;
      if (!siteCode) return "Nombre no disponible";
      let ans = this.$dig(this.sizeChart, "names", siteCode);
      if (!ans) return "Nombre no disponible para esta integración";
      return ans;
    },
    sizeChartPresent() {
      return Object.keys(this.sizeChart).length > 0;
    }
  },
  methods: {
    ...mapMutations(["setMercadoLibreSizeChart"]),
    /**
     * Carga informacion de GDT si se trata de una edicion
     */
    loadExternalSizeChart() {
      if (!this._value || !this._value.length) {
        this.sizeChart = {};
        this.loading = false;
        return;
      }
      this.loading = true;
      return this.$apollo
        .query({
          query: INTEGRATION_CONFIG_MERCADO_LIBRE_SIZE_CHART,
          variables: {
            integrationConfigId: this.integrationConfig.id,
            sizeChartId: this._value
          },
          fetchPolicy: "no-cache"
        })
        .then(({ data }) => {
          this.sizeChart = data.integrationConfigMercadoLibre.sizeChart;
          this.loading = false;
        });
    },
    /**
     * Captura cambio en seleccion de GDT
     */
    emitChange() {
      this.$emit("change", this.$ifNull(this.$dig(this.sizeChart, "id"), ""));
    },
    /**
     * Elimina asociacion entre GDT de centry y meli momentaneamente
     */
    deleteSizeChart() {
      this.sizeChart = {};
    }
  },
  watch: {
    _value() {
      this.loadExternalSizeChart();
    },
    sizeChart() {
      this.emitChange();
      this.$bvModal.hide("sizeChartModal");
    },
    loading() {
      this.$emit("changeSizeChart", this.loading);
    }
  }
};
</script>
