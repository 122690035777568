<template>
  <div>
    <base-header
      title="Monitor de colas de tareas asíncronas"
      title-link="/"
      :title-size="12"
    />
    <b-container fluid="">
      <b-row>
        <b-col md="12">
          <charts-filter
            :initialStartDate="startDate"
            :initialEndDate="endDate"
            @start-date-changed="startDate = $event"
            @end-date-changed="endDate = $event"
          ></charts-filter>

          <ApolloQuery
            :query="require('../../graphql/AllSystemSidekiqQueueStats.gql')"
            :variables="currentFilters"
          >
            <template
              slot-scope="{ result: { error, data }, query, isLoading }"
            >
              <!-- Error -->
              <b-alert v-if="error" show="" variant="danger">
                Ha ocurrido un error
              </b-alert>
              <!-- Result -->
              <queue-size-chart
                v-if="data && data.allSystemSidekiqQueueStats"
                :sidekiq-queue-stats="data.allSystemSidekiqQueueStats.edges"
              />
              <queue-latency-chart
                v-if="data && data.allSystemSidekiqQueueStats"
                :sidekiq-queue-stats="data.allSystemSidekiqQueueStats.edges"
              />

              <b-spinner
                v-if="isLoading"
                label="Spinning"
                class="m-2 float-right"
              ></b-spinner>
            </template>
          </ApolloQuery>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import BaseHeader from "@/components/BaseHeader";
import ChartsFilter from "@/components/SidekiqMonitor/ChartsFilter.vue";
import QueueSizeChart from "@/components/SidekiqMonitor/QueueSizeChart.vue";
import QueueLatencyChart from "@/components/SidekiqMonitor/QueueLatencyChart.vue";

export default {
  name: "SidekiqMonitor",
  components: {
    BaseHeader,
    ChartsFilter,
    QueueSizeChart,
    QueueLatencyChart
  },
  data() {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return {
      startDate: today,
      endDate: null
    };
  },
  computed: {
    currentFilters() {
      const filter = {};
      if (this.startDate) {
        filter.from = this.startDate;
      }
      if (this.endDate) {
        filter.to = this.endDate;
      }
      return filter;
    }
  }
};
</script>
