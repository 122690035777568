<template>
  <div>
    <base-header
      title="Seleccionar grupo"
      title-link="#"
      :map="[
        'Mercado Libre',
        'Atributos',
        'Integraciones',
        integrationConfigLabel,
        'Categorías'
      ]"
      :title-size="8"
    >
      <template v-slot:filters>
        <mercado-libre-attributes-group-integration-filters></mercado-libre-attributes-group-integration-filters>
        <span class="font-weight-bold text-muted float-right">
          {{ filterCaption }}
          <b-button v-if="filterCaption.length" size="sm" @click="cleanFilters"
            >Limpiar filtros</b-button
          ></span
        >
      </template>
    </base-header>
    <ApolloQuery
      :query="
        require('../graphql/AllIntegrationsMercadoLibreAttributeGroupIC.gql')
      "
      :variables="{
        cursor: '',
        integrationConfigId: integrationConfigId,
        quality: filters.quality
      }"
      :fetchPolicy="'cache-and-network'"
      clientId="apolloClientCached"
    >
      <template slot-scope="{ result: { error, data }, query, isLoading }">
        <b-row align-h="end">
          <span v-if="data && isLoading" class="m-2 float-right">
            Actualizando grupos de atributos...
          </span>
        </b-row>
        <div v-if="error">
          Error al obtener los Grupos de Atributos
        </div>
        <b-list-group
          v-else-if="
            data &&
              data.allIntegrationsMercadoLibreAttributeGroupIntegrationConfigs &&
              data.allIntegrationsMercadoLibreAttributeGroupIntegrationConfigs
                .edges.length
          "
        >
          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
            v-bind:key="group.id"
            :to="{
              name: 'MercadoLibreAttribute',
              params: {
                attributeGroupIntegrationConfigId: group.node.id
              }
            }"
            v-for="group of data
              .allIntegrationsMercadoLibreAttributeGroupIntegrationConfigs
              .edges"
          >
            {{ group.node.attributeGroup.name }}
            <b-badge variant="primary" pill
              >Calidad minima:
              {{ formatQuality(group.node.minQuality) }}</b-badge
            >
          </b-list-group-item>
        </b-list-group>
        <b-spinner
          v-if="isLoading"
          label="Spinning"
          class="m-2 float-right"
        ></b-spinner>
        <div v-else>
          <b-button
            v-if="hasNextPage(data)"
            class="m-2 float-right"
            @click="
              seeMore(
                query,
                data.allIntegrationsMercadoLibreAttributeGroupIntegrationConfigs
                  .pageInfo.endCursor
              )
            "
          >
            Ver más
          </b-button>
          <b-alert v-else show variant="light" class="text-center">
            No hay más datos para mostrar.
          </b-alert>
        </div>
      </template>
    </ApolloQuery>
  </div>
</template>

<script>
import BaseHeader from "@/components/BaseHeader.vue";
import INTEGRATION_CONFIG_MERCADO_LIBRE from "@/graphql/IntegrationConfigMercadoLibre.gql";
import { mapMutations, mapState } from "vuex";
import MercadoLibreAttributesGroupIntegrationFilters from "../components/MercadoLibreAttributesGroupIntegrationFilters";
export default {
  name: "MercadoLibreAttributeGroups",
  components: {
    MercadoLibreAttributesGroupIntegrationFilters,
    BaseHeader
  },
  computed: {
    ...mapState(["filters"]),
    filterCaption() {
      return this.filters.quality
        ? "Buscando por: " + this.filters.quality
        : "";
    },
    integrationConfigLabel() {
      if (this.integrationConfigMeli?.label) {
        return this.integrationConfigMeli.label;
      }
      return "loading";
    }
  },
  props: {
    integrationConfigId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      integrationConfigMeli: {}
    };
  },
  beforeMount() {
    this.getIntegrationConfig();
  },
  methods: {
    ...mapMutations(["setFilter"]),
    cleanFilters() {
      this.setFilter({
        quality: null
      });
    },
    /**
     * Indica si hay una página siguiente en la query de GraphQl basado
     * exclusivamente en la existencia de un cursor.
     * @return {boolean}
     */
    hasNextPage(data) {
      return (
        data?.allIntegrationsMercadoLibreAttributeGroupIntegrationConfigs
          ?.pageInfo?.endCursor != null
      );
    },
    /**
     * Trae más resultados de una query avanzando en su cursor.
     * @param {Object} query
     * @param {String} cursor
     */
    async seeMore(query, cursor) {
      await query.fetchMore({
        variables: {
          cursor: cursor,
          quality: this.filters.quality
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          const updated = Object.assign({}, this.$dup(prev));
          updated.allIntegrationsMercadoLibreAttributeGroupIntegrationConfigs.pageInfo =
            fetchMoreResult.allIntegrationsMercadoLibreAttributeGroupIntegrationConfigs.pageInfo;
          if (
            fetchMoreResult
              ?.allIntegrationsMercadoLibreAttributeGroupIntegrationConfigs
              ?.edges?.length !== 0
          ) {
            updated.allIntegrationsMercadoLibreAttributeGroupIntegrationConfigs.edges.push(
              ...fetchMoreResult
                .allIntegrationsMercadoLibreAttributeGroupIntegrationConfigs
                .edges
            );
          }
          return updated;
        }
      });
    },
    formatQuality(quality) {
      if (quality === 0) {
        return "0%";
      } else if (quality) {
        return Math.round(quality * 100) + " %";
      } else {
        return "Desconocida";
      }
    },
    getIntegrationConfig() {
      this.$apollo
        .query({
          query: INTEGRATION_CONFIG_MERCADO_LIBRE,
          variables: { id: this.integrationConfigId }
        })
        .then(result => {
          this.integrationConfigMeli =
            result.data.integrationConfigMercadoLibre;
        });
    }
  }
};
</script>

<style scoped>
.list-group {
  margin-bottom: 30px;
}
</style>
