import { render, staticRenderFns } from "./MagentoAttributesHomologation.vue?vue&type=template&id=7e95e5ce&scoped=true&"
import script from "./MagentoAttributesHomologation.vue?vue&type=script&lang=js&"
export * from "./MagentoAttributesHomologation.vue?vue&type=script&lang=js&"
import style0 from "./MagentoAttributesHomologation.vue?vue&type=style&index=0&id=7e95e5ce&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e95e5ce",
  null
  
)

export default component.exports