<template>
  <div>
    <b-alert
      v-if="error || success"
      :variant="alertVariant"
      :show="error ? true : dismissCountDown"
      dismissible
      @dismissed="softReset"
      @dismiss-count-down="countDownChanged"
    >
      {{ alertMessage }}</b-alert
    >
    <b-table :items="newVariants" :fields="fields">
      <template #cell(stock_by_integration)="row">
        <span v-if="loadingStock"> <b-spinner /></span>
        <b-button
          v-else
          size="lg"
          variant="outlined"
          class="p-0 mx-1 focus-btn"
          :title="
            (row.detailsShowing ? 'Ocultar' : 'Ver') + ' stock por integración'
          "
          @click="row.toggleDetails"
          v-b-tooltip.hover
        >
          <b-icon
            stacked
            :icon="row.detailsShowing ? 'eye-slash' : 'eye'"
            scale="0.75"
            flip-h
          ></b-icon>
        </b-button>
      </template>

      <template #cell(color)="data">
        {{ $dig(data, "value", "name") || "--Sin color--" }}
      </template>
      <template #cell(size)="data">
        {{ $dig(data, "value", "name") || "--Sin talla--" }}
      </template>
      <template #cell(table_actions)="data">
        <variants-table-actions
          :variant="data.item"
          @delete-variant="error => deleteVariant(data.index, error)"
          is-pack
        />
      </template>
      <template #row-details="item">
        <b-table-simple striped small>
          <b-thead>
            <b-tr>
              <b-th>Integración</b-th>
              <b-th>Stock</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr
              v-for="(quantity, ic, i) of stockByIntegrations[item.item.sku]"
              :key="i"
            >
              <b-th>
                {{ ic }}
              </b-th>
              <b-td>
                {{ quantity }}
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </template>
    </b-table>
  </div>
</template>
<script>
import VARIANT_STOCK_BY_IC from "../../../graphql/Product/Inventory/VariantsStockByIntegrationConfig.gql";
import VariantsTableActions from "./VariantsTableActions.vue";
export default {
  name: "VariantsPackTable",
  components: {
    VariantsTableActions
  },
  props: {
    variants: {
      type: Array,
      default() {
        return [];
      }
    },
    productId: String
  },
  data() {
    return {
      newVariants: null,
      stockByIntegrations: {},
      error: false,
      errorMessage: null,
      success: false,
      dismissSecs: 3,
      dismissCountDown: 0,
      loadingStock: false,
      fields: [
        { key: "stock_by_integration", label: "Stock por integración" },
        { key: "size", label: "Talla" },
        { key: "color", label: "Color" },
        { key: "sku", label: "Sku" },
        { key: "barcode", label: "Código de barra (ISBN, UPC, ETC.)" },
        { key: "table_actions", label: "" }
      ]
    };
  },
  created() {
    this.newVariants = this.$dup(this.variants);
    this.getVariantsStock();
  },
  computed: {
    alertVariant() {
      return this.error ? "danger" : "success";
    },
    alertMessage() {
      return this.error
        ? "Ha ocurrido un error guardando los datos: " + this.errorMessage
        : "Variante eliminada exitosamente";
    }
  },
  methods: {
    /**
     * Obtiene el stock por integracion de las variantes
     */
    getVariantsStock() {
      this.loadingStock = true;
      this.$apollo
        .query({
          query: VARIANT_STOCK_BY_IC,
          variables: { productId: this.productId }
        })
        .then(({ data }) => {
          this.stockByIntegrations = data.variantsStockByIntegrationConfig;
          this.loadingStock = false;
        });
    },
    /**
     * Se encarga de resetear las variables que indican el error o exito
     */
    softReset() {
      this.error = false;
      this.errorMessage = null;
      this.success = false;
    },
    /**
     * Actualiza el valor actual del contador de la alerta
     * @param {Number} dismissCountDown - segundo que le quedan a la alerta
     */
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    /**
     * Emite el evento de elimiancion de una variante
     * solo si no hay algun error, so lo hay lo muestra
     * @param {Number} index - indice de la variante
     * @param {String} error - mensaje de error
     */
    deleteVariant(index, error = null) {
      this.softReset();
      if (error) {
        this.error = true;
        this.errorMessage = error;
      } else {
        this.newVariants.splice(index, 1);
        this.$emit("delete-variants", this.newVariants);
      }
    }
  },
  watch: {
    variants: {
      handler(val) {
        this.newVariants = this.$dup(val);
      },
      deep: true
    }
  }
};
</script>
<style scoped>
.focus-btn {
  color: #aab1b5;
  display: inline-block;
  background-color: white;
  width: 34px;
  height: 34px;
  text-align: center;
  border-radius: 50%;
  font-size: 14px;
  border: 2px solid #e0e2e4;
  padding: 5px 0;
}

.focus-btn:hover {
  border-color: #aab1b5;
  color: black;
}
</style>
