<template>
  <div>
    <vtex-base-promotion-collections-and-skus
      v-model="promotion"
      :vtexIntegrationConfigId="vtexIntegrationConfigId"
      @filledRequiredFields="setSkusRequiredFields"
    >
      <b-row v-for="(discount, index) in progressiveDiscounts" :key="index">
        <b-col>
          <b-form-group label="Cantidad*">
            <b-form-input type="number" min="0" v-model="discount.quantity">
            </b-form-input>
            <span
              v-if="requiredMessage.discountData[index].quantity"
              class="text-danger"
            >
              La cantidad es requerida
            </span>
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group label="Descuento*">
            <b-input-group append="%">
              <b-form-input
                type="number"
                min="0"
                max="100"
                v-model="discount.percent"
              >
              </b-form-input>
            </b-input-group>
            <span
              v-if="requiredMessage.discountData[index].percent"
              class="text-danger"
            >
              El descuento es requerido
            </span>
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group>
            <template slot="label">&#8203;</template>
            <b-button
              variant="outline-info"
              @click="deleteProgressiveDiscount(index)"
            >
              <b-icon-x-circle></b-icon-x-circle>
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>

      <div>
        <b-button variant="outline-info" @click="addProgressiveDiscount">
          <b-icon-plus></b-icon-plus> Agregar Descuento
        </b-button>
        <div>
          <span v-if="requiredMessage.atLeastOne" class="text-danger">
            Almenos un descuento es requerido
          </span>
          <span v-else>
            *Maximo de 10 descuentos progresivos
          </span>
        </div>
      </div>
    </vtex-base-promotion-collections-and-skus>
  </div>
</template>

<script>
import VtexBasePromotionCollectionsAndSkus from "./VtexBasePromotionCollectionsAndSkus.vue";

export default {
  name: "VtexPromotionProgressiveItems",
  components: {
    VtexBasePromotionCollectionsAndSkus
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    vtexIntegrationConfigId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      promotion: this.value,
      progressiveDiscounts: this.value.percentualDiscountValueList,
      filledRequiredFields: false,
      discountsRequiredFields: false,
      skusRequiredFields: false
    };
  },
  mounted() {
    if (this.progressiveDiscounts.length === 0) {
      this.addProgressiveDiscount();
    }
  },
  methods: {
    /**
     * Agrega un nuevo campo de descuento progresivo.
     */
    addProgressiveDiscount() {
      if (this.progressiveDiscounts.length < 10) {
        this.progressiveDiscounts.push({
          quantity: 0,
          percent: 0
        });
      }
    },
    /**
     * Elimina el campo de descuento progresivo seleccionado.
     * @param {Number} index - El indice a eliminar.
     */
    deleteProgressiveDiscount(index) {
      this.progressiveDiscounts.splice(index, 1);
    },
    /**
     * Emite si los datos obligatorios han sido completados.
     * @param {Boolean} value - Valor a emitir.
     */
    emitFilledRequiredFields(value) {
      this.$emit("filledRequiredFields", value);
    },
    /**
     * Guarda el valor emitido, correspondiente a si los campos obligatorios
     * de los skus de la promoción han sido completados.
     * @param {Boolean} value - valor emitido.
     */
    setSkusRequiredFields(value) {
      this.skusRequiredFields = value;
    }
  },
  computed: {
    /**
     * Revisa si los atributos obligatorios de la promoción en la vista estan completos
     * para saber donde mostrar los mensajes de requerido.
     * @returns {Object} - Objeto con los atributos obligatorios y si requieren mensaje.
     */
    requiredMessage() {
      let required = {};
      required.atLeastOne = this.progressiveDiscounts.length === 0;
      if (this.progressiveDiscounts.length > 0) {
        required.discountData = [];
        for (let i = 0; i < this.progressiveDiscounts.length; i++) {
          let quantity = this.progressiveDiscounts[i].quantity;
          let percent = this.progressiveDiscounts[i].percent;
          let requiredDiscountQuantity = !quantity || parseInt(quantity) === 0;
          let requiredDiscountPercent = !percent || parseInt(percent) === 0;
          required.discountData.push({
            quantity: requiredDiscountQuantity,
            percent: requiredDiscountPercent
          });
        }
      }
      return required;
    }
  },
  /**
   * En general, el objetivo de los watches es cambiar el valor de una propiedad
   * de la promocion cuando se cambia su correspondiente en la vista
   */
  watch: {
    value(newValue) {
      this.promotion = newValue;
    },
    promotion: {
      handler(newValue) {
        this.$emit("input", newValue);
      },
      deep: true
    },
    progressiveDiscounts: {
      handler(newValue) {
        this.promotion.percentualDiscountValueList = newValue;
      },
      deep: true
    },
    /**
     * Si cambia el valor de requiredMessage, se revisa si no se necesita
     * ningun mensaje y se guarda este valor en filledRequiredFields.
     */
    requiredMessage: {
      handler(newValue) {
        let filledRequiredDiscountData = false;
        if (newValue.discountData) {
          let filledRequiredDiscountDataResume = [];
          for (let i = 0; i < newValue.discountData.length; i++) {
            filledRequiredDiscountDataResume.push(
              !Object.values(newValue.discountData[i]).includes(true)
            );
          }
          filledRequiredDiscountData = !filledRequiredDiscountDataResume.includes(
            false
          );
        }
        if (filledRequiredDiscountData && !newValue.atLeastOne) {
          this.discountsRequiredFields = true;
        } else {
          this.discountsRequiredFields = false;
        }
      },
      immediate: true
    },
    /**
     * Si cambia el valor de filledRequiredFields, se emite este valor al padre.
     * @param {Boolean} value - Valor a emitir.
     */
    filledRequiredFields(newValue) {
      this.emitFilledRequiredFields(newValue);
    },
    /**
     * Si cambia skusRequiredFields se revisa si ya se cumplen todos
     * los campos requeridos y se asigna este valor en filledRequiredFields.
     * @param {Boolean} newValue - Si los campos obligatorios de skus estan completos.
     */
    skusRequiredFields(newValue) {
      if (this.discountsRequiredFields && newValue) {
        this.filledRequiredFields = true;
      } else {
        this.filledRequiredFields = false;
      }
    },
    /**
     * Si cambia discountsRequiredFields se revisa si ya se cumplen todos
     * los campos requeridos y se asigna este valor en filledRequiredFields.
     * @param {Boolean} newValue - Si los campos obligatorios en discounts estan completos.
     */
    discountsRequiredFields(newValue) {
      if (this.skusRequiredFields && newValue) {
        this.filledRequiredFields = true;
      } else {
        this.filledRequiredFields = false;
      }
    }
  }
};
</script>
