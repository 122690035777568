<template>
  <div>
    <b-spinner label="Spinning" v-if="loading"></b-spinner>
    <div v-else>
      <base-header
        title="Productos"
        title-link="/productos"
        v-if="item"
        :map="['Mercado Libre', item.title, 'Fulfillment', 'Stock']"
      >
        <template v-slot:actions>
          <b-container>
            <b-dropdown variant="outline-info" text="Ver más">
              <b-dropdown-item
                v-if="$route.meta.action !== 'Stock de fulfillment'"
                :to="{
                  name: 'MercadoLibreItemFulfillmentStock',
                  params: {
                    integrationConfigId: integrationConfigId,
                    itemId: itemId
                  }
                }"
              >
                Stock
              </b-dropdown-item>
              <b-dropdown-item
                v-if="$route.meta.action !== 'Operaciones de fulfillment'"
                :to="{
                  name: 'MercadoLibreItemFulfillmentOperations',
                  params: {
                    integrationConfigId: integrationConfigId,
                    itemId: itemId
                  }
                }"
              >
                Operaciones
              </b-dropdown-item>
            </b-dropdown>
          </b-container>
        </template>
      </base-header>
      <b-container fluid="">
        <b-alert show variant="info" v-if="!item.inventory_id">
          Este producto no participa del programa fulfillment
        </b-alert>
        <b-alert show variant="info" v-else-if="!stock">
          Este producto no tiene stock registrado en fulfillment
        </b-alert>
        <b-table-simple small responsive v-else>
          <b-tbody>
            <b-tr>
              <b-th colspan="2">Total</b-th>
              <b-td>{{ this.stock.total }}</b-td>
            </b-tr>
            <b-tr>
              <b-th colspan="2">Disponible</b-th>
              <b-td>{{ this.stock.available_quantity }}</b-td>
            </b-tr>
            <b-tr>
              <b-th colspan="2">No Disponible</b-th>
              <b-td>{{ this.stock.not_available_quantity }}</b-td>
            </b-tr>
            <b-tr v-for="detail of notAvailableDetail" :key="detail.key">
              <b-th></b-th>
              <b-th class="text-muted">{{ detail.caption }}</b-th>
              <b-td class="text-muted">{{
                getNotAvailableDetailStock(detail.key)
              }}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-container>
    </div>
  </div>
</template>

<script>
import INTEGRATION_CONFIG_MERCADO_LIBRE from "../graphql/IntegrationConfigMercadoLibre.gql";
import BaseHeader from "@/components/BaseHeader.vue";
export default {
  name: "MercadoLibreItemFulfillmentStock",
  props: {
    integrationConfigId: {
      type: String,
      required: true
    },
    itemId: {
      type: String,
      required: true
    }
  },
  components: {
    BaseHeader
  },
  data() {
    return {
      loading: true,
      integrationConfigMeli: this.integrationConfig,
      item: {},
      stock: null,
      notAvailableDetail: [
        { key: "damage", caption: "Dañado" },
        { key: "lost", caption: "Perdido" },
        { key: "noFiscalCoverage", caption: "Sin covertura fiscal" },
        { key: "withdrawal", caption: "Reservados para retiro" },
        {
          key: "internal_process",
          caption: "Reservados por procesamiento interno"
        },
        {
          key: "transfer",
          caption: "Reservado para ser transferido a depósito"
        },
        { key: "not_supported", caption: "No identificables o procesables" }
      ]
    };
  },
  beforeMount() {
    this.getIntegrationConfig();
  },
  methods: {
    getNotAvailableDetailStock(key) {
      const detail = this.stock.not_available_detail.find(i => i.status == key);
      return detail ? detail.quantity : 0;
    },
    getIntegrationConfig() {
      this.$apollo
        .query({
          query: INTEGRATION_CONFIG_MERCADO_LIBRE,
          variables: { id: this.integrationConfigId }
        })
        .then(result => {
          this.integrationConfigMeli =
            result.data.integrationConfigMercadoLibre;
          this.getItem();
        });
    },
    async getItem() {
      const res = await fetch(
        `https://api.mercadolibre.com/items/${this.itemId}?access_token=${this.integrationConfigMeli.accessToken}`
      );
      const item = await res.json();
      this.item = item;
      this.getStock();
    },
    async getStock() {
      if (!this.item.inventory_id) {
        this.loading = false;
        // INICIO Datos de prueba. Eliminar antes de entregar
        // this.item.inventory_id = "CODIGOFALSO";
        // this.stock = {
        //   inventory_id: "LCQI05831",
        //   total: 20,
        //   available_quantity: 5,
        //   not_available_quantity: 15,
        //   not_available_detail: [
        //     {
        //       status: "damage",
        //       quantity: 2
        //     },
        //     {
        //       status: "lost",
        //       quantity: 1
        //     },
        //     {
        //       status: "noFiscalCoverage",
        //       quantity: 5
        //     },
        //     {
        //       status: "withdrawal",
        //       quantity: 5
        //     },
        //     {
        //       status: "internal_process",
        //       quantity: 1
        //     },
        //     {
        //       status: "transfer",
        //       quantity: 1
        //     }
        //   ],
        //   external_references: [
        //     {
        //       type: "item",
        //       id: "MLB1557246024",
        //       variation_id: 4742223403
        //     }
        //   ]
        // };
        // FIN Datos de prueba. Eliminar antes de entregar
        return;
      }
      const res = await fetch(
        `https://api.mercadolibre.com/inventories/${this.item.inventory_id}/stock/fulfillment?access_token=${this.integrationConfigMeli.accessToken}`
      );
      const data = await res.json();
      this.stock = data;
      this.loading = false;
    }
  }
};
</script>

<style scoped>
.table td,
.table th {
  height: inherit;
  vertical-align: inherit;
}
</style>
