<template>
  <div>
    <base-alert-with-count-down
      v-model="showAlert"
      :alert-variant="alertVariant"
      :dismiss-secs="error ? 10 : 5"
    >
      {{ alertMessage }}</base-alert-with-count-down
    >
    <b-row>
      <b-col xl="10" cols="12">
        <b-row>
          <b-col xl="6" cols="12">
            <input-with-skeleton
              :loading="isLoading"
              :invalid-feedback="validation.callbackUrl.invalidFeedback"
              :state="validation.callbackUrl.valid"
              v-model="newWebhook.callbackUrl"
            >
              <template #label>
                <h4 class="font-weight-bold my-auto">
                  URL que atenderá las notificaciones de Centry
                </h4>
                <hr />
              </template>
            </input-with-skeleton>
          </b-col>
          <b-col xl="6" cols="12">
            <h4 class="font-weight-bold my-auto">
              Topicos a suscribirse
            </h4>
            <hr />
            <div v-if="isLoading">
              <div v-for="i in 10" :key="i" cols="4" class="mb-3">
                <b-skeleton
                  animation="wave"
                  :width="`${80 - Math.floor(Math.random() * 20)}%`"
                />
              </div>
            </div>
            <div v-else>
              <div
                v-for="(topic, index) of Object.keys(webhookTopicsTranslate)"
                :key="index"
                class="my-1 align-middle"
                xl="12"
              >
                <b-form-checkbox v-model="newWebhook[topic]">
                  {{ webhookTopicsTranslate[topic] }}
                </b-form-checkbox>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col xl="2" cols="12">
        <b-button
          variant="info"
          :disabled="!changed || saving"
          :title="changed ? '' : 'No hay cambios'"
          @click="save"
          class="mb-3 w-100"
        >
          <span v-if="saving">
            Guardando
            <b-spinner label="Spinning" />
          </span>
          <span v-else>Guardar</span>
        </b-button>
        <b-button
          variant="outline-info"
          class="w-100 px-0"
          :disabled="!changed || saving"
          @click="resetData()"
        >
          <span>Restablecer</span>
        </b-button>
      </b-col>
    </b-row>
    <webhook-report :webhook="webhook" />
  </div>
</template>

<script>
import InputWithSkeleton from "../components/Base/InputWithSkeleton.vue";
import BaseAlertWithCountDown from "../components/Base/BaseAlertWithCountDown.vue";
import WebhookReport from "../components/Webhooks/Reports.vue";
import UPDATE_WEBHOOK from "../graphql/Webhooks/UpdateWebhook.gql";
import CREATE_WEBHOOK from "../graphql/Webhooks/CreateWebhook.gql";
export default {
  name: "WebhookEdit",
  components: {
    InputWithSkeleton,
    BaseAlertWithCountDown,
    WebhookReport
  },
  props: {
    webhook: {
      type: Object,
      default() {
        return {};
      }
    },
    isLoading: Boolean
  },
  data() {
    return {
      newWebhook: this.$dup(this.webhook),
      saving: false,
      error: false,
      showAlert: false,
      errorMessage: null,

      webhookTopicsTranslate: {
        onProductSave: "Modificacion de productos (creación + edición)",
        onProductDelete: "Eliminación de productos",
        onOrderSave: "Modificacion de órdenes (creación + edición)",
        onOrderDelete: "Eliminación de órdenes",
        onIntegrationConfigSave:
          "Modificacion de integraciones (creación + edición)",
        onIntegrationConfigDelete: "Eliminación de integraciones",
        onInvoiceConfigSave:
          "Modificacion de integraciones de facturación (creación + edición)",
        onInvoiceConfigDelete: "Eliminación de integraciones de facturación",
        onAsyncTaskFinish: "Término exitoso de tareas asíncronas",
        onAsyncTaskFailed: "Término fallido de tareas asíncronas"
      }
    };
  },
  computed: {
    validation() {
      return {
        callbackUrl: {
          valid: this.newWebhook.callbackUrl?.length > 0,
          invalidFeedback: "La url es obligatoria"
        }
      };
    },
    valid() {
      let valid = true;
      Object.keys(this.validation).forEach(x => {
        valid = valid && this.validation[x].valid;
      });
      return valid;
    },
    changed() {
      return !this.$objDeepCompare(this.webhook, this.newWebhook);
    },
    alertVariant() {
      return this.error ? "danger" : "success";
    },
    alertMessage() {
      return this.error
        ? "Ha ocurrido un error guardando los datos: " + this.errorMessage
        : "Webhook guardado exitosamente";
    }
  },
  methods: {
    /**
     * Indica si es creacion o no
     * @return {Boolean}
     */
    isConsolidated() {
      if (this.$route.name == "WebhookNew") {
        return false;
      } else {
        return true;
      }
    },
    /**
     * Valida si se puede guardar el webhook y redirecciona a la
     * lista de webhooks si se puede guardar
     */
    async save() {
      this.showAlert = false;
      this.error = false;
      this.errorMessage = null;
      if (!this.valid) {
        this.error = true;
        this.errorMessage = "Hay campos que se deben rellenar";
        this.showAlert = true;
      } else {
        this.saving = true;
        let action = "updated";
        if (this.isConsolidated()) {
          await this.updateWebhook();
        } else {
          await this.createWebhook();
          action = "created";
        }
        if (!this.error) {
          this.$router.push({
            name: "Webhooks",
            params: {
              action: action
            }
          });
        }
      }
    },
    /**
     * Envia la mutacion para actualizar un webhook
     */
    async updateWebhook() {
      let tempWebhook = {};
      tempWebhook.callbackUrl = this.newWebhook.callbackUrl;
      Object.keys(this.webhookTopicsTranslate).forEach(val => {
        tempWebhook[val] = this.newWebhook[val];
      });
      await this.$apollo
        .mutate({
          mutation: UPDATE_WEBHOOK,
          variables: {
            webhook: {
              id: this.webhook.id,
              ...tempWebhook
            }
          }
        })
        .then(({ data }) => {
          if (!data.updateWebhook.result) {
            this.error = true;
            this.errorMessage = data.updateWebhook.error;
            this.showAlert = true;
          } else {
            Object.assign(this.webhook, this.tempWebhook);
          }
          this.saving = false;
        })
        .catch(e => {
          this.error = true;
          this.errorMessage = e.message;
          this.showAlert = true;
          this.saving = false;
        });
    },
    /**
     * Envia la mutacion para crear un webhook
     */
    async createWebhook() {
      let tempWebhook = {};
      tempWebhook.callbackUrl = this.newWebhook.callbackUrl;
      Object.keys(this.webhookTopicsTranslate).forEach(val => {
        tempWebhook[val] = this.newWebhook[val];
      });
      await this.$apollo
        .mutate({
          mutation: CREATE_WEBHOOK,
          variables: {
            webhook: {
              ...tempWebhook
            }
          }
        })
        .then(({ data }) => {
          if (!data.createWebhook.result) {
            this.error = true;
            this.errorMessage = data.createWebhook.error;
            this.showAlert = true;
          } else {
            Object.assign(this.webhook, this.tempWebhook);
            this.webhook.id = data.createWebhook.warehouseId;
          }
          this.saving = false;
        })
        .catch(e => {
          this.error = true;
          this.errorMessage = e.message;
          this.showAlert = true;
          this.saving = false;
        });
    },
    /**
     * Se encarga de resetear los valores del formulario
     */
    resetData() {
      this.newWebhook = this.$dup(this.webhook);
    }
  },
  watch: {
    webhook: {
      handler(val) {
        this.newWebhook = this.$dup(val);
      },
      deep: true
    }
  }
};
</script>

<style></style>
