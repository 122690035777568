<template>
  <div>
    <ApolloQuery
      :query="require('@/graphql/AdminCategoryAttribute.gql')"
      :variables="{ id: $route.params.caId }"
      fetchPolicy="no-cache"
    >
      <template slot-scope="{ result: { error, data }, query, isLoading }">
        <!-- Loading -->
        <b-spinner v-if="isLoading" label="Spinning"></b-spinner>
        <!-- Error -->
        <b-alert v-else-if="error" show="" variant="danger">
          Ha ocurrido un error
        </b-alert>
        <!-- Result -->
        <base-header
          v-if="data && data.adminCategoryAttribute"
          :title="data.adminCategoryAttribute.name"
          title-link="#"
          :title-size="9"
        >
          <template v-slot:actions>
            <b-button variant="outline-info" size="sm" to="/vtex_homologation/">
              <b-icon-arrow-left></b-icon-arrow-left> Volver
            </b-button>
          </template>
        </base-header>
        <b-container fluid="">
          <b-row v-if="data && data.adminCategoryAttribute">
            <b-col md="6">
              <h4>Homologaciones</h4>
              <vtex-homologation-attribute-new-modal
                :category-attribute="data.adminCategoryAttribute"
                @new-purpose="reloadPurposes(query)"
              ></vtex-homologation-attribute-new-modal>
              <vtex-homologation-attributes-table
                :purposes="purposes"
                @change-purposes="reloadPurposes(query)"
              ></vtex-homologation-attributes-table>
            </b-col>
            <b-col md="6" v-if="purposes.length > 0">
              <h4>Valores</h4>
              <vtex-homologation-attributes-values-table
                :purposes="purposes"
                :options="data.adminCategoryAttribute.options"
              ></vtex-homologation-attributes-values-table></b-col
          ></b-row>
        </b-container>
      </template>
    </ApolloQuery></div
></template>
<script>
import BaseHeader from "@/components/BaseHeader.vue";
import VtexHomologationAttributeNewModal from "@/components/VtexHomologation/VtexHomologationAttributes/VtexHomologationAttributeNewModal";
import VtexHomologationAttributesTable from "@/components/VtexHomologation/VtexHomologationAttributes/VtexHomologationAttributesTable";
import VtexHomologationAttributesValuesTable from "@/components/VtexHomologation/VtexHomologationAttributes/VtexHomologationAttributesValuesTable";
import ALL_VTEX_PURPOSES from "@/graphql/AllVtexPurposes.gql";
export default {
  name: "VtexHomologationAttributesEdit",
  components: {
    VtexHomologationAttributesTable,
    VtexHomologationAttributeNewModal,
    VtexHomologationAttributesValuesTable,
    BaseHeader
  },
  props: {
    caId: String
  },
  data() {
    return {
      purposes: []
    };
  },
  beforeMount() {
    this.getVtexPurposes();
  },
  methods: {
    /**
     * Recarga la lista de purposes
     */
    reloadPurposes(query) {
      query.refresh();
    },
    /**
     * Obtiene los purposes de Vtex que sean de marca
     * e indica que ya cargó esta parte.
     */
    async getVtexPurposes() {
      await this.$apollo
        .query({
          query: ALL_VTEX_PURPOSES,
          variables: {
            model: "category_attribute",
            caId: this.caId
          }
        })
        .then(({ data }) => {
          this.purposes = data.allVtexPurposes.edges;
        });
    }
  }
};
</script>

<style scoped>
td {
  cursor: pointer;
}
</style>
