<template>
  <div>
    <ApolloQuery
      :query="require('../../../graphql/AllOrdersDocuments.gql')"
      :variables="queryVariables('')"
      :fetchPolicy="'cache-and-network'"
      clientId="apolloClientCached"
    >
      <template slot-scope="{ result: { error, data }, query, isLoading }">
        <!-- Error -->
        <b-alert v-if="error" show="" variant="danger">
          Ha ocurrido un error
        </b-alert>
        <span v-if="data && isLoading" class="m-2 float-right">
          Actualizando lista documentos...
        </span>
        <!-- Result -->
        <b-table-simple hover>
          <b-thead>
            <b-tr>
              <b-th>N° de Pedido</b-th>
              <b-th>Origen</b-th>
              <b-th>Fecha creación</b-th>
              <b-th>Folio Venta</b-th>
              <b-th>Documento de Venta</b-th>
              <b-th>ID Anulación</b-th>
            </b-tr>
            <b-tr>
              <b-th>
                <b-form-group>
                  <b-form-tags
                    v-model="ordersNumbersSelected"
                    separator=", "
                    placeholder="N° de orden"
                    size="sm"
                  ></b-form-tags>
                </b-form-group>
              </b-th>
              <b-th>
                <b-form-group>
                  <v-select
                    v-model="integrationsSelected"
                    placeholder="Todos"
                    :options="allOrigins"
                    multiple=""
                    :reduce="origin => origin.value"
                  ></v-select>
                </b-form-group>
              </b-th>

              <b-th>
                <b-form-group>
                  <date-picker
                    v-model="dateSelected"
                    type="date"
                    value-type="date"
                    range
                    :clearable="true"
                  >
                  </date-picker>
                </b-form-group>
              </b-th>
              <b-th>
                <b-form-group>
                  <b-form-tags
                    v-model="foliosNumbersSelected"
                    separator=", "
                    placeholder="N° de folio"
                    size="sm"
                  ></b-form-tags>
                </b-form-group>
              </b-th>
              <b-th colspan="2"></b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-if="data && !data.allOrders.edges.length">
              <b-td colspan="11">No tienes pedidos registrados</b-td>
            </b-tr>

            <documents-tabs-b-sale-row
              v-else-if="data"
              v-for="order of data.allOrders.edges"
              :ic="ic"
              :key="order.node.id"
              :order="order.node"
            />
          </b-tbody>
        </b-table-simple>
        <b-spinner
          v-if="isLoading"
          label="Spinning"
          class="m-2 float-right"
        ></b-spinner>
        <div v-else>
          <b-button
            v-if="hasNextPage(data)"
            class="m-2 float-right"
            @click="seeMore(query, data.allOrders.pageInfo.endCursor)"
          >
            Ver más
          </b-button>
          <b-alert v-else show variant="light" class="text-center">
            No hay más datos para mostrar.
          </b-alert>
        </div>
      </template>
    </ApolloQuery>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import ORDER_FILTER_INDEXES from "../../../graphql/OrderFilterIndexes.gql";
import DocumentsTabsBSaleRow from "./DocumentsTabsBSaleRow.vue";
export default {
  name: "DocumentsTabsBSale",
  components: { DocumentsTabsBSaleRow },
  model: {
    event: "change"
  },
  props: {
    ic: Object,
    params: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      buyersSelected: null,
      dateSelected: [null, null],
      integrationsSelected: [],
      ordersNumbersSelected: null,
      foliosNumbersSelected: null,
      allOrigins: []
    };
  },
  beforeMount() {
    this.orderFilterIndexes();
  },
  computed: {
    ...mapState(["filters"])
  },
  methods: {
    ...mapMutations(["setFilter"]),
    cleanFilters() {
      this.setFilter({
        ids: null,
        numbers: null,
        folios: null,
        skus: null,
        statuses: [],
        origins: [],
        createFrom: null,
        createTo: null,
        updateFrom: null,
        updateTo: null,
        shippingLabelPrint: null,
        buyers: null
      });
    },
    orderFilterIndexes() {
      this.$apollo
        .query({
          query: ORDER_FILTER_INDEXES
        })
        .then(result => {
          this.allOrigins = result.data.orderFilterIndexes.origins.map(o => {
            return { label: o, value: o };
          });
        });
    },

    queryVariables(cursor) {
      const variables = {};
      if (cursor) {
        variables.cursor = cursor;
      }
      if (this.filters.numbers) {
        variables.numbers = this.filters.numbers;
      }
      if (this.filters.folios) {
        variables.folios = this.filters.folios;
      }
      if (this.filters.ids) {
        variables.ids = this.filters.ids;
      }
      if (this.filters.origins) {
        variables.origins = this.filters.origins;
      }
      if (this.filters.createFromO) {
        variables.createFrom = this.filters.createFromO;
      }
      if (this.filters.createToO) {
        variables.createTo = this.filters.createToO;
      }

      return variables;
    },
    /**
     * Indica si hay una página siguiente en la query de GraphQl basado
     * exclusivamente en la existencia de un cursor.
     * @return {boolean}
     */
    hasNextPage(data) {
      return data?.allOrders?.pageInfo?.endCursor != null;
    },
    /**
     * Trae más resultados de una query avanzando en su cursor.
     * @param {Object} query
     * @param {String} cursor
     */
    async seeMore(query, cursor) {
      await query.fetchMore({
        variables: this.queryVariables(cursor),
        updateQuery: (prev, { fetchMoreResult }) => {
          const updated = Object.assign({}, this.$dup(prev));
          updated.allOrders.pageInfo = fetchMoreResult.allOrders.pageInfo;
          if (fetchMoreResult?.allOrders?.edges?.length !== 0) {
            updated.allOrders.edges.push(...fetchMoreResult.allOrders.edges);
          }
          return updated;
        }
      });
    }
  },
  watch: {
    filters() {
      this.$emit("change", this.filters);
    },
    integrationsSelected(newValue) {
      this.setFilter({ origins: newValue });
    },
    ordersNumbersSelected(newValue) {
      this.setFilter({ numbers: newValue });
    },
    foliosNumbersSelected(newValue) {
      this.setFilter({ folios: newValue });
    },
    dateSelected(newValue) {
      this.setFilter({ createFromO: newValue[0] });
      newValue[1]?.setHours(23, 59, 59);
      this.setFilter({ createToO: newValue[1] });
      if (newValue[0] == null) {
        this.setFilter({
          createFromO: null
        });
      }
      if (newValue[1] == null) {
        this.setFilter({ createTo0: null });
      }
      this.params.createFrom = this.filters.createFromO;
    }
  }
};
</script>

<style scoped>
.btn-sm {
  margin: 5px;
}
</style>
