var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ApolloQuery',{attrs:{"query":require('../../../graphql/BulkUpload/TemplateDownload/AllBulkUploadTemplateDownload.gql'),"variables":{ cursor: '', first: _vm.first },"fetchPolicy":'cache-and-network',"clientId":"apolloClientCached","notifyOnNetworkStatusChange":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_result = ref.result;
var error = ref_result.error;
var data = ref_result.data;
var query = ref.query;
var isLoading = ref.isLoading;
return [(data && isLoading)?_c('span',{staticClass:"m-2 float-right"},[_vm._v(" Actualizando tabla... ")]):_vm._e(),(data && data.allBulkUploadTemplateDownload)?_c('b-table-simple',{attrs:{"responsive":"","hover":""}},[_c('b-thead',[_c('b-tr',[_c('b-th',[_vm._v("Fecha")]),_c('b-th',[_vm._v("Template")]),_c('b-th',[_vm._v("Solicitado por")]),_c('b-th',[_vm._v("Progreso")]),_c('b-th',[_vm._v("Descargar archivo")]),_c('b-th',[_vm._v("Informe de Errores")])],1)],1),_c('b-tbody',[(!data.allBulkUploadTemplateDownload.edges.length)?_c('b-tr',[_c('b-td',{attrs:{"colspan":"6"}},[_vm._v("No existe registro de ninguna carga")])],1):_vm._e(),_vm._l((data.allBulkUploadTemplateDownload.edges),function(history){return _c('downloads-table-row',{key:history.node.id,attrs:{"history":history.node}})})],2)],1):_vm._e(),(error)?_c('b-alert',{attrs:{"show":"","variant":"danger"}},[_vm._v(" Ha ocurrido un error ")]):_vm._e(),(!data && isLoading)?_c('b-skeleton-table',{attrs:{"rows":10,"columns":5,"table-props":{ bordered: true, striped: true }}}):_c('div',[(data && isLoading)?_c('b-spinner',{staticClass:"m-2 float-right"}):(_vm.hasNextPage(data))?_c('b-button',{staticClass:"m-2 float-right",on:{"click":function($event){return _vm.seeMore(
              query,
              data.allBulkUploadTemplateDownload.pageInfo.endCursor
            )}}},[_vm._v(" Ver más ")]):_c('b-alert',{staticClass:"text-center",attrs:{"show":"","variant":"light"}},[_vm._v(" No hay más datos para mostrar. ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }