<template>
  <b-spinner
    label="Spinning"
    v-if="loadingPurposes || loadingSizes || loadingTable"
  ></b-spinner>

  <div v-else>
    <base-homologation-table
      :key="id"
      :columns="columns"
      :rows="rows"
      :purposes-index-rev="purposesIndexRev"
      :homologations-ids-rev="sizesIdsRev"
      :homologation-index="homologationIndex"
      :mutation="mutation"
      mutation-name="saveVtexHomologation"
      model-name="Products::Size"
      :all-values-father="this.allValues"
      @all-values="allValuesChange"
    ></base-homologation-table>
  </div>
</template>

<script>
import ALL_SIZES from "@/graphql/AllSizes.gql";
import ALL_VTEX_PURPOSES from "@/graphql/AllVtexPurposes.gql";
import SAVE_VTEX_HOMOLOGATION from "@/graphql/SaveVtexHomologation.gql";
import BaseHomologationTable from "@/components/BaseHomologationTable";
import { mapState } from "vuex";
import GET_VTEX_SPECIFICATION_VALUES from "@/graphql/IntegrationConfigVtexGetSpecificationValues.gql";

export default {
  name: "VtexHomologationSizes",
  components: { BaseHomologationTable },
  computed: {
    ...mapState(["currentUser"])
  },
  data() {
    return {
      purposes: [],
      sizes: [],
      rows: [],
      columns: [
        {
          type: "text",
          title: "Talla Centry",
          width: 300,
          readOnly: true
        }
      ],
      mutation: SAVE_VTEX_HOMOLOGATION,
      purposesIndex: {},
      purposesIndexRev: {},
      homologationIndex: {},
      homologationValues: {},
      loadingSizes: true,
      loadingPurposes: true,
      loadingTable: true,
      sizesIds: {},
      sizesIdsRev: {},
      available: false,
      allValues: false,
      id: 0
    };
  },
  async mounted() {
    Promise.all([this.getSizes(), this.getVtexPurposes()])
      .then(() => this.prepareColumns())
      .then(() => this.prepareRows())
      .then(() => (this.loadingTable = false));
  },
  methods: {
    /**
     * Obtiene las tallas de Centry usadas por la empresa del
     * usuario o todas las existentes en Centry.
     */
    async getSizes() {
      this.loadingSizes = true;
      await this.$getAllPages(
        ALL_SIZES,
        {
          companyId: this.allValues ? "" : this.currentUser.company.id
        },
        "allSizes"
      ).then(array => {
        this.sizes = array;
        this.loadingSizes = false;
      });
    },
    /**
     * Obtiene los purposes de Vtex que sean de talla
     * e indica que ya cargó esta parte.
     */
    async getVtexPurposes() {
      await this.$apollo
        .query({
          query: ALL_VTEX_PURPOSES,
          variables: {
            model: "product_size"
          }
        })
        .then(({ data }) => {
          this.purposes = data.allVtexPurposes.edges;
          this.purposes.forEach(purpose => {
            this.homologationValues[purpose.node.id] = {};
            purpose.node.vtexHomologations.forEach(hv => {
              this.homologationValues[purpose.node.id][hv.vtexHomologableId] =
                hv.v;
            });
          });
          this.loadingPurposes = false;
        });
    },
    /**
     * Prepara las columnas de la tabla trayendo las
     * opciones desde Vtex si corresponde.
     */
    async prepareColumns() {
      let indexes = {};
      let indexesRev = {};
      let i = 0;
      for (let p of this.purposes) {
        let icId = p.node.integrationConfig.id;
        let options = await this.getSizesFromVtex(
          icId,
          p.node.specId,
          p.node.id
        );
        let column = {
          type: p.node.dataType,
          title: p.node.integrationConfig.label + ": " + p.node.specName,
          width: 300,
          source: options
        };
        this.columns.push(column);
        indexes[p.node.id] = i + 1;
        indexesRev[i + 1] = p.node.id;
        i += 1;
      }
      this.purposesIndex = indexes;
      this.purposesIndexRev = indexesRev;
    },
    /**
     * Prepara las filas obteniendo los datos ya rellenados
     * para las columnas correspondientes.
     */
    prepareRows() {
      const indexes = this.purposesIndex;
      const purposesLength = Object.keys(indexes).length;
      let rows = [];
      this.sizes.forEach(size => {
        let row = [];
        row[0] = size.node.name;
        this.homologationIndex[size.node.name] = size.node.id;
        for (let i = 1; i <= purposesLength; i++) {
          row[i] = "";
        }
        this.purposes.forEach(purpose => {
          let index = indexes[purpose.node.id];
          let value = this.homologationValues[purpose.node.id][size.node.id];
          if (value) {
            row[index] = this.sizesIds[purpose.node.id][value] || value;
          }
        });
        rows.push(row);
      });
      this.rows = rows;
    },
    /**
     * Obtiene las opciones de una especificación
     * en Vtex
     */
    async getSizesFromVtex(icId, specId, purposeId) {
      return await this.$apollo
        .query({
          query: GET_VTEX_SPECIFICATION_VALUES,
          variables: {
            specificationId: specId,
            id: icId
          }
        })
        .then(({ data }) => {
          let options = [];
          this.sizesIds[purposeId] = {};
          this.sizesIdsRev[purposeId] = {};
          let values =
            data?.integrationConfigVtex?.specificationValuesList || [];
          values.forEach(v => {
            options.push(v.value);
            this.sizesIdsRev[purposeId][v.value] = v.fieldValueId;
            this.sizesIds[purposeId][v.fieldValueId] = v.value;
          });
          return options;
        });
    },
    /**
     * Actualiza los valores de la tabla cuando
     * hay un cambio.
     */
    async allValuesChange(value) {
      this.allValues = value;
      await this.getSizes();
      await this.prepareRows();
      this.id = this.id += 1;
    }
  }
};
</script>
