<template>
  <b-spinner v-if="loading"></b-spinner>
  <base-accordion v-else :id="accordionId">
    <base-collapse-card
      :id="`selectDomain${id}`"
      :accordion="accordionId"
      :visible="!showForm"
      @show="hideForm"
      :disabled="editMode"
    >
      <template slot="title">
        Tipo de Productos
      </template>
      <mercado-libre-domain-selector
        v-model="domain"
        :integration-config-id="integrationConfigId"
        label="¿Para qué tipo de productos se necesita la tabla de tallas?"
      ></mercado-libre-domain-selector>
    </base-collapse-card>
    <base-collapse-card
      :id="`sizeChartForm${id}`"
      :accordion="accordionId"
      :visible="showForm"
      :disabled="!domain"
      class="overflow-visible"
    >
      <template slot="title">
        Tabla de tallas
      </template>
      <slot></slot>
    </base-collapse-card>
  </base-accordion>
</template>

<script>
import BaseAccordion from "../../BaseAccordion.vue";
import BaseCollapseCard from "../../Base/CollapseCard.vue";
import MercadoLibreDomainSelector from "./DomainSelector.vue";
export default {
  name: "GridChartSelector",
  components: {
    BaseAccordion,
    BaseCollapseCard,
    MercadoLibreDomainSelector
  },
  model: {
    prop: "_value",
    event: "change"
  },
  props: {
    integrationConfigId: {
      type: String,
      default: null
    },
    loading: {
      type: Boolean,
      default: true
    },
    sizeChart: {
      type: Object,
      default: null
    },
    _value: {
      type: Object,
      default: null
    },
    showForm: {
      type: Boolean,
      default: false
    },
    editMode: {
      type: Boolean,
      required: true
    },
    id: {
      type: String,
      default: "Id"
    }
  },
  data() {
    return {
      domain: this._value
    };
  },
  computed: {
    accordionId() {
      return `formSizeChartAccordion${this.id}`;
    }
  },
  methods: {
    /**
     * Esconde modal de formulario de creacion
     */
    hideForm() {
      this.$emit("hideForm");
    }
  },
  watch: {
    domain(newValue) {
      this.$emit("change", newValue);
    },
    _value() {
      this.domain = this._value;
    }
  }
};
</script>
