<template>
  <date-picker
    v-model="value"
    @input="emitChange"
    :type="type"
    :value-type="valueType"
    :formatter="tzFormat"
    :renderInputText="renderInputText"
    :disabled="disabled"
  >
  </date-picker>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "BaseDateTime",
  model: {
    prop: "_value",
    event: "change"
  },
  props: {
    _value: {
      type: [Date, String],
      default: null
    },
    type: {
      type: String,
      default: "date"
    },
    valueType: {
      type: String,
      default: "date"
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      value: this._value
    };
  },
  computed: {
    ...mapState(["currentUser"]),
    /**
     * OJO que esto funciona sólo cuando
     * valueType es 'format'
     */
    tzFormat() {
      const moment = this.$moment;
      const timeZone = this.currentUser.company.timeZoneName;
      return {
        /**
         * Entrega el valor formateado en ISOString
         * @param {Date} date
         * @returns {String}
         */
        stringify(date) {
          return moment(date)
            .tz(timeZone)
            .format();
        },
        /**
         * Parsea un valor de string a fecha.
         * @param {String} value
         * @returns {Date}
         */
        parse(value) {
          if (!value || !value.length) return null;
          return moment.tz(value, timeZone).toDate();
        }
      };
    }
  },
  methods: {
    emitChange(value) {
      this.$emit("change", value);
    },
    /**
     * Entrega el texto para mostrar
     * a partir la fecha manejada como valor
     * @param {Date} date
     * @returns {String}
     */
    renderInputText(date) {
      if (!date || isNaN(date.getTime())) return "";
      const moment = this.$moment;
      const timeZone = this.currentUser.company.timeZoneName;
      return moment(date)
        .tz(timeZone)
        .format("DD/MM/YYYY HH:mm");
    }
  },
  watch: {
    _value(newValue) {
      this.value = newValue;
    }
  }
};
</script>
