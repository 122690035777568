var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ApolloQuery',{attrs:{"query":require('../graphql/allDocumentInvoiceRequestResponse.gql'),"variables":{
      cursor: '',
      documentId: this.documentId
    },"fetchPolicy":'cache-and-network',"clientId":"apolloClientCached","notifyOnNetworkStatusChange":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var ref_result = ref.result;
    var error = ref_result.error;
    var data = ref_result.data;
    var isLoading = ref.isLoading;
return [(data && isLoading)?_c('span',{staticClass:"m-2 float-right"},[_vm._v(" Actualizando lista de Invoice Request Responses... ")]):_vm._e(),(data.allDocumentInvoiceRequestResponse.edges != null)?_c('invoice-request-response-row',{attrs:{"irr":data.allDocumentInvoiceRequestResponse.edges.map(function (x) { return x.node; })}}):_vm._e(),(error)?_c('b-alert',{attrs:{"show":"","variant":"danger"}},[_vm._v(" "+_vm._s(error.message ? error.message : "Ha ocurrido un error")+" ")]):_vm._e(),(isLoading)?_c('b-spinner',{staticClass:"m-2 float-right",attrs:{"label":"Spinning"}}):_c('div',[(_vm.hasNextPage(data))?_c('b-button',{staticClass:"m-2 float-right",on:{"click":function($event){return _vm.seeMore(
              _vm.query,
              data.allDocumentInvoiceRequestResponse.pageInfo.endCursor
            )}}},[_vm._v(" Ver más ")]):_c('b-alert',{staticClass:"text-center",attrs:{"show":"","variant":"light"}},[_vm._v(" No hay más datos para mostrar. ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }