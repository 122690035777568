<template>
  <div class="d-inline-block">
    <b-button
      :disabled="disabled || loading"
      variant="outline-info"
      :title="title"
      @click="generateReport"
    >
      <b-icon-file-earmark-arrow-down></b-icon-file-earmark-arrow-down>
      Descargar detalles
    </b-button>
    <br /><br />
    <span class="text-info text-center" v-if="loading">
      {{ processed }} de {{ total }} Pedidos Procesados
    </span>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="letter"
      pdf-content-width="719px"
      ref="html2Pdf"
      :htmlToPdfOptions="{
        filename: 'order-details'
      }"
    >
      <section slot="pdf-content" class="pdf">
        <div v-for="(orders, origin) in groupedOrders" :key="origin">
          <div
            v-for="(order, indexOrder) in orders"
            :key="order.id"
            style="margin: 20px"
          >
            <section class="pdf-item">
              <table class="general-table">
                <tbody>
                  <tr>
                    <th>Empresa:</th>
                    <td>{{ $store.state.currentUser.company.name }}</td>
                  </tr>
                  <tr>
                    <th>Origen:</th>
                    <td>{{ origin }}</td>
                  </tr>
                  <tr>
                    <th>Número de pedido:</th>
                    <td>{{ order.numberOrigin || order.idOrigin }}</td>
                  </tr>
                  <tr>
                    <th>Fecha de venta:</th>
                    <td>{{ order.createdAtOrigin | formatDateTime }}</td>
                  </tr>
                  <tr>
                    <th>Transportista:</th>
                    <td>
                      {{
                        order.items
                          ? $dig(order.items[0], "shipmentProvider")
                          : ""
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th>Compromiso de entrega:</th>
                    <td>
                      {{ order.dateForDelivery | formatDateTime }}
                    </td>
                  </tr>
                  <tr>
                    <th>Envio prioritario:</th>
                    <td>
                      {{ order.priorityShipping ? "Si" : "No" }}
                    </td>
                  </tr>
                </tbody>
              </table>

              <h1>Productos</h1>
              <b-table-simple :small="true" class="orders">
                <b-thead>
                  <b-tr>
                    <b-th>SKU</b-th>
                    <b-th>Producto</b-th>
                    <b-th>Cantidad</b-th>
                    <b-th>Precio unitario</b-th>
                    <b-th>Descuento</b-th>
                    <b-th>Subtotal</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr v-for="item in order.items" :key="item.id">
                    <b-td>{{ item.sku }}</b-td>
                    <b-td>{{ item.name }}</b-td>
                    <b-td>
                      {{ (item.quantity || 0) - (item.quantityRestocked || 0) }}
                    </b-td>
                    <b-td>{{ item.unitPrice | toCurrency }}</b-td>
                    <b-td>{{ item.discountAmount | toCurrency }}</b-td>
                    <b-td>{{ item.paidPrice | toCurrency }}</b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>

              <h1>Resumen Orden</h1>
              <b-table-simple :small="true" class="orders">
                <b-thead>
                  <b-tr>
                    <b-th>Monto pedido</b-th>
                    <b-th>Descuento a productos</b-th>
                    <b-th>Despacho</b-th>
                    <b-th>Descuento al despacho</b-th>
                    <b-th>Total pagado</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr>
                    <b-td>{{ order.totalAmount | toCurrency }}</b-td>
                    <b-td>{{ order.discountAmount | toCurrency }}</b-td>
                    <b-td>{{ order.shippingAmount | toCurrency }}</b-td>
                    <b-td>{{ order.shippingDiscount | toCurrency }}</b-td>
                    <b-td>{{ order.paidAmount | toCurrency }}</b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>

              <h1>Información de Facturación</h1>
              <table class="address">
                <tbody>
                  <tr>
                    <th>Cliente</th>
                    <td>
                      {{ $dig(order, "addressBilling", "firstName") }}
                      {{ $dig(order, "addressBilling", "lastName") }}
                    </td>
                    <th class="ml-2">Dirección:</th>
                    <td>
                      {{ $dig(order, "addressBilling", "line1") }}
                      {{ $dig(order, "addressBilling", "line2") }}
                    </td>
                  </tr>
                  <tr>
                    <th>Rut:</th>
                    <td>{{ order.buyerDni }}</td>
                    <th class="ml-2">Comuna/Distrito:</th>
                    <td>{{ $dig(order, "addressBilling", "county") }}</td>
                  </tr>
                  <tr>
                    <th>Email:</th>
                    <td>{{ order.bunyerEmail }}</td>
                    <th class="ml-2">Ciudad:</th>
                    <td>{{ $dig(order, "addressBilling", "city") }}</td>
                  </tr>
                  <tr>
                    <th>Teléfono:</th>
                    <td>{{ order.buyerPhone }}</td>
                    <th class="ml-2">Región /Provincia:</th>
                    <td>{{ $dig(order, "addressBilling", "state") }}</td>
                  </tr>
                  <tr>
                    <th></th>
                    <td></td>
                    <th class="ml-2">Pais</th>
                    <td>{{ $dig(order, "addressBilling", "country") }}</td>
                  </tr>
                </tbody>
              </table>

              <h1>Información de Despacho</h1>
              <table class="address">
                <tbody>
                  <tr>
                    <th>Cliente</th>
                    <td>
                      {{ $dig(order, "addressShipping", "firstName") }}
                      {{ $dig(order, "addressShipping", "lastName") }}
                    </td>
                    <th class="ml-2">Dirección:</th>
                    <td>
                      {{ $dig(order, "addressShipping", "line1") }}
                      {{ $dig(order, "addressShipping", "line2") }}
                    </td>
                  </tr>
                  <tr>
                    <th>Rut:</th>
                    <td>{{ order.buyerDni }}</td>
                    <th class="ml-2">Comuna/Distrito:</th>
                    <td>{{ $dig(order, "addressShipping", "county") }}</td>
                  </tr>
                  <tr>
                    <th>Email:</th>
                    <td>{{ order.bunyerEmail }}</td>
                    <th class="ml-2">Ciudad:</th>
                    <td>{{ $dig(order, "addressShipping", "city") }}</td>
                  </tr>
                  <tr>
                    <th>Teléfono:</th>
                    <td>{{ order.buyerPhone }}</td>
                    <th class="ml-2">Región /Provincia:</th>
                    <td>{{ $dig(order, "addressShipping", "state") }}</td>
                  </tr>
                  <tr>
                    <th></th>
                    <td></td>
                    <th class="ml-2">Pais</th>
                    <td>{{ $dig(order, "addressShipping", "country") }}</td>
                  </tr>
                </tbody>
              </table>
            </section>
            <div
              v-if="!(origin in lastOrder) || lastOrder[origin] !== indexOrder"
              class="html2pdf__page-break"
            />
          </div>
        </div>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";
import gql from "graphql-tag";
import { mapState } from "vuex";

const ALL_ORDERS = gql`
  query allOrders(
    $cursor: String
    $numbers: [String!]
    $ids: [ID!]
    $skus: [String!]
    $statuses: [String!]
    $origins: [String!]
    $createFrom: ISO8601DateTime
    $createTo: ISO8601DateTime
    $updateFrom: ISO8601DateTime
    $updateTo: ISO8601DateTime
    $shippingLabelPrint: Boolean
    $buyers: [String!]
  ) {
    allOrders(
      first: 60
      after: $cursor
      numbers: $numbers
      ids: $ids
      skus: $skus
      statuses: $statuses
      origins: $origins
      createFrom: $createFrom
      createTo: $createTo
      updateFrom: $updateFrom
      updateTo: $updateTo
      shippingLabelPrint: $shippingLabelPrint
      buyers: $buyers
    ) {
      edges {
        node {
          id
          origin
          numberOrigin
          idOrigin
          buyerFirstName
          buyerLastName
          buyerDni
          buyerEmail
          buyerPhone
          buyerMobilephone
          createdAt
          updatedAt
          createdAtOrigin
          updatedAtOrigin
          status
          statusOrigin
          shippingLastPrintDate
          totalAmount
          shippingAmount
          discountAmount
          shippingDiscount
          paidAmount
          itemsCount
          orderNotesCount
          documentsCount
          hasShippingLabel
          billable
          modifyStock
          priorityShipping
          dateForDelivery
          addressBilling {
            id
            createdAt
            updatedAt
            firstName
            lastName
            phone1
            phone2
            email
            line1
            line2
            zipCode
            city
            county
            state
            country
            dni
          }
          addressShipping {
            id
            createdAt
            updatedAt
            firstName
            lastName
            phone1
            phone2
            email
            line1
            line2
            zipCode
            city
            county
            state
            country
            dni
          }
          items {
            sku
            name
            unitPrice
            paidPrice
            discountAmount
            shippingAmount
            shipmentProvider
            quantity
            quantityRestocked
            trackingCode
            variant {
              sku
              productId
            }
          }
          orderNotes {
            id
            createdAt
            text
            user {
              firstName
              lastName
            }
          }
        }
      }
      pageInfo {
        endCursor
      }
    }
  }
`;

export default {
  name: "OrdersDetailsButton",
  components: {
    VueHtml2pdf
  },
  props: {
    disabled: Boolean,
    title: String
  },
  data() {
    return {
      loading: false,
      processed: 0,
      total: 0,
      groupedOrders: {},
      lastOrder: {}
    };
  },
  computed: {
    ...mapState(["selectedOrders"])
  },
  methods: {
    async generateReport() {
      this.loading = true;
      this.processed = 0;
      const ids = this.getIds();
      this.total = ids.length;
      this.groupedOrders = {};
      this.lastOrder = {};
      let errors = false;
      let ordersFull = {};
      let swal_text = "";
      let keys = [];
      // FIXME: Lo correcto sería pasarle todos los identificadores una vez y
      // avanzar en la paginación de qraphql con
      // `data.allOrders.pageInfo.endCursor`
      const splited = this.$splitArray(ids, 60);
      for (let i = 0; i < splited.length; i++) {
        const subIds = splited[i];
        if (!errors) {
          await this.$apollo
            .query({
              query: ALL_ORDERS,
              variables: { ids: subIds }
              // variables: { ids: ids }
            })
            .then(({ data }) => {
              if (data.allOrders.edges) {
                data.allOrders.edges.forEach(o => {
                  if (!o.node.billable && !o.node.modifyStock) {
                    if (!ordersFull[o.node.origin]) {
                      ordersFull[o.node.origin] = [];
                    }
                    ordersFull[o.node.origin].push(o.node);
                  } else {
                    if (!this.groupedOrders[o.node.origin]) {
                      this.groupedOrders[o.node.origin] = [];
                    }
                    this.groupedOrders[o.node.origin].push(o.node);
                  }
                });
                keys = Object.keys(this.groupedOrders);
                this.total = keys;
              }
            });
        }
      }
      if (Object.keys(ordersFull).length !== 0) {
        swal_text += `<p>Las siguientes órdenes no tienen detalles disponibles debido a que son órdenes full:</p>
                        <div style="max-height: 10em; overflow-y: auto; width: 100%;">
                          ${Object.entries(ordersFull)
                            .map(key => {
                              return `<p>${key[0]}</p><ul>
                            ${key[1]
                              .map(
                                order =>
                                  `<li style="text-align: start">${
                                    order.numberOrigin
                                      ? order.numberOrigin
                                      : order.idOrigin
                                  }</li>`
                              )
                              .join("")}</ul>`;
                            })
                            .join("<br>")}
                            </div>`;
      }
      if (keys.length !== 0) {
        let lastKey = keys[keys.length - 1];
        this.lastOrder[lastKey] = this.groupedOrders[lastKey].length - 1;
      }
      if (swal_text !== "") {
        this.$swal
          .fire({
            title: "Algunos detalles no están disponibles",
            html: swal_text,
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: "Aceptar"
          })
          .then(result => {
            if (!errors && result.isConfirmed && this.total !== 0) {
              this.$refs.html2Pdf.generatePdf();
            }
          });
      } else {
        if (!errors && this.total !== 0) {
          this.$refs.html2Pdf.generatePdf();
        }
      }
      this.loading = false;
    },
    getIds() {
      return Object.keys(this.selectedOrders).filter(
        k => this.selectedOrders[k].selected
      );
    }
  }
};
</script>

<style scoped>
.pdf h1 {
  font-size: 20px;
  margin: 60px 0 10px 0;
}
table.general-table th {
  padding-right: 10px;
}
table.orders th,
table.address th {
  font-weight: bold;
  color: black;
}
table.orders td,
table.address td {
  font-weight: normal;
  color: black;
  padding: 2px !important;
  height: initial;
  vertical-align: top;
}
table.address tr th:first-child {
  width: 10%;
}
table.address tr td:nth-child(2) {
  width: 30%;
}
table.address tr th:nth-child(3) {
  width: 20%;
}
table.address tr td:nth-child(4) {
  width: 40%;
}
</style>
