<template>
  <div>
    <p>
      El descuento de stock sirve para dejar de publicar un cierto número de
      unidades de cada una de las variantes en {{ icName }}. Este descuento se
      puede hacer por un número de unidades específicas o por un porcentaje del
      stock disponible.
    </p>

    <p>
      Por ejemplo, supongamos que en el campo libre se ingresa un número
      <code>10</code>, entonces el efecto de seleccionar
      <code>Por unidad</code> o <code>Porcentaje</code> sería el siguiente en
      estos casos:
    </p>
    <b-table-simple bordered small fixed>
      <b-tr>
        <b-th>Caso</b-th>
        <b-th>Stock en Centry</b-th>
        <b-th>Stock publicado en {{ icName }} descontando por unidad</b-th>
        <b-th>Stock publicado en {{ icName }} descontando por porcentaje</b-th>
      </b-tr>
      <b-tr>
        <b-th>1</b-th>
        <b-td>25</b-td>
        <b-td>15</b-td>
        <b-td>22</b-td>
      </b-tr>
      <b-tr>
        <b-th>2</b-th>
        <b-td>10</b-td>
        <b-td>0</b-td>
        <b-td>9</b-td>
      </b-tr>
      <b-tr>
        <b-th>3</b-th>
        <b-td>5</b-td>
        <b-td>0</b-td>
        <b-td>4</b-td>
      </b-tr>
      <b-tr>
        <b-th>4</b-th>
        <b-td>0</b-td>
        <b-td>0</b-td>
        <b-td>0</b-td>
      </b-tr>
    </b-table-simple>

    <p>
      Como se puede ver en el ejemplo si el cálculo del procentaje resulta en un
      número decimal, este es redondeado.
    </p>
  </div>
</template>

<script>
export default {
  name: "HelpStockDiscountModal",
  props: {
    icName: {
      type: String,
      default: ""
    }
  }
};
</script>

<style scoped>
code {
  font-size: 100%;
}
table {
  margin-bottom: 10px;
}

.table td {
  padding-block: 0px !important;
  padding-left: 0.3rem !important;
  font-weight: 500 !important;
}

p {
  margin-bottom: 10px;
}
</style>
