<template>
  <div>
    <b-alert v-model="errorSaving" variant="danger" dismissible>
      Se produjo un error enviando la sugerencia:

      {{ errorMessage }}
    </b-alert>
    <div v-if="!sent">
      <p>
        Para mejorar la experiencia de Centry, disponemos de atributos de
        categoría que funcionan para todos los marketplaces que tenemos a tu
        disposición. Si algún atributo de tu producto no está incluido en la
        lista, puedes pedir que se agregue llenando este formulario.
      </p>
      <b-form-group>
        <b-form-group
          label="* Nombre de atributo"
          label-for="categoryAttributeSugest"
          :state="validation.categoryAttributeSugest.valid"
          :invalid-feedback="validation.categoryAttributeSugest.invalidFeedback"
        >
          <b-input
            v-model="categoryAttributeSugest"
            id="categoryAttributeSugest"
          ></b-input>
        </b-form-group>
        <b-form-group
          label="* Marketplace que lo requiere"
          label-for="marketplacesSugest"
          :state="validation.marketplacesSugest.valid"
          :invalid-feedback="validation.marketplacesSugest.invalidFeedback"
        >
          <v-select
            multiple
            :options="
              publicIntegrationInformations.map(x => {
                return { label: x.name, value: x.id };
              })
            "
            :reduce="x => x.value"
            clearable
            v-model="marketplacesSugest"
            id="marketplacesSugest"
          ></v-select>
        </b-form-group>
      </b-form-group>
      <b-button variant="success" @click="sendCategoryAttributeSugestion"
        >Enviar</b-button
      >
    </div>
    <div v-else>
      Recibimos tu sugerencia, nos pondremos en contacto contigo cuando
      revisemos el caso
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import SEND_CATEGORY_ATTRIBUTE_SUGGESTION from "../../../graphql/SendCategoryAttributeSuggestion.gql";

export default {
  name: "ProductEditTabsCategoryAttributesHelpModal",
  props: {
    icIds: {
      type: Array,
      default() {
        return [];
      }
    },
    categoryId: {
      type: String,
      default: ""
    },
    showing: {
      type: Boolean,
      default: false
    },
    publicIntegrationInformations: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      sent: false,
      categoryAttributeSugest: "",
      marketplacesSugest: [],
      errorSaving: false,
      errorMessage: ""
    };
  },

  computed: {
    ...mapState(["currentUser"]),
    validation() {
      return {
        categoryAttributeSugest: {
          valid: this.categoryAttributeSugest.length > 0,
          invalidFeedback: "Se debe ingresar un nombre"
        },
        marketplacesSugest: {
          valid: this.marketplacesSugest.length != 0,
          invalidFeedback: "Se debe ingresar al menos un marketplace"
        }
      };
    },
    valid() {
      let valid = true;
      Object.keys(this.validation).forEach(x => {
        valid = valid && this.validation[x].valid;
      });
      return valid;
    }
  },
  methods: {
    /**
     * Envia la request para crear una sugerencia de category_attributes
     */
    sendCategoryAttributeSugestion() {
      if (!this.valid) return;
      this.saved = true;
      var body = {
        categoryAttributeName: this.categoryAttributeSugest,
        categoryId: this.categoryId,
        publicIntegrationInformations: this.marketplacesSugest
      };
      this.$apollo
        .mutate({
          mutation: SEND_CATEGORY_ATTRIBUTE_SUGGESTION,
          variables: {
            create: body
          }
        })
        .then(({ data }) => {
          if (data.suggestCategoryAttribute.result) {
            this.sent = true;
          } else {
            this.errorMessage = data.suggestCategoryAttribute.error;
            this.errorSaving = true;
          }
        })
        .catch(error => {
          this.errorMessage = error;
          this.errorSaving = true;
        });
    }
  }
};
</script>
