<template>
  <div>
    <company-edit-page :company="company" />
  </div>
</template>
<script>
import CompanyEditPage from "../../components/Company/Edit/EditPage.vue";
export default {
  name: "CompanyEdit",
  components: {
    CompanyEditPage
  },
  props: {
    company: Object
  }
};
</script>
