<template>
  <div>
    <base-header title="Mapeador de modelos por Integración" title-link="#">
      <template v-slot:actions>
        <b-container>
          <b-button
            :href="centryUrl + '/integracion/' + integrationConfigId + '/edit'"
            variant="outline-warning"
          >
            <b-icon-arrow-return-left></b-icon-arrow-return-left>
            Volver
          </b-button>

          <b-button
            :to="'/integracion/' + integrationConfigId + '/translation_map/new'"
            variant="outline-info"
          >
            <b-icon-plus></b-icon-plus>
            Nuevo
          </b-button>
        </b-container>
      </template>
    </base-header>

    <b-container fluid="">
      <b-row>
        <b-col md="12">
          <translation-centry-integrations-table>
          </translation-centry-integrations-table>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import BaseHeader from "@/components/BaseHeader.vue";
import TranslationCentryIntegrationsTable from "@/components/TranslationCentryIntegrations/TranslationCentryIntegrationsTable";
import { centryUrl } from "../../main";

export default {
  name: "TranslationCentryIntegrations",
  components: {
    TranslationCentryIntegrationsTable,
    BaseHeader
  },

  data() {
    return {
      centryUrl,
      integrationConfigId: this.$route.params.integrationConfigId
    };
  }
};
</script>

<style scoped></style>
