<template>
  <div>
    <b-table-simple>
      <b-tr v-for="position of activeClons" :key="'clon-' + position">
        <b-td>Clon</b-td>
        <b-td>
          <b-input v-model="arr_clones[position].id" @input="emitChange">
          </b-input>
        </b-td>
        <b-td>
          <b-input
            v-model="arr_clones[position].title"
            :placeholder="product.title"
            @input="emitChange"
          >
          </b-input>
        </b-td>
        <b-td>
          <v-select
            :id="position + 'listing_type_id'"
            :options="listingTypes"
            placeholder="Seleccionar"
            v-model="arr_clones[position].listing_type_id"
            :disabled="arr_clones[position].id !== ''"
            :reduce="listing => listing.value"
            @input="emitChange"
          ></v-select>
        </b-td>
        <b-td>
          <b-form-input
            v-model="arr_clones[position].price"
            :placeholder="String(product.priceCompare)"
            :state="$validFormPrice(arr_clones[position].price)"
            :formatter="$formatFormPrice"
            @input="emitChange"
          >
          </b-form-input>
        </b-td>
        <b-td v-if="officialStores.length > 0">
          <v-select
            :id="position + 'official_store_id'"
            :options="officialStores"
            placeholder="Seleccionar"
            v-model="arr_clones[position].official_store_id"
            :reduce="listing => listing.value"
            @input="emitChange"
          ></v-select>
        </b-td>
        <b-td>
          <b-spinner v-if="loadingPosition[position]"></b-spinner>
          <span v-else>{{ itemPosition[position] }}</span>
        </b-td>
        <b-td>
          <b-dropdown variant="white" no-caret="" offset="-110">
            <template v-slot:button-content>
              <b-icon-three-dots-vertical></b-icon-three-dots-vertical>
            </template>
            <b-dropdown-item
              :to="{
                name: 'MercadoLibreItemFulfillmentStock',
                params: {
                  integrationConfigId: integrationConfig.id,
                  itemId: arr_clones[position].id
                }
              }"
            >
              Ver fulfillment
            </b-dropdown-item>
            <b-dropdown-item @click="deleteClon(position)">
              Eliminar
            </b-dropdown-item>
          </b-dropdown>
          <!-- <b-button variant="danger" @click="deleteClon(position)">X</b-button> -->
        </b-td>
      </b-tr>
    </b-table-simple>
    <div>
      <b-button variant="outline-info" @click="addClon">Agregar</b-button>
    </div>
  </div>
</template>
<script>
import MERCADO_LIBRE_GET_ITEM_POSITION from "../graphql/MercadoLibreGetItemPosition.gql";

export default {
  name: "ProductEditMercadoLibreClones",
  components: {},
  model: {
    prop: "_value",
    event: "change"
  },
  props: {
    _value: {
      type: Array,
      default() {
        return [];
      }
    },
    officialStores: Array,
    listingTypes: Array,
    product: Object,
    integrationConfig: Object
  },
  computed: {
    activeClons() {
      return this.arr_clones
        .map((u, position) => {
          return u.deleted ? -1 : position;
        })
        .filter(x => x >= 0);
    }
  },
  beforeMount() {
    this.loadPositions();
  },
  data() {
    return {
      arr_clones: JSON.parse(JSON.stringify(this._value)),
      itemPosition: this._value.map(() => "-- No Informada --"),
      loadingPosition: this._value.map(() => true)
    };
  },
  methods: {
    loadPositions() {
      this.loadingPosition = this.arr_clones.map(() => true);
      this.arr_clones.forEach((_x, pos) => this.loadPosition(pos));
    },
    loadPosition(positionInArray) {
      if (!this.arr_clones[positionInArray].id) {
        this.itemPosition[positionInArray] = "-- Producto no publicado --";
        this.loadingPosition[positionInArray] = false;
        return;
      }
      this.$apollo
        .query({
          query: MERCADO_LIBRE_GET_ITEM_POSITION,
          variables: {
            meliId: this.arr_clones[positionInArray].id,
            integrationConfigId: this.integrationConfig.id
          }
        })
        .then(({ data }) => {
          if (data) {
            const position = this.$dig(
              data,
              "mercadoLibreGetItemPosition",
              "position"
            );
            if (position) {
              this.$set(this.itemPosition, positionInArray, position);
            }
          }
          this.$set(this.loadingPosition, positionInArray, false);
        });
    },
    emitChange() {
      this.$emit("change", this.arr_clones);
    },
    addClon() {
      const newClon = {
        id: "",
        title: "",
        listing_type_id: "",
        price: "",
        official_store_id: ""
      };
      this.arr_clones.push(newClon);
      this.loadingPosition.push(false);
      this.itemPosition.push("-- Producto no creado --");
    },
    deleteClon(position) {
      if (this.arr_clones[position].id !== "") {
        this.arr_clones.splice(position, 1, {
          ...this.arr_clones[position],
          ...{ deleted: true }
        });
      } else {
        this.arr_clones.splice(position, 1);
        this.loadingPosition.splice(position, 1);
        this.itemPosition.splice(position, 1);
      }
      this.emitChange();
    }
  }
};
</script>
