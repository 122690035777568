<template>
  <div>
    <b-modal
      v-model="showModal"
      size="lg"
      @hidden="handleHidden"
      @ok="handleAction"
      @cancel="handleCancel"
    >
      <template #modal-title>
        <base-header
          v-if="!loading"
          :title="modalTitle"
          :titleSize="12"
          :map="mapHeader"
        >
        </base-header>
      </template>

      <b-spinner v-if="loading" label="Spinning"></b-spinner>
      <b-container v-else-if="specificationValue">
        <b-row>
          <b-col>
            <b-form-group label="Nombre*" label-for="name">
              <b-form-input
                id="name"
                v-model="specificationValue.name"
                placeholder="Nombre"
              >
              </b-form-input>
              <span v-if="requiredMessage.name" class="text-danger">
                El nombre es requerido
              </span>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label="Estado" label-for="status">
              <base-boolean-selector
                v-model="specificationValue.isActive"
                trueText="Activo"
                falseText="Inactivo"
              >
              </base-boolean-selector>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group label="Especificación*" label-for="specification">
              <v-select
                id="specification"
                placeholder="Seleccione una especificación"
                v-model="specificationValue.fieldId"
                :options="specifications"
                disabled
              ></v-select>
              <span v-if="requiredMessage.fieldId" class="text-danger">
                La especificación es requerida
              </span>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group label="Descripción" label-for="text">
              <b-form-textarea
                id="text"
                v-model="specificationValue.text"
                placeholder="Descripción"
              >
              </b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group label="Posición" label-for="position">
              <b-form-input
                id="position"
                type="number"
                v-model="specificationValue.position"
                placeholder="Posición"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>

      <template #modal-footer="{ cancel, ok }">
        <b-button-group>
          <b-button variant="outline-info" @click="cancel">
            <b-icon-x-circle></b-icon-x-circle> Cancelar
          </b-button>
          <b-button variant="info" @click="ok" :disabled="disabledOkButton">
            <b-icon-cloud-upload></b-icon-cloud-upload> {{ actionTraduction }}
          </b-button>
        </b-button-group>
      </template>
    </b-modal>
  </div>
</template>

<script>
import BaseBooleanSelector from "@/components/BaseBooleanSelector";
import BaseHeader from "@/components/BaseHeader.vue";

import VTEX_CREATE_SPECIFICATION_VALUE from "@/graphql/VtexCreateSpecificationValue.gql";
import INTEGRATION_CONFIG_VTEX_GET_SPECIFICATION_VALUE from "@/graphql/IntegrationConfigVtexGetSpecificationValue.gql";
import VTEX_UPDATE_SPECIFICATION_VALUE from "@/graphql/VtexUpdateSpecificationValue.gql";

const EMPTY_SPECIFICATION_VALUE = {
  fieldValueId: null,
  fieldId: null,
  name: null,
  text: null,
  isActive: false,
  position: null
};
const ACCEPTED_KEYS = Object.keys(EMPTY_SPECIFICATION_VALUE);

export default {
  name: "VtexSpecificationValuesModal",
  components: {
    BaseBooleanSelector,
    BaseHeader
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    vtexIntegrationConfigId: {
      type: String,
      required: true
    },
    action: {
      type: String,
      default: "create"
    },
    specificationValueId: {
      type: String,
      default: null
    },
    specification: {
      required: true,
      validator: prop => typeof prop === "object" || prop === null
    }
  },
  data() {
    return {
      loading: false,
      showModal: false,
      specificationValue: null,
      originalSpecificationValue: null
    };
  },
  mounted: function() {
    this.specificationValue = this.defaultSpecificationValue();
  },
  methods: {
    /**
     * Realiza la acción correspondiente al presionar Crear/Editar.
     */
    async handleAction() {
      this.action === "create"
        ? await this.createSpecificationValue()
        : await this.updateSpecificationValue();
    },
    /**
     * Al cancelar los cambios realizados, se dejan registrados en la vista
     * los valores originales del valor de la especificación.
     */
    handleCancel() {
      this.specificationValue = Object.assign(
        {},
        this.originalSpecificationValue
      );
    },
    /**
     * Emite el evento 'input' con el valor necesario para cerrar el modal.
     */
    handleHidden() {
      this.$emit("input", false);
    },
    /**
     * Realiza la petición para crear un valor de especificación en Vtex
     */
    async createSpecificationValue() {
      await this.$apollo
        .mutate({
          mutation: VTEX_CREATE_SPECIFICATION_VALUE,
          variables: {
            integrationConfigId: this.vtexIntegrationConfigId,
            specificationValue: this.specificationValueTransformToVtex(
              this.specificationValue
            )
          }
        })
        .then(({ data }) => {
          if (!data?.vtexCreateSpecificationValue?.specificationValue) {
            this.emitErrorMessage(
              "No se pudo crear el valor para la especificación."
            );
          } else {
            this.emitSuccessfulMessage(
              "Valor creado con éxito. Puede tardar unos minutos en reflejarse en el listado."
            );
            this.specificationValue = null;
          }
        })
        .catch(() => {
          this.emitErrorMessage(
            "No se pudo crear el valor para la especificación."
          );
        });
    },
    /**
     * Realiza la petición para obtener un valor de especificación en Vtex.
     * @param {String} specificationValueId
     */
    async getSpecificationValue(specificationValueId) {
      this.loading = true;
      await this.$apollo
        .query({
          query: INTEGRATION_CONFIG_VTEX_GET_SPECIFICATION_VALUE,
          variables: {
            id: this.vtexIntegrationConfigId,
            specificationValueId: specificationValueId
          }
        })
        .then(({ data }) => {
          if (!data?.integrationConfigVtex?.getSpecificationValue) {
            this.emitErrorMessage(
              "No se pudo obtener el valor de la especificación para editarla."
            );
            this.handleHidden();
          } else {
            this.specificationValue =
              data.integrationConfigVtex.getSpecificationValue;
          }
        })
        .catch(() => {
          this.emitErrorMessage(
            "No se pudo actualizar el valor para la especificación."
          );
        });
      this.loading = false;
    },
    /**
     * Realiza la petición para actualizar un valor de especificación en Vtex.
     */
    async updateSpecificationValue() {
      await this.$apollo
        .mutate({
          mutation: VTEX_UPDATE_SPECIFICATION_VALUE,
          variables: {
            integrationConfigId: this.vtexIntegrationConfigId,
            specificationValueId: this.specificationValueId,
            specificationValue: this.specificationValueTransformToVtex(
              this.specificationValue
            )
          }
        })
        .then(({ data }) => {
          if (!data?.vtexUpdateSpecificationValue?.specificationValue) {
            this.emitErrorMessage(
              "No se pudo actualizar el valor para la especificación."
            );
          } else {
            this.emitSuccessfulMessage(
              "Valor actualizado con éxito. Puede tardar unos minutos en reflejarse en el listado."
            );
            this.specificationValue =
              data.vtexUpdateSpecificationValue.specificationValue;
          }
        })
        .catch(() => {
          this.emitErrorMessage(
            "No se pudo actualizar el valor para la especificación."
          );
        });
    },
    /**
     * Se encarga de transformar los valores seleccionados en la vista a
     * aquellos que deben ser enviados a Vtex.
     * @param {Object} specificationValue
     * @return {Object}
     */
    specificationValueTransformToVtex(specificationValue) {
      let newSpecificationValue = Object.assign({}, specificationValue);
      if (
        specificationValue.fieldId !== null &&
        typeof specificationValue.fieldId === "object"
      ) {
        newSpecificationValue.fieldId = specificationValue.fieldId.value;
      }
      if (specificationValue.position !== null) {
        newSpecificationValue.position = parseInt(specificationValue.position);
      }
      return newSpecificationValue;
    },
    /**
     * Se encarga de transformar los valores provenientes de Vtex a
     * aquellos que se utilizan en la vista.
     */
    specificationValueTransformToVue() {
      if (
        typeof this.specificationValue.fieldId === "string" &&
        this.specificationValue.fieldId === this.specification.id
      ) {
        this.specificationValue.fieldId = {
          value: this.specification.id,
          label: this.specification.name
        };
      }
    },
    /**
     * Emite el evento 'succesfulMessage' con el mensaje de exito correspondiente
     * @param {String} message
     */
    emitErrorMessage(errorMessage) {
      this.$emit("errorMessage", errorMessage);
    },
    /**
     * Emite el evento 'succesfulMessage' con el mensaje de exito correspondiente
     * @param {String} message
     */
    emitSuccessfulMessage(message) {
      this.$emit("successfulMessage", message);
    },
    /**
     * Se encarga de seleccionar ciertos atributos del valor de especificación.
     * @param {Object} specificationValue
     * @return {Object}
     */
    selectKeysFromSpecificationValue(specificationValue) {
      return Object.fromEntries(
        ACCEPTED_KEYS.filter(key => key in specificationValue).map(key => [
          key,
          specificationValue[key]
        ])
      );
    },
    /**
     * Genera un objeto de valor de especificación vacio y añade la
     * especificación a la que pertenece.
     * @return {Object}
     */
    defaultSpecificationValue() {
      let specificationValue = Object.assign({}, EMPTY_SPECIFICATION_VALUE);
      if (this.specification)
        specificationValue.fieldId = {
          value: this.specification.id,
          label: this.specification.name
        };
      return specificationValue;
    }
  },
  computed: {
    actionTraduction() {
      return this.action === "create" ? "Crear" : "Guardar";
    },
    modalTitle() {
      let title = "valor de especificación";
      return this.action === "create" ? `Crear ${title}` : `Editar ${title}`;
    },
    mapHeader() {
      return this.action === "update" ? [this.specificationValue.name] : [];
    },
    disabledOkButton() {
      return (
        this.specificationValue.name === "" ||
        this.specificationValue.name === null ||
        this.specificationValue.fieldId === null
      );
    },
    requiredMessage() {
      let required = {};
      required.name = !this.specificationValue.name;
      required.fieldId = !this.specificationValue.fieldId;
      return required;
    },
    specifications() {
      return [{ value: this.specification.id, label: this.specification.name }];
    }
  },
  watch: {
    value: function(newValue) {
      this.showModal = newValue;
    },
    specificationValueId: function(newSpecificationValueId) {
      newSpecificationValueId !== null
        ? this.getSpecificationValue(newSpecificationValueId)
        : (this.specificationValue = this.defaultSpecificationValue());
    },
    specificationValue: function(newSpecificationValue) {
      if (newSpecificationValue === null) {
        this.specificationValue = this.defaultSpecificationValue();
      } else if (
        Object.keys(newSpecificationValue).some(
          key => !ACCEPTED_KEYS.includes(key)
        )
      ) {
        this.specificationValue = this.selectKeysFromSpecificationValue(
          newSpecificationValue
        );
      } else {
        this.specificationValue = newSpecificationValue;
      }
      this.specificationValueTransformToVue();
      this.originalSpecificationValue = Object.assign(
        {},
        this.specificationValue
      );
    }
  }
};
</script>
