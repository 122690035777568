<template>
  <b-tr>
    <b-td>
      <b-row>
        <b-col md="6" style="display: none;">
          <b-form-group style="margin-top: 18px;">
            <b-form-checkbox
              size="sm"
              v-model="promotion.enabled"
              switch
              disabled
            >
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col class="text-left" style="margin-left: 15px;">
          <b-badge
            pill
            :id="'activate_status_' + promotion.id"
            :class="
              promotion.status == 'active' ? 'text-success' : 'text-danger'
            "
            :variant="promotion.status == 'active' ? 'success' : 'danger'"
            >.</b-badge
          >
          <template v-if="promotion.status != 'active'">
            <b-tooltip
              :target="'activate_status_' + promotion.id"
              v-if="
                promotion.beginsAt == null ||
                  inDate(promotion.beginsAt, promotion.expiresAt)
              "
              triggers="hover"
            >
              La promoción no está activa actualmente.
            </b-tooltip>
            <b-tooltip
              :target="'activate_status_' + promotion.id"
              v-else
              triggers="hover"
            >
              {{
                formatTooltip(
                  $options.filters.formatDate(promotion.beginsAt),
                  $options.filters.formatDate(promotion.expiresAt)
                )
              }}
            </b-tooltip>
          </template>
        </b-col>
      </b-row>
    </b-td>
    <b-td>
      <a
        target="_blank"
        :href="jumpsellerPromotionForm()"
        v-if="promotion.discountTarget == 'shipping'"
      >
        {{ promotion.name }}
      </a>
      <router-link
        :to="{
          name: 'PromotionEdit',
          params: { id: promotion.id, integration_id: ic.id }
        }"
        v-else
      >
        {{ promotion.name }}
      </router-link>
    </b-td>
    <b-td>
      {{ printCouponsInfo(promotion.coupons) }}
    </b-td>
    <b-td class="text-center">
      {{ printTimesUsed(promotion.timesUsed, promotion.maxTimesUsed) }}
    </b-td>
    <b-td>
      <b-icon-check
        font-scale="3"
        v-if="promotion.cumulative"
        variant="success"
      ></b-icon-check>
    </b-td>

    <b-td class="text-left">
      <span v-if="promotion.discountAmountFix > 0">
        {{ printAmountFix(promotion.discountAmountFix) }}
      </span>
      <span v-else-if="promotion.discountAmountPercent > 0">
        {{ `${promotion.discountAmountPercent}%` }}
      </span>
      <b-icon-truck
        v-if="promotion.discountTarget == 'shipping'"
      ></b-icon-truck>
      <b-icon-cart3
        v-else-if="promotion.discountTarget == 'order'"
      ></b-icon-cart3>
      <b-icon-archive v-else></b-icon-archive>
    </b-td>
    <b-td>
      <b-dropdown right no-caret variant="white">
        <template #button-content>
          <b-icon-three-dots-vertical></b-icon-three-dots-vertical>
        </template>

        <b-dropdown-item
          target="_blank"
          :href="jumpsellerPromotionForm()"
          v-if="promotion.discountTarget == 'shipping'"
        >
          Editar en Jumpseller
          <b-icon-box-arrow-up-right></b-icon-box-arrow-up-right>
        </b-dropdown-item>
        <b-dropdown-item
          :to="{
            name: 'PromotionEdit',
            params: { id: promotion.id, integration_id: ic.id }
          }"
          v-else
        >
          Editar
        </b-dropdown-item>
        <b-dropdown-item @click="showModal(promotion.id)">
          Eliminar
        </b-dropdown-item>
      </b-dropdown>
    </b-td>
  </b-tr>
</template>
<script>
export default {
  name: "PromotionsIndexJumpsellerRow",
  data() {
    return {};
  },
  props: {
    promotion: Object,
    ic: Object,
    store_code: String,
    today: Date
  },
  methods: {
    //Mostrar Modal de eliminación
    showModal(id) {
      this.$emit("showModal", id);
    },
    //Texto formato para mostrar fechas en que la promo es valida
    formatTooltip(begins_at, expires_at) {
      return `La promoción sólo es válida desde ${begins_at} hasta ${expires_at} 23:59`;
    },
    //Determina si la fecha actual esta dentro del período valido de la promoción
    inDate(begins_at, expires_at) {
      if (begins_at == null) return false;
      const dt_start = new Date(begins_at);
      const dt_end = new Date(expires_at);
      return dt_start <= this.today && dt_end >= this.today;
    },
    //url para redireccionar a formulario de edición de la app de jumpseller
    jumpsellerPromotionForm() {
      return `https://${this.store_code}.jumpseller.com/admin/cl/promotions/edit/${this.promotion.id}`;
    },
    printCouponsInfo(coupons) {
      if (coupons.length > 0) {
        if (coupons.length > 1) {
          return coupons.length;
        } else {
          return coupons[0].code;
        }
      } else {
        return "";
      }
    },
    // Retorna string con monto de descuento,
    // agregando puntos a las unidades de mil y el signo $ al principio
    // Ej: 1000 => $1.000
    printAmountFix(amount) {
      return `$${amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
    },
    //Retorna un string con la información referente al uso
    //de la promoción con respecto al limite de usos (si es que lo hay)
    printTimesUsed(times_used, max_times_used) {
      if (max_times_used == 0) return String(times_used);
      return `${times_used} de ${max_times_used}`;
    }
  }
};
</script>

<style scoped>
td {
  height: 100px;
}
</style>
