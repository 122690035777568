<template>
  <b-container>
    <b-row>
      <b-col>
        <b-alert v-if="errorMessage" show="" variant="danger" dismissible>
          {{ errorMessage }}
        </b-alert>
        <b-alert v-if="successfulMessage" show="" variant="success" dismissible>
          {{ successfulMessage }}
        </b-alert>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-button variant="outline-info" @click="createSpecificationModal">
          <b-icon-plus></b-icon-plus> Crear especificacion
        </b-button>

        <vtex-specification-modal
          v-model="showSpecificationModal"
          :vtexIntegrationConfigId="vtexIntegrationConfigId"
          :action="specificationModalAction"
          :specificationId="specificationId"
          :fieldGroups="specificationsGroups"
          @errorMessage="setErrorMessage"
          @successfulMessage="setSuccessfulMessage"
        >
        </vtex-specification-modal>
      </b-col>
    </b-row>

    <br />

    <vtex-specifications-table
      :vtexIntegrationConfigId="vtexIntegrationConfigId"
      :categoryId="categoryId"
      @openSpecificationModal="updateSpecificationModal"
      @errorMessage="setErrorMessage"
      @emitSpecification="emitSpecification"
    >
    </vtex-specifications-table>
  </b-container>
</template>

<script>
import VtexSpecificationsTable from "./VtexSpecificationsTable.vue";
import VtexSpecificationModal from "./VtexSpecificationModal.vue";

export default {
  name: "VtexSpecificationsMaintainer",
  components: {
    VtexSpecificationsTable,
    VtexSpecificationModal
  },
  props: {
    vtexIntegrationConfigId: {
      type: String,
      required: true
    },
    categoryId: {
      required: true,
      validator: prop => {
        return prop === null || typeof prop === "string";
      }
    },
    specificationsGroups: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      showSpecificationModal: false,
      specificationModalAction: null,
      specificationId: null,
      errorMessage: null,
      successfulMessage: null
    };
  },
  methods: {
    /**
     * Abre el modal de edición de especificacion para crear uno nuevo.
     */
    createSpecificationModal() {
      this.specificationModalAction = "create";
      this.showSpecificationModal = true;
      this.specificationId = null;
    },
    /**
     * Abre el modal de edición de especificacion para editar uno.
     * @param {String} specificationId
     */
    updateSpecificationModal(specificationId) {
      this.specificationModalAction = "update";
      this.showSpecificationModal = true;
      this.specificationId = specificationId;
    },
    /**
     * Guarda el mensaje de error en la data y vuelve al inicio de la página
     * @param {String} errorMessage
     */
    setErrorMessage(errorMessage) {
      this.errorMessage = errorMessage;
      this.scrollToTop();
    },
    /**
     * Guarda el mensaje de éxito en la data y vuelve al inicio de la página
     * @param {String} successfulMessage
     */
    setSuccessfulMessage(message) {
      this.successfulMessage = message;
      this.scrollToTop();
    },
    /**
     * Emite el evento 'scrollToTop' para volver al inicio de la página
     */
    scrollToTop() {
      this.$emit("scrollToTop");
    },
    /**
     * Emite el evento 'setSpecification' junto a la especificación informada.
     * @param {Object} specification
     */
    emitSpecification(specification) {
      this.$emit("setSpecification", specification);
    }
  },
  watch: {
    errorMessage: function(newErrorMessage) {
      if (newErrorMessage !== null) this.successfulMessage = null;
    },
    successfulMessage: function(newMessage) {
      if (newMessage !== null) this.errorMessage = null;
    }
  }
};
</script>
