<template>
  <div>
    <base-header
      title="Sugerencias de catálogo de Mercado Libre"
      title-link="/mercadolibre/catalog_suggestions/integrations"
      :loading="!integrationConfigLabel"
      :map="[
        !integrationConfigLabel ? 'loading' : integrationConfigLabel,
        'Sugerencias de catálogo'
      ]"
      :title-size="12"
    >
      <template v-slot:actions>
        <mercado-libre-catalog-suggestions-create
          :integration-config-id="integrationConfigId"
        />
      </template>
    </base-header>
    <mercado-libre-catalog-suggestions-table
      :integration-config-id="integrationConfigId"
    />
  </div>
</template>

<script>
import BaseHeader from "../../../components/BaseHeader.vue";
import MercadoLibreCatalogSuggestionsCreate from "../../../components/MercadoLibre/CatalogSuggestions/Create.vue";
import INTEGRATION_CONFIG_LABEL from "../../../graphql/Base/IntegrationConfigLabel.gql";
import MercadoLibreCatalogSuggestionsTable from "../../../components/MercadoLibre/CatalogSuggestions/Table.vue";

export default {
  name: "MercadoLibreCatalogSuggestionsList",
  components: {
    BaseHeader,
    MercadoLibreCatalogSuggestionsCreate,
    MercadoLibreCatalogSuggestionsTable
  },
  props: {
    integrationConfigId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      integrationConfigLabel: null
    };
  },
  mounted() {
    this.getIntegrationConfigLabel();
  },
  methods: {
    /**
     * Obtiene la etiqueta del integration_config
     * y lo guarda en variable interna
     */
    getIntegrationConfigLabel() {
      this.$apollo
        .query({
          query: INTEGRATION_CONFIG_LABEL,
          variables: {
            id: this.integrationConfigId
          }
        })
        .then(({ data }) => {
          const label = this.$dig(data, "integrationConfig", "label");
          if (label) {
            this.integrationConfigLabel = label;
          } else {
            this.integrationConfigLabel = "MercadoLibre";
          }
        })
        .catch(() => (this.integrationConfigLabel = "MercadoLibre"));
    }
  }
};
</script>
