<template>
  <div>
    <ApolloQuery
      :query="require('../../graphql/AllTranslationCentryIntegrations.gql')"
      :variables="{
        integrationConfigId: integrationConfigId
      }"
    >
      <template slot-scope="{ result: { loading, error, data } }">
        <b-table-simple
          v-if="data && data.allTranslationCentryIntegrations"
          hover=""
        >
          <b-thead>
            <b-tr>
              <b-th colspan="1">Integration Config</b-th>
              <b-th>Tipo</b-th>
              <b-th># Campos</b-th>

              <b-th>Acciones</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-if="!data.allTranslationCentryIntegrations.edges.length">
              <b-td colspan="3">Aún no has creado ninguno</b-td>
            </b-tr>
            <translation-centry-integrations-table-row
              v-for="product of data.allTranslationCentryIntegrations.edges"
              :key="product.node.id"
              :product="product.node"
            ></translation-centry-integrations-table-row>
          </b-tbody>
        </b-table-simple>
        <b-spinner v-if="loading" label="Spinning"></b-spinner>
        <b-alert v-if="error" show="" variant="danger">
          Ha ocurrido un error
        </b-alert>
      </template>
    </ApolloQuery>
  </div>
</template>

<script>
import TranslationCentryIntegrationsTableRow from "./TranslationCentryIntegrationsTableRow";

export default {
  name: "TranslationCentryIntegrationsTable",
  components: {
    TranslationCentryIntegrationsTableRow
  },
  data() {
    return {
      integrationConfigId: this.$route.params.integrationConfigId
    };
  }
};
</script>

<style scoped></style>
