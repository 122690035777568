<template>
  <b-card no-body>
    <b-card-header header-tag="header" class="p-1 bg-white header" role="tab">
      <b-button
        block
        v-b-toggle="id"
        :variant="buttonVariant"
        :class="buttonClass"
        :disabled="disabled"
      >
        <slot name="title" :title="title">
          {{ title }}
        </slot>
        <b-icon-chevron-up v-if="show" class="chevron-accordion" />
        <b-icon-chevron-down v-else class="chevron-accordion" />
      </b-button>
    </b-card-header>
    <b-collapse
      :id="id"
      :visible="visible"
      v-model="show"
      :accordion="accordion"
      role="tabpanel"
      @show="() => $emit('show')"
    >
      <b-card-body>
        <slot></slot>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>
<script>
export default {
  name: "BaseCollapseCard",
  props: {
    id: String,
    title: String,
    visible: {
      type: Boolean,
      default: false
    },
    accordion: String,
    disabled: {
      type: Boolean,
      default: false
    },
    buttonVariant: {
      type: String,
      default: "light"
    }
  },
  data() {
    return {
      show: this.visible
    };
  },
  computed: {
    buttonClass() {
      if (this.show) return "accordion-button";
      return "accordion-button accordion-button-unselected";
    }
  },
  watch: {
    visible() {
      this.show = this.visible;
    }
  }
};
</script>
<style scoped>
.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  padding: 12px !important;
  text-align: left;
  border: 0;
}
.accordion-button-unselected {
  background-color: #fff;
  color: black;
}
.card-header {
  padding: 0px !important;
}
.chevron-accordion {
  float: right;
  position: absolute;
  right: 10px;
  top: 10px;
}
</style>
