<template>
  <b-modal :id="`qr-modal-${variantId}`" @show="getQrCode">
    <template v-slot:modal-title>
      <h4>Código QR para variante</h4>
    </template>
    <div v-if="loadingQr" style="display: flex; justify-content: center">
      <b-skeleton-img no-aspect width="285px" height="285px"></b-skeleton-img>
    </div>
    <div v-else style="display: flex; justify-content: center">
      <table v-if="qr && qr.length" class="qr">
        <tr v-for="i in qr.length" :key="i">
          <td
            v-for="(val, j) in qr[i - 1]"
            :key="i * 100 + j"
            :class="val ? 'black' : 'white'"
          />
        </tr>
      </table>
    </div>
    <template #modal-footer>
      <b-row class="w-100" align-h="between">
        <b-col>
          <b-button variant="outline-info" @click="downloadQR('png')">
            <span v-if="downloadingQr['png']"> <b-spinner /></span>
            <span v-else>
              Descargar como PNG
            </span>
          </b-button>
        </b-col>
        <b-col>
          <b-button variant="outline-info" @click="downloadQR('pdf')">
            <span v-if="downloadingQr['pdf']"> <b-spinner /></span>
            <span v-else>
              Descargar como PDF
            </span>
          </b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>
<script>
import SHOW_QR_CODE from "../../../graphql/Product/Inventory/ShowQrCode.gql";
import DOWNLOAD_QRCODES from "../../../graphql/Product/Inventory/DownloadQrcodes.gql";
export default {
  name: "QrModal",
  props: {
    variantId: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      qr: null,
      downloadingQr: { png: false, pdf: false },
      productId: this.$route.params.id,
      loadingQr: false
    };
  },
  methods: {
    /**
     * Obtiene el codigo qr para una variante
     */
    getQrCode() {
      this.loadingQr = true;
      this.$apollo
        .query({
          query: SHOW_QR_CODE,
          variables: {
            variantId: this.variantId
          }
        })
        .then(({ data }) => {
          this.qr = this.$dup(data.showQrCode);
          this.loadingQr = false;
        });
    },
    /**
     * Descarga el codigo qr de una variante ya sea en png o pdf
     * @param {String} typeDownload - tipo de descarga
     */
    downloadQR(typeDownload) {
      this.downloadingQr[typeDownload] = true;
      this.$apollo
        .query({
          query: DOWNLOAD_QRCODES,
          variables: {
            productId: this.productId,
            variantId: this.variantId,
            typeDownload: typeDownload
          }
        })
        .then(({ data }) => {
          if (typeDownload == "png") {
            var a = document.createElement("a"); //Create <a>
            a.href = data.downloadQrcodes.contentBase64; //Image Base64 Goes here
            a.download = data.downloadQrcodes.filename; //File name Here
            a.click();
          } else {
            let newQr = data.downloadQrcodes;
            newQr.content_base_64 = data.downloadQrcodes.contentBase64;
            newQr.content_type = data.downloadQrcodes.contentType;
            this.$downloadBASE64([data.downloadQrcodes]);
          }
          this.downloadingQr[typeDownload] = false;
        });
    }
  }
};
</script>
<style scoped>
.qr {
  border-width: 0;
  border-style: none;
  border-color: #0000ff;
  border-collapse: collapse;
  /*width: 50%;*/
}
.qr td {
  border-width: 0;
  border-style: none;
  border-color: #0000ff;
  border-collapse: collapse;
  padding: 0;
  margin: 0;
  width: 5px;
  height: 5px;
}
.qr td.black {
  background-color: #000;
}
.qr td.white {
  background-color: #fff;
}
</style>
