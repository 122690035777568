<template>
  <b-card
    no-body
    class="shadow my-4"
    style="background-color: #efefef; border-radius: 10px; height: 110px"
    body-class="p-0"
  >
    <b-row no-gutters class="h-100">
      <b-col xl="2" md="3" cols="3">
        <div
          class="company-image"
          :style="`background-image: url(${assetUrl})`"
        >
          <label class="sync">
            <input
              type="checkbox"
              class="integration-chk"
              :disabled="!integration.available"
              v-model="value"
              @change="emitChange"
            />
            <span class="checkmark"></span>
          </label>
        </div>
      </b-col>
      <b-col xl="10" md="9" cols="9" class="h-100">
        <b-card no-body style="height: 100% !important; border: 0">
          <b-card-body class="p-0 pt-2" style="height: 100% !important">
            <b-card-title v-if="isLoading" class="pl-2">
              <b-skeleton width="40%" />
            </b-card-title>
            <b-card-title v-else-if="label" class="pl-2">
              {{ label }}
            </b-card-title>

            <b-card-title v-else class="pl-2">
              {{ integration.label }}
              <div v-if="pii.status == 'beta'" class="status">Beta</div>
            </b-card-title>
            <b-card-sub-title v-if="isLoading" class="pl-2">
              <b-skeleton width="50%" />
            </b-card-sub-title>
            <b-card-sub-title v-else-if="subtitle" class="pl-2">
              <div>
                {{ subtitle }}
              </div>
            </b-card-sub-title>
            <b-card-sub-title v-else class="pl-2">
              <div v-if="!integration.available">
                Sin una cuenta conectada.
              </div>
              <div v-else-if="loading">
                <b-spinner small />
              </div>
              <div v-if="pii.applicationDeveloper && !other">
                Desarrollador:
                <b-link :href="pii.applicationDeveloper.url">
                  {{ pii.applicationDeveloper.name }}
                </b-link>
              </div>
              <div v-if="Object.keys(pii).length === 0 && other">
                <b-link
                  :href="
                    `${centryUrl}/oauth/applications/${integration.applicationId}`
                  "
                  target="_blank"
                >
                  Aplicación privada
                </b-link>
              </div>
              <div v-if="errors || warnings">
                <div class="error text-danger w-100" v-if="errors">
                  {{ errors }}
                </div>
                <div class="error text-warning w-100" v-if="warnings">
                  {{ warnings }}
                </div>
              </div>
            </b-card-sub-title>
          </b-card-body>
          <b-card-footer v-if="buttonLabel" class="button">
            <b-button
              block
              variant="primary"
              :to="link"
              class="btn font-weight-bold"
            >
              {{ buttonLabel }}
            </b-button>
          </b-card-footer>
          <b-card-footer v-else class="button">
            <b-skeleton
              type="button"
              width="100%"
              class="btn"
              v-if="loadingAuth || isLoading"
            />
            <b-button
              v-else-if="authUrl"
              block
              variant="warning"
              @click="reBind"
              class="btn font-weight-bold text-white"
            >
              Volver a vincular
            </b-button>
            <b-button
              v-else-if="integration.available"
              block
              variant="primary"
              :to="`/integration/${integration.id}`"
              class="btn font-weight-bold"
            >
              Editar
            </b-button>
            <b-button
              v-else
              block
              variant="danger"
              :to="`/integration/${integration.id}`"
              class="btn font-weight-bold"
            >
              Vincular
            </b-button>
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import CONNECTION_STATUS from "../../../graphql/Company/ConnectionStatus.gql";
import AUTH_URL from "../../../graphql/Company/AuthUrlIntegration.gql";
import { centryUrl } from "../../../main";
import { mapState } from "vuex";
export default {
  name: "IntegrationCard",
  props: {
    integration: {
      type: Object,
      default() {
        return {};
      }
    },
    other: {
      type: Boolean,
      default: false
    },
    _value: {
      type: Boolean,
      default: false
    },
    subtitle: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    buttonLabel: {
      type: String,
      default: ""
    },
    link: {
      type: String,
      default: ""
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  model: {
    prop: "_value",
    event: "change"
  },
  data() {
    return {
      centryUrl,
      value: this._value,
      loading: false,
      loadingAuth: false,
      authUrl: null,
      errors: null,
      warnings: null
    };
  },
  created() {
    if (!this.isLoading) {
      if (this.integration.id && this.integration.available) {
        this.getConnectionInfo();
      }
      if (
        [
          "mercado_libre",
          "mercado_libre_mx",
          "mercado_libre_pe",
          "mercado_libre_co",
          "mercado_libre_ar",
          "mercado_libre_cbt",
          "jumpseller"
        ].includes(this.pii.identifier)
      ) {
        this.getAuthUrl();
      }
    }
  },
  computed: {
    ...mapState(["currentUser"]),
    pii() {
      return this.integration.publicIntegrationInformation || {};
    },
    assetUrl() {
      if (Object.keys(this.pii).length === 0 && this.other) {
        return require("../../../assets/images/icon.png");
      } else {
        return this.pii.logoUrl;
      }
    }
  },
  methods: {
    /**
     * Obtiene el auth link para las integraciones que corresponde
     */
    getAuthUrl() {
      this.loadingAuth = true;
      this.$apollo
        .query({
          query: AUTH_URL,
          variables: { integrationConfigId: this.integration.id },
          fetchPolicy: "no-cache"
        })
        .then(({ data }) => {
          this.loadingAuth = false;
          this.authUrl = data.integrationConfig?.authUrl;
        })
        .catch(() => {
          this.loadingAuth = false;
        });
    },
    /**
     * Obtiene la informacion de conexion para todas las integraciones
     */
    getConnectionInfo() {
      this.loading = true;
      this.$apollo
        .query({
          query: CONNECTION_STATUS,
          variables: { integrationConfigId: this.integration.id },
          fetchPolicy: "no-cache"
        })
        .then(({ data }) => {
          this.loading = false;
          this.errors = data.integrationConfig?.connectionStatus?.errors;
          this.warnings = data.integrationConfig?.connectionStatus?.warnings;
        })
        .catch(() => {
          this.loading = false;
          this.errors =
            "No se pudo obtener información sobre el estado de la conexión.";
        });
    },
    /**
     * Emite el evento indicando el cambio en el model
     */
    emitChange() {
      this.$emit("change", this.value);
    },
    /**
     * Re-direcciona a la url para volver a vincular una integracion
     */
    reBind() {
      if (this.currentUser.role === 0) {
        this.$swal
          .fire({
            title: "Precaución",
            text:
              "Asegúrate de no tener una sesión abierta de otra empresa en Mercado Libre",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Confirmar",
            cancelButtonText: "Cancelar"
          })
          .then(result => {
            if (result.value) {
              window.location.href = this.authUrl;
            }
          });
      } else {
        window.location.href = this.authUrl;
      }
    }
  },
  watch: {
    _value(newVal) {
      this.value = newVal;
    }
  }
};
</script>

<style scoped>
.company-image {
  border-right: 2px solid #bababa;
  display: flex;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  float: left;
  background-size: 90%;
  background-position: center;
  background-repeat: no-repeat;
  background-color: white;
  border-top-left-radius: inherit;
  border-bottom-left-radius: 8px;
}

.sync {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.sync input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #efefef;
  border-right: 2px solid #bababa;
  border-bottom: 2px solid #bababa;
  border-top-left-radius: 8px;
}

/* On mouse-over, add a grey background color */
.sync:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.sync input:checked ~ .checkmark {
  background-color: #007bff;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.sync input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.sync .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.button {
  width: 100%;
  height: 34px;
  min-height: 20%;
  padding: 0;
  bottom: 0px;
  right: 0;
}

.btn {
  border-radius: 0 0 8px 0;
  border: 0;
  padding: 0;
}

.status {
  margin-right: 10px;
  margin-top: 0;
  color: red;
  float: right;
  border: 1px solid red;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 15px;
  padding-left: 3px;
  padding-right: 3px;
  padding-bottom: 3px;
  padding: 2px;
}

.error {
  box-sizing: border-box;
  float: left;
  font-size: 1em;
  height: 20%;
  max-height: 20%;
  display: flex;
  flex-direction: column;
  margin: 5 0px;
}
</style>
