var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-dropdown',{attrs:{"variant":"white","no-caret":"","offset":"-110"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('b-icon-three-dots-vertical')]},proxy:true}])},[_c('b-dropdown-item',{attrs:{"to":{
        name: 'ProductDetail',
        params: { id: _vm.product.id }
      }}},[_vm._v(" Detalle ")]),_c('b-dropdown-item',{attrs:{"to":{
        name: 'ProductEdit',
        params: { id: _vm.product.id }
      }}},[_vm._v(" Editar ")]),_c('b-dropdown-item',{attrs:{"to":{ name: 'ProductImageManager', query: { productId: _vm.product.id } }}},[_vm._v(" Imágenes ")]),_c('b-dropdown-item',{attrs:{"to":'/product/' + _vm.product.id + '/inventory'}},[_vm._v(" Inventario ")]),_c('b-dropdown-item',{attrs:{"to":{
        name: 'ProductHistories',
        params: { id: _vm.product.id }
      }}},[_vm._v(" Sincronizaciones ")]),_c('b-dropdown-divider'),_c('ApolloMutation',{attrs:{"mutation":require('../graphql/DeleteProduct.gql'),"variables":{ id: _vm.product.id }},on:{"done":_vm.updateDeletedProduct,"error":_vm.errorMessageDeleting},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var mutate = ref.mutate;
return [_c('b-dropdown-item',{on:{"click":function($event){return _vm.deleteIfAccept(mutate)}}},[_vm._v(" Eliminar ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }