var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('histories-actions',{attrs:{"product":_vm.product,"rangeDate":_vm.rangeDate,"editorHeight":_vm.editorHeight},on:{"update:rangeDate":function($event){_vm.rangeDate=$event},"update:range-date":function($event){_vm.rangeDate=$event},"update:editorHeight":function($event){_vm.editorHeight=$event},"update:editor-height":function($event){_vm.editorHeight=$event}}}),_c('apollo-query',{attrs:{"query":require('../graphql/HistoriesByProduct.gql'),"variables":_vm.queryVariables(''),"fetchPolicy":'cache-and-network',"clientId":"apolloClientCached"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_result = ref.result;
var error = ref_result.error;
var data = ref_result.data;
var query = ref.query;
var isLoading = ref.isLoading;
return [(error)?_c('span',[_vm._v("-- error al obtener los historiales --")]):(isLoading && !data)?_c('b-spinner',{attrs:{"label":"Spinning"}}):(data.allSynchronizationHistories.edges.length === 0)?_c('span',[_vm._v(" -- no hay movimientos registrados -- ")]):(data && isLoading)?_c('span',{staticClass:"m-2 float-right"},[_vm._v(" Actualizando historial de sincronizaciones... ")]):_vm._e(),(data)?_c('histories-table',{attrs:{"histories":data.allSynchronizationHistories.edges.map(function (x) { return x.node; }),"product":_vm.product,"editorsHeightPx":_vm.editorHeight}}):_vm._e(),(isLoading)?_c('b-spinner',{staticClass:"m-2 float-right",attrs:{"label":"Spinning"}}):_c('div',[(_vm.hasNextPage(data))?_c('b-button',{staticClass:"m-2 float-right",on:{"click":function($event){return _vm.seeMore(
              query,
              data.allSynchronizationHistories.pageInfo.endCursor
            )}}},[_vm._v(" Ver más ")]):_c('b-alert',{staticClass:"text-center",attrs:{"show":"","variant":"light"}},[_vm._v(" No hay más datos para mostrar. ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }