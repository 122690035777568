<template>
  <div>
    <b-alert v-model="error" show="" variant="danger">
      Ha ocurrido un error
    </b-alert>
    <b-spinner v-if="loadingPromotions" label="Spinning"></b-spinner>
    <div v-else>
      <b-row>
        <b-col>
          <b-input-group>
            <b-input-group-prepend is-text>
              <b-icon-search></b-icon-search>
            </b-input-group-prepend>
            <b-form-tags
              v-model="keywords"
              separator=","
              remove-on-delete=""
              placeholder="Palabras claves"
            />
          </b-input-group>
        </b-col>

        <b-col cols="2">
          <vtex-promotions-table-filters-modal
            v-model="showModal"
            :actualFilters="actualFilters"
            @setModalFilters="setModalFilters"
          />
          <b-button @click="showModal = !showModal">
            <b-icon-gear-fill></b-icon-gear-fill>
            Más filtros
          </b-button>

          <div v-if="activeKeywordsOrFilters">
            <br />
            <b-button @click="cleanFiltersAndKeywords">
              Limpiar filtros
            </b-button>
          </div>
        </b-col>
      </b-row>

      <br />

      <b-table-simple responsive>
        <b-thead>
          <b-tr>
            <b-th class="col-md-2"> Nombre </b-th>
            <b-th class="col-md-2"> Tipo </b-th>
            <b-th class="col-md-2">
              Estado
              <b-icon-info-circle-fill id="info"></b-icon-info-circle-fill>
              <b-tooltip target="info" triggers="hover">
                Es activo si la promoción tiene estado activo y la fecha de
                termino todavia no es alcanzada
              </b-tooltip>
            </b-th>
            <b-th class="col-md-2"> Fecha Inicio </b-th>
            <b-th class="col-md-2"> Fecha Fin </b-th>
            <b-th class="col-md-2"> Acciones </b-th>
          </b-tr>
        </b-thead>

        <b-tbody>
          <b-tr
            v-for="promotion in promotionsPage"
            :key="promotion.idCalculatorConfiguration"
          >
            <b-td> {{ promotion.name }} </b-td>
            <b-td> {{ translatePromotionType(promotion.type) }} </b-td>
            <b-td>
              {{ isPromotionActive(promotion) ? "Activo" : "Inactivo" }}
            </b-td>
            <b-td> {{ formatISOStringDate(promotion.beginDate) }} </b-td>
            <b-td> {{ formatISOStringDate(promotion.endDate) }} </b-td>
            <b-td>
              <b-dropdown variant="white" no-caret="" offset="-110">
                <template v-slot:button-content>
                  <b-icon-three-dots-vertical></b-icon-three-dots-vertical>
                </template>
                <b-dropdown-item @click="editPromotion(promotion)">
                  Editar
                </b-dropdown-item>
              </b-dropdown>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <b-row align-h="center">
        <b-pagination
          v-model="page"
          :total-rows="totalPaginationRows"
          :per-page="pageSize"
          @change="changePage"
        />
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import INTEGRATION_CONFIG_VTEX_PROMOTIONS from "@/graphql/IntegrationConfigVtexPromotions.gql";
import VtexPromotionsTableFiltersModal from "./VtexPromotionsTableFiltersModal.vue";

const emptyFilter = {
  active: null,
  type: null
};

export default {
  name: "VtexPromotionsTable",
  components: {
    VtexPromotionsTableFiltersModal
  },
  props: {
    vtexIntegrationConfigId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loadingPromotions: true,
      promotions: [],
      filteredPromotions: [],
      promotionsPage: [],
      error: false,
      page: 1,
      pageSize: 10,
      keywords: [],
      showModal: false,
      actualFilters: { ...emptyFilter }
    };
  },
  async mounted() {
    this.getVtexPromotions();
  },
  methods: {
    /**
     * Obtiene listado de las promociones no archivada de Vtex
     */
    async getVtexPromotions() {
      return this.$apollo
        .query({
          query: INTEGRATION_CONFIG_VTEX_PROMOTIONS,
          variables: {
            id: this.vtexIntegrationConfigId
          }
        })
        .then(({ data }) => {
          let promotions = data?.integrationConfigVtex?.promotions?.items;
          if (promotions) {
            this.promotions = promotions;
            this.changePage(1);
          } else {
            this.error = true;
          }
          this.loadingPromotions = false;
        });
    },
    /**
     * Cambia la página de la tabla
     * @param {Number} page - Número de página
     */
    changePage(page) {
      this.page = page;
      let offset = (page - 1) * this.pageSize;
      if (this.activeKeywordsOrFilters) {
        this.promotionsPage = this.filteredPromotions.slice(
          offset,
          offset + this.pageSize
        );
      } else {
        this.promotionsPage = this.promotions.slice(
          offset,
          offset + this.pageSize
        );
      }
    },
    /**
     * Traduce el nombre del tipo de la promoción para mostrarlo en español.
     * @param {String} type - tipo de la promoción.
     * @returns {String} - nombre del tipo de la promoción en español.
     */
    translatePromotionType(type) {
      switch (type) {
        case "regular":
          return "Regular";
        case "combo":
          return "Comprar Juntos";
        case "forThePriceOf":
          return "Mas por Menos";
        case "progressive":
          return "Descuento Progresivo";
        case "buyAndWin":
          return "Comprar y Obtener";
        case "campaign":
          return "Campaña";
      }
    },
    /**
     * Determina si la promoción está activa o no segun atributo isActive y
     * fecha de termino.
     * @param {Object} promotion - promoción a evaluar.
     * @returns {Boolean} - true si la promoción está activa.
     */
    isPromotionActive(promotion) {
      return promotion.isActive && !this.isPastDate(promotion.endDate);
    },
    /**
     * Determina si la fecha pasada como parámetro es anterior a la fecha actual.
     * @param {String} dateString - fecha a evaluar.
     * @returns {Boolean} - true si la fecha es anterior a la actual.
     */
    isPastDate(dateString) {
      let date = Date.parse(dateString);
      let today = new Date();

      return date < today;
    },
    /**
     * Cambia una fecha en formato ISO a una fecha en formato dd/mm/aaaa HH:mm
     * @param {string} date - Fecha en formato ISO
     * @returns {string} - Fecha en formato dd/mm/aaaa HH:mm
     */
    formatISOStringDate(isoString) {
      let date = Date.parse(isoString);
      return this.$moment(date)
        .tz(this.timeZone)
        .format("DD/MM/YYYY HH:mm");
    },
    /**
     * Se encarga de retornar la vista de edición de una promoción
     */
    editPromotion(promotion) {
      this.$router.push({
        name: "VtexPromotionEdit",
        params: {
          vtexIntegrationConfigId: this.vtexIntegrationConfigId,
          promotionType: promotion.type,
          id: promotion.idCalculatorConfiguration
        }
      });
    },
    /**
     * Guarda los filtros seleccionados en el modal para ser aplicados en la tabla
     */
    setModalFilters(modalFilters) {
      Object.entries(modalFilters).forEach(([key, value]) => {
        this.$set(this.actualFilters, key, value);
      });
    },
    /**
     * Reinicia los filtros aplicados en la tabla
     */
    cleanFiltersAndKeywords() {
      this.keywords = [];
      this.$set(this, "actualFilters", { ...emptyFilter });
    },
    /**
     * Aplica los filtros seleccionados y vuelve a la primera página de la tabla
     */
    applyKeywordsAndFilters() {
      if (!this.activeKeywordsOrFilters) {
        this.filteredPromotions = [];
      } else {
        let filteredPromotions = this.applyKeywords(this.promotions);
        filteredPromotions = this.applyFilters(filteredPromotions);
        this.$set(this, "filteredPromotions", filteredPromotions);
      }
      this.changePage(1);
    },
    /**
     * Filtra las promociones entregadas segun las palabras clave ingresadas
     * en el buscador de la tabla.
     * @param {Array} promotions - Promociones a filtrar.
     * @returns {Array} - Promociones filtradas.
     */
    applyKeywords(promotions) {
      if (this.activeKeywords) {
        return promotions.filter(promotion => {
          for (let keyword of this.keywords) {
            if (promotion.name.includes(keyword)) {
              return true;
            }
          }
          return false;
        });
      }
      return promotions;
    },
    /**
     * Filtra las promociones entregadas segun los filtros seleccionados.
     * @param {Array} promotions - Promociones a filtrar.
     * @returns {Array} - Promociones filtradas.
     */
    applyFilters(promotions) {
      if (this.activeFilters) {
        for (let [key, value] of Object.entries(this.actualFilters)) {
          if (value !== null) {
            promotions = this.applyFilter(promotions, key, value);
          }
        }
      }
      return promotions;
    },
    /**
     * Filtra las promociones entregadas segun el filtro entregado.
     * @param {Array} promotions - Promociones a filtrar.
     * @param {String} key - Nombre del filtro.
     * @param {String} value - Valor del filtro.
     * @returns {Array} - Promociones filtradas.
     */
    applyFilter(promotions, key, value) {
      return promotions.filter(promotion => {
        if (key === "active" && this.isPromotionActive(promotion) === value) {
          return true;
        } else if (promotion[key] === value) {
          return true;
        }
        return false;
      });
    }
  },
  computed: {
    ...mapState(["currentUser"]),
    timeZone() {
      return this.currentUser.company.timeZoneName;
    },
    activeKeywordsOrFilters() {
      return this.activeKeywords || this.activeFilters;
    },
    activeKeywords() {
      return this.keywords.length > 0;
    },
    activeFilters() {
      return Object.values(this.actualFilters).some(value => value !== null);
    },
    /**
     * Retorna la cantidad de elementos de la tabla dependiendo de si se aplican filtros o no.
     */
    totalPaginationRows() {
      if (this.activeKeywordsOrFilters) {
        return this.filteredPromotions.length;
      }
      return this.promotions.length;
    }
  },
  watch: {
    keywords() {
      this.applyKeywordsAndFilters();
    },
    actualFilters: {
      handler() {
        this.applyKeywordsAndFilters();
      },
      deep: true
    }
  }
};
</script>
