<template>
  <b-modal id="formModal" cancel-disabled="" size="lg">
    <template v-slot:modal-title>
      <h4>{{ title }}</h4>
    </template>
    <template>
      <div>
        <span v-if="!!originalData">{{ originalData.name }}</span>
        <b-form-group v-else label="Nombre">
          <b-form-input v-model="name" />
        </b-form-group>
        <b-form-group label="Cron">
          <b-form-input v-model="cron" />
        </b-form-group>
        <b-form-group label="Clase">
          <b-form-input v-model="klass" />
        </b-form-group>
        <b-form-group label="Argumentos">
          <b-form-input v-model="args" />
        </b-form-group>
        <span v-if="!!originalData">Grupo {{ originalData.group }}</span>
        <b-form-group label="Grupo" v-else>
          <ApolloQuery
            :query="
              gql => gql`
                query {
                  allSystemSidekiqGroupIndexes
                }
              `
            "
          >
            <template slot-scope="{ result: { data }, isLoading }">
              <b-spinner v-if="isLoading || !data" label="Spinning" />
              <v-select
                v-else
                :options="
                  data.allSystemSidekiqGroupIndexes.map(x => ({
                    value: x,
                    label: x
                  }))
                "
                placeholder="Seleccionar"
                v-model="group"
                :clearable="false"
                :reduce="x => x.value"
              ></v-select>
            </template>
          </ApolloQuery>
        </b-form-group>
      </div>
    </template>
    <template v-slot:modal-footer="{ ok, cancel }">
      <b-button variant="info" @click="() => save(ok)">
        Guardar
      </b-button>
      <b-button variant="outline-info" @click="cancel">
        Cancelar
      </b-button>
    </template>
    <slot></slot>
  </b-modal>
</template>
<script>
import CREATE_SIDEKIQ_CRON from "../../graphql/SidekiqCron/CreateSidekiqCron.gql";
export default {
  name: "SidekiqCronModal",
  props: {
    title: String,
    originalData: Object
  },
  data() {
    return {
      name: this.originalData ? this.originalData.name : "",
      cron: this.originalData ? this.originalData.cron : "",
      klass: this.originalData ? this.originalData.klass : "",
      args: this.originalData ? this.originalData.args : "",
      group: this.originalData ? this.originalData.group : null
    };
  },
  methods: {
    /**
     * Guarda la tarea asíncrona del formulario
     * @param {Function} ok
     */
    save(ok) {
      if (!this.validate()) {
        this.$swal.fire({
          title: "Error",
          text: "Debe completar todos los campos",
          icon: "error"
        });
        return;
      }
      this.createCron(ok);
    },
    /**
     * Valida que todos los campos del formulario estén completos
     * @returns {Boolean}
     */
    validate() {
      console.log(this.name, this.cron, this.klass, this.args, this.group);
      if (!this.name || !this.name.length) return false;
      if (!this.cron || !this.cron.length) return false;
      if (!this.klass || !this.klass.length) return false;
      if (!this.args || !this.args.length) return false;
      if (this.group === null) return false;
      return true;
    },
    /**
     * Crea la tarea asíncrona programada
     * @param {Function} ok
     */
    createCron(ok) {
      this.$apollo
        .mutate({
          mutation: CREATE_SIDEKIQ_CRON,
          variables: {
            cron: {
              name: this.name,
              cron: this.cron,
              klass: this.klass,
              args: this.args,
              group: this.group
            }
          }
        })
        .then(({ data }) => {
          const result = data.createSidekiqCron.result;
          if (!result) {
            this.$swal.fire({
              title: "Error",
              text: data.createSidekiqCron.errors,
              icon: "error"
            });
          } else {
            this.$swal
              .fire({
                title: "Éxito",
                text: "Tarea creada correctamente",
                icon: "success"
              })
              .then(() => {
                ok();
                window.location.reload();
              });
          }
        })
        .catch(error => {
          this.$swal.fire({
            title: "Error",
            text: error,
            icon: "error"
          });
        });
    }
  },
  watch: {
    originalData(val) {
      this.name = val ? val.name : "";
      this.cron = val ? val.cron : "";
      this.klass = val ? val.klass : "";
      this.args = val ? val.args : "";
      this.group = val ? val.group : null;
    }
  }
};
</script>
