<template>
  <div>
    <b-row>
      <b-col>
        <b-button variant="outline-info" @click="createBrandModal">
          <b-icon-plus></b-icon-plus> Crear
        </b-button>
        <vtex-brands-maintainer-brand-modal
          v-model="showBrandModal"
          :action="action"
          :brandId="brandId"
          :vtexIntegrationConfigId="vtexIntegrationConfigId"
          @errorMessage="setErrorMessage"
          @successfulMessage="setSuccessfulMessage"
          @scrollToTop="scrollToTop"
        >
        </vtex-brands-maintainer-brand-modal>
      </b-col>
    </b-row>

    <br />

    <b-row>
      <b-col>
        <b-alert v-if="errorMessage" show="" variant="danger" dismissible>
          {{ errorMessage }}
        </b-alert>
        <b-alert v-if="message" show="" variant="success" dismissible>
          {{ message }}
        </b-alert>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="12">
        <vtex-brands-maintainer-table
          :vtexIntegrationConfigId="vtexIntegrationConfigId"
          @updateBrand="updateBrandModal"
          @errorMessage="setErrorMessage"
          @successfulMessage="setSuccessfulMessage"
          @scrollToTop="scrollToTop"
        >
        </vtex-brands-maintainer-table>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="1" class="ml-auto">
        <b-button id="scroll-to-top" @click="scrollToTop">
          <b-icon-arrow-bar-up></b-icon-arrow-bar-up>
        </b-button>
        <b-tooltip target="scroll-to-top" triggers="hover">
          Vuelve al inicio de la página
        </b-tooltip>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import VtexBrandsMaintainerTable from "@/components/VtexMaintainers/Brands/VtexBrandsMaintainerTable.vue";
import VtexBrandsMaintainerBrandModal from "@/components/VtexMaintainers/Brands/VtexBrandsMaintainerBrandModal.vue";

export default {
  name: "VtexBrandsMaintainerTab",
  components: {
    VtexBrandsMaintainerTable,
    VtexBrandsMaintainerBrandModal
  },
  props: {
    vtexIntegrationConfigId: String
  },
  data() {
    return {
      showBrandModal: false,
      brandId: null,
      action: null,
      errorMessage: null,
      message: null
    };
  },
  methods: {
    /**
     * Abre el modal de edición de Marcas para crear uno nuevo.
     */
    createBrandModal() {
      this.action = "create";
      this.brandId = null;
      this.showBrandModal = true;
    },
    /**
     * Abre el modal de edición de Marcas para editar uno.
     * @param {String} brandId
     */
    updateBrandModal(brandId) {
      this.action = "update";
      this.brandId = brandId;
      this.showBrandModal = true;
    },
    /**
     * Guarda el mensaje de error en la data del modelo
     * @param {String} errorMessage
     */
    setErrorMessage(errorMessage = null) {
      if (errorMessage === null) {
        errorMessage = "Ha ocurrido un error";
      }
      this.errorMessage = errorMessage;
    },
    /**
     * Guarda el mensaje de éxito en la data del modelo
     * @param {String} successfulMessage
     */
    setSuccessfulMessage(successfulMessage) {
      this.message = successfulMessage;
    },
    /**
     * Vuelve al inicio de la página
     */
    scrollToTop() {
      window.scrollTo(0, 0);
    }
  },
  watch: {
    errorMessage: function(newErrorMessage) {
      if (newErrorMessage !== null) {
        this.message = null;
      }
    },
    message: function(newMessage) {
      if (newMessage !== null) {
        this.errorMessage = null;
      }
    }
  }
};
</script>
