<template>
  <div class=" mx-5">
    <b-row align-h="between" class="pr-0 mb-2 mx-0">
      <b-col>
        <b-row>
          <b-badge class="mr-1">
            <h4 class="font-weight-bold">Variante</h4>
          </b-badge>
          <b-badge class="mr-1" variant="info" v-if="variant.color">
            <h4 class="font-weight-bold">Color: {{ variant.color.name }}</h4>
          </b-badge>
          <b-badge variant="info" v-if="variant.size">
            <h4 class="font-weight-bold">Talla: {{ variant.size.name }}</h4>
          </b-badge>
        </b-row>
      </b-col>
      <b-col style="display:flex; justify-content: flex-end">
        <b-badge variant="info">
          <h4 class="font-weight-bold">SKU: {{ variant.sku }}</h4>
        </b-badge>
      </b-col>
    </b-row>
    <base-skeleton-image-row v-if="loading" />
    <tbody align-h="center" v-else>
      <b-row class="mx-0 justify-content-start">
        <b-col
          style="width: 150px !important"
          class="px-0 mr-1 mb-2"
          v-for="(asset, position) of productAssets"
          :key="position"
        >
          <variant-image-container
            :asset="asset"
            :border-red="assetSelected(asset.id)"
            @click.native="selectAsset(asset.id)"
          />
        </b-col>
      </b-row>
    </tbody>
    <hr style="border-top: 2px solid rgba(0,0,0,.1);" />
  </div>
</template>

<script>
import ALL_VARIANT_ASSETS_BY_VARIANT_ID from "@/graphql/AllVariantAssetsByVariantId.gql";
import CREATE_VARIANT_ASSET from "@/graphql/CreateVariantAsset.gql";
import DELETE_VARIANT_ASSET from "@/graphql/DeleteVariantAsset.gql";
import VariantImageContainer from "./VariantImageContainer.vue";
import BaseSkeletonImageRow from "@/components/BaseSkeletonImageRow.vue";
export default {
  name: "ProductImageManagerVariantView",
  components: {
    VariantImageContainer,
    BaseSkeletonImageRow
  },
  props: {
    variant: {
      type: Object,
      default() {
        return {};
      }
    },
    productAssets: {
      type: Array,
      default() {
        return [];
      }
    },
    originalProductAssets: {
      type: Array,
      default() {
        return [];
      }
    },
    productId: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      originalAssetIds: [],
      assetsIds: [],
      loading: true
    };
  },
  async mounted() {
    this.getVariantAssets();
  },
  computed: {},
  methods: {
    /**
     * Indica si la imagen esta seleccioanda para la variante
     * @param {String} id - id de la imagen a comprobar
     * @returns {Boolean}
     */
    assetSelected(id) {
      return this.assetsIds.includes(id);
    },
    /**
     * Selecciona o deselecciona una imagen
     * @param {String} id - Imagens a seleccionar o deseleccionar
     */
    selectAsset(id) {
      if (this.assetSelected(id)) {
        let index = this.assetsIds.indexOf(id);
        this.assetsIds.splice(index, 1);
        this.deleteVariantAsset(id);
      } else {
        this.assetsIds.push(id);
        this.createVariantAsset(id);
      }
    },
    /**
     * Obtiene los varaintAsset de una variante
     */
    async getVariantAssets() {
      this.loading = true;
      await this.$apollo
        .query({
          query: ALL_VARIANT_ASSETS_BY_VARIANT_ID,
          variables: {
            id: this.variant.id
          }, // Sin cache para poder actualiuzar los assets cuando cambian
          fetchPolicy: "no-cache"
        })
        .then(({ data }) => {
          this.originalAssetIds = data.variant.variantAssets.map(
            asset => asset.assetId
          );
          this.assetsIds = this.$dup(this.originalAssetIds);
          this.loading = false;
        });
    },
    /**
     * Envia la mutacion para crear un variantAsset
     * @param {String} id - id del asset para crear el variantAsset
     */
    createVariantAsset(id) {
      this.$retryMutationWithTimeout(CREATE_VARIANT_ASSET, {
        variantId: this.variant.id,
        productId: this.productId,
        assetId: id
      }).then(({ data }) => {
        console.log(data);
      });
    },
    /**
     * Envia la mutacion para elminar un variantAsset
     * @param {String} id - id del asset a elminar
     */
    deleteVariantAsset(id) {
      this.$retryMutationWithTimeout(DELETE_VARIANT_ASSET, {
        variantId: this.variant.id,
        assetId: id
      }).then(({ data }) => {
        console.log(data);
      });
    }
  },
  watch: {
    originalProductAssets: {
      async handler(newVal, oldVal) {
        let getVariantAssets = false;
        let oldsIds = oldVal.map(asset => asset.id);
        newVal.forEach(val => {
          if (!oldsIds.includes(val.id)) {
            getVariantAssets = true;
          }
        });
        if (getVariantAssets) {
          await this.getVariantAssets();
        }
      },
      deep: true
    },
    async variant() {
      await this.getVariantAssets();
    }
  }
};
</script>
<style scoped></style>
