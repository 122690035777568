<template>
  <div>
    <base-header
      title="Sugerencias de catálogo de Mercado Libre"
      title-link="#"
      :map="['Mercado Libre', 'Sugerencias de catálogo', 'Integraciones']"
      :title-size="12"
    >
    </base-header>

    <mercado-libre-select-integration
      url-name="MercadoLibreCatalogSuggestionsList"
    />
  </div>
</template>

<script>
import BaseHeader from "../../../components/BaseHeader.vue";
import MercadoLibreSelectIntegration from "../../../components/MercadoLibre/SelectIntegration.vue";

export default {
  name: "MercadoLibreCatalogSuggestionsIntegrations",
  components: {
    BaseHeader,
    MercadoLibreSelectIntegration
  }
};
</script>
