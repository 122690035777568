<template>
  <div>
    <apollo-query
      :query="require('../graphql/ProductTransactionFilters.gql')"
      :variables="{ productId: this.product.id }"
    >
      <template slot-scope="{ result: { data, error, loading } }">
        <b-alert v-if="error">Ha ocurrido un error</b-alert>
        <b-spinner v-else-if="loading || !data"></b-spinner>
        <b-row v-else>
          <b-col md="6">
            <transactions-more-filters
              :operation-options="
                optionsForSelect(
                  data.cache.transactionsOperations,
                  x => operationMap[x]
                )
              "
              :variant-options="variantsOptions"
              :warehouse-options="warehousesOptions"
            ></transactions-more-filters>
          </b-col>
          <b-col md="6">
            <v-select
              :options="optionsForSelect(data.cache.transactionsSources)"
              :reduce="x => x.value"
              placeholder="Origen"
              v-model="source"
            ></v-select>
          </b-col>
        </b-row>
      </template>
    </apollo-query>
  </div>
</template>
<script>
import { mapMutations, mapState } from "vuex";
import TransactionsMoreFilters from "./TransactionsMoreFilters";

export default {
  name: "TransactionsFilters",
  components: { TransactionsMoreFilters },
  props: {
    product: Object
  },
  data() {
    return {
      source: null,
      operationMap: {
        product_endpoint: "API de Productos",
        variant_endpoint: "API de Variantes",
        variant_warehouse_endpoint: "API de Bodegas de Variantes",
        move: "Stock entre bodegas",
        sale: "Pedido",
        restock: "Reposición de Stock",
        edit: "Edición Manual",
        bulk_upload: "Carga Masiva",
        stocks: "Editor Stocks"
      }
    };
  },
  computed: {
    ...mapState(["filters", "currentUser"]),
    variantsOptions() {
      if (this.product.variants) {
        return this.product.variants.map(x => {
          return { value: x.id, label: x.sku };
        });
      }
      return [];
    },
    warehousesOptions() {
      if (
        this.currentUser &&
        this.currentUser.company &&
        this.currentUser.company.warehouses
      ) {
        return this.currentUser.company.warehouses.map(x => {
          return { value: x.id, label: x.name };
        });
      }
      return [];
    }
  },
  methods: {
    ...mapMutations(["setFilter"]),
    optionsForSelect(elem, labelTransform = x => x) {
      if (!elem) return [];

      return elem.map(x => {
        return { value: x, label: labelTransform(x) };
      });
    }
  },
  watch: {
    source(newValue) {
      if (newValue !== this.filters.source) {
        this.setFilter({ source: newValue });
      }
    },
    filters(newValue) {
      if (newValue.source !== this.source) {
        this.source = newValue.source;
      }
    }
  }
};
</script>
