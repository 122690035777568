<template>
  <error-dashboard
    v-if="!!error || !!timeoutError"
    :error="!!error"
    :timeout-error="!!timeoutError"
    @retry="loadAll"
  ></error-dashboard>
  <div v-else>
    <b-row no-gutters align-h="between" class="mt-2">
      <h4>{{ widget.name }}</h4>
      <export-xlsx-dashboard
        v-if="!loading && !!xlsxData && !!xlsxData.length"
        :data="xlsxData"
        :widget="widget"
      ></export-xlsx-dashboard>
    </b-row>
    <b-skeleton-img v-if="loading"></b-skeleton-img>
    <stacked-bar
      v-else-if="infoWidget.graphType === 'bar'"
      :data="chartData"
      :options="options"
    />
    <line-chart
      v-else-if="infoWidget.graphType === 'line'"
      :data="chartData"
      :height="200"
      :options="options"
    />
  </div>
</template>

<script>
import DASHBOARD_TIME_SERIE_WIDGET from "../../graphql/DashboardTimeSerieWidget.gql";
import StackedBar from "../../components/charts/StackedBar.vue";
import LineChart from "../../components/charts/LineChart.vue";
import { mapState } from "vuex";
import BaseWidget from "@/components/dashboard/BaseWidget";
import ErrorDashboard from "@/components/dashboard/ErrorDashboard";
import ExportXlsxDashboard from "@/components/dashboard/ExportXlsxDashboard";
export default {
  name: "DashboardTimeSerie",
  extends: BaseWidget,
  components: {
    ExportXlsxDashboard,
    StackedBar,
    LineChart,
    ErrorDashboard
  },
  data() {
    return {
      infoWidget: {},
      months: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre"
      ],
      formatting: null
    };
  },
  computed: {
    ...mapState(["filters"]),
    options() {
      let formatting = this.formatting;
      if (!formatting || !formatting.length) {
        formatting = "value => value";
      }
      return {
        scales: {
          yAxes: [
            {
              stacked: this.infoWidget && this.infoWidget.graphType === "bar",
              ticks: {
                beginAtZero: true,
                // eslint-disable-next-line no-unused-vars
                callback: value => eval(formatting)(value)
              }
            }
          ],
          xAxes: [
            {
              gridLines: {
                display: false
              }
            }
          ]
        },
        tooltips: {
          callbacks: {
            label: function(tooltipItem, data) {
              let label = data.datasets[tooltipItem.datasetIndex].label;
              // eslint-disable-next-line no-unused-vars
              let value =
                data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
              let formatValue = eval(formatting);
              let valueFormatted = formatValue(value);
              return label + ": " + valueFormatted;
            }
          }
        },
        maintainAspectRatio: true
      };
    },
    chartData() {
      const data = this.getChartData(this.data, this.getOriginalLabels());

      if (
        !this.compareData ||
        !this.compareData.length ||
        this.infoWidget.graphType === "line"
      ) {
        return data;
      }
      const compareData = this.getChartData(
        this.compareData,
        this.getCompareLabels()
      );
      return this.combineData(data, compareData);
    },
    xlsxData() {
      const data = this.getChartData(this.data, this.getOriginalLabels());
      const datasets = data.datasets;
      const labels = this.chartData.labels;
      if (!datasets || !labels) return [];
      const all = labels.map((x, index) => {
        const hash = {};
        hash[this.infoWidget.timeColumn] = labels[index];
        return hash;
      });
      datasets.forEach(dataset => {
        dataset.data.forEach((d, index) => {
          all[index][dataset.label] = d;
        });
      });
      return all;
    }
  },
  methods: {
    getWidget() {
      return this.$apollo
        .query({
          query: DASHBOARD_TIME_SERIE_WIDGET,
          variables: {
            id: this.widget.id
          }
        })
        .then(result => {
          this.infoWidget = result.data.dashboardTimeSerieWidget;
          this.formatting = this.infoWidget.formatter;
        });
    },
    dataPerChannel(data) {
      let sales = [];
      let separator = this.$toCamelCase(this.infoWidget.separatorColumn);
      let accumulator = this.$toCamelCase(this.infoWidget.dataColumn);
      let time = this.$toCamelCase(this.infoWidget.timeColumn);
      data.forEach(report => {
        let container = {};
        let date = this.$moment(report[time]);
        container.separator = report[separator];
        container.accumulator = report[accumulator];
        container.time = this.transformDate(date);
        let sale = sales.find(
          sale =>
            sale.separator === container.separator &&
            sale.time === container.time
        );
        if (sale) {
          sale.accumulator += container.accumulator;
        } else {
          sales.push(container);
        }
      });
      sales.sort(function(a, b) {
        let separatorA = a.time;
        let separatorB = b.time;
        let accumulatorA = parseFloat(a.accumulator);
        let accumulatorB = parseFloat(b.accumulator);
        return separatorA - separatorB || accumulatorB - accumulatorA;
      });

      return sales;
    },
    transformDate(date) {
      if (this.filters.granularity === "month") {
        return this.$moment(date).format("MMMM YYYY");
      } else if (this.filters.granularity === "week") {
        return this.$moment(date).format("DD MMMM YYYY");
      } else if (this.filters.granularity === "day") {
        return this.$moment(date).format("DD MMMM YYYY");
      }
    },
    getChartData(data, labels) {
      const chartDataSet = this.dataPerChannel(data);
      const datasets = [];
      const backgroundColor = [];
      chartDataSet.forEach(report => {
        let label = report.time;
        let index = labels.findIndex(foundDate => foundDate === label);
        if (index === -1) {
          labels.push(label);
        }
      });
      labels = labels.sort((a, b) => b.date - a.date);
      chartDataSet.forEach((report, i) => {
        const container = {};
        container.label = report.separator;
        backgroundColor.push(this.colorFor(container.label));
        let label = report.time;
        let accumulator = Number.parseFloat(report.accumulator);
        let reporte = datasets.find(
          dataset => dataset.label === container.label
        );
        if (reporte) {
          let index = labels.findIndex(foundDate => foundDate === label);
          reporte.data[index] = accumulator;
        } else {
          let data = []; //Aparece el array lleno
          let index = labels.findIndex(foundDate => foundDate === label);
          data[index] = accumulator;
          container.backgroundColor = backgroundColor[i];
          container.data = data;
          container.fill = false;
          container.borderColor = backgroundColor[i];
          datasets.push(container);
        }
      });
      datasets.forEach(datos => {
        let fixedData = datos.data;
        for (let i = 0; i < labels.length; i++) {
          if (fixedData.length <= i || !fixedData[i]) {
            fixedData[i] = 0;
          }
        }
      });
      const finalDataset = {};
      finalDataset.labels = labels;
      finalDataset.datasets = datasets;
      return finalDataset;
    },
    getOriginalLabels() {
      return this.getLabels(this.filters.startDate, this.filters.endDate);
    },
    getCompareLabels() {
      let days =
        (this.filters.endDate.getTime() - this.filters.startDate.getTime()) /
        (1000 * 60 * 60 * 24);
      let endDate = this.$moment(this.filters.compareDate).add("days", days);
      return this.getLabels(this.filters.compareDate, endDate);
    },
    combineData(data, compareData) {
      data.datasets = this.combineDatasets(data.datasets, compareData.datasets);
      data.labels = this.combineLabels(data.labels, compareData.labels);
      return data;
    },
    combineLabels(originalLabels, compareLabels) {
      const finalLabels = [];
      for (let i = 0; i < compareLabels.length; i++) {
        finalLabels.push(compareLabels[i] + " (c)");
        if (originalLabels.length > i) {
          finalLabels.push(originalLabels[i]);
        }
      }
      return finalLabels;
    },
    combineDatasets(originalDatasets, compareDatasets) {
      const finalDatasets = {};
      originalDatasets.forEach(dataset => {
        finalDatasets[dataset.label] = this.$dup(dataset);
        const data = [];
        dataset.data.forEach(x => {
          data.push(0);
          data.push(x);
        });
        finalDatasets[dataset.label].data = data;
      });
      compareDatasets.forEach(dataset => {
        const finalDataset = this.$dup(dataset);
        let reference = finalDatasets[dataset.label];
        if (reference) {
          finalDataset.backgroundColor = this.increaseBrightness(
            reference.backgroundColor,
            50
          );
        }
        finalDataset.label = dataset.label + " (c)";
        finalDataset.data = [];
        finalDatasets[finalDataset.label] = finalDataset;
        for (let x = 0; x < dataset.data.length; x++) {
          const index = x * 2;
          if (finalDataset.data.length <= index)
            this.addZerosTo(finalDataset.data, index);
          finalDataset.data[index] = dataset.data[x];
        }
      });
      return Object.values(finalDatasets);
    },
    addZerosTo(array, index) {
      while (array.length <= index) {
        array.push(0);
      }
    },
    getLabels(startDate, endDate) {
      let current = this.$moment(startDate);
      const end = this.$moment(endDate);
      let variation = { days: 1 };
      if (this.filters.granularity === "week") {
        variation = { days: 7 };
        current = current.startOf("isoWeek");
      } else if (this.filters.granularity === "month") {
        variation = { months: 1 };
        current = current.startOf("month");
      }
      const labels = [];
      while (current < end) {
        labels.push(this.transformDate(current));
        current.add(variation);
      }
      return labels;
    },
    increaseBrightness(hex, percent) {
      // strip the leading # if it's there
      hex = hex.replace(/^\s*#|\s*$/g, "");

      // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
      if (hex.length === 3) {
        hex = hex.replace(/(.)/g, "$1$1");
      }

      let r = parseInt(hex.substr(0, 2), 16),
        g = parseInt(hex.substr(2, 2), 16),
        b = parseInt(hex.substr(4, 2), 16);

      return (
        "#" +
        (0 | ((1 << 8) + r + ((256 - r) * percent) / 100))
          .toString(16)
          .substr(1) +
        (0 | ((1 << 8) + g + ((256 - g) * percent) / 100))
          .toString(16)
          .substr(1) +
        (0 | ((1 << 8) + b + ((256 - b) * percent) / 100))
          .toString(16)
          .substr(1)
      );
    }
  }
};
</script>
