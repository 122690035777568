<template>
  <div>
    <h4 class="font-weight-bold m-3">Datos generales</h4>

    <b-row>
      <b-col>
        <b-form-group label="Nombre*" label-for="name">
          <b-form-input id="name" v-model="promotion.name" placeholder="Nombre">
          </b-form-input>
          <span v-if="missingRequiredFields.name" class="text-danger">
            El nombre es requerido
          </span>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Estado" label-for="status">
          <base-boolean-selector
            v-model="promotion.isActive"
            trueText="Activo"
            falseText="Inactivo"
          >
          </base-boolean-selector>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group label="Descripción" label-for="description">
          <b-form-textarea
            id="description"
            v-model="promotion.description"
            placeholder="Descripción"
          >
          </b-form-textarea>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import BaseBooleanSelector from "@/components/BaseBooleanSelector";

export default {
  name: "VtexBasePromotionGeneralData",
  components: {
    BaseBooleanSelector
  },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      promotion: this.value
    };
  },
  methods: {},
  computed: {
    /**
     * Para cada atributo obligatorio de OverviewGeneralData se informa
     * si falta el dato.
     * @returns {Object}
     */
    missingRequiredFields() {
      let missing = {};
      missing.name = !this.promotion.name;
      return missing;
    }
  },
  watch: {
    value: {
      handler(newValue) {
        this.promotion = newValue;
      },
      deep: true
    },
    promotion: {
      handler(newValue) {
        this.$emit("input", newValue);
      },
      deep: true
    }
  }
};
</script>
