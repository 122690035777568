<template>
  <b-modal v-model="show" size="xl" scrollable ok-only>
    <template #modal-title>
      <h2>Imágenes con error</h2>
    </template>
    <div v-if="Object.keys(productsWithErrors) != 0">
      <h4>
        Las siguientes imágenes no se pudieron guardar debido a que ya existían
        en el producto
      </h4>
      <b-card
        class="my-1"
        v-for="(images, sku) in productsWithErrors"
        :key="sku"
      >
        <b-card-title>
          <b-badge variant="info">
            <h4 class="font-weight-bold">SKU: {{ sku }}</h4>
          </b-badge>
        </b-card-title>
        <b-card-body>
          <b-row style="width: 100%; overflow-x: auto; flex-wrap: nowrap">
            <div
              style="width: 150px !important"
              class="px-0 mx-2"
              v-for="(image, index) in images"
              :key="index"
            >
              <variant-image-container
                :asset="image"
                show-image-name
                image-name="original_filename"
              />
            </div>
          </b-row>
        </b-card-body>
      </b-card>
    </div>

    <div v-if="Object.keys(imagesWithErrors) != 0">
      <h4>
        Las siguientes imágenes no se pudieron guardar debido a algún error
      </h4>
      <b-card
        class="my-1"
        v-for="(image, filename) in imagesWithErrors"
        :key="filename"
      >
        <b-card-body>
          <b-row style="width: 100%; overflow-x: auto; flex-wrap: nowrap">
            <b-col style="width: 150px !important" class="px-0 mx-2">
              <variant-image-container
                :asset="image"
                show-image-name
                image-name="original_filename"
              />
            </b-col>
            <div class="mx-3">
              Errores:
              <ul>
                <li v-for="(message, index) in image.messages" :key="index">
                  {{ message }}
                </li>
              </ul>
            </div>
          </b-row>
        </b-card-body>
      </b-card>
    </div>
  </b-modal>
</template>

<script>
import VariantImageContainer from "./VariantImageContainer.vue";
export default {
  name: "ImagesGalleryErrorsModal",
  components: {
    VariantImageContainer
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    productsWithErrors: {
      type: Object,
      default() {
        return {};
      }
    },
    imagesWithErrors: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      show: this.value
    };
  },
  watch: {
    value(newShow) {
      this.show = newShow;
    },
    show(newShow) {
      this.$emit("input", newShow);
    }
  }
};
</script>
