<template>
  <ApolloQuery
    :query="require('../graphql/NotSynchronizedErrorSummary.gql')"
    :variables="{
      createdAtLte: filters.createdAtLte,
      createdAtGte: filters.createdAtGte,
      origins: filters.origins,
      publicApplicationInformationIds: filters.publicApplicationInformationIds,
      companyIds: filters.companyIds
    }"
  >
    <template slot-scope="{ result: { data } }">
      <b-row>
        <b-col md="4">
          <h4>Distribución por orígen</h4>
          <base-pie-chart
            v-if="!!data"
            :chart-data="{
              labels: Object.keys(
                data.statistics.notSynchronizedErrorsReport.by_origin
              ),
              datasets: [
                {
                  backgroundColor: Object.keys(
                    data.statistics.notSynchronizedErrorsReport.by_origin
                  ).map(() => randomColor()),
                  data: Object.values(
                    data.statistics.notSynchronizedErrorsReport.by_origin
                  )
                }
              ]
            }"
          ></base-pie-chart
        ></b-col>
        <b-col md="4">
          <h4>Distribución por integración</h4>
          <base-pie-chart
            v-if="!!data"
            :chart-data="{
              labels: Object.keys(
                data.statistics.notSynchronizedErrorsReport.by_integration
              ),
              datasets: [
                {
                  backgroundColor: Object.keys(
                    data.statistics.notSynchronizedErrorsReport.by_integration
                  ).map(() => randomColor()),
                  data: Object.values(
                    data.statistics.notSynchronizedErrorsReport.by_integration
                  )
                }
              ]
            }"
          ></base-pie-chart
        ></b-col>
        <b-col md="4">
          <h4>Distribución por empresa</h4>
          <base-pie-chart
            v-if="!!data"
            :chart-data="{
              labels: Object.keys(
                data.statistics.notSynchronizedErrorsReport.by_company
              ),
              datasets: [
                {
                  backgroundColor: Object.keys(
                    data.statistics.notSynchronizedErrorsReport.by_company
                  ).map(() => randomColor()),
                  data: Object.values(
                    data.statistics.notSynchronizedErrorsReport.by_company
                  )
                }
              ]
            }"
          ></base-pie-chart
        ></b-col>
      </b-row>
    </template>
  </ApolloQuery>
</template>
<script>
import BasePieChart from "@/components/BasePieChart";
import { mapState } from "vuex";
export default {
  name: "SynchronizationMonitoringSummary",
  components: { BasePieChart },
  computed: {
    ...mapState(["filters"])
  },
  methods: {
    randomColor() {
      return "#" + ((Math.random() * 0xffffff) << 0).toString(16);
    }
  }
};
</script>
