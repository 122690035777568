<template>
  <div>
    <base-alert-with-count-down
      v-model="showAlert"
      :alert-variant="alertVariant"
      :dismiss-secs="error ? 10 : 5"
    >
      {{ alertMessage }}
    </base-alert-with-count-down>
    <b-row>
      <b-col md="3">
        <h2>General</h2>
        <input-with-skeleton
          :loading="isLoading"
          label="Nombre"
          :invalid-feedback="validation.name.invalidFeedback"
          :state="validation.name.valid"
          v-model="workingMessage.name"
        />
        <input-with-skeleton
          :loading="isLoading"
          label="Fecha de inicio"
          :invalid-feedback="validation.startDate.invalidFeedback"
          :state="validation.startDate.valid"
          type="date"
          v-model="workingMessage.startDate"
        />
        <input-with-skeleton
          :loading="isLoading"
          label="Fecha de término"
          :invalid-feedback="validation.endDate.invalidFeedback"
          :state="validation.endDate.valid"
          type="date"
          v-model="workingMessage.endDate"
        />

        <b-form-checkbox v-model="workingMessage.monday">
          Lunes
        </b-form-checkbox>
        <b-form-checkbox v-model="workingMessage.tuesday">
          Martes
        </b-form-checkbox>
        <b-form-checkbox v-model="workingMessage.wednesday">
          Miércoles
        </b-form-checkbox>
        <b-form-checkbox v-model="workingMessage.thursday">
          Jueves
        </b-form-checkbox>
        <b-form-checkbox v-model="workingMessage.friday">
          Viernes
        </b-form-checkbox>
        <b-form-checkbox v-model="workingMessage.saturday">
          Sábado
        </b-form-checkbox>
        <b-form-checkbox v-model="workingMessage.sunday">
          Domingo
        </b-form-checkbox>

        <b-form-group label="Destinatarios:" v-slot="{ ariaDescribedby }">
          <b-form-checkbox-group
            v-model="workingMessage.userTypes"
            :aria-describedby="ariaDescribedby"
          >
            <b-form-checkbox value="COMPANY_OWNER">
              Representantes
            </b-form-checkbox>
            <b-form-checkbox value="EMPLOYEE">Empleados</b-form-checkbox>
            <b-form-checkbox value="ADMIN">
              Administradores
            </b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
      </b-col>

      <b-col md="3">
        <h2>Empresas</h2>
        <b-form-group label="Buscador:">
          <b-input-group>
            <base-live-select
              id="company"
              v-model="companyToAdd"
              placeholder="Seleccionar"
              :clearable="false"
              @search="searchCompanies"
              :min-search-characters="2"
              style="flex: 1 1 auto"
              :debounce="750"
            />

            <template #append>
              <b-button variant="outline-info" size="sm" @click="addCompany">
                <b-icon-plus-circle />
              </b-button>
            </template>
          </b-input-group>
        </b-form-group>
        <b-form-group label="Empresas seleccionadas:">
          <b-list-group>
            <b-list-group-item
              v-for="companyId in workingMessage.companyIds"
              :key="companyId"
              class="d-flex justify-content-between align-items-center"
            >
              {{ companyName(companyId) }}

              <b-button
                variant="danger"
                @click="removeCompanyId(companyId)"
                size="sm"
                class="float-right"
              >
                <b-icon-trash />
              </b-button>
            </b-list-group-item>
          </b-list-group>
        </b-form-group>
      </b-col>

      <b-col md="6">
        <h2>Mensaje</h2>
        <input-with-skeleton
          :loading="isLoading"
          label="Asunto"
          :invalid-feedback="validation.subject.invalidFeedback"
          :state="validation.subject.valid"
          v-model="workingMessage.subject"
        />
        <input-with-skeleton
          :loading="isLoading"
          label="Responder a"
          :invalid-feedback="validation.replyTo.invalidFeedback"
          :state="validation.replyTo.valid"
          v-model="workingMessage.replyTo"
        />
        <b-tabs content-class="mt-3">
          <b-tab title="Editor" active>
            <ace-editor
              mode="html_ruby"
              theme="monokai"
              width="100%"
              height="350px"
              name="template"
              :value="workingMessage.template"
              :enableBasicAutocompletion="true"
              :onChange="updateTemplate"
            />
          </b-tab>
          <b-tab title="Vista previa">
            <div v-html="workingMessage.template" class="mail-preview" />
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-button
          variant="info"
          :disabled="!changed || saving"
          :title="changed ? '' : 'No hay cambios'"
          @click="save"
          class="float-right"
        >
          <span v-if="saving">
            Guardando
            <b-spinner label="Spinning" />
          </span>
          <span v-else>Guardar</span>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { Ace as AceEditor } from "vue2-brace-editor";
import "brace/mode/html_ruby";
import "brace/theme/monokai";
import "brace/ext/searchbox";

import BaseAlertWithCountDown from "@/components/Base/BaseAlertWithCountDown.vue";
import BaseLiveSelect from "@/components/BaseLiveSelect.vue";
import InputWithSkeleton from "@/components/Base/InputWithSkeleton.vue";

import ADMIN_COMPANIES from "@/graphql/AdminCompanies.gql";
import COMPANY from "@/graphql/Company/CompanyName.gql";
import UPDATE from "@/graphql/Messages/UpdateScheduled.gql";
import CREATE from "@/graphql/Messages/CreateScheduled.gql";

export default {
  name: "MessageScheduledEdit",
  props: {
    messageScheduled: {
      type: Object,
      default() {
        return {};
      }
    },
    isLoading: Boolean
  },
  components: {
    AceEditor,
    BaseLiveSelect,
    BaseAlertWithCountDown,
    InputWithSkeleton
  },
  data() {
    return {
      workingMessage: {},
      companyToAdd: null,
      companyNames: {},
      saving: false,
      error: false,
      showAlert: false,
      errorMessage: null
    };
  },
  mounted() {
    if (this.messageScheduled) {
      this.workingMessage = { ...this.messageScheduled };
    } else {
      this.workingMessage = {
        companyIds: [],
        userTypes: [],
        template: "",
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
        replyTo: "contacto@centry.cl"
      };
    }
  },
  computed: {
    validation() {
      return {
        name: {
          valid: this.workingMessage.name?.length > 0,
          invalidFeedback: "El nombre es obligatorio"
        },
        startDate: {
          valid: this.workingMessage.startDate?.length > 0,
          invalidFeedback: "La fecha de inicio es obligatoria"
        },
        endDate: {
          valid: this.workingMessage.endDate?.length > 0,
          invalidFeedback: "La fecha de término es obligatoria"
        },
        subject: {
          valid: this.workingMessage.subject?.length > 0,
          invalidFeedback: "El asunto es obligatorio"
        },
        replyTo: {
          valid: /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.workingMessage.replyTo),
          invalidFeedback: "El correo de respuesta es obligatorio"
        },
        template: {
          valid: this.workingMessage.template?.length > 0,
          invalidFeedback: "El mensaje es obligatorio"
        },
        daysOfWeek: {
          valid:
            this.workingMessage.monday ||
            this.workingMessage.tuesday ||
            this.workingMessage.wednesday ||
            this.workingMessage.thursday ||
            this.workingMessage.friday ||
            this.workingMessage.saturday ||
            this.workingMessage.sunday,
          invalidFeedback: "Debe seleccionar al menos un día de la semana"
        },
        userTypes: {
          valid: this.workingMessage.userTypes?.length > 0,
          invalidFeedback: "Debe seleccionar al menos un tipo de usuario"
        },
        companyIds: {
          valid: this.workingMessage.companyIds?.length > 0,
          invalidFeedback: "Debe seleccionar al menos una empresa"
        }
      };
    },
    valid() {
      let valid = true;
      Object.keys(this.validation).forEach(x => {
        valid = valid && this.validation[x].valid;
      });
      return valid;
    },
    changed() {
      if (!this.messageScheduled) {
        return true;
      }
      return Object.keys(this.workingMessage).some(
        key => this.workingMessage[key] !== this.messageScheduled[key]
      );
    },
    alertVariant() {
      return this.error ? "danger" : "success";
    },
    alertMessage() {
      return this.error
        ? "Ha ocurrido un error guardando los datos: " + this.errorMessage
        : "Mensaje programado exitosamente";
    }
  },
  methods: {
    /**
     * Indica si el formulario actual es de creación de un mensaje programado.
     * @return {Boolean}
     */
    isCreationForm() {
      return this.$route.name == "Messages Scheduled New";
    },
    /**
     * Valida si se puede guardar el mensaje programado y redirecciona al
     * listado de mensajes si se puede guardar.
     */
    async save() {
      this.error = false;
      this.errorMessage = null;
      if (!this.valid) {
        this.error = true;
        this.errorMessage = "Hay campos que se deben rellenar.";
        this.showAlert = true;
      } else {
        this.saving = true;
        if (this.isCreationForm()) {
          await this.messagesCreateScheduled();
        } else {
          await this.messagesUpdateScheduled();
        }
        if (!this.error) {
          this.$router.push({ name: "Messages Scheduled" });
        }
      }
    },
    /**
     * Envía la mutación para actualizar un mensaje programado.
     */
    async messagesUpdateScheduled() {
      const input = { ...this.workingMessage };
      delete input.__typename;
      await this.$apollo
        .mutate({
          mutation: UPDATE,
          variables: { input: input }
        })
        .then(({ data }) => {
          if (data.messagesUpdateScheduled.error) {
            this.error = true;
            this.errorMessage = data.messagesUpdateScheduled.error;
            this.showAlert = true;
          }
          this.saving = false;
        })
        .catch(e => {
          this.error = true;
          this.errorMessage = e.message;
          this.showAlert = true;
          this.saving = false;
        });
    },
    /**
     * Envía la mutación para crear un mensaje programado.
     */
    async messagesCreateScheduled() {
      await this.$apollo
        .mutate({
          mutation: CREATE,
          variables: { messageScheduled: this.workingMessage }
        })
        .then(({ data }) => {
          if (data.messagesCreateScheduled.error) {
            this.error = true;
            this.errorMessage = data.messagesCreateScheduled.error;
          }
          this.showAlert = true;
          this.saving = false;
        })
        .catch(e => {
          this.error = true;
          this.errorMessage = e.message;
          this.showAlert = true;
          this.saving = false;
        });
    },
    /**
     * Se encarga de filtrar las empresas a mostrar como opciones del select
     * solicitando al servidor un subconjunto que contenga en su nombre el
     * término ingresado por el usuario.
     * @param {String} search término a ser buscado
     * @param {Boolean} loading indica si el request está en curso
     * @param {Function} setOptions función del componente BaseLiveSelect que
     *                              espera recibir un arreglo de opciones.
     */
    async searchCompanies(search, loading, setOptions) {
      loading(true);
      this.$apollo
        .query({
          query: ADMIN_COMPANIES,
          variables: { name: search }
        })
        .then(async result => {
          if (result?.data?.adminCompanies?.edges) {
            setOptions(
              result.data.adminCompanies.edges
                .map(x => this.itemForSelect(x.node))
                .filter(x => x)
            );
          }
          loading(false);
        })
        .catch(() => {
          loading(false);
        });
    },
    /**
     * Transforma un elemento obtenido desde Centry para que sea un item dentro
     * del listado de opciones que maneja el componente BaseLiveSelect.
     *
     * El resultado es un objeto con la siguiente estructura:
     * `{ value: "id", label: "nombre" }`
     *
     * @param {Object} item un objeto de Centry. Se espera que tenga `id` y
     *                      `name`.
     */
    itemForSelect(item) {
      if (item) {
        this.companyNames[item.id] = item.name;
        if (this.workingMessage.companyIds?.includes(item.id)) {
          return null;
        }
        return { value: item.id, label: item.name };
      }
      return null;
    },
    /**
     * Entrega el nombre de una empresa a partir de su id. Si el nombre no ha
     * sido solicitado previamente, se realiza una consulta al servidor para
     * obtenerlo.
     *
     * @param {String} companyId identificador de la empresa
     */
    companyName(companyId) {
      if (this.companyNames[companyId]) {
        return this.companyNames[companyId];
      }
      this.$apollo
        .query({
          query: COMPANY,
          variables: { id: companyId }
        })
        .then(async result => {
          if (result?.data?.company) {
            this.companyNames[companyId] = result.data.company.name;
            this.$forceUpdate();
          }
        });
      return `🗘 ${companyId}`;
    },
    /**
     * Agrega una empresa a la lista de empresas seleccionadas para el mensaje
     * programado.
     */
    addCompany() {
      if (this.companyToAdd) {
        this.workingMessage.companyIds.push(this.companyToAdd.value);
        this.companyToAdd = null;
      }
    },
    /**
     * Elimina una empresa de la lista de empresas seleccionadas para el mensaje
     * programado.
     *
     * @param {String} companyId identificador de la empresa a eliminar
     */
    removeCompanyId(companyId) {
      this.workingMessage.companyIds = this.workingMessage.companyIds.filter(
        x => x !== companyId
      );
    },
    /**
     * Actualiza el valor del template del mensaje programado.
     *
     * @param {String} value nuevo valor del template
     */
    updateTemplate(value) {
      this.workingMessage.template = value;
    }
  }
};
</script>

<style scoped>
.mail-preview {
  background-color: #f8f9fa;
  border: 1px solid #ccc;
  padding: 10px;
  height: 350px;
  overflow-y: auto;
}
</style>
