<template>
  <b-modal id="download-bulk-upload-template" @hidden="softResetData" size="lg">
    <template v-slot:modal-title>
      <h4>¿Qué desea incluir en la descarga?</h4>
    </template>
    <b-container class="h-100">
      <div
        v-if="global && !showCategory && Object.keys(integrations).length"
        class="mb-2"
      >
        <div v-for="(integration, pii, i) of integrations" :key="pii + i">
          <b-row class="mx-0">
            <div>
              <b>Integracion {{ pii }}: </b>&nbsp;&nbsp;&nbsp;
            </div>
            <b-form-checkbox
              v-for="ic in integration"
              v-model="integrationsSelected"
              :key="ic.id"
              :value="ic.id"
              inline
            >
              {{ ic.name }}
            </b-form-checkbox>
          </b-row>
        </div>
      </div>
      <div v-if="global && !showCategory && warehouses.length" class="mb-2">
        <b-row class="mx-0">
          <div><b>Bodegas: </b>&nbsp;&nbsp;&nbsp;</div>
          <b-form-checkbox
            v-for="wh in warehouses"
            v-model="warehousesSelected"
            :key="wh.id"
            :value="wh.id"
            inline
          >
            {{ wh.name }}
          </b-form-checkbox>
        </b-row>
      </div>
      <div v-if="useProducts && !showCategory" class="mb-2">
        <vMultiselectListbox
          style="min-width: 100%"
          v-model="brandsSelected"
          :options="allBrands"
          :reduce-display-property="option => option.name"
          :reduce-value-property="option => option.id"
          search-options-placeholder="Buscar marcas"
          selected-options-placeholder="Buscar marcas seleccionadas"
          no-options-text="No hay marcas"
          selected-no-options-text="No hay marcas seleccionadas"
          no-options-found-text="No se encontró la marca"
          no-selected-options-found-text="No se encontró la marca seleccionada"
        >
        </vMultiselectListbox>
      </div>

      <div v-if="showCategory" class="mb-2">
        <b-row class="mx-0">
          <div><b>Integraciones: </b>&nbsp;&nbsp;&nbsp;</div>
          <b-form-checkbox
            v-for="pii in allPii"
            v-model="piiSelected"
            :key="pii.id"
            :value="pii.id"
            inline
          >
            {{ pii.name }}
          </b-form-checkbox>
        </b-row>
        <br />
        <b-spinner v-if="loadingCategories" />
        <vMultiselectListbox
          v-else-if="!showAllCategories"
          style="min-width: 100%"
          v-model="companyCategoriesSelected"
          :options="companyCategories"
          :reduce-display-property="option => option.name"
          :reduce-value-property="option => option.id"
          search-options-placeholder="Buscar categorias"
          selected-options-placeholder="Buscar categorias seleccionadas"
          no-options-text="No hay categorias"
          selected-no-options-text="No hay categorias seleccionadas"
          no-options-found-text="No se encontró la categoria"
          no-selected-options-found-text="No se encontró la categoria seleccionada"
          @input="changes"
        >
        </vMultiselectListbox>
        <vMultiselectListbox
          v-else
          style="min-width: 100%"
          v-model="noCompanyCategoriesSelected"
          :options="allNoCompanyCategories"
          :reduce-display-property="option => option.name"
          :reduce-value-property="option => option.id"
          search-options-placeholder="Buscar categorias"
          selected-options-placeholder="Buscar categorias seleccionadas"
          no-options-text="No hay categorias"
          selected-no-options-text="No hay categorias seleccionadas"
          no-options-found-text="No se encontró la categoria"
          no-selected-options-found-text="No se encontró la categoria seleccionada"
          @input="changes"
        >
        </vMultiselectListbox>
        <b-form-checkbox v-model="showAllCategories" inline>
          Ver otras categorias de Centry
        </b-form-checkbox>
        <div>
          Se generaran {{ categoryAttributesQuantity }} columnas de atributos
        </div>
      </div>
    </b-container>
    <template #modal-footer>
      <b-button variant="primary" class="float-right" @click="downloadWithSwal">
        Descargar
      </b-button>
    </template>
  </b-modal>
</template>
<script>
import vMultiselectListbox from "vue-multiselect-listbox";
import ALL_CATEGORIES from "../../../graphql/AllCategories.gql";
import CATEGORY_ATTRIBUTES_BY_CATEGORY from "../../../graphql/BulkUpload/TemplateDownload/GetCategoryAttributeIds.gql";
import { mapState } from "vuex";
export default {
  name: "ModalDownload",
  components: {
    vMultiselectListbox
  },
  props: {
    useProducts: {
      type: Boolean,
      default: false
    },
    integrations: {
      type: Object,
      default() {
        return {};
      }
    },
    warehouses: {
      type: Array,
      default() {
        return [];
      }
    },
    showCategory: {
      type: Boolean,
      default: false
    },
    global: {
      type: Boolean,
      default: false
    },
    companyCategories: {
      type: Array,
      default() {
        return [];
      }
    },
    allBrands: {
      type: Array,
      default() {
        return [];
      }
    },
    allPii: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      brandsSelected: [],
      warehousesSelected: [],
      integrationsSelected: [],
      companyCategoriesSelected: [],
      noCompanyCategoriesSelected: [],
      showAllCategories: false,
      allNoCompanyCategories: [],
      loadingCategories: false,
      categoriesHash: {},
      categoryAttributes: {},
      piiSelected: []
    };
  },
  computed: {
    ...mapState(["currentUser"]),
    categoryAttributesQuantity() {
      return Object.keys(this.categoryAttributes).length;
    }
  },
  methods: {
    /**
     * Resetea todos los valores que se pueden cambiar
     */
    softResetData() {
      this.brandsSelected = [];
      this.warehousesSelected = [];
      this.integrationsSelected = [];
      this.companyCategoriesSelected = [];
      this.noCompanyCategoriesSelected = [];
      this.showAllCategories = false;
      this.categoryAttributes = {};
      this.piiSelected = [];
    },
    /**
     * Obtiene todas las categorias que no son de la empresa
     */
    getAllNotCompanyCategories() {
      this.loadingCategories = true;
      this.$getAllPages(
        ALL_CATEGORIES,
        {
          notCompanyId: this.currentUser.company.id
        },
        "allCategories"
      ).then(data => {
        if (!data) {
          this.allNoCompanyCategories = [];
        } else {
          this.allNoCompanyCategories = data.map(edge => edge.node);
        }
        this.loadingCategories = false;
      });
    },
    /**
     * Obtiene los atributos de categoria para unna categoria
     * @param {String} categoryId
     */
    async getCategoryAttributes(categoryId) {
      let { data } = await this.$apollo.query({
        query: CATEGORY_ATTRIBUTES_BY_CATEGORY,
        variables: {
          categoryId: categoryId
        }
      });
      let atts = data.category.categoryAttributeIds;
      this.categoriesHash[categoryId] = atts;
      return atts;
    },
    /**
     * Actualiza la cantidad de atributos a crear segun las categorias seleccionadas
     * @param {Array} categories
     */
    async changes(categories) {
      let tempCategoryAttributes = {};
      let newAtts = [];
      let tempAtt = [];
      let tempPromise = [];
      await categories.forEach(cat => {
        if (this.categoriesHash[cat]) {
          tempAtt = this.categoriesHash[cat];
          newAtts = newAtts.concat(this.$dup(tempAtt));
        } else {
          tempPromise.push(this.getCategoryAttributes(cat));
        }
      });

      await Promise.all(tempPromise).then(allAtts => {
        allAtts.forEach(atts =>
          atts.forEach(att => (tempCategoryAttributes[att] = true))
        );
        newAtts.forEach(att => (tempCategoryAttributes[att] = true));
        this.categoryAttributes = this.$dup(tempCategoryAttributes);
      });
    },
    /**
     * Emite un evento indicando la descarga de la planilla con las variables correspondientes
     */
    downloadTemplate() {
      let variables = {
        warehouseIds: this.warehousesSelected,
        integrationsIds: this.integrationsSelected,
        brandIds: this.brandsSelected,
        categoryIdsCentry: this.noCompanyCategoriesSelected,
        categoryIdsUser: this.companyCategoriesSelected,
        piiIds: this.piiSelected
      };
      this.$emit("download", variables);
    },
    /**
     * Descarga luego de desplegar un sweetAlert, si corresponde
     */
    downloadWithSwal() {
      if (!this.showCategory || !this.useProducts) {
        return this.downloadTemplate();
      }
      this.$swal
        .fire({
          title: "Planilla de atributos",
          text:
            "La planilla contiene colores para ayudarte a completar los atributos. Estos NO CAMBIAN al modificar la categoría en la planilla",
          icon: "info",
          confirmButtonText: "Entendido"
        })
        .then(() => {
          this.downloadTemplate();
        });
    }
  },
  watch: {
    showAllCategories(val) {
      if (val && !this.allNoCompanyCategories.length) {
        this.getAllNotCompanyCategories();
      }
    }
  }
};
</script>
