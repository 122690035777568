<template>
  <div>
    <b-alert show variant="danger">
      ERROR: {{ message }}. Este error no puede ser corregido en vivo. Por
      favor, corríjalo, y
      <b-link href="/bulk_upload">vuelva a subir la planilla.</b-link>
    </b-alert>
    <xlsx-workbook>
      <xlsx-sheet :collection="sheetErrors" sheet-name="Errores" />
      <xlsx-download filename="Errores-carga-masiva.xlsx">
        <b-button variant="info">
          Informe de errores <b-icon-download></b-icon-download>
        </b-button>
      </xlsx-download>
    </xlsx-workbook>
    <br />
  </div>
</template>

<script>
export default {
  name: "BulkUploadTabsPreparationCriticalError",
  props: {
    data: Array,
    errors: Object,
    message: String,
    idProductColumn: Number,
    skuProductColumn: Number,
    idVariantColumn: Number,
    skuVariantColumn: Number
  },
  methods: {
    /**
     * Genera los títulos de las columnas del informe de errores
     * @return {Array}
     */
    sheetErrorsHeaders() {
      let headers = this.headersColumnIndex.map(columnIndex =>
        this.columnName(columnIndex)
      );
      return headers.concat("Error");
    },
    /**
     * Obtiene el titulo del identificador de producto o variante para usar
     * en el informe de errores
     * @param {Number} columnIndex
     * @return {String}
     */
    columnName(columnIndex) {
      switch (columnIndex) {
        case this.idProductColumn:
          return "ID Producto";
        case this.skuProductColumn:
          return "SKU Producto";
        case this.idVariantColumn:
          return "ID Variante";
        case this.skuVariantColumn:
          return "SKU Variante";
      }
    }
  },
  computed: {
    /**
     * Obtiene el indice de la columna del archivo cargado que se utiliza como
     * identificador del producto
     * @return {Number}
     */
    productKeyColumn() {
      if (this.idProductColumn >= 0) return this.idProductColumn;
      if (this.skuProductColumn >= 0) return this.skuProductColumn;
      if (this.idVariantColumn >= 0) return this.idVariantColumn;
      return this.skuVariantColumn;
    },
    /**
     * Obtiene el indice de la columna del archivo cargado que se utiliza como
     * identificador de la variante
     * @return {Number}
     */
    variantKeyColumn() {
      if (this.idVariantColumn >= 0) return this.idVariantColumn;
      return this.skuVariantColumn;
    },
    /**
     * Calcula que columnas del archivo cargado utilizar para identificar los
     * productos y variantes en el informe de errores
     * @return {Array<Number>}
     */
    headersColumnIndex() {
      if (this.productKeyColumn === this.variantKeyColumn) {
        return [this.productKeyColumn];
      }
      if (this.idVariantColumn < 0 && this.skuVariantColumn < 0) {
        return [this.productKeyColumn];
      }
      if (this.idProductColumn < 0 && this.skuProductColumn < 0) {
        return [this.variantKeyColumn];
      }
      return [this.productKeyColumn, this.variantKeyColumn];
    },
    /**
     * Genera el informe de errores de la carga masiva
     * @return {Array<Array>}
     */
    sheetErrors() {
      let sheet = [this.sheetErrorsHeaders()];
      for (var [dataIndex, errors] of Object.entries(this.errors)) {
        let row = this.headersColumnIndex.map(
          columnIndex => this.data[dataIndex][columnIndex]
        );
        row.push(errors.filter(error => error.length > 0).join(". "));
        sheet.push(row);
      }
      return sheet;
    }
  }
};
</script>
