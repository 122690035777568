<template>
  <div>
    <product-transactions-page :product="product"></product-transactions-page>
  </div>
</template>
<script>
import ProductTransactionsPage from "../components/ProductTransactionsPage.vue";
export default {
  name: "ProductTransactions",
  props: {
    product: Object
  },
  components: {
    ProductTransactionsPage
  }
};
</script>
