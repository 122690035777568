<template>
  <div>
    <hr />
    <h4 class="font-weight-bold m-3">Colores</h4>
    <p>
      En {{ integrationConfig.label }} los productos no pueden variar por
      colores, por lo que las variantes serán agrupadas por colores por lo que
      en esta sección podrás controlar los atributos que corresponderán a cada
      publicación.
    </p>
    <b-table-simple>
      <b-thead>
        <b-tr>
          <b-th></b-th>
          <b-th v-for="color of colorIndexes" :key="'h' + color">
            {{ colors[color].name }}
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="attr of optionalAttributes" :key="attr.FeedName">
          <b-td>{{ attr.Label }}</b-td>
          <b-td v-for="color of colorIndexes" :key="color">
            <product-edit-tabs-rocket-internet-attribute-input
              :attribute="attr"
              v-model="colors[color][attr.FeedName]"
              @input="() => project(color, attr.FeedName)"
              :show-label="false"
            />
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>Título de la publicación</b-td>
          <b-td v-for="color of colorIndexes" :key="'n' + color">
            <b-form-group>
              <b-form-input
                v-model="colors[color].Name"
                @input="() => project(color, 'Name')"
              ></b-form-input>
            </b-form-group>
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>Precio</b-td>
          <b-td v-for="color of colorIndexes" :key="'p' + color">
            <b-form-group>
              <b-form-input
                v-model="colors[color].Price"
                @input="() => project(color, 'Price')"
                :state="$validFormPrice(colors[color].Price)"
                :formatter="$formatFormPrice"
              ></b-form-input>
            </b-form-group>
            <h4>
              <b-alert
                :show="differentPrices(color, 'Price') && !saved"
                variant="warning"
              >
                Una de tus variantes tiene un precio distinto. Por favor guarda
                para que todas las variantes tengan el mismo precio.
              </b-alert>
            </h4>
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>Precio de oferta</b-td>
          <b-td v-for="color of colorIndexes" :key="'sp' + color">
            <b-form-group>
              <b-form-input
                v-model="colors[color].SalePrice"
                @input="() => project(color, 'SalePrice')"
                :state="$validFormPrice(colors[color].SalePrice)"
                :formatter="$formatFormPrice"
              ></b-form-input>
            </b-form-group>
            <h4>
              <b-alert
                :show="differentPrices(color, 'SalePrice') && !saved"
                variant="warning"
              >
                Una de tus variantes tiene un precio distinto. Por favor guarda
                para que todas las variantes tengan el mismo precio.
              </b-alert>
            </h4>
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>Inicio de la oferta</b-td>
          <b-td v-for="color of colorIndexes" :key="'ssd' + color">
            <b-form-group>
              <base-date-time
                type="date"
                value-type="format"
                v-model="colors[color].SaleStartDate"
                @change="() => project(color, 'SaleStartDate')"
              ></base-date-time>
            </b-form-group>
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>Término de la oferta</b-td>
          <b-td v-for="color of colorIndexes" :key="'sed' + color">
            <b-form-group>
              <base-date-time
                type="date"
                value-type="format"
                v-model="colors[color].SaleEndDate"
                @change="() => project(color, 'SaleEndDate')"
              ></base-date-time>
            </b-form-group>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <hr />
    <h4 class="font-weight-bold m-3">Variantes</h4>
    <b-table-simple>
      <b-thead>
        <b-tr>
          <b-th colspan="3">Datos en Centry</b-th>
          <b-th colspan="4">Datos de {{ integrationConfig.label }}</b-th>
        </b-tr>
        <b-tr>
          <b-th>Color</b-th>
          <b-th>Talla</b-th>
          <b-th>SKU Centry</b-th>
          <b-th>Seller SKU</b-th>
          <b-th>BARCODE</b-th>
          <b-th v-for="attr of variationFields" :key="attr.FeedName">
            {{ attr.Label }}
          </b-th>
          <b-th>Estado</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="(variant, index) of product.variants" :key="index">
          <b-td>{{ variant.color ? variant.color.name : "" }}</b-td>
          <b-td>{{ variant.size ? variant.size.name : "" }}</b-td>
          <b-td>{{ variant.sku }}</b-td>
          <b-td>
            <b-form-group>
              <b-form-input
                v-model="array[index].SellerSku"
                @input="emitChange"
              ></b-form-input>
            </b-form-group>
          </b-td>
          <b-td>
            <b-form-group>
              <b-form-input
                v-model="array[index].ProductId"
                @input="emitChange"
              ></b-form-input>
            </b-form-group>
          </b-td>
          <b-td v-for="attr of variationFields" :key="attr.FeedName">
            <product-edit-tabs-rocket-internet-attribute-input
              :attribute="attr"
              v-model="array[index][attr.FeedName]"
              @input="emitChange"
              :show-label="false"
            />
          </b-td>
          <b-td>
            <b-form-group>
              <v-select
                :options="statusOptions"
                v-model="array[index].Status"
                :reduce="x => x.value"
                @input="emitChange"
                :clearable="false"
              ></v-select> </b-form-group
          ></b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>
<script>
import BaseDateTime from "../../BaseDateTime.vue";
import ProductEditTabsRocketInternetAttributeInput from "./AttributeInput.vue";
import { mapActions } from "vuex";
export default {
  name: "ProductEditTabsRocketInternetVariants",
  components: { BaseDateTime, ProductEditTabsRocketInternetAttributeInput },
  model: {
    prop: "_array",
    event: "change"
  },
  props: {
    _array: {
      type: Array,
      default() {
        return [];
      }
    },
    categoryAttributes: Array,
    product: Object,
    integrationConfig: Object,
    saved: Boolean
  },
  async created() {
    this.colorIndexes.forEach(color => {
      const fields = [
        "Name",
        "Price",
        "SalePrice",
        "SaleStartDate",
        "SaleEndDate",
        "Color",
        "ColorBasico",
        "ColorNameBrand",
        "ColorFamily"
      ];
      fields.forEach(field => {
        this.project(color, field, false);
      });
    });
  },
  data() {
    const colors = {};
    if (this.product.variants) {
      this.product.variants.forEach((v, idx) => {
        const x = this._array[idx];
        const key = v.color ? v.color.id : "--";
        if (!colors[key]) {
          colors[key] = {
            name: v.color ? v.color.name : "--Sin Color--",
            variants: [idx],
            Name: x.Name,
            Price: x.Price,
            SalePrice: x.SalePrice,
            SaleStartDate: x.SaleStartDate,
            SaleEndDate: x.SaleEndDate,
            Color: x.Color,
            ColorBasico: x.ColorBasico,
            ColorNameBrand: x.ColorNameBrand,
            ColorFamily: this.withNoDuplicates(x.ColorFamily)
          };
        } else {
          colors[key].variants.push(idx);
        }
      });
    }
    return {
      array: this._array.map(x => Object.assign({}, x)),
      statusOptions: [
        { label: "---", value: "" },
        { label: "Activo", value: "active" },
        { label: "Pausado", value: "inactive" }
      ],
      colors
    };
  },
  computed: {
    /**
     * Atributos opcionales, asociados a color
     */
    optionalAttributes() {
      return ["Color", "ColorBasico", "ColorNameBrand", "ColorFamily"]
        .map(feedName =>
          this.categoryAttributes.find(x => x.FeedName === feedName)
        )
        .filter(x => !!x);
    },
    variationFields() {
      const sizeKeys = ["Variation", "Talla", "Size"];
      return this.categoryAttributes.filter(x => sizeKeys.includes(x.FeedName));
    },
    colorIndexes() {
      return Object.keys(this.colors);
    }
  },
  methods: {
    ...mapActions(["timezoneDate"]),
    async toDate(string) {
      if (string && string.length > 0) {
        const dateString = await this.timezoneDate(string);
        return new Date(dateString);
      }
      return null;
    },
    emitChange() {
      this.$emit("change", this.array);
    },
    project(color, field, emit = true) {
      const colorField = this.colors[color];
      let toSet = colorField[field];
      colorField.variants.forEach(x => {
        this.array[x][field] = toSet;
      });
      if (emit) this.emitChange();
    },
    differentPrices(color, field) {
      let different = false;
      if (!this.product.variants || !this.product.variants.length) {
        return false;
      }
      const firstVariant = this.product.variants[0];
      const firstVariantIntegration = firstVariant.integrations
        ? firstVariant.integrations
        : {};
      const fvSeparator = firstVariantIntegration[
        this.integrationConfig.fullLabel
      ]
        ? firstVariantIntegration[this.integrationConfig.fullLabel]
        : {};
      const colorPrice = fvSeparator[field] ? fvSeparator[field] : "";
      this.product.variants.forEach(v => {
        if (color === this.$dig(v, "color", "id")) {
          const original = v.integrations
            ? v.integrations[this.integrationConfig.fullLabel]
              ? v.integrations[this.integrationConfig.fullLabel]
              : {}
            : {};
          if (colorPrice !== (original[field] ? original[field] : "")) {
            different = true;
            this.emitChange();
          }
        }
      });
      return different;
    },
    withNoDuplicates(arr) {
      if (!arr) return null;
      const h = {};
      arr.forEach(x => (h[x] = true));
      return Object.keys(h);
    }
  }
};
</script>
