<template>
  <div>
    <b-form-group label="Tipo de tabla de tallas" label-for="type">
      <v-select
        v-model="type"
        :options="optionTypes"
        id="type"
        placeholder="Seleccione el tipo de tabla de tallas"
        :reduce="x => x.value"
      ></v-select>
    </b-form-group>
    <b-form-group>
      <mercado-libre-grid-chart-selector
        v-if="!!type"
        :integrationConfigId="integrationConfigId"
        :loading="loading"
        :sizeChart="sizeChartToEdit"
        :showForm="showForm"
        v-model="domain"
        :editMode="false"
        @change="setDomain"
        @hideForm="hideForm"
      >
        <b-spinner v-if="loading"></b-spinner>
        <b-alert show variant="danger" v-if="error">
          Ocurrio un error al tratar de crear la guía de tallas.
        </b-alert>
        <b-spinner v-if="loading"></b-spinner>
        <size-chart-mercado-libre-form-all-attributes
          v-else-if="domain"
          :domain="domain"
          :typeChart="type"
          :loadingAllGrids="loadingQuery"
          v-model="sizeChartToEdit"
          :integration-config-id="integrationConfigId"
          :refresh="refresh"
          @changeAttributeRequired="() => {}"
          @changeCharts="setCompleteQuery"
          @setLoading="setLoadingQuery"
        ></size-chart-mercado-libre-form-all-attributes>
      </mercado-libre-grid-chart-selector>
      <b-link
        class="h6"
        v-if="customType"
        @click="() => editMercadoLibreSizeChart(null)"
      >
        Crear
      </b-link>
      <size-chart-mercado-libre-form-modal
        id="form-modal"
        v-if="customType"
        :size-chart="sizeChartToEdit"
        :integration-config-id="integrationConfigId"
        :editMode="editMode"
        :oldRows="oldRows"
        @cancelForm="cancelForm"
        @refresh="refreshListSizeChart"
        @select="refreshAndSelectListSizeChart"
      ></size-chart-mercado-libre-form-modal>
    </b-form-group>
    <b-list-group>
      <b-spinner label="Spinning" v-if="loadingQuery"></b-spinner>
      <span v-else-if="chartsQuery.length === 0 && completeQuery">
        {{ notFoundMessage }}
      </span>
      <div v-else-if="chartsQuery.length > 0">
        <span>
          Guías de tallas disponibles:
        </span>
        <b-list-group-item
          v-for="item of chartsQuery"
          :key="item.id"
          target="_blank"
          :active="_value ? item.id == _value.id : false"
          href="#"
          @click="event => selectSizeChart(event, item)"
        >
          {{ item.names[siteCode] }}
          <b-link
            :id="editTargetId"
            :class="getCompleteClassEditLink(item.id)"
            v-if="customType"
            @click="editMercadoLibreSizeChart(item)"
            >Editar</b-link
          >
        </b-list-group-item>
      </div>
    </b-list-group>
  </div>
</template>
<script>
import SizeChartMercadoLibreFormModal from "./SizeChartMercadoLibreFormModal.vue";
import MercadoLibreGridChartSelector from "./MercadoLibreGridChartSelector.vue";
import SizeChartMercadoLibreFormAllAttributes from "./SizeChartMercadoLibreFormAllAttributes.vue";
import { mapMutations } from "vuex";
export default {
  name: "SizeChartMercadoLibreSelector",
  components: {
    MercadoLibreGridChartSelector,
    SizeChartMercadoLibreFormAllAttributes,
    SizeChartMercadoLibreFormModal
  },
  model: {
    event: "change",
    prop: "_value"
  },
  mounted() {
    this.setMercadoLibreSizeChart(this._value);
  },
  props: {
    _value: {
      type: Object,
      default: null
    },
    integrationConfigId: {
      type: String,
      default: null
    },
    siteCode: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      type: this.value,
      loading: false,
      sizeChartToEdit: null,
      domain: null,
      showForm: false,
      error: false,
      chartsQuery: [],
      completeQuery: false,
      loadingQuery: false,
      editTargetId: "edit_link",
      editMode: false,
      refresh: false,
      oldRows: null,
      selectModalChart: null
    };
  },
  computed: {
    optionTypes() {
      let options;
      if (["MLA", "MLM", "MLB"].includes(this.siteCode)) {
        options = [
          { value: "STANDARD", label: "Mercado Libre" },
          { value: "BRAND", label: "Marca" },
          { value: "SPECIFIC", label: "Personalizado" }
        ];
      } else {
        options = [{ value: "SPECIFIC", label: "Personalizado" }];
      }
      return options;
    },
    customType() {
      return this.type === "SPECIFIC";
    },
    types() {
      if (!this.type) return null;
      if (this.type == "SPECIFIC") {
        return ["SPECIFIC"];
      }
      return ["BRAND", "STANDARD"];
    },
    notFoundMessage() {
      if (this.type !== "SPECIFIC")
        return "-- No se encontraron tablas de talla para esta búsqueda --";
      return "-- No se encontraron tablas de talla presonalizadas para esta búsqueda, puedes crearla de ser necesario --";
    }
  },
  methods: {
    ...mapMutations(["setMercadoLibreSizeChart"]),
    /**
     * Emite evento que selecciona una GDT del listado de busqueda
     * @param {Object} event
     * @param {Object} sizeChart
     */
    selectSizeChart(event, sizeChart) {
      if (event.target.id == this.editTargetId) return;
      this.$emit("change", sizeChart);
    },
    /**
     * Cancela modal de creacion/edicion
     */
    cancelForm() {
      this.sizeChartToEdit = null;
    },
    /**
     * Abre modal para editar una GDT personalizada
     * Una GDT personalizada solo puede editar el nombre y sus filas de gdt.
     * Los atributos generales como el genero y la marca, no pueden ser editados.
     * @param {Object} sizeChart
     */
    editMercadoLibreSizeChart(sizeChart) {
      this.sizeChartToEdit = this.buildBody(sizeChart);
      if (this.sizeChartToEdit) {
        this.oldRows = this.sizeChartToEdit.rows;
      }
      this.editMode = sizeChart ? true : false;
      this.$bvModal.show("form-modal");
    },
    /**
     * Adapta GDT personalizada de meli para hacer aceptado por el formulario de centry para ser editado
     * @param {Object} sizeChart
     * @returns {Object}
     */
    buildBody(sizeChart) {
      if (!sizeChart) return sizeChart;
      let rest = this.$dup(sizeChart);
      let name = sizeChart.names ? sizeChart.names[this.siteCode] : "";
      rest.name = name;
      delete rest.names;
      let new_rows = this.buildDataForRows(sizeChart);
      let new_attrs = this.buildDataForFilterAttributes(sizeChart);
      rest.filter_attributes_values = new_attrs;
      rest.rows = new_rows;
      return rest;
    },
    /**
     * Transforma atributos generales para ser aceptados por el formulario de edicion de GDT
     * TODO: no se maneja caso de multiples valores. Existen realmente (?)
     * @param {Object} sizeChart
     * @returns {Object}
     */
    buildDataForFilterAttributes(sizeChart) {
      let attr_values = {};
      sizeChart.attributes.forEach(attr => {
        attr_values[attr.id] = attr.values[0].name;
      });
      return attr_values;
    },
    /**
     * Construye data de las filas de la GDT para enviar a modal de edicion
     * @param {Object} sizeChart
     * @returns {Array<Object>}
     */
    buildDataForRows(sizeChart) {
      let new_rows = [];
      sizeChart.rows.forEach(row => {
        let attrs = row.attributes;
        let data = { id: row.id };
        attrs.forEach(attr => {
          data[attr.id] = attr.values[0].name.split(" ")[0];
        });
        new_rows.push(data);
      });
      return new_rows;
    },
    /**
     * Esconde modal de formulario de creacion
     */
    hideForm() {
      this.showForm = false;
    },
    /**
     * Setea un nuevo dominio seleccionado
     * @param {Object}
     */
    setDomain(newValue) {
      this.domain = newValue;
      this.completeQuery = false;
    },
    /**
     * Recibe resultado de busqueda de GDTs
     * @param {Array<Object>} charts
     */
    setCompleteQuery(charts) {
      this.chartsQuery = charts;
      this.completeQuery = true;
      this.loadingQuery = false;
      if (this.selectModalChart) {
        let sizeChartToSelect = this.getSpecificSizeChartById(
          this.selectModalChart
        );
        this.selectSizeChart({ target: { id: null } }, sizeChartToSelect);
      }
      this.selectModalChart = null;
    },
    /**
     * Si hubo creacion/edicion exitosa y hay listado activo de consulta, se vuelve a consultar para updatear la info
     */
    refreshListSizeChart() {
      if (this.completeQuery) {
        this.refresh = !this.refresh;
      }
    },
    /**
     * Refresca listado y selecciona la GDT recien seleccionada
     * @param {String} chart_id
     */
    refreshAndSelectListSizeChart(chart_id) {
      this.refreshListSizeChart();
      this.selectModalChart = chart_id;
    },
    /**
     * Retorna una guia de tallas especifica para su seleccion
     * @param {String} chart_id
     * @returns {Object}
     */
    getSpecificSizeChartById(chart_id) {
      let ans = {};
      this.chartsQuery.forEach(sizeChart => {
        if (chart_id === sizeChart.id) {
          ans = sizeChart;
        }
      });
      return ans;
    },
    /**
     * Activa o no spinner de query de GDTs
     * @param {Boolean} value
     */
    setLoadingQuery(value) {
      this.loadingQuery = value;
    },
    /**
     * Entrega clase css que debe estar asociada al link
     * @param {String} item_id
     */
    getCompleteClassEditLink(item_id) {
      if (!this._value) return "float-right";
      if (item_id != this._value.id) return "float-right";
      return "float-right text-white";
    }
  },
  watch: {
    _value(newValue) {
      this.setMercadoLibreSizeChart(newValue);
    },
    domain(newVal) {
      if (newVal) this.showForm = true;
    },
    type() {
      this.chartsQuery = [];
      this.completeQuery = false;
      this.loadingQuery = false;
    }
  }
};
</script>
