<template>
  <b-card
    no-body
    class="handle"
    style="width: 100% !important; cursor: all-scroll;"
  >
    <b-card-body class="py-2">
      <b-row>
        <b-col cols="10">
          <b-row align-h="start" align-v="center">
            <div>
              {{ warehouse.name }}
            </div>
            <div v-if="locations.length > 0 && using" class="ml-2">
              <b-icon icon="chevron-right" />
            </div>
            <div v-if="locations.length > 0 && using" class="ml-2">
              <b-form-group
                class="m-0"
                :invalid-feedback="'Este campo es obligatorio'"
                :state="!invalidState"
              >
                <v-select
                  :options="locations"
                  :value="locationId"
                  label="name"
                  :clearable="false"
                  @input="$emit('update:locationId', $event)"
                  :reduce="val => val.id"
                />
              </b-form-group>
            </div>
          </b-row>
        </b-col>
        <b-col cols="2" style="display: flex; justify-content: end;">
          <b-button
            v-if="using"
            variant="outline"
            @click="stopUsing"
            class="p-0"
          >
            <b-icon-x
              variant="danger"
              style="cursor: pointer"
              v-b-tooltip.hover="'Dejar de usar esta bodega'"
            />
          </b-button>
          <b-button v-else variant="outline" @click="use" class="p-0">
            <b-icon-chevron-right
              variant="danger"
              style="cursor: pointer"
              v-b-tooltip.hover="'Usar esta bodega'"
            />
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
export default {
  name: "WarehouseContainer",
  props: {
    warehouse: {
      type: Object,
      default() {
        return {};
      }
    },
    locations: {
      type: Array,
      default() {
        return [];
      }
    },
    locationId: {
      type: String,
      default: null
    },
    using: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    invalidState() {
      return this.using && this.locationId == null;
    }
  },
  data() {
    return {};
  },
  methods: {
    /**
     * Emite un evento cuando una bodega se deja de usar
     */
    stopUsing() {
      this.$emit("stopUsing");
    },
    /**
     * Emite un evento cuando una bodega se empieza a usar
     */
    use() {
      this.$emit("use");
    }
  }
};
</script>

<style scoped></style>
