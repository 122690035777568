<template>
  <b-card
    body-class="p-0"
    class="shadow"
    :style="{
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      border: borderRed ? '3px solid' : '',
      width: '145px'
    }"
    :border-variant="borderRed ? 'danger' : ''"
  >
    <b-card-img-lazy
      class="cover cover-rectangle transparent-bg"
      :src="asset.originalUrl"
      alt="cover"
      v-if="asset.originalUrl"
    ></b-card-img-lazy>
    <b-card-footer v-if="showImageName">
      <div style="display: flex; justify-content: center">
        {{ asset[imageName] }}
      </div>
    </b-card-footer>
  </b-card>
</template>

<script>
export default {
  name: "VariantImageContainer",
  components: {},
  props: {
    asset: Object,
    borderRed: {
      type: Boolean,
      default: false
    },
    showImageName: {
      type: Boolean,
      default: false
    },
    imageName: {
      type: String,
      default: "Name"
    }
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {},
  methods: {},
  watch: {}
};
</script>
<style scoped>
.cover {
  width: 100% !important;
  height: 100% !important;
  border-radius: 50%;
}
.cover-rectangle {
  border-radius: 0px;
  height: auto;
}

.transparent-bg {
  background-color: #ffffff;
  background-image: linear-gradient(45deg, #cacaca 25%, transparent 25%),
    linear-gradient(-45deg, #cacaca 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, #cacaca 75%),
    linear-gradient(-45deg, transparent 75%, #cacaca 75%);
  background-size: 20px 20px;
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
}
</style>
