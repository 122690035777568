<template>
  <div>
    <ApolloQuery
      :query="require('../graphql/AdminBulkUploadForMonitoring.gql')"
      :variables="currentFilters"
    >
      <template slot-scope="{ result: { error, data }, query, isLoading }">
        <!-- Error -->
        <b-alert v-if="error" show="" variant="danger">
          Ha ocurrido un error
        </b-alert>
        <!-- Result -->
        <b-table-simple v-if="data && data.adminBulkUploads" hover>
          <b-thead>
            <b-tr>
              <b-th>Fecha de creación</b-th>
              <b-th>Empresa</b-th>
              <b-th>Estado</b-th>
              <b-th>Estado real</b-th>
              <b-th></b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <pending-bulk-upload-table-row
              v-for="bulkUpload of data.adminBulkUploads.edges"
              :key="bulkUpload.node.id"
              :bulk-upload="bulkUpload.node"
              :sidekiq-ids="sidekiqIds"
            ></pending-bulk-upload-table-row>
          </b-tbody>
        </b-table-simple>
        <b-spinner
          v-if="isLoading"
          label="Spinning"
          class="m-2 float-right"
        ></b-spinner>
        <div v-else>
          <b-button
            v-if="hasNextPage(data)"
            class="m-2 float-right"
            @click="seeMore(query, data.adminBulkUploads.pageInfo.endCursor)"
          >
            Ver más
          </b-button>
          <b-alert v-else show variant="light" class="text-center">
            No hay más datos para mostrar.
          </b-alert>
        </div>
      </template>
    </ApolloQuery>
  </div>
</template>
<script>
import PendingBulkUploadTableRow from "./PendingBulkUploadTableRow.vue";
import GET_BULK_UPLOAD_SIDEKIQ_IDS from "../graphql/GetBulkUploadSidekiqIds.gql";
import { mapState } from "vuex";
export default {
  name: "PendingBulkUploadTable",
  components: {
    PendingBulkUploadTableRow
  },
  data() {
    return {
      sidekiqIds: null
    };
  },
  mounted() {
    this.$apollo
      .query({ query: GET_BULK_UPLOAD_SIDEKIQ_IDS })
      .then(({ data }) => {
        this.sidekiqIds = data.getBulkUploadSidekiqIds;
      });
  },
  computed: {
    ...mapState(["filters"]),
    currentFilters() {
      return {
        createdAtGte: this.$moment().subtract(1, "months")
      };
    }
  },
  methods: {
    /**
     * Indica si hay una página siguiente en la query de GraphQl basado
     * exclusivamente en la existencia de un cursor.
     * @return {boolean}
     */
    hasNextPage(data) {
      return data?.adminBulkUploads?.pageInfo?.endCursor != null;
    },
    /**
     * Trae más resultados de una query avanzando en su cursor.
     * @param {Object} query
     * @param {String} cursor
     */
    async seeMore(query, cursor) {
      await query.fetchMore({
        variables: this.queryVariables(cursor),
        updateQuery: (prev, { fetchMoreResult }) => {
          if (
            !fetchMoreResult ||
            !fetchMoreResult.adminBulkUploads ||
            fetchMoreResult.adminBulkUploads.edges.length === 0
          ) {
            return prev;
          }
          const updated = Object.assign({}, this.$dup(prev));
          updated.adminBulkUploads.pageInfo =
            fetchMoreResult.adminBulkUploads.pageInfo;
          updated.adminBulkUploads.edges.push(
            ...fetchMoreResult.adminBulkUploads.edges
          );
          return updated;
        }
      });
    }
  }
};
</script>

<style scoped>
.btn-sm {
  margin: 5px;
}
</style>
