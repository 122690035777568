<template>
  <b-alert show variant="success">
    <b-row align-v="center" class="pl-4">
      <b-button
        size="lg"
        variant="outlined"
        class="p-0 mx-1 focus-btn"
        title="Actualizar pagina"
        v-b-tooltip.hover
        @click="click"
      >
        <b-icon stacked icon="arrow-counterclockwise" scale="0.75"></b-icon>
      </b-button>
      Hay una actualización de Centry disponible, por favor&nbsp;
      <b-link @click="click">actualice la página.</b-link> &nbsp; (Precaución,
      si actualizas la página se perderán los cambios no guardados, además, al
      actualizar una pestaña se actualizarán todas las pestañas abiertas)
    </b-row>
  </b-alert>
</template>

<script>
export default {
  name: "RefreshPage",

  props: {
    click: Function
  }
};
</script>

<style>
.focus-btn {
  color: #aab1b5;
  display: inline-block;
  background-color: white;
  width: 34px;
  height: 34px;
  text-align: center;
  border-radius: 50%;
  font-size: 14px;
  border: 2px solid #e0e2e4;
  padding: 5px 0;
}

.focus-btn:hover {
  border-color: #aab1b5;
  color: black;
}
</style>
