<template>
  <div>
    <base-header
      title="Guías de tallas"
      title-link="/size_charts"
      :map="baseHeaderMap"
      :titleSize="6"
    >
    </base-header>
    <b-card no-body>
      <b-spinner
        label="Spinning"
        v-if="(!sizeChart && !creating) || !integrationConfigs"
      ></b-spinner>
      <b-tabs v-else card @activate-tab="changeTab" v-model="selected">
        <b-tab title="Centry" :key="0">
          <size-chart-tabs-centry
            :size-chart="sizeChart"
            @change="setSizeChart"
          ></size-chart-tabs-centry>
        </b-tab>
        <b-tab
          v-for="ic of sizeChartIntegrationConfigs"
          :title="ic.label"
          :key="ic.id"
          lazy=""
          :disabled="!sizeChart"
        >
          <size-chart-tabs-mercado-libre
            v-if="isMercadoLibre(ic)"
            :integration-config="ic"
            :size-chart="sizeChart"
          ></size-chart-tabs-mercado-libre>
          <size-chart-tabs-mirakl
            v-else-if="isMirakl(ic)"
            :integration-config="ic"
            :size-chart="sizeChart"
            @change="setSizeChart"
          ></size-chart-tabs-mirakl>
          <size-chart-tabs-rocket-internet
            v-else-if="isRocketInternet(ic)"
            :integration-config="ic"
            :size-chart="sizeChart"
            @change="setSizeChart"
          ></size-chart-tabs-rocket-internet>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import BaseHeader from "../components/BaseHeader.vue";
import SizeChartTabsCentry from "../components/SizeChart/SizeChartTabsCentry.vue";
import SizeChartTabsMercadoLibre from "../components/MercadoLibre/SizeChart/SizeChartTabsMercadoLibre.vue";
import SizeChartTabsMirakl from "../components/SizeChart/SizeChartTabsMirakl.vue";
import SizeChartTabsRocketInternet from "../components/SizeChart/SizeChartTabsRocketInternet.vue";
import INTEGRATION_CONFIGS_FOR_TABS from "../graphql/IntegrationConfigsForTabs.gql";
import SIZE_CHART from "../graphql/SizeChart/SizeChart.gql";

export default {
  name: "SizeChart",
  components: {
    BaseHeader,
    SizeChartTabsCentry,
    SizeChartTabsMercadoLibre,
    SizeChartTabsRocketInternet,
    SizeChartTabsMirakl
  },
  props: {
    sizeChartId: {
      type: String
    }
  },
  mounted() {
    this.getIntegrationConfigs();
    this.getSizeChart();
  },
  data() {
    return {
      selected: 0,
      integrationConfigs: null,
      sizeChart: null
    };
  },
  computed: {
    sizeChartIntegrationConfigs() {
      if (this.integrationConfigs == null) return null;
      return this.integrationConfigs.filter(integrationConfig =>
        this.hasSizeCharts(integrationConfig)
      );
    },
    baseHeaderMap() {
      let map = [this.$route.meta.action];
      if (this.sizeChart) {
        map.push(this.sizeChart.name);
      }
      return map;
    },
    creating() {
      return this.$route.meta.action === "Crear";
    }
  },
  methods: {
    /**
     * Verifica si la integracion puede tener o no GDT asociada
     * @param {Object} integrationConfig
     * @returns {Boolean}
     */
    hasSizeCharts(integrationConfig) {
      return (
        this.isMercadoLibre(integrationConfig) ||
        this.isMirakl(integrationConfig) ||
        this.isRocketInternet(integrationConfig)
      );
    },
    /**
     * Verifica si la integracion es de Mercado Libre
     * @param {Object} integrationConfig
     * @returns {Boolean}
     */
    isMercadoLibre(integrationConfig) {
      return integrationConfig.identifier.startsWith("mercado_libre");
    },
    /**
     * Verifica si la integracion es de Ripley
     * @param {Object} integrationConfig
     * @returns {Boolean}
     */
    isMirakl(integrationConfig) {
      return integrationConfig.identifier.startsWith("ripley");
    },
    /**
     * Verifica si la integracion es de Dafiti
     * @param {Object} integrationConfig
     * @returns {Boolean}
     */
    isRocketInternet(integrationConfig) {
      return (
        integrationConfig.identifier.startsWith("dafiti") &&
        integrationConfig.identifier !== "dafiti_second"
      );
    },
    /**
     * Consulta por todas las integraciones de la cuenta actual
     */
    getIntegrationConfigs() {
      return this.$apollo
        .query({
          query: INTEGRATION_CONFIGS_FOR_TABS
        })
        .then(({ data }) => {
          this.integrationConfigs = data.allIntegrationConfigs;
        });
    },
    /**
     * Maneja evento de cambio de tab
     * @param {Number} newTabIndex
     */
    changeTab(newTabIndex) {
      this.selected = newTabIndex;
    },
    /**
     * Obtiene info de la GDT de Centry si se trata de una edición
     */
    getSizeChart() {
      if (this.$route.meta.action !== "Crear") {
        return this.$apollo
          .query({
            query: SIZE_CHART,
            variables: {
              id: this.sizeChartId
            }
          })
          .then(({ data }) => {
            this.setSizeChart(data.sizeChart);
          });
      }
    },
    /**
     * Guarda GDT en el estado
     * @param {Object} sizeChart
     */
    setSizeChart(sizeChart) {
      this.sizeChart = sizeChart;
    }
  },
  watch: {
    sizeChartId() {
      this.getSizeChart();
    }
  }
};
</script>
