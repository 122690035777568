<template>
  <div>
    <b-dropdown variant="white" no-caret="" offset="-110">
      <template v-slot:button-content>
        <b-icon-three-dots-vertical></b-icon-three-dots-vertical>
      </template>
      <b-dropdown-item
        :to="{
          name: 'ProductDetail',
          params: { id: product.id }
        }"
      >
        Detalle
      </b-dropdown-item>
      <b-dropdown-item
        :to="{
          name: 'ProductEdit',
          params: { id: product.id }
        }"
      >
        Editar
      </b-dropdown-item>
      <b-dropdown-item
        :to="{ name: 'ProductImageManager', query: { productId: product.id } }"
      >
        Imágenes
      </b-dropdown-item>
      <b-dropdown-item :to="'/product/' + product.id + '/inventory'">
        Inventario
      </b-dropdown-item>
      <b-dropdown-item
        :to="{
          name: 'ProductHistories',
          params: { id: product.id }
        }"
      >
        Sincronizaciones
      </b-dropdown-item>
      <b-dropdown-divider></b-dropdown-divider>
      <ApolloMutation
        :mutation="require('../graphql/DeleteProduct.gql')"
        :variables="{ id: product.id }"
        @done="updateDeletedProduct"
        @error="errorMessageDeleting"
      >
        <template slot-scope="{ mutate }">
          <b-dropdown-item @click="deleteIfAccept(mutate)">
            Eliminar
          </b-dropdown-item>
        </template>
      </ApolloMutation>
    </b-dropdown>
  </div>
</template>
<script>
import { centryUrl } from "@/main";
export default {
  name: "ProductsTableRowActions",
  props: {
    product: Object
  },
  data() {
    return {
      centryUrl
    };
  },
  methods: {
    deleteIfAccept(mutate) {
      this.$swal
        .fire({
          title: "Eliminar",
          text:
            'Se eliminará el producto "' +
            this.product.name +
            '" permanentemente ¿Está seguro?',
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si"
        })
        .then(result => {
          if (result.value) {
            mutate();
          }
        });
    },
    updateDeletedProduct(data) {
      if (!data.data.deleteProduct.result) {
        this.errorMessageDeleting(data.data.deleteProduct.error);
      } else {
        this.$emit("deleting");
      }
    },
    errorMessageDeleting(message) {
      if (message) {
        message = "Ha ocurrido un error al eliminar el producto: " + message;
      } else {
        message = "Ha ocurrido un error al eliminar el producto";
      }
      this.$swal.fire({
        icon: "error",
        text: message
      });
    }
  }
};
</script>
