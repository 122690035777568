<template>
  <div>
    <base-header
      title="Días no laborales"
      titleLink="#"
      :map="['Mercado Libre', 'Integraciones']"
      :title-size="12"
    >
    </base-header>
    <mercado-libre-select-integration url-name="NotWorkingDaysList" />
  </div>
</template>
<script>
import BaseHeader from "../../../components/BaseHeader.vue";
import MercadoLibreSelectIntegration from "../../../components/MercadoLibre/SelectIntegration.vue";
export default {
  name: "MercadoLibreMe1Integrations",
  components: {
    BaseHeader,
    MercadoLibreSelectIntegration
  },
  methods: {}
};
</script>
