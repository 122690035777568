<template>
  <b-tr>
    <b-td>{{ spec.name }}</b-td>
    <b-td>{{ homologationsToText(spec.vtexPurposes) }}</b-td>
    <b-td
      ><b-button :to="'/vtex_homologation/' + spec.id + '/edit'"
        >Editar</b-button
      ></b-td
    >
  </b-tr>
</template>
<script>
import { centryUrl } from "@/main";
export default {
  name: "VtexHomologationAttributesRow",
  props: {
    spec: Object
  },
  data() {
    return {
      centryUrl
    };
  },
  methods: {
    /**
     * Retorna como texto la lista de homologaciones que
     * tiene un atributo de categoría
     * @param {Array[Object]} homologationsArray - Arreglo de purposes
     */
    homologationsToText(homologationsArray) {
      let names = (homologationsArray || []).map(h => {
        return h.specName;
      });
      return names.join(", ");
    }
  }
};
</script>

<style scoped>
td {
  cursor: pointer;
}
</style>
