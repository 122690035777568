var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ApolloQuery',{attrs:{"query":require('../graphql/Banners/ActualBanner.gql'),"fetchPolicy":'cache-and-network',"cliendId":"apolloClientCached"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.result.data;
var isLoading = ref.isLoading;
return [(data && data.actualBanner != null && !isLoading)?_c('div',{staticClass:"banner",style:('background: linear-gradient(to right,' +
          data.actualBanner.colorPickLeft +
          ' 0%,' +
          data.actualBanner.colorPickLeft +
          ' 50%,' +
          data.actualBanner.colorPickRight +
          ' 50%, ' +
          data.actualBanner.colorPickRight +
          ' 100% )')},[_c('a',{attrs:{"href":data.actualBanner.redirectUrl,"target":"_blank"}},[_c('b-img',{staticClass:"img-banner",attrs:{"src":data.actualBanner.imageUrl}})],1)]):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }