<template>
  <div>
    <p class="text-justify">
      El prefijo del SKU permite anteponer un texto en los códigos SKU tanto del
      producto como de las variantes que se integren a {{ icName }}.
    </p>
    <p class="text-justify">
      Para entender mejor el efecto de este campo, veamos un ejemplo. Supongamos
      que en el inventario que tenemos cargado en Centry, tenemos un producto y
      sus variantes con la siguiente información:
    </p>
    <b-table-simple bordered small fixed>
      <b-tr>
        <b-th colspan="2">PRODUCTO</b-th>
      </b-tr>
      <b-tr>
        <b-th>Nombre:</b-th>
        <b-td>Polera deportiva</b-td>
      </b-tr>
      <b-tr>
        <b-th>SKU:</b-th>
        <b-td>PROD001</b-td>
      </b-tr>
      <b-tr>
        <b-th colspan="2"><br />VARIANTES</b-th>
      </b-tr>
      <b-tr>
        <b-th>Talla</b-th>
        <b-th>SKU</b-th>
      </b-tr>
      <b-tr>
        <b-th>S</b-th>
        <b-td>PROD001S</b-td>
      </b-tr>
      <b-tr>
        <b-th>M</b-th>
        <b-td>PROD001M</b-td>
      </b-tr>
      <b-tr>
        <b-th>L</b-th>
        <b-td>PROD001L</b-td>
      </b-tr>
    </b-table-simple>

    <p class="text-justify">
      Si el prefijo del SKU lo definimos como <code> demo_</code>, entonces en
      {{ icName }} tendremos que el producto se publicará de la siguiente
      manera:
    </p>
    <b-table-simple bordered small fixed>
      <b-tr>
        <b-th colspan="2">PRODUCTO</b-th>
      </b-tr>
      <b-tr>
        <b-th>Nombre:</b-th>
        <b-td>Polera deportiva</b-td>
      </b-tr>
      <b-tr>
        <b-th>SKU:</b-th>
        <b-td>demo_PROD001</b-td>
      </b-tr>
      <b-tr>
        <b-th colspan="2"><br />VARIANTES</b-th>
      </b-tr>
      <b-tr>
        <b-th>Talla</b-th>
        <b-th>SKU</b-th>
      </b-tr>
      <b-tr>
        <b-th>S</b-th>
        <b-td>demo_PROD001S</b-td>
      </b-tr>
      <b-tr>
        <b-th>M</b-th>
        <b-td>demo_PROD001M</b-td>
      </b-tr>
      <b-tr>
        <b-th>L</b-th>
        <b-td>demo_PROD001L</b-td>
      </b-tr>
    </b-table-simple>

    <p class="text-justify">
      Es importante destacar de que el prefijo del SKU afectará a todos los
      productos que tengas en Centry y que se estén sincronizando con
      {{ icName }}. Si quieres que este prefijo sea aplicado al resto de los
      Marketplaces a los que estás conectado, entonces tendrás que repetir este
      procedimiento en la configuración de cada integración.
    </p>
    <p class="text-justify">
      Esta función es útil si es que en el Marketplace estás teniendo conflictos
      con los SKU, por ejemplo si rechaza la sincronización alegando de que los
      códigos ya existen. Si no es tu caso, entonces es recomendable dejar este
      campo en blanco.
    </p>
  </div>
</template>

<script>
export default {
  name: "HelpSkuPrefixModal",
  props: {
    icName: {
      type: String,
      default: ""
    }
  }
};
</script>

<style scoped>
code {
  font-size: 100%;
}
table {
  margin-bottom: 10px;
}

.table td {
  padding-block: 0px !important;
  padding-left: 0.3rem !important;
  font-weight: 500 !important;
}

p {
  margin-bottom: 10px;
}
</style>
