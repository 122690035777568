<template>
  <b-table-simple>
    <b-thead>
      <b-tr>
        <b-th>Nombre</b-th>
        <b-th>Cron</b-th>
        <b-th>Clase</b-th>
        <b-th>Argumentos</b-th>
        <b-th>Estado</b-th>
        <b-th>Grupo</b-th>
        <b-th>Acciones</b-th>
      </b-tr>
      <b-tr>
        <b-td>
          <b-form-input v-model="nameFilter" @input="updateFilterData" />
        </b-td>
        <b-td></b-td>
        <b-td>
          <b-form-input v-model="classFilter" @input="updateFilterData" />
        </b-td>
        <b-td></b-td>
        <b-td>
          <v-select
            v-model="stateFilter"
            :options="states"
            :reduce="x => x.value"
            @input="updateFilterData"
          />
        </b-td>
        <b-td></b-td>
        <b-td></b-td>
      </b-tr>
    </b-thead>
    <b-tbody>
      <b-tr v-for="(row, index) of filterData" :key="index">
        <b-td>{{ row.name }}</b-td>
        <b-td>{{ row.cron }}</b-td>
        <b-td>{{ row.klass }}</b-td>
        <b-td>{{ row.args }}</b-td>
        <b-td>{{ translateStatus(row.status) }}</b-td>
        <b-td>{{ row.group }}</b-td>
        <b-td>
          <b-dropdown variant="white" no-caret="" offset="-110">
            <template v-slot:button-content>
              <b-icon-three-dots-vertical></b-icon-three-dots-vertical>
            </template>
            <b-dropdown-item @click="() => $emit('edit', row)">
              Editar
            </b-dropdown-item>
            <b-dropdown-item
              @click="disableCron(row)"
              v-if="row.status == 'enabled'"
            >
              Desabilitar
            </b-dropdown-item>
            <b-dropdown-item @click="enableCron(row)" v-else>
              Habilitar
            </b-dropdown-item>
            <b-dropdown-item @click="destroyCron(row)">
              Eliminar
            </b-dropdown-item>
          </b-dropdown>
        </b-td>
      </b-tr>
    </b-tbody>
  </b-table-simple>
</template>
<script>
import DISABLE_SIDEKIQ_CRON from "../../graphql/SidekiqCron/DisableSidekiqCron.gql";
import DESTROY_SIDEKIQ_CRON from "../../graphql/SidekiqCron/DestroySidekiqCron.gql";
import ENABLE_SIDEKIQ_CRON from "../../graphql/SidekiqCron/EnableSidekiqCron.gql";

export default {
  name: "SidekiqCronTable",
  props: {
    originalData: { type: Array, default: () => [] }
  },
  data() {
    return {
      nameFilter: "",
      cronFilter: "",
      classFilter: "",
      stateFilter: "",
      states: [
        { value: "enabled", label: "Habilitado" },
        { value: "disabled", label: "Deshabilitado" }
      ],
      filterData: this.$dup(this.originalData)
    };
  },
  methods: {
    /**
     * Actualiza los datos de la tabla según los filtros
     */
    updateFilterData() {
      this.filterData = this.originalData.filter(row => {
        return (
          (!this.nameFilter.length ||
            row.name
              .toLowerCase()
              .includes(this.nameFilter.toLocaleLowerCase())) &&
          (!this.classFilter.length ||
            row.klass
              .toLocaleLowerCase()
              .includes(this.classFilter.toLocaleLowerCase())) &&
          (!this.stateFilter || row.status == this.stateFilter)
        );
      });
    },
    /**
     * Traduce el estado de la tarea
     * @param {String} status
     * @returns {String}
     */
    translateStatus(status) {
      return status === "enabled" ? "Habilitado" : "Deshabilitado";
    },
    /**
     * Deshabilita la tarea programada
     * @param {Object} row
     */
    disableCron(row) {
      this.$swal
        .fire({
          title: "Deshabilitar tarea programada",
          text: "¿Deseas deshabilitar esta tarea?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si",
          cancelButtonText: "No"
        })
        .then(result => {
          if (result.value) {
            this.$apollo
              .mutate({
                mutation: DISABLE_SIDEKIQ_CRON,
                variables: {
                  name: row.name,
                  group: row.group
                }
              })
              .then(({ data }) => {
                const errors = data.disableSidekiqCron.errors;
                if (errors) {
                  this.$swal.fire({
                    title: "Error",
                    text: errors,
                    icon: "error"
                  });
                  return;
                } else {
                  this.$swal
                    .fire({
                      title: "Tarea deshabilitada",
                      text: "La tarea ha sido deshabilitada",
                      icon: "success"
                    })
                    .then(() => {
                      row.status = "disabled";
                    });
                }
              })
              .catch(() => {
                this.$swal.fire({
                  title: "Error",
                  text: "Ha ocurrido un error al deshabilitar la tarea",
                  icon: "error"
                });
              });
          }
        });
    },
    /**
     * Habilita la tarea programada
     * @param {Object} row
     */
    enableCron(row) {
      this.$swal
        .fire({
          title: "Habilitar tarea programada",
          text: "¿Deseas habilitar esta tarea?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si",
          cancelButtonText: "No"
        })
        .then(result => {
          if (result.value) {
            this.$apollo
              .mutate({
                mutation: ENABLE_SIDEKIQ_CRON,
                variables: {
                  name: row.name,
                  group: row.group
                }
              })
              .then(({ data }) => {
                const errors = data.enableSidekiqCron.errors;
                if (errors) {
                  this.$swal.fire({
                    title: "Error",
                    text: errors,
                    icon: "error"
                  });
                  return;
                } else {
                  this.$swal
                    .fire({
                      title: "Tarea habilitada",
                      text: "La tarea ha sido habilitada",
                      icon: "success"
                    })
                    .then(() => {
                      row.status = "enabled";
                    });
                }
              })
              .catch(() => {
                this.$swal.fire({
                  title: "Error",
                  text: "Ha ocurrido un error al habilitar la tarea",
                  icon: "error"
                });
              });
          }
        });
    },
    /**
     * Elimina la tarea programada
     * @param {Object} row
     */
    destroyCron(row) {
      this.$swal
        .fire({
          title: "Eliminar tarea programada",
          text: "¿Deseas eliminar esta tarea?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si",
          cancelButtonText: "No"
        })
        .then(result => {
          if (result.value) {
            this.$apollo
              .mutate({
                mutation: DESTROY_SIDEKIQ_CRON,
                variables: {
                  name: row.name,
                  group: row.group
                }
              })
              .then(({ data }) => {
                const errors = data.destroySidekiqCron.errors;
                if (errors) {
                  this.$swal.fire({
                    title: "Error",
                    text: errors,
                    icon: "error"
                  });
                  return;
                } else {
                  this.$swal
                    .fire({
                      title: "Tarea eliminada",
                      text: "La tarea ha sido eliminada",
                      icon: "success"
                    })
                    .then(() => {
                      window.location.reload();
                    });
                }
              })
              .catch(() => {
                this.$swal.fire({
                  title: "Error",
                  text: "Ha ocurrido un error al eliminar la tarea",
                  icon: "error"
                });
              });
          }
        });
    }
  }
};
</script>
