<template>
  <div>
    <!-- Loading -->
    <b-spinner
      v-if="isLoading || integrationsLoading"
      label="Spinning"
    ></b-spinner>
    <!-- Result -->
    <div v-else>
      <base-header
        :title="purpose.name || 'Nueva Homologación'"
        title-link="#"
        :title-size="9"
      >
        <template v-slot:actions>
          <b-button-group size="sm">
            <b-button
              variant="outline-info"
              to="/vtex_homologation/vtex_purposes"
            >
              <b-icon-x-circle></b-icon-x-circle> Cancelar
            </b-button>
            <b-button
              variant="info"
              @click="save"
              :disabled="saving || !specificationSelected.name || badSpec"
            >
              <span v-if="saving"
                ><b-spinner label="Spinning"></b-spinner
              ></span>
              <b-icon-cloud-upload></b-icon-cloud-upload>Guardar
            </b-button>
          </b-button-group>
          <p v-if="badSpec" style="color: red">
            Color y talla deben usar especificaciones de sku. Género y garantía
            deben usar especificaciones de producto.
          </p>
        </template>
      </base-header>
      <b-container fluid="">
        <b-form-group label="Modelo" label-for="model">
          <v-select
            id="model"
            :options="modelOptions"
            placeholder="Selecciona un modelo"
            v-model="model"
            :reduce="m => m.value"
          ></v-select>
        </b-form-group>
        <vtex-homologation-specification-select
          @spec-change="specUpdate"
        ></vtex-homologation-specification-select>
        <hr />
        <h3 class="text-success">
          {{ specMessage }}
        </h3>
      </b-container>
    </div>
  </div>
</template>

<script>
import BaseHeader from "@/components/BaseHeader.vue";
import ALL_VTEX_INTEGRATIONS from "@/graphql/AllVtexIntegrations.gql";
import CREATE_VTEX_PURPOSE from "@/graphql/CreateVtexPurpose.gql";
import VtexHomologationSpecificationSelect from "@/components/VtexHomologation/VtexHomologationAttributes/VtexHomologationSpecificationSelect";
import GET_VTEX_SPECIFICATION from "@/graphql/IntegrationConfigVtexGetSpecification.gql";
export default {
  name: "VtexHomologationPurposeForm",
  components: {
    VtexHomologationSpecificationSelect,
    BaseHeader
  },
  props: {
    purposeId: String
  },
  beforeMount() {
    this.getVtexIntegrations().then(() => (this.isLoading = false));
  },
  data() {
    return {
      purpose: {},
      isLoading: true,
      integrationsLoading: true,
      specificationSelected: {},
      integrationConfigId: null,
      model: null,
      modelOptions: [
        { value: "product_color", label: "Color" },
        { value: "product_size", label: "Talla" },
        { value: "product_gender", label: "Género" },
        { value: "product_warranty", label: "Garantía" }
      ],
      allVtexIntegrations: [],
      saving: false,
      badSpec: false
    };
  },
  computed: {
    specMessage() {
      if (Object.keys(this.specificationSelected).length) {
        return (
          "Especificación seleccionada:" +
          this.specificationSelected.name +
          " (Especificación de " +
          (this.specificationSelected.isStockKeepingUnit ? "Sku)" : "Producto)")
        );
      }
      return "";
    }
  },
  methods: {
    /**
     * Obtiene las integraciones que tiene Centry de Vtex,
     * formateandolas para dejarlas en un selector e indica que
     * ya terminó de cargar esta parte.
     */
    getVtexIntegrations() {
      return this.$apollo
        .query({
          query: ALL_VTEX_INTEGRATIONS
        })
        .then(({ data }) => {
          if (data && data.allVtexIntegrationConfigs) {
            this.allVtexIntegrations = data.allVtexIntegrationConfigs.map(
              ic => {
                return { value: ic.id, label: ic.label };
              }
            );
            this.integrationsLoading = false;
          }
        });
    },
    /**
     * Obtiene una especificación en Vtex para guardar el
     * identificador del tipo de dato.
     */
    getSpecification() {
      return this.$apollo
        .query({
          query: GET_VTEX_SPECIFICATION,
          variables: {
            id: this.integrationConfigId,
            specificationId: this.specificationSelected.fieldId
          }
        })
        .then(({ data }) => {
          if (this.$dig(data, "integrationConfigVtex", "getSpecification")) {
            this.specificationSelected["fieldTypeId"] =
              data.integrationConfigVtex.getSpecification.fieldTypeId;
          }
        });
    },
    /**
     * Actualiza la especificación e integración seleccionada en el
     * componente.
     * @param {Object} spec - Especificación seleccionada
     */
    specUpdate(spec) {
      this.specificationSelected = spec["specification"];
      this.integrationConfigId = spec["integrationConfigId"];
    },
    /**
     * Manda a crear el purpose en Centry.
     */
    async save() {
      this.saving = true;
      await this.getSpecification();
      let dataType = this.$options.filters.VtexDataTypeTranslate(
        this.specificationSelected.fieldTypeId
      );
      let create = {
        model: this.model,
        dataType: dataType,
        specId: this.specificationSelected.fieldId.toString(),
        specName: this.specificationSelected.name,
        integrationConfigId: this.integrationConfigId
      };
      this.$apollo
        .mutate({
          mutation: CREATE_VTEX_PURPOSE,
          variables: {
            create: create
          }
        })
        .then(({ data }) => {
          this.alertCreate(data);
        });
    },
    /**
     * Valida si el modelo del purpose puede ser
     * homologado con el tipo de especificación de Vtex:
     * Colores y Tallas solo pueden ser homologados con especificaciones
     * de sku, Género y garantía solo pueden ser homologados con especificaciones
     * de producto.
     */
    validateSpecType() {
      let skuSpec = this.specificationSelected.isStockKeepingUnit;
      let incorrect = skuSpec;
      switch (this.model) {
        case "product_color":
          incorrect = !skuSpec;
          break;
        case "product_size":
          incorrect = !skuSpec;
          break;
      }
      if (this.model && Object.keys(this.specificationSelected).length) {
        this.badSpec = incorrect;
      }
    },
    /**
     * Crea la alerta que indica si el purpose se creó con éxito
     * o no.
     */
    alertCreate(data) {
      let result = data?.createVtexPurpose?.vtexPurpose;
      let title = result ? "Creación existosa" : "Creación Fallida";
      let text = result ? "El destino ha sido creado" : "Hubo un error";
      let icon = result ? "success" : "error";
      this.$swal
        .fire({
          title: title,
          text: text,
          icon: icon
        })
        .then(() => {
          if (result) {
            this.$router.push("/vtex_homologation/vtex_purposes");
          }
        });
      this.saving = false;
    }
  },
  watch: {
    specificationSelected() {
      this.validateSpecType();
    },
    model() {
      this.validateSpecType();
    }
  }
};
</script>

<style scoped>
td {
  cursor: pointer;
}
</style>
