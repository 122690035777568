<template>
  <div>
    <base-header title="Bloqueos" title-link="#">
      <template v-slot:filters>
        <b-button
          v-if="
            $permissionToRead(currentUser, 'blockages') ||
              $companyOwner(currentUser)
          "
          :to="'/blockage/new'"
          variant="outline-info"
          class="mb-2"
        >
          <b-icon-plus></b-icon-plus>
          Nuevo bloqueo
        </b-button>
      </template>
    </base-header>
    <b-container fluid="">
      <b-row>
        <b-col md="12">
          <base-alert-with-count-down
            v-model="showAlert"
            alert-variant="success"
            :dismiss-secs="5"
          >
            {{ alertMessage }}</base-alert-with-count-down
          >
          <blockages-list />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import BaseHeader from "../../components/BaseHeader.vue";
import BlockagesList from "../../components/Blockages/BlockagesList.vue";
import BaseAlertWithCountDown from "../../components/Base/BaseAlertWithCountDown.vue";
import { mapState } from "vuex";
export default {
  name: "Blockages",
  components: {
    BaseHeader,
    BlockagesList,
    BaseAlertWithCountDown
  },
  props: {
    action: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      showAlert: this.action ? true : false
    };
  },
  computed: {
    ...mapState(["currentUser"]),
    userGroup() {
      return this.$userGroup(this.currentUser);
    },
    alertMessage() {
      if (this.action == "created") {
        return "Bloqueo creado correctamente";
      } else {
        return "Bloqueo actualizado exitosamente";
      }
    }
  },
  watch: {
    action(val) {
      this.showAlert = val ? true : false;
    }
  }
};
</script>

<style></style>
