<template>
  <div>
    <b-input-group>
      <b-input-group-prepend is-text>
        <b-icon-search></b-icon-search>
      </b-input-group-prepend>
      <b-form-tags
        :value="phrases"
        separator=","
        remove-on-delete=""
        :placeholder="placeholder"
        @input="$emit('update:phrases', $event)"
      ></b-form-tags>
      <b-input-group-append v-if="showAdvancedButton">
        <b-button v-b-modal.products-filters-modal="" variant="link">
          <b-iconstack>
            <b-icon stacked icon="gear"></b-icon>
            <b-icon
              v-if="hasAdvancedFilterApplied"
              stacked
              icon="exclamation-circle-fill"
              variant="danger"
              shift-h="8"
              shift-v="8"
              scale="0.7"
              v-b-tooltip="'Se están aplicando filtros avanzados'"
            ></b-icon>
          </b-iconstack>
        </b-button>
      </b-input-group-append>
    </b-input-group>

    <b-modal id="products-filters-modal" size="lg" :hide-footer="true">
      <template v-slot:modal-title>
        <h4>Filtro avanzado de productos</h4>
      </template>
      <b-form-group>
        <div>
          <b-row v-if="showCategoriesSelect">
            <b-col md="3">
              <strong>Categorías</strong>
            </b-col>
            <b-col md="9">
              <b-form-group>
                <base-live-select
                  id="category"
                  :value="categoriesSelected"
                  placeholder="Seleccionar"
                  :clearable="false"
                  :multiple="true"
                  @search="updateCategories"
                  @change="$emit('update:categoriesSelected', $event)"
                ></base-live-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="showBrandsSelect">
            <b-col md="3">
              <strong>Marcas</strong>
            </b-col>
            <b-col md="9">
              <b-form-group>
                <base-live-select
                  id="brand"
                  :value="brandsSelected"
                  placeholder="Seleccionar"
                  :clearable="false"
                  :multiple="true"
                  @search="updateBrands"
                  @change="$emit('update:brandsSelected', $event)"
                  :min-search-characters="1"
                ></base-live-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="4" v-if="showActiveCheckbox">
              <b-form-checkbox
                :checked="active"
                name="active"
                @input="$emit('update:active', $event)"
                >Activos</b-form-checkbox
              >
            </b-col>
            <b-col md="4" v-if="showInactiveCheckbox">
              <b-form-checkbox
                :checked="inactive"
                name="inactive"
                @input="$emit('update:inactive', $event)"
                >Inactivos</b-form-checkbox
              >
            </b-col>
            <b-col md="4" v-if="showNoStockCheckbox">
              <b-form-checkbox
                :checked="noStock"
                name="noStock"
                @input="$emit('update:noStock', $event)"
                >Solo sin stock</b-form-checkbox
              >
            </b-col>
          </b-row>
        </div>
      </b-form-group>
    </b-modal>
  </div>
</template>
<script>
import BaseLiveSelect from "./BaseLiveSelect.vue";
import ALL_CATEGORIES from "../graphql/AllCategories.gql";
import ALL_BRANDS from "../graphql/AllBrands.gql";
import { mapState } from "vuex";

export default {
  name: "ProductsFilter",
  props: {
    placeholder: { type: String, default: "Nombre o SKU" },
    // Visibilidad de los campos
    showAdvancedButton: { type: Boolean, default: true },
    showCategoriesSelect: { type: Boolean, default: true },
    showBrandsSelect: { type: Boolean, default: true },
    showActiveCheckbox: { type: Boolean, default: true },
    showInactiveCheckbox: { type: Boolean, default: true },
    showNoStockCheckbox: { type: Boolean, default: true },
    // Valores de los campos
    categoriesSelected: { type: Array, default: () => [] },
    brandsSelected: { type: Array, default: () => [] },
    phrases: { type: Array, default: () => [] },
    active: { type: Boolean, default: true },
    inactive: { type: Boolean, default: true },
    noStock: { type: Boolean, default: false }
  },
  components: {
    BaseLiveSelect
  },
  computed: {
    ...mapState(["currentUser"]),
    /**
     * Indica si se están aplicando filtros especiales en el formulario
     * avanzado. Esto se usa para mostrar un ícono especial en el botón que
     * abre el modal con el buscador avanzado.
     *
     * @return {boolean}
     */
    hasAdvancedFilterApplied() {
      return (
        this.categoriesSelected.length > 0 ||
        this.brandsSelected.length > 0 ||
        !this.active ||
        !this.inactive ||
        this.noStock
      );
    }
  },
  methods: {
    /**
     * Se encarga de filtrar las categorías a mostrar como opciones del select
     * solicitando al servidor un subconjunto que contenga en su nombre el
     * término ingresado por el usuario.
     * @param {String} search término a ser buscado
     * @param {Boolean} loading indica si el request está en curso
     * @param {Function} setOptions función del componente BaseLiveSelect que
     *                              espera recibir un arreglo de opciones.
     */
    async updateCategories(search, loading, setOptions) {
      loading(true);
      this.$apollo
        .query({
          query: ALL_CATEGORIES,
          variables: { companyId: this.currentUser.company.id, name: search }
        })
        .then(async result => {
          if (result?.data?.allCategories?.edges) {
            setOptions(
              result.data.allCategories.edges.map(x => {
                return this.itemForSelect(x.node);
              })
            );
          }
        });
      loading(false);
    },
    /**
     * Se encarga de filtrar las marcas a mostrar como opciones del select
     * solicitando al servidor un subconjunto que contenga en su nombre el
     * término ingresado por el usuario.
     * @param {String} search término a ser buscado
     * @param {Boolean} loading indica si el request está en curso
     * @param {Function} setOptions función del componente BaseLiveSelect que
     *                              espera recibir un arreglo de opciones.
     */
    async updateBrands(search, loading, setOptions) {
      loading(true);
      this.$apollo
        .query({
          query: ALL_BRANDS,
          variables: { companyId: this.currentUser.company.id, name: search }
        })
        .then(async result => {
          if (result?.data?.allBrands?.edges) {
            setOptions(
              result.data.allBrands.edges.map(x => {
                return this.itemForSelect(x.node);
              })
            );
          }
        });
      loading(false);
    },
    /**
     * Transforma un elemento obtenido desde Centry para que sea un item dentro
     * del listado de opciones que maneja el componente BaseLiveSelect.
     *
     * El resultado es un objeto con la siguiente estructura:
     * `{ value: "id", label: "nombre" }`
     *
     * @param {Object} item un objeto de Centry. Se espera que tenga `id` y
     *                      `name`.
     */
    itemForSelect(item) {
      if (item) {
        return { value: item.id, label: item.name };
      }
      return null;
    }
  }
};
</script>
