<template>
  <div>
    <base-header
      title="Tareas asíncronas programadas"
      title-link="/"
      :title-size="12"
    >
      <template v-slot:actions>
        <b-button variant="outline-info" @click="createCron">
          <b-icon-plus-circle /> Crear
        </b-button>
      </template>
    </base-header>
    <ApolloQuery
      :query="
        gql => gql`
          query {
            allSystemSidekiqCrons {
              name
              cron
              klass
              args
              status
              group
            }
          }
        `
      "
      :fetchPolicy="'no-cache'"
      clientId="apolloClientCached"
    >
      <template slot-scope="{ result: { data }, isLoading }">
        <b-spinner v-if="isLoading || !data" label="Spinning" />
        <sidekiq-cron-table
          v-else
          :original-data="data.allSystemSidekiqCrons"
          @edit="editCron"
        />
        <sidekiq-cron-modal :title="modalTitle" :original-data="current" />
      </template>
    </ApolloQuery>
  </div>
</template>
<script>
import BaseHeader from "@/components/BaseHeader";
import SidekiqCronTable from "@/components/SidekiqCron/Table";
import SidekiqCronModal from "@/components/SidekiqCron/Modal";

export default {
  name: "SidekiqCron",
  components: {
    BaseHeader,
    SidekiqCronTable,
    SidekiqCronModal
  },
  data() {
    return {
      modalTitle: "",
      current: null
    };
  },
  methods: {
    /**
     * Abre el modal para crear una tarea asíncrona programada
     */
    createCron() {
      this.modalTitle = "Crear tarea asíncrona programada";
      this.current = null;
      this.$bvModal.show("formModal");
    },
    /**
     * Abre el modal para editar una tarea asíncrona programada
     * @param {Object} originalData
     */
    editCron(originalData) {
      this.modalTitle = "Editar tarea asíncrona programada";
      this.current = originalData;
      this.$bvModal.show("formModal");
    }
  }
};
</script>
