<template>
  <div>
    <base-header
      title="Destino de Homologaciones Vtex"
      title-link="#"
      :title-size="9"
    >
      <template v-slot:actions>
        <b-button
          variant="outline-info"
          to="/vtex_homologation/vtex_purposes/form"
          size="xl"
        >
          <b-icon-plus></b-icon-plus>
          Nuevo Destino
        </b-button>
      </template>
    </base-header>
    <ApolloQuery
      :query="require('@/graphql/AllVtexPurposes.gql')"
      :variables="queryVariables('')"
      fetchPolicy="no-cache"
    >
      <template slot-scope="{ result: { error, data }, query, isLoading }">
        <!-- Loading -->
        <b-spinner v-if="isLoading" label="Spinning"></b-spinner>
        <!-- Error -->
        <b-alert v-else-if="error" show="" variant="danger">
          Ha ocurrido un error
        </b-alert>
        <!-- Result -->
        <b-table-simple v-if="data && data.allVtexPurposes.edges" hover>
          <b-thead>
            <b-tr>
              <b-th>Atributo</b-th>
              <b-th>Modelo</b-th>
              <b-th>Tipo de Dato</b-th>
              <b-th>Integración</b-th>
              <b-th></b-th>
            </b-tr>
            <b-tr>
              <b-th
                ><b-form-group>
                  <b-form-input
                    v-model="categoryNameSelected"
                    placeholder="Atributo"
                    size="sm"
                  ></b-form-input></b-form-group
              ></b-th>
              <b-th></b-th>
              <b-th></b-th><b-th></b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-if="!data.allVtexPurposes.edges.length">
              <b-td colspan="4">No tienes Destinos.</b-td>
            </b-tr>
            <b-tr
              v-else
              v-for="spec of data.allVtexPurposes.edges"
              :key="spec.node.id"
            >
              <b-td>{{ spec.node.specName }}</b-td>
              <b-td>{{ spec.node.model | CentryClassTranslate }}</b-td>
              <b-td>{{ spec.node.dataType | ColumnTypeTranslate }}</b-td>
              <b-td>{{ spec.node.integrationConfig.label }}</b-td>
              <b-td
                ><b-dropdown variant="white" no-caret="" offset="-110">
                  <template v-slot:button-content>
                    <b-icon-three-dots-vertical></b-icon-three-dots-vertical>
                  </template>
                  <b-dropdown-item @click="deleteIfAccept(spec, query)">
                    Eliminar
                  </b-dropdown-item></b-dropdown
                ></b-td
              >
            </b-tr>
          </b-tbody>
        </b-table-simple>
        <b-button
          v-if="$dig(data, 'allVtexPurposes', 'pageInfo', 'hasNextPage')"
          class="m-2 float-right"
          @click="seeMore(query, data.allVtexPurposes.pageInfo.endCursor)"
        >
          Ver más
        </b-button>
        <b-spinner
          v-if="seeMoreLoading"
          label="Spinning"
          class="m-2 float-right"
        ></b-spinner>
      </template>
    </ApolloQuery>
  </div>
</template>

<script>
import BaseHeader from "@/components/BaseHeader.vue";
import DELETE_VTEX_PURPOSE from "@/graphql/DeleteVtexPurpose.gql";
import { mapMutations, mapState } from "vuex";

export default {
  name: "VtexHomologationPurposes",
  components: { BaseHeader },
  computed: {
    ...mapState(["filters"])
  },
  data() {
    return {
      centryInfo: [],
      seeMoreLoading: false,
      categoryNameSelected: null,
      query: null
    };
  },
  methods: {
    ...mapMutations(["setFilter"]),
    /**
     * Retorna un objeto que contiene los filtros para
     * la query de los purposes.
     * @param {String} cursor - Desde donde se consultará la lista
     */
    queryVariables(cursor) {
      const variables = {};
      if (this.filters.name) {
        variables.name = this.filters.name;
      }
      if (cursor) {
        variables.cursor = cursor;
      }
      variables.limit = 50;
      variables.order = "asc";
      variables.models = [
        "product_color",
        "product_warranty",
        "product_gender",
        "product_size"
      ];
      return variables;
    },
    /**
     * Trae más resultados de la query de purposes desde el cursor
     * indicado
     * @param {Query} query - query que se utilizará para hacer la consulta
     * @param {String} cursor - Desde donde se consultará la lista
     */
    async seeMore(query, cursor) {
      this.seeMoreLoading = true;
      await query.fetchMore({
        variables: this.queryVariables(cursor),
        updateQuery: (prev, { fetchMoreResult }) => {
          if (
            !fetchMoreResult ||
            !fetchMoreResult.allVtexPurposes ||
            fetchMoreResult.allVtexPurposes.edges.length === 0
          ) {
            return prev;
          }
          const updated = Object.assign({}, this.$dup(prev));
          updated.allVtexPurposes.pageInfo =
            fetchMoreResult.allVtexPurposes.pageInfo;
          updated.allVtexPurposes.edges.push(
            ...fetchMoreResult.allVtexPurposes.edges
          );
          this.seeMoreLoading = false;
          return updated;
        }
      });
    },
    /**
     * Manda a eliminar el purpose si es que el usuario acepta
     * el dialogo
     * @param {Object} spec - Purpose que se quiere eliminar.
     * @param {Query} query - Query del listado de purposes para recargarlo.
     */
    deleteIfAccept(purpose, query) {
      this.$swal
        .fire({
          title: "Eliminar",
          text:
            'Se eliminará la homologación "' +
            purpose.node.specName +
            '" permanentemente ¿Está seguro?',
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si"
        })
        .then(result => {
          if (result.value) {
            this.deletePurpose(query, purpose.node.id);
          }
        });
    },
    /**
     * Envia la mutación para eliminar el purpose en Centry
     * @param {Query} query - Query del listado de purposes para recargarlo.
     * @param {String} id - id del purpose a eliminar.
     */
    deletePurpose(allQuery, id) {
      this.$apollo
        .mutate({
          mutation: DELETE_VTEX_PURPOSE,
          variables: {
            id: id
          }
        })
        .then(({ data }) => {
          this.errorMessageDeleting(data.deleteVtexPurpose.result);
        });
    },
    /**
     * Muestra un error dependiendo si la eliminación fue
     * exitosa o no
     * @param {Boolean} result - Resultado de la eliminación del purpose
     */
    errorMessageDeleting(result) {
      let message;
      if (result) {
        message = "El destino fue eliminado correctamente.";
      } else {
        message = "Ha ocurrido un error al eliminar la homologación";
      }
      this.$swal.fire({
        icon: result ? "success" : "error",
        text: message
      });
    }
  },
  watch: {
    categoryNameSelected(newValue) {
      this.setFilter({ name: newValue });
    }
  }
};
</script>
