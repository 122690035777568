var templateObject = Object.freeze(["\n              query {\n                allSystemSidekiqGroupIndexes\n              }\n            "]);
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"formModal","cancel-disabled":"","size":"lg"},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('h4',[_vm._v(_vm._s(_vm.title))])]},proxy:true},{key:"modal-footer",fn:function(ref){
var ok = ref.ok;
var cancel = ref.cancel;
return [_c('b-button',{attrs:{"variant":"info"},on:{"click":function () { return _vm.save(ok); }}},[_vm._v(" Guardar ")]),_c('b-button',{attrs:{"variant":"outline-info"},on:{"click":cancel}},[_vm._v(" Cancelar ")])]}}])},[[_c('div',[(!!_vm.originalData)?_c('span',[_vm._v(_vm._s(_vm.originalData.name))]):_c('b-form-group',{attrs:{"label":"Nombre"}},[_c('b-form-input',{model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('b-form-group',{attrs:{"label":"Cron"}},[_c('b-form-input',{model:{value:(_vm.cron),callback:function ($$v) {_vm.cron=$$v},expression:"cron"}})],1),_c('b-form-group',{attrs:{"label":"Clase"}},[_c('b-form-input',{model:{value:(_vm.klass),callback:function ($$v) {_vm.klass=$$v},expression:"klass"}})],1),_c('b-form-group',{attrs:{"label":"Argumentos"}},[_c('b-form-input',{model:{value:(_vm.args),callback:function ($$v) {_vm.args=$$v},expression:"args"}})],1),(!!_vm.originalData)?_c('span',[_vm._v("Grupo "+_vm._s(_vm.originalData.group))]):_c('b-form-group',{attrs:{"label":"Grupo"}},[_c('ApolloQuery',{attrs:{"query":function (gql) { return gql(templateObject); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var data = ref.result.data;
            var isLoading = ref.isLoading;
return [(isLoading || !data)?_c('b-spinner',{attrs:{"label":"Spinning"}}):_c('v-select',{attrs:{"options":data.allSystemSidekiqGroupIndexes.map(function (x) { return ({
                  value: x,
                  label: x
                }); }),"placeholder":"Seleccionar","clearable":false,"reduce":function (x) { return x.value; }},model:{value:(_vm.group),callback:function ($$v) {_vm.group=$$v},expression:"group"}})]}}])})],1)],1)],_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }