<template>
  <div>
    <b-alert
      v-if="!allowSave || error || success"
      show=""
      :variant="alertVariant"
      dismissible
      @dismissed="success = false"
    >
      {{ alertMessage }}
    </b-alert>
    <b-row v-if="loading">
      <b-col v-for="i in 6" :key="i" cols="3" class="mb-5">
        <b-skeleton
          animation="wave"
          type="input"
          :width="`${80 - Math.floor(Math.random() * 20)}%`"
        />
      </b-col>
    </b-row>
    <div v-else align-h="between">
      <b-row>
        <b-col xl="10" cols="12">
          <integration-configs-b-sale-integration-data-accordion
            :original-data="originalData"
            :ic="ic"
            v-model="value"
            @change="updateChanged"
            :allow-save="allowSave"
            @success-save-client="successSaveClient"
            :use="use"
          />
        </b-col>
        <b-col xl="2" cols="12">
          <b-button
            variant="info"
            :disabled="!allowSave || !changed || saving"
            :title="titleSave"
            @click="save"
            class="mb-3 w-100"
          >
            <span v-if="saving"><b-spinner label="Spinning"></b-spinner></span>
            <span v-else>Guardar</span>
          </b-button>
          <b-button
            variant="outline-info"
            class="w-100 px-0"
            :disabled="!changed || saving"
            @click="resetData()"
            v-if="allowSave"
          >
            <span>Restablecer</span>
          </b-button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import IntegrationConfigsBSaleIntegrationDataAccordion from "./IntegrationDataAccordion.vue";
import INTEGRATION_CONFIG_DATA from "../../../graphql/IntegrationConfig/BSale/IntegrationData.gql";
import UPDATE_INTEGRATION_CONFIG_BSALE from "../../../graphql/IntegrationConfig/BSale/UpdateIntegrationConfigBSale.gql";

import { mapState, mapMutations } from "vuex";
export default {
  name: "IntegrationConfigsBSaleIntegrationData",
  components: {
    IntegrationConfigsBSaleIntegrationDataAccordion
  },
  props: {
    ic: Object,
    visible: Boolean
  },
  data() {
    return {
      originalData: {
        generalDocumentData: {},
        productData: {},
        salesDocumentData: {},
        otherData: {}
      },
      value: {},
      changed: false,
      loading: true,
      error: false,
      errorMessage: "",
      success: false,
      saving: false,
      use: null
    };
  },
  mounted() {
    this.loadPreviousData();
  },
  computed: {
    ...mapState(["currentUser"]),
    titleSave() {
      if (!this.allowSave) {
        return "No tiene permitido modificar estos datos";
      }
      return this.changed ? "" : "No hay cambios";
    },
    /**
     * Indica si tiene o no permiso para modificar
     * los datos de la integración
     * @return {Boolean}
     */
    allowSave() {
      const companyUserGroup = this.$userGroup(this.currentUser);
      return (
        this.currentUser.role === 0 ||
        this.$ifNull(this.$dig(companyUserGroup, "integrations"), 0) & 4
      );
    },
    alertVariant() {
      if (!this.allowSave) {
        return "warning";
      }
      return this.error ? "danger" : "success";
    },
    alertMessage() {
      if (!this.allowSave) {
        return "No tienes permisos para modificar este recurso, el formulario es de solo lectura";
      }
      return this.error
        ? "Ha ocurrido un error guardando los datos: " + this.errorMessage
        : "Guardado exitosamente";
    }
  },
  methods: {
    ...mapMutations(["setIntegrationConfigChanged"]),
    /**
     * Carga la info previa de la pestaña
     */
    loadPreviousData() {
      this.loading = true;
      this.$apollo
        .query({
          query: INTEGRATION_CONFIG_DATA,
          variables: {
            integrationConfigId: this.ic.id
          },
          fetchPolicy: "no-cache"
        })
        .then(({ data }) => {
          this.setData(data.integrationConfigBSale);
          this.loading = false;
        });
    },
    /**
     * Setea los datos en los objectos
     * originalData y value
     * @param {Object} integrationData
     */
    setData(integrationData) {
      const data = {
        generalDocumentData: this.extractGeneralData(integrationData),
        productData: this.extractProductData(integrationData),
        salesDocumentData: this.extractSalesDocumentData(integrationData),
        otherData: this.extractOtherData(integrationData)
      };
      this.use = integrationData.use;
      this.originalData = data;
      this.value = this.$dup(data);
    },
    /**
     * Extrae la información del tab
     * "generalData" desde toda la info del integrationConfig
     * @param {Object} integrationData
     * @returns {Object}
     */
    extractGeneralData(integrationData) {
      const keys = [
        "doNotCreateDocumentsBefore",
        "formatRut",
        "customPaymentTypes",
        "sendEmailToClientOnDocument",
        "officeId",
        "documentCreationRequirements",
        "saveDocumentsInCentryAs",
        "strictStockValidation",
        "billingLastNameAsActivity",
        "defaultClientId",
        "useDefaultClient"
      ];
      return this.$only(integrationData, keys);
    },
    /**
     * Extrae la información del tab
     * "productData" desde toda la info del integrationConfig
     * @param {Object} integrationData
     * @returns {Object}
     */
    extractProductData(integrationData) {
      const keys = [
        "priceListNormal",
        "priceListDiscount",
        "productBehavior",
        "priceDiscountThreshold",
        "productSku",
        "inactiveNewProductsBehavior",
        "unlimitedStockBehavior"
      ];
      return this.$only(integrationData, keys);
    },
    /**
     * Extrae la información del tab
     * "salesDocumentData" desde toda la info del integrationConfig
     * @param {Object} integrationData
     * @returns {Object}
     */
    extractSalesDocumentData(integrationData) {
      const keys = [
        "typeDocument",
        "createDocumentsWhenOrderStateGte",
        "clientCriteria",
        "clientInvoiceCriteria",
        "typeDocumentInvoice",
        "autoDispatch",
        "clientInDocument",
        "taxesInDocument",
        "discountVatOnDocuments",
        "autoInformToSii",
        "createDocumentsWhenAmountsMatch",
        "referencesInDocument"
      ];
      const subObject = this.$only(integrationData, keys);
      subObject.taxesInDocument = this.$ifNull(subObject.taxesInDocument, []);
      return subObject;
    },
    /**
     * Extrae la información del tab
     * "otherData" desde toda la info del integrationConfig
     * @param {Object} integrationData
     * @returns {Object}
     */
    extractOtherData(integrationData) {
      const keys = ["typeDebit", "typeReturn", "typeCancel"];
      return this.$only(integrationData, keys);
    },
    /**
     * Actualiza el booleano
     * changed
     * @param {Boolean} newVal
     */
    updateChanged(newVal) {
      this.changed = newVal;
    },
    /**
     * Obtiene un hash con todos los
     * datos que han sido cambiados en el formulario
     * @returns {Object}
     */
    getChanges() {
      return Object.assign(
        {},
        this.$objectDiff(
          this.originalData.generalDocumentData,
          this.value.generalDocumentData
        ),
        this.$objectDiff(this.originalData.productData, this.value.productData),
        this.$objectDiff(
          this.originalData.salesDocumentData,
          this.value.salesDocumentData
        ),
        this.$objectDiff(this.originalData.otherData, this.value.otherData)
      );
    },
    /**
     * Envia la mutacion para guardar los nuevos
     * valores de vinculación de la cuenta
     */
    save() {
      const changes = this.getChanges();
      if (Object.keys(changes).length === 0) {
        return;
      }
      this.saving = true;
      this.error = false;
      this.success = false;
      this.errorMessage = null;
      this.$apollo
        .mutate({
          mutation: UPDATE_INTEGRATION_CONFIG_BSALE,
          variables: {
            integrationConfigId: this.ic.id,
            patch: changes
          }
        })
        .then(({ data }) => {
          this.saving = false;
          if (data?.updateIntegrationConfigBSale?.result) {
            this.errorMessage = null;
            this.error = false;
            this.success = true;
            this.originalData = this.$dup(this.value);
          } else {
            this.errorMessage = data?.updateIntegrationConfigBSale?.error;
            this.error = true;
          }
        })
        .catch(e => {
          this.saving = false;
          this.errorMessage = e;
          this.error = true;
        });
    },
    /**
     * Se encarga de resetear los datos
     */
    resetData() {
      this.loading = true;
      this.errorMessage = null;
      this.error = false;
      this.success = false;
      this.loading = false;
      this.value = this.$dup(this.originalData);
    },
    /**
     * Se encarga de recibir evento y
     *  mostrar en pantalla que se guardo correctamente el cliente por defecto
     */
    successSaveClient() {
      this.success = true;
    }
  },
  watch: {
    changed(val) {
      this.setIntegrationConfigChanged(val);
    },
    visible(val) {
      if (val) {
        this.resetData();
      }
    }
  }
};
</script>
