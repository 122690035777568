<template>
  <div producto="product-to-use-table">
    <b-alert v-model="error1" variant="danger" dismissible>
      No existen productos filtrados
    </b-alert>
    <b-alert v-model="error2" variant="danger" dismissible>
      Se debe seleccionar al menos un producto
    </b-alert>
    <vue-bootstrap4-table
      :rows="rows"
      :columns="columns"
      :actions="actions"
      :config="config"
      @on-download-selected="onDownloadSelected"
      @on-download-all="onDownloadAll"
      ref="myvuetable"
    >
      <template slot="paginataion-previous-button">
        Anterior
      </template>
      <template slot="paginataion-next-button">
        Siguiente
      </template>
      <template slot="pagination-info" slot-scope="props">
        | Total: {{ props.originalRowsLength }} <br />
        | Filtrado: {{ props.filteredRowsLength }} <br />
      </template>
      <template slot="selected-rows-info" slot-scope="props">
        Seleccionados: {{ props.selectedItemsCount }}
      </template>

      <template slot="sort-asc-icon">
        <b-icon icon="arrow-up"></b-icon>
      </template>
      <template slot="sort-desc-icon">
        <b-icon icon="arrow-down"></b-icon>
      </template>
      <template slot="no-sort-icon">
        <b-icon icon="arrow-up"></b-icon>
        <b-icon icon="arrow-down"></b-icon>
      </template>
    </vue-bootstrap4-table>
  </div>
</template>

<script>
import "lodash";
import "bootstrap";
import VueBootstrap4Table from "vue-bootstrap4-table";
import { mapMutations } from "vuex";

export default {
  name: "ProductToUseTable",
  data: function() {
    return {
      selected_items: [],
      error1: false,
      error2: false,
      columns: [
        {
          label: "Sku",
          name: "sku",
          filter: {
            type: "simple",
            placeholder: "Sku..."
          },
          sort: true
        },
        {
          label: "Producto",
          name: "producto",
          filter: {
            type: "simple",
            placeholder: "Producto..."
          },
          sort: true
        },
        {
          label: "Marca",
          name: "marca",
          filter: {
            type: "simple",
            placeholder: "Marca..."
          },
          sort: true
        },
        {
          label: "Categoria",
          name: "categoria",
          filter: {
            type: "simple",
            placeholder: "Categoría..."
          },
          sort: true
        },
        {
          label: "Precio original",
          name: "original_price",
          filter: {
            type: "simple",
            placeholder: "Precio..."
          },
          sort: true
        }
      ],
      actions: [
        {
          btn_text: "Utilizar solo productos seleccionados",
          event_name: "on-download-selected",
          event_payload: {
            msg: "Agregar productos a promoción "
          },
          class: "mx-2 btn-secondary"
        },
        {
          btn_text: "Utilizar todos los productos obtenidos",
          event_name: "on-download-all",
          event_payload: {
            msg: "Agregar productos a promoción "
          }
        }
      ],
      config: {
        pagination: true,
        pagination_info: true,
        num_of_visibile_pagination_buttons: 7,
        per_page: 15,
        checkbox_rows: true,
        highlight_row_hover: true,
        rows_selectable: true,
        multi_column_sort: false,
        highlight_row_hover_color: "lightblue",
        card_mode: false,
        selected_rows_info: true,
        per_page_options: [10, 30, 50, 100],
        global_search: { visibility: false },
        show_reset_button: false,
        show_refresh_button: false,

        classes: {
          table: "table-bordered table-striped"
        }
      }
    };
  },
  components: {
    VueBootstrap4Table
  },
  props: {
    products: {
      type: Array,
      default: null
    },
    selectedProducts: Array,
    mirror: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    rows() {
      return this.products ? this.products : [];
    }
  },
  methods: {
    ...mapMutations(["setSelectedProducts"]),
    /**
     * Actualiza los productos seleccionados y
     * se emite el evento 'download'
     * @method
     */
    async onDownloadSelected(payload) {
      if (payload?.selectedItems?.length) {
        await this.setSelectedProducts(payload.selectedItems);
        this.$emit("download", payload?.selectedItems?.length);
      } else {
        this.error2 = true;
      }
    },
    /**
     * Marca todos los productos como productos seleccionados
     * y se emite el evento 'download'
     * @method
     */
    async onDownloadAll() {
      if (this.products.length > 0) {
        await this.setSelectedProducts(this.products);
        this.$emit("download", this.products.length);
      } else {
        this.error1 = true;
      }
    }
  }
};
</script>

<style scoped>
.table td {
  height: 0;
}
.table-active,
.table-active > td,
.table-active > th {
  background-color: rgba(0, 0, 0, 0);
}
</style>
