<template>
  <div>
    <b-row>
      <b-col>
        <b-form-group>
          <b-form-checkbox v-model="activeClientsCluster">
            Cluster de clientes
          </b-form-checkbox>

          <v-select
            v-model="clusterOperator"
            :options="clusterOperatorOptions"
            :disabled="!activeClientsCluster"
            :clearable="false"
          ></v-select>

          <b-row v-for="(expression, index) in clusterExpressions" :key="index">
            <b-col>
              <b-form-group label="Tag">
                <b-form-input
                  v-model="clusterExpressions[index]"
                  :disabled="!activeClientsCluster"
                >
                </b-form-input>
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group label="Eliminar">
                <b-button
                  variant="outline-info"
                  @click="deleteClusterExpression(index)"
                  :disabled="!activeClientsCluster"
                >
                  <b-icon-x-circle></b-icon-x-circle>
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>

          <b-button
            v-show="activeClientsCluster"
            variant="outline-info"
            @click="addClusterExpression"
          >
            <b-icon-plus></b-icon-plus> Agregar Expresión
          </b-button>
        </b-form-group>
      </b-col>

      <b-col v-if="['regular', 'progressive'].indexOf(promotion.type) != -1">
        <b-form-group>
          <b-form-checkbox v-model="activeCountries">
            Países de entrega
          </b-form-checkbox>

          <b-spinner v-if="loadingCountries" label="Spinning"></b-spinner>
          <v-select
            v-else
            multiple
            v-model="countries"
            :options="countriesOptions"
            :disabled="!activeCountries"
          ></v-select>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import INTEGRATION_CONFIG_VTEX_ALL_DOCKS_COUNTRIES from "@/graphql/IntegrationConfigVtexAllDocksCountries.gql";

const clusterOperatorOptions = [
  { value: "all", label: "Todos los siguientes" },
  { value: "Any", label: "Al menos uno de los siguientes" }
];

export default {
  name: "VtexBasePromotionClusterAndCountries",
  props: {
    value: {
      type: Object,
      required: true
    },
    vtexIntegrationConfigId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      promotion: this.value,
      activeClientsCluster: this.value.clusterExpressions.length > 0,
      clusterOperator: this.clusterOperatorByValue(this.value.clusterOperator),
      clusterOperatorOptions: clusterOperatorOptions,
      clusterExpressions: this.value.clusterExpressions,
      activeCountries:
        !!this.value.countries && this.value.countries.length > 0,
      countries: [],
      loadingCountries: true
    };
  },
  /**
   * Se obtienen los datos de paises disponibles en Vtex.
   * Los datos se guardan en los selectores correspondientes de la vista.
   * Tambien se asignan los valores seleccionados de la promoción a los de la vista.
   */
  async mounted() {
    this.getCountries().then(() => {
      this.setSelectedCountries();
    });
  },
  methods: {
    /**
     * Revisa que opción del selector de operadores del cluster de clientes utilizar.
     * @param {String} value
     */
    clusterOperatorByValue(value) {
      return clusterOperatorOptions.find(option => option.value === value);
    },
    /**
     * Añade una nueva expresión para el cluster de clientes de la promoción.
     */
    addClusterExpression() {
      this.clusterExpressions.push("");
    },
    /**
     * Elimina una expresión para el cluster de clientes de la promoción.
     * @param {Number} index
     */
    deleteClusterExpression(index) {
      this.clusterExpressions.splice(index, 1);
    },
    /**
     * Obtiene los paises disponibles en Vtex.
     */
    async getCountries() {
      await this.$apollo
        .query({
          query: INTEGRATION_CONFIG_VTEX_ALL_DOCKS_COUNTRIES,
          variables: {
            id: this.vtexIntegrationConfigId
          }
        })
        .then(({ data }) => {
          this.countriesOptions = data.integrationConfigVtex.allDocksCountries.map(
            country => ({
              label: country.name,
              value: country.acronym
            })
          );
          this.loadingCountries = false;
        });
    },
    /**
     * Revisa y selecciona los paises seleccionados en la promoción.
     */
    setSelectedCountries() {
      if (this.value.countries) {
        this.countries = this.value.countries.map(country => {
          return this.countriesOptions.find(option => option.value === country);
        });
      }
    }
  },
  watch: {
    value(newValue) {
      this.promotion = newValue;
    },
    promotion: {
      handler(newValue) {
        this.$emit("input", newValue);
      },
      deep: true
    },
    activeClientsCluster(newValue) {
      if (newValue) {
        this.promotion.clusterOperator = this.clusterOperator.value;
        this.promotion.clusterExpressions = this.clusterExpressions;
      } else {
        this.promotion.clusterOperator = "all";
        this.promotion.clusterExpressions = [];
      }
    },
    clusterOperator(newValue) {
      this.promotion.clusterOperator = newValue.value;
    },
    clusterExpressions() {
      this.promotion.clusterExpressions = this.clusterExpressions;
    },
    activeCountries(newValue) {
      if (newValue) {
        this.promotion.countries = this.countries.map(country => {
          return country.value;
        });
      } else {
        this.promotion.countries = [];
      }
    },
    countries(newValue) {
      this.promotion.countries = newValue.map(country => {
        return country.value;
      });
    }
  }
};
</script>
