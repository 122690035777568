<template>
  <b-modal ref="modal" :title="title" lazy>
    <div v-if="hasSolution">
      <h5 class="mb-2">
        Hay columnas que usan integraciones que fueron eliminadas. Debes
        actualizar las columnas para poder utilizar este template:
      </h5>
      <b-table-simple>
        <b-thead>
          <b-tr>
            <b-th>Columna</b-th>
            <b-th>Integración</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="templateParam of templateParams" :key="templateParam.id">
            <b-td>
              {{ templateParam.param.name }}
            </b-td>
            <b-td>
              <span v-if="!withErrorTemplateParams[templateParam.id]">
                {{ configForTemplateParam(templateParam) }}
              </span>
              <b-form-group
                v-else
                invalid-feedback="Debes completar este campo"
                :state="!!correctIntegration[templateParam.id]"
              >
                <v-select
                  :options="optionsForTemplateParam(templateParam)"
                  v-model="correctIntegration[templateParam.id]"
                  placeholder="Selecciona una integración"
                ></v-select>
              </b-form-group>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
    <div v-else>
      <h5>
        El template que seleccionaste usa integraciones que han sido eliminadas.
        Debes elegir otro template para continuar
      </h5>
    </div>
    <template v-slot:modal-footer="{ cancel }">
      <b-skeleton type="button" v-if="saving"></b-skeleton>
      <b-button
        v-else-if="hasSolution"
        variant="info"
        @click="saveTemplate"
        :disabled="anyEmpty"
        :title="anyEmpty ? 'Debes completar todos los campos' : ''"
      >
        Actualizar Template
      </b-button>
      <b-button @click="cancel">
        {{ cancelText }}
      </b-button>
    </template>
  </b-modal>
</template>
<script>
import UPDATE_TEMPLATE from "../../graphql/BulkUpload/UpdateTemplate.gql";
import ALL_PARAMS_FOR_MATCH from "../../graphql/AllParamsForMatch.gql";
export default {
  name: "BulkUploadRepairTemplateIntegrationsModal",
  props: {
    template: Object,
    /**
     * Objeto {id de PublicApplicationInformation => [integrationConfigs]}
     * para obtener las integrationConfig válidas por integración
     */
    applicationIntegrationsMap: Object
  },
  data() {
    return { correctIntegration: {}, saving: false, params: null };
  },
  computed: {
    /**
     * Entrega los templateParams con configs inválidas
     * Map de: {templateParam.id => opciones}
     * @returns {Object}
     */
    withErrorTemplateParams() {
      if (!this.template) return {};
      const hash = {};
      this.template.sortedParams.forEach(templateParam => {
        const param = this.params[templateParam.param.id];
        if (param.publicApplicationInformation && !templateParam.config) {
          hash[templateParam.id] = this.optionsForTemplateParam(templateParam);
        }
      });
      return hash;
    },
    /**
     * Entrega false si no hay solución para el template
     * porque hay columnas que no tienen opciones de integraciones disponibles
     * @returns {Boolean}
     */
    hasSolution() {
      if (!this.template) return false;
      return !Object.keys(this.withErrorTemplateParams).find(
        templateParamId =>
          !this.optionsForTemplateParam(
            this.template.sortedParams.find(tp => tp.id === templateParamId)
          ).length
      );
    },
    templateParams() {
      if (!this.template) return [];
      return this.template.sortedParams;
    },
    title() {
      if (this.hasSolution) {
        return "Corrige tu template";
      }
      return "Debes elegir otro template";
    },
    cancelText() {
      if (this.hasSolution) {
        return "Usar otro template";
      }
      return "Entendido";
    },
    /**
     * Indica si hay o no algún campo obligatorio
     * sin rellenar
     * @returns {Boolean}
     */
    anyEmpty() {
      let anyEmpty = false;
      Object.keys(this.withErrorTemplateParams).forEach(templateParamId => {
        if (!this.correctIntegration[templateParamId]) {
          anyEmpty = true;
          return;
        }
      });
      return anyEmpty;
    }
  },
  methods: {
    /**
     * Entrega el listado de opciones para un templateParam
     * para el v-select
     * @param {Object} templateParam
     * @returns {Array<Object>}
     */
    optionsForTemplateParam(templateParam) {
      const param = this.params[templateParam.param.id];
      return this.$ifNull(
        this.applicationIntegrationsMap[param.publicApplicationInformation.id],
        []
      ).map(ic => {
        return {
          label: ic.label,
          value: ic.id
        };
      });
    },
    /**
     * Entrega el label de la integrationConfig asociado al
     * templateParam
     * @param {Object} templateParam
     * @returns {String}
     */
    configForTemplateParam(templateParam) {
      const param = this.params[templateParam.param.id];
      if (param.publicApplicationInformation) {
        return this.$dig(templateParam.config.label);
      }
      return " --- No aplica ---";
    },
    /**
     * Muestra el modal
     */
    showModal() {
      this.$refs["modal"].show();
      return this.$getAllPages(ALL_PARAMS_FOR_MATCH, {}, "allParams").then(
        array => {
          const params = {};
          array.forEach(paramNode => {
            params[paramNode.node.id] = paramNode.node;
          });
          this.params = params;
        }
      );
    },
    /**
     * Entrega el template recalculado
     * @returns {Object}
     */
    recalculateTemplate() {
      const template = this.$dup(this.template);
      template.sortedParams.forEach(templateParam => {
        if (this.withErrorTemplateParams[templateParam.id]) {
          templateParam.config = {
            id: this.correctIntegration[templateParam.id].value,
            label: this.correctIntegration[templateParam.id].label
          };
        }
      });
      return template;
    },
    /**
     * Entrega el body para guardar
     * @returns {Object}
     */
    templateToSave() {
      const toSave = { templateParams: [] };
      this.template.sortedParams.forEach(templateParam => {
        const newTemplateParam = { id: templateParam.id };
        if (this.withErrorTemplateParams[templateParam.id]) {
          newTemplateParam.configId = this.correctIntegration[
            templateParam.id
          ].value;
        }
        toSave.templateParams.push(newTemplateParam);
      });
      return toSave;
    },
    /**
     * Guarda el template, cierra el modal y emite el cambio
     */
    saveTemplate() {
      this.saving = true;
      const toSave = this.templateToSave();
      this.$apollo
        .mutate({
          mutation: UPDATE_TEMPLATE,
          variables: { id: this.template.id, patch: toSave }
        })
        .then(({ data }) => {
          if (data.updateTemplate.result) {
            this.$emit("repair", this.recalculateTemplate());
          } else {
            this.$emit("error", data.errors);
          }
        })
        .catch(err => {
          this.$emit("error", err.message);
        })
        .finally(() => {
          this.saving = false;
          this.$refs["modal"].hide();
        });
    }
  }
};
</script>
