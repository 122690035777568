<template>
  <div>
    <ApolloMutation
      :mutation="mutation"
      :variables="variables"
      @done="notifySave"
    >
      <template slot-scope="{ mutate, error }">
        <b-button-group size="sm">
          <b-button variant="outline-info" @click="cancel">
            <b-icon-x-circle></b-icon-x-circle> Cancelar
          </b-button>
          <b-button
            variant="info"
            :disabled="!changed || saving"
            :title="changed ? '' : 'No hay cambios'"
            v-b-tooltip.hover=""
            @click="
              saving = true;
              mutate();
            "
          >
            <span v-if="saving && !error"
              ><b-spinner label="Spinning"></b-spinner
            ></span>
            <span v-else
              ><b-icon-cloud-upload></b-icon-cloud-upload>Guardar</span
            >
          </b-button>
        </b-button-group>
        <b-alert v-if="error" variant="danger" show="">
          Ha ocurrido un error
        </b-alert>
        <b-row>
          <b-col md="4">
            <b-form-group label="Tipo">
              <v-select
                :options="types"
                v-model="type"
                :reduce="x => x.value"
                :clearable="false"
                v-if="!optionList.id"
              ></v-select>
            </b-form-group>
            <b-form-group label="Nombre">
              <b-form-input v-model="data.name" @input="change"></b-form-input>
            </b-form-group>
            <b-form-group label="Tipo de Integración">
              <ApolloQuery
                :query="
                  require('../graphql/AllPublicIntegrationInformation.gql')
                "
              >
                <template slot-scope="{ result }">
                  <v-select
                    v-model="data.publicIntegrationInformationId"
                    :reduce="x => x.value"
                    @input="change"
                    :options="integrationOptions(result.data)"
                  ></v-select>
                </template>
              </ApolloQuery>
            </b-form-group>
          </b-col>
          <b-col md="8">
            <option-list-form-data-base
              v-if="type === 'data_base'"
              v-model="data"
              @change="change"
            ></option-list-form-data-base>
            <option-list-form-request
              v-else-if="type === 'request'"
              v-model="data"
              @change="change"
            ></option-list-form-request>
          </b-col>
        </b-row>
      </template>
    </ApolloMutation>
  </div>
</template>
<script>
import OptionListFormDataBase from "@/components/OptionListFormDataBase";
import OptionListFormRequest from "@/components/OptionListFormRequest";
export default {
  name: "OptionListForm",
  components: { OptionListFormRequest, OptionListFormDataBase },
  props: {
    optionList: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    let type = this.$ifNull(this.optionList.type, "category");
    return {
      changed: false,
      saving: false,
      type,
      data: {},
      types: [
        { value: "category", label: "Arbol de Categorías" },
        { value: "request", label: "Resultado de un request específico" },
        { value: "data_base", label: "Consulta a la base de datos" }
      ]
    };
  },
  computed: {
    variables() {
      if (this.optionList.id) {
        return {
          id: this.optionList.id,
          patch: this.data
        };
      } else {
        return {
          create: this.data
        };
      }
    },
    mutation() {
      return this.mutationRequire(this.type);
    }
  },
  created() {
    this.refreshData();
  },
  methods: {
    integrationOptions(data) {
      if (!data) return [];
      return data.allPublicIntegrationInformation.edges.map(x => {
        return { value: x.node.id, label: x.node.name };
      });
    },
    mutationRequire(type) {
      if (this.optionList.id) {
        return this.updateMutationRequire(type);
      }
      return this.createMutationRequire(type);
    },
    updateMutationRequire(type) {
      switch (type) {
        case "category":
          return require("../graphql/UpdateHomologationCategoryOptionList.gql");
        case "data_base":
          return require("../graphql/UpdateHomologationDataBaseOptionList.gql");
        case "request":
          return require("../graphql/UpdateHomologationRequestOptionList.gql");
      }
    },
    createMutationRequire(type) {
      switch (type) {
        case "category":
          return require("../graphql/CreateHomologationCategoryOptionList.gql");
        case "data_base":
          return require("../graphql/CreateHomologationDataBaseOptionList.gql");
        case "request":
          return require("../graphql/CreateHomologationRequestOptionList.gql");
        default:
          break;
      }
    },
    refreshData() {
      const fields = ["name"];
      if (this.type === "data_base") {
        fields.push(...["model", "idField", "nameField"]);
      } else if (this.type === "request") {
        fields.push(
          ...[
            "methodName",
            "endpoint",
            "params",
            "arrayPath",
            "itemIdPath",
            "itemNamePath"
          ]
        );
      }
      const data = {};
      const selectionFields = ["methodName", "params"];
      fields.forEach(field => {
        data[field] = this.$ifNull(
          this.data[field],
          this.$ifNull(
            this.optionList[field],
            selectionFields.includes(field) ? null : ""
          )
        );
      });
      data.publicIntegrationInformationId = this.$dig(
        this.optionList,
        "publicIntegrationInformation",
        "id"
      );
      this.data = data;
    },
    notifySave() {
      this.$swal
        .fire({
          title: "Actualización exitosa",
          text: "El listado de opciones ha sido guardado con éxito",
          icon: "success",
          confirmButtonText: "Aceptar"
        })
        .then(() => {
          this.$router.push("/admin/option_list");
        });
    },
    cancel() {
      if (this.changed) {
        this.$swal
          .fire({
            title: "Cancelar",
            text: "Si cancelas, perderás tus cambios. ¿Estás seguro?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "No"
          })
          .then(result => {
            if (result.value) {
              this.$router.push("/admin/option_list");
            }
          });
      } else {
        this.$router.push("/admin/option_list");
      }
    },
    change() {
      this.changed = true;
    }
  },
  watch: {
    type() {
      this.change();
      this.refreshData();
    }
  }
};
</script>
