<template>
  <div>
    <b-alert v-model="errorSaving" variant="danger" dismissible>
      Se produjo un error enviando la sugerencia:

      {{ errorMessage }}
    </b-alert>
    <div v-if="!sent">
      <p>
        Para mejorar la experiencia de Centry, disponemos de marcas que
        funcionan para todos los marketplaces que tenemos a tu disposición. Si
        la marca de tu producto no está incluida en la lista, puedes pedir que
        se agregue llenando este formulario
      </p>
      <b-form-group>
        <b-input
          v-model="brandSugest"
          @keydown.enter="sendBrandSugestion"
        ></b-input>
      </b-form-group>
      <b-button variant="success" @click="sendBrandSugestion">Enviar</b-button>
    </div>
    <div v-else>
      Recibimos tu sugerencia, nos pondremos en contacto contigo cuando
      revisemos el caso
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import SEND_BRAND_SUGGESTION from "@/graphql/SendBrandSuggestion.gql";

export default {
  name: "ProductEditTabsCentryHelpBrand",
  data() {
    return {
      sent: false,
      brandSugest: "",
      errorSaving: false,
      errorMessage: ""
    };
  },
  computed: {
    ...mapState(["currentUser"])
  },
  methods: {
    sendBrandSugestion() {
      var body = {
        brandName: this.brandSugest
      };
      this.$apollo
        .mutate({
          mutation: SEND_BRAND_SUGGESTION,
          variables: {
            create: body
          }
        })
        .then(({ data }) => {
          if (data.suggestBrand.result) {
            this.sent = true;
          } else {
            this.errorMessage = data.suggestBrand.error;
            this.errorSaving = true;
          }
        })
        .catch(error => {
          this.errorMessage = error;
          this.errorSaving = true;
        });
    }
  }
};
</script>
