var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"size":"lg"},on:{"hidden":_vm.handleHidden,"ok":function($event){$event.preventDefault();return _vm.handleOk.apply(null, arguments)}},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('base-header',{attrs:{"title":_vm.headerTitle,"titleSize":12}})]},proxy:true},{key:"modal-footer",fn:function(ref){
var cancel = ref.cancel;
var ok = ref.ok;
return [_c('b-button-group',[_c('b-button',{attrs:{"variant":"outline-info"},on:{"click":cancel}},[_c('b-icon-x-circle'),_vm._v(" Cancelar ")],1),_c('b-button',{attrs:{"variant":"info","disabled":!_vm.hasDataSelected},on:{"click":ok}},[(_vm.loadingProducts || _vm.loadingSkus)?_c('span',[_c('b-spinner',{attrs:{"label":"Spinning"}})],1):_c('span',[_c('b-icon-cloud-download'),_vm._v("Obtener")],1)])],1)]}}]),model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('b-container',{attrs:{"fluid":""}},[_c('b-row',[(_vm.loadingCategories)?_c('b-spinner',{attrs:{"label":"Spinning"}}):_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Seleccione categorias a las que pedir los datos"}},_vm._l((_vm.categories),function(category){return _c('vtex-base-promotion-products-and-skus-data-item',{key:category.id,attrs:{"category":category},model:{value:(_vm.selectedCategories),callback:function ($$v) {_vm.selectedCategories=$$v},expression:"selectedCategories"}})}),1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Seleccione datos a obtener de las categorias escogidas"}},[_c('b-form-radio-group',{attrs:{"stacked":""},model:{value:(_vm.selectedProductsAndSkus),callback:function ($$v) {_vm.selectedProductsAndSkus=$$v},expression:"selectedProductsAndSkus"}},[_c('b-form-radio',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasProductsListener),expression:"hasProductsListener"}],attrs:{"value":{
                allProducts: true,
                allSkus: false
              }}},[_vm._v(" Solo los productos ")]),_c('b-form-radio',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasSkusListener),expression:"hasSkusListener"}],attrs:{"value":{
                allProducts: false,
                allSkus: true
              }}},[_vm._v(" Solo los SKUs ")]),_c('b-form-radio',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasProductsListener && _vm.hasSkusListener),expression:"hasProductsListener && hasSkusListener"}],attrs:{"value":{
                allProducts: true,
                allSkus: true
              }}},[_vm._v(" Ambos ")])],1)],1)],1)],1),_c('hr'),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-alert',{attrs:{"show":"","variant":"danger"}},[_vm._v(" Opciones no recomendadas. Las siguientes opciones obtienenen los datos desde todas las categorias de la cuenta, por lo tanto, pueden demorar hasta varios minutos dependiendo de la cantidad de productos y SKUs disponibles. Utilizar solo si es absolutamente necesario. ")])],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Seleccione datos a obtener desde todas las categorias de la cuenta"}},[_c('b-form-radio-group',{attrs:{"stacked":""},model:{value:(_vm.selectedAllProductsAndSkus),callback:function ($$v) {_vm.selectedAllProductsAndSkus=$$v},expression:"selectedAllProductsAndSkus"}},[_c('b-form-radio',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasProductsListener),expression:"hasProductsListener"}],attrs:{"value":{
                allProducts: true,
                allSkus: false
              }}},[_vm._v(" Todos los productos ")]),_c('b-form-radio',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasSkusListener),expression:"hasSkusListener"}],attrs:{"value":{
                allProducts: false,
                allSkus: true
              }}},[_vm._v(" Todos los SKUs ")]),_c('b-form-radio',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasProductsListener && _vm.hasSkusListener),expression:"hasProductsListener && hasSkusListener"}],attrs:{"value":{
                allProducts: true,
                allSkus: true
              }}},[_vm._v(" Ambos ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }