<template>
  <div id="header">
    <b-row>
      <b-col :md="titleSize">
        <h1>
          <b-spinner v-if="titleLink === 'loading'" />
          <a
            v-else
            class="title"
            style="padding-right: 5px; cursor: pointer"
            @click="emitClick"
            >{{ title }}</a
          >
          <div
            style="display: inline;"
            v-for="(elem, i) of map ? map : []"
            :key="i"
          >
            <span v-if="elem === 'loading'"
              >&gt; <b-spinner small type="grow" /> {{ " " }}
            </span>
            <span v-else style="padding-right: 5px;">
              {{ " " }} &gt; {{ elem }}
            </span>
          </div>
        </h1>
        <slot name="actions"></slot>
      </b-col>
      <b-col :md="12 - titleSize" class="text-right">
        <slot name="filters"></slot>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  name: "BaseHeader",
  props: {
    title: String,
    titleLink: String,
    map: Array,
    titleSize: {
      type: Number,
      default: 3
    },
    clickeable: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    /**
    Emite el evento 'click' si el header permite ser clickeado.
     */
    emitClick() {
      if (this.clickeable) {
        this.$emit("click");
      }
      this.toLink();
    },
    /**
     * Se encarga de redireccionar a la url del titulo
     */
    toLink() {
      let link = this.titleLink;
      if (link[0] == "/") {
        this.$router.push(link);
      } else {
        window.location.href = link;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import ".././assets/styles/variants.scss";
a.title {
  vertical-align: top;
  margin-top: 6px;
  display: inline-block;
  font-size: 21px;
  font-weight: 600;
  line-height: 25px;
}
span {
  font-size: 12px;
  vertical-align: top;
  display: inline-block;
  font-weight: 600;
  color: $textColorLight;
  padding-top: 15px;
  line-height: 12px;
}
#header {
  margin: 20px;
}
h1 {
  margin-left: 15px;
}
</style>
