<template>
  <div id="pretty-scale-test" :style="'font-size: ' + size + 'px;'">
    <p-check
      class="p-svg p-curv m-0"
      color="success"
      v-model="currentValue"
      :disabled="disabled"
    >
      <b-icon-check
        slot="extra"
        class="svg svg-icon"
        viewBox="0 0 15 15"
      ></b-icon-check
    ></p-check>
  </div>
</template>
<script>
export default {
  name: "BasePrettyCheckBox",
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    size: {
      type: Number,
      default: 20
    },
    value: Boolean,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentValue: this.value
    };
  },
  watch: {
    currentValue(val) {
      this.$emit("change", val);
    },
    value(val) {
      if (val !== this.currentValue) this.currentValue = val;
    }
  }
};
</script>
