<template>
  <b-tr>
    <b-td>{{ order.numberOrigin ? order.numberOrigin : order.idOrigin }}</b-td>
    <b-td>{{ order.origin }}</b-td>
    <b-td
      v-b-tooltip.hover=""
      :title="$timezoneDate(currentUser, order.createdAt) | formatDateTime"
    >
      {{ $timezoneDate(currentUser, order.createdAtOrigin) | formatDateTime }}
    </b-td>

    <b-td>
      <b-button
        v-if="actionFolio === 'Generar'"
        @click="onGenerate()"
        :disabled="!action"
        variant="primary"
        >Generar</b-button
      >
      <span v-else-if="errorsFolio">
        <div v-if="Array.isArray(errorsFolio)">
          <span :key="index" v-for="(item, index) in errorsFolio">
            {{ item }}
          </span>
        </div>
        <span v-else>
          {{ errorsFolio }}
        </span>
      </span>
      <span v-else>{{ documentFolio }}</span>
    </b-td>
    <b-td>
      <b-button
        v-if="actionDownload == 'Reemitir'"
        :disabled="!action"
        variant="primary"
        @click="onDownloadCentry()"
        >Reintentar Descarga</b-button
      >
      <b-button
        variant="success"
        v-else-if="documentDownload"
        v-on:click="onDownloadDocument()"
        >Descargar</b-button
      >
      <span v-else></span>
    </b-td>

    <b-td>
      <span v-if="!documentCancel"> {{ "" }}</span>
      <span v-else-if="documentCancel.length > 0">
        <span :key="index" v-for="(item, index) in documentCancel">
          {{ item }}
        </span></span
      >
      <b-button
        v-else-if="documentCancel.length == 0"
        :disabled="!action"
        variant="warning"
        v-on:click="anularOrden()"
        >Anular</b-button
      >
    </b-td>
  </b-tr>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import ORDER_DOCUMENT_GENERATE from "../../../graphql/OrderDocumentGenerate.gql";
import ORDER_TO_CANCELLED from "../../../graphql/OrderToCancelled.gql";
import ORDER_DOCUMENT_DOWNLOAD from "../../../graphql/OrderDocumentDownload.gql";
export default {
  name: "DocumentsTabsBSaleRow",
  beforeMount() {
    this.documentSet();
  },
  data() {
    return {
      actualOrder: this.order,
      selected: false,
      documentFolio: null,
      documentDownload: null,
      actionDownload: null,
      documentCancel: null,
      action: true,
      actionFolio: null,
      errorsFolio: null
    };
  },
  computed: {
    ...mapState(["selectedOrders", "disableOrderSelection", "currentUser"])
  },
  props: {
    order: Object,
    ic: Object
  },
  methods: {
    ...mapMutations(["setSelectedOrder", "unsetSelectedOrder"]),
    showDetails() {
      this.$router.push({
        name: "Order",
        params: { orderId: this.actualOrder.id }
      });
    },
    resetData() {
      this.selected = false;
      this.documentDownload = null;
      this.documentFolio = null;
      this.actionDownload = null;
      this.documentCancel = null;
      this.action = true;
      this.actionFolio = null;
      this.errorsFolio = null;
    },
    documentSet() {
      this.resetData();
      let bsale = this.actualOrder.integrations.b_sale;
      let failure = this.documentLastFailure();
      let downloadFailure = this.documentLastDownloadFailure();
      if (!this.actualOrder.billable) {
        this.documentFolio = "Not billable";
      } else if (bsale == null) {
        this.actionFolio = "Generar";
      } else if (bsale.folio != null || bsale?.response?.number) {
        this.documentFolio = bsale.folio || bsale.response.number;
        let document = this.getDocumentFolio(this.documentFolio);
        if (document) {
          this.documentDownload = document;
          this.documentCancelSet();
        } else if (downloadFailure?.error) {
          this.errorsFolio = downloadFailure.error;
          this.actionDownload = "Reemitir";
        } else if (failure?.reasons) {
          this.errorsFolio = failure.reasons;
        } else {
          this.actionDownload = "Reemitir";
        }
      } else if (failure?.reasons) {
        this.errorsFolio = failure.reasons;
      }
    },
    documentCancelSet() {
      if (this.actualOrder.status.startsWith("cancelled")) {
        let bsale = this.actualOrder.integrations.b_sale;
        let documentsCancel = bsale.return_ids;
        let documentsCancelCreditNote = bsale.cancel_ids;
        if (documentsCancel == undefined) {
          documentsCancel = [];
        }
        if (documentsCancelCreditNote == undefined) {
          documentsCancelCreditNote = [];
        }
        let documentsCancelFolio = documentsCancel?.concat(
          documentsCancelCreditNote
        );
        this.documentCancel =
          documentsCancelFolio != null ? documentsCancelFolio : [];
      }
    },
    getDocumentFolio(folio) {
      let document = this.actualOrder.documents.filter(
        d =>
          d.fileFileName.split(".")[0] == folio &&
          d.integrationConfig?.identifier == "b_sale"
      );
      return document.length > 0 ? document[0] : null;
    },
    documentLastFailure() {
      let bsale = this.actualOrder.integrations.b_sale;
      if (bsale?.failed_attempts) {
        let failures = bsale.failed_attempts?.sort((a, b) =>
          a.timestamp < b.timestamp ? 1 : b.timestamp < a.timestamp ? -1 : 0
        );
        return failures[0];
      }
    },
    documentLastDownloadFailure() {
      let bsale = this.actualOrder.integrations.b_sale;
      if (bsale?.failed_download) {
        let failures = bsale.failed_download?.sort((a, b) =>
          a.timestamp < b.timestamp ? 1 : b.timestamp < a.timestamp ? -1 : 0
        );
        return failures[0];
      }
    },
    onGenerate() {
      this.action = false;
      this.$apollo
        .mutate({
          mutation: ORDER_DOCUMENT_GENERATE,
          variables: {
            orderId: this.actualOrder.id,
            icId: this.ic.id
          }
        })
        .then(result => {
          let valid = result.data.orderDocumentGenerate.result;
          if (valid) {
            this.$swal.fire({
              title: "Actualizacion exitosa",
              text: "Se ha actualizado el documento exitosamente",
              icon: "success",
              confirmButtonText: "Aceptar"
            });
            this.actualOrder = result.data.orderDocumentGenerate.order;
            this.documentSet();
          } else {
            this.action = true;
            this.$swal.fire({
              title: "Actualizacion fallida",
              text: "Ha fallado la actualización del documento",
              icon: "error",
              confirmButtonText: "Aceptar"
            });
          }
        });
    },
    newWindowHref(name, url) {
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", name + ".pdf"); //or any other extension
      link.setAttribute("target", "_blank"); //or any other extension
      document.body.appendChild(link);
      link.click();
    },
    onDownloadDocument() {
      this.newWindowHref(
        this.documentDownload.fileFileName,
        this.documentDownload.fileOriginalUrl
      );
    },
    onDownloadDocumentCancel() {
      this.documentCancelDownload.foreach(d =>
        this.newWindowHref(d.fileFileName, d.fileOriginalUrl)
      );
    },
    onDownloadCentry() {
      this.action = false;
      this.$apollo
        .mutate({
          mutation: ORDER_DOCUMENT_DOWNLOAD,
          variables: {
            orderId: this.actualOrder.id,
            integrationConfigId: this.ic.id
          }
        })
        .then(result => {
          let valid = result.data.orderDocumentDownload.result;
          if (valid) {
            this.$swal.fire({
              title: "Solicitud exitosa",
              text:
                "Se ha solicitado la descarga de una boleta en Centry exitosamente",
              icon: "success",
              confirmButtonText: "Aceptar"
            });
            this.actualOrder = result.data.orderDocumentDownload.order;
            this.documentSet();
          } else {
            this.action = true;
            this.$swal.fire({
              title: "Solicitud fallida",
              text:
                "No se ha logrado solicitar la descarga de una boleta en Centry",
              icon: "error",
              confirmButtonText: "Aceptar"
            });
          }
        });
    },
    anularOrden() {
      this.action = false;
      this.$apollo
        .mutate({
          mutation: ORDER_TO_CANCELLED,
          variables: {
            orderId: this.actualOrder.id,
            icId: this.ic.id
          }
        })
        .then(result => {
          let valid = result.data.orderToCancelled.result;
          if (valid) {
            this.$swal.fire({
              title: "Solicitud exitosa",
              text: "Se ha solicitado la anulacion exitosamente",
              icon: "success",
              confirmButtonText: "Aceptar"
            });
            this.actualOrder = result.data.orderToCancelled.order;
            this.documentSet();
          } else {
            this.action = true;
            this.$swal.fire({
              title: "Solicitud fallida",
              text: "Ha fallado la solicitud de anulacion",
              icon: "error",
              confirmButtonText: "Aceptar"
            });
          }
        });
    }
  }
};
</script>

<style scoped>
td {
  height: 100px;
}
</style>
