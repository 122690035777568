<template>
  <b-navbar-brand
    v-if="currentUser.companies.length <= 1"
    href="#"
    class="font-weight-bolder text-uppercase text-white"
  >
    {{ currentUser.company.name }}
  </b-navbar-brand>
  <b-navbar-nav v-else class="navbar-brand navbar-brand-dropdown">
    <b-nav-item-dropdown
      class="active font-weight-bolder text-uppercase text-white"
    >
      <template #button-content>
        <span v-if="saving">
          <b-spinner />
        </span>
        <span v-else>
          {{ currentUser.company.name }}
        </span>
      </template>
      <ApolloMutation
        :mutation="require('../graphql/UpdateProfile.gql')"
        @done="updateCurrentUser"
        :variables="{ input }"
      >
        <template slot-scope="{ mutate }">
          <b-dropdown-item
            v-for="company of companiesWithoutCurrent"
            href="#"
            @click="setCompanyId(company.id) && mutate()"
            :key="company.id"
          >
            {{ company.name }}
          </b-dropdown-item>
        </template>
      </ApolloMutation>
    </b-nav-item-dropdown>
  </b-navbar-nav>
</template>
<script>
import { mapMutations } from "vuex";
export default {
  name: "TheMenuBrand",
  props: {
    currentUser: Object
  },
  data() {
    return {
      input: {
        companyId: ""
      },
      saving: false
    };
  },
  computed: {
    companiesWithoutCurrent() {
      return this.currentUser.companies.filter(
        x => x.id !== this.currentUser.company.id
      );
    }
  },
  methods: {
    setCompanyId(companyId) {
      this.saving = true;
      this.input.companyId = companyId;
      return true;
    },
    async updateCurrentUser(result) {
      let newUser = result.data.updateProfile.user;
      await this.setCurrentUser(newUser);
      localStorage["current-user"] = JSON.stringify(newUser);
      localStorage.companyId = this.input.companyId;
      this.saving = false;
    },
    ...mapMutations(["setCurrentUser"])
  }
};
</script>
<style scoped>
.navbar-brand {
  padding: 8px 15px 8px 25px;
}
.navbar-brand-dropdown {
  padding: 0 15px 0 15px;
}
</style>
