<template>
  <div>
    <u
      style="cursor: pointer"
      :class="spanClass"
      @click="$bvModal.show(`errors-modal-${id}`)"
      v-b-tooltip.hover="'Click aqui para ver el detalle de los errores'"
    >
      {{ errors.length }}
    </u>
    <b-modal :id="`errors-modal-${id}`" ok-only size="lg">
      <template #modal-title>
        <h4>Detalle de los errores</h4>
      </template>

      <b-table-simple>
        <b-thead>
          <b-tr>
            <b-th colspan="3">Producto</b-th>
            <b-th>Problema</b-th>
          </b-tr>
          <b-tr>
            <b-th>Nombre</b-th>
            <b-th>Marca</b-th>
            <b-th>Categoría</b-th>
            <b-th />
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="(error, i) of errors" :key="i">
            <b-td colspan="3" v-if="!error.product">
              -sin información del producto--
            </b-td>
            <b-td v-if="error.product">
              <b-link
                :to="`/product/${error.product._id}/histories`"
                target="_blank"
              >
                {{ error.product.name }}
              </b-link>
            </b-td>
            <b-td v-if="error.product">
              {{ error.product.brand ? error.product.brand : "" }}
            </b-td>

            <b-td v-if="error.product">
              {{ error.product.category ? error.product.category : "" }}
            </b-td>

            <b-td v-if="error.error && error.error.message">
              {{ error.error.message }}
            </b-td>
            <b-td v-else>
              --sin información del error--
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "ErrorsModal",
  props: {
    errors: Array,
    spanClass: String,
    id: String
  },
  methods: {}
};
</script>

<style></style>
