<template>
  <b-row>
    <b-col md="3">
      <b-img
        thumbnail=""
        :src="coverUrl"
        fluid
        alt="cover"
        class="transparent-bg"
        v-if="coverUrl"
      ></b-img>
      <div v-else class="centry-no-image">
        <b-icon
          icon="image"
          variant="secondary"
          font-scale="5"
          shift-h="-8"
          shift-v="8"
        ></b-icon>
      </div>
      <product-detail-page-image-quality
        :product="product"
      ></product-detail-page-image-quality>
    </b-col>
    <b-col md="9">
      <h2>
        {{ product.name }} <span class="text-muted">{{ brandName }}</span>
      </h2>
      <span class="text-muted">{{ categoryName }}</span>
      <p>{{ product.sku }}</p>
      <p class="prices">
        <span class="main-price">{{ mainPrice }}</span>
        <span class="compare-price" v-if="hasComparePrice">{{
          comparePrice
        }}</span>
      </p>
      <product-detail-page-integrations
        :product="product"
      ></product-detail-page-integrations>
    </b-col>
    <b-col md="12">
      <product-detail-page-transactions
        :product="product"
      ></product-detail-page-transactions>
    </b-col>
    <b-col md="12">
      <product-detail-page-sales :product="product"></product-detail-page-sales>
    </b-col>
  </b-row>
</template>
<script>
import ProductDetailPageIntegrations from "./ProductDetailPageIntegrations";
import ProductDetailPageSales from "./ProductDetailPageSales";
import ProductDetailPageTransactions from "./ProductDetailPageTransactions";
import ProductDetailPageImageQuality from "./ProductDetailPageImageQuality";
export default {
  name: "ProductDetailPage",
  components: {
    ProductDetailPageImageQuality,
    ProductDetailPageTransactions,
    ProductDetailPageSales,
    ProductDetailPageIntegrations
  },
  props: {
    product: Object
  },
  computed: {
    brandName() {
      return this.product.brand ? this.product.brand.name : "";
    },
    categoryName() {
      return this.product.category ? this.product.category.name : "";
    },
    hasComparePrice() {
      return !!this.product.price;
    },
    mainPrice() {
      if (this.product.price) {
        return "$" + Intl.NumberFormat("de-DE").format(this.product.price);
      }
      return this.comparePrice;
    },
    comparePrice() {
      if (this.product.priceCompare) {
        return (
          "$" + Intl.NumberFormat("de-DE").format(this.product.priceCompare)
        );
      }
      return "";
    },
    coverUrl() {
      if (
        this.product.assets &&
        this.product.assets.length &&
        this.product.assets[0].originalUrl
      ) {
        return this.product.assets[0].originalUrl;
      }
      return null;
    }
  }
};
</script>
<style scoped>
.text-muted {
  font-size: 12px !important;
  font-weight: bold;
  color: #aab1b5 !important;
}
.prices {
  margin-top: 15px;
}
.main-price {
  font-weight: bold;
}
.compare-price {
  font-weight: bold;
  color: #aab1b5;
  text-decoration-line: line-through;
  margin-left: 10px;
}
.centry-no-image {
  background-color: #dddddd;
  padding-top: 100%;
  position: relative;
}

.centry-no-image svg {
  position: absolute;
  top: 50%;
  left: 50%;
  bottom: 0;
  right: 0;
}
</style>
