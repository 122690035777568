<template>
  <div>
    <b-alert
      v-if="error || success"
      show=""
      :variant="error ? 'danger' : 'success'"
      dismissible
    >
      {{
        error
          ? "Ha ocurrido un error guardando los datos: " + errorMessage
          : "Guardado exitosamente"
      }}
    </b-alert>
    <b-row v-if="loading">
      <b-col v-for="i in 18" :key="i" cols="4" class="mb-3">
        <b-skeleton
          animation="wave"
          :width="`${80 - Math.floor(Math.random() * 20)}%`"
        />
      </b-col>
    </b-row>
    <div v-else align-h="between">
      <b-row>
        <b-col xl="10" cols="12">
          <b-row>
            <b-col
              v-for="(checkbox, i) of Object.values(checkboxes)"
              :key="i"
              lg="4"
              sm="6"
              cols="12"
              class="mb-3"
            >
              <b-form-checkbox
                v-model="values[checkbox.model]"
                :disabled="!checkbox.enabled"
              >
                {{ checkbox.label }}
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-col>
        <b-col xl="2" cols="12">
          <b-button
            variant="info"
            :disabled="!changed || saving"
            :title="changed ? '' : 'No hay cambios'"
            @click="save"
            class="mb-3 w-100"
          >
            <span v-if="saving"><b-spinner label="Spinning"></b-spinner></span>
            <span v-else>Guardar</span>
          </b-button>
          <b-button
            variant="outline-info"
            class="w-100 px-0"
            :disabled="!changed || saving"
            @click="resetData()"
          >
            <span>Restablecer</span>
          </b-button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import INTEGRATION_CONFIG_CHECKBOXES from "../../graphql/IntegrationConfig/Checkboxes.gql";
import INTEGRATION_CONFIG_FIELDS_TO_SYNC from "../../graphql/IntegrationConfig/FieldsToSync.gql";
import UPDATE_INTEGRATION_CONFIG from "../../graphql/IntegrationConfig/UpdateIntegrationConfig.gql";
import { mapMutations, mapState } from "vuex";
export default {
  name: "IntegrationConfigFieldsToSync",
  props: {
    ic: {
      type: Object,
      default() {
        return {};
      }
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      checkboxes: {
        name: { label: "Nombre de producto" },
        category: { label: "Categoría" },
        description: { label: "Descripción de producto" },
        shortdescription: { label: "Listado de características" },
        priceCompare: { label: "Precio" },
        price: { label: "Precio de oferta" },
        condition: { label: "Condición" },
        warranty: { label: "Garantía" },
        productstatus: { label: "Estado del producto" },
        packageDimensions: { label: "Dimensiones del paquete" },
        packageWeight: { label: "Peso del paquete" },
        barcode: { label: "Código de barra" },
        sku: { label: "SKU del producto" },
        images: { label: "Imágenes" },
        seoTitle: { label: "Título SEO" },
        seoDescription: { label: "Descripción SEO" },
        size: { label: "Tallas" },
        color: { label: "Colores" }
      },
      originalValues: {},
      values: {},
      loading: false,
      saving: false,
      errorMessage: null,
      error: false,
      success: false
    };
  },
  computed: {
    ...mapState(["currentUser"]),
    changed() {
      return !this.$objSimpleCompare(this.originalValues, this.values);
    }
  },
  async mounted() {
    this.loading = true;
    await this.getCheckboxes();
    await this.getFieldsToSync();
  },
  methods: {
    ...mapMutations(["setIntegrationConfigChanged"]),
    /**
     * Query que se encarga de obtener los posibles
     * checkboxes y si estab habilitados o no
     */
    async getCheckboxes() {
      await this.$apollo
        .query({
          query: INTEGRATION_CONFIG_CHECKBOXES,
          variables: { integrationConfigId: this.ic.id }
        })
        .then(({ data }) => {
          let checkboxes = this.$dup(data.integrationConfigCheckboxes);
          delete checkboxes.__typename;
          let merged = this.$deepMerge(this.checkboxes, checkboxes);
          if (this.currentUser.role != 0) {
            delete merged.size;
            delete merged.color;
          }
          Object.keys(merged).forEach(val => {
            delete merged[val].__typename;
            merged[val].model = val;
          });
          this.checkboxes = merged;
        });
    },
    /**
     * Se encarga de obtener el valor actual de
     * los campos para la sincronizacion
     */
    async getFieldsToSync() {
      if (Object.keys(this.values).length == 0) {
        await this.$apollo
          .query({
            query: INTEGRATION_CONFIG_FIELDS_TO_SYNC,
            variables: { integrationConfigId: this.ic.id }
          })
          .then(({ data }) => {
            let fieldsToSync = this.$dup(data.integrationConfig);
            delete fieldsToSync.id;
            delete fieldsToSync.__typename;
            let tempValues = {};
            Object.keys(fieldsToSync).forEach(val => {
              let options = this.checkboxes[val];
              if (options) {
                tempValues[val] = options.enabled
                  ? fieldsToSync[val]
                  : options.checked;
              }
            });
            this.values = this.$dup(tempValues);
            this.originalValues = this.$dup(this.values);
            this.loading = false;
          });
      }
    },
    /**
     * Envia la mutacion para guardar los nuevos
     * valores de los campos para la sinncronizacion
     */
    save() {
      this.saving = true;
      this.$apollo
        .mutate({
          mutation: UPDATE_INTEGRATION_CONFIG,
          variables: {
            integrationConfigId: this.ic.id,
            patch: this.values
          }
        })
        .then(({ data }) => {
          this.saving = false;
          if (data?.updateIntegrationConfig?.result) {
            this.errorMessage = null;
            this.error = false;
            this.success = true;
            this.originalValues = this.$dup(this.values);
          } else {
            this.errorMessage = data?.updateIntegrationConfig?.error;
            this.error = true;
          }
        });
    },
    /**
     * Se encarga de resetear los datos
     */
    resetData() {
      this.loading = true;
      this.values = this.$dup(this.originalValues);
      this.errorMessage = null;
      this.error = false;
      this.success = false;
      this.loading = false;
    }
  },
  watch: {
    changed(val) {
      this.setIntegrationConfigChanged(val);
    },
    visible(val) {
      if (val) {
        this.resetData();
      }
    }
  }
};
</script>

<style scoped></style>
