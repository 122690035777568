<template>
  <div>
    <b-modal :id="id" cancel-disabled="" size="xl">
      <template v-slot:modal-title>
        <h4>{{ title }}</h4>
      </template>

      <template id="">
        <b-form-group>
          <div>
            <b-row>
              <b-col md="3">
                <strong>Categorías</strong>
              </b-col>
              <b-col md="9">
                <b-form-group>
                  <base-live-select
                    id="category"
                    v-model="categoriesSelectedObject"
                    placeholder="Seleccionar"
                    :clearable="false"
                    :multiple="true"
                    @search="updateCategories"
                    @change="
                      categoriesSelected = categoriesSelectedObject.map(
                        x => x.value
                      )
                    "
                  ></base-live-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="3">
                <strong>Marcas</strong>
              </b-col>
              <b-col md="9">
                <b-form-group>
                  <base-live-select
                    id="brand"
                    v-model="brandsSelectedObject"
                    placeholder="Seleccionar"
                    :clearable="false"
                    :multiple="true"
                    @search="updateBrands"
                    @change="
                      brandsSelected = brandsSelectedObject.map(x => x.value)
                    "
                    :min-search-characters="1"
                  ></base-live-select>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <b-button @click="searchProducts()" variant="info btn-block">
            Filtrar
          </b-button>
        </b-form-group>
      </template>

      <template id="content">
        <div></div>
        <b-spinner v-if="loading" label="Spinning"></b-spinner>
        <template v-if="products.length > 0">
          <product-for-packs-centry-table
            :products="products"
            :selectedProducts="selectedProducts"
            @add-selected-products="addSelectedProducts"
          />
        </template>
      </template>
      <template v-slot:modal-footer="{ ok }">
        <b-button variant="info" @click="ok">
          Entendido
        </b-button>
      </template>
      <slot></slot>
    </b-modal>
  </div>
</template>

<script>
import ProductForPacksCentryTable from "./ProductsForPacksCentryTable.vue";
import { mapState } from "vuex";
import BaseLiveSelect from "./BaseLiveSelect";
import ALL_CATEGORIES from "../graphql/AllCategories.gql";
import ALL_BRANDS from "../graphql/AllBrands.gql";
import ALL_PRODUCTS_IDS from "../graphql/AllProductsIds.gql";
import ALL_VARIANTS_FOR_PACK from "../graphql/AllVariantsForPack.gql";

export default {
  computed: {
    ...mapState(["currentUser"])
  },
  components: {
    ProductForPacksCentryTable,
    BaseLiveSelect
  },

  name: "ProductsForPacksCentry",
  props: {
    id: String,
    title: String,
    selectedProducts: Array,
    colors: Array,
    sizes: Array
  },

  data: () => ({
    categoriesSelected: [],
    brandsSelected: [],
    value: [],
    products: [],
    loading: false,
    categoriesSelectedObject: null,
    brandsSelectedObject: null
  }),
  methods: {
    async searchProducts() {
      let productIds = [];
      this.loading = true;
      await this.$getAllPages(
        ALL_PRODUCTS_IDS,
        {
          categoryId: this.categoriesSelected.join(","),
          brandId: this.brandsSelected.join(",")
        },
        "allProducts"
      ).then(result => {
        productIds = productIds.concat(result.map(p => p.node.id));
      });
      await this.searchVariants(productIds);
      this.loading = false;
    },
    async searchVariants(productIds) {
      this.products = [];
      await this.$getAllPages(
        ALL_VARIANTS_FOR_PACK,
        {
          productIds: productIds
        },
        "allVariants",
        null,
        7000
      ).then(result => {
        this.products = this.products.concat(
          result.map(v => ({
            id: v.node.id,
            size: v.node.sizeName,
            color: v.node.colorName,
            sku: v.node.sku,
            barcode: v.node.barcode,
            producto: v.node.product.name,
            marca: v.node.product.brandName || "",
            categoria: v.node.product.categoryName || "",
            original_price: v.node.product.priceCompare
          }))
        );
      });
    },
    addSelectedProducts(selectedProducts) {
      let prods = selectedProducts;
      this.$emit("add-new-items", { prods });
    },
    /**
     * Se encarga de filtrar las categorías a mostrar como opciones del select
     * solicitando al servidor un subconjunto que contenga en su nombre el
     * término ingresado por el usuario.
     * @param {String} search término a ser buscado
     * @param {Boolean} loading indica si el request está en curso
     * @param {Function} setOptions función del componente BaseLiveSelect que
     *                              espera recibir un arreglo de opciones.
     */
    async updateCategories(search, loading, setOptions) {
      loading(true);
      this.$apollo
        .query({
          query: ALL_CATEGORIES,
          variables: { companyId: this.currentUser.company.id, name: search }
        })
        .then(async result => {
          if (this.$dig(result, "data", "allCategories", "edges")) {
            setOptions(
              result.data.allCategories.edges.map(x => {
                return this.itemForSelect(x.node);
              })
            );
          }
        });
      loading(false);
    },
    /**
     * Se encarga de filtrar las marcas a mostrar como opciones del select
     * solicitando al servidor un subconjunto que contenga en su nombre el
     * término ingresado por el usuario.
     * @param {String} search término a ser buscado
     * @param {Boolean} loading indica si el request está en curso
     * @param {Function} setOptions función del componente BaseLiveSelect que
     *                              espera recibir un arreglo de opciones.
     */
    async updateBrands(search, loading, setOptions) {
      loading(true);
      this.$apollo
        .query({
          query: ALL_BRANDS,
          variables: { companyId: this.currentUser.company.id, name: search }
        })
        .then(async result => {
          if (this.$dig(result, "data", "allBrands", "edges")) {
            setOptions(
              result.data.allBrands.edges.map(x => {
                return this.itemForSelect(x.node);
              })
            );
          }
        });
      loading(false);
    },
    itemForSelect(item) {
      if (item) {
        return { value: item.id, label: item.name };
      }
      return null;
    }
  }
};
</script>
