<template>
  <div>
    <b-row>
      <b-col>
        <b-input-group>
          <b-input-group-prepend is-text>
            <b-icon-search></b-icon-search>
          </b-input-group-prepend>
          <b-form-tags
            v-model="keywords"
            separator=","
            remove-on-delete=""
            placeholder="Palabras claves"
          ></b-form-tags>
        </b-input-group>
      </b-col>
    </b-row>

    <br />

    <b-spinner v-if="loading" label="Spinning"></b-spinner>
    <div v-else-if="specificationsList">
      <b-table-simple responsive>
        <b-thead>
          <b-tr>
            <b-th class="col-md-3"> Nombre </b-th>
            <b-th class="col-md-2"> Id </b-th>
            <b-th class="col-md-2"> Id Categoria </b-th>
            <b-th class="col-md-2"> Activo </b-th>
            <b-th class="col-md-2"> Especificación SKU </b-th>
            <b-th class="col-md-1"> Acciones </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="specification in actualSpecificationsList"
            :key="specification.fieldId"
          >
            <b-td> {{ specification.name }} </b-td>
            <b-td> {{ specification.fieldId }} </b-td>
            <b-td> {{ specification.categoryId }} </b-td>
            <b-td> {{ specification.isActive }} </b-td>
            <b-td> {{ specification.isStockKeepingUnit }} </b-td>
            <b-td>
              <b-dropdown variant="white" no-caret="" offset="-110">
                <template v-slot:button-content>
                  <b-icon-three-dots-vertical></b-icon-three-dots-vertical>
                </template>
                <b-dropdown-item
                  @click="openSpecificationModal(specification.fieldId)"
                >
                  Editar
                </b-dropdown-item>
                <b-dropdown-item @click="emitSpecification(specification)">
                  Valores
                </b-dropdown-item>
              </b-dropdown>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <b-row align-h="center">
        <b-pagination
          v-model="page"
          :total-rows="specificationsList.length"
          :per-page="pageSize"
          @change="changePage"
        ></b-pagination>
      </b-row>
    </div>
  </div>
</template>

<script>
import INTEGRATION_CONFIG_VTEX_GET_SPECIFICATIONS from "@/graphql/IntegrationConfigVtexGetSpecifications.gql";

export default {
  name: "VtexSpecificationsTable",
  data() {
    return {
      loading: true,
      pageSize: 10,
      page: 1,
      specificationsList: null,
      actualSpecificationsList: null,
      keywords: []
    };
  },
  props: {
    vtexIntegrationConfigId: {
      type: String,
      required: true
    },
    categoryId: {
      required: true,
      validator: prop => typeof prop === "string" || prop === null
    }
  },
  mounted: async function() {
    this.categoryId !== null
      ? await this.getSpecifications(this.categoryId)
      : (this.loading = false);
  },
  methods: {
    /**
     * Realiza la petición para obtener las especificaciones de una categoria.
     * @param {String} categoryId
     */
    async getSpecifications(categoryId) {
      this.loading = true;
      await this.$apollo
        .query({
          query: INTEGRATION_CONFIG_VTEX_GET_SPECIFICATIONS,
          variables: {
            id: this.vtexIntegrationConfigId,
            categoryId: categoryId,
            keywords: this.keywords.length > 0 ? this.keywords : null
          }
        })
        .then(({ data }) => {
          if (!data?.integrationConfigVtex?.specificationsList) {
            this.emitErrorMessage(
              "No se pudo obtener el listado de especificaciones."
            );
          } else {
            this.specificationsList =
              data.integrationConfigVtex.specificationsList;
          }
        });
      this.loading = false;
    },
    /**
     * Cambia la página de la tabla
     * @param {Number} page
     */
    changePage(page) {
      this.page = page;
      let offset = (page - 1) * this.pageSize;
      this.actualSpecificationsList = this.specificationsList.slice(
        offset,
        offset + this.pageSize
      );
    },
    /**
     * Emite el evento 'errorMessage' con el mensaje de error correspondiente
     * @param {String} errorMessage
     */
    emitErrorMessage(errorMessage) {
      this.$emit("errorMessage", errorMessage);
    },
    /**
     * Emite el evento 'emitSpecification' con la especificación correspondiente.
     * @param {Object} specification
     */
    emitSpecification(specification) {
      this.$emit("emitSpecification", specification);
    },
    /**
     * Emite el evento 'openSpecificationModal' para abrir un modal con los
     * datos de la especificación informada.
     * @param {String} specificationId
     */
    openSpecificationModal(specificationId) {
      this.$emit("openSpecificationModal", specificationId);
    },
    /**
     * Emite el evento 'openSpecificationValuesModal' para abrir un modal con
     * los datos de los valores de la especificación informada.
     * @param {String} specificationId
     */
    openSpecificationValuesModal(specificationId) {
      this.$emit("openSpecificationValuesModal", specificationId);
    },
    /**
     * Define la froma de comparar dos especificaciones para ser
     * ordenados en una lista
     * @param {Object} spec1
     * @param {Object} spec2
     * @return {Integer}
     */
    compareSpecificationsForSortedList(spec1, spec2) {
      let category1 = spec1.categoryId;
      let category2 = spec2.categoryId;
      if (category1 === null && category2 === null) return 0;
      if (category1 === null) return 1;
      if (category2 === null) return -1;

      category1 = parseInt(category1);
      category2 = parseInt(category2);
      if (category1 < category2) return 1;
      if (category1 > category2) return -1;

      let id1 = parseInt(spec1.id);
      let id2 = parseInt(spec2.id);
      if (id1 < id2) return -1;
      if (id1 > id2) return 1;
      return 0;
    },
    /**
     * Revisa si el listado de especificaciones entregado esta ordenado.
     * @param {Array<Object>} specificationsList
     * @return {Boolean}
     */
    specificationsListIsSorted(specificationsList) {
      let sorted = true;
      for (let i = 0; i < specificationsList.length - 1; i++) {
        const spec = specificationsList[i];
        const nextSpec = specificationsList[i + 1];

        if (this.compareSpecificationsForSortedList(spec, nextSpec) > 0) {
          sorted = false;
          break;
        }
      }
      return sorted;
    }
  },
  watch: {
    categoryId(newCategoryId) {
      this.getSpecifications(newCategoryId);
    },
    keywords() {
      this.getSpecifications(this.categoryId);
    },
    specificationsList(newspecificationsList) {
      if (!this.specificationsListIsSorted(newspecificationsList)) {
        newspecificationsList.sort(this.compareSpecificationsForSortedList);
        this.specificationsList = newspecificationsList;
      }
      this.changePage(1);
    }
  }
};
</script>
