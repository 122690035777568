<template>
  <div class="container">
    <b-row>
      <b-col md="12">
        <div class="d-flex align-items-center mb-3">
          <input
            type="checkbox"
            id="prodCat"
            class="mx-2 mb-2"
            v-model="companyCategories"
          />
          <label for="prodCat"
            >Mostrar solo categorías usadas por los productos.</label
          >
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-spinner label="Spinning" v-if="loadingCategories"></b-spinner>
        <vMultiselectListbox
          style="min-width: 100%"
          v-else
          v-model="selectedCategories"
          :options="categories"
          :reduce-display-property="option => option.label"
          :reduce-value-property="option => option.value"
          search-options-placeholder="Buscar categorías"
          selected-options-placeholder="Buscar categorías seleccionadas"
          no-options-text="No hay categorías"
          selected-no-options-text="No hay categorías seleccionadas"
          no-options-found-text="No se encontró la categoría"
          no-selected-options-found-text="No se encontró la categoría seleccionada"
        >
        </vMultiselectListbox>
      </b-col>
    </b-row>
    <b-row class="my-2">
      <b-col md="12">
        <b-button
          @click="saveCategories"
          class="float-right"
          :disabled="loadingCategories || saving"
          >{{ saving ? "Guardando..." : "Guardar" }}</b-button
        >
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import vMultiselectListbox from "vue-multiselect-listbox";
import ALL_CATEGORIES from "../graphql/AllCategories.gql";
import ADD_CATEGORIES_TO_ATTRIBUTE_SET from "../graphql/MagentoAddCategoriesToAttributeSet.gql";
import "vue-multiselect-listbox/dist/vue-multi-select-listbox.css";

export default {
  name: "CategorySelector",
  mounted() {
    this.getCategories();
  },
  components: {
    vMultiselectListbox
  },
  props: ["attributeSet"], // {id: String, magentoId: Int, name: String, integrationConfigId: String, selectedCategories: [String]}
  data() {
    return {
      categories: [],
      selectedCategories: [],
      loadingCategories: true,
      companyCategories: false,
      saving: false
    };
  },
  methods: {
    async getCategories() {
      this.loadingCategories = true;
      let cursor = "";
      let companyId = this.companyCategories ? this.currentUser.company.id : "";
      let variables = { companyId: companyId, cursor: cursor };
      let categories = await this.$getAllPages(
        ALL_CATEGORIES,
        variables,
        "allCategories"
      ).then(result => {
        if (result) {
          return result.map(c => ({ value: c.node.id, label: c.node.name }));
        }
      });
      this.categories = categories;
      this.selectedCategories = this.attributeSet.selectedCategories;
      this.loadingCategories = false;
    },
    async saveCategories() {
      this.saving = true;
      this.$apollo
        .mutate({
          mutation: ADD_CATEGORIES_TO_ATTRIBUTE_SET,
          variables: {
            integrationConfigId: this.attributeSet.integrationConfigId,
            attributeSetId: this.attributeSet.magentoId,
            categories: this.selectedCategories
          }
        })
        .then(() => {
          this.$bvToast.toast("Categorías guardadas exitosamente", {
            title: "Categorías guardadas",
            variant: "success",
            solid: true
          });
        })
        .catch(() => {
          this.$bvToast.toast("Hubo un error al guardar las categorías", {
            title: "Error",
            variant: "danger",
            solid: true
          });
        })
        .finally(() => {
          this.saving = false;
        });
    }
  },
  computed: {
    ...mapState(["currentUser"])
  },
  watch: {
    companyCategories: function() {
      this.getCategories();
    },
    selectedCategories: function() {
      return true;
    }
  }
};
</script>

<style scoped>
.table td,
.table th {
  height: inherit;
}
h4 {
  height: 30px;
}
</style>
