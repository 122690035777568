<template>
  <div>
    <base-exclusions
      style="min-width: 100%"
      :ic="ic"
      :visible="visible"
      search-options-placeholder="Buscar marcas"
      selected-options-placeholder="Buscar marcas seleccionadas"
      no-options-text="No hay marcas"
      selected-no-options-text="No hay marcas seleccionadas"
      no-options-found-text="No se encontró la marca"
      no-selected-options-found-text="
        No se encontró la marca seleccionada
      "
      all-options-label="Todas las marcas"
      selected-options-label="Marcas seleccionadas"
      :base-options="brands"
      :base-selected-options="selectedBrands"
      field-to-exclude="excludedBrandIds"
      field-to-include="includedBrandIds"
      field-excluded-products-without="excludeProductsWithoutBrand"
      label-excluded-products-without="Excluir productos sin marca"
      :base-excluded-products-without="excludedProductsWithout"
    >
    </base-exclusions>
  </div>
</template>

<script>
import ALL_BRANDS from "../../graphql/AllBrands.gql";
import EXCLUDED_BRAND_IDS from "../../graphql/IntegrationConfig/ExcludedBrandIds.gql";
import BaseExclusions from "./BaseExclusions.vue";
import { mapState } from "vuex";

export default {
  name: "ExclusionByBrand",
  components: {
    BaseExclusions
  },
  props: {
    ic: {
      type: Object,
      default() {
        return {};
      }
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      brands: [],
      selectedBrands: [],
      excludedProductsWithout: null
    };
  },
  async mounted() {
    await this.getBrands();
    await this.getExcludedBrands();
  },
  computed: {
    ...mapState(["currentUser"])
  },
  methods: {
    /**
     * Obtiene todas las marcas
     */
    async getBrands() {
      await this.$getAllPages(
        ALL_BRANDS,
        { companyId: this.currentUser.company.id },
        "allBrands"
      ).then(result => {
        this.brands = result.map(x => x.node);
      });
    },
    /**
     * Obtiene el id de todas las marcas excluidas
     */
    async getExcludedBrands() {
      this.$apollo
        .query({
          query: EXCLUDED_BRAND_IDS,
          variables: { integrationConfigId: this.ic.id }
        })
        .then(({ data }) => {
          this.selectedBrands = this.$dup(
            data.integrationConfig.excludedBrandIds
          );
          this.excludedProductsWithout =
            data.integrationConfig.excludeProductsWithoutBrand;
        });
    }
  }
};
</script>

<style scoped></style>
