<template>
  <li>
    <div @click="toggle()" v-bind:style="style">
      <button :style="style">{{ item.name }}</button>
      <span v-if="isFolder">[{{ isOpen ? "-" : "+" }}]</span>
    </div>
    <ul v-show="isOpen" v-if="isFolder">
      <base-category-tree-item
        class="item"
        v-for="(child, index) in childrens"
        :key="index"
        :item="child"
        :children-path="childrenPath"
        :selected="selectedCategory"
        :item-id="itemId"
        @category="emitCategory"
      ></base-category-tree-item>
    </ul>
  </li>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "BaseCategoryTreeItem",
  props: {
    item: Object,
    childrenPath: String,
    selected: Object,
    itemId: {
      type: String,
      default: "id"
    }
  },
  data() {
    return {
      isOpen: false,
      selectedCategory: this.selected,
      childrens: this.$ifNull(this.item[this.childrenPath], [])
    };
  },
  beforeMount() {
    this.addPath();
  },
  computed: {
    ...mapState(["allColors"]),
    isFolder() {
      return (
        this.item[this.childrenPath] && this.item[this.childrenPath].length
      );
    },
    style() {
      let style = {
        "font-weight": this.isFolder ? "bold" : "normal",
        background: "none",
        border: "none",
        outline: "none"
      };
      if (this.selectedCategory[this.itemId] == this.item[this.itemId]) {
        style["color"] = this.allColors[17];
      } else {
        style["color"] = this.allColors[13];
      }
      return style;
    }
  },
  methods: {
    /**
     * Se encarga de emitir la categoria seleccionada y mostrar las categorias
     * hijas si existen
     */
    toggle() {
      this.emitCategory(this.item);
      if (this.isFolder) {
        this.isOpen = !this.isOpen;
      }
    },
    /**
     * Emite el evento 'category' informando la categoria seleccionada
     * @param {Object} category
     */
    emitCategory(category) {
      this.$emit("category", category);
      this.selectedCategory = category;
    },
    /**
     * Agrega el atributo path a las categorias hijas
     */
    addPath() {
      this.childrens = this.childrens.map(category => {
        let path = [...this.$ifNull(this.item.path, [])];
        path.push(category.name);
        category.path = path;
        return category;
      });
    }
  },
  watch: {
    selected(newVal) {
      this.selectedCategory = newVal;
    }
  }
};
</script>
<style scoped>
.a:visited {
  color: #17a2b8;
}
.navbar-brand-dropdown {
  padding: 0 15px 0 15px;
}
</style>
