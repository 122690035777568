<template>
  <div>
    <base-header
      title="Novedades de Mercado Libre"
      :map="['Mercado Libre', 'Integraciones', 'Novedades']"
    >
    </base-header>
    <v-select
      v-if="news && news.length != 0"
      class="m-2"
      v-model="selected"
      :options="options"
      :reduce="option => option.code"
      label="label"
      placeholder="Filtrar por tipo de novedad"
    ></v-select>
    <b-spinner v-if="!copyNews" label="Spinning"></b-spinner>
    <b-alert
      class="mx-2"
      show
      varaint="info"
      v-if="copyNews && copyNews.length == 0"
    >
      No tienes novedades
      {{
        selected ? "para el tipo " + typeTraduction[selected].toLowerCase() : ""
      }}
    </b-alert>
    <div v-for="notice of copyNews" :key="notice.id">
      <mercado-libre-new
        :notice="notice"
        :typeTraduction="typeTraduction"
        :tagsTraduction="tagsTraduction"
      ></mercado-libre-new>
    </div>
    <b-button
      v-if="copyNews && copyNews.length > 0 && limit + offset < total"
      class="m-2 float-right"
      @click="seeMore()"
    >
      Ver más
    </b-button>
  </div>
</template>

<script>
import BaseHeader from "../components/BaseHeader.vue";
import MercadoLibreNew from "../components/MercadoLibreNew.vue";
import allNovedades from "../graphql/AllNews.gql";

export default {
  name: "MercadoLibreNewsIndex",
  components: { BaseHeader, MercadoLibreNew },
  props: {
    integrationConfigId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      selected: null,
      typeTraduction: {
        EVENTS: "Eventos",
        BILLING: "Facturación",
        SHIPPING: "Envios",
        PUBLICATIONS: "Publicaciones",
        COUNTRIES: "Países",
        ATTENTION: "Atención"
      },
      options: [
        {
          code: "EVENTS",
          label: "Eventos"
        },
        {
          code: "BILLING",
          label: "Facturación"
        },
        {
          code: "SHIPPING",
          label: "Envios"
        },
        {
          code: "PUBLICATIONS",
          label: "Publicaciones"
        },
        {
          code: "COUNTRIES",
          label: "Países"
        },
        {
          code: "ATTENTION",
          label: "Atención"
        }
      ],
      tagsTraduction: {
        EVENTS: {
          CHRISTMAS: "Navidad",
          BLACK_FRIDAY: "Black Friday",
          HOT_SALE: "Hot Sale",
          CYBER_MONDAY: "Cyber Monday",
          COVID: "COVID-19"
        },
        BILLING: {
          COST: "Costos",
          BILLING: "Facturación",
          TRANSMITTER: "Emisor de NF-e"
        },
        SHIPPING: {
          SHIPPING_GENERIC: "Envíos",
          SHIPPING: "Mercado Envíos",
          SHIPPING_XD: "Mercado Envíos colecta",
          FLEX: "Flex",
          FULL: "Full",
          SHIPPING_CARRIER: "Mercado Envíos Partnered Carrier"
        },
        PUBLICATIONS: {
          PUBLICATIONS: "Publicaciones",
          PROMOTIONS_CENTRAL: "Central de promociones",
          CATALOG: "Catálogo"
        },
        COUNTRIES: {
          MCO: "Colombia",
          MLC_FULFILLMENT: "Chile - Full",
          MLC_REMOTE: "Chile",
          MLB: "Brasil",
          MLM_REMOTE: "México",
          MLM_FULFILLMENT: "México - Full"
        },
        ATTENTION: {
          METRICS: "Métricas",
          CANCELLATIONS: "Cancelaciones",
          RETURNS: "Devoluciones",
          CLAIMS: "Reclamos",
          REPUTATION: "Reputación"
        }
      },
      news: null,
      copyNews: null,
      limit: 10,
      offset: 0,
      total: null
    };
  },
  watch: {
    selected(newSelected) {
      console.log(newSelected);
      this.filterNews(newSelected);
    }
  },
  methods: {
    seeMore() {
      this.$apollo
        .query({
          query: allNovedades,
          variables: {
            ic: this.integrationConfigId,
            limit: this.limit,
            offset: this.offset + 1
          }
        })
        .then(({ data }) => {
          this.offset += this.limit;
          this.news.push(data.allNovedades.body["results"]);
          this.filterNews(this.selected);
        });
    },
    filterNews(tag) {
      if (tag == null) {
        this.copyNews = this.news;
      } else {
        this.copyNews = [];
        this.news.map(notice => {
          for (let newTag of notice.tags) {
            if (newTag["type"] == tag) {
              this.copyNews.push(notice);
              break;
            }
          }
        });
      }
    }
  },
  created() {
    this.$apollo
      .query({
        query: allNovedades,
        variables: {
          ic: this.integrationConfigId,
          limit: this.limit,
          offset: this.offset
        }
      })
      .then(({ data }) => {
        this.news = data.allNovedades.body["results"];
        this.copyNews = data.allNovedades.body["results"];
        this.total = data.allNovedades.body["paging"]["total"];
        this.offset += this.limit;
      });
  }
};
</script>

<style scoped></style>
