<template>
  <div>
    <image-gallery-component
      title=""
      styleCard="height: 300px;"
      :productsWithErrors="{}"
      :imagesWithErrors="imagesWithErrors"
      :loading="loading"
      v-model="defaultImages"
      :mutation="mutation"
      :getKeyDataMutation="getKeyDataMutation"
      @change="changeDefaultImages"
      @updateModalShow="updateModalShow"
      @setProductsWithErrors="setProductsWithErrors"
    />
    <image-gallery-errors-modal
      v-model="modalShow"
      :productsWithErrors="{}"
      :imagesWithErrors="imagesWithErrors"
    />
  </div>
</template>

<script>
import ImageGalleryComponent from "../../../ProductImageManager/ImageGalleryComponent.vue";
import ImageGalleryErrorsModal from "../../../ProductImageManager/ImageGalleryErrorsModal.vue";
import CREATE_DEFAULT_IMAGES_CATALOG_SUGGESTION from "../../../../graphql/MercadoLibre/CatalogSuggestions/CreateDefaultImages.gql";

export default {
  name: "MercadoLibreCatalogSuggestionFormDefaultImageGallery",
  components: {
    ImageGalleryComponent,
    ImageGalleryErrorsModal
  },
  model: {
    prop: "_defaultImages",
    event: "change"
  },
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    _defaultImages: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      imagesWithErrors: {},
      modalShow: false,
      defaultImages: this._defaultImages
    };
  },
  computed: {
    mutation() {
      return CREATE_DEFAULT_IMAGES_CATALOG_SUGGESTION;
    }
  },
  mounted() {},
  methods: {
    /**
     * Captura evento de mostrar o no modal de errores
     */
    updateModalShow() {
      this.modalShow = !this.modalShow;
    },
    /**
     * En este caso no se necesita manejar este arreglo
     */
    setProductsWithErrors() {
      return;
    },
    /**
     * Entrega cual es la llave que se debe leer en la mutacion
     * de creacion de imagenes
     */
    getKeyDataMutation(data) {
      return data.mercadoLibreCatalogSuggestionCreateDefaultImages;
    },
    /**
     * Emite cambios sobre las imagenes
     * @param {Array<Object>} data
     */
    changeDefaultImages(data) {
      this.$emit("change", data);
    }
  },
  watch: {
    _defaultImages(newValue) {
      this.defaultImages = newValue;
      this.$emit("change", newValue);
    },
    defaultImages(newValue) {
      this.$emit("change", newValue);
    }
  }
};
</script>
