<template>
  <div>
    <b-alert
      v-if="error || success"
      show=""
      :variant="alertVariant"
      dismissible
    >
      {{ alertMessage }}
    </b-alert>

    <div align-h="between">
      <b-row>
        <b-col xl="10" cols="12">
          <b-row class="mb-2">
            <b-col cols="12">
              <products-now-excluded-form
                :invalid-state="invalidState"
                v-model="previousProductsNowExcluded"
              />
            </b-col>
            <b-col>
              <h4 class="font-weight-bold my-auto">
                {{ allOptionsLabel }}
              </h4>
            </b-col>
            <b-col>
              <h4 class="font-weight-bold my-auto">
                {{ selectedOptionsLabel }}
              </h4>
            </b-col>
          </b-row>
          <b-spinner v-if="loading" />
          <div v-else>
            <vMultiselectListbox
              ref="exclusionSelector"
              style="min-width: 100%"
              v-model="selectedOptions"
              :options="options"
              :reduce-display-property="option => option.name"
              :reduce-value-property="option => option.id"
              @diff-changed="changeSelecteds"
              :highlightDiff="true"
              :search-options-placeholder="searchOptionsPlaceholder"
              :selected-options-placeholder="selectedOptionsPlaceholder"
              :no-options-text="noOptionsText"
              :selected-no-options-text="selectedNoOptionsText"
              :no-options-found-text="noOptionsFoundText"
              :no-selected-options-found-text="noSelectedOptionsFoundText"
            >
            </vMultiselectListbox>
            <b-form-checkbox v-model="excludedProductsWithout" class="mt-2">
              {{ labelExcludedProductsWithout }}
            </b-form-checkbox>
          </div>
        </b-col>

        <b-col xl="2" cols="12">
          <b-button
            variant="info"
            :disabled="!changed || saving"
            :title="changed ? '' : 'No hay cambios'"
            @click="save"
            class="mb-3 w-100"
          >
            <span v-if="saving"><b-spinner label="Spinning"></b-spinner></span>
            <span v-else>Guardar</span>
          </b-button>
          <b-button
            variant="outline-info"
            class="w-100 px-0"
            :disabled="!changed || saving"
            @click="resetData()"
          >
            <span>Restablecer</span>
          </b-button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import UPDATE_INTEGRATION_CONFIG_EXCLUSION from "../../graphql/IntegrationConfig/UpdateIntegrationConfigExclusion.gql";

import ProductsNowExcludedForm from "./ProductsNowExcludedForm.vue";
import vMultiselectListbox from "vue-multiselect-listbox";
import "vue-multiselect-listbox/dist/vue-multi-select-listbox.css";
import { mapMutations, mapState } from "vuex";

export default {
  name: "BaseExlcusions",
  components: {
    vMultiselectListbox,
    ProductsNowExcludedForm
  },
  props: {
    ic: {
      type: Object,
      default() {
        return {};
      }
    },
    visible: {
      type: Boolean,
      default: false
    },
    baseOptions: {
      type: Array,
      default() {
        return [];
      }
    },
    baseSelectedOptions: {
      type: Array,
      default() {
        return [];
      }
    },
    baseExcludedProductsWithout: {
      type: Boolean,
      default: null
    },
    fieldToExclude: {
      type: String,
      default: ""
    },
    fieldToInclude: {
      type: String,
      default: ""
    },
    allOptionsLabel: {
      type: String,
      default: "Todas las opciones"
    },
    selectedOptionsLabel: {
      type: String,
      default: "Opciones seleccionadas"
    },
    noOptionsText: {
      type: String,
      default: "No hay opciones"
    },
    selectedNoOptionsText: {
      type: String,
      default: "No hay opciones seleccionadas"
    },
    noOptionsFoundText: {
      type: String,
      default: "No se encontró la opción"
    },
    noSelectedOptionsFoundText: {
      type: String,
      default: "No se encontró la opción seleccionada"
    },
    searchOptionsPlaceholder: {
      type: String,
      default: "Buscar"
    },
    selectedOptionsPlaceholder: {
      type: String,
      default: "Buscar"
    },
    labelExcludedProductsWithout: {
      type: String,
      default: "Excluir productos"
    },
    fieldExcludedProductsWithout: {
      type: String,
      defualt: ""
    }
  },
  computed: {
    ...mapState(["currentUser"]),
    changed() {
      return (
        this.valuesToExclude.length != 0 ||
        this.valuesToInclude.length != 0 ||
        this.excludedProductsWithout != this.originalExcludedProductsWithout
      );
    },
    invalidState() {
      return (
        (this.valuesToExclude.length != 0 ||
          (this.excludedProductsWithout !==
            this.originalExcludedProductsWithout &&
            this.excludedProductsWithout)) &&
        !this.previousProductsNowExcluded
      );
    },
    hasLoad() {
      return (
        Array.isArray(this.selectedOptions) &&
        Array.isArray(this.options) &&
        this.excludedProductsWithout != null
      );
    },
    alertVariant() {
      return this.error ? "danger" : "success";
    },

    alertMessage() {
      return this.error
        ? "Ha ocurrido un error guardando los datos: " + this.errorMessage
        : "Guardado exitosamente";
    }
  },
  data() {
    return {
      options: null,
      selectedOptions: null,
      originalSelectedOptions: [],
      valuesToExclude: [],
      valuesToInclude: [],
      loading: true,
      saving: false,
      errorMessage: null,
      error: false,
      success: false,
      previousProductsNowExcluded: null,
      excludedProductsWithout: null,
      originalExcludedProductsWithout: null
    };
  },
  methods: {
    ...mapMutations(["setIntegrationConfigChanged"]),
    /**
     * Envia la mutacion para guardar las nuevas
     * exclusiones para marca, categoria y generos
     */
    save() {
      this.saving = true;
      this.sofResetData();
      if (!this.invalidState) {
        let patch = { id: this.ic.id };
        patch[this.fieldToInclude] = this.valuesToInclude;
        patch[this.fieldToExclude] = this.valuesToExclude;
        patch[this.fieldExcludedProductsWithout] = this.excludedProductsWithout;
        this.$apollo
          .mutate({
            mutation: UPDATE_INTEGRATION_CONFIG_EXCLUSION,
            variables: {
              patch: patch,
              previousProductsNowExcluded: this.previousProductsNowExcluded
            }
          })
          .then(({ data }) => {
            let response = data.updateIntegrationConfigExclusions;
            if (response.result) {
              this.saving = false;
              this.originalSelectedOptions = this.$dup(this.selectedOptions);
              this.originalExcludedProductsWithout = this.excludedProductsWithout;
              this.$refs.exclusionSelector.resetOriginalCopy();
              this.valuesToExclude = [];
              this.valuesToInclude = [];
              this.previousProductsNowExcluded = null;
              this.success = true;
            } else {
              this.saving = false;
              this.errorMessage = response.errors;
              this.error = true;
            }
          });
      } else {
        this.saving = false;
        this.errorMessage = "Hay campos que se deben rellenar";
        this.error = true;
      }
    },
    /**
     * Actualiza los nuevos valores incluidos y excluidos
     * @param {Obejct} evt - objecto con valores seleccionadas y deseleccionados
     */
    changeSelecteds(evt) {
      this.valuesToExclude = this.$dup(evt.newSelected);
      this.valuesToInclude = this.$dup(evt.newUnselected);
    },
    /**
     * Se encarga de resetear los datos
     */
    resetData() {
      this.loading = true;
      this.selectedOptions = this.$dup(this.originalSelectedOptions);
      this.excludedProductsWithout = this.originalExcludedProductsWithout;
      this.previousProductsNowExcluded = null;
      this.valuesToExclude = [];
      this.valuesToInclude = [];
      this.sofResetData();
      this.loading = false;
    },
    /**
     * Se encarga de resetear solo las
     * variables de exito y error
     */
    sofResetData() {
      this.error = false;
      this.success = false;
      this.errorMessage = null;
    }
  },
  watch: {
    changed(val) {
      if (!val) {
        this.previousProductsNowExcluded = null;
      }
      this.setIntegrationConfigChanged(val);
    },
    baseOptions(val) {
      if (Array.isArray(val)) {
        this.options = this.$dup(val);
        this.originalSelectedOptions = this.$dup(val);
      }
    },
    baseSelectedOptions(val) {
      if (Array.isArray(val)) {
        this.selectedOptions = this.$dup(val);
        this.originalSelectedOptions = this.$dup(val);
      }
    },
    baseExcludedProductsWithout(val) {
      this.excludedProductsWithout = val;
      this.originalExcludedProductsWithout = val;
    },
    hasLoad(val) {
      if (val) {
        this.loading = false;
      }
    },
    visible(val) {
      if (val) {
        this.resetData();
      }
    }
  }
};
</script>

<style lang="scss">
.msl-searchable-list__item {
  font-size: 0.9em;
  padding: 5px 10px;
  cursor: pointer;
  border-bottom: 1px solid #252b45;
  &:hover {
    background: #17a2b8;
    color: #fff;
  }
}
.msl-searchable-list__item--removed {
  background: #f4c7c3;
  color: black;
}

.msl-searchable-list__item--added {
  background: #b7e1cd;
  color: black;
}
</style>
