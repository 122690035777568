<template>
  <div class="d-inline-block">
    <b-button
      :disabled="disabled"
      variant="outline-info"
      :title="title"
      @click="openModal"
    >
      <b-icon-bell></b-icon-bell>
      Notificar
    </b-button>
    <br /><br />
    <b-modal :ref="'notificar'" :id="'notificar'" ok-disabled="" size="lg">
      <template v-slot:modal-title>
        <h4>Notificar Pedidos</h4>
      </template>

      <b-container fluid>
        <b-form-checkbox v-model="selectAll">
          <h3>Webhooks</h3>
        </b-form-checkbox>

        <br />

        <b-form-checkbox-group
          v-model="visibleColumns"
          :required="true"
          name="columns"
          stacked
        >
          <b-form-checkbox v-for="w of webHooks" :key="w.id" :value="w.id"
            ><b-row>
              <b-col cols="12" class="font-weight-bolder">{{
                w.application ? "  " + w.application.publicName : ""
              }}</b-col>
              <b-col cols="12">{{ w.callbackUrl }} </b-col>
            </b-row>
            <br />
          </b-form-checkbox>
        </b-form-checkbox-group>
      </b-container>
      <template v-slot:modal-footer>
        <b-button variant="info" @click="confirmNotify"> Notificar </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { mapState } from "vuex";
import ALL_WEBHOOKS from "../../graphql/AllWebhooks.gql";

import WEBHOOK_ORDER_REQUEUE_EXEC from "../../graphql/WebhookOrderRequeueExec.gql";
export default {
  name: "OrderWeebHookNotifyButton",
  components: {},
  props: {
    disabled: Boolean,
    title: String
  },
  data() {
    return this.getDefaultData();
  },
  computed: {
    ...mapState(["selectedOrders", "currentUser"])
  },
  methods: {
    resetData() {
      Object.assign(this.$data, this.$options.data.call(this));
    },
    openModal() {
      this.$apollo
        .query({
          query: ALL_WEBHOOKS,
          variables: {
            onOrderSave: true,
            companyId: this.currentUser.company.id
          }
        })
        .then(({ data }) => {
          if (data) {
            this.resetData();
            data.allWebhooks.edges.forEach(x => {
              this.webHooks.push(x.node);
            });
          }
        });

      this.$refs["notificar"].show();
    },
    getDefaultData() {
      return {
        selectAll: false,
        loading: false,
        webHooks: [],
        webHooksMap: {},
        visibleColumns: [],
        first: true
      };
    },
    confirmNotify() {
      this.$apollo
        .mutate({
          mutation: WEBHOOK_ORDER_REQUEUE_EXEC,
          variables: {
            webhookIds: this.visibleColumns,
            orderIds: Object.keys(this.selectedOrders)
          }
        })
        .then(({ data }) => {
          this.resetData();
          let valid = data.webhookOrderRequeueExec.result;
          this.$refs["notificar"].hide();
          if (valid) {
            this.$swal.fire({
              title: "Notificación exitosa",
              text: "Se han notificado los pedidos de forma exitosa",
              icon: "success",
              confirmButtonText: "Aceptar"
            });
          } else {
            this.$swal.fire({
              title: "Notificación fallida",
              text: "No se han podido notificar los pedidos",
              icon: "error",
              confirmButtonText: "Aceptar"
            });
          }
        })
        .catch(() => {
          this.$swal.fire({
            title: "Notificación fallida",
            text: "Fallo al enviar formulario",
            icon: "error",
            confirmButtonText: "Aceptar"
          });
        });
    }
  },

  watch: {
    selectAll(value) {
      if (value) {
        this.visibleColumns = this.webHooks.map(w => w.id);
      } else {
        if (this.visibleColumns.length === this.webHooks.length) {
          this.visibleColumns = [];
        }
      }
    },
    visibleColumns(value) {
      if (value.length !== this.webHooks.length) {
        this.selectAll = false;
      }
    }
  }
};
</script>
