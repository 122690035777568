<template>
  <div>
    <ApolloQuery
      :query="require('../graphql/Product.gql')"
      :variables="queryVariables()"
      @result="updateImageFilter"
      :fetchPolicy="'cache-and-network'"
      notifyOnNetworkStatusChange
    >
      <template slot-scope="{ result: { data }, isLoading }">
        <base-header
          title="Productos"
          title-link="/products"
          :titleSize="0"
          :map="[$route.meta.action, $dig(data, 'product', 'name')]"
        >
          <template v-slot:actions>
            <b-container v-if="data && $dig(data, 'product', '_type') != 'new'">
              <b-dropdown variant="outline-info" text="Ver más">
                <b-dropdown-item
                  v-if="$route.meta.action !== 'Detalle'"
                  :to="{
                    name: 'ProductDetail',
                    params: { id: data.product.id }
                  }"
                >
                  Detalle
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="$route.meta.action !== 'Editar'"
                  :to="`/product/${data.product.id}/edit`"
                >
                  Editar
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="$route.meta.action !== 'Administrador de imágenes'"
                  :to="{
                    name: 'ProductImageManager',
                    query: { productId: data.product.id }
                  }"
                >
                  Imágenes
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="$route.meta.action !== 'Inventario'"
                  :to="'/product/' + data.product.id + '/inventory'"
                >
                  Variantes
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="$route.meta.action !== 'Movimientos'"
                  :to="{
                    name: 'ProductTransactions',
                    params: { id: data.product.id }
                  }"
                >
                  Movimientos
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="$route.meta.action !== 'Sincronizaciones'"
                  :to="{
                    name: 'ProductHistories',
                    params: { id: data.product.id }
                  }"
                >
                  Sincronizaciones
                </b-dropdown-item>
                <b-dropdown-item
                  :href="
                    centryUrl + '/productos/' + data.product.id + '/duplicate'
                  "
                >
                  Duplicar
                </b-dropdown-item>
              </b-dropdown>
            </b-container>
          </template>
          <template v-slot:filters>
            <transactions-filters
              v-if="$route.meta.action === 'Movimientos'"
              :product="data.product"
            ></transactions-filters>
            <b-form-group
              content-cols="3"
              v-if="$route.meta.action === 'Administrador de imágenes'"
              v-slot="{ ariaDescribedby }"
            >
              <products-filter
                :aria-describedby="ariaDescribedby"
                :phrases.sync="productFilters.phrases"
                :showAdvancedButton="false"
              ></products-filter>
            </b-form-group>
          </template>
        </base-header>
        <b-spinner
          v-if="isLoading"
          label="Spinning"
          class="m-2 float-right"
        ></b-spinner>
        <b-container fluid="">
          <router-view
            v-if="data && !isLoading"
            :product="data.product"
            :filters="productFilters"
          ></router-view>
        </b-container>
      </template>
    </ApolloQuery>
  </div>
</template>

<script>
import BaseHeader from "../components/BaseHeader.vue";
import { centryUrl } from "../main";
import TransactionsFilters from "../components/TransactionsFilters";
import ProductsFilter from "../components/ProductsFilter.vue";

export default {
  name: "Product",
  components: {
    TransactionsFilters,
    BaseHeader,
    ProductsFilter
  },
  data() {
    return {
      productFilters: {
        categoriesSelected: [],
        brandsSelected: [],
        phrases: [],
        active: true,
        inactive: true,
        noStock: false
      },
      centryUrl
    };
  },
  methods: {
    /**
     * Se actualiza el parametro phrases de filters para
     * indicar el sku en la vista del administrador de imagenes
     * @param {Object} result - Resultadod e la query de producto
     */
    updateImageFilter(result) {
      if (result.data?.product?.sku) {
        this.productFilters.phrases.push(result.data?.product?.sku);
      }
    },
    /**
     * Entrega las variables para la query que permite obtener el producto
     */
    queryVariables() {
      let variables = {};
      variables.id = "new";
      if (this.$route.name === "ProductImageManager") {
        if (this.$route.query.productId) {
          variables.id = this.$route.query.productId;
        }
      } else {
        if (this.$route.params.id) {
          variables.id = this.$route.params.id;
        }
      }
      return variables;
    }
  }
};
</script>
