<template>
  <div>
    <b-row>
      <b-col>
        <b-input-group>
          <b-input-group-prepend is-text>
            <b-icon-search></b-icon-search>
          </b-input-group-prepend>
          <b-form-tags
            v-model="keywords"
            separator=","
            remove-on-delete=""
            placeholder="Palabras claves (Ej: adidas)"
          ></b-form-tags>
        </b-input-group>
      </b-col>
    </b-row>

    <br />

    <b-spinner v-if="loading" label="Spinning"></b-spinner>
    <div v-else-if="brandsList">
      <b-row>
        <b-col>
          <b-table-simple>
            <b-thead>
              <b-tr>
                <b-th class="col-md-2">Nombre</b-th>
                <b-th class="col-md-6">Descripción</b-th>
                <b-th class="col-md-3 text-center">Activo</b-th>
                <b-th class="col-md-1">Acciones</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="brand in brandsList" :key="brand.id">
                <b-td>{{ brand.name }}</b-td>
                <b-td>{{ brand.metaTagDescription }}</b-td>
                <b-td class="text-center">{{ brand.isActive }}</b-td>
                <b-td>
                  <b-dropdown variant="white" no-caret="" offset="-110">
                    <template v-slot:button-content>
                      <b-icon-three-dots-vertical></b-icon-three-dots-vertical>
                    </template>
                    <b-dropdown-item @click="updateBrand(brand.id)">
                      Editar
                    </b-dropdown-item>
                    <b-dropdown-item @click.prevent="confirmDeleteBrand(brand)">
                      Eliminar
                    </b-dropdown-item>
                  </b-dropdown>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>

      <b-row align-h="center">
        <b-pagination
          v-if="searched"
          v-model="page"
          :total-rows="totalBrands"
          :per-page="pageSize"
          @change="changePageForSearchedBrandList"
        ></b-pagination>
        <b-pagination
          v-else
          v-model="page"
          :total-rows="totalBrands"
          :per-page="pageSize"
          @change="changePage"
        ></b-pagination>
      </b-row>
    </div>
  </div>
</template>

<script>
import INTEGRATION_CONFIG_VTEX_BRANDS_LIST from "@/graphql/IntegrationConfigVtexBrandsList.gql";
import INTEGRATION_CONFIG_VTEX_BRANDS_LIST_PER_PAGE from "@/graphql/IntegrationConfigVtexBrandsListPerPage.gql";
import VTEX_DELETE_BRAND from "@/graphql/VtexDeleteBrand.gql";

export default {
  name: "VtexBrandsMaintainerTable",
  props: {
    vtexIntegrationConfigId: String
  },
  data() {
    return {
      loading: true,
      brandsList: null,
      auxBrandsList: null,
      keywords: [],
      searched: false,
      pageSize: 10,
      page: 1,
      totalBrands: 0
    };
  },
  mounted: async function() {
    if (this.vtexIntegrationConfigId) {
      await this.getVtexIntegrationConfigBrandsListPerPage();
    }
  },
  methods: {
    /**
     * Obtiene el listado de marcas existentes en Vtex según los valores
     * de página entregados
     */
    async getVtexIntegrationConfigBrandsListPerPage() {
      await this.$apollo
        .query({
          query: INTEGRATION_CONFIG_VTEX_BRANDS_LIST_PER_PAGE,
          variables: {
            id: this.vtexIntegrationConfigId,
            pageSize: this.pageSize,
            page: this.page
          }
        })
        .then(({ data }) => {
          if (
            !this.$dig(
              data,
              "integrationConfigVtex",
              "brandsListPerPage",
              "brands"
            )
          ) {
            this.emitErrorMessage("No se pudo obtener el listado de marcas");
          } else {
            this.brandsList =
              data.integrationConfigVtex.brandsListPerPage.brands;
            this.totalBrands =
              data.integrationConfigVtex.brandsListPerPage.paging.total;
          }
          this.loading = false;
        })
        .catch(() => {
          this.emitErrorMessage("No se pudo obtener el listado de marcas");
        });
    },
    /**
     * Obtiene los valores de marca de Vtex según los valores de palabras
     * claves entregadas
     */
    async getVtexIntegrationConfigBrandsListFilterByKeywords() {
      await this.$apollo
        .query({
          query: INTEGRATION_CONFIG_VTEX_BRANDS_LIST,
          variables: {
            id: this.vtexIntegrationConfigId,
            keywords: this.keywords.length > 0 ? this.keywords : null
          }
        })
        .then(({ data }) => {
          if (!this.$dig(data, "integrationConfigVtex", "brandsList")) {
            this.emitErrorMessage("No se pudo obtener el listado de marcas");
          } else {
            this.auxBrandsList = data.integrationConfigVtex.brandsList;
            this.brandsList = data.integrationConfigVtex.brandsList.slice(
              0,
              this.pageSize
            );
            this.page = 1;
            this.totalBrands = data.integrationConfigVtex.brandsList.length;
          }
          this.loading = false;
        })
        .catch(() => {
          this.emitErrorMessage("No se pudo obtener el listado de marcas");
        });
    },
    /**
     * Emite el evento 'updateBrand' con el id de la marca a ser editada.
     * @param {String} brandId
     */
    updateBrand(brandId) {
      this.$emit("updateBrand", brandId);
    },
    /**
     * Abre en la vista una ventana pidiendo la confirmación de la eliminación
     * de una marca. En caso de ser aceptado, se manda a eliminar.
     * @param {Object} brand
     */
    async confirmDeleteBrand(brand) {
      document.activeElement.blur();
      let result = await this.$swal.fire({
        title: "Eliminar",
        text: `¿Estás seguro de eliminar la marca ${brand.name}?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si",
        cancelButtonText: "No"
      });
      if (result.value) {
        await this.deleteBrand(brand.id);
      }
    },
    /**
     * Realiza la petición para eliminar una marca en Vtex.
     * @param {String} brandId
     */
    async deleteBrand(brandId) {
      await this.$apollo
        .mutate({
          mutation: VTEX_DELETE_BRAND,
          variables: {
            integrationConfigId: this.vtexIntegrationConfigId,
            brandId: brandId
          }
        })
        .then(({ data }) => {
          if (!this.$dig(data, "vtexDeleteBrand", "success")) {
            this.emitErrorMessage("No se pudo eliminar la marca");
          } else {
            this.emitSuccessfulMessage(
              "Marca eliminada con éxito. Puede tardar unos minutos en reflejarse en el listado."
            );
          }
        })
        .catch(() => {
          this.emitErrorMessage("No se pudo eliminar la marca");
        });
      this.$emit("scrollToTop");
    },
    /**
     * Emite el evento 'errorMessage' con el mensaje de error correspondiente
     * @param {String} errorMessage
     */
    emitErrorMessage(errorMessage = null) {
      this.$emit("errorMessage", errorMessage);
    },
    /**
     * Emite el evento 'succesfulMessage' con el mensaje de exito correspondiente
     * @param {String} message
     */
    emitSuccessfulMessage(message) {
      this.$emit("successfulMessage", message);
    },
    /**
     * Cambia la pagina del listado de marcas. Utiliza directamente la paginación
     * dada por la API de Vtex.
     * @param {Integer} page
     */
    changePage(page) {
      this.page = page;
      this.loading = true;
      this.getVtexIntegrationConfigBrandsListPerPage();
    },
    /**
     * Cambia la pagina del listado de marcas. Se utiliza cuando existe alguna
     * clave en el buscador.
     * @param {Integer} page
     */
    changePageForSearchedBrandList(page) {
      this.page = page;
      let offset = (page - 1) * this.pageSize;
      this.brandsList = this.auxBrandsList.slice(
        offset,
        offset + this.pageSize
      );
    }
  },
  watch: {
    keywords: async function(newKeywords) {
      if (newKeywords.length > 0) {
        this.searched = true;
      } else {
        this.searched = false;
      }
      this.loading = true;
      await this.getVtexIntegrationConfigBrandsListFilterByKeywords();
    }
  }
};
</script>
