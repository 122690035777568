<template>
  <div>
    <b-form-group>
      <b-dropdown variant="danger" class="w-100" :disabled="!template.id">
        <template #button-content>
          <span v-if="loading"><b-spinner /> </span>
          <span v-else>Descargar </span>
        </template>
        <b-dropdown-item @click="download(true)"
          >Template con productos</b-dropdown-item
        >
        <b-dropdown-item @click="download(false)"
          >Template con ejemplo</b-dropdown-item
        >
      </b-dropdown>
    </b-form-group>
    <modal-download
      :use-products="useProducts"
      :show-category="showCategory"
      :integrations="integrations"
      :warehouses="warehouses"
      :all-brands="allBrands"
      :all-pii="allPii"
      :company-categories="allCategories"
      :global="global"
      @download="variables => downloadBulkUpload(useProducts, variables)"
    />
  </div>
</template>
<script>
import DOWNLOAD_BULK_UPLOAD_TEMPLATE from "../../../graphql/BulkUpload/TemplateDownload/DownloadBulkUploadTemplate.gql";
import IC_AND_WH_FOR_TEMPLATE from "../../../graphql/BulkUpload/TemplateDownload/IntegrationsAndWarehousesForTemplate.gql";
import ALL_PII_BY_COMPANY from "../../../graphql/AllPublicIntegrationInformation.gql";

import ALL_BRANDS from "../../../graphql/AllBrands.gql";
import ALL_CATEGORIES from "../../../graphql/AllCategories.gql";
import ModalDownload from "./ModalDownload.vue";
import { mapState } from "vuex";
export default {
  name: "DownloadBulkUpload",
  components: {
    ModalDownload
  },
  props: {
    template: {
      type: Object,
      default() {
        return { global: false, id: null, admin: false, type: null };
      }
    }
  },
  data() {
    return {
      errorMessage: null,
      error: false,
      useProducts: false,
      global: false,
      showCategory: false,
      integrations: {},
      warehouses: [],
      allBrands: [],
      allCategories: [],
      showModal: false,
      loading: false,
      allPii: []
    };
  },
  computed: {
    ...mapState(["currentUser"])
  },
  methods: {
    /**
     * Obtiene los datos necesarios para el modal segun el template seleccionado
     * @param {Boolean} useProducts - indica si es de ejemplo o con productos
     */
    async download(useProducts) {
      this.global = false;
      this.showCategory = false;
      this.useProducts = useProducts;
      this.loading = true;
      if (!this.template.global && !this.template.admin && !useProducts) {
        this.downloadBulkUpload(useProducts);
        this.loading = false;
        return;
      } else if (this.template.global || this.template.admin) {
        this.global = true;
        if (this.template.type == "category_attribute") {
          this.showCategory = true;
          await this.getPii();
          await this.getCategories();
        } else {
          await this.getIcAndWh();
        }
      }
      if (useProducts && !this.showCategory) {
        await this.getBrands();
      }

      if (
        !useProducts &&
        !this.showCategory &&
        this.warehouses.length == 0 &&
        Object.keys(this.integrations).length == 0
      ) {
        this.downloadBulkUpload(useProducts);
        this.loading = false;
        return;
      }
      this.loading = false;
      this.$bvModal.show("download-bulk-upload-template");
    },
    /**
     * Se encarga de enviar la mutacion para empezar con la descarga de la planilla
     * @param {Boolean} useProducts - indica si es de ejemplo o nno
     * @param {Object} newVariables - variables extras
     */
    downloadBulkUpload(useProducts, newVariables = {}) {
      let variables = Object.assign(
        {},
        {
          templateId: this.template.id,
          useProducts: useProducts
        },
        newVariables
      );
      this.$apollo
        .mutate({
          mutation: DOWNLOAD_BULK_UPLOAD_TEMPLATE,
          variables: variables
        })
        .then(({ data }) => {
          if (data.downloadBulkUploadTemplate.result) {
            this.$emit("success-download");
          } else {
            this.$emit("error", data.downloadBulkUploadTemplate.error);
          }
        })
        .catch(error => {
          this.$emit("error", error);
        })
        .finally(() => {
          this.$bvModal.hide("download-bulk-upload-template");
        });
    },
    /**
     * Obtiene las integraciones y bodegas para mostrar en el modal
     */
    async getIcAndWh() {
      await this.$apollo
        .query({
          query: IC_AND_WH_FOR_TEMPLATE,
          variables: {
            id: this.template.id
          }
        })
        .then(({ data }) => {
          if (!data || !data.bulkUploadTemplate) return;
          let integrations = {};
          if (data.bulkUploadTemplate.integrations) {
            data.bulkUploadTemplate.integrations.forEach(ic => {
              let pii = ic?.publicIntegrationInformation?.name;
              if (!integrations[pii]) {
                integrations[pii] = [{ id: ic.id, name: ic.fullLabel }];
              } else {
                integrations[pii].push({ id: ic.id, name: ic.fullLabel });
              }
            });
          }
          let warehouses = [];
          if (data.bulkUploadTemplate.warehouses) {
            data.bulkUploadTemplate.warehouses.forEach(wh => {
              warehouses.push({ id: wh.id, name: wh.name });
            });
          }
          this.integrations = integrations;
          this.warehouses = warehouses;
        });
    },
    /**
     * Obtiene todas las marcas de la compañia
     */
    async getBrands() {
      await this.$getAllPages(
        ALL_BRANDS,
        {
          companyId: this.currentUser.company.id
        },
        "allBrands"
      ).then(data => {
        if (!data) {
          this.allBrands = [];
        } else {
          this.allBrands = data.map(edge => edge.node);
        }
      });
    },
    /**
     * Obtiene todas las categorias de la compañia
     */
    async getCategories() {
      await this.$getAllPages(
        ALL_CATEGORIES,
        {
          companyId: this.currentUser.company.id
        },
        "allCategories"
      ).then(data => {
        if (!data) {
          this.allCategories = [];
        } else {
          this.allCategories = data.map(edge => edge.node);
        }
      });
    },
    /**
     * Obtiene todas las integraciones de la compañia
     */ async getPii() {
      await this.$getAllPages(
        ALL_PII_BY_COMPANY,
        {
          companyId: this.currentUser.company.id
        },
        "allPublicIntegrationInformation"
      ).then(data => {
        if (!data) {
          this.allPii = [];
        } else {
          this.allPii = data.map(edge => edge.node);
        }
      });
    }
  }
};
</script>
