<template>
  <div>
    <b-form-group label="Modelo">
      <b-form-input v-model="model"></b-form-input>
    </b-form-group>
    <b-form-group label="Campo con ID">
      <b-form-input v-model="idField"></b-form-input>
    </b-form-group>
    <b-form-group label="Campo con nombre">
      <b-form-input v-model="nameField"></b-form-input>
    </b-form-group>
  </div>
</template>
<script>
export default {
  name: "OptionListFormDataBase",
  model: {
    prop: "data",
    event: "change"
  },
  props: {
    data: Object
  },
  data() {
    return {
      model: this.data.model,
      idField: this.data.idField,
      nameField: this.data.nameField
    };
  },
  methods: {
    emitChange() {
      this.$emit(
        "change",
        Object.assign({}, this.data, {
          model: this.model,
          idField: this.idField,
          nameField: this.nameField
        })
      );
    }
  },
  watch: {
    model() {
      this.emitChange();
    },
    idField() {
      this.emitChange();
    },
    nameField() {
      this.emitChange();
    }
  }
};
</script>
