<template>
  <div>
    <h4 class="font-weight-bold m-3">
      Listados de SKUs que tendran descuento
    </h4>

    <vtex-base-promotion-products-and-skus-data-modal
      v-model="showModal"
      :vtexIntegrationConfigId="vtexIntegrationConfigId"
      @skus="setSkus"
    />

    <b-row>
      <b-col class="border-right">
        <h3>Lista 1</h3>

        <br />

        <b-form-group label="SKUs*">
          <v-select
            multiple
            placeholder="Seleccione SKUs"
            v-model="selectedSkus1"
            :options="skus"
          ></v-select>
          <span v-if="requiredMessage.selectedSkus1" class="text-danger">
            Algun SKU es requerido
          </span>
        </b-form-group>

        <b-form-group>
          <b-button variant="outline-info" @click="showModal = !showModal">
            <b-icon-cloud-download></b-icon-cloud-download> Obtener SKUs
          </b-button>
        </b-form-group>

        <b-form-group label="Descuento*">
          <b-input-group append="%">
            <b-form-input
              type="number"
              min="0"
              max="100"
              v-model="percentualDiscount1"
            >
            </b-form-input>
          </b-input-group>
          <span v-if="requiredMessage.someDiscount" class="text-danger">
            Algun valor de descuento es requerido
          </span>
        </b-form-group>

        <b-form-group label="Cantidad minima de SKUs en el pedido*">
          <b-form-input type="number" min="0" v-model="minimumSkuQuantity">
          </b-form-input>
          <span v-if="requiredMessage.minimumSkuQuantity" class="text-danger">
            Una cantidad minima de SKUs es requerida
          </span>
        </b-form-group>
      </b-col>

      <b-col>
        <h3>Lista 2</h3>

        <br />

        <b-form-group label="SKUs*">
          <v-select
            multiple
            placeholder="Seleccione SKUs"
            v-model="selectedSkus2"
            :options="skus"
          ></v-select>
          <span v-if="requiredMessage.selectedSkus2" class="text-danger">
            Algun SKU es requerido
          </span>
        </b-form-group>

        <b-form-group>
          <b-button variant="outline-info" @click="showModal = !showModal">
            <b-icon-cloud-download></b-icon-cloud-download> Obtener SKUs
          </b-button>
        </b-form-group>

        <b-form-group label="Descuento">
          <b-input-group append="%">
            <b-form-input
              type="number"
              min="0"
              max="100"
              v-model="percentualDiscount2"
            >
            </b-form-input>
          </b-input-group>
          <span v-if="requiredMessage.someDiscount" class="text-danger">
            Algun valor de descuento es requerido
          </span>
        </b-form-group>
      </b-col>
    </b-row>

    <hr />
  </div>
</template>

<script>
import VtexBasePromotionProductsAndSkusDataModal from "../VtexBasePromotionProductsAndSkusDataModal.vue";

export default {
  name: "VtexPromotionComboItems",
  components: {
    VtexBasePromotionProductsAndSkusDataModal
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    vtexIntegrationConfigId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      promotion: this.value,
      selectedSkus1: [],
      percentualDiscount1: this.value.percentualDiscountValueList1,
      minimumSkuQuantity: this.value.minimumQuantityBuyTogether,
      selectedSkus2: [],
      percentualDiscount2: this.value.percentualDiscountValueList2,
      filledRequiredFields: false,
      skus: [],
      showModal: false
    };
  },
  /**
   * Se asignan los valores seleccionados de la promoción a los de la vista.
   */
  mounted() {
    this.setSelectedSkus();
  },
  methods: {
    /**
     * Revisa y selecciona los SKUs de la promoción.
     */
    setSelectedSkus() {
      if (this.promotion.listSku1BuyTogether) {
        this.selectedSkus1 = this.promotion.listSku1BuyTogether.map(sku => ({
          value: sku.id,
          label: sku.name + " (SKU " + sku.id + ")"
        }));
      }
      if (this.promotion.listSku2BuyTogether) {
        this.selectedSkus2 = this.promotion.listSku2BuyTogether.map(sku => ({
          value: sku.id,
          label: sku.name + " (SKU " + sku.id + ")"
        }));
      }
    },
    /**
     * Emite si los datos obligatorios han sido completados.
     * @param {Boolean} value - Valor a emitir.
     */
    emitFilledRequiredFields(value) {
      this.$emit("filledRequiredFields", value);
    },
    setSkus(skus) {
      this.skus = skus;
    }
  },
  computed: {
    /**
     * Revisa si los atributos obligatorios de la promoción en la vista estan completos
     * para saber donde mostrar los mensajes de requerido.
     * @returns {Object} - Objeto con los atributos obligatorios y si requieren mensaje.
     */
    requiredMessage() {
      let required = {};
      required.selectedSkus1 = this.selectedSkus1.length === 0;
      required.selectedSkus2 = this.selectedSkus2.length === 0;
      required.someDiscount =
        (!this.percentualDiscount1 ||
          parseFloat(this.percentualDiscount1) === 0) &&
        (!this.percentualDiscount2 ||
          parseFloat(this.percentualDiscount2) === 0);
      required.minimumSkuQuantity =
        !this.minimumSkuQuantity || parseInt(this.minimumSkuQuantity) === 0;
      return required;
    }
  },
  /**
   * En general, el objetivo de los watches es cambiar el valor de una propiedad
   * de la promocion cuando se cambia su correspondiente en la vista
   */
  watch: {
    value(newValue) {
      this.promotion = newValue;
    },
    promotion: {
      handler(newValue) {
        this.$emit("input", newValue);
      },
      deep: true
    },
    selectedSkus1(newValue) {
      this.promotion.listSku1BuyTogether = newValue.map(selectedSku => {
        return {
          id: selectedSku.value,
          name: selectedSku.label
        };
      });
    },
    selectedSkus2(newValue) {
      this.promotion.listSku2BuyTogether = newValue.map(selectedSku => {
        return {
          id: selectedSku.value,
          name: selectedSku.label
        };
      });
    },
    percentualDiscount1(newValue) {
      this.promotion.percentualDiscountValueList1 = parseFloat(newValue);
    },
    percentualDiscount2(newValue) {
      this.promotion.percentualDiscountValueList2 = parseFloat(newValue);
    },
    minimumSkuQuantity(newValue) {
      this.promotion.minimumQuantityBuyTogether = parseInt(newValue);
    },
    /**
     * Si cambia el valor de requiredMessage, se revisa si no se necesita
     * ningun mensaje y se guarda este valor en filledRequiredFields.
     */
    requiredMessage: function(newValue) {
      this.filledRequiredFields = !Object.values(newValue).includes(true);
    },
    /**
     * Si cambia el valor de filledRequiredFields, se emite este valor al padre.
     * @param {Boolean} value - Valor a emitir.
     */
    filledRequiredFields: function(newValue) {
      this.emitFilledRequiredFields(newValue);
    }
  }
};
</script>
