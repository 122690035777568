<template>
  <div>
    <base-header
      title="Homologaciones de atributos en cuarentena"
      title-link="#"
      :title-size="6"
    >
      <template v-slot:actions>
        <massive-actions></massive-actions>
      </template>
    </base-header>
    <b-container fluid="">
      <b-row>
        <b-col md="12">
          <homologations-table></homologations-table>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import BaseHeader from "@/components/BaseHeader.vue";
import HomologationsTable from "@/components/QuarantinedAttributeHomologations/HomologationsTable.vue";
import MassiveActions from "../../components/QuarantinedAttributeHomologations/MassiveActions.vue";

export default {
  name: "QuarantinedAttributeHomologations",
  components: {
    BaseHeader,
    HomologationsTable,
    MassiveActions
  }
};
</script>
