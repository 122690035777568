<template>
  <div>
    <b-row v-if="['regular'].indexOf(promotion.type) != -1">
      <b-col>
        <b-form-group>
          <b-form-checkbox v-model="activeMarketingTags">
            Marketing Tags
          </b-form-checkbox>

          <v-select
            v-model="marketingTagsAreNotInclusive"
            :options="notInclusiveOptions"
            :clearable="false"
            :disabled="!activeMarketingTags"
          ></v-select>

          <b-row v-for="(tag, index) in marketingTags" :key="index">
            <b-col>
              <b-form-group label="Tag">
                <b-form-input
                  v-model="marketingTags[index]"
                  :disabled="!activeMarketingTags"
                >
                </b-form-input>
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group label="Eliminar">
                <b-button
                  variant="outline-info"
                  @click="deleteMarketingTag(index)"
                  :disabled="!activeMarketingTags"
                >
                  <b-icon-x-circle></b-icon-x-circle>
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>

          <b-button
            v-show="activeMarketingTags"
            variant="outline-info"
            @click="addMarketingTag"
          >
            <b-icon-plus></b-icon-plus> Agregar Tag
          </b-button>
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group>
          <b-form-checkbox v-model="activeAffiliates" :disabled="true">
            Afiliados
          </b-form-checkbox>

          <v-select
            multiple
            v-model="affiliates"
            :options="affiliatesOptions"
            :disabled="true"
          ></v-select>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
const notInclusiveOptions = [
  { value: false, label: "Es igual a" },
  { value: true, label: "Diferente de" }
];

export default {
  name: "VtexBasePromotionTagsAndAffiliates",
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      promotion: this.value,
      notInclusiveOptions: notInclusiveOptions,
      activeMarketingTags: this.value.marketingTags.length > 0,
      marketingTagsAreNotInclusive: this.notInclusiveOptionByValue(
        this.value.marketingTagsAreNotInclusive
      ),
      marketingTags: this.value.marketingTags,
      activeAffiliates: this.value.affiliates.length > 0,
      affiliates: this.value.affiliates.map(affiliate => {
        return {
          label: affiliate.name,
          value: affiliate
        };
      }),
      affiliatesOptions: []
    };
  },
  methods: {
    /**
     * Revisa que opción del selector de no inclusiones de Marketing Tags utilizar.
     * @param {String} value
     */
    notInclusiveOptionByValue(value) {
      return notInclusiveOptions.find(option => option.value === value);
    },
    /**
     * Añade una nuevo Marketing Tag a la lista de Marketing Tags de la promoción.
     */
    addMarketingTag() {
      this.marketingTags.push("");
    },
    /**
     * Elimina un Marketing Tag de la lista de Marketing Tags de la promoción.
     * @param {Number} index
     */
    deleteMarketingTag(index) {
      this.marketingTags.splice(index, 1);
    }
  },
  watch: {
    value(newValue) {
      this.promotion = newValue;
    },
    promotion: {
      handler(newValue) {
        this.$emit("input", newValue);
      },
      deep: true
    },
    activeMarketingTags(newValue) {
      if (newValue) {
        this.promotion.marketingTagsAreNotInclusive = this.marketingTagsAreNotInclusive.value;
        this.promotion.marketingTags = this.marketingTags;
      } else {
        this.promotion.marketingTagsAreNotInclusive = false;
        this.promotion.marketingTags = [];
      }
    },
    marketingTagsAreNotInclusive(newValue) {
      this.promotion.marketingTagsAreNotInclusive = newValue.value;
    },
    marketingTags() {
      this.promotion.marketingTags = this.marketingTags;
    },
    activeAffiliates(newValue) {
      if (newValue) {
        this.promotion.affiliates = this.affiliates.map(affiliate => {
          return affiliate.value;
        });
      } else {
        this.promotion.affiliates = [];
      }
    },
    affiliates(newValue) {
      this.promotion.affiliates = newValue.map(affiliate => {
        return affiliate.value;
      });
    }
  }
};
</script>
