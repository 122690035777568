<template>
  <div>
    <b-button
      @click="openModal"
      variant="outline-secondary"
      class="mr-1 mb-1"
      v-b-tooltip.hover
      title="Confirmar pedido"
    >
      <b-icon-check />
    </b-button>
    <b-modal
      :ref="'confirmation-' + order.id"
      :id="'confirmation-' + order.id"
      ok-disabled=""
      size="lg"
      title="Confirmar pedido"
    >
      <b-container fluid v-if="order.isConfirmable && loading">
        <b-row>
          <b-col class="col-12">
            <b-spinner label="Spinning" />
          </b-col>
        </b-row>
      </b-container>
      <b-container fluid v-else-if="order.isConfirmable">
        <b-row>
          <b-col class="col-12">
            <div v-if="isFromVtex">
              <b-form-checkbox v-model="isInvoice">
                Agregar Factura/Boleta
              </b-form-checkbox>

              <b-form-group
                v-if="isInvoice"
                label="Numero de factura/boleta*"
                label-for="number"
                label-cols="6"
                content-cols="6"
              >
                <b-form-input
                  id="number"
                  v-model="invoiceNumber"
                  placeholder="0123456"
                  :state="invoiceNumber.length !== 0"
                >
                </b-form-input>
              </b-form-group>
              <b-form-group
                v-if="isInvoice"
                label="Url"
                label-for="url"
                label-cols="6"
                content-cols="6"
              >
                <b-form-input id="url" v-model="url" placeholder="">
                </b-form-input>
              </b-form-group>
            </div>
            <b-form-group
              v-if="allowReason"
              label="Motivo de la confirmación"
              label-for="reason"
              :invalid-feedback="validation.reason.invalidFeedback"
              :state="validation.reason.valid"
            >
              <v-select
                id="reason"
                :options="confirmationReasons"
                placeholder="Selecciona un motivo"
                v-model="reasonId"
                :reduce="r => r.value"
              ></v-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-table-simple v-show="showTable" responsive>
          <b-tr>
            <b-th>Articulo</b-th>
            <b-th>Cantidad</b-th>
            <b-th v-for="i in itemsQuantity" :key="i">Caja {{ i }}</b-th>
            <b-th v-if="showBoxesButtons">
              <b-button
                variant="info"
                size="sm"
                :disabled="disabled"
                @click="addBox"
              >
                <b-icon-plus></b-icon-plus>Caja
              </b-button>
            </b-th>
          </b-tr>
          <b-tr v-for="(item, index) in groupedItems" :key="index">
            <b-th>{{ item.name }} ({{ item.sku }})</b-th>
            <b-td>{{ item.quantity }}</b-td>
            <b-td v-for="i in itemsQuantity" :key="i"
              ><b-input
                :disabled="disabled"
                :id="'sku-' + i"
                v-model="skus[item.sku][i - 1]"
              ></b-input
            ></b-td>
          </b-tr>
          <b-tr v-if="allowMeasures">
            <b-td>Largo</b-td>
            <b-td></b-td>
            <b-td v-for="i in itemsQuantity" :key="i"
              ><b-input
                :disabled="disabled"
                :id="'length-' + i"
                v-model="lengths[i - 1]"
              ></b-input
            ></b-td>
          </b-tr>
          <b-tr v-if="allowMeasures">
            <b-td>Ancho</b-td>
            <b-td></b-td>
            <b-td v-for="i in itemsQuantity" :key="i"
              ><b-input
                :disabled="disabled"
                :id="'width-' + i"
                v-model="widths[i - 1]"
              ></b-input
            ></b-td>
          </b-tr>
          <b-tr v-if="allowMeasures">
            <b-td>Alto</b-td>
            <b-td></b-td>
            <b-td v-for="i in itemsQuantity" :key="i"
              ><b-input
                :disabled="disabled"
                :id="'height-' + i"
                v-model="heights[i - 1]"
              ></b-input
            ></b-td>
          </b-tr>
          <b-tr v-if="allowMeasures">
            <b-td>Peso</b-td>
            <b-td></b-td>
            <b-td v-for="i in itemsQuantity" :key="i"
              ><b-input
                :disabled="disabled"
                :id="'weight-' + i"
                v-model="weights[i - 1]"
              ></b-input
            ></b-td>
          </b-tr>
          <b-tr v-if="allowPackageId">
            <b-td>Packgage ID</b-td>
            <b-td></b-td>
            <b-td v-for="i in itemsQuantity" :key="i"
              ><b-input
                :id="'packageId-' + i"
                v-model="packageIds[i - 1]"
              ></b-input
            ></b-td>
          </b-tr>
          <b-tr v-if="showBoxesButtons">
            <b-td></b-td>
            <b-td></b-td>
            <b-td v-for="i in itemsQuantity" :key="i">
              <center>
                <b-button :disabled="disabled" @click="deleteBox(i)">
                  <b-icon-trash></b-icon-trash>
                </b-button>
              </center>
            </b-td>
          </b-tr>
        </b-table-simple>

        <div v-if="isFromVtex">
          <b-form-group
            v-if="isInvoice"
            label="Total Articulos"
            label-for="number"
            label-cols="6"
            content-cols="6"
          >
            <b-form-input disabled v-model="order.totalAmount" />
          </b-form-group>

          <b-form-group
            v-if="isInvoice"
            label="Total Envio"
            label-for="number"
            label-cols="6"
            content-cols="6"
          >
            <b-form-input disabled v-model="order.shippingAmount" />
          </b-form-group>

          <b-form-group
            v-if="isInvoice"
            label="Total Descuentos"
            label-for="number"
            label-cols="6"
            content-cols="6"
          >
            <b-form-input disabled v-model="order.discountAmount" />
          </b-form-group>

          <b-form-group
            v-if="isInvoice"
            label="Total Factura/Boleta"
            label-for="number"
            label-cols="6"
            content-cols="6"
          >
            <b-form-input disabled v-model="order.paidAmount" />
          </b-form-group>
        </div>
      </b-container>

      <b-container fluid v-if="order.isReadyToShip">
        <label class="h4 text-info"
          >¿Seguro de querer marcar este pedido como listo para el
          despacho?</label
        >
      </b-container>

      <template v-slot:modal-footer>
        <b-button variant="info" :disabled="!confirmable" @click="confirmOrder">
          Confirmar
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import CREATE_ORDER_CONFIRMATION from "../../graphql/CreateOrderConfirmation.gql";
import SET_ORDER_READY_TO_SHIP from "../../graphql/SetOrderReadyToShip.gql";
import ALL_CONFIRMATION_REASONS from "../../graphql/AllConfirmationReasons.gql";
export default {
  name: "OrderConfirmationModal",
  props: {
    order: Object
  },
  beforeMount() {
    let hash = {};
    this.groupedItems.forEach(item => (hash[item.sku] = {}));
    this.skus = hash;
    if (this.order.isConfirmable) {
      this.getConfirmationReasons();
    }
    this.itemsQuantity = this.setItemsQuantity();
  },
  data() {
    return {
      itemsQuantity: 0,
      skus: {},
      lengths: [],
      widths: [],
      weights: [],
      heights: [],
      isInvoice: false,
      invoiceNumber: "",
      url: "",
      confirmationReasons: [],
      packageIds: [],
      reasonId: null,
      loading: true,
      validation: { reason: { valid: true, invalidFeedback: "" } }
    };
  },
  mounted() {
    this.setEmptyBoxes();
  },
  computed: {
    groupedItems() {
      const grouped = {};
      this.order.items.forEach(item => {
        if (!grouped[item.sku]) {
          grouped[item.sku] = {
            name: item.name,
            quantity: item.quantity,
            sku: item.sku
          };
        } else {
          grouped[item.sku].quantity += item.quantity;
        }
      });
      return Object.values(grouped);
    },
    isMercadoLibreOrder() {
      return [
        "mercado_libre",
        "mercado_libre_mx",
        "mercado_libre_pe",
        "mercado_libre_co",
        "mercado_libre_ar"
      ].includes(this.$dig(this.order, "integrationConfig", "identifier"));
    },
    allowReason() {
      return this.isMercadoLibreOrder;
    },
    allowMeasures() {
      return (
        !this.isMercadoLibreOrder && !this.isFromVtex && !this.isDafitiOrder
      );
    },
    allowPackageId() {
      return this.isMercadoLibreOrder;
    },
    isFromVtex() {
      return (
        this.$dig(this.order, "integrationConfig", "identifier") === "vtex"
      );
    },
    isDafitiOrder() {
      return ["dafiti", "dafiti_second"].includes(
        this.$dig(this.order, "integrationConfig", "identifier")
      );
    },
    /**
     * Decide si deshabilitar todos los elementos de la tabla de cajas
     * @return {Boolean}
     */
    disabled() {
      return this.isFromVtex;
    },
    /**
     * Decide si mostrar la tabla de cajas
     * @return {Boolean}
     */
    showTable() {
      if (this.isFromVtex) {
        return this.isInvoice;
      }

      return true;
    },
    /**
     * Decide si habilitar el boton de confirmar pedido
     * @return {Boolean}
     */
    confirmable() {
      if (this.isFromVtex) {
        return (
          !this.isInvoice ||
          (this.invoiceNumber.length > 0 && this.itemsQuantity > 0)
        );
      }

      return this.itemsQuantity > 0;
    },
    /**
     * Decide si mostrar los botones para agregar/eliminar cajas
     * @return {Boolean}
     */
    showBoxesButtons() {
      return !this.isMercadoLibreOrder && !this.isFromVtex;
    }
  },
  methods: {
    openModal() {
      this.$refs["confirmation-" + this.order.id].show();
    },
    /**
     * Decide cuantas cajas crear antes de montar el componente segun el
     * origen del pedido
     * @return {Integer}
     */
    setItemsQuantity() {
      if (this.isMercadoLibreOrder) {
        return 2;
      }
      if (this.isFromVtex) {
        return 0;
      }
      return 1;
    },
    /**
     * Agrega un cero a todos los items de cada caja presente al montar el
     * componente
     */
    setEmptyBoxes() {
      [...Array(this.itemsQuantity)].forEach((_, i) => {
        this.order.items.forEach(item => {
          this.skus[item.sku][i] = 0;
        });
      });
    },
    /**
     * Decide que petición utilizar para confirmar el pedido
     */
    confirmOrder() {
      if (this.order.isConfirmable) {
        this.createOrderConfirmation();
      } else if (this.order.isReadyToShip) {
        this.setReadyToShip();
      }
    },
    /**
     * Valida que el formulario esté correcto
     */
    validate() {
      this.validateReason();
      return Object.keys(this.validation).reduce((acc, key) => {
        return acc && this.validation[key].valid;
      }, true);
    },
    /**
     * Valida que el motivo de la confirmación sea válido
     */
    validateReason() {
      if (this.isMercadoLibreOrder) {
        this.validation.reason.valid = this.reasonId !== null;
        this.validation.reason.invalidFeedback =
          "Debes seleccionar un motivo de confirmación";
      }
    },
    /**
     * Crea una caja que contiene todos los items
     */
    createFilledBox() {
      this.order.items.forEach(item => {
        this.skus[item.sku][0] = item.quantity;
      });
      this.itemsQuantity = 1;
    },
    /**
     * Elimina todas las cajas
     */
    deleteAllBoxes() {
      this.order.items.forEach(item => (this.skus[item.sku] = {}));
      this.itemsQuantity = 0;
    },
    /**
     * Agrega una nueva caja
     */
    addBox() {
      this.itemsQuantity++;
    },
    /**
     * Elimina la caja en el indice entregado, para esto, cada caja copia
     * a la del indice siguiente y luego se elimina la ultima caja.
     * @param {number} index
     */
    deleteBox(index) {
      let times = this.itemsQuantity - index;
      let actualBoxIndex = index - 1;
      while (times > 0) {
        this.copyNextBox(actualBoxIndex);
        actualBoxIndex++;
        times--;
      }
      return this.deleteLastBox();
    },
    /**
     * Copia la información de la caja en el indice siguiente.
     */
    copyNextBox(index) {
      this.order.items.forEach(item => {
        this.skus[item.sku][index] = this.skus[item.sku][index + 1];
      });
    },
    /**
     * Elimina la información de la caja en el ultimo indice y luego
     * reduce la cantidad de cajas en 1.
     */
    deleteLastBox() {
      this.order.items.forEach(item => {
        delete this.skus[item.sku][this.itemsQuantity - 1];
      });
      this.itemsQuantity--;
    },
    createOrderConfirmation() {
      if (!this.validate()) return;
      this.$refs["confirmation-" + this.order.id].hide();
      let create = {
        orderId: this.order.id,
        boxes: this.boxesFromModal(),
        reasonId: this.reasonId
      };
      this.$apollo
        .mutate({
          mutation: CREATE_ORDER_CONFIRMATION,
          variables: {
            create: create
          }
        })
        .then(result => {
          let valid = result.data.createOrderConfirmation.result;
          if (valid) {
            this.$swal.fire({
              title: "Confirmación exitosa",
              text: "Se ha confirmado el pedido de forma exitosa",
              icon: "success",
              confirmButtonText: "Aceptar"
            });
            this.clearBoxes();
          } else {
            this.$swal.fire({
              title: "Confirmación fallida",
              text: result.data.createOrderConfirmation.errors,
              icon: "error",
              confirmButtonText: "Aceptar"
            });
          }
        });
    },
    setReadyToShip() {
      this.$refs["confirmation-" + this.order.id].hide();
      this.$apollo
        .mutate({
          mutation: SET_ORDER_READY_TO_SHIP,
          variables: {
            orderId: this.order.id
          }
        })
        .then(result => {
          let valid = result.data.setOrderReadyToShip.result;
          if (valid) {
            this.$swal.fire({
              title: "Confirmación exitosa",
              text: "Se ha confirmado el pedido de forma exitosa",
              icon: "success",
              confirmButtonText: "Aceptar"
            });
          } else {
            this.$swal.fire({
              title: "Confirmación fallida",
              text: "No se ha podido confirmar el pedido",
              icon: "error",
              confirmButtonText: "Aceptar"
            });
          }
        });
    },
    /**
     * Limpia las cajas
     */
    clearBoxes() {
      const hash = {};
      this.groupedItems.forEach(item => (hash[item.sku] = {}));
      this.skus = hash;
    },
    boxesFromModal() {
      let boxes = [];
      for (let index = 0; index < this.itemsQuantity; index++) {
        let box = {};
        let skus = [];
        box.length = parseFloat(this.lengths[index]);
        box.width = parseFloat(this.widths[index]);
        box.weight = parseFloat(this.weights[index]);
        box.height = parseFloat(this.heights[index]);
        if (this.isFromVtex) {
          box.invoiceNumber = this.invoiceNumber;
          box.url = this.url;
        }
        this.groupedItems.forEach(item => {
          let sku = {};
          if (this.skus[item.sku][index]) {
            sku.sku = item.sku;
            sku.quantity = parseInt(this.skus[item.sku][index]);
            if (sku.quantity > 0) {
              skus.push(sku);
            }
          }
        });
        box.skus = skus;
        if (box.skus.length > 0) {
          boxes.push(box);
        }
        if (this.packageIds[index]) {
          box.packageId = this.packageIds[index];
        }
      }
      return boxes;
    },
    /**
     * Obtiene las razones de confirmación
     * para el selector, y las almacena en
     * la variable confirmationReasons
     */
    getConfirmationReasons() {
      this.$apollo
        .query({
          query: ALL_CONFIRMATION_REASONS
        })
        .then(result => {
          let reasons = result.data.allConfirmationReasons.edges;
          this.confirmationReasons = reasons.map(r => {
            return {
              value: r.node.id,
              label: r.node.name
            };
          });
          this.loading = false;
        });
    }
  },
  watch: {
    reason() {
      this.validateReason();
    },
    isInvoice(value) {
      if (value) {
        this.createFilledBox();
      } else {
        this.deleteAllBoxes();
      }
    }
  }
};
</script>

<style scoped>
.table {
  margin-bottom: 0 !important;
}
.table td {
  padding: 8px 8px !important;
  height: 10px !important;
}
</style>
