<template>
  <div>
    <b-button-group size="sm">
      <b-button variant="outline-info" @click="cancel">
        <b-icon-x-circle></b-icon-x-circle> Cancelar
      </b-button>
      <b-button
        variant="info"
        :disabled="!changed || saving || !validChangesPrices"
        :title="changed ? '' : 'No hay cambios'"
        v-b-tooltip.hover=""
        @click="save"
      >
        <span v-if="saving"><b-spinner label="Spinning"></b-spinner></span>
        <span v-else><b-icon-cloud-upload></b-icon-cloud-upload>Guardar</span>
      </b-button>
    </b-button-group>
    <b-alert
      v-model="saved"
      :variant="errorSaving ? 'danger' : 'success'"
      dismissible
    >
      {{
        errorSaving
          ? "Ha ocurrido un error"
          : "El producto ha sido actualizado exitosamente"
      }}
    </b-alert>
    <h4 class="font-weight-bold m-3">Detalles producto</h4>
    <b-row>
      <b-col md="3">
        <b-form-group
          label="SKU"
          :label-for="'SKU' + integrationConfig.fullLabel"
        >
          <b-form-input
            v-model="sku"
            :id="'SKU' + integrationConfig.fullLabel"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col md="9">
        <b-form-group label="Estado de la publicación">
          <base-boolean-selector
            v-model="status"
            allow-null=""
            true-text="Activa"
            false-text="Pausada"
            default-text="Como en la configuración general"
          ></base-boolean-selector>
        </b-form-group>
      </b-col>
    </b-row>
    <hr />
    <h4 class="font-weight-bold m-3">Precios</h4>
    <b-row>
      <b-col md="3">
        <b-form-group
          label="Precio normal"
          :label-for="'price-' + integrationConfig.fullLabel"
        >
          <b-form-input
            v-model="price"
            :state="$validFormPrice(price)"
            :formatter="$formatFormPrice"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group
          label="Precio oferta"
          :label-for="'special_price-' + integrationConfig.fullLabel"
        >
          <b-form-input
            v-model="special_price"
            :id="'special_price-' + integrationConfig.fullLabel"
            :state="$validFormPrice(special_price)"
            :formatter="$formatFormPrice"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group
          label="Fecha inicio oferta"
          :label-for="'price_from-' + integrationConfig.fullLabel"
        >
          <base-date-time
            v-model="price_from"
            @change="change"
          ></base-date-time>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group
          label="Fecha término oferta"
          :label-for="'price_to-' + integrationConfig.fullLabel"
        >
          <base-date-time v-model="price_to" @change="change"></base-date-time>
        </b-form-group>
      </b-col>
    </b-row>
    <hr />
    <h4 class="font-weight-bold m-3">Variantes</h4>
    <b-table-simple v-if="product.variants">
      <b-thead>
        <b-tr>
          <b-th colspan="3">Datos de Centry</b-th>
          <b-th colspan="2">Datos en {{ integrationConfig.label }}</b-th>
        </b-tr>
        <b-tr>
          <b-th>Color</b-th>
          <b-th>Talla</b-th>
          <b-th>Sku Centry</b-th>
          <b-th>Sku</b-th>
          <b-th>Precio</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="(variant, idx) of product.variants" :key="idx">
          <b-td>{{ variant.color ? variant.color.name : "" }}</b-td>
          <b-td>{{ variant.size ? variant.size.name : "" }}</b-td>
          <b-td>{{ variant.sku }}</b-td>
          <b-td>
            <b-form-input
              v-model="variants[idx].sku"
              @input="change"
            ></b-form-input>
          </b-td>
          <b-td>
            <b-form-input
              v-model="variants[idx].price"
              @input="change"
            ></b-form-input>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <hr />
    <b-row>
      <b-col md="12">
        <product-edit-tabs-magento-category-attributes
          :attributes="attributes"
          :attributesValues="attributesValues"
          :isLoading="isLoading"
          @change="change"
        >
        </product-edit-tabs-magento-category-attributes>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import BaseBooleanSelector from "./BaseBooleanSelector";
import BaseDateTime from "./BaseDateTime";
import { mapActions } from "vuex";
import { updateProduct, updateVariant } from "@/main";
import ProductEditTabsMagentoCategoryAttributes from "./ProductEditTabsMagentoCategoryAttributes";

export default {
  name: "ProductEditTabsMagento",
  components: {
    BaseDateTime,
    BaseBooleanSelector,
    ProductEditTabsMagentoCategoryAttributes
  },
  model: {
    prop: "_changed",
    event: "change"
  },
  props: {
    integrationConfig: Object,
    product: Object
  },
  data() {
    const original = this.product.integrations[
      this.integrationConfig.fullLabel
    ];
    return {
      changed: false,
      saving: false,
      errorSaving: false,
      sku: original ? original.sku : "",
      status: original
        ? original.status === 1 || original.status === "1"
        : null,
      price: original ? original.price : "",
      special_price: original ? original.special_price : "",
      price_from: null,
      price_to: null,
      saved: false,
      attributes: [],
      attributesValues: {},
      notIncludedAtrributeCodes: [],
      variants: [],
      isLoading: true
    };
  },
  async created() {
    this.product.variants.forEach(variant => {
      if (variant.integrations[this.integrationConfig.fullLabel]) {
        this.variants.push(
          variant.integrations[this.integrationConfig.fullLabel]
        );
      } else {
        this.variants.push({
          sku: "",
          price: ""
        });
      }
    });

    const original = this.product.integrations[
      this.integrationConfig.fullLabel
    ];
    if (original) {
      this.price_from = await this.toDate(original.price_from);
      this.price_to = await this.toDate(original.price_to);

      await this.$apollo
        .query({
          query: require("../graphql/MagentoApiProduct.gql"),
          variables: {
            icId: this.integrationConfig.id,
            productSku: original.sku ? original.sku : this.product.sku
          }
        })
        .then(async ({ data }) => {
          let productData = data;
          if (productData && productData.magentoApiProduct) {
            await this.$apollo
              .query({
                query: require("../graphql/MagentoApiAllUserDefinedAttributes.gql"),
                variables: {
                  icId: this.integrationConfig.id,
                  attributeSetId: productData.magentoApiProduct.attributeSetId
                }
              })
              .then(async ({ data }) => {
                let attributesData = data;
                if (
                  attributesData &&
                  attributesData.magentoApiAllUserDefinedAttributes
                ) {
                  await this.getAttributes(attributesData, productData);
                }
              });
            this.setAttributesValues(
              productData.magentoApiProduct.customAttributes
            );
          }
        })
        .catch(() => {
          this.setAttributesValues([]);
        });
    }
    this.isLoading = false;
  },
  computed: {
    /**
     * Indica si los precios son validos para habilitar el botón
     * de guardar
     * @returns {Boolean}
     */
    validChangesPrices() {
      return (
        this.changed &&
        (this.$validFormPrice(this.price) ||
          this.$validFormPrice(this.price) == null) &&
        (this.$validFormPrice(this.special_price) ||
          this.$validFormPrice(this.special_price) == null)
      );
    }
  },
  methods: {
    async getAttributes(attributesData, productData) {
      this.attributes = await this.excludeConfigurableAttributes(
        attributesData.magentoApiAllUserDefinedAttributes,
        productData.magentoApiProduct.extensionAttributes
          .configurableProductOptions
      );

      let attributesValues = {};
      this.attributes.forEach(attribute => {
        attributesValues[attribute.attributeCode] = null;
      });
      this.attributesValues = attributesValues;
    },
    async excludeConfigurableAttributes(attributes, configurableAttributes) {
      if (configurableAttributes) {
        await this.asyncForEach(configurableAttributes, async attribute => {
          await this.excludeAttributeCode(attribute);
        });
      }

      return attributes.filter(attribute => {
        return !this.notIncludedAtrributeCodes.includes(
          attribute.attributeCode
        );
      });
    },
    async excludeAttributeCode(attribute) {
      await this.$apollo
        .query({
          query: require("../graphql/MagentoApiAttribute.gql"),
          variables: {
            icId: this.integrationConfig.id,
            attributeId: attribute.attributeId,
            attributeCode: null
          }
        })
        .then(({ data }) => {
          if (data && data.magentoApiAttribute) {
            this.notIncludedAtrributeCodes.push(
              data.magentoApiAttribute.attributeCode
            );
          }
        });
    },
    async asyncForEach(array, callback) {
      for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
      }
    },
    setAttributesValues(customAttributes) {
      customAttributes.forEach(attribute => {
        if (attribute.attributeCode in this.attributesValues) {
          this.attributesValues[attribute.attributeCode] = attribute.value;
          const attr = this.attributes.find(
            attr => attr.attributeCode == attribute.attributeCode
          );
          if (attr && attr.frontendInput == "multiselect" && attribute.value) {
            this.attributesValues[attribute.attributeCode] =
              attribute.value == "" ? [] : attribute.value.split(",");
          }
        }
      });
    },
    ...mapActions(["timezoneDate"]),
    async toDate(string) {
      if (string && string.length > 0) {
        const dateString = await this.timezoneDate(string);
        return new Date(dateString);
      }
      return null;
    },
    updatedProductData() {
      const updated = { integrations: {} };
      updated.integrations[this.integrationConfig.fullLabel] = {};
      let original = this.product.integrations[
        this.integrationConfig.fullLabel
      ];
      if (!original) original = {};
      const changes = [
        {
          key: "sku",
          original: original.sku,
          current: this.sku
        },
        {
          key: "status",
          original: original.status,
          current: this.status === null ? "" : this.status ? "1" : "2"
        },
        { key: "price", original: original.price, current: this.price },
        {
          key: "special_price",
          original: original.special_price,
          current: this.special_price
        },
        {
          key: "price_from",
          original: original.price_from,
          current: this.price_from ? this.price_from.toISOString() : ""
        },
        {
          key: "price_to",
          original: original.price_to,
          current: this.price_to ? this.price_to.toISOString() : ""
        }
      ];
      let changed = false;
      changes.forEach(x => {
        if (x.original !== x.current) {
          changed = true;
          updated.integrations[this.integrationConfig.fullLabel][x.key] =
            x.current;
        }
      });
      let changedAttributes = this.changedCustomAttributesValues(original);
      if (Object.keys(changedAttributes).length > 0) {
        changed = true;
        if (
          original.custom_attributes &&
          Object.keys(original.custom_attributes).length > 0
        ) {
          updated.integrations[
            this.integrationConfig.fullLabel
          ].custom_attributes = original.custom_attributes;
          for (const [key, value] of Object.entries(changedAttributes)) {
            updated.integrations[
              this.integrationConfig.fullLabel
            ].custom_attributes[key] = value;
          }
        } else {
          updated.integrations[
            this.integrationConfig.fullLabel
          ].custom_attributes = changedAttributes;
        }
      }
      if (changed) {
        return updated;
      }
      return null;
    },
    changedCustomAttributesValues(original) {
      let originalCustomAttributes = original.custom_attributes;
      if (!originalCustomAttributes) {
        originalCustomAttributes = {};
      }

      const changes = [];
      this.attributes.forEach(attribute => {
        const attributeCode = attribute.attributeCode;
        const currentAttributeValue = this.attributesValues[attributeCode];
        const originalAttributeValue = originalCustomAttributes[attributeCode];
        changes.push({
          key: attributeCode,
          original: originalAttributeValue,
          current: currentAttributeValue
        });
      });

      let changedAttributes = {};
      changes.forEach(x => {
        if (x.original !== x.current && x.current !== null) {
          changedAttributes[x.key] = x.current;
        }
      });
      return changedAttributes;
    },
    updateProductCache(product) {
      if (product) {
        this.product.integrations = product.integrations;
      }
    },
    async updateProduct() {
      const updated = this.updatedProductData();
      if (!updated) {
        return;
      }
      await updateProduct(this.$apollo, this.product.id, updated)
        .then(async ({ data }) => {
          if (data && data.updateProduct && data.updateProduct.product) {
            this.errorSaving = false;
            const product = data.updateProduct.product;
            this.updateProductCache(product);
          }
        })
        .catch(() => {
          this.errorSaving = true;
        });
    },
    updateVariantCache(position, variant) {
      this.product.variants[position] = variant;
    },
    async updateVariant(position) {
      const updated = this.updatedVariantData(position);
      if (!updated) {
        return;
      }
      await updateVariant(
        this.$apollo,
        this.product.variants[position].id,
        updated
      )
        .then(async ({ data }) => {
          if (data && data.updateVariant && data.updateVariant.variant) {
            const variant = data.updateVariant.variant;
            this.updateVariantCache(position, variant);
          }
        })
        .catch(() => {
          this.errorSaving = true;
        });
    },
    async save() {
      this.saving = true;
      this.errorSaving = false;
      await this.updateProduct();
      for (
        let position = 0;
        position < this.product.variants.length;
        position++
      ) {
        await this.updateVariant(position);
      }
      this.saving = false;
      this.saved = true;
      if (!this.errorSaving) {
        this.changed = false;
        this.$emit("change", false);
      }
    },
    updatedVariantData(position) {
      const variant = this.product.variants[position];
      const original = variant.integrations[this.integrationConfig.fullLabel];
      const current = this.variants[position];
      const changes = [
        {
          key: "sku",
          original: original ? original.sku : "",
          current: current.sku
        },
        {
          key: "price",
          original: original ? original.price : "",
          current: current.price
        }
      ];
      let updated = {};
      changes.forEach(x => {
        if (x.original !== x.current) {
          updated[x.key] = x.current;
        }
      });
      if (Object.keys(updated).length) {
        const toSend = { integrations: {} };
        toSend.integrations[this.integrationConfig.fullLabel] = updated;
        return toSend;
      }
      return null;
    },
    cancel() {
      if (this.changed) {
        this.$swal
          .fire({
            title: "Cancelar",
            text: "Si cancelas, perderás tus cambios. ¿Estás seguro?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "No"
          })
          .then(result => {
            if (result.value) {
              this.$router.push({ name: "Products" });
            }
          });
      } else {
        this.$router.push({ name: "Products" });
      }
    },
    change() {
      this.changed = true;
      this.$emit("change", true);
    }
  },
  watch: {
    sku() {
      this.change();
    },
    status() {
      this.change();
    },
    price() {
      this.change();
    },
    special_price() {
      this.change();
    }
  }
};
</script>
