<template>
  <div>
    <vtex-base-promotion-general-data v-model="promotion" />
    <hr />

    <vtex-base-promotion-schedule v-model="promotion" />
    <hr />

    <div v-if="promotionType === 'campaign'">
      <vtex-base-promotion-audience v-model="promotion" />
      <hr />
    </div>

    <div v-if="showPromotionDiscounts">
      <vtex-base-promotion-discounts
        v-model="promotion"
        :vtexIntegrationConfigId="vtexIntegrationConfigId"
        @filledRequiredFields="setDiscountsRequiredFields"
        @shippingDiscount="emitShippingDiscount"
        @allowManualPrice="emitAllowManualPrice"
        @allowOtherPromotions="emitAllowOtherPromotions"
      />
      <hr />
    </div>

    <vtex-base-promotion-other-data
      v-model="promotion"
      :vtexIntegrationConfigId="vtexIntegrationConfigId"
    />
    <hr />
  </div>
</template>

<script>
import VtexBasePromotionGeneralData from "./VtexPromotionOverview/VtexBasePromotionGeneralData.vue";
import VtexBasePromotionSchedule from "./VtexPromotionOverview/VtexBasePromotionSchedule.vue";
import VtexBasePromotionAudience from "./VtexPromotionOverview/VtexBasePromotionAudience.vue";
import VtexBasePromotionDiscounts from "./VtexPromotionOverview/VtexBasePromotionDiscounts.vue";
import VtexBasePromotionOtherData from "./VtexPromotionOverview/VtexBasePromotionOtherData.vue";

export default {
  name: "VtexBasePromotionOverview",
  components: {
    VtexBasePromotionGeneralData,
    VtexBasePromotionSchedule,
    VtexBasePromotionAudience,
    VtexBasePromotionDiscounts,
    VtexBasePromotionOtherData
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    vtexIntegrationConfigId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      promotion: this.value,
      filledRequiredFields: false,
      overviewRequiredFields: false,
      discountsRequiredFields: false
    };
  },
  mounted() {
    if (!this.showPromotionDiscounts) this.discountsRequiredFields = true;
  },
  methods: {
    /**
     * Emite si los datos obligatorios han sido completados.
     * @param {Boolean} value - Valor a emitir.
     */
    emitFilledRequiredFields(value) {
      this.$emit("filledRequiredFields", value);
    },
    /**
     * Guarda el valor emitido, correspondiente a si los campos obligatorios
     * de los descuentos de la promoción han sido completados.
     * @param {Boolean} value - valor emitido.
     */
    setDiscountsRequiredFields(value) {
      this.discountsRequiredFields = value;
    },
    /**
     * Emite si la promoción hace un descuento al precio del envio.
     * @param {Boolean} value - Valor a emitir.
     */
    emitShippingDiscount(value) {
      this.$emit("shippingDiscount", value);
    },
    /**
     * Emite si la promoción permite elegir si acumularse o no con precios manuales.
     * @param {Boolean} value - Valor a emitir.
     */
    emitAllowManualPrice(value) {
      this.$emit("allowManualPrice", value);
    },
    /**
     * Emite si la promoción permite elegir si acumularse o no con otras promociones.
     * @param {Boolean} value - Valor a emitir.
     */
    emitAllowOtherPromotions(value) {
      this.$emit("allowOtherPromotions", value);
    }
  },
  computed: {
    /**
     * Retorna el tipo de promoción.
     */
    promotionType() {
      return this.promotion.type;
    },
    /**
     * Para cada atributo obligatorio de Overview se informa si falta el dato.
     * @returns {Object}
     */
    missingRequiredFields() {
      let missing = {};
      missing.name = !this.promotion.name;
      missing.beginDateUtc = !this.promotion.beginDateUtc;
      missing.endDateUtc = !this.promotion.endDateUtc;
      return missing;
    },
    /**
     * Revisa si mostrar el selector del tipo de descuento de la promoción
     * segun el tipo de promoción.
     * @returns {Object} - Objeto con los atributos obligatorios y si estan completos.
     */
    showPromotionDiscounts() {
      return ["regular", "buyAndWin", "campaign"].includes(this.promotionType);
    }
  },
  /**
   * En general, el objetivo de los watches es cambiar el valor de una propiedad
   * de la promocion cuando se cambia su correspondiente en la vista
   */
  watch: {
    value: {
      handler(newValue) {
        this.promotion = newValue;
      },
      deep: true
    },
    promotion: {
      handler(newValue) {
        this.$emit("input", newValue);
      },
      deep: true
    },
    /**
     * Si cambia el valor de missingRequiredFields, se revisa si no falta ningun
     * campo requerido y se guarda este valor en overviewRequiredFields.
     */
    missingRequiredFields: {
      handler(newValue) {
        this.overviewRequiredFields = !Object.values(newValue).includes(true);
      },
      immediate: true
    },
    /**
     * Si cambia el valor de filledRequiredFields, se emite este valor al padre.
     * @param {Boolean} value - Valor a emitir.
     */
    filledRequiredFields: {
      handler(newValue) {
        this.emitFilledRequiredFields(newValue);
      },
      immediate: true
    },
    /**
     * Si cambia overviewRequiredFields se revisa si ya se cumplen todos
     * los campos requeridos y se asigna este valor en filledRequiredFields.
     * @param {Boolean} newValue - Si los campos obligatorios en overview estan completos.
     */
    overviewRequiredFields(newValue) {
      if (this.discountsRequiredFields && newValue) {
        this.filledRequiredFields = true;
      } else {
        this.filledRequiredFields = false;
      }
    },
    /**
     * Si cambia discountsRequiredFields se revisa si ya se cumplen todos
     * los campos requeridos y se asigna este valor en filledRequiredFields.
     * @param {Boolean} newValue - Si los campos obligatorios en discounts estan completos.
     */
    discountsRequiredFields(newValue) {
      if (this.overviewRequiredFields && newValue) {
        this.filledRequiredFields = true;
      } else {
        this.filledRequiredFields = false;
      }
    }
  }
};
</script>
