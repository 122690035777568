var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',[_c('b-table-simple',{attrs:{"hover":"","small":"","responsive":""}},[_c('b-thead',[_c('b-tr',[_vm._l((_vm.attributes),function(attribute){return _c('b-th',{key:attribute.id,staticStyle:{"min-width":"100px"},attrs:{"variant":_vm.isMainAttribute(attribute) ? 'info' : 'white'}},[_vm._v(_vm._s(_vm.requiredAttribute(attribute) ? "*" : "")+" "+_vm._s(attribute.name)+_vm._s(_vm.prettyAttributeUnit(attribute))+" ")])}),_c('b-th')],2)],1),_c('b-tbody',_vm._l((_vm.rows),function(row,index){return _c('b-tr',{key:index},[_vm._l((_vm.attributes),function(attribute){return _c('b-td',{key:'col_' + attribute.id,attrs:{"variant":_vm.isMainAttribute(attribute) ? 'info' : 'white'}},[_c('b-form-group',[(attribute.values && attribute.values.length)?_c('v-select',{attrs:{"id":'a' + attribute.id,"multiple":attribute.multivalued === true,"options":attribute.values.map(function (x) {
                      return { label: x.name, value: x.id };
                    }),"reduce":function (x) { return x.value; }},on:{"input":_vm.emitChange},model:{value:(_vm.rows[index][attribute.id]),callback:function ($$v) {_vm.$set(_vm.rows[index], attribute.id, $$v)},expression:"rows[index][attribute.id]"}}):_c('b-input',{attrs:{"id":'a' + attribute.id,"disabled":_vm.isMainAttribute(attribute) && _vm.editMode && row.id != null,"state":_vm.currentStateAttribute(attribute, index),"title":_vm.isMainAttribute(attribute)
                      ? 'Este atributo no se puede editar. Es el atributo principal'
                      : ''},on:{"input":_vm.emitChange},model:{value:(_vm.rows[index][attribute.id]),callback:function ($$v) {_vm.$set(_vm.rows[index], attribute.id, $$v)},expression:"rows[index][attribute.id]"}}),(
                    (_vm.requiredAttribute(attribute) &&
                      (!_vm.rows[index][attribute.id] ||
                        !_vm.rows[index][attribute.id].length)) ||
                      _vm.isMainAttribute(attribute)
                  )?_c('b-form-invalid-feedback',{attrs:{"state":!!_vm.rows[index][attribute.id] &&
                      !!_vm.rows[index][attribute.id].length}},[_vm._v(" Este atributo es obligatorio ")]):_vm._e()],1)],1)}),_c('b-td',[(!row.id)?_c('b-link',{on:{"click":function($event){return _vm.deleteRow(index)}}},[_vm._v(" Eliminar ")]):_vm._e()],1)],2)}),1)],1)],1)],1),_c('b-form-group',[_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":_vm.addRow}},[_c('b-icon-plus'),_vm._v(" Agregar fila")],1),(!_vm.rows || !_vm.rows.length)?_c('b-form-invalid-feedback',{attrs:{"state":!!_vm.rows && !!_vm.rows.length}},[_vm._v(" Debes agregar al menos una fila ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }