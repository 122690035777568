<template>
  <div>
    <base-header
      title="Recomendaciones de Moda"
      title-link="/mercadolibre/recommendations"
      :map="[
        integrationConfigLabel || 'loading',
        'Recomedaciones de Moda',
        'Listado'
      ]"
      :title-size="12"
    />
    <!-- Spinner -->
    <b-skeleton-table
      v-if="isLoading"
      :rows="10"
      :columns="fields.length"
      :table-props="{ bordered: true, striped: true }"
    ></b-skeleton-table>
    <!-- Tabla de Recomendaciones -->
    <div v-else>
      <b-col sm="4" xs="12" class="float-left">
        <b-form-group
          label="Estado"
          style="margin-top: 18px; color:gray; font-weight: bolder;"
        >
          <b-form-select
            v-model="status"
            :options="statusOptions"
          ></b-form-select></b-form-group
      ></b-col>
      <b-button
        class="m-2 float-right"
        size="sm"
        @click="restartRecommendations"
        title="Recargar Recomendaciones"
      >
        <b-icon-arrow-repeat />
      </b-button>
      <b-table
        :items="allRecommendations"
        :fields="fields"
        responsive="sm"
        style="background-color: #f7f8f8"
      >
        <template #cell(show_details)="row" v-if="status == 'NOT_CERTIFIED'">
          <ul class="table-actions">
            <li>
              <b-link
                size="sm"
                @click="row.toggleDetails"
                class="mr-2"
                role="button"
              >
                <b-icon-eye-fill></b-icon-eye-fill>
              </b-link>
            </li>
          </ul>
        </template>
        <template #cell(img)="row">
          <img :src="`${row.item.img}`" />
        </template>

        <template #row-details="row">
          <recommendation-reasons
            :integration-config-id="integrationConfigId"
            :item-id="row.item.meliId"
          ></recommendation-reasons>
        </template>
      </b-table>
      <b-spinner
        v-if="isLoadingMore"
        label="Spinning"
        class="m-2 float-right"
      ></b-spinner>
      <div v-else>
        <b-button
          v-if="hasNextPage()"
          class="m-2 float-right"
          @click="seeMore()"
        >
          Ver más
        </b-button>
        <b-alert v-else show variant="light" class="text-center">
          No hay más datos para mostrar.
        </b-alert>
      </div>
    </div>
    <!-- Mensaje de Error en caso de que falle la Query -->
    <div v-if="error">
      <p>
        Hubo un error al tratar de obtener las Recomedaciones
        <b-link @click="allRecommendations == [] ? seeFirst : seeMore"
          >aqui.</b-link
        >
      </p>
    </div>
  </div>
</template>

<script>
import RecommendationReasons from "../../../views/MercadoLibre/Recommendations/Reasons";
import INTEGRATION_CONFIG_LABEL from "../../../graphql/IntegrationConfigMercadoLibreLabel.gql";
import GET_RECOMMENDATIONS from "../../../graphql/MercadoLibre/Recommendations/AllItemsByStatusCertification.gql";
import BaseHeader from "../../../components/BaseHeader.vue";
export default {
  name: "RecommendationsList",
  components: {
    BaseHeader,
    RecommendationReasons
  },
  props: {
    integrationConfigId: {
      type: String
    }
  },
  data() {
    return {
      fields: [
        { key: "img", label: "Imagen" },
        { key: "name", label: "Nombre", sortable: true },
        { key: "sku", label: "Sku", sortable: true },
        { key: "meliId", label: "ML ID", sortable: true },
        { key: "status", label: "Estado" },
        { key: "show_details", label: "" }
      ],
      total: null,
      allRecommendations: [],
      status: "NOT_CERTIFIED",
      statusOptions: [
        {
          value: "UNDER_REVIEW",
          text: "En Revisión"
        },
        {
          value: "NOT_CERTIFIED",
          text: "No Certificado"
        },
        {
          value: "CERTIFIED",
          text: "Certificado"
        }
      ],
      offset: 0,
      limit: 10,
      error: false,
      isLoading: true,
      isLoadingMore: false,
      integrationConfigLabel: null
    };
  },
  async mounted() {
    const promise = Promise.resolve(this.getIntegrationConfigLabel());
    await promise.then(() => {
      this.seeFirst();
    });
  },
  methods: {
    /**
     * Obtiene la etiqueta del integration_config
     * y lo guarda en variable interna
     */
    async getIntegrationConfigLabel() {
      await this.$apollo
        .query({
          query: INTEGRATION_CONFIG_LABEL,
          variables: {
            id: this.integrationConfigId
          }
        })
        .then(({ data }) => {
          const label = this.$dig(
            data,
            "integrationConfigMercadoLibre",
            "label"
          );
          if (label) {
            this.integrationConfigLabel = label;
          } else {
            this.integrationConfigLabel = "MercadoLibre";
          }
        })
        .catch(() => (this.integrationConfigLabel = "MercadoLibre"));
    },
    /**
     * Se encarga de pedir la primera consulta de las recomendaciones
     * Se reciben ultimos 10 registros
     */
    async seeFirst() {
      this.isLoading = true;
      await this.awaitForRecommendations();
    },
    /**
     * Se encarga de realizar consulta de las recomendaciones
     * Se reciben 10 registros segun offset
     */
    async awaitForRecommendations() {
      this.error = false;
      if (!this.status) {
        return;
      }
      await this.$apollo
        .query({
          query: GET_RECOMMENDATIONS,
          variables: this.queryVariables(),
          fetchPolicy: "no-cache"
        })
        .then(({ data }) => {
          if (this.$dig(data, "allItemsByStatusCertification")) {
            let status = this.statusName(
              this.$dig(data, "allItemsByStatusCertification", "itemStatus")
            );
            let items = [];
            this.$dig(data, "allItemsByStatusCertification", "items").forEach(
              item => {
                items.push(this.buildRowItem(item, status));
              }
            );
            this.allRecommendations = this.allRecommendations.concat(items);
            this.offset += this.getSpecificValueInData(data, "offset");
            this.limit = this.getSpecificValueInData(data, "limit");
            this.total = this.getSpecificValueInData(data, "total");
          } else {
            this.statusError();
          }
          this.isLoading = false;
          this.isLoadingMore = false;
        })
        .catch(() => {
          this.statusError();
        });
    },
    /**
     * Modifica estado de componentes, señalando que hubo errores
     */
    statusError() {
      this.error = true;
      this.isLoading = false;
      this.isLoadingMore = false;
    },
    /**
     * Construye objecto con informacion de una fila en el listado de recomendaciones
     * @param {Object} item
     * @param {String} status
     * @returns {Object}
     */
    buildRowItem(item, status) {
      let product = item.product;
      return {
        img: product.assets[0].thumbUrl,
        name: product.name,
        sku: product.sku,
        meliId: item.meliId,
        status: status
      };
    },
    /**
     * Entrega valor de una llave de paginamiento
     * en una respuesta de la peticion de obtener recomendaciones
     * @param {Object} data
     * @param {String} key
     * @returns {String}
     */
    getSpecificValueInData(data, key) {
      return this.$dig(data, "allItemsByStatusCertification", "paging", key);
    },
    /**
     * Costruye objeto con variables para consulta de items con recomendaciones de moda
     * El objeto debe incluir la id de la integration config, offset y limit
     * Puede incluir status y/o id de la categoria
     * @return {Object}
     */
    queryVariables() {
      const variables = {};
      variables.icId = this.integrationConfigId;
      variables.offset = this.offset;
      variables.limit = this.limit;
      variables.status = this.status;
      return variables;
    },
    /**
     * Verifica si es posible continuar con el paginamiento
     * Si los datos totales son mayores que el limite mas el offset
     * @return {Boolean}
     */
    hasNextPage() {
      return this.total > this.limit + this.offset;
    },
    /**
     * Se pide realizar nuevamente la consulta a recomendaciones de moda
     * se modifica el offset para tal proposito
     */
    async seeMore() {
      this.offset = this.limit + this.offset;
      this.isLoadingMore = true;
      await this.awaitForRecommendations();
    },
    /**
     * Refresca las recomendaciones de moda
     * para esto hay que borrar listados y reiniciar offset a 0
     */
    async restartRecommendations() {
      this.offset = 0;
      this.allRecommendations = [];
      await this.seeFirst();
    },
    /**
     * Traduce el estado del producto para que
     * sea mas legible
     * @param {String} status
     * @returns {string}
     */
    statusName(status) {
      let name = "";
      switch (status) {
        case "UNDER_REVIEW":
          name = "En Revisión";
          break;
        case "NOT_CERTIFIED":
          name = "No Certificado";
          break;
        case "CERTIFIED":
          name = "Certificado";
          break;
      }
      return name;
    }
  },
  watch: {
    status: function() {
      this.restartRecommendations();
    }
  }
};
</script>

<style scoped>
.table-actions {
  list-style: none;
  padding-left: 0;
}
.table-actions li {
  display: inline-block;
  padding: 0px 7px;
}
.table-actions li a {
  color: #aab1b5;
  display: inline-block;
  background-color: white;
  width: 34px;
  height: 34px;
  text-align: center;
  border-radius: 50%;
  font-size: 14px;
  border: 2px solid #e0e2e4;
  padding: 5px 0;
  -webkit-transition: color 0.1s ease;
  -o-transition: color 0.1s ease;
  transition: color 0.1s ease;
  -webkit-transition: border-color 0.1s ease;
  -o-transition: border-color 0.1s ease;
  transition: border-color 0.1s ease;
}
.table-actions li a:hover {
  border-color: #aab1b5;
  color: black;
}
</style>
