<template>
  <b-container>
    <b-row>
      <h4 class="font-weight-bold m-3">{{ title }}</h4>
      <b-button
        class="mt-2 mb-4"
        variant="outline-secondary"
        title="Formulario vacío"
        v-b-tooltip.hover
        @click="emitEmpty"
        ><b-icon-plus /> {{ emptyButtonText }}
      </b-button>
    </b-row>
    <ApolloQuery
      :query="require('../../graphql/MercadoLibre/AllItems.gql')"
      :variables="queryVariables()"
      @result="isEmptyResult"
    >
      <template slot-scope="{ result: { error, data }, query, isLoading }">
        <div>
          <b-skeleton-table
            v-if="isLoading && !data"
            :rows="5"
            :columns="5"
            :table-props="{ hover: true }"
          ></b-skeleton-table>
          <b-alert show variant="danger" v-else-if="!!error">
            Ha ocurrido un error al obtener los productos</b-alert
          >
          <b-table-simple v-else>
            <b-thead>
              <b-tr>
                <b-th />
                <b-th>Título</b-th>
                <b-th>Precio</b-th>
                <b-th>Marca</b-th>
                <b-th>Modelo</b-th>
                <b-th />
              </b-tr>
            </b-thead>
            <b-tbody>
              <mercado-libre-item-row
                :item="item"
                v-for="item of data.allMercadoLibreItems.itemResults"
                :key="item.id"
                :product="item"
                @select="emitProduct"
              />
            </b-tbody>
          </b-table-simple>
          <b-alert v-if="!!errorSeeingMore" show variant="danger">{{
            errorSeeingMore
          }}</b-alert>
          <b-skeleton
            type="button"
            v-else-if="isLoading"
            class="m-2 float-right"
          ></b-skeleton>
          <b-button
            class="m-2 float-right"
            v-else-if="hasNextPage(data)"
            @click="seeMore(query, data.allMercadoLibreItems.paging.scrollId)"
          >
            Ver más
          </b-button>
        </div>
      </template>
    </ApolloQuery>
  </b-container>
</template>
<script>
import MercadoLibreItemRow from "./ItemRow.vue";
export default {
  name: "MercadoLibreSelectProduct",
  components: {
    MercadoLibreItemRow
  },
  props: {
    integrationConfigId: String,
    title: String,
    emptyButtonText: String,
    query: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      pageSize: 20,
      pagesLoaded: 1,
      errorSeeingMore: null
    };
  },
  methods: {
    /**
     * Emite el producto seleccionado
     * @param {Object} product
     */
    emitProduct(product) {
      this.$emit("select", product);
    },
    /**
     * Emite selección de ningún producto
     */
    emitEmpty() {
      this.$emit("select", null);
    },
    /**
     * Entrega las variables de la query
     * @param {String} scrollId
     * @returns {Object}
     */
    queryVariables(scrollId) {
      return Object.assign({}, this.query, {
        scrollId: scrollId,
        integrationConfigId: this.integrationConfigId,
        limit: this.pageSize
      });
    },
    /**
     * Indica si tiene o no más páginas
     * Se basa en el total de datos, el tamaño de la página
     * y las páginas cargadas
     * @param {Object} data
     * @returns {Boolean}
     */
    hasNextPage(data) {
      return (
        this.$ifNull(
          this.$dig(data, "allMercadoLibreItems", "paging", "total"),
          0
        ) >
        this.pageSize * this.pagesLoaded
      );
    },
    /**
     * Trae más resultados de una query avanzando en su cursor.
     * @param {Object} query
     * @param {String} cursor
     */
    async seeMore(query, cursor) {
      this.pagesLoaded += 1;
      await query
        .fetchMore({
          variables: this.queryVariables(cursor),
          updateQuery: (prev, { fetchMoreResult }) => {
            const updated = Object.assign({}, prev);
            if (
              fetchMoreResult?.allMercadoLibreItems?.itemResults?.length !== 0
            ) {
              updated.allMercadoLibreItems.itemResults.push(
                ...fetchMoreResult.allMercadoLibreItems.itemResults
              );
              updated.allMercadoLibreItems.paging.scrollId =
                fetchMoreResult.allMercadoLibreItems.paging.scrollId;
            }
            return updated;
          }
        })
        .catch(err => {
          this.errorSeeingMore = `Error cargando una nueva página: ${err.message}`;
        });
    },
    /**
     * emite el evento de selección vacía si no hay productos
     * @param {Object} response
     * @returns {Boolean}
     */
    isEmptyResult(response) {
      const result = this.$dig(response, "data", "allMercadoLibreItems");
      if (result.paging.total == 0) {
        this.$emit("select", null);
      }
    }
  }
};
</script>
