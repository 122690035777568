<template>
  <div>
    <b-modal :id="id" size="lg" cancel-disabled="">
      <template v-slot:modal-title>
        <h4>{{ title }}</h4>
      </template>

      <template id="content">
        <b-list-group>
          <b-row>
            <b-col>
              <p>
                Precio convertirte en ganador:
                <b-badge variant="success" pill style="font-size:100%;">{{
                  modelToCompare.priceToWin | toCurrency
                }}</b-badge>
              </p>
            </b-col>
            <b-col>
              <p class="text-center">
                Tu publicación
                <b-badge variant="primary" pill>{{
                  item_status_to_human_str(modelToCompare.status)
                }}</b-badge>
              </p>
            </b-col>
            <b-col v-if="modelToCompare.status == 'competing'">
              <p class="text-center">Ganador</p>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-list-group-item href="#">
                Nivel de visitas que recibes:
              </b-list-group-item>
            </b-col>
            <b-col>
              <b-list-group-item>
                {{ visit_share_to_human_str(modelToCompare.visitShare) }}
              </b-list-group-item>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-list-group-item href="#">
                Precio en la publicación
              </b-list-group-item>
            </b-col>
            <b-col>
              <b-list-group-item>
                {{ modelToCompare.currentPrice | toCurrency }}
              </b-list-group-item>
            </b-col>
            <b-col
              style="padding-left:0"
              v-if="modelToCompare.status == 'competing'"
            >
              <b-list-group-item>
                {{ modelToCompare.winner.price | toCurrency }}
              </b-list-group-item>
            </b-col>
          </b-row>

          <b-row v-for="(boost, idx) of modelToCompare.boosts" :key="idx">
            <b-col>
              <b-list-group-item href="#">
                {{ boost.description }}
              </b-list-group-item>
            </b-col>
            <b-col>
              <b-list-group-item v-bind:active="isBoosted(boost.status)">
                {{ status_to_human_str(boost.status) }}
              </b-list-group-item>
            </b-col>
            <b-col
              style="padding-left:0"
              v-if="modelToCompare.status == 'competing'"
              v-bind:active="isBoosted(boost.status)"
            >
              <b-list-group-item>
                {{
                  status_to_human_str(modelToCompare.winner.boosts[idx].status)
                }}
              </b-list-group-item>
            </b-col>
          </b-row>
        </b-list-group>
      </template>

      <template v-slot:modal-footer="{ ok }">
        <b-button variant="info" @click="ok">
          Entendido
        </b-button>
      </template>
      <slot></slot>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: {
    id: String,
    title: String,
    tooltip: String,
    modelToCompare: Object,
    linkClass: {
      type: String,
      default: "float-right p-0"
    }
  },
  methods: {
    isBoosted(item_status) {
      if (item_status == "opportunity") {
        return true;
      }
    },

    status_to_human_str(status) {
      switch (status) {
        case "opportunity":
          return "Ofrécelo y mejora tus posibilidades de ganar";
        case "boosted":
          return "Actualamente mejorado";
        case "not_boosted":
          return "Ofrecido, pero no mejora las posibilidades";
        case "not_apply":
          return "No aplica como mejora donde se está compitiendo";
        default:
      }
    },

    item_status_to_human_str(status) {
      switch (status) {
        case "listed":
          return "Perdiendo";
        case "competing":
          return "Compitiendo";
        case "sharing_first_place":
          return "Compartiendo el primer lugar";
        case "winning":
          return "Ganando";
        default:
      }
    },
    visit_share_to_human_str(visit_share) {
      switch (visit_share) {
        case "maximum":
          return "Máximo";
        case "medium":
          return "Medio";
        case "minimum":
          return "Mínimo";
        default:
      }
    }
  }
};
</script>
