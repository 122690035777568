<template>
  <b-tr>
    <b-td>
      <b-badge
        pill
        :id="'status_' + index"
        :class="day.enabled ? 'text-success' : 'text-danger'"
        :variant="day.enabled ? 'success' : 'danger'"
      >
        .
      </b-badge>
      <template>
        <b-tooltip
          :target="'status_' + index"
          v-if="day.finalized"
          triggers="hover"
        >
          Ya finalizó
        </b-tooltip>
        <b-tooltip
          :target="'status_' + index"
          v-else-if="!day.enabled"
          triggers="hover"
        >
          No se puede configurar
        </b-tooltip>
        <b-tooltip :target="'status_' + index" v-else triggers="hover">
          Disponible para configurar
        </b-tooltip>
      </template>
    </b-td>
    <b-td>{{ day.description }}</b-td>
    <b-td>{{ prettyDate }}</b-td>
    <b-td>
      <b-form-group style="margin-top: 18px;">
        <b-form-checkbox
          :id="'switch_' + index"
          size="lg"
          v-model="day.checked"
          @change="onChange(index)"
          switch
          :disabled="!day.enabled"
        >
        </b-form-checkbox>
      </b-form-group>
    </b-td>
  </b-tr>
</template>
<script>
export default {
  name: "MercadoLibreNotWorkingRowsRow",
  props: {
    day: Object,
    index: Number
  },
  computed: {
    prettyDate() {
      return this.day.date
        .split("-")
        .reverse()
        .join("-");
    }
  },
  methods: {
    /**
     * Captura evento de cambio de valor en el switch del dia no laboral
     * @param {Number} index
     */
    onChange(index) {
      this.$emit("change", index);
    }
  }
};
</script>
