<template>
  <div>
    <base-header
      title="Mapeador de modelos por Integración"
      :title-link="`/integracion/${integrationConfigId}/translation_map/`"
      :map="['Editar']"
      :title-size="12"
    ></base-header>

    <b-container fluid="">
      <b-row>
        <b-col md="6">
          <div class="enablescroller">
            <label class="label">Tipo de modelo</label>
            <v-select :options="myOptions" v-model="model_type" required />
          </div>

          <autoextra :collection="maps" v-slot="{ item, last, index }">
            <b-row :class="{ last: last }">
              <b-col md="6">
                <div class="field column">
                  <div class="control">
                    <label class="label"
                      >{{ last ? "Nuevo " : "" }}Campo de Centry
                      {{ !last ? index + 1 : "" }}</label
                    >
                    <b-form-input v-model="item.centryField" required />
                  </div>
                </div>
              </b-col>
              <b-col md="6">
                <div class="field column">
                  <label class="label">Campo del modelo a integrar</label>
                  <div class="control">
                    <b-form-input v-model="item.integrationField" />
                  </div>
                </div>
              </b-col>
            </b-row>
          </autoextra>

          <b-button variant="info" :disabled="saving" @click="save">
            <b-icon-cloud-upload></b-icon-cloud-upload> Guardar
          </b-button>
        </b-col>

        <b-col md="6">
          <b-button
            homologationMap="info"
            :title="'Probar modelo'"
            @click="testWithOrder"
            class="button is-danger"
          >
            <span v-if="saving"><b-spinner label="Spinning"></b-spinner></span>
            <span v-else><b-icon-check></b-icon-check>Probar Modelo</span>
          </b-button>
          <json-viewer
            :value="jsonData"
            :expand-depth="5"
            copyable
            boxed
            sort
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import BaseHeader from "@/components/BaseHeader.vue";
import Autoextra from "vue-autoextra";
import JsonViewer from "vue-json-viewer";

import { updateHomologationMap } from "@/main";
import { updateVisualizationHomologationMap } from "@/main";
import AllTranslationCentryIntegrations from "../../graphql/AllTranslationCentryIntegrations.gql";

export default {
  name: "TranslationCentryIntegrationEdit",
  data() {
    return {
      integrationConfigId: this.$route.params.integrationConfigId,
      translationCentryIntegrationId: this.$route.params.translationMapId,
      maps: this.getHomologationMaps(),
      jsonData: {},
      model_type: "",
      myOptions: ["Products::Order"],
      saving: false,
      changed: false,
      saved: false,
      errorSaving: false,
      formErrors: false
    };
  },

  components: {
    Autoextra,
    BaseHeader,
    JsonViewer
  },

  methods: {
    updateHomologationMap(
      params,
      integration_config_id,
      translationCentryIntegrationId
    ) {
      updateHomologationMap(
        this.$apollo,
        params,
        integration_config_id,
        translationCentryIntegrationId
      )
        .then(({ data }) => {
          if (
            data &&
            data.updateHomologationMap &&
            data.updateHomologationMap.homologation_map
          ) {
            this.$router.push(
              "/integracion/" +
                this.$route.params.integrationConfigId +
                "/translation_map/"
            );
          }
        })
        .catch(() => {
          this.errorSaving = true;
        });
    },
    updateVisualizationHomologationMap(
      params,
      integration_config_id,
      translationCentryIntegrationId
    ) {
      updateVisualizationHomologationMap(
        this.$apollo,
        params,
        integration_config_id,
        translationCentryIntegrationId
      )
        .then(({ data }) => {
          if (data && data.updateVisualizationHomologationMap) {
            this.jsonData = JSON.parse(
              data.updateVisualizationHomologationMap.result
            );
            return JSON.parse(data.updateVisualizationHomologationMap.result);
          }
        })
        .catch(() => {
          this.errorSaving = true;
        });
    },
    cancel() {
      this.$router.push(
        "/integracion/" +
          this.$route.params.integrationConfigId +
          "/translation_map/"
      );
    },
    getHomologationMaps() {
      this.$apollo
        .query({
          query: AllTranslationCentryIntegrations,
          variables: {
            integrationConfigId: this.$route.params.integrationConfigId,
            id: this.$route.params.translationMapId
          }
        })
        .then(({ data }) => {
          if (data) {
            this.model_type =
              data.allTranslationCentryIntegrations.edges[0].node.modelType;
            this.maps =
              data.allTranslationCentryIntegrations.edges[0].node.homologationMaps;
          }
          this.loadingCategoryAttributes = false;
        });
    },
    save() {
      this.saving = true;
      this.errorSaving = false;

      this.updateHomologationMap(
        this.maps,
        this.$route.params.integrationConfigId,
        this.$route.params.translationMapId
      );
      this.$router.push(
        "/integracion/" +
          this.$route.params.integrationConfigId +
          "/translation_map/"
      );
      this.saving = false;
      this.saved = true;
      if (!this.errorSaving) {
        this.changed = false;
        this.$emit("change", false);
      }
    },

    testWithOrder() {
      this.updateVisualizationHomologationMap(
        this.maps,
        this.$route.params.integrationConfigId,
        this.$route.params.translationMapId
      );

      this.saving = false;
      this.saved = true;
      if (!this.errorSaving) {
        this.changed = false;
        this.$emit("change", false);
      }
    }
  }
};
</script>

<style scoped>
.last {
  opacity: 0.3;
  margin-bottom: 15px;
}
</style>
