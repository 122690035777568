<template>
  <div>
    <b-row>
      <b-col cols="6"
        ><b-spinner v-if="loadingItems" label="Spinning"></b-spinner
      ></b-col>
      <b-col>
        <b-form-input
          placeholder="ID MercadoLibre"
          v-model="itemId"
          @input="filter"
          mb="2"
        />
      </b-col>
      <b-col>
        <v-select
          placeholder="Status"
          :options="statuses"
          v-model="status"
          @input="filter"
          :reduce="cond => cond.value"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import MERCADO_LIBRE_GET_PROMOTION_ITEMS from "../graphql/MercadoLibreGetPromotionItems.gql";

export default {
  name: "MercadoLibrePromotionItems",
  model: {
    prop: "_value",
    event: "change"
  },
  props: {
    _value: {
      type: Array,
      required: true
    },
    integrationConfigId: {
      type: String,
      required: true
    },
    dealId: {
      type: String,
      required: true
    },
    dealType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loadingItems: true,
      meliItems: [],
      totalItems: 1,
      searchAfter: null,
      itemId: null,
      status: null,
      statuses: [
        { value: "started", label: "Ya empezó" },
        { value: "finished", label: "Finalizado" },
        { value: "pending", label: "Pendiente" },
        { value: "candidate", label: "Candidato" }
      ]
    };
  },
  mounted() {
    this.getItems();
  },
  methods: {
    /**
     * Obtiene todo los items de la promoción de forma paginada. Se pide la
     * primera página para obtener los datos de paginación y luego obtiene el
     * resto de las paginas en paralelo.
     */
    async getItems() {
      this.loadingItems = true;
      this.paginatedPromotionItemsWithRetries();
    },
    async filter() {
      this.searchAfter = null;
      this.meliItems = [];
      this.getItems();
    },
    /**
     * Realiza la petición para obtener información de los items de una
     * promoción
     * @param {options} options
     * @return {Object} respuesta de la mutación
     */
    async promotionItems(options) {
      let variables = {
        integrationConfigId: this.integrationConfigId,
        dealId: this.dealId,
        dealType: this.dealType,
        itemId: this.itemId,
        status: this.status
      };
      variables = { ...variables, ...options };
      return this.$apollo.query({
        query: MERCADO_LIBRE_GET_PROMOTION_ITEMS,
        variables: variables
      });
    },
    /**
     * Intenta <code>retries</code> veces la obtención de una página de items
     * @param {Int} retries
     * @return {Object} respuesta de la mutación
     */
    async paginatedPromotionItemsWithRetries(retries = 4) {
      return this.$retryCall(retries, () =>
        this.paginatedPromotionItems(this.searchAfter)
      ).then(() => {
        this.$emit("change", this.meliItems);
        this.loadingItems = false;
      });
    },
    /**
     * Obtiene los items de la promoción por página y los agrega al jexcel.
     * El limit de meli para el request es de 50, asique cada página se
     * considera de tamaño 50. Es decir, para obtener la tercera página se
     * necesita un offset de 100.
     * @param {String} searchAfter
     * @return {Object} respuesta de la mutación
     */
    async paginatedPromotionItems(searchAfter = null) {
      return this.promotionItems({ searchAfter: searchAfter }).then(result => {
        let response = result.data.mercadoLibrePromotionItems;
        if (response.results && response.results.length > 0) {
          this.addItems(response.results);
          this.totalItems = response.paging.total;
          this.searchAfter = response.paging.searchAfter;
          this.$emit("cursor", response.paging.searchAfter);
        }
        return response;
      });
    },
    /**
     * Agrega los items obtenido al listado del componente
     * @param {Array<Object>} itemsArray
     */
    addItems(itemsArray) {
      itemsArray.forEach(item => {
        this.meliItems.push(item);
      });
    }
  },
  watch: {
    loadingItems(loading) {
      this.$emit("loading", loading);
    }
  }
};
</script>
