<template>
  <div>
    <b-container fluid="sm">
      <b-row>
        <ul id="demo">
          <base-category-tree-item
            v-for="cat of categoriesArray"
            :key="cat.id"
            class="item"
            :item="cat"
            :children-path="childrenPath"
            :selected="category"
            :item-id="itemId"
            @category="emitCategory"
          ></base-category-tree-item>
        </ul>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import BaseCategoryTreeItem from "@/components/BaseCategoryTreeItem";
export default {
  name: "BaseCategoryTree",
  components: { BaseCategoryTreeItem },
  props: {
    categories: Array,
    childrenPath: String,
    idPath: String,
    itemId: {
      type: String,
      default: "id"
    }
  },
  data() {
    return {
      aux: [],
      category: {},
      categoriesArray: [...this.categories]
    };
  },
  beforeMount() {
    this.aux.push(this.categories);
    this.addPath();
  },
  methods: {
    /**
     * Emite el evento 'category' informando la categoria seleccionada
     * @param {Object} category
     */
    emitCategory(category) {
      this.$emit("category", category);
      this.category = category;
    },
    /**
     * Agrega el atributo path a las categorias
     */
    addPath() {
      this.categoriesArray = this.categoriesArray.map(category => {
        let path = [];
        path.push(category.name);
        category.path = path;
        return category;
      });
    }
  },
  watch: {
    categories: function(newCategories) {
      this.categoriesArray = [...newCategories];
      this.addPath();
    }
  }
};
</script>
