<template>
  <div>
    <b-row>
      <b-col md="12" class="mb-4">
        <b-button-group>
          <b-button variant="outline-info" @click="cancel">
            <b-icon-x-circle></b-icon-x-circle> Cancelar
          </b-button>
          <b-button
            variant="info"
            :disabled="!changed || saving"
            :title="changed ? '' : 'No hay cambios'"
            v-b-tooltip.hover=""
            @click="save"
          >
            <span v-if="saving"><b-spinner label="Spinning"></b-spinner></span>
            <span v-else>
              <b-icon-cloud-upload></b-icon-cloud-upload>Guardar
            </span>
          </b-button>
        </b-button-group>
        <b-alert
          v-model="saved"
          :variant="error ? 'danger' : 'success'"
          dismissible
        >
          {{ error ? "Error: " + error : "Cambios guardados con éxito" }}
        </b-alert>
      </b-col>
    </b-row>
    <br />
    <b-row>
      <b-col md="6">
        <size-chart-mirakl-filter> </size-chart-mirakl-filter>
        <br />
        <size-chart-mirakl-homologator
          v-model="selectedSizeChart"
          :integration-config-id="integrationConfig.id"
        >
        </size-chart-mirakl-homologator>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import SizeChartMiraklFilter from "./SizeChartMiraklFilter.vue";
import SizeChartMiraklHomologator from "./SizeChartMiraklHomologator.vue";
import UPDATE_SIZE_CHART from "../../graphql/SizeChart/UpdateSizeChart.gql";

export default {
  name: "SizeChartTabsMirakl",
  components: {
    SizeChartMiraklFilter,
    SizeChartMiraklHomologator
  },
  props: {
    integrationConfig: Object,
    sizeChart: Object
  },
  data() {
    const original = this.sizeChart.homologations[
      this.integrationConfig.fullLabel
    ];

    return {
      changed: false,
      saving: false,
      saved: false,
      error: null,
      selectedSizeChart: original ? original.tablas_tallas : "",
      oldSelectedSizeChart: original ? original.tablas_tallas : ""
    };
  },
  methods: {
    /**
     * Cancela formulario
     */
    cancel() {
      if (this.changed) {
        this.$swal
          .fire({
            title: "Cancelar",
            text: "Si cancelas, perderás tus cambios. ¿Estás seguro?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "No"
          })
          .then(result => {
            if (result.value) {
              this.$router.push("/size_charts/");
            }
          });
      } else {
        this.$router.push("/size_charts/");
      }
    },
    /**
     * Guarda informacion de homologaciones de tabla de tallas
     */
    save() {
      this.saving = true;
      const updatedHomologations = this.calculateUpdatedHomologations();
      if (updatedHomologations) {
        this.updateSizeChart(updatedHomologations);
      }
      this.changed = false;
    },
    /**
     * Calcula homologaciones para enviar a Centry
     * @returns {Object}
     */
    calculateUpdatedHomologations() {
      const changes = this.calculateChangedData();

      const updated = { homologations: {} };
      updated.homologations[this.integrationConfig.fullLabel] = {};

      let changed = false;
      changes.forEach(change => {
        if (change.original !== change.current) {
          changed = true;
          updated.homologations[this.integrationConfig.fullLabel][change.key] =
            change.current;
        }
      });

      if (changed) return updated.homologations;
      return null;
    },
    /**
     * verifica cambios en valores de tablas
     * @returns {Object}
     */
    calculateChangedData() {
      let original = this.sizeChart.homologations[
        this.integrationConfig.fullLabel
      ];
      if (!original) original = {};

      const changes = [
        {
          key: "tablas_tallas",
          original: original.tablas_tallas,
          current: this.selectedSizeChart
        }
      ];

      return changes;
    },
    /**
     * Realiza petición de actualizacion de homologaciones
     * @param {Object} updatedHomologations
     */
    updateSizeChart(updatedHomologations) {
      let sizeChartInput = {
        homologations: updatedHomologations
      };
      this.$apollo
        .mutate({
          mutation: UPDATE_SIZE_CHART,
          variables: { id: this.sizeChart.id, patch: sizeChartInput }
        })
        .then(response => {
          this.setSaved(null);
          let sizeChart = response.data.updateSizeChart.sizeChart;
          this.$emit("change", sizeChart);
        })
        .catch(error => {
          this.setSaved(error);
        });
    },
    /**
     * Setea estado de error
     * @param {*} error
     */
    setSaved(error) {
      this.error = error;
      this.saving = false;
      this.saved = true;
      this.oldSelectedSizeChart = this.selectedSizeChart;
    }
  },
  watch: {
    selectedSizeChart(newValue) {
      this.changed = newValue != this.oldSelectedSizeChart;
    }
  }
};
</script>
