<template>
  <div>
    <hr />
    <b-row align-h="between" no-gutters class="mb-2">
      <h4 class="font-weight-bold my-auto">
        Reportes de llamadas
      </h4>
      <b-button
        variant="info"
        @click="generateReport"
        :disabled="!$dig(webhook, 'id')"
      >
        Generar nuevo reporte <b-spinner v-if="loading" small />
      </b-button>
    </b-row>
    <base-alert-with-count-down
      v-model="showAlert"
      :alert-variant="alertVariant"
    >
      {{ alertMessage }}
    </base-alert-with-count-down>
    <b-skeleton-table v-if="!$dig(webhook, 'id')" :rows="5" :columns="4" />
    <ApolloQuery
      v-else
      :query="require('../../graphql/Webhooks/AllWebhookReports.gql')"
      :variables="queryVariables('')"
      :fetchPolicy="'cache-and-network'"
      clientId="apolloClientCached"
      :pollInterval="pollInterval"
      @result="checkResult"
    >
      <template slot-scope="{ result: { error, data }, query, isLoading }">
        <span v-if="error"
          >Error al obtener los reportes. Reintentar
          <b-link @click="query.refetch()">aqui.</b-link>
        </span>
        <b-spinner label="Spinning" v-else-if="isLoading && !data"></b-spinner>

        <span v-else-if="data && isLoading" class="m-2 float-right">
          Actualizando lista de reportes...
        </span>

        <b-table-simple responsive v-if="data && data.allWebhookReports">
          <b-thead>
            <b-tr>
              <b-th>Fecha</b-th>
              <b-th>Responsable</b-th>
              <b-th>Progreso</b-th>
              <b-th></b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-if="!data.allWebhookReports.edges.length">
              <b-td colspan="4" class="text-center">
                -- no hay reportes disponibles --
              </b-td>
            </b-tr>
            <report-table-row
              v-for="report of data.allWebhookReports.edges"
              :key="report.node.id"
              :report="report.node"
              :current-user="currentUser"
            />
          </b-tbody>
        </b-table-simple>

        <b-spinner
          v-if="isLoading"
          label="Spinning"
          class="m-2 float-right"
        ></b-spinner>
        <div v-else>
          <b-button
            v-if="hasNextPage(data)"
            class="m-2 float-right"
            @click="seeMore(query, data.allWebhookReports.pageInfo.endCursor)"
          >
            Ver más
          </b-button>
          <b-alert v-else show variant="light" class="text-center">
            No hay más datos para mostrar.
          </b-alert>
        </div>
      </template>
    </ApolloQuery>
  </div>
</template>

<script>
import CREATE_WEBHOOK_REPORT from "../../graphql/Webhooks/CreateWebhookReport.gql";
import ReportTableRow from "./ReportsTableRow.vue";
import BaseAlertWithCountDown from "../Base/BaseAlertWithCountDown.vue";
import { mapState } from "vuex";
export default {
  name: "WebhookReport",
  props: {
    webhook: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  components: {
    ReportTableRow,
    BaseAlertWithCountDown
  },
  data() {
    return {
      pollInterval: 0,
      newLength: null,
      firstId: null,
      error: false,
      showAlert: false,
      errorMessage: null,
      loading: false
    };
  },
  computed: {
    ...mapState(["currentUser"]),
    alertVariant() {
      return this.error ? "danger" : "success";
    },
    alertMessage() {
      if (!this.error) {
        return "Generación de reporte iniciada exitosamente, abajo puedes ver el avance";
      } else {
        return `Ha ocurrido un error iniciando la generación del reporte: ${this.errorMessage}`;
      }
    }
  },
  methods: {
    /**
     * Indica si hay una página siguiente en la query de GraphQl basado
     * exclusivamente en la existencia de un cursor.
     * @return {Boolean}
     */
    hasNextPage(data) {
      if (
        (!this.newLength && data?.allWebhookReports?.edges.length < 10) ||
        (this.newLength && this.newLength < 10)
      ) {
        return false;
      }
      return data?.allWebhookReports?.pageInfo?.endCursor != null;
    },
    /**
     * Trae más resultados de una query avanzando en su cursor.
     * @param {Object} query
     * @param {String} cursor
     */
    async seeMore(query, cursor) {
      await query.fetchMore({
        variables: this.queryVariables(cursor),
        updateQuery: (prev, { fetchMoreResult }) => {
          const updated = Object.assign({}, this.$dup(prev));
          updated.allWebhookReports.pageInfo =
            fetchMoreResult.allWebhookReports.pageInfo;
          let length = fetchMoreResult?.allWebhookReports?.edges?.length;
          if (length !== 0) {
            this.newLength = length;
            updated.allWebhookReports.edges.push(
              ...fetchMoreResult.allWebhookReports.edges
            );
          }
          return updated;
        }
      });
    },
    /**
     * Se encarga crear el objeto de las variables para la query
     * @param {String} cursor
     * @param {Object} variables
     * @return {Object}
     */
    queryVariables(cursor, variables = {}) {
      if (cursor) {
        variables.cursor = cursor;
      }
      variables.first = 10;
      variables.webhookId = this.webhook?.id;

      return variables;
    },
    /**
     * Revisa si se actualizo la lista de reportes, si lo hizo deja de hacer las consultas
     * @param {Object} result - respuesta de query
     */
    checkResult(result) {
      if (result?.data?.allWebhookReports) {
        let newId = result.data.allWebhookReports?.edges[0]?.node?.id;
        if (newId !== this.firstId) {
          this.firstId = newId;
          this.pollInterval = 0;
        }
      }
    },
    /**
     * Envia la mutacion para generar el reporte de webhooks
     */
    generateReport() {
      this.error = false;
      this.errorMessage = null;
      this.loading = true;
      this.$apollo
        .mutate({
          mutation: CREATE_WEBHOOK_REPORT,
          variables: { webhookId: this.webhook.id }
        })
        .then(({ data }) => {
          if (!data.createWebhookReport.result) {
            this.errorMessage = data.createWebhookReport.error;
            this.error = true;
          } else {
            this.pollInterval = 1000;
          }
          this.showAlert = true;
          this.loading = false;
        })
        .catch(e => {
          this.errorMessage = e.message;
          this.error = true;
          this.showAlert = true;
          this.loading = false;
        });
    }
  }
};
</script>

<style></style>
