<template>
  <b-modal
    id="my-modal"
    size="lg"
    centered
    hide-header-close
    no-close-on-backdrop
    scrollable
  >
    <template #modal-title>
      <h3>{{ titleModal }}</h3>
    </template>
    <b-skeleton-table
      v-if="loadingClients"
      :rows="4"
      :columns="fields_clients.length"
      :table-props="{ bordered: true, striped: true }"
    ></b-skeleton-table>
    <div v-else>
      <b-alert v-if="success" show="" :variant="alertVariant" dismissible>
        {{ alertMessage }}
      </b-alert>
      <b-button
        class="m-2"
        :variant="buttonVariant"
        size="sm"
        @click="goToForm"
      >
        {{ buttonControl }}
      </b-button>
      <b-table
        v-if="selectorDefaultClient"
        :items="clients"
        :fields="fields_clients"
        responsive
        style="background-color: #f7f8f8;"
      >
        <template #thead-top>
          <b-tr>
            <b-th>
              <b-form-input
                v-model="filters.firstName"
                placeholder="Nombre"
                size="sm"
              ></b-form-input>
            </b-th>
            <b-th>
              <b-form-input
                v-model="filters.lastName"
                placeholder="Apellido"
                size="sm"
              ></b-form-input>
            </b-th>
            <b-th>
              <b-form-input
                v-model="filters.code"
                placeholder="Rut"
                size="sm"
              ></b-form-input>
            </b-th>
            <b-th>
              <b-spinner v-if="loadingClients"></b-spinner>
              <b-button
                v-else
                variant="dark"
                size="sm"
                @click="searchClientsByFilters"
              >
                Buscar
              </b-button>
            </b-th>
          </b-tr>
        </template>
        <template #cell(select_client)="data">
          <b-button
            v-if="defaultClientId != data.item.id"
            size="sm"
            v-b-modal="'modal-1'"
            class="mr-2"
            variant="info"
            @click="selectCandidateClient(data.item)"
          >
            Seleccionar
          </b-button>
          <div v-else class="mr-2">
            Cliente Seleccionado
          </div>
        </template>
        <template #custom-foot>
          <b-tr>
            <b-td colspan="4">
              <b-spinner
                v-if="isLoadingMore"
                label="Spinning"
                class="m-2 float-right"
              ></b-spinner>
              <div v-else>
                <b-button
                  v-if="hasNextPage()"
                  class="m-2 float-right"
                  @click="seeMore()"
                >
                  Ver más
                </b-button>
                <b-alert v-else show variant="light" class="text-center">
                  No hay más datos para mostrar.
                </b-alert>
              </div>
            </b-td>
          </b-tr>
        </template>
      </b-table>
      <template v-else>
        <form-create-client
          @cancel="goToForm"
          :ic="ic"
          @choice="selectNewClient"
        />
      </template>
    </div>

    <template #modal-footer>
      <b-button
        class="mt-3"
        variant="outline-secondary"
        @click="$bvModal.hide('my-modal')"
        :disabled="false"
      >
        Cerrar
      </b-button>
    </template>
    <b-modal
      id="modal-1"
      centered
      hide-header-close
      no-close-on-backdroptitle="BootstrapVue"
    >
      <template #modal-title>
        <h3>Seleccionar Cliente</h3>
        <b-alert v-if="error" show="" :variant="alertVariant" dismissible>
          {{ alertMessage }}
        </b-alert>
      </template>
      <p>
        ¿Está seguro de seleccionar este cliente por defecto? El sistema estará
        generando documentos de venta para él.
      </p>
      <div v-if="candidateClient">
        <b>Nombre:</b>
        {{ candidateClient.firstName }}
        <br />
        <b>Apellido:</b>
        {{ candidateClient.lastName }}
        <br />
        <b>Rut:</b>
        {{ candidateClient.code }}
        <br />
      </div>
      <template #modal-footer>
        <b-button
          class="mt-3"
          variant="info"
          @click="saveNewDefaultClient"
          :disabled="success"
          v-if="!saving"
        >
          Cambiar Cliente
        </b-button>
        <div class="m-4" v-else>
          <b-spinner></b-spinner>
        </div>
        <b-button
          class="mt-3"
          variant="outline-secondary"
          @click="closeCandidateClient"
          :disabled="false"
        >
          Cancelar
        </b-button>
      </template>
    </b-modal>
  </b-modal>
</template>

<script>
import UPDATE_DEFAULT_CLIENT_BSALE from "../../../graphql/IntegrationConfig/BSale/UpdateIntegrationConfigBSale.gql";
import INTEGRATION_DATA_CURRENT_DEFAULT_CLIENT from "../../../graphql/IntegrationConfig/BSale/getDefaultClient.gql";
import INTEGRATION_DATA_DEFAULT_CLIENTS from "../../../graphql/IntegrationConfig/BSale/getDefaultClientsBSale.gql";
import FormCreateClient from "./FormCreateClient.vue";
export default {
  name: "ModalClientsBSale",
  components: {
    FormCreateClient
  },
  data() {
    return {
      candidateClient: {},
      saving: false,
      error: false,
      success: false,
      errorMessage: "",
      fields_clients: [
        { key: "firstName", label: "Nombre", sortable: true },
        { key: "lastName", label: "Apellido", sortable: true },
        { key: "code", label: "Rut", sortable: true },
        { key: "select_client", label: "" }
      ],
      clients: [],
      loadingClients: true,
      clientErrorData: "",
      limit_clients: 50,
      offset_clients: 0,
      isLoadingMore: false,
      firstLoadClients: true,
      filters: {
        firstName: "",
        lastName: "",
        code: ""
      },
      selectorDefaultClient: true
    };
  },
  props: {
    ic: Object,
    defaultClientId: String,
    currentDefaultClient: Object
  },
  mounted() {
    this.$root.$on("bv::modal::show", () => {
      this.success = false;
      this.error = false;
      this.tryToLoadClients();
    });
    this.loadCurrentDefaultClient();
  },
  methods: {
    /**
     * Carga el nombre del cliente por defecto actual en el campo que corresponde
     */
    loadCurrentDefaultClient() {
      if (!this.defaultClientId || !this.defaultClientId.length) {
        this.$emit("setCurrentDefaultClient", null);
        this.$emit("loadingCurrentDefaultClient", false);
        return;
      }
      this.$emit("loadingCurrentDefaultClient", true);
      this.$apollo
        .query({
          query: INTEGRATION_DATA_CURRENT_DEFAULT_CLIENT,
          variables: {
            integrationConfigId: this.ic.id,
            client_id: this.defaultClientId
          }
        })
        .then(({ data }) => {
          this.$emit(
            "setCurrentDefaultClient",
            data.getDefaultClientBSale,
            false
          );
          this.$emit("loadingCurrentDefaultClient", false);
          this.$emit("set", true);
        })
        .catch(() => {
          this.$emit("setCurrentDefaultClient", null);
          this.$emit("loadingCurrentDefaultClient", false);
          this.$emit(
            "changeDescription",
            "Error: No fue posible obtener información de cliente por defecto actual"
          );
        });
    },
    /**
     * Carga las opciones de clientes
     * en tabla de seleccion de cliente por defecto
     */
    async loadClients() {
      this.$apollo
        .query({
          query: INTEGRATION_DATA_DEFAULT_CLIENTS,
          variables: {
            integrationConfigId: this.ic.id,
            limit: this.limit_clients,
            offset: this.offset_clients,
            firstName: this.filters.firstName,
            lastName: this.filters.lastName,
            code: this.filters.code
          }
        })
        .then(({ data }) => {
          this.clients = this.clients.concat(data.getDefaultClientsBSale);
          this.current_clients = data.getDefaultClientsBSale;
          this.loadingClients = false;
          this.isLoadingMore = false;
          this.firstLoadClients = false;
        })
        .catch(() => {
          this.clients = [];
          this.current_clients = [];
          this.loadingClients = false;
          this.isLoadingMore = false;
          this.clientErrorData =
            "Error: No pudimos cargar el listado de clientes. Revisa los datos de vinculación";
        });
    },
    /**
     * Se verifica que todavia se puede realizar paginamiento en
     * el listado de clientes por defecto
     * @return {Boolean}
     */
    hasNextPage() {
      return this.current_clients.length === this.limit_clients;
    },
    /**
     * Se consulta por Clientes disponibles para BSale
     */
    async searchClients() {
      this.loadingClients = true;
      await this.loadClients();
    },
    /**
     * Se solicita ver la proxima pagina del listado de clientes disponibles
     */
    async seeMore() {
      this.offset_clients = this.clients.length;
      this.isLoadingMore = true;
      await this.loadClients();
    },
    /**
     * Carga de listado de clientes disponibles de forma lazy
     */
    async tryToLoadClients() {
      if (this.firstLoadClients) {
        this.loadingClients = true;
        await this.loadClients();
      }
    },
    /**
     * Edita el cliente por defecto actual
     * @param {Object} newDefaultClient
     */
    changeCurrentDefaultClient(newDefaultClient) {
      this.$emit("setCurrentDefaultClient", newDefaultClient, true);
    },
    /**
     * Se selecciona un cliente candidato para ser
     * el cliente por defecto.
     * @param {Object} client_data
     */
    selectCandidateClient(client_data) {
      this.candidateClient = client_data;
    },
    /**
     * Se guarda en sistema nuevo cliente por defecto
     * Se actualiza informacion del cliente por defecto actual en el formulario
     */
    saveNewDefaultClient() {
      this.saving = true;
      this.error = false;
      this.success = false;
      this.errorMessage = null;
      const changes = { defaultClientId: this.candidateClient.id };
      this.$apollo
        .mutate({
          mutation: UPDATE_DEFAULT_CLIENT_BSALE,
          variables: {
            integrationConfigId: this.ic.id,
            patch: changes
          }
        })
        .then(({ data }) => {
          this.saving = false;
          if (data?.updateIntegrationConfigBSale?.result) {
            this.errorMessage = null;
            this.error = false;
            this.success = true;
            this.changeCurrentDefaultClient(this.candidateClient);
            this.closeCandidateClient();
            this.$bvModal.hide("my-modal");
          } else {
            this.errorMessage = data?.updateIntegrationConfig?.error;
            this.error = true;
            this.success = false;
          }
        })
        .catch(e => {
          this.saving = false;
          this.errorMessage = e;
          this.error = true;
          this.success = false;
        });
    },
    /**
     * Reinicia el listado de clientes por defecto para
     * realizar la busqueda segun flitros de nombre, apellido y rut
     */
    searchClientsByFilters() {
      this.clients = [];
      this.offset_clients = 0;
      this.searchClients();
    },
    /**
     * Se cierra modal que pregunta si esta seguro de cambiar cliente por defecto
     * Se deja como nulo el cliente candidato
     */
    closeCandidateClient() {
      this.$bvModal.hide("modal-1");
      this.candidateClient = null;
    },
    /**
     * Cambia de Formulario a Tabla, o viceversa
     */
    goToForm() {
      this.selectorDefaultClient = !this.selectorDefaultClient;
    },
    /**
     * Selecciona el nuevo cliente por defecto y lo guarda en Centry
     */
    async selectNewClient(client) {
      this.candidateClient = client;
      await this.saveNewDefaultClient();
      this.$emit("change", client);
    }
  },
  computed: {
    alertVariant() {
      return this.error ? "danger" : "success";
    },
    alertMessage() {
      return this.error
        ? "Ha ocurrido un error guardando los datos: " + this.errorMessage
        : "Guardado exitosamente";
    },
    buttonControl() {
      return this.selectorDefaultClient ? "Crear Nuevo Cliente" : "Volver";
    },
    buttonVariant() {
      return this.selectorDefaultClient ? "info" : "outline-secondary";
    },
    titleModal() {
      return this.selectorDefaultClient
        ? "Clientes Disponibles"
        : "Crear Nuevo Cliente";
    }
  }
};
</script>
