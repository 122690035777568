<template>
  <div>
    <base-header
      title="Promociones de Mercado Libre"
      :title-link="titleLink"
      :map="['Mercado Libre', 'Integraciones', 'Promociones', 'Campaña SMART']"
    ></base-header>

    <mercado-libre-promotion-items
      v-model="meliItems"
      :integration-config-id="integrationConfigId"
      :deal-id="smartId"
      deal-type="SMART"
      @loading="value => (loadingItems = value)"
      ref="items"
      @cursor="value => (cursor = value)"
    />

    <div v-if="!loadingItems || items.length">
      <b-container fluid>
        <b-row>
          <b-col cols="10">
            <p>Nombre: Campaña Smart</p>
          </b-col>
          <b-col>
            <b-button @click="sendProducts()" :disabled="saving">
              <b-spinner label="Spinning" v-if="saving" />
              <b-icon-check-circle v-else></b-icon-check-circle>Guardar
            </b-button>
          </b-col>
        </b-row>
        <br />
      </b-container>

      <base-j-excel-table
        v-model="items"
        :columns="columns"
        :on-selection="this.onSelection"
        :allow-insert-row="false"
        :allow-insert-column="false"
      ></base-j-excel-table>
      <b-button v-if="!!cursor" :disabled="!!loadingItems" @click="seeMore"
        >Ver más</b-button
      >
    </div>
  </div>
</template>

<script>
import BaseHeader from "../components/BaseHeader.vue";
import BaseJExcelTable from "@/components/BaseJExcelTable";
import MercadoLibrePromotionItems from "../components/MercadoLibrePromotionItems";
import MERCADO_LIBRE_DELETE_PROMOTION_GENERIC from "@/graphql/MercadoLibreDeletePromotionGeneric.gql";
import CREATE_MERCADO_LIBRE_ITEM_PROMOTIONS_BODY from "@/graphql/MercadoLibreSetPromotionBody.gql";

export default {
  name: "MercadoLibrePromotionsSmartIndex",
  props: {
    integrationConfigId: {
      type: String,
      required: true
    },
    smartId: {
      type: String,
      required: true
    }
  },
  components: {
    BaseHeader,
    BaseJExcelTable,
    MercadoLibrePromotionItems
  },
  data() {
    return {
      cursor: true,
      loadingItems: true,
      meliItems: [],
      items: [],
      saving: false,
      ACTIONS: 11,
      ITEM_ID: 2,
      OFFER_ID: 3,
      STATUS: 8,
      PRICE: 5,
      ORIGINAL_PRICE: 4,
      columns: [
        { type: "checkbox", title: "Activo?" },
        { type: "text", title: "Producto", width: 240, readOnly: true },
        {
          type: "text",
          title: "Código MercadoLibre",
          width: 150,
          readOnly: true
        },
        { type: "text", title: "ID oferta", width: 120, readOnly: true },
        {
          type: "numeric",
          title: "Precio Original",
          width: 120,
          mask: "$ #.##0,0",
          decimal: ",",
          readOnly: true
        },
        {
          type: "numeric",
          title: "Precio Oferta",
          width: 120,
          mask: "$ #.##0,0",
          decimal: ",",
          readOnly: true
        },
        {
          type: "numeric",
          title: "Porcentaje MercadoLibre",
          width: 130,
          decimal: ",",
          readOnly: true
        },
        {
          type: "numeric",
          title: "Porcentaje Vendedor",
          width: 130,
          decimal: ",",
          readOnly: true
        },
        { type: "text", title: "Estado", width: 120, readOnly: true },
        { type: "text", title: "Fecha de Inicio", width: 200, readOnly: true },
        { type: "text", title: "Fecha de término", width: 200, readOnly: true },

        { type: "html", title: "Acciones", width: 120 }
      ]
    };
  },
  computed: {
    titleLink: {
      get: function() {
        return `/mercadolibre/promotions/${this.integrationConfigId}`;
      }
    }
  },
  methods: {
    /**
     * Crea el producto en la campaña
     * @param {Integer} index
     */
    createItemPromotion(index) {
      return this.$apollo
        .mutate({
          mutation: CREATE_MERCADO_LIBRE_ITEM_PROMOTIONS_BODY,
          variables: this.variablesToSendProduct(this.items[index])
        })
        .then(result => {
          console.log(result);
          const item_result = result.data.mercadoLibreSetPromotionBody.result;
          this.items[index][this.STATUS] = "Ya empezó";
          this.items[index][this.PRICE] = item_result.price;
          this.items[index][this.ORIGINAL_PRICE] = item_result.original_price;
          this.items[index][this.OFFER_ID] = item_result.offer_id;
        });
    },
    /**
     * Envía los productos a agregarse o quitarse de la campaña
     */
    sendProducts() {
      const processes = [];
      this.saving = true;
      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i][0] && this.items[i][this.STATUS] == "Candidato") {
          processes.push(this.createItemPromotion(i));
        } else if (
          this.items[i][0] == "0" &&
          this.items[i][this.STATUS] == "Ya empezó"
        ) {
          processes.push(
            this.deleteItem(
              this.items[i][this.ITEM_ID],
              i,
              this.items[i][this.OFFER_ID]
            )
          );
        }
      }
      Promise.all(processes).then(() => {
        var spreadsheet = document.getElementById("spreadsheet");
        spreadsheet.jexcel.refresh();
        this.saving = false;
      });
    },
    /**
     * Recibe los items de la promoción y realiza la transformación de datos
     * para que se muestren en la tabla
     * @param {Array<Object>} meliItems
     */
    addItemsToJExcel(meliItems) {
      this.items = [];
      meliItems.forEach(item => {
        this.items.push([
          item.status == "started" ? 1 : 0,
          item.productTitle,
          item.id,
          item.offerId,
          item.originalPrice != 0 ? item.originalPrice : "",
          item.price != 0 ? item.price : "",
          item.meliPercentage != 0 ? item.meliPercentage : "",
          item.sellerPercentage != 0 ? item.sellerPercentage : "",
          this.$options.filters.meliToCentry(item.status),
          item.startDate,
          item.endDate,
          "Eliminar"
        ]);
      });
    },
    /**
     * Si selecciona eliminar, se elimina el producto de la campaña
     * @param {Object} instance
     * @param {Integer} x1
     * @param {Integer} y1
     * @param {Integer} x2
     * @param {Integer} y2
     */
    async onSelection(instance, x1, y1, x2, y2) {
      if (x1 === x2 && y1 === y2 && x1 === this.ACTIONS) {
        if (confirm("El item sera eliminado")) {
          await this.deleteItem(
            this.items[y1][this.ITEM_ID],
            y1,
            this.items[y1][this.OFFER_ID]
          );
        }
      }
    },
    /**
     * Envía la request para eliminar el producto de la campaña
     * @param {String} itemId
     * @param {Integer} itemIndex
     * @param {String} offerId
     */
    async deleteItem(itemId, itemIndex, offerId) {
      return this.$apollo
        .mutate({
          mutation: MERCADO_LIBRE_DELETE_PROMOTION_GENERIC,
          variables: {
            item_id: itemId,
            integration_config_id: this.integrationConfigId,
            params: JSON.stringify({
              promotion_type: "SMART",
              offer_id: offerId,
              promotion_id: this.smartId
            })
          }
        })
        .then(result => {
          if (
            result.data.mercadoLibreDeletePromotionGeneric.result == "200" ||
            result.data.mercadoLibreDeletePromotionGeneric.result == 200
          ) {
            this.items[itemIndex][this.STATUS] = "Finalizado";
          }
        });
    },
    /**
     * Genera las variables necesarias para la creación/actualización del
     * item en la promoción
     * @param {Object} item
     */
    variablesToSendProduct(item) {
      return {
        body: JSON.stringify({
          offer_id: item[this.OFFER_ID],
          promotion_id: this.smartId,
          promotion_type: "SMART"
        }),
        item_id: item[this.ITEM_ID],
        integration_config_id: this.integrationConfigId
      };
    },
    /**
     * Carga una página más de ítemes
     */
    seeMore() {
      this.$refs.items.getItems();
    }
  },
  watch: {
    meliItems(newVal) {
      this.addItemsToJExcel(newVal);
    }
  }
};
</script>
