<template>
  <base-table-row-with-details
    :fields="fields"
    :sub_fields="sub_fields"
    :query="query"
    :queryVariables="queryVariables"
    :offset="offset"
    :baseOffset="''"
    :limit="limit"
    @setOffset="setOffset"
    :extractData="extractData"
    :extractDataRow="extractDataRow"
    :getCursorQuery="getCursorQuery"
    errorMessage="Hubo un error al tratar de obtener los correos enviados a su usuario."
    titleButtonRestart="Recargar correos enviados"
  >
    <template scope="{ row }">
      <b-table
        :fields="sub_fields"
        :items="[{ body: row.item.body }]"
        style="border: none; padding: 0 !important; margin: 0; height: 0;"
      >
        <template #cell(body)="data">
          <iframe
            style="width:100%; height: 300px; background-color: #ffffff"
            :srcdoc="data.value"
          ></iframe>
        </template>
      </b-table>
    </template>
  </base-table-row-with-details>
</template>

<script>
import moment from "moment-timezone";
import GET_USER_INBOX_NOTIFICATION from "../../graphql/UserInboxNotification/AllUserInboxNotifications.gql";
import BaseTableRowWithDetails from "../../components/Base/BaseTableRowWithDetails.vue";

export default {
  name: "UserInboxNotificationTable",
  components: {
    BaseTableRowWithDetails
  },
  props: {
    ic: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      fields: [
        { key: "date", label: "Fecha", sortable: true },
        { key: "from", label: "Emisor", sortable: true },
        { key: "to", label: "Para" },
        { key: "subject", label: "Asunto" },
        { key: "show_details", label: "" }
      ],
      sub_fields: [{ key: "body", label: "Contenido" }],
      offset: "",
      limit: 10,
      query: GET_USER_INBOX_NOTIFICATION
    };
  },
  methods: {
    /**
     * Construye objeto con variables para consulta de historial de
     * modificaciones.
     * El objeto debe incluir la id de la integration config, offset y limit
     */
    queryVariables() {
      const variables = {};
      variables.offset = this.offset;
      variables.limit = this.limit;
      return variables;
    },
    /**
     * Modifica el valor actual del offset
     * @param {Integer} newValue
     */
    setOffset(newValue) {
      this.offset = newValue;
    },
    /**
     * Extracción de datos según query
     */
    extractData(data) {
      return this.$dig(data, "allUserInboxNotifications", "edges");
    },
    /**
     * Transforma un record para que sea legible en la tabla
     * @param {Object} row
     * @returns {Object}
     */
    extractDataRow(row) {
      let newRow = this.$dig(row, "node");
      newRow.date = moment(newRow.date).format("DD/MM/YYYY HH:mm:ss");
      newRow.from = this.transformEmailList(newRow.from);
      newRow.to = this.transformEmailList(newRow.to);
      return newRow;
    },
    /**
     * Transforma un listado de correos en un string
     * @param {Array<String>} emails
     * @return {String}
     */
    transformEmailList(emails) {
      return Array.isArray(emails) ? emails.join(", ") : emails;
    },
    /**
     * Entrega Cursor actual para próximas consultas
     * @param {String} data
     * @return {String}
     */
    getCursorQuery(data) {
      return this.$dig(
        data,
        "allUserInboxNotifications",
        "pageInfo",
        "endCursor"
      );
    }
  }
};
</script>
