<template>
  <div>
    <b-alert
      v-model="errorSaving"
      :variant="errorSaving ? 'danger' : 'success'"
      dismissible
    >
      {{ messageAlert }}
    </b-alert>
    <div>
      <b-container fluid="">
        <b-row>
          <b-col md="12">
            <list-disponible-domains
              v-if="waitForDomain"
              :integrationConfigId="integrationConfigId"
              @confirm="onConfirmDomain"
            />
            <mercado-libre-select-product
              v-else-if="waitForProduct"
              :integration-config-id="integrationConfigId"
              title="Elige uno de tus productos como base, o comienza con el formulario vacío"
              empty-button-text="Ir al formulario vacío"
              @select="onProductSelect"
              :query="productQuery"
            />
            <form-domain-attributes
              v-else
              action="crear"
              :integrationConfigId="integrationConfigId"
              :domain="domain"
              :saving="saving"
              :previous="previous"
              @save="onSave"
              @cancel="onCancelForm"
              need-previous-validation
            />
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import MERCADO_LIBRE_CREATE_CATALOG_SUGGESTION from "../../../graphql/MercadoLibre/CatalogSuggestions/CreateCatalogSuggestion.gql";
import MERCADO_LIBRE_CREATE_DESCRIPTION_CATALOG_SUGGESTION from "../../../graphql/MercadoLibre/CatalogSuggestions/CreateDescriptionCatalogSuggestion.gql";
import MercadoLibreSelectProduct from "../../../components/MercadoLibre/SelectProduct.vue";
import ListDisponibleDomains from "./ListDisponibleDomains.vue";
import FormDomainAttributes from "../../../components/MercadoLibre/CatalogSuggestions/Form/DomainAttributes.vue";

export default {
  name: "MercadoLibreCatalogSuggestionsList",
  components: {
    ListDisponibleDomains,
    FormDomainAttributes,
    MercadoLibreSelectProduct
  },
  props: {
    integrationConfigId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      waitForDomain: true,
      domain: null,
      errorSaving: false,
      msgError: "",
      saving: false,
      waitForProduct: true,
      previous: null
    };
  },
  computed: {
    messageAlert() {
      if (this.errorSaving && this.msgError == "") {
        return "Ha ocurrido un error";
      } else if (this.msgError != "") {
        return this.msgError;
      } else {
        return "La sugerencia ha sido creada exitosamente";
      }
    },
    productQuery() {
      if (this.domain) {
        return { domainId: this.domain.id };
      }
      return {};
    }
  },
  methods: {
    /**
     * Funcion que recibe el evento de confirmar un dominio para la creación de sugerencia
     * @param {Object} domain
     */
    onConfirmDomain(domain) {
      this.waitForDomain = false;
      this.domain = { ...domain };
      this.waitForProduct = true;
    },
    /**
     * Cancela formulario, quitando la categoria seleccionada
     */
    onCancelForm() {
      this.waitForDomain = true;
      this.domain = null;
    },
    /**
     * Intenta guardar una nueva sugerencia a partir del body (atributos) y la descripcion
     * Una vez que se crea con exito, se intenta guardar la descripcion si esta no es vacia
     * @param {Object} body
     * @param {String} description
     */
    async onSave(body, description) {
      this.saving = true;
      const domainId = body.domainId;
      delete body.domainId;
      this.$apollo
        .mutate({
          mutation: MERCADO_LIBRE_CREATE_CATALOG_SUGGESTION,
          variables: {
            integrationConfigId: this.integrationConfigId,
            domainId: domainId,
            patch: body
          }
        })
        .then(({ data }) => {
          const response = this.$dig(
            data,
            "mercadoLibreCreateCatalogSuggestion"
          );
          if (this.$dig(response, "result")) {
            const suggestionId = this.$dig(response, "suggestion", "id");
            if (description != "") {
              this.createDescription(suggestionId, description);
            } else {
              this.fireSuccessCreation("Sugerencia creada.");
            }
          } else {
            this.errorSaving = true;
            const error = this.$dig(response, "error");
            var msg = "Ocurrio un error inesperado.";
            if (error) {
              msg += " " + JSON.parse(error)["cause"][0]["message"];
            }
            this.msgError = msg;
          }
          this.saving = false;
        })
        .catch(error => {
          this.errorSaving = true;
          this.msgError = `Ocurrio un error inesperado. ${error}`;
          this.saving = false;
        });
    },
    /**
     * Intenta crear descripcion de la sugerencia recien creada
     * se retorna si fue exitosa o no la creación
     * @param {String} suggestionId
     * @param {String} description
     * @returns {Boolean}
     */
    createDescription(suggestionId, description) {
      this.$apollo
        .mutate({
          mutation: MERCADO_LIBRE_CREATE_DESCRIPTION_CATALOG_SUGGESTION,
          variables: {
            integrationConfigId: this.integrationConfigId,
            suggestionId: suggestionId,
            description: description
          }
        })
        .then(({ data }) => {
          const response = this.$dig(
            data,
            "mercadoLibreCreateDescriptionCatalogSuggestion"
          );
          var textResult = "Sugerencia creada.";
          if (!this.$dig(response, "result")) {
            textResult += " Descripción no pudo ser creada.";
          }
          this.fireSuccessCreation(textResult);
        })
        .catch(() => {
          return false;
        });
    },
    /**
     * Lanza un modal que ratifica la creación exitosa para el usuario
     * @param {String} textResult
     */
    fireSuccessCreation(textResult) {
      this.$swal
        .fire({
          title: "Sugerencia creada",
          text: textResult,
          icon: "success"
        })
        .then(() => {
          this.$router.push(
            `/mercadolibre/catalog_suggestions/${this.integrationConfigId}/list`
          );
        });
    },
    /**
     * Se maneja la selección de un producto de base
     * @param {Object}
     */
    onProductSelect(product) {
      this.previous = product;
      this.waitForProduct = false;
    }
  }
};
</script>
