<template>
  <div>
    <div v-if="categoryAttributeList.length">
      <hr />
      <b-row class="mb-3" align-v="center">
        <b-col cols="11" sm="6" md="5" lg="7" xl="4">
          <h4 class="font-weight-bold my-auto d-inline ">
            Atributos específicos de categoría
          </h4>

          <base-help-modal
            class="d-inline"
            :linkClass="'p-0'"
            size="md"
            id="helpCategoryAttribute"
            tooltip="¿No encuentras un atributo? click aquí"
            title="¿No está el atributo que necesitas?"
            @show="showingModal = true"
            @hide="showingModal = false"
          >
            <product-edit-tabs-category-attributes-help-modal
              :ic-ids="icIds"
              :category-id="categoryId"
              :showing="showingModal"
              :publicIntegrationInformations="piiByCompany"
            ></product-edit-tabs-category-attributes-help-modal>
          </base-help-modal>
        </b-col>
        <search-category-attributes-input
          v-model="keyword"
          @change="setValueKeyword"
          :pii="publicIntegrationInformations"
          :piiSelected.sync="piiSelected"
        />
      </b-row>
      <b-row>
        <b-col
          xl="3"
          lg="3"
          md="4"
          sm="6"
          v-for="categoryAttribute of categoryAttributesFiltered"
          :key="categoryAttribute.id"
        >
          <b-form-group
            :label-for="'ca' + categoryAttribute.id"
            label-class="pt-1"
            :state="validation[categoryAttribute.id]"
            :invalid-feedback="messageInvalidFeedback(categoryAttribute)"
          >
            <template #label>
              <b-row>
                <b-col cols="8">
                  {{
                    (categoryAttribute.required ? "* " : "") +
                      categoryAttribute.name
                  }}
                </b-col>
                <b-col cols="4" v-if="categoryAttribute.colors.length > 0">
                  <div class="container-flags">
                    <div
                      class="flag"
                      v-for="(platform, ind) of categoryAttribute.colors"
                      :key="ind"
                      :style="{ 'background-color': platform.color }"
                      v-b-tooltip.hover.top="platform.name"
                    ></div>
                  </div>
                </b-col>
              </b-row>
            </template>
            <v-select
              :id="'ca' + categoryAttribute.id"
              v-if="categoryAttributeNeedOptions(categoryAttribute)"
              :multiple="categoryAttribute.valueType === 'select_many'"
              :options="
                categoryAttribute.options.map(x => {
                  return { label: x.name, value: x.id };
                })
              "
              :selectable="
                opt => {
                  return isSelectableOption(opt);
                }
              "
              :reduce="x => x.value"
              :clearable="true"
              @input="emitChange(categoryAttribute)"
              v-model="categoryAttributeValues[categoryAttribute.id]"
            ></v-select>
            <b-input
              :id="'ca' + categoryAttribute.id"
              v-else-if="categoryAttribute.valueType === 'number'"
              v-model="categoryAttributeValues[categoryAttribute.id]"
              type="number"
              min="0"
              @input="emitChange(categoryAttribute)"
            ></b-input>
            <base-boolean-selector
              v-else-if="categoryAttribute.valueType === 'boolean'"
              v-model="categoryAttributeValues[categoryAttribute.id]"
              :allow-null="true"
              true-text="Si"
              true-value="1"
              false-text="No"
              false-value="0"
              default-text="No aplica"
              @change="emitChange(categoryAttribute)"
            ></base-boolean-selector>
            <b-input
              :id="'ca' + categoryAttribute.id"
              v-else
              v-model="categoryAttributeValues[categoryAttribute.id]"
              @input="emitChange(categoryAttribute)"
            ></b-input>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import BaseHelpModal from "../../BaseHelpModal.vue";
import BaseBooleanSelector from "../../BaseBooleanSelector.vue";
import ProductEditTabsCategoryAttributesHelpModal from "./HelpModal.vue";
import SearchCategoryAttributesInput from "./SearchCategoryAttributesInput.vue";
import PII_BY_INTEGRATION_CONFIG from "../../../graphql/Product/Edit/piiByIntegrationConfig.gql";
export default {
  name: "ProductEditTabsCategoryAttributesForm",
  components: {
    BaseHelpModal,
    BaseBooleanSelector,
    ProductEditTabsCategoryAttributesHelpModal,
    SearchCategoryAttributesInput
  },
  model: {
    prop: "_value",
    event: "change"
  },
  props: {
    _value: {
      type: Object,
      default() {
        return {};
      }
    },
    categoryAttributes: {
      type: Object,
      default() {
        return {};
      }
    },
    icIds: {
      type: Array,
      default() {
        return [];
      }
    },
    categoryId: {
      type: String,
      default: ""
    },
    piiByCategoryAttributes: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      categoryAttributeValues: Object.assign({}, this._value),
      validation: {},
      showingModal: false,
      keyword: [],
      piiByCompany: [],
      piiSelected: [],
      valid: { required: true, validValues: true }
    };
  },
  created() {
    this.refreshValidation();
    this.getPiiByIntegrationConfig();
  },
  computed: {
    categoryAttributeList() {
      return Object.values(this.categoryAttributes);
    },
    categoryAttributeTerms() {
      return Object.values(this.categoryAttributes).map(categoryAttribute => {
        return this.formatTerm(categoryAttribute.name);
      });
    },
    /**
     * Retorna los atributos filtrados según keyword o pii seleccionados
     * @returns {Array<Hash>}
     */
    categoryAttributesFiltered() {
      if (this.keyword.length === 0 && this.piiSelected.length === 0)
        return this.categoryAttributeList;
      let filtered = this.categoryAttributeList;
      if (this.keyword.length) {
        filtered = this.categoryAttributeList.filter(
          (_categoryAttribute, index) => {
            return (
              this.keyword.filter(value =>
                this.categoryAttributeTerms[index].includes(value.toLowerCase())
              ).length > 0
            );
          }
        );
      }

      if (this.piiSelected.length) {
        filtered = filtered.filter(categoryAttribute => {
          let piis = categoryAttribute.piis;
          for (let pii of piis) {
            if (this.piiSelected.includes(pii)) {
              return true;
            }
          }
          return false;
        });
      }
      return filtered;
    },
    /**
     * Retorna un listado de pii que está ocupando la
     * empresa y que están dentro de los atributos
     * de categoría del producto.
     * @returns {Array<Hash>}
     */
    publicIntegrationInformations() {
      let labelPii = this.piiByCompany.map(pii => pii.name);
      return this.piiByCategoryAttributes.filter(value =>
        labelPii.includes(value)
      );
    }
  },
  methods: {
    /**
     * Se refrescan las validaciones de los atributos de categoria
     */
    refreshValidation() {
      if (!this.categoryAttributes || !this.categoryAttributeValues) return;
      const newValidations = {};
      this.valid = { required: true, validValues: true };
      Object.keys(this.categoryAttributes).forEach(catAttrId => {
        newValidations[catAttrId] = this.validValue(
          catAttrId,
          this.categoryAttributeValues[catAttrId]
        );
      });
      this.validation = newValidations;
      this.$emit("valid", this.valid);
    },
    /**
     * Captura evento de cambio en el input del atributo de categoría
     */
    emitChange(catAttr) {
      if (catAttr.valueType === "select_many") {
        this.removeUndefinedValues(catAttr.id);
      }
      this.refreshValidation();
      this.$emit("change", this.categoryAttributeValues);
    },
    /**
     * Remueve todos los valores +undefined+ de un arreglo de valores para
     * atributos de selección multiple
     * @param {String} catAttrId
     */
    removeUndefinedValues(catAttrId) {
      let values = this.categoryAttributeValues[catAttrId];
      this.categoryAttributeValues[catAttrId] = values.filter(
        v => v !== undefined
      );
    },
    /**
     * Verifica si el atributo de categoría puede
     * tener multiples valores asociados o no
     * @param {Object} categoryAttribute
     */
    categoryAttributeNeedOptions(categoryAttribute) {
      return (
        categoryAttribute.valueType === "select_one" ||
        categoryAttribute.valueType === "select_many"
      );
    },
    /**
     * Validacion de campos obligatorios
     * @param {String} catAttrId
     * @param {String || Array} value
     * @returns {Boolean}
     */
    validValue(catAttrId, value) {
      let hasValueRequired =
        !this.categoryAttributes[catAttrId].required ||
        this.hasValueAssigned(value);
      this.valid.required = this.valid.required && hasValueRequired;
      let validValues = this.reviewValuesSelect(catAttrId, value);
      this.valid.validValues = this.valid.validValues && validValues;
      return (
        (!this.categoryAttributes ||
          !this.categoryAttributes[catAttrId] ||
          hasValueRequired) &&
        validValues
      );
    },
    /**
     * Revisa si los valores selecciones en un campo de selección son válidos
     * Se verifica previamente si el atributo es de selección y tiene un valor asigando
     * @param {String} catAttrId
     * @param {String || Array} value
     * @returns {Boolean}
     */
    reviewValuesSelect(catAttrId, value) {
      let categoryAttribute = this.categoryAttributes[catAttrId];
      if (
        !this.categoryAttributeNeedOptions(categoryAttribute) ||
        !this.hasValueAssigned(value)
      ) {
        return true;
      }
      return this.reviewOptionsSelect(categoryAttribute, value);
    },
    /**
     * Retorna verdadero si un atributo tiene un valor asociado para sincronizar
     * @param {String || Array} value
     */
    hasValueAssigned(value) {
      return value !== null && value.length > 0;
    },
    /**
     * Envia a validar valores de selector segun seleccion unica o multiple
     * @param {Object} categoryAttribute
     * @param {String || Array} value
     */
    reviewOptionsSelect(categoryAttribute, value) {
      if (categoryAttribute.valueType === "select_many") {
        return this.reviewOptionsSelectMany(categoryAttribute, value);
      } else {
        return this.reviewOptionsSelectOne(categoryAttribute, value);
      }
    },
    /**
     * Valida si el valor actual de un atributo de seleccion única esta dentro de las opciones disponibles
     * @param {Object} categoryAttribute
     * @param {String} value
     * @returns {Boolean}
     */
    reviewOptionsSelectOne(categoryAttribute, value) {
      let options = categoryAttribute.options;
      return this.searchValueInOptions(options, value);
    },
    /**
     * Valida si todos los valores actuales de un atributo de seleccion multiple estan dentro de las opciones disponibles
     * @param {Object} categoryAttribute
     * @param {Array} value
     * @returns {Boolean}
     */
    reviewOptionsSelectMany(categoryAttribute, value) {
      let options = categoryAttribute.options;
      let ans = value.every(val => this.searchValueInOptions(options, val));
      return ans;
    },
    /**
     * Verifica si un valor es válido entre las opciones de un atributo
     * @param {Object} categoryAttribute
     * @param {Array} value
     * @returns {Boolean}
     */
    searchValueInOptions(options, val) {
      return !!options.find(opt => opt.id === val);
    },
    /**
     * Dar formato a los términos de los atributos para comparacion con tags
     * @param {String} term
     * @returns {String}
     */
    formatTerm(term) {
      return this.removeAccentDiacritics(term.toLowerCase());
    },
    /**
     * Elimina acentos de tag ingresado
     * @param {String} tag
     * @returns {String}
     */
    removeAccentDiacritics(tag) {
      return tag.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    },
    /**
     * Setea nuevos tags para filtrar listado
     * @param {Array<String>} newValue
     */
    setValueKeyword(newValue) {
      this.keyword = [...newValue];
    },

    /**
     * Obtiene los public integrations informations
     * de las integraciones de la empresa
     */
    getPiiByIntegrationConfig() {
      this.$apollo
        .query({ query: PII_BY_INTEGRATION_CONFIG })
        .then(({ data }) => {
          this.piiByCompany = this.getPublicIntegrationInformations(
            data.allIntegrationConfigs
              .filter(ic => this.icIds.includes(ic.id))
              .concat(this.getFalabellaIc(data))
          );
        });
    },
    /**
     * Obtiene los public_integration_information
     *  desde una lista de integration_configs
     * @param {Array<IntegrationConfig>} integrationConfigs - Lista de integratioonConfig
     * @return {Array<Object>}
     */
    getPublicIntegrationInformations(integrationConfigs) {
      var piis = {};
      integrationConfigs.forEach(ic => {
        var pii = ic.publicIntegrationInformation;
        if (pii) {
          piis[pii.id] = { id: pii.id, name: pii.name };
        }
      });
      return Object.values(piis);
    },
    /**
     * Entrega la lista de integration_config que
     * estan disponibles y son de Falabella Global
     * @param {Array} data - lista de ics
     * @return {Array}
     */
    getFalabellaIc(data) {
      if (!data || !data.allIntegrationConfigs) {
        return [];
      }
      const available = data.allIntegrationConfigs.filter(
        x => x.available && this.isFalabellaGlobal(x)
      );
      return available;
    },
    /**
     * Indica si el integration_config corresponde a Falabella Global
     * @return {Boolean}
     */
    isFalabellaGlobal(ic) {
      return ic.identifier.startsWith("falabella_global");
    },
    /**
     * Determina el mensaje de error para un atributo
     *
     */
    messageInvalidFeedback(categoryAttribute) {
      let catAttrId = categoryAttribute.id;
      let value = this.categoryAttributeValues[catAttrId];
      if (
        this.categoryAttributes[catAttrId].required &&
        !this.hasValueAssigned(value)
      ) {
        return `${categoryAttribute.name} es un atributo obligatorio.`;
      } else {
        return `${categoryAttribute.name} posee valor(es) inválido(s).`;
      }
    },
    /**
     * Indica si la opcion es seleccionable o esta deprecada
     * @param {Object} option
     * @returns {Boolean}
     */
    isSelectableOption(option) {
      return !option.label.includes("Deprecado");
    }
  },
  watch: {
    _value(newVal) {
      this.categoryAttributeValues = Object.assign({}, newVal);
      this.refreshValidation();
    },
    categoryAttributes() {
      this.refreshValidation();
    }
  }
};
</script>
<style scoped>
.container-flags {
  display: flex;
  height: 20px;
  justify-content: space-between;
}

.flag:hover {
  opacity: 1;
}

.flag {
  flex-grow: 1;
  opacity: 0.5;
}
</style>
