<template>
  <div>
    <b-skeleton height="50px" v-if="loading" />
    <b-alert v-else-if="!!errorLoading" :variant="variantAlert" show>
      {{ errorLoadingTitle }}
      {{ errorLoading }}
    </b-alert>
    <b-alert v-else-if="!!errors.length" :variant="variantAlert" show>
      {{ errorsTitle }}
      <ul>
        <li v-for="(error, i) of errors" :key="i">{{ error }}</li>
      </ul>
    </b-alert>
  </div>
</template>

<script>
export default {
  name: "MercadoLibreCatalogSuggestionFormResultantValidation",
  props: {
    errorLoadingTitle: {
      type: String,
      default: "Ha ocurrido un error al obtener el resultado de la validación: "
    },
    variantAlert: {
      type: String,
      default: "danger"
    },
    errorsTitle: {
      type: String,
      default: "Hay errores de validación en el formulario:"
    },
    doQuery: {
      type: Function,
      required: true
    },
    loadErrors: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      errors: [],
      errorLoading: null,
      loading: false
    };
  },
  mounted() {
    this.validate();
  },
  methods: {
    /**
     * Ejecuta la query para validar
     * @returns {Promise}
     */
    validate() {
      this.loading = true;
      this.doQuery()
        .then(({ data }) => {
          if (!data) {
            this.errorLoading =
              "Ha ocurrido un error al solicitar la validación";
            this.$emit("valid", false);
          } else {
            const errors = this.loadErrors(data);
            this.$emit("valid", !errors || !errors.length);
            this.errors = errors;
          }
        })
        .catch(err => {
          this.errorLoading = err.message;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
