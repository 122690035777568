<template>
  <div>
    <!-- :variables="{
        cursor: '',
        integrationConfigId: integrationConfigId,
        excludeEmpty: true,
        keywords: filters.keyword.split(',')}" -->
    <ApolloQuery
      :query="require('../graphql/AllStockDestinations.gql')"
      :variables="queryVariables('')"
      :fetchPolicy="'cache-and-network'"
      clientId="apolloClientCached"
    >
      <template slot-scope="{ result: { error, data }, query, isLoading }">
        <!-- Loading -->
        <b-spinner v-if="isLoading && !data" label="Spinning"></b-spinner>
        <!-- Error -->
        <b-alert v-else-if="error" show="" variant="danger">
          Ha ocurrido un error
        </b-alert>
        <span v-if="data && isLoading" class="m-2 float-right">
          Actualizando lista de diferencias...
        </span>
        <!-- Result -->
        <b-table-simple v-if="data && data.allStockDestinations" hover>
          <b-thead>
            <b-tr>
              <b-th>Producto</b-th>
              <b-th>SKU variante</b-th>
              <b-th>Centry</b-th>
              <b-th>Integración</b-th>
              <b-th>Diferencia</b-th>
              <b-th>Fecha actualización integración</b-th>
              <b-th>
                <!-- Botones de accíón: Actualizar Centry, enviar diferencia -->
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-if="!data.allStockDestinations.edges.length">
              <b-td colspan="7">
                No hay diferencias de stock controladas para esta integración
              </b-td>
            </b-tr>
            <stock-destinations-table-row
              v-for="stockDestination of data.allStockDestinations.edges"
              :key="stockDestination.node.id"
              :stockDestination="stockDestination.node"
            ></stock-destinations-table-row>
          </b-tbody>
        </b-table-simple>
        <b-spinner
          v-if="isLoading"
          label="Spinning"
          class="m-2 float-right"
        ></b-spinner>
        <div v-else>
          <b-button
            v-if="hasNextPage(data)"
            class="m-2 float-right"
            @click="
              seeMore(query, data.allStockDestinations.pageInfo.endCursor)
            "
          >
            Ver más
          </b-button>
          <b-alert v-else show variant="light" class="text-center">
            No hay más datos para mostrar.
          </b-alert>
        </div>
      </template>
    </ApolloQuery>
  </div>
</template>

<script>
import StockDestinationsTableRow from "./StockDestinationsTableRow";
import { mapState, mapMutations } from "vuex";
export default {
  name: "StockDestinationsTable",
  components: {
    StockDestinationsTableRow
  },
  model: {
    event: "change"
  },
  props: {
    integrationConfigId: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState(["filters"])
  },
  methods: {
    ...mapMutations(["setFilter"]),
    queryVariables(cursor) {
      const variables = {
        integrationConfigId: this.integrationConfigId,
        excludeEmpty: true,
        keywords: this.filters.keyword.split(",").filter(p => p.trim().length)
      };
      if (cursor) {
        variables.cursor = cursor;
      }
      return variables;
    },
    /**
     * Indica si hay una página siguiente en la query de GraphQl basado
     * exclusivamente en la existencia de un cursor.
     * @return {boolean}
     */
    hasNextPage(data) {
      return data?.allStockDestinations?.pageInfo?.endCursor != null;
    },
    /**
     * Trae más resultados de una query avanzando en su cursor.
     * @param {Object} query
     * @param {String} cursor
     */
    async seeMore(query, cursor) {
      await query.fetchMore({
        variables: this.queryVariables(cursor),
        updateQuery: (prev, { fetchMoreResult }) => {
          const updated = Object.assign({}, this.$dup(prev));
          updated.allStockDestinations.pageInfo =
            fetchMoreResult.allStockDestinations.pageInfo;
          if (fetchMoreResult?.allStockDestinations?.edges?.length !== 0) {
            updated.allStockDestinations.edges.push(
              ...fetchMoreResult.allStockDestinations.edges
            );
          }
          return updated;
        }
      });
    }
  }
};
</script>

<style scoped>
.btn-sm {
  margin: 5px;
}
</style>
