<template>
  <b-card no-body>
    <b-spinner label="Spinning" v-if="loading"></b-spinner>
    <b-tabs card @activate-tab="changeTab" v-model="selected">
      <b-tab title="Centry" active="" :key="0 + '-' + icInfo[0].updates">
        <product-edit-tabs-centry
          :product="product"
          v-model="icInfo[0].change"
          @category="updateCategoryId"
        ></product-edit-tabs-centry>
      </b-tab>
      <b-tab
        title="Atributos de Categoría"
        :key="1 + '-' + icInfo[1].updates"
        lazy
      >
        <product-edit-tabs-category-attributes
          :product="product"
          v-model="icInfo[1].change"
          :category-id="categoryId"
          :ic-ids="icIds"
        ></product-edit-tabs-category-attributes>
      </b-tab>
      <b-tab
        v-for="(ic, index) of integrationConfigs"
        :key="ic.id"
        :title="ic.label"
        lazy=""
      >
        <product-edit-tabs-application
          v-if="isApplication(ic)"
          :product="product"
          :integration-config="ic"
          v-model="icInfo[index + 2].change"
        ></product-edit-tabs-application>
        <product-edit-tabs-b-sale
          v-else-if="isBSale(ic)"
          :product="product"
          :integration-config="ic"
          v-model="icInfo[index + 2].change"
        ></product-edit-tabs-b-sale>
        <product-edit-tabs-jumpseller
          v-else-if="isJumpseller(ic)"
          :product="product"
          :integration-config="ic"
          v-model="icInfo[index + 2].change"
        ></product-edit-tabs-jumpseller>
        <product-edit-tabs-mercado-libre
          v-else-if="isMercadoLibre(ic)"
          :product="product"
          :integration-config="ic"
          v-model="icInfo[index + 2].change"
        ></product-edit-tabs-mercado-libre>
        <product-edit-tabs-mirakl
          v-else-if="isMirakl(ic)"
          :product="product"
          :integration-config="ic"
          v-model="icInfo[index + 2].change"
        ></product-edit-tabs-mirakl>
        <product-edit-tabs-rocket-internet
          v-else-if="isRocketInternet(ic)"
          :product="product"
          :integration-config="ic"
          v-model="icInfo[index + 2].change"
        ></product-edit-tabs-rocket-internet>
        <product-edit-tabs-shopify
          v-else-if="isShopify(ic)"
          :product="product"
          :integration-config="ic"
          v-model="icInfo[index + 2].change"
        ></product-edit-tabs-shopify>
        <product-edit-tabs-woo-commerce
          v-else-if="isWooCommerce(ic)"
          :product="product"
          :integration-config="ic"
          v-model="icInfo[index + 2].change"
        ></product-edit-tabs-woo-commerce>
        <product-edit-tabs-magento
          v-else-if="isMagento(ic)"
          :product="product"
          :integration-config="ic"
          v-model="icInfo[index + 2].change"
        ></product-edit-tabs-magento>
        <product-edit-tabs-paris
          v-else-if="isParis(ic)"
          :product="product"
          :integration-config="ic"
          v-model="icInfo[index + 2].change"
        ></product-edit-tabs-paris>
        <product-edit-tabs-vtex
          v-else-if="isVtex(ic)"
          :product="product"
          :integration-config="ic"
          v-model="icInfo[index + 2].change"
        ></product-edit-tabs-vtex>
      </b-tab>
    </b-tabs>
  </b-card>
</template>
<script>
import ProductEditTabsCentry from "./ProductEditTabs/Centry.vue";
import ProductEditTabsCategoryAttributes from "./ProductEditTabs/CategoryAttributes.vue";
import ProductEditTabsApplication from "./ProductEditTabsApplication.vue";
import ProductEditTabsBSale from "./ProductEditTabsBSale.vue";
import ProductEditTabsJumpseller from "./ProductEditTabsJumpseller.vue";
import ProductEditTabsMercadoLibre from "./ProductEditTabsMercadoLibre.vue";
import ProductEditTabsMirakl from "./ProductEditTabsMirakl.vue";
import ProductEditTabsRocketInternet from "./ProductEditTabs/RocketInternet.vue";
import ProductEditTabsShopify from "./ProductEditTabsShopify.vue";
import ProductEditTabsWooCommerce from "./ProductEditTabsWooCommerce.vue";
import ProductEditTabsMagento from "./ProductEditTabsMagento.vue";
import ProductEditTabsVtex from "./ProductEditTabsVtex.vue";
import INTEGRATION_CONFIGS_FOR_TABS from "../graphql/IntegrationConfigsForTabs.gql";
import ProductEditTabsParis from "./ProductEditTabs/Paris.vue";

export default {
  name: "ProductEditTabs",
  props: {
    product: Object
  },
  components: {
    ProductEditTabsCentry,
    ProductEditTabsCategoryAttributes,
    ProductEditTabsApplication,
    ProductEditTabsBSale,
    ProductEditTabsJumpseller,
    ProductEditTabsMercadoLibre,
    ProductEditTabsMirakl,
    ProductEditTabsRocketInternet,
    ProductEditTabsShopify,
    ProductEditTabsWooCommerce,
    ProductEditTabsMagento,
    ProductEditTabsParis,
    ProductEditTabsVtex
  },
  mounted() {
    this.$apollo
      .query({ query: INTEGRATION_CONFIGS_FOR_TABS })
      .then(({ data }) => {
        this.integrationConfigs = this.availableIntegrationConfigs(data);
        this.icIds = this.integrationConfigs.map(ic => ic.id);
        this.loading = false;
      });
  },
  data() {
    return {
      selected: 0,
      icInfo: [
        { change: false, updates: 0 },
        { change: false, updates: 0 }
      ],
      loading: true,
      integrationConfigs: [],
      icIds: [],
      categoryId: this.$dig(this.product, "category", "id")
    };
  },
  methods: {
    availableIntegrationConfigs(data) {
      if (!data || !data.allIntegrationConfigs) {
        return [];
      }
      const available = data.allIntegrationConfigs.filter(
        x => x.available && this.hasSeparator(x)
      );
      const toAdd = [];
      for (let i = this.icInfo.length; i <= available.length + 1; i++) {
        toAdd.push({ change: false, updates: 0 });
      }
      this.icInfo.push(...toAdd);

      return available;
    },
    hasSeparator(ic) {
      return (
        this.isApplication(ic) ||
        this.isBSale(ic) ||
        this.isJumpseller(ic) ||
        this.isMercadoLibre(ic) ||
        this.isMirakl(ic) ||
        this.isRocketInternet(ic) ||
        this.isShopify(ic) ||
        this.isWooCommerce(ic) ||
        this.isMagento(ic) ||
        this.isParis(ic) ||
        this.isVtex(ic)
      );
    },
    isApplication(ic) {
      return ic.identifier === "application";
    },
    isBSale(ic) {
      return ic.identifier === "b_sale";
    },
    isJumpseller(ic) {
      return ic.identifier === "jumpseller";
    },
    isMercadoLibre(ic) {
      return ic.identifier.startsWith("mercado_libre");
    },
    isMirakl(ic) {
      return ic.identifier.startsWith("ripley");
    },
    isRocketInternet(ic) {
      return (
        (ic.identifier.startsWith("linio") ||
          ic.identifier.startsWith("dafiti") ||
          ic.identifier.startsWith("falabella_global")) &&
        ic.identifier !== "linio_second" &&
        ic.identifier !== "dafiti_second"
      );
    },
    isShopify(ic) {
      return ic.identifier === "shopify";
    },
    isWooCommerce(ic) {
      return ic.identifier === "woo_commerce";
    },
    isMagento(ic) {
      return ic.identifier === "magento" || ic.identifier === "komax";
    },
    isParis(ic) {
      return ic.identifier === "paris" || ic.identifier === "paris_preprod";
    },
    isVtex(ic) {
      return ic.identifier === "vtex";
    },
    /**
     * Indica si el integration_config corresponde a Falabella Global
     * @return {Boolean}
     */
    isFalabellaGlobal(ic) {
      return ic.identifier.startsWith("falabella_global");
    },
    changeTab(newTabIndex, prevTabIndex, bvEvt) {
      if (this.icInfo[prevTabIndex].change) {
        bvEvt.preventDefault();
        this.$swal
          .fire({
            title: "Cambiar de pestaña",
            text: "Si cambias de pestaña, perderás tus cambios. ¿Estás seguro?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "No"
          })
          .then(result => {
            if (result.value) {
              this.icInfo[prevTabIndex].change = false;
              this.icInfo[prevTabIndex].updates++;
              this.selected = newTabIndex;
            }
          });
      }
    },
    /**
     * Obtiene los public_integration_information
     *  desde una lista de integration_configs
     * @param {Array<IntegrationConfig>} integrationConfigs - Lista de integratioonConfig
     * @return {Array<Object>}
     */
    getPublicIntegrationInformations(integrationConfigs) {
      var piis = {};
      integrationConfigs.forEach(ic => {
        var pii = ic.publicIntegrationInformation;
        if (pii) {
          piis[pii.id] = { id: pii.id, name: pii.name };
        }
      });
      return Object.values(piis);
    },
    /**
     * Entrega la lista de integration_config que
     * estan disponibles y son de Falabella Global
     * @param {Array} data - lista de ics
     * @return {Array}
     */
    getFalabellaIc(data) {
      if (!data || !data.allIntegrationConfigs) {
        return [];
      }
      const available = data.allIntegrationConfigs.filter(
        x => x.available && this.isFalabellaGlobal(x)
      );
      return available;
    },
    /**
     * Actualiza la variable categoryId
     * @param {String} categoryId
     */
    updateCategoryId(categoryId) {
      this.categoryId = categoryId;
    }
  }
};
</script>
