<template>
  <div>
    <base-header
      title="Magento"
      title-link="/"
      :map="[centryAttributeSet.name]"
      :titleSize="6"
    ></base-header>
    <b-container fluid>
      <b-row>
        <b-col md="12" lg="6">
          <div>
            <h3>Categorías</h3>
            <p>
              Selecciona las categorías de los productos que quieras asociar a
              {{ centryAttributeSet.name }}:
            </p>
            <category-selector
              :attribute-set="centryAttributeSet"
            ></category-selector>
          </div>
        </b-col>
        <b-col sm="12" md="6" lg="3">
          <h3>Tallas</h3>
          <p>
            Selecciona un atributo de Magento para homologar con las Tallas de
            Centry:
          </p>
          <magento-attributes-homologation
            v-if="centryAttributeSet.id"
            :query-import="allSizes"
            :centry-model="'Products::Size'"
            :attribute-set="centryAttributeSet"
            :magento-attributes="attributeSelector"
          ></magento-attributes-homologation>
        </b-col>
        <b-col sm="12" md="6" lg="3">
          <div>
            <h3>Colores</h3>
            <p>
              Selecciona un atributo de Magento para homologar con los Colores
              de Centry:
            </p>
            <magento-attributes-homologation
              v-if="centryAttributeSet.id"
              :query-import="allColors"
              :centry-model="'Products::Color'"
              :attribute-set="centryAttributeSet"
              :magento-attributes="attributeSelector"
            ></magento-attributes-homologation>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import BaseHeader from "@/components/BaseHeader";
import CategorySelector from "@/components/CategorySelector";
import MagentoAttributesHomologation from "@/components/MagentoAttributesHomologation";
import UserDefinedAttributes from "../graphql/MagentoApiUserDefinedAttributes.gql";
import AllSizes from "../graphql/AllSizes.gql";
import AllColors from "../graphql/AllColors.gql";
import AllAttributeSets from "@/graphql/AllMagentoAttributeSets.gql";
import { createMagentoAttributeSet } from "@/main";

export default {
  name: "MagentoAttributes",
  components: {
    BaseHeader,
    CategorySelector,
    MagentoAttributesHomologation
  },
  mounted() {
    this.getUserDefinedAttributes();
    this.getOrCreateAttributeSet();
  },
  data() {
    return {
      attributeSet: {
        id: this.$route.params.attribute_set_id,
        name: this.$route.params.attribute_set_name,
        integrationConfigId: this.$route.params.integration_config_id
      },
      allSizes: AllSizes,
      allColors: AllColors,
      attributeSelector: [{ value: 0, label: "Cargando atributos..." }],
      centryAttributeSet: {
        id: null,
        magentoId: null,
        integrationConfigId: null,
        name: null,
        selectedCategories: [],
        attributes: []
      }
    };
  },
  methods: {
    async getUserDefinedAttributes() {
      let attributes = await this.$apollo
        .query({
          query: UserDefinedAttributes,
          variables: {
            icMagentoId: this.attributeSet.integrationConfigId,
            attributeSetId: this.attributeSet.id
          }
        })
        .then(({ data }) => {
          if (data && data.magentoApiUserDefinedAttributes) {
            return data.magentoApiUserDefinedAttributes.map(attribute => ({
              attributeId: attribute.attributeId,
              value: attribute.attributeCode,
              label: attribute.defaultFrontendLabel
            }));
          }
        });
      this.attributeSelector = attributes;
    },
    async getOrCreateAttributeSet() {
      await this.$apollo
        .query({
          query: AllAttributeSets,
          variables: {
            integrationConfigId: this.$route.params.integration_config_id,
            magentoId: parseInt(this.attributeSet.id)
          }
        })
        .then(({ data }) => {
          if (
            data &&
            data.allMagentoAttributeSets &&
            data.allMagentoAttributeSets.edges &&
            data.allMagentoAttributeSets.edges.length > 0
          ) {
            this.defineAttributeSet(data.allMagentoAttributeSets.edges[0].node);
          } else {
            this.createAttributeSet(
              this.attributeSet.integrationConfigId,
              this.attributeSet.id,
              this.attributeSet.name
            );
          }
        });
    },
    async createAttributeSet(integrationConfigId, magentoId, name) {
      await createMagentoAttributeSet(
        this.$apollo,
        integrationConfigId,
        magentoId,
        name
      ).then(({ data }) => {
        if (data && data.createMagentoAttributeSet) {
          this.defineAttributeSet(data.createMagentoAttributeSet);
        }
      });
    },
    defineAttributeSet(attrSet) {
      this.centryAttributeSet.id = attrSet.id;
      this.centryAttributeSet.magentoId = attrSet.magentoId;
      this.centryAttributeSet.integrationConfigId = attrSet.integrationConfigId;
      this.centryAttributeSet.name = attrSet.name;
      this.centryAttributeSet.selectedCategories = attrSet.apiMagentoAttributeCategories.map(
        c => c.centryCategoryId
      );
    }
  }
};
</script>
