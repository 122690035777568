<template>
  <b-container>
    <b-row>
      <b-col>
        <b-alert v-if="errorMessage" show="" variant="danger" dismissible>
          {{ errorMessage }}
        </b-alert>
        <b-alert v-if="successfulMessage" show="" variant="success" dismissible>
          {{ successfulMessage }}
        </b-alert>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-button
          variant="outline-info"
          @click="createSpecificationsGroupModal"
        >
          <b-icon-plus></b-icon-plus> Crear grupo
        </b-button>

        <vtex-specifications-group-modal
          v-model="showModal"
          :vtexIntegrationConfigId="vtexIntegrationConfigId"
          :specificationsGroup="specificationsGroup"
          :action="modalAction"
          :categoryId="categoryId"
          :categories="categories"
          @errorMessage="setErrorMessage"
          @successfulMessage="setSuccessfulMessage"
        >
        </vtex-specifications-group-modal>
      </b-col>
    </b-row>

    <br />

    <b-row>
      <b-col>
        <vtex-specifications-groups-table
          :vtexIntegrationConfigId="vtexIntegrationConfigId"
          :categoryId="categoryId"
          :specificationsGroups="specificationsGroups"
          @openSpecificationsGroupModal="updateSpecificationsGroupModal"
          @errorMessage="setErrorMessage"
          @getSpecificationsGroups="getSpecificationsGroups"
        >
        </vtex-specifications-groups-table
      ></b-col>
    </b-row>
  </b-container>
</template>

<script>
import VtexSpecificationsGroupsTable from "./VtexSpecificationsGroupsTable.vue";
import VtexSpecificationsGroupModal from "./VtexSpecificationsGroupModal.vue";

export default {
  name: "VtexSpecificationsGroupsMaintainer",
  components: {
    VtexSpecificationsGroupsTable,
    VtexSpecificationsGroupModal
  },
  props: {
    vtexIntegrationConfigId: {
      type: String,
      required: true
    },
    categoryId: {
      required: true,
      validator: prop => {
        return prop === null || typeof prop === "string";
      }
    },
    categories: {
      required: true,
      type: Array,
      default: () => []
    },
    specificationsGroups: {
      required: true,
      validator: prop => Array.isArray(prop) || prop === null
    }
  },
  data() {
    return {
      showModal: false,
      modalAction: null,
      specificationsGroup: null,
      errorMessage: null,
      successfulMessage: null
    };
  },
  methods: {
    /**
     * Abre el modal de edición de grupos de especificacion para crear uno nuevo.
     */
    createSpecificationsGroupModal() {
      this.modalAction = "create";
      this.showModal = true;
      this.specificationsGroup = null;
    },
    /**
     * Abre el modal de edición de grupos de especificacion para editar uno.
     * @param {String} specificationId
     */
    updateSpecificationsGroupModal(specificationsGroup) {
      this.modalAction = "update";
      this.showModal = true;
      this.specificationsGroup = specificationsGroup;
    },
    /**
     * Guarda el mensaje de error en la data y vuelve al inicio de la página
     * @param {String} errorMessage
     */
    setErrorMessage(errorMessage) {
      this.errorMessage = errorMessage;
      this.scrollToTop();
    },
    /**
     * Guarda el mensaje de éxito en la data y vuelve al inicio de la página
     * @param {String} message
     */
    setSuccessfulMessage(message) {
      this.successfulMessage = message;
      this.scrollToTop();
    },
    /**
     * Emite el evento 'getSpecificationsGroups'
     */
    getSpecificationsGroups() {
      this.$emit("getSpecificationsGroups");
    },
    /**
     * Emite el evento 'scrollToTop' para volver al inicio de la página
     */
    scrollToTop() {
      this.$emit("scrollToTop");
    }
  },
  watch: {
    errorMessage: function(newErrorMessage) {
      if (newErrorMessage !== null) this.successfulMessage = null;
    },
    successfulMessage: function(newMessage) {
      if (newMessage !== null) this.errorMessage = null;
    }
  }
};
</script>
