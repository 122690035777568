<template>
  <div>
    <base-header
      title="Carga Masiva"
      :title-size="9"
      title-link="/bulk_upload/history"
    >
      <template v-slot:actions>
        <b-button to="/bulk_upload" variant="outline-info">
          <b-icon-upload></b-icon-upload>
          Cargar un archivo
        </b-button>
        <b-button
          to="/bulk_upload/template_downloads"
          variant="outline-info"
          class="ml-2"
        >
          <b-icon-plus></b-icon-plus>
          Generar planilla de carga
        </b-button>
      </template>
      <template v-slot:filters>
        <b-link
          :href="centryUrl + '/admin/cargamasivav2'"
          v-if="
            sections.includes('bulk_upload') &&
              (!userGroup || userGroup.inventory > 0)
          "
        >
          Volver al antiguo panel de carga masiva
        </b-link>
      </template>
    </base-header>
    <bulk-upload-histories-table></bulk-upload-histories-table>
  </div>
</template>
<script>
import BaseHeader from "@/components/BaseHeader";
import BulkUploadHistoriesTable from "@/components/BulkUploadHistoriesTable";
import { centryUrl } from "../main";
import { mapState } from "vuex";

export default {
  name: "BulkUploadHistory",
  components: {
    BulkUploadHistoriesTable,
    BaseHeader
  },
  data() {
    return {
      centryUrl
    };
  },
  computed: {
    ...mapState(["currentUser"]),
    userGroup() {
      if (!this.currentUser?.userGroups) {
        return null;
      }
      return this.currentUser.userGroups.find(
        x => x.company.id === this.currentUser.company.id
      );
    },
    sections() {
      return this.currentUser.company.betaSectionsAllowed.map(x => {
        return x.section;
      });
    }
  }
};
</script>
