var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loading || _vm.loadingDomains)?_c('b-spinner'):(
    !_vm.technicalSpecs ||
      !_vm.gridTemplateRequiredAttributes ||
      _vm.gridTemplateRequiredAttributes.length === 0
  )?_c('span',[_vm._v(" El dominio no permite guías de tallas. Elige otro ")]):(!_vm.isActive)?_c('span',[_vm._v(" El dominio no esta habilitado para crear guia de tallas. ")]):_c('div',[_c('b-row',[_c('b-col',[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" Dominio: "+_vm._s(_vm.domain.domain_name)+" ")])])],1),(!_vm.requiredComplete)?_c('b-row',{staticClass:"mt-2 mb-2"},[_c('b-col',[_c('span',[_vm._v(" Complete todos los atributos ")])])],1):_vm._e(),_vm._l((_vm.gridTemplateRequiredAttributes),function(attribute){return _c('b-row',{key:attribute.id},[_c('b-col',[_c('b-form-group',{attrs:{"label":'* ' + attribute.name,"label-for":'a' + attribute.id}},[(attribute.values && attribute.values.length)?_c('v-select',{attrs:{"id":'a' + attribute.id,"multiple":attribute.multivalued === true,"options":_vm.getOptions(attribute),"reduce":function (x) { return x.value; }},on:{"input":_vm.reviewAttributes},model:{value:(_vm.requiredAttributes[attribute.id]),callback:function ($$v) {_vm.$set(_vm.requiredAttributes, attribute.id, $$v)},expression:"requiredAttributes[attribute.id]"}}):_c('b-input',{attrs:{"id":'a' + attribute.id,"state":!!_vm.requiredAttributes[attribute.id] &&
              !!_vm.requiredAttributes[attribute.id].length},on:{"input":_vm.reviewAttributes},model:{value:(_vm.requiredAttributes[attribute.id]),callback:function ($$v) {_vm.$set(_vm.requiredAttributes, attribute.id, $$v)},expression:"requiredAttributes[attribute.id]"}}),(
            !_vm.requiredAttributes[attribute.id] ||
              !_vm.requiredAttributes[attribute.id].length
          )?_c('b-form-invalid-feedback',{attrs:{"state":!!_vm.requiredAttributes[attribute.id] &&
              !!_vm.requiredAttributes[attribute.id].length}},[_vm._v(" Este atributo es obligatorio ")]):_vm._e()],1)],1)],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }