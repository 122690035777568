<template>
  <div ref="scrollReference">
    <b-row>
      <b-col class="border-right" md="2">
        <b-nav vertical pills>
          <b-nav-item
            :active="pageVisibility.selectCategory"
            @click="changePageVisibility('selectCategory')"
          >
            Seleccionar categoría
          </b-nav-item>

          <b-nav-item
            :active="pageVisibility.spectificationsGroupsMaintainer"
            :disabled="specificationsMaintainerDisabled"
            @click="changePageVisibility('spectificationsGroupsMaintainer')"
          >
            Mantenedor de grupos de especificaciones
          </b-nav-item>

          <b-nav-item
            :active="pageVisibility.specificationsMaintainer"
            :disabled="specificationsMaintainerDisabled"
            @click="changePageVisibility('specificationsMaintainer')"
          >
            Mantenedor de especificaciones
          </b-nav-item>

          <b-nav-item
            :active="pageVisibility.specificationValuesMaintainer"
            :disabled="specificationValuesMaintainerDisabled"
            @click="changePageVisibility('specificationValuesMaintainer')"
          >
            Mantenedor de valores de una especificación
          </b-nav-item>
        </b-nav>
      </b-col>

      <b-col class="border-left">
        <b-spinner label="Spinning" v-if="loading"></b-spinner>
        <div v-else>
          <base-header
            :title="selectedCategoryName"
            :titleSize="12"
            :map="titleMap"
            :clickeable="true"
            @click="changePageVisibility('selectCategory')"
          >
          </base-header>

          <vtex-specifications-maintainer-category-selector
            v-show="pageVisibility.selectCategory"
            :category="selectedCategory"
            :categories="categories"
            @category="setCategory"
            @changePage="changePageVisibility"
          >
          </vtex-specifications-maintainer-category-selector>

          <vtex-specifications-groups-maintainer
            v-if="pageVisibility.spectificationsGroupsMaintainer"
            :vtexIntegrationConfigId="vtexIntegrationConfigId"
            :categoryId="selectedCategoryId"
            :categories="categoriesForSpecificationsGroupsMaintainer"
            :specificationsGroups="specificationsGroups"
            @scrollToTop="scrollToElement"
            @getSpecificationsGroups="getSpecificationsGroups"
          >
          </vtex-specifications-groups-maintainer>

          <vtex-specifications-maintainer
            v-if="pageVisibility.specificationsMaintainer"
            :vtexIntegrationConfigId="vtexIntegrationConfigId"
            :categoryId="selectedCategoryId"
            :specificationsGroups="
              specificationsGroupsForSpecificationsMaintainer
            "
            @scrollToTop="scrollToElement"
            @setSpecification="setSpecification"
          >
          </vtex-specifications-maintainer>

          <vtex-specification-values-maintainer
            v-if="pageVisibility.specificationValuesMaintainer"
            :vtexIntegrationConfigId="vtexIntegrationConfigId"
            :specification="specification"
            @scrollToTop="scrollToElement"
          >
          </vtex-specification-values-maintainer>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import BaseHeader from "@/components/BaseHeader.vue";
import VtexSpecificationsMaintainerCategorySelector from "./VtexSpecificationsMaintainerCategorySelector.vue";
import VtexSpecificationsGroupsMaintainer from "./VtexSpecificationsGroupsMaintainer.vue";
import VtexSpecificationsMaintainer from "./VtexSpecificationsMaintainer.vue";
import VtexSpecificationValuesMaintainer from "./VtexSpecificationValuesMaintainer.vue";

import INTEGRATION_CONFIG_VTEX_GET_SPECIFICATIONS_GROUPS from "@/graphql/IntegrationConfigVtexGetSpecificationsGroups.gql";

const PAGE_VISIBILITY = {
  selectCategory: false,
  specificationsGroupsMaintainer: false,
  specificationsMaintainer: false,
  specificationValuesMaintainer: false
};

export default {
  name: "VtexSpecificationsMaintainerTab",
  components: {
    BaseHeader,
    VtexSpecificationsMaintainerCategorySelector,
    VtexSpecificationsGroupsMaintainer,
    VtexSpecificationsMaintainer,
    VtexSpecificationValuesMaintainer
  },
  props: {
    vtexIntegrationConfigId: String,
    categories: Array
  },
  data() {
    return {
      loading: true,
      selectedCategory: null,
      specificationsGroups: null,
      specification: null,
      pageVisibility: {
        selectCategory: true,
        spectificationsGroupsMaintainer: false,
        specificationsMaintainer: false,
        specificationValuesMaintainer: false
      }
    };
  },
  mounted() {
    this.loading = false;
  },
  methods: {
    /**
     * Realiza la petición para obtener los grupos de especificaciones de Vtex
     */
    async getSpecificationsGroups() {
      await this.$apollo
        .query({
          query: INTEGRATION_CONFIG_VTEX_GET_SPECIFICATIONS_GROUPS,
          variables: {
            id: this.vtexIntegrationConfigId,
            categoryId: this.selectedCategoryId
          }
        })
        .then(({ data }) => {
          if (!data?.integrationConfigVtex?.specificationsGroupsList) {
            this.emitErrorMessage(
              "No se pudo obtener el listado de grupos de especificaciones"
            );
          } else {
            this.specificationsGroups =
              data.integrationConfigVtex.specificationsGroupsList;
          }
        });
    },
    /**
     * Genera los cambios necesarios para decidir que componente mostrar en
     * la vista.
     * @param {String} key
     */
    changePageVisibility(key) {
      let newAccordionVisibility = Object.assign({}, PAGE_VISIBILITY);
      newAccordionVisibility[key] = true;
      this.pageVisibility = newAccordionVisibility;
    },
    /**
     * Genera los cambios necesarios para marcar como seleccionada una categoria.
     * @param {Object} category
     */
    setCategory(category) {
      this.selectedCategory = category;
      this.specification = null;
    },
    /**
     * Genera los cambios necesarios para maracar como
     * seleccionada una especificación.
     * @param {Object} specification
     */
    setSpecification(specification) {
      this.specification = {
        id: specification.fieldId.toString(),
        name: specification.name
      };
      this.changePageVisibility("specificationValuesMaintainer");
    },
    /**
     * A partir de la referencia 'scrollReference' se itera sobre los objetos
     * padres hasta descubrir el offsetTop del último elemento y se deja
     * la vista a esa altura.
     */
    scrollToElement() {
      let offsetTop = 0;
      let actualElement = this.$refs.scrollReference;
      while (actualElement !== null && actualElement !== document.body) {
        offsetTop = actualElement.offsetTop;
        actualElement = actualElement.offsetParent;
      }
      window.scrollTo(0, offsetTop);
    },
    /**
     * Asiste al método computado 'categoriesForSpecificationsGroupsMaintainer'
     * a generar una lista de todas las categorias a partir del arbol de categorias.
     * @param {Object} category
     * @return {Array<Object>}
     */
    categoriesForSpecificationsGroupsMaintainerHelper(category) {
      let allCategories = [category];
      if (category.children && category.children.length > 0) {
        category.children.forEach(childCategory => {
          allCategories = allCategories.concat(
            this.categoriesForSpecificationsGroupsMaintainerHelper(
              childCategory
            )
          );
        });
      }
      return allCategories;
    }
  },
  computed: {
    specificationsMaintainerDisabled() {
      if (this.selectedCategory === null) return true;
      return false;
    },
    specificationValuesMaintainerDisabled() {
      return !this.specification;
    },
    categoriesForSpecificationsGroupsMaintainer() {
      let allCategories = [];
      if (this.categories !== null) {
        this.categories.forEach(category => {
          allCategories = allCategories.concat(
            this.categoriesForSpecificationsGroupsMaintainerHelper(category)
          );
        });
      }

      return allCategories.map(category => {
        return {
          value: category.id,
          label: category.path.join(" / ")
        };
      });
    },
    specificationsGroupsForSpecificationsMaintainer() {
      if (this.specificationsGroups === null) this.getSpecificationsGroups();
      if (this.specificationsGroups !== null) {
        return this.specificationsGroups.map(specificationsGroup => {
          return {
            value: {
              fieldGroupId: specificationsGroup.id,
              categoryId: specificationsGroup.categoryId
            },
            label: specificationsGroup.name
          };
        });
      }
      return [];
    },
    titleMap() {
      let map = [];
      if (this.pageVisibility.spectificationsGroupsMaintainer) {
        map.push("Mantenedor de grupos de especificaciones");
      } else if (this.pageVisibility.specificationsMaintainer) {
        map.push("Mantenedor de especificaciones");
      } else if (this.pageVisibility.specificationValuesMaintainer) {
        map.push("Mantenedor de valores de especificaciones");
        map.push("Especificacion");
        map.push(this.specification.name);
      }
      return map;
    },
    selectedCategoryName() {
      if (this.selectedCategory !== null) return this.selectedCategory.name;
      return "Seleccionar categoria";
    },
    selectedCategoryId() {
      if (this.selectedCategory !== null) return this.selectedCategory.id;
      return null;
    }
  },
  watch: {
    selectedCategory: function() {
      this.specificationsGroups = null;
    }
  }
};
</script>
