<template>
  <div>
    <base-header
      title="Listado Destino-Precio de Mercado Libre"
      title-link="/mercadolibre/quotations"
      :loading="!integrationConfigLabel"
      :map="[
        integrationConfigLabel || 'loading',
        'Destino-Precio para flete dinámico'
      ]"
      :title-size="12"
    >
    </base-header>
    <b-alert show v-if="error" variant="danger" dismissible>
      <ul>
        <li>{{ errorMessage }}</li>
      </ul>
    </b-alert>
    <b-skeleton-table
      v-else-if="loadingDestinations"
      :rows="5"
      :columns="4"
      :table-props="{ bordered: true, striped: true }"
    ></b-skeleton-table>
    <b-overlay :show="loading" no-center rounded="sm" v-else>
      <template #overlay>
        <div class="text-center">
          <b-icon icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
          <p id="cancel-label">
            Por favor espera, estamos cargando datos todavía...
          </p>
          <b>{{ loadingPorcentage }} %</b>
        </div>
      </template>
      <mercado-libre-dynamic-shipping-table
        :integration-config-id="integrationConfigId"
        :destinationPrices="currentDestinationPrices"
        :regions="regions"
        :cities="cities"
      />
    </b-overlay>
  </div>
</template>

<script>
import BaseHeader from "../../../components/BaseHeader.vue";
import MercadoLibreDynamicShippingTable from "../../../components/MercadoLibre/DynamicShipping/Table.vue";
import INTEGRATION_CONFIG_LABEL from "../../../graphql/IntegrationConfigMercadoLibreLabel.gql";
import ALL_DESTINATION_PRICES from "../../../graphql/MercadoLibre/DynamicShipping/AllDestinationPrices.gql";
import ALL_DESTINATION from "../../../graphql/MercadoLibre/DynamicShipping/AllDestination.gql";
import TOTAL_DESTINATION_PRICES from "../../../graphql/MercadoLibre/DynamicShipping/TotalDestinationPrice.gql";

export default {
  name: "MercadoLibreDynamicShippingList",
  components: {
    BaseHeader,
    MercadoLibreDynamicShippingTable
  },
  props: {
    integrationConfigId: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      integrationConfigLabel: null,
      siteCode: null,
      errorDestinations: false,
      errorDestinationPrices: false,
      errorMessage: "",
      loadingDestinations: true,
      loadingDestinationPrices: true,
      currentDestinationPrices: [],
      regions: [],
      cities: { all: [] },
      totalDestinationPrices: 0,
      currentNumberDestinationPrices: 0
    };
  },
  computed: {
    loading() {
      return this.loadingDestinations || this.loadingDestinationPrices;
    },
    error() {
      return this.errorDestinations || this.errorDestinationPrices;
    },
    loadingPorcentage() {
      return Math.trunc(
        (this.currentNumberDestinationPrices / this.totalDestinationPrices) *
          100
      );
    }
  },
  mounted() {
    const promise = Promise.resolve(this.getIntegrationConfigLabel());
    promise.then(() => {
      this.getTotalDestinationPrice();
      this.getDestinations();
      this.getAllDestinationPrice();
    });
  },
  methods: {
    /**
     * Controla la obtención de todos los Destination Prices asociados a la integracion
     */
    getAllDestinationPrice() {
      this.loadingDestinationPrices = true;
      const promise = Promise.resolve(this.getAllDestinationPriceByCursor());

      promise
        .then(() => {
          this.loadingDestinationPrices = false;
        })
        .catch(error => {
          this.loadingDestinationPrices = false;
          this.errorDestinations = true;
          this.errorMessage = error.message;
        });
    },
    /**
     * Obtiene todos los Destination Prices asociados a la integracion recorriendo el paginamiento
     * Se muestra primera pagina en un principio
     * y luego se continuan cargando las siguientes paginas hasta que se tengan todas
     */
    async getAllDestinationPriceByCursor() {
      const variables = {};
      const path = "allMercadoLibreMe1DestinationPrices";
      const timeWaiting = 10000;
      let cursor = "";
      variables.integrationConfigId = this.integrationConfigId;
      variables.cursor = cursor;
      let rest_of_pages = [];
      while (cursor !== null) {
        let newVariables = Object.assign({}, variables, { cursor });
        let { data } = await this.$retryQueryWithTimeout(
          ALL_DESTINATION_PRICES,
          newVariables,
          timeWaiting
        );
        const page = this.$dig(data, path, "edges");
        if (page) {
          if (this.currentDestinationPrices.length === 0) {
            this.currentDestinationPrices.push(...page);
          }
          rest_of_pages.push(...page);
          this.currentNumberDestinationPrices = rest_of_pages.length;
        }
        cursor = this.$dig(data, path, "pageInfo", "endCursor");
      }
      this.currentDestinationPrices = rest_of_pages;
    },
    /**
     * Obtiene los posibles destinos para seleccionar como region-ciudad
     */
    getDestinations() {
      this.$apollo
        .query({
          query: ALL_DESTINATION,
          variables: {
            site: this.siteCode
          }
        })
        .then(({ data }) => {
          const places = this.$dig(data, "allMercadoLibreMe1Destinations");
          if (places) {
            this.regions = this.prepareRegions(places);
            this.cities = this.prepareCities(places);
          } else {
            this.errorDestinations = true;
            this.errorMessage = "No fue posible obtener destinos";
          }
          this.loadingDestinations = false;
        })
        .catch(error => {
          this.loadingDestinations = false;
          this.errorDestinations = true;
          this.errorMessage = String(error);
        });
    },
    /**
     * Construye arreglo con regiones (nombre de region en strings)
     * @param {Array<Object>} data
     * @returns {Array<String>}
     */
    prepareRegions(data) {
      const regions = new Set();
      data.map(dest => {
        regions.add(dest.firstLevel);
      });
      return Array.from(regions);
    },
    /**
     * Construye diccionario con las ciudades disponibles por region
     * llave es cada region, y el valor es un arreglo con las ciudades
     * en la llave 'all' se guardan todas las ciudades.
     * @param {Array<Object>} data
     * @returns {Object}
     */
    prepareCities(data) {
      const cities = { all: [] };
      data.map(dest => {
        if (cities[dest.firstLevel] === undefined) {
          cities[dest.firstLevel] = [];
        }
        cities[dest.firstLevel].push(dest.secondLevel);
        cities.all.push(dest.secondLevel);
      });
      return cities;
    },
    /**
     * Obtiene la etiqueta del integration_config
     * y lo guarda en variable interna
     */
    async getIntegrationConfigLabel() {
      await this.$apollo
        .query({
          query: INTEGRATION_CONFIG_LABEL,
          variables: {
            id: this.integrationConfigId
          }
        })
        .then(({ data }) => {
          const label = this.$dig(
            data,
            "integrationConfigMercadoLibre",
            "label"
          );
          const siteCode = this.$dig(
            data,
            "integrationConfigMercadoLibre",
            "siteCode"
          );
          if (label) {
            this.integrationConfigLabel = label;
          } else {
            this.integrationConfigLabel = "MercadoLibre";
          }
          if (siteCode) {
            this.siteCode = siteCode;
          }
        })
        .catch(() => (this.integrationConfigLabel = "MercadoLibre"));
    },
    /**
     * Obtiene el total de Destination Prices asociados a la integración
     */
    getTotalDestinationPrice() {
      this.$apollo
        .query({
          query: TOTAL_DESTINATION_PRICES,
          variables: {
            integrationConfigId: this.integrationConfigId
          }
        })
        .then(({ data }) => {
          const total = this.$dig(data, "getTotalDestinationPrices", "total");
          if (total) {
            this.totalDestinationPrices = total;
          } else {
            this.totalDestinationPrices = 0;
          }
        })
        .catch(() => (this.totalDestinationPrices = 0));
    }
  }
};
</script>
