<template>
  <div>
    <base-header title="Carga Masiva" title-link="/bulk_upload/history">
    </base-header>
    <bulk-upload-tabs></bulk-upload-tabs>
  </div>
</template>
<script>
import BaseHeader from "@/components/BaseHeader";
import BulkUploadTabs from "@/components/BulkUploadTabs";

export default {
  name: "BulkUpload",
  components: {
    BulkUploadTabs,
    BaseHeader
  }
};
</script>
