<template>
  <div>
    <span class="font-weight-bold text-muted float-right">
      {{ filterCaption }}
      <b-button v-if="filterCaption.length" size="sm" @click="cleanFilters"
        >Limpiar filtros</b-button
      ></span
    >
    <b-table-simple class="text-left">
      <b-thead>
        <b-th>Fecha</b-th>
        <b-th>Desde</b-th>
        <b-th>Evento</b-th>
        <b-th>SKU</b-th>
        <b-th>Bodega</b-th>
        <b-th>Movimiento</b-th>
        <b-th>Stock</b-th>
      </b-thead>
      <b-tbody>
        <b-tr v-for="(transaction, idx) of transactions" :key="idx">
          <b-td>{{ timeZoneDates[idx] }}</b-td>
          <b-td>{{ transaction.source }}</b-td>
          <b-td>
            <b-link
              v-if="transaction.order"
              :to="`/orders/${transaction.order.id}`"
            >
              {{
                operationMap[transaction.operation] + " " + origin(transaction)
              }}
            </b-link>
            <span v-else>
              {{
                operationMap[transaction.operation] + " " + origin(transaction)
              }}
            </span>
          </b-td>
          <b-td>{{ transaction.variant ? transaction.variant.sku : "" }}</b-td>
          <b-td>
            <b-link
              v-if="!!transaction.warehouse"
              :href="centryUrl + '/bodegas/' + transaction.warehouse.id"
              >{{ transaction.warehouse.name }}</b-link
            >
          </b-td>
          <b-td>
            <span
              :class="transaction.quantity > 0 ? 'text-success' : 'text-danger'"
            >
              {{
                transaction.quantity > 0
                  ? "+" + transaction.quantity
                  : transaction.quantity
              }}
            </span>
          </b-td>
          <b-td>{{ transaction.stock }}</b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>
<script>
import { centryUrl } from "../main";
import { mapActions, mapState, mapMutations } from "vuex";
import moment from "moment-timezone";

export default {
  name: "TransactionsTable",
  props: {
    transactions: Array,
    product: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  created() {
    this.refreshTimeZoneDates();
  },
  data() {
    return {
      centryUrl,
      operationMap: {
        product_endpoint: "API de Productos",
        variant_endpoint: "API de Variantes",
        variant_warehouse_endpoint: "API de Bodegas de Variantes",
        move: "Stock entre bodegas",
        sale: "Pedido",
        restock: "Reposición de Stock",
        edit: "Edición Manual",
        bulk_upload: "Carga Masiva",
        stocks: "Editor Stocks"
      },
      timeZoneDates: {}
    };
  },
  computed: {
    ...mapState(["filters", "currentUser"]),
    filterCaption() {
      const filters = [];
      if (this.filters.source) {
        filters.push(this.filters.source);
      }
      if (this.filters.operation) {
        filters.push(this.operationMap[this.filters.operation]);
      }
      if (this.filters.variantId) {
        filters.push(
          "Variante: " +
            this.product.variants.find(x => x.id === this.filters.variantId).sku
        );
      }
      if (this.filters.warehouseId) {
        filters.push(
          "Bodega: " +
            this.currentUser.company.warehouses.find(
              x => x.id === this.filters.warehouseId
            ).name
        );
      }
      return filters.join(", ");
    }
  },
  methods: {
    ...mapActions(["timezoneDate"]),
    ...mapMutations(["setFilter"]),
    origin(transaction) {
      const _origin = this._origin(transaction);
      if (_origin) {
        return "(" + _origin + ")";
      }
      return "";
    },
    _origin(transaction) {
      if (transaction.order) {
        return transaction.order.numberOrigin
          ? transaction.order.numberOrigin
          : transaction.order.idOrigin;
      }
      if (transaction.user) {
        return transaction.user.email;
      }
      return null;
    },
    async refreshTimeZoneDates() {
      const timeZoneDates = {};
      for (let i = 0; i < this.transactions.length; i++) {
        const t = this.transactions[i];
        const date = await this.timezoneDate(t.createdAt);
        timeZoneDates[i] = moment(date).format("DD/MM/YYYY HH:mm:ss");
      }
      this.timeZoneDates = timeZoneDates;
    },
    cleanFilters() {
      this.setFilter({
        source: null,
        operation: null,
        variantId: null,
        warehouseId: null
      });
    }
  },
  watch: {
    transactions() {
      this.refreshTimeZoneDates();
    }
  }
};
</script>
<style scoped>
.table td {
  height: 15px;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
</style>
