<template>
  <div class="mb-3">
    <h4 class="font-weight-bold my-auto">Sincronización</h4>
    <hr />
    <base-alert-with-count-down
      v-model="showAlert"
      :alert-variant="alertVariant"
    >
      {{ alertMessage }}
    </base-alert-with-count-down>
    <b-row>
      <b-col cols="12" class="mb-2">
        Puedes forzar la sincronización de todos tus productos presionando el
        botón "Sincronizar". Esta operación puede tardar varios minutos
        dependiendo de la cantidad de productos que tengas en tu inventario.
      </b-col>
    </b-row>
    <b-row class="ml-0">
      <div class="mr-2 my-2">
        <b-button variant="danger" @click="sendSyncMulti('crucial')">
          <b-icon icon="arrow-repeat" /> Sincronizar stock, precios y estado
          <b-spinner v-if="loading['crucial']" small />
        </b-button>
      </div>
      <div class="mr-2 my-2" v-if="currentUser.role === 0">
        <b-button variant="danger" @click="sendSyncMulti('massive')">
          <b-icon icon="arrow-repeat" /> Sincronización completa
          <b-spinner v-if="loading['massive']" small />
        </b-button>
      </div>
      <div class="mr-2 my-2" v-if="currentUser.role === 0">
        <b-form-checkbox v-model="checked" @change="handleChecked">
          Seleccionar todas la integraciones disponibles</b-form-checkbox
        >
      </div>
    </b-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import SEND_SYNC_MULTI from "../../../graphql/Company/SendSyncMulti.gql";
import BaseAlertWithCountDown from "../../Base/BaseAlertWithCountDown.vue";
export default {
  name: "Synchronization",
  components: {
    BaseAlertWithCountDown
  },
  props: {
    ics: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      checked: false,
      icsSelected: [],
      showAlert: false,
      error: false,
      errorMessage: null,
      loading: {
        massive: false,
        crucial: false
      }
    };
  },
  computed: {
    ...mapState(["currentUser"]),
    alertVariant() {
      return this.error ? "danger" : "success";
    },
    alertMessage() {
      if (!this.error) {
        return "Sincronización masiva iniciada exitosamente, abajo puedes ver el avance";
      } else {
        return `Ha ocurrido un error iniciando la sincronización masiva: ${this.errorMessage}`;
      }
    }
  },
  created() {
    this.icsSelected = this.$dup(this.ics);
  },
  methods: {
    /**
     * Maneja el cambio del checkbox que selcciona todas las integraciones
     * @param {Boolean} val
     */
    handleChecked(val) {
      if (val) {
        this.icsSelected = ["all"];
      } else {
        this.icsSelected = [];
      }
      this.$emit("change", this.checked);
    },
    /**
     * Hace una revision previa a la sincronizacion
     * @param {String} type - tipo de sincro
     */
    sendSyncMulti(type) {
      if (this.icsSelected.length === 0) {
        this.$swal.fire({
          title: "Error",
          text:
            "Debes seleccionar al menos una integración con la cual sincronizar",
          icon: "error"
        });
      } else {
        this.loading[type] = true;
        this.sendMutation(type);
      }
    },
    /**
     * Envia la mutacion para empezar las sincronizaciones
     * @param {String} type - tipo de sincro
     */
    sendMutation(type) {
      this.error = false;
      this.errorMessage = null;
      this.$apollo
        .mutate({
          mutation: SEND_SYNC_MULTI,
          variables: { integrations: this.icsSelected, type: type }
        })
        .then(({ data }) => {
          if (!data.syncMulti.result) {
            this.errorMessage = data.syncMulti.errors;
            this.error = true;
          } else {
            this.$emit("sincro-sended");
          }
          this.showAlert = true;
          this.loading[type] = false;
        });
    }
  },
  watch: {
    ics: {
      handler(val) {
        this.icsSelected = this.$dup(val);
      },
      deep: true
    }
  }
};
</script>

<style></style>
