<template>
  <div>
    <b-row>
      <b-col>
        <b-input-group>
          <b-input-group-prepend is-text>
            <b-icon-search></b-icon-search>
          </b-input-group-prepend>
          <b-form-tags
            v-model="keywords"
            separator=","
            remove-on-delete=""
            placeholder="Palabras claves"
          ></b-form-tags>
        </b-input-group>
      </b-col>
    </b-row>

    <br />

    <b-spinner v-if="loading" label="Spinning"></b-spinner>
    <div v-else-if="specificationGroupsList">
      <b-table-simple responsive>
        <b-thead>
          <b-tr>
            <b-th class="col-md-5"> Nombre </b-th>
            <b-th class="col-md-2"> Id </b-th>
            <b-th class="col-md-2"> Id Categoria </b-th>
            <b-th class="col-md-2"> Posición </b-th>
            <b-th class="col-md-1"> Acciones </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="group in actualSpecificationsGroupsList" :key="group.id">
            <b-td> {{ group.name }} </b-td>
            <b-td> {{ group.id }} </b-td>
            <b-td> {{ group.categoryId }} </b-td>
            <b-td> {{ group.position }} </b-td>
            <b-td>
              <b-dropdown variant="white" no-caret="" offset="-110">
                <template v-slot:button-content>
                  <b-icon-three-dots-vertical></b-icon-three-dots-vertical>
                </template>
                <b-dropdown-item @click="openSpecificationsGroupModal(group)">
                  Editar
                </b-dropdown-item>
              </b-dropdown>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <b-row align-h="center">
        <b-pagination
          v-model="page"
          :total-rows="specificationGroupsList.length"
          :per-page="pageSize"
          @change="changePage"
        ></b-pagination>
      </b-row>
    </div>
  </div>
</template>

<script>
import INTEGRATION_CONFIG_VTEX_GET_SPECIFICATIONS_GROUPS from "@/graphql/IntegrationConfigVtexGetSpecificationsGroups.gql";

export default {
  name: "VtexSpecificationsGroupsTable",
  data() {
    return {
      loading: true,
      pageSize: 10,
      page: 1,
      specificationGroupsList: null,
      actualSpecificationsGroupsList: null,
      keywords: []
    };
  },
  props: {
    vtexIntegrationConfigId: {
      type: String,
      required: true
    },
    categoryId: {
      required: true,
      validator: prop => typeof prop === "string" || prop === null
    },
    specificationsGroups: {
      required: true,
      validator: prop => Array.isArray(prop) || prop === null
    }
  },
  mounted: async function() {
    if (this.categoryId !== null && this.specificationsGroups !== null) {
      this.specificationGroupsList = [...this.specificationsGroups];
      this.loading = false;
    } else if (this.categoryId !== null && this.specificationsGroups === null) {
      this.$emit("getSpecificationsGroups");
    }
  },
  methods: {
    /**
     * Realiza la petición para obtener los grupos de especificaciones en Vtex.
     */
    async getSpecificationsGroups(categoryId) {
      this.loading = true;
      await this.$apollo
        .query({
          query: INTEGRATION_CONFIG_VTEX_GET_SPECIFICATIONS_GROUPS,
          variables: {
            id: this.vtexIntegrationConfigId,
            categoryId: categoryId,
            keywords: this.keywords.length > 0 ? this.keywords : null
          }
        })
        .then(({ data }) => {
          if (!data?.integrationConfigVtex?.specificationsGroupsList) {
            this.emitErrorMessage(
              "No se pudo obtener el listado de grupos de especificaciones"
            );
          } else {
            this.specificationGroupsList =
              data.integrationConfigVtex.specificationsGroupsList;
          }
        });
      this.loading = false;
    },
    /**
     * Cambia la pagina del listado de grupos de especificación.
     * @param {Integer} page
     */
    changePage(page) {
      this.page = page;
      let offset = (page - 1) * this.pageSize;
      this.actualSpecificationsGroupsList = this.specificationGroupsList.slice(
        offset,
        offset + this.pageSize
      );
    },
    /**
     * Emite el evento 'openSpecificationsGroupModal' con el objeto del grupo
     * de especificaciones correspondiente.
     * @param {String} group
     */
    openSpecificationsGroupModal(group) {
      this.$emit("openSpecificationsGroupModal", group);
    },
    /**
     * Emite el evento 'errorMessage' con el mensaje de error correspondiente
     * @param {String} errorMessage
     */
    emitErrorMessage(errorMessage) {
      this.$emit("errorMessage", errorMessage);
    },
    /**
     * Define la froma de comparar dos grupos de especificaciones para ser
     * ordenados en una lista
     * @param {Object} group1
     * @param {Object} group2
     * @return {Integer}
     */
    compareGroupsForSortedList(group1, group2) {
      let category1 = group1.categoryId;
      let category2 = group2.categoryId;
      if (category1 === null && category2 === null) return 0;
      if (category1 === null) return 1;
      if (category2 === null) return -1;

      category1 = parseInt(category1);
      category2 = parseInt(category2);
      if (category1 < category2) return 1;
      if (category1 > category2) return -1;

      let position1 = parseInt(group1.position);
      let position2 = parseInt(group2.position);
      if (position1 < position2) return -1;
      if (position1 > position2) return 1;

      let id1 = parseInt(group1.id);
      let id2 = parseInt(group2.id);
      if (id1 < id2) return -1;
      if (id1 > id2) return 1;
      return 0;
    },
    /**
     * Revisa si el listado de grupos de especificaciones entregado esta ordenado.
     * @param {Array<Object>} specificationsGroupsList
     * @return {Boolean}
     */
    specificationsGroupsListIsSorted(specificationsGroupsList) {
      let sorted = true;
      for (let i = 0; i < specificationsGroupsList.length - 1; i++) {
        const group = specificationsGroupsList[i];
        const nextGroup = specificationsGroupsList[i + 1];

        if (this.compareGroupsForSortedList(group, nextGroup) > 0) {
          sorted = false;
          break;
        }
      }
      return sorted;
    }
  },
  watch: {
    categoryId(newCategoryId) {
      if (newCategoryId !== null) this.getSpecificationsGroups(newCategoryId);
    },
    keywords() {
      if (this.categoryId !== null)
        this.getSpecificationsGroups(this.categoryId);
    },
    specificationGroupsList(newspecificationGroupsList) {
      if (!this.specificationsGroupsListIsSorted(newspecificationGroupsList)) {
        newspecificationGroupsList.sort(this.compareGroupsForSortedList);
        this.specificationGroupsList = newspecificationGroupsList;
      }
      this.changePage(1);
    },
    specificationsGroups(newSpecificationsGroups) {
      if (newSpecificationsGroups !== null) {
        this.specificationGroupsList = [...newSpecificationsGroups];
      }
      this.loading = false;
    }
  }
};
</script>
