<template>
  <b-tr>
    <b-td>{{ bulkUpload.createdAt }}</b-td>
    <b-td>{{ bulkUpload.company.name }}</b-td>
    <b-td>{{ status }}</b-td>
    <b-td v-if="!sidekiqIds">
      <b-spinner label="Spinning"></b-spinner>
    </b-td>
    <b-td v-else>
      {{ sidekiqStatus }}
    </b-td>
    <b-td v-if="enqueuable">
      <b-spinner v-if="enqueuing" label="Spinning"></b-spinner>
      <b-badge v-else-if="enqueued"
        >Re-encolado (actualice la página para ver el estado)</b-badge
      >
      <b-button v-else @click="enqueue">Reencolar</b-button>
    </b-td>
    <b-td v-else></b-td>
  </b-tr>
</template>
<script>
import ENQUEUE_BULK_UPLOAD from "../graphql/EnqueueBulkUpload.gql";
export default {
  name: "PendingBulkUploadTableRow",
  props: {
    bulkUpload: Object,
    sidekiqIds: Object
  },
  data() {
    return {
      enqueuing: false,
      enqueued: false
    };
  },
  computed: {
    status() {
      switch (this.bulkUpload.status) {
        case "submited":
          return "Esperando revisión";
        case "checking":
          return "Revisando consistencia de la planilla";
      }
      return "Procesando";
    },
    sidekiqStatus() {
      switch (this.sidekiqIds[this.bulkUpload.id]) {
        case "scheduled":
          return "Programado";
        case "retry":
          return "Por reintentarse";
        case "enqueue":
          return "En cola";
        case "running":
          return "Ejecutándose";
        case "dead":
          return "Proceso muerto";
      }
      return "Perdido";
    },
    enqueuable() {
      if (
        !this.sidekiqIds[this.bulkUpload.id] ||
        this.sidekiqIds[this.bulkUpload.id] == "dead"
      ) {
        return true;
      }
      return false;
    }
  },
  methods: {
    enqueue() {
      this.enqueing = true;
      this.$apollo
        .mutate({
          mutation: ENQUEUE_BULK_UPLOAD,
          variables: {
            id: this.bulkUpload.id
          }
        })
        .then(() => {
          this.enqueing = false;
          this.enqueued = true;
        });
    }
  }
};
</script>
