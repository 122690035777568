<template>
  <div>
    <template>
      <base-header
        title="Promociones"
        title-link="/jumpseller/promotions"
        :map="getMapping"
        :titleSize="3"
      >
      </base-header>
      <template>
        <div>
          <b-container fluid="">
            <router-view :integrationConfig="integrationConfig"></router-view>
          </b-container>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import BaseHeader from "../../components/BaseHeader.vue";
import { centryUrl } from "../../main";

export default {
  name: "Promotion",
  components: {
    BaseHeader
  },
  data() {
    return {
      centryUrl,
      integrationConfig: this.$route.params.integration_id
    };
  },
  computed: {
    getMapping() {
      if (this.$route.name == "PromotionDetail") {
        return ["Crear", ""];
      } else {
        return ["Editar", ""];
      }
    }
  },
  methods: {}
};
</script>
