<template>
  <div>
    <ApolloQuery
      :query="require('../graphql/Webhooks/Webhook.gql')"
      :variables="queryVariables()"
      @result="mangeResult"
    >
      <template slot-scope="{ result: { data }, isLoading }">
        <base-header
          title="Webhooks"
          title-link="/integrations/webhooks"
          :titleSize="0"
          :map="[$route.meta.action]"
        >
        </base-header>
        <b-container fluid="">
          <router-view
            :is-loading="isLoading == 1"
            :webhook="$dig(data, 'webhook')"
          ></router-view>
        </b-container>
      </template>
    </ApolloQuery>
  </div>
</template>

<script>
import BaseHeader from "../components/BaseHeader.vue";
import { mapState } from "vuex";
export default {
  name: "Webhook",
  components: {
    BaseHeader
  },
  created() {
    if (this.$route.name == "WebhookNew") {
      this.$redirectToProfile(this.currentUser, "webhooks", "create");
    } else {
      this.$redirectToProfile(this.currentUser, "webhooks", "update");
    }
  },
  computed: {
    ...mapState(["currentUser"])
  },
  methods: {
    /**
     * Entrega las variables para la query que permite obtener el webhook
     */
    queryVariables() {
      let variables = {};
      variables.id = "new";
      if (this.$route.params.id) {
        variables.id = this.$route.params.id;
      }
      return variables;
    },
    /**
     * Se encarga de manejar el resultado de la query,
     * si no existe el webhook se re-direcciona a la lista de webhooks
     * @param {Object} result - resultado de query
     */
    mangeResult(result) {
      if (!result.error && !result.data.webhook) {
        this.$router.push({ name: "Webhooks" });
      }
    }
  }
};
</script>

<style></style>
