<template>
  <div>
    <b-modal
      v-model="showModal"
      size="lg"
      @hidden="handleHidden"
      @ok="handleAction"
      @cancel="handleCancel"
    >
      <template #modal-title>
        <base-header :title="modalTitle" :titleSize="12" :map="mapHeader">
        </base-header>
      </template>

      <b-container v-if="group">
        <b-row>
          <b-col>
            <b-form-group label="Nombre*" label-for="name">
              <b-form-input id="name" v-model="group.name" placeholder="Nombre">
              </b-form-input>
              <span v-if="disabledOkButton" class="text-danger">
                El nombre es requerido
              </span>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group label="Categoria" label-for="categoryId">
              <v-select
                id="categoryId"
                placeholder="Seleccione una categoria"
                v-model="group.categoryId"
                :options="categories"
                disabled
              ></v-select>
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>

      <template #modal-footer="{ cancel, ok }">
        <b-button-group>
          <b-button variant="outline-info" @click="cancel">
            <b-icon-x-circle></b-icon-x-circle> Cancelar
          </b-button>
          <b-button variant="info" @click="ok" :disabled="disabledOkButton">
            <b-icon-cloud-upload></b-icon-cloud-upload> {{ actionTraduction }}
          </b-button>
        </b-button-group>
      </template>
    </b-modal>
  </div>
</template>

<script>
import VTEX_CREATE_SPECIFICATIONS_GROUP from "@/graphql/VtexCreateSpecificationsGroup.gql";
import VTEX_UPDATE_SPECIFICATIONS_GROUP from "@/graphql/VtexUpdateSpecificationsGroup.gql";
import BaseHeader from "@/components/BaseHeader.vue";

export default {
  name: "VtexSpecificationsGroupModal",
  components: {
    BaseHeader
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    action: {
      type: String,
      default: "create"
    },
    specificationsGroup: {
      type: Object,
      default: () => null
    },
    vtexIntegrationConfigId: {
      type: String,
      required: true
    },
    categoryId: {
      required: true,
      validator: prop => typeof prop === "string" || prop === null
    },
    categories: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      showModal: false,
      group: null,
      originalGroup: null
    };
  },
  mounted() {
    this.group = this.defaultGroup();
    this.groupTransformToVue();
    this.originalGroup = Object.assign({}, this.group);
  },
  methods: {
    /**
     * Realiza la acción correspondiente al presionar Crear/Editar.
     */
    async handleAction() {
      this.action === "create"
        ? await this.createSpecificationsGroup()
        : await this.updateSpecificationsGroup();
    },
    /**
     * Al cancelar los cambios realizados, se dejan registrados en la vista
     * los valores originales del grupo de especificaciones.
     */
    handleCancel() {
      this.group = Object.assign({}, this.originalGroup);
    },
    /**
     * Emite el evento 'input' con el valor necesario para cerrar el modal.
     */
    handleHidden() {
      this.$emit("input", false);
    },
    /**
     * Realiza la petición para crear un grupo de especificaciones en Vtex.
     */
    async createSpecificationsGroup() {
      let groupToSend = {
        name: this.group.name,
        categoryId: this.group.categoryId
      };
      await this.$apollo
        .mutate({
          mutation: VTEX_CREATE_SPECIFICATIONS_GROUP,
          variables: {
            integrationConfigId: this.vtexIntegrationConfigId,
            specificationsGroup: this.groupTransformToVtex(groupToSend)
          }
        })
        .then(({ data }) => {
          if (!data?.vtexCreateSpecificationsGroup?.specificationsGroup) {
            this.emitErrorMessage(
              "No se pudo crear el grupo de especificaciones. " +
                data.vtexCreateSpecificationsGroup.errors
            );
          } else {
            this.emitSuccessfulMessage(
              "Grupo de especificaciones creado con éxito. Puede tardar unos minutos en reflejarse en el listado."
            );
          }
          this.group = Object.assign({}, this.originalGroup);
        })
        .catch(error => {
          this.emitErrorMessage(
            "No se pudo crear el grupo de especificaciones. " + error
          );
        });
    },
    /**
     * Realiza la petición para actualizar un grupo de especificaciones en Vtex.
     */
    async updateSpecificationsGroup() {
      let groupToSend = {
        id: this.group.id,
        name: this.group.name
      };
      await this.$apollo
        .mutate({
          mutation: VTEX_UPDATE_SPECIFICATIONS_GROUP,
          variables: {
            integrationConfigId: this.vtexIntegrationConfigId,
            specificationsGroup: this.groupTransformToVtex(groupToSend)
          }
        })
        .then(({ data }) => {
          if (!data?.vtexUpdateSpecificationsGroup?.specificationsGroup) {
            this.emitErrorMessage(
              "No se pudo actualizar el grupo de especificaciones. " +
                data.vtexUpdateSpecificationsGroup.errors
            );
          } else {
            this.emitSuccessfulMessage(
              "Grupo de especificaciones actualizado con éxito. Puede tardar unos minutos en reflejarse en el listado."
            );
          }
          this.originalGroup = Object.assign({}, this.group);
        })
        .catch(error => {
          this.emitErrorMessage(
            "No se pudo actualizar el grupo de especificaciones. " + error
          );
        });
    },
    /**
     * Emite el evento 'errorMessage' con el mensaje de error correspondiente
     * @param {String} errorMessage
     */
    emitErrorMessage(errorMessage) {
      this.$emit("errorMessage", errorMessage);
    },
    /**
     * Emite el evento 'succesfulMessage' con el mensaje de exito correspondiente
     * @param {String} message
     */
    emitSuccessfulMessage(message) {
      this.$emit("successfulMessage", message);
    },
    /**
     * Se encarga de seleccionar ciertos atributos del grupo de especificación.
     * @param {Object} specificationValue
     * @return {Object}
     */
    selectKeysFromGroup({ id, categoryId, name, position }) {
      return { id, categoryId, name, position };
    },
    /**
     * Genera un objeto de grupo de especificación vacio y añade la
     * categoría a la que pertenece.
     * @return {Object}
     */
    defaultGroup() {
      let group = { id: null, categoryId: null, name: null, position: null };
      group.categoryId = this.categoryId;
      return group;
    },
    /**
     * Se encarga de transformar los valores provenientes de Vtex a
     * aquellos que se utilizan en la vista.
     */
    groupTransformToVue() {
      if (typeof this.group.categoryId === "string") {
        this.group.categoryId = this.searchForCategory(this.group.categoryId);
      }
    },
    /**
     * Busca la opción del selector de categorias correspondiente al id informado.
     * @param {String} categoryId
     * @return {Object}
     */
    searchForCategory(categoryId) {
      let category = this.categories.find(
        category => category.value === categoryId
      );
      return category;
    },
    /**
     * Se encarga de transformar los valores seleccionados en la vista a
     * aquellos que deben ser enviados a Vtex.
     * @param {Object} specificationValue
     * @return {Object}
     */
    groupTransformToVtex(group) {
      let newGroup = Object.assign({}, group);
      if (group.categoryId !== null && typeof group.categoryId === "object") {
        newGroup.categoryId = group.categoryId.value;
      }
      return newGroup;
    }
  },
  computed: {
    actionTraduction() {
      return this.action === "create" ? "Crear" : "Guardar";
    },
    modalTitle() {
      let title = "grupo de especificaciones";
      return this.action === "create" ? `Crear ${title}` : `Editar ${title}`;
    },
    mapHeader() {
      return this.action === "update" ? [this.group.name] : [];
    },
    disabledOkButton() {
      return this.group.name === null || this.group.name === "";
    },
    disabledCategoryId() {
      return this.action === "update";
    }
  },
  watch: {
    value: function(newValue) {
      this.showModal = newValue;
    },
    specificationsGroup: function(newGroup) {
      let acceptedKeys = ["id", "categoryId", "name", "position"];
      if (newGroup === null) {
        this.group = this.defaultGroup();
      } else if (
        Object.keys(newGroup).some(key => !acceptedKeys.includes(key))
      ) {
        this.group = this.selectKeysFromGroup(newGroup);
      } else {
        this.group = newGroup;
      }

      this.groupTransformToVue();
      this.originalGroup = Object.assign({}, this.group);
    }
  }
};
</script>
