<template>
  <div>
    <b-button-group size="sm">
      <b-button variant="outline-info" @click="cancel">
        <b-icon-x-circle></b-icon-x-circle> Cancelar
      </b-button>
      <b-button
        variant="info"
        :disabled="
          !changed || saving || loadingIntegrationConfig || !validChangesPrices
        "
        :title="changed ? '' : 'No hay cambios'"
        v-b-tooltip.hover=""
        @click="save"
      >
        <span v-if="saving"><b-spinner label="Spinning"></b-spinner></span>
        <span v-else><b-icon-cloud-upload></b-icon-cloud-upload>Guardar</span>
      </b-button>
    </b-button-group>
    <b-alert
      v-model="saved"
      :variant="errorSaving ? 'danger' : 'success'"
      dismissible
    >
      {{
        errorSaving
          ? "Ha ocurrido un error"
          : "El producto ha sido actualizado exitosamente"
      }}
    </b-alert>
    <h4 class="font-weight-bold m-3">Detalles producto</h4>
    <b-row>
      <b-col md="4">
        <b-form-group
          label="Nombre"
          :label-for="'name-' + integrationConfig.fullLabel"
        >
          <b-form-input
            v-model="name"
            :id="'name-' + integrationConfig.fullLabel"
            :placeholder="product.name"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Sku"
          :label-for="'sku-' + integrationConfig.fullLabel"
        >
          <b-form-input
            v-model="sku"
            :id="'sku-' + integrationConfig.fullLabel"
            :placeholder="product.sku"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Price"
          :label-for="'price-' + integrationConfig.fullLabel"
        >
          <b-form-input
            v-model="price"
            :id="'price-' + integrationConfig.fullLabel"
            :placeholder="originalPrice"
            :state="this.$validFormPrice(this.price)"
            :formatter="$formatFormPrice"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Marca"
          :label-for="'brand-' + integrationConfig.fullLabel"
        >
          <b-form-input
            v-model="brand"
            :id="'brand-' + integrationConfig.fullLabel"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Código de barra"
          :label-for="'barcode-' + integrationConfig.fullLabel"
        >
          <b-form-input
            v-model="barcode"
            :id="'barcode-' + integrationConfig.fullLabel"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group label="Descripción">
          <base-html-text-area
            v-model="description"
            :id="'description-' + integrationConfig.fullLabel"
          ></base-html-text-area>
        </b-form-group>
        <b-form-group label="Status">
          <v-select
            v-model="status"
            :options="statuses"
            :reduce="x => x.value"
            :clearable="false"
          ></v-select>
        </b-form-group>
        <b-form-group label="Destacado">
          <v-select
            v-model="featured"
            :options="featureds"
            :reduce="x => x.value"
            :clearable="false"
          ></v-select>
        </b-form-group>
        <b-spinner label="Spinning" v-if="loadingIntegrationConfig"></b-spinner>
        <b-form-group label="Categorías" v-else>
          <v-select
            v-model="categories"
            :options="allCategories"
            :reduce="x => x.value"
            :multiple="true"
          ></v-select>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group
          label="Peso"
          :label-for="'weight-' + integrationConfig.fullLabel"
        >
          <b-form-input
            v-model="weight"
            :id="'weight-' + integrationConfig.fullLabel"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Formato Empaque">
          <v-select
            v-model="packageFormat"
            :options="packageFormats"
            :clearable="false"
            :reduce="x => x.value"
          ></v-select>
        </b-form-group>
        <b-form-group
          label="Largo"
          v-if="packageFormat === 'box' || packageFormat === 'cylinder'"
          :label-for="'length-' + integrationConfig.fullLabel"
        >
          <b-form-input
            v-model="length"
            :id="'length-' + integrationConfig.fullLabel"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Ancho"
          v-if="packageFormat === 'box'"
          :label-for="'width-' + integrationConfig.fullLabel"
        >
          <b-form-input
            v-model="width"
            :id="'width-' + integrationConfig.fullLabel"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Alto"
          v-if="packageFormat === 'box'"
          :label-for="'height-' + integrationConfig.fullLabel"
        >
          <b-form-input
            v-model="height"
            :id="'height-' + integrationConfig.fullLabel"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Diámetro"
          v-if="packageFormat === 'cylinder'"
          :label-for="'diameter-' + integrationConfig.fullLabel"
        >
          <b-form-input
            v-model="diameter"
            :id="'diameter-' + integrationConfig.fullLabel"
          ></b-form-input>
          <b-form-group
            label="Permalink"
            :label-for="'permalink-' + integrationConfig.fullLabel"
          >
            <b-form-input
              v-model="permalink"
              :id="'permalink-' + integrationConfig.fullLabel"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label="Título SEO"
            :label-for="'pageTitle-' + integrationConfig.fullLabel"
          >
            <b-form-input
              v-model="pageTitle"
              :id="'pageTitle-' + integrationConfig.fullLabel"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label="Meta Descripción"
            :label-for="'metaDescription-' + integrationConfig.fullLabel"
          >
            <b-form-input
              v-model="metaDescription"
              :id="'metaDescription-' + integrationConfig.fullLabel"
            ></b-form-input>
          </b-form-group>
        </b-form-group>
      </b-col>
    </b-row>
    <h4 class="font-weight-bold m-3">Variantes</h4>
    <b-table-simple v-if="product.variants">
      <b-thead>
        <b-tr>
          <b-th colspan="3">Datos de Centry</b-th>
          <b-th colspan="2">Datos en {{ integrationConfig.label }}</b-th>
        </b-tr>
        <b-tr>
          <b-th>Color</b-th>
          <b-th>Talla</b-th>
          <b-th>Sku Centry</b-th>
          <b-th>Sku</b-th>
          <b-th>Precio</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="(variant, idx) of product.variants" :key="idx">
          <b-td>{{ variant.color ? variant.color.name : "" }}</b-td>
          <b-td>{{ variant.size ? variant.size.name : "" }}</b-td>
          <b-td>{{ variant.sku }}</b-td>
          <b-td>
            <b-form-input
              v-model="variants[idx].sku"
              @input="change"
            ></b-form-input
          ></b-td>
          <b-td>
            <b-form-input
              v-model="variants[idx].price"
              @input="change"
            ></b-form-input
          ></b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>
<script>
import BaseHtmlTextArea from "./BaseHtmlTextArea";
import { mapActions } from "vuex";
import INTEGRATION_CONFIG_JUMPSELLER from "../graphql/IntegrationConfigJumpseller.gql";
import { updateProduct, updateVariant } from "../main";
export default {
  name: "ProductEditTabsJumpseller",
  components: { BaseHtmlTextArea },
  model: {
    prop: "_changed",
    event: "change"
  },
  props: {
    integrationConfig: Object,
    product: Object
  },
  async created() {
    const original = this.product.integrations[
      this.integrationConfig.fullLabel
    ];
    await this.$apollo
      .query({
        query: INTEGRATION_CONFIG_JUMPSELLER,
        variables: {
          id: this.integrationConfig.id
        }
      })
      .then(({ data }) => {
        if (
          data &&
          data.integrationConfigJumpseller &&
          data.integrationConfigJumpseller.categories
        ) {
          const categoryHash = {};
          data.integrationConfigJumpseller.categories.forEach(x => {
            categoryHash[x.category.id] = x;
          });
          this.allCategories = data.integrationConfigJumpseller.categories.map(
            x => {
              let name = x.category.name;
              let parentId = x.category.parent_id;
              while (parentId) {
                name = categoryHash[parentId].category.name + " > " + name;
                parentId = categoryHash[parentId].category.parent_id;
              }
              return { label: name, value: x.category.id.toString() };
            }
          );
          if (original) {
            this.categories =
              original.categories === "" || original.categories === null
                ? []
                : original.categories;
          }
          this.$watch("categories", this.change);
        }
        this.loadingIntegrationConfig = false;
      });
  },
  data() {
    const original = this.product.integrations[
      this.integrationConfig.fullLabel
    ];
    return {
      changed: false,
      saving: false,
      errorSaving: false,
      saved: false,
      loadingIntegrationConfig: true,
      name: original ? original.name : "",
      sku: original ? original.sku : "",
      price: original ? original.price : "",
      brand: original ? original.brand : "",
      barcode: original ? original.barcode : "",
      permalink: original ? original.permalink : "",
      weight: original ? original.weight : "",
      packageFormat: original ? original.package_format : "",
      description: original ? original.description : "",
      pageTitle: original ? original.page_title : "",
      metaDescription: original ? original.meta_description : "",
      categories: null,
      status: original ? original.status : "",
      featured: original ? original.featured : "",
      length: original ? original.length : "",
      width: original ? original.width : "",
      height: original ? original.height : "",
      diameter: original ? original.diameter : "",
      variants: this.initialVariantArray(),
      statuses: [
        { label: "", value: "" },
        { label: "Disponible", value: "available" },
        { label: "No Disponible", value: "not-available" },
        { label: "Deshabilitado", value: "disabled" }
      ],
      featureds: [
        { label: "", value: "" },
        { label: "Sí", value: "true" },
        { label: "No", value: "false" }
      ],
      packageFormats: [
        { label: "", value: "" },
        { label: "Caja", value: "box" },
        { label: "Cilindro", value: "cylinder" }
      ],
      allCategories: []
    };
  },
  computed: {
    originalPrice() {
      return this.$ifNull(this.product.priceCompare, "").toString();
    },
    /**
     * Indica si los precios son validos para habilitar el botón
     * de guardar
     * @returns {Boolean}
     */
    validChangesPrices() {
      return (
        this.changed &&
        (this.$validFormPrice(this.price) ||
          this.$validFormPrice(this.price) == null)
      );
    }
  },
  methods: {
    ...mapActions(["timezoneDate"]),
    change() {
      this.changed = true;
      this.$emit("change", true);
    },
    async toDate(string) {
      if (string && string.length > 0) {
        const dateString = await this.timezoneDate(string);
        return new Date(dateString);
      }
      return null;
    },
    cancel() {
      if (this.changed) {
        this.$swal
          .fire({
            title: "Cancelar",
            text: "Si cancelas, perderás tus cambios. ¿Estás seguro?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "No"
          })
          .then(result => {
            if (result.value) {
              this.$router.push({ name: "Products" });
            }
          });
      } else {
        this.$router.push({ name: "Products" });
      }
    },
    changes(changesArray) {
      let updated = {};
      changesArray.forEach(x => {
        if (x.original !== x.current) {
          updated[x.key] = x.current;
        }
      });
      return updated;
    },
    updatedProductData() {
      const original = this.product.integrations[
        this.integrationConfig.fullLabel
      ];
      const changes = [
        {
          key: "name",
          original: original ? original.name : "",
          current: this.name
        },
        {
          key: "sku",
          original: original ? original.sku : "",
          current: this.sku
        },
        {
          key: "price",
          original: original ? original.price : "",
          current: Number.parseInt(this.price)
            ? Number.parseInt(this.price)
            : this.price
        },
        {
          key: "brand",
          original: original ? original.brand : "",
          current: this.brand
        },
        {
          key: "barcode",
          original: original ? original.barcode : "",
          current: this.barcode
        },
        {
          key: "permalink",
          original: original ? original.permalink : "",
          current: this.permalink
        },
        {
          key: "weight",
          original: original ? original.weight : "",
          current: this.weight
        },
        {
          key: "packageFormat",
          original: original ? original.packageFormat : "",
          current: this.packageFormat
        },
        {
          key: "description",
          original: original ? original.description : "",
          current: this.description
        },
        {
          key: "pageTitle",
          original: original ? original.pageTitle : "",
          current: this.pageTitle
        },
        {
          key: "metaDescription",
          original: original ? original.metaDescription : "",
          current: this.metaDescription
        },
        {
          key: "status",
          original: original ? original.status : "",
          current: this.status
        },
        {
          key: "featured",
          original: original ? original.featured : "",
          current: this.featured
        },
        {
          key: "length",
          original: original ? original.length : "",
          current: this.length
        },
        {
          key: "width",
          original: original ? original.width : "",
          current: this.width
        },
        {
          key: "height",
          original: original ? original.height : "",
          current: this.height
        },
        {
          key: "diameter",
          original: original ? original.diameter : "",
          current: this.diameter
        }
      ];
      const updated = this.changes(changes);
      const originalCategories = original ? original.categories : "";
      const currentCategories = this.categories ? this.categories : "";
      if (originalCategories !== currentCategories) {
        if (
          originalCategories === "" ||
          currentCategories === "" ||
          this.$ifNull(originalCategories, []).length !==
            currentCategories.length
        ) {
          updated.categories = currentCategories;
        } else {
          let hasChanges = false;
          this.$ifNull(originalCategories, []).forEach((x, i) => {
            if (currentCategories[i] !== x) {
              hasChanges = true;
            }
          });
          if (hasChanges) {
            updated.categories = currentCategories;
          }
        }
      }
      if (Object.keys(updated).length) {
        const toSend = { integrations: {} };
        toSend.integrations[this.integrationConfig.fullLabel] = updated;
        return toSend;
      }
      return null;
    },
    updateProductCache(product) {
      if (product) {
        this.product.integrations = product.integrations;
      }
    },
    async updateProduct() {
      const updated = this.updatedProductData();
      if (!updated) {
        return;
      }
      await updateProduct(this.$apollo, this.product.id, updated)
        .then(({ data }) => {
          if (data && data.updateProduct && data.updateProduct.product) {
            this.errorSaving = false;
            const product = data.updateProduct.product;
            this.updateProductCache(product);
          }
        })
        .catch(() => {
          this.errorSaving = true;
        });
    },
    updatedVariantData(position) {
      const variant = this.product.variants[position];
      const original = variant.integrations[this.integrationConfig.fullLabel];
      const current = this.variants[position];
      const changes = [
        {
          key: "sku",
          original: original ? original.sku : "",
          current: current.sku
        },
        {
          key: "price",
          original: original ? original.price : "",
          current: current.price
        }
      ];
      const updated = this.changes(changes);
      if (Object.keys(updated).length) {
        const toSend = { integrations: {} };
        toSend.integrations[this.integrationConfig.fullLabel] = updated;
        return toSend;
      }
      return null;
    },
    updateVariantCache(position, variant) {
      this.product.variants[position] = variant;
    },
    async updateVariant(position) {
      const updated = this.updatedVariantData(position);
      if (!updated) {
        return;
      }
      await updateVariant(
        this.$apollo,
        this.product.variants[position].id,
        updated
      )
        .then(async ({ data }) => {
          if (data && data.updateVariant && data.updateVariant.variant) {
            const variant = data.updateVariant.variant;
            this.updateVariantCache(position, variant);
          }
        })
        .catch(() => {
          this.errorSaving = true;
        });
    },
    async save() {
      this.saving = true;
      this.errorSaving = false;
      await this.updateProduct();
      for (
        let position = 0;
        position < this.product.variants.length;
        position++
      ) {
        await this.updateVariant(position);
      }
      this.saving = false;
      this.saved = true;
      if (!this.errorSaving) {
        this.changed = false;
        this.$emit("change", false);
      }
    },
    initialVariantArray() {
      if (this.product.variants) {
        return this.product.variants.map(x => {
          const original = this.$ifNull(x.integrations, {})[
            this.integrationConfig.fullLabel
          ];
          return {
            sku: original ? original.sku : "",
            price: original ? original.price : ""
          };
        });
      }
      return null;
    }
  },
  watch: {
    name() {
      this.change();
    },
    sku() {
      this.change();
    },
    price() {
      this.change();
    },
    brand() {
      this.change();
    },
    barcode() {
      this.change();
    },
    permalink() {
      this.change();
    },
    weight() {
      this.change();
    },
    packageFormat() {
      this.change();
    },
    description() {
      this.change();
    },
    pageTitle() {
      this.change();
    },
    metaDescription() {
      this.change();
    },
    status() {
      this.change();
    },
    featured() {
      this.change();
    },
    length() {
      this.change();
    },
    width() {
      this.change();
    },
    height() {
      this.change();
    },
    diameter() {
      this.change();
    }
  }
};
</script>
