<template>
  <b-navbar-nav>
    <b-nav-item-dropdown
      text="Inventario"
      class="font-weight-bolder"
      v-if="$permissionToRead(currentUser, 'inventory')"
    >
      <b-dropdown-item to="/products">Productos</b-dropdown-item>
      <b-dropdown-item :href="centryUrl + '/editor_dinamico'"
        >Editor Dinámico</b-dropdown-item
      >
      <b-dropdown-item :to="{ name: 'ProductImageManager' }"
        >Administrador de Imágenes</b-dropdown-item
      >
      <b-dropdown-item
        :href="centryUrl + '/stocks'"
        v-if="currentUser && currentUser.role === 0"
        >Stocks (Alfa)</b-dropdown-item
      >
      <b-dropdown-item to="/jumpseller/promotions"
        >Promociones Jumpseller (Beta)</b-dropdown-item
      >
      <b-dropdown-divider />
      <b-dropdown-item disabled="">MercadoLibre</b-dropdown-item>
      <b-dropdown-item
        to="/mercadolibre/attributes/integrations"
        v-if="sections.includes('product_meli_mirror')"
        >Atributos (Beta)</b-dropdown-item
      >
      <b-dropdown-item to="/mercadolibre/promotions">
        Central de Ofertas
      </b-dropdown-item>
      <b-dropdown-item to="/mercadolibre/quotations">
        Flete Dinámico
      </b-dropdown-item>
      <b-dropdown-item to="/size_charts">
        Guías de tallas (Beta)
      </b-dropdown-item>
      <b-dropdown-item to="/mercadolibre/catalog_suggestions/integrations">
        Sugerencias de catálogo (Beta)
      </b-dropdown-item>
      <b-dropdown-item
        to="/mercadolibre/recommendations"
        :style="{ display: 'none' }"
        >Recomendaciones de Moda
      </b-dropdown-item>
      <b-dropdown-item to="/mercadolibre/not_working_days">
        Días no laborales
      </b-dropdown-item>
    </b-nav-item-dropdown>
    <b-nav-item
      :to="'/warehouses'"
      class="font-weight-bolder"
      v-if="$permissionToRead(currentUser, 'warehouses')"
    >
      Bodegas
    </b-nav-item>
    <b-nav-item-dropdown
      text="Pedidos"
      class="font-weight-bolder"
      v-if="$permissionToRead(currentUser, 'orders')"
    >
      <b-dropdown-item to="/orders" class="font-weight-bolder">
        Pedidos
      </b-dropdown-item>
      <b-dropdown-item to="/orders/documents" class="font-weight-bolder">
        Documentos (Beta)
      </b-dropdown-item>
      <b-dropdown-divider />
      <b-dropdown-item to="/mercadolibre/me1"
        >Estados de Mercado Envios 1 MercadoLibre</b-dropdown-item
      >
    </b-nav-item-dropdown>
    <div v-else>
      <b-nav-item to="/orders" class="font-weight-bolder">
        Pedidos
      </b-nav-item>
    </div>
    <b-nav-item-dropdown
      text="Reportes"
      class="font-weight-bolder"
      v-if="$permissionToRead(currentUser, 'reports')"
    >
      <!-- <b-dropdown-item :href="centryUrl + '/admin/reportes/inventario'"
        >Inventario</b-dropdown-item
      > -->
      <b-dropdown-item :to="`/dashboards/${dashboardId}`"
        >Ventas (Beta)</b-dropdown-item
      >
      <b-dropdown-item
        :href="centryUrl + '/admin/reportes/notificaciones_recibidas'"
        >Notificaciones Recibidas</b-dropdown-item
      >
      <b-dropdown-item to="/sync_statuses">
        Estado por Integración
      </b-dropdown-item>
      <b-dropdown-item to="/moderations">
        Moderaciones
      </b-dropdown-item>
      <b-dropdown-item :href="centryUrl + '/admin/reportes/linio_sku_shops'"
        >Linio: SKU Internos (Beta)</b-dropdown-item
      >
      <b-dropdown-item :href="centryUrl + '/admin/reportes/compare_stocks'"
        >Verificar Stock (Beta)</b-dropdown-item
      >
      <b-dropdown-item disabled="">MercadoLibre</b-dropdown-item>
      <b-dropdown-item
        :href="centryUrl + '/admin/reportes/mercado_libre/unmodifiable_prices'"
        >Precios Inmodificables (Beta)</b-dropdown-item
      >
      <b-dropdown-item
        :href="centryUrl + '/admin/reportes/mercado_libre/cost_prices'"
        >Costos envíos (Beta)</b-dropdown-item
      >
      <b-dropdown-item to="/mercadolibre/highlights"
        >Productos más Vendidos de MercadoLibre (Beta)</b-dropdown-item
      >
    </b-nav-item-dropdown>
    <b-nav-item
      to="/bulk_upload/history"
      class="font-weight-bolder"
      v-if="$permissionToRead(currentUser, 'inventory')"
    >
      Carga Masiva
    </b-nav-item>
    <b-nav-item-dropdown
      text="Mensajería"
      class="font-weight-bolder"
      v-if="$permissionToRead(currentUser, 'messages')"
    >
      <b-dropdown-item :href="centryUrl + '/mensajeria/buzon'"
        >Bandeja de entrada</b-dropdown-item
      >
      <b-dropdown-item :href="centryUrl + '/mensajeria/plantillas'"
        >Plantillas de respuestas</b-dropdown-item
      >
      <b-dropdown-item :href="centryUrl + '/mensajeria/respuestas_automaticas'"
        >Mensajes automáticos</b-dropdown-item
      >
      <b-dropdown-item :to="'/inbox'">
        Correos enviados
      </b-dropdown-item>
    </b-nav-item-dropdown>
  </b-navbar-nav>
</template>
<script>
import { centryUrl, dashboardId } from "@/main";

export default {
  name: "TheMenuSections",
  props: {
    userGroup: Object,
    sections: Array,
    currentUser: Object
  },
  data() {
    return {
      centryUrl,
      dashboardId
    };
  }
};
</script>
