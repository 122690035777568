<template>
  <b-row>
    <b-col md="12">
      <b-alert variant="danger" show v-if="error">Ha ocurrido un error</b-alert>
      <b-skeleton-table
        v-else-if="loading"
        :rows="4"
        :columns="4"
      ></b-skeleton-table>
      <form-domain-attributes
        v-else
        action="editar"
        :integrationConfigId="integrationConfigId"
        :domain="domain"
        :saving="saving"
        @save="onSave"
        @cancel="onCancelForm"
        :previous="previous"
        check-validation
      />
    </b-col>
  </b-row>
</template>

<script>
import FormDomainAttributes from "../../../components/MercadoLibre/CatalogSuggestions/Form/DomainAttributes.vue";
import MERCADO_LIBRE_CATALOG_SUGGESTION from "../../../graphql/MercadoLibre/CatalogSuggestions/CatalogSuggestion.gql";
import MERCADO_LIBRE_CATALOG_SUGGESTION_DESCRIPTION from "../../../graphql/MercadoLibre/CatalogSuggestions/Description.gql";
import MERCADO_LIBRE_UPDATE_CATALOG_SUGGESTION from "../../../graphql/MercadoLibre/CatalogSuggestions/Update.gql";
import MERCADO_LIBRE_UPDATE_CATALOG_SUGGESTION_DESCRIPTION from "../../../graphql/MercadoLibre/CatalogSuggestions/UpdateDescription.gql";
import MERCADO_LIBRE_CREATE_DESCRIPTION_CATALOG_SUGGESTION from "../../../graphql/MercadoLibre/CatalogSuggestions/CreateDescriptionCatalogSuggestion.gql";

export default {
  name: "MercadoLibreCatalogSuggestionsEdit",
  components: {
    FormDomainAttributes
  },
  props: {
    integrationConfigId: {
      type: String,
      required: true
    },
    catalogSuggestionId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      error: false,
      domain: null,
      catalogSuggestion: null,
      currentDescription: null,
      descriptionExists: null,
      previous: null,
      saving: false
    };
  },
  mounted() {
    Promise.all([
      this.getCatalogSuggestion(),
      this.getCatalogSuggestionDescription()
    ]).then(() => {
      this.buildPrevious();
      this.loading = false;
    });
  },
  methods: {
    /**
     * Se obtiene la sugerencia de catálogo a editar
     * @returns {Promise}
     */
    getCatalogSuggestion() {
      return this.$apollo
        .query({
          query: MERCADO_LIBRE_CATALOG_SUGGESTION,
          variables: {
            integrationConfigId: this.integrationConfigId,
            suggestionId: this.catalogSuggestionId
          },
          fetchPolicy: "no-cache"
        })
        .then(({ data }) => {
          this.catalogSuggestion = this.$dig(
            data,
            "getSpecificMercadoLibreSuggestion"
          );
          this.domain = {
            ...this.$dig(data, "getSpecificMercadoLibreSuggestion", "domain")
          };
        })
        .catch(() => {
          this.error = true;
        });
    },
    /**
     * Se obtiene la descripción actual
     * @returns {Promise}
     */
    getCatalogSuggestionDescription() {
      return this.$apollo
        .query({
          query: MERCADO_LIBRE_CATALOG_SUGGESTION_DESCRIPTION,
          variables: {
            integrationConfigId: this.integrationConfigId,
            suggestionId: this.catalogSuggestionId
          },
          fetchPolicy: "no-cache"
        })
        .then(({ data }) => {
          this.descriptionExists = true;
          this.currentDescription = this.$dig(
            data,
            "getMercadoLibreDescriptionCatalogSuggestion",
            "plainText"
          );
        })
        .catch(() => {
          this.descriptionExists = false;
        });
    },
    /**
     * Se redirige al listado de sugerencias
     */
    onCancelForm() {
      this.$router.push(
        `/mercadolibre/catalog_suggestions/${this.integrationConfigId}/list`
      );
    },
    /**
     * Se actualiza la sugerencia
     * @param {Object} body
     * @param {String} description
     */
    onSave(body, description) {
      this.saving = true;
      Promise.all([
        this.updateCatalogSuggestion(body),
        this.updateDescription(description)
      ])
        .then(() => {
          this.$swal
            .fire({
              title: "Sugerencia actualizada",
              text: "Se ha actualizado con éxito",
              icon: "success"
            })
            .then(() => {
              this.$router.push(
                `/mercadolibre/catalog_suggestions/${this.integrationConfigId}/list`
              );
            });
          this.saving = false;
        })
        .catch(err => {
          this.$swal.fire({
            title: "Ha ocurrido un error",
            text: this.prettyError(err),
            icon: "error"
          });
          this.saving = false;
        });
    },
    /**
     * Retorna un error legible para el usuario
     * @param {String} err
     * @return {Strign}
     */
    prettyError(err) {
      if (err.includes("403 Forbidden")) {
        return "Error: Petición inválida";
      }
      return `Error ${err}`;
    },
    /**
     * Actualiza la sugerencia, o lanza una excepción
     * @param {Object} body
     * @returns {Promise}
     */
    updateCatalogSuggestion(body) {
      delete body.domainId;
      return this.$apollo
        .mutate({
          mutation: MERCADO_LIBRE_UPDATE_CATALOG_SUGGESTION,
          variables: {
            integrationConfigId: this.integrationConfigId,
            suggestionId: this.catalogSuggestionId,
            patch: body
          }
        })
        .then(({ data }) => {
          this.processResponse(
            data,
            "mercadoLibreUpdateCatalogSuggestion",
            "Sugerencia"
          );
        });
    },
    /**
     * Actualiza la descripción, o lanza una excepción
     * @param {String} description
     * @returns {Promise}
     */
    updateDescription(description) {
      const newDescription = this.$ifNull(description, "");
      const oldDescription = this.$ifNull(this.currentDescription, "");
      if (newDescription === oldDescription) {
        return;
      }
      if (!this.descriptionExists) {
        return this.createDescription(description);
      }
      return this.$apollo
        .mutate({
          mutation: MERCADO_LIBRE_UPDATE_CATALOG_SUGGESTION_DESCRIPTION,
          variables: {
            integrationConfigId: this.integrationConfigId,
            suggestionId: this.catalogSuggestionId,
            description: newDescription
          }
        })
        .then(({ data }) => {
          this.processResponse(
            data,
            "mercadoLibreUpdateDescriptionCatalogSuggestion",
            "Descripción"
          );
        });
    },
    /**
     * Procesa la respuesta, si hay un error, tira una excepción
     * @param {Object} data
     * @param {String} key
     * @param {String} info
     */
    processResponse(data, key, info) {
      const success = this.$dig(data, key, "result");
      if (!success) {
        throw (`en ${info}:, ${this.$ifNull(this.$dig(data, key, "error"))}`,
        `en ${info}:, Error desconocido`);
      }
    },
    /**
     * Intenta crear descripcion de la sugerencia recien creada
     * @param {String} description
     * @returns {Promise}
     */
    createDescription(description) {
      return this.$apollo
        .mutate({
          mutation: MERCADO_LIBRE_CREATE_DESCRIPTION_CATALOG_SUGGESTION,
          variables: {
            integrationConfigId: this.integrationConfigId,
            suggestionId: this.catalogSuggestionId,
            description: description
          }
        })
        .then(({ data }) => {
          this.processResponse(
            data,
            "mercadoLibreCreateDescriptionCatalogSuggestion",
            "Descripción"
          );
        });
    },
    /**
     * Se construye el objeto 'previous',
     * y lo guarda en data, para completar el formulario
     */
    buildPrevious() {
      this.previous = Object.assign(
        {},
        this.$ifNull(this.catalogSuggestion, {}),
        {
          description: this.currentDescription
        }
      );
    }
  }
};
</script>
