<template>
  <div>
    <mercado-libre-catalog-suggestion-form-resultant-validation
      :integration-config-id="integrationConfigId"
      :suggestion-id="previous.id"
      v-if="checkValidation"
    />
    <mercado-libre-catalog-suggestion-form-previous-validation
      :integration-config-id="integrationConfigId"
      :body="currentBody"
      @valid="previousValidation"
      v-if="needPreviousValidation"
    />
    <b-alert :show="formErrors && !valid" variant="danger" class="m-4">
      {{ `No se pudo ${action} la surgerencia debido a:` }}
      <ul>
        <li v-for="(error, index) of errorList" :key="index">
          {{ error }}
        </li>
      </ul>
    </b-alert>
    <b-skeleton-table
      v-if="loading || saving"
      :rows="4"
      :columns="4"
    ></b-skeleton-table>
    <div v-else-if="error">
      No fue posible obtener las especificaciones técnicas del dominio.
    </div>
    <b-container v-else>
      <b-button
        class="m-4"
        variant="outline-info"
        v-b-tooltip.hover
        @click="backToDomainsSelector"
        :title="titleBackButton"
        ><b-icon-backspace /> Volver
      </b-button>
      <b-button
        variant="info"
        class="m-2"
        @click="save"
        :disabled="saving"
        v-b-tooltip.hover
        title="Guardar Sugerencia"
      >
        Guardar
      </b-button>
      <b-row>
        <b-col md="4">
          <b-card no-body class="m-2">
            <div class="m-2">
              <h4>Categoría Seleccionada</h4>
              {{ domain.name }}
              <b-row>
                <b-col md="4">
                  <b-img
                    thumbnail
                    :src="imageUrl(domain)"
                    class="centry-thumb transparent-bg"
                    alt="image"
                    loading="lazy"
                    v-if="imageUrl(domain)"
                    width="80px"
                  ></b-img>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </b-col>
        <b-col md="8">
          <mercado-libre-form-card
            title="Título"
            description="El título solo incluye producto, marca, modelo y características
                principales. No agregues condiciones de venta, como cuotas sin
                interés o envíos gratis."
            :invalidFeedback="validation.name.invalidFeedback"
            :valid="validation.name.valid"
            :originalVariable="title"
            @change="setVariableTitle"
          />
        </b-col>
        <b-col md="12">
          <mercado-libre-form-card
            title="Código Universal de Producto"
            description="Es un número que se encuentra junto al código de barras, en la
                caja del producto o en su etiqueta."
            :invalidFeedback="validation.code.invalidFeedback"
            :valid="validation.code.valid"
            :originalVariable="code"
            @change="setVariableCode"
          />
        </b-col>
        <b-col md="12">
          <b-card no-body class="m-2">
            <div class="m-2">
              <h4>Fotos</h4>
              <small class="form-text text-muted">
                Tener buenas fotos es clave para un producto de calidad,
                asegúrate que la primera foto tenga fondo blanco puro creado con
                un editor de imágenes. No agregues bordes, logos ni marcas de
                agua.
              </small>
              <b-form-group
                :invalid-feedback="validation.images.invalidFeedback"
                :state="validation.images.valid"
              >
                <default-image-gallery
                  v-model="defaultImages"
                  :loading="loading"
                  @change="setDefaultImages"
                />
              </b-form-group>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <mercado-libre-form-section
        :section="section"
        :input="onInput"
        v-for="section of technicalSpecs"
        :key="section.id"
        :previous="values"
      />
      <b-card no-body class="m-2">
        <div class="m-2">
          <h4>Descripción</h4>
          <small class="form-text text-muted">
            Ingresa una breve descripción del producto. Sigue las siguientes
            recomendaciones: no dejar teléfonos de contacto, ni links a redes
            sociales u otros.
          </small>
          <b-form-group
            :description="
              `Evita incluir garantía o condiciones de venta como cuotas sin interés y envío gratis. (${description.length}/50000)`
            "
          >
            <b-form-textarea
              id="description"
              v-model="description"
              rows="3"
              max-rows="6"
              maxlength="50000"
            ></b-form-textarea>
          </b-form-group>
        </div>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import MERCADO_LIBRE_TECHNICAL_SPECS from "../../../../graphql/MercadoLibre/CatalogSuggestions/TechnicalSpecs.gql";
import MercadoLibreFormSection from "./SectionAttributes.vue";
import DefaultImageGallery from "./DefaultImageGallery.vue";
import MercadoLibreCatalogSuggestionFormPreviousValidation from "./PreviousValidation.vue";
import MercadoLibreCatalogSuggestionFormResultantValidation from "./ResultantValidation.vue";
import MercadoLibreFormCard from "./Card.vue";

export default {
  name: "MercadoLibreFormDomainAttribute",
  components: {
    MercadoLibreFormSection,
    DefaultImageGallery,
    MercadoLibreCatalogSuggestionFormPreviousValidation,
    MercadoLibreCatalogSuggestionFormResultantValidation,
    MercadoLibreFormCard
  },
  props: {
    integrationConfigId: {
      type: String,
      required: true
    },
    domain: {
      type: Object,
      required: true
    },
    saving: {
      type: Boolean,
      required: true
    },
    previous: {
      type: Object,
      required: false
    },
    needPreviousValidation: {
      type: Boolean,
      default: false
    },
    checkValidation: {
      type: Boolean,
      default: false
    },
    action: String
  },
  data() {
    return {
      loading: true,
      error: false,
      technicalSpecs: [],
      title: this.fromPrevious("title"),
      values: {},
      code: this.getAttributeValueFromPrevious({ id: "GTIN" }),
      description: this.fromPrevious("description"),
      formErrors: false,
      changed: true,
      defaultImages: this.getPicturesFromPrevious(),
      currentBody: null
    };
  },
  computed: {
    validation() {
      return {
        name: {
          valid: this.title.length > 0,
          invalidFeedback: "El título es obligatorio"
        },
        code: {
          valid: this.code.length > 0,
          invalidFeedback: "El código unviersal es obligatorio"
        },
        images: {
          valid: this.defaultImages.length > 0,
          invalidFeedback: "El producto debe contar con al menos una imagen"
        }
      };
    },
    //Valida el conjunto de validaciones completo
    valid() {
      let valid = true;
      //Titulo y Codigo Universal
      Object.keys(this.validation).forEach(x => {
        valid = valid && this.validation[x].valid;
      });
      //Campos Dinamicos segun Categoria
      Object.keys(this.values).forEach(id => {
        valid = valid && this.validateAttributeCategory(id);
      });
      return valid;
    },
    //Almacena arreglo con errores encontrados en el proceso de validacion
    errorList() {
      var errors = Object.keys(this.validation)
        .map(key => {
          return this.validation[key].valid
            ? null
            : this.validation[key].invalidFeedback;
        })
        .filter(x => x !== null);
      errors = errors.concat(
        Object.keys(this.values)
          .map(key => {
            return this.validateAttributeCategory(key)
              ? null
              : this.values[key]["name"] + " es un atributo obligatorio";
          })
          .filter(x => x !== null)
      );
      return errors;
    }
  },
  created() {
    this.getTechnicalSpecsByDomain();
  },
  methods: {
    /**
     * Entrega el valor desde el objeto previous
     * @param {String} key
     * @returns {String | Integer}
     */
    fromPrevious(key) {
      return this.$ifNull(this.$dig(this.previous, key), "");
    },
    /**
     * Obtiene las especificaciones tecnicas dadas un dominio
     */
    getTechnicalSpecsByDomain() {
      this.$apollo
        .query({
          query: MERCADO_LIBRE_TECHNICAL_SPECS,
          variables: {
            integrationConfigId: this.integrationConfigId,
            domain: this.domain.id,
            channelId: "catalog_suggestions"
          }
        })
        .then(({ data }) => {
          const technicalSpecs = data.allMercadoLibreTechnicalSpecsInputSearch;
          if (technicalSpecs) {
            this.technicalSpecs = technicalSpecs;
            this.values = this.getAllAttributeIds(technicalSpecs);
          }
        })
        .catch(() => {
          this.error = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    /**
     * Construye diccionaio de todos los atributos de las especificaciones tecnicas
     * Este diccionario sirve para determinar que atributos son obligatorios o son selectores
     * al momento de crear una sugerencia
     * @param {Object} technicalSpecs
     * @return {Object}
     */
    getAllAttributeIds(technicalSpecs) {
      var ans = {};
      technicalSpecs.map(section => {
        const attributeIds = this.getAllComponents(section);
        ans = { ...ans, ...attributeIds };
      });
      return ans;
    },
    /**
     * A partir de una seccion, se obtienen los atributos asociados
     * @param {Object} section
     * @return {Object}
     */
    getAllComponents(section) {
      var ans = {};
      section.components.map(component => {
        const attributeIds = this.getAllAttributes(component);
        ans = { ...ans, ...attributeIds };
      });
      return ans;
    },
    /**
     * A partir de un componente, se obtienen los atributos asociados
     * @param {Object} section
     * @return {Object}
     */
    getAllAttributes(component) {
      var ans = {};
      component.attributes.map(attr => {
        if (attr.id !== "GTIN") {
          ans[attr.id] = {
            value: this.getAttributeValueFromPrevious(attr),
            required: attr.tags.includes("required"),
            selector: this.isSelector(attr),
            name: attr.name
          };
        }
      });
      return ans;
    },
    /**
     * A partir de un atributo, se determina si este necesita un selector o no
     * @param {Object} attr
     * @return {Boolean}
     */
    isSelector(attr) {
      if (!attr.values) return false;
      return attr.values && attr.values.length;
    },
    /** Obtiene el valor inicial de un atributo.
     * Utiliza el data 'previous', si no hay previous entrega un
     * valor vacío
     * @param {Object} attr La definición del atributo
     * @returns {String | Array}
     */
    getAttributeValueFromPrevious(attr) {
      if (!this.previous) {
        return "";
      }
      const attributes = this.$dig(this.previous, "attributes");
      if (!attributes) return "";

      const attributeValue = attributes.find(elem => elem.id === attr.id);
      if (!attributeValue) return "";

      const values = attributeValue.values;
      if (!values) return "";
      if (!values.length) return "";

      if (attr.values) {
        if (attr.tags.includes("multivalued")) {
          return values
            .map(elem => elem.valueId)
            .filter(elem => elem !== null && this.validOption(attr, elem));
        } else {
          return this.validOption(attr, values[0].valueId)
            ? values[0].valueId
            : "";
        }
      }
      return values[0].name;
    },
    /**
     * Indica si la opción es válida
     * para el atributo
     * @param {Object} attribute
     * @param {String} value
     * @returns {Boolean}
     */
    validOption(attribute, value) {
      if (!attribute.values) return true;
      return !!attribute.values.find(av => av.id === value);
    },
    /**
     * Entrega la url de la imagen
     * @returns {String}
     */
    imageUrl(domain) {
      return this.$dig(domain, "pictures", 0, "url");
    },
    /**
     * Emite la cancelación del llenado del formulario
     */
    backToDomainsSelector() {
      this.$emit("cancel");
    },
    /**
     * Valida si el formulario contiene errores
     * @return {Boolean}
     */
    validate() {
      if (!this.valid) {
        this.formErrors = true;
        return false;
      }
      this.formErrors = false;
      return true;
    },
    /**
     * Trata de guardar la nueva sugerencia junto a su descripcion
     */
    async save() {
      if (!this.validate()) {
        this.currentBody = null;
        return;
      }
      const body = this.buildVariables();
      this.currentBody = body;
      if (!this.needPreviousValidation) {
        this.$emit("save", body, this.description);
      }
    },
    /**
     * Cuando el validador indica que el body está validado,
     * se emite este body para ser almacenado en mercadoLibre
     * @param {Boolean} value
     */
    previousValidation(value) {
      if (value) {
        this.$emit("save", this.currentBody, this.description);
      }
    },
    /**
     * Construye el body para enviar a la peticion de una nueva sugerencia
     */
    buildVariables() {
      const body = {};
      body.title = this.title;
      body.domainId = this.domain.id;
      body.pictures = this.buildSourceImages();
      body.attributes = [{ id: "GTIN", values: [{ name: this.code }] }];
      Object.keys(this.values).map(key => {
        if (key !== "GTIN" && this.values[key]["value"] != "") {
          body.attributes.push({
            id: key,
            values: this.buildValues(key)
          });
        }
      });
      return body;
    },
    /**
     * Contruye objeto con información para enviar de fotos
     * @return {Array<Object>}
     */
    buildSourceImages() {
      return this.defaultImages.map(image => {
        return { source: image.originalUrl };
      });
    },
    /**
     * Transforma el o los valores de un atributo en particular en
     * el formato que se necesita para enviar a la mutacion
     * @param {String } attrId
     * @return {Object}
     * */
    buildValues(attrId) {
      const allValues = this.values[attrId]["value"];
      const isSelector = this.values[attrId]["selector"];
      const values = [];
      [allValues].flat().map(val => {
        if (isSelector) {
          values.push({ id: val });
        } else {
          values.push({ name: val });
        }
      });
      return values;
    },
    /**
     * Recibe evento de cambio en algun atributo,
     * actualiza el valor actual del atributo
     * @param {Object} attr
     * @return {String || Array<String>} value
     * */
    onInput(attr, value) {
      this.$set(this.values, attr.id, {
        value: value,
        required: attr.tags.includes("required"),
        name: attr.name,
        selector: this.isSelector(attr)
      });
    },
    /**
     * Valida si un atributo de categoria es obligatorio o no
     * @param {String} paramId
     * @return {Boolean}
     * */
    validateAttributeCategory(paramId) {
      return (
        !this.values[paramId] ||
        !this.values[paramId]["required"] ||
        (this.values[paramId]["value"] !== null &&
          this.values[paramId]["value"].length > 0)
      );
    },
    /**
     * Retorna las fotos asociadas al cargar la información de la sugerencia
     * @return {Array<Object>}
     */
    getPicturesFromPrevious() {
      if (!this.previous) {
        return [];
      }
      return this.previous.pictures.map(picture => {
        return {
          id: picture.id,
          originalId: picture.id,
          originalUrl: picture.url,
          size: picture.size,
          defaultImage: true
        };
      });
    },
    /**
     * Setea valor del titulo de la sugerencia
     * @param {String} value
     */
    setVariableTitle(value) {
      this.title = value;
    },
    /**
     * Setea valor del codigo universal de la sugerencia
     * @param {String} value
     */
    setVariableCode(value) {
      this.code = value;
    },
    /**
     * Setea arreglo de imagenes de la sugerencia
     * @param {Array} value
     */
    setDefaultImages(value) {
      this.defaultImages = value;
    },
    titleBackButton() {
      return `Volver a ${
        this.action == "crear" ? "seleccionar categoría" : "listado sugerencias"
      }`;
    }
  }
};
</script>
