<template>
  <div>
    <b-button-group size="sm">
      <b-button variant="outline-info" @click="cancel">
        <b-icon-x-circle></b-icon-x-circle> Cancelar
      </b-button>
      <b-button
        variant="info"
        :disabled="!changed || saving"
        :title="changed ? '' : 'No hay cambios'"
        v-b-tooltip.hover=""
        @click="save"
      >
        <span v-if="saving"><b-spinner label="Spinning"></b-spinner></span>
        <span v-else><b-icon-cloud-upload></b-icon-cloud-upload>Guardar</span>
      </b-button>
    </b-button-group>
    <b-alert
      v-model="saved"
      :variant="errorSaving ? 'danger' : 'success'"
      dismissible
    >
      {{ messageAlert() }}
    </b-alert>
    <b-alert :show="formErrors && !valid" variant="danger">
      No se pudo actualizar el producto debido a:
      <ul>
        <li v-for="(error, index) of errorList" :key="index">{{ error }}</li>
      </ul>
    </b-alert>
    <h4 class="font-weight-bold m-3">Detalles del producto</h4>
    <b-row>
      <b-col md="6">
        <b-row>
          <b-col md="8">
            <b-form-group
              label="* Nombre"
              label-for="name"
              :invalid-feedback="validation.name.invalidFeedback"
              :state="validation.name.valid"
            >
              <b-form-input
                id="name"
                v-model="name"
                placeholder="Ej: Botín Margarita Beige"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="¿Compuesto?" label-for="isFullproductname">
              <b-form-checkbox
                id="isFullproductname"
                v-model="isFullproductname"
              ></b-form-checkbox>
            </b-form-group>
            <b-form-group label="¿Pack?" label-for="isPack">
              <b-form-checkbox
                id="isPack"
                v-model="isPack"
                :disabled="this.consolidated"
                @change="convertPack($event)"
              ></b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col md="1">
            <base-help-modal
              id="helpIfFullproductname"
              title="¿El producto tiene un nombre compuesto?"
              tooltip="Click aquí para ver un ejemplo"
              size="lg"
            >
              <product-edit-tabs-centry-help-full-product-name></product-edit-tabs-centry-help-full-product-name>
            </base-help-modal>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="11">
            <b-form-group
              label="* Categoría"
              label-for="category"
              :invalid-feedback="validation.category.invalidFeedback"
              :state="validation.category.valid"
            >
              <base-live-select
                id="category"
                v-model="category"
                placeholder="Seleccionar"
                :clearable="false"
                @search="updateCategories"
              >
              </base-live-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="11">
            <b-form-group
              label="* Marca"
              label-for="brand"
              :invalid-feedback="validation.brand.invalidFeedback"
              :state="validation.brand.valid"
            >
              <paginated-base-live-select
                id="brand"
                v-model="brand"
                placeholder="Seleccionar"
                :clearable="false"
                :min-search-characters="1"
                :timeout="1"
                :query="updateBrand"
                :limit="100"
                :valueOption="'id'"
                :labelOption="'name'"
              >
              </paginated-base-live-select>
            </b-form-group>
          </b-col>
          <b-col md="1">
            <base-help-modal
              id="helpBrand"
              tooltip="¿No encuentras la marca? click aquí"
              title="¿No está la marca de tu producto?"
            >
              <product-edit-tabs-centry-help-brand></product-edit-tabs-centry-help-brand>
            </base-help-modal>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="6">
        <b-row>
          <b-col md="6">
            <b-form-group label="Condición" label-for="condition">
              <v-select
                id="condition"
                :options="conditions"
                placeholder="Seleccionar"
                v-model="condition"
                :reduce="cond => cond.value"
              ></v-select>
            </b-form-group>
          </b-col>
          <b-col md="5">
            <b-form-group label="Estado" label-for="status">
              <base-boolean-selector v-model="status"> </base-boolean-selector>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group label="Temporada" label-for="season">
              <v-select
                id="season"
                :options="seasons"
                placeholder="Seleccionar"
                v-model="season"
                :reduce="cond => cond.value"
              ></v-select>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Género" label-for="gender">
              <base-live-select
                id="gender"
                v-model="gender"
                placeholder="Seleccionar"
                :clearable="false"
                @search="updateGenders"
                :min-search-characters="1"
              ></base-live-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group label="Año" label-for="seasonyear">
              <b-input
                id="seasonyear"
                v-model="seasonyear"
                placeholder="Ej: 2020"
              ></b-input>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Garantía" label-for="warranty_id">
              <v-select
                v-model="warrantyId"
                placeholder="Seleccionar"
                :options="allWarranties"
                :reduce="x => x.value"
              ></v-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Tabla de tallas" label-for="size_chart">
              <base-live-select
                id="size_chart"
                v-model="sizeChart"
                placeholder="Seleccionar"
                @search="updateSizeCharts"
                :min-search-characters="1"
              ></base-live-select>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <b-row>
          <b-col md="11">
            <b-form-group
              label="* Descripción"
              label-for="description"
              v-if="true"
              :invalid-feedback="validation.description.invalidFeedback"
              :state="validation.description.valid"
            >
              <base-html-text-area
                id="description"
                v-model="description"
              ></base-html-text-area>
            </b-form-group>
          </b-col>
          <b-col md="1">
            <base-help-modal
              id="helpDescription"
              title="Descripción del producto"
              tooltip="Click aquí para ver un ejemplo"
            >
              <product-edit-tabs-centry-help-description></product-edit-tabs-centry-help-description>
            </base-help-modal>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="6">
        <b-row>
          <b-col md="11">
            <b-form-group
              label="Características principales (en forma de viñetas)"
              label-for="shortdescription"
              v-if="true"
            >
              <base-html-text-area
                id="shortdescription"
                v-model="shortdescription"
              ></base-html-text-area>
            </b-form-group>
          </b-col>
          <b-col md="1">
            <base-help-modal
              id="helpShortDescription"
              title="Características del producto"
              tooltip="Click aquí para ver un ejemplo"
            >
              <product-edit-tabs-centry-help-short-description></product-edit-tabs-centry-help-short-description>
            </base-help-modal>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <hr />
    <h4 class="font-weight-bold m-3">Precios</h4>
    <b-row>
      <b-col md="3">
        <b-form-group
          label="* Precio"
          label-for="priceCompare"
          :state="validation.priceCompare.valid"
          :invalid-feedback="validation.priceCompare.invalidFeedback"
        >
          <b-input
            id="priceCompare"
            v-model="priceCompare"
            placeholder="5990"
          ></b-input>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group
          label="Precio Oferta"
          label-for="price"
          :state="validation.price.valid"
          :invalid-feedback="validation.price.invalidFeedback"
        >
          <b-input id="price" v-model="price" placeholder="4500"></b-input>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group
          label="Inicio oferta"
          label-for="salestartdate"
          :state="validation.salestartdate.valid"
          :invalid-feedback="validation.salestartdate.invalidFeedback"
        >
          <base-date-time
            v-model="salestartdate"
            type="datetime"
          ></base-date-time>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group
          label="Término oferta"
          label-for="saleenddate"
          format="DD/MM/YYYY HH:mm"
          :state="validation.saleenddate.valid"
          :invalid-feedback="validation.saleenddate.invalidFeedback"
        >
          <base-date-time
            v-model="saleenddate"
            type="datetime"
          ></base-date-time>
        </b-form-group>
      </b-col>
    </b-row>
    <hr />
    <h4 class="font-weight-bold m-3">Empaque y despacho</h4>
    <b-row>
      <b-col md="2">
        <b-form-group
          label="Alto (cm)"
          label-for="packageheight"
          :state="validation.packageheight.valid"
          :invalid-feedback="validation.packageheight.invalidFeedback"
        >
          <b-form-input
            id="packageheight"
            v-model="packageheight"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col md="2">
        <b-form-group
          label="Ancho (cm)"
          label-for="packagewidth"
          :state="validation.packagewidth.valid"
          :invalid-feedback="validation.packagewidth.invalidFeedback"
        >
          <b-form-input id="packagewidth" v-model="packagewidth"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col md="2">
        <b-form-group
          label="Profundo (cm)"
          label-for="packagelength"
          :state="validation.packagelength.valid"
          :invalid-feedback="validation.packagelength.invalidFeedback"
        >
          <b-form-input
            id="packagelength"
            v-model="packagelength"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group
          label="Peso (kg)"
          label-for="packageweight"
          :state="validation.packageweight.valid"
          :invalid-feedback="validation.packageweight.invalidFeedback"
        >
          <b-form-input
            id="packageweight"
            v-model="packageweight"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group
          label="Tempo de entrega (días)"
          label-for="deliverytimesupplier"
        >
          <b-form-input
            id="deliverytimesupplier"
            v-model="deliverytimesupplier"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <hr />
    <h4 class="font-weight-bold m-3">Inventario</h4>
    <b-row>
      <b-col md="6">
        <b-form-group
          label="* SKU (Stock Keeping Unit)"
          label-for="sku"
          :state="validation.sku.valid"
          :invalid-feedback="validation.sku.invalidFeedback"
        >
          <b-input v-model="sku" id="sku"></b-input>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group label="Barcode (ISBN, UPC, etc)" label-for="barcode">
          <b-input v-model="barcode" id="barcode"></b-input>
        </b-form-group>
      </b-col>
    </b-row>
    <hr />
    <h4 class="font-weight-bold m-3">Variantes</h4>
    <product-edit-tabs-centry-variants
      v-model="variants"
      :isPack="this.isPack"
      @change="change"
      @valid="updateVariantValidation"
      ref="packChanges"
    ></product-edit-tabs-centry-variants>
    <div v-if="isPack == true">
      <h4 class="font-weight-bold m-3">Pack</h4>
      <product-edit-tabs-packs
        v-model="packVariants"
        @change="change"
        @valid="updateVariantValidation"
        @packVariantsChange="packVariantsChange"
        :isPack="isPack"
        :variants="variants"
      ></product-edit-tabs-packs>
    </div>
    <hr />
    <h4 class="font-weight-bold m-3">SEO</h4>
    <b-row>
      <b-col md="6">
        <b-form-group label="Título de página">
          <b-input placeholder="Título" v-model="seoTitle"></b-input>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group label="Meta descripción">
          <b-form-textarea
            placeholder="Descripción"
            v-model="seoDescription"
          ></b-form-textarea>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { mapState } from "vuex";
import BaseHtmlTextArea from "../BaseHtmlTextArea.vue";
import BaseHelpModal from "../BaseHelpModal.vue";
import BaseLiveSelect from "../BaseLiveSelect.vue";
import PaginatedBaseLiveSelect from "../PaginatedBaseLiveSelect.vue";
import BaseBooleanSelector from "../BaseBooleanSelector.vue";
import BaseDateTime from "../BaseDateTime.vue";
import ProductEditTabsCentryVariants from "./Centry/Variants.vue";
import ProductEditTabsCentryHelpFullProductName from "./Centry/HelpFullProductName.vue";
import ProductEditTabsCentryHelpDescription from "./Centry/HelpDescription.vue";
import ProductEditTabsCentryHelpShortDescription from "./Centry/HelpShortDescription.vue";
import ProductEditTabsCentryHelpBrand from "./Centry/HelpBrand.vue";
import ProductEditTabsPacks from "./Centry/Packs.vue";
import ALL_CATAEGORIES from "../../graphql/AllCategories.gql";
import ALL_BRANDS from "../../graphql/AllBrands.gql";
import ALL_GENDERS from "../../graphql/AllGenders.gql";
import ALL_WARRANTIES from "../../graphql/AllWarranties.gql";
import ALL_SIZE_CHARTS from "../../graphql/SizeChart/AllSizeCharts.gql";
import {
  createVariant,
  createVariantWarehouse,
  updateProduct,
  createProduct,
  updateVariant,
  updateVariantWarehouse
} from "../../main";
export default {
  name: "ProductEditTabsCentry",
  components: {
    BaseLiveSelect,
    BaseBooleanSelector,
    BaseDateTime,
    BaseHtmlTextArea,
    BaseHelpModal,
    ProductEditTabsCentryVariants,
    ProductEditTabsCentryHelpFullProductName,
    ProductEditTabsCentryHelpDescription,
    ProductEditTabsCentryHelpShortDescription,
    ProductEditTabsCentryHelpBrand,
    ProductEditTabsPacks,
    PaginatedBaseLiveSelect
  },
  model: {
    prop: "_changed",
    event: "change"
  },
  props: {
    _changed: {
      type: Boolean,
      default: false
    },
    product: Object
  },
  data() {
    return {
      readonly: true,
      changed: this._changed,
      name: this.product.name !== null ? this.product.name : "",
      description:
        this.product.description !== null ? this.product.description : "",
      isFullproductname: this.product.isFullproductname,
      category: this.itemForSelect(this.product.category),
      brand: this.itemForSelect(this.product.brand),
      gender: this.itemForSelect(this.product.gender),
      condition: this.product.condition,
      season: this.product.season,
      status: this.product.status,
      seasonyear: this.product.seasonyear,
      warrantyId: null,
      sizeChart: this.itemForSelect(this.product.sizeChart),
      shortdescription: this.product.shortdescription,
      price: this.product.price !== null ? this.product.price.toString() : "",
      priceCompare:
        this.product.priceCompare !== null
          ? this.product.priceCompare.toString()
          : "",
      salestartdate:
        this.product.salestartdate !== null
          ? new Date(this.product.salestartdate)
          : null,
      saleenddate:
        this.product.saleenddate !== null
          ? new Date(this.product.saleenddate)
          : null,
      packageheight:
        this.product.packageheight !== null
          ? this.product.packageheight.toString()
          : "",
      packagewidth:
        this.product.packagewidth !== null
          ? this.product.packagewidth.toString()
          : "",
      packagelength:
        this.product.packagelength !== null
          ? this.product.packagelength.toString()
          : "",
      packageweight:
        this.product.packageweight !== null
          ? this.product.packageweight.toString()
          : "",
      deliverytimesupplier:
        this.product.deliverytimesupplier !== null
          ? this.product.deliverytimesupplier.toString()
          : "",
      sku: this.product.sku,
      barcode: this.product.barcode,
      seoTitle: this.product.seoTitle,
      seoDescription: this.product.seoDescription,
      product_type: this.product._type,
      variants: this.initialVariantArray(),
      packVariants: [],
      conditions: [
        { label: "Nuevo", value: "new" },
        { label: "Usado", value: "used" },
        { label: "Renovado", value: "refurbished" }
      ],
      seasons: [
        { label: "Invierno", value: "Invierno" },
        { label: "Verano", value: "Verano" },
        { label: "Todas las temporadas", value: "Todas las temporadas" }
      ],
      saving: false,
      saved: false,
      errorSaving: false,
      formErrors: false,
      validCategoryAttributes: true,
      validVariants: true,
      allWarranties: [],
      isPack: this.isPackVerifier(this.product._type),
      consolidated: this.isConsolidated(),
      errorsSaving: {}
    };
  },
  async mounted() {
    this.updateWarranties();
  },
  computed: {
    ...mapState(["currentUser"]),
    validation() {
      return {
        name: {
          valid: this.name.length > 0,
          invalidFeedback: "El nombre es obligatorio"
        },
        category: {
          valid: this.category !== null,
          invalidFeedback: "La categoría es obligatoria"
        },
        brand: {
          valid: this.brand !== null,
          invalidFeedback: "La marca es obligatoria"
        },
        description: {
          valid: this.description.length > 0,
          invalidFeedback: "La descripción es obligatoria"
        },
        priceCompare: {
          valid:
            this.priceCompare.length > 0 && !isNaN(Number(this.priceCompare)),
          invalidFeedback:
            "El precio debe ser un valor numérico, y no puede estar vacío"
        },
        price: {
          valid:
            this.price.length === 0 ||
            (!isNaN(Number(this.price)) &&
              Number(this.price) < Number(this.priceCompare)),
          invalidFeedback:
            "El precio oferta debe ser numérico y menor al precio normal"
        },
        salestartdate: {
          valid:
            (this.price.length > 0 &&
              this.salestartdate !== null &&
              this.saleenddate !== null) ||
            (this.price.length === 0 &&
              this.salestartdate === null &&
              this.saleenddate === null),
          invalidFeedback: "Debe especificar todos los datos de la oferta"
        },
        saleenddate: {
          valid:
            this.saleenddate === null || this.saleenddate > this.salestartdate,
          invalidFeedback:
            "La fecha de finalización de oferta debe ser mayor al inicio"
        },
        packageheight: {
          valid:
            this.packageheight.length === 0 ||
            !isNaN(Number(this.packageheight)),
          invalidFeedback: "El alto del producto debe ser numérico"
        },
        packagewidth: {
          valid:
            this.packagewidth.length === 0 || !isNaN(Number(this.packagewidth)),
          invalidFeedback: "El ancho del producto debe ser numérico"
        },
        packagelength: {
          valid:
            this.packagelength.length === 0 ||
            !isNaN(Number(this.packagelength)),
          invalidFeedback: "La profundidad del producto debe ser numérica"
        },
        packageweight: {
          valid:
            this.packageweight.length === 0 ||
            !isNaN(Number(this.packageweight)),
          invalidFeedback: "El peso del producto debe ser numérico"
        },
        sku: {
          valid: this.sku.length > 0,
          invalidFeedback: "El SKU es obligatorio"
        },
        variants: {
          valid: this.validVariants,
          invalidFeedback: "Existe errores en las variantes"
        }
      };
    },
    valid() {
      let valid = true;
      Object.keys(this.validation).forEach(x => {
        valid = valid && this.validation[x].valid;
      });
      return valid;
    },
    errorList() {
      return Object.keys(this.validation)
        .map(key => {
          return this.validation[key].valid
            ? null
            : this.validation[key].invalidFeedback;
        })
        .filter(x => x !== null);
    }
  },
  methods: {
    change() {
      this.changed = true;
      this.$emit("change", true);
    },
    updateWarranties() {
      this.$getAllPages(ALL_WARRANTIES, {}, "allWarranties").then(data => {
        this.allWarranties = data.map(w => {
          return { value: w.node.id, label: w.node.name };
        });
        this.warrantyId = this.product.warrantyId;
        this.$watch("warrantyId", this.change);
      });
    },
    itemForSelect(item) {
      if (item) {
        return { value: item.id, label: item.name };
      }
      return null;
    },
    updateVariantValidation(valid) {
      this.validVariants = valid;
    },
    initialVariantArray() {
      return this.product.variants.map(v => {
        let variantWarehouseMap = {};
        if (v.variantWarehouses) {
          v.variantWarehouses.forEach(vw => {
            variantWarehouseMap[vw.warehouse.id] = {
              id: vw.id,
              quantity: vw.quantity
            };
          });
        }
        return {
          id: v.id,
          size: v.size ? { label: v.size.name, value: v.size.id } : null,
          color: v.color ? { label: v.color.name, value: v.color.id } : null,
          sku: v.sku !== null ? v.sku : "",
          barcode: v.barcode !== null ? v.barcode : "",
          variantWarehouseMap
        };
      });
    },
    isPackVerifier(type) {
      if (type == "Products::ProductPack") {
        return true;
      } else {
        return false;
      }
    },
    isConsolidated() {
      if (this.$route.name == "ProductNew") {
        return false;
      } else {
        return true;
      }
    },
    typeTranslate(isPack) {
      if (isPack) {
        return "Products::ProductPack";
      } else {
        return "Products::Product";
      }
    },
    typeTranslateVariant(isPack) {
      if (isPack) {
        return "Products::VariantPack";
      } else {
        return "Products::Variant";
      }
    },
    genders(data) {
      if (data && data.allGenders && data.allGenders.edges) {
        return data.allGenders.edges.map(x => this.itemForSelect(x.node));
      }
    },
    async updateCategories(search, loading, setOptions) {
      loading(true);
      this.$apollo
        .query({ query: ALL_CATAEGORIES, variables: { name: search } })
        .then(async result => {
          if (
            result.data &&
            result.data.allCategories &&
            result.data.allCategories.edges
          ) {
            setOptions(
              result.data.allCategories.edges.map(x => {
                return this.itemForSelect(x.node);
              })
            );
          }
        });
      loading(false);
    },
    async updateGenders(search, loading, setOptions) {
      loading(true);
      this.$apollo
        .query({ query: ALL_GENDERS, variables: { name: search } })
        .then(async result => {
          if (
            result.data &&
            result.data.allGenders &&
            result.data.allGenders.edges
          ) {
            setOptions(
              result.data.allGenders.edges.map(x => {
                return this.itemForSelect(x.node);
              })
            );
          }
        });
      loading(false);
    },
    async updateBrand(search, limit, cursor) {
      let brands = [];
      let newCursor = null;
      await this.$apollo
        .query({
          query: ALL_BRANDS,
          variables: { name: search, cursor: cursor, limit: limit }
        })
        .then(async result => {
          if (
            result.data &&
            result.data.allBrands &&
            result.data.allBrands.edges
          ) {
            brands = result.data.allBrands.edges.map(x => {
              return x.node;
            });
          }
          newCursor = result.data.allBrands.pageInfo.endCursor;
        });
      return { items: brands, cursor: newCursor };
    },
    /**
     * busca las guias de tallas dado un filtro
     * @param {String} search
     * @param {Function} loading
     * @param {Function} setOptions
     */
    async updateSizeCharts(search, loading, setOptions) {
      loading(true);
      this.$apollo
        .query({ query: ALL_SIZE_CHARTS, variables: { name: search } })
        .then(async result => {
          if (
            result.data &&
            result.data.allSizeCharts &&
            result.data.allSizeCharts.edges
          ) {
            setOptions(
              result.data.allSizeCharts.edges.map(x => {
                return this.itemForSelect(x.node);
              })
            );
          }
        });
      loading(false);
    },
    cancel() {
      if (this.changed) {
        this.$swal
          .fire({
            title: "Cancelar",
            text: "Si cancelas, perderás tus cambios. ¿Estás seguro?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "No"
          })
          .then(result => {
            if (result.value) {
              this.$router.push({ name: "Products" });
            }
          });
      } else {
        this.$router.push({ name: "Products" });
      }
    },
    /**
     * Actualiza el caché del producto, todos
     * los datos contenidos en product se almacenan en this.product
     * @param {Object} product
     */
    updateProductCache(product) {
      this.product.name = product.name;
      this.product.description = product.description;
      this.product.isFullproductname = product.isFullproductname;
      this.product.category = product.category;
      this.$emit("category", this.$dig(this.$dig(product.category, "id")));
      this.product.brand = product.brand;
      this.product.gender = product.gender;
      this.product.condition = product.condition;
      this.product.season = product.season;
      this.product.status = product.status;
      this.product.seasonyear = product.seasonyear;
      this.product.warrantyId = product.warrantyId;
      this.product.shortdescription = product.shortdescription;
      this.product.price = product.price;
      this.product.priceCompare = product.priceCompare;
      this.product.salestartdate = product.salestartdate;
      this.product.saleenddate = product.saleenddate;
      this.product.packageheight = product.packageheight;
      this.product.packagewidth = product.packagewidth;
      this.product.packagelength = product.packagelength;
      this.product.packageweight = product.packageweight;
      this.product.deliverytimesupplier = product.deliverytimesupplier;
      this.product.sku = product.sku;
      this.product.barcode = product.barcode;
      this.product.seoTitle = product.seoTitle;
      this.product.seoDescription = product.seoDescription;
      this.product._type = product._type;
    },
    changes(changesArray) {
      let updated = {};
      changesArray.forEach(x => {
        if (x.original !== x.current) {
          updated[x.key] = x.current;
        }
      });
      return updated;
    },
    updatedProductData() {
      const changes = [
        { key: "name", original: this.product.name, current: this.name },
        {
          key: "description",
          original: this.product.description,
          current: this.description
        },
        {
          key: "isFullproductname",
          original: this.product.isFullproductname,
          current: this.isFullproductname
        },
        {
          key: "categoryId",
          original: this.product.category ? this.product.category.id : null,
          current: this.category ? this.category.value : null
        },
        {
          key: "brandId",
          original: this.product.brand ? this.product.brand.id : null,
          current: this.brand ? this.brand.value : null
        },
        {
          key: "genderId",
          original: this.product.gender ? this.product.gender.id : null,
          current: this.gender ? this.gender.value : null
        },
        {
          key: "condition",
          original: this.product.condition,
          current: this.condition
        },
        { key: "season", original: this.product.season, current: this.season },
        { key: "status", original: this.product.status, current: this.status },
        {
          key: "seasonyear",
          original: this.product.seasonyear,
          current: this.seasonyear
        },
        {
          key: "warrantyId",
          original: this.product.warrantyId,
          current: this.warrantyId
        },
        {
          key: "sizeChartId",
          original: this.product.sizeChart ? this.product.sizeChart.id : null,
          current: this.sizeChart ? this.sizeChart.value : null
        },
        {
          key: "shortdescription",
          original: this.product.shortdescription,
          current: this.shortdescription
        },
        {
          key: "price",
          original: this.product.price,
          current: this.price && this.price.length ? Number(this.price) : null
        },
        {
          key: "priceCompare",
          original: this.product.priceCompare,
          current:
            this.priceCompare && this.priceCompare.length
              ? Number(this.priceCompare)
              : null
        },
        {
          key: "salestartdate",
          original: this.product.salestartdate
            ? new Date(this.product.salestartdate).toISOString()
            : null,
          current: this.salestartdate ? this.salestartdate.toISOString() : null
        },
        {
          key: "saleenddate",
          original: this.product.saleenddate
            ? new Date(this.product.saleenddate).toISOString()
            : null,
          current: this.saleenddate ? this.saleenddate.toISOString() : null
        },
        {
          key: "packageheight",
          original: this.product.packageheight,
          current:
            this.packageheight && this.packageheight.length
              ? Number(this.packageheight)
              : null
        },
        {
          key: "packagewidth",
          original: this.product.packagewidth,
          current:
            this.packagewidth && this.packagewidth.length
              ? Number(this.packagewidth)
              : null
        },
        {
          key: "packagelength",
          original: this.product.packagelength,
          current:
            this.packagelength && this.packagelength.length
              ? Number(this.packagelength)
              : null
        },
        {
          key: "packageweight",
          original: this.product.packageweight,
          current:
            this.packageweight && this.packageweight.length
              ? Number(this.packageweight)
              : null
        },
        {
          key: "deliverytimesupplier",
          original: this.product.deliverytimesupplier,
          current:
            this.deliverytimesupplier && this.deliverytimesupplier.length
              ? Number(this.deliverytimesupplier)
              : null
        },
        { key: "sku", original: this.product.sku, current: this.sku },
        {
          key: "barcode",
          original: this.product.barcode,
          current: this.barcode
        },
        {
          key: "seoTitle",
          original: this.product.seoTitle,
          current: this.seoTitle
        },
        {
          key: "seoDescription",
          original: this.product.seoDescription,
          current: this.seoDescription
        },
        {
          key: "_type",
          original: this.product._type,
          current: this.typeTranslate(this.isPack)
        }
      ];
      const updated = this.changes(changes);
      return Object.keys(updated).length > 0 ? updated : null;
    },
    /**
     * Actualiza todos los datos del producto
     * manejados en esta tab
     */
    async updateProduct() {
      const updateProductData = this.updatedProductData();
      if (updateProductData === null) {
        this.errorSaving = false;
        return;
      }
      await updateProduct(this.$apollo, this.product.id, updateProductData)
        .then(async ({ data }) => {
          const newProduct = this.$dig(data, "updateProduct", "product");
          if (newProduct) {
            this.errorSaving = false;
            this.updateProductCache(newProduct);
          }
        })
        .catch(() => {
          console.log("error 1");
          this.errorSaving = true;
        });
    },
    async createProduct() {
      const createProductData = this.updatedProductData();
      if (createProductData === null) {
        this.errorSaving = false;
        return;
      }
      await createProduct(this.$apollo, createProductData)
        .then(async ({ data }) => {
          if (data && data.createProduct) {
            this.errorSaving = false;
            this.consolidated = true;
            const newProduct = data.createProduct.product;
            this.product.id = data.createProduct.product.id;
            this.updateProductCache(newProduct);
          }
        })
        .catch(error => {
          console.log("error 2", error);
          this.errorSaving = true;
        });
    },
    updateVariantCache(params) {
      while (this.product.variants.length <= params.position) {
        this.product.variants.push({});
      }
      this.product.variants[params.position] = params;
      this.variants[params.position].id = params.id;
    },
    async updateVariant(id, params) {
      await updateVariant(this.$apollo, id, params)
        .then(async ({ data }) => {
          let errors = data?.updateVariant?.errors;
          if (!errors && data?.updateVariant?.variant) {
            const newVariant = data.updateVariant.variant;
            this.updateVariantCache(newVariant);
          } else if (errors) {
            this.errorSaving = true;
            if (this.errorsSaving[params["sku"]]) {
              this.errorsSaving[params["sku"]].push(errors);
            } else {
              this.errorsSaving[params["sku"]] = [errors];
            }
          }
        })
        .catch(() => {
          console.log("error 3");
          this.errorSaving = true;
        });
    },
    async createVariant(params) {
      params._type = this.typeTranslateVariant(this.isPack);
      await createVariant(this.$apollo, params)
        .then(async ({ data }) => {
          let errors = data?.createVariant?.errors;
          if (!errors && data?.createVariant?.variant) {
            const newVariant = data.createVariant.variant;
            this.updateVariantCache(newVariant);
          } else if (errors && errors.includes("SKU")) {
            this.errorSaving = true;
            if (this.errorsSaving[params["sku"]]) {
              this.errorsSaving[params["sku"]].push(errors);
            } else {
              this.errorsSaving[params["sku"]] = [errors];
            }
          }
        })
        .catch(error => {
          console.log("error 4", error);
          this.errorSaving = true;
        });
    },
    updateVariantWarehouseCache(newVariantWarehouse, variantPosition) {
      this.product.variants[
        variantPosition
      ].variantWarehouses = this.product.variants[
        variantPosition
      ].variantWarehouses.filter(
        x => x.warehouse.id !== newVariantWarehouse.warehouse.id
      );
      this.product.variants[variantPosition].variantWarehouses.push(
        newVariantWarehouse
      );
      this.variants[variantPosition].variantWarehouseMap[
        newVariantWarehouse.warehouse.id
      ].id = newVariantWarehouse.id;
    },
    async updateVariantWarehouse(id, params, variantPosition) {
      await updateVariantWarehouse(this.$apollo, id, params)
        .then(async ({ data }) => {
          if (data && data.variantWarehouse) {
            const newVariantWarehouse = data.variantWarehouse;
            this.updateVariantWarehouseCache(
              newVariantWarehouse,
              variantPosition
            );
          }
        })
        .catch(() => {
          console.log("error 5");
          this.errorSaving = true;
        });
    },
    async createVariantWarehouse(params, variantPosition) {
      await createVariantWarehouse(this.$apollo, params)
        .then(async ({ data }) => {
          if (data && data.createVariantWarehouse) {
            const newVariantWarehouse =
              data.createVariantWarehouse.variantWarehouse;
            this.updateVariantWarehouseCache(
              newVariantWarehouse,
              variantPosition
            );
          }
        })
        .catch(error => {
          console.log("error 6", error);
          this.errorSaving = true;
        });
    },
    validate() {
      if (!this.valid) {
        this.formErrors = true;
        return false;
      }
      this.formErrors = false;
      return true;
    },
    async saveVariantWarehouses(variantPosition, originalVariant) {
      const keys = Object.keys(
        this.variants[variantPosition].variantWarehouseMap
      );
      for (let index = 0; index < keys.length; index++) {
        const wId = keys[index];
        const vw = this.variants[variantPosition].variantWarehouseMap[wId];
        const vwparams = {
          quantity: Number(vw.quantity)
        };
        if (vw.id) {
          const oldVW = originalVariant.variantWarehouses.find(
            x => x.id === vw.id
          );
          if (Number(oldVW.quantity) !== vwparams.quantity) {
            await this.updateVariantWarehouse(vw.id, vwparams, variantPosition);
          }
        } else {
          vwparams.variantId = this.variants[variantPosition].id;
          vwparams.warehouseId = wId;
          await this.createVariantWarehouse(vwparams, variantPosition);
        }
      }
    },
    allVariantParams(newVariant) {
      if (this.isPack) {
        return {
          sku: newVariant.sku,
          barcode: newVariant.barcode,
          colorId: newVariant.color ? newVariant.color.value : null,
          sizeId: newVariant.size ? newVariant.size.value : null,
          position: newVariant.position,
          productsVariantsQuantities: newVariant.productsVariantsQuantities
        };
      } else {
        return {
          sku: newVariant.sku,
          barcode: newVariant.barcode,
          colorId: newVariant.color ? newVariant.color.value : null,
          sizeId: newVariant.size ? newVariant.size.value : null,
          position: newVariant.position
        };
      }
    },
    variantParams(position, originalVariant) {
      const newVariant = this.variants[position];
      newVariant.position = position;
      if (!originalVariant) {
        return this.allVariantParams(newVariant);
      }
      const changes = [
        { key: "sku", original: originalVariant.sku, current: newVariant.sku },
        {
          key: "barcode",
          original: originalVariant.barcode,
          current: newVariant.barcode
        },
        {
          key: "colorId",
          original: originalVariant.color ? originalVariant.color.id : null,
          current: newVariant.color ? newVariant.color.value : null
        },
        {
          key: "sizeId",
          original: originalVariant.size ? originalVariant.size.id : null,
          current: newVariant.size ? newVariant.size.value : null
        },
        {
          key: "position",
          original: originalVariant.position,
          current: position
        }
      ];
      if (this.isPack) {
        changes.push({
          key: "productsVariantsQuantities",
          original: originalVariant.productsVariantsQuantities,
          current: newVariant.productsVariantsQuantities
        });
      }
      return this.changes(changes);
    },
    async save() {
      if (!this.validate()) return;
      this.saving = true;
      this.saved = false;
      this.errorSaving = false;
      this.errorsSaving = {};
      if (this.consolidated) {
        await this.updateProduct();
      } else {
        await this.createProduct();
      }
      if (this.isPack) {
        const variantQuantity = [];
        this.packVariants.forEach(variant =>
          variantQuantity.push({
            variant: variant.id,
            quantity: Number(variant.quantity)
          })
        );
        this.variants[0].productsVariantsQuantities = variantQuantity;
      }
      const originalVariants = this.product.variants.map(x => x);

      for (let position = 0; position < this.variants.length; position++) {
        const originalVariant = originalVariants.find(
          x => x.id === this.variants[position].id
        );
        const vparams = this.variantParams(position, originalVariant);
        if (Object.keys(vparams).length === 0) {
          await this.saveVariantWarehouses(position, originalVariant);
          continue;
        }
        if (this.variants[position].id) {
          await this.updateVariant(this.variants[position].id, vparams);
        } else {
          vparams.productId = this.product.id;
          await this.createVariant(vparams);
        }
        await this.saveVariantWarehouses(position, originalVariant);
      }
      this.saving = false;
      this.saved = true;
      if (!this.errorSaving) {
        this.changed = false;
        this.$emit("change", false);
      }
    },
    packVariantsChange() {
      this.$refs.packChanges.maxStock(this.packVariants);
    },
    convertPack(isPack) {
      if (isPack && this.variants.length > 0) {
        this.variants.splice(1);
      }
    },
    messageAlert() {
      if (this.errorSaving) {
        let msg = ``;
        let sku_err = new Set();
        for (const [sku, errors] of Object.entries(this.errorsSaving)) {
          errors.forEach(error => {
            if (error.includes("SKU")) {
              sku_err.add(sku);
            }
          });
        }
        if (sku_err.size > 0) {
          msg += `Los siguientes SKU son usados por otra variante: ${Array.from(
            sku_err
          ).join(", ")}`;
        }
        return msg ? msg : "Ha ocurrido un error";
      } else if (this.consolidated) {
        return "El producto ha sido actualizado exitosamente";
      } else {
        return "El producto ha sido creado exitosamente";
      }
    }
  },
  watch: {
    name() {
      this.change();
    },
    description() {
      this.change();
    },
    isFullproductname() {
      this.change();
    },
    category() {
      this.change();
    },
    brand() {
      this.change();
    },
    gender() {
      this.change();
    },
    condition() {
      this.change();
    },
    season() {
      this.change();
    },
    status() {
      this.change();
    },
    seasonyear() {
      this.change();
    },
    sizeChart() {
      this.change();
    },
    shortdescription() {
      this.change();
    },
    price() {
      this.change();
    },
    priceCompare() {
      this.change();
    },
    salestartdate() {
      this.change();
    },
    saleenddate() {
      this.change();
    },
    packageheight() {
      this.change();
    },
    packagewidth() {
      this.change();
    },
    packagelength() {
      this.change();
    },
    packageweight() {
      this.change();
    },
    deliverytimesupplier() {
      this.change();
    },
    sku() {
      this.change();
    },
    barcode() {
      this.change();
    },
    seoTitle() {
      this.change();
    },
    seoDescription() {
      this.change();
    },
    variants() {
      this.change();
    },
    product_type() {
      this.change();
    },
    packVariants() {
      this.change();
      this.$refs.packChanges.maxStock(this.packVariants);
    }
  }
};
</script>
