<template>
  <div>
    <div v-if="variants && variants.length">
      <b-row align-h="end" class="mb-2">
        <b-button
          @click="downloadQrs"
          title="Descarga los códigos QR de todas las variantes en formato PDF"
        >
          <span v-if="loadingQr"><b-spinner label="Spinning"></b-spinner></span>
          <span v-else>
            <b-icon icon="download" class="mr-2" />Descargar códigos QR
          </span>
        </b-button>
      </b-row>
      <b-alert
        v-if="success"
        variant="success"
        :show="dismissCountDown"
        dismissible
        @dismiss-count-down="countDownChanged"
        @dismissed="success = false"
      >
        Variante actualizada correctamente</b-alert
      >
      <variants-pack-table
        v-if="product._type == 'Products::ProductPack'"
        :variants="variants"
        :productId="product.id"
        @delete-variants="deleteVariants"
      />
      <variants-table
        v-else
        :variants="variants"
        @delete-variants="deleteVariants"
        @change-variants="changeVariants"
        :key="forceRender"
        :number-success="deleteSuccess"
      />
    </div>
    <div v-else>
      El producto no cuenta con variantes
      <b-button
        size="sm"
        variant="outline-info"
        :to="{ name: 'ProductEdit', params: { id: product.id } }"
      >
        <b-icon-plus-circle /> Agregar variantes
      </b-button>
    </div>
  </div>
</template>
<script>
import VariantsTable from "./VariantsTable.vue";
import VariantsPackTable from "./VariantsPackTable.vue";
import DOWNLOAD_QRCODES from "../../../graphql/Product/Inventory/DownloadQrcodes.gql";
export default {
  name: "ProductInventoryPage",
  components: {
    VariantsTable,
    VariantsPackTable
  },
  props: {
    product: Object
  },
  data() {
    return {
      variants: this.$dup(this.product.variants),
      loadingQr: false,
      forceRender: 0,
      deleteSuccess: 0,
      success: false,
      dismissCountDown: 3
    };
  },
  methods: {
    /**
     * Se encarga de actualizar las variantes cuando se elimina una
     * @param {Array} variants - nuevo array de variantes
     */
    deleteVariants(variants) {
      this.variants = this.$dup(variants);
      this.deleteSuccess += 1;
    },
    /**
     * Actualiza los variantWareohuses de una variante
     * @param {Array} variantWarehouses - nuevo array de variantWarehouses
     * @param {Number} index - indice de la variante a a ctualizar
     */
    changeVariants(variantWarehouses, index) {
      this.variants[index].variantWarehouses = this.$dup(variantWarehouses);
      this.forceRender += 1;
      this.success = true;
    },
    /**
     * Se encarga de obtener el archivo pdf con los codigos qr para descargar
     */
    downloadQrs() {
      this.loadingQr = true;
      this.$apollo
        .query({
          query: DOWNLOAD_QRCODES,
          variables: { productId: this.product.id }
        })
        .then(({ data }) => {
          let newQr = data.downloadQrcodes;
          newQr.content_base_64 = data.downloadQrcodes.contentBase64;
          newQr.content_type = data.downloadQrcodes.contentType;
          this.$downloadBASE64([data.downloadQrcodes]);
          this.loadingQr = false;
        });
    },
    /**
     * Actualiza el valor actual del contador de la alerta
     * @param {Number} dismissCountDown - segundo que le quedan a la alerta
     */
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    }
  }
};
</script>
