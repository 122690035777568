<template>
  <div class="container">
    <form action="">
      <div class="enablescroller">
        <label class="label">Tipo de modelo</label>
        <v-select :options="myOptions" v-model="model_type" required></v-select>
      </div>

      <div class="row">
        <div class="column">
          <autoextra :collection="maps" v-slot="{ item, last, index }">
            <div class="columns is-mobile" :class="{ last: last }">
              <div class="field column">
                <div class="control">
                  <label class="label"
                    >{{ last ? "Nuevo " : "" }}Campo de Centry
                    {{ !last ? index + 1 : "" }}</label
                  >
                  <input
                    class="input"
                    type="text"
                    v-model="item.centryField"
                    required
                  />
                </div>
              </div>
              <div class="field column">
                <label class="label">Campo del modelo a integrar</label>
                <div class="control">
                  <input
                    class="input"
                    type="text"
                    v-model="item.integrationField"
                  />
                </div>
              </div>
            </div>
          </autoextra>
        </div>

        <div class="column">
          <pre>{{ maps }}</pre>
        </div>
      </div>

      <b-button-group size="sm">
        <b-button
          homologationMap="outline-info"
          class="button "
          :to="
            '/integracion/' +
              this.$route.params.integrationConfigId +
              '/translation_map/'
          "
        >
          <b-icon-arrow-return-left></b-icon-arrow-return-left> Volver
        </b-button>

        <b-button
          homologationMap="info"
          :disabled="saving"
          :title="'Enviar'"
          v-b-tooltip.hover=""
          class="button is-danger"
          @click="save"
        >
          <span v-if="saving"><b-spinner label="Spinning"></b-spinner></span>
          <span v-else><b-icon-cloud-upload></b-icon-cloud-upload>Guardar</span>
        </b-button>
      </b-button-group>
    </form>
  </div>
</template>

<script>
import Autoextra from "vue-autoextra";
import { createHomologationMap } from "@/main";

export default {
  name: "TranslationCentryIntegrationNew",
  data: () => {
    return {
      maps: [
        { centryField: "buyer_dni", integrationField: "billing_address.zip" }
      ],
      model_type: "",
      myOptions: ["Products::Order"],
      saving: false,
      changed: false,
      saved: false,
      errorSaving: false,
      formErrors: false
    };
  },
  components: {
    Autoextra
  },
  methods: {
    createHomologationMap(params, model_type, integration_config_id) {
      createHomologationMap(
        this.$apollo,
        params,
        model_type,
        integration_config_id
      )
        .then(({ data }) => {
          if (
            data &&
            data.createHomologationMap &&
            data.createHomologationMap.homologation_map
          ) {
            this.$router.push(
              "/integracion/" +
                this.$route.params.integrationConfigId +
                "/translation_map/"
            );
          }
        })
        .catch(() => {
          this.errorSaving = true;
        });
    },
    cancel() {
      this.$router.push(
        "/integracion/" +
          this.$route.params.integrationConfigId +
          "/translation_map/"
      );
    },
    save() {
      // if (!this.validate()) return;
      this.saving = true;
      this.errorSaving = false;

      this.createHomologationMap(
        this.maps,
        this.model_type,
        this.$route.params.integrationConfigId
      );

      this.saving = false;
      this.saved = true;
      this.$router.push(
        "/integracion/" +
          this.$route.params.integrationConfigId +
          "/translation_map/"
      );

      if (!this.errorSaving) {
        this.changed = false;
        this.$emit("change", false);
      }
    }
  }
};
</script>

<style scoped>
/* @import "https://cdnjs.cloudflare.com/ajax/libs/bulma/0.7.5/css/bulma.css"; */

.last {
  opacity: 0.3;
}
</style>
