<template>
  <div id="icon-background" />
</template>

<script>
export default {
  name: "FreshdeskChat",
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  mounted() {
    this.initializeChat(document, "Freshchat-js-sdk");
  },
  methods: {
    /**
     * Inicializa el chat de freshdesk
     */
    initFreshChat() {
      window.fcWidget.init({
        token: "60d7792d-4031-4b84-ab13-865b553e42d9",
        host: "https://anymarketlatam-support.freshchat.com",
        widgetUuid: "39b63076-1bf8-4003-a886-e31a88ddcd2d",
        externalId: this.user.company.name,
        email: this.user.email,
        firstName: this.user.firstName
      });
    },
    /**
     * Crea el tag de chat de freshdesk
     * y lo inicializa
     * @param {Object} i (parent object)
     * @param {String} t (tag id)
     */
    initializeChat(i, t) {
      var e;
      i.getElementById(t)
        ? this.initFreshChat()
        : (((e = i.createElement("script")).id = t),
          (e.async = !0),
          (e.src = "https://anymarketlatam-support.freshchat.com/js/widget.js"),
          (e.onload = this.initFreshChat),
          i.head.appendChild(e));
    }
  }
};
</script>

<style scoped>
@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

#icon-background {
  position: fixed;
  bottom: 20px;
  right: 20px;
  border-radius: 34px 8px 34px 34px;
  box-shadow: 0 5px 4px 0 rgba(0, 0, 0, 0.26);
  cursor: pointer;
  height: 60px;
  width: 60px;

  background-color: #8937df !important;
  color: #ffffff !important;
  border-color: transparent #8937df transparent transparent;

  animation: heartbeat 1s infinite;
}
</style>
