<template>
  <b-tr>
    <b-td
      ><b-icon
        icon="exclamation-circle-fill"
        :variant="errorCreate ? 'danger' : 'warning'"
        v-b-tooltip.hover="this.errorCreateInfo"
        v-if="(errorCreate || errorWarning) && !loadingUpdate"
      ></b-icon>
      <b-spinner label="Spinning" v-else-if="loadingUpdate"></b-spinner>
      <b-icon-check variant="success" v-else-if="beenUpdated"></b-icon-check
    ></b-td>
    <b-td>
      {{ order.numberOrigin ? order.numberOrigin : order.idOrigin }}
    </b-td>
    <b-td>
      <b-form-input v-model="code" :disabled="initialCode ? true : false">
      </b-form-input>
    </b-td>
    <b-td>
      <v-select
        id="status"
        :options="statusOptions"
        placeholder="Selecciona un estado"
        v-model="status"
        :reduce="s => s.value"
        :disabled="delivered"
      ></v-select
    ></b-td>
    <b-td>
      <b-form-input v-model="comment" :disabled="delivered"></b-form-input
    ></b-td>
  </b-tr>
</template>

<script>
import ALL_ORDER_TRACKINGS from "../../../graphql/AllOrderTrackings.gql";
import CREATE_ORDER_TRACKING from "../../../graphql/CreateOrderTracking.gql";
import CREATE_ORDER_TRACKING_STATUS from "../../../graphql/CreateOrderTrackingStatus.gql";
export default {
  name: "Me1TableRow",

  props: {
    order: Object
  },
  data() {
    return {
      loadingUpdate: false,
      code: "",
      status: "",
      initialCode: "",
      initialStatus: "",
      statusOptions: [
        { label: "Enviado", value: "shipped" },
        { label: "No entregado", value: "not_delivered" },
        { label: "Entregado", value: "delivered" }
      ],
      comment: "",
      trackingStatuses: [],
      trackingId: "",
      errorCreate: false,
      errorWarning: false,
      errorCreateInfo: "",
      beenUpdated: false
    };
  },
  mounted() {
    this.getTracking();
  },
  methods: {
    /**
     * Verifica si el tracking puede ser actualizado
     * Ya sea se cambie el estado o si este ya esta entregado
     */
    isNotUpdateable() {
      let delivered = this.delivered;
      return delivered || this.status == this.initialStatus || !this.code;
    },
    /**
     * Actualiza el status del tracking de una orden
     * si es que se permite hacerlo
     */
    async updateTracking() {
      this.errorCreate = false;
      this.errorWarning = false;
      this.beenUpdated = false;
      this.loadingUpdate = true;
      if (this.isNotUpdateable()) {
        this.loadingUpdate = false;
        if (!this.delivered && this.hasValues) {
          this.errorWarning = true;
          this.errorCreateInfo = "Código de seguimiento y estado obligatorios";
        }
        return;
      }
      await this.createTrackingStatus();
      this.beenUpdated = true;
      this.loadingUpdate = false;
    },
    /**
     * Crea el tracking en Centry asociado a la orden y el code enviado
     */
    async createTracking() {
      let create = {
        orderId: this.order.id,
        code: this.code
      };
      await this.$apollo
        .mutate({
          mutation: CREATE_ORDER_TRACKING,
          variables: {
            create: create
          }
        })
        .then(result => {
          let tracking = result.data.createOrderTracking.tracking;
          this.trackingId = tracking.id;
        });
    },
    /**
     * Actualiza el estado del tracking de una orden
     */
    async createTrackingStatus() {
      if (!this.trackingId) {
        await this.createTracking();
      }
      let create = {
        trackingId: this.trackingId,
        comment: this.comment,
        trackingStatus: this.status,
        subStatus:
          this.trackingStatus == "not_delivered" ? "returning_to_sender" : ""
      };
      await this.$apollo
        .mutate({
          mutation: CREATE_ORDER_TRACKING_STATUS,
          variables: {
            create: create
          }
        })
        .then(result => {
          let valid = result.data.createOrderTrackingStatus.result;
          if (valid) {
            this.getTracking();
          } else {
            this.errorCreate = true;
            this.errorCreateInfo = result.data.createOrderTrackingStatus.errors;
          }
        })
        .catch(err => {
          this.errorCreate = true;
          this.errorCreateInfo = err;
        });
    },
    /**
     * Obtiene la informacion de tracking asociado a una orden
     */
    async getTracking() {
      this.$apollo
        .query({
          query: ALL_ORDER_TRACKINGS,
          variables: {
            orderId: this.order.id
          },
          fetchPolicy: "no-cache"
        })
        .then(result => {
          let tracking = result.data.allOrderTrackings.edges;
          if (tracking.length > 0) {
            this.trackingId = tracking[0].node.id;
            this.code = tracking[0].node.code;
            this.initialCode = this.$dup(this.code);
            this.status = tracking[0].node.trackingStatus;
            this.initialStatus = this.$dup(this.status);
            this.trackingStatuses = tracking[0].node.trackingStatuses;
          }
        });
    }
  },
  computed: {
    delivered() {
      return (
        this.$ifNull(this.trackingStatuses[0], {}).trackingStatus == "delivered"
      );
    },
    hasValues() {
      return this.code != "" || this.status != "" || this.comment != "";
    }
  }
};
</script>
