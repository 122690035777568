<template>
  <div>
    <b-row>
      <b-col>
        <b-input-group>
          <b-input-group-prepend is-text>
            <b-icon-search></b-icon-search>
          </b-input-group-prepend>
          <b-form-tags
            v-model="keywords"
            separator=","
            remove-on-delete=""
            placeholder="Palabras claves"
          ></b-form-tags>
        </b-input-group>
      </b-col>
    </b-row>

    <br />

    <b-spinner v-if="loading" label="Spinning"></b-spinner>
    <b-table-simple v-else-if="specificationValuesList">
      <b-thead>
        <b-tr>
          <b-th class="col-md-5"> Valor </b-th>
          <b-th class="col-md-2"> Id </b-th>
          <b-th class="col-md-2"> Activo </b-th>
          <b-th class="col-md-2"> Posición </b-th>
          <b-th class="col-md-1"> Acciones </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr
          v-for="specificationValue in actualSpecificationValuesList"
          :key="specificationValue.fieldValueId"
        >
          <b-td> {{ specificationValue.value }} </b-td>
          <b-td> {{ specificationValue.fieldValueId }} </b-td>
          <b-td> {{ specificationValue.isActive }} </b-td>
          <b-td> {{ specificationValue.position }} </b-td>
          <b-td>
            <b-dropdown variant="white" no-caret="" offset="-110">
              <template v-slot:button-content>
                <b-icon-three-dots-vertical></b-icon-three-dots-vertical>
              </template>
              <b-dropdown-item
                @click="
                  openSpecificationValuesModal(specificationValue.fieldValueId)
                "
              >
                Editar
              </b-dropdown-item>
            </b-dropdown>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>

    <b-row align-h="center">
      <b-pagination
        v-model="page"
        :total-rows="specificationValuesList.length"
        :per-page="pageSize"
        @change="changePage"
      ></b-pagination>
    </b-row>
  </div>
</template>

<script>
import INTEGRATION_CONFIG_VTEX_GET_SPECIFICATION_VALUES from "@/graphql/IntegrationConfigVtexGetSpecificationValues.gql";
import INTEGRATION_CONFIG_VTEX_GET_SPECIFICATION from "@/graphql/IntegrationConfigVtexGetSpecification.gql";

const COMBO_FIELD_TYPE_ID = 5;
const RADIO_FIELD_TYPE_ID = 6;
const fieldTypesName = {
  1: "Texto",
  2: "Texto Multi-Linea",
  4: "Número",
  7: "CheckBox",
  8: "Texto Indexado",
  9: "Texto Indexado Multi-Linea"
};

export default {
  name: "VtexSpecificationValuesTable",
  props: {
    vtexIntegrationConfigId: {
      type: String,
      required: true
    },
    specificationId: {
      required: true,
      validator: prop => typeof prop === "string" || prop === null
    }
  },
  data() {
    return {
      loading: false,
      pageSize: 10,
      page: 1,
      specificationValuesList: [],
      actualSpecificationValuesList: null,
      keywords: []
    };
  },
  mounted() {
    if (this.specificationId) this.getSpecification();
  },
  methods: {
    /**
     * Realiza la petición para obtener una especificación en Vtex.
     */
    async getSpecification() {
      this.loading = true;
      await this.$apollo
        .query({
          query: INTEGRATION_CONFIG_VTEX_GET_SPECIFICATION,
          variables: {
            id: this.vtexIntegrationConfigId,
            specificationId: this.specificationId
          }
        })
        .then(async ({ data }) => {
          if (!data?.integrationConfigVtex?.getSpecification) {
            this.emitErrorMessage(
              "No se pudo verificar si la especificación acepta valores."
            );
          } else {
            let specification = data.integrationConfigVtex.getSpecification;
            let fieldTypeId = specification.fieldTypeId;
            if (
              fieldTypeId != COMBO_FIELD_TYPE_ID &&
              fieldTypeId != RADIO_FIELD_TYPE_ID
            ) {
              let message = "Esta especificación no acepta valores. ";
              message += `La especificación actual es de tipo ${fieldTypesName[fieldTypeId]} `;
              message += "y solo las de tipo Combo o Radio permiten valores.";
              this.emitWarningMessage(message);
              this.emitHideButtonAndTable();
            } else {
              await this.getSpecificationValues();
            }
          }
        });
      this.loading = false;
    },
    /**
     * Realiza la petición para obtener los valores de una especificación en Vtex.
     */
    async getSpecificationValues() {
      this.loading = true;
      await this.$apollo
        .query({
          query: INTEGRATION_CONFIG_VTEX_GET_SPECIFICATION_VALUES,
          variables: {
            id: this.vtexIntegrationConfigId,
            specificationId: this.specificationId,
            keywords: this.keywords.length > 0 ? this.keywords : null
          }
        })
        .then(async ({ data }) => {
          if (!data?.integrationConfigVtex?.specificationValuesList) {
            this.emitErrorMessage(
              "No se pudo obtener el listado de valores de la especificación."
            );
          } else {
            this.specificationValuesList =
              data.integrationConfigVtex.specificationValuesList;
            this.changePage(1);
          }
        })
        .catch(() => {
          this.emitErrorMessage(
            "No se pudo obtener el listado de valores de la especificación."
          );
        });
      this.loading = false;
    },
    /**
     * Cambia la pagina del listado de valores de especificación.
     * @param {Integer} page
     */
    changePage(page) {
      this.page = page;
      let offset = (page - 1) * this.pageSize;
      this.actualSpecificationValuesList = this.specificationValuesList.slice(
        offset,
        offset + this.pageSize
      );
    },
    /**
     * Emite el evento 'errorMessage' con el mensaje de error correspondiente
     * @param {String} errorMessage
     */
    emitErrorMessage(errorMessage) {
      this.$emit("errorMessage", errorMessage);
    },
    /**
     * Emite el evento 'warningMessage' con la advertencia correspondiente
     * @param {String} warningMessage
     */
    emitWarningMessage(warningMessage) {
      this.$emit("warningMessage", warningMessage);
    },
    /**
     * Emite el evento 'openSpecificationValuesModal' con el id del valor de
     * especificación informado.
     * @param {String} specificationValueId
     */
    openSpecificationValuesModal(specificationValueId) {
      this.$emit("openSpecificationValuesModal", specificationValueId);
    },
    /**
     * Emite el evento 'setHideButtonAndTable' para ocultar los componentes
     * de la vista.
     */
    emitHideButtonAndTable() {
      this.$emit("setHideButtonAndTable", true);
    }
  },
  watch: {
    specificationId: function(newSpecificationId) {
      if (newSpecificationId !== null) this.getSpecification();
    },
    keywords: function() {
      this.getSpecificationValues(this.specificationId);
    }
  }
};
</script>
