<template>
  <div>
    <base-header
      title="Seleccionar país "
      title-link="/highlights"
      :map="['Mercado Libre', 'Productos más vendidos']"
      :title-size="9"
    >
    </base-header>
    <ApolloQuery :query="require('../graphql/MercadoLibreGetCountries.gql')">
      <template slot-scope="{ result: { data } }">
        <b-spinner label="Spinning" v-if="!data"></b-spinner>
        <b-row v-else>
          <b-col
            v-if="redirect(data['mercadoLibreGetCountries'])"
            md="12"
            class="border-right"
          >
            <h4>Seleccionar país de Mercado Libre</h4>
            <td></td>
            <td></td>
            <b-list-group
              v-for="(label, id) of data['mercadoLibreGetCountries']"
              :key="id"
              :title="label"
              align="center"
            >
              <b-list-group-item
                :to="{
                  name: 'MercadoLibreHighlightsBySite',
                  params: {
                    siteId: label[0]
                  }
                }"
                class="flex-column col-md-2"
              >
                <div class="card-image">
                  <img :src="getImageUrl(label[1])" style="width:100px" />
                </div>
                <td></td>
                <div class="w-100">
                  <h3 class="mb-1">{{ label[1] }}</h3>
                </div>
              </b-list-group-item>
              <td></td>
              <td></td>
            </b-list-group>
          </b-col>
          <b-col v-else md="12" class="border-right">
            <h4>No posee integraciones de Mercadolibre</h4>
          </b-col>
        </b-row>
      </template>
    </ApolloQuery>
  </div>
</template>

<script>
import BaseHeader from "@/components/BaseHeader.vue";
//import MercadoLibreGetCountries from "@/graphql/MercadoLibreGetCountries.gql"
export default {
  name: "MercadoLibreHighlihts",
  components: {
    BaseHeader
  },
  methods: {
    redirect(countries) {
      if (countries.length > 1) {
        return true;
      } else if (countries.length == 1) {
        this.$router.push("/mercadolibre/highlights/" + countries[0][0]);
      } else {
        return false;
      }
    },
    getImageUrl(country) {
      var images = require.context("../assets/images/", false, /\.png$/);
      return images("./FlagOf" + country + ".png");
    }
  }
};
</script>
