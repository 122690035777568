<template>
  <div>
    <base-alert-with-count-down
      v-model="showAlert"
      :alert-variant="alertVariant"
    >
      {{ alertMessage }}
    </base-alert-with-count-down>
    <b-row class="my-2">
      <b-col xl="10" cols="12">
        <div class="mb-3">
          <h4 class="font-weight-bold my-auto">Datos</h4>
          <hr />
          <company-basic-info-form
            ref="company-info-edit"
            :company="company"
            @change="handleChange"
          />
        </div>
        <beta-sections
          v-if="currentUser.role == 0"
          :beta-sections-selected="betaSectionsSelected"
          @change="val => (betaSectionsSelected = val)"
        />
      </b-col>
      <b-col xl="2" cols="12">
        <b-button
          variant="info"
          :disabled="!changes"
          @click="save"
          class="mb-3 w-100"
        >
          Guardar <b-spinner v-if="savingCompany || savingBetaSections" small />
        </b-button>
        <b-button
          variant="outline-info"
          class="w-100 px-0"
          :disabled="!changes"
          @click="resetData()"
        >
          <span>Restablecer</span>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import CompanyBasicInfoForm from "../BasicInfoForm.vue";
import BetaSections from "./BetaSections.vue";
import BaseAlertWithCountDown from "../../Base/BaseAlertWithCountDown.vue";
import { mapState, mapMutations } from "vuex";
import UPDATE_COMPANY from "../../../graphql/Company/UpdateCompany.gql";
import UPDATE_BETA_SECTIONS from "../../../graphql/Company/UpdateBetaSections.gql";
export default {
  name: "CompanyEditPage",
  components: {
    CompanyBasicInfoForm,
    BetaSections,
    BaseAlertWithCountDown
  },
  props: {
    company: Object
  },
  data() {
    return {
      savingCompany: false,
      savingBetaSections: false,
      originalBetaSectionsSelected: [],
      betaSectionsSelected: [],
      originalValues: {
        name: this.company.name,
        mainWarehouseId: this.company.mainWarehouse.id,
        timeZone: this.company.timeZone
      },
      newValues: {
        name: this.company.name,
        mainWarehouseId: this.company.mainWarehouse.id,
        timeZone: this.company.timeZone
      },
      showAlert: false,
      error: false,
      errorMessage: ""
    };
  },
  computed: {
    ...mapState(["currentUser"]),
    changeBetaSections() {
      return !this.$arrayDeepCompare(
        this.originalBetaSectionsSelected,
        this.betaSectionsSelected
      );
    },
    changeValues() {
      return !this.$objDeepCompare(this.originalValues, this.newValues);
    },
    changes() {
      return this.changeBetaSections || this.changeValues;
    },
    alertVariant() {
      return this.error ? "danger" : "success";
    },
    alertMessage() {
      if (!this.error) {
        return "El perfil de tu empresa ha sido actualizado con éxito.";
      } else {
        return `Ha ocurrido un error actualizando el perfil de tu empresa: ${this.errorMessage}`;
      }
    }
  },
  created() {
    this.updateBetaSectionsSelected();
  },
  methods: {
    ...mapMutations(["setCurrentUser"]),
    /**
     * Maneja el cambio cuando se cambia un valro del formulario
     * @param {String} label
     * @param {String} value
     */
    handleChange(label, value) {
      this.newValues[label] = value;
    },
    /**
     * Se encarga de ver que cambios ocurrieron para enviar las mutaciones
     */
    save() {
      this.softResetData();
      if (this.changeValues) {
        this.updateCompany();
      }
      if (this.changeBetaSections) {
        this.updateBetaSections();
      }
    },
    /**
     * Envia la mutacion para actualizar la informacion de la empresa
     */
    updateCompany() {
      this.savingCompany = true;
      this.$apollo
        .mutate({
          mutation: UPDATE_COMPANY,
          variables: { input: this.newValues }
        })
        .then(({ data }) => {
          if (data.updateCompany.result) {
            this.originalValues = this.$dup(this.newValues);
            this.company.name = this.newValues.name;
            this.company.timeZone = this.newValues.timeZone;
            this.currentUser.company.warehouses.forEach(wh => {
              if (wh.id == this.newValues.mainWarehouseId) {
                this.company.mainWarehouse = wh;
              }
            });
            this.savingCompany = false;
          } else {
            this.error = true;
            this.errorMessage += data.updateCompany.errors;
          }
          this.showAlert = true;
        });
    },
    /**
     * Envia la mutacion para actualizar las secciones beta
     */
    updateBetaSections() {
      this.savingBetaSections = true;
      this.$apollo
        .mutate({
          mutation: UPDATE_BETA_SECTIONS,
          variables: { sections: this.betaSectionsSelected }
        })
        .then(({ data }) => {
          if (data.updateBetaSections.result) {
            this.originalBetaSectionsSelected = this.$dup(
              this.betaSectionsSelected
            );
            this.updateCurrentUser();

            this.savingBetaSections = false;
          } else {
            this.error = true;
            this.errorMessage += data.updateBetaSections.errors;
          }
          this.showAlert = true;
        });
    },
    /**
     * Resetea los valores de la empresa y las secciones beta
     */
    resetData() {
      this.betaSectionsSelected = this.originalBetaSectionsSelected;
      this.$refs["company-info-edit"].setValues();
    },
    /**
     * Resetea los valores de las variables encargadas de mostrar la alerta
     */
    softResetData() {
      this.error = false;
      this.errorMessage = "";
    },
    /**
     * Actualiza el listado de secciones betas seleccioandas
     */
    updateBetaSectionsSelected() {
      let betaSections = this.currentUser.company.betaSectionsAllowed;
      this.originalBetaSectionsSelected = betaSections.map(bs => bs.section);
      this.betaSectionsSelected = this.$dup(this.originalBetaSectionsSelected);
    },
    /**
     * Actualiza la informacion de las secciones betas del currentUser
     */
    updateCurrentUser() {
      let tempUser = this.$dup(this.currentUser);
      tempUser.company.betaSectionsAllowed = this.betaSectionsSelected.map(
        b => {
          return { section: b };
        }
      );
      this.setCurrentUser(tempUser);
    }
  },
  watch: {
    currentUser: {
      handler() {
        this.updateBetaSectionsSelected();
      },
      deep: true
    }
  }
};
</script>
