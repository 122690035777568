<template>
  <b-spinner
    label="Spinning"
    v-if="loadingPurposes || loadingBrands || loadingTable"
  ></b-spinner>

  <div v-else>
    <base-homologation-table
      :key="id"
      :columns="columns"
      :rows="rows"
      :purposes-index-rev="purposesIndexRev"
      :homologations-ids-rev="brandsIdsRev"
      :homologation-index="homologationIndex"
      :mutation="mutation"
      mutation-name="saveVtexHomologation"
      model-name="Products::Brand"
      :all-values-father="this.allValues"
      @all-values="allValuesChange"
    ></base-homologation-table>
  </div>
</template>

<script>
import ALL_BRANDS from "@/graphql/AllBrands.gql";
import ALL_VTEX_PURPOSES from "@/graphql/AllVtexPurposes.gql";
import ALL_VTEX_BRANDS from "@/graphql/AllVtexBrands.gql";
import SAVE_VTEX_HOMOLOGATION from "@/graphql/SaveVtexHomologation.gql";
import BaseHomologationTable from "@/components/BaseHomologationTable";
import { mapState } from "vuex";

export default {
  name: "VtexHomologationBrands",
  components: { BaseHomologationTable },
  computed: {
    ...mapState(["currentUser"])
  },
  data() {
    return {
      purposes: [],
      brands: [],
      rows: [],
      columns: [
        {
          type: "text",
          title: "Marca Centry",
          width: 300,
          readOnly: true
        }
      ],
      mutation: SAVE_VTEX_HOMOLOGATION,
      purposesIndex: {},
      purposesIndexRev: {},
      homologationIndex: {},
      homologationValues: {},
      loadingBrands: true,
      loadingPurposes: true,
      loadingTable: true,
      brandsIds: {},
      brandsIdsRev: {},
      available: false,
      allValues: false,
      id: 0
    };
  },
  async mounted() {
    await this.getBrands();
    await this.getVtexPurposes();
    await this.prepareColumns();
    await this.prepareRows();
    this.loadingTable = false;
  },
  methods: {
    /**
     * Obtiene las marcas de Centry usadas por la empresa del
     * usuario o todas las existentes en Centry.
     */
    async getBrands() {
      this.loadingBrands = true;
      await this.$getAllPages(
        ALL_BRANDS,
        {
          companyId: this.allValues ? "" : this.currentUser.company.id
        },
        "allBrands"
      ).then(array => {
        this.brands = array;
        this.loadingBrands = false;
      });
    },
    /**
     * Obtiene los purposes de Vtex que sean de marca
     * e indica que ya cargó esta parte.
     */
    async getVtexPurposes() {
      await this.$apollo
        .query({
          query: ALL_VTEX_PURPOSES,
          variables: {
            model: "product_brand"
          }
        })
        .then(({ data }) => {
          this.purposes = data.allVtexPurposes.edges;
          this.purposes.forEach(purpose => {
            this.homologationValues[purpose.node.id] = {};
            purpose.node.vtexHomologations.forEach(hv => {
              this.homologationValues[purpose.node.id][hv.vtexHomologableId] =
                hv.v;
            });
          });
          this.loadingPurposes = false;
        });
    },
    /**
     * Prepara las columnas de la tabla trayendo las
     * opciones desde Vtex si corresponde.
     */
    async prepareColumns() {
      let indexes = {};
      let indexesRev = {};
      let i = 0;
      for (let p of this.purposes) {
        let icId = p.node.integrationConfig.id;
        let options = await this.getBrandsFromVtex(icId, p.node.id);
        let column = {
          type: p.node.dataType,
          title: p.node.integrationConfig.label + ": " + p.node.specName,
          width: 300,
          source: options
        };
        this.columns.push(column);
        indexes[p.node.id] = i + 1;
        indexesRev[i + 1] = p.node.id;
        i += 1;
      }
      this.purposesIndex = indexes;
      this.purposesIndexRev = indexesRev;
    },
    /**
     * Prepara las filas obteniendo los datos ya rellenados
     * para las columnas correspondientes.
     */
    prepareRows() {
      const indexes = this.purposesIndex;
      const purposesLength = Object.keys(indexes).length;
      let rows = [];
      this.brands.forEach(brand => {
        let row = [];
        row[0] = brand.node.name;
        this.homologationIndex[brand.node.name] = brand.node.id;
        for (let i = 1; i <= purposesLength; i++) {
          row[i] = "";
        }
        this.purposes.forEach(purpose => {
          let index = indexes[purpose.node.id];
          let value = this.homologationValues[purpose.node.id][brand.node.id];
          if (value) {
            row[index] = this.brandsIds[purpose.node.id][value] || value;
          }
        });
        rows.push(row);
      });
      this.rows = rows;
    },
    /**
     * Obtiene las opciones de una especificación
     * en Vtex
     */
    async getBrandsFromVtex(icId, purposeId) {
      return await this.$apollo
        .query({
          query: ALL_VTEX_BRANDS,
          variables: {
            ic: icId,
            limit: 100,
            offset: 0
          }
        })
        .then(({ data }) => {
          let options = [];
          this.brandsIds[purposeId] = {};
          this.brandsIdsRev[purposeId] = {};
          data.allVtexBrands.forEach(b => {
            options.push(b.name);
            this.brandsIdsRev[purposeId][b.name] = b.id;
            this.brandsIds[purposeId][b.id] = b.name;
          });
          return options;
        });
    },
    /**
     * Actualiza los valores de la tabla cuando
     * hay un cambio.
     */
    async allValuesChange(value) {
      this.allValues = value;
      await this.getBrands();
      await this.prepareRows();
      this.id = this.id += 1;
    }
  }
};
</script>
