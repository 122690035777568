<template>
  <div>
    <b-row>
      <b-col>
        <b-form-checkbox v-model="activeUtmSource" :disabled="true">
          Aplicar utm_source
        </b-form-checkbox>

        <v-select
          v-model="utmSource"
          :options="utmSourceOptions"
          :disabled="true"
        />

        <b-form-checkbox v-model="activeUtmCampaign" :disabled="true">
          Aplicar utm_campaign
        </b-form-checkbox>

        <v-select
          v-model="utmCampaign"
          :options="utmCampaignOptions"
          :disabled="true"
        />
      </b-col>

      <b-col v-if="promotion.type === 'regular'">
        <b-form-checkbox v-model="activeUtmiCampaign" :disabled="true">
          Aplicar utmi_campaign
        </b-form-checkbox>

        <v-select
          v-model="utmiCampaign"
          :options="utmiCampaignOptions"
          :disabled="true"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "VtexBasePromotionUtmAndUtmi",
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      promotion: this.value,
      utmSource: this.value.utmSource,
      activeUtmSource: this.value.utmSource !== null,
      utmSourceOptions: [],
      utmCampaign: this.value.utmCampaign,
      activeUtmCampaign: this.value.utmCampaign !== null,
      utmCampaignOptions: [],
      utmiCampaign: this.value.utmiCampaign,
      activeUtmiCampaign: this.value.utmiCampaign !== null,
      utmiCampaignOptions: []
    };
  },
  watch: {
    value(newValue) {
      this.promotion = newValue;
    },
    promotion: {
      handler(newValue) {
        this.$emit("input", newValue);
      },
      deep: true
    }
  }
};
</script>
