<template>
  <b-card>
    <b-row>
      <b-col class="col-md-2">
        <b-button
          variant="outline-secondary"
          class="mr-1 mb-1"
          v-b-tooltip.hover
          :title="`Ver en ${order.origin}`"
          :href="order.supplementedUrlOrigin"
          target="_blank"
        >
          <b-icon-eye />
        </b-button>
      </b-col>
      <b-col class="col-md-2" v-if="order.hasShippingLabel">
        <order-shipping-label-button
          :order="order"
        ></order-shipping-label-button>
      </b-col>
      <b-col class="col-md-2" v-if="order.isCancelable">
        <order-cancellation-modal :order="order"></order-cancellation-modal>
      </b-col>
      <b-col class="col-md-2" v-if="order.isConfirmable || order.isReadyToShip">
        <order-confirmation-modal :order="order"></order-confirmation-modal>
      </b-col>
      <b-col class="col-md-2" v-if="order.isReschedulable">
        <order-reschedule-modal :order="order"></order-reschedule-modal>
      </b-col>
      <b-col class="col-md-2" v-if="order.ownShipment">
        <order-tracking-modal :order="order"></order-tracking-modal>
      </b-col>
      <b-col class="col-md-2" v-else-if="order.isDeliveryConfirmable">
        <order-delivery-confirmation-modal
          :order="order"
        ></order-delivery-confirmation-modal>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import OrderDeliveryConfirmationModal from "@/components/order/OrderDeliveryConfirmationModal";
import OrderCancellationModal from "@/components/order/OrderCancellationModal";
import OrderConfirmationModal from "@/components/order/OrderConfirmationModal";
import OrderTrackingModal from "@/components/order/OrderTrackingModal";
import OrderShippingLabelButton from "@/components/order/OrderShippingLabelButton";
import OrderRescheduleModal from "@/components/order/OrderRescheduleModal";
export default {
  name: "OrderActionsCard",
  components: {
    OrderShippingLabelButton,
    OrderTrackingModal,
    OrderConfirmationModal,
    OrderDeliveryConfirmationModal,
    OrderCancellationModal,
    OrderRescheduleModal
  },
  props: {
    order: Object
  }
};
</script>
