var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ApolloQuery',{attrs:{"query":require('../graphql/Product.gql'),"variables":_vm.queryVariables(),"fetchPolicy":'cache-and-network',"notifyOnNetworkStatusChange":""},on:{"result":_vm.updateImageFilter},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.result.data;
var isLoading = ref.isLoading;
return [_c('base-header',{attrs:{"title":"Productos","title-link":"/products","titleSize":0,"map":[_vm.$route.meta.action, _vm.$dig(data, 'product', 'name')]},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(data && _vm.$dig(data, 'product', '_type') != 'new')?_c('b-container',[_c('b-dropdown',{attrs:{"variant":"outline-info","text":"Ver más"}},[(_vm.$route.meta.action !== 'Detalle')?_c('b-dropdown-item',{attrs:{"to":{
                  name: 'ProductDetail',
                  params: { id: data.product.id }
                }}},[_vm._v(" Detalle ")]):_vm._e(),(_vm.$route.meta.action !== 'Editar')?_c('b-dropdown-item',{attrs:{"to":("/product/" + (data.product.id) + "/edit")}},[_vm._v(" Editar ")]):_vm._e(),(_vm.$route.meta.action !== 'Administrador de imágenes')?_c('b-dropdown-item',{attrs:{"to":{
                  name: 'ProductImageManager',
                  query: { productId: data.product.id }
                }}},[_vm._v(" Imágenes ")]):_vm._e(),(_vm.$route.meta.action !== 'Inventario')?_c('b-dropdown-item',{attrs:{"to":'/product/' + data.product.id + '/inventory'}},[_vm._v(" Variantes ")]):_vm._e(),(_vm.$route.meta.action !== 'Movimientos')?_c('b-dropdown-item',{attrs:{"to":{
                  name: 'ProductTransactions',
                  params: { id: data.product.id }
                }}},[_vm._v(" Movimientos ")]):_vm._e(),(_vm.$route.meta.action !== 'Sincronizaciones')?_c('b-dropdown-item',{attrs:{"to":{
                  name: 'ProductHistories',
                  params: { id: data.product.id }
                }}},[_vm._v(" Sincronizaciones ")]):_vm._e(),_c('b-dropdown-item',{attrs:{"href":_vm.centryUrl + '/productos/' + data.product.id + '/duplicate'}},[_vm._v(" Duplicar ")])],1)],1):_vm._e()]},proxy:true},{key:"filters",fn:function(){return [(_vm.$route.meta.action === 'Movimientos')?_c('transactions-filters',{attrs:{"product":data.product}}):_vm._e(),(_vm.$route.meta.action === 'Administrador de imágenes')?_c('b-form-group',{attrs:{"content-cols":"3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var ariaDescribedby = ref.ariaDescribedby;
return [_c('products-filter',{attrs:{"aria-describedby":ariaDescribedby,"phrases":_vm.productFilters.phrases,"showAdvancedButton":false},on:{"update:phrases":function($event){return _vm.$set(_vm.productFilters, "phrases", $event)}}})]}}],null,true)}):_vm._e()]},proxy:true}],null,true)}),(isLoading)?_c('b-spinner',{staticClass:"m-2 float-right",attrs:{"label":"Spinning"}}):_vm._e(),_c('b-container',{attrs:{"fluid":""}},[(data && !isLoading)?_c('router-view',{attrs:{"product":data.product,"filters":_vm.productFilters}}):_vm._e()],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }