<template>
  <div>
    <ApolloQuery
      :query="
        gql => gql`
          query allOrders(
            $cursor: String
            $numbers: [String!]
            $ids: [ID!]
            $skus: [String!]
            $statuses: [String!]
            $origins: [String!]
            $createFrom: ISO8601DateTime
            $createTo: ISO8601DateTime
            $updateFrom: ISO8601DateTime
            $updateTo: ISO8601DateTime
            $shippingLabelPrint: Boolean
            $buyers: [String!]
          ) {
            allOrders(
              first: 30
              after: $cursor
              numbers: $numbers
              ids: $ids
              skus: $skus
              statuses: $statuses
              origins: $origins
              createFrom: $createFrom
              createTo: $createTo
              updateFrom: $updateFrom
              updateTo: $updateTo
              shippingLabelPrint: $shippingLabelPrint
              buyers: $buyers
            ) {
              edges {
                node {
                  id
                  origin
                  numberOrigin
                  idOrigin
                  buyerFirstName
                  buyerLastName
                  buyerDni
                  buyerEmail
                  buyerPhone
                  buyerMobilephone
                  createdAt
                  updatedAt
                  createdAtOrigin
                  updatedAtOrigin
                  status
                  statusOrigin
                  shippingLastPrintDate
                  totalAmount
                  shippingAmount
                  discountAmount
                  shippingDiscount
                  paidAmount
                  itemsCount
                  orderNotesCount
                  documentsCount
                  hasShippingLabel
                  items {
                    sku
                    name
                    unitPrice
                    paidPrice
                    discountAmount
                    quantity
                    quantityRestocked
                    variant {
                      sku
                      productId
                    }
                  }
                  orderNotes {
                    id
                    createdAt
                    text
                    user {
                      firstName
                      lastName
                    }
                  }
                }
              }
              pageInfo {
                endCursor
              }
            }
          }
        `
      "
      :variables="queryVariables('')"
    >
      <template slot-scope="{ result: { error, data }, query, isLoading }">
        <span class="font-weight-bold text-muted float-right">
          {{ filterCaption }}
          <b-button v-if="filterCaption.length" size="sm" @click="cleanFilters">
            Limpiar filtros
          </b-button>
        </span>
        <!-- Error -->
        <b-alert v-if="error" show="" variant="danger">
          Ha ocurrido un error
        </b-alert>
        <!-- Result -->
        <div v-else-if="data && data.allOrders">
          <orders-list-item
            v-for="order of data.allOrders.edges"
            :key="order.node.id"
            :order="order.node"
            class="mb-3"
          ></orders-list-item>
        </div>
        <b-spinner
          v-if="isLoading"
          label="Spinning"
          class="m-2 float-right"
        ></b-spinner>
        <div v-else>
          <b-button
            v-if="hasNextPage(data)"
            class="m-2 float-right"
            @click="seeMore(query, data.allOrders.pageInfo.endCursor)"
          >
            Ver más
          </b-button>
          <b-alert v-else show variant="light" class="text-center">
            No hay más datos para mostrar.
          </b-alert>
        </div>
      </template>
    </ApolloQuery>
  </div>
</template>
<script>
import OrdersListItem from "./OrdersListItem";
import INTEGRATION_CONFIGS_FOR_TABS from "../graphql/IntegrationConfigsForTabs.gql";
import { mapState, mapMutations } from "vuex";
export default {
  name: "OrdersList",
  components: {
    OrdersListItem
  },
  model: {
    prop: "params",
    event: "change"
  },
  props: {
    params: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      buyersSelected: null,
      dateSelected: [
        //new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
        //new Date()
        new Date(new Date().setMonth(new Date().getMonth() - 1)),
        new Date()
      ],
      integrationsSelected: [],
      ordersNumbersSelected: null,
      statusesSelected: [],
      shippingLabelSelected: null,
      skusSelected: null,
      allIntegrationConfigs: [],
      labelPrintOptions: [
        { label: "SI", value: true },
        { label: "NO", value: false }
      ],
      ordersStatuses: [
        { label: "PENDIENTES", value: "pending" },
        { label: "ENVIADOS", value: "shipped" },
        { label: "ENTREGADOS", value: "delivered" },
        { label: "ANULADOS", value: "cancelled" },
        { label: "DESCONOCIDOS", value: "unknown" }
      ]
    };
  },
  beforeMount() {
    this.getIntegrationConfigs();
  },
  computed: {
    ...mapState(["filters"]),
    filterCaption() {
      let caption = [];
      if (this.filters.keyword.length) {
        caption.push(this.filters.keyword);
      }
      if (this.filters.categoryId.length) {
        caption.push(
          this.filters.categoryId.split(",").length + " categoría(s)"
        );
      }
      if (this.filters.brandId.length) {
        caption.push(this.filters.brandId.split(",").length + " marca(s)");
      }
      if (this.filters.status !== null) {
        caption.push(this.filters.status ? "activos" : "inactivos");
      }
      if (this.filters.noStock) {
        caption.push("sin stock");
      }
      return caption.length ? "Buscando por: " + caption.join(" - ") : "";
    }
  },
  methods: {
    ...mapMutations(["setFilter"]),
    cleanFilters() {
      this.setFilter({
        ids: null,
        numbers: null,
        skus: null,
        statuses: [],
        origins: [],
        createFrom: new Date(new Date().setMonth(new Date().getMonth() - 1)),
        createTo: new Date(),
        updateFrom: null,
        updateTo: null,
        shippingLabelPrint: null,
        buyers: null
      });
    },
    integrationOptions(data) {
      if (!data || !data.allIntegrationConfigs) {
        return [];
      }
      return data.allIntegrationConfigs.map(x => {
        return { label: x.label, value: x.id };
      });
    },
    getIntegrationConfigs() {
      return this.$apollo
        .query({
          query: INTEGRATION_CONFIGS_FOR_TABS
        })
        .then(({ data }) => {
          this.allIntegrationConfigs = this.integrationOptions(data);
        });
    },
    queryVariables(cursor) {
      const variables = {};
      if (cursor) {
        variables.cursor = cursor;
      }
      if (this.filters.numbers) {
        variables.numbers = this.filters.numbers;
      }
      if (this.filters.ids) {
        variables.ids = this.filters.ids;
      }
      if (this.filters.skus) {
        variables.skus = this.filters.skus;
      }
      if (this.filters.statuses) {
        variables.statuses = this.filters.statuses;
      }
      if (this.filters.origins) {
        variables.origins = this.filters.origins;
      }
      if (this.filters.createFrom) {
        variables.createFrom = this.filters.createFrom;
      }
      if (this.filters.createTo) {
        variables.createTo = this.filters.createTo;
      }
      if (this.filters.updateFrom) {
        variables.updateFrom = this.filters.updateFrom;
      }
      if (this.filters.updateTo) {
        variables.updateTo = this.filters.updateTo;
      }
      if (this.filters.shippingLabelPrint) {
        variables.shippingLabelPrint = this.filters.shippingLabelPrint;
      }
      return variables;
    },
    /**
     * Indica si hay una página siguiente en la query de GraphQl basado
     * exclusivamente en la existencia de un cursor.
     * @return {boolean}
     */
    hasNextPage(data) {
      return data?.allOrders?.pageInfo?.endCursor != null;
    },
    /**
     * Trae más resultados de una query avanzando en su cursor.
     * @param {Object} query
     * @param {String} cursor
     */
    async seeMore(query, cursor) {
      await query.fetchMore({
        variables: this.queryVariables(cursor),
        updateQuery: (prev, { fetchMoreResult }) => {
          const updated = Object.assign({}, this.$dup(prev));
          updated.allOrders.pageInfo = fetchMoreResult.allOrders.pageInfo;
          if (fetchMoreResult?.allOrders?.edges?.length !== 0) {
            updated.allOrders.edges.push(...fetchMoreResult.allOrders.edges);
          }
          return updated;
        }
      });
    }
  },
  watch: {
    buyersSelected(newValue) {
      this.setFilter({ buyers: newValue });
    },
    dateSelected(newValue) {
      this.setFilter({ createFrom: newValue[0] });
      this.setFilter({ createTo: newValue[1] });
      if (newValue[0] == null) {
        this.setFilter({
          createFrom: null
        });
      }
      if (newValue[1] == null) {
        this.setFilter({ createTo: null });
      }
      this.params.createFrom = this.filters.createFrom;
    },
    filters() {
      this.$emit("change", this.filters);
    },
    integrationsSelected(newValue) {
      this.setFilter({ origins: newValue });
    },
    ordersNumbersSelected(newValue) {
      this.setFilter({ numbers: newValue });
    },
    shippingLabelSelected(newValue) {
      this.setFilter({ shippingLabelPrint: newValue });
    },
    statusesSelected(newValue) {
      this.setFilter({ statuses: newValue });
    },
    skusSelected(newValue) {
      this.setFilter({ skus: newValue });
    }
  }
};
</script>

<style scoped>
.btn-sm {
  margin: 5px;
}
</style>
