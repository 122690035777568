var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('apollo-query',{attrs:{"query":require('../graphql/ProductTransactionFilters.gql'),"variables":{ productId: this.product.id }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_result = ref.result;
var data = ref_result.data;
var error = ref_result.error;
var loading = ref_result.loading;
return [(error)?_c('b-alert',[_vm._v("Ha ocurrido un error")]):(loading || !data)?_c('b-spinner'):_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('transactions-more-filters',{attrs:{"operation-options":_vm.optionsForSelect(
                data.cache.transactionsOperations,
                function (x) { return _vm.operationMap[x]; }
              ),"variant-options":_vm.variantsOptions,"warehouse-options":_vm.warehousesOptions}})],1),_c('b-col',{attrs:{"md":"6"}},[_c('v-select',{attrs:{"options":_vm.optionsForSelect(data.cache.transactionsSources),"reduce":function (x) { return x.value; },"placeholder":"Origen"},model:{value:(_vm.source),callback:function ($$v) {_vm.source=$$v},expression:"source"}})],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }