<template>
  <div>
    <h4 class="font-weight-bold m-3">Descuento</h4>

    <b-row>
      <b-col>
        <b-form-group label="Tipo de descuento*" label-for="discountType">
          <v-select
            id="discountType"
            placeholder="Seleccione tipo de descuento"
            v-model="discountType"
            :options="discountTypes"
          ></v-select>
          <span v-if="requiredMessage.discountType" class="text-danger">
            Un tipo de descuento es requerido
          </span>
        </b-form-group>

        <div v-if="!!discountType">
          <div v-if="showPriceDiscount">
            <b-input-group>
              <b-input-group-prepend is-text>
                $
              </b-input-group-prepend>
              <b-form-input type="number" min="0" v-model="discountAmount">
              </b-form-input>
            </b-input-group>
            <span v-if="requiredMessage.discountAmount" class="text-danger">
              Un valor de descuento es requerido
            </span>
          </div>

          <div v-else-if="showPorcentualDiscount">
            <b-input-group>
              <b-input-group-prepend is-text>
                %
              </b-input-group-prepend>
              <b-form-input
                type="number"
                min="0"
                max="100"
                v-model="discountAmount"
                :disabled="discountType.value === 6"
              >
              </b-form-input>
            </b-input-group>
            <span v-if="requiredMessage.discountAmount" class="text-danger">
              Un valor de descuento es requerido
            </span>
          </div>

          <div v-else-if="showGiftDiscount">
            <b-form-group label="SKUs">
              <div>
                <v-select
                  multiple
                  placeholder="Seleccione SKUs"
                  v-model="selectedSkus"
                  :options="skus"
                ></v-select>
                <span v-if="requiredMessage.selectedSKU" class="text-danger">
                  Algun SKU es requerido
                </span>
              </div>
            </b-form-group>

            <b-form-group>
              <b-button variant="outline-info" @click="showModal = !showModal">
                <b-icon-cloud-download></b-icon-cloud-download> Obtener SKUs
              </b-button>
              <vtex-base-promotion-products-and-skus-data-modal
                v-model="showModal"
                :vtexIntegrationConfigId="vtexIntegrationConfigId"
                @skus="setSkus"
              />
            </b-form-group>

            <b-form-group>
              <b-form-checkbox v-model="activeGiftMultiplier">
                Activar multiplicador de regalo
              </b-form-checkbox>
            </b-form-group>

            <b-form-group label="Cantidad maxima seleccionable*">
              <b-form-input type="number" min="0" v-model="maximumQuantity">
              </b-form-input>

              <span v-if="requiredMessage.maximumQuantity" class="text-danger">
                Una cantidad maxima es requerida
              </span>
            </b-form-group>
          </div>

          <div v-else-if="showTablePriceDiscount">
            <b-form-group label="Tabla de precios">
              <div>
                <v-select
                  placeholder="Seleccione tabla de precios"
                  v-model="priceTableName"
                  :options="priceTables"
                  :disabled="true"
                ></v-select>
                <span v-if="requiredMessage.priceTableName" class="text-danger">
                  Una tabla de precios es requerida
                </span>
              </div>
            </b-form-group>
          </div>

          <div v-else-if="showNominalDiscountExpression">
            <b-form-group label="Formula*">
              <b-form-input v-model="discountExpression"> </b-form-input>
              <span
                v-if="requiredMessage.discountExpression"
                class="text-danger"
              >
                Una expresión es requerida
              </span>
              <span :style="{ display: 'block' }">
                Use las variables "total" y/o "freight" para definir la
                expresión. Ejemplo: ((total + freight) * 0.08). El separador
                decimal debe ser un punto.
              </span>
            </b-form-group>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import VtexBasePromotionProductsAndSkusDataModal from "../VtexBasePromotionProductsAndSkusDataModal.vue";

const discountTypes = [
  { value: 0, label: "Nominal" },
  { value: 1, label: "Envío Nominal" },
  { value: 2, label: "Porcentual" },
  { value: 3, label: "Precio Máximo por Artículo" },
  { value: 4, label: "Envío Porcentual" },
  { value: 5, label: "Envío Máximo" },
  { value: 6, label: "Envío Gratis" },
  { value: 7, label: "Regalo" },
  { value: 8, label: "Nominal Basado en Fórmula" },
  { value: 9, label: "Valor de Fidelidad Nominal" },
  { value: 10, label: "Valor de Fidelidad Porcentual" },
  { value: 11, label: "Tabla de Precios Promocionales" }
];

const discountTypesForBuyAndWin = [3, 6, 7];

export default {
  name: "VtexBasePromotionDiscounts",
  components: {
    VtexBasePromotionProductsAndSkusDataModal
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    vtexIntegrationConfigId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      promotion: this.value,
      discountType: this.calculateDiscountType(this.value),
      discountTypes: discountTypes,
      discountAttributes: [
        "nominalDiscountValue",
        "nominalShippingDiscountValue",
        "percentualDiscountValue",
        "maximumUnitPriceDiscount",
        "percentualShippingDiscountValue",
        "absoluteShippingDiscountValue",
        "percentualShippingDiscountValue",
        "skusGift",
        "discountExpression",
        "nominalRewardValue",
        "percentualRewardValue",
        "priceTableName"
      ],
      discountAmount: 0,
      filledRequiredFields: false,
      selectedSkus: [],
      skusGift: this.value.skusGift,
      activeGiftMultiplier: this.value.activateGiftsMultiplier,
      maximumQuantity: this.value.skusGift.quantitySelectable,
      priceTableName: this.value.priceTableName,
      priceTables: [],
      discountExpression: this.value.discountExpression,
      showModal: false,
      skus: []
    };
  },
  /**
   * Si el tipo de promoción es buyAndWin, filtra los tipo de descuentos.
   * Revisa los skus seleccionados de la promoción.
   */
  async mounted() {
    if (this.promotion.type === "buyAndWin") {
      this.discountTypes = discountTypesForBuyAndWin.map(value => {
        return discountTypes.find(discountType => discountType.value === value);
      });
    }
    this.setSelectedSkus();
  },
  methods: {
    /**
     * Calcula el tipo de descuento utilizado por la promoción.
     * @param {Object} promotion
     * @returns {Object} Objeto con atributos value y label del tipo de descuento.
     */
    calculateDiscountType(promotion) {
      if (promotion.nominalDiscountValue > 0) return discountTypes[0];
      if (promotion.nominalShippingDiscountValue > 0) return discountTypes[1];
      if (promotion.percentualDiscountValue > 0) return discountTypes[2];
      if (promotion.maximumUnitPriceDiscount > 0) return discountTypes[3];
      if (promotion.percentualShippingDiscountValue > 0) {
        if (promotion.percentualShippingDiscountValue === 100) {
          return discountTypes[6];
        } else {
          return discountTypes[4];
        }
      }
      if (promotion.absoluteShippingDiscountValue > 0) return discountTypes[5];
      if (
        promotion.skusGift.gifts !== undefined &&
        promotion.skusGift.gifts.length !== 0
      )
        return discountTypes[7];
      if (promotion.discountExpression !== null) return discountTypes[8];
      if (promotion.nominalRewardValue > 0) return discountTypes[9];
      if (promotion.percentualRewardValue > 0) return discountTypes[10];
      if (promotion.priceTableName !== null) return discountTypes[11];
    },
    /**
     * Emite si los datos obligatorios han sido completados.
     * @param {Boolean} value - Valor a emitir.
     */
    emitFilledRequiredFields(value) {
      this.$emit("filledRequiredFields", value);
    },
    /**
     * Revisa y selecciona los SKUs de la promoción.
     */
    setSelectedSkus() {
      if (this.promotion.skusGift.gifts) {
        this.selectedSkus = this.promotion.skusGift.gifts.map(sku => ({
          value: sku.id,
          label: sku.name + " (SKU " + sku.id + ")"
        }));
      }
    },
    /**
     * Emite si la promoción hace un descuento al precio del envio.
     * @param {Object} discountType
     */
    emitShippingDiscount(discountType) {
      if (discountType) {
        const value = discountType.value;
        if ([1, 4, 5, 6].includes(value)) {
          this.$emit("shippingDiscount", true);
        } else {
          this.$emit("shippingDiscount", false);
        }
      }
    },
    /**
     * Emite si la promoción permite elegir si acumularse o no con precios manuales.
     * @param {Object} discountType
     */
    emitAllowManualPrice(discountType) {
      if (discountType && this.promotion.type !== "buyAndWin") {
        const value = discountType.value;
        if ([0, 2, 3].includes(value)) {
          this.$emit("allowManualPrice", true);
        } else {
          this.$emit("allowManualPrice", false);
        }
      }
    },
    /**
     * Emite si la promoción permite elegir si acumularse o no con otras promociones.
     * @param {Object} discountType
     */
    emitAllowOtherPromotions(discountType) {
      if (discountType && this.promotion.type !== "buyAndWin") {
        const value = discountType.value;
        if (value !== 11) {
          this.$emit("allowOtherPromotions", true);
        } else {
          this.$emit("allowOtherPromotions", false);
        }
      }
    },
    /**
     * Asigna los atributos de cata tipo de descuento de una promoción
     * a los valores por defecto cuando no son seleccionados.
     */
    eraseDiscountsAmounts() {
      this.promotion.nominalDiscountValue = 0;
      this.promotion.nominalShippingDiscountValue = 0;
      this.promotion.percentualDiscountValue = 0;
      this.promotion.maximumUnitPriceDiscount = 0;
      this.promotion.percentualShippingDiscountValue = 0;
      this.promotion.absoluteShippingDiscountValue = 0;
      this.promotion.percentualShippingDiscountValue = 0;
      this.promotion.skusGift = {
        quantitySelectable: 1
      };
      this.promotion.discountExpression = null;
      this.promotion.nominalRewardValue = 0;
      this.promotion.percentualRewardValue = 0;
      this.promotion.priceTableName = null;
    },
    setSkus(skus) {
      this.skus = skus;
    }
  },
  computed: {
    /**
     * Decide si mostrar la vista de descuento nominal al precio de la promoción.
     * @returns {Boolean}
     */
    showPriceDiscount() {
      if (this.discountType && this.discountAmount !== null) {
        const value = this.discountType.value;
        return [0, 1, 3, 5, 9].includes(value);
      }
      return false;
    },
    /**
     * Decide si mostrar la vista de descuento percentual al precio de la promoción.
     * @returns {Boolean}
     */
    showPorcentualDiscount() {
      if (this.discountType && this.discountAmount !== null) {
        const value = this.discountType.value;
        return [2, 4, 6, 10].includes(value);
      }
      return false;
    },
    /**
     * Decide si mostrar la vista de añadir regalo a la promoción.
     * @returns {Boolean}
     */
    showGiftDiscount() {
      if (this.discountType) {
        const value = this.discountType.value;
        return [7].includes(value);
      }
      return false;
    },
    /**
     * Decide si mostrar la vista de seleccionar un precio de tablas para la promoción.
     * @returns {Boolean}
     */
    showTablePriceDiscount() {
      if (this.discountType) {
        const value = this.discountType.value;
        return [11].includes(value);
      }
      return false;
    },
    /**
     * Decide si mostrar la vista de descuento nominal por formula.
     * @returns {Boolean}
     */
    showNominalDiscountExpression() {
      if (this.discountType) {
        const value = this.discountType.value;
        return [8].includes(value);
      }
      return false;
    },
    /**
     * Revisa si los atributos obligatorios de la promoción en la vista estan completos
     * para saber donde mostrar los mensajes de requerido.
     * @returns {Object} - Objeto con los atributos obligatorios y si requieren mensaje.
     */
    requiredMessage() {
      let required = {};
      required.discountType = !this.discountType;
      required.discountAmount =
        (this.showPriceDiscount || this.showPorcentualDiscount) &&
        (!this.discountAmount || !this.discountAmount > 0);
      required.selectedSKU =
        this.showGiftDiscount && this.selectedSkus.length === 0;
      required.maximumQuantity =
        this.showGiftDiscount &&
        (!this.maximumQuantity || parseInt(this.maximumQuantity) === 0);
      required.priceTableName =
        this.showTablePriceDiscount && !this.priceTableName;
      required.discountExpression =
        this.showNominalDiscountExpression && !this.discountExpression;
      return required;
    }
  },
  /**
   * En general, el objetivo de los watches es cambiar el valor de una propiedad
   * de la promocion cuando se cambia su correspondiente en la vista
   */
  watch: {
    value(newValue) {
      this.promotion = newValue;
    },
    promotion: {
      handler(newValue) {
        this.$emit("input", newValue);
      },
      deep: true
    },
    /**
     * Emite valores dependiendo del tipo de descuento seleccionado. Luego,
     * guarda el valor actual del descuento de la promoción en la vista.
     * @param {Object} newDiscountType
     */
    discountType: {
      handler(newDiscountType) {
        this.emitShippingDiscount(newDiscountType);
        this.emitAllowManualPrice(newDiscountType);
        this.emitAllowOtherPromotions(newDiscountType);
        if (newDiscountType) {
          const value = newDiscountType.value;
          const attribute = this.discountAttributes[value];
          const actualDiscountAmount = this.promotion[attribute];
          if (![7, 8, 11].includes(value)) {
            this.discountAmount = actualDiscountAmount;
          } else {
            this.discountAmount = 0;
            this.$set(this, attribute, actualDiscountAmount);
          }
        }
      },
      immediate: true
    },
    /**
     * Si cambia el tipo del descuento, se actualiza el valor de la promoción
     * segun el atributo que corresponda al tipo de descuento.
     * @param {String} newValue - Nuevo valor del campo de descuento.
     */
    discountAmount(newValue) {
      if (this.discountType && ![7, 8, 11].includes[this.discountType.value]) {
        const value = this.discountType.value;
        this.eraseDiscountsAmounts();
        this.promotion[this.discountAttributes[value]] = parseFloat(newValue);
      }
    },
    /**
     * Si cambia el valor de requiredMessage, se revisa si no se necesita
     * ningun mensaje y se guarda este valor en filledRequiredFields.
     */
    requiredMessage: {
      handler(newValue) {
        this.filledRequiredFields = !Object.values(newValue).includes(true);
      },
      immediate: true
    },
    /**
     * Si cambia el valor de filledRequiredFields, se emite este valor al padre.
     * @param {Boolean} value - Valor a emitir.
     */
    filledRequiredFields: {
      handler(newValue) {
        this.emitFilledRequiredFields(newValue);
      },
      immediate: true
    },
    /**
     * Se agregan los skus seleccionados como regalos a la promoción.
     */
    skusGift: {
      handler(newValue) {
        if (newValue.gifts && newValue.gifts.length > 0) {
          this.eraseDiscountsAmounts();
        }
        this.promotion.skusGift = newValue;
      },
      deep: true
    },
    /**
     * Se guardan los skus seleccionados como regalos a la promoción en el
     * atributo correspondiente de skusGift.
     */
    selectedSkus(newValue) {
      this.$set(
        this.skusGift,
        "gifts",
        newValue.map(selectedSku => {
          return {
            id: selectedSku.value,
            name: selectedSku.label,
            quantity: 1
          };
        })
      );
    },
    activeGiftMultiplier(newValue) {
      this.promotion.activateGiftsMultiplier = newValue;
    },
    maximumQuantity(newValue) {
      this.promotion.skusGift.quantitySelectable = parseInt(newValue);
    },
    discountExpression(newValue) {
      if (newValue) {
        this.eraseDiscountsAmounts();
      }
      this.promotion.discountExpression = newValue;
    },
    priceTableName(newValue) {
      if (newValue) {
        this.eraseDiscountsAmounts();
      }
      this.promotion.priceTableName = newValue;
    }
  }
};
</script>
