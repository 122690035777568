<template>
  <div>
    <b-button-group size="sm">
      <b-button variant="outline-info" @click="cancel">
        <b-icon-x-circle></b-icon-x-circle> Cancelar
      </b-button>
      <b-button
        variant="info"
        :disabled="
          !changed ||
            saving ||
            loadingIntegrationConfig ||
            loadingCategoryAttributes ||
            !validChangesPrices
        "
        :title="changed ? '' : 'No hay cambios'"
        v-b-tooltip.hover=""
        @click="save"
      >
        <span v-if="saving"><b-spinner label="Spinning"></b-spinner></span>
        <span v-else><b-icon-cloud-upload></b-icon-cloud-upload>Guardar</span>
      </b-button>
    </b-button-group>
    <b-alert
      v-model="saved"
      :variant="errorSaving ? 'danger' : 'success'"
      dismissible
    >
      {{
        errorSaving
          ? "Ha ocurrido un error"
          : "El producto ha sido actualizado exitosamente"
      }}
    </b-alert>
    <h4 class="font-weight-bold m-3">Detalles producto</h4>
    <b-row>
      <b-col md="6">
        <b-form-group label="Marca">
          <paginated-base-live-select
            v-model="brand"
            :query="searchBrand"
            :options="allBrands"
            :min-search-characters="1"
          ></paginated-base-live-select>
        </b-form-group>
        <b-form-group label="Descripción">
          <base-html-text-area
            :id="'description-' + integrationConfig.fullLabel"
            v-model="description"
          ></base-html-text-area>
        </b-form-group>
        <b-form-group
          v-if="isDafiti"
          label="Código de agrupación"
          :label-for="'ProductGroup-' + integrationConfig.fullLabel"
        >
          <b-form-input
            :id="'ProductGroup-' + integrationConfig.fullLabel"
            v-model="productGroup"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group v-if="isDafiti" label="Aceptar cambios y devoluciones">
          <base-boolean-selector
            v-model="isExchangeable"
            :allow-null="true"
            true-text="Si"
            false-text="No"
            default-text="Como la configuración general"
          ></base-boolean-selector>
        </b-form-group>
      </b-col>
      <b-col md="6" v-if="loadingIntegrationConfig">
        <b-spinner label="Spinning"></b-spinner>
      </b-col>
      <b-col md="6" v-else>
        <b-form-group label="Categoría Primaria">
          <v-select
            v-model="primaryCategory"
            :options="allCategories"
            :reduce="x => x.value"
            @input="updateCategoryAttributes"
          ></v-select>
        </b-form-group>
        <b-form-group label="Subcategorías">
          <v-select
            v-model="categories"
            multiple=""
            :options="allCategories"
            :reduce="x => x.value"
          ></v-select>
        </b-form-group>
        <b-form-group label="Categorías secundarias">
          <v-select
            v-model="browseNodes"
            multiple=""
            :options="allCategories"
            :reduce="x => x.value"
          ></v-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-if="loadingCategoryAttributes">
      <b-col md="12" class="text-center">
        <b-spinner label="Spinning"></b-spinner>
      </b-col>
    </b-row>
    <div v-else>
      <product-edit-tabs-rocket-internet-category-attributes
        :category-attributes="categoryAttributes"
        v-model="categoryAttributeValues"
        :is-dafiti="isDafiti"
      ></product-edit-tabs-rocket-internet-category-attributes>
      <product-edit-tabs-rocket-internet-variants
        :category-attributes="categoryAttributes"
        :product="product"
        v-model="variants"
        :saved="saved"
        :integration-config="integrationConfig"
        @change="change"
      ></product-edit-tabs-rocket-internet-variants>
    </div>
  </div>
</template>
<script>
import BaseBooleanSelector from "../BaseBooleanSelector.vue";
import ProductEditTabsRocketInternetCategoryAttributes from "./RocketInternet/CategoryAttributes.vue";
import ProductEditTabsRocketInternetVariants from "./RocketInternet/Variants.vue";
import BaseHtmlTextArea from "../BaseHtmlTextArea.vue";
import INTEGRATION_CONFIG_ROCKET_INTERNET from "../../graphql/IntegrationConfigRocketInternet.gql";
import INTEGRATION_CONFIG_ROCKET_INTERNET_CATEGORY_ATTRIBUTES from "../../graphql/IntegrationConfigRocketInternetCategoryAttributes.gql";
import { updateProduct, updateVariant } from "../../main";
import PaginatedBaseLiveSelect from "../PaginatedBaseLiveSelect.vue";
export default {
  name: "ProductEditTabsRocketInternet",
  components: {
    PaginatedBaseLiveSelect,
    BaseHtmlTextArea,
    ProductEditTabsRocketInternetVariants,
    ProductEditTabsRocketInternetCategoryAttributes,
    BaseBooleanSelector
  },
  props: {
    integrationConfig: Object,
    product: Object
  },
  data() {
    const original = this.product.integrations[
      this.integrationConfig.fullLabel
    ];
    return {
      changed: false,
      saving: false,
      loadingIntegrationConfig: true,
      loadingCategoryAttributes: true,
      saved: false,
      errorSaving: false,
      primaryCategory: null, //PrimaryCategory
      brand: null, //Brand
      description: original
        ? original.Description
          ? original.Description
          : ""
        : "",
      productGroup: original
        ? original.ProductGroup
          ? original.ProductGroup
          : ""
        : "",
      isExchangeable: original
        ? original.ProductData
          ? original.ProductData.IsExchangeable &&
            original.ProductData.IsExchangeable.length
            ? original.ProductData.IsExchangeable === "1"
            : null
          : null
        : null,
      categories: null, //Categories
      browseNodes: null, //BrowseNodes
      allCategories: [],
      allBrands: [],
      categoryAttributes: [],
      categoryAttributeValues: original
        ? original.ProductData
          ? JSON.parse(JSON.stringify(original.ProductData))
          : {}
        : {},
      variants: this.initialVariantArray()
    };
  },
  computed: {
    isDafiti() {
      return this.integrationConfig.identifier.startsWith("dafiti");
    },
    /**
     * Indica si la integracion es de falabella o no
     */
    isFalabella() {
      return this.calculateIsFalabella();
    },
    /**
     * Indica si los precios son validos para habilitar el botón
     * de guardar
     * @returns {Boolean}
     */
    validChangesPrices() {
      let validPrices = this.changed;
      this.variants.forEach(variant => {
        validPrices =
          validPrices &&
          (this.$validFormPrice(variant.Price) ||
            this.$validFormPrice(variant.Price) == null) &&
          (this.$validFormPrice(variant.SalePrice) ||
            this.$validFormPrice(variant.SalePrice) == null);
      });
      return validPrices;
    }
  },
  created() {
    this.$apollo
      .query({
        query: INTEGRATION_CONFIG_ROCKET_INTERNET,
        variables: {
          id: this.integrationConfig.id
        }
      })
      .then(({ data }) => {
        if (data && data.integrationConfigRocketInternet) {
          if (data.integrationConfigRocketInternet.categoryTree) {
            this.poblateCategorySelect(
              data.integrationConfigRocketInternet.categoryTree
            );
          }
          if (data.integrationConfigRocketInternet.brandsList) {
            this.allBrands = this.brandsForSelect(
              data.integrationConfigRocketInternet.brandsList
            );
          }
        }
        const original = this.product.integrations[
          this.integrationConfig.fullLabel
        ];
        if (original) {
          this.brand = original.Brand
            ? original.Brand.length
              ? { label: original.Brand, value: original.Brand }
              : null
            : null;
          this.primaryCategory = original.PrimaryCategory
            ? original.PrimaryCategory.length
              ? original.PrimaryCategory
              : null
            : null;
          this.categories = original.Categories ? original.Categories : [];
          this.browseNodes = original.BrowseNodes ? original.BrowseNodes : [];
        }
        this.updateCategoryAttributes();
        this.loadingIntegrationConfig = false;
        this.$watch("primaryCategory", this.change);
        this.$watch("brand", this.change);
        this.$watch("categories", this.change);
        this.$watch("browseNodes", this.change);
      });
  },
  methods: {
    /**
     * Indica si la integración es o no de falabella
     */
    calculateIsFalabella() {
      return this.integrationConfig.identifier.startsWith("falabella_global");
    },
    /**
     * Entrega un listado de marcas no repetidas
     * @param {Array<Object>} brandsList
     * @returns {Array<Object>}
     */
    brandsForSelect(brandsList) {
      const notRepeated = [];
      const alreadyAdded = {};
      brandsList.forEach(x => {
        if (!alreadyAdded[x.Name]) {
          notRepeated.push({ label: x.Name, value: x.Name });
          alreadyAdded[x.Name] = true;
        }
      });
      return notRepeated;
    },
    change() {
      this.changed = true;
      this.$emit("change", true);
    },
    updateCategoryAttributes() {
      this.loadingCategoryAttributes = true;
      const categoryIdCentry = this.product.category
        ? this.product.category.id
        : null;
      this.$apollo
        .query({
          query: INTEGRATION_CONFIG_ROCKET_INTERNET_CATEGORY_ATTRIBUTES,
          variables: {
            id: this.integrationConfig.id,
            categoryIdCentry,
            categoryIdRocket: this.primaryCategory
          }
        })
        .then(({ data }) => {
          if (
            data &&
            data.integrationConfigRocketInternet &&
            data.integrationConfigRocketInternet.categoryAttributes
          ) {
            this.categoryAttributes =
              data.integrationConfigRocketInternet.categoryAttributes;
          } else {
            this.categoryAttributes = [];
          }
          this.loadingCategoryAttributes = false;
        });
    },
    /**
     * Entrega el elemento para armar el listado
     * de categorías hojas.
     * @param {Object} category
     * @param {String} prefix
     * @returns {Object}
     */
    poblateCategorySelectToProcessElement(category, prefix) {
      return {
        id: category.CategoryId,
        label: category.Name,
        prefix: prefix,
        children: category.Children
          ? this.$arrayWrap(category.Children.Category)
          : null
      };
    },
    poblateCategorySelect(categories) {
      const toProcess = categories.map(x => {
        return this.poblateCategorySelectToProcessElement(x, "");
      });
      const allCategories = [];
      while (toProcess.length) {
        const current = toProcess.shift();
        if (current.children && current.children.length) {
          current.children.forEach(x => {
            toProcess.push(
              this.poblateCategorySelectToProcessElement(
                x,
                current.prefix + current.label + " > "
              )
            );
          });
        } else {
          allCategories.push({
            label: current.prefix + current.label,
            value: current.id
          });
        }
      }
      this.allCategories = allCategories;
    },
    cancel() {
      if (this.changed) {
        this.$swal
          .fire({
            title: "Cancelar",
            text: "Si cancelas, perderás tus cambios. ¿Estás seguro?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "No"
          })
          .then(result => {
            if (result.value) {
              this.$router.push({ name: "Products" });
            }
          });
      } else {
        this.$router.push({ name: "Products" });
      }
    },
    updatedProductData() {
      const original = this.product.integrations[
        this.integrationConfig.fullLabel
      ];
      const changes = [
        {
          key: "PrimaryCategory",
          original: original ? original.PrimaryCategory : "",
          current: this.primaryCategory ? this.primaryCategory : ""
        },
        {
          key: "Brand",
          original: original ? original.Brand : "",
          current: this.brand ? this.brand.value : ""
        },
        {
          key: "Description",
          original: original ? original.Description : "",
          current: this.description ? this.description : ""
        }
      ];
      if (this.isDafiti) {
        changes.push({
          key: "ProductGroup",
          original: original ? original.ProductGroup : "",
          current: this.productGroup
        });
        changes.push({
          key: "IsExchangeable",
          original: original?.ProductData
            ? original.ProductData.IsExchangeable
            : "",
          current:
            this.isExchangeable === null ? "" : this.isExchangeable ? "1" : "0"
        });
      }
      const currentChanges = this.changes(changes);
      if (
        JSON.stringify(original ? original.Categories : "") !==
        JSON.stringify(this.categories ? this.categories : "")
      ) {
        currentChanges.Categories = this.categories ? this.categories : "";
      }
      if (
        JSON.stringify(original ? original.BrowseNodes : "") !==
        JSON.stringify(this.browseNodes ? this.browseNodes : "")
      ) {
        currentChanges.BrowseNodes = this.browseNodes ? this.browseNodes : "";
      }
      if (this.categoryAttributeValuesHaveChanges()) {
        currentChanges.ProductData = this.categoryAttributeValues;
      }
      if ("IsExchangeable" in currentChanges) {
        if ("ProductData" in currentChanges) {
          currentChanges.ProductData.IsExchangeable =
            currentChanges.IsExchangeable;
        } else {
          currentChanges.ProductData = {
            IsExchangeable: currentChanges.IsExchangeable
          };
        }
        delete currentChanges.IsExchangeable;
      }
      if (Object.keys(currentChanges).length) {
        const toUpdate = { integrations: {} };
        toUpdate.integrations[
          this.integrationConfig.fullLabel
        ] = currentChanges;
        return toUpdate;
      }
      return null;
    },
    updateProductCache(product) {
      if (product) {
        this.product.integrations = product.integrations;
      }
    },
    changes(changesArray) {
      let updated = {};
      changesArray.forEach(x => {
        if (x.original !== x.current) {
          updated[x.key] = x.current;
        }
      });
      return updated;
    },
    async updateProduct() {
      const updateProductData = this.updatedProductData();
      if (updateProductData === null) {
        this.errorSaving = false;
        return;
      }
      await updateProduct(this.$apollo, this.product.id, updateProductData)
        .then(async ({ data }) => {
          if (data && data.product) {
            this.errorSaving = false;
            const newProduct = data.product;
            this.updateProductCache(newProduct);
          }
        })
        .catch(() => {
          this.errorSaving = true;
        });
    },
    /**
     * Entrega un valor para formulario, a partir de la
     * llave y el hash
     * @param {Object} hash
     * @param {String} key
     * @returns {String}
     */
    valueFromHash(hash, key) {
      return this.$ifNull(this.$dig(hash, key), "");
    },
    updatedVariantData(position) {
      const variant = this.product.variants[position];
      const originalVariant = this.$ifNull(
        variant.integrations[this.integrationConfig.fullLabel],
        {}
      );
      const currentVariant = this.variants[position];
      const globalKeys = [
        "SellerSku",
        "ProductId",
        "Status",
        "Name",
        "Price",
        "SalePrice",
        "SaleStartDate",
        "SaleEndDate",
        "Variation",
        "Size",
        "Talla",
        "ColorBasico"
      ];
      const pdKeys = ["ColorNameBrand", "ColorFamily"];
      if (this.isFalabella) {
        globalKeys.push("Color");
      } else {
        pdKeys.push("Color");
      }
      const changes = globalKeys.map(key => {
        return {
          key: key,
          original: this.valueFromHash(originalVariant, key),
          current: currentVariant[key]
        };
      });
      const updated = this.changes(changes);
      const productData = originalVariant ? originalVariant.ProductData : null;
      const pdChanges = pdKeys.map(key => {
        return {
          key: key,
          original: this.valueFromHash(productData, key),
          current: currentVariant[key]
        };
      });
      const pdUpdated = this.changes(pdChanges);
      if (Object.keys(pdUpdated).length) {
        updated.ProductData = pdUpdated;
      }
      if (Object.keys(updated).length) {
        const toSave = { integrations: {} };
        toSave.integrations[this.integrationConfig.fullLabel] = updated;
        return toSave;
      }
      return null;
    },
    updateVariantCache(position, variant) {
      this.product.variants[position] = variant;
    },
    async updateVariant(position) {
      const updated = this.updatedVariantData(position);
      if (!updated) {
        return;
      }
      await updateVariant(
        this.$apollo,
        this.product.variants[position].id,
        updated
      )
        .then(async ({ data }) => {
          if (data && data.updateVariant && data.updateVariant.variant) {
            const variant = data.updateVariant.variant;
            this.updateVariantCache(position, variant);
          }
        })
        .catch(() => {
          this.errorSaving = true;
        });
    },
    async save() {
      this.saving = true;
      this.errorSaving = false;
      await this.updateProduct();
      for (let i = 0; i < this.variants.length; i++) {
        await this.updateVariant(i);
      }
      this.saved = true;
      this.saving = false;
      if (!this.errorSaving) {
        this.changed = false;
        this.$emit("change", false);
      }
    },
    categoryAttributeValuesHaveChanges() {
      const original = this.product.integrations[
        this.integrationConfig.fullLabel
      ];
      if (!original || !original.ProductData) return true;
      if (
        Object.keys(original.ProductData).length !==
        Object.keys(this.categoryAttributeValues).length
      ) {
        return true;
      }
      let hasChanges = false;
      Object.keys(this.categoryAttributeValues).forEach(x => {
        const current = this.categoryAttributeValues[x];
        const originalVal = original.ProductData[x];
        if (Array.isArray(originalVal) && Array.isArray(current)) {
          if (originalVal.length !== current.length) {
            hasChanges = true;
          } else {
            originalVal.forEach((x, index) => {
              if (x !== current[index]) {
                hasChanges = true;
              }
            });
          }
        } else if (originalVal !== current) {
          hasChanges = true;
        }
      });
      return hasChanges;
    },
    initialVariantArray() {
      return this.product.variants
        ? this.product.variants.map(x => {
            const original = x.integrations[this.integrationConfig.fullLabel];
            const productData = original ? original.ProductData : null;
            return {
              SellerSku: this.valueFromHash(original, "SellerSku"),
              ProductId: this.valueFromHash(original, "ProductId"),
              Variation: this.valueFromHash(original, "Variation"),
              Size: this.valueFromHash(original, "Size"),
              Talla: this.valueFromHash(original, "Talla"),
              Status: this.valueFromHash(original, "Status"),
              Name: this.valueFromHash(original, "Name"),
              Price: this.valueFromHash(original, "Price"),
              SalePrice: this.valueFromHash(original, "SalePrice"),
              SaleStartDate: this.valueFromHash(original, "SaleStartDate"),
              SaleEndDate: this.valueFromHash(original, "SaleEndDate"),
              Color: this.getColor(original),
              ColorNameBrand: this.valueFromHash(productData, "ColorNameBrand"),
              ColorFamily: this.valueFromHash(productData, "ColorFamily"),
              ColorBasico: this.valueFromHash(original, "ColorBasico")
            };
          })
        : [];
    },
    /**
     * Obtiene el valor del atributo color,
     * que varía según integración de rocket
     */
    getColor(original) {
      let obj = this.$ifNull(original, {});
      if (!this.calculateIsFalabella()) {
        obj = this.$ifNull(obj.ProductData, {});
      }
      return this.$ifNull(obj.Color, "");
    },
    /**
     * Busca las marcas de forma paginada
     * @param {String} search
     * @param {Integer} limit
     * @param {String} cursor
     * @returns {Object}
     */
    searchBrand(search, limit, cursor) {
      return {
        items: this.allBrands
          .filter(x => x.label.toLowerCase().includes(search.toLowerCase()))
          .slice(cursor, cursor + limit),
        cursor: cursor + limit
      };
    }
  },
  watch: {
    description() {
      this.change();
    },
    productGroup() {
      this.change();
    },
    isExchangeable() {
      this.change();
    },
    categoryAttributeValues() {
      this.change();
    },
    variants() {
      this.change();
    }
  }
};
</script>
