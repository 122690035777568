<template>
  <div>
    <b-button @click="showModal">Seleccionar Categoría</b-button>
    <b-modal
      ref="modal-categories"
      id="modal-categories"
      size="xl"
      scrollable
      title="Seleccionar Categoría"
    >
      <b-container class="h-100">
        <b-row>
          Debes seleccionar una categoría "hoja" del árbol de categorias, es
          decir que la categoría que selecciones no puede tener ninguna
          subcategoría que cuelgue de ella.
        </b-row>
        <hr />
        <b-row
          id="row_categories"
          style="overflow: scroll; white-space:nowrap; flex-wrap: nowrap; height: 90% !important"
        >
          <b-col>
            <b-button-group vertical>
              <b-button
                v-for="root of rootCategories"
                :key="root.value"
                @click="getCategoryChilds(root.value, 0)"
                :id="root.value"
              >
                {{ root.label }}
              </b-button>
            </b-button-group>
          </b-col>
          <b-col v-for="(row, position) of aux" :key="position" class="mx-2">
            <b-button-group vertical>
              <b-button
                v-for="child of aux[position]"
                :key="child.value"
                @click="getCategoryChilds(child.value, position + 1)"
                :id="child.value"
              >
                {{ child.label }}
              </b-button>
            </b-button-group>
          </b-col>
        </b-row>
      </b-container>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            :disabled="childs"
            @click="changeCategory"
          >
            Seleccionar Categoría
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "ProductEditTabsMercadoLibreCategoriesModal",
  props: {
    siteCode: String,
    currentCategoryId: String
  },
  data() {
    return {
      rootCategories: [],
      aux: [],
      categoryId: "",
      childs: true
    };
  },
  methods: {
    showModal() {
      if (this.currentCategoryId) {
        this.$swal
          .fire({
            title: "Precaución",
            text:
              "Si cambias de categoría perderás la información de los atributos generales que no correspondan a la categoría seleccionada, ¿Deseas continuar?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "No"
          })
          .then(result => {
            if (result.value) {
              this.getRootCategories();
              this.$refs["modal-categories"].show();
            }
          });
      } else {
        this.getRootCategories();
        this.$refs["modal-categories"].show();
      }
    },
    changeCategory() {
      this.$refs["modal-categories"].hide();
      this.$emit("categoryId", this.categoryId);
    },
    getRootCategories() {
      this.cleanAux(0);
      fetch(`https://api.mercadolibre.com/sites/${this.siteCode}/categories`)
        .then(function(response) {
          return response.json();
        })
        .then(myJson => {
          this.rootCategories = myJson.map(c => {
            return { value: c.id, label: c.name };
          });
          this.childs = true;
        });
    },
    getCategoryChilds(category_id, position) {
      this.childs = true;
      this.cleanAux(position);
      var categoryBtn = document.getElementById(category_id);
      categoryBtn.classList.add("active");
      fetch("https://api.mercadolibre.com/categories/" + category_id)
        .then(function(response) {
          return response.json();
        })
        .then(myJson => {
          this.categoryId = category_id;
          if (
            "children_categories" in myJson &&
            myJson.children_categories.length > 0
          ) {
            this.childs = true;
            return this.aux.push(
              myJson.children_categories.map(c => {
                return { value: c.id, label: c.name };
              })
            );
          } else {
            this.childs = false;
          }
        });
    },
    cleanAux(column) {
      var categoryBtns =
        document
          .getElementById("row_categories")
          ?.childNodes[column].querySelectorAll(".active") || [];
      categoryBtns.forEach(node => node.classList.remove("active"));
      if (this.aux.length >= column + 1) {
        this.aux.splice(column, this.aux.length);
      }
    }
  }
};
</script>
