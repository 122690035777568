<template>
  <div>
    <h4 class="font-weight-bold m-3">Fechas, días y horarios</h4>

    <b-row>
      <b-col>
        <b-form-group label="Inicio oferta*" label-for="beginDate">
          <base-date-time v-model="beginDate" type="datetime"></base-date-time>
          <span v-if="missingRequiredFields.beginDateUtc" class="text-danger">
            El inicio de oferta es requerido
          </span>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Termino oferta*" label-for="endDate">
          <base-date-time v-model="endDate" type="datetime"></base-date-time>
          <span v-if="missingRequiredFields.endDateUtc" class="text-danger">
            El termino de oferta es requerido
          </span>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-checkbox v-model="activeRecurrencyCrons">
          Utilizar configuraciones de recurrencia
        </b-form-checkbox>
      </b-col>
    </b-row>

    <b-row v-if="activeRecurrencyCrons">
      <b-col>
        <b-form-group label="Dia de la semana">
          <b-form-radio v-model="allDays" :value="true">
            Todos los días de la semana
          </b-form-radio>

          <b-form-radio v-model="allDays" :value="false">
            Días especificos de la semana
          </b-form-radio>

          <b-form-group v-show="!allDays">
            <b-form-checkbox-group v-model="selectedDays" stacked>
              <b-form-checkbox value="SUN">Domingo</b-form-checkbox>
              <b-form-checkbox value="MON">Lunes</b-form-checkbox>
              <b-form-checkbox value="TUE">Martes</b-form-checkbox>
              <b-form-checkbox value="WED">Miércoles</b-form-checkbox>
              <b-form-checkbox value="THU">Jueves</b-form-checkbox>
              <b-form-checkbox value="FRI">Viernes</b-form-checkbox>
              <b-form-checkbox value="SAT">Sabado</b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group label="Horario">
          <b-form-radio v-model="allHours" :value="true">
            Todo el día
          </b-form-radio>

          <b-form-radio v-model="allHours" :value="false">
            Horarios especificos
          </b-form-radio>
        </b-form-group>

        <div v-show="!allHours">
          <div v-for="(selectedHour, index) in selectedHours" :key="index">
            <b-row>
              <b-col>
                <b-form-group
                  label="Hora de Inicio"
                  :label-for="'beginHour-' + index"
                >
                  <v-select
                    :id="'beginHour-' + index"
                    placeholder="Seleccione hora de inicio"
                    v-model="selectedHour.beginHour"
                    :options="hours"
                  ></v-select>
                </b-form-group>
              </b-col>

              <b-col>
                <b-form-group
                  label="Hora de Termino"
                  :label-for="'endHour-' + index"
                >
                  <v-select
                    :id="'endHour-' + index"
                    placeholder="Seleccione hora de termino"
                    v-model="selectedHour.endHour"
                    :options="hours"
                  ></v-select>
                </b-form-group>
              </b-col>

              <b-col>
                <b-form-group label="Eliminar">
                  <b-button
                    size="sm"
                    variant="outline-info"
                    @click="deleteSelectedHour(index)"
                  >
                    <b-icon-x-circle></b-icon-x-circle>
                  </b-button>
                </b-form-group>
              </b-col>
            </b-row>
          </div>

          <b-button variant="outline-info" @click="addSelectedHour">
            <b-icon-plus></b-icon-plus> Agregar Horario
          </b-button>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import BaseDateTime from "@/components/BaseDateTime";

const hours = [...Array(24).keys()].map(hour => {
  return { value: hour, label: `${hour}:00` };
});

export default {
  name: "VtexBasePromotionSchedule",
  components: {
    BaseDateTime
  },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      promotion: this.value,
      beginDate: this.value.beginDateUtc
        ? new Date(this.value.beginDateUtc)
        : null,
      endDate: this.value.endDateUtc ? new Date(this.value.endDateUtc) : null,
      activeRecurrencyCrons:
        !!this.value.recurrencyCrons && this.value.recurrencyCrons.length > 0,
      recurrencyCrons: this.calculateRecurrencyCrons(
        this.value.recurrencyCrons
      ),
      allDays: this.checkAllDays(this.value.recurrencyCrons),
      selectedDays: this.getSelectedDays(this.value.recurrencyCrons),
      allHours: this.checkAllHours(this.value.recurrencyCrons),
      selectedHours: this.getSelectedHours(this.value.recurrencyCrons),
      hours: hours
    };
  },
  methods: {
    /**
     * Recibe el recurrencyCrons de la promoción y lo convierte en un arreglo
     * para poder ser manipulado por el componente.
     * @param {Array<String>} recurrencyCrons - Arreglo de un unico string.
     * @returns {Array}
     */
    calculateRecurrencyCrons(recurrencyCron) {
      if (!recurrencyCron) {
        return [];
      }
      return recurrencyCron[0].split(" ");
    },
    /**
     * Verifica si la opción todos los días esta seleccionada.
     * @param {Array<String>} recurrencyCrons - Arreglo de un unico string.
     * @returns {Boolean} - True si esta seleccionado, false si no.
     */
    checkAllDays(recurrencyCron) {
      if (!recurrencyCron) return true;
      let values = recurrencyCron[0].split(" ");
      if (values[5] === "*" || values[5] === "SUN,MON,TUE,WED,THU,FRI,SAT") {
        return true;
      }
      return false;
    },
    /**
     * Retorna los dias seleccionados para la promoción.
     * @param {Array<String>} recurrencyCrons - Arreglo de un unico string.
     * @returns {Array<String>} - Arreglo de dias seleccionados.
     */
    getSelectedDays(recurrencyCron) {
      if (!recurrencyCron) return [];
      let values = recurrencyCron[0].split(" ");
      if (values[5] === "*" || values[5] === "SUN,MON,TUE,WED,THU,FRI,SAT") {
        return [];
      }
      return values[5].split(",");
    },
    /**
     * Verifica si la opción todos las horas esta seleccionada.
     * @param {Array<String>} recurrencyCrons - Arreglo de un unico string.
     * @returns {Boolean} - True si esta seleccionado, false si no.
     */
    checkAllHours(recurrencyCron) {
      if (!recurrencyCron) return true;
      let values = recurrencyCron[0].split(" ");
      if (values[2] === "*") return true;
      return false;
    },
    /**
     * Retorna los horas seleccionados para la promoción.
     * @param {Array<String>} recurrencyCrons - Arreglo de un unico string.
     * @returns {Array<Object>} - Arreglo de horas seleccionadas.
     */
    getSelectedHours(recurrencyCron) {
      if (!recurrencyCron) return [];
      let values = recurrencyCron[0].split(" ");
      if (values[2] === "*") return [];
      return values[2].split(",").map(hours => {
        let data = hours.split("-");
        let beginHour = data[0];
        let endHour = data[1];
        return {
          beginHour: this.selectedHourByValue(beginHour),
          endHour: this.selectedHourByValue(endHour)
        };
      });
    },
    /**
     * Retorna los objetos equivalentes al rango de horas seleccionado en el cron.
     * @param {String} value - Valor de la hora.
     * @returns {Object} - Objeto con el valor de la hora y el label.
     */
    selectedHourByValue(value) {
      return hours.find(option => option.value === parseInt(value));
    },
    /**
     * Se agrega un nuevo rango de horas disponible a la promoción.
     */
    addSelectedHour() {
      this.selectedHours.push({
        beginHour: null,
        endHour: null
      });
    },
    /**
     * Se elimina un rango de horas disponible a la promoción.
     * @param {Number} index - Indice de las horas a eliminar.
     */
    deleteSelectedHour(index) {
      this.selectedHours.splice(index, 1);
    }
  },
  computed: {
    /**
     * Para cada atributo obligatorio de Overview se informa si falta el dato.
     * @returns {Object}
     */
    missingRequiredFields() {
      let missing = {};
      missing.beginDateUtc = !this.promotion.beginDateUtc;
      missing.endDateUtc = !this.promotion.endDateUtc;
      return missing;
    }
  },
  watch: {
    value: {
      handler(newValue) {
        this.promotion = newValue;
      },
      deep: true
    },
    promotion: {
      handler(newValue) {
        this.$emit("input", newValue);
      },
      deep: true
    },
    beginDate: function(newBeginDate) {
      if (newBeginDate) {
        this.promotion.beginDateUtc = newBeginDate.toISOString();
      } else {
        this.promotion.beginDateUtc = null;
      }
    },
    endDate: function(newEndDate) {
      if (newEndDate) {
        this.promotion.endDateUtc = newEndDate.toISOString();
      } else {
        this.promotion.endDateUtc = null;
      }
    },
    /**
     * Si se activan las opciones avanzadas de fechas y horarios, se asignan
     * estos valores a la promoción. Si se desactivan, se asigna null.
     * @param {Boolean} newValue - Si se activa o desactiva.
     */
    activeRecurrencyCrons: function(newValue) {
      if (newValue) {
        if (this.recurrencyCrons.length > 0) {
          this.promotion.recurrencyCrons = [this.recurrencyCrons.join(" ")];
        } else {
          this.recurrencyCrons = ["*", "*", "*", "*", "*", "*"];
        }
      } else {
        this.promotion.recurrencyCrons = null;
      }
    },
    recurrencyCrons: function(newRecurrencyCrons) {
      if (!newRecurrencyCrons || newRecurrencyCrons.lenght === 0) {
        this.promotion.recurrencyCrons = null;
      } else {
        this.promotion.recurrencyCrons = [newRecurrencyCrons.join(" ")];
      }
    },
    /**
     * Si se activa la opción de todos los dias, se asigna * en el indice correspondiente.
     * Si se desactiva, se asignan los dias seleccionados en la vista.
     * @param {Boolean} newAllDays - Si se activa o desactiva
     */
    allDays: function(newAllDays) {
      if (newAllDays || this.selectedDays.length == 0) {
        this.$set(this.recurrencyCrons, 5, "*");
      } else {
        this.$set(this.recurrencyCrons, 5, this.selectedDays.join(","));
      }
    },
    selectedDays: function(newSelectedDays) {
      if (newSelectedDays.length === 0) {
        this.$set(this.recurrencyCrons, 5, "*");
      } else {
        this.$set(this.recurrencyCrons, 5, newSelectedDays.join(","));
      }
    },
    /**
     * Si se activa la opción de todas las horas, se asigna * en el indice correspondiente.
     * Si se desactiva, se asignan las horas seleccionadas en la vista.
     * @param {Boolean} newAllHours - Si se activa o desactiva
     */
    allHours: function(newAllHours) {
      if (newAllHours || this.selectedHours.length == 0) {
        this.$set(this.recurrencyCrons, 2, "*");
      } else {
        this.$set(
          this.recurrencyCrons,
          2,
          this.selectedHours
            .map(hour => {
              return `${hour.beginHour.value}-${hour.endHour.value}`;
            })
            .join(",")
        );
      }
    },
    /**
     * Asigna el valor de las nuevas horas seleccionadas en
     * el indice correspondiente del arreglo recurrencyCrons.
     * @param {Array<Object>} newSelectedHours - Horas seleccionadas.
     */
    selectedHours: {
      handler(newSelectedHours) {
        if (newSelectedHours.length === 0) {
          this.$set(this.recurrencyCrons, 2, "*");
        } else {
          let hours = newSelectedHours.map(hour => {
            if (!!hour.beginHour && !!hour.endHour) {
              return `${hour.beginHour.value}-${hour.endHour.value}`;
            }
            return null;
          });
          this.$set(this.recurrencyCrons, 2, hours.join(","));
        }
      },
      deep: true
    }
  }
};
</script>
