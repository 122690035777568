<template>
  <xlsx-workbook>
    <xlsx-sheet :collection="errors" :sheet-name="sheetName" />
    <xlsx-download :filename="fileName">
      <b-button variant="outline"><b-icon-download></b-icon-download></b-button>
    </xlsx-download>
  </xlsx-workbook>
</template>
<script>
export default {
  name: "BulkUploadHistoriesTableErrors",
  props: {
    errors: Array,
    sheetName: String,
    fileName: String
  }
};
</script>
