<template>
  <div>
    <b-button-group size="sm">
      <b-button variant="outline-info" @click="cancel">
        <b-icon-x-circle></b-icon-x-circle> Cancelar
      </b-button>
      <b-button
        variant="info"
        :disabled="
          !changed || saving || loadingIntegrationConfig || !validChangesPrices
        "
        :title="changed ? '' : 'No hay cambios'"
        v-b-tooltip.hover=""
        @click="save"
      >
        <span v-if="saving"><b-spinner label="Spinning"></b-spinner></span>
        <span v-else><b-icon-cloud-upload></b-icon-cloud-upload>Guardar</span>
      </b-button>
    </b-button-group>
    <b-alert
      v-model="saved"
      :variant="errorSaving ? 'danger' : 'success'"
      dismissible
    >
      {{
        errorSaving
          ? "Ha ocurrido un error"
          : "El producto ha sido actualizado exitosamente"
      }}
    </b-alert>
    <h4 class="font-weight-bold m-3">Detalles producto</h4>
    <b-row>
      <b-col md="6">
        <b-form-group
          label="Nombre"
          :label-for="'name-' + integrationConfig.fullLabel"
        >
          <b-form-input
            :id="'name-' + integrationConfig.fullLabel"
            v-model="name"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="SKU"
          :label-for="'sku-' + integrationConfig.fullLabel"
        >
          <b-form-input
            :id="'sku-' + integrationConfig.fullLabel"
            v-model="sku"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Precio normal"
          :label-for="'regular-price-' + integrationConfig.fullLabel"
        >
          <b-form-input
            :id="'regular-price-' + integrationConfig.fullLabel"
            v-model="regularPrice"
            step="0.01"
            :state="$validFormPrice(regularPrice)"
            :formatter="$formatFormPrice"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Precio de oferta"
          :label-for="'sale-price-' + integrationConfig.fullLabel"
        >
          <b-form-input
            :id="'sale-price-' + integrationConfig.fullLabel"
            v-model="salePrice"
            step="0.01"
            :state="$validFormPrice(salePrice)"
            :formatter="$formatFormPrice"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Inicio oferta"
          :label-for="'date-on-sale-from-' + integrationConfig.fullLabel"
        >
          <base-date-time
            :id="'date-on-sale-from-' + integrationConfig.fullLabel"
            v-model="dateOnSaleFrom"
            type="datetime"
          ></base-date-time>
        </b-form-group>
        <b-form-group
          label="Término oferta"
          :label-for="'date-on-sale-to-' + integrationConfig.fullLabel"
        >
          <base-date-time
            :id="'date-on-sale-to-' + integrationConfig.fullLabel"
            v-model="dateOnSaleTo"
            type="datetime"
          ></base-date-time>
        </b-form-group>
        <b-form-group label="Estado">
          <base-boolean-selector
            v-model="status"
            allow-null=""
            true-text="Disponible"
            false-text="Deshabilitado"
            default-text="Como en la configuración general"
          ></base-boolean-selector>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Descripción"
          :label-for="'description-' + integrationConfig.fullLabel"
        >
          <base-html-text-area
            :id="'description-' + integrationConfig.fullLabel"
            v-model="description"
          ></base-html-text-area>
        </b-form-group>
        <b-form-group
          label="Descripción corta"
          :label-for="'shortDescription-' + integrationConfig.fullLabel"
        >
          <base-html-text-area
            :id="'shortDescription-' + integrationConfig.fullLabel"
            v-model="shortDescription"
          ></base-html-text-area>
        </b-form-group>
      </b-col>
    </b-row>

    <hr />

    <b-row>
      <h4 class="font-weight-bold m-3">Variantes</h4>
      <p v-if="!product.variants || product.variants.length === 0">
        Este producto no tiene variantes
      </p>
      <b-table-simple v-else>
        <b-thead>
          <b-tr>
            <b-th colspan="3">Centry</b-th>
            <b-th>{{ integrationConfig.label }}</b-th>
          </b-tr>
          <b-tr>
            <b-th>Color</b-th>
            <b-th>Talla</b-th>
            <b-th>SKU</b-th>
            <b-th>SKU</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="(variant, index) of product.variants" :key="index">
            <b-td>{{ variant.color ? variant.color.name : "---" }}</b-td>
            <b-td>{{ variant.size ? variant.size.name : "---" }}</b-td>
            <b-td>{{ variant.sku }}</b-td>
            <b-td
              ><b-form-input
                v-model="
                  productVariants[index].integrations[
                    integrationConfig.fullLabel
                  ].sku
                "
                @input="change"
              ></b-form-input
            ></b-td>
          </b-tr>
        </b-tbody> </b-table-simple
    ></b-row>
  </div>
</template>
<script>
import BaseHtmlTextArea from "./BaseHtmlTextArea";
import BaseBooleanSelector from "./BaseBooleanSelector";
import BaseDateTime from "./BaseDateTime";
import { updateProduct, updateVariant } from "../main";
import INTEGRATION_CONFIG_WOO_COMMERCE from "../graphql/IntegrationConfigWooCommerce.gql";

export default {
  name: "ProductEditTabsWooCommerce",
  components: { BaseHtmlTextArea, BaseBooleanSelector, BaseDateTime },
  props: {
    integrationConfig: Object,
    product: Object
  },
  data() {
    const original =
      this.product.integrations[this.integrationConfig.fullLabel] || {};
    return {
      changed: false,
      saving: false,
      errorSaving: false,
      saved: false,
      loadingIntegrationConfig: true,
      // http://woocommerce.github.io/woocommerce-rest-api-docs/#product-properties
      name: original.name,
      status: this.getStatus(),
      // featured	boolean	Featured product. Default is false.
      // catalog_visibility	string	Catalog visibility. Options: visible, catalog, search and hidden. Default is visible.
      description: original.description,
      shortDescription: original.short_description,
      sku: original.sku,
      regularPrice: original.regular_price,
      salePrice: original.sale_price,
      dateOnSaleFrom:
        original.date_on_sale_from !== null
          ? new Date(original.date_on_sale_from)
          : null,
      dateOnSaleTo:
        original.date_on_sale_to !== null
          ? new Date(original.date_on_sale_to)
          : null,
      // virtual	boolean	If the product is virtual. Default is false.
      // downloadable	boolean	If the product is downloadable. Default is false.
      // downloads	array	List of downloadable files. See Product - Downloads properties
      // download_limit	integer	Number of times downloadable files can be downloaded after purchase. Default is -1.
      // download_expiry	integer	Number of days until access to downloadable files expires. Default is -1.
      // external_url	string	Product external URL. Only for external products.
      // button_text	string	Product external button text. Only for external products.
      // tax_status	string	Tax status. Options: taxable, shipping and none. Default is taxable.
      // tax_class	string	Tax class.
      // manage_stock	boolean	Stock management at product level. Default is false.
      // backorders	string	If managing stock, this controls if backorders are allowed. Options: no, notify and yes. Default is no.
      // sold_individually	boolean	Allow one item to be bought in a single order. Default is false.
      // weight	string	Product weight.
      // dimensions.length	string	Product length.
      // dimensions.width	string	Product width.
      // dimensions.height	string	Product height.
      // shipping_class	string	Shipping class slug.
      // reviews_allowed	boolean	Allow reviews. Default is true.
      // upsell_ids	array	List of up-sell products IDs.
      // cross_sell_ids	array	List of cross-sell products IDs.
      // parent_id	integer	Product parent ID.
      // purchase_note	string	Optional note to send the customer after purchase.
      // categories	array	List of categories. See Product - Categories properties
      // tags	array	List of tags. See Product - Tags properties
      // menu_order	integer	Menu order, used to custom sort products.
      productVariants: this.initialVariantSkus()
    };
  },
  beforeMount() {
    this.getIntegrationConfig();
  },
  computed: {
    /**
     * Indica si los precios son validos para habilitar el botón
     * de guardar
     * @returns {Boolean}
     */
    validChangesPrices() {
      return (
        this.changed &&
        (this.$validFormPrice(this.regularPrice) ||
          this.$validFormPrice(this.regularPrice) == null) &&
        (this.$validFormPrice(this.salePrice) ||
          this.$validFormPrice(this.salePrice) == null)
      );
    }
  },
  methods: {
    change() {
      this.changed = true;
      this.$emit("change", true);
    },
    getStatus() {
      const status = this.$dig(
        this.product,
        "integrations",
        this.integrationConfig.fullLabel,
        "status"
      );
      if (!status) {
        return null;
      } else if (status === "publish") {
        return true;
      } else {
        return false;
      }
    },
    initialVariantSkus() {
      if (!this.product.variants) {
        return [];
      }
      const variantArray = JSON.parse(JSON.stringify(this.product.variants));
      variantArray.forEach(variant => {
        const hash = this.$ifNull(
          this.$ifNull(variant.integrations, {})[
            this.integrationConfig.fullLabel
          ],
          {}
        );
        hash.sku = this.$ifNull(hash.sku, "");
        variant.integrations[this.integrationConfig.fullLabel] = hash;
      });
      return variantArray;
    },
    async getIntegrationConfig() {
      this.$apollo
        .query({
          query: INTEGRATION_CONFIG_WOO_COMMERCE,
          variables: { id: this.integrationConfig.id }
        })
        .then(() => {
          this.loadingIntegrationConfig = false;
        });
    },
    updatedProductData() {
      const updated = { integrations: {} };
      updated.integrations[this.integrationConfig.fullLabel] = {};
      let original =
        this.product.integrations[this.integrationConfig.fullLabel] || {};
      if (!original) original = {};
      let status = "";
      switch (this.status) {
        case true:
          status = "publish";
          break;
        case false:
          status = "draft";
          break;
      }
      const changes = [
        {
          key: "name",
          original: original.name,
          current: this.name
        },
        {
          key: "status",
          original: original.published,
          current: status
        },
        {
          key: "description",
          original: original.description,
          current: this.description
        },
        {
          key: "short_description",
          original: original.short_description,
          current: this.shortDescription
        },
        {
          key: "sku",
          original: original.sku,
          current: this.sku
        },
        {
          key: "regular_price",
          original: original.regular_price,
          current: this.regularPrice
        },
        {
          key: "sale_price",
          original: original.sale_price,
          current: this.salePrice
        },
        {
          key: "date_on_sale_from",
          original: original.date_on_sale_from,
          current: this.dateOnSaleFrom
        },
        {
          key: "date_on_sale_to",
          original: original.date_on_sale_to,
          current: this.dateOnSaleTo
        }
      ];
      let changed = false;
      changes.forEach(x => {
        if (x.original !== x.current) {
          changed = true;
          updated.integrations[this.integrationConfig.fullLabel][x.key] =
            x.current;
        }
      });
      if (changed) {
        return updated;
      }
      return null;
    },
    updateProductCache(product) {
      if (product) {
        this.product.integrations = product.integrations;
      }
    },
    async updateProduct() {
      const updated = this.updatedProductData();
      if (!updated) {
        return;
      }
      await updateProduct(this.$apollo, this.product.id, updated)
        .then(async ({ data }) => {
          if (data && data.updateProduct && data.updateProduct.product) {
            this.errorSaving = false;
            const product = data.updateProduct.product;
            this.updateProductCache(product);
          }
        })
        .catch(() => {
          this.errorSaving = true;
        });
    },
    updatedVariantData(position) {
      const variant = this.product.variants[position];
      const original = this.$ifNull(
        variant.integrations[this.integrationConfig.fullLabel],
        {}
      );
      const current = this.productVariants[position].integrations[
        this.integrationConfig.fullLabel
      ];
      const updated = { integrations: {} };
      updated.integrations[this.integrationConfig.fullLabel] = {};
      const changes = [
        {
          key: "sku",
          original: original.sku,
          current: current.sku
        }
      ];
      let changed = false;
      changes.forEach(x => {
        if (x.original !== x.current) {
          changed = true;
          updated.integrations[this.integrationConfig.fullLabel][x.key] =
            x.current;
        }
      });
      if (changed) {
        return updated;
      }
      return null;
    },
    updateVariantCache(position, variant) {
      this.product.variants[position] = variant;
    },
    async updateVariant(position) {
      const updated = this.updatedVariantData(position);
      if (!updated) {
        return;
      }
      await updateVariant(
        this.$apollo,
        this.product.variants[position].id,
        updated
      )
        .then(async ({ data }) => {
          if (data && data.updateVariant && data.updateVariant.variant) {
            const variant = data.updateVariant.variant;
            this.updateVariantCache(position, variant);
          }
        })
        .catch(() => {
          this.errorSaving = true;
        });
    },
    async save() {
      this.saving = true;
      this.errorSaving = false;
      await this.updateProduct();
      for (
        let position = 0;
        position < this.product.variants.length;
        position++
      ) {
        await this.updateVariant(position);
      }
      this.saving = false;
      this.saved = true;
      if (!this.errorSaving) {
        this.changed = false;
        this.$emit("change", false);
      }
    },
    cancel() {
      if (this.changed) {
        this.$swal
          .fire({
            title: "Cancelar",
            text: "Si cancelas, perderás tus cambios. ¿Estás seguro?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "No"
          })
          .then(result => {
            if (result.value) {
              this.$router.push({ name: "Products" });
            }
          });
      } else {
        this.$router.push({ name: "Products" });
      }
    }
  },
  watch: {
    name() {
      this.change();
    },
    status() {
      this.change();
    },
    description() {
      this.change();
    },
    shortDescription() {
      this.change();
    },
    sku() {
      this.change();
    },
    regularPrice() {
      this.change();
    },
    salePrice() {
      this.change();
    },
    dateOnSaleFrom() {
      this.change();
    },
    dateOnSaleTo() {
      this.change();
    }
  }
};
</script>
