<template>
  <b-tr>
    <b-th>{{ category.id }}</b-th>
    <b-th>{{ category.name }}</b-th>
    <b-th>{{ category.path.join(" / ") }}</b-th>
    <b-th>{{ category.children.length ? "No" : "Si" }}</b-th>
    <b-th>
      <b-dropdown variant="white" no-caret="" offset="-110">
        <template v-slot:button-content>
          <b-icon-three-dots-vertical></b-icon-three-dots-vertical>
        </template>
        <b-dropdown-item @click="editCategory">
          Actualizar
        </b-dropdown-item> </b-dropdown
      ><br
    /></b-th>
  </b-tr>
</template>
<script>
import { centryUrl } from "../../../main";
export default {
  name: "VtexCategoriesMaintainerTableRow",
  props: {
    category: Object,
    integrationConfigId: String
  },
  data() {
    return {
      selected: false,
      errorMessage: null,
      centryUrl
    };
  },
  methods: {
    /**
     * Redirecciona a la vista de edición de una catgoria
     */
    editCategory() {
      this.$router.push({
        name: "VtexCategoriesMaintainerNew",
        params: {
          integrationConfigId: this.integrationConfigId,
          id: this.category.id
        }
      });
    }
  },
  watch: {}
};
</script>
