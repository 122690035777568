<template>
  <div>
    <b-table-simple>
      <b-thead>
        <b-tr>
          <b-th />
          <b-th>Nombre</b-th>
          <b-th>Fecha</b-th>
          <b-th>Estado</b-th>
        </b-tr>
      </b-thead>
      <b-tbody v-if="dates">
        <b-tr v-if="!dates">
          <b-td colspan="5">
            No hay días laborales asociados a esta integración
          </b-td>
        </b-tr>
        <mercado-libre-not-working-days-row
          :day="day"
          v-for="(day, idx) of dates"
          :key="idx"
          :index="realIndex(day)"
          :integration-config-id="integrationConfigId"
          @change="emitChange"
        />
      </b-tbody>
    </b-table-simple>
  </div>
</template>
<script>
import MercadoLibreNotWorkingDaysRow from "./Row.vue";
export default {
  name: "MercadoLibreCatalogSuggestionsTable",
  components: {
    MercadoLibreNotWorkingDaysRow
  },
  props: {
    integrationConfigId: String,
    dates: Array
  },
  methods: {
    /**
     * Emite cambio en fila de la tabla de dias no laborales
     * @param {Number} index
     */
    emitChange(index) {
      this.$emit("change", index);
    },
    /**
     * Entrega indice real de el dia no laboral sobre todo el conjunto sin filtros
     * @param {Object} day
     */
    realIndex(day) {
      return day.realIndex;
    }
  }
};
</script>
