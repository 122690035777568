import IntegrationConfig from "../views/IntegrationConfig/IntegrationConfig.vue";
import IntegrationConfigApplication from "../views/IntegrationConfig/IntegrationConfigApplication.vue";
import IntegrationConfigBSale from "../views/IntegrationConfig/IntegrationConfigBSale.vue";
import IntegrationConfigDafiti from "../views/IntegrationConfig/IntegrationConfigDafiti.vue";
import IntegrationConfigDafitiCo from "../views/IntegrationConfig/IntegrationConfigDafitiCo.vue";
import IntegrationConfigFalabella from "../views/IntegrationConfig/IntegrationConfigFalabella.vue";
import IntegrationConfigFalabellaGlobal from "../views/IntegrationConfig/IntegrationConfigFalabellaGlobal.vue";
import IntegrationConfigFalabellaMkp from "../views/IntegrationConfig/IntegrationConfigFalabellaMkp.vue";
import IntegrationConfigJumpseller from "../views/IntegrationConfig/IntegrationConfigJumpseller.vue";
import IntegrationConfigKomax from "../views/IntegrationConfig/IntegrationConfigKomax.vue";
import IntegrationConfigLinio from "../views/IntegrationConfig/IntegrationConfigLinio.vue";
import IntegrationConfigLinioCo from "../views/IntegrationConfig/IntegrationConfigLinioCo.vue";
import IntegrationConfigLinioMx from "../views/IntegrationConfig/IntegrationConfigLinioMx.vue";
import IntegrationConfigLinioPe from "../views/IntegrationConfig/IntegrationConfigLinioPe.vue";
import IntegrationConfigMagento from "../views/IntegrationConfig/IntegrationConfigMagento.vue";
import IntegrationConfigMercadoLibre from "../views/IntegrationConfig/IntegrationConfigMercadoLibre.vue";
import IntegrationConfigMirakl from "../views/IntegrationConfig/IntegrationConfigMirakl.vue";
import IntegrationConfigPrestashop from "../views/IntegrationConfig/IntegrationConfigPrestashop.vue";
import IntegrationConfigShopify from "../views/IntegrationConfig/IntegrationConfigShopify.vue";
import IntegrationConfigWooCommerce from "../views/IntegrationConfig/IntegrationConfigWooCommerce.vue";
import IntegrationConfigParis from "../views/IntegrationConfig/IntegrationConfigParis.vue";
import IntegrationConfigVtex from "../views/IntegrationConfig/IntegrationConfigVtex.vue";
import Webhooks from "../views/Webhooks.vue";
import Webhook from "../views/Webhook.vue";
import WebhookEdit from "../views/WebhookEdit.vue";

export default [
  {
    path: "/integration/:id",
    name: "IntegrationConfigs",
    children: [
      {
        path: "application",
        name: "IntegrationConfigApplication",
        component: IntegrationConfigApplication,
        meta: {
          requireAuth: true,
          action: "Edit Application IntegrationConfig",
          title: "Editar | Aplicación"
        }
      },
      {
        path: "b_sale",
        name: "IntegrationConfigBSale",
        component: IntegrationConfigBSale,
        meta: {
          requireAuth: true,
          action: "Edit BSale IntegrationConfig",
          title: "Editar | BSale"
        }
      },
      {
        path: "dafiti",
        name: "IntegrationConfigDafiti",
        component: IntegrationConfigDafiti,
        meta: {
          requireAuth: true,
          action: "Edit Dafiti IntegrationConfig",
          title: "Editar | Dafiti"
        }
      },
      {
        path: "dafiti_second",
        name: "IntegrationConfigDafitiSecond",
        component: IntegrationConfigDafiti,
        meta: {
          requireAuth: true,
          action: "Edit DafitiSecond IntegrationConfig",
          title: "Editar | DafitiSecond"
        }
      },
      {
        path: "dafiti_co",
        name: "IntegrationConfigDafitiCo",
        component: IntegrationConfigDafitiCo,
        meta: {
          requireAuth: true,
          action: "Edit DafitiCo IntegrationConfig",
          title: "Editar | DafitiCo"
        }
      },
      {
        path: "falabella",
        name: "IntegrationConfigFalabella",
        component: IntegrationConfigFalabella,
        meta: {
          requireAuth: true,
          action: "Edit Falabella IntegrationConfig",
          title: "Editar | Falabella"
        }
      },
      {
        path: "falabella_global_production",
        name: "IntegrationConfigFalabellaGlobalProduction",
        component: IntegrationConfigFalabellaGlobal,
        meta: {
          requireAuth: true,
          action: "Edit FalabellaGlobalProduction IntegrationConfig",
          title: "Editar | Falabella Global Production"
        }
      },
      {
        path: "falabella_global_production_peru",
        name: "IntegrationConfigFalabellaGlobalProductionPeru",
        component: IntegrationConfigFalabellaGlobal,
        meta: {
          requireAuth: true,
          action: "Edit FalabellaGlobalProduction IntegrationConfig",
          title: "Editar | Falabella Global Production Perú"
        }
      },
      {
        path: "falabella_global_production_colombia",
        name: "IntegrationConfigFalabellaGlobalProductionColombia",
        component: IntegrationConfigFalabellaGlobal,
        meta: {
          requireAuth: true,
          action: "Edit FalabellaGlobalProduction IntegrationConfig",
          title: "Editar | Falabella Global Production Colombia"
        }
      },
      {
        path: "falabella_global_staging",
        name: "IntegrationConfigFalabellaGlobalStaging",
        component: IntegrationConfigFalabellaGlobal,
        meta: {
          requireAuth: true,
          action: "Edit FalabellaGlobalStaging IntegrationConfig",
          title: "Editar | Falabella Global Staging"
        }
      },
      {
        path: "falabella_mkp_cl",
        name: "IntegrationConfigFalabellaMkpCL",
        component: IntegrationConfigFalabellaMkp,
        meta: {
          requireAuth: true,
          action: "Edit FalabellaMkp IntegrationConfig",
          title: "Editar | Falabella Mkp"
        }
      },
      {
        path: "falabella_mkp_cl_preprod",
        name: "IntegrationConfigFalabellaMkpCLPreprod",
        component: IntegrationConfigFalabellaMkp,
        meta: {
          requireAuth: true,
          action: "Edit FalabellaMkpPreprod IntegrationConfig",
          title: "Editar | Falabella Mkp Preprod"
        }
      },
      {
        path: "jumpseller",
        name: "IntegrationConfigJumpseller",
        component: IntegrationConfigJumpseller,
        meta: {
          requireAuth: true,
          action: "Edit Jumpseller IntegrationConfig",
          title: "Editar | Jumpseller"
        }
      },
      {
        path: "komax",
        name: "IntegrationConfigKomax",
        component: IntegrationConfigKomax,
        meta: {
          requireAuth: true,
          action: "Edit Komax IntegrationConfig",
          title: "Editar | Komax"
        }
      },
      {
        path: "linio",
        name: "IntegrationConfigLinio",
        component: IntegrationConfigLinio,
        meta: {
          requireAuth: true,
          action: "Edit Linio IntegrationConfig",
          title: "Editar | Linio"
        }
      },
      {
        path: "linio_co",
        name: "IntegrationConfigLinioCo",
        component: IntegrationConfigLinioCo,
        meta: {
          requireAuth: true,
          action: "Edit LinioCo IntegrationConfig",
          title: "Editar | LinioCo"
        }
      },
      {
        path: "linio_mx",
        name: "IntegrationConfigLinioMx",
        component: IntegrationConfigLinioMx,
        meta: {
          requireAuth: true,
          action: "Edit LinioMx IntegrationConfig",
          title: "Editar | LinioMx"
        }
      },
      {
        path: "linio_pe",
        name: "IntegrationConfigLinioPe",
        component: IntegrationConfigLinioPe,
        meta: {
          requireAuth: true,
          action: "Edit LinioPe IntegrationConfig",
          title: "Editar | LinioPe"
        }
      },
      {
        path: "linio_second",
        name: "IntegrationConfigLinioSecond",
        component: IntegrationConfigLinio,
        meta: {
          requireAuth: true,
          action: "Edit LinioSecond IntegrationConfig",
          title: "Editar | LinioSecond"
        }
      },
      {
        path: "magento",
        name: "IntegrationConfigMagento",
        component: IntegrationConfigMagento,
        meta: {
          requireAuth: true,
          action: "Edit Magento IntegrationConfig",
          title: "Editar | Magento"
        }
      },
      {
        path: "mercado_libre",
        name: "IntegrationConfigMercadoLibre",
        component: IntegrationConfigMercadoLibre,
        meta: {
          requireAuth: true,
          action: "Edit MercadoLibre IntegrationConfig",
          title: "Editar | Mercado Libre"
        }
      },
      {
        path: "mercado_libre_mx",
        name: "IntegrationConfigMercadoLibreMx",
        component: IntegrationConfigMercadoLibre,
        meta: {
          requireAuth: true,
          action: "Edit MercadoLibreMx IntegrationConfig",
          title: "Editar | Mercado Libre Mx"
        }
      },
      {
        path: "mercado_libre_ar",
        name: "IntegrationConfigMercadoLibreAr",
        component: IntegrationConfigMercadoLibre,
        meta: {
          requireAuth: true,
          action: "Edit MercadoLibreAr IntegrationConfig",
          title: "Editar | Mercado Libre Ar"
        }
      },
      {
        path: "mercado_libre_co",
        name: "IntegrationConfigMercadoLibreCo",
        component: IntegrationConfigMercadoLibre,
        meta: {
          requireAuth: true,
          action: "Edit MercadoLibreCo IntegrationConfig",
          title: "Editar | Mercado Libre Co"
        }
      },
      {
        path: "mercado_libre_pe",
        name: "IntegrationConfigMercadoLibrePe",
        component: IntegrationConfigMercadoLibre,
        meta: {
          requireAuth: true,
          action: "Edit MercadoLibrePe IntegrationConfig",
          title: "Editar | Mercado Libre Pe"
        }
      },
      {
        path: "ripley",
        name: "IntegrationConfigMiraklRipley",
        component: IntegrationConfigMirakl,
        meta: {
          requireAuth: true,
          action: "Edit Ripley IntegrationConfig",
          title: "Editar | Ripley"
        }
      },
      {
        path: "ripley_peru",
        name: "IntegrationConfigMiraklRipleyPe",
        component: IntegrationConfigMirakl,
        meta: {
          requireAuth: true,
          action: "Edit RipleyPeru IntegrationConfig",
          title: "Editar | Ripley Peru"
        }
      },
      {
        path: "ripley_preprod",
        name: "IntegrationConfigMiraklRipleyPreprod",
        component: IntegrationConfigMirakl,
        meta: {
          requireAuth: true,
          action: "Edit RipleyPreprod IntegrationConfig",
          title: "Editar | Ripley Preprod"
        }
      },
      {
        path: "shopify",
        name: "IntegrationConfigShopify",
        component: IntegrationConfigShopify,
        meta: {
          requireAuth: true,
          action: "Edit Shopify IntegrationConfig",
          title: "Editar | Shopify"
        }
      },
      {
        path: "prestashop",
        name: "IntegrationConfigPrestashop",
        component: IntegrationConfigPrestashop,
        meta: {
          requireAuth: true,
          action: "Edit Prestahsop IntegrationConfig",
          title: "Editar | Prestashop"
        }
      },
      {
        path: "woo_commerce",
        name: "IntegrationConfigWooCommerce",
        component: IntegrationConfigWooCommerce,
        meta: {
          requireAuth: true,
          action: "Edit WooCommerce IntegrationConfig",
          title: "Editar | WooCommerce"
        }
      },
      {
        path: "paris",
        name: "IntegrationConfigParis",
        component: IntegrationConfigParis,
        meta: {
          requireAuth: true,
          action: "Edit Paris IntegrationConfig",
          title: "Editar | Paris"
        }
      },
      {
        path: "paris_preprod",
        name: "IntegrationConfigParisPreprod",
        component: IntegrationConfigParis,
        meta: {
          requireAuth: true,
          action: "Edit ParisPreprod IntegrationConfig",
          title: "Editar | Paris Preprod"
        }
      },
      {
        path: "vtex",
        name: "IntegrationConfigVtex",
        component: IntegrationConfigVtex,
        meta: {
          requireAuth: true,
          action: "Edit Vtex IntegrationConfig",
          title: "Editar | Vtex"
        }
      },
      {
        path: "*",
        redirect: () => {
          // Si se intena acceder a un integration_config no implementado
          return { path: "/products" };
        }
      }
    ],
    component: IntegrationConfig,
    meta: {
      requireAuth: true,
      action: "IntegrationConfig"
    }
  },
  {
    path: "/integrations/webhooks",
    name: "Webhooks",
    component: Webhooks,
    meta: {
      requireAuth: true,
      action: "Webhooks"
    },
    props: true
  },
  {
    path: "/integrations/webhook",
    name: "Webhook",
    children: [
      {
        path: "new",
        name: "WebhookNew",
        component: WebhookEdit,
        meta: {
          requireAuth: true,
          action: "Crear"
        }
      },
      {
        path: ":id/edit",
        name: "WebhookEdit",
        component: WebhookEdit,
        meta: {
          requireAuth: true,
          action: "Editar"
        }
      }
    ],
    component: Webhook
  }
];
