<template>
  <div>
    <p class="text-justify">
      Este número permite manipular el precio de los productos de manera que se
      aproximen a la cifra que uno quiera. Esta herramienta es epecialmente útil
      para cuando se use el factor de precio.
    </p>
    <p class="text-justify">
      Veamos el siguiente ejemplo: Supongamos que tenemos un producto con un
      precio de <code>$7.500</code> y que se le aplica un factor de precio para
      aumentar un <code>15%</code>. De este modo el precio resultaría ser:
    </p>
    <p class="text-justify">
      <code>$7.500</code>
      &times;
      <code>1,15</code>
      =
      <code>$8.625</code>
    </p>
    <p class="text-justify">
      El redondeo de precio permite aproximar esa número al monto que se estime
      conveniente. Para seguir con el ejemplo, se podría fijar el redondeo a
      <code>990</code>, con eso el precio de publicación sería de
      <code>$8.990</code>.
    </p>
    <p class="text-justify">
      A continuación puedes usar una herramienta que te permitirá visualizar el
      efecto de combinar el factor de precio con el redondeo.
    </p>
    <b-table-simple style="margin: 10px auto;">
      <b-thead>
        <b-th></b-th>
        <b-th>Precio original</b-th>
        <b-th></b-th>
        <b-th>Factor de precio</b-th>
        <b-th></b-th>
        <b-th>Redondeo</b-th>
        <b-th></b-th>
        <b-th>Resultado</b-th>
      </b-thead>
      <b-tbody>
        <b-td style="padding: 5px">(</b-td>
        <b-td style="width: 150px">
          <b-form-input
            id="ex-price"
            type="number"
            number
            min="0"
            step="1"
            v-model="exPrice"
          />
        </b-td>
        <b-td style="padding: 5px">&times;</b-td>
        <b-td style="width: 150px">
          <b-form-input
            id="ex-factor"
            type="number"
            number
            min="0"
            step="0.01"
            v-model="exFactor"
          />
        </b-td>
        <b-td style="padding: 5px">) →</b-td>
        <b-td style="width: 150px">
          <b-form-input
            id="ex-round"
            type="number"
            number
            min="0"
            step="1"
            v-model="exRound"
          />
        </b-td>
        <b-td style="padding: 5px">=</b-td>
        <b-td
          >$<code id="ex-result">{{ rounded }}</code></b-td
        >
      </b-tbody>
    </b-table-simple>
    <p class="text-justify">
      <strong><u>Advertencia:</u></strong>
    </p>
    <p class="text-justify">
      Este herramienta aproxima el monto del producto a la cifra más cercana, la
      cual puede ser inferior al precio original del producto.
    </p>
    <p class="text-justify">
      Por ejemplo, si tenemos un producto con un precio de
      <code>$3.200</code>, se le aplica un factor de precio de
      <code>1,08</code> (aumento de 8%) y se redondea a <code>$990</code>.
      Entonces el resultado sería de <code>$2.990</code>. Esto es así porque
      <code>$3.200</code>&times;<code>1,08</code> = <code>3.456</code> está más
      cerda de <code>$2.990</code> que de <code>$3.990</code>
    </p>
  </div>
</template>

<script>
export default {
  name: "HelpPriceRoundFormatModal",
  data() {
    return {
      exPrice: 7500,
      exFactor: 1.15,
      exRound: 990
    };
  },
  computed: {
    rounded() {
      return this.roundedPrice(this.exPrice * this.exFactor, this.exRound);
    }
  },
  methods: {
    /**
     * Se encarga de redondear el precio
     * segun el precio y el redondeo
     * @param {Integer} price
     * @param {Integer} rest
     */
    roundedPrice(price, rest) {
      if (rest == "") {
        return price;
      }
      if (price < rest) {
        return rest;
      }
      let lgth = ("" + rest).length;
      let mll = Math.pow(10, lgth);
      let corr = mll - rest;
      return Math.round((price + corr) / mll) * mll - corr;
    }
  }
};
</script>

<style scoped>
code {
  font-size: 100%;
}
</style>
