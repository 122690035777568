<template>
  <div class="accordion" role="tablist" id="bsale-accordion">
    <base-collapse-card
      id="general-doc-data"
      accordion="bsale-accordion"
      :visible="true"
    >
      <template slot="title">
        <b-icon-circle-fill
          variant="warning"
          font-scale="0.8"
          class="mr-2"
          v-if="generalDocumentDataChanged"
          title="Hay cambios en esta pestaña"
        />
        Configuración General de Documentos
      </template>
      <integration-configs-b-sale-general-document-data
        v-model="value.generalDocumentData"
        :original-data="originalData.generalDocumentData"
        :allow-save="allowSave"
        :ic="ic"
        @change="emitValue"
        @success-save-client="successSave"
        :use="use"
      />
    </base-collapse-card>
    <base-collapse-card
      id="product-doc-data"
      accordion="bsale-accordion"
      :visible="true"
    >
      <template slot="title">
        <b-icon-circle-fill
          variant="warning"
          font-scale="0.8"
          class="mr-2"
          v-if="productDataChanged"
        />
        Configuración de Productos
      </template>
      <integration-configs-b-sale-product-data
        v-model="value.productData"
        :original-data="originalData.productData"
        :allow-save="allowSave"
        :ic="ic"
        @change="emitValue"
        :use="use"
      />
    </base-collapse-card>
    <base-collapse-card
      id="sales-doc-data"
      accordion="bsale-accordion"
      :visible="true"
      v-if="notSlave"
    >
      <template slot="title">
        <b-icon-circle-fill
          variant="warning"
          font-scale="0.8"
          class="mr-2"
          v-if="salesDocumentDataChanged"
        />
        Configuración de Documentos de Venta
      </template>
      <integration-configs-b-sale-sales-document-data
        v-model="value.salesDocumentData"
        :original-data="originalData.salesDocumentData"
        :allow-save="allowSave"
        @change="emitValue"
        :loading="loadingDocumentTypes || loadingTaxes"
        :error-loading-taxes="errorLoadingTaxes"
        :error-loading-document-types="errorLoadingDocumentTypes"
        :taxes-in-document-options="taxesInDocumentOptions"
        :type-document-options="typeDocumentOptions"
      />
    </base-collapse-card>
    <base-collapse-card
      id="other-doc-data"
      accordion="bsale-accordion"
      :visible="true"
      title="Otros"
      v-if="notSlave"
    >
      <template slot="title">
        <b-icon-circle-fill
          variant="warning"
          font-scale="0.8"
          class="mr-2"
          v-if="otherDataChanged"
        />
        Otros Datos
      </template>
      <integration-configs-b-sale-other-data
        v-model="value.otherData"
        :original-data="originalData.otherData"
        :allow-save="allowSave"
        @change="emitValue"
        :loading="loadingDocumentTypes"
        :error-loading="errorLoadingDocumentTypes"
        :type-debit-options="typeDebitOptions"
        :type-return-options="typeReturnOptions"
      />
    </base-collapse-card>
  </div>
</template>
<script>
import IntegrationConfigsBSaleGeneralDocumentData from "./GeneralDocumentData.vue";
import IntegrationConfigsBSaleProductData from "./ProductData.vue";
import IntegrationConfigsBSaleSalesDocumentData from "./SalesDocumentData.vue";
import IntegrationConfigsBSaleOtherData from "./OtherData.vue";
import BaseCollapseCard from "../../Base/CollapseCard.vue";
import INTEGRATION_DATA_SALES_OPTIONS from "../../../graphql/IntegrationConfig/BSale/IntegrationDataSalesOptions.gql";

import { mapState } from "vuex";
export default {
  name: "IntegrationConfigsBSaleIntegrationDataAccordion",
  components: {
    IntegrationConfigsBSaleGeneralDocumentData,
    IntegrationConfigsBSaleProductData,
    IntegrationConfigsBSaleSalesDocumentData,
    IntegrationConfigsBSaleOtherData,
    BaseCollapseCard
  },
  props: {
    originalData: Object,
    allowSave: Boolean,
    _value: Object,
    ic: Object,
    use: String
  },
  model: {
    event: "input",
    prop: "_value"
  },
  created() {
    this.setValue(this.originalData);
    if (this.notSlave) this.loadSalesAndOtherData();
  },
  data() {
    return {
      value: {
        generalDocumentData: {},
        productData: {},
        salesDocumentData: {},
        otherData: {}
      },
      loadingTaxes: true,
      loadingDocumentTypes: true,
      errorLoadingTaxes: "",
      errorLoadingDocumentTypes: "",
      taxesInDocumentOptions: [],
      allTypeDocuments: []
    };
  },
  computed: {
    ...mapState(["currentUser"]),
    notSlave() {
      return this.use !== "slave";
    },
    typeDocumentOptions() {
      return this.toOptionList(
        this.allTypeDocuments.filter(elem => elem.isSalesNote === 0)
      );
    },
    typeDebitOptions() {
      return this.toOptionList(
        this.allTypeDocuments.filter(elem => elem.use === 4)
      );
    },
    typeReturnOptions() {
      return this.toOptionList(
        this.allTypeDocuments.filter(elem => elem.use === 1)
      );
    },
    generalDocumentDataChanged() {
      return !this.$objSimpleCompare(
        this.originalData.generalDocumentData,
        this.value.generalDocumentData
      );
    },
    productDataChanged() {
      return !this.$objSimpleCompare(
        this.originalData.productData,
        this.value.productData
      );
    },
    salesDocumentDataChanged() {
      return !this.$objDeepCompare(
        this.originalData.salesDocumentData,
        this.value.salesDocumentData
      );
    },
    otherDataChanged() {
      return !this.$objSimpleCompare(
        this.originalData.otherData,
        this.value.otherData
      );
    },
    changed() {
      return (
        this.generalDocumentDataChanged ||
        this.productDataChanged ||
        this.salesDocumentDataChanged ||
        this.otherDataChanged
      );
    }
  },
  methods: {
    /**
     * Retorna un listado de opciones para
     * selectores a partir del listado original
     * @param {Array} list
     * @returns {Array}
     */
    toOptionList(list) {
      return list.map(elem => {
        return { value: parseInt(elem.id), label: elem.name, all: elem };
      });
    },
    /**
     * Setea el valor sin gatillar evento
     * de cambio en el modelo (para evitar loops)
     * @param {Object} value
     */
    setValue(value) {
      this.value.generalDocumentData = this.$dup(value.generalDocumentData);
      this.value.productData = this.$dup(value.productData);
      this.value.salesDocumentData = this.$dup(value.salesDocumentData);
      this.value.otherData = this.$dup(value.otherData);
    },
    /**
     * Emite el valor para generar un
     * cambio en el modelo
     */
    emitValue() {
      this.$emit("input", this.value);
    },
    /**
     * Se carga la información de tipos de documentos
     * y taxes
     */
    loadSalesAndOtherData() {
      this.loadingTaxes = true;
      this.loadingDocumentTypes = true;
      this.$apollo
        .query({
          query: INTEGRATION_DATA_SALES_OPTIONS,
          variables: {
            integrationConfigId: this.ic.id
          }
        })
        .then(({ data }) => {
          this.setTaxesOptionsFromQuery(data);
          this.setDocumentTypeOptionsFromQuery(data);
        })
        .catch(() => {
          this.taxesInDocumentOptions = [];
          this.allTypeDocuments = [];
          this.errorLoadingTaxes =
            "No se pudo obtener el listado de impuestos de BSale. Revise los datos de vinculación";
          this.loadingTaxes = false;
          this.errorLoadingDocumentTypes =
            "No se pudo obtener el listado de tipos de documentos de BSale. Revise los datos de vinculación";
          this.loadingDocumentTypes = false;
        });
    },
    /**
     * Se setea la variable de "taxesInDocumentOptions",
     * y si no se puede, se setea un error
     * @param {Object} data
     */
    setTaxesOptionsFromQuery(data) {
      const taxesArray = this.$dig(data, "integrationConfigBSale", "taxes");
      if (!taxesArray) {
        this.errorLoadingTaxes =
          "No se pudo cargar el listado de impuestos. Revise su integración con BSale";
      } else {
        this.taxesInDocumentOptions = taxesArray.map(option => {
          return { value: parseInt(option.id), label: option.name };
        });
        this.errorLoadingTaxes = "";
      }
      this.loadingTaxes = false;
    },
    /**
     * Se setea la variable de "allTypeDocuments",
     * y si no se puede, se setea un error
     * @param {Object} data
     */
    setDocumentTypeOptionsFromQuery(data) {
      const documentTypesArray = this.$dig(
        data,
        "integrationConfigBSale",
        "allTypeDocuments"
      );
      if (!documentTypesArray) {
        this.errorLoadingDocumentTypes =
          "No se pudo cargar el listado de documentos. Revise su integración con BSale";
      } else {
        this.allTypeDocuments = documentTypesArray;
        this.errorLoadingDocumentTypes = "";
      }
      this.loadingDocumentTypes = false;
    },
    /**
     * Emite a componente padre que se guardo exitosamente el cliente por defecto
     */
    successSave() {
      this.$emit("success-save-client");
    }
  },
  watch: {
    changed(val) {
      this.$emit("change", val);
    },
    _value(val) {
      this.setValue(val);
    },
    value() {
      this.emitValue();
    }
  }
};
</script>
