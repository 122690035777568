<template>
  <div>
    <vtex-base-promotion-collections-and-skus
      v-model="promotion"
      :vtexIntegrationConfigId="vtexIntegrationConfigId"
      @filledRequiredFields="setSkusRequiredFields"
    >
      <b-form-group label="Cantidad minima de productos*">
        <b-form-input type="number" min="0" v-model="minimumQuantity">
        </b-form-input>
        <span v-if="requiredMessage.minimumQuantity" class="text-danger">
          Una cantidad minima es requerida
        </span>
      </b-form-group>
    </vtex-base-promotion-collections-and-skus>
  </div>
</template>

<script>
import VtexBasePromotionCollectionsAndSkus from "./VtexBasePromotionCollectionsAndSkus.vue";

export default {
  name: "VtexPromotionBuyAndWinItems",
  components: {
    VtexBasePromotionCollectionsAndSkus
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    vtexIntegrationConfigId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      promotion: this.value,
      minimumQuantity: this.value.minimumQuantityBuyTogether,
      filledRequiredFields: false,
      skusRequiredFields: false,
      quantityRequiredField: false
    };
  },
  methods: {
    /**
     * Emite si los datos obligatorios han sido completados.
     * @param {Boolean} value - Valor a emitir.
     */
    emitFilledRequiredFields(value) {
      this.$emit("filledRequiredFields", value);
    },
    /**
     * Guarda el valor emitido, correspondiente a si los campos obligatorios
     * de los skus de la promoción han sido completados.
     * @param {Boolean} value - valor emitido.
     */
    setSkusRequiredFields(value) {
      this.skusRequiredFields = value;
    }
  },
  computed: {
    /**
     * Revisa si los atributos obligatorios de la promoción en la vista estan completos
     * para saber donde mostrar los mensajes de requerido.
     * @returns {Object} - Objeto con los atributos obligatorios y si requieren mensaje.
     */
    requiredMessage() {
      let required = {};
      required.minimumQuantity =
        !this.minimumQuantity || parseInt(this.minimumQuantity) === 0;
      return required;
    }
  },
  /**
   * En general, el objetivo de los watches es cambiar el valor de una propiedad
   * de la promocion cuando se cambia su correspondiente en la vista
   */
  watch: {
    value(newValue) {
      this.promotion = newValue;
    },
    promotion: {
      handler(newValue) {
        this.$emit("input", newValue);
      },
      deep: true
    },
    minimumQuantity(newValue) {
      this.promotion.minimumQuantityBuyTogether = parseInt(newValue);
    },
    /**
     * Si cambia el valor de requiredMessage, se revisa si no se necesita
     * ningun mensaje y se guarda este valor en filledRequiredFields.
     */
    requiredMessage: {
      handler(newValue) {
        this.quantityRequiredField = !Object.values(newValue).includes(true);
      },
      immediate: true
    },
    /**
     * Si cambia el valor de filledRequiredFields, se emite este valor al padre.
     * @param {Boolean} value - Valor a emitir.
     */
    filledRequiredFields(newValue) {
      this.emitFilledRequiredFields(newValue);
    },
    /**
     * Si cambia skusRequiredFields se revisa si ya se cumplen todos
     * los campos requeridos y se asigna este valor en filledRequiredFields.
     * @param {Boolean} newValue - Si los campos obligatorios de skus estan completos.
     */
    skusRequiredFields(newValue) {
      if (this.quantityRequiredField && newValue) {
        this.filledRequiredFields = true;
      } else {
        this.filledRequiredFields = false;
      }
    },
    /**
     * Si cambia quantityRequiredField se revisa si ya se cumplen todos
     * los campos requeridos y se asigna este valor en filledRequiredFields.
     * @param {Boolean} newValue - Si los campos obligatorios de cantidad estan completos.
     */
    quantityRequiredField(newValue) {
      if (this.skusRequiredFields && newValue) {
        this.filledRequiredFields = true;
      } else {
        this.filledRequiredFields = false;
      }
    }
  }
};
</script>
