var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ApolloQuery',{attrs:{"query":require('../../graphql/IntegrationConfigsForTabs.gql')},on:{"result":_vm.checkResultIc},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.result.data;
return [(!_vm.loading)?_c('h4',[_vm._v(_vm._s(_vm.prettyTitle))]):_vm._e(),(_vm.loading || !data)?_c('b-spinner',{attrs:{"label":"Spinning"}}):_vm._l((_vm.integrationConfigs),function(ic){return _c('b-list-group',{key:ic.id,attrs:{"title":ic.label,"lazy":""}},[(_vm.isMercadoLibre(ic))?_c('b-list-group-item',{staticClass:"flex-column align-items-start",attrs:{"to":{
            name: _vm.urlName,
            params: {
              integrationConfigId: ic.id
            }
          }}},[_c('div',{staticStyle:{"display":"flex","flex-direction":"column"}},[_c('div',{staticClass:"card-image",staticStyle:{"display":"flex","justify-content":"center"}},[_c('img',{staticStyle:{"width":"100px"},attrs:{"src":require('../../assets/images/meli.png')}})]),_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('h5',{staticClass:"mb-1"},[_vm._v(_vm._s(ic.label))])])])]):_vm._e()],1)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }