<template>
  <b-button
    variant="outline-secondary"
    class="mr-1 mb-1"
    v-b-tooltip.hover
    title="Imprimir etiqueta de despacho"
    @click="getShippingLabel"
  >
    <b-spinner label="Spinning" v-if="loading" small></b-spinner>
    <b-icon-file-arrow-down v-else />
  </b-button>
</template>
<script>
import ALL_SHIPPING_LABELS from "@/graphql/AllShippingLabels.gql";

export default {
  name: "OrderShippingLabelButton",
  props: {
    order: Object
  },
  data() {
    return {
      loading: false
    };
  },
  methods: {
    getShippingLabel() {
      this.loading = true;
      this.$apollo
        .query({
          query: ALL_SHIPPING_LABELS,
          variables: {
            ids: [this.order.id]
          }
        })
        .then(({ data }) => {
          if (data.allShippingLabels.result) {
            const { filesArray, warnings } = this.processBody(
              data.allShippingLabels.body
            );
            if (Object.keys(warnings).length > 0) {
              this.warningsSwal(warnings, filesArray);
            } else {
              this.$downloadBASE64(filesArray);
            }
          } else if (data.allShippingLabels.errors) {
            let errorsArray = [];
            data.allShippingLabels.body.forEach(group => {
              group.failed.forEach(f => errorsArray.push(f));
            });
            this.errorsSwal(errorsArray);
          } else {
            this.genericErrorSwal();
          }
        })
        .then(() => (this.loading = false));
    },
    /**
     * Procesa el body obtenido en la respuesta de ALL_SHIPPING_LABELS.
     * @param {Object} body
     * @return {Object}
     */
    processBody(body) {
      const filesArray = [];
      const warnings = {};
      body.forEach(group => {
        group.files.forEach(file => filesArray.push(file));
        if (group.warnings) {
          Object.entries(group.warnings).forEach(([document_type, warning]) => {
            if (!Object.hasOwn(document_type)) {
              warnings[document_type] = [];
            }
            warnings[document_type].push(warning);
          });
        }
      });
      return { filesArray: filesArray, warnings: warnings };
    },
    /**
     * Muestra el modal con las advertencias obtenidas en el shipping label
     * y da la opción de descargar los documentos obtenidos
     * @param {Object} warnings
     * @param {Array} filesArray
     */
    warningsSwal(warnings, filesArray) {
      this.$swal
        .fire({
          title: "Algunos documentos no están disponibles",
          html: this.warningsHTML(warnings),
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: "Descargar documentos obtenidos"
        })
        .then(result => {
          if (result.isConfirmed) {
            this.$downloadBASE64(filesArray);
          }
        });
    },
    /**
     * Construye el html utilizado por el warningsSwal
     * @param {Object} warning
     * @return {String}
     */
    warningsHTML(warnings) {
      return (
        "<div style='text-align: left;'><ul>" +
        Object.entries(warnings)
          .map(([document, errors]) => {
            return `<li>${document}: ${errors.join(", ")}</li>`;
          })
          .join("") +
        "</ul></div>"
      );
    },
    /**
     * Muestra el modal con los errores recibidos en la respuesta
     * @param {Array} errorsArray
     */
    errorsSwal(errorsArray) {
      this.$swal.fire({
        title: "Error al descargar etiqueta",
        html: this.errorsHTML(errorsArray),
        icon: "error"
      });
    },
    /**
     * Construye el html utilizado por el errorsSwal
     * @param {Array} errorsArray
     * @return {String}
     */
    errorsHTML(errorsArray) {
      return (
        "<p>Las siguientes etiquetas no están disponibles para ser descargadas:</p>" +
        `<p><ul><li>
            ${errorsArray.map(o => `${o.errors}`).join("</li><li>")}
        </li></ul></p>`
      );
    },
    /**
     * Muestra un modal con un error generico
     */
    genericErrorSwal() {
      this.$swal.fire({
        title: "Error al descargar etiqueta",
        text: "No hay etiquetas para descargar",
        icon: "error"
      });
    }
  }
};
</script>
