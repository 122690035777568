<template>
  <div>
    <b-alert
      v-if="error || success"
      show=""
      :variant="error ? 'danger' : 'success'"
      dismissible
    >
      {{
        error
          ? "Ha ocurrido un error guardando los datos: " + errorMessage
          : "Guardado exitosamente"
      }}
    </b-alert>
    <div align-h="between">
      <b-row>
        <b-col xl="10" cols="12">
          <b-row>
            <b-col>
              <h4 class="font-weight-bold my-auto">
                Bodegas sin usar
              </h4>
            </b-col>
            <b-col>
              <h4 class="font-weight-bold my-auto">
                Bodegas de las cuales tomar stock
              </h4>
            </b-col>
          </b-row>

          <hr />
          <b-row v-if="loading">
            <b-col>
              <b-skeleton
                v-for="i in 2"
                :key="i"
                animation="wave"
                width="100%"
                height="40px"
              />
            </b-col>
            <b-col>
              <b-skeleton
                v-for="i in 2"
                :key="i"
                animation="wave"
                width="100%"
                height="40px"
              />
            </b-col>
          </b-row>
          <b-row v-else>
            <b-col>
              <draggable
                v-model="warehousesNotUsed"
                tag="tbody"
                v-bind="dragOptions"
                handle=".handle"
                style="display: flex; flex-direction: column; height: 100%"
                @change="onDropToNotUsed"
              >
                <warehouse-container
                  v-for="(warehouseNotUsed, i) in warehousesNotUsed"
                  :key="i"
                  :warehouse="warehouseNotUsed.warehouse"
                  :locations="locations"
                  :locationId.sync="warehouseNotUsed.locationId"
                  @use="() => use(i)"
                />
              </draggable>
            </b-col>
            <b-col>
              <draggable
                v-model="warehousesUsed"
                tag="tbody"
                v-bind="dragOptions"
                handle=".handle"
                style="display: flex; flex-direction: column; height: 100%"
              >
                <warehouse-container
                  v-for="(warehouseUsed, i) in warehousesUsed"
                  :key="i"
                  :warehouse="warehouseUsed.warehouse"
                  :locations="locations"
                  :locationId.sync="warehouseUsed.locationId"
                  :using="true"
                  @stopUsing="() => stopUsing(i)"
                />
                <div
                  v-if="warehousesUsed.length == 0"
                  no-body
                  style="width: 100% !important"
                ></div>
              </draggable>
            </b-col>
          </b-row>
          <b-row class="mt-4">
            <b-col cols="6" />
            <b-col cols="6">
              <span style="color: #aab1b5;">
                * De la primera bodega de la lista se tomará el stock cuando se
                registre un pedido. Cuando se agote el stock de la primera se
                seguirá con la segunda y así sucesivamente.
              </span>
            </b-col>
          </b-row>
        </b-col>

        <b-col xl="2" cols="12">
          <b-button
            variant="info"
            :disabled="!changed || saving"
            :title="changed ? '' : 'No hay cambios'"
            @click="save"
            class="mb-3 w-100"
          >
            <span v-if="saving"><b-spinner label="Spinning"></b-spinner></span>
            <span v-else>Guardar</span>
          </b-button>
          <b-button
            variant="outline-info"
            class="w-100 px-0"
            :disabled="!changed || saving"
            @click="resetData()"
          >
            <span>Restablecer</span>
          </b-button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import UPDATE_INTEGRATION_CONFIG from "../../graphql/IntegrationConfig/UpdateIntegrationConfig.gql";
import WAREHOUSES from "../../graphql/IntegrationConfig/Warehouses.gql";
import WarehouseContainer from "./WarehouseContainer.vue";
import { mapMutations } from "vuex";
export default {
  name: "IntegrationConfigWarehouses",
  components: {
    WarehouseContainer
  },
  props: {
    ic: {
      type: Object,
      default() {
        return {};
      }
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      originalWarehousesUsed: [],
      warehousesUsed: [],
      originalWarehousesNotUsed: [],
      warehousesNotUsed: [],
      locations: [],
      loading: false,
      saving: false,
      errorMessage: null,
      error: false,
      success: false,
      changed: false
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },
  async mounted() {
    this.loading = true;
    await this.getWarehouses();
  },
  methods: {
    ...mapMutations(["setIntegrationConfigChanged"]),
    /**
     * Query que se encarga de obtener las bodegas usadas y no
     */
    async getWarehouses() {
      await this.$apollo
        .query({
          query: WAREHOUSES,
          variables: {
            integrationConfigId: this.ic.id
          }
        })
        .then(({ data }) => {
          let warehousesUsed = this.$dup(
            data?.integrationConfig?.warehousesUsed
          );
          let tempWarehousesUsed = [];
          warehousesUsed.forEach(val => {
            delete val.__typename;
            delete val.warehouse.__typename;
            tempWarehousesUsed.push(val);
          });
          let warehousesNotUsed = this.$dup(
            data?.integrationConfig?.warehousesNotUsed
          );
          let tempWarehousesNotUsed = [];
          warehousesNotUsed.forEach(val => {
            delete val.__typename;
            tempWarehousesNotUsed.push({
              warehouse: val,
              locationId: null,
              id: null
            });
          });
          let locations = this.$dup(data?.integrationConfig?.locations || []);
          let tempLocations = [];
          locations.forEach(val => {
            delete val.__typename;
            tempLocations.push(val);
          });
          if (tempLocations.length > 0) {
            tempLocations.unshift({
              id: null,
              name: "-- Seleccione sucursal --"
            });
          }
          this.warehousesUsed = this.$dup(tempWarehousesUsed);
          this.originalWarehousesUsed = this.$dup(this.warehousesUsed);
          this.warehousesNotUsed = this.$dup(tempWarehousesNotUsed);
          this.originalWarehousesNotUsed = this.$dup(this.warehousesNotUsed);
          this.locations = this.$dup(tempLocations);
          this.loading = false;
        });
    },
    /**
     * Envia la mutacion para guardar los nuevos
     * valores de los campos para la sinncronizacion
     */
    save() {
      this.saving = true;
      this.errorMessage = null;
      this.error = false;
      this.success = false;
      let newWarehousesUsed = [];
      let errorForm = false;
      this.warehousesUsed.forEach((val, i) => {
        errorForm ||= val.locationId == null;
        newWarehousesUsed.push({
          priority: i,
          warehouseId: val.warehouse.id,
          locationId: val.locationId
        });
      });
      if (
        (this.locations.length > 0 && !errorForm) ||
        (this.locations.length == 0 && errorForm)
      ) {
        this.$apollo
          .mutate({
            mutation: UPDATE_INTEGRATION_CONFIG,
            variables: {
              integrationConfigId: this.ic.id,
              patch: { warehousesUsed: newWarehousesUsed }
            }
          })
          .then(({ data }) => {
            this.saving = false;
            if (data?.updateIntegrationConfig?.result) {
              this.changed = false;
              this.errorMessage = null;
              this.error = false;
              this.success = true;
              this.originalWarehousesUsed = this.$dup(this.warehousesUsed);
              this.originalWarehousesNotUsed = this.$dup(
                this.warehousesNotUsed
              );
            } else {
              this.errorMessage = data?.updateIntegrationConfig?.error;
              this.error = true;
            }
          })
          .catch(data => {
            this.saving = false;
            this.error = true;
            this.errorMessage = data?.message || "";
          });
      } else {
        this.saving = false;
        this.errorMessage = "Hay campos que se deben rellenar";
        this.error = true;
      }
    },
    /**
     * Se encarga de resetear los datos
     */
    resetData() {
      this.loading = true;
      this.warehousesUsed = this.$dup(this.originalWarehousesUsed);
      this.warehousesNotUsed = this.$dup(this.originalWarehousesNotUsed);
      this.errorMessage = null;
      this.error = false;
      this.success = false;
      this.loading = false;
    },
    /**
     * Se encarga de ver si hay cambios
     * en la lista warehouses_used
     */
    handleChange() {
      this.changed = !this.$arrayDeepCompare(
        this.originalWarehousesUsed,
        this.warehousesUsed
      );
    },
    /**
     * Pasa una bodega a la lista de bodegas no usadas
     * @param {Integer} position - posicion en el array de usadas
     */
    stopUsing(position) {
      let elem = this.warehousesUsed.splice(position, 1);
      let newElem = this.$dup(elem[0]);
      newElem.locationId = null;
      this.warehousesNotUsed.push(newElem);
    },
    /**
     * Pasa una bodega a la lista de bodegas usadas
     * @param {Integer} position - posicion en el array de no usadas
     */
    use(position) {
      let elem = this.warehousesNotUsed.splice(position, 1);
      let newElem = this.$dup(elem[0]);
      this.warehousesUsed.push(newElem);
    },
    /**
     * Cambia el valor del locationId cuando una
     * bodega se arrastra a la lista de no usadas
     * @param {Objet} evt - evento de drop
     */
    onDropToNotUsed(evt) {
      if (evt.added) {
        evt.added.element.locationId = null;
      }
    }
  },
  watch: {
    changed(val) {
      this.setIntegrationConfigChanged(val);
    },
    visible(val) {
      if (val) {
        this.resetData();
      }
    },
    warehousesUsed: {
      handler() {
        this.handleChange();
      },
      deep: true
    }
  }
};
</script>

<style scoped></style>
