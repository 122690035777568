<template>
  <div>
    <base-header title="Banners" title-link="#">
      <template v-slot:actions>
        <b-button
          v-if="currentUser.role === 0"
          :to="'/admin/banner/new'"
          variant="outline-info"
          class="mb-2"
        >
          <b-icon-plus></b-icon-plus>
          Nuevo Banner
        </b-button>
      </template>
    </base-header>
    <b-container fluid="">
      <b-row>
        <b-col md="12">
          <base-alert-with-count-down
            v-model="showAlert"
            alert-variant="success"
            :dismiss-secs="5"
          >
            {{ alertMessage }}</base-alert-with-count-down
          >
          <span
            >* Si hay más de un banner activo y con fechas vigentes se usará el
            último creado.</span
          >
          <banners-list />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import BaseHeader from "../components/BaseHeader.vue";
import BannersList from "../components/Banners/BannersList.vue";
import BaseAlertWithCountDown from "../components/Base/BaseAlertWithCountDown.vue";
import { centryUrl } from "../main";
import { mapState } from "vuex";
export default {
  name: "Banners",
  components: {
    BaseHeader,
    BannersList,
    BaseAlertWithCountDown
  },
  props: {
    action: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      showAlert: this.action ? true : false,
      centryUrl
    };
  },
  computed: {
    ...mapState(["currentUser"]),
    alertMessage() {
      if (this.action == "created") {
        return "Banner creado correctamente";
      } else {
        return "Banner actualizado exitosamente";
      }
    }
  },
  watch: {
    action(val) {
      this.showAlert = val ? true : false;
    }
  }
};
</script>

<style></style>
