<template>
  <div>
    <base-header
      title="Promociones de Mercado Libre"
      :title-link="titleLink"
      :map="['Mercado Libre', 'Integraciones', 'Promociones', 'Campaña']"
    ></base-header>

    <mercado-libre-promotion-items
      v-model="meliItems"
      :integration-config-id="integrationConfigId"
      :deal-id="campaignId"
      deal-type="MARKETPLACE_CAMPAIGN"
      @loading="value => (loadingItems = value)"
      @cursor="value => (cursor = value)"
      ref="items"
    />
    <div v-if="!loadingItems || items.length">
      <b-container fluid>
        <b-row>
          <b-col cols="3">
            <p>Nombre: {{ this.promotion_name }}</p>
            <p>
              Fecha de inicio:
              {{
                this.promotion_start_date | moment("dddd, MMMM D YYYY, h:mm a")
              }}
            </p>
            <p>Estado: {{ this.promotion_status | meliToCentry }}</p>
          </b-col>
          <b-col cols="7">
            <p>
              Descuentos: Meli: {{ this.discount_percent }}% | Vendedor:
              {{ this.top_discount_percent }}%
            </p>
            <p>
              Fecha de término:
              {{
                this.promotion_end_date | moment("dddd, MMMM D YYYY, h:mm a")
              }}
            </p>
            <p>
              Fecha de corte:
              {{
                this.promotion_deadline_date
                  | moment("dddd, MMMM D YYYY, h:mm a")
              }}
            </p>
          </b-col>
          <b-col cols="2">
            <b-button @click="sendProducts()">
              <b-icon-check-circle></b-icon-check-circle>Guardar
            </b-button>
          </b-col>
        </b-row>
        <br />
      </b-container>

      <base-j-excel-table
        v-model="items"
        :columns="columns"
        :on-selection="this.onSelection"
        :allow-insert-row="false"
        :allow-insert-column="false"
        :custom-on-change="this.onChange"
      ></base-j-excel-table>
      <b-button v-if="!!cursor" :disabled="!!loadingItems" @click="seeMore"
        >Ver más</b-button
      >
    </div>
  </div>
</template>

<script>
import BaseHeader from "../components/BaseHeader.vue";
import MERCADO_LIBRE_GET_PROMOTION_INFO from "../graphql/MercadoLibreGetPromotionInfo.gql";
import BaseJExcelTable from "@/components/BaseJExcelTable";
import MercadoLibrePromotionItems from "@/components/MercadoLibrePromotionItems";
// import jexcel from "jexcel";
import INTEGRATION_CONFIG_MERCADO_LIBRE from "../graphql/IntegrationConfigMercadoLibre.gql";
import jexcel from "jexcel";
import MERCADO_LIBRE_DELETE_PROMOTION_MARKETPLACE_ITEMS from "@/graphql/MercadoLibreDeletePromotionMarketplaceItems.gql";
import CREATE_MERCADO_LIBRE_MARKETPLACE_PROMOTION from "@/graphql/MercadoLibreSetPromotionMarketplaceItems.gql";
require("moment/locale/es");

export default {
  name: "MercadoLibrePromotionsDealIndex",
  props: {
    integrationConfigId: {
      type: String,
      required: true
    },
    campaignId: {
      type: String,
      required: true
    }
  },
  computed: {
    titleLink: {
      get: function() {
        return `/mercadolibre/promotions/${this.integrationConfigId}`;
      }
    }
  },
  components: {
    BaseHeader,
    BaseJExcelTable,
    MercadoLibrePromotionItems
  },
  data() {
    return {
      cursor: true,
      showAddModal: false,
      integrationConfigMeli: {},
      meliItems: [],
      items: [],
      loadingItems: true,
      columns: [
        { type: "checkbox", title: "Activo?" },
        { type: "text", title: "Producto", width: 240, readOnly: true },
        // { type: "text", title: "SKU", width: 120, readOnly: true },
        {
          type: "text",
          title: "Código MercadoLibre",
          width: 150,
          readOnly: true
        },
        {
          type: "numeric",
          title: "Precio Original",
          width: 120,
          mask: "$ #.##0,0",
          decimal: ",",
          readOnly: true
        },
        {
          type: "numeric",
          title: "Precio Oferta",
          width: 120,
          mask: "$ #.##0,0",
          decimal: ",",
          readOnly: true
        },
        { type: "text", title: "Estado", width: 120, readOnly: true },
        { type: "text", title: "ID oferta", width: 120, readOnly: true },
        { type: "html", title: "Acciones", width: 120 }
      ],
      ACTIVE: 1,
      ITEM_ID: 2,
      BASE_PRICE: 3,
      OFFER_PRICE: 4,
      STATUS: 5,
      OFFER_ID: 6,
      ACTIONS: 7,
      promotion_start_date: "",
      promotion_end_date: "",
      promotion_deadline_date: "",
      promotion_status: "",
      promotion_name: "",
      discount_percent: "",
      top_discount_percent: ""
    };
  },
  beforeMount() {
    this.getIntegrationConfig();
    this.getPromotionInfo();
  },
  methods: {
    onChange(instance, cell, x, y, value, jExcelInstance) {
      const oldValue = this.items[y][x];
      if (value !== oldValue)
        jExcelInstance.setStyle(
          jexcel.getColumnNameFromId([x, y]),
          "font-weight",
          "bold",
          true
        );
      else
        jExcelInstance.setStyle(
          jexcel.getColumnNameFromId([x, y]),
          "font-weight",
          "inherit",
          true
        );
      if (!this.changed) this.changed = true;
    },
    getIntegrationConfig() {
      this.$apollo
        .query({
          query: INTEGRATION_CONFIG_MERCADO_LIBRE,
          variables: { id: this.integrationConfigId }
        })
        .then(result => {
          this.integrationConfigMeli =
            result.data.integrationConfigMercadoLibre;
        });
    },
    async onSelection(instance, x1, y1, x2, y2) {
      if (x1 === x2 && y1 === y2 && x1 === this.ACTIONS) {
        if (confirm("El item será eliminado")) {
          await this.deleteItem(
            this.items[y1][this.ITEM_ID],
            y1,
            this.items[y1][this.OFFER_ID]
          );
        }
      }
    },
    async deleteItem(itemId, itemIndex, offerId) {
      this.$apollo
        .mutate({
          mutation: MERCADO_LIBRE_DELETE_PROMOTION_MARKETPLACE_ITEMS,
          variables: {
            items: itemId,
            deal_id: this.campaignId,
            integration_config_id: this.integrationConfigId,
            offer_id: offerId
          }
        })
        .then(result => {
          if (
            result.data.mercadoLibreDeletePromotionMarketplaceItems.result ==
              "200" ||
            result.data.mercadoLibreDeletePromotionMarketplaceItems.result ==
              200
          ) {
            this.items.splice(itemIndex, 1);
          }
        });
    },
    sendProducts() {
      for (let i = 0; i < this.$data.items.length; i++) {
        if (this.$data.items[i][0]) {
          this.$apollo
            .mutate({
              mutation: CREATE_MERCADO_LIBRE_MARKETPLACE_PROMOTION,
              variables: {
                items: this.$data.items[i][2],
                deal_id: this.campaignId,
                integration_config_id: this.integrationConfigId
              }
            })
            .then(result => {
              if (
                result.data.mercadoLibreSetPromotionMarketplaceItems.result
                  .offer_id != null
              ) {
                const item_result =
                  result.data.mercadoLibreSetPromotionMarketplaceItems.result;
                this.items[i][5] = "Activo";
                this.items[i][4] = item_result.price;
                this.items[i][3] = item_result.original_price;
                this.items[i][6] = item_result.offer_id;
                var spreadsheet = document.getElementById("spreadsheet");
                spreadsheet.jexcel.refresh();
              }
            });
        } else if (
          this.$data.items[i][this.ACTIVE] == "0" &&
          this.$data.items[i][this.STATUS] == "Activo"
        ) {
          this.$apollo
            .mutate({
              mutation: MERCADO_LIBRE_DELETE_PROMOTION_MARKETPLACE_ITEMS,
              variables: {
                items: this.items[i][this.ITEM_ID],
                deal_id: this.campaignId,
                integration_config_id: this.integrationConfigId,
                offer_id: this.items[i][this.OFFER_ID]
              }
            })
            .then(result => {
              if (
                result.data.mercadoLibreDeletePromotionMarketplaceItems
                  .result == "200"
              ) {
                this.items.splice(i, 1);
              }
            });
        }
      }
    },
    getPromotionInfo() {
      this.$apollo
        .mutate({
          mutation: MERCADO_LIBRE_GET_PROMOTION_INFO,
          variables: {
            deal_id: this.campaignId,
            integration_config_id: this.integrationConfigId,
            promotion_type: "MARKETPLACE_CAMPAIGN"
          }
        })
        .then(result => {
          const result_body = result.data.mercadoLibreGetPromotionInfo.result;
          this.promotion_start_date = result_body?.start_date;
          this.promotion_end_date = result_body?.finish_date;
          this.promotion_deadline_date = result_body?.deadline_date;
          this.promotion_status = result_body?.status;
          this.promotion_name = result_body?.name;
          this.discount_percent = result_body?.benefits?.meli_percent;
          this.top_discount_percent = result_body?.benefits?.seller_percent;
        });
    },
    /**
     * Recibe los items de la promoción y realiza la transformación de datos
     * para que se muestren en la tabla
     * @param {Array<Object>} meliItems
     */
    addItemsToJExcel(meliItems) {
      this.items = [];
      meliItems.forEach(item => {
        this.items.push([
          item.status == "ACTIVE" ? 1 : 0,
          item.productTitle,
          item.id,
          item.originalPrice != 0 ? item.originalPrice : "",
          item.price != 0 ? item.price : "",
          this.$options.filters.meliToCentry(item.status),
          item.offerId,
          "Eliminar"
        ]);
      });
    },
    /**
     * Carga una página más de ítemes
     */
    seeMore() {
      this.$refs.items.getItems();
    }
  },
  watch: {
    meliItems(newVal) {
      this.addItemsToJExcel(newVal);
    }
  }
};
</script>
