<template>
  <div>
    <base-header
      title="Días no laborales"
      title-link="/mercadolibre/not_working_days"
      :loading="!integrationConfigLabel"
      :map="[
        integrationConfigLabel || 'loading',
        'Listado de días no laborales'
      ]"
      :title-size="12"
    >
    </base-header>
    <div class="mt-3 mb-3">
      <b-spinner v-if="loadingNotWorkingDays || saving" />
      <b-container class="ml-0" v-else>
        <b-row>
          <b-col>
            <b-button-group>
              <b-button
                variant="primary"
                :disabled="notChanges"
                @click="saveChanges"
              >
                Guardar
              </b-button>
              <b-button
                variant="outline-primary"
                :disabled="notChanges"
                @click="restartChanges"
              >
                Restablecer
              </b-button>
            </b-button-group>
          </b-col>
          <b-col>
            <b-form-group>
              <date-picker
                v-model="dateSelected"
                type="date"
                value-type="format"
                format="YYYY-MM-DD"
                range
                :clearable="true"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <div class="d-inline float-right m-1">
              <b-icon-search></b-icon-search>
            </div>
            <div class="d-inline float-right">
              <b-form-input
                size="sm"
                type="search"
                placeholder="Nombre día no laboral"
                v-model="keyword"
                :clearable="true"
              ></b-form-input>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <b-alert show v-if="error || errorSaving" variant="danger" dismissible>
      <ul>
        <li>{{ errorMessage }}</li>
      </ul>
    </b-alert>
    <b-alert show v-if="saved" variant="success" dismissible>
      <ul>
        <li>Se han guardado los cambios exitosamente</li>
      </ul>
    </b-alert>
    <b-skeleton-table
      v-if="loadingNotWorkingDays"
      :rows="5"
      :columns="4"
      :table-props="{ bordered: true, striped: true }"
    ></b-skeleton-table>
    <b-overlay :show="loading" no-center rounded="sm" v-else>
      <template #overlay>
        <div class="text-center">
          <b-icon icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
          <p id="cancel-label">
            Por favor espera, estamos cargando datos todavía...
          </p>
        </div>
      </template>
      <mercado-libre-not-working-days-table
        :integration-config-id="integrationConfigId"
        :dates="filterDates"
        @change="reviewChanges"
      />
    </b-overlay>
  </div>
</template>
<script>
import BaseHeader from "../../../components/BaseHeader.vue";
import MercadoLibreNotWorkingDaysTable from "../../../components/MercadoLibre/NotWorkingDays/Table.vue";
import INTEGRATION_CONFIG_LABEL from "../../../graphql/IntegrationConfigMercadoLibreLabel.gql";
import ALL_NOT_WORKING_DAYS from "../../../graphql/MercadoLibre/NotWorkingDays/AllNotWorkingDays.gql";
import MERCADO_LIBRE_SAVE_INFO_NOT_WORKING_DATES from "../../../graphql/MercadoLibre/NotWorkingDays/SaveNotWorkingDays.gql";

export default {
  name: "MercadoLibreNotWorkingDaysList",
  components: {
    BaseHeader,
    MercadoLibreNotWorkingDaysTable
  },
  data() {
    return {
      integrationConfigLabel: null,
      dateSelected: [null, null],
      error: false,
      errorMessage: "",
      loadingNotWorkingDays: true,
      dates: [],
      oldDates: [],
      changes: new Set(),
      saving: false,
      errorSaving: false,
      saved: false,
      keyword: ""
    };
  },
  props: {
    integrationConfigId: {
      type: String,
      required: true
    }
  },
  computed: {
    loading() {
      return this.loadingNotWorkingDays;
    },
    notChanges() {
      return this.changes.size === 0;
    },
    filterDates() {
      let ans = this.dates;
      ans = this.filterByRangeDate(ans);
      ans = this.filterByName(ans);
      return ans;
    }
  },
  mounted() {
    const promise = Promise.resolve(this.getIntegrationConfigLabel());
    promise.then(() => {
      this.getNotWorkingDays();
    });
  },
  methods: {
    /**
     * Retorna un objecto de clase Date a partir de un string
     * @param {String} date
     * @returns {Date}
     */
    getSpecificDate(date) {
      let ans = new Date(date);
      ans.setDate(ans.getDate() + 1);
      return ans;
    },
    /**
     * Obtiene todos los dias no laborales configurados por meli para el seller actual
     */
    getNotWorkingDays() {
      this.$apollo
        .query({
          query: ALL_NOT_WORKING_DAYS,
          variables: {
            integrationConfigId: this.integrationConfigId
          }
        })
        .then(({ data }) => {
          const dates = this.$dig(
            data,
            "allMercadoLibreNotWorkingDays",
            "dates"
          );
          if (dates) {
            let data = this.buildDatesData(dates);
            this.dates = this.$dup(data);
            this.oldDates = this.$dup(data);
          } else {
            this.error = true;
            this.errorMessage = "No fue posible obtener los días no laborales";
          }
          this.loadingNotWorkingDays = false;
        })
        .catch(error => {
          this.loadingNotWorkingDays = false;
          this.error = true;
          this.errorMessage = String(error);
        });
    },
    /**
     * Agrega indice real a los dias no laborales,
     * para evitar discordancias con los filtros de fecha y nombre
     * @param {Array<Object>} dates
     * @returns {Array<Object>}
     */
    buildDatesData(dates) {
      return dates.map((day, idx) => {
        return { ...day, realIndex: idx };
      });
    },
    /**
     * Obtiene la etiqueta del integration_config
     * y lo guarda en variable interna
     */
    async getIntegrationConfigLabel() {
      await this.$apollo
        .query({
          query: INTEGRATION_CONFIG_LABEL,
          variables: {
            id: this.integrationConfigId
          }
        })
        .then(({ data }) => {
          const label = this.$dig(
            data,
            "integrationConfigMercadoLibre",
            "label"
          );
          if (label) {
            this.integrationConfigLabel = label;
          } else {
            this.integrationConfigLabel = "MercadoLibre";
          }
        })
        .catch(() => (this.integrationConfigLabel = "MercadoLibre"));
    },
    /**
     * Guarda cambios en los día no laborales
     */
    saveChanges() {
      if (this.changes.size === 0) return;
      let params = this.buildParams();
      this.saving = true;
      this.$apollo
        .mutate({
          mutation: MERCADO_LIBRE_SAVE_INFO_NOT_WORKING_DATES,
          variables: {
            integrationConfigId: this.integrationConfigId,
            path: params
          }
        })
        .then(({ data }) => {
          const response = this.$dig(data, "mercadoLibreUpdateNotWorkingDays");
          if (this.$dig(response, "status")) {
            this.errorSaving = false;
            this.errorSaving = "";
            this.saved = true;
            this.changes = new Set();
            this.oldDates = this.$dup(this.dates);
          } else {
            this.errorSaving = true;
            const error = this.$dig(response, "error");
            var msg = `Ocurrio un error inesperado. ${error}`;
            this.errorSaving = msg;
            this.saved = false;
          }
          this.saving = false;
        })
        .catch(error => {
          this.errorSaving = true;
          this.errorSaving = `Ocurrio un error inesperado. ${error}`;
          this.saving = false;
          this.saved = false;
        });
    },
    /**
     * Construye parametros para el guardado de todos los dias no laborales con cambios
     * @returns {Object}
     */
    buildParams() {
      let ans = [];
      this.changes.forEach(index => {
        let aux = this.paramsByDate(index);
        ans.push(aux);
      });
      return { dates: ans };
    },
    /**
     * Construye parametros para el guardado de un dia no laboral particular
     * @returns {Object}
     */
    paramsByDate(index) {
      let aux = {};
      let current_day = this.dates[index];
      aux.description = current_day.description;
      aux.checked = current_day.checked;
      aux.date = current_day.date;
      return aux;
    },
    /**
     * Reinicia valores de chequeado de cada día no laboral
     */
    restartChanges() {
      this.changes.forEach(index => {
        this.dates[index].checked = this.oldDates[index].checked;
      });
      this.changes = new Set();
    },
    /**
     * Revisa si se esta realizando un cambio o no en el dia laboral
     * @param {Number} index
     */
    reviewChanges(index) {
      let current_value = this.dates[index];
      let old_value = this.oldDates[index];
      if (old_value.checked != current_value.checked) {
        this.addChange(index);
      } else {
        this.removeChange(index);
      }
    },
    /**
     * Agrega un dia laboral al conjunto de cambios
     * @param {Number} index
     */
    addChange(index) {
      let aux = this.changes;
      aux.add(index);
      this.changes = new Set([...aux]);
    },
    /**
     * Quita un dia laboral del conjunto de cambios
     * @param {Numer} index
     */
    removeChange(index) {
      let aux = this.changes;
      aux.delete(index);
      this.changes = new Set([...aux]);
    },
    /**
     * Verifica si la descripcion del dia no laboral calza con el filtro de nombre
     * @param {String} description
     * @returns {Boolean}
     */
    filterBySpecificName(description) {
      return description.toLowerCase().includes(this.keyword.toLowerCase());
    },
    /**
     * Filtro por rango de fechas
     * @param {Array} dates
     * @returns {Array}
     */
    filterByRangeDate(dates) {
      let ans = dates;
      if (this.dateSelected[0]) {
        let dtStart = this.dateSelected[0];
        let dtEnd = this.dateSelected[1];
        ans = ans.filter(day => {
          let dateOfDay = day.date;
          return dateOfDay >= dtStart && dateOfDay <= dtEnd;
        });
      }
      return ans;
    },
    /**
     * Filtro por nombre
     * @param {Array} dates
     * @returns {Array}
     */
    filterByName(dates) {
      let ans = dates;
      if (this.keyword.length > 0) {
        ans = ans.filter(day => {
          return this.filterBySpecificName(day.description);
        });
      }
      return ans;
    }
  }
};
</script>
