<template>
  <div>
    <b-row>
      <b-col md="1">
        <strong>Agrupar</strong>
        <b-form-group>
          <v-select
            v-model="groupDataType"
            placeholder="Diario"
            :options="groupDataTypeOptions"
            :reduce="group => group.value"
          ></v-select>
        </b-form-group>
      </b-col>
      <b-col md="2">
        <strong>Periodo</strong>
        <b-form-group>
          <date-picker
            v-model="dateSelected"
            type="date"
            value-type="date"
            range
            :clearable="false"
          >
          </date-picker>
        </b-form-group>
      </b-col>
      <b-col md="2">
        <strong>Periodo de comparación</strong>
        <b-form-group>
          <date-picker
            v-model="compareDateSelected"
            type="date"
            value-type="date"
          >
          </date-picker>
          <span class="text-center text-muted">{{ compareDateEnd }}</span>
        </b-form-group>
      </b-col>
      <b-col md="2">
        <strong>Canal</strong>
        <b-form-group>
          <v-select
            v-model="integrationsSelected"
            placeholder="Seleccionar"
            :options="integrationConfigs"
            multiple=""
            :reduce="category => category.value"
          ></v-select>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <strong>Categorías</strong>
        <b-form-group>
          <v-select
            v-model="categoriesSelected"
            placeholder="Seleccionar"
            :options="categories"
            multiple=""
            :reduce="category => category.value"
          ></v-select>
        </b-form-group>
      </b-col>
      <b-col md="2">
        <strong>Marca</strong>
        <b-form-group>
          <v-select
            v-model="brandsSelected"
            placeholder="Seleccionar"
            :options="brands"
            multiple=""
            :reduce="brand => brand.value"
          ></v-select>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { getEndCompareDate } from "@/main";

export default {
  name: "ProductsSalesReportFilter",
  props: {
    integrationConfigs: Array,
    categories: Array,
    brands: Array
  },
  data() {
    let startDate = new Date();
    let endDate = new Date();
    return {
      keyword: [],
      categoriesSelected: [],
      brandsSelected: [],
      integrationsSelected: [],
      dateSelected: [startDate, endDate],
      compareDateSelected: "",
      groupDataType: "",
      groupDataTypeOptions: [
        { value: "month", label: "Mensual" },
        { value: "week", label: "Semanal" },
        { value: "day", label: "Diario" }
      ]
    };
  },
  computed: {
    ...mapState(["currentUser", "filters"]),
    compareDateEnd() {
      if (!this.compareDateSelected) {
        return "";
      }
      return (
        "Fin del período: " +
        this.$moment(
          getEndCompareDate(
            this.filters.startDate,
            this.filters.endDate,
            this.compareDateSelected
          )
        ).format("YYYY-MM-DD")
      );
    }
  },
  methods: {
    ...mapMutations(["setFilter"])
  },
  watch: {
    categoriesSelected(newValue) {
      if (newValue !== this.filters.categoryId) {
        this.setFilter({ categoryIds: newValue });
      }
    },
    brandsSelected(newValue) {
      if (newValue !== this.filters.brandId) {
        this.setFilter({ brandIds: newValue });
      }
    },
    integrationsSelected(newValue) {
      if (newValue !== this.filters.integrationId) {
        this.setFilter({ origins: newValue });
      }
    },
    dateSelected(newValue) {
      this.setFilter({ startDate: newValue[0] });
      this.setFilter({ endDate: newValue[1] });
      if (newValue[0] == null) {
        this.setFilter({
          startDate: null
        });
      }
      if (newValue[1] == null) {
        this.setFilter({ endDate: null });
      }
    },
    compareDateSelected(newValue) {
      this.setFilter({ compareDate: newValue });
    },
    groupDataType(newValue) {
      if (newValue !== this.filters.groupDataType) {
        this.setFilter({ granularity: newValue });
      }
      if (newValue == null) {
        this.setFilter({ granularity: "day" });
      }
    }
  }
};
</script>
