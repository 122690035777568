<template>
  <div>
    <b-button
      @click="openModal"
      variant="outline-secondary"
      class="mr-1 mb-1"
      v-b-tooltip.hover
      title="Anular pedido"
    >
      <b-icon-x />
    </b-button>
    <b-modal
      :ref="'cancellation-' + order.id"
      :id="'cancellation-' + order.id"
      ok-disabled=""
      size="lg"
      title="Anular pedido"
    >
      <b-container fluid v-if="order.clientType == 'normal'">
        <b-row>
          <b-col class="col-12">
            <b-form-group label="Calificación del Comprador" label-for="rate">
              <v-select
                id="rate"
                :options="buyerRates"
                placeholder="Selecciona una calificación"
                v-model="buyerRate"
                :reduce="br => br.value"
              ></v-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="col-12">
            <b-form-group label="Motivo de la anulación" label-for="reason">
              <v-select
                id="reason"
                :options="reasonCancellations"
                placeholder="Selecciona un motivo"
                v-model="reason"
                :reduce="r => r.value"
              ></v-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="reasonRestock">
          <b-col class="col-12">
            <b-form-checkbox
              v-model="restock"
              :value="true"
              :unchecked-value="false"
            >
              Reponer el stock
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="col-12">
            <b-form-group label="Comentario" label-for="comment">
              <b-input id="comment" v-model="comment"></b-input>
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>
      <b-container fluid v-else>
        <b-row>
          <ul>
            <li>
              Te vamos a reintegrar el cargo de esta venta y le devolveremos el
              dinero al comprador.
            </li>
            <li>
              Tu reputación no se verá afectada porque le vendiste a un
              comprador invitado.
            </li>
            <li>
              Se reembolsará un total de:
              <strong>
                {{ order.paidAmount | toCurrency(2) }}
              </strong>
            </li>
          </ul>
        </b-row>
      </b-container>
      <template v-slot:modal-footer>
        <b-button variant="info" @click="cancelOrder">
          Anular
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import CREATE_ORDER_CANCELLATION from "../../graphql/CreateOrderCancellation.gql";
import ALL_BUYER_RATES from "../../graphql/AllBuyerRates.gql";
import ALL_CANCELLATION_REASONS from "../../graphql/AllCancellationReasons.gql";
export default {
  name: "OrderCancellationModal",
  props: {
    order: Object
  },
  data() {
    return {
      buyerRates: [],
      reasonCancellations: [],
      buyerRate: "",
      reason: "",
      restock: false,
      comment: "",
      reasonRestock: ""
    };
  },
  methods: {
    openModal() {
      this.$refs["cancellation-" + this.order.id].show();
      if (this.order.clientType == "normal") {
        this.getBuyerRates();
        this.getCancellationReasons();
      }
    },
    /**
     * Se construye objeto que contiene parametros
     * para cancelar un pedido. Se envia solo el order_id
     * si el cliente del pedido es de tipo invitado
     * @return {Object}
     */
    buildParamsCancelOrder() {
      if (this.order.clientType == "guest") {
        return { orderId: this.order.id };
      }
      return {
        buyerRateId: this.buyerRate,
        reasonId: this.reason,
        restock: this.restock,
        message: this.comment,
        orderId: this.order.id
      };
    },
    /**
     * Se envia mutación para cancelar un pedido cualquiera
     */
    cancelOrder() {
      this.$refs["cancellation-" + this.order.id].hide();
      let create = this.buildParamsCancelOrder();
      this.$apollo
        .mutate({
          mutation: CREATE_ORDER_CANCELLATION,
          variables: {
            create: create
          }
        })
        .then(result => {
          let valid = result.data.createOrderCancellation.result;
          if (valid) {
            this.$swal.fire({
              title: "Cancelación exitosa",
              text: "Se ha cancelado el pedido de forma exitosa",
              icon: "success",
              confirmButtonText: "Aceptar"
            });
          } else {
            this.$swal.fire({
              title: "Cancelación fallida",
              text: result.data.createOrderCancellation.errors,
              icon: "error",
              confirmButtonText: "Aceptar"
            });
          }
        });
    },
    getBuyerRates() {
      this.$apollo
        .query({
          query: ALL_BUYER_RATES
        })
        .then(result => {
          let rates = result.data.allBuyerRates.edges;
          this.buyerRates = rates.map(br => {
            return {
              value: br.node.id,
              label: br.node.name
            };
          });
        });
    },
    getCancellationReasons() {
      this.$apollo
        .query({
          query: ALL_CANCELLATION_REASONS
        })
        .then(result => {
          let reasons = result.data.allCancellationReasons.edges;
          this.reasonCancellations = reasons.map(r => {
            return {
              value: r.node.id,
              label: r.node.name,
              restock: r.node.restock
            };
          });
        });
    }
  },
  watch: {
    reason(newValue) {
      let reasonSelected = this.reasonCancellations.find(
        reason => newValue == reason.value
      );
      this.reasonRestock =
        reasonSelected.restock == "not_allowed" ? false : true;
    }
  }
};
</script>
<style scoped>
.table {
  margin-bottom: 0 !important;
}
.table td {
  padding: 8px 8px !important;
  height: 10px !important;
}
</style>
