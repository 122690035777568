<template>
  <div>
    <base-header
      title="Moderaciones"
      title-link="/moderations"
      :map="['Detalle', productData.name]"
    ></base-header>
    <b-container fluid>
      <b-row>
        <b-col md="12">
          <a class="title"> {{ productData.name }} ({{ productData.sku }}) </a>
          <b-button
            :to="`/product/${productId}/edit`"
            variant="outline-info"
            style="margin-left: 10px"
            size="sm"
          >
            Editar
          </b-button>

          <b-button
            v-if="!unpublishStatus"
            @click="unpublish"
            variant="outline-info"
            style="margin-left: 10px"
            size="sm"
          >
            Despublicar
          </b-button>
          <b-spinner
            v-if="isLoadingUnpublish"
            label="Spinning"
            style="margin-left: 5px"
          ></b-spinner>
          <b-dropdown
            variant="outline-info"
            text="Politicas"
            size="sm"
            style="margin-left: 10px"
          >
            <b-dropdown-item disabled>
              Politicas MercadoLibre
            </b-dropdown-item>
            <b-dropdown-item href="https://www.mercadolibre.cl/ayuda/1022">
              Publicación en la categoría apropiada
            </b-dropdown-item>
            <b-dropdown-item
              href="https://www.mercadolibre.cl/ayuda/Crear-variantes-de-tusproductos_679"
            >
              Crear variantes de tus productos
            </b-dropdown-item>
          </b-dropdown>
          <ApolloQuery
            :query="require('../graphql/AllModerations.gql')"
            :variables="{
              cursor: '',
              productId: this.productId
            }"
            @result="showButtonUnpublish"
          >
            <template slot-scope="{ result: { data }, query, isLoading }">
              <b-table-simple hover>
                <b-thead>
                  <b-tr>
                    <b-th>Error</b-th>
                    <b-th>Solución</b-th>
                    <b-th>Integración</b-th>
                    <b-th>Id Publicación</b-th>
                    <b-th>Severidad</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr
                    v-for="moderation of data.allModerations.edges"
                    :key="moderation.node.id"
                  >
                    <b-td>{{ moderation.node.reason }}</b-td>
                    <b-td>{{ moderation.node.remedy }}</b-td>
                    <b-td>{{
                      $dig(moderation, "node", "integrationConfig", "label")
                    }}</b-td>
                    <b-td>{{ moderation.node.publicationId }}</b-td>
                    <b-td>{{
                      moderation.node.severity | translateSeverity
                    }}</b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
              <b-spinner
                v-if="isLoading"
                label="Spinning"
                class="m-2 float-right"
              ></b-spinner>
              <div v-else>
                <b-button
                  v-if="hasNextPage(data)"
                  class="m-2 float-right"
                  @click="
                    seeMore(query, data.allModerations.pageInfo.endCursor)
                  "
                >
                  Ver más
                </b-button>
                <b-alert v-else show variant="light" class="text-center">
                  No hay más datos para mostrar.
                </b-alert>
              </div>
            </template>
          </ApolloQuery>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import BaseHeader from "../components/BaseHeader.vue";
import PRODUCTSIMPLE from "../graphql/ProductSimple.gql";
import UNPUBLISH_MODERATION from "../graphql/MercadoLibre/Moderations/DeleteItemsPublications.gql";

export default {
  name: "ModerationsDetails",
  props: {
    productModeration: Object
  },
  components: {
    BaseHeader
  },
  data() {
    return {
      productId: this.$route.params.productId,
      productData: {},
      unpublishStatus: false,
      isLoadingUnpublish: false
    };
  },
  mounted() {
    this.getProductSimple();
  },
  methods: {
    /**
     * Se encarga de decidir si se muestra el boton de despublicar*/
    async showButtonUnpublish(result) {
      let moderations = await result.data.allModerations.edges;
      let unpublished = moderations.map(
        moderation => moderation.node.unpublished
      );
      unpublished = await [...new Set(unpublished)];
      this.unpublishStatus = unpublished.length == 1 && unpublished[0];
    },
    /**
     * Se encarga de despublicar un producto de mercado libre en las integraciones que poseean problemas
     */
    async unpublish() {
      this.isLoadingUnpublish = true;
      await this.$apollo
        .mutate({
          mutation: UNPUBLISH_MODERATION,
          variables: {
            products: [this.productId],
            async: false
          }
        })
        .then(data => {
          let resp = data.data.mercadoLibreDeleteItemsPublications;
          if (resp.result == true) {
            this.$swal.fire({
              title: "Producto despublicado",
              text: "Producto despublicado exitosamente",
              icon: "success",
              confirmButtonText: "Aceptar"
            });
          } else {
            let error = resp.errors[0].error;
            this.$swal.fire({
              title: "Error",
              text: `Ocurrio el siguiente error al despublicar: ${error}`,
              icon: "error",
              confirmButtonText: "Aceptar"
            });
          }
        })
        .catch(error => {
          this.$swal.fire({
            title: "Error",
            text: `Ocurrio el siguiente error: ${error}`,
            icon: "error",
            confirmButtonText: "Aceptar"
          });
        });
      this.isLoadingUnpublish = false;
    },
    async getProductSimple() {
      await this.$apollo
        .query({
          query: PRODUCTSIMPLE,
          variables: {
            id: this.productId
          }
        })
        .then(({ data }) => {
          console.log(data);
          this.productData = data.product;
        });
    },
    /**
     * Indica si hay una página siguiente en la query de GraphQl basado
     * exclusivamente en la existencia de un cursor.
     * @return {boolean}
     */
    hasNextPage(data) {
      return data?.allModerations?.pageInfo?.endCursor != null;
    },
    /**
     * Trae más resultados de una query avanzando en su cursor.
     * @param {Object} query
     * @param {String} cursor
     */
    async seeMore(query, cursor) {
      await query.fetchMore({
        variables: {
          cursor: cursor,
          productId: this.productId
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          const updated = Object.assign({}, this.$dup(prev));
          updated.allModerations.pageInfo =
            fetchMoreResult.allModerations.pageInfo;
          if (fetchMoreResult?.allModerations?.edges?.length !== 0) {
            updated.allModerations.edges.push(
              ...fetchMoreResult.allModerations.edges
            );
          }
          return updated;
        }
      });
    }
  }
};
</script>

<style>
a.title {
  margin-bottom: 6px;
  display: inline-block;
  font-size: 15px;
  font-weight: 600;
  line-height: 25px;
}
</style>
