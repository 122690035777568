<template>
  <div>
    <b-row>
      <b-col
        md="4"
        v-for="categoryAttribute of category_attributes"
        :key="categoryAttribute.value"
      >
        <b-form-group
          :label="
            (categoryAttribute.required ? '* ' : '') + categoryAttribute.label
          "
          :label-for="'ca' + categoryAttribute.value"
        >
          <product-edit-tabs-mercado-libre-size-chart-selector
            v-if="categoryAttribute.value == 'SIZE_GRID_ID'"
            :integration-config="integrationConfig"
            v-model="caAttributesValue[categoryAttribute.value].value"
            @change="emitChange"
            @changeSizeChart="emitSizeChartChange"
          />
          <product-edit-tabs-mercado-libre-attributes-input
            v-else
            v-model="caAttributesValue[categoryAttribute.value]"
            :attribute-info="categoryAttribute"
            @input="emitChange"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ProductEditTabsMercadoLibreAttributesInput from "./ProductEditTabsMercadoLibreAttributesInput.vue";
import ProductEditTabsMercadoLibreSizeChartSelector from "./ProductEditTabsMercadoLibreSizeChartSelector.vue";

export default {
  name: "ProductEditTabsMercadoLibreCategoryAttributes",
  components: {
    ProductEditTabsMercadoLibreAttributesInput,
    ProductEditTabsMercadoLibreSizeChartSelector
  },
  model: {
    prop: "_value"
  },
  props: {
    _value: {
      type: Object,
      default() {
        return {};
      }
    },
    category_attributes: Array,
    integrationConfig: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      caAttributesValue: this._value
    };
  },
  methods: {
    /**
     * Captura y envia evento change
     */
    emitChange() {
      this.$emit("change", this.array);
    },
    /**
     * Emite evento de cambio en la GDT
     */
    emitSizeChartChange(loading) {
      this.$emit("sizeChartChange", loading);
    }
  },
  watch: {
    _value(newVal) {
      this.caAttributesValue = Object.assign({}, newVal);
    }
  }
};
</script>
