<template>
  <div>
    <ApolloQuery
      :query="require('../graphql/Warehouses/Warehouse.gql')"
      :variables="queryVariables()"
    >
      <template slot-scope="{ result: { data }, isLoading }">
        <base-header
          title="Bodegas"
          title-link="/warehouses"
          :titleSize="0"
          :map="
            $dig(data, 'warehouse', 'name')
              ? [$route.meta.action, $dig(data, 'warehouse', 'name')]
              : [$route.meta.action]
          "
        >
        </base-header>
        <b-container fluid="">
          <router-view
            :is-loading="isLoading == 1"
            :warehouse="$dig(data, 'warehouse')"
          ></router-view>
        </b-container>
      </template>
    </ApolloQuery>
  </div>
</template>

<script>
import BaseHeader from "../components/BaseHeader.vue";

export default {
  name: "Warehouse",
  components: {
    BaseHeader
  },
  methods: {
    /**
     * Entrega las variables para la query que permite obtener la bodega
     */
    queryVariables() {
      let variables = {};
      variables.id = "new";
      if (this.$route.params.id) {
        variables.id = this.$route.params.id;
      }
      return variables;
    }
  }
};
</script>
