<template>
  <div>
    <ApolloQuery
      :query="require('../../graphql/Blockages/Blockage.gql')"
      :variables="queryVariables()"
      @result="manageResult"
    >
      <template slot-scope="{ result: { data }, isLoading }">
        <base-header
          title="Bloqueos"
          title-link="/blockages"
          :titleSize="0"
          :map="
            $dig(data, 'blockage', 'name')
              ? [$route.meta.action, $dig(data, 'blockage', 'name')]
              : [$route.meta.action]
          "
        >
        </base-header>
        <b-container fluid="">
          <router-view
            :is-loading-blockage="isLoading == 1"
            :blockage="$dig(data, 'blockage')"
          ></router-view>
        </b-container>
      </template>
    </ApolloQuery>
  </div>
</template>

<script>
import BaseHeader from "../../components/BaseHeader.vue";

export default {
  name: "Blockage",
  components: {
    BaseHeader
  },
  methods: {
    /**
     * Entrega las variables para la query que permite obtener el bloqueo
     * @return {Object} - variables para consulta
     */
    queryVariables() {
      let variables = {};
      variables.id = "new";
      if (this.$route.params.id) {
        variables.id = this.$route.params.id;
      }
      return variables;
    },
    /**
     * Se encarga de manejar el resultado de la query,
     * si no existe el bloqueo se re-direcciona a la lista de bloqueos
     * @param {Object} result - resultado de query
     */
    manageResult(result) {
      if (!result.error && !result.data.blockage) {
        this.$router.replace({ name: "Blockages" });
      }
    }
  }
};
</script>

<style></style>
