<template>
  <div>
    <b-button
      @click="openModal"
      variant="outline-secondary"
      class="mr-1 mb-1"
      v-b-tooltip.hover
      title="Confirmar entrega"
    >
      <b-icon-truck />
    </b-button>
    <b-modal
      :ref="'delivery-confirmation-' + order.id"
      :id="'delivery-confirmation-' + order.id"
      ok-disabled=""
      size="lg"
      title="Confirmar Entrega"
    >
      <b-container fluid>
        <b-row>
          <b-col class="col-12">
            <b-form-group label="Calificación del Comprador" label-for="rate">
              <v-select
                id="rate"
                :options="buyerRates"
                placeholder="Selecciona una calificación"
                v-model="buyerRate"
                :reduce="br => br.value"
              ></v-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="col-12">
            <b-form-group label="Comentario" label-for="comment">
              <b-input id="comment" v-model="comment"></b-input>
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>
      <template v-slot:modal-footer>
        <b-button variant="info" @click="confirmOrder">
          Confirmar
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import CREATE_DELIVERY_CONFIRMATION from "../../graphql/CreateOrderDeliveryConfirmation.gql";
import ALL_BUYER_RATES from "../../graphql/AllBuyerRates.gql";
export default {
  name: "OrderDeliveryConfirmationModal",
  props: {
    order: Object
  },
  data() {
    return {
      buyerRates: [],
      buyerRate: "",
      comment: ""
    };
  },
  methods: {
    openModal() {
      this.$refs["delivery-confirmation-" + this.order.id].show();
      this.getBuyerRates();
    },
    confirmOrder() {
      this.$refs["delivery-confirmation-" + this.order.id].hide();
      let create = {
        buyerRateId: this.buyerRate,
        message: this.comment,
        orderId: this.order.id
      };
      this.$apollo
        .mutate({
          mutation: CREATE_DELIVERY_CONFIRMATION,
          variables: {
            create: create
          }
        })
        .then(result => {
          let valid = result.data.createOrderDeliveryConfirmation.result;
          if (valid) {
            this.$swal.fire({
              title: "Confirmación exitosa",
              text: "Se ha confirmado la entrega de forma exitosa",
              icon: "success",
              confirmButtonText: "Aceptar"
            });
          } else {
            this.$swal.fire({
              title: "Confirmación fallida",
              text: "No se ha podido confirmar la entrega del pedido",
              icon: "error",
              confirmButtonText: "Aceptar"
            });
          }
        });
    },
    getBuyerRates() {
      this.$apollo
        .query({
          query: ALL_BUYER_RATES
        })
        .then(result => {
          let rates = result.data.allBuyerRates.edges;
          this.buyerRates = rates.map(br => {
            return {
              value: br.node.id,
              label: br.node.name
            };
          });
        });
    }
  }
};
</script>
<style scoped>
.table {
  margin-bottom: 0 !important;
}
.table td {
  padding: 8px 8px !important;
  height: 10px !important;
}
</style>
