<template>
  <div>
    <b-button
      @click="openModal"
      variant="outline-secondary"
      class="mr-1 mb-1"
      v-b-tooltip.hover
      title="Categoría"
    >
      Seleccionar Categoría
    </b-button>
    <b-modal
      ref="father-category"
      id="father-category"
      ok-disabled=""
      size="lg"
      title="Categoría Padre"
    >
      <b-container>
        <base-category-tree
          :categories="categories"
          children-path="children"
          id-path="id"
          @category="updateCategory"
        >
        </base-category-tree>
      </b-container>
      <template v-slot:modal-footer>
        <b-button @click="closeModal">
          Seleccionar
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import BaseCategoryTree from "@/components/BaseCategoryTree";

export default {
  name: "VtexCategoriesMaintainerCategorySelector",
  components: {
    BaseCategoryTree
  },
  props: {
    fatherCategoryName: String,
    categories: Array
  },
  data() {
    return {
      fatherCategory: {},
      fatherName: this.fatherCategoryName
    };
  },
  methods: {
    /**
     * Abre el modal para seleccionar la categoria padre de la nueva categoria
     */
    openModal() {
      this.$refs["father-category"].show();
    },
    /**
     * Cierra el modal para seleccionar la categoria padre de la nueva categoria
     */
    closeModal() {
      this.$refs["father-category"].hide();
      this.emitFather();
    },
    /**
     * Guarda los datos de la categoria seleccionada
     * @param {Object} category
     */
    updateCategory(category) {
      this.fatherCategory = category;
      this.fatherName = category.path.join(" / ");
    },
    /**
     * Emite el evento 'father' con los datos de la categoria padre seleccionada
     */
    emitFather() {
      let father = { id: this.fatherCategory.id, path: this.fatherName };
      this.$emit("father", father);
    }
  }
};
</script>
