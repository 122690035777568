import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

import Moderations from "../views/Moderations.vue";
import ModerationsDetails from "../views/ModerationsDetails.vue";
import Login from "../views/Login.vue";
import MagentoAttributeSets from "../views/MagentoAttributeSets.vue";
import MagentoAttributes from "../views/MagentoAttributes.vue";
import SynchronizationMonitoring from "../views/admin/SynchronizationMonitoring.vue";
import OptionListCreate from "../views/admin/OptionListCreate.vue";
import OptionListCategoryUpdate from "../views/admin/OptionListCategoryUpdate";
import OptionListDataBaseUpdate from "../views/admin/OptionListDataBaseUpdate";
import OptionListRequestUpdate from "../views/admin/OptionListRequestUpdate";
import OptionList from "../views/admin/OptionList.vue";
import PendingBulkUpload from "../views/admin/PendingBulkUpload.vue";
import SidekiqQueueMonitor from "../views/admin/SidekiqQueueMonitor.vue";
import SidekiqCron from "../views/admin/SidekiqCron.vue";
import MessagesScheduled from "../views/admin/MessagesScheduled.vue";
import MessageScheduled from "../views/admin/MessageScheduled.vue";
import MessageScheduledEdit from "../views/admin/MessageScheduledEdit.vue";
import ErrorTranslationEdit from "../views/admin/ErrorTranslationEdit.vue";
import CategoryAttributeEdit from "../views/admin/CategoryAttributeEdit.vue";
import CategoryAttributeNew from "../views/admin/CategoryAttributeNew.vue";
import BulkUpload from "../views/BulkUpload.vue";
import BulkUploadHistory from "../views/BulkUploadHistory.vue";
import BulkUploadTemplateDownloads from "../views/BulkUploadTemplateDownloads.vue";
import Products from "../views/Products.vue";
import Product from "../views/Product.vue";
import ProductDetail from "../views/ProductDetail.vue";
import ProductImageManager from "../views/ProductImageManager.vue";
import ProductInventory from "../views/ProductInventory.vue";
import ProductEdit from "../views/ProductEdit.vue";
import ProductTransactions from "../views/ProductTransactions.vue";
import ProductHistories from "../views/ProductHistories.vue";
import SizeCharts from "../views/SizeCharts.vue";
import SizeChart from "../views/SizeChart.vue";
import StockDestinations from "../views/StockDestinations.vue";
import TranslationCentryIntegrations from "../views/IntegrationConfig/TranslationCentryIntegrations.vue";
import TranslationCentryIntegrationNew from "../views/IntegrationConfig/TranslationCentryIntegrationNew.vue";
import TranslationCentryIntegrationEdit from "../views/IntegrationConfig/TranslationCentryIntegrationEdit.vue";
import QuarantinedAttributeHomologations from "../views/admin/QuarantinedAttributeHomologations.vue";

import Dashboard from "../views/Dashboard.vue";
import Orders from "../views/Orders.vue";
import Orders2 from "../views/Orders2.vue";
import Order from "../views/Order.vue";
import LastSynchronizationStatuses from "../views/LastSynchronizationStatuses.vue";
import Documents from "../views/Documents.vue";
import Section from "../views/admin/Section.vue";
import VtexMaintainer from "../views/VtexMaintainer.vue";
import VtexHomologation from "../views/VtexHomologation.vue";
import VtexCategoriesMaintainerNew from "@/components/VtexMaintainers/Categories/VtexCategoriesMaintainerNew";
import VtexPromotionEdit from "@/components/VtexMaintainers/Promotions/VtexPromotionEdit";
import VtexHomologationSpecificationEdit from "@/components/VtexHomologation/VtexHomologationAttributes/VtexHomologationAttributesEdit";
import VtexHomologationPurposes from "@/components/VtexHomologation/VtexHomologationPurposes/VtexHomologationPurposesTable";
import VtexHomologationPurposeForm from "@/components/VtexHomologation/VtexHomologationPurposes/VtexHomologationPurposeForm";
import JumpsellerPromotions from "../views/jumpseller/PromotionsTabs.vue";
import JumpsellerPromotionDetail from "../views/jumpseller/PromotionDetail.vue";
import JumpsellerPromotion from "../views/jumpseller/Promotion.vue";
import JumpsellerPromotionEdit from "../views/jumpseller/PromotionEdit.vue";
import Profile from "../views/Profile.vue";
import Company from "../views/company/Company.vue";
import CompanyShow from "../views/company/Show.vue";
import CompanyEdit from "../views/company/Edit.vue";
import Warehouses from "../views/Warehouses.vue";
import Warehouse from "../views/Warehouse.vue";
import WarehouseEdit from "../views/WarehouseEdit.vue";
import UserEdit from "../views/UserEdit.vue";
import Users from "../views/Users.vue";
import User from "../views/User.vue";
import BlockageEdit from "../views/blockages/BlockageEdit.vue";
import Blockage from "../views/blockages/Blockage.vue";
import BlockageShow from "../views/blockages/BlockageShow.vue";
import Blockages from "../views/blockages/Blockages.vue";
import Subscription from "../views/Subscription.vue";
import Banners from "../views/Banners.vue";
import Banner from "../views/Banner.vue";
import BannerEdit from "../views/BannerEdit.vue";

import integrationConfigRoutes from "./integration-config-routes";
import MercadoLibreRoutes from "./mercado-libre-routes";

import UserInboxNotification from "../views/UserInboxNotification/UserInboxNotificationIndex.vue";

Vue.use(VueRouter);

const goToHome = (to, from, next) => {
  if (store.state.currentUser) {
    next("/products");
  } else {
    next("/login");
  }
};

// route level code-splitting
// this generates a separate chunk (about.[hash].js) for this route
// which is lazy-loaded when the route is visited.

// lazy-loading example
// component: () => import(../views/Example.vue)

// Se desactiva el lazy-loading por producir errores de redireccionamiento
// en la aplicación por recibir mal las rutas en los hooks del ciclo de vida
// en App.vue

const baseRoutes = [
  {
    path: "/login",
    name: "Login",
    component: Login,

    meta: {
      requireAuth: false,
      title: "Login"
    }
  },
  {
    path: "/magento/:integration_config_id/attribute_sets/",
    name: "Magento",
    component: MagentoAttributeSets,

    meta: {
      requireAuth: true
    }
  },
  {
    path:
      "/magento/:integration_config_id/attribute_sets/:attribute_set_id/attributes",
    name: "MagentoAttributes",

    component: MagentoAttributes,
    meta: {
      requireAuth: true,
      action: "Editar"
    },
    props: true
  },
  {
    path: "/products",
    name: "Products",
    component: Products,
    meta: {
      requireAuth: true,
      title: "Productos"
    }
  },
  {
    path: "/admin/synchronization_monitoring",
    name: "Synchronization Monitoring",
    component: SynchronizationMonitoring,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/admin/option_list/create",
    name: "Create Option List",
    component: OptionListCreate,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/admin/pending_bulk_upload",
    name: "Pending Bulk Upload",
    component: PendingBulkUpload,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/admin/sidekiq_queue_monitor",
    name: "Sidekiq Queue Monitor",
    component: SidekiqQueueMonitor,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/admin/sidekiq_cron",
    name: "Sidekiq Cron",
    component: SidekiqCron,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/admin/messages_scheduled",
    name: "Messages Scheduled",
    component: MessagesScheduled,
    meta: {
      requireAuth: true,
      title: "Mensajes programados"
    }
  },
  {
    path: "/admin/message_scheduled",
    name: "Message Scheduled",
    children: [
      {
        path: "new",
        name: "Messages Scheduled New",
        component: MessageScheduledEdit,
        meta: {
          requireAuth: true,
          action: "Crear",
          title: "Nuevo | Mensaje programado"
        }
      },
      {
        path: ":id/edit",
        name: "Messages Scheduled Edit",
        component: MessageScheduledEdit,
        meta: {
          requireAuth: true,
          action: "Editar",
          title: "Editar | Mensaje programado"
        }
      }
    ],
    component: MessageScheduled
  },
  {
    path: "/admin/option_list/category/:id/edit",
    name: "Edit category option List",
    component: OptionListCategoryUpdate,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/admin/option_list/data_base/:id/edit",
    name: "Edit data base option List",
    component: OptionListDataBaseUpdate,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/admin/option_list/request/:id/edit",
    name: "Edit request option List",
    component: OptionListRequestUpdate,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/admin/option_list",
    name: "Option List",
    component: OptionList,
    meta: {
      requireAuth: true,
      title: "Listado de opciones"
    }
  },
  {
    path: "/admin/error_translation/:id/edit",
    name: "Edit Error Translation",
    component: ErrorTranslationEdit,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/admin/category_attribute/:id/edit",
    name: "Edit Category Attribute",
    component: CategoryAttributeEdit,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/admin/category_attribute/new",
    name: "Create Category Attribute",
    component: CategoryAttributeNew,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/admin/quarantined_attributes",
    name: "Quarantined Attributes",
    component: QuarantinedAttributeHomologations,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/admin/section/new",
    name: "Nueva regla de sección (video-tutoriales)",
    component: Section,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/admin/section/:sectionId/edit",
    name: "Editar regla de sección (video-tutoriales)",
    component: Section,
    meta: {
      requireAuth: true
    },
    props: true
  },
  {
    path: "/bulk_upload",
    name: "Bulk Upload",
    component: BulkUpload,
    meta: {
      requireAuth: true,
      title: "Carga masiva | Configuración de la carga"
    }
  },
  {
    path: "/bulk_upload/history",
    name: "Bulk Upload History",
    component: BulkUploadHistory,
    meta: {
      requireAuth: true,
      title: "Carga masiva"
    }
  },
  {
    path: "/bulk_upload/template_downloads",
    name: "Bulk Upload Template Downloads",
    component: BulkUploadTemplateDownloads,
    meta: {
      requireAuth: true,
      title: "Últimos archivos generados"
    }
  },
  {
    path: "/product",
    name: "Product",
    children: [
      {
        path: "new",
        name: "ProductNew",
        component: () => import("../views/ProductEdit.vue"),
        meta: {
          requireAuth: true,
          action: "Crear",
          title: "Crear | Producto"
        }
      },
      {
        path: "image_manager",
        name: "ProductImageManager",
        component: ProductImageManager,
        meta: {
          requireAuth: true,
          action: "Administrador de imágenes",
          title: "Administrador de imágene"
        },
        props: route => ({ productId: route.query.productId || "" })
      }
    ],
    component: () => import("../views/Product.vue")
  },
  {
    path: "/product/:id",
    children: [
      {
        path: "edit",
        name: "ProductEdit",
        component: ProductEdit,
        meta: {
          requireAuth: true,
          action: "Editar",
          title: "Editar | Producto"
        }
      },
      {
        path: "transactions",
        name: "ProductTransactions",
        component: ProductTransactions,
        meta: {
          requireAuth: true,
          action: "Movimientos",
          title: "Movimientos | Producto"
        }
      },
      {
        path: "/",
        name: "ProductDetail",
        component: ProductDetail,
        meta: {
          requireAuth: true,
          action: "Detalle",
          title: "Detalle | Producto"
        }
      },
      {
        path: "histories",
        name: "ProductHistories",
        component: ProductHistories,
        meta: {
          requireAuth: true,
          action: "Sincronizaciones",
          title: "Historial | Producto"
        }
      },
      {
        path: "inventory",
        name: "ProductInventory",
        component: ProductInventory,
        meta: {
          requireAuth: true,
          action: "Inventario",
          title: "Inventario | Producto"
        }
      }
    ],
    component: Product
  },
  {
    path: "/size_charts",
    name: "SizeCharts",
    component: SizeCharts,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/size_chart/",
    name: "SizeChart",
    component: SizeChart,
    meta: {
      requireAuth: true
    },
    children: [
      {
        path: "new",
        name: "sizeChartNew",
        component: SizeChart,
        meta: {
          requireAuth: true,
          action: "Crear"
        }
      }
    ]
  },
  {
    path: "/size_chart/:sizeChartId/edit",
    name: "sizeChartDetails",
    component: SizeChart,
    meta: {
      requireAuth: true,
      action: "Editar"
    },
    props: true
  },
  {
    path: "/stock_destinations/:integrationConfigId",
    name: "Stockdestinations",
    component: StockDestinations,
    meta: {
      requireAuth: true
    },
    props: true
  },
  {
    path: "/integracion/:integrationConfigId/translation_map",
    name: "TranslationCentryIntegrations",
    component: TranslationCentryIntegrations,
    meta: {
      requireAuth: true,
      action: "Index de TranslationIntegrations"
    },
    props: true
  },
  {
    path: "/integracion/:integrationConfigId/translation_map/new",
    name: "TranslationCentryIntegrationsNew",
    component: TranslationCentryIntegrationNew,
    meta: {
      requireAuth: true,
      action: "Nuevo TranslationIntegrations"
    },
    props: true
  },
  {
    path:
      "/integracion/:integrationConfigId/translation_map/:translationMapId/edit",
    name: "TranslationCentryIntegrationsEdit",
    component: TranslationCentryIntegrationEdit,
    meta: {
      requireAuth: true,
      action: "Edit TranslationIntegrations"
    },
    props: true
  },
  {
    path: "/",
    name: "Home",
    beforeEnter: goToHome
  },
  {
    path: "/dashboards/:dashboardId",
    name: "Report",
    component: Dashboard,
    meta: {
      requireAuth: true,
      action: "Show Dashboard"
    }
  },
  {
    path: "/inbox",
    name: "Inbox",
    component: UserInboxNotification,
    meta: {
      requireAuth: true,
      action: "Show Inbox"
    }
  },
  {
    path: "/orders",
    name: "Orders",
    component: Orders,

    meta: {
      requireAuth: true,
      action: "Orders List",
      title: "Órdenes"
    }
  },
  {
    path: "/orders/documents",
    name: "Documents",
    component: Documents,

    meta: {
      requireAuth: true,
      action: "Documents List",
      title: "Documentos"
    }
  },
  {
    path: "/orders2",
    name: "Orders2",
    component: Orders2,

    meta: {
      requireAuth: true,
      action: "Orders2 List",
      title: "Órdenes"
    }
  },
  {
    path: "/orders/:orderId",
    name: "Order",
    component: Order,
    meta: {
      requireAuth: true,
      action: "Order details",
      title: "Detalle | Orden"
    }
  },
  {
    path: "/sync_statuses",
    name: "LastSynchronizationStatuses",
    component: LastSynchronizationStatuses,

    meta: {
      requireAuth: true,
      action: "LastSynchronizationStatuses List",
      title: "Estado por integración"
    }
  },
  {
    path: "/moderations",
    name: "Moderations",
    component: Moderations,
    meta: {
      requireAuth: true,
      action: "Moderations List",
      title: "Moderaciones"
    }
  },
  {
    path: "/moderations/:productId/details",
    name: "ModerationsDetails",
    component: ModerationsDetails,
    props: true,
    meta: {
      requireAuth: true,
      action: "Moderations details",
      title: "Detalle | Moderaciones"
    }
  },
  {
    path: "/vtex_maintainer/:integrationConfigId",
    name: "VtexMaintainer",
    component: VtexMaintainer,
    props: true,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/vtex_homologation",
    name: "VtexHomologation",
    component: VtexHomologation,
    props: true,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/vtex_homologation/:caId/edit",
    name: "VtexHomologationSpecificationEdit",
    component: VtexHomologationSpecificationEdit,
    props: true,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/vtex_homologation/vtex_purposes",
    name: "VtexHomologationPurposes",
    component: VtexHomologationPurposes,
    props: true,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/vtex_homologation/vtex_purposes/form/:purposeId?",
    name: "VtexHomologationPurposeForm",
    component: VtexHomologationPurposeForm,
    props: true,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/vtex_maintainer/:integrationConfigId/category/:id?",
    name: "VtexCategoriesMaintainerNew",
    component: VtexCategoriesMaintainerNew,
    props: true,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/vtex_maintainer/:integrationConfigId/promotion/:id?",
    name: "VtexPromotionEdit",
    component: VtexPromotionEdit,
    props: true,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/jumpseller/promotions",
    name: "JumpsellerPromotions",
    component: JumpsellerPromotions,

    meta: {
      requireAuth: true,
      action: "Jumpseller Promotions List",
      title: "Promociones Jumpseller"
    }
  },
  {
    path: "/jumpseller/:integration_id/promotion",
    children: [
      {
        path: "new",
        name: "PromotionDetail",
        component: JumpsellerPromotionDetail,
        meta: {
          requireAuth: true,
          action: "Detalle Promocion",
          title: "Detalle | Promoción Jumpseller"
        }
      },
      {
        path: ":id/edit",
        name: "PromotionEdit",
        component: JumpsellerPromotionEdit,
        meta: {
          requireAuth: true,
          action: "Editar Promocion",
          title: "Editar | Promoción Jumpseller"
        }
      }
    ],
    component: JumpsellerPromotion
  },
  {
    path: "/profile/:id/edit",
    name: "Profile",
    component: Profile,
    meta: {
      requireAuth: true,
      action: "Editar perfil",
      title: "Editar | Perfil"
    }
  },
  {
    path: "/company/:id",
    children: [
      {
        path: "/",
        name: "CompanyShow",
        component: CompanyShow,
        meta: {
          requireAuth: true,
          action: "Información",
          title: "Mi Empresa"
        }
      },
      {
        path: "edit",
        name: "CompanyEdit",
        component: CompanyEdit,
        meta: {
          requireAuth: true,
          action: "Editar empresa",
          title: "Editar | Mi Empresa"
        }
      }
    ],
    component: Company
  },
  {
    path: "/warehouses",
    name: "Warehouses",
    component: Warehouses,
    meta: {
      requireAuth: true,
      action: "Warehouses",
      title: "Bodegas"
    },
    props: true
  },
  {
    path: "/warehouse",
    name: "Warehouse",
    children: [
      {
        path: "new",
        name: "WarehouseNew",
        component: WarehouseEdit,
        meta: {
          requireAuth: true,
          action: "Crear",
          title: "Nuevo | Bodega"
        }
      },
      {
        path: ":id/edit",
        name: "WarehouseEdit",
        component: WarehouseEdit,
        meta: {
          requireAuth: true,
          action: "Editar",
          title: "Editar | Bodega"
        }
      }
    ],
    component: Warehouse
  },
  {
    path: "/users",
    name: "Users",
    component: Users,
    meta: {
      requireAuth: true,
      action: "Users",
      title: "Usuarios"
    },
    props: true
  },
  {
    path: "/user",
    name: "User",
    children: [
      {
        path: "new",
        name: "UserNew",
        component: UserEdit,
        meta: {
          requireAuth: true,
          action: "Crear",
          title: "Nuevo | Usuario"
        }
      },
      {
        path: ":id/edit",
        name: "UserEdit",
        component: UserEdit,
        meta: {
          requireAuth: true,
          action: "Editar",
          title: "Editar | Usuario"
        }
      }
    ],
    component: User
  },
  {
    path: "/blockage",
    name: "Blockage",
    children: [
      {
        path: "new",
        name: "BlockageNew",
        component: BlockageEdit,
        meta: {
          requireAuth: true,
          action: "Crear",
          title: "Crear | Bloqueo"
        }
      },
      {
        path: ":id/edit",
        name: "BlockageEdit",
        component: BlockageEdit,
        meta: {
          requireAuth: true,
          action: "Editar",
          title: "Editar | Bloqueo"
        }
      },
      {
        path: ":id",
        name: "BlockageShow",
        component: BlockageShow,
        meta: {
          requireAuth: true,
          action: "Detalle",
          title: "Detalle | Bloqueo"
        }
      }
    ],
    component: Blockage
  },
  {
    path: "/blockages",
    name: "Blockages",
    component: Blockages,
    meta: {
      requireAuth: true,
      action: "Blockages",
      title: "Bloqueos"
    },
    props: true
  },
  {
    path: "/subscription",
    name: "Subscription",
    component: Subscription,
    meta: {
      requireAuth: true,
      action: "Subscription",
      title: "Suscripción"
    },
    props: true
  },
  {
    path: "/admin/banners",
    name: "Banners",
    component: Banners,
    meta: {
      requireAuth: true,
      action: "Banners",
      title: "Banners"
    },
    props: true
  },
  {
    path: "/admin/banner",
    name: "Banner",
    children: [
      {
        path: "new",
        name: "BannerNew",
        component: BannerEdit,
        meta: {
          requireAuth: true,
          action: "Crear",
          title: "Nuevo | Banner"
        }
      },
      {
        path: ":id/edit",
        name: "BannerEdit",
        component: BannerEdit,
        meta: {
          requireAuth: true,
          action: "Editar",
          title: "Editar | Banner"
        }
      }
    ],
    component: Banner
  }
];

let routes = baseRoutes.concat(integrationConfigRoutes);
routes = routes.concat(MercadoLibreRoutes);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
