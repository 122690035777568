<template>
  <div>
    <ApolloQuery
      :query="
        require('../../../graphql/BulkUpload/TemplateDownload/AllBulkUploadTemplateDownload.gql')
      "
      :variables="{ cursor: '', first: first }"
      :fetchPolicy="'cache-and-network'"
      clientId="apolloClientCached"
      notifyOnNetworkStatusChange
    >
      <template slot-scope="{ result: { error, data }, query, isLoading }">
        <span v-if="data && isLoading" class="m-2 float-right">
          Actualizando tabla...
        </span>
        <b-table-simple
          responsive
          v-if="data && data.allBulkUploadTemplateDownload"
          hover=""
        >
          <b-thead>
            <b-tr>
              <b-th>Fecha</b-th>
              <b-th>Template</b-th>
              <b-th>Solicitado por</b-th>
              <b-th>Progreso</b-th>
              <b-th>Descargar archivo</b-th>
              <b-th>Informe de Errores</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-if="!data.allBulkUploadTemplateDownload.edges.length">
              <b-td colspan="6">No existe registro de ninguna carga</b-td>
            </b-tr>
            <downloads-table-row
              v-for="history of data.allBulkUploadTemplateDownload.edges"
              :key="history.node.id"
              :history="history.node"
            >
            </downloads-table-row>
          </b-tbody>
        </b-table-simple>
        <b-alert v-if="error" show="" variant="danger">
          Ha ocurrido un error
        </b-alert>
        <b-skeleton-table
          v-if="!data && isLoading"
          :rows="10"
          :columns="5"
          :table-props="{ bordered: true, striped: true }"
        ></b-skeleton-table>
        <div v-else>
          <b-spinner v-if="data && isLoading" class="m-2 float-right" />
          <b-button
            v-else-if="hasNextPage(data)"
            class="m-2 float-right"
            @click="
              seeMore(
                query,
                data.allBulkUploadTemplateDownload.pageInfo.endCursor
              )
            "
          >
            Ver más
          </b-button>
          <b-alert v-else show variant="light" class="text-center">
            No hay más datos para mostrar.
          </b-alert>
        </div>
      </template>
    </ApolloQuery>
  </div>
</template>
<script>
import DownloadsTableRow from "./DownloadsTableRow.vue";
export default {
  name: "DownloadsTable",
  components: {
    DownloadsTableRow
  },
  data() {
    return {
      first: 15,
      newDataLength: null,
      firstTime: true
    };
  },
  methods: {
    /**
     * Indica si hay una página siguiente en la query de GraphQl basado
     * exclusivamente en la existencia de un cursor.
     * @return {boolean}
     */
    hasNextPage(data) {
      if (this.firstTime) {
        return data.allBulkUploadTemplateDownload.edges.length == this.first;
      } else {
        return this.newDataLength == this.first;
      }
    },
    /**
     * Trae más resultados de una query avanzando en su cursor.
     * @param {Object} query
     * @param {String} cursor
     */
    async seeMore(query, cursor) {
      await query.fetchMore({
        variables: {
          cursor: cursor,
          first: this.first
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          this.firstTime = false;
          const updated = Object.assign({}, this.$dup(prev));
          updated.allBulkUploadTemplateDownload.pageInfo =
            fetchMoreResult.allBulkUploadTemplateDownload.pageInfo;
          if (
            fetchMoreResult?.allBulkUploadTemplateDownload?.edges?.length !== 0
          ) {
            updated.allBulkUploadTemplateDownload.edges.push(
              ...fetchMoreResult.allBulkUploadTemplateDownload.edges
            );
            this.newDataLength =
              fetchMoreResult.allBulkUploadTemplateDownload.edges.length;
          } else {
            this.newDataLength = 0;
          }
          return updated;
        }
      });
    }
  }
};
</script>
