<template>
  <div>
    <b-row>
      <b-col>
        <b-form-group
          :label="label"
          :description="
            domainQuery.length < 3 ? 'Escriba al menos 3 letras' : ''
          "
        >
          <b-form-input
            id="search-domain"
            v-model="domainQuery"
            placeholder="Ej: Zapatillas MiMarca de Mujer"
            debounce="1000"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-spinner v-if="loading"></b-spinner>
        <span v-else-if="queryWithoutOptions">
          -- La búsqueda no arrojó resultados --
        </span>
        <b-list-group v-else-if="domains && domains.length">
          <b-list-group-item
            v-for="d of domains"
            :key="d.domainId"
            target="_blank"
            href="#"
            :active="activeDomain(d)"
            @click="() => selectDomain(d)"
          >
            {{ d.domainName }}
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import INTEGRATION_CONFIG_MERCADO_LIBRE_DOMAIN_DISCOVERY from "../../../graphql/IntegrationConfigMercadoLibreDomainDiscovery.gql";

export default {
  components: {},
  name: "MercadoLibreDomainSelector",
  model: {
    prop: "domain",
    event: "change"
  },
  props: {
    domain: {
      type: Object,
      required: false
    },
    label: {
      type: String,
      default: "Buscar dominio"
    },
    integrationConfigId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      domainQuery: "",
      domains: [],
      loading: false
    };
  },
  computed: {
    queryWithoutOptions() {
      return (
        this.domainQuery &&
        this.domainQuery.length >= 3 &&
        !this.domains.length &&
        !this.loading
      );
    }
  },
  methods: {
    /**
     * Emite evento de selección de dominio
     */
    selectDomain(domain) {
      this.$emit("change", domain);
    },
    /**
     * Predice los dominios que mas se acercan a query de busqueda
     * @param {String} newQuery
     */
    predictDomains(newQuery) {
      this.loading = true;
      this.$apollo
        .query({
          query: INTEGRATION_CONFIG_MERCADO_LIBRE_DOMAIN_DISCOVERY,
          variables: {
            q: newQuery,
            integrationConfigId: this.integrationConfigId
          }
        })
        .then(({ data }) => {
          const hash = {};
          this.$ifNull(
            data.integrationConfigMercadoLibre.domainDiscovery,
            []
          ).forEach(domain => {
            hash[domain.domainId] = domain;
          });
          this.domains = Object.values(hash);
          this.loading = false;
        });
    },
    /**
     * Activa en listado de dominios, el dominio seleccionado
     * @param {Object} domainItem
     * @returns {Boolean}
     */
    activeDomain(domainItem) {
      if (!this.domain) return false;
      return domainItem.domainId === this.domain.domainId;
    }
  },
  watch: {
    domainQuery(newQuery) {
      if (newQuery.length < 3) {
        this.domains = [];
        return;
      }
      this.predictDomains(newQuery);
    }
  }
};
</script>
<style scoped>
tr {
  cursor: pointer;
}
</style>
