<template>
  <div>
    <b-table-simple>
      <b-thead>
        <tr>
          <th :colspan="3">Datos en Centry</th>
          <th :colspan="3">Datos en MercadoLibre</th>
        </tr>
        <tr>
          <th>Color</th>
          <th>Talla</th>
          <th>* SKU Centry</th>
          <b-th
            v-for="(attribute, position) of category_attributes"
            :key="position"
          >
            {{ (attribute.required ? "* " : "") + attribute.label }}
          </b-th>
        </tr>
      </b-thead>

      <b-tbody>
        <b-tr v-for="(_variant, position) of array" :key="position">
          <b-td v-if="array[position].color">{{
            array[position].color.name
          }}</b-td>
          <b-td v-else>Sin Color</b-td>
          <b-td v-if="array[position].size">{{
            array[position].size.name
          }}</b-td>
          <b-td v-else>Sin Talla</b-td>
          <b-td>{{ array[position].sku }}</b-td>
          <b-td v-for="attribute of category_attributes" :key="attribute.value">
            <div>
              <b-spinner
                label="Spinning"
                v-if="
                  (attribute.allow_variations && loadingSoldInformation) ||
                    (attribute.value == 'SIZE_GRID_ROW_ID' && sizeChartLoading)
                "
              ></b-spinner>
              <product-edit-tabs-mercado-libre-attributes-input
                v-else
                v-model="
                  array[position].integrations[integrationConfig.fullLabel][
                    attribute.value
                  ]
                "
                :attribute-info="attribute"
                :disabled="disabledAttribute(attribute, position)"
                :show-not-apply-button="!attribute.allow_variations"
                :input-and-button-on-same-line="false"
                @input="emitChange"
              />
            </div>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
import MERCADO_LIBRE_VARIATIONS_SOLD_INFO from "../graphql/MercadoLibreVariationsSoldInfo.gql";
import ProductEditTabsMercadoLibreAttributesInput from "./ProductEditTabsMercadoLibreAttributesInput.vue";

export default {
  name: "ProductEditMercadoLibreVariants",
  components: { ProductEditTabsMercadoLibreAttributesInput },
  model: {
    prop: "_value",
    event: "change"
  },
  props: {
    _value: {
      type: Array,
      default() {
        return [];
      }
    },
    integrationConfig: Object,
    category_attributes: Array,
    meli_id: String,
    sizeChartLoading: Boolean
  },
  data() {
    return {
      array: this.$dup(this._value),
      originalArray: JSON.parse(JSON.stringify(this._value)),
      integration: this.integrationConfig,
      values: [],
      variationsSoldInfo: {},
      loadingSoldInformation: true
    };
  },
  async mounted() {
    this.getVariationsSoldInfo().then(
      () => (this.loadingSoldInformation = false)
    );
  },
  methods: {
    emitChange() {
      this.$emit("change", this.array);
    },
    /**
     * Realiza la petición para saber si las variaciones del item han sido vendidas
     * o no por MercadoLibre.
     */
    async getVariationsSoldInfo() {
      if (this.meli_id) {
        await this.$apollo
          .query({
            query: MERCADO_LIBRE_VARIATIONS_SOLD_INFO,
            variables: {
              integrationConfigId: this.integrationConfig.id,
              meliId: this.meli_id
            }
          })
          .then(({ data }) => {
            if (data?.mercadoLibreVariationsSoldInfo?.soldInfo) {
              this.variationsSoldInfo =
                data.mercadoLibreVariationsSoldInfo.soldInfo;
            }
          });
      }
    },
    /**
     * Deshabilita la edición de un atributo de las variantes si el atributo
     * en Centry tiene un valor asignado y la variación en Meli ya ha sido vendida.
     * @param {Object} attribute Atributo de la variante
     * @param {Number} position Posición de la variante
     */
    disabledAttribute(attribute, position) {
      if (!attribute.allow_variations) return false;
      const attributeId = attribute.value;
      const integrationLabel = this.integrationConfig.fullLabel;
      const originalVariantIntegrationHash = this.originalArray[position]
        .integrations[integrationLabel];
      const filledOriginalAttributeValue = !!originalVariantIntegrationHash[
        attributeId
      ].value;
      const soldVariation = this.variationsSoldInfo[
        originalVariantIntegrationHash.id
      ];
      return filledOriginalAttributeValue && soldVariation;
    }
  }
};
</script>
