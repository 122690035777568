<template>
  <v-select
    v-model="value"
    @input="emitChange"
    :id="id"
    :clearable="clearable"
    :placeholder="placeholder"
    :options="paginated"
    :multiple="multiple"
    @search="inputSearch"
    @open="resetValues"
  >
    <template v-slot:no-options="{ search, loading }">
      <b-spinner
        v-if="
          loading ||
            (search.length >= minSearchCharacters && !finishedCountdown)
        "
        label="Spinning"
      ></b-spinner>
      <p v-else-if="search.length >= minSearchCharacters">
        No hay opciones disponibles
      </p>
      <p v-else>
        Escriba al menos {{ minSearchCharacters }} caracter{{
          minSearchCharacters > 1 ? "es" : ""
        }}
      </p>
    </template>
    <li v-if="options.length >= limit" slot="list-footer" class="pagination">
      <b-button
        variant="outline-info"
        size="sm"
        :disabled="offset == 0"
        @click="offset -= limit"
        >Anterior</b-button
      >
      <b-button
        variant="outline-info"
        size="sm"
        :disabled="offset === endElements"
        @click="updateOptions(search, true)"
      >
        Siguiente
      </b-button>
    </li>
  </v-select>
</template>
<script>
export default {
  name: "PaginatedBaseLiveSelect",
  model: {
    prop: "_value",
    event: "change"
  },
  props: {
    _value: {
      type: [Object, Array],
      default() {
        return null;
      }
    },
    id: String,
    clearable: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String,
      default: ""
    },
    minSearchCharacters: {
      type: Number,
      default: 3
    },
    multiple: {
      type: Boolean,
      default: false
    },
    timeout: {
      type: Number,
      default: 2
    },
    limit: {
      type: Number,
      default: 100
    },
    query: {
      type: Function,
      default: () => {
        return { items: [], cursor: null };
      }
    },
    valueOption: {
      type: String,
      default: "value"
    },
    labelOption: {
      type: String,
      default: "label"
    }
  },
  data() {
    return {
      value: this._value,
      options: [],
      timeoutId: null,
      finishedCountdown: true,
      offset: 0,
      cursor: null,
      search: null,
      endElements: null
    };
  },
  computed: {
    paginated() {
      return this.options.slice(this.offset, this.limit + this.offset);
    }
  },
  methods: {
    emitChange() {
      this.$emit("change", this.value);
    },
    inputSearch(search) {
      clearTimeout(this.timeoutId);
      if (search.length >= this.minSearchCharacters) {
        this.search = search;
        this.finishedCountdown = false;
        this.options = [];
        this.resetValues();
        this.timeoutId = setTimeout(
          this.updateOptions,
          this.timeout * 1000,
          search
        );
      } else {
        this.options = [];
      }
    },
    async updateOptions(search, next = false) {
      if (!next || this.cursor) {
        this.finishedCountdown = true;
        this.loading = true;
        let { items, cursor } = await this.query(
          search,
          this.limit,
          this.cursor
        );
        this.loading = false;
        if (!items || !items.length) {
          this.endElements = this.offset;
          return;
        }
        this.cursor = cursor;
        this.setOptions(items);
      }
      if (next) {
        this.offset += this.limit;
      }
    },
    setOptions(options) {
      this.options = this.options.concat(
        options.map(x => {
          return this.itemForSelect(x);
        })
      );
    },
    itemForSelect(item) {
      if (item) {
        return { value: item[this.valueOption], label: item[this.labelOption] };
      }
      return null;
    },
    resetValues() {
      this.offset = 0;
      this.cursor = null;
      this.endElements = null;
    }
  },
  watch: {
    _value(newVal) {
      this.value = newVal;
    }
  }
};
</script>

<style scoped>
.pagination {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}
.pagination button {
  flex-grow: 1;
}
</style>
