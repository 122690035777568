<template>
  <b-tr>
    <b-td
      ><span>{{ formatedCreatedDate }} </span></b-td
    >
    <b-td
      ><span> {{ internalReport.responsible.name }} </span>
    </b-td>
    <b-td>
      <span>{{ internalReport.progress | toPercentage }} </span></b-td
    >
    <b-td>
      <b-button
        v-if="internalReport.progress == 1"
        size="lg"
        variant="outlined"
        class="p-0 focus-btn"
        :href="internalReport.url"
        title="Descargar archivo"
        v-b-tooltip.hover
      >
        <b-icon stacked icon="download" scale="0.60"></b-icon>
      </b-button>
    </b-td>
  </b-tr>
</template>

<script>
import WEBHOOK_REPORT_BY_ID from "../../graphql/Webhooks/WebhookReport.gql";

export default {
  name: "ReportsTableRow",
  props: {
    report: Object,
    currentUser: Object
  },
  data() {
    return {
      internalReport: this.$dup(this.report),
      mounted: true
    };
  },
  mounted() {
    setTimeout(this.updateInternalReport, 1000);
  },
  beforeDestroy() {
    this.mounted = false;
  },
  computed: {
    formatedCreatedDate() {
      const date = this.$timezoneDate(
        this.currentUser,
        this.internalReport.createdAt
      );
      return this.$moment(date).format("DD/MM/YYYY HH:mm");
    },
    isUpdatableInternalReport() {
      return (
        this.isPending &&
        new Date() - new Date(this.internalReport.updatedAt) < 60 * 60 * 1000
      );
    },
    isPending() {
      return this.internalReport.progress < 1;
    }
  },
  methods: {
    /**
     * Actualiza el progreso de un reporte si es que puede
     */
    async updateInternalReport() {
      if (this.isUpdatableInternalReport) {
        await this.$apollo
          .query({
            query: WEBHOOK_REPORT_BY_ID,
            variables: {
              id: this.report.id
            },
            fetchPolicy: "no-cache"
          })
          .then(({ data }) => {
            if (!data?.webhookReport) return;
            Object.assign(this.internalReport, data.webhookReport);
          });
        if (this.mounted) setTimeout(this.updateInternalReport, 1000);
      }
    }
  }
};
</script>

<style scoped>
.focus-btn {
  color: #aab1b5;
  display: inline-block;
  background-color: white;
  width: 34px;
  height: 34px;
  text-align: center;
  border-radius: 50%;
  font-size: 14px;
  border: 2px solid #e0e2e4;
  padding: 5px 0;
}

.focus-btn:hover {
  border-color: #aab1b5;
  color: black;
}
</style>
