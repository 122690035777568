<template>
  <div>
    <b-alert v-if="saveStatus" v-model="saved" variant="success" dismissible>
      Las homologaciones fueron actualizadas correctamente.
    </b-alert>
    <b-alert v-else v-model="saved" variant="danger" dismissible>
      No se pudo actualizar por el siguiente motivo:
      <ul>
        <li>{{ errorMessage }}</li>
      </ul>
    </b-alert>
    <b-row align-v="center">
      <b-col md="9">
        <b-button variant="info" @click="save" :disabled="saving">
          <span v-if="saving"><b-spinner label="Spinning"></b-spinner></span>
          <b-icon-cloud-upload></b-icon-cloud-upload>Guardar
        </b-button></b-col
      ><b-col>
        <b-form-checkbox v-model="allValues"
          >Mostrar todos los valores</b-form-checkbox
        >
      </b-col></b-row
    >
    <base-j-excel-table
      :columns="columns"
      v-model="currentRows"
      :custom-on-change="this.onChange"
      :skip-validation="true"
      :after-mounted="this.setTableStyle"
      :allow-search="true"
      :pagination="30"
    ></base-j-excel-table>
  </div>
</template>

<script>
import { mapState } from "vuex";
import BaseJExcelTable from "@/components/BaseJExcelTable";
import jexcel from "jexcel";
export default {
  name: "BaseHomologationTable",
  props: {
    columns: Array,
    rows: Array,
    purposesIndexRev: Object,
    homologationsIdsRev: Object,
    homologationIndex: Object,
    mutation: Object,
    mutationName: String,
    modelName: String,
    allValuesFather: Boolean
  },
  components: { BaseJExcelTable },
  computed: {
    ...mapState(["currentUser"])
  },
  data() {
    return {
      currentRows: [...this.rows],
      oldRows: [...this.rows],
      saveStatus: null,
      error: false,
      saving: false,
      saved: false,
      errorMessage: "",
      allValues: this.allValuesFather
    };
  },
  async mounted() {},
  methods: {
    /**
     * Manda a guardar las homologaciones con un body del tipo
     * {modelId: [{homologableId: "idPurpose", "v": "valor"}]}
     * y el modelo que sería la clase la cual puede ser:
     * [Products::Brand Products::Category Products::Size
     * Products::Gender Products::Color Products::Warranty
     * Products::CategoryAttributeOption]
     */
    save() {
      this.saving = true;
      const allHomologations = this.getHomologationsToSave();
      this.$apollo
        .mutate({
          mutation: this.mutation,
          variables: {
            body: allHomologations,
            model: this.modelName
          }
        })
        .then(({ data }) => {
          if (!data[this.mutationName].result) {
            this.errorMessage = data[this.mutationName].errors;
          } else {
            this.currentRows = [...this.currentRows];
          }
          this.saveStatus = data[this.mutationName].result;
          this.saving = false;
          this.saved = true;
        });
    },
    /**
     * Recorre las filas de la tabla, guardando en un objeto
     * solo aquellas celdas en las que hubo un cambio. El objeto
     * retornado sigue la siguiente estructura:
     * { modelId: [{ homologableId: "idPurpose", "v": "valor" }] }
     */
    getHomologationsToSave() {
      const homologationsToSave = {};
      for (let row = 0; row < this.currentRows.length; row++) {
        if (
          !homologationsToSave[this.homologationIndex[this.currentRows[row][0]]]
        ) {
          homologationsToSave[
            this.homologationIndex[this.currentRows[row][0]]
          ] = [];
        }
        for (let i = 1; i < this.currentRows[row].length; i++) {
          let homologationToSave = {};
          let actualRow = this.currentRows[row][i];
          let homologableId = this.purposesIndexRev[i];
          if (actualRow !== this.oldRows[row][i]) {
            homologationToSave = {
              v:
                this.homologationsIdsRev[homologableId][actualRow] || actualRow,
              homologableId: homologableId
            };
          }
          if (Object.keys(homologationToSave).length) {
            homologationsToSave[
              this.homologationIndex[this.currentRows[row][0]]
            ].push(homologationToSave);
          }
        }
      }
      return homologationsToSave;
    },
    /**
     * Método que se le entregará a el componente de jexcel que
     * modifica el estilo de una celda cuando el contenido de esta
     * cambia.
     * @param instance
     * @param cell
     * @param x - numero de columna
     * @param y - numero de fila
     * @param value - valor de la celda
     * @param jExcelInstance - instancia de jexcel
     */
    onChange(instance, cell, x, y, value, jExcelInstance) {
      const oldValue = this.rows[y][x];
      if (value !== oldValue)
        jExcelInstance.setStyle(
          jexcel.getColumnNameFromId([x, y]),
          "background-color",
          "yellow",
          true
        );
      else
        jExcelInstance.setStyle(
          jexcel.getColumnNameFromId([x, y]),
          "background-color",
          "white",
          true
        );
    },
    /**
     * Método que se le entragará al componente de jexcel que
     * setea el estilo de la tabla al inicio de esta
     * @param jExcelInstance - instancia de jexcel
     */
    setTableStyle(jExcelInstance) {
      this.rows.forEach((row, rowNum) => {
        row.forEach((col, colNum) => {
          jExcelInstance.setStyle(
            jexcel.getColumnNameFromId([colNum, rowNum]),
            "background-color",
            "white"
          );
        });
      });
    }
  },
  watch: {
    allValues(newValue) {
      this.$emit("all-values", newValue);
      this.allValues = newValue;
    }
  }
};
</script>
