<template>
  <b-container>
    <b-alert
      v-if="error"
      show=""
      variant="danger"
      dismissible
      @dismissed="error = false"
    >
      {{ errorMessage }}
    </b-alert>
    <b-row>
      <b-col md="12">
        <b-row>
          <b-col md="3">
            <b-form-group
              label="Rut"
              label-for="code"
              :invalid-feedback="validation.code.invalidFeedback"
              :state="validation.code.valid"
            >
              <b-form-input id="code" v-model="code" size="sm"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="Nombre"
              label-for="name"
              :invalid-feedback="validation.name.invalidFeedback"
              :state="validation.name.valid"
            >
              <b-form-input id="name" v-model="name" size="sm"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="Apellido"
              label-for="lastname"
              :invalid-feedback="validation.lastname.invalidFeedback"
              :state="validation.lastname.valid"
            >
              <b-form-input
                id="lastname"
                v-model="lastname"
                size="sm"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="Empresa"
              label-for="company"
              :invalid-feedback="validation.company.invalidFeedback"
              :state="validation.company.valid"
            >
              <b-form-input
                id="company"
                v-model="company"
                size="sm"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="12">
        <b-row>
          <b-col md="3">
            <b-form-group
              label="Dirección"
              label-for="address"
              :invalid-feedback="validation.address.invalidFeedback"
              :state="validation.address.valid"
            >
              <b-form-input
                id="address"
                v-model="address"
                size="sm"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="Ciudad"
              label-for="city"
              :invalid-feedback="validation.city.invalidFeedback"
              :state="validation.city.valid"
            >
              <b-form-input id="city" v-model="city" size="sm"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="Comuna"
              label-for="municipality"
              :invalid-feedback="validation.municipality.invalidFeedback"
              :state="validation.municipality.valid"
            >
              <b-form-input
                id="municipality"
                v-model="municipality"
                size="sm"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Actividad" label-for="activity">
              <b-form-input
                id="activity"
                v-model="activity"
                size="sm"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="12">
        <b-row>
          <b-col md="3">
            <b-form-group label="Email" label-for="email">
              <b-form-input id="email" v-model="email" size="sm"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Teléfono" label-for="phone">
              <b-form-input id="phone" v-model="phone" size="sm"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="12" class="text-center">
        <b-spinner v-if="loading" />
        <b-button
          class="m-2"
          variant="info"
          size="sm"
          @click="save"
          :disabled="loading"
        >
          Guardar y Elegir
        </b-button>
        <b-button
          class="m-2"
          variant="secondary"
          size="sm"
          :disabled="loading"
          @click="restartForm"
        >
          Cancelar
        </b-button>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import CREATE_DEFAULT_CLIENT from "../../../graphql/IntegrationConfig/BSale/createClient.gql";

export default {
  name: "FormCreateClient",
  props: {
    ic: Object
  },
  model: {},
  data() {
    return {
      name: "",
      code: "",
      lastname: "",
      phone: "",
      address: "",
      email: "",
      municipality: "",
      activity: "",
      city: "",
      company: "",
      error: false,
      errorMessage: "",
      loading: false
    };
  },
  computed: {
    validation() {
      return {
        name: {
          valid: this.name.length > 0,
          invalidFeedback: "El nombre es obligatorio"
        },
        code: {
          valid: this.code.length > 0,
          invalidFeedback: "El rut es obligatorio"
        },
        lastname: {
          valid: this.lastname.length > 0,
          invalidFeedback: "El Apellido es obligatorio"
        },
        address: {
          valid: this.address.length > 0,
          invalidFeedback: "El dirección es obligatorio"
        },
        municipality: {
          valid: this.municipality.length > 0,
          invalidFeedback: "La comuna es obligatoria"
        },
        company: {
          valid: this.company.length > 0,
          invalidFeedback: "La empresa es obligatoria"
        },
        city: {
          valid: this.city.length > 0,
          invalidFeedback: "La ciudad es obligatoria"
        }
      };
    },
    //Valida el conjunto de validaciones completo
    valid() {
      return Object.values(this.validation).reduce(
        (acum, object) => acum && object.valid,
        true
      );
    }
  },
  methods: {
    /**
     * Cancela Formulario reiniciando campos y volviendo a tabla de clientes
     */
    restartForm() {
      this.restartFieldsForm();
      this.$emit("cancel");
    },
    /**
     * Restaura formulario con valores vacios
     */
    restartFieldsForm() {
      this.name = "";
      this.code = "";
      this.lastname = "";
      this.phone = "";
      this.address = "";
      this.email = "";
      this.municipality = "";
      this.activity = "";
      this.city = "";
      this.company = "";
    },
    /**
     * Guarda nuevo cliente bsale directamente en bsale
     */
    async save() {
      this.loading = true;
      if (!this.valid) {
        this.error = true;
        this.errorMessage = "Complete todos los campos obligatorios";
        this.loading = false;
        return;
      }
      await this.createClient();
      this.loading = false;
    },
    /**
     * Construye diccionario con valores ingresados en el formulario
     */
    clientData() {
      const variables = {};
      variables.code = this.code;
      variables.firstName = this.name;
      variables.lastName = this.lastname;
      variables.municipality = this.municipality;
      variables.city = this.city;
      variables.address = this.address;
      variables.company = this.company;
      variables.companyOrPerson = true;
      variables.email = this.email;
      variables.phone = this.phone;
      variables.activity = this.activity;
      return variables;
    },
    /**
     * Prepara creacion de Cliente Bsale y
     * recibe resultado de la mutación
     */
    async createClient() {
      const client_body = this.clientData();
      if (client_body === null) {
        this.error = true;
        this.errorMessage = "Hubo un problema al cargar campos";
        return;
      }

      await this.createClientMutation(this.$apollo, this.ic.id, client_body)
        .then(async ({ data }) => {
          if (this.$dig(data, "createClientBSale", "result")) {
            this.error = false;
            this.errorMessage = "";
            this.$emit("choice", data.createClientBSale.client);
            this.restartForm();
          } else {
            this.error = true;
            this.errorMessage = data.createClientBSale.error;
          }
        })
        .catch(error => {
          this.error = true;
          this.errorMessage = error;
        });
    },
    /**
     * Realiza mutación para crear un nuevo cliente BSale
     * @param {Object} apollo
     * @param {String} apollo
     * @param {Object} patch
     */
    createClientMutation(apollo, ic_id, patch) {
      const variables = {
        integrationConfigId: ic_id,
        patch: patch
      };
      return apollo.mutate({
        mutation: CREATE_DEFAULT_CLIENT,
        variables: variables
      });
    }
  },
  watch: {}
};
</script>
