<template>
  <b-tr>
    <b-td
      ><base-pretty-check-box
        v-model="selected"
        @change="value => $emit('change', value)"
        :disabled="disableOrderSelection"
      ></base-pretty-check-box>
    </b-td>
    <b-td>{{ order.origin }}</b-td>
    <b-td>
      {{ order.numberOrigin ? order.numberOrigin : order.idOrigin }}
    </b-td>
    <b-td v-if="configColumns.client">
      {{ order.buyerFirstName }} {{ order.buyerLastName }}
      {{ prettyClientType }}
    </b-td>
    <b-td
      v-b-tooltip.hover
      :title="$timezoneDate(currentUser, order.createdAt) | formatDateTime"
    >
      {{ $timezoneDate(currentUser, order.createdAtOrigin) | formatDateTime }}
    </b-td>
    <b-td v-if="configColumns.dateForDelivery">{{
      order.dateForDelivery | formatDateTime
    }}</b-td>
    <b-td v-b-tooltip.hover :title="order.statusOrigin">
      {{ order.status | translateState }}
    </b-td>
    <b-td v-if="configColumns.shippingLabel">
      <b-icon-check v-if="order.shippingLastPrintDate"></b-icon-check>
    </b-td>
    <b-td v-if="configColumns.total">{{ order.paidAmount | toCurrency }}</b-td>
    <b-td v-if="configColumns.items">
      {{ order.itemsCount }}
      <span v-if="order.itemsCount == 1">item</span>
      <span v-else>items</span>
    </b-td>
    <b-td v-if="configColumns.modifyStock">
      {{ order.modifyStock ? "No" : "Si" }}
    </b-td>
    <b-td v-if="configColumns.priorityShipping">
      {{ order.priorityShipping ? "Si" : "No" }}
    </b-td>
    <b-td>
      <b-button
        size="lg"
        variant="outlined"
        class="p-0 focus-btn"
        v-b-tooltip.hover="'Ver detalle'"
        :to="`/orders/${order.id}`"
      >
        <b-icon stacked icon="eye" scale="0.75"></b-icon>
      </b-button>
    </b-td>
  </b-tr>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import BasePrettyCheckBox from "@/components/BasePrettyCheckBox";
export default {
  name: "OrdersTableRow",
  components: { BasePrettyCheckBox },
  mounted() {
    this.setSelectedOrder({
      orderId: this.order.id,
      selected: false,
      order: this.order
    });
  },
  destroyed() {
    this.unsetSelectedOrder(this.order.id);
  },
  data() {
    return {
      selected: false
    };
  },
  computed: {
    ...mapState(["selectedOrders", "disableOrderSelection", "currentUser"]),
    /**
     * Muestra deforma legible el tipo de cliente al usuario
     * @return {String}
     */
    prettyClientType() {
      if (this.order.clientType === "guest") {
        return "(Invitado)";
      } else {
        return "";
      }
    }
  },
  props: {
    order: Object,
    configColumns: Object
  },
  methods: {
    ...mapMutations(["setSelectedOrder", "unsetSelectedOrder"])
  },
  watch: {
    selected(val) {
      if (val !== this.selectedOrders[this.order.id].selected)
        this.setSelectedOrder({
          orderId: this.order.id,
          selected: val,
          order: this.order
        });
    },
    selectedOrders(hash) {
      const current = hash[this.order.id];
      this.selected = current.selected;
    }
  }
};
</script>

<style>
.focus-btn {
  color: #aab1b5;
  display: inline-block;
  background-color: white;
  width: 34px;
  height: 34px;
  text-align: center;
  border-radius: 50%;
  font-size: 14px;
  border: 2px solid #e0e2e4;
  padding: 5px 0;
}

.focus-btn:hover {
  border-color: #aab1b5;
  color: black;
}
</style>
