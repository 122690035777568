<template>
  <div>
    <h4 class="font-weight-bold m-3">Condiciones de la promoción</h4>

    <vtex-base-promotion-orders-and-items-prices v-model="promotion" />
    <br />

    <vtex-base-promotion-price-list-and-bin v-model="promotion" />
    <br />

    <vtex-base-promotion-tags-and-affiliates v-model="promotion" />
    <br />

    <vtex-base-promotion-cluster-and-countries
      v-model="promotion"
      :vtexIntegrationConfigId="vtexIntegrationConfigId"
    />
    <br />

    <vtex-base-promotion-zip-code-and-shipping-type
      v-model="promotion"
      :vtexIntegrationConfigId="vtexIntegrationConfigId"
      :shippingDiscount="shippingDiscount"
    />
    <br />

    <vtex-base-promotion-payments-and-installments
      v-model="promotion"
      :vtexIntegrationConfigId="vtexIntegrationConfigId"
    />
    <br />

    <vtex-base-promotion-first-buy-and-subscription v-model="promotion" />
    <br />

    <vtex-base-promotion-utm-and-utmi v-model="promotion" />
    <hr />
  </div>
</template>

<script>
import VtexBasePromotionOrdersAndItemsPrices from "./VtexPromotionConditions/VtexBasePromotionOrdersAndItemsPrices.vue";
import VtexBasePromotionPriceListAndBin from "./VtexPromotionConditions/VtexBasePromotionPriceListAndBin.vue";
import VtexBasePromotionTagsAndAffiliates from "./VtexPromotionConditions/VtexBasePromotionTagsAndAffiliates.vue";
import VtexBasePromotionClusterAndCountries from "./VtexPromotionConditions/VtexBasePromotionClusterAndCountries.vue";
import VtexBasePromotionZipCodeAndShippingType from "./VtexPromotionConditions/VtexBasePromotionZipCodeAndShippingType.vue";
import VtexBasePromotionPaymentsAndInstallments from "./VtexPromotionConditions/VtexBasePromotionPaymentsAndInstallments.vue";
import VtexBasePromotionFirstBuyAndSubscription from "./VtexPromotionConditions/VtexBasePromotionFirstBuyAndSubscription.vue";
import VtexBasePromotionUtmAndUtmi from "./VtexPromotionConditions/VtexBasePromotionUtmAndUtmi.vue";

export default {
  name: "VtexBasePromotionConditions",
  components: {
    VtexBasePromotionOrdersAndItemsPrices,
    VtexBasePromotionPriceListAndBin,
    VtexBasePromotionTagsAndAffiliates,
    VtexBasePromotionClusterAndCountries,
    VtexBasePromotionZipCodeAndShippingType,
    VtexBasePromotionPaymentsAndInstallments,
    VtexBasePromotionFirstBuyAndSubscription,
    VtexBasePromotionUtmAndUtmi
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    vtexIntegrationConfigId: {
      type: String,
      required: true
    },
    shippingDiscount: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      promotion: this.value
    };
  },
  watch: {
    value(newValue) {
      this.promotion = newValue;
    },
    promotion: {
      handler(newValue) {
        this.$emit("input", newValue);
      },
      deep: true
    }
  }
};
</script>
