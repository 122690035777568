<template>
  <div>
    <p class="text-justify">
      El factor de precio es un número por el cual se multiplica el precio del
      producto con el fin de aumentarlo o disminutirlo. La fórmula es tan
      sencilla como esta:
    </p>
    <p>
      <code>Precio en {{ icName }}</code>
      =
      <code>Precio en Centry</code>
      &times;
      <code>Factor de Precio</code>
    </p>
    <p class="text-justify">
      De esta manera si, por ejemplo, se quiere aumentar un 20% el valor de cada
      producto, entonces el factor de precio tiene que ser igual a
      <code>1,2</code>.
    </p>
    <p class="text-justify">
      Si por el contrario, lo que se busca es reducir el precio en un 20%,
      entonces el factor de precio tiene que ser igual a
      <code>0,8</code>.
    </p>
  </div>
</template>

<script>
export default {
  name: "HelpPriceFactorModal",
  props: {
    icName: {
      type: String,
      default: ""
    }
  }
};
</script>

<style scoped>
code {
  font-size: 100%;
}
</style>
