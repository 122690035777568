<template>
  <div>
    <b-form-group label="Método http">
      <v-select
        v-model="methodName"
        :options="methodOptions"
        :reduce="x => x.value"
        :clearable="false"
      ></v-select>
    </b-form-group>
    <b-form-group label="Endpoint">
      <b-form-input v-model="endpoint"></b-form-input>
    </b-form-group>
    <b-form-group label="Parámetros en url (query). Escribir como objeto json">
      <base-json-input v-model="params"></base-json-input>
    </b-form-group>
    <b-form-group label="Ruta al arreglo de resultados">
      <b-form-input v-model="arrayPath"></b-form-input>
    </b-form-group>
    <b-form-group
      label="Ruta al identificador de un item dentro del listado de resultados"
    >
      <b-form-input v-model="itemIdPath"></b-form-input>
    </b-form-group>
    <b-form-group
      label="Ruta al nombre de un item dentro del listado de resultados"
    >
      <b-form-input v-model="itemNamePath"></b-form-input>
    </b-form-group>
  </div>
</template>
<script>
import BaseJsonInput from "@/components/BaseJsonInput";
export default {
  name: "OptionListFormRequest",
  components: { BaseJsonInput },
  model: {
    prop: "data",
    event: "change"
  },
  props: {
    data: Object
  },
  data() {
    return {
      methodName: this.$ifNull(this.data.methodName, "get"),
      endpoint: this.data.endpoint,
      params: this.data.params,
      arrayPath: this.data.arrayPath,
      itemIdPath: this.data.itemIdPath,
      itemNamePath: this.data.itemNamePath,
      methodOptions: [
        { label: "GET", value: "get" },
        { label: "POST", value: "post" }
      ]
    };
  },
  methods: {
    emitChange() {
      this.$emit(
        "change",
        Object.assign({}, this.data, {
          methodName: this.methodName,
          endpoint: this.endpoint,
          params: this.params,
          arrayPath: this.arrayPath,
          itemIdPath: this.itemIdPath,
          itemNamePath: this.itemNamePath
        })
      );
    }
  },
  watch: {
    methodName() {
      this.emitChange();
    },
    endpoint() {
      this.emitChange();
    },
    params() {
      this.emitChange();
    },
    arrayPath() {
      this.emitChange();
    },
    itemIdPath() {
      this.emitChange();
    },
    itemNamePath() {
      this.emitChange();
    }
  }
};
</script>
