<template>
  <div>
    <ApolloQuery
      :query="require('../../graphql/IntegrationConfigsForTabs.gql')"
      @result="checkResultIc"
    >
      <template slot-scope="{ result: { data } }">
        <h4 v-if="!loading">{{ prettyTitle }}</h4>
        <b-spinner label="Spinning" v-if="loading || !data"></b-spinner>
        <b-list-group
          v-for="ic of integrationConfigs"
          :key="ic.id"
          :title="ic.label"
          lazy=""
          v-else
        >
          <b-list-group-item
            :to="{
              name: urlName,
              params: {
                integrationConfigId: ic.id
              }
            }"
            class="flex-column align-items-start"
            v-if="isMercadoLibre(ic)"
          >
            <div style="display: flex; flex-direction: column;">
              <div
                class="card-image"
                style="display: flex; justify-content: center;"
              >
                <img
                  :src="require('../../assets/images/meli.png')"
                  style="width:100px"
                />
              </div>
              <div style="display: flex; justify-content: center;">
                <h5 class="mb-1">{{ ic.label }}</h5>
              </div>
            </div>
          </b-list-group-item>
        </b-list-group>
      </template>
    </ApolloQuery>
  </div>
</template>
<script>
export default {
  name: "MercadLibreSelectIntegration",
  props: {
    urlName: {
      type: String,
      default: ""
    },
    filterFunction: {
      type: Function,
      default: () => {
        return true;
      }
    }
  },
  data() {
    return {
      loading: true,
      integrationConfigs: []
    };
  },

  methods: {
    /**Filtra las integraciones si es que esta tiene informacion
     * @param {Object<Array>} result
     */
    checkResultIc(result) {
      if (result.data) {
        this.availableIntegrationConfigs(result.data);
      }
    },
    /**
     * Filtra el listado de integraciones, solo
     * entrega las integraciones de mercadolibre y available
     * @param {Array<Object>} data
     * @returns {Array<Object>}
     */
    async availableIntegrationConfigs(data) {
      if (!data || !data.allIntegrationConfigs) {
        return [];
      }
      let ics = await data.allIntegrationConfigs.filter(x => {
        return x.available && this.isMercadoLibre(x);
      });
      let fMapFunction = ics.map(async ic => {
        return this.filterFunction(ic.id);
      });
      await Promise.all(fMapFunction).then(value => {
        ics = ics.filter((_x, i) => value[i]);
      });
      this.integrationConfigs = this.$dup(ics);
      this.loading = false;
    },
    /**
     * Indica si la integración es o no hacia MercadoLibre
     * @param {Object} ic
     * @returns {Boolean}
     */
    isMercadoLibre(ic) {
      return ic.identifier.startsWith("mercado_libre");
    }
  },
  computed: {
    prettyTitle() {
      if (this.integrationConfigs.length > 0) {
        return "Seleccionar integración de Mercado Libre";
      } else {
        return "No hay integraciones disponibles. Revise su configuración y asegurese que la vinculación este correcta.";
      }
    }
  }
};
</script>
