<template>
  <div>
    <b-table-simple responsive>
      <b-thead>
        <tr>
          <th
            :style="{ minWidth: '150px' }"
            class="sticky-white"
            style="left: 0px"
          >
            Talla
          </th>
          <th
            :style="{ minWidth: '150px' }"
            class="sticky-white"
            style="left: 150px;"
          >
            Color
          </th>
          <th
            :style="{ minWidth: '150px' }"
            class="sticky-white"
            style="left: 300px;"
          >
            * SKU variante
          </th>
          <th
            class="sticky-white right-border"
            :style="{ minWidth: '150px' }"
            style="left: 450px; "
          >
            Código de barra
          </th>
          <th
            v-for="warehouse of warehouses"
            :key="warehouse.id"
            class="border-bottom"
          >
            <!--            <input-->
            <!--              v-if="isPack"-->
            <!--              type="checkbox"-->
            <!--              :id="warehouse.id"-->
            <!--              :value="warehouse"-->
            <!--              v-model="checkedWarehouses"-->
            <!--            />-->
            <br />
            Stock {{ warehouse.name }}
          </th>
          <th></th>
        </tr>
      </b-thead>

      <draggable
        v-model="array"
        tag="tbody"
        v-bind="dragOptions"
        @update="emitChange"
        handle=".handle"
      >
        <b-tr v-for="(_variant, position) of array" :key="position">
          <b-td class="sticky-white" style="left: 0px;">
            <b-form-group>
              <base-live-select
                :min-search-characters="1"
                placeholder="Talla"
                v-model="array[position].size"
                :appendToBody="true"
                @search="updateSize"
                @change="emitChange"
              ></base-live-select>
            </b-form-group>
          </b-td>
          <b-td class="sticky-white" style="left: 150px;">
            <b-form-group>
              <base-live-select
                :min-search-characters="1"
                placeholder="Color"
                v-model="array[position].color"
                :appendToBody="true"
                @search="updateColor"
                @change="emitChange"
              ></base-live-select>
            </b-form-group>
          </b-td>
          <b-td class="sticky-white" style="left: 300px;">
            <b-form-group
              :state="array[position].sku.length > 0"
              invalid-feedback="El SKU es obligatorio"
            >
              <b-input
                placeholder="SKU"
                v-model="array[position].sku"
                @input="emitChange"
              ></b-input>
            </b-form-group>
          </b-td>
          <b-td class="sticky-white right-border" style="left: 450px; ">
            <b-form-group>
              <b-input
                placeholder="Código de barra"
                v-model="array[position].barcode"
                @input="emitChange"
              ></b-input>
            </b-form-group>
          </b-td>
          <b-td v-for="warehouse of warehouses" :key="warehouse.id">
            <b-form-group v-if="isPack == false">
              <b-form-input
                v-model="
                  array[position].variantWarehouseMap[warehouse.id].quantity
                "
                @input="emitChange"
              ></b-form-input>
            </b-form-group>

            <b-form-group v-else>
              {{
                array[position].variantWarehouseMap[warehouse.id].maxStock !=
                null
                  ? parseInt(
                      array[position].variantWarehouseMap[warehouse.id].maxStock
                    )
                  : "0"
              }}
            </b-form-group>
          </b-td>

          <b-td>
            <b-icon-arrows-move
              class="handle"
              style="cursor: pointer"
              title="Arrastra para modificar el orden de tus variantes"
              v-b-tooltip.hover=""
              @change="emitChange"
            ></b-icon-arrows-move>
          </b-td>
        </b-tr>
      </draggable>
    </b-table-simple>
    <div v-if="isPack == false || this.array.length < 1">
      <b-button variant="outline-info" @click="addVariant">Agregar</b-button>
    </div>
  </div>
</template>
<script>
import BaseLiveSelect from "../../BaseLiveSelect";
import { mapState } from "vuex";
import ALL_COLORS from "../../../graphql/AllColors.gql";
import ALL_SIZES from "../../../graphql/AllSizes.gql";
import VARIANT_PACK_QUANTITY_SEARCH_FOR_VARIANT from "../../../graphql/VariantPackQuantitySearchForVariant.gql";
//import MAX_STOCK_BY_VW from "../graphql/MaxStockByVw.gql"

export default {
  name: "ProductEditTabsCentryVariants",
  components: {
    BaseLiveSelect
  },
  model: {
    prop: "_value",
    event: "change"
  },
  props: {
    _value: {
      type: Array,
      default() {
        return [];
      }
    },
    isPack: {
      type: Boolean
    },
    packVariants: {
      type: Array
    }
  },
  data() {
    return {
      array: this._value,
      maxStockTotal: [],
      checkedWarehouses: [],
      totalStock: 0
    };
  },
  created() {
    this.addAllWarehouses(this.array);
    this.initialVariantPackArray(this.array);
  },
  computed: {
    ...mapState(["currentUser"]),
    warehouses() {
      if (
        this.currentUser &&
        this.currentUser.company &&
        this.currentUser.company.warehouses
      ) {
        return this.currentUser.company.warehouses;
      }
      return [];
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },
  methods: {
    emitChange() {
      this.$emit("change", this.array);
      let valid = true;
      this.array.forEach(x => {
        if (x.sku === null || x.sku.length === 0) {
          valid = false;
        }
      });
      this.$emit("valid", valid);
    },
    addAllWarehouses(variants) {
      variants.forEach(v => {
        this.addWarehouses(v);
      });
    },
    addWarehouses(variant) {
      this.warehouses.forEach(w => {
        if (!variant.variantWarehouseMap[w.id]) {
          variant.variantWarehouseMap[w.id] = { quantity: 0 };
        }
      });
    },
    addVariant() {
      const newVariant = {
        size: null,
        color: null,
        sku: "",
        barcode: "",
        variantWarehouseMap: {}
      };
      this.addWarehouses(newVariant);
      this.array.push(newVariant);
    },
    itemForSelect(item) {
      if (item) {
        return { value: item.id, label: item.name };
      }
      return null;
    },
    async updateColor(search, loading, setOptions) {
      loading(true);
      this.$apollo
        .query({ query: ALL_COLORS, variables: { name: search } })
        .then(async result => {
          if (
            result.data &&
            result.data.allColors &&
            result.data.allColors.edges
          ) {
            setOptions(
              result.data.allColors.edges.map(x => {
                return this.itemForSelect(x.node);
              })
            );
          }
        });
      loading(false);
    },
    async updateSize(search, loading, setOptions) {
      loading(true);
      this.$apollo
        .query({ query: ALL_SIZES, variables: { name: search } })
        .then(async result => {
          if (
            result.data &&
            result.data.allSizes &&
            result.data.allSizes.edges
          ) {
            setOptions(
              result.data.allSizes.edges.map(x => {
                return this.itemForSelect(x.node);
              })
            );
          }
        });
      loading(false);
    },
    initialVariantPackArray(variants) {
      if (variants[0] == null || this.isPack === false) {
        return;
      }
      const variantId = variants[0].id;
      this.$apollo
        .query({
          query: VARIANT_PACK_QUANTITY_SEARCH_FOR_VARIANT,
          variables: { Id: variantId }
        })
        .then(async result => {
          if (result.data) {
            var array = [];
            for (const variant in result.data.variantPackQuantitySearch.edges) {
              var variantObject =
                result.data.variantPackQuantitySearch.edges[variant].node;
              var warehouses = [];
              for (const warehouseVariant in variantObject.variant
                .variantWarehouses) {
                warehouses.push({
                  id:
                    variantObject.variant.variantWarehouses[warehouseVariant]
                      .warehouse.id,
                  quantity:
                    variantObject.variant.variantWarehouses[warehouseVariant]
                      .quantity
                });
              }
              array.push({
                id: variantObject.variant.id,
                quantity: variantObject.quantity,
                variantWarehouseMap: warehouses
              });
            }
            this.maxStock(array);
          }
        });
    },
    maxStock(variants) {
      const max_stock = [];
      const max_stock_by_wh = [];
      const stock_by_wh = [];
      for (var variant in variants) {
        var variantObject = {
          variantId: variants[variant].id,
          quantity: variants[variant].quantity,
          warehouses: []
        };
        for (var warehouse in variants[variant].variantWarehouseMap) {
          var quantity =
            variants[variant].variantWarehouseMap[warehouse].quantity /
            variantObject.quantity;
          if (isNaN(quantity)) {
            quantity = 0;
          }
          variantObject.warehouses.push({
            warehouse: variants[variant].variantWarehouseMap[warehouse].id,
            maxStock: parseFloat(quantity.toFixed(2))
          });
          stock_by_wh.push({
            warehouse: variants[variant].variantWarehouseMap[warehouse].id,
            maxStock: parseFloat(quantity.toFixed(2))
          });
        }
        max_stock.push(variantObject);
      }

      for (var stock in stock_by_wh) {
        var maxStockWh = max_stock_by_wh.find(
          stocks => stocks.id == stock_by_wh[stock].warehouse
        );
        if (!maxStockWh) {
          max_stock_by_wh.push({
            id: stock_by_wh[stock].warehouse,
            quantity: stock_by_wh[stock].maxStock
          });
        } else {
          if (maxStockWh.quantity > stock_by_wh[stock].maxStock) {
            maxStockWh.quantity = stock_by_wh[stock].maxStock;
          }
        }
      }
      for (var variantWarehouse in this.array[0].variantWarehouseMap) {
        var variantWh = this.array[0].variantWarehouseMap[variantWarehouse];
        var maxWhStock = max_stock_by_wh.find(wh => wh.id == variantWarehouse);
        if (!maxWhStock) {
          variantWh.maxStock = 0;
        } else {
          variantWh.maxStock = maxWhStock.quantity;
        }
      }
      this.maxStockTotal = max_stock_by_wh;
      this.calculateTotalStock();
    },
    calculateTotalStock() {
      var maxStock = 0;
      if (this.checkedWarehouses.length === 0) {
        this.$forceUpdate();
      }
      for (var wh in this.checkedWarehouses) {
        var whStock = this.maxStockTotal.find(
          max => max.id == this.checkedWarehouses[wh].id
        );
        if (!whStock) {
          maxStock += 0;
        } else {
          maxStock += whStock.quantity;
        }
      }
      this.totalStock = Math.floor(maxStock);
    }
  },
  watch: {
    array() {
      this.emitChange();
    },
    checkedWarehouses() {
      this.calculateTotalStock();
    }
  }
};
</script>

<style scoped>
.sticky-white {
  position: sticky;
  background-color: white !important;
  z-index: 1;
}

td::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  border-top: 1px solid #e0e2e4 !important;
}

.right-border::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  border-right: 2px solid #e0e2e4 !important;
}

.table td {
  border-top: 0;
}
</style>
