<template>
  <div>
    <b-button
      @click="openModal"
      variant="outline-secondary"
      class="mr-1 mb-1"
      v-b-tooltip.hover
      title="Tracking"
    >
      <b-icon-truck />
    </b-button>
    <b-modal
      :ref="'tracking-' + order.id"
      :id="'tracking-' + order.id"
      ok-disabled=""
      size="lg"
      title="Seguimiento"
    >
      <b-container fluid>
        <b-row>
          <b-col
            ><b-row>
              <b-col>
                <b-form-group label="Código de Seguimiento">
                  <b-form-input v-model="code"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Estado" label-for="status">
                  <v-select
                    id="status"
                    :options="statusOptions"
                    placeholder="Selecciona un estado"
                    v-model="status"
                    :reduce="s => s.value"
                    :disabled="
                      this.$ifNull(trackingStatuses[0], {}).trackingStatus ==
                        'delivered'
                    "
                  ></v-select>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Comentario">
                  <b-form-input v-model="comment"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row style="margin-top: 10px; margin-right: 5px">
              <b-col class="col-md-10"
                >*Si el seguimiento llega al estado Entregado, no podrá seguir
                actualizandose.</b-col
              >
              <b-col class="col-md-2"
                ><b-button
                  variant="info"
                  @click="createTrackingStatus"
                  :disabled="
                    this.$ifNull(trackingStatuses[0], {}).trackingStatus ==
                      'delivered'
                  "
                >
                  Actualizar
                </b-button></b-col
              >
            </b-row>
          </b-col>
        </b-row>
        <hr />
        <b-row
          ><b-col
            ><b-row
              ><b-col class="col-md-4" style="text-align: center"
                ><span id="preparing-icon"
                  ><b-icon-archive font-scale="5" /><br /><label
                    id="preparing-label"
                    >En preparación</label
                  ></span
                ></b-col
              >
              <b-col class="col-md-4" style="text-align: center">
                <span id="shipped-icon"
                  ><b-icon-truck
                    :flip-h="
                      this.$ifNull(trackingStatuses[0], {}).trackingStatus ==
                        'not_delivered'
                    "
                    id="truck-icon"
                    font-scale="5"
                    @click="easterEgg"
                  /><br /><label id="shipped-label">Enviado</label></span
                ></b-col
              >
              <b-col class="col-md-4" style="text-align: center">
                <span id="delivered-icon">
                  <b-icon-calendar-check font-scale="5" /><br /><label
                    id="delivered-label"
                    >Entregado</label
                  ></span
                ></b-col
              ></b-row
            >
          </b-col></b-row
        >
        <hr />
        <b-table-simple>
          <b-tr>
            <b-th>Fecha</b-th>
            <b-th>Hora</b-th>
            <b-th>Estado</b-th>
            <b-th>Comentario</b-th>
          </b-tr>
          <b-tr v-for="ts in trackingStatuses" :key="ts.id">
            <b-td>{{ ts.createdAt | formatDate }}</b-td>
            <b-td>{{ ts.createdAt | formatTime }}</b-td>
            <b-td>{{ translateStatus(ts.trackingStatus) }}</b-td>
            <b-td>{{ ts.comment }}</b-td>
          </b-tr>
        </b-table-simple>
      </b-container>
      <template v-slot:modal-footer>
        <b-button @click="closeModal">
          Cerrar
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import ALL_ORDER_TRACKINGS from "../../graphql/AllOrderTrackings.gql";
import CREATE_ORDER_TRACKING from "../../graphql/CreateOrderTracking.gql";
import CREATE_ORDER_TRACKING_STATUS from "../../graphql/CreateOrderTrackingStatus.gql";
export default {
  name: "OrderTrackingModal",
  props: {
    order: Object
  },
  data() {
    return {
      code: "",
      status: "",
      statusOptions: [
        { label: "Enviado", value: "shipped" },
        { label: "No entregado", value: "not_delivered" },
        { label: "Entregado", value: "delivered" }
      ],
      comment: "",
      trackingStatuses: [],
      trackingId: "",
      clicks: 0
    };
  },
  methods: {
    openModal() {
      this.$refs["tracking-" + this.order.id].show();
      this.getTracking();
    },
    closeModal() {
      this.$refs["tracking-" + this.order.id].hide();
    },
    easterEgg() {
      this.clicks++;
      if (this.clicks == 5) {
        let time = 0;
        let truck = document.getElementById("truck-icon");
        let intervalId = setInterval(function() {
          if (time > 5000) {
            clearInterval(intervalId);
          }
          truck.style.marginLeft = time + "px";
          time += 2;
        }, 20);
      }
    },
    async getTracking() {
      this.$apollo
        .query({
          query: ALL_ORDER_TRACKINGS,
          variables: {
            orderId: this.order.id
          },
          fetchPolicy: "no-cache"
        })
        .then(result => {
          let tracking = result.data.allOrderTrackings.edges;
          if (tracking.length > 0) {
            this.trackingId = tracking[0].node.id;
            this.code = tracking[0].node.code;
            this.status = tracking[0].node.trackingStatus;
            this.trackingStatuses = tracking[0].node.trackingStatuses;
          }
          this.updateStatus();
        });
    },
    async createTracking() {
      let create = {
        orderId: this.order.id,
        code: this.code
      };
      await this.$apollo
        .mutate({
          mutation: CREATE_ORDER_TRACKING,
          variables: {
            create: create
          }
        })
        .then(result => {
          let tracking = result.data.createOrderTracking.tracking;
          this.trackingId = tracking.id;
        });
    },
    async createTrackingStatus() {
      if (!this.trackingId) {
        await this.createTracking();
      }
      let create = {
        trackingId: this.trackingId,
        comment: this.comment,
        trackingStatus: this.status,
        subStatus:
          this.trackingStatus == "not_delivered" ? "returning_to_sender" : ""
      };
      this.$apollo
        .mutate({
          mutation: CREATE_ORDER_TRACKING_STATUS,
          variables: {
            create: create
          }
        })
        .then(result => {
          let valid = result.data.createOrderTrackingStatus.result;
          if (valid) {
            this.getTracking();
            this.$swal.fire({
              title: "Seguimiento actualizado",
              text: "Se ha actualizado el seguimiento de forma exitosa",
              icon: "success",
              confirmButtonText: "Aceptar"
            });
          } else {
            this.$swal.fire({
              title: "Actualización fallida",
              text: "No se ha podido actualizar el seguimiento del pedido",
              icon: "error",
              confirmButtonText: "Aceptar"
            });
          }
        });
    },
    updateStatus() {
      switch (this.status) {
        case "pending":
          document.getElementById("preparing-icon").style.color = "green";
          document.getElementById("shipped-label").innerHTML = "Enviado";
          break;
        case "shipped":
          document.getElementById("preparing-icon").style.color = "green";
          document.getElementById("shipped-icon").style.color = "green";
          document.getElementById("shipped-label").innerHTML = "Enviado";
          break;
        case "not_delivered":
          document.getElementById("preparing-icon").style.color = "green";
          document.getElementById("shipped-icon").style.color = "red";
          document
            .getElementById("truck-icon")
            .classList.add("fa-flip-horizontal");
          document.getElementById("shipped-label").innerHTML = "Devuelto";
          break;
        case "delivered":
          document.getElementById("preparing-icon").style.color = "green";
          document.getElementById("shipped-icon").style.color = "green";
          document.getElementById("delivered-icon").style.color = "green";
          document.getElementById("shipped-label").innerHTML = "Enviado";
          break;
      }
    },
    translateStatus(status) {
      switch (status) {
        case "shipped":
          return "Enviado";
        case "not_delivered":
          return "No entregado";
        case "delivered":
          return "Entregado";
      }
    }
  }
};
</script>
<style scoped>
.table {
  margin-bottom: 0 !important;
}
.table td {
  padding: 8px 8px !important;
  height: 10px !important;
}
</style>
