<template>
  <div>
    <b-row>
      <b-col lg="4" sm="6" cols="12">
        <b-form-group
          label="Tipo de Documento para pedidos que no requieren factura"
          label-for="typeDocument"
          class="mb-3"
          :description="errorLoadingDocumentTypes"
        >
          <b-spinner v-if="loading" />
          <v-select
            v-else
            id="typeDocument"
            v-model="value.typeDocument"
            :reduce="x => x.value"
            :options="typeDocumentOptions"
            :clearable="false"
            append-to-body=""
            @input="emitChange"
            placeholder="Selecciona una opción"
            :disabled="!allowSave"
          />
        </b-form-group>
        <b-form-group
          label="Tipo de Documento para pedidos que solicitan factura"
          label-for="typeDocumentInvoice"
          class="mb-3"
          :description="typeDocumentInvoiceDescription"
        >
          <b-spinner v-if="loading" />
          <v-select
            v-else
            id="typeDocumentInvoice"
            v-model="value.typeDocumentInvoice"
            :reduce="x => x.value"
            :options="typeDocumentOptions"
            append-to-body=""
            @input="emitChange"
            placeholder="Selecciona una opción"
            :disabled="!allowSave"
          />
        </b-form-group>
        <b-form-group
          label="Cómo completar la información del cliente"
          label-for="clientCriteria"
          class="mb-3"
        >
          <v-select
            id="clientCriteria"
            v-model="value.clientCriteria"
            :reduce="x => x.value"
            :options="clientCriteriaOptions"
            :clearable="false"
            append-to-body=""
            @input="emitChange"
            placeholder="Selecciona una opción"
            :disabled="!allowSave"
          />
        </b-form-group>
        <b-form-group
          label="Cómo completar la información del cliente para las facturas"
          label-for="clientInvoiceCriteria"
          class="mb-3"
        >
          <v-select
            id="clientInvoiceCriteria"
            v-model="value.clientInvoiceCriteria"
            :reduce="x => x.value"
            :options="clientCriteriaOptions"
            :clearable="false"
            append-to-body=""
            @input="emitChange"
            placeholder="Selecciona una opción"
            :disabled="!allowSave"
          />
        </b-form-group>
        <b-form-group
          label="Crear en BSale documentos de ventas sólo cuando el pedido alcance el estado"
          label-for="createDocumentsWhenOrderStateGte"
          class="mb-3"
        >
          <v-select
            id="createDocumentsWhenOrderStateGte"
            v-model="value.createDocumentsWhenOrderStateGte"
            :reduce="x => x.value"
            :options="createDocumentsWhenOrderStateGteOptions"
            :clearable="false"
            append-to-body=""
            @input="emitChange"
            placeholder="Selecciona una opción"
            :disabled="!allowSave"
          />
        </b-form-group>
      </b-col>
      <b-col lg="3" sm="6" cols="12">
        <b-form-checkbox
          v-model="value.autoDispatch"
          @input="emitChange"
          class="mb-3"
          :disabled="!allowSave"
        >
          Marcar inmediatamente los artículos como despachados en la creación de
          un documento de venta.
        </b-form-checkbox>
        <b-form-checkbox
          v-model="value.clientInDocument"
          @input="emitChange"
          class="mb-3"
          :disabled="!allowSave || disableClientInDocument"
        >
          Incluir información del cliente en documento de venta
        </b-form-checkbox>
        <b-form-checkbox
          v-model="value.autoInformToSii"
          @input="emitChange"
          class="mb-3"
          :disabled="!allowSave"
        >
          Informar inmediatamente al SII en la creación del documento de venta
          en BSale.
        </b-form-checkbox>
        <b-form-checkbox
          v-model="value.createDocumentsWhenAmountsMatch"
          @input="emitChange"
          class="mb-3"
          v-if="superAdmin"
        >
          Crear documentos en BSale sólo cuando el monto total coincida con la
          suma de todos los items del pedido. (Alfa)
        </b-form-checkbox>
        <b-form-checkbox
          v-model="value.referencesInDocument"
          @input="emitChange"
          class="mb-3"
          :disabled="!allowSave"
        >
          Asociar orden de compra con datos del pedido de la plataforma de
          origen al documento de venta
        </b-form-checkbox>
      </b-col>
      <b-col lg="5" sm="6" cols="12">
        <b-form-checkbox
          v-model="value.discountVatOnDocuments"
          @input="emitChange"
          class="mb-3"
          :disabled="!allowSave"
        >
          Considerar impuestos en los precios de glosas del documento de venta
          en BSale
        </b-form-checkbox>
        <b-form-group
          label="Impuestos que se aplican en los items de un documento de venta"
          :description="errorLoadingTaxes"
          v-if="value.discountVatOnDocuments"
        >
          <b-spinner label="Spinning" v-if="loading"></b-spinner>
          <vMultiselectListbox
            v-else-if="allowSave"
            style="min-width: 100%"
            v-model="value.taxesInDocument"
            :options="taxesInDocumentOptions"
            :reduce-display-property="option => option.label"
            :reduce-value-property="option => option.value"
            search-options-placeholder="Buscar impuestos a usar"
            selected-options-placeholder="Buscar impuestos seleccionados"
            no-options-text="No hay impuestos para seleccionar"
            selected-no-options-text="No hay impuestos seleccionados"
            no-options-found-text="No se encontró el impuesto"
            no-selected-options-found-text="No se encontró el impuesto seleccionado"
            @input="emitChange"
          >
          </vMultiselectListbox>
          <b-list-group v-else>
            <b-list-group-item
              v-if="
                !completeSelectedTaxesInDocument ||
                  !completeSelectedTaxesInDocument.length
              "
            >
              No hay impuestos seleccionados
            </b-list-group-item>
            <b-list-group-item
              v-for="taxId of completeSelectedTaxesInDocument"
              :key="taxId.value"
            >
              {{ taxId.label }}
            </b-list-group-item>
          </b-list-group>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import vMultiselectListbox from "vue-multiselect-listbox";
import { mapState } from "vuex";
export default {
  name: "IntegrationConfigsBSaleSalesDocumentData",
  components: {
    vMultiselectListbox
  },
  props: {
    _value: Object,
    originalData: Object,
    allowSave: Boolean,
    loading: {
      type: Boolean,
      default: true
    },
    taxesInDocumentOptions: {
      type: Array,
      default() {
        return [];
      }
    },
    typeDocumentOptions: {
      type: Array,
      default() {
        return [];
      }
    },
    errorLoadingTaxes: {
      type: String,
      default: ""
    },
    errorLoadingDocumentTypes: {
      type: String,
      default: ""
    }
  },
  model: {
    event: "change",
    prop: "_value"
  },
  data() {
    return {
      value: this.$dup(this._value),
      clientCriteriaOptions: [
        { value: "buyer", label: "Según la información general del cliente" },
        { value: "address_shipping", label: "Según la dirección de despacho" },
        { value: "address_billing", label: "Según la dirección de facturación" }
      ],
      createDocumentsWhenOrderStateGteOptions: [
        { value: "pending", label: "Pendiente" },
        { value: "paid", label: "Pagado" },
        { value: "shipped", label: "Despachado" },
        { value: "received", label: "Recibido" }
      ]
    };
  },
  computed: {
    ...mapState(["currentUser"]),
    completeSelectedTaxesInDocument() {
      if (!this.taxesInDocumentOptions || !this.value.taxesInDocument) {
        return [];
      }
      return this.taxesInDocumentOptions.filter(elem =>
        this.value.taxesInDocument.includes(elem.value)
      );
    },
    superAdmin() {
      return this.currentUser.role === 0;
    },
    completeTypeDocumentSelected() {
      if (!this.typeDocumentOptions) {
        return null;
      }
      return this.typeDocumentOptions.find(
        elem => elem.value === this.value.typeDocument
      );
    },
    disableClientInDocument() {
      return (
        this.$ifNull(
          this.$dig(this.completeTypeDocumentSelected, "all", "useClient"),
          0
        ) === 1
      );
    },
    typeDocumentInvoiceDescription() {
      if (
        this.errorLoadingDocumentTypes &&
        this.errorLoadingDocumentTypes.length
      ) {
        return this.errorLoadingDocumentTypes;
      }
      if (this.value.typeDocumentInvoice === null) {
        return "Si no completa este campo, Centry no podrá emitir facturas de los pedidos que lo requieran";
      }
      return "";
    }
  },
  methods: {
    /**
     * Emite el valor para actualizar el modelo
     */
    emitChange() {
      this.$emit("change", this.value);
    },
    /**
     * Setea el valor en value
     * si es que es un objeto distinto
     * @param {Object} val
     */
    setValue(val) {
      if (val !== this.value) this.value = val;
    }
  },
  watch: {
    value() {
      this.emitChange();
    },
    _value(val) {
      this.setValue(val);
    }
  }
};
</script>
