var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-table-simple',[_c('b-thead',[_c('tr',[_c('th',{attrs:{"colspan":3}},[_vm._v("Datos en Centry")]),_c('th',{attrs:{"colspan":3}},[_vm._v("Datos en MercadoLibre")])]),_c('tr',[_c('th',[_vm._v("Color")]),_c('th',[_vm._v("Talla")]),_c('th',[_vm._v("* SKU Centry")]),_vm._l((_vm.category_attributes),function(attribute,position){return _c('b-th',{key:position},[_vm._v(" "+_vm._s((attribute.required ? "* " : "") + attribute.label)+" ")])})],2)]),_c('b-tbody',_vm._l((_vm.array),function(_variant,position){return _c('b-tr',{key:position},[(_vm.array[position].color)?_c('b-td',[_vm._v(_vm._s(_vm.array[position].color.name))]):_c('b-td',[_vm._v("Sin Color")]),(_vm.array[position].size)?_c('b-td',[_vm._v(_vm._s(_vm.array[position].size.name))]):_c('b-td',[_vm._v("Sin Talla")]),_c('b-td',[_vm._v(_vm._s(_vm.array[position].sku))]),_vm._l((_vm.category_attributes),function(attribute){return _c('b-td',{key:attribute.value},[_c('div',[(
                (attribute.allow_variations && _vm.loadingSoldInformation) ||
                  (attribute.value == 'SIZE_GRID_ROW_ID' && _vm.sizeChartLoading)
              )?_c('b-spinner',{attrs:{"label":"Spinning"}}):_c('product-edit-tabs-mercado-libre-attributes-input',{attrs:{"attribute-info":attribute,"disabled":_vm.disabledAttribute(attribute, position),"show-not-apply-button":!attribute.allow_variations,"input-and-button-on-same-line":false},on:{"input":_vm.emitChange},model:{value:(
                _vm.array[position].integrations[_vm.integrationConfig.fullLabel][
                  attribute.value
                ]
              ),callback:function ($$v) {_vm.$set(_vm.array[position].integrations[_vm.integrationConfig.fullLabel], 
                  attribute.value
                , $$v)},expression:"\n                array[position].integrations[integrationConfig.fullLabel][\n                  attribute.value\n                ]\n              "}})],1)])})],2)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }