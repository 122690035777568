<template>
  <div>
    <b-skeleton-img v-if="loading" height="100px"></b-skeleton-img>
    <error-dashboard
      v-else-if="!!error || !!timeoutError"
      :error="!!error"
      :timeout-error="!!timeoutError"
      @retry="loadAll"
    ></error-dashboard>
    <summary-message-sales-report
      v-else
      :title="widget.name"
      :currency="''"
      :amount="amount + ''"
      :percentage="comparePercentage + ''"
    />
  </div>
</template>

<script>
import SummaryMessageSalesReport from "../../components/SummaryMessageSalesReport.vue";
import DASHBOARD_NUMBER_WIDGET from "../../graphql/DashboardNumberWidget.gql";
import BaseWidget from "@/components/dashboard/BaseWidget";
import ErrorDashboard from "@/components/dashboard/ErrorDashboard";

export default {
  name: "DashboardNumber",
  extends: BaseWidget,
  components: {
    ErrorDashboard,
    SummaryMessageSalesReport
  },
  computed: {
    amount() {
      if (!this.infoWidget || !this.data) return "";
      let data = this.data;
      let r = eval(this.infoWidget.aggregationFormula);
      return parseInt(r(data), 10) + "";
    },
    compareAmount() {
      if (!this.infoWidget || !this.compareData) return 0;
      let data = this.compareData;
      let r = eval(this.infoWidget.aggregationFormula);
      return parseInt(r(data), 10) + "";
    },
    comparePercentage() {
      if (this.amount === 0 || this.compareAmount === 0) {
        return null;
      }
      return Math.trunc(
        (parseInt(this.amount) / parseInt(this.compareAmount)) * 100 - 100
      );
    }
  },
  methods: {
    getWidget() {
      return this.$apollo
        .query({
          query: DASHBOARD_NUMBER_WIDGET,
          variables: {
            id: this.widget.id
          }
        })
        .then(result => {
          this.infoWidget = result.data.dashboardNumberWidget;
        });
    }
  }
};
</script>
