<template>
  <div>
    <span class="text-dark mr-2" v-if="selectedHomologationsCount > 0">
      {{ selectedHomologationsCount }} seleccionado(s)
    </span>
    <massive-actions-button
      text="Eliminar"
      variants="danger"
      :disabled="selectedHomologationsCount == 0"
      dialog-title="Eliminar homologaciones"
      dialog-text="¿Está seguro de eliminar estas homologaciones?"
      :action="deleteSelected"
    ></massive-actions-button>
    <massive-actions-button
      text="Reactivar"
      :disabled="selectedHomologationsCount == 0"
      dialog-title="Reactivar homologaciones"
      dialog-text="¿Está seguro de reactivar estas homologaciones?"
      :action="reactivateSelected"
    ></massive-actions-button>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { centryUrl } from "@/main";
import MassiveActionsButton from "@/components/QuarantinedAttributeHomologations/MassiveActionsButton.vue";
import DELETE_PURPOSE_CATEGORY_ATTRIBUTES from "@/graphql/QuarantinedAttributeHomologations/DeletePurposeCategoryAttributes.gql";
import RESET_PURPOSE_CATEGORY_ATTRIBUTES from "@/graphql/QuarantinedAttributeHomologations/ResetPurposeCategoryAttributes.gql";

export default {
  components: { MassiveActionsButton },
  name: "MasiveActions",
  data() {
    return {
      centryUrl
    };
  },
  computed: {
    ...mapState(["selectedHomologations"]),
    selectedHomologationsCount() {
      return Object.keys(this.selectedHomologations).reduce(
        (accumulator, currentValue) =>
          accumulator + (this.selectedHomologations[currentValue] ? 1 : 0),
        0
      );
    }
  },
  methods: {
    /**
     * Elimina las homologaciones con ids
     * entregados como parámetros, ejecutando
     * una mutación GraphQl
     * @param {Array} ids
     */
    deleteSelected(ids) {
      return this.$apollo
        .mutate({
          mutation: DELETE_PURPOSE_CATEGORY_ATTRIBUTES,
          variables: { ids: ids }
        })
        .then(() => {
          this.$router.go(0);
        });
    },
    /**
     * Reactiva la homologaciones con los
     * ids entregados como parámetros, ejecutando
     * una mutación GraphQl
     * @param {String} ids
     */
    reactivateSelected(ids) {
      return this.$apollo
        .mutate({
          mutation: RESET_PURPOSE_CATEGORY_ATTRIBUTES,
          variables: { ids: ids }
        })
        .then(() => {
          this.$router.go(0);
        });
    }
  }
};
</script>
