<template>
  <b-row class="justify-content-md-center">
    <b-col cols="12" md="8" lg="6" xl="5">
      <div id="login-form-div">
        <ApolloMutation
          :mutation="require('../graphql/SignIn.gql')"
          :variables="{
            input
          }"
          @done="performLogin"
        >
          <template slot-scope="{ mutate, loading, error }">
            <p class="text-center text-white">
              Por motivos de seguridad, necesitamos que ingreses nuevamente
            </p>
            <b-form id="login-form" @submit.prevent="validate() && mutate()">
              <b-spinner
                v-if="loading || loadingSession"
                label="Spinning"
              ></b-spinner>
              <b-alert
                v-else-if="validationError || error"
                show=""
                variant="danger"
              >
                {{ error ? "Ha ocurrido un error" : validationError }}
              </b-alert>
              <h2>Inicio de Sesión</h2>
              <b-form-group>
                <b-form-input
                  placeholder="Email"
                  v-model="input.email"
                ></b-form-input>
                <b-form-input
                  type="password"
                  placeholder="Contraseña"
                  v-model="input.password"
                ></b-form-input>
                <vue-recaptcha
                  :sitekey="recaptchaSiteKey"
                  :load-recaptcha-script="true"
                  @verify="verifyRecaptcha"
                ></vue-recaptcha>
                <b-button type="submit">Ingresar</b-button>
              </b-form-group>
            </b-form>
          </template>
        </ApolloMutation>
        <div id="ret-pass-div" class="text-center font-weight-bold">
          <p>
            ¿No tienes cuenta?
            <a :href="centryUrl + '/registro'">
              Regístrate ahora mismo.
            </a>
          </p>
          <p>
            <a :href="centryUrl + '/recuperar_clave/new'">
              ¿Olvidaste tu contraseña?
            </a>
          </p>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { onLogin } from "@/vue-apollo";
import { mapMutations } from "vuex";
import CURRENT_USER from "../graphql/CurrentUser.gql";
import { centryUrl, recaptchaSiteKey } from "@/main";

export default {
  name: "LoginForm",
  data() {
    return {
      input: {
        email: "",
        password: "",
        recaptchaResponse: null
      },
      validationError: null,
      loadingSession: false,
      centryUrl,
      recaptchaSiteKey
    };
  },
  methods: {
    ...mapMutations(["setCurrentUser"]),
    verifyRecaptcha(response) {
      this.input.recaptchaResponse = response;
    },
    validate() {
      if (this.input.email.length === 0) {
        this.validationError = "Debe ingresar un correo";
        return false;
      }
      if (this.input.password.length === 0) {
        this.validationError = "Debe ingresar una contraseña";
        return false;
      }
      if (!this.input.recaptchaResponse) {
        this.validationError = "Debe validar que no es un robot";
        return false;
      }
      return true;
    },
    async performLogin(result) {
      if (result && result.data && result.data.signinUser) {
        const token = result.data.signinUser.token;
        if (token) {
          this.validationError = null;
          this.loadingSession = true;
          await onLogin(this.$apollo.getClient(), token);
          const result = await this.$apollo.query({ query: CURRENT_USER });
          const data = result.data;
          const currentUser = data.currentUser;
          await this.setCurrentUser(currentUser);
          localStorage["current-user"] = JSON.stringify(currentUser);
          localStorage.companyId = currentUser.company.id;
          if (currentUser) {
            await this.$router.push("/products");
          }
        }
      } else {
        this.validationError = "El usuario o clave son incorrectos";
      }
    }
  }
};
</script>

<style scoped>
body a {
  color: #f1c050;
}

#login-form-div {
  margin: 100px 0;
}

#login-form {
  background-color: rgba(255, 255, 255, 0.6);
  text-align: center;
  padding: 10px;
}

input {
  margin-top: 10px;
  margin-bottom: 10px;
}

#ret-pass-div {
  margin-top: 10px;
  color: #ffffff;
}
</style>
