<template>
  <mercado-libre-suggestion-form-validation-component
    variant-alert="warning"
    errors-title="Para que tu sugerencia sea válida, debes corregir los siguientes datos:"
    :do-query="doQuery"
    :load-errors="loadErrors"
  />
</template>

<script>
import VALIDATION from "../../../../graphql/MercadoLibre/CatalogSuggestions/Validation.gql";
import MercadoLibreSuggestionFormValidationComponent from "./ValidationComponent.vue";
export default {
  name: "MercadoLibreCatalogSuggestionFormResultantValidation",
  components: {
    MercadoLibreSuggestionFormValidationComponent
  },
  props: {
    integrationConfigId: {
      type: String,
      required: true
    },
    suggestionId: {
      type: String,
      required: true
    }
  },
  methods: {
    /**
     * Ejecuta la query para validar el body de
     * creación / edición de sugerencias
     * @returns {Promise}
     */
    doQuery() {
      return this.$apollo.query({
        query: VALIDATION,
        variables: {
          integrationConfigId: this.integrationConfigId,
          suggestionId: this.suggestionId
        },
        fetchPolicy: "no-cache"
      });
    },
    /**
     * Carga la información de error en el arreglo errors
     * @param {Object} data
     * @return {Array<String>}
     */
    loadErrors(data) {
      const errors = this.$ifNull(
        this.$dig(data, "validationSpecificMercadoLibreSuggestion"),
        []
      );
      return errors.map(error => error.message);
    }
  }
};
</script>
