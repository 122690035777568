<template>
  <b-form-group
    label="¿Qué hacer con los productos que se hubieran publicado y que ahora estarían excluidos?"
    v-slot="{ ariaDescribedby }"
  >
    <b-form-radio-group
      v-model="value"
      @input="emitChange"
      :aria-describedby="ariaDescribedby"
      :state="!invalidState"
      :disabled="!invalidState && !value"
    >
      <b-form-radio value="leave">Dejar como están</b-form-radio>
      <b-form-radio value="unpublish">Despublicar</b-form-radio>
      <b-form-invalid-feedback :state="!invalidState"
        >Debes seleccionar una opción</b-form-invalid-feedback
      >
    </b-form-radio-group>
  </b-form-group>
</template>

<script>
export default {
  name: "ProductsNowExcludedForm",
  model: {
    prop: "_value",
    event: "change"
  },
  props: {
    invalidState: {
      type: Boolean,
      default: false
    },
    _value: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      value: this._value
    };
  },
  methods: {
    /**
     * Emite el evento de cambio
     * @param {String} value
     */
    emitChange(value) {
      this.$emit("change", value);
    }
  },
  watch: {
    _value(newValue) {
      this.value = newValue;
    }
  }
};
</script>

<style scoped>
code {
  font-size: 100%;
}
</style>
