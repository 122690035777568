<template>
  <ApolloQuery
    :query="require('../graphql/LastSynchronizationStatuses.gql')"
    :variables="queryVariables('')"
    :context="{ timeout: 60000 }"
    :fetchPolicy="'cache-and-network'"
    clientId="apolloClientCached"
  >
    <template slot-scope="{ result: { error, data }, query, isLoading }">
      <b-overlay :show="isLoading != 0 && !data" rounded="sm">
        <b-row>
          <b-col>
            <b-form-group label="Integraciones:" v-slot="{ ariaDescribedby }">
              <integration-config-checkboxes
                :aria-describedby="ariaDescribedby"
                :integrationConfigs.sync="integrationsSelected"
                :typeExcluded="['Integrations::IntegrationConfigApplication']"
                @populated="
                  hasIntegrationConfigCheckboxesPopulatedOptions($event)
                "
              ></integration-config-checkboxes>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Estado:" v-slot="{ ariaDescribedby }">
              <b-form-checkbox-group
                v-model="statusesSelected"
                :aria-describedby="ariaDescribedby"
              >
                <b-form-checkbox value="ok">
                  <b-icon icon="check" variant="success"></b-icon>
                  Correctos
                </b-form-checkbox>
                <b-form-checkbox value="failed">
                  <b-icon
                    icon="exclamation-triangle-fill"
                    variant="danger"
                  ></b-icon>
                  Fallidos
                </b-form-checkbox>
                <b-form-checkbox value="excluded">
                  <b-icon icon="dash-circle-fill" variant="secondary"></b-icon>
                  Excluidos
                </b-form-checkbox>
                <b-form-checkbox value="not_synched">
                  <b-icon icon="three-dots" variant="secondary"></b-icon>
                  Sin sincronizar
                </b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="Filtro de productos:"
              v-slot="{ ariaDescribedby }"
            >
              <products-filter
                :aria-describedby="ariaDescribedby"
                v-bind.sync="productFilters"
                :showActiveCheckbox="false"
                :showInactiveCheckbox="false"
                :showNoStockCheckbox="false"
              ></products-filter>
            </b-form-group>
          </b-col>
        </b-row>
      </b-overlay>
      <!-- Loading -->
      <b-skeleton-table
        v-if="isLoading && !data"
        :rows="25"
        :columns="4"
        :table-props="{ hover: true }"
      ></b-skeleton-table>
      <!-- Error -->
      <b-alert v-else-if="error && retry == 5" show="" variant="danger">
        Ha ocurrido un error
      </b-alert>
      <b-row align-h="end">
        <span v-if="data && isLoading" class="m-2 float-right">
          Actualizando lista de estados por integración...
        </span>
      </b-row>
      <!-- Result -->
      <b-table-simple
        v-if="data && data.allProductLastSynchronizationStatus"
        hover
        sticky-header="65vh"
        no-border-collapse
      >
        <b-thead>
          <b-tr>
            <b-th>Producto</b-th>
            <b-th
              v-for="integration of integrationsSelected"
              :key="integration.id"
              >{{ integration.label }}</b-th
            >
          </b-tr>
        </b-thead>
        <b-tbody>
          <last-synchronization-statuses-list-item
            v-for="product of data.allProductLastSynchronizationStatus.edges"
            :key="product.node.id"
            :product="product.node"
            :integrationsSelected="integrationsSelected"
          ></last-synchronization-statuses-list-item>
        </b-tbody>
      </b-table-simple>
      <b-spinner
        v-if="isLoading"
        label="Spinning"
        class="m-2 float-right"
      ></b-spinner>
      <div v-else>
        <b-button
          v-if="hasNextPage(data)"
          class="m-2 float-right"
          @click="
            seeMore(
              query,
              data.allProductLastSynchronizationStatus.pageInfo.endCursor
            )
          "
        >
          Ver más
        </b-button>
        <b-alert v-else show variant="light" class="text-center">
          No hay más datos para mostrar.
        </b-alert>
      </div>
    </template>
  </ApolloQuery>
</template>
<script>
import IntegrationConfigCheckboxes from "../components/IntegrationConfigCheckboxes.vue";
import LastSynchronizationStatusesListItem from "../components/LastSynchronizationStatusesListItem.vue";
import ProductsFilter from "../components/ProductsFilter.vue";
export default {
  name: "LastSynchronizationStatusesList",
  components: {
    IntegrationConfigCheckboxes,
    LastSynchronizationStatusesListItem,
    ProductsFilter
  },
  data() {
    return {
      firstIntegrationsSelectionsCompleted: false,
      integrationsSelected: [],
      statusesSelected: ["ok", "failed", "excluded", "not_synched"],
      productFilters: {
        categoriesSelected: [],
        brandsSelected: [],
        phrases: [],
        active: true,
        inactive: true,
        noStock: false
      }
    };
  },
  watch: {
    integrationsSelected: function() {
      this.notify();
    },
    statusesSelected: function() {
      this.notify();
    },
    productFilters: function() {
      this.notify();
    }
  },
  computed: {
    /**
     * Indica si el grupo de checkboxes para filtrar por resultado de
     * sincronización tiene al menos una opción seleccionada.
     *
     * @return {Boolean}
     */
    isStatusCheckboxValid() {
      return this.statusesSelected.length > 0;
    }
  },
  methods: {
    /**
     * Recibe las opciones que el componente
     * `LastSynchronizationStatusesListItem` informa como disponibles y las
     * usa para seleccionarlas todas.. Esto se hace sólo una vez.
     * @param {Array} checkboxesAvailable listado de opciones informadas por el
     *                componente `LastSynchronizationStatusesListItem`.
     */
    hasIntegrationConfigCheckboxesPopulatedOptions(checkboxesAvailable) {
      if (!this.firstIntegrationsSelectionsCompleted) {
        this.integrationsSelected = checkboxesAvailable;
        this.firstIntegrationsSelectionsCompleted = true;
      }
    },
    /**
     * Se encarga de crear un objeto con
     * las variables para la query
     * @method
     * @param {String} cursor
     * @param {Object} variables
     */
    queryVariables(cursor, variables = {}) {
      if (cursor) {
        variables.cursor = cursor;
      }
      if (this.productFilters.categoriesSelected) {
        variables.categoryIds = this.productFilters.categoriesSelected.map(
          c => c.value
        );
      }
      if (this.productFilters.brandsSelected) {
        variables.brandIds = this.productFilters.brandsSelected.map(
          b => b.value
        );
      }
      if (this.productFilters.phrases) {
        variables.phrases = this.productFilters.phrases;
      }
      variables.integrationStatuses = {
        integrationConfigIds: this.integrationsSelected.map(i => i.id),
        statuses: this.statusesSelected
      };
      return variables;
    },
    /**
     * Indica si hay una página siguiente en la query de GraphQl basado
     * exclusivamente en la existencia de un cursor.
     * @return {boolean}
     */
    hasNextPage(data) {
      return (
        data?.allProductLastSynchronizationStatus?.pageInfo?.endCursor != null
      );
    },
    /**
     * Trae más resultados de una query avanzando en su cursor.
     * @param {Object} query
     * @param {String} cursor
     */
    async seeMore(query, cursor) {
      await query.fetchMore({
        variables: this.queryVariables(cursor),
        updateQuery: (prev, { fetchMoreResult }) => {
          const updated = Object.assign({}, this.$dup(prev));
          updated.allProductLastSynchronizationStatus.pageInfo =
            fetchMoreResult.allProductLastSynchronizationStatus.pageInfo;
          if (
            fetchMoreResult?.allProductLastSynchronizationStatus?.edges
              ?.length !== 0
          ) {
            updated.allProductLastSynchronizationStatus.edges.push(
              ...fetchMoreResult.allProductLastSynchronizationStatus.edges
            );
          }
          return updated;
        }
      });
    },
    /**
     * Notifica a quién esté suscrito al evento `"change"` los cambios que hayan
     * habido en los filtros de integraciones, productos o resultados de
     * sincronización.
     */
    notify() {
      const filters = {
        integrationsSelected: this.integrationsSelected,
        productFilters: this.productFilters,
        statusesSelected: this.statusesSelected
      };
      this.$emit("change", filters);
    }
  }
};
</script>
