<template>
  <div>
    <h4 class="font-weight-bold my-auto">Activar secciones betas</h4>
    <hr />
    <b-row>
      <b-col
        v-for="(checkbox, i) of checkboxes"
        :key="i"
        lg="3"
        md="4"
        sm="6"
        cols="12"
        class="mb-3"
      >
        <b-form-checkbox v-model="values[checkbox]">
          {{ labelSection(checkbox) }}
        </b-form-checkbox>
      </b-col>
      <b-col>
        * Solo se verán estos checkbox si eres administrador
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ALL_BETA_SECTION from "../../../graphql/Company/AllBetaSections.gql";

export default {
  name: "BetaSections",
  props: {
    betaSectionsSelected: Array
  },
  data() {
    return {
      checkboxes: [],
      values: {}
    };
  },

  mounted() {
    if (this.checkboxes.length == 0) {
      this.getAllBetaSections();
    } else {
      this.setValues();
    }
  },
  methods: {
    /**
     * Se encarga de obtener todas las secciones beta
     */
    getAllBetaSections() {
      this.$apollo.query({ query: ALL_BETA_SECTION }).then(({ data }) => {
        this.checkboxes = this.$dup(data.allBetaSections);
        this.setValues();
      });
    },
    /**
     * Traduce el nombre de una seccion
     * @param {String} section
     * @return {String}
     */
    labelSection(section) {
      switch (section) {
        case "product_meli_mirror":
          return "Atributos Mercado Libre";
        case "bulk_upload":
          return "Carga Masiva";
        case "addresses_normalization":
          return "Normalización de direcciones";
        case "notify":
          return "Notificar pedidos";
        default:
          return section;
      }
    },
    /**
     * Setea los valores por defecto de las secciones beta
     */
    setValues() {
      let tempValues = {};
      this.checkboxes.forEach(ck => {
        tempValues[ck] = this.betaSectionsSelected.includes(ck);
      });
      this.values = this.$dup(tempValues);
    }
  },
  watch: {
    values: {
      handler(val) {
        let tempVals = [];
        Object.keys(val).forEach(key => {
          if (val[key]) {
            tempVals.push(key);
          }
        });
        this.$emit("change", tempVals);
      },
      deep: true
    },
    betaSectionsSelected(newValue, oldValue) {
      if (!this.$arrayDeepCompare(newValue, oldValue)) {
        this.setValues();
      }
    }
  }
};
</script>

<style></style>
