<template>
  <b-tr>
    <b-td>
      {{ banner.eventName }}
    </b-td>
    <b-td>
      {{ banner.eventDateAt }}
    </b-td>
    <b-td>
      {{ banner.eventDateTo }}
    </b-td>
    <b-td>
      {{ banner.status ? "Activo" : "Inactivo" }}
    </b-td>
    <b-td>
      {{ banner.redirectUrl }}
    </b-td>
    <b-td>
      <img :src="banner.thumbUrl" alt="" />
    </b-td>
    <b-td>
      <b-row align-h="end" no-gutters>
        <b-button
          v-if="$permissionToUpdate(currentUser, 'users')"
          size="lg"
          variant="outlined"
          class="p-0 focus-btn"
          v-b-tooltip.hover="'Editar Banner'"
          :to="`banner/${banner.id}/edit`"
        >
          <b-icon stacked icon="pencil" scale="0.60"></b-icon>
        </b-button>
        <b-button
          v-if="$permissionToDelete(currentUser, 'users')"
          size="lg"
          variant="outlined"
          class="p-0 focus-btn"
          v-b-tooltip.hover="'Eliminar Banner'"
          @click="swalDeleteBanner"
        >
          <b-icon stacked icon="x" scale="0.75"></b-icon>
        </b-button>
      </b-row>
    </b-td>
  </b-tr>
</template>

<script>
import DELETE_BANNER from "../../graphql/Banners/DeleteBanner.gql";
import { mapState } from "vuex";
export default {
  name: "BannersDetail",
  props: {
    banner: Object
  },
  data() {
    return {
      deleting: false,
      updating: false
    };
  },
  computed: {
    ...mapState(["currentUser"])
  },
  methods: {
    /**
     * Se encarga de enviar la mutacion para borrar un banner
     */
    deleteBanner() {
      this.deleting = true;
      this.$apollo
        .mutate({
          mutation: DELETE_BANNER,
          variables: {
            id: this.banner.id
          }
        })
        .then(({ data }) => {
          if (data.deleteBanner.result) {
            this.$emit("delete-banner");
            this.deleting = false;
          } else {
            this.$emit("delete-banner", data.deleteBanner.error);
            this.deleting = false;
          }
        });
    },
    /**
     * Lanza un swal para confirmar la eliminacion del banner
     */
    swalDeleteBanner() {
      this.$swal
        .fire({
          title: "Eliminar Banner",
          text: "El Banner se eliminará de forma permanente. ¿Estás seguro?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si",
          cancelButtonText: "No"
        })
        .then(result => {
          if (result.value) {
            this.deleteBanner();
          }
        });
    }
  }
};
</script>

<style></style>
