<template>
  <b-skeleton width="100%" v-if="loading"></b-skeleton>
  <div width="100%" v-else-if="error">
    No puedes crear más sugerencias.
  </div>
  <b-card v-else no-body>
    <b-container>
      <b-row>
        <b-col md="12">
          <h4 class="font-weight-bold m-3">{{ title }}</h4>
        </b-col>
      </b-row>
      <b-row>
        <span id="button-confirm" class="d-inline-block" tabindex="0">
          <b-button
            class="ml-4"
            variant="outline-secondary"
            :disabled="currentDomainSelected.id === ''"
            @click="goToAttributes"
            ><b-icon-search /> Confirmar
          </b-button>
        </span>
        <b-tooltip target="button-confirm">
          {{
            !currentDomainSelected.id
              ? "Seleccione una Categoría"
              : "Confirmar Categoría"
          }}
        </b-tooltip>
      </b-row>
      <b-row>
        <b-col md="12">
          <b-input-group class="mt-3">
            <b-form-input
              v-model="filter"
              @keyup.enter="searchDomain"
              placeholder="Ej: Celulares y Smartphones"
            ></b-form-input>
            <b-input-group-append>
              <b-button
                variant="outline-info"
                :disabled="quota === 0"
                title="Buscar Categoría"
                v-b-tooltip.hover
                @click="searchDomain"
              >
                Buscar
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <br />
      <b-row>
        <b-col md="12">
          <b-skeleton-table
            v-if="loadingDomains"
            :rows="5"
            :columns="2"
            :table-props="{ bordered: true, striped: true }"
          ></b-skeleton-table>
          <div v-else-if="errorDomains">
            No fue posible obtener las categorías. Intente de nuevo
          </div>
          <b-table
            v-else
            striped
            hover
            :items="currentDomains"
            :fields="fields"
            responsive="sm"
            style="background-color: #f7f8f8, cursor: pointer"
            @row-clicked="rowClickHandler"
            :tbody-tr-class="rowClass"
          >
            <template #cell(imageDomain)="data">
              <b-img
                thumbnail
                :src="imageUrl(data.item)"
                class="centry-thumb transparent-bg"
                alt="image"
                loading="lazy"
                v-if="imageUrl(data.item)"
                width="80px"
              ></b-img>
            </template>
            <template #cell(selectDomain)="data">
              <h3>
                <b-badge pill :variant="getClassDomainBadge(data.item.id)"
                  ><b-icon-check-square
                    v-if="data.item.id == currentDomainSelected.id"
                  />
                  <b-icon-dash-circle-fill v-else />
                </b-badge>
              </h3>
            </template>
          </b-table>
          <b-button
            v-if="hasNextPage()"
            title="Ver más categorías"
            v-b-tooltip.hover
            class="m-2 float-right"
            @click="seeMore()"
          >
            Ver más
          </b-button>
          <b-alert v-else show variant="light" class="text-center">
            No hay más datos para mostrar.
          </b-alert>
        </b-col>
      </b-row>
    </b-container>
  </b-card>
</template>

<script>
import MERCADO_LIBRE_SUGGESTION_QUOTA from "../../../graphql/MercadoLibre/CatalogSuggestions/Quota.gql";
import MERCADO_LIBRE_DOMAINS_AVAILABLE from "../../../graphql/MercadoLibre/CatalogSuggestions/DisponibleDomains.gql";

export default {
  name: "MercadoLibreListDisponibleDomains",
  components: {},
  props: {
    integrationConfigId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      quota: 0,
      loading: true,
      error: false,
      filter: "",
      domains: [],
      filteredDomains: [],
      limit: 10,
      fields: [
        { key: "imageDomain", label: "" },
        { key: "name", label: "nombre", sortable: true },
        { key: "selectDomain", label: "" }
      ],
      loadingDomains: true,
      errorDomains: false,
      currentDomainSelected: { id: "" }
    };
  },
  computed: {
    title() {
      return "Seleccione una Categoría Disponible";
    },
    quotaText() {
      if (this.quota === 0 && this.error) {
        return "Tu usuario no puede crear sugerencias";
      } else if (this.quota === 0) {
        return "No puedes crear más sugerencias";
      } else if (this.quota === 1) {
        return "Solo puedes crear una sugerencia más";
      }
      return `Puedes crear hasta ${this.quota} sugerencias más`;
    },
    /**
     * Tooltip del botón crear
     * @returns {String}
     */
    createTitle() {
      if (this.quota === 0) {
        return this.quotaText;
      }
      return "";
    },
    currentDomains() {
      return this.filteredDomains.slice(0, this.limit);
    }
  },
  mounted() {
    this.getQuota();
    this.getDomainsAvailable();
  },
  methods: {
    /**
     * Obtiene la cuota, y la
     * almacena en el data
     */
    getQuota() {
      this.$apollo
        .query({
          query: MERCADO_LIBRE_SUGGESTION_QUOTA,
          variables: {
            integrationConfigId: this.integrationConfigId
          }
        })
        .then(({ data }) => {
          const quotas = data.allMercadoLibreCatalogSuggestionsQuota;
          if (quotas) {
            this.quota = quotas.reduce(
              (acum, quota) => acum + quota.available,
              0
            );
          }
        })
        .catch(() => {
          this.error = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    /**
     * Obtiene los dominios disponibles para catalogo
     */
    getDomainsAvailable() {
      this.$apollo
        .query({
          query: MERCADO_LIBRE_DOMAINS_AVAILABLE,
          variables: {
            integrationConfigId: this.integrationConfigId,
            available: true
          }
        })
        .then(({ data }) => {
          const domains = data.allMercadoLibreCatalogSuggestionsDomainsSearch;
          if (domains) {
            this.domains = domains;
            this.filteredDomains = domains;
          }
        })
        .catch(() => {
          this.errorDomains = true;
        })
        .finally(() => {
          this.loadingDomains = false;
        });
    },
    /**
     * Indica si es posible ver más dominios en listado
     * paginamiento
     * @return {boolean}
     */
    hasNextPage() {
      return this.limit < this.filteredDomains.length;
    },
    /**
     * Se aumenta el numero de dominios en la tabla
     */
    seeMore() {
      this.limit = 10 + this.limit;
    },
    /**
     * Selecciona o deselecciona una categoría
     * @param {Object} record
     */
    rowClickHandler(record) {
      if (this.currentDomainSelected.id === record.id) {
        this.currentDomainSelected = { id: "" };
      } else {
        this.currentDomainSelected = record;
      }
    },
    /**
     * Entrega clase para el color de la fila
     * @param {Object} item
     * @param {String} type
     * @returns {String}
     */
    rowClass(item, type) {
      if (!item || type !== "row") return "cursor-pointer";
      if (item.id === this.currentDomainSelected.id)
        return "cursor-pointer table-success";
      return "cursor-pointer";
    },
    /**
     * Entrega clase para el color del badge
     * @param {String} domainId
     * @return {String}
     */
    getClassDomainBadge(domainId) {
      if (domainId === this.currentDomainSelected.id) {
        return "success";
      } else {
        return "danger";
      }
    },
    /**
     * Permite filtrar el conjunto de dominios por nombre
     */
    searchDomain() {
      this.filteredDomains = this.domains.filter(domain => {
        return domain.name.toLowerCase().includes(this.filter.toLowerCase());
      });
    },
    /**
     * Función que permite dirigirse al formulario de selección de producto
     * y atributos
     */
    goToAttributes() {
      this.$emit("confirm", this.currentDomainSelected);
    },
    /**
     * Entrega la url de la imagen
     * @returns {String}
     */
    imageUrl(domain) {
      return this.$dig(domain, "pictures", 0, "url");
    }
  }
};
</script>
<style>
.cursor-pointer {
  cursor: pointer;
}
</style>
