<template>
  <div>
    <base-header
      title="Monitoreo de sincronizaciones"
      title-link="/admin/option_list"
    >
    </base-header>
    <b-container fluid="">
      <b-row>
        <b-col md="12">
          <synchronization-monitoring-filters></synchronization-monitoring-filters>
          <synchronization-monitoring-summary></synchronization-monitoring-summary>
          <synchronization-monitoring-table></synchronization-monitoring-table>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import BaseHeader from "../../components/BaseHeader";
import SynchronizationMonitoringTable from "@/components/SynchronizationMonitoringTable";
import SynchronizationMonitoringSummary from "@/components/SynchronizationMonitoringSummary";
import SynchronizationMonitoringFilters from "@/components/SynchronizationMonitoringFIlters";

export default {
  name: "SynchronizationMonitoring",
  components: {
    SynchronizationMonitoringFilters,
    SynchronizationMonitoringSummary,
    SynchronizationMonitoringTable,
    BaseHeader
  }
};
</script>
