<template>
  <div>
    <base-pretty-check-box
      v-model="selectAll"
      :disabled="disableOrderSelection"
    ></base-pretty-check-box>
    <div v-if="selectAll">
      <span class="text-info mb-2" v-if="loadingExternal">
        Seleccionados {{ externalSelectedSize }} pedidos<br />
        Último con fecha:
        {{ lastExternalSelectedDate }}
      </span>
      <b-button
        v-if="loadingExternal"
        class="mr-2"
        variant="outline-secondary"
        size="sm"
        @click="stopSelectAll"
        >Detener</b-button
      >
      <b-button size="sm" @click="selectAlls" :disabled="loadingExternal"
        >Todos</b-button
      >
    </div>
  </div>
</template>
<script>
import BasePrettyCheckBox from "@/components/BasePrettyCheckBox";
import { mapState, mapMutations } from "vuex";
import ALL_ORDERS from "../../graphql/AllOrdersForSelectAll.gql";
export default {
  name: "OrderSelectAll",
  components: {
    BasePrettyCheckBox
  },
  data() {
    return {
      selectAll: false,
      external: false,
      loadingExternal: false,
      queryInfo: {}
    };
  },
  computed: {
    ...mapState(["selectedOrders", "disableOrderSelection", "filters"]),
    externalSelectedSize() {
      return this.$ifNull(this.$dig(this.queryInfo, "size"), 0);
    },
    lastExternalSelectedDate() {
      const datetime = this.$ifNull(
        this.$dig(this.queryInfo, "lastRegistry", "node", "createdAt")
      );
      if (datetime) {
        return datetime.split("T")[0];
      }
      return "Ninguna";
    }
  },
  methods: {
    ...mapMutations([
      "selectAllOrders",
      "unselectAllOrders",
      "selectAllsOrders",
      "unselectExtraOrders",
      "setDisableOrderSelection"
    ]),
    selectAlls() {
      this.loadingExternal = true;
      this.setDisableOrderSelection(true);
      // TODO: Seria bueno agregar condiciones para evitar
      // que se seleccionen todas las ordenes de una empresa
      // si es que no se agrega ningun filtro
      this.$getAllPages(
        ALL_ORDERS,
        this.queryVariables(),
        "allOrders",
        this.queryInfo
      ).then(result => {
        this.selectAllsOrders(result.map(x => x.node));
        this.loadingExternal = false;
        this.setDisableOrderSelection(false);
      });
    },
    stopSelectAll() {
      this.$set(this.queryInfo, "stop", true);
      this.loadingExternal = false;
    },
    queryVariables() {
      const variables = {};
      if (this.filters.numbers) {
        variables.numbers = this.filters.numbers;
      }
      if (this.filters.ids) {
        variables.ids = this.filters.ids;
      }
      if (this.filters.skus) {
        variables.skus = this.filters.skus;
      }
      if (this.filters.statuses) {
        variables.statuses = this.filters.statuses;
      }
      if (this.filters.origins) {
        variables.origins = this.filters.origins;
      }
      if (this.filters.createFrom) {
        variables.createFrom = this.filters.createFrom;
      }
      if (this.filters.createTo) {
        variables.createTo = this.filters.createTo;
      }
      if (this.filters.updateFrom) {
        variables.updateFrom = this.filters.updateFrom;
      }
      if (this.filters.updateTo) {
        variables.updateTo = this.filters.updateTo;
      }
      if (this.filters.shippingLabelPrint) {
        variables.shippingLabelPrint = this.filters.shippingLabelPrint;
      }
      return variables;
    }
  },
  watch: {
    selectAll(value) {
      if (this.external) {
        this.external = false;
        return;
      }
      if (value) {
        this.selectAllOrders();
      } else {
        this.unselectAllOrders();
      }
    },
    selectedOrders(hash) {
      if (
        !this.external &&
        this.selectAll &&
        Object.keys(hash).filter(oid => !hash[oid].selected && !hash[oid].extra)
          .length
      ) {
        this.external = true;
        this.unselectExtraOrders();
        this.selectAll = false;
      }
    }
  }
};
</script>
