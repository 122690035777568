<template>
  <div>
    <base-header
      title="Promociones de Mercado Libre"
      :title-link="titleLink"
      :map="['Mercado Libre', 'Integraciones', 'Promociones', 'Oferta del Día']"
    ></base-header>

    <mercado-libre-promotion-items
      v-model="meliItems"
      :integration-config-id="integrationConfigId"
      :deal-id="dodId"
      deal-type="DOD"
      @loading="value => (loadingItems = value)"
      @cursor="value => (cursor = value)"
      ref="items"
    />
    <div v-if="!loadingItems || items.length">
      <b-container fluid>
        <b-row>
          <b-col cols="10">
            <p>Nombre: Oferta del día</p>
          </b-col>
          <b-col cols="2">
            <b-button @click="sendProducts()">
              <b-icon-check-circle></b-icon-check-circle>Guardar
            </b-button>
          </b-col>
        </b-row>
        <br />
      </b-container>

      <base-j-excel-table
        v-model="items"
        :columns="columns"
        :on-selection="this.onSelection"
        :allow-insert-row="false"
        :allow-insert-column="false"
        :custom-on-change="this.onChange"
      ></base-j-excel-table>
      <b-button v-if="!!cursor" :disabled="!!loadingItems" @click="seeMore"
        >Ver más</b-button
      >
    </div>
  </div>
</template>

<script>
import BaseHeader from "../components/BaseHeader.vue";
// import MERCADO_LIBRE_GET_PROMOTION_INFO from "../graphql/MercadoLibreGetPromotionInfo.gql";
import BaseJExcelTable from "@/components/BaseJExcelTable";
import MercadoLibrePromotionItems from "@/components/MercadoLibrePromotionItems";
// import jexcel from "jexcel";
import INTEGRATION_CONFIG_MERCADO_LIBRE from "../graphql/IntegrationConfigMercadoLibre.gql";
import jexcel from "jexcel";
import MERCADO_LIBRE_DELETE_PROMOTION_GENERIC from "@/graphql/MercadoLibreDeletePromotionGeneric.gql";
import CREATE_MERCADO_LIBRE_ITEM_PROMOTIONS_BODY from "@/graphql/MercadoLibreSetPromotionBody.gql";
require("moment/locale/es");

export default {
  name: "MercadoLibrePromotionsDealIndex",
  props: {
    integrationConfigId: {
      type: String,
      required: true
    },
    dodId: {
      type: String,
      required: true
    }
  },
  computed: {
    titleLink: {
      get: function() {
        return `/mercadolibre/promotions/${this.integrationConfigId}`;
      }
    }
  },
  components: {
    BaseHeader,
    BaseJExcelTable,
    MercadoLibrePromotionItems
  },
  data() {
    return {
      cursor: true,
      showAddModal: false,
      integrationConfigMeli: {},
      meliItems: [],
      items: [],
      loadingItems: true,
      columns: [
        { type: "checkbox", title: "Activo?" },
        { type: "text", title: "Producto", width: 240, readOnly: true },
        {
          type: "text",
          title: "Código MercadoLibre",
          width: 150,
          readOnly: true
        },
        {
          type: "numeric",
          title: "Precio Máximo ORIGINAL",
          width: 120,
          mask: "$ #.##0,0",
          decimal: ",",
          readOnly: true
        },
        {
          type: "numeric",
          title: "Precio Original",
          width: 120,
          mask: "$ #.##0,0",
          decimal: ",",
          readOnly: true
        },
        {
          type: "numeric",
          title: "Precio Oferta",
          width: 120,
          mask: "$ #.##0,0",
          decimal: ",",
          readOnly: false
        },
        {
          type: "numeric",
          title: "STOCK mínimo",
          width: 120,
          readOnly: true,
          decimal: "."
        },
        { type: "text", title: "Estado", width: 120, readOnly: true },
        { type: "text", title: "Fecha de Inicio", width: 200, readOnly: true },
        { type: "text", title: "Fecha de término", width: 200, readOnly: true },

        { type: "html", title: "Acciones", width: 120 }
      ],
      ACTIVE: 0,
      ITEM_NAME: 1,
      ITEM_ID: 2,
      MAX_PRICE: 3,
      BASE_PRICE: 4,
      OFFER_PRICE: 5,
      STOCK: 6,
      STATUS: 7,
      START_DATE: 8,
      FINISH_DATE: 9,

      ACTIONS: 10,
      promotion_start_date: "",
      promotion_end_date: "",
      promotion_deadline_date: "",
      promotion_status: "",
      promotion_name: "",
      discount_percent: "",
      top_discount_percent: ""
    };
  },
  beforeMount() {
    this.getIntegrationConfig();
    // this.getPromotionInfo();
  },
  methods: {
    onChange(instance, cell, x, y, value, jExcelInstance) {
      const oldValue = this.items[y][x];
      if (value !== oldValue)
        jExcelInstance.setStyle(
          jexcel.getColumnNameFromId([x, y]),
          "font-weight",
          "bold",
          true
        );
      else
        jExcelInstance.setStyle(
          jexcel.getColumnNameFromId([x, y]),
          "font-weight",
          "inherit",
          true
        );
      if (!this.changed) this.changed = true;
    },
    getIntegrationConfig() {
      this.$apollo
        .query({
          query: INTEGRATION_CONFIG_MERCADO_LIBRE,
          variables: { id: this.integrationConfigId }
        })
        .then(result => {
          this.integrationConfigMeli =
            result.data.integrationConfigMercadoLibre;
        });
    },
    async onSelection(instance, x1, y1, x2, y2) {
      if (x1 === x2 && y1 === y2 && x1 === this.ACTIONS) {
        if (confirm("El item sera eliminado")) {
          await this.deleteItem(this.items[y1][this.ITEM_ID], y1);
        }
      }
    },
    async deleteItem(itemId, itemIndex) {
      this.$apollo
        .mutate({
          mutation: MERCADO_LIBRE_DELETE_PROMOTION_GENERIC,
          variables: {
            item_id: itemId,
            integration_config_id: this.integrationConfigId,
            params: JSON.stringify({ promotion_type: "DOD" })
          }
        })
        .then(result => {
          // console.log(result.data.mercadoLibreDeletePromotionGeneric.result)
          if (
            result.data.mercadoLibreDeletePromotionGeneric.result == "200" ||
            result.data.mercadoLibreDeletePromotionGeneric.result == 200
          ) {
            this.items.splice(itemIndex, 1);
          }
        });
    },

    sendProducts() {
      // console.log(this.$data.items)
      // console.log(this.integrationConfigId)
      // console.log(this.dealId)

      for (let i = 0; i < this.$data.items.length; i++) {
        console.log(this.items);
        if (this.$data.items[i][0]) {
          this.$apollo
            .mutate({
              mutation: CREATE_MERCADO_LIBRE_ITEM_PROMOTIONS_BODY,
              variables: this.variablesToSendProduct(this.items[i])
            })
            .then(result => {
              const item_result =
                result.data.mercadoLibreSetPromotionBody.result;
              if (item_result.price != null) {
                this.items.push([
                  0,
                  this.$data.items[i][this.ITEM_NAME],
                  this.$data.items[i][this.ITEM_ID],
                  "",
                  item_result.original_price,
                  item_result.price,
                  "",
                  "",
                  "",
                  "",
                  "Eliminar"
                ]);
                var spreadsheet = document.getElementById("spreadsheet");
                spreadsheet.jexcel.refresh();
              }
            });
        } else if (
          this.$data.items[i][this.ACTIVE] == "0" &&
          this.$data.items[i][this.STATUS] == "Activo"
        ) {
          this.$apollo
            .mutate({
              mutation: CREATE_MERCADO_LIBRE_ITEM_PROMOTIONS_BODY,
              variables: this.variablesToSendProduct(this.items[i])
            })
            .then(result => {
              console.log(result.data.mercadoLibreDeletePromotionItems.result);
              if (
                result.data.mercadoLibreDeletePromotionMarketplaceItems
                  .result == "200"
              ) {
                this.items.splice(i, 1);
              }
            });
        }
      }
    },
    /**
     * Genera las variables necesarias para la creación/actualización del
     * item en la promoción
     * @param {Object} item
     */
    variablesToSendProduct(item) {
      return {
        body: JSON.stringify({
          deal_price:
            typeof item[this.OFFER_PRICE] === "number"
              ? item[this.OFFER_PRICE]
              : parseInt(item[this.OFFER_PRICE].replace(/\D/g, "")),
          promotion_type: "DOD"
        }),
        item_id: item[this.ITEM_ID],
        integration_config_id: this.integrationConfigId
      };
    },
    // getPromotionInfo() {
    //   this.$apollo
    //       .mutate({
    //         mutation: MERCADO_LIBRE_GET_PROMOTION_INFO,
    //         variables: {
    //           deal_id: this.dodId,
    //           integration_config_id: this.integrationConfigId,
    //           promotion_type: 'DOD'
    //         }
    //       })
    //       .then(result => {
    //         // console.log(result.data.mercadoLibreGetPromotionInfo.result)
    //         // const result_body = result.data.mercadoLibreGetPromotionInfo.result
    //         // this.promotion_start_date = result_body.start_date
    //         // this.promotion_end_date = result_body.finish_date
    //         // this.promotion_deadline_date  = result_body.deadline_date
    //         // this.promotion_status = result_body.status
    //         // this.promotion_name  = result_body.name
    //         // this.discount_percent  = result_body.benefits.meli_percent
    //         // this.top_discount_percent = result_body.benefits.seller_percent
    //
    //       });
    // }
    /**
     * Recibe los items de la promoción y realiza la transformación de datos
     * para que se muestren en la tabla
     * @param {Array<Object>} meliItems
     */
    addItemsToJExcel(meliItems) {
      this.items = [];
      meliItems.forEach(item => {
        this.items.push([
          item.status == "ACTIVE" ? 1 : 0,
          item.productTitle,
          item.id,
          item.maxOriginalPrice != 0 ? item.maxOriginalPrice : "",
          item.originalPrice != 0 ? item.originalPrice : "",
          item.price != 0 ? item.price : "",
          item.stock != null ? item.stock.min : "",
          this.$options.filters.meliToCentry(item.status),
          item.startDate,
          item.finishDate,
          "Eliminar"
        ]);
      });
    },
    /**
     * Carga una página más de ítemes
     */
    seeMore() {
      this.$refs.items.getItems();
    }
  },
  watch: {
    meliItems(newVal) {
      this.addItemsToJExcel(newVal);
    }
  }
};
</script>
