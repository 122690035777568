<template>
  <div>
    <b-row>
      <b-col :lg="notSlave ? 4 : 6" sm="6" cols="12">
        <b-form-group
          label="Lista de precio normal"
          label-for="priceListNormal"
          class="mb-3"
          :description="loadedDescription"
        >
          <b-spinner v-if="loading" />
          <v-select
            v-else
            id="priceListNormal"
            v-model="value.priceListNormal"
            :reduce="x => x.value"
            :options="priceListOptions"
            :clearable="false"
            append-to-body=""
            @input="emitChange"
            placeholder="Selecciona una opción"
            :disabled="!allowSave"
          />
        </b-form-group>
        <b-form-group
          label="Lista de precio Descuentos"
          label-for="priceListDiscount"
          class="mb-3"
          :description="loadedDescription"
        >
          <b-spinner v-if="loading" />
          <v-select
            v-else
            id="priceListDiscount"
            v-model="value.priceListDiscount"
            :reduce="x => x.value"
            :options="priceListOptions"
            append-to-body=""
            @input="emitChange"
            placeholder="Selecciona una opción"
            :disabled="!allowSave"
          />
        </b-form-group>
      </b-col>
      <b-col lg="4" sm="6" cols="12" v-if="notSlave">
        <b-form-group
          label="Cómo rellenar el SKU de producto en Centry"
          label-for="productSku"
          class="mb-3"
        >
          <v-select
            id="productSku"
            v-model="value.productSku"
            :reduce="x => x.value"
            :options="productSkuOptions"
            :clearable="false"
            append-to-body=""
            @input="emitChange"
            placeholder="Selecciona una opción"
            :disabled="!allowSave"
          />
        </b-form-group>
        <b-form-group
          label="Considerar como nulos los precios de descuento menores que"
          label-for="priceDiscountThreshold"
          class="mb-3"
        >
          <b-form-input
            id="priceDiscountThreshold"
            type="number"
            number
            step="1"
            min="0"
            v-model="value.priceDiscountThreshold"
            @input="emitChange"
            :disabled="!allowSave || value.priceListDiscount === null"
          />
        </b-form-group>
      </b-col>
      <b-col :lg="notSlave ? 4 : 6" sm="6" cols="12">
        <b-form-group
          label="Cómo maneja productos en BSale"
          label-for="productBehavior"
          class="mb-3"
        >
          <v-select
            id="productBehavior"
            v-model="value.productBehavior"
            :reduce="x => x.value"
            :options="productBehaviorOptions"
            :clearable="false"
            append-to-body=""
            @input="emitChange"
            placeholder="Selecciona una opción"
            :disabled="!allowSave"
          />
        </b-form-group>
        <b-form-group
          label="Cómo manejar en Centry productos nuevos inactivos"
          label-for="inactiveNewProductsBehavior"
          class="mb-3"
          v-if="notSlave"
        >
          <v-select
            id="inactiveNewProductsBehavior"
            v-model="value.inactiveNewProductsBehavior"
            :reduce="x => x.value"
            :options="inactiveNewProductsBehaviorOptions"
            :clearable="false"
            append-to-body=""
            @input="emitChange"
            placeholder="Selecciona una opción"
            :disabled="!allowSave"
          />
        </b-form-group>
        <b-form-group
          label="Si una variante no controla stock como quiere que Centry tome el stock"
          label-for="unlimitedStockBehavior"
          class="mb-3"
          v-if="notSlave"
        >
          <v-select
            id="unlimitedStockBehavior"
            v-model="value.unlimitedStockBehavior"
            :reduce="x => x.value"
            :options="unlimitedStockBehaviorOptions"
            :clearable="false"
            append-to-body=""
            @input="emitChange"
            placeholder="Selecciona una opción"
            :disabled="!allowSave"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import INTEGRATION_DATA_PRICE_LISTS from "../../../graphql/IntegrationConfig/BSale/IntegrationDataPriceLists.gql";

export default {
  name: "IntegrationConfigsBSaleProductData",
  props: {
    _value: Object,
    originalData: Object,
    allowSave: Boolean,
    ic: Object,
    use: String
  },
  model: {
    event: "change",
    prop: "_value"
  },
  mounted() {
    this.loadPriceLists();
  },
  data() {
    return {
      value: this.$dup(this._value),
      loadedDescription: "",
      loading: true,
      priceListOptions: [],
      productSkuOptions: [
        { value: "id_b_sale", label: "Identificador del producto en BSale" },
        { value: "id_centry", label: "Identificador del producto en Centry" },
        {
          value: "first_variant_sku",
          label: "SKU de la primera variante"
        },
        {
          value: "common_varinat_sku_prefix",
          label: "Prefijo común de los SKU de las variantes"
        }
      ],
      productBehaviorOptions: [
        { value: "as_product", label: "Productos como productos" },
        { value: "as_category", label: "Productos como categorias" }
      ],
      inactiveNewProductsBehaviorOptions: [
        { value: "create_inactive", label: "Crear inactivo en Centry" },
        { value: "not_create_inactive", label: "No crear en Centry" }
      ],
      unlimitedStockBehaviorOptions: [
        { value: "zero", label: "Igual a cero" },
        { value: "equal", label: "Stock que se tenga en Centry" },
        { value: "infinite", label: "Número muy grande" }
      ]
    };
  },
  computed: {
    notSlave() {
      return this.use !== "slave";
    }
  },
  methods: {
    /**
     * Emite el valor para actualizar el modelo
     */
    emitChange() {
      this.$emit("change", this.value);
    },

    /**
     * Setea el valor en value
     * si es que es un objeto distinto
     * @param {Object} val
     */
    setValue(val) {
      if (val !== this.value) this.value = val;
    },
    /**
     * Carga el listado de precios desde el servicio
     */
    loadPriceLists() {
      this.loading = true;
      this.$apollo
        .query({
          query: INTEGRATION_DATA_PRICE_LISTS,
          variables: {
            integrationConfigId: this.ic.id
          }
        })
        .then(({ data }) => {
          this.priceListOptions = data.integrationConfigBSale.priceLists.map(
            option => {
              return { value: parseInt(option.id), label: option.name };
            }
          );
          this.loading = false;
        })
        .catch(() => {
          this.priceListOptions = [];
          this.loading = false;
          this.loadedDescription =
            "Error: No pudimos cargar las listas de precio. Revisa los datos de vinculación";
        });
    }
  },
  watch: {
    value() {
      this.emitChange();
    },
    _value(val) {
      this.setValue(val);
    }
  }
};
</script>
