<template>
  <div>
    <b-row>
      <b-col>
        <b-form-group>
          <b-form-checkbox v-model="activePaymentsMethods">
            Métodos de pago
          </b-form-checkbox>

          <b-spinner v-if="loadingPaymentsMethods" label="Spinning"></b-spinner>
          <v-select
            v-else
            multiple
            v-model="paymentsMethods"
            :options="paymentsMethodsOptions"
            :disabled="!activePaymentsMethods"
          ></v-select>
          <br />
        </b-form-group>
      </b-col>

      <b-col v-if="['regular'].indexOf(promotion.type) != -1">
        <b-row>
          <b-col>
            <b-form-checkbox v-model="isMinMaxInstallments">
              Número de cuotas
            </b-form-checkbox>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            Minimo de cuotas
          </b-col>

          <b-col>
            <b-form-input
              type="number"
              min="0"
              v-model="minInstallment"
              :disabled="!isMinMaxInstallments"
            >
            </b-form-input>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            Maximo de cuotas
          </b-col>

          <b-col>
            <b-form-input
              type="number"
              min="0"
              v-model="maxInstallment"
              :disabled="!isMinMaxInstallments"
            >
            </b-form-input>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import INTEGRATION_CONFIG_VTEX_ALL_PAYMENT_METHODS from "@/graphql/IntegrationConfigVtexAllPaymentMethods.gql";

export default {
  name: "VtexBasePromotionPaymentsAndInstallments",
  props: {
    value: {
      type: Object,
      required: true
    },
    vtexIntegrationConfigId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      promotion: this.value,
      activePaymentsMethods: this.value.paymentsMethods.length > 0,
      paymentsMethods: this.value.paymentsMethods.map(paymentMethod => {
        return {
          label: paymentMethod.name,
          value: paymentMethod
        };
      }),
      loadingPaymentsMethods: true,
      isMinMaxInstallments: this.value.isMinMaxInstallments,
      minInstallment: this.value.minInstallment,
      maxInstallment: this.value.maxInstallment
    };
  },
  /**
   * Se obtienen los datos de métodos de pagos disponibles en Vtex.
   */
  async mounted() {
    this.getPaymentMethods();
  },
  methods: {
    /**
     * Obtiene los metodos de pagos disponibles en Vtex.
     */
    async getPaymentMethods() {
      this.$apollo
        .query({
          query: INTEGRATION_CONFIG_VTEX_ALL_PAYMENT_METHODS,
          variables: {
            id: this.vtexIntegrationConfigId
          }
        })
        .then(({ data }) => {
          this.paymentsMethodsOptions = data.integrationConfigVtex.allPaymentMethods.map(
            pm => ({
              label: pm.name + " (" + pm.id + ")",
              value: { id: pm.id, name: pm.name + " (" + pm.id + ")" }
            })
          );
          this.loadingPaymentsMethods = false;
        });
    }
  },
  watch: {
    value(newValue) {
      this.promotion = newValue;
    },
    promotion: {
      handler(newValue) {
        this.$emit("input", newValue);
      },
      deep: true
    },
    activePaymentsMethods(newValue) {
      if (newValue) {
        this.promotion.paymentsMethods = this.paymentsMethods.map(
          paymentMethod => {
            return paymentMethod.value;
          }
        );
      } else {
        this.promotion.paymentsMethods = [];
      }
    },
    paymentsMethods(newValue) {
      this.promotion.paymentsMethods = newValue.map(paymentMethod => {
        return paymentMethod.value;
      });
    },
    isMinMaxInstallments(newValue) {
      this.promotion.isMinMaxInstallments = newValue;
      if (newValue) {
        this.promotion.minInstallment = parseInt(this.minInstallment);
        this.promotion.maxInstallment = parseInt(this.maxInstallment);
      } else {
        this.promotion.minInstallment = 0;
        this.promotion.maxInstallment = 0;
      }
    },
    minInstallment(newValue) {
      if (newValue) {
        this.promotion.minInstallment = parseInt(newValue);
      } else {
        this.promotion.minInstallment = 0;
      }
    },
    maxInstallment(newValue) {
      if (newValue) {
        this.promotion.maxInstallment = parseInt(newValue);
      } else {
        this.promotion.maxInstallment = 0;
      }
    }
  }
};
</script>
