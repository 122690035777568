<template>
  <div>
    <b-row>
      <b-col>
        <v-select
          :options="qualityForSelect()"
          :reduce="x => x.value"
          placeholder="Calidad"
          v-model="quality"
        ></v-select>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { mapMutations, mapState } from "vuex";

export default {
  name: "MercadoLibreAttributesGroupIntegrationFilters",
  props: {
    product: Object
  },
  data() {
    return {
      quality: null
    };
  },
  computed: {
    ...mapState(["filters", "currentUser"])
  },
  methods: {
    ...mapMutations(["setFilter"]),
    qualityForSelect() {
      return [
        { value: 0.8, label: "< 80%" },
        { value: 0.6, label: "< 60%" },
        { value: 0.4, label: "< 40%" },
        { value: 0.2, label: "< 20%" }
      ];
    }
  },
  watch: {
    quality(newValue) {
      if (newValue !== this.filters.quality) {
        this.setFilter({ quality: newValue });
      }
    },
    filters(newValue) {
      if (newValue.quality !== this.quality) {
        this.quality = newValue.quality;
      }
    }
  }
};
</script>
