var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ApolloQuery',{attrs:{"query":require('../graphql/AllHomologationOptionList.gql'),"variables":{ cursor: '', name: _vm.filters.name },"fetchPolicy":'cache-and-network',"clientId":"apolloClientCached"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_result = ref.result;
var error = ref_result.error;
var data = ref_result.data;
var query = ref.query;
var isLoading = ref.isLoading;
return [(data && isLoading)?_c('span',{staticClass:"m-2 float-right"},[_vm._v(" Actualizando listado de opciones... ")]):_vm._e(),(data && data.allHomologationOptionList)?_c('b-table-simple',{attrs:{"hover":""}},[_c('b-thead',[_c('b-tr',[_c('b-th',[_vm._v("Nombre")]),_c('b-th',[_vm._v("Tipo")]),_c('b-th')],1)],1),_c('b-tbody',[(!data.allHomologationOptionList.edges.length)?_c('b-tr',[_c('b-td',{attrs:{"colspan":"7"}},[_vm._v("No tienes listado de opciones configurado")])],1):_vm._e(),_vm._l((data.allHomologationOptionList.edges),function(optionList){return _c('b-tr',{key:optionList.node.id},[_c('b-td',[_vm._v(_vm._s(optionList.node.name))]),_c('b-td',[_vm._v(_vm._s(_vm.type(optionList.node.type)))]),_c('b-td',[_c('b-dropdown',{attrs:{"variant":"white","no-caret":"","offset":"-110"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('b-icon-three-dots-vertical')]},proxy:true}],null,true)},[_c('b-dropdown-item',{attrs:{"to":_vm.editUrl(optionList.node)}},[_vm._v(" Editar ")]),_c('b-dropdown-divider'),_c('ApolloMutation',{attrs:{"mutation":require('../graphql/DeleteHomologationOptionList.gql'),"variables":{ id: optionList.node.id },"update":function (store, allData) { return _vm.updateCache(optionList.node.id, store, allData); }},on:{"done":_vm.updateDeletedOptionList,"error":_vm.errorMessageDeleting},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var mutate = ref.mutate;
return [_c('b-dropdown-item',{on:{"click":function($event){return _vm.deleteIfAccept(optionList, mutate)}}},[_vm._v(" Eliminar ")])]}}],null,true)})],1)],1)],1)})],2)],1):_vm._e(),(error)?_c('b-alert',{attrs:{"show":"","variant":"danger"}},[_vm._v(" Ha ocurrido un error ")]):_vm._e(),(isLoading)?_c('b-spinner',{staticClass:"m-2 float-right",attrs:{"label":"Spinning"}}):_c('div',[(_vm.hasNextPage(data))?_c('b-button',{staticClass:"m-2 float-right",on:{"click":function($event){return _vm.seeMore(query, data.allHomologationOptionList.pageInfo.endCursor)}}},[_vm._v(" Ver más ")]):_c('b-alert',{staticClass:"text-center",attrs:{"show":"","variant":"light"}},[_vm._v(" No hay más datos para mostrar. ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }