<template>
  <div>
    <b-input-group>
      <b-input-group-prepend is-text>
        <b-icon-search></b-icon-search>
      </b-input-group-prepend>

      <b-form-tags
        v-model="keyword"
        separator=","
        remove-on-delete=""
        placeholder="Palabras claves (Ej: adidas, calzado, mujer)"
        @keydown.native.enter="updateKeyword"
        @keydown.native.delete="cleanSearch"
        @input="cleanSearch"
      ></b-form-tags>
    </b-input-group>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";

export default {
  name: "ProductsSimpleFilter",
  data() {
    return {
      keyword: []
    };
  },
  computed: {
    ...mapState(["filters"]),
    activateSearch() {
      return this.keyword.length > 0;
    }
  },
  methods: {
    ...mapMutations(["setFilter"]),
    /**
     * Actualiza valor de filtro de busqueda
     */
    updateKeyword() {
      this.setFilter({ keyword: this.keyword.join(",") });
    },
    /**
     * Limpia valor de filtro de busqueda
     */
    cleanSearch() {
      this.updateKeyword();
    }
  },
  watch: {
    filters(newValue, oldValue) {
      const oldKeyword = oldValue.keyword;
      const newKeyword = newValue.keyword;
      if (oldKeyword !== newKeyword && this.keyword.join(",") !== newKeyword) {
        this.keyword = newKeyword.split(",");
      }
    }
  }
};
</script>
