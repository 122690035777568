<template>
  <tbody align-h="center">
    <b-row class="mx-0">
      <b-col class="px-0 mr-1" v-for="i in quantity" :key="i">
        <b-skeleton-img
          class="p-0"
          :height="height"
          :width="width"
          no-aspect
        ></b-skeleton-img>
      </b-col>
    </b-row>
  </tbody>
</template>

<script>
export default {
  name: "BaseSkeletonImageRow",
  components: {},
  props: {
    quantity: {
      type: Number,
      default: 6
    },
    height: {
      type: String,
      default: "200px"
    },
    width: {
      type: String,
      default: "150px"
    }
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {},
  methods: {},
  watch: {}
};
</script>
<style scoped></style>
