<template>
  <b-card title="Documentos">
    <div v-for="document of documents" :key="document.id">
      <div>
        <div class="row" style="padding-bottom: 0.5rem;">
          <div class="text-left col">
            <strong>
              {{ document.documentType }}
            </strong>
          </div>
          <div class="text-right col">
            <strong v-if="document.number != ''">
              <span class="text-muted">Folio:</span>
              <b>{{ document.number }}</b>
            </strong>
            <b-button
              variant="outline"
              class="p-0 ml-3"
              @click="swalDeleteDocument(document.id)"
            >
              <b-icon-trash
                v-if="!deleting[document.id]"
                variant="danger"
                style="cursor: pointer"
                v-b-tooltip.hover="'Eliminar documento'"
              />
              <b-spinner v-else />
            </b-button>
            <b-button variant="outline" class="p-0 ml-3" v-if="superAdmin">
              <b-icon-arrow-down-up
                variant="primary"
                style="cursor: pointer"
                v-b-tooltip.hover="'Sincronizaciones Envio Documento'"
                v-b-modal="`modal-sync-req-res-${document.id}`"
              />
            </b-button>
          </div>
          <b-modal
            :id="`modal-sync-req-res-${document.id}`"
            title="Sincronizacion de Documentos enviados"
            :hide-footer="false"
            size="xl"
          >
            <invoice-request-response-list
              v-bind:documentId="document.id"
            ></invoice-request-response-list>
          </b-modal>
        </div>
      </div>
      <div class="text-left">
        <a :href="document.fileOriginalUrl">{{ document.fileFileName }}</a>
      </div>
      <div class="text-right text-muted">
        {{ $timezoneDate(currentUser, document.createdAt) | formatDateTime }}
      </div>
      <pre></pre>
      <hr />
    </div>
    <b-row>
      <b-col cols="1" md="auto" style="padding-bottom:1rem;width: 75%;">
        <b-form-file
          v-model="newFile"
          placeholder=""
          browse-text="Seleccionar"
          drop-placeholder="Suelta el archivo aquí..."
        ></b-form-file>
      </b-col>
      <b-col cols="1" md="auto" style="padding-bottom:1rem;width: 75%;">
        <b-form-select
          v-model="documentType"
          :options="documentTypeOptions"
        ></b-form-select>
      </b-col>
      <b-col cols="1" md="auto" style="padding-bottom:1rem;width: 75%;">
        <b-form-input
          v-model="number"
          id="documentTypeOptions"
          placeholder="Folio (Opcional)"
        ></b-form-input>
      </b-col>
      <b-col cols="1" md="auto" style="padding-bottom:1rem">
        <b-button variant="primary" @click="uploadFile">
          <b-icon-upload></b-icon-upload>
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { mapState } from "vuex";
import { createOrderDocument } from "../main";
import InvoiceRequestResponseList from "./InvoiceRequestResponseTable.vue";
import DELETE_ORDER_DOCUMENT from "../graphql/DeleteOrderDocument.gql";
export default {
  name: "OrderDocumentsCard",
  props: {
    order: Object
  },
  components: {
    InvoiceRequestResponseList
  },
  data() {
    let deleting = {};
    this.order.documents?.forEach(d => {
      deleting[d.id] = false;
    });
    return {
      documents: this.order.documents,
      deleting: deleting,
      documentType: null,
      newFile: null,
      number: null,
      // TODO: Obtener este listado desde Rails
      documentTypeOptions: [
        { value: null, text: "--Tipo documento--" },
        { value: "bill", text: "Boleta" },
        { value: "invoice", text: "Factura" },
        { value: "credit_note", text: "Nota de crédito" },
        { value: "debit_note", text: "Nota de débito" },
        { value: "shipping_guide", text: "Guía de despacho" },
        { value: "other", text: "Otro" }
      ]
    };
  },
  computed: {
    ...mapState(["currentUser"]),
    superAdmin() {
      return this.currentUser.role === 0;
    }
  },
  methods: {
    /**
     * Lanza una confirmacion para eliminar un documento
     * @param {String} docuemntId
     */
    swalDeleteDocument(documentId) {
      this.$swal
        .fire({
          title: "Eliminar documento",
          text: "El documento se eliminara permanentemente. ¿Está seguro?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si",
          cancelButtonText: "No"
        })
        .then(result => {
          if (result.value) {
            this.deleting[documentId] = true;
            this.deleteDocument(documentId);
          }
        });
    },
    /**
     * Envia la mutacion para eliminar un documento
     * @param {documentId}
     */
    deleteDocument(documentId) {
      this.$apollo
        .mutate({
          mutation: DELETE_ORDER_DOCUMENT,
          variables: {
            id: documentId,
            orderId: this.order.id
          }
        })
        .then(({ data }) => {
          const error = this.$dig(data, "deleteOrderDocument", "error");
          if (error) {
            this.$swal.fire({
              title: "Eliminación fallida",
              text: `El documento no pudo eliminarse: ${error}`,
              icon: "error"
            });
          } else {
            this.$swal.fire({
              title: "Eliminación exitosa",
              text: "El documento ha sido eliminado",
              icon: "success"
            });
            let tempDocuments = [...this.documents];
            let i = tempDocuments.map(e => e.id).indexOf(documentId);
            tempDocuments.splice(i, 1);
            this.documents = tempDocuments;
          }
          this.deleting[documentId] = false;
        })
        .catch(error => {
          this.$swal.fire({
            title: "Eliminación fallida",
            text: `El documento no pudo eliminarse: ${error}`,
            icon: "error"
          });
          this.deleting[documentId] = false;
        });
    },
    async uploadFile() {
      if (!this.newFile || !this.documentType) {
        this.$swal.fire(
          "Problemas",
          "Debes seleccionar un archivo y un tipo de documento.",
          "warning"
        );
        return;
      }
      const file = this.newFile;
      let fileContents = "";
      const readFile = file => {
        var read = new FileReader();
        return new Promise(resolve => {
          read.onload = () => {
            resolve(read.result);
          };
          read.readAsDataURL(file);
        });
      };
      const uploadFileData = async file => {
        fileContents = await readFile(file);
      };
      await uploadFileData(file);
      const params = {
        orderId: this.order.id,
        documentType: this.documentType,
        file: fileContents,
        fileName: this.newFile.name,
        number: this.number
      };
      await createOrderDocument(this.$apollo, params)
        .then(result => {
          let document = result.data.createOrderDocument.document;
          let valid = result.data && document;
          if (valid) {
            this.$swal.fire({
              title: "Solicitud exitosa",
              text: "Se ha creado el documento exitosamente",
              icon: "success",
              confirmButtonText: "Aceptar"
            });
            let dcs = this.documents.map(x => x);
            dcs.push(document);
            this.documents = dcs;
            this.deleting[document.id] = false;
          } else {
            this.$swal.fire({
              title: "Solicitud fallida",
              text: result.data.createOrderDocument.error,
              icon: "error",
              confirmButtonText: "Aceptar"
            });
          }
        })
        .catch(() => {
          this.$swal.fire({
            title: "Solicitud fallida",
            text: "Ha fallado la creacion del documento",
            icon: "error",
            confirmButtonText: "Aceptar"
          });
        })
        .finally(() => {
          this.documentType = null;
          this.newFile = null;
          this.number = null;
        });
    }
  },
  watch: {
    order(val) {
      this.documents = val.documents;
    }
  }
};
</script>

<style scoped></style>
