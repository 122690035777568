<template>
  <div>
    <base-header
      title="Mercado Envios 1"
      title-link="#"
      :map="['Mercado Libre', 'Integraciones']"
      :title-size="12"
    >
    </base-header>

    <mercado-libre-select-integration
      url-name="MercadoLibreMe1List"
      :filter-function="shippingModeMe1"
    />
  </div>
</template>

<script>
import BaseHeader from "../../../components/BaseHeader.vue";
import MercadoLibreSelectIntegration from "../../../components/MercadoLibre/SelectIntegration.vue";
import INTEGRATION_CONFIG_MERCADO_LIBRE from "../../../graphql/MercadoLibre/Me1/IntegrationConfigMercadoLibre.gql";
export default {
  name: "MercadoLibreMe1Integrations",
  components: {
    BaseHeader,
    MercadoLibreSelectIntegration
  },
  methods: {
    /**
     * Consulta si el shipping mode de la integracion es de tipo Me1
     * @param {String} ic_id
     * @return {Boolean}
     */
    async shippingModeMe1(ic_id) {
      let result = await this.$apollo.query({
        query: INTEGRATION_CONFIG_MERCADO_LIBRE,
        variables: { id: ic_id }
      });
      result = await result.data.integrationConfigMercadoLibre.shippingModes;
      return result.includes("me1");
    }
  }
};
</script>
