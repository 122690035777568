<template>
  <div>
    <ApolloQuery
      :query="require('../graphql/AllOrders.gql')"
      :variables="queryVariables('')"
      :fetchPolicy="'cache-and-network'"
      clientId="apolloClientCached"
      notifyOnNetworkStatusChange
    >
      <template slot-scope="{ result: { error, data }, query, isLoading }">
        <!-- Loading -->
        <b-spinner v-if="!data && isLoading" label="Spinning"></b-spinner>

        <span v-else-if="data && isLoading" class="m-2 float-right">
          Actualizando lista de pedidos...
        </span>
        <!-- Result -->
        <b-table-simple hover responsive>
          <b-thead>
            <b-tr>
              <b-th> </b-th>
              <b-th>Origen</b-th>
              <b-th>N° de Orden</b-th>
              <b-th v-if="configColumns.client">Cliente</b-th>
              <b-th>Fecha creación</b-th>
              <b-th v-if="configColumns.dateForDelivery"
                >Compromiso de entrega</b-th
              >
              <b-th>Estado</b-th>
              <b-th v-if="configColumns.shippingLabel">Etiqueta impresa</b-th>
              <b-th v-if="configColumns.total">Total</b-th>
              <b-th v-if="configColumns.items">Items</b-th>
              <b-th v-if="configColumns.modifyStock" style="width: 150px"
                >Fulfillment</b-th
              >
              <b-th v-if="configColumns.priorityShipping"
                >Envio prioritario</b-th
              >
              <b-th />
            </b-tr>
            <b-tr>
              <b-td><order-select-all></order-select-all></b-td>
              <b-th>
                <b-form-group>
                  <v-select
                    v-model="integrationsSelected"
                    placeholder="Todos"
                    :options="allOrigins"
                    multiple=""
                    :reduce="origin => origin.value"
                  ></v-select>
                </b-form-group>
              </b-th>
              <b-th>
                <b-form-group>
                  <b-form-tags
                    v-model="ordersNumbersSelected"
                    separator=", "
                    placeholder="N° de orden"
                    size="sm"
                  ></b-form-tags>
                </b-form-group>
              </b-th>
              <b-th v-if="configColumns.client">
                <b-form-group>
                  <b-form-tags
                    v-model="buyersSelected"
                    separator=", "
                    placeholder="Cliente"
                    size="sm"
                  ></b-form-tags>
                </b-form-group>
              </b-th>
              <b-th>
                <b-form-group>
                  <date-picker
                    v-model="dateSelected"
                    type="date"
                    value-type="format"
                    range
                  >
                  </date-picker>
                </b-form-group>
              </b-th>
              <b-th v-if="configColumns.dateForDelivery"></b-th>
              <b-th>
                <b-form-group>
                  <v-select
                    v-model="statusesSelected"
                    placeholder="Todos"
                    :options="ordersStatuses"
                    multiple=""
                    :reduce="status => status.value"
                  ></v-select>
                </b-form-group>
              </b-th>
              <b-th v-if="configColumns.shippingLabel">
                <b-form-group>
                  <v-select
                    v-model="shippingLabelSelected"
                    placeholder="Todos"
                    :options="labelPrintOptions"
                    :reduce="shipping => shipping.value"
                  ></v-select>
                </b-form-group>
              </b-th>
              <b-th v-if="configColumns.total"></b-th>
              <b-th v-if="configColumns.items">
                <b-form-group>
                  <b-form-tags
                    input-id="tags-sku"
                    v-model="skusSelected"
                    separator=", "
                    placeholder="SKU"
                    size="sm"
                  ></b-form-tags>
                </b-form-group>
              </b-th>
              <b-th v-if="configColumns.modifyStock">
                <b-form-group>
                  <v-select
                    v-model="typesSelected"
                    placeholder="Todos"
                    :options="ordersType"
                    :reduce="status => status.value"
                  ></v-select>
                </b-form-group>
              </b-th>
              <b-th v-if="configColumns.priorityShipping">
                <b-form-group>
                  <v-select
                    v-model="priorityShippingSelected"
                    placeholder="Todos"
                    :options="priorityShippingOptions"
                    :reduce="shipping => shipping.value"
                  ></v-select>
                </b-form-group>
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody v-if="data && data.allOrders">
            <b-tr v-if="!data.allOrders.edges.length">
              <b-td colspan="11">No tienes pedidos registrados</b-td>
            </b-tr>
            <orders-table-row
              v-for="order of data.allOrders.edges"
              :key="order.node.id"
              :order="order.node"
              :configColumns="configColumns"
            ></orders-table-row>
          </b-tbody>
        </b-table-simple>
        <!-- Error -->
        <b-alert v-if="error" show variant="light" class="text-center">
          Error al obtener los pedidos. Reintentar
          <b-link
            @click="
              query.setOptions({ fetchPolicy: 'no-cache' });
              query.refetch();
            "
            >aqui.</b-link
          >
        </b-alert>
        <b-spinner
          v-if="isLoading"
          label="Spinning"
          class="m-2 float-right"
        ></b-spinner>
        <div v-else-if="!error">
          <b-button
            v-if="hasNextPage(data)"
            class="m-2 float-right"
            @click="seeMore(query, data.allOrders.pageInfo.endCursor)"
          >
            Ver más
          </b-button>
          <b-alert v-else show variant="light" class="text-center">
            No hay más datos para mostrar.
          </b-alert>
        </div>
      </template>
    </ApolloQuery>
  </div>
</template>
<script>
import OrdersTableRow from "./OrdersTableRow.vue";
import ORDER_FILTER_INDEXES from "../graphql/OrderFilterIndexes.gql";
import { mapState, mapMutations } from "vuex";
import OrderSelectAll from "@/components/order/OrderSelectAll";
export default {
  name: "OrdersTable",
  components: {
    OrderSelectAll,
    OrdersTableRow
  },
  model: {
    prop: "params",
    event: "change"
  },
  props: {
    params: {
      type: Object,
      default() {
        return {};
      }
    },
    configColumns: {
      type: Object
    }
  },
  data() {
    return {
      originalFromDate: null,
      originalToDate: null,
      buyersSelected: null,
      dateSelected: [null, null],
      integrationsSelected: [],
      ordersNumbersSelected: [],
      statusesSelected: [],
      typesSelected: "",
      shippingLabelSelected: null,
      skusSelected: null,
      priorityShippingSelected: null,
      allOrigins: [],
      labelPrintOptions: [
        { label: "SI", value: true },
        { label: "NO", value: false }
      ],
      priorityShippingOptions: [
        { label: "SI", value: true },
        { label: "NO", value: false }
      ],
      ordersStatuses: [
        { label: "PENDIENTES", value: "pending" },
        { label: "ENVIADOS", value: "shipped" },
        { label: "ENTREGADOS", value: "received" },
        { label: "ANULADOS", value: "cancelled" },
        { label: "DESCONOCIDOS", value: "unknown" }
      ],
      ordersType: [
        { label: "Todos", value: null },
        { label: "Fulfillment", value: "fulfillment" },
        { label: "No Fulfillment", value: "no-fulfillment" }
      ],
      configColumnsLabel: "columnsbuttons"
    };
  },
  mounted() {
    this.ordersNumbersSelected = this.filters["numbers"] || [];
    this.buyersSelected = this.filters["buyers"] || [];
    this.dateSelected = [
      this.filters["createFrom"] || null,
      this.filters["createTo"] || null
    ];

    this.integrationsSelected = this.filters["origins"] || [];
    this.statusesSelected = this.filters["statuses"] || [];
    this.typesSelected = this.filters["order_type"] || "";
    this.shippingLabelSelected =
      typeof this.filters["shippingLabelPrint"] == "boolean"
        ? this.filters["shippingLabelPrint"]
        : null;
    this.skusSelected = this.filters["skus"] || [];
    this.orderFilterIndexes();
  },

  computed: {
    ...mapState(["filters", "currentUser"])
  },
  methods: {
    ...mapMutations(["setFilter"]),
    cleanFilters() {
      this.setFilter({
        numbers: [],
        skus: [],
        statuses: [],
        origins: [],
        createFrom: null,
        createTo: null,
        shippingLabelPrint: null,
        buyers: null,
        order_type: "all"
      });
    },
    orderFilterIndexes() {
      this.$apollo
        .query({
          query: ORDER_FILTER_INDEXES
        })
        .then(result => {
          this.allOrigins =
            result.data.orderFilterIndexes?.origins?.map(o => {
              return { label: o, value: o };
            }) || [];
        });
    },
    /**
     * Se encarga de crear un objeto con
     * las variables para la query
     * @method
     * @param {String} cursor
     * @param {Object} variables
     */
    queryVariables(cursor, variables = {}) {
      if (cursor) {
        variables.cursor = cursor;
      }
      if (this.filters.numbers?.length) {
        variables.numbers = this.filters.numbers;
      }
      if (this.filters.ids?.length) {
        variables.ids = this.filters.ids;
      }
      if (this.filters.skus?.length) {
        variables.skus = this.filters.skus;
      }
      if (this.filters.statuses?.length) {
        variables.statuses = this.filters.statuses;
      }
      if (this.filters.origins?.length) {
        variables.origins = this.filters.origins;
      }
      if (this.filters.createFrom) {
        variables.createFrom = this.filters.createFrom;
      }
      if (this.filters.createTo) {
        variables.createTo = this.filters.createTo;
      }
      if (this.filters.updateFrom) {
        variables.updateFrom = this.filters.updateFrom;
      }
      if (this.filters.updateTo) {
        variables.updateTo = this.filters.updateTo;
      }
      if (this.filters.shippingLabelPrint) {
        variables.shippingLabelPrint = this.filters.shippingLabelPrint;
      }
      if (this.filters.buyers?.length) {
        variables.buyers = this.filters.buyers;
      }
      if (this.filters.order_type) {
        variables.orderType = this.filters.order_type;
      }
      if (this.filters.priorityShipping) {
        variables.priorityShipping = this.filters.priorityShipping;
      }
      return variables;
    },
    /**
     * Indica si hay una página siguiente en la query de GraphQl basado
     * exclusivamente en la existencia de un cursor.
     * @return {boolean}
     */
    hasNextPage(data) {
      return data?.allOrders?.pageInfo?.endCursor != null;
    },
    /**
     * Trae más resultados de una query avanzando en su cursor.
     * @param {Object} query
     * @param {String} cursor
     */
    async seeMore(query, cursor) {
      await query.fetchMore({
        variables: this.queryVariables(cursor),
        updateQuery: (prev, { fetchMoreResult }) => {
          const updated = Object.assign({}, this.$dup(prev));
          updated.allOrders.pageInfo = fetchMoreResult.allOrders.pageInfo;
          if (fetchMoreResult?.allOrders?.edges?.length !== 0) {
            updated.allOrders.edges.push(...fetchMoreResult.allOrders.edges);
          }
          return updated;
        }
      });
    },
    /**
     * Modifica el rango horario según zona horaria para consultar pedidos.
     * @param {Array<Date>} range
     * @returns {Array<String>}
     */
    setTimeZone(range) {
      if (range[0] == null) return range;

      const timeZone = this.currentUser.company.timeZoneName;
      const startDate = range[0]
        ? this.$moment
            .tz(range[0], timeZone)
            .startOf("day")
            .format()
        : null;
      const endDate = range[1]
        ? this.$moment
            .tz(range[1], timeZone)
            .endOf("day")
            .format()
        : null;

      return [startDate, endDate];
    }
  },
  watch: {
    buyersSelected(newValue) {
      this.setFilter({ buyers: newValue });
    },
    dateSelected(newValueSelected) {
      let newValue = this.setTimeZone(newValueSelected);
      this.setFilter({ createFrom: newValue[0] });
      this.setFilter({ createTo: newValue[1] });
      if (newValue[0] == null) {
        this.setFilter({
          createFrom: null
        });
      }
      if (newValue[1] == null) {
        this.setFilter({ createTo: null });
      }
      this.params.createFrom = this.filters.createFrom;
      this.params.createTo = this.filters.createTo;
    },
    filters(newValue, prevValue) {
      if (!this.$objDeepCompare(prevValue, newValue)) {
        this.$emit("change", this.filters);
      }
    },
    integrationsSelected(newValue) {
      this.setFilter({ origins: newValue });
    },
    ordersNumbersSelected(newValue) {
      this.setFilter({ numbers: newValue });
    },
    shippingLabelSelected(newValue) {
      this.setFilter({ shippingLabelPrint: newValue });
    },
    priorityShippingSelected(newValue) {
      this.setFilter({ priorityShipping: newValue });
    },
    statusesSelected(newValue) {
      this.setFilter({ statuses: newValue });
    },
    typesSelected(newValue) {
      this.setFilter({ order_type: newValue });
    },
    skusSelected(newValue) {
      this.setFilter({ skus: newValue });
    }
  }
};
</script>

<style scoped>
.btn-sm {
  margin: 5px;
}
</style>
