<template>
  <div>
    <b-row>
      <b-col xl="4" md="6" cols="12" class="pr-0">
        <h4 class="font-weight-bold my-auto">Marketplaces</h4>
        <hr />
        <div v-if="isLoading">
          <div v-for="i of 5" :key="i" class="px-1">
            <integration-card :is-loading="true" />
          </div>
        </div>
        <div v-else>
          <div v-for="mk of marketplaces" :key="mk.id" class="px-1">
            <integration-card :integration="mk" v-model="selected[mk.id]" />
          </div>
        </div>
      </b-col>
      <b-col xl="4" md="6" cols="12" class="px-0">
        <h4 class="font-weight-bold my-auto pl-3">Ecommerce</h4>
        <hr />
        <div v-if="isLoading">
          <div v-for="i of 3" :key="i" class="px-1">
            <integration-card :is-loading="true" />
          </div>
        </div>
        <div v-else>
          <div v-for="ec of ecommerces" :key="ec.id" class="px-1">
            <integration-card :integration="ec" v-model="selected[ec.id]" />
          </div>
        </div>
      </b-col>
      <b-col xl="4" md="6" cols="12" class="pl-0">
        <h4 class="font-weight-bold my-auto pl-3">Otros</h4>
        <hr />
        <div v-if="isLoading">
          <div v-for="i of 2" :key="i" class="px-1">
            <integration-card :is-loading="true" />
          </div>
        </div>
        <div v-else>
          <div v-for="ot of others" :key="ot.id" class="px-1">
            <integration-card
              :integration="ot"
              v-model="selected[ot.id]"
              other
            />
          </div>
          <div class="px-1">
            <integration-card
              v-model="selected['Webhooks']"
              subtitle="Sólo para enviar notificaciones a tus webhooks de
                      productos."
              label=" Webhooks de productos"
              button-label=" Administrar webhooks"
              link="/integrations/webhooks"
              other
            />
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import IntegrationCard from "./IntegrationCard.vue";
import { centryUrl } from "../../../main";
export default {
  name: "IntegrationsList",
  components: {
    IntegrationCard
  },
  props: {
    company: Object,
    integrations: Array,
    isLoading: {
      type: Boolean,
      default: false
    },
    selectAll: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      centryUrl,
      selected: {
        Webhooks: false
      }
    };
  },
  computed: {
    marketplaces() {
      return this.integrations.filter(
        ic => ic?.publicIntegrationInformation?.category === "marketplace"
      );
    },
    ecommerces() {
      return this.integrations.filter(
        ic => ic?.publicIntegrationInformation?.category === "ecommerce"
      );
    },
    others() {
      return this.integrations.filter(
        ic =>
          ic?.publicIntegrationInformation?.category !== "ecommerce" &&
          ic?.publicIntegrationInformation?.category !== "marketplace"
      );
    }
  },
  watch: {
    selected: {
      handler(val) {
        this.$emit("change", val);
      },
      deep: true
    },
    selectAll(val) {
      if (!val) {
        this.selected = {
          Webhooks: false
        };
      } else {
        let tempSelected = { Webhooks: true };
        this.integrations.forEach(ic => {
          tempSelected[ic.id] = ic.available;
        });
        this.selected = this.$dup(tempSelected);
      }
    }
  }
};
</script>
