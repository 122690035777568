<template>
  <b-card bg-variant="light" class="text-center">
    <b-card-text>
      <h3>{{ title }}</h3>
      <b-row class="justify-content-md-center mt-2" align-v="stretch">
        <h4 style="display: flex; align-items: center" v-if="currency">
          {{ currency.toUpperCase() }}
        </h4>
        <h1>{{ numberWithDots(amount) }}</h1>
      </b-row>
      <p class="h1" v-if="percentage < 0">
        <b-icon icon="arrow-down-short" variant="danger"></b-icon>
        <span class="h2 text-danger font-weight-bold">{{ percentage }}% </span>
      </p>
      <p class="h1" v-else-if="percentage > 0">
        <b-icon icon="arrow-up-short" variant="success"></b-icon>
        <span class="h2 text-success font-weight-bold">{{ percentage }}% </span>
      </p>
      <p class="h2 text-muted font-weight-bold" v-else-if="percentage == 0">
        = {{ percentage }}%
      </p>
    </b-card-text>
  </b-card>
</template>

<script>
export default {
  name: "SummaryMessageSalesReport",
  props: {
    title: String,
    currency: String,
    amount: String,
    percentage: String,
    widget: Object
  },
  data() {
    return {};
  },
  methods: {
    numberWithDots(x) {
      if (!x) return null;
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
  }
};
</script>
