<template>
  <div>
    <div>
      <base-header
        title="Seleccionar grupo"
        :title-link="titleLink"
        :map="[
          'Mercado Libre',
          'Atributos',
          'Grupos',
          attributeGroupIntegrationConfigName
        ]"
        :title-size="12"
      >
      </base-header>

      <b-spinner label="Spinning" v-if="loading"></b-spinner>
      <mercado-libre-attributes-table
        v-else
        :attribute-group-integration-config="attributeGroupIntegrationConfig"
      >
      </mercado-libre-attributes-table>
    </div>
  </div>
</template>

<script>
import BaseHeader from "@/components/BaseHeader.vue";
import MercadoLibreAttributesTable from "@/components/MercadoLibreAttributesTable";
import INTEGRATIONS_MERCADO_LIBRE_ATTRIBUTE_GROUP_INTEGRATION_CONFIG from "../graphql/IntegrationsMercadoLibreAttributeGroupIntegrationConfig.gql";
export default {
  name: "MercadoLibreAttributes",
  components: {
    MercadoLibreAttributesTable,
    BaseHeader
  },
  props: {
    attributeGroupIntegrationConfigId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      attributeGroupIntegrationConfig: {}
    };
  },
  beforeMount() {
    this.getAttributeGroupIntegrationConfig();
  },
  methods: {
    getAttributeGroupIntegrationConfig() {
      this.$apollo
        .query({
          query: INTEGRATIONS_MERCADO_LIBRE_ATTRIBUTE_GROUP_INTEGRATION_CONFIG,
          variables: { id: this.attributeGroupIntegrationConfigId }
        })
        .then(result => {
          this.attributeGroupIntegrationConfig =
            result.data.integrationsMercadoLibreAttributeGroupIntegrationConfig;
          this.loading = false;
        });
    }
  },
  computed: {
    attributeGroupIntegrationConfigName() {
      return this.$ifNull(
        this.$dig(
          this.attributeGroupIntegrationConfig,
          "attributeGroup",
          "name"
        ),
        "loading"
      );
    },
    titleLink() {
      let integrationConfigId = this.$dig(
        this.attributeGroupIntegrationConfig,
        "integrationConfig",
        "id"
      );

      if (!integrationConfigId) return "loading";
      return "/mercadolibre/attributes/" + integrationConfigId + "/groups";
    }
  }
};
</script>

<style scoped>
.table td,
.table th {
  height: inherit;
}
</style>
