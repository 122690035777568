<template>
  <b-card no-body>
    <template #header>
      <b-row align-v="center">
        <b-col sm="12" md="4">
          <h4 class="mb-0">
            <router-link
              :to="{
                name: 'Messages Scheduled Edit',
                params: { id: messageScheduled.id }
              }"
            >
              {{ messageScheduled.name }}
            </router-link>
          </h4>
        </b-col>
        <b-col sm="12" md="4">
          Del
          {{ messageScheduled.endDate | formatDate }}
          al
          {{ messageScheduled.endDate | formatDate }}
        </b-col>
        <b-col sm="12" md="3">
          Afectando a {{ messageScheduled.companyIds.length }} empresas
        </b-col>
        <b-col sm="12" md="1">
          <b-button
            variant="danger"
            @click="deleteMessageScheduled"
            class="float-right"
          >
            <b-icon-trash />
          </b-button>
        </b-col>
      </b-row>
    </template>

    <b-card-body v-html="messageScheduled.template" />
  </b-card>
</template>
<script>
import DELETE from "@/graphql/Messages/DeleteScheduled.gql";

export default {
  name: "ListItem",
  props: {
    messageScheduled: Object
  },
  methods: {
    /**
     * Elimina el mensaje programado. Si ocurre un error, muestra un mensaje
     * de alerta. Si no, emite el evento "deleted" con el id del mensaje.
     */
    deleteMessageScheduled() {
      this.$apollo
        .mutate({
          mutation: DELETE,
          variables: { id: this.messageScheduled.id }
        })
        .then(({ data }) => {
          if (data.messagesDeleteScheduled.error) {
            alert(data.messagesDeleteScheduled.error);
          } else {
            this.$emit("deleted", this.messageScheduled.id);
          }
        });
    }
  }
};
</script>
