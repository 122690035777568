<template>
  <b-row>
    <b-col md="2">
      <b-form-group label="Desde">
        <base-date-time type="datetime" v-model="createdAtGte"></base-date-time>
      </b-form-group>
    </b-col>
    <b-col md="2">
      <b-form-group label="Hasta">
        <base-date-time type="datetime" v-model="createdAtLte"></base-date-time>
      </b-form-group>
    </b-col>
    <b-col md="2">
      <b-form-group label="Origen">
        <v-select
          v-model="origins"
          :options="allOrigins"
          :reduce="x => x.value"
          placeholder="Seleccionar"
          :multiple="true"
        ></v-select>
      </b-form-group>
    </b-col>
    <b-col md="2">
      <b-form-group label="Integración">
        <v-select
          v-model="publicApplicationInformationIds"
          :options="allPublicApplicationInformations"
          placeholder="Seleccionar"
          :multiple="true"
          :reduce="x => x.value"
        ></v-select>
      </b-form-group>
    </b-col>
    <b-col md="2">
      <b-form-group label="Empresa">
        <v-select
          v-model="companyIds"
          :options="allCompanies"
          placeholder="Seleccionar"
          :reduce="x => x.value"
          :multiple="true"
        ></v-select>
      </b-form-group>
    </b-col>
    <b-col md="2">
      <b-button @click="filter">Filtrar</b-button>
    </b-col>
  </b-row>
</template>
<script>
import BaseDateTime from "@/components/BaseDateTime";
import { mapMutations } from "vuex";
import ALL_PUBLIC_APPLICATION_INFORMATION from "@/graphql/AllPublicApplicationInformations.gql";
import ADMIN_COMPANIES from "@/graphql/AdminCompanies.gql";
export default {
  name: "SynchronizationMonitoringFilters",
  components: { BaseDateTime },
  mounted() {
    this.$getAllPages(
      ALL_PUBLIC_APPLICATION_INFORMATION,
      {},
      "allPublicApplicationInformations"
    ).then(all => {
      this.allPublicApplicationInformations = all.map(elem => {
        return { label: elem.node.name, value: elem.node.id };
      });
    });
    this.$getAllPages(ADMIN_COMPANIES, {}, "adminCompanies").then(all => {
      this.allCompanies = all.map(elem => {
        return { label: elem.node.name, value: elem.node.id };
      });
    });
  },
  data() {
    return {
      createdAtGte: null,
      createdAtLte: null,
      origins: [],
      allOrigins: [
        { value: "api_bulk_update", label: "Actualización por API" },
        { value: "bulk_upload", label: "Carga Masiva" },
        { value: "order_save", label: "Pedido" },
        { value: "unknown", label: "Otro" }
      ],
      publicApplicationInformationIds: [],
      allPublicApplicationInformations: [],
      companyIds: [],
      allCompanies: []
    };
  },
  methods: {
    ...mapMutations(["setFilter"]),
    filter() {
      this.setFilter({
        createdAtGte: this.createdAtGte,
        createdAtLte: this.createdAtLte,
        origins: this.origins,
        publicApplicationInformationIds: this.publicApplicationInformationIds,
        companyIds: this.companyIds
      });
    }
  }
};
</script>
<style scoped>
.btn {
  margin-top: 20px;
}
</style>
