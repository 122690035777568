<template>
  <b-tr>
    <b-td>{{ this.$dig(stockDestination.variant, "product", "name") }}</b-td>
    <b-td>{{ this.$dig(stockDestination.variant, "sku") }}</b-td>
    <b-td>{{ stockDestination.centryQuantity }}</b-td>
    <b-td>{{ stockDestination.platformQuantity }}</b-td>
    <b-td>{{
      stockDestination.centryQuantity - stockDestination.platformQuantity
    }}</b-td>
    <b-td>{{ stockDestination.platformUpdatedAt }}</b-td>
    <b-td class="actioncell">
      <stock-destinations-table-row-actions
        :stockDestination="stockDestination"
      ></stock-destinations-table-row-actions>
    </b-td>
  </b-tr>
</template>

<script>
import StockDestinationsTableRowActions from "./StockDestinationsTableRowActions";
export default {
  name: "StockDestinationsTableRow",
  components: {
    StockDestinationsTableRowActions
  },
  props: {
    stockDestination: Object
  }
};
</script>
