<template>
  <b-overlay :show="saving" no-center rounded="sm">
    <template #overlay>
      <div class="text-center">
        <b-icon icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
        <p id="cancel-label">
          NO CIERRES ESTA PÁGINA!, estamos guardando los datos todavía...
        </p>
        <p>{{ progress }} %</p>
      </div>
    </template>
    <div>
      <b-alert v-if="saveStatus" v-model="saved" variant="success" dismissible>
        Las tarifas fueron actualizadas correctamente.
      </b-alert>
      <b-alert v-else v-model="saved" variant="danger" dismissible>
        No se pudo actualizar por el siguiente motivo:
        <ul>
          <li v-for="(error, index) in errorMessages" :key="index">
            {{ error }}
          </li>
        </ul>
      </b-alert>
      <b-alert
        v-if="csvNotUsedYet"
        v-model="csvNotUsedYet"
        variant="warning"
        dismissible
      >
        ¡Aprovecha la opción de subir un archivo csv! ¡Actualiza tus datos
        rápidamente!
        <p>
          <b>Pero recuerda la información se sobreescribe completamente</b>.
        </p>
      </b-alert>
      <b-row align-v="center">
        <b-col md="5">
          <b-button variant="info" :disabled="!changed" @click="save">
            <b-icon-cloud-upload></b-icon-cloud-upload> Guardar
          </b-button>
          <b-button
            class="ml-2"
            variant="info"
            :disabled="!changed"
            @click="restartData"
          >
            <b-icon-arrow-clockwise></b-icon-arrow-clockwise> Restablecer
          </b-button>
        </b-col>
        <b-col>
          <base-csv-reader @ready="setData" />
        </b-col>
      </b-row>

      <base-j-excel-table
        :columns="columns"
        v-model="currentRows"
        :allow-insert-column="false"
        :allow-insert-row="true"
        :allow-delete-row="true"
        :column-sorting="false"
        :pagination="200"
        :skipValidation="true"
        @change="onChange"
        :custom-on-change="reviewFormatValue"
        :allow-search="true"
        ref="spreadsheet"
      ></base-j-excel-table>
    </div>
  </b-overlay>
</template>
<script>
import BaseJExcelTable from "../../BaseJExcelTable.vue";
import BaseCsvReader from "../../Base/CsvReader.vue";
import UPSERT_DESTINATION_PRICE from "../../../graphql/MercadoLibre/DynamicShipping/CreateAndEditDestinationPrices.gql";
import DELETE_DESTINATION_PRICE from "../../../graphql/MercadoLibre/DynamicShipping/DeleteDestinationPrices.gql";

export default {
  name: "MercadoLibreCatalogSuggestionsTable",
  components: {
    BaseJExcelTable,
    BaseCsvReader
  },
  props: {
    integrationConfigId: String,
    destinationPrices: Array,
    regions: Array,
    cities: Object,
    values: Array
  },
  data() {
    const existingDestinationPricesHash = this.calculateDestinationPriceHash(
      this.destinationPrices.map(elem => elem.node)
    );
    const rows = this.prepareRows(this.destinationPrices);
    return {
      saving: false,
      totalPages: 1,
      progressSaving: 0,
      progressDeleting: 0,
      totalPagesDeleting: 1,
      saved: false,
      deleteBucketSize: 600,
      columns: [
        {
          type: "dropdown",
          title: "Región",
          width: 150,
          source: this.regions
        },
        {
          type: "dropdown",
          title: "Ciudad",
          width: 150,
          source: this.cities.all,
          filter: this.dropdownFilter
        },
        {
          type: "numeric",
          title: "Peso Máximo (kg)",
          width: 150
        },
        {
          type: "numeric",
          title: "Precio Flete",
          width: 200
        },
        {
          type: "numeric",
          title: "Precio por kg excedente",
          width: 200
        },
        {
          type: "numeric",
          title: "Tiempo de Preparación  (días)",
          width: 200
        },
        {
          type: "numeric",
          title: "Tiempo de Envío (días)",
          width: 200
        }
      ],
      currentRows: rows,
      oldRows: this.prepareRows(this.destinationPrices),
      existingDestinationPricesHash,
      errorUpsert: null,
      errorDeleting: null,
      errorsValidating: [],
      changed: false,
      withChangesRows: {},
      bucketSize: 200,
      csvNotUsedYet: true
    };
  },
  computed: {
    progress() {
      return Math.trunc(
        ((this.progressSaving + this.progressDeleting) * 100.0) /
          (this.totalPages + this.totalPagesDeleting)
      );
    },
    /**
     * Validaciones
     * Se validan los siguientes puntos
     * i) No pueden haber valores vacios. (No se revisa columna precio excedente)
     * ii) Los valores numericos no pueden ser negativos.
     * iii) La Region debe ser válida
     * iv) la ciudad debe pertenecer a Region
     * v)  tupla (region, ciudad, peso maximo) debe ser unico para cualquier fila
     * vi) Solo el ultimo peso maximo de la region-ciudad
     *     puede tener asociado un precio por kg excedente
     */
    validations() {
      return [
        [this.notNullValues, "Valor vació"],
        [this.validateOnlyPositivesValues, "Solo valores numericos positivos"],
        [this.validateRegion, "La región no es válida"],
        [
          this.validateCityInRegion,
          "La Comuna no corresponde a la Región Señalada"
        ]
      ];
    },
    /**
     * Indica si el guardado salió o no correctamente
     */
    saveStatus() {
      return !this.errorMessages.length;
    },
    /**
     * Arreglo de todos los errores
     */
    errorMessages() {
      let errors = [];
      if (this.errorUpsert) {
        errors.push(this.errorUpsert);
      }
      if (this.errorDeleting) {
        errors.push(this.errorDeleting);
      }
      if (this.errorsValidating.length) {
        errors = errors.concat(this.errorsValidating);
      }
      return errors;
    }
  },
  methods: {
    /**
     * Valida que todos los valores numericos sean positivos
     * @param {Number} x (columna)
     * @param {String | Number} value
     * @returns {Boolean}
     */
    validateOnlyPositivesValues(x, value) {
      if (x < 2 || x == 4 || x == 5) return true;
      return this.number(value) > 0;
    },
    /**
     * Valida que la comuna corresponde a la region señalada
     * @param {Integer} x
     * @param {String} value
     * @param {Array} currentRow
     */
    validateCityInRegion(x, value, currentRow) {
      if (x !== 1) return true;
      const region = currentRow[0];
      if (!this.regions.includes(region)) return false;
      return this.cities[region].includes(value);
    },
    /**
     * Valida que la region ingresada sea real y valida.
     * @param {Integer} x
     * @param {String} value
     * @returns {Boolean}
     */
    validateRegion(x, value) {
      if (x !== 0) return true;
      return this.regions.includes(value);
    },
    /**
     * Verifica que los campos no sean nulos o vacios
     * @param {Integer} x
     * @param {String} value
     * @returns {Boolean}
     */
    notNullValues(x, value) {
      if (x === 4 || x === 5) return true;
      return !!value && (value + "").length;
    },
    /**
     * Metodo que verifica todas las validaciones
     * @param {Number} x
     * @param {String} value
     * @param {Array} row
     * @returns {Array<String>}
     */
    validationFunction(x, value, row) {
      const errors = [];
      this.validations.map(validation => {
        const f = validation[0];
        const msg = validation[1];
        let valid = f(x, value, row);
        if (!valid) {
          errors.push(msg);
        }
      });
      return errors.join(", ");
    },
    /**
     * Se valida una fila
     * @param {Array} row
     * @returns {Array<String>}
     */
    validateRow(row) {
      const errors = [];
      for (let x = 0; x < 7; x++) {
        const value = row[x];
        const error = this.validationFunction(x, value, row);
        if (error && error.length) {
          errors.push(error);
        }
      }
      return errors;
    },
    /**
     * Obtiene un diccionario de key => id de los destinationPrices
     * existentes
     * @param {Array<Object>} destinationPrices
     * @returns {Object}
     */
    calculateDestinationPriceHash(destinationPrices) {
      const hash = {};
      destinationPrices.forEach(elem => {
        hash[this.destinationPriceKey(elem)] = elem.id;
      });
      return hash;
    },
    /**
     * Setea la data de la planilla
     * @param {Array<Array>} data
     */
    setData(data) {
      this.csvNotUsedYet = false;
      this.onChange();
      this.currentRows = data;
    },
    /**
     * retorna la versión numérica del
     * valor en elem
     * @param {String | Number} number
     * @returns {Number}
     */
    number(elem) {
      let str = elem + "";
      str = str
        .replace("$", "")
        .replace(".", "")
        .replace(",", ".")
        .trim();
      return Number(str);
    },
    /**
     * Constuye Objeto particular para una fila del jexcel
     * @param {Array} values
     * @return {Object}
     */
    buildObjectDestinationPrice(values) {
      const body = {};
      body.destination = `${values[0]}/${values[1]}`;
      body.maxWeight = this.number(values[2]);
      body.price = this.number(values[3]);
      body.excedentPrice = this.number(values[4]);
      body.handlingTime = this.number(values[5]);
      body.shippingTime = this.number(values[6]);
      body.id = this.existingDestinationPricesHash[
        this.destinationPriceKey(body)
      ];
      return body;
    },
    /**
     * Entrega el valor de la llave para un destination price
     * @param {Object} destinationPrice
     * @return {String}
     */
    destinationPriceKey(destinationPrice) {
      return `${destinationPrice.destination}/${destinationPrice.maxWeight}`;
    },
    /**
     * Quita cambios realizados en la tabla
     * y se vuelve a los valores originales
     */
    restartData() {
      this.currentRows = this.$dup(this.oldRows);
      this.destinationPricesIdsForDelete = [];
      this.destinationPricesForUpsert = [];
      this.$refs.spreadsheet.reset();
    },
    /**
     * Prepara las filas, en base al arreglo data.
     * Si no hay nada en data, se entrega el equivalente a una fila vacía
     * en el excel
     * @param {Array<Object>} data
     * @returns {Array<Array>}
     */
    prepareRows(data) {
      var rows = [];
      data.map(destinationPrice => {
        const dp = destinationPrice.node;
        rows.push(this.buildRowFromDestinationPrice(dp));
      });
      return rows.length ? rows : [["", "", null, null, null, null, null]];
    },
    /**
     * Construye fila para tabla
     * @param {<Object>} destinationPrice
     * @returns {Array<String>}
     */
    buildRowFromDestinationPrice(destinationPrice) {
      const region = destinationPrice.destination.split("/")[0];
      const city = destinationPrice.destination.split("/")[1];

      return [
        region,
        city,
        this.formatNumber(destinationPrice.maxWeight),
        this.formatNumber(destinationPrice.price),
        destinationPrice.excedentPrice
          ? this.formatNumber(destinationPrice.excedentPrice)
          : "",
        destinationPrice.handlingTime
          ? this.formatNumber(destinationPrice.handlingTime)
          : "",
        this.formatNumber(destinationPrice.shippingTime)
      ];
    },
    /**
     * Se formatea el número a '#.###,###'
     * @param {Number} number
     * @return {String}
     */
    formatNumber(number) {
      return number
        .toFixed(2) // always two decimal digits
        .replace(".", ",") // replace decimal point character with ,
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    },
    /**
     * Filtra celda de columna ciudad segun region seleccionada
     * @param instance
     * @param cell
     * @param {Number} c
     * @param {Number} r
     * @param {Array<String>} source
     * @returns {Array<String>}
     */
    dropdownFilter(instance, cell, c, r, source) {
      var value = instance.jexcel.getValueFromCoords(c - 1, r);
      if (this.regions.includes(value)) {
        return this.cities[value];
      } else {
        return source;
      }
    },
    /**
     * Guarda cambios realizados en tabla
     * Esto incluye creaciones y ediciones
     */
    save() {
      this.saved = false;
      this.saving = true;
      const { toDelete, buckets, valid } = this.sliceAndPrepareData();
      const createdHash = {};
      if (!valid) {
        this.saving = false;
        this.saved = true;
        return;
      }
      Promise.all([
        this.upsertDestinationPrices(buckets, createdHash),
        this.deleteDestinationPrices(toDelete)
      ]).then(() => {
        this.updateCache(createdHash, toDelete);
        this.saving = false;
        this.saved = true;
      });
    },
    /**
     * Actualiza el hash de datos existentes
     * @param {Object} createdHash key => id
     * @param {Array<String>} toDelete listado de ids
     */
    updateCache(createdHash, toDelete) {
      toDelete.forEach(id => {
        delete this.existingDestinationPricesHash[id];
      });
      Object.assign(this.existingDestinationPricesHash, createdHash);
      this.oldRows = this.$dup(this.currentRows);
      this.changed = false;
    },
    /**
     * Actualiza un grupo de destinationPrices
     * @param {Object} bucket
     * @return {Promise}
     */
    upsertDestinationPriceBucket(bucket, createdHash) {
      return this.$apollo
        .mutate({
          mutation: UPSERT_DESTINATION_PRICE,
          variables: {
            integrationConfigId: this.integrationConfigId,
            patch: Object.values(bucket)
          }
        })
        .then(({ data }) => {
          const result = this.$dig(
            data,
            "mercadoLibreMe1UpsertDestinationPrices"
          );
          const success = result.success;
          if (success) {
            if (result.created && result.created.length) {
              result.created.forEach(destinationPrice => {
                createdHash[this.destinationPriceKey(destinationPrice)] =
                  destinationPrice.id;
              });
            }
          } else {
            this.errorUpsert = "Ocurrio un error actualizando productos";
          }
          this.progressSaving++;
        })
        .catch(err => {
          this.errorUpsert = `Ocurrio un error inesperado: ${err.message}`;
          this.progressSaving++;
        });
    },
    /**
     * Valida y entrega los datos actuales del excel
     * @return {Object}
     */
    validateAndGetData() {
      const toDeleteHash = this.$dup(this.existingDestinationPricesHash);
      const data = [];
      const withErrorRows = {};
      const excedentPriceHash = {};
      const uniqueTupleHash = {};
      const excedentPriceErrors = {};
      const uniqueTupleErrors = {};
      const invalidDataErrors = {};
      this.currentRows.forEach((row, index) => {
        const destinationPrice = this.buildObjectDestinationPrice(row);
        const key = this.destinationPriceKey(destinationPrice);
        if (toDeleteHash[key]) toDeleteHash[key] = false;
        let errors = this.validateRow(row).length;
        if (errors) {
          invalidDataErrors[index] = true;
        }
        if (uniqueTupleHash[key]) {
          errors = true;
          uniqueTupleErrors[key] = true;
        }
        if (destinationPrice.excedentPrice) {
          if (excedentPriceHash[destinationPrice.destination]) {
            errors = true;
            excedentPriceErrors[destinationPrice.destination] = true;
          }
          excedentPriceHash[destinationPrice.destination] = true;
        }
        if (errors) {
          withErrorRows[index] = errors;
        }
        uniqueTupleHash[key] = true;
        data.push(destinationPrice);
      });
      const toDelete = [];
      Object.keys(toDeleteHash).forEach(key => {
        if (toDeleteHash[key]) {
          toDelete.push(toDeleteHash[key]);
        }
      });
      let valid = true;
      if (Object.keys(withErrorRows).length) {
        valid = false;
      }
      this.paintCells(withErrorRows);
      this.buildErrorsValidating(
        excedentPriceErrors,
        uniqueTupleErrors,
        invalidDataErrors
      );
      return {
        valid,
        data,
        toDelete
      };
    },
    /**
     * Arma el arreglo de errores para mostrar
     * @param {Object} excedentPriceErrors
     * @param {Object} uniqueTupleErrors
     * @param {Object} invalidDataErrors
     */
    buildErrorsValidating(
      excedentPriceErrors,
      uniqueTupleErrors,
      invalidDataErrors
    ) {
      const errors = [];
      if (Object.keys(excedentPriceErrors).length) {
        errors.push(
          `Tienes definido más de una vez el precio por kilo excedente para los siguientes destinos: ${Object.keys(
            excedentPriceErrors
          ).join(", ")}`
        );
      }
      if (Object.keys(uniqueTupleErrors).length) {
        errors.push(
          `Debes agregar sólo una fila por cada combinación destino/peso máximo. Las siguientes combinaciones están repetidas: ${Object.keys(
            uniqueTupleErrors
          ).join(", ")}`
        );
      }
      if (Object.keys(invalidDataErrors).length) {
        errors.push(
          `Las siguientes filas tienen errores en el formato de datos, o hay datos sin completar (Recuerda que el destino, precio, peso máximo y tiempo de despacho son obligatorios): ${Object.keys(
            invalidDataErrors
          )
            .map(x => parseInt(x) + 1)
            .join(", ")}`
        );
      }
      this.errorsValidating = errors;
    },
    /**
     * Pinta las filas con error
     * @param {Object} errorHash {nro fila => true}
     */
    paintCells(errorHash) {
      for (let y = 0; y < this.currentRows.length; y++) {
        let color = "#ffff";
        if (errorHash[y]) {
          color = "#ff3838";
        }
        for (let x = 0; x < 7; x++) {
          this.$refs.spreadsheet.setColor(y, x, color);
        }
      }
    },
    /**
     * Divide los datos en buckets y los prepara para ser enviados.
     * Valida que los datos estén correctos.
     * Genera los arreglos para actualizar y el de ids para eliminar
     * @return {Object} {buckets, toDetele, valid}
     */
    sliceAndPrepareData() {
      let init = 0;
      const buckets = [];

      const { toDelete, data, valid } = this.validateAndGetData();
      if (!valid) {
        return { toDelete: null, buckets: null, valid: null };
      }
      do {
        buckets.push(data.slice(init, init + this.bucketSize));
        init = this.bucketSize + init;
      } while (data.length > init);
      return { toDelete, buckets, valid };
    },
    /**
     * Divide los datos a eliminar en buckets
     * @param {Array<String>} toDelete
     * @return {Array<Array<String>>}
     */
    sliceToDelete(toDelete) {
      let init = 0;
      const buckets = [];
      do {
        buckets.push(toDelete.slice(init, init + this.deleteBucketSize));
        init = this.deleteBucketSize + init;
      } while (toDelete.length > init);
      return buckets;
    },
    /**
     * Manda mutacion para crear/editar nuevos tarifarios
     * @param {Array<Object>} buckets
     */
    async upsertDestinationPrices(buckets, createdHash) {
      let processing = [];
      this.progressSaving = 0;
      this.totalPages = Math.max(buckets.length, 1);
      this.errorUpsert = null;
      for await (const arrData of buckets) {
        const request = this.upsertDestinationPriceBucket(arrData, createdHash);
        processing.push(request);
        processing = await this.$concurrentVacancy(processing, 10);
      }
      return Promise.all(processing);
    },
    /**
     * Elimina una página de datos
     * @param {Array<String>} toDelete
     * @returns {Promise}
     */
    deleteDestinationPriceBucket(toDelete) {
      return this.$apollo
        .mutate({
          mutation: DELETE_DESTINATION_PRICE,
          variables: {
            destinationPriceIds: toDelete
          }
        })
        .then(({ data }) => {
          const success = this.$dig(
            data,
            "mercadoLibreMe1DeleteDestinationPrices",
            "success"
          );
          if (success) {
            this.progressDeleting++;
          }
        })
        .catch(err => {
          this.errorDeleting = `Ocurrió un error al eliminar registros: ${err.message}`;
        });
    },
    /**
     * Elimina los destinationPrices
     * eliminados del excel
     * @param {Array<Object>} toDelete
     * @returns {Promise}
     */
    async deleteDestinationPrices(toDelete) {
      if (!toDelete.length) {
        this.errorDeleting = null;
        return;
      }
      const buckets = this.sliceToDelete(toDelete);
      let processing = [];
      this.progressDeleting = 0;
      this.totalPagesDeleting = Math.max(buckets.length, 1);
      this.errorDeleting = null;
      for await (const arrData of buckets) {
        const request = this.deleteDestinationPriceBucket(arrData);
        processing.push(request);
        processing = await this.$concurrentVacancy(processing, 10);
      }
      return Promise.all(processing);
    },
    /**
     * Maneja el evento de cambio
     * @params {Array<Array>} data
     */
    onChange() {
      this.changed = true;
    },
    /**
     * Revisa formato de celda numerica, agregando puntos y comas
     * @param {Object} instance
     * @param {String} cell
     * @param {Number} x
     * @param {Number} y
     * @param {String} value
     * @param {Object} obj
     */
    reviewFormatValue(instance, cell, x, y, value, obj) {
      if (x < 2) return;
      const numberValue = Number(value);
      if (!isNaN(numberValue)) {
        const formatNumber = this.formatNumber(Number(value));
        if (formatNumber != Number(value)) {
          obj.setValue(cell, formatNumber, false);
        }
      }
    }
  },
  watch: {
    destinationPrices(newValue) {
      this.existingDestinationPricesHash = this.calculateDestinationPriceHash(
        newValue.map(elem => elem.node)
      );
      this.currentRows = [...this.prepareRows(newValue)];
      this.oldRows = [...this.prepareRows(newValue)];
    }
  }
};
</script>
