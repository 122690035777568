<template>
  <b-col md="5">
    <template>
      <b-spinner label="Spinning" v-if="saving"></b-spinner>
      <b-button
        variant="info"
        class="m-2"
        @click="save"
        :disabled="!changed || saving"
      >
        Guardar
      </b-button>
      <b-button
        variant="outline-info"
        class="m-2"
        v-if="nothing_change"
        :to="{ name: 'JumpsellerPromotions' }"
      >
        Cancelar
      </b-button>
      <b-button
        variant="outline-info"
        class="m-2"
        v-else
        href="/jumpseller/promotions"
      >
        Cancelar
      </b-button>
    </template>
  </b-col>
</template>
<script>
export default {
  name: "MenuButtonsFormPromotion",
  data() {
    return {};
  },
  props: {
    save: Function,
    changed: Boolean,
    saving: Boolean,
    nothing_change: Boolean,
    loadingSave: Boolean
  },
  computed: {},
  methods: {},
  watch: {}
};
</script>
