<template>
  <b-card no-body class="m-2">
    <div class="m-2">
      <h4>{{ title }}</h4>
      <small v-if="description != ''" class="form-text text-muted">
        {{ description }}
      </small>
      <b-form-group :invalid-feedback="invalidFeedback" :state="valid">
        <b-form-input
          v-model="variable"
          placeholder=""
          @input="setVariable"
        ></b-form-input>
      </b-form-group>
    </div> </b-card
></template>

<script>
export default {
  name: "MercadoLibreCatalogSuggestionFormCard",
  components: {},
  props: {
    title: String,
    description: String,
    valid: Boolean,
    invalidFeedback: String,
    originalVariable: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      variable: this.originalVariable
    };
  },
  computed: {},
  mounted() {},
  methods: {
    /**
     * Emite evento para cambiar valor de la variable correspondiente
     */
    setVariable() {
      this.$emit("change", this.variable);
    }
  },
  watch: {}
};
</script>
