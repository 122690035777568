var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ApolloQuery',{attrs:{"query":require('../graphql/LastSynchronizationStatuses.gql'),"variables":_vm.queryVariables(''),"context":{ timeout: 60000 },"fetchPolicy":'cache-and-network',"clientId":"apolloClientCached"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_result = ref.result;
var error = ref_result.error;
var data = ref_result.data;
var query = ref.query;
var isLoading = ref.isLoading;
return [_c('b-overlay',{attrs:{"show":isLoading != 0 && !data,"rounded":"sm"}},[_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Integraciones:"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('integration-config-checkboxes',{attrs:{"aria-describedby":ariaDescribedby,"integrationConfigs":_vm.integrationsSelected,"typeExcluded":['Integrations::IntegrationConfigApplication']},on:{"update:integrationConfigs":function($event){_vm.integrationsSelected=$event},"update:integration-configs":function($event){_vm.integrationsSelected=$event},"populated":function($event){return _vm.hasIntegrationConfigCheckboxesPopulatedOptions($event)}}})]}}],null,true)})],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Estado:"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-checkbox-group',{attrs:{"aria-describedby":ariaDescribedby},model:{value:(_vm.statusesSelected),callback:function ($$v) {_vm.statusesSelected=$$v},expression:"statusesSelected"}},[_c('b-form-checkbox',{attrs:{"value":"ok"}},[_c('b-icon',{attrs:{"icon":"check","variant":"success"}}),_vm._v(" Correctos ")],1),_c('b-form-checkbox',{attrs:{"value":"failed"}},[_c('b-icon',{attrs:{"icon":"exclamation-triangle-fill","variant":"danger"}}),_vm._v(" Fallidos ")],1),_c('b-form-checkbox',{attrs:{"value":"excluded"}},[_c('b-icon',{attrs:{"icon":"dash-circle-fill","variant":"secondary"}}),_vm._v(" Excluidos ")],1),_c('b-form-checkbox',{attrs:{"value":"not_synched"}},[_c('b-icon',{attrs:{"icon":"three-dots","variant":"secondary"}}),_vm._v(" Sin sincronizar ")],1)],1)]}}],null,true)})],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Filtro de productos:"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('products-filter',_vm._b({attrs:{"aria-describedby":ariaDescribedby,"showActiveCheckbox":false,"showInactiveCheckbox":false,"showNoStockCheckbox":false}},'products-filter',_vm.productFilters,false,true))]}}],null,true)})],1)],1)],1),(isLoading && !data)?_c('b-skeleton-table',{attrs:{"rows":25,"columns":4,"table-props":{ hover: true }}}):(error && _vm.retry == 5)?_c('b-alert',{attrs:{"show":"","variant":"danger"}},[_vm._v(" Ha ocurrido un error ")]):_vm._e(),_c('b-row',{attrs:{"align-h":"end"}},[(data && isLoading)?_c('span',{staticClass:"m-2 float-right"},[_vm._v(" Actualizando lista de estados por integración... ")]):_vm._e()]),(data && data.allProductLastSynchronizationStatus)?_c('b-table-simple',{attrs:{"hover":"","sticky-header":"65vh","no-border-collapse":""}},[_c('b-thead',[_c('b-tr',[_c('b-th',[_vm._v("Producto")]),_vm._l((_vm.integrationsSelected),function(integration){return _c('b-th',{key:integration.id},[_vm._v(_vm._s(integration.label))])})],2)],1),_c('b-tbody',_vm._l((data.allProductLastSynchronizationStatus.edges),function(product){return _c('last-synchronization-statuses-list-item',{key:product.node.id,attrs:{"product":product.node,"integrationsSelected":_vm.integrationsSelected}})}),1)],1):_vm._e(),(isLoading)?_c('b-spinner',{staticClass:"m-2 float-right",attrs:{"label":"Spinning"}}):_c('div',[(_vm.hasNextPage(data))?_c('b-button',{staticClass:"m-2 float-right",on:{"click":function($event){return _vm.seeMore(
            query,
            data.allProductLastSynchronizationStatus.pageInfo.endCursor
          )}}},[_vm._v(" Ver más ")]):_c('b-alert',{staticClass:"text-center",attrs:{"show":"","variant":"light"}},[_vm._v(" No hay más datos para mostrar. ")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }