<template>
  <b-tr>
    <b-td>{{ $dig(webhook, "application", "publicName") }}</b-td>
    <b-td>{{ webhook.callbackUrl }}</b-td>
    <b-td v-for="(type, index) of webhookTopics" :key="index">
      <p class="h3 m-0">
        <b-icon
          :icon="webhook[type] ? 'check' : 'x'"
          :variant="webhook[type] ? 'success' : 'danger'"
        />
      </p>
    </b-td>
    <b-td>
      <b-row align-h="end" no-gutters align-v="center">
        <div class="mr-2">
          <span v-if="deleting"> Eliminando <b-spinner small /> </span>
        </div>
        <b-button
          v-if="$permissionToUpdate(currentUser, 'webhooks')"
          size="lg"
          variant="outlined"
          class="p-0 mr-1 focus-btn"
          v-b-tooltip.hover="'Editar webhook'"
          :to="`/integrations/webhook/${webhook.id}/edit`"
        >
          <b-icon stacked icon="pencil" scale="0.60"></b-icon>
        </b-button>
        <b-button
          v-if="$permissionToDelete(currentUser, 'webhooks')"
          size="lg"
          variant="outlined"
          class="p-0 mr-1 focus-btn"
          v-b-tooltip.hover="'Eliminar webhook'"
          @click="swalDeleteWebhook"
        >
          <b-icon stacked icon="x" scale="0.75"></b-icon>
        </b-button>
      </b-row>
    </b-td>
  </b-tr>
</template>

<script>
import DELETE_WEBHOOK from "../../graphql/Webhooks/DeleteWebhook.gql";
export default {
  name: "WebhookDetail",
  props: {
    webhook: {
      type: Object,
      default() {
        return {};
      }
    },
    currentUser: Object
  },
  data() {
    return {
      deleting: false,
      webhookTopics: [
        "onProductSave",
        "onProductDelete",
        "onOrderSave",
        "onOrderDelete",
        "onIntegrationConfigSave",
        "onIntegrationConfigDelete",
        "onInvoiceConfigSave",
        "onInvoiceConfigDelete",
        "onAsyncTaskFinish",
        "onAsyncTaskFailed"
      ]
    };
  },
  methods: {
    /**
     * Se encarga de enviar la mutacion para borrar un webhook
     */
    deleteWebhook() {
      this.deleting = true;
      this.$apollo
        .mutate({
          mutation: DELETE_WEBHOOK,
          variables: {
            webhookId: this.webhook.id
          }
        })
        .then(({ data }) => {
          if (data.deleteWebhook.result) {
            this.$emit("delete-webhook");
            this.deleting = false;
          } else {
            this.$emit("delete-webhook", data.deleteWebhook.error);
            this.deleting = false;
          }
        })
        .catch(e => {
          this.$emit("delete-webhook", e.message);
          this.deleting = false;
        });
    },
    /**
     * Lanza un swal para confirmar la eliminacion del webhook
     */
    swalDeleteWebhook() {
      this.$swal
        .fire({
          title: "Eliminar webhook",
          text: "El webhook se eliminará definitivamente. ¿Estás seguro?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si",
          cancelButtonText: "No"
        })
        .then(result => {
          if (result.value) {
            this.deleteWebhook();
          }
        });
    }
  }
};
</script>

<style>
.focus-btn {
  color: #aab1b5;
  display: inline-block;
  background-color: white;
  width: 34px;
  height: 34px;
  text-align: center;
  border-radius: 50%;
  font-size: 14px;
  border: 2px solid #e0e2e4;
  padding: 5px 0;
}

.focus-btn:hover {
  border-color: #aab1b5;
  color: black;
}
</style>
