<template>
  <ApolloQuery
    :query="require('../../graphql/IntegrationConfigMiraklForSizeCharts.gql')"
    :variables="{
      id: IntegrationConfigId,
      keywords: filters.keyword.split(',')
    }"
  >
    <template slot-scope="{ result: { loading, error, data } }">
      <v-select
        v-if="data && data.integrationConfigMirakl"
        v-model="selectedSizeChart"
        :clearable="true"
        :options="optionsForSelect(data.integrationConfigMirakl.chartsQuery)"
        placeholder="Escoja una tabla de tallas"
      >
      </v-select>
      <b-spinner v-if="loading" label="Spinning"></b-spinner>
      <b-alert v-if="error" show="" variant="danger">
        Ha ocurrido un error
      </b-alert>
    </template>
  </ApolloQuery>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "SizeChartMiraklHomologator",
  model: {
    prop: "_value",
    event: "change"
  },
  props: {
    IntegrationConfigId: String,
    _value: String
  },
  data() {
    return {
      selectedSizeChart: this._value
    };
  },
  computed: {
    ...mapState(["filters"])
  },
  methods: {
    /**
     * Construye arreglo con opciones de seleccion
     * @param {Array<Object>} sizeCharts
     * @returns {Array<Object>}
     */
    optionsForSelect(sizeCharts) {
      if (!sizeCharts) return [];
      return sizeCharts.map(sizeChart => {
        return {
          label: sizeChart.code.split("_").join(" "),
          value: sizeChart.code
        };
      });
    }
  },
  watch: {
    _value(newValue) {
      this.selectedSizeChart = newValue;
    },
    selectedSizeChart(newValue) {
      if (newValue === null) {
        this.$emit("change", "");
      } else if (typeof newValue === "object") {
        this.$emit("change", newValue.value);
      }
    }
  }
};
</script>
