<template>
  <div>
    <base-header title="Traducir error" title-link="#" :map="['Editar']">
    </base-header>
    <b-container fluid="">
      <b-row>
        <b-col md="12">
          <error-translation-form
            :error-translation-id="$route.params.id"
          ></error-translation-form>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import BaseHeader from "@/components/BaseHeader";
import ErrorTranslationForm from "@/components/ErrorTranslationForm";

export default {
  name: "ErrorTranslationEdit",
  components: {
    ErrorTranslationForm,
    BaseHeader
  }
};
</script>
