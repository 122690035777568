<template>
  <div>
    <b-table
      v-if="dataReady"
      :items="items"
      :fields="fields"
      striped
      class="text-left"
    >
      <template #cell(Detalles)="row">
        <b-button size="sm" @click="handleClick(row)" class="mr-2">
          {{ row.detailsShowing ? "Esconder" : "Mostrar" }} Detalles
        </b-button>
      </template>

      <template #row-details="row">
        <b-card v-if="row.item.requestResponse">
          <b-row
            class="mb-2"
            :style="'height: ' + editorsHeightPx + 'px'"
            :key="forceRenderEditor"
          >
            <b-col md="6">
              <h4>Request</h4>
              <AceEditor
                mode="json"
                theme="monokai"
                width="100%"
                height="100%"
                :name="`req-editor-${row.index}`"
                :showPrintMargin="false"
                :readOnly="true"
                :value="jsonPrettyPrint(row.item.requestResponse.request)"
              />
            </b-col>
            <b-col md="6">
              <h4>Response</h4>
              <AceEditor
                mode="json"
                theme="monokai"
                width="100%"
                height="100%"
                :name="`res-editor-${row.index}`"
                :showPrintMargin="false"
                :readOnly="true"
                :value="jsonPrettyPrint(row.item.requestResponse.response)"
              />
            </b-col>
          </b-row>
        </b-card>
        <div v-else-if="row.item.error">
          <p>
            Hubo un error al tratar de obtener el detalle de la sincronización.
            Reintentar
            <b-link @click="getRequestResponse(row.item.id, row.index)"
              >aqui.</b-link
            >
          </p>
        </div>
        <b-spinner v-else />
      </template>
    </b-table>
  </div>
</template>
<script>
import { centryUrl, synchronizeProduct } from "../main";
import { mapActions, mapState } from "vuex";
import moment from "moment-timezone";
import { Ace as AceEditor } from "vue2-brace-editor";
import "brace/mode/json";
import "brace/theme/monokai";
import "brace/ext/searchbox";
import REQUEST_RESPONSE_BY_SYNC_ID from "../graphql/Product/History/RequestResponseBySyncId.gql";

export default {
  name: "HistoriesTable",
  components: { AceEditor },
  props: {
    histories: Array,
    product: {
      type: Object,
      default() {
        return {};
      }
    },
    editorsHeightPx: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      centryUrl,
      icsToSync: [],
      syncing: false,
      errorSyncing: false,
      sync: false,
      items: [],
      fields: [
        "Fecha",
        "Plataforma",
        "Acción",
        "Respuesta Preliminar",
        "Detalles"
      ],
      dataReady: false,
      forceRenderEditor: 0
    };
  },
  async mounted() {
    await this.getItems();
    this.dataReady = true;
  },
  computed: {
    ...mapState(["currentUser"])
  },
  methods: {
    ...mapActions(["timezoneDate"]),
    async getItems() {
      let items = [];
      await this.histories.forEach(async (history, index) => {
        let date = await this.timezoneDate(history.createdAt);
        let datetime = moment(date).format("DD/MM/YYYY HH:mm:ss");
        items.push({
          Fecha: datetime,
          Plataforma: history.marketplace,
          Acción: history.action,
          "Respuesta Preliminar": history.success,
          requestResponse: this.items[index]?.requestResponse
            ? this.items[index]
            : null,
          id: history.id,
          error: false
        });
      });
      this.items = items;
    },
    availableIntegrations(data) {
      if (!data || !data.allIntegrationConfigs) {
        return [];
      }
      return data.allIntegrationConfigs
        .filter(x => x.available)
        .map(op => {
          return { value: op.id, text: op.label };
        });
    },
    async syncIntegrations(type) {
      this.errorSyncing = false;
      this.syncing = true;
      await synchronizeProduct(
        this.$apollo,
        this.product.id,
        this.icsToSync,
        type
      )
        .then(async ({ data }) => {
          if (
            data &&
            data.synchronizeProduct &&
            data.synchronizeProduct.result
          ) {
            this.syncing = false;
            this.sync = true;
          }
        })
        .catch(() => {
          this.errorSyncing = true;
          this.syncing = false;
          this.sync = true;
        });
    },
    jsonPrettyPrint(json) {
      return JSON.stringify(JSON.parse(json), null, "  ");
    },
    /**
     * Muestra el detalle de la fila y si no tiene detalle lo busca
     * @param {Object} row - detalle de la fila
     */
    handleClick(row) {
      if (!row.item.requestResponse) {
        this.getRequestResponse(row.item.id, row.index);
      }
      row.toggleDetails();
    },
    /**
     * Obtiene el request/response de una sincronizacion en particular
     * @param {String} id - id de la sincronizacion
     * @param {Number} index - indice de la sincronizacion
     */
    getRequestResponse(id, index) {
      this.items[index].error = false;
      this.$apollo
        .query({
          query: REQUEST_RESPONSE_BY_SYNC_ID,
          variables: {
            syncId: id
          }
        })
        .then(({ data }) => {
          let history = data.synchronizationHistory;
          this.items[index].requestResponse = {
            request:
              history.requestResponse !== null
                ? history.requestResponse.request
                : history.request,
            response:
              history.requestResponse !== null
                ? history.requestResponse.response
                : history.response
          };
        })
        .catch(() => (this.items[index].error = true));
    }
  },
  watch: {
    histories(val, prev) {
      if (!this.$arrayDeepCompare(val, prev)) {
        this.getItems();
      }
    },
    editorsHeightPx() {
      this.forceRenderEditor += 1;
    }
  }
};
</script>
<style scoped>
.table td {
  height: 15px;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
</style>
