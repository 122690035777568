<template>
  <b-container>
    <base-header
      title="Magento"
      title-link="/products"
      :map="['Conjuntos de Atributos']"
    >
    </base-header>
    <magento-attribute-set-list></magento-attribute-set-list>
  </b-container>
</template>

<script>
import MagentoAttributeSetList from "@/components/MagentoAttributeSetList";
import BaseHeader from "@/components/BaseHeader";
export default {
  name: "Magento",
  components: {
    MagentoAttributeSetList,
    BaseHeader
  }
};
</script>
