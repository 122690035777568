<template>
  <div class="d-inline-block">
    <b-button
      :disabled="disabled || loading"
      variant="outline-info"
      :title="title"
      @click="download"
    >
      <b-spinner label="Spinning" v-if="loading"></b-spinner>
      <b-icon-truck v-else></b-icon-truck>
      Imprimir etiquetas </b-button
    ><br /><br />
    <span class="text-info text-center" v-if="loading">
      {{ processed }} de {{ withLabel }} Pedidos Procesados ({{
        withoutLabel
      }}
      sin etiqueta disponible)</span
    >
  </div>
</template>
<script>
import ALL_SHIPPING_LABELS from "@/graphql/AllShippingLabels.gql";
import { mapState } from "vuex";

export default {
  name: "OrdersShippingLabelButton",
  props: {
    disabled: Boolean,
    title: String
  },
  data() {
    return {
      loading: false,
      processed: 0,
      withoutLabel: 0,
      withLabel: 0
    };
  },
  computed: {
    ...mapState(["selectedOrders"])
  },
  methods: {
    async download() {
      this.loading = true;
      this.processed = 0;
      const ids = this.getIds();
      this.withoutLabel =
        Object.keys(this.selectedOrders).filter(
          k => this.selectedOrders[k].selected
        ).length - ids.length;
      this.withLabel = ids.length;
      const filesArray = [];
      const unavailablesArray = [];
      let errors = false;
      const splited = this.$splitArray(ids, 60);
      for (let i = 0; i < splited.length; i++) {
        const subIds = splited[i];
        if (!errors) {
          await this.$apollo
            .query({
              query: ALL_SHIPPING_LABELS,
              variables: {
                ids: subIds
              }
            })
            .then(({ data }) => {
              if (data.allShippingLabels.result) {
                data.allShippingLabels.body.forEach(group => {
                  group.files?.forEach(file => filesArray.push(file));
                  group.failed?.forEach(order => unavailablesArray.push(order));
                });
              } else if (data.allShippingLabels.errors) {
                this.$swal.fire({
                  title: "Error al descargar etiqueta",
                  text:
                    "Ha ocurrido un error: " + data.allShippingLabels.errors,
                  icon: "error"
                });
                errors = true;
              }
            });
        }
      }
      if (!errors) {
        if (unavailablesArray.length > 0) {
          this.$swal.fire({
            title: "Algunas etiquetas no están disponibles",
            html: `<p>Las siguientes etiquetas no están disponibles para ser descargadas:</p>
            <p><ul><li>
            ${unavailablesArray
              .map(o => `${o.origin} - ${o.number_origin || o.id_origin}`)
              .join("</li><li>")}
            </li></ul></p>
            <p>Consulta en cada plataforma si existen problemas con estos pedidos.</p>`,
            icon: "warning"
          });
        }
        this.$downloadBASE64(filesArray).then(() => (this.loading = false));
      }
      this.loading = false;
    },
    getIds() {
      return Object.keys(this.selectedOrders).filter(
        k =>
          this.selectedOrders[k].selected &&
          this.selectedOrders[k].order.hasShippingLabel
      );
    }
  }
};
</script>
