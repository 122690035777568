<template>
  <b-row>
    <b-col>
      <b-form-checkbox :style="inlineStyle" v-model="selected">
        {{ category.name }}
      </b-form-checkbox>

      <span :style="spanStyle" v-if="isFather" @click="changeOpen()">
        [{{ isOpen ? "-" : "+" }}]
      </span>

      <div v-show="isOpen" :style="leftSpace">
        <vtex-base-promotion-products-and-skus-data-item
          v-for="_category in category.children"
          :key="_category.id"
          :category="_category"
          :indexation="indexation + 1"
          v-model="selectedValues"
        />
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "VtexBasePromotionProductsAndSkusDataItem",
  props: {
    value: {
      type: Array,
      required: true
    },
    category: {
      type: Object,
      required: true
    },
    indexation: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      selected: false,
      isOpen: false,
      statusChildren: this.category.children.map(() => false),
      childrenIds: this.category.children.map(category => category.id),
      selectedValues: this.value || []
    };
  },
  methods: {
    /**
     * Se encarga de mostrar u ocultar los hijos de una categoría padre.
     */
    changeOpen() {
      this.isOpen = !this.isOpen;
    },
    /**
     * Se encarga de añadir o quitar una categoría a la lista de categorías seleccionadas.
     * @param {Boolean} status - Estado del hijo. (Seleccionado o no)
     */
    addOrDeleteCategoryId(selected) {
      if (selected) {
        this.selectedValues.push(this.categoryId);
      } else {
        this.selectedValues.splice(
          this.selectedValues.indexOf(this.categoryId),
          1
        );
      }
    }
  },
  computed: {
    categoryId() {
      return this.category.id;
    },
    leftSpace() {
      return {
        marginLeft: this.indexation * 20 + "px"
      };
    },
    isFather() {
      return this.category.children.length > 0;
    },
    isLeaf() {
      return this.category.children.length === 0;
    },
    inlineStyle() {
      return {
        display: "inline"
      };
    },
    spanStyle() {
      return { fontWeight: "bold", cursor: "pointer" };
    }
  },
  watch: {
    value(value) {
      this.selectedValues = value;
    },
    /**
     * Se emiten los nuevos valores (ids de categorias) seleccionados si
     * son distintos a los actuales.
     */
    selectedValues(newValues) {
      if (
        !(
          newValues.lenght === this.value.lenght &&
          newValues.every((value, index) => value === this.value[index])
        )
      ) {
        this.$emit("input", newValues);
      }
    },
    /**
     * Quita o añade el id de la categoria actual de la lista de seleccionados.
     */
    selected(newValue) {
      this.addOrDeleteCategoryId(newValue);
    }
  }
};
</script>
