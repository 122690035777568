<template>
  <div>
    <b-button-group size="sm">
      <b-button variant="outline-info" @click="cancel">
        <b-icon-x-circle></b-icon-x-circle> Cancelar
      </b-button>
      <b-button
        variant="info"
        :disabled="
          !changed || saving || loadingIntegrationConfig || !validChangesPrices
        "
        :title="changed ? '' : 'No hay cambios'"
        v-b-tooltip.hover=""
        @click="save"
      >
        <span v-if="saving"><b-spinner label="Spinning"></b-spinner></span>
        <span v-else><b-icon-cloud-upload></b-icon-cloud-upload>Guardar</span>
      </b-button>
    </b-button-group>
    <b-alert
      v-model="saved"
      :variant="errorSaving ? 'danger' : 'success'"
      dismissible
    >
      {{
        errorSaving
          ? "Ha ocurrido un error"
          : "El producto ha sido actualizado exitosamente"
      }}
    </b-alert>
    <h4 class="font-weight-bold m-3">Detalles producto</h4>
    <b-row>
      <b-col md="6">
        <b-form-group
          label="Nombre"
          :label-for="'titulo-' + integrationConfig.fullLabel"
        >
          <b-form-input
            :id="'titulo-' + integrationConfig.fullLabel"
            v-model="titulo"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Marca (vendor)"
          :label-for="'vendor-' + integrationConfig.fullLabel"
        >
          <b-form-input
            :id="'vendor-' + integrationConfig.fullLabel"
            v-model="vendor"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Tipo de producto"
          :label-for="'product-type-' + integrationConfig.fullLabel"
        >
          <b-form-input
            :id="'product-type-' + integrationConfig.fullLabel"
            v-model="productType"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Url amigable"
          :label-for="'url-' + integrationConfig.fullLabel"
        >
          <b-form-input
            :id="'url-' + integrationConfig.fullLabel"
            v-model="url"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Titulo seo"
          :label-for="'seo-' + integrationConfig.fullLabel"
        >
          <b-form-input
            :id="'seo-' + integrationConfig.fullLabel"
            v-model="seo"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Meta descripción"
          :label-for="'metaDescription-' + integrationConfig.fullLabel"
        >
          <b-form-input
            :id="'metaDescription-' + integrationConfig.fullLabel"
            v-model="metaDescription"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="* Descripción"
          :label-for="'description-' + integrationConfig.fullLabel"
        >
          <base-html-text-area
            :id="'description-' + integrationConfig.fullLabel"
            v-model="description"
          ></base-html-text-area>
        </b-form-group>
        <b-form-group
          label="Colecciones"
          :label-for="'collections-' + integrationConfig.fullLabel"
        >
          <v-select
            v-if="!loadingIntegrationConfig"
            :id="'collections-' + integrationConfig.fullLabel"
            :multiple="true"
            :options="categoryList"
            v-model="collections"
            :reduce="x => x.value"
            @input="change"
          ></v-select>
        </b-form-group>
        <b-form-group label="Tags">
          <b-form-tags
            input-id="tags-basic"
            v-model="tags"
            separator=","
            placeholder=""
            class="mb-2"
          >
          </b-form-tags>
          <b-form-text>Separar mediante ","</b-form-text>
        </b-form-group>
        <b-form-group label="Estado de la publicación">
          <base-boolean-selector
            v-model="published"
            allow-null=""
            true-text="Disponible"
            false-text="Deshabilitado"
            default-text="Como en la configuración general"
          ></base-boolean-selector>
        </b-form-group>
      </b-col>
    </b-row>
    <hr />
    <h4 class="font-weight-bold m-3">Opciones</h4>
    <b-row>
      <b-col>
        <b-table-simple>
          <b-thead>
            <b-tr>
              <b-th>Colores</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-td>
              <b-tr v-for="(item, key, index) of colors" :key="index">
                <b-form-input
                  v-model="colors[key].value"
                  :placeholder="colors[key].name"
                  @input="change"
                ></b-form-input>
              </b-tr>
            </b-td>
          </b-tbody>
        </b-table-simple>
      </b-col>
      <b-col>
        <b-table-simple>
          <b-thead>
            <b-tr>
              <b-th>Tallas</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-td>
              <b-tr v-for="(item, key, index) of sizes" :key="index">
                <b-form-input
                  v-model="sizes[key].value"
                  :placeholder="sizes[key].name"
                  @input="change"
                ></b-form-input>
              </b-tr>
            </b-td>
          </b-tbody>
        </b-table-simple>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <h4 class="font-weight-bold m-3">Variantes</h4>
      <p v-if="!product.variants || product.variants.length === 0">
        Este producto no tiene variantes
      </p>
      <b-table-simple v-else>
        <b-thead>
          <b-tr>
            <b-th colspan="3">Centry</b-th>
            <b-th>{{ integrationConfig.label }}</b-th>
          </b-tr>
          <b-tr>
            <b-th>Color</b-th>
            <b-th>Talla</b-th>
            <b-th>SKU</b-th>
            <b-th>SKU</b-th>
            <b-th>Precio de venta</b-th>
            <b-th>Precio de comparación</b-th>
            <b-th>Fecha inicio de oferta</b-th>
            <b-th>Fecha final de oferta</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="(variant, index) of product.variants" :key="index">
            <b-td>{{ variant.color ? variant.color.name : "---" }}</b-td>
            <b-td>{{ variant.size ? variant.size.name : "---" }}</b-td>
            <b-td>{{ variant.sku }}</b-td>
            <b-td
              ><b-form-input
                v-model="
                  productVariants[index].integrations[
                    integrationConfig.fullLabel
                  ].sku
                "
                @input="change"
              ></b-form-input
            ></b-td>
            <b-td
              ><b-form-input
                v-model="
                  productVariants[index].integrations[
                    integrationConfig.fullLabel
                  ].price
                "
                :state="
                  $validFormPrice(
                    productVariants[index].integrations[
                      integrationConfig.fullLabel
                    ].price
                  )
                "
                :formatter="$formatFormPrice"
                @input="change"
              ></b-form-input
            ></b-td>
            <b-td
              ><b-form-input
                v-model="
                  productVariants[index].integrations[
                    integrationConfig.fullLabel
                  ].compare_at_price
                "
                :state="
                  $validFormPrice(
                    productVariants[index].integrations[
                      integrationConfig.fullLabel
                    ].compare_at_price
                  )
                "
                :formatter="$formatFormPrice"
                @input="change"
              ></b-form-input
            ></b-td>
            <b-td
              ><base-date-time
                v-model="
                  productVariants[index].integrations[
                    integrationConfig.fullLabel
                  ].sale_startdate
                "
                @input="change"
                type="datetime"
              ></base-date-time
            ></b-td>
            <b-td
              ><base-date-time
                v-model="
                  productVariants[index].integrations[
                    integrationConfig.fullLabel
                  ].sale_enddate
                "
                @input="change"
                type="datetime"
              ></base-date-time
            ></b-td>
          </b-tr>
        </b-tbody> </b-table-simple
    ></b-row>
  </div>
</template>
<script>
import BaseHtmlTextArea from "./BaseHtmlTextArea";
import BaseBooleanSelector from "./BaseBooleanSelector";
import BaseDateTime from "./BaseDateTime";
import { updateProduct, updateVariant } from "../main";
import INTEGRATION_CONFIG_SHOPIFY from "../graphql/IntegrationConfigShopify.gql";

export default {
  name: "ProductEditTabsShopify",
  components: { BaseHtmlTextArea, BaseBooleanSelector, BaseDateTime },
  props: {
    integrationConfig: Object,
    product: Object
  },
  computed: {
    /**
     * Indica si los precios son validos para habilitar el botón
     * de guardar
     * @returns {Boolean}
     */
    validChangesPrices() {
      let validPrices = this.changed;
      this.productVariants.forEach(variant => {
        let hashIntegration =
          variant.integrations[this.integrationConfig.fullLabel];
        validPrices =
          validPrices &&
          (this.$validFormPrice(hashIntegration.price) ||
            this.$validFormPrice(hashIntegration.price) == null) &&
          (this.$validFormPrice(hashIntegration.compare_at_price) ||
            this.$validFormPrice(hashIntegration.compare_at_price) == null);
      });
      return validPrices;
    }
  },
  data() {
    const original = this.$ifNull(
      this.$dig(this.product, "integrations", this.integrationConfig.fullLabel),
      {}
    );
    return {
      changed: false,
      saving: false,
      errorSaving: false,
      saved: false,
      loadingIntegrationConfig: true,
      titulo: original.title,
      vendor: original.vendor,
      productType: original.product_type,
      url: original.handle,
      description: original.body_html,
      seo: original.metafields_global_title_tag,
      metaDescription: original.metafields_global_description_tag,
      published: this.getPublishedStatus(),
      tags: this.$ifNull(original.tags, "").split(","),
      collections: original.collections,
      categoryList: [],
      colors: {},
      sizes: {},
      productVariants: this.initialVariantSkus()
    };
  },
  beforeMount() {
    this.colorandSize();
    this.getIntegrationConfig();
  },
  methods: {
    change() {
      this.changed = true;
      this.$emit("change", true);
    },
    getPublishedStatus() {
      const status = this.$ifNull(
        this.$dig(
          this.product,
          "integrations",
          this.integrationConfig.fullLabel
        ),
        {}
      ).published;
      if (status === "true" || status === true) {
        return true;
      } else if (status === "false" || status === false) {
        return false;
      } else {
        return null;
      }
    },
    initialVariantSkus() {
      if (!this.product.variants) {
        return [];
      }
      const variantArray = JSON.parse(JSON.stringify(this.product.variants));
      variantArray.forEach(variant => {
        const hash = this.$ifNull(
          this.$ifNull(variant.integrations, {})[
            this.integrationConfig.fullLabel
          ],
          {}
        );
        hash.sku = this.$ifNull(hash.sku, "");
        hash.price = this.$ifNull(hash.price, "");
        hash.compare_at_price = this.$ifNull(hash.compare_at_price, "");
        hash.sale_startdate = this.$ifNull(
          hash.sale_startdate !== null ? new Date(hash.sale_startdate) : null,
          null
        );
        hash.sale_enddate = this.$ifNull(
          hash.sale_enddate !== null ? new Date(hash.sale_enddate) : null,
          null
        );
        variant.integrations[this.integrationConfig.fullLabel] = hash;
      });
      return variantArray;
    },
    colorandSize() {
      const original = this.$ifNull(
        this.product.integrations[this.integrationConfig.fullLabel],
        {}
      );
      var colors = {};
      var sizes = {};
      this.product.variants.forEach(variant => {
        if (variant.color && variant.color.name && variant.color.id) {
          colors[variant.color.id] = {
            name: variant.color.name,
            value: this.$ifNull(original.color, {})[variant.color.id]
          };
        }
        if (variant.size && variant.size.name && variant.size.id) {
          sizes[variant.size.id] = {
            name: variant.size.name,
            value: this.$ifNull(original.size, {})[variant.size.id]
          };
        }
      });
      this.colors = colors;
      this.sizes = sizes;
    },
    async getIntegrationConfig() {
      this.$apollo
        .query({
          query: INTEGRATION_CONFIG_SHOPIFY,
          variables: { id: this.integrationConfig.id }
        })
        .then(result => {
          this.categoryList = this.categoryOptions(
            result.data.integrationConfigShopify
          );
          this.loadingIntegrationConfig = false;
        });
    },
    categoryOptions(json) {
      return this.$ifNull(json.collectionList, []).map(x => {
        return { value: String(x.id), label: x.title };
      });
    },
    updatedProductData() {
      const updated = { integrations: {} };
      updated.integrations[this.integrationConfig.fullLabel] = {};
      let original = this.product.integrations[
        this.integrationConfig.fullLabel
      ];
      if (!original) original = {};
      const changes = [
        {
          key: "title",
          original: original.title,
          current: this.titulo
        },
        {
          key: "vendor",
          original: original.vendor,
          current: this.vendor
        },
        {
          key: "product_type",
          original: original.product_type,
          current: this.productType
        },
        {
          key: "handle",
          original: original.handle,
          current: this.url
        },
        {
          key: "body_html",
          original: original.body_html,
          current: this.description
        },
        {
          key: "metafields_global_title_tag",
          original: original.metafields_global_title_tag,
          current: this.seo
        },
        {
          key: "metafields_global_description_tag",
          original: original.metafields_global_description_tag,
          current: this.metaDescription
        },
        {
          key: "published",
          original:
            original.published === null ? null : String(original.published),
          current: this.published === null ? null : String(this.published)
        },
        {
          key: "tags",
          original: original.tags,
          current: this.tags.join(",")
        },
        {
          key: "collections",
          original: original.collections,
          current: this.collections
        }
      ];
      let changed = false;
      let opsChanges = this.optionsChanges();
      if ("color" in opsChanges) {
        updated.integrations[this.integrationConfig.fullLabel].color =
          opsChanges.color;
        changed = true;
      }
      if ("size" in opsChanges) {
        updated.integrations[this.integrationConfig.fullLabel].size =
          opsChanges.size;
        changed = true;
      }
      changes.forEach(x => {
        if (x.key == "collections") {
          if (
            (!x.current && x.original) ||
            (x.current && !x.original) ||
            (x.current && x.original && x.current.length !== x.original.length)
          ) {
            changed = true;
            updated.integrations[this.integrationConfig.fullLabel][x.key] =
              x.current;
          } else if (x.current && x.original) {
            for (var pos = 0; pos < x.current.length; pos++) {
              if (x.current[0] !== x.original[0]) {
                changed = true;
                updated.integrations[this.integrationConfig.fullLabel][x.key] =
                  x.current;
              }
            }
          }
        } else if (x.original !== x.current) {
          changed = true;
          updated.integrations[this.integrationConfig.fullLabel][x.key] =
            x.current;
        }
      });
      if (changed) {
        return updated;
      }
      return null;
    },
    optionsChanges() {
      let updated = {};
      const original = this.$ifNull(
        this.product.integrations[this.integrationConfig.fullLabel],
        {}
      );
      Object.keys(this.colors).forEach(key => {
        if (this.colors[key].value !== this.$ifNull(original.color, {})[key]) {
          if (!("color" in updated)) {
            updated["color"] = {};
          }
          if (!(key in updated["color"])) {
            updated["color"][key] = {};
          }
          updated.color[key] = this.colors[key].value;
        }
      });
      Object.keys(this.sizes).forEach(key => {
        if (this.sizes[key].value !== this.$ifNull(original.size, {})[key]) {
          if (!("size" in updated)) {
            updated["size"] = {};
          }
          if (!(key in updated["size"])) {
            updated["size"][key] = {};
          }
          updated.size[key] = this.sizes[key].value;
        }
      });
      return updated;
    },
    updateProductCache(product) {
      if (product) {
        this.product.integrations = product.integrations;
      }
    },
    async updateProduct() {
      const updated = this.updatedProductData();
      if (!updated) {
        return;
      }
      await updateProduct(this.$apollo, this.product.id, updated)
        .then(async ({ data }) => {
          if (data && data.updateProduct && data.updateProduct.product) {
            this.errorSaving = false;
            const product = data.updateProduct.product;
            this.updateProductCache(product);
          }
        })
        .catch(() => {
          this.errorSaving = true;
        });
    },
    updatedVariantData(position) {
      const variant = this.product.variants[position];
      const original = this.$ifNull(
        variant.integrations[this.integrationConfig.fullLabel],
        {}
      );
      const current = this.productVariants[position].integrations[
        this.integrationConfig.fullLabel
      ];
      const updated = { integrations: {} };
      updated.integrations[this.integrationConfig.fullLabel] = {};
      const changes = [
        {
          key: "sku",
          original: original.sku,
          current: current.sku
        },
        {
          key: "price",
          original: original.price,
          current: current.price
        },
        {
          key: "compare_at_price",
          original: original.compare_at_price,
          current: current.compare_at_price
        },
        {
          key: "sale_startdate",
          original:
            original.sale_startdate !== null
              ? new Date(original.sale_startdate)
              : null,
          current:
            current.sale_startdate !== null
              ? new Date(current.sale_startdate)
              : null
        },
        {
          key: "sale_enddate",
          original:
            original.sale_enddate !== null
              ? new Date(original.sale_enddate)
              : null,
          current:
            current.sale_enddate !== null
              ? new Date(current.sale_enddate)
              : null
        }
      ];
      let changed = false;
      changes.forEach(x => {
        if (x.original !== x.current) {
          changed = true;
          updated.integrations[this.integrationConfig.fullLabel][x.key] =
            x.current;
        }
      });
      if (changed) {
        return updated;
      }
      return null;
    },
    updateVariantCache(position, variant) {
      this.product.variants[position] = variant;
    },
    async updateVariant(position) {
      const updated = this.updatedVariantData(position);
      if (!updated) {
        return;
      }
      await updateVariant(
        this.$apollo,
        this.product.variants[position].id,
        updated
      )
        .then(async ({ data }) => {
          if (data && data.updateVariant && data.updateVariant.variant) {
            const variant = data.updateVariant.variant;
            this.updateVariantCache(position, variant);
          }
        })
        .catch(() => {
          this.errorSaving = true;
        });
    },
    async save() {
      this.saving = true;
      this.errorSaving = false;
      await this.updateProduct();
      for (
        let position = 0;
        position < this.product.variants.length;
        position++
      ) {
        await this.updateVariant(position);
      }
      this.saving = false;
      this.saved = true;
      if (!this.errorSaving) {
        this.changed = false;
        this.$emit("change", false);
      }
    },
    cancel() {
      if (this.changed) {
        this.$swal
          .fire({
            title: "Cancelar",
            text: "Si cancelas, perderás tus cambios. ¿Estás seguro?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "No"
          })
          .then(result => {
            if (result.value) {
              this.$router.push({ name: "Products" });
            }
          });
      } else {
        this.$router.push({ name: "Products" });
      }
    }
  },
  watch: {
    titulo() {
      this.change();
    },
    vendor() {
      this.change();
    },
    productType() {
      this.change();
    },
    url() {
      this.change();
    },
    description() {
      this.change();
    },
    seo() {
      this.change();
    },
    metaDescription() {
      this.change();
    },
    published() {
      this.change();
    },
    tags() {
      this.change();
    }
  }
};
</script>
