<template>
  <div>
    <b-spinner label="Spinning" v-if="loading"></b-spinner>
    <div v-else>
      <base-header
        title="Productos"
        title-link="/productos"
        v-if="item"
        :map="['Mercado Libre', item.title, 'Fulfillment', 'Operaciones']"
      >
        <template v-slot:actions>
          <b-container>
            <b-dropdown variant="outline-info" text="Ver más">
              <b-dropdown-item
                v-if="$route.meta.action !== 'Stock de fulfillment'"
                :to="{
                  name: 'MercadoLibreItemFulfillmentStock',
                  params: {
                    integrationConfigId: integrationConfigId,
                    itemId: itemId
                  }
                }"
              >
                Stock
              </b-dropdown-item>
              <b-dropdown-item
                v-if="$route.meta.action !== 'Operaciones de fulfillment'"
                :to="{
                  name: 'MercadoLibreItemFulfillmentOperations',
                  params: {
                    integrationConfigId: integrationConfigId,
                    itemId: itemId
                  }
                }"
              >
                Operaciones
              </b-dropdown-item>
            </b-dropdown>
          </b-container>
        </template>
      </base-header>
      <b-container fluid="">
        <b-alert show variant="info" v-if="operations.length == 0">
          Este producto no tiene operaciones de fulfillment
        </b-alert>
        <div v-else>
          <b-table-simple small responsive>
            <b-thead>
              <b-tr>
                <b-th>Fecha</b-th>
                <b-th>Tipo</b-th>
                <b-th rowspan="2">Movimiento</b-th>
                <b-th colspan="3">Stock</b-th>
              </b-tr>
              <b-tr>
                <b-th>
                  <date-picker
                    v-model="dateRange"
                    valueType="format"
                    :range="true"
                    @change="listOperations(null)"
                  ></date-picker>
                </b-th>
                <b-th>
                  <v-select
                    v-model="type"
                    :options="typesList"
                    :reduce="x => x.value"
                    @input="listOperations(null)"
                  />
                </b-th>
                <b-th>Disponible</b-th>
                <b-th>No Disponible</b-th>
                <b-th>Total</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="operation of operations" :key="operation.id">
                <b-th>{{ operation.date_created }}</b-th>
                <b-th>{{ operation.type }}</b-th>
                <b-th
                  :class="{
                    'text-success': operation.detail.available_quantity > 0,
                    'text-danger': operation.detail.available_quantity < 0
                  }"
                >
                  {{ operation.detail.available_quantity > 0 ? "+" : ""
                  }}{{ operation.detail.available_quantity }}
                </b-th>
                <b-th>{{ operation.result.available_quantity }}</b-th>
                <b-th>{{ operation.result.not_available_quantity }}</b-th>
                <b-th>{{ operation.result.total }}</b-th>
              </b-tr>
            </b-tbody>
          </b-table-simple>
          <b-button
            v-if="scroll"
            class="m-2 float-right"
            @click="listOperations(scroll)"
          >
            Ver más
          </b-button>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import INTEGRATION_CONFIG_MERCADO_LIBRE from "../graphql/IntegrationConfigMercadoLibre.gql";
import BaseHeader from "@/components/BaseHeader.vue";
export default {
  name: "MercadoLibreItemFulfillmentOperations",
  props: {
    integrationConfigId: {
      type: String,
      required: true
    },
    itemId: {
      type: String,
      required: true
    }
  },
  components: {
    BaseHeader
  },
  data() {
    return {
      loading: true,
      integrationConfigMeli: {},
      item: {},
      dateRange: null,
      type: null,
      shipmentId: null,
      scroll: null,
      typesList: [
        { label: "Ajuste", value: "ADJUSTMENT" },
        { label: "Anulación de pedido", value: "SALE_CANCELATION" },
        { label: "Confirmación de Pedido", value: "SALE_CONFIRMATION" },
        { label: "Recepción", value: "INBOUND_RECEPTION" }
      ],
      operations: []
    };
  },
  beforeMount() {
    this.getIntegrationConfig();
  },
  methods: {
    getIntegrationConfig() {
      this.$apollo
        .query({
          query: INTEGRATION_CONFIG_MERCADO_LIBRE,
          variables: { id: this.integrationConfigId }
        })
        .then(result => {
          this.integrationConfigMeli =
            result.data.integrationConfigMercadoLibre;
          this.getItem();
        });
    },
    async getItem() {
      const res = await fetch(
        `https://api.mercadolibre.com/items/${this.itemId}?access_token=${this.integrationConfigMeli.accessToken}`
      );
      const item = await res.json();
      this.item = item;
      this.listOperations(null);
    },
    async listOperations(scroll) {
      if (!this.item.inventory_id) {
        // INICIO Datos de prueba. Eliminar antes de entregar
        // const data = {
        //   paging: {
        //     total: 4,
        //     scroll: ""
        //   },
        //   results: [
        //     {
        //       id: 306811273,
        //       seller_id: 384324657,
        //       inventory_id: "DEHW09303",
        //       date_created: "2020-06-18T18:43:26Z",
        //       type: "ADJUSTMENT",
        //       detail: {
        //         available_quantity: -5,
        //         not_available_quantity: 5,
        //         not_available_detail: [
        //           {
        //             status: "lost",
        //             quantity: 5
        //           }
        //         ]
        //       },
        //       result: {
        //         total: 100,
        //         available_quantity: 95,
        //         not_available_quantity: 5,
        //         not_available_detail: [
        //           {
        //             status: "lost",
        //             quantity: 5
        //           }
        //         ]
        //       },
        //       external_references: []
        //     },
        //     {
        //       id: 306745917,
        //       seller_id: 384324657,
        //       inventory_id: "DEHW09303",
        //       date_created: "2020-06-18T18:15:13Z",
        //       type: "SALE_CANCELATION",
        //       detail: {
        //         available_quantity: 10,
        //         not_available_detail: []
        //       },
        //       result: {
        //         total: 100,
        //         available_quantity: 100,
        //         not_available_quantity: 0,
        //         not_available_detail: []
        //       },
        //       external_references: [
        //         {
        //           type: "shipment_id",
        //           value: "28312959315"
        //         }
        //       ]
        //     },
        //     {
        //       id: 306718974,
        //       seller_id: 384324657,
        //       inventory_id: "DEHW09303",
        //       date_created: "2020-06-18T18:02:33Z",
        //       type: "SALE_CONFIRMATION",
        //       detail: {
        //         available_quantity: -10,
        //         not_available_detail: []
        //       },
        //       result: {
        //         total: 90,
        //         available_quantity: 90,
        //         not_available_quantity: 0,
        //         not_available_detail: []
        //       },
        //       external_references: [
        //         {
        //           type: "shipment_id",
        //           value: "28312961122"
        //         }
        //       ]
        //     },
        //     {
        //       id: 306705012,
        //       seller_id: 384324657,
        //       inventory_id: "DEHW09303",
        //       date_created: "2020-06-18T17:55:42Z",
        //       type: "INBOUND_RECEPTION",
        //       detail: {
        //         available_quantity: 100,
        //         not_available_detail: []
        //       },
        //       result: {
        //         total: 100,
        //         available_quantity: 100,
        //         not_available_quantity: 0,
        //         not_available_detail: []
        //       },
        //       external_references: [
        //         {
        //           type: "inbound_id",
        //           value: "0001"
        //         }
        //       ]
        //     }
        //   ],

        //   filters: [],
        //   available_filters: [],
        //   available_sort: [],
        //   sort: [],
        //   available_sorts: []
        // };
        // this.operations = data.results;
        // this.scroll = data.paging.scroll != "" ? data.paging.scroll : null;
        // FIN Datos de prueba. Eliminar antes de entregar
        this.loading = false;
        return;
      }

      var url = new URL(
        "https://api.mercadolibre.com/stock/fulfillment/operations/search"
      );
      url.searchParams.append(
        "access_token",
        this.integrationConfigMeli.accessToken
      );
      url.searchParams.append("seller_id", this.integrationConfigMeli.sellerId);
      url.searchParams.append("inventory_id", this.item.inventory_id);
      if (this.dateRange) {
        if (this.dateRange[0]) {
          url.searchParams.append("date_from", this.dateRange[0]);
        }
        if (this.dateRange[1]) {
          url.searchParams.append("date_to", this.dateRange[1]);
        }
      }
      if (this.type) {
        url.searchParams.append("type", this.type);
      }
      if (this.shipmentId) {
        url.searchParams.append(
          "external_references.shipment_id",
          this.shipmentId
        );
      }
      if (scroll) {
        url.searchParams.append("scroll", scroll);
      }
      const res = await fetch(url);
      const data = await res.json();
      this.operations = scroll ? this.operations + data.results : data.results;
      this.scroll = data.paging.scroll != "" ? data.paging.scroll : null;
      this.loading = false;
    }
  }
};
</script>

<style scoped>
.table td,
.table th {
  height: inherit;
}
</style>
