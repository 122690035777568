<template>
  <div>
    <b-alert
      v-if="error || success"
      show=""
      :variant="alertVariant"
      dismissible
    >
      {{ alertMessage }}
    </b-alert>
    <b-row v-if="loading">
      <b-col v-for="i in 6" :key="i" cols="4" class="mb-5">
        <b-skeleton
          animation="wave"
          type="input"
          :width="`${80 - Math.floor(Math.random() * 20)}%`"
        />
      </b-col>
    </b-row>
    <div v-else align-h="between">
      <b-row>
        <b-col xl="10" cols="12">
          <b-row>
            <b-col cols="12">
              <products-now-excluded-form
                :invalid-state="invalidState"
                v-model="previousProductsNowExcluded"
              />
            </b-col>
            <b-col xl="7" cols="12" class="mb-2">
              <b-row>
                <b-col cols="12" class="mb-2">
                  <h4 class="font-weight-bold my-auto">
                    Exclusión por rango de precio
                  </h4>
                </b-col>
                <b-col cols="12">
                  <b-table-simple borderless small class="mb-0">
                    <b-tr>
                      <b-th />
                      <b-th>
                        <span class="font-weight-bold text-muted">
                          Menor o igual
                        </span>
                      </b-th>
                      <b-th>
                        <span class="font-weight-bold text-muted">
                          Mayor o igual
                        </span>
                      </b-th>
                    </b-tr>
                    <b-tr>
                      <b-td>
                        <h4 class="font-weight-bold text-muted">
                          Centry precio normal:
                        </h4>
                      </b-td>
                      <b-td
                        style="padding-block: 0.1rem !important; padding-left: 0.3rem !important;"
                      >
                        <b-form-input
                          id="excludePriceCompareLte"
                          type="number"
                          number
                          v-model="values.excludePriceCompareLte"
                          @input="
                            val => handleInput(val, 'excludePriceCompareLte')
                          "
                        ></b-form-input>
                      </b-td>
                      <b-td
                        style="padding-block: 0.1rem !important; padding-left: 0.3rem !important;"
                      >
                        <b-form-input
                          id="excludePriceCompareGte"
                          type="number"
                          number
                          v-model="values.excludePriceCompareGte"
                          @input="
                            val => handleInput(val, 'excludePriceCompareGte')
                          "
                        ></b-form-input>
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>
                        <h4 class="font-weight-bold text-muted">
                          Centry precio oferta:
                        </h4>
                      </b-td>
                      <b-td
                        style="padding-block: 0.1rem !important; padding-left: 0.3rem !important; "
                      >
                        <b-form-input
                          id="excludePriceLte"
                          type="number"
                          number
                          v-model="values.excludePriceLte"
                          @input="val => handleInput(val, 'excludePriceLte')"
                        ></b-form-input>
                      </b-td>
                      <b-td
                        style="padding-block: 0.1rem !important;padding-left: 0.3rem !important;"
                      >
                        <b-form-input
                          id="excludePriceGte"
                          type="number"
                          number
                          v-model="values.excludePriceGte"
                          @input="val => handleInput(val, 'excludePriceGte')"
                        ></b-form-input>
                      </b-td>
                    </b-tr>
                  </b-table-simple>
                </b-col>
              </b-row>
            </b-col>
            <b-col>
              <hr class="hr-vertical m-auto" style="height: 100%" />
            </b-col>
            <b-col xl="4" cols="12" class="mb-2">
              <b-row>
                <b-col cols="12" class="mb-2">
                  <h4 class="font-weight-bold my-auto">
                    Exclusión por estado
                  </h4>
                </b-col>
                <b-col cols="12">
                  <b-form-checkbox v-model="values.excludePaused">
                    Excluir productos con estado pausado
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
        <b-col xl="2" cols="12">
          <b-button
            variant="info"
            :disabled="!changed || saving"
            :title="changed ? '' : 'No hay cambios'"
            @click="save"
            class="mb-3 w-100"
          >
            <span v-if="saving"><b-spinner label="Spinning"></b-spinner></span>
            <span v-else>Guardar</span>
          </b-button>
          <b-button
            variant="outline-info"
            class="w-100 px-0"
            :disabled="!changed || saving"
            @click="resetData()"
          >
            <span>Restablecer</span>
          </b-button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import UPDATE_INTEGRATION_CONFIG_EXCLUSION from "../../graphql/IntegrationConfig/UpdateIntegrationConfigExclusion.gql";
import EXCLUSION_BY_PRICE_RANGE from "../../graphql/IntegrationConfig/ExclusionByPriceRange.gql";
import ProductsNowExcludedForm from "./ProductsNowExcludedForm.vue";
import { mapMutations } from "vuex";

export default {
  name: "ExclusionByPriceRange",
  components: {
    ProductsNowExcludedForm
  },
  props: {
    ic: {
      type: Object,
      default() {
        return {};
      }
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      originalValues: {},
      values: {},
      loading: false,
      saving: false,
      errorMessage: null,
      error: false,
      success: false,
      previousProductsNowExcluded: null
    };
  },
  async mounted() {
    await this.getExclusionByPriceRange();
  },
  computed: {
    changed() {
      return !this.$objSimpleCompare(this.originalValues, this.values);
    },
    invalidState() {
      return (
        ((this.changed && !this.allNull) ||
          (this.originalValues.excludePaused !== this.values.excludePaused &&
            this.values.excludePaused)) &&
        this.previousProductsNowExcluded === null
      );
    },
    allNull() {
      let newPriceCompareLte = this.values.excludePriceCompareLte;
      let newPriceCompareGte = this.values.excludePriceCompareGte;
      let newPriceLte = this.values.excludePriceLte;
      let newPriceGte = this.values.excludePriceGte;
      return (
        newPriceCompareGte === null &&
        newPriceCompareLte === null &&
        newPriceGte === null &&
        newPriceLte === null
      );
    },
    alertVariant() {
      return this.error ? "danger" : "success";
    },

    alertMessage() {
      return this.error
        ? "Ha ocurrido un error guardando los datos: " + this.errorMessage
        : "Guardado exitosamente";
    }
  },
  methods: {
    ...mapMutations(["setIntegrationConfigChanged"]),
    /**
     * Se encarga de obtener el valor actual de
     * los campos para la transformacion de valores
     */
    async getExclusionByPriceRange() {
      this.$apollo
        .query({
          query: EXCLUSION_BY_PRICE_RANGE,
          variables: { integrationConfigId: this.ic.id }
        })
        .then(({ data }) => {
          let values = this.$dup(data.integrationConfig);
          delete values.id;
          delete values.__typename;
          let tempValues = {};
          Object.keys(values).forEach(val => {
            tempValues[val] = values[val];
          });
          this.values = this.$dup(tempValues);
          this.originalValues = this.$dup(this.values);
        });
    },
    /**
     * Envia la mutacion para guardar los nuevos
     * valores de los campos para la sinncronizacion
     */
    save() {
      this.saving = true;
      this.sofResetData();
      if (!this.invalidState) {
        let patch = Object.assign({ id: this.ic.id }, this.values);
        this.$apollo
          .mutate({
            mutation: UPDATE_INTEGRATION_CONFIG_EXCLUSION,
            variables: {
              patch: patch,
              previousProductsNowExcluded: this.previousProductsNowExcluded
            }
          })
          .then(({ data }) => {
            this.saving = false;
            let response = data.updateIntegrationConfigExclusions;
            if (response.result) {
              this.success = true;
              this.originalValues = this.$dup(this.values);
              this.previousProductsNowExcluded = null;
            } else {
              this.errorMessage = response.errors;
              this.error = true;
            }
          });
      } else {
        this.saving = false;
        this.errorMessage = "Hay campos que se deben rellenar";
        this.error = true;
      }
    },
    /**
     * Se encarga de resetear los datos
     */
    resetData() {
      this.loading = true;
      this.sofResetData();
      this.saving = false;
      this.values = this.$dup(this.originalValues);
      this.previousProductsNowExcluded = null;
      this.loading = false;
    },
    /**
     * Se encarga de resetear solo las
     * variables de exito y error
     */
    sofResetData() {
      this.error = false;
      this.success = false;
      this.errorMessage = null;
    },
    /**
     * Actualiza el valor de los input, si el valor
     * es un string vacio lo reemplaza por null
     * @param {String} val - valor del input
     * @param {String} model - model del input
     */
    handleInput(val, model) {
      if (!val) {
        this.values[model] = null;
      }
    }
  },
  watch: {
    changed(val) {
      if (!val) {
        this.previousProductsNowExcluded = null;
      }
      this.setIntegrationConfigChanged(val);
    },
    visible(val) {
      if (val) {
        this.resetData();
      }
    }
  }
};
</script>

<style scoped>
.hr-vertical {
  border: none;
  border-left: 1px solid hsla(200, 10%, 50%, 100);
  width: 5px;
}
</style>
