var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('ApolloQuery',{attrs:{"query":require('../graphql/MagentoApiAttributeSets.gql'),"variables":{
          ic_magento_id: this.$route.params.integration_config_id
        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var data = ref.result.data;
return _vm._l((_vm.getAttributeSets(data)),function(as,index){return _c('b-list-group',{key:index},[_c('b-list-group-item',{staticClass:"flex-column align-items-start",attrs:{"to":{
                name: 'MagentoAttributes',
                params: {
                  integration_config_id: _vm.$route.params.integration_config_id,
                  attribute_set_id: as.id,
                  attribute_set_name: as.name
                }
              }}},[_c('div',{staticClass:"d-flex w-100 justify-content-between"},[_c('h5',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(as.name)+" ")])])])],1)})}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }