<template>
  <div>
    <base-alert-with-count-down
      v-model="showAlert"
      :alert-variant="alertVariant"
      :dismiss-secs="error ? 10 : 5"
    >
      {{ alertMessage }}</base-alert-with-count-down
    >
    <b-row>
      <b-col xl="10" cols="12">
        <b-row>
          <b-col cols="12">
            <h4 class="font-weight-bold my-auto">
              Atributos generales del bloqueo
            </h4>

            <hr />
          </b-col>
          <b-col md="4" sm="6" cols="12">
            <input-with-skeleton
              :loading="isLoadingBlockage"
              label="Nombre"
              :invalid-feedback="validation.name.invalidFeedback"
              :state="validation.name.valid"
              v-model="name"
            />
          </b-col>
          <b-col md="4" sm="6" cols="12">
            <input-with-skeleton
              :loading="isLoadingBlockage"
              label="Descripción"
              :invalid-feedback="validation.description.invalidFeedback"
              :state="validation.description.valid"
              v-model="description"
            />
          </b-col>
          <b-col md="4" sm="6" cols="12">
            <b-form-group label="Tipo de bloqueo">
              <b-form-radio-group
                v-if="!isLoadingBlockage"
                id="radio-group-2"
                v-model="allInventory"
                name="radio-sub-component"
              >
                <b-form-radio name="some-radios" :value="true"
                  >Para todo el inventario</b-form-radio
                >
                <b-form-radio name="some-radios" :value="false"
                  >Utilizar filtros</b-form-radio
                >
              </b-form-radio-group>
              <b-skeleton v-else />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <h4 class="font-weight-bold my-auto">
              Atributos extras para el bloqueo
            </h4>
            <hr />
          </b-col>
          <b-col md="4" sm="6" cols="12">
            <input-with-skeleton
              :loading="isLoadingBlockage"
              label="Precio regular - Limite inferior"
              type="number"
              :invalid-feedback="
                validation.lowerLimitRegularPrice.invalidFeedback
              "
              :state="validation.lowerLimitRegularPrice.valid"
              v-model="lowerLimitRegularPrice"
            />
          </b-col>
          <b-col md="4" sm="6" cols="12">
            <input-with-skeleton
              :loading="isLoadingBlockage"
              label="Precio oferta - Limite inferior"
              type="number"
              :invalid-feedback="validation.lowerLimitSalePrice.invalidFeedback"
              :state="validation.lowerLimitSalePrice.valid"
              v-model="lowerLimitSalePrice"
            />
          </b-col>
        </b-row>
        <b-row v-if="!allInventory">
          <b-col cols="12">
            <h4 class="font-weight-bold my-auto">
              Filtros
            </h4>
            <hr />
          </b-col>
        </b-row>
        <b-row
          v-if="!allInventory"
          :style="
            windowWidth > 1200 ? 'overflow-x: auto; flex-wrap: nowrap;' : ''
          "
        >
          <b-col xl="4" sm="6" cols="12" class="my-2">
            <b-row align-h="between" no-gutters>
              <b-col class="mr-2">
                <b-form-group label="Tipo de productos a mostrar:">
                  <v-select
                    v-model="productType"
                    :options="productsType"
                    :reduce="type => type.value"
                    :clearable="false"
                  ></v-select>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Filtro por nombre o sku:">
                  <b-form-tags
                    v-model="keyword"
                    style="height: 30px !important; font-size: 15px"
                    separator=","
                    remove-on-delete
                    placeholder="Nombre o SKU"
                    @keydown.native.enter="updateKeyword"
                    @keydown.native.delete="updateSearch"
                    @input="updateSearch"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row align-h="between" no-gutters>
              <b-col class="pr-0">
                <span class="font-weight-bold text-muted float-right">
                  {{ filterCaption }}
                  <b-button
                    v-if="filterCaption.length"
                    size="sm"
                    @click="cleanFilters"
                  >
                    Limpiar filtros
                  </b-button>
                </span>
              </b-col>
            </b-row>
            <b-table-simple
              class="mb-2"
              hover
              small
              responsive
              sticky-header="350px"
              style="border-bottom: 1px solid #dee2e6;"
            >
              <b-thead>
                <b-tr>
                  <b-th>Nombre</b-th>
                  <b-th>SKU</b-th>
                  <b-th>Seleccionado</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-if="!products.length && !loadingProducts">
                  <b-td colspan="7">No hay productos para mostrar</b-td>
                </b-tr>
                <b-tr v-for="(product, i) of products" :key="i">
                  <b-td
                    class="font-weight-normal"
                    style="padding: 5px 10px !important"
                    >{{ product.sku }}</b-td
                  >
                  <b-td
                    class="font-weight-normal"
                    style="padding: 5px 10px !important"
                    >{{ product.name }}</b-td
                  >
                  <b-td
                    class="font-weight-normal"
                    style="padding: 5px 10px !important"
                  >
                    <b-form-checkbox
                      :checked="productIdsSelected.includes(product.id)"
                      @change="evt => handleChange(evt, product.id)"
                      switch
                      :state="false"
                    >
                    </b-form-checkbox>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <b-skeleton-table v-if="loadingProducts" :columns="3" :rows="10" />
            <div v-else>
              <b-button
                v-if="hasNextPage()"
                class="float-right"
                @click="getProducts()"
              >
                Ver más
              </b-button>
              <b-alert
                v-else-if="!loadingProducts"
                show
                variant="light"
                class="text-center"
              >
                No hay más datos para mostrar.
              </b-alert>
            </div>
          </b-col>
          <b-col
            xl="4"
            sm="6"
            cols="12"
            class="my-2"
            :style="windowWidth > 1200 ? 'min-width: fit-content' : ''"
          >
            <vMultiselectListbox
              v-if="!loadingBrands && !isLoadingBlockage"
              ref="exclusionSelector"
              style="width: 100%; height: 450px"
              v-model="brandIdsSelected"
              :options="brands"
              :reduce-display-property="option => option.name"
              :reduce-value-property="option => option.id"
              :highlightDiff="true"
              search-options-placeholder="Buscar marcas"
              selected-options-placeholder="Buscar marcas seleccionadas"
              no-options-text="No hay marcas"
              selected-no-options-text="No hay marcas seleccionadas"
              no-options-found-text="No se encontró la marca"
              no-selected-options-found-text="No se encontró la marca"
            />
            <b-skeleton-img style="width: 100%; height: 450px" v-else />
          </b-col>
          <b-col xl="4" sm="6" cols="12" class="my-2">
            <vMultiselectListbox
              v-if="!loadingCategories && !isLoadingBlockage"
              ref="exclusionSelector"
              style="width: 100%; height: 450px"
              v-model="categoryIdsSelected"
              :options="categories"
              :reduce-display-property="option => option.name"
              :reduce-value-property="option => option.id"
              :highlightDiff="true"
              search-options-placeholder="Buscar categorias"
              selected-options-placeholder="Buscar categorias seleccionadas"
              no-options-text="No hay categorias"
              selected-no-options-text="No hay categorias seleccionadas"
              no-options-found-text="No se encontró la categorias"
              no-selected-options-found-text="No se encontró la categorias"
            />
            <b-skeleton-img style="width: 100%; height: 450px" v-else />
          </b-col>
        </b-row>
      </b-col>
      <b-col xl="2" cols="12">
        <b-button
          variant="info"
          :disabled="!changed || saving"
          :title="changed ? '' : 'No hay cambios'"
          @click="save"
          class="mb-3 w-100"
        >
          <span v-if="saving">
            Guardando
            <b-spinner label="Spinning" />
          </span>
          <span v-else>Guardar</span>
        </b-button>
        <b-button
          variant="outline-info"
          class="w-100 px-0"
          :disabled="!changed || saving"
          @click="resetData()"
        >
          <span>Restablecer</span>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import InputWithSkeleton from "../../components/Base/InputWithSkeleton.vue";
import BaseAlertWithCountDown from "../../components/Base/BaseAlertWithCountDown.vue";
import ALL_PRODUCTS from "../../graphql/IntegrationConfig/AllProductsForExclusion.gql";
import ALL_BRANDS from "../../graphql/AllBrands.gql";
import ALL_CATEGORIES from "../../graphql/AllCategories.gql";
import UPDATE_BLOCKAGE from "../../graphql/Blockages/UpdateBlockage.gql";
import CREATE_BLOCKAGE from "../../graphql/Blockages/CreateBlockage.gql";
import { mapState } from "vuex";
import vMultiselectListbox from "vue-multiselect-listbox";
import "vue-multiselect-listbox/dist/vue-multi-select-listbox.css";
export default {
  name: "BlockageEdit",
  props: {
    blockage: {
      type: Object,
      default() {
        return {};
      }
    },
    isLoadingBlockage: Boolean
  },
  components: {
    InputWithSkeleton,
    BaseAlertWithCountDown,
    vMultiselectListbox
  },
  data() {
    return {
      name: this.blockage?.name || "",
      description: this.blockage?.description || "",
      allInventory:
        this.blockage?.allInventory == null
          ? true
          : this.blockage?.allInventory,
      lowerLimitSalePrice: this.blockage?.lowerLimitSalePrice || null,
      lowerLimitRegularPrice: this.blockage?.lowerLimitRegularPrice || null,
      brandIdsSelected: this.blockage?.brandIds ? this.blockage.brandIds : [],
      productIdsSelected: this.blockage?.productIds
        ? this.blockage.productIds
        : [],
      categoryIdsSelected: this.blockage?.categoryIds
        ? this.blockage.categoryIds
        : [],
      loadingBrands: false,
      loadingCategories: false,
      loadingProducts: false,
      brands: [],
      categories: [],
      showAlert: false,
      saving: false,
      error: false,
      errorMessage: null,
      filters: {
        keyword: "",
        cursor: null,
        first: 15
      },
      keyword: [],
      products: [],
      newProductsCount: 0,
      windowWidth: window.innerWidth,
      productsType: [
        { label: "Todos los productos", value: "all" },
        { label: "Productos seleccionados", value: "selected" },
        { label: "No seleccionados", value: "not_selected" }
      ],
      productType: "all"
    };
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
    if (!this.isConsolidated()) {
      this.name = "";
      this.description = "";
    } else {
      if (!this.allInventory) {
        this.getBrands();
        this.getCategories();
        this.getProducts();
      }
    }
  },
  computed: {
    ...mapState(["currentUser"]),
    validation() {
      return {
        name: {
          valid: this.name?.length > 0,
          invalidFeedback: "El nombre es obligatorio"
        },
        description: {
          valid: this.description?.length > 0,
          invalidFeedback: "La descripción es obligatoria"
        },
        lowerLimitRegularPrice: {
          valid: ![null, ""].includes(this.lowerLimitRegularPrice),
          invalidFeedback: "Debes señalar un limite para el precio regular"
        },
        lowerLimitSalePrice: {
          valid: ![null, ""].includes(this.lowerLimitSalePrice),
          invalidFeedback: "Debes señalar un limite para el precio de oferta"
        }
      };
    },
    valid() {
      let valid = true;
      Object.keys(this.validation).forEach(x => {
        valid = valid && this.validation[x].valid;
      });
      return valid;
    },
    changed() {
      return (
        (this.blockage?.name || "") != this.name ||
        (this.blockage?.description || "") != this.description ||
        this.blockage?.allInventory != this.allInventory ||
        (this.blockage?.lowerLimitSalePrice || null) !=
          this.lowerLimitSalePrice ||
        (this.blockage?.lowerLimitRegularPrice || null) !=
          this.lowerLimitRegularPrice ||
        !this.$arrayDeepCompare(
          [...this.productIdsSelected].sort(),
          [...this.blockage.productIds].sort()
        ) ||
        !this.$arrayDeepCompare(
          [...this.categoryIdsSelected].sort(),
          [...this.blockage.categoryIds].sort()
        ) ||
        !this.$arrayDeepCompare(
          [...this.brandIdsSelected].sort(),
          [...this.blockage.brandIds].sort()
        )
      );
    },
    alertVariant() {
      return this.error ? "danger" : "success";
    },
    alertMessage() {
      return this.error
        ? "Ha ocurrido un error guardando los datos: " + this.errorMessage
        : "Bloqueo guardado exitosamente";
    },
    filterCaption() {
      let caption = [];
      if (this.filters.keyword.length) {
        caption.push(this.filters.keyword);
      }
      return caption.length ? "Buscando por: " + caption.join(" - ") : "";
    }
  },
  methods: {
    /**
     * Indica si es creacion o no
     * @return {Boolean}
     */
    isConsolidated() {
      if (this.$route.name == "BlockageNew") {
        return false;
      } else {
        return true;
      }
    },
    /**
     * Se encarga de enviar la mutacion que corresponde
     * al guardar los datos
     */
    async save() {
      this.error = false;
      this.errorMessage = null;
      if (!this.valid) {
        this.error = true;
        this.errorMessage = "Hay campos que se deben rellenar";
        this.showAlert = true;
      } else {
        this.saving = true;
        let action = "updated";
        if (this.isConsolidated()) {
          await this.updateBlockage();
        } else {
          await this.createBlockage();
          action = "created";
        }
        if (!this.error) {
          this.$router.push({
            name: "Blockages",
            params: {
              action: action
            }
          });
        }
      }
    },
    /**
     * Envia la mutacion para actualizar un bloqueo
     */
    async updateBlockage() {
      let blockage = this.getVariables();
      await this.$apollo
        .mutate({
          mutation: UPDATE_BLOCKAGE,
          variables: {
            blockage: blockage
          }
        })
        .then(({ data }) => {
          this.manageResult(data, "updateBlockage");
        })
        .catch(e => {
          this.error = true;
          this.errorMessage = e.message;
          this.showAlert = true;
          this.saving = false;
        });
    },
    /**
     * Envia la mutacion para crear un bloqueo
     */
    async createBlockage() {
      let blockage = this.getVariables();
      await this.$apollo
        .mutate({
          mutation: CREATE_BLOCKAGE,
          variables: {
            blockage: blockage
          }
        })
        .then(({ data }) => {
          this.manageResult(data, "createBlockage");
        })
        .catch(e => {
          this.error = true;
          this.errorMessage = e.message;
          this.showAlert = true;
          this.saving = false;
        });
    },
    /**
     * Se encarga de resetear los valores del formulario
     */
    resetData() {
      this.name = this.blockage.name || "";
      this.description = this.blockage.description || "";
      this.allInventory =
        this.blockage.allInventory != null ? this.blockage.allInventory : true;
      this.lowerLimitSalePrice = this.blockage.lowerLimitSalePrice || null;
      this.lowerLimitRegularPrice =
        this.blockage.lowerLimitRegularPrice || null;
      this.brandIdsSelected = [...this.blockage.brandIds] || [];
      this.categoryIdsSelected = [...this.blockage.categoryIds] || [];
      this.productIdsSelected = [...this.blockage.productIds] || [];
      this.cleanFilters();
    },
    /**
     * Se encarga de ccrear el objeto con las variables
     * para actualizar o crear el bloqueo
     * @return {Object}
     */
    getVariables() {
      let toUpdate = {};
      if (this.isConsolidated()) {
        toUpdate["id"] = this.blockage.id;
      }
      if (this.blockage?.description != this.description) {
        toUpdate["description"] = this.description;
      }
      if (this.blockage?.name != this.name) {
        toUpdate["name"] = this.name;
      }
      if (this.blockage?.allInventory != this.allInventory) {
        toUpdate["allInventory"] = this.allInventory;
      }
      if (this.blockage?.lowerLimitSalePrice != this.lowerLimitSalePrice) {
        toUpdate["lowerLimitSalePrice"] = this.lowerLimitSalePrice;
      }
      if (
        this.blockage?.lowerLimitRegularPrice != this.lowerLimitRegularPrice
      ) {
        toUpdate["lowerLimitRegularPrice"] = this.lowerLimitRegularPrice;
      }
      if (
        !this.$arrayDeepCompare(
          [...this.brandIdsSelected].sort(),
          [...this.blockage.brandIds].sort()
        )
      ) {
        toUpdate["brandIds"] = this.brandIdsSelected;
      }
      if (
        !this.$arrayDeepCompare(
          [...this.categoryIdsSelected].sort(),
          [...this.blockage.categoryIds].sort()
        )
      ) {
        toUpdate["categoryIds"] = this.categoryIdsSelected;
      }
      if (
        !this.$arrayDeepCompare(
          [...this.productIdsSelected].sort(),
          [...this.blockage.productIds].sort()
        )
      ) {
        toUpdate["productIds"] = this.productIdsSelected;
      }
      return toUpdate;
    },
    /**
     * Obtiene todas las marcas
     */
    async getBrands() {
      if (this.brands.length) {
        return;
      }
      this.loadingBrands = true;
      await this.$getAllPages(
        ALL_BRANDS,
        { companyId: this.currentUser.company.id },
        "allBrands"
      )
        .then(result => {
          this.brands = result.map(x => x.node);
          this.loadingBrands = false;
        })
        .catch(() => {
          this.loadingBrands = false;
        });
    },
    /**
     * Obtiene todas las categories
     */
    async getCategories() {
      if (this.categories.length) {
        return;
      }
      this.loadingCategories = true;
      await this.$getAllPages(
        ALL_CATEGORIES,
        { companyId: this.currentUser.company.id },
        "allCategories"
      )
        .then(result => {
          this.categories = result.map(x => x.node);
          this.loadingCategories = false;
        })
        .catch(() => {
          this.loadingCategories = false;
        });
    },
    /**
     * Obtiene los productos segun los keyword y cursor
     */
    async getProducts() {
      this.loadingProducts = true;
      let variables = this.$dup(this.filters);
      if (this.productType == "selected") {
        variables["ids"] = this.blockage.productIds;
      } else if (this.productType == "not_selected") {
        variables["noIds"] = this.blockage.productIds;
      }
      await this.$apollo
        .query({ query: ALL_PRODUCTS, variables: variables })
        .then(({ data }) => {
          if (data.allProducts.edges.length) {
            this.products = this.products.concat(
              data.allProducts.edges.map(x => x.node)
            );
          }
          this.newProductsCount = data.allProducts.edges.length;
          this.filters.cursor = data.allProducts.pageInfo.endCursor;
          this.loadingProducts = false;
        });
    },
    /**
     * Consulta si se pueden consultar mas datos o no
     * @return {Boolean}
     */
    hasNextPage() {
      return this.newProductsCount == this.filters.first;
    },
    /**
     * Limpia los filtros
     */
    cleanFilters() {
      this.keyword = [];
      this.filters.keyword = "";
      this.filters.cursor = null;
    },
    /**
     * Agrega el id del producto a "para incluir" o
     * "para excluir" según el valor del checkbox
     * @param {Boolean} evt
     * @param {String} id
     */
    handleChange(evt, id) {
      if (evt) {
        this.addToFilter(id);
      } else {
        this.removeFromFilter(id);
      }
    },
    /**
     * Cambia el keyword al formato interesado
     */
    updateKeyword() {
      this.filters.keyword = this.keyword.join(",");
    },
    /**
     * El valor del filtro keyword solo si se ingresa un keyword
     */
    updateSearch() {
      if (!this.keyword.length) {
        this.updateKeyword();
      }
    },
    /**
     * Añade el id de un producto a la lista del filtro
     * @param {String} id - product_id
     */
    addToFilter(id) {
      this.productIdsSelected.push(id);
    },
    /**
     * Elimina el id de un producto de la lista del filtro
     * @param {String} id - product_id
     */
    removeFromFilter(id) {
      let i = this.productIdsSelected.findIndex(i => i === id);
      this.productIdsSelected.splice(i, 1);
    },
    /**
     * Se encarga de actualizar las variables del bloqueo o
     * del mensaje de error segun la respuesta de la mutacion
     * @param {Object} data - respuesta de mutacion
     * @param {String} path - path dependiendo si es creacion o actualizacion
     */
    manageResult(data, path) {
      if (!data[path].result) {
        this.error = true;
        this.errorMessage = data[path].error;
        this.showAlert = true;
      } else {
        if (path == "createBlockage") {
          this.blockage.id = data.createBlockage.blockageId;
        }
        this.blockage.name = this.name;
        this.blockage.description = this.description;
        this.blockage.allInventory = this.allInventory;
        this.blockage.lowerLimitSalePrice = this.lowerLimitSalePrice;
        this.blockage.lowerLimitRegularPrice = this.lowerLimitRegularPrice;
        this.blockage.brandIds = this.brandIdsSelected;
        this.blockage.productIds = this.productIdsSelected;
        this.blockage.categoryIds = this.categoryIdsSelected;
      }
      this.saving = false;
    }
  },
  watch: {
    blockage: {
      handler(val) {
        this.name = val.name || "";
        this.description = val.description || "";
        this.allInventory = val.allInventory != null ? val.allInventory : true;
        this.lowerLimitSalePrice = val.lowerLimitSalePrice || null;
        this.lowerLimitRegularPrice = val.lowerLimitRegularPrice || null;
        this.brandIdsSelected = [...val.brandIds] || [];
        this.categoryIdsSelected = [...val.categoryIds] || [];
        this.productIdsSelected = [...val.productIds] || [];
      },
      deep: true
    },
    allInventory(val) {
      if (!val) {
        this.getBrands();
        this.getCategories();
        this.getProducts();
      }
    },
    "filters.keyword"() {
      this.products = [];
      this.filters.cursor = null;
      this.getProducts();
    },
    productType() {
      this.products = [];
      this.filters.cursor = null;
      this.getProducts();
    }
  }
};
</script>
