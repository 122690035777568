<template>
  <div>
    <h4 class="font-weight-bold m-3">Artículos de la promoción</h4>

    <b-alert
      v-if="
        requiredMessage.someProduct &&
          !loadingCategories &&
          !loadingBrands &&
          !loadingCollections
      "
      show=""
      variant="danger"
    >
      Seleccione alguna opción o aplique a todos los productos
    </b-alert>

    <b-form-group>
      <b-form-radio v-model="allProducts" :value="true">
        Aplicar a todos los productos
      </b-form-radio>

      <b-form-radio v-model="allProducts" :value="false">
        Aplicar a los siguientes productos
      </b-form-radio>
    </b-form-group>

    <div v-if="!allProducts">
      <div v-if="loadingCategories">
        <b-spinner label="Spinning"></b-spinner>
        <br />
      </div>
      <b-form-group v-else label="Categorias">
        <v-select
          v-model="equalCategories"
          :options="inclusionOptions"
          :clearable="false"
        ></v-select>
        <v-select
          multiple
          placeholder="Seleccione categorías"
          v-model="selectedCategories"
          :options="categories"
        ></v-select>
      </b-form-group>

      <div v-if="loadingBrands">
        <b-spinner label="Spinning"></b-spinner>
        <br />
      </div>
      <b-form-group v-else label="Marcas">
        <v-select
          v-model="equalBrands"
          :options="inclusionOptions"
          :clearable="false"
        ></v-select>
        <v-select
          multiple
          placeholder="Seleccione marcas"
          v-model="selectedBrands"
          :options="brands"
        ></v-select>
      </b-form-group>

      <div v-if="loadingCollections">
        <b-spinner label="Spinning"></b-spinner>
        <br />
      </div>
      <b-form-group v-else label="Colecciones">
        <v-select
          v-model="equalCollections"
          :options="inclusionOptions"
          :clearable="false"
        ></v-select>
        <v-select
          multiple
          placeholder="Seleccione colecciones"
          v-model="selectedCollections"
          :options="collections"
        ></v-select>
      </b-form-group>

      <b-form-group label="Productos">
        <v-select
          v-model="equalProducts"
          :options="inclusionOptions"
          :clearable="false"
        ></v-select>
        <v-select
          multiple
          placeholder="Seleccione productos"
          v-model="selectedProducts"
          :options="products"
        ></v-select>
      </b-form-group>

      <b-form-group label="SKUs">
        <v-select
          v-model="equalSKUs"
          :options="inclusionOptions"
          :clearable="false"
        ></v-select>
        <v-select
          multiple
          placeholder="Seleccione SKUs"
          v-model="selectedSkus"
          :options="skus"
        ></v-select>
      </b-form-group>

      <b-button variant="outline-info" @click="showModal = !showModal">
        <b-icon-cloud-download></b-icon-cloud-download> Obtener Productos y SKUs
      </b-button>
      <vtex-base-promotion-products-and-skus-data-modal
        v-model="showModal"
        :vtexIntegrationConfigId="vtexIntegrationConfigId"
        @products="setProducts"
        @skus="setSkus"
      />
    </div>

    <hr />
  </div>
</template>

<script>
import GET_VTEX_CATEGORIES from "@/graphql/AllVtexCategories.gql";
import INTEGRATION_CONFIG_VTEX_BRANDS_LIST from "@/graphql/IntegrationConfigVtexBrandsList.gql";
import INTEGRATION_CONFIG_VTEX_ALL_COLLECTIONS from "@/graphql/IntegrationConfigVtexAllCollections.gql";

import VtexBasePromotionProductsAndSkusDataModal from "../VtexBasePromotionProductsAndSkusDataModal.vue";

export default {
  name: "VtexBasePromotionAllItems",
  components: {
    VtexBasePromotionProductsAndSkusDataModal
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    vtexIntegrationConfigId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      promotion: this.value,
      allProducts: this.checkIfAllProducts(this.value),
      equalCategories: null,
      categories: [],
      selectedCategories: [],
      loadingCategories: true,
      equalBrands: null,
      brands: [],
      selectedBrands: [],
      loadingBrands: true,
      equalCollections: null,
      collections: [],
      selectedCollections: [],
      loadingCollections: true,
      equalProducts: null,
      products: [],
      selectedProducts: [],
      equalSKUs: null,
      skus: [],
      selectedSkus: [],
      inclusionOptions: [
        { value: true, label: "Es igual a" },
        { value: false, label: "Diferente de" }
      ],
      filledRequiredFields: this.checkIfAllProducts(this.value),
      showModal: false
    };
  },
  /**
   * Se obtienen los datos de categorias, marcas, colecciones, productos y skus.
   * Los datos se guardan en los selectores correspondientes de la vista.
   * Tambien se asignan los valores seleccionados de la promoción a los de la vista.
   */
  async mounted() {
    this.setCategories(this.promotion);
    this.setBrands(this.promotion);
    this.setCollections(this.promotion);
    this.setProvisionalProducts(this.promotion);
    this.setProvisionalSkus(this.promotion);
  },
  methods: {
    /**
     * Entrega la opcion equivalente en inclusionOptions.
     * @param {bool} isInclusive - Si el item es inclusivo (true) o exclusivo (false).
     */
    findInclusionOption(isInclusive) {
      return this.inclusionOptions.find(option => option.value === isInclusive);
    },
    /**
     * Revisa si las categorias seleccionadas son inclusivas o exclusivas.
     * Luego, obiene las categorias desde Vtex y las guarda. Por último,
     * revisa las categorias ya seleccionadas de la promoción.
     * @param {Object} promotion - Promoción.
     */
    async setCategories(promotion) {
      this.equalCategories = this.findInclusionOption(
        promotion.categoriesAreInclusive
      );
      this.getVtexCategories().then(() => {
        this.setSelectedDataFromPromotion(
          "selectedCategories",
          promotion.categories,
          this.categories
        );
      });
    },
    /**
     * Obtiene las categorias de Vtex y las guarda para el selector correspondiente.
     */
    async getVtexCategories() {
      return this.$apollo
        .query({
          query: GET_VTEX_CATEGORIES,
          variables: {
            ic: this.vtexIntegrationConfigId,
            limit: 100,
            offset: 0,
            flatten: true
          }
        })
        .then(({ data }) => {
          this.categories = data.allVtexCategories.map(category => ({
            value: category.id,
            label: category.name + " (" + category.id + ")"
          }));
          this.loadingCategories = false;
        });
    },
    /**
     * Revisa si las marcas seleccionadas son inclusivas o exclusivas.
     * Luego, obiene las marcas desde Vtex y las guarda. Por último,
     * revisa las marcas ya seleccionadas de la promoción.
     * @param {Object} promotion - Promoción.
     */
    async setBrands(promotion) {
      this.equalBrands = this.findInclusionOption(promotion.brandsAreInclusive);
      this.getVtexBrands().then(() => {
        this.setSelectedDataFromPromotion(
          "selectedBrands",
          promotion.brands,
          this.brands
        );
      });
    },
    /**
     * Obtiene las marcas de Vtex y las guarda para el selector correspondiente.
     */
    async getVtexBrands() {
      return this.$apollo
        .query({
          query: INTEGRATION_CONFIG_VTEX_BRANDS_LIST,
          variables: {
            id: this.vtexIntegrationConfigId
          }
        })
        .then(({ data }) => {
          this.brands = data.integrationConfigVtex.brandsList.map(brand => ({
            value: brand.id,
            label: brand.name + " (" + brand.id + ")"
          }));
          this.loadingBrands = false;
        });
    },
    /**
     * Revisa si las colecciones seleccionadas son inclusivas o exclusivas.
     * Luego, obiene las colecciones desde Vtex y las guarda. Por último,
     * revisa las colecciones ya seleccionadas de la promoción.
     * @param {Object} promotion - Promoción.
     */
    async setCollections(promotion) {
      this.equalCollections = this.findInclusionOption(
        promotion.collectionsIsInclusive
      );
      this.getVtexCollections().then(() => {
        this.setSelectedDataFromPromotion(
          "selectedCollections",
          promotion.collections,
          this.collections
        );
      });
    },
    /**
     * Obtiene las colecciones de Vtex y las guarda para el selector correspondiente.
     */
    async getVtexCollections() {
      return this.$apollo
        .query({
          query: INTEGRATION_CONFIG_VTEX_ALL_COLLECTIONS,
          variables: {
            id: this.vtexIntegrationConfigId
          }
        })
        .then(({ data }) => {
          this.collections = data.integrationConfigVtex.allCollections.map(
            collection => ({
              value: collection.id,
              label: collection.name + " (" + collection.id + ")"
            })
          );
          this.loadingCollections = false;
        });
    },
    /**
     * Carga los datos de productos de la promoción en el selector correspondiente.
     * Solo agrega los productos que esten ya seleccionados.
     * @param {Object} promotion - Promoción.
     */
    setProvisionalProducts(promotion) {
      this.equalProducts = this.findInclusionOption(
        promotion.productsAreInclusive
      );
      this.products = promotion.products.map(product => ({
        value: product.id,
        label: product.name
      }));
      this.setSelectedDataFromPromotion(
        "selectedProducts",
        promotion.products,
        this.products
      );
    },
    /**
     * Carga los datos de skus de la promoción en el selector correspondiente.
     * Solo agrega los skus que esten ya seleccionados.
     * @param {Object} promotion - Promoción.
     */
    setProvisionalSkus(promotion) {
      this.equalSKUs = this.findInclusionOption(promotion.skusAreInclusive);
      this.skus = promotion.skus.map(sku => ({
        value: sku.id,
        label: sku.name
      }));
      this.setSelectedDataFromPromotion(
        "selectedSkus",
        promotion.skus,
        this.skus
      );
    },
    /**
     * Revisa y selecciona los datos de la promoción que se encuentran entre
     * las opciones del selector correspondiente.
     * @param {String} key - Atributo en el que se guardaran las opciones seleccionadas.
     * @param {Array} selectedData - Datos seleccionados en la promoción.
     * @param {Array} options - Opciones del selector correspondiente en la vista.
     */
    setSelectedDataFromPromotion(key, selectedData, options) {
      this[key] = selectedData.map(selected => {
        return options.find(option => option.value === selected.id);
      });
    },
    /**
     * Revisa los datos seleccionados en la vista y los guarda en la promoción.
     * @param {String} key - Atributo en el que se guardaran las opciones seleccionadas.
     * @param {Array} data - Datos seleccionados en la vista.
     */
    setSelectedDataToPromotion(key, data) {
      this.promotion[key] = data.map(selected => {
        return {
          id: selected.value,
          name: selected.label
        };
      });
    },
    /**
     * Emite si los datos obligatorios han sido completados.
     * @param {Boolean} value - Valor a emitir.
     */
    emitFilledRequiredFields(value) {
      this.$emit("filledRequiredFields", value);
    },
    /**
     * Revisa si se estan utilizando todos los productos en la promoción.
     * @param {Object} promotion - Promoción.
     * @returns {Boolean}
     */
    checkIfAllProducts(promotion) {
      return (
        promotion.categoriesAreInclusive === true &&
        promotion.brandsAreInclusive === true &&
        promotion.collectionsIsInclusive === true &&
        promotion.productsAreInclusive === true &&
        promotion.skusAreInclusive === true &&
        promotion.categories.length === 0 &&
        promotion.brands.length === 0 &&
        promotion.collections.length === 0 &&
        promotion.products.length === 0 &&
        promotion.skus.length === 0
      );
    },
    setProducts(products) {
      this.products = products;
    },
    setSkus(skus) {
      this.skus = skus;
    }
  },
  computed: {
    /**
     * Revisa si los atributos obligatorios de la promoción en la vista estan completos
     * para saber donde mostrar los mensajes de requerido.
     * @returns {Object} - Objeto con los atributos obligatorios y si requieren mensaje.
     */
    requiredMessage() {
      let required = {};
      required.someProduct =
        !this.allProducts &&
        this.selectedCategories.length === 0 &&
        this.selectedBrands.length === 0 &&
        this.selectedCollections.length === 0 &&
        this.selectedProducts.length === 0 &&
        this.selectedSkus.length === 0;
      return required;
    }
  },
  /**
   * En general, el objetivo de los watches es cambiar el valor de una propiedad
   * de la promocion cuando se cambia su correspondiente en la vista
   */
  watch: {
    value(newPromotion) {
      this.promotion = newPromotion;
    },
    promotion: {
      handler(newPromotion) {
        this.$emit("input", newPromotion);
      },
      deep: true
    },
    /**
     * Si la opción de aplicar la promocion a todos los productos es true,
     * se cambian los valores correspondientes para lograr este efecto en la promoción.
     * Si es false, se cambia la promoción a los valores que esten seleccionados en la vista.
     * @param {Boolean} newAllProducts - Si aplicar todos los productos o no.
     */
    allProducts(newAllProducts) {
      if (newAllProducts) {
        this.promotion.categories = [];
        this.promotion.categoriesAreInclusive = true;
        this.promotion.brands = [];
        this.promotion.brandsAreInclusive = true;
        this.promotion.collections = [];
        this.promotion.collectionsIsInclusive = true;
        this.promotion.products = [];
        this.promotion.productsAreInclusive = true;
        this.promotion.skus = [];
        this.promotion.skusAreInclusive = true;
      } else {
        this.promotion.categoriesAreInclusive = this.equalCategories.value;
        this.setSelectedDataToPromotion("categories", this.selectedCategories);
        this.promotion.brandsAreInclusive = this.equalBrands.value;
        this.setSelectedDataToPromotion("brands", this.selectedBrands);
        this.promotion.collectionsIsInclusive = this.equalCollections.value;
        this.setSelectedDataToPromotion(
          "collections",
          this.selectedCollections
        );
        this.promotion.productsAreInclusive = this.equalProducts.value;
        this.setSelectedDataToPromotion("products", this.selectedProducts);
        this.promotion.skusAreInclusive = this.equalSKUs.value;
        this.setSelectedDataToPromotion("skus", this.selectedSkus);
      }
    },
    equalCategories(newValue) {
      this.promotion.categoriesAreInclusive = newValue.value;
    },
    selectedCategories(newValue) {
      this.setSelectedDataToPromotion("categories", newValue);
    },
    equalBrands(newValue) {
      this.promotion.brandsAreInclusive = newValue.value;
    },
    selectedBrands(newValue) {
      this.setSelectedDataToPromotion("brands", newValue);
    },
    equalCollections(newValue) {
      this.promotion.collectionsIsInclusive = newValue.value;
    },
    selectedCollections(newValue) {
      this.setSelectedDataToPromotion("collections", newValue);
    },
    equalProducts(newValue) {
      this.promotion.productsAreInclusive = newValue.value;
    },
    selectedProducts(newValue) {
      this.setSelectedDataToPromotion("products", newValue);
    },
    equalSKUs(newValue) {
      this.promotion.skusAreInclusive = newValue.value;
    },
    selectedSkus(newValue) {
      this.setSelectedDataToPromotion("skus", newValue);
    },
    /**
     * Si cambia el valor de requiredMessage, se revisa si no se necesita
     * ningun mensaje y se guarda este valor en filledRequiredFields.
     */
    requiredMessage: function(newRequiredMessage) {
      this.filledRequiredFields = !Object.values(newRequiredMessage).includes(
        true
      );
    },
    /**
     * Si cambia el valor de filledRequiredFields, se emite este valor al padre.
     * @param {Boolean} value - Valor a emitir.
     */
    filledRequiredFields: {
      handler(value) {
        this.emitFilledRequiredFields(value);
      },
      immediate: true
    }
  }
};
</script>
