<template>
  <div>
    <b-button-group size="sm">
      <b-button variant="outline-info" @click="cancel">
        <b-icon-x-circle></b-icon-x-circle>Cancelar
      </b-button>
      <b-button
        variant="info"
        :disabled="
          !changed || saving || loadingIntegrationConfig || !validChangesPrices
        "
        :title="changed ? '' : 'No hay cambios'"
        v-b-tooltip.hover
        @click="save"
      >
        <span v-if="saving">
          <b-spinner label="Spinning"></b-spinner>
        </span>
        <span v-else> <b-icon-cloud-upload></b-icon-cloud-upload>Guardar </span>
      </b-button>
    </b-button-group>
    <b-alert
      v-model="saved"
      :variant="errorSaving ? 'danger' : 'success'"
      dismissible
    >
      {{
        errorSaving
          ? errorMessage
          : "El producto ha sido actualizado exitosamente"
      }}
    </b-alert>
    <h4 class="font-weight-bold m-3">Clones</h4>
    <b-table-simple>
      <b-thead>
        <tr>
          <th></th>
          <th>ID Mercado Libre</th>
          <th>Nombre</th>
          <th>Tipo de Publicación</th>
          <th v-if="isMarketplaceAvailable">Precio</th>
          <th v-if="isMshopsAvailable">Precio Mercado Shops</th>
          <th v-if="officialStores.length > 0">Tienda Oficial</th>
          <th>Posición en ventas</th>
          <th></th>
        </tr>
      </b-thead>

      <b-tbody>
        <b-tr>
          <b-td>Original</b-td>
          <b-td>
            <b-input v-model="productId" @input="change"></b-input>
          </b-td>
          <b-td>
            <b-input
              v-model="title"
              :placeholder="product.title"
              @input="change"
            ></b-input>
          </b-td>
          <b-td>
            <v-select
              id="listing_type_id"
              :options="listingTypes"
              placeholder="Seleccionar"
              :disabled="productId !== ''"
              v-model="listingTypeId"
              :reduce="listing => listing.value"
              @input="change"
            ></v-select>
          </b-td>
          <b-td v-if="isMarketplaceAvailable">
            <b-form-input
              v-model="price"
              :placeholder="String(product.priceCompare)"
              :state="$validFormPrice(price)"
              :formatter="$formatFormPrice"
              @input="change"
            ></b-form-input>
          </b-td>
          <b-td v-if="isMshopsAvailable">
            <b-form-input
              v-model="priceMshops"
              :placeholder="String(product.priceCompare)"
              :state="$validFormPrice(priceMshops)"
              :formatter="$formatFormPrice"
              @input="change"
            ></b-form-input>
          </b-td>
          <b-td v-if="officialStores.length > 0">
            <v-select
              id="officialStoreId"
              :options="officialStores"
              placeholder="Seleccionar"
              v-model="officialStoreId"
              :reduce="listing => listing.value"
              @input="change"
            ></v-select>
          </b-td>
          <b-td>
            <b-spinner label="Spinning" v-if="loadingPosition"></b-spinner>
            <span v-else>{{ itemPosition }}</span>
          </b-td>
          <b-td>
            <b-dropdown variant="white" no-caret offset="-110">
              <template v-slot:button-content>
                <b-icon-three-dots-vertical></b-icon-three-dots-vertical>
              </template>
              <b-dropdown-item
                :to="{
                  name: 'MercadoLibreItemFulfillmentStock',
                  params: {
                    integrationConfigId: integrationConfig.id,
                    itemId: productId
                  }
                }"
                >Ver fulfillment</b-dropdown-item
              >
            </b-dropdown>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <product-edit-tabs-mercado-libre-clones
      v-model="clones"
      :official-stores="officialStores"
      :listing-types="listingTypes"
      :product="product"
      :integrationConfig="integrationConfig"
      @change="change"
      ref="clones"
    ></product-edit-tabs-mercado-libre-clones>
    <hr />
    <div>
      <b-row>
        <h4 class="font-weight-bold m-3">Descuento Individual</h4>
        <base-help-modal
          id="helpDiscountPrice"
          tooltip="Consideraciones"
          title="Consideraciones para Descuentos individuales"
          size="lg"
        >
          <product-edit-tabs-mercado-libre-help-discount-price></product-edit-tabs-mercado-libre-help-discount-price>
        </base-help-modal>
      </b-row>
    </div>

    <b-row>
      <b-col md="3">
        <b-form-group label-for="discountPrice">
          <template v-slot:label>
            Precio con descuento
            <b-icon-info-circle
              id="discount-price-tooltip"
            ></b-icon-info-circle>
            <b-tooltip target="discount-price-tooltip"
              >Precio con descuento para todos los compradores. Este descuento
              debe ser de al menos un 5% respecto al precio original con un tope
              máximo de 80% de descuento. <br /><br />
              Ejemplo: Si el precio original es de $10.000, entonces el precio
              con descuento minimo será de $9.500 y el precio de descuento
              máximo será de $2.000</b-tooltip
            >
          </template>
          <b-input
            type="number"
            id="discountPrice"
            v-model="discountPrice"
          ></b-input>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group label-for="topDiscountPrice">
          <template v-slot:label>
            Precio con descuento para usuarios TOP
            <b-icon-info-circle
              id="top-discount-price-tooltip"
            ></b-icon-info-circle>
            <b-tooltip target="top-discount-price-tooltip"
              >Este campo es opcional y sólo debe ser rellenado en caso de que
              se quiera ofrecer un descuento mayor para los mejores compradores
              de ML (con nivel Mercado Puntos 3 a 6). Este descuento debe ser de
              al menos 5% inferior al descuento indicado en Precio con
              descuento.<br /><br />
              Ejemplo: Si el precio original es de $10.000 y además configuraste
              un precio con descuento de $9.500, entonces el precio con
              descuento mínimo para usuarios TOP debe ser de $9.000 (Este precio
              debe ser siempre inferior al precio con descuento).
            </b-tooltip>
          </template>
          <b-input
            type="number"
            id="topDiscountPrice"
            v-model="topDiscountPrice"
          ></b-input>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group label="Fecha de inicio del descuento">
          <base-date-time
            type="datetime"
            v-model="discountStartDate"
          ></base-date-time>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group label="Fecha de término del descuento">
          <base-date-time
            type="datetime"
            v-model="discountFinishDate"
          ></base-date-time>
        </b-form-group>
      </b-col>
    </b-row>
    <hr />
    <h4 class="font-weight-bold m-3">Atributos Generales</h4>
    <b-row>
      <b-col md="6">
        <b-row v-if="!id">
          <b-col md="11">
            <ProductEditTabsMercadoLibreCategoriesModal
              :siteCode="integrationConfigMeli.siteCode"
              :current-category-id="categoryId"
              @categoryId="updateCategoryId"
            ></ProductEditTabsMercadoLibreCategoriesModal>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="11">
            <b-form-group label="Categoría" label-for="categoryName">
              <pre>{{ categoryName }}</pre>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="product.meliProductDatas.length === 0">
          <b-col md="11">
            <b-form-group label="Url Video en Youtube" label-for="videoId">
              <b-input
                id="videoId"
                v-model="videoId"
                placeholder="Ej: https://www.youtube.com/watch?v=dQw4w9WgXcQ"
              ></b-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="11">
            <b-form-group
              label="Disponibilidad de stock"
              label-for="manufacturingTime"
            >
              <b-input
                id="manufacturingTime"
                v-model="manufacturingTime"
              ></b-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="11">
            <b-form-group
              label="Descripción"
              label-for="descriptionPlainText"
              v-if="true"
            >
              <b-form-textarea
                id="descriptionPlainText"
                v-model="descriptionPlainText"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="6">
        <b-row>
          <b-col md="11">
            <b-form-group label="Tipo de envío" label-for="shipping_mode">
              <v-select
                id="shipping_mode"
                :options="shippingTypes"
                :placeholder="
                  loadingShippingModes ? 'Cargando opciones' : 'Seleccionar'
                "
                :disabled="disabledShippingModes"
                v-model="tempShippingMode"
                :reduce="listing => listing.value"
                :loading="loadingShippingModes"
              >
              </v-select>
            </b-form-group>
          </b-col>
          <b-col md="1">
            <base-help-modal
              id="helpShippingMode"
              tooltip="¿No se encuentra el tipo de envío deseado? click aquí"
              title="¿No está el tipo de envío que buscas?"
            >
              <p>
                Los tipos de envío que se muestran son los entregados por
                Mercado Libre para la categoría ingresada.
              </p>
            </base-help-modal>
          </b-col>
        </b-row>
        <b-row v-if="shippingMode === 'me2' || shippingMode === 'custom'">
          <b-col md="11">
            <input type="checkbox" id="free_shipping" v-model="freeShipping" />
            <label for="free_shipping">Envío Gratis</label>
          </b-col>
        </b-row>
        <b-row
          v-if="
            (shippingMode === 'me2' || shippingMode === 'custom') &&
              isMshopsAvailable
          "
        >
          <b-col md="11">
            <input
              type="checkbox"
              id="free_shipping_mshops"
              v-model="freeShippingMshops"
            />
            <label for="free_shipping_mshops"
              >Envío Gratis para Mercado Shops</label
            >
          </b-col>
        </b-row>
        <b-row v-if="shippingMode === 'custom' && !freeShipping">
          <b-col md="11">
            <b-form-group
              label="Costos personalizados de envío"
              label-for="shippingCosts"
            >
              <product-edit-tabs-mercado-libre-shipping-costs
                v-model="shippingCosts"
                @change="change"
              ></product-edit-tabs-mercado-libre-shipping-costs>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="11">
            <b-form-group
              label="Retiro en Tienda"
              label-for="shippingModeLocalPickUp"
            >
              <product-edit-tabs-mercado-libre-boolean-selector
                v-model="shippingModeLocalPickUp"
                :allow-null="true"
                true-text="Si"
                false-text="No"
                default-text="Como la configuración general"
              ></product-edit-tabs-mercado-libre-boolean-selector>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="11">
            <b-form-group
              label="Aceptar mercadopago"
              label-for="acceptsMercadopago"
            >
              <product-edit-tabs-mercado-libre-boolean-selector
                v-model="acceptsMercadopago"
                :allow-null="true"
                true-text="Si"
                false-text="No"
                default-text="Como la configuración general"
              ></product-edit-tabs-mercado-libre-boolean-selector>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="11">
            <b-form-group
              label="Relistar automaticamente"
              label-for="automaticRelist"
            >
              <product-edit-tabs-mercado-libre-boolean-selector
                v-model="automaticRelist"
                :allow-null="true"
                true-text="Si"
                false-text="No"
                default-text="Como la configuración general"
              ></product-edit-tabs-mercado-libre-boolean-selector>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="11">
            <b-form-group label="Mercado Envío Flex" label-for="meliFlexStatus">
              <product-edit-tabs-mercado-libre-boolean-selector
                v-model="meliFlexStatus"
                :allow-null="true"
                true-text="Si"
                false-text="No"
                default-text="Como la configuración general"
              ></product-edit-tabs-mercado-libre-boolean-selector>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="11">
            <b-form-group label="Estado de la publicación" label-for="status">
              <product-edit-tabs-mercado-libre-boolean-selector-status
                v-model="status"
                :allow-null="true"
                true-text="Activada"
                false-text="Pausada"
                default-text="Como la configuración general"
              ></product-edit-tabs-mercado-libre-boolean-selector-status>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="integrationConfigMeli.isMshopsAvailable">
          <b-col md="11">
            <b-form-group label="Canales de venta" label-for="status">
              <b-button-group>
                <b-button
                  :variant="
                    channels &&
                    channels.length == 1 &&
                    channels[0] == 'marketplace'
                      ? 'primary'
                      : 'outline-secondary'
                  "
                  @click="setChannels(['marketplace'])"
                >
                  Sólo Mercado Libre (marketplace)
                </b-button>
                <b-button
                  :variant="
                    channels && channels.length == 1 && channels[0] == 'mshops'
                      ? 'primary'
                      : 'outline-secondary'
                  "
                  @click="setChannels(['mshops'])"
                >
                  Sólo Mercado Shops (e-commerce)
                </b-button>
                <b-button
                  :variant="
                    channels && channels.length == 2
                      ? 'primary'
                      : 'outline-secondary'
                  "
                  @click="setChannels(['marketplace', 'mshops'])"
                >
                  Ambos
                </b-button>
                <b-button
                  :variant="!channels ? 'secondary' : 'outline-secondary'"
                  @click="setChannels(null)"
                >
                  Como la configuración general
                </b-button>
              </b-button-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <hr />
    <h4 class="font-weight-bold m-3">Atributos de Categoría</h4>
    <div v-if="showMeliMirrorLink" class="mb-4">
      Para cambiar estos campos dirijase a la sección de
      <b-link :to="meliMirrorLink" target="_blank">
        Atributos de Mercado Libre <b-icon-box-arrow-up-right />
      </b-link>
    </div>
    <b-row v-else-if="loadingCategoryAttributes">
      <b-col md="12" class="text-center">
        <b-spinner label="Spinning"></b-spinner>
      </b-col>
    </b-row>
    <div v-else>
      <product-edit-tabs-mercado-libre-category-attributes
        v-model="prodAttributesValues"
        :category_attributes="prodCategoryAttributes"
        @change="change"
        :integration-config="integrationConfigMeli"
        @sizeChartChange="sizeChartChange"
      ></product-edit-tabs-mercado-libre-category-attributes>
      <hr />
      <h4 class="font-weight-bold m-3">Variantes</h4>
      <product-edit-tabs-mercado-libre-variants
        v-model="productVariants"
        :integration-config="integrationConfigMeli"
        :category_attributes="varCategoryAttributes"
        :meli_id="productId"
        :size-chart-loading="sizeChartLoading"
        @change="change"
      ></product-edit-tabs-mercado-libre-variants>
    </div>
  </div>
</template>
<script>
import ProductEditTabsMercadoLibreBooleanSelector from "./ProductEditTabsMercadoLibreBooleanSelector";
import ProductEditTabsMercadoLibreBooleanSelectorStatus from "./ProductEditTabsMercadoLibreBooleanSelectorStatus";
import ProductEditTabsMercadoLibreVariants from "./ProductEditTabsMercadoLibreVariants";
import ProductEditTabsMercadoLibreClones from "./ProductEditTabsMercadoLibreClones";
import ProductEditTabsMercadoLibreShippingCosts from "./ProductEditTabsMercadoLibreShippingCosts";
import ProductEditTabsMercadoLibreCategoryAttributes from "./ProductEditTabsMercadoLibreCategoryAttributes";
import ProductEditTabsMercadoLibreHelpDiscountPrice from "./MercadoLibre/HelpDiscountPrice.vue";
import INTEGRATION_CONFIG_MERCADO_LIBRE from "../graphql/IntegrationConfigMercadoLibre.gql";
import INTEGRATION_CONFIG_MERCADO_LIBRE_CATEGORY_ATTRIBUTES from "../graphql/IntegrationConfigMercadoLibreCategoryAttributes.gql";
import MERCADO_LIBRE_GET_ITEM_POSITION from "../graphql/MercadoLibreGetItemPosition.gql";
import MERCADO_LIBRE_CATEGORY_USER_SHIPPING_MODES from "../graphql/MercadoLibreCategoryUserShippingModes.gql";
import ProductEditTabsMercadoLibreCategoriesModal from "./ProductEditTabsMercadoLibreCategoriesModal";
import BaseDateTime from "./BaseDateTime";
import BaseHelpModal from "./BaseHelpModal";

import { updateProduct, updateVariant } from "@/main";
import { mapState } from "vuex";
export default {
  name: "ProductEditTabsMercadoLibre",
  components: {
    ProductEditTabsMercadoLibreCategoriesModal,
    ProductEditTabsMercadoLibreCategoryAttributes,
    ProductEditTabsMercadoLibreBooleanSelectorStatus,
    ProductEditTabsMercadoLibreBooleanSelector,
    ProductEditTabsMercadoLibreVariants,
    ProductEditTabsMercadoLibreClones,
    ProductEditTabsMercadoLibreShippingCosts,
    ProductEditTabsMercadoLibreHelpDiscountPrice,
    BaseDateTime,
    BaseHelpModal
  },
  model: {
    prop: "_changed",
    event: "change"
  },
  props: {
    integrationConfig: Object,
    product: Object
  },
  data() {
    let original = this.$ifNull(
      this.$dig(this.product, "integrations", this.integrationConfig.fullLabel),
      {}
    );
    return {
      changed: false,
      saving: false,
      errorSaving: false,
      loadingIntegrationConfig: true,
      loadingCategoryAttributes: true,
      saved: false,
      integrationConfigMeli: this.integrationConfig,
      id: original.id,
      videoId: original.video_id,
      manufacturingTime: original.manufacturing_time,
      descriptionPlainText: original.description_plain_text,
      status: original.status,
      shippingModeLocalPickUp: original.shipping_mode_local_pick_up,
      acceptsMercadopago: original.accepts_mercadopago,
      automaticRelist: original.automatic_relist,
      meliFlexStatus: original.meli_flex_status,
      channels: original.channels,
      shippingMode: original.shipping_mode,
      productVariants: this.getVariantsArray(),
      prodAttributesValues: JSON.parse(JSON.stringify(original)),
      freeShipping: original.free_shipping,
      freeShippingMshops: original.free_shipping_mshops,
      shippingCosts: original.shipping_costs,
      categoryId: original.category_id,
      prodCategoryAttributes: [],
      clones: this.getClonesArray(),
      productId: original.id,
      title: original.title,
      listingTypeId: original.listing_type_id,
      price: original.price,
      priceMshops: original.price_mshops,
      varCategoryAttributes: [],
      officialStores: [],
      officialStoreId: original.official_store_id,
      productData: this.selectMeliProductData(),
      baseShippingTypes: {
        not_specified: "A acordar con el vendedor",
        me1: "Mercado Envíos 1",
        me2: "Mercado Envíos 2",
        custom: "Personalizado"
      },
      shippingTypes: [],
      listingTypes: [
        { label: "Clásica", value: "gold_special" },
        { label: "Premium", value: "gold_pro" }
      ],
      categoryName: "",
      discountPrice: original.discount_price,
      topDiscountPrice: original.top_discount_price,
      discountStartDate:
        original.discount_start_date !== null
          ? new Date(original.discount_start_date)
          : null,
      discountFinishDate:
        original.discount_finish_date !== null
          ? new Date(original.discount_finish_date)
          : null,
      errorMessage: "Ha ocurrido un error",
      wrongAttribute: undefined,
      loadingPosition: true,
      itemPosition: "-- No informada --",
      loadingShippingModes: true,
      tempShippingMode: null,
      sizeChartLoading: true,
      rowsUnit: {}
    };
  },
  beforeMount() {
    this.getIntegrationConfig().then(() => {
      if (!this.showMeliMirrorLink) {
        this.showCategoryAttributes();
      }
      this.getOfficialStores();
    });
    this.getCategory();
    this.getShippingModes();
    this.loadPosition();
  },
  async created() {
    this.loadingIntegrationConfig = false;
  },
  methods: {
    loadPosition() {
      if (!this.productId) {
        this.itemPosition = "-- Producto no publicado --";
        this.loadingPosition = false;
        return;
      }
      this.$apollo
        .query({
          query: MERCADO_LIBRE_GET_ITEM_POSITION,
          variables: {
            meliId: this.productId,
            integrationConfigId: this.integrationConfig.id
          }
        })
        .then(({ data }) => {
          if (data) {
            const position = this.$dig(
              data,
              "mercadoLibreGetItemPosition",
              "position"
            );
            if (position) {
              this.itemPosition = position;
            } else {
              this.itemPosition = "-- No informada --";
            }
          }
          this.loadingPosition = false;
        });
    },
    updateCategoryId(categoryId) {
      this.categoryId = categoryId;
    },
    change() {
      this.changed = true;
      this.$emit("change", true);
    },
    getVariantsArray() {
      return JSON.parse(JSON.stringify(this.product.variants));
    },
    getClonesArray() {
      const clones = this.$ifNull(
        this.$dig(
          this.product,
          "integrations",
          this.integrationConfig.fullLabel,
          "clones"
        ),
        []
      );
      return clones.map(x => {
        return Object.assign({}, x);
      });
    },
    getOfficialStores() {
      fetch(
        "https://api.mercadolibre.com/users/" +
          this.integrationConfigMeli.sellerId +
          "/brands"
      )
        .then(function(response) {
          return response.json();
        })
        .then(myJson => {
          this.officialStores = this.orderStores(myJson);
        });
    },
    orderStores(json) {
      return this.$ifNull(this.$dig(json, "brands"), []).map(store => {
        return { value: store.official_store_id, label: store.name };
      });
    },
    itemForSelect(item) {
      if (item) {
        return { value: item.id, label: item.name };
      }
      return null;
    },
    showCategoryAttributes() {
      this.loadingCategoryAttributes = true;
      const categoryIdCentry = this.product.category
        ? this.product.category.id
        : null;
      this.$apollo
        .query({
          query: INTEGRATION_CONFIG_MERCADO_LIBRE_CATEGORY_ATTRIBUTES,
          variables: {
            id: this.integrationConfig.id,
            categoryIdCentry,
            categoryIdMeli: this.$ifNull(this.categoryId, "")
          }
        })
        .then(({ data }) => {
          if (
            data &&
            data.integrationConfigMercadoLibre &&
            data.integrationConfigMercadoLibre.categoryAttributes
          ) {
            this.productAttributes(
              data.integrationConfigMercadoLibre.categoryAttributes
            );
          }
          this.loadingCategoryAttributes = false;
        });
    },
    productAttributes(json) {
      var prod_attributes = [];
      var var_attributes = [];
      var prod_attributes_values = {};
      json.forEach(attr => {
        if (
          typeof attr === "object" &&
          attr !== null &&
          attr.tags !== undefined &&
          !("read_only" in attr.tags) &&
          ["SIZE", "73002", "73001", "83000"].indexOf(attr.id) === -1
        ) {
          if (
            ("variation_attribute" in attr.tags ||
              "allow_variations" in attr.tags) &&
            attr.id !== "COLOR"
          ) {
            var_attributes.push({
              label: attr.name,
              value: attr.id,
              options: attr.values,
              value_type: attr.valueType,
              multivalued: attr.tags.multivalued,
              allow_variations: attr.tags.allow_variations,
              required: attr.tags.required
            });
            this.attributeValuesVar(attr);
          } else {
            prod_attributes.push({
              label: attr.name,
              value: attr.id,
              options: attr.values,
              value_type: attr.valueType,
              multivalued: attr.tags.multivalued,
              required: attr.tags.required
            });
            prod_attributes_values = this.attributeValuesProd(
              attr,
              prod_attributes_values
            );
          }
        }
      });
      this.varCategoryAttributes = var_attributes;
      this.prodCategoryAttributes = prod_attributes;
      this.prodAttributesValues = prod_attributes_values;
    },
    async getIntegrationConfig() {
      await this.$apollo
        .query({
          query: INTEGRATION_CONFIG_MERCADO_LIBRE,
          variables: { id: this.integrationConfig.id }
        })
        .then(result => {
          this.integrationConfigMeli =
            result.data.integrationConfigMercadoLibre;
          this.integrationConfigMeli.id = this.integrationConfig.id;
        });
    },
    cancel() {
      if (this.changed) {
        this.$swal
          .fire({
            title: "Cancelar",
            text: "Si cancelas, perderás tus cambios. ¿Estás seguro?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "No"
          })
          .then(result => {
            if (result.value) {
              this.$router.push({ name: "Products" });
            }
          });
      } else {
        this.$router.push({ name: "Products" });
      }
    },
    categoryAtributeValueType(attr) {
      if (attr.values && attr.values.length) {
        return "value_id";
      }
      return "value_name";
    },
    getCategory() {
      if (!this.categoryId || !this.categoryId.length) {
        return;
      }
      fetch("https://api.mercadolibre.com/categories/" + this.categoryId)
        .then(function(response) {
          return response.json();
        })
        .then(myJson => {
          return (this.categoryName = this.getCategoryName(myJson));
        });
    },
    getCategoryName(json) {
      let name = "";
      json.path_from_root.forEach(c => {
        name += c.name + "\n ↳";
      });
      name = name.substring(0, name.length - 1);
      return name;
    },
    attributeValuesProd(attribute, hash) {
      if (attribute.id in this.prodAttributesValues) {
        hash[attribute.id] = this.prodAttributesValues[attribute.id];
      } else {
        hash[attribute.id] = {
          value: "",
          value_type: this.categoryAtributeValueType(attribute)
        };
      }
      return hash;
    },
    attributeValuesVar(attribute) {
      this.productVariants.forEach((variant, position) => {
        let original = this.$ifNull(
          this.$dig(variant, "integrations", this.integrationConfig.fullLabel),
          {}
        );
        if (!(attribute.id in original)) {
          this.$deepSet(
            this.productVariants[position],
            {
              value: "",
              value_type: this.categoryAtributeValueType(attribute)
            },
            "integrations",
            this.integrationConfig.fullLabel,
            attribute.id
          );
        }
      });
    },
    updatedProductData() {
      const updated = { integrations: {} };
      updated.integrations[this.integrationConfig.fullLabel] = {};
      let original = this.$ifNull(
        this.product.integrations[this.integrationConfig.fullLabel],
        {}
      );
      const changes = [
        { key: "id", original: original.id, current: this.productId },
        { key: "title", original: original.title, current: this.title },
        {
          key: "listing_type_id",
          original: original.listing_type_id,
          current: this.listingTypeId
        },
        { key: "price", original: original.price, current: this.price },
        {
          key: "price_mshops",
          original: original.price_mshops,
          current: this.priceMshops
        },
        {
          key: "official_store_id",
          original: original.official_store_id,
          current: this.officialStoreId
        },
        {
          key: "video_id",
          original: original.video_id,
          current: this.videoId
        },
        {
          key: "manufacturing_time",
          original: original.manufacturing_time,
          current: this.manufacturingTime
        },
        {
          key: "description_plain_text",
          original: original.description_plain_text,
          current: this.descriptionPlainText
        },
        { key: "status", original: original.status, current: this.status },
        {
          key: "shipping_mode_local_pick_up",
          original: original.shipping_mode_local_pick_up,
          current: this.shippingModeLocalPickUp
        },
        {
          key: "accepts_mercadopago",
          original: original.accepts_mercadopago,
          current: this.acceptsMercadopago
        },
        {
          key: "automatic_relist",
          original: original.automatic_relist,
          current: this.automaticRelist
        },
        {
          key: "meli_flex_status",
          original: original.meli_flex_status,
          current: this.meliFlexStatus
        },
        {
          key: "channels",
          original: original.channels,
          current: this.channels
        },
        {
          key: "shipping_mode",
          original: original.shipping_mode,
          current: this.shippingMode
        },
        {
          key: "category_id",
          original: original.category_id,
          current: this.categoryId
        },
        {
          key: "clones",
          original: original.clones ? original.clones : [],
          current: this.$ifNull(this.clones, [])
        },
        {
          key: "shipping_costs",
          original: original.shipping_costs ? original.shipping_costs : [],
          current:
            this.freeShipping || this.shippingMode !== "custom"
              ? []
              : this.shippingCosts
        },
        {
          key: "free_shipping",
          original: original.free_shipping,
          current: this.freeShipping
        },
        {
          key: "discount_price",
          original: original.discountPrice,
          current: this.discountPrice
        },
        {
          key: "top_discount_price",
          original: original.topDiscountPrice,
          current: this.topDiscountPrice
        },
        {
          key: "discount_start_date",
          original: original.discountStartDate,
          current: this.discountStartDate
        },
        {
          key: "discount_finish_date",
          original: original.discountFinishDate,
          current: this.discountFinishDate
        }
      ];
      let updatedCategoryAttributes = this.updatedCategoryAttributesData();
      let changed = false;
      if (Object.keys(updatedCategoryAttributes).length > 0) {
        changed = true;
        updated.integrations[
          this.integrationConfig.fullLabel
        ] = updatedCategoryAttributes;
      }
      let clonChanged = false;
      changes.forEach(x => {
        if (x.key === "clones") {
          if (x.current.length !== x.original.length) {
            clonChanged = true;
          } else {
            x.current.forEach((clon, position) => {
              Object.entries(clon).forEach(key => {
                if (key[1] !== original.clones[position][key[0]]) {
                  clonChanged = true;
                }
              });
            });
          }
          if (clonChanged === true) {
            changed = true;
            updated.integrations[this.integrationConfig.fullLabel][x.key] =
              x.current;
          }
        } else {
          if (x.original !== x.current) {
            changed = true;
            updated.integrations[this.integrationConfig.fullLabel][x.key] =
              x.current;
          }
        }
      });
      if (changed) {
        return updated;
      }
      return null;
    },
    async updateProduct() {
      const updated = this.updatedProductData();
      this.unitForRows();
      if (!updated) {
        return;
      }
      await updateProduct(this.$apollo, this.product.id, updated)
        .then(async ({ data }) => {
          if (data && data.updateProduct && data.updateProduct.product) {
            this.errorSaving = false;
            const product = data.updateProduct.product;
            this.updateProductCache(product);
          }
        })
        .catch(() => {
          this.errorSaving = true;
        });
    },
    updatedCategoryAttributesData() {
      const updated = {};
      const original = this.product.integrations[
        this.integrationConfig.fullLabel
      ];
      Object.entries(this.prodAttributesValues)
        .filter(
          p =>
            typeof p[1] === "object" &&
            p[1] !== null &&
            !!Object.getOwnPropertyDescriptor(p[1], "value")
        )
        .forEach(array => {
          if (
            array[0] in this.$ifNull(original, {}) &&
            this.$ifNull(array[1], []).value !== undefined
          ) {
            if (
              original[array[0]].value !== array[1].value ||
              original[array[0]].value_type !== array[1].value_type
            ) {
              updated[array[0]] = array[1];
            }
          } else {
            updated[array[0]] = array[1];
          }
        });
      return updated;
    },
    updateProductCache(product) {
      if (product) {
        this.product.integrations = product.integrations;
        this.clones = this.getClonesArray();
      }
    },
    updatedVariantData(position) {
      const variant = this.product.variants[position];
      const original = this.$ifNull(
        variant.integrations[this.integrationConfig.fullLabel],
        {}
      );
      const current = this.$ifNull(
        this.productVariants[position].integrations[
          this.integrationConfig.fullLabel
        ],
        {}
      );
      const updated = { integrations: {} };
      updated.integrations[this.integrationConfig.fullLabel] = {};
      Object.entries(current).forEach(array => {
        if (array[0] == "SIZE_GRID_ROW_ID") {
          array[1]["value_unit"] = this.rowsUnit[array[1].value];
        }
        if (array[0] in original) {
          if (
            this.$ifNull(original[array[0]], {}).value !==
              this.$ifNull(array[1], {}).value ||
            this.$ifNull(original[array[0]], {}).value_type !==
              this.$ifNull(array[1], {}).value_type ||
            this.$ifNull(original[array[0]], {}).value_unit !==
              this.$ifNull(array[1], {}).value_unit
          ) {
            updated.integrations[this.integrationConfig.fullLabel][array[0]] =
              array[1];
          }
        } else {
          updated.integrations[this.integrationConfig.fullLabel][array[0]] =
            array[1];
        }
      });
      return updated;
    },
    updateVariantCache(position, variant) {
      this.product.variants[position] = variant;
    },
    async updateVariant(position) {
      const updated = this.updatedVariantData(position);
      if (!updated) {
        return;
      }
      await updateVariant(
        this.$apollo,
        this.product.variants[position].id,
        updated
      )
        .then(async ({ data }) => {
          if (data && data.updateVariant && data.updateVariant.variant) {
            const variant = data.updateVariant.variant;
            this.updateVariantCache(position, variant);
          }
        })
        .catch(() => {
          this.errorSaving = true;
        });
    },
    async save() {
      this.saving = true;
      this.errorSaving = false;
      if (!this.correctData()) {
        this.errorSaving = true;
        this.saving = false;
        this.saved = true;
        this.changed = false;
        this.$emit("change", false);
        return;
      }

      if (!this.correctDiscounts()) {
        this.errorMessage = "Error en los porcentajes de descuento.";
        this.errorSaving = true;
        this.saving = false;
        this.saved = true;
        this.changed = false;
        this.$emit("change", false);
        return;
      }
      this.loadingPosition = true;
      this.loadPosition();
      this.$refs.clones.loadPositions();
      await this.updateProduct();
      for (
        let position = 0;
        position < this.product.variants.length;
        position++
      ) {
        await this.updateVariant(position);
      }
      this.saving = false;
      this.saved = true;
      if (!this.errorSaving) {
        this.changed = false;
        this.$emit("change", false);
      }
    },
    correctData() {
      if (!this.correctShipping()) {
        this.errorMessage =
          "Debes tener dimensiones y peso en la ficha de Centry para utilizar Mercado Envios 1.";
        return false;
      }
      if (!this.correctAllowVariationsAttributes()) {
        this.errorMessage = `Debes marcar un valor para el atributo "${this.wrongAttribute}" para cada variante o para ninguna`;
        return false;
      }
      return true;
    },
    correctShipping() {
      if (
        this.shippingMode == "me1" &&
        !this.product.packageheight &&
        !this.product.packagewidth &&
        !this.product.packagelength &&
        !this.product.packageweight
      ) {
        return false;
      }
      return true;
    },
    correctDiscounts() {
      if (this.discountPrice) {
        if (parseInt(this.discountPrice) < parseInt(this.topDiscountPrice)) {
          return false;
        }
      }
      return true;
    },
    correctAllowVariationsAttributes() {
      for (const attribute of this.varCategoryAttributes) {
        if (
          attribute.allow_variations &&
          this.productVariants.some(variant =>
            this.variantHaveAttribute(variant, attribute)
          )
        ) {
          if (
            !this.productVariants.every(variant =>
              this.variantHaveAttribute(variant, attribute)
            )
          ) {
            return false;
          }
        }
      }
      return true;
    },
    variantHaveAttribute(variation, attribute) {
      let attributeId = attribute.value;
      let variantMeliAttributes =
        variation.integrations[this.integrationConfig.fullLabel];
      if (
        !Object.prototype.hasOwnProperty.call(
          variantMeliAttributes,
          attributeId
        ) ||
        !variantMeliAttributes[attributeId].value
      ) {
        this.wrongAttribute = attribute.label;
        return false;
      }
      return true;
    },
    setChannels(channels) {
      this.channels = channels;
    },
    isChannelAvailable(channel) {
      if (this.channels && this.channels.length > 0) {
        return this.channels.includes(channel);
      }
      return this.integrationConfigMeli.channels?.includes(channel);
    },
    /**
     * Obtiene los metodos de envio para una categoria
     */
    async getShippingModes() {
      this.shippingTypes = [];
      this.tempShippingMode = null;
      this.loadingShippingModes = true;
      if (!this.disabledShippingModes) {
        await this.$apollo
          .query({
            query: MERCADO_LIBRE_CATEGORY_USER_SHIPPING_MODES,
            variables: {
              categoryIdCentry: this.$ifNull(
                this.$dig(this.product, "category", "id"),
                ""
              ),
              categoryIdMeli: this.$ifNull(this.categoryId, ""),
              integrationConfigId: this.integrationConfig.id
            }
          })
          .then(({ data }) => {
            if (data) {
              this.updateShippingModes(data);
            }
          });
      }
      this.loadingShippingModes = false;
    },
    /**
     * Actualiza los metodos de entrega segun los metodos recibidos.
     */
    updateShippingModes(data) {
      const shippingModes =
        this.$dig(
          data,
          "mercadoLibreCategoryUserShippingModes",
          "shippingModes"
        ) || [];
      var hasCurrentShippingMode = false;
      shippingModes.forEach(mode => {
        var baseShippingType = this.baseShippingTypes[mode];
        if (baseShippingType) {
          if (mode == this.shippingMode) {
            hasCurrentShippingMode = true;
          }
          var shippingType = { label: baseShippingType, value: mode };
          this.shippingTypes.push(shippingType);
        }
      });
      this.tempShippingMode = hasCurrentShippingMode ? this.shippingMode : null;
    },
    /**
     * Retorna el primer meliProductData entre los productData del producto que
     * tenga el id de la integración de meli actual
     * @return {Object}
     */
    selectMeliProductData() {
      const productDatas = this.$ifNull(
        this.$dig(this.product, "meliProductDatas"),
        []
      );
      if (productDatas.length > 0) {
        return productDatas.find(pd => {
          return pd.integrationConfigId === this.integrationConfig.id;
        });
      }
    },
    /**
     * Cambia el estado a la variable de carga de tabla de talla
     * @param {Boolean} loading
     */
    sizeChartChange(loading) {
      this.sizeChartLoading = loading;
    },
    /**
     * Guarda en una variable la unidad para cada fila de la GDT de meli
     */
    unitForRows() {
      if (Object.keys(this.mercadoLibreSizeChart).length == 0) {
        return;
      }
      this.mercadoLibreSizeChart.rows.forEach(row => {
        let unit = null;
        row.attributes.forEach(attribute => {
          if (attribute.id == "SIZE") {
            unit = this.$dig(attribute, "values", 0, "struct", "unit");
          }
          if (unit) {
            this.rowsUnit[row.id] = unit;
          }
        });
      });
    }
  },
  watch: {
    categoryId() {
      this.showCategoryAttributes();
      this.getCategory();
      this.getShippingModes();
      this.change();
    },
    videoId() {
      this.change();
    },
    manufacturingTime() {
      this.change();
    },
    descriptionPlainText() {
      this.change();
    },
    status() {
      this.change();
    },
    shippingModeLocalPickUp() {
      this.change();
    },
    acceptsMercadopago() {
      this.change();
    },
    automaticRelist() {
      this.change();
    },
    meliFlexStatus() {
      this.change();
    },
    shippingMode() {
      this.change();
    },
    freeShipping() {
      this.change();
    },
    topDiscountPrice() {
      this.change();
    },
    discountPrice() {
      this.change();
    },
    discountStartDate() {
      this.change();
    },
    discountFinishDate() {
      this.change();
    },
    channels() {
      this.change();
    },
    freeShippingMshops() {
      this.change();
    },
    tempShippingMode(value) {
      this.shippingMode = value;
    }
  },
  computed: {
    ...mapState(["mercadoLibreSizeChart"]),
    validChangesPrices() {
      let validPrices =
        this.changed &&
        (this.$validFormPrice(this.price) ||
          this.$validFormPrice(this.price) == null) &&
        (this.$validFormPrice(this.priceMshops) ||
          this.$validFormPrice(this.priceMshops) == null);
      this.clones.forEach(clon => {
        validPrices =
          validPrices &&
          (this.$validFormPrice(clon.price) ||
            this.$validFormPrice(clon.price) == null);
      });
      return validPrices;
    },
    isMarketplaceAvailable: function() {
      return this.isChannelAvailable("marketplace");
    },
    isMshopsAvailable: function() {
      return this.isChannelAvailable("mshops");
    },
    disabledShippingModes: function() {
      return !this.categoryId && !this.$dig(this.product, "category", "id");
    },
    attributeGroupIntegrationConfigId() {
      return this.$dig(this.productData, "attributeGroupIntegrationConfigId");
    },
    meliMirrorLink() {
      if (this.attributeGroupIntegrationConfigId) {
        return (
          "/mercadolibre/attributes/" + this.attributeGroupIntegrationConfigId
        );
      }
      return (
        "/mercadolibre/attributes/" + this.integrationConfig.id + "/groups"
      );
    },
    homologatedProduct() {
      return !!this.id;
    },
    showMeliMirrorLink() {
      return this.integrationConfigMeli.meliMirror && this.homologatedProduct;
    }
  }
};
</script>
