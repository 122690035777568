<template>
  <div>
    <ApolloQuery
      :query="require('../../graphql/IntegrationConfig/IntegrationConfig.gql')"
      :variables="{
        integrationConfigId: $route.params.id != null ? $route.params.id : ''
      }"
      @result="manageResult"
    >
      <template slot-scope="{ result: { data }, isLoading }">
        <b-spinner
          v-if="isLoading"
          label="Spinning"
          class="m-2 float-right"
        ></b-spinner>
        <div v-else-if="data">
          <base-header
            title="Mi Empresa"
            :title-link="`/company/${currentUser.company.id}`"
            :title-size="9"
            :map="['Editar', 'Integraciones', data.integrationConfig.label]"
          />

          <router-view :ic="data.integrationConfig" />
        </div>
      </template>
    </ApolloQuery>
  </div>
</template>

<script>
import BaseHeader from "../../components/BaseHeader.vue";
import { mapState } from "vuex";

export default {
  name: "IntegrationConfig",
  components: {
    BaseHeader
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(["currentUser"])
  },
  methods: {
    /**
     * Se encarga de redireccionar si la ruta
     * no corresponde al tipo de ic segun el id
     * @param {Object} result - respuesta de la query
     */
    manageResult(result) {
      let ic = result?.data?.integrationConfig;
      if (ic) {
        let hash = this.$route.hash;
        let path = `/integration/${ic.id}/${ic.identifier}${hash}`;
        if (this.$route.fullPath != path) {
          this.$router.replace(path);
        }
      } else {
        this.$router.push("/products");
      }
    }
  }
};
</script>
