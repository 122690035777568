<template>
  <div>
    <h4 class="font-weight-bold m-3">Atributos de Categoría</h4>
    <div v-if="isLoading">
      <b-spinner label="Spinning"></b-spinner>
    </div>
    <div v-else>
      <b-row
        v-for="(attributeGroup, index) in categoryAtributesGroups"
        :key="`group-${index}`"
      >
        <b-col
          :md="12 / attributeGroup.length"
          v-for="attribute in attributeGroup"
          :key="attribute.attributeCode"
        >
          <b-form-group
            :label="attribute.defaultFrontendLabel"
            :label-for="attribute.attributeCode"
          >
            <b-form-input
              v-if="attribute.frontendInput == 'text'"
              v-model="attributesValues[attribute.attributeCode]"
              :id="attribute.attributeCode"
              @change="change"
            >
            </b-form-input>
            <b-form-textarea
              v-else-if="attribute.frontendInput == 'textarea'"
              v-model="attributesValues[attribute.attributeCode]"
              :id="attribute.attributeCode"
              @change="change"
            >
            </b-form-textarea>
            <b-form-datepicker
              v-else-if="attribute.frontendInput == 'date'"
              v-model="attributesValues[attribute.attributeCode]"
              :id="attribute.attributeCode"
              @change="change"
            >
            </b-form-datepicker>
            <b-form-select
              v-else-if="attribute.frontendInput == 'select'"
              v-model="attributesValues[attribute.attributeCode]"
              :options="attribute.options"
              text-field="label"
              :id="attribute.attributeCode"
              @change="change"
            >
            </b-form-select>
            <b-form-checkbox
              v-else-if="attribute.frontendInput == 'boolean'"
              v-model="attributesValues[attribute.attributeCode]"
              :id="attribute.attributeCode"
              @change="change"
            >
            </b-form-checkbox>
            <b-form-input
              v-else-if="attribute.frontendInput == 'price'"
              v-model="attributesValues[attribute.attributeCode]"
              :id="attribute.attributeCode"
              @change="change"
              type="number"
            >
            </b-form-input>
            <b-form-checkbox-group
              v-else-if="attribute.frontendInput == 'multiselect'"
              v-model="attributesValues[attribute.attributeCode]"
              :options="filterOptions(attribute.options)"
              text-field="label"
              :id="attribute.attributeCode"
              @change="change"
            >
            </b-form-checkbox-group>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductEditTabsMagentoCategoryAttributes",
  props: {
    attributes: {
      type: Array,
      default() {
        return {};
      }
    },
    attributesValues: {
      type: Object,
      default() {
        return {};
      }
    },
    isLoading: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      categoryAtributesGroups: []
    };
  },
  mounted: function() {
    this.divideCategoriesAttributes();
  },
  methods: {
    divideCategoriesAttributes() {
      let firstAttributesGroup = [];
      let secondAttributesGroup = [];
      let thirdAttributesGroup = [];
      this.attributes.forEach(attribute => {
        if (
          attribute.frontendInput == "text" ||
          attribute.frontendInput == "select" ||
          attribute.frontendInput == "price" ||
          attribute.frontendInput == "boolean" ||
          attribute.frontendInput == "date"
        ) {
          firstAttributesGroup.push(attribute);
        } else if (attribute.frontendInput == "textarea") {
          secondAttributesGroup.push(attribute);
        } else if (attribute.frontendInput == "multiselect") {
          thirdAttributesGroup.push(attribute);
        }
      });
      for (let i = 0; i < firstAttributesGroup.length; i += 4) {
        this.categoryAtributesGroups.push(firstAttributesGroup.slice(i, i + 4));
      }
      for (let i = 0; i < secondAttributesGroup.length; i += 3) {
        this.categoryAtributesGroups.push(
          secondAttributesGroup.slice(i, i + 3)
        );
      }
      for (let i = 0; i < thirdAttributesGroup.length; i += 3) {
        this.categoryAtributesGroups.push(thirdAttributesGroup.slice(i, i + 3));
      }
    },

    filterOptions(attributeOptions) {
      let options = [];
      attributeOptions.forEach(option => {
        if (option.label !== "" && option.label !== " ") {
          options.push(option);
        }
      });
      return options;
    },
    change() {
      this.$emit("change", true);
    }
  },
  watch: {
    attributes: function() {
      this.divideCategoriesAttributes();
    }
  }
};
</script>
