var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":_vm.id,"cancel-disabled":"","size":"xl"},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('h4',[_vm._v(_vm._s(_vm.title))])]},proxy:true},{key:"modal-footer",fn:function(ref){
var ok = ref.ok;
return [_c('b-button',{attrs:{"variant":"info"},on:{"click":ok}},[_vm._v(" Entendido ")])]}}])},[[_c('b-form-group',[_c('div',[_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('strong',[_vm._v("Categorías")])]),_c('b-col',{attrs:{"md":"9"}},[_c('ApolloQuery',{attrs:{"query":require('../graphql/AllCategories.gql'),"variables":{ companyId: _vm.currentUser.company.id }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_result = ref.result;
var loading = ref_result.loading;
var data = ref_result.data;
return [(loading)?_c('b-spinner',{attrs:{"label":"Spinning"}}):_vm._e(),_c('b-form-group',[_c('v-select',{attrs:{"placeholder":"Seleccionar","options":_vm.categoryOptions(data),"multiple":"","reduce":function (category) { return category.value; }},model:{value:(_vm.categoriesSelected),callback:function ($$v) {_vm.categoriesSelected=$$v},expression:"categoriesSelected"}})],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('strong',[_vm._v("Marcas")])]),_c('b-col',{attrs:{"md":"9"}},[_c('ApolloQuery',{attrs:{"query":require('../graphql/AllBrands.gql'),"variables":{ companyId: _vm.currentUser.company.id }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_result = ref.result;
var loading = ref_result.loading;
var data = ref_result.data;
return [(loading)?_c('b-spinner',{attrs:{"label":"Spinning"}}):_vm._e(),_c('b-form-group',[_c('v-select',{attrs:{"placeholder":"Seleccionar","options":_vm.brandOptions(data),"multiple":"","reduce":function (brand) { return brand.value; }},model:{value:(_vm.brandsSelected),callback:function ($$v) {_vm.brandsSelected=$$v},expression:"brandsSelected"}})],1)]}}])})],1)],1)],1),_c('b-button',{on:{"click":function($event){return _vm.searchProducts()}}},[_vm._v(" FILTRAR ")])],1)],[[_c('product-to-use-table',{attrs:{"products":_vm.products,"selectedProducts":_vm.selectedProducts}})]],_vm._t("default")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }