<template>
  <b-skeleton-img v-if="loading" height="100px"></b-skeleton-img>
  <summary-message-sales-report
    v-else
    :title="widget.name"
    currency=""
    :amount="amount.toString()"
  />
</template>
<script>
import DASHBOARD_NUMBER_CENTRY_DATA from "@/graphql/DashboardNumberCentryData.gql";
import SummaryMessageSalesReport from "@/components/SummaryMessageSalesReport.vue";
export default {
  name: "DashboardTableWithCentryData",
  props: {
    widget: Object
  },
  data() {
    return {
      loading: true,
      amount: null
    };
  },
  components: {
    SummaryMessageSalesReport
  },
  mounted() {
    this.loadWidgetData();
  },
  methods: {
    /**
     * Carga el amount del widget
     */
    async loadWidgetData() {
      this.loading = true;
      const response = await this.$apollo.query({
        query: DASHBOARD_NUMBER_CENTRY_DATA,
        variables: {
          id: this.widget.id
        }
      });
      this.amount = response.data.dashboardNumberCentryDataWidget.numberData;
      this.loading = false;
    }
  }
};
</script>
