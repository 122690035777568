<template>
  <ApolloQuery
    :query="
      require('../../../graphql/MercadoLibre/CatalogSuggestions/AllCatalogSuggestions.gql')
    "
    :fetchPolicy="'cache-and-network'"
    :variables="queryVariables()"
  >
    <template slot-scope="{ result: { error, data }, query, isLoading }">
      <div>
        <div v-if="isLoading && data">
          <b-spinner></b-spinner>
          Actualizando información de la tabla
        </div>
        <b-skeleton-table
          v-if="isLoading && !data"
          :rows="5"
          :columns="5"
          :table-props="{ hover: true }"
        ></b-skeleton-table>
        <b-alert show variant="danger" v-else-if="!!error">
          Ha ocurrido un error al obtener las sugerencias</b-alert
        >
        <b-table-simple v-else>
          <b-thead>
            <b-tr>
              <b-th />
              <b-th>Título</b-th>
              <b-th>Estado</b-th>
              <b-th>Dominio</b-th>
              <b-th />
            </b-tr>
          </b-thead>
          <b-tbody v-if="data">
            <b-tr v-if="!data.allMercadoLibreSuggestions.total">
              <b-td colspan="5"
                >No tienes sugerencias de catálogo registradas</b-td
              >
            </b-tr>
            <mercado-libre-catalog-suggestions-row
              :suggestion="suggestion"
              v-for="suggestion of data.allMercadoLibreSuggestions.suggestions"
              :key="suggestion.id"
              :integration-config-id="integrationConfigId"
            />
          </b-tbody>
        </b-table-simple>
        <b-alert v-if="!!errorSeeingMore" show variant="danger">{{
          errorSeeingMore
        }}</b-alert>
        <b-skeleton
          type="button"
          v-else-if="isLoading"
          class="m-2 float-right"
        ></b-skeleton>
        <b-button
          class="m-2 float-right"
          v-else-if="hasNextPage(data)"
          @click="seeMore(query, data.allMercadoLibreSuggestions.scrollId)"
        >
          Ver más
        </b-button>
      </div>
    </template>
  </ApolloQuery>
</template>
<script>
import MercadoLibreCatalogSuggestionsRow from "./Row.vue";
export default {
  name: "MercadoLibreCatalogSuggestionsTable",
  components: {
    MercadoLibreCatalogSuggestionsRow
  },
  props: {
    integrationConfigId: String
  },
  data() {
    return {
      pageSize: 20,
      pagesLoaded: 1,
      errorSeeingMore: null
    };
  },
  methods: {
    /**
     * Entrega las variables de la query
     * @param {String} scrollId
     * @returns {Object}
     */
    queryVariables(scrollId) {
      return {
        scrollId: scrollId,
        integrationConfigId: this.integrationConfigId,
        limit: this.pageSize
      };
    },
    /**
     * Indica si tiene o no más páginas
     * Se basa en el total de datos, el tamaño de la página
     * y las páginas cargadas
     * @param {Object} data
     * @returns {Boolean}
     */
    hasNextPage(data) {
      return (
        this.$ifNull(
          this.$dig(data, "allMercadoLibreSuggestions", "total"),
          0
        ) >
        this.pageSize * this.pagesLoaded
      );
    },
    /**
     * Trae más resultados de una query avanzando en su cursor.
     * @param {Object} query
     * @param {String} cursor
     */
    async seeMore(query, cursor) {
      this.pagesLoaded += 1;
      await query
        .fetchMore({
          variables: this.queryVariables(cursor),
          updateQuery: (prev, { fetchMoreResult }) => {
            const updated = Object.assign({}, prev);
            if (
              fetchMoreResult?.allMercadoLibreSuggestions?.suggestions
                ?.length !== 0
            ) {
              updated.allMercadoLibreSuggestions.suggestions.push(
                ...fetchMoreResult.allMercadoLibreSuggestions.suggestions
              );
              updated.allMercadoLibreSuggestions.scrollId =
                fetchMoreResult.allMercadoLibreSuggestions.scrollId;
            }
            return updated;
          }
        })
        .catch(err => {
          this.errorSeeingMore = `Error cargando una nueva página: ${err.message}`;
        });
    }
  }
};
</script>
