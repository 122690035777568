<template>
  <div>
    <b-table-simple>
      <b-tbody>
        <b-tr v-for="(cost, position) of costsArray" :key="cost.id">
          <b-td>
            <b-input
              v-model="costsArray[position].description"
              @input="emitChange"
              placeholder="Descripción"
            >
            </b-input>
          </b-td>
          <b-td>
            <b-input
              v-model="costsArray[position].price"
              placeholder="Costo"
              @input="emitChange"
            >
            </b-input>
          </b-td>
          <b-td>
            <b-button variant="danger" @click="deleteCost(position)"
              >X</b-button
            >
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <div>
      <b-button variant="outline-info" @click="addCost">Agregar</b-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "ProductEditMercadoLibreShippingCosts",
  components: {},
  model: {
    prop: "_value",
    event: "change"
  },
  props: {
    _value: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      costsArray: JSON.parse(JSON.stringify(this._value))
    };
  },
  methods: {
    emitChange() {
      this.$emit("change", this.costsArray);
    },
    addCost() {
      if (!this.costsArray) {
        this.costsArray = [];
      }
      const newCost = {
        description: "",
        price: ""
      };
      this.costsArray.push(newCost);
    },
    deleteCost(position) {
      this.costsArray.splice(position, 1);
    }
  },
  watch: {
    costsArray() {
      this.emitChange();
    }
  }
};
</script>
