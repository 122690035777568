<template>
  <b-card no-body>
    <b-tabs>
      <ApolloQuery
        :query="require('../../../graphql/IntegrationConfigsForTabs.gql')"
        :fetchPolicy="'cache-and-network'"
        clientId="apolloClientCached"
      >
        <template slot-scope="{ result: { data } }">
          <div
            v-for="(ic, index) of availableIntegrationConfigs(data)"
            :key="index + '-' + icInfo[index + 1].updates"
          >
            <b-tab :title="ic.label" lazy="">
              <documents-tabs-b-sale
                v-if="isBSale(ic)"
                :ic="ic"
                v-model="icInfo[index + 1].change"
              ></documents-tabs-b-sale>
            </b-tab>
          </div>
        </template>
      </ApolloQuery>
    </b-tabs>
  </b-card>
</template>
<script>
import DocumentsTabsBSale from "./DocumentsTabsBSale.vue";

export default {
  name: "ProductEditTabs",
  props: {
    product: Object
  },
  components: {
    DocumentsTabsBSale
  },
  data() {
    return {
      selected: 0,
      icInfo: [{ change: false, updates: 0 }]
    };
  },
  methods: {
    availableIntegrationConfigs(data) {
      if (!data || !data.allIntegrationConfigs) {
        return [];
      }
      const available = data.allIntegrationConfigs.filter(
        x => x.available && this.hasSeparator(x)
      );
      if (available.length > this.icInfo.length - 1) {
        const toAdd = [];
        for (let i = this.icInfo.length; i <= available.length + 1; i++) {
          toAdd.push({ change: false, updates: 0 });
        }
        this.icInfo.push(...toAdd);
      }
      return available;
    },
    hasSeparator(ic) {
      return this.isBSale(ic);
    },
    isApplication(ic) {
      return ic.identifier === "application";
    },
    isBSale(ic) {
      return ic.identifier === "b_sale";
    },
    isJumpseller(ic) {
      return ic.identifier === "jumpseller";
    },
    isMercadoLibre(ic) {
      return ic.identifier.startsWith("mercado_libre");
    },
    isMirakl(ic) {
      return ic.identifier.startsWith("ripley");
    },
    isRocketInternet(ic) {
      return (
        (ic.identifier.startsWith("linio") ||
          ic.identifier.startsWith("dafiti")) &&
        ic.identifier !== "linio_second" &&
        ic.identifier !== "dafiti_second"
      );
    },
    isShopify(ic) {
      return ic.identifier === "shopify";
    },
    isWooCommerce(ic) {
      return ic.identifier === "woo_commerce";
    },
    isMagento(ic) {
      return ic.identifier === "magento";
    }
  }
};
</script>
