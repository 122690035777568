<template>
  <b-card>
    <b-row>
      <b-col>
        <strong>Origen:</strong>
        {{ order.origin }}
        <br />

        <strong>Número de pedido:</strong>
        {{ order.numberOrigin || order.idOrigin }}
        <br />

        <strong>Creación:</strong>
        {{
          ($timezoneDate(currentUser, order.createdAtOrigin) ||
            $timezoneDate(currentUser, order.createdAt)) | formatDateTime
        }}
        <br />

        <strong>Última actualización:</strong>
        {{
          ($timezoneDate(currentUser, order.UpdatedAtOrigin) ||
            $timezoneDate(currentUser, order.updatedAt)) | formatDateTime
        }}
        <br />

        <strong>Entrega:</strong>
        {{ order.dateForDelivery | formatDateTime }}
      </b-col>

      <b-col>
        <strong>Monto pedido:</strong>
        {{ order.totalAmount | toCurrency(2) }}
        <br />

        <strong>Descuento:</strong>
        {{ order.discountAmount | toCurrency(2) }}
        <br />

        <strong>Despacho:</strong>
        {{ order.shippingAmount | toCurrency(2) }}
        <br />

        <strong>Total pagado:</strong>
        {{ order.paidAmount | toCurrency(2) }}
        <br />
        <strong>Fulfillment: </strong>
        {{ order.modifyStock ? "No" : "Sí" }}
      </b-col>

      <b-col>
        <strong>Cliente:</strong>
        {{ order.buyerFirstName }}
        {{ order.buyerLastName }}
        <br />
        <strong>Tipo:</strong>
        {{ prettyClientType }}
        <br />

        <strong>RUT:</strong>
        {{ order.buyerDni }}
        <br />

        <strong>Email:</strong>
        {{ order.buyerEmail }}
        <br />

        <strong>Teléfono:</strong>
        {{ order.buyerPhone }}
        {{ order.buyerMobilePhone }}
        <template v-if="order.origin === 'WooCommerce'">
          <br />
          <strong>Tipo Entrega:</strong>
          {{ order.shippingType }}
        </template>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "OrderGeneralInfoCard",
  props: {
    order: Object
  },
  computed: {
    ...mapState(["currentUser"]),
    /**
     * Muestra deforma legible el tipo de cliente al usuario
     * @return {String}
     */
    prettyClientType() {
      return this.order.clientType === "guest" ? "Invitado" : "Normal";
    }
  }
};
</script>
