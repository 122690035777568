<template>
  <div>
    <bulk-upload-repair-template-integrations-modal
      :template="templateToRepair"
      :applicationIntegrationsMap="applicationIntegrationsMap"
      ref="repairModal"
      @repair="repairTemplate"
      @error="errorOnRepairTemplate"
    />
    <b-row align-v="center">
      <b-col md="3">
        <b-form-group label="Cargar desde la fila">
          <b-spinbutton
            v-model="startRow"
            :min="1"
            :max="dataSheet.length"
          ></b-spinbutton>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group label="Usar template de carga">
          <b-spinner label="Spinning" v-if="!templates"></b-spinner>
          <b-form-select
            v-else
            v-model="template"
            :options="templateOptions"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-button
          variant="primary"
          :disabled="loadingAutomaticSelection || !templates"
          @click="emitReady"
          >Continuar</b-button
        >
      </b-col>
      <b-col md="2" v-if="loadingAutomaticSelection">
        Cargando Columnas: {{ Math.trunc((pivot / total) * 100) }} %
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-table-simple :responsive="true" :small="true" :fixed="true">
          <b-thead>
            <b-tr>
              <b-td style="width: 30px"></b-td>
              <b-td v-for="(column, idx) of columns" :key="column">
                <base-live-select
                  v-if="
                    (!loadingAutomaticSelection && !loadingTemplate) ||
                      notLoadingColumn[idx]
                  "
                  v-model="columnParams[idx]"
                  @search="searchParams"
                  placeholder="-- Ignorar columna --"
                  :min-search-characters="3"
                  :appendToBody="true"
                  :debounce="1000"
                ></base-live-select>
                <b-spinner label="Spinning" v-else></b-spinner>
              </b-td>
            </b-tr>
            <b-tr>
              <b-th>{{ headerRowNum }}</b-th>
              <b-th v-for="column of columns" :key="column + 'h'">
                <span
                  :title="toString(previewHeader[column])"
                  v-b-tooltip.hover=""
                >
                  {{ cellString(previewHeader[column]) }}
                </span>
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="(row, idx) of previewData" :key="idx + 'row'">
              <b-th>{{ rowNum(idx + 1) }}</b-th>
              <b-td v-for="column of columns" :key="column + 'data'">
                <span
                  :title="toString(row[column], column).toString()"
                  v-b-tooltip.hover=""
                >
                  {{ cellString(row[column], column) }}
                </span>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import ALL_TEMPLATES from "../graphql/AllTemplates.gql";
import BULK_UPLOAD_TEMPLATE_BY_ID from "../graphql/BulkUploadTemplateById.gql";
import SEARCH_PARAM_BY_FILTER from "../graphql/SearchParamByFilter.gql";
import AUTOMATIC_SELECTION_PARAM from "../graphql/AutomaticSelectionParam.gql";
import INTEGRATION_CONFIGS_FOR_PARAM_MATCH from "../graphql/IntegrationConfigsForParamMatch.gql";
import { mapState } from "vuex";
import BaseLiveSelect from "@/components/BaseLiveSelect";
import BulkUploadRepairTemplateIntegrationsModal from "./BulkUpload/RepairTemplateIntegrationsModal.vue";
export default {
  name: "BulkUploadTabsSelectColumns",
  components: {
    BaseLiveSelect,
    BulkUploadRepairTemplateIntegrationsModal
  },
  props: {
    data: Object
  },
  mounted() {
    this.$getAllPages(ALL_TEMPLATES, {}, "allTemplates").then(data => {
      if (data) {
        this.templates = data.map(x => x.node);
      }
    });
    this.updateParams();
    this.$retryQueryWithTimeout(INTEGRATION_CONFIGS_FOR_PARAM_MATCH, {}).then(
      ({ data }) => {
        const applicationIntegrationsMap = {};
        data.allIntegrationConfigs.forEach(x => {
          let appId = this.$ifNull(
            this.$dig(x, "publicIntegrationInformation", "id"),
            ""
          );
          const identifier = this.$dig(
            x,
            "publicIntegrationInformation",
            "identifier"
          );
          if (!identifier || identifier === "application") appId = "";
          if (!applicationIntegrationsMap[appId]) {
            applicationIntegrationsMap[appId] = [];
          }
          applicationIntegrationsMap[appId].push(x);
          this.integrationConfigs[x.id] = x;
        });
        this.applicationIntegrationsMap = applicationIntegrationsMap;
      }
    );
  },
  data() {
    return {
      startRow: 2,
      template: null,
      columns: this.$dig(this.data, "data").length
        ? this.$dig(this.data, "data")[0].map((_x, idx) => idx)
        : [],
      columnParams: this.$dig(this.data, "data").length
        ? this.$dig(this.data, "data")[0].map(() => null)
        : [],
      templates: null,
      applicationIntegrationsMap: null,
      integrationConfigs: {},
      loadingAutomaticSelection: true,
      loadingTemplate: false,
      templateToRepair: null,
      params: {},
      parallelBatches: 5,
      pivot: 0,
      notLoadingColumn: {},
      total: 1
    };
  },
  computed: {
    ...mapState(["currentUser"]),
    dataSheet() {
      return this.$dig(this.data, "data");
    },
    previewData() {
      return this.dataSheet.slice(this.startRow - 1, 6 + this.startRow - 1);
    },
    previewHeader() {
      return this.dataSheet[Math.max(this.startRow - 2, 0)];
    },
    headerRowNum() {
      if (this.startRow === 1) {
        return 1;
      }
      return this.startRow - 1;
    },
    templateOptions() {
      const options = [{ value: null, text: "--Ninguno--" }];
      if (this.templates) {
        const admin = this.templates.filter(x => x.admin);
        const global = this.templates.filter(x => x.global && !x.admin);
        const local = this.templates.filter(x => !x.global && !x.admin);
        if (this.currentUser.role === 0) {
          options.push({
            label: "Administrador",
            options: admin.map(x => {
              return { value: x.id, text: x.name };
            })
          });
        }
        options.push({
          label: "Globales",
          options: global.map(x => {
            return { value: x.id, text: x.name };
          })
        });
        options.push({
          label: "Empresa",
          options: local.map(x => {
            return { value: x.id, text: x.name };
          })
        });
      }
      return options;
    }
  },
  methods: {
    rowNum(num) {
      if (this.startRow === 1) {
        return num;
      }
      return num + this.startRow - 1;
    },
    toString(string, column = null) {
      if (!string && string != 0) {
        return "";
      }
      if (column) {
        let typeOf = this.$dig(
          this.params,
          this.$dig(this.columnParams, column, "value")?.split("_")[0],
          "typeOf"
        );
        if (
          ["datetime", "date"].includes(typeOf) &&
          typeof string == "number"
        ) {
          var date = new Date(Math.round((string - (25567 + 2)) * 86400 * 1000))
            .toISOString()
            .slice(0, 19)
            .replace("T", " ");
          string = date;
        }
      }
      return string.toString();
    },
    /**
     * Transformación previa para valores en celdas a texto
     * en la vista previa de la carga masiva
     * @param {String} string
     * @param {Object} column
     * @return {String}
     */
    cellString(string, column = null) {
      const str = this.toString(string, column);
      if (str.length > 17 && column) {
        return str.slice(0, 17) + "...";
      }
      return str;
    },
    /**
     * Consulta y mapea nombres de columnas de la carga masiva
     * con la mas cercana a los parametros de carga masiva en centry
     */
    updateParams() {
      this.automaticSelection();
    },
    searchParams(search, loading, setOptions) {
      loading(true);

      this.$getAllPages(
        SEARCH_PARAM_BY_FILTER,
        {
          filter: search,
          template: this.template ? this.template.id : null
        },
        "paramsByFilter"
      ).then(data => {
        if (data) {
          setOptions(
            data.map(edge => {
              return edge.node;
            })
          );
          loading(false);
        }
      });
    },
    paramOption(param, integrationConfig, warehouse) {
      if (integrationConfig) {
        return {
          label: param.name + " / " + integrationConfig.label,
          value: param.id + "_" + integrationConfig.id + "_"
        };
      }
      if (warehouse) {
        return {
          label: param.name + " / " + warehouse.name,
          value: param.id + "__" + warehouse.id
        };
      }
      return { label: param.name, value: param.id + "__" };
    },
    /**
     * Divide el total de columnas en batches para realizar consultas
     * Ademas guarda el indice real de cada columna
     * @param {Array<Object>} columnData
     * @returns {Array<Array<String>>}
     */
    splitColumnsInBatches(columnData) {
      let size = 5;
      let ans = [];
      let columnDataWithIndex = columnData.map((val, i) => [val, i]);
      for (let i = 0; i < columnDataWithIndex.length; i += size)
        ans.push(columnDataWithIndex.slice(i, i + size));
      return ans;
    },
    /**
     * Controla las consultas para obtener nombres
     * mas semejantes para las columnas de la carga masiva
     */
    async automaticSelection() {
      this.loadingAutomaticSelection = true;
      this.notLoadingColumn = {};
      let query_columns = this.previewHeader;
      this.total = query_columns.length;
      this.$limitedDataExecution(
        this.splitColumnsInBatches(query_columns),
        this.parallelBatches,
        this.executeAutomaticaSelection
      ).then(() => {
        this.loadingAutomaticSelection = false;
        this.pivot = 0;
      });
    },
    /**
     * Ejecuta una consulta de seleccion automatica de nombres para las columnas de carga masiva
     *  @param {Array<Array<Object, Integer>>} columnData
     */
    async executeAutomaticaSelection(columnData) {
      let data = await this.$retryQueryWithTimeout(
        AUTOMATIC_SELECTION_PARAM,
        {
          template: this.template,
          columns: columnData.map(val => val[0])
        },
        30000
      );

      let best_choices = this.$dig(data, "data", "automaticSelectionParam");
      if (best_choices) {
        best_choices.forEach((col, idx) => {
          let real_index = columnData[idx][1];
          if (col.value) {
            this.columnParams[real_index] = col;
            let aux = this.$dup(this.params);
            aux[col.value.split("_")[0]] = col;
            this.params = aux;
          } else {
            this.columnParams[real_index] = null;
          }
          let auxLoading = this.$dup(this.notLoadingColumn);
          auxLoading[real_index] = true;
          this.notLoadingColumn = auxLoading;
        });
        this.pivot += best_choices.length;
      }
    },
    setGlobalTemplate(template) {
      this.template = template.id;
      this.automaticSelection();
    },
    /**
     * Indica si el template tiene o no algún parámetro con una
     * integración eliminada
     * @param {Object} template
     * @returns {Boolean}
     */
    hasDeletedIntegrationConfigs(template) {
      let hasDeleted = false;
      template.sortedParams.forEach(templateParam => {
        const param = templateParam.param;
        if (param.publicApplicationInformation && !templateParam.config) {
          hasDeleted = true;
          return;
        }
      });
      return hasDeleted;
    },
    /**
     * Limpia todos los datos de las columnas
     */
    clearColumns() {
      this.columnParams.forEach((x, idx) => {
        this.columnParams[idx] = null;
      });
    },
    /**
     * Setea el template, retorna true si la selección pudo realizarse
     * @param {Object} template
     * @returns {Boolean}
     */
    setTemplate(template) {
      if (template.global) {
        this.setGlobalTemplate(template);
      } else if (this.hasDeletedIntegrationConfigs(template)) {
        this.templateToRepair = template;
        this.$refs.repairModal.showModal();
        this.clearColumns();
      } else {
        this.clearColumns();
        template.sortedParams.forEach((templateParam, idx) => {
          if (idx < this.columnParams.length) {
            this.columnParams[idx] = this.paramOption(
              templateParam.param,
              templateParam.config,
              templateParam.warehouse
            );
          }
        });
      }
    },
    performEmitReady() {
      this.$emit(
        "ready",
        Object.assign(this.data, {
          columnParams: this.columnParams.map(x => this.$dig(x, "value")),
          startRow: this.startRow,
          integrationConfigs: this.integrationConfigs
        })
      );
    },
    emitReady() {
      const emptyColumns = this.columnParams
        .map(cp => (cp ? 0 : 1))
        .reduce((accum, currentVal) => accum + currentVal);
      if (emptyColumns > 0) {
        this.$swal
          .fire({
            title: "Columnas ignoradas",
            text:
              "Hay " +
              emptyColumns +
              " columnas ignoradas. ¿Estás seguro de continuar?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "No"
          })
          .then(result => {
            if (result.value) {
              this.performEmitReady();
            }
          });
      } else {
        this.performEmitReady();
      }
    },
    async updateTemplate() {
      if (this.template) {
        this.loadingTemplate = true;
        let template = await this.getTemplate(this.template);
        this.setTemplate(template);
        this.loadingTemplate = false;
      } else {
        this.automaticSelection();
      }
    },
    /**
     * Obtiene un template por su id
     * @method
     * @param {String} template_id
     * @returns {Object}
     */
    async getTemplate(template_id) {
      let template = await this.$retryQueryWithTimeout(
        BULK_UPLOAD_TEMPLATE_BY_ID,
        {
          id: template_id
        }
      );
      return template?.data?.bulkUploadTemplate || { sortedParams: [] };
    },
    /**
     * Selecciona el template reparado
     * @param {Object} template
     */
    repairTemplate(template) {
      this.loadingTemplate = true;
      this.setTemplate(template);
      this.loadingTemplate = false;
    },
    /**
     * Muestra un error si falló la reparación de
     * template
     */
    errorOnRepairTemplate() {
      this.$swal.fire({
        title: "Ha ocurrido un error",
        text:
          "Ha ocurrido un error al actualizar el template. Debe utilizar otro",
        icon: "error"
      });
    }
  },
  watch: {
    template() {
      this.updateTemplate();
    },
    startRow() {
      this.updateTemplate();
    }
  }
};
</script>
<style scoped>
.table thead th {
  padding: 1px !important;
  text-transform: none !important;
  font-size: 12px;
  color: black;
  background-color: #c3c4c4;
  border: 1px solid #939b9d;
}
.table tbody th {
  background-color: #c3c4c4;
  border: 1px solid #939b9d;
}
.table td {
  padding: 0 !important;
  height: auto;
  border: 1px solid #939b9d;
  width: 200px;
}
</style>
