<template>
  <div>
    <base-header title="Productos" title-link="#">
      <template v-slot:actions>
        <b-container>
          <b-button :to="'/product/new'" variant="outline-info">
            <b-icon-plus></b-icon-plus>
            Nuevo
          </b-button>
        </b-container>
      </template>
      <template v-slot:filters>
        <products-simple-filter></products-simple-filter>
        <products-filters></products-filters>
      </template>
    </base-header>
    <b-container fluid="">
      <b-row>
        <b-col md="12">
          <products-table></products-table>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import BaseHeader from "../components/BaseHeader.vue";
import ProductsTable from "../components/ProductsTable.vue";
import ProductsFilters from "../components/ProductsFilters.vue";
import ProductsSimpleFilter from "../components/ProductsSimpleFilter.vue";
import { centryUrl } from "../main";

export default {
  name: "Products",
  components: {
    ProductsSimpleFilter,
    BaseHeader,
    ProductsTable,
    ProductsFilters
  },
  data() {
    return {
      centryUrl
    };
  }
};
</script>
