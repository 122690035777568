<template>
  <b-row>
    <b-col cols="5">
      <b-form-group>
        <template #label>
          <b-row align-h="between" class="px-3">
            <span>Templates</span>
            <base-help-modal
              id="helpPriceFactor"
              tooltip="¿Qué es esto?"
              title="Templates de carga masiva"
              size="md"
            >
              <div>
                <p>
                  Los templates de carga masiva permiten generar templates con
                  campos pre-establecidos.
                </p>
                <p>
                  Para crear, eliminar o modificar templates, presionar el botón
                  "Administrar templates"
                </p>
              </div>
            </base-help-modal>
          </b-row>
        </template>
        <b-skeleton type="input" v-if="!templateOptions"></b-skeleton>
        <b-form-select
          v-else
          v-model="template"
          :options="templateOptions"
        ></b-form-select>
      </b-form-group>
    </b-col>
    <b-col align-self="end" cols="3">
      <download-bulk-upload
        :template="template"
        @success-download="successDownload"
        @error="emitError"
      />
    </b-col>
    <b-col align-self="end" cols="4">
      <b-form-group>
        <b-button
          :href="centryUrl + '/admin/cargamasiva_plantillas'"
          variant="primary"
          class="w-100"
        >
          Administrar tempĺates
        </b-button>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import ALL_TEMPLATES from "../../../graphql/BulkUpload/TemplateDownload/AllTemplatesForDownload.gql";
import BaseHelpModal from "../../BaseHelpModal.vue";
import DownloadBulkUpload from "./DownloadBulkUpload.vue";
import { centryUrl } from "../../../main";
import { mapState } from "vuex";
export default {
  name: "ManageTemplates",
  components: {
    BaseHelpModal,
    DownloadBulkUpload
  },
  data() {
    return {
      centryUrl,
      templateOptions: null,
      template: {}
    };
  },
  created() {
    this.$getAllPages(ALL_TEMPLATES, {}, "allTemplates").then(data => {
      if (data) {
        let templates = data.map(x => x.node);
        let options = [];
        const admin = templates.filter(x => x.admin);
        const global = templates.filter(x => x.global && !x.admin);
        const local = templates.filter(x => !x.global && !x.admin);
        if (this.currentUser.role === 0) {
          options.push({
            label: "Administrador",
            options: admin.map(x => {
              return {
                value: {
                  global: true,
                  id: x.id,
                  type: x.templateType,
                  admin: true
                },
                text: x.name
              };
            })
          });
        }
        options.push({
          label: "Globales",
          options: global.map(x => {
            return {
              value: {
                global: true,
                id: x.id,
                type: x.templateType,
                admin: false
              },
              text: x.name
            };
          })
        });
        options.push({
          label: "Empresa",
          options: local.map(x => {
            return {
              value: {
                global: false,
                id: x.id,
                type: x.templateType,
                admin: false
              },
              text: x.name
            };
          })
        });
        this.templateOptions = options;
      }
    });
  },
  computed: {
    ...mapState(["currentUser"])
  },
  methods: {
    /**
     * Emite el evento indicando que la descarga fue correcta
     */
    successDownload() {
      this.$emit("success-download");
    },
    /**
     * Emite el evento indicando hubo un error
     * @param {String} error
     */
    emitError(error) {
      this.$emit("error", error);
    }
  }
};
</script>
