<template>
  <div>
    <ApolloQuery
      :query="require('../graphql/AllV3BulkUpload.gql')"
      :variables="{ cursor: '' }"
      :fetchPolicy="'cache-and-network'"
      clientId="apolloClientCached"
      notifyOnNetworkStatusChange
    >
      <template slot-scope="{ result: { error, data }, query, isLoading }">
        <span v-if="data && isLoading" class="m-2 float-right">
          Actualizando lista de descargas...
        </span>
        <b-table-simple responsive v-if="data && data.allV3BulkUpload" hover="">
          <b-thead>
            <b-tr>
              <b-th>Fecha</b-th>
              <b-th>Responsable</b-th>
              <b-th>Resultado</b-th>
              <b-th>Formulario</b-th>
              <b-th>Planilla Original</b-th>
              <b-th>Errores corregidos</b-th>
              <b-th>Informe de Errores</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-if="!data.allV3BulkUpload.edges.length">
              <b-td colspan="6">No existe registro de ninguna carga</b-td>
            </b-tr>
            <bulk-upload-histories-table-row
              v-for="history of data.allV3BulkUpload.edges"
              :key="history.node.id"
              :history="history.node"
            >
            </bulk-upload-histories-table-row>
          </b-tbody>
        </b-table-simple>
        <b-alert v-if="error" show="" variant="danger">
          Ha ocurrido un error
        </b-alert>
        <b-spinner
          v-if="isLoading"
          label="Spinning"
          class="m-2 float-right"
        ></b-spinner>
        <div v-else>
          <b-button
            v-if="hasNextPage(data)"
            class="m-2 float-right"
            @click="seeMore(query, data.allV3BulkUpload.pageInfo.endCursor)"
          >
            Ver más
          </b-button>
          <b-alert v-else show variant="light" class="text-center">
            No hay más datos para mostrar.
          </b-alert>
        </div>
      </template>
    </ApolloQuery>
  </div>
</template>
<script>
import BulkUploadHistoriesTableRow from "@/components/BulkUploadHistoriesTableRow";
export default {
  name: "BulkUploadHistoriesTable",
  components: {
    BulkUploadHistoriesTableRow
  },
  methods: {
    /**
     * Indica si hay una página siguiente en la query de GraphQl basado
     * exclusivamente en la existencia de un cursor.
     * @return {boolean}
     */
    hasNextPage(data) {
      return data?.allV3BulkUpload?.pageInfo?.endCursor != null;
    },
    /**
     * Trae más resultados de una query avanzando en su cursor.
     * @param {Object} query
     * @param {String} cursor
     */
    async seeMore(query, cursor) {
      await query.fetchMore({
        variables: {
          cursor: cursor
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          const updated = Object.assign({}, this.$dup(prev));
          updated.allV3BulkUpload.pageInfo =
            fetchMoreResult.allV3BulkUpload.pageInfo;
          if (fetchMoreResult?.allV3BulkUpload?.edges?.length !== 0) {
            updated.allV3BulkUpload.edges.push(
              ...fetchMoreResult.allV3BulkUpload.edges
            );
          }
          return updated;
        }
      });
    }
  }
};
</script>
