import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import "./plugins/bootstrap-vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import { createProvider } from "./vue-apollo";
import { IconsPlugin, ToastPlugin } from "bootstrap-vue";
import VueSweetalert2 from "vue-sweetalert2";
import tinymce from "vue-tinymce-editor";
import DatePicker from "vue2-datepicker";
import JSZip from "jszip";
import PrettyCheckbox from "pretty-checkbox-vue";
import VueGtag from "vue-gtag";
import {
  XlsxRead,
  XlsxSheets,
  XlsxJson,
  XlsxDownload,
  XlsxWorkbook,
  XlsxSheet
} from "vue-xlsx";
import vSelect from "vue-select";
import moment from "moment-timezone";
import VueMoment from "vue-moment";
import draggable from "vuedraggable";
import VueProgress from "vue-progress";
import VueRecaptcha from "vue-recaptcha";
import "sweetalert2/dist/sweetalert2.min.css";
import "vue-select/dist/vue-select.css";
import "vue2-datepicker/index.css";
import "pretty-checkbox/dist/pretty-checkbox.min.css";
import VueObserveVisibility from "vue-observe-visibility";
import VueTour from "vue-tour";
import UPDATE_PRODUCT from "./graphql/UpdateProduct.gql";
import CREATE_PRODUCT from "./graphql/CreateProduct.gql";
import CREATE_PRODUCTS from "./graphql/CreateProducts.gql";
import UPDATE_PRODUCTS from "./graphql/UpdateProducts.gql";
import UNPUBLISH_PRODUCTS from "./graphql/UnpublishProducts.gql";
import SYNCHRONIZE_PRODUCTS from "./graphql/SynchronizeProducts.gql";
import CREATE_VARIANT from "./graphql/CreateVariant.gql";
import CREATE_VARIANTS from "./graphql/CreateVariants.gql";
import UPDATE_VARIANT from "./graphql/UpdateVariant.gql";
import UPDATE_VARIANTS from "./graphql/UpdateVariants.gql";
import CREATE_VARIANT_WAREHOUSE from "./graphql/CreateVariantWarehouse.gql";
import CREATE_VARIANT_WAREHOUSES from "./graphql/CreateVariantWarehouses.gql";
import UPDATE_VARIANT_WAREHOUSE from "./graphql/UpdateVariantWarehouse.gql";
import UPDATE_VARIANT_WAREHOUSES from "./graphql/UpdateVariantWarehouses.gql";
import SYNCHRONIZE_PRODUCT from "./graphql/SynchronizeProduct.gql";
import CREATE_HOMOLOGATION_MAP from "./graphql/CreateHomologationMap.gql";
import UPDATE_HOMOLOGATION_MAP from "./graphql/updateHomologationMap.gql";
import UPDATE_VISUALIZATION_HOMOLOGATION_MAP from "./graphql/updateVisualizationHomologationMap.gql";
import CREATE_MAGENTO_ATTRIBUTE_SET from "./graphql/CreateMagentoAttributeSet.gql";
import CREATE_MAGENTO_ATTRIBUTE from "./graphql/SaveMagentoAttribute.gql";
import MAGENTO_ADD_OPTION_TO_ATTRIBUTE from "./graphql/AddOptionsToMagentoAttribute.gql";
import CREATE_ORDER_NOTE from "./graphql/CreateOrderNote.gql";
import CREATE_ORDER_DOCUMENT from "./graphql/CreateOrderDocument.gql";
import CREATE_PROMOTION from "./graphql/integrations/jumpseller/jumpsellerCreatePromotion.gql";
import UPDATE_PROMOTION from "./graphql/integrations/jumpseller/jumpsellerUpdatePromotion.gql";

require("vue-tour/dist/vue-tour.css");

Vue.component("tinymce", tinymce);
Vue.component("v-select", vSelect);
Vue.component("date-picker", DatePicker);
Vue.component("draggable", draggable);
Vue.component("xlsx-read", XlsxRead);
Vue.component("xlsx-sheets", XlsxSheets);
Vue.component("xlsx-json", XlsxJson);
Vue.component("xlsx-workbook", XlsxWorkbook);
Vue.component("xlsx-download", XlsxDownload);
Vue.component("xlsx-sheet", XlsxSheet);
Vue.component("vue-recaptcha", VueRecaptcha);
Vue.use(ToastPlugin);
Vue.use(IconsPlugin);
Vue.use(VueSweetalert2);
Vue.use(VueMoment, { moment });
Vue.use(VueProgress);
Vue.use(PrettyCheckbox);
Vue.use(VueObserveVisibility);
Vue.use(VueTour);
Vue.use(
  VueGtag,
  {
    config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID }
  },
  router
);

export const centryUrl = process.env.VUE_APP_CENTRY_URL;
export const recaptchaSiteKey = process.env.VUE_APP_RECAPTCHA_SITE_KEY;
export const dashboardId = process.env.VUE_APP_DASHBOARD_ID;
Vue.config.productionTip = false;

export const createProducts = (apollo, create, v3BulkUploadId) => {
  return apollo.mutate({
    mutation: CREATE_PRODUCTS,
    variables: {
      create: create,
      v3BulkUploadId: v3BulkUploadId
    }
  });
};

export const createHomologationMap = (
  apollo,
  homologation_map,
  model_type,
  integration_config_id
) => {
  return apollo.mutate({
    mutation: CREATE_HOMOLOGATION_MAP,
    variables: {
      create: homologation_map,
      model_type: model_type,
      integration_config_id: integration_config_id
    }
  });
};

export const updateHomologationMap = (
  apollo,
  homologation_map,
  integration_config_id,
  translation_centry_integration_id
) => {
  return apollo.mutate({
    mutation: UPDATE_HOMOLOGATION_MAP,
    variables: {
      translation_centry_integration_id: translation_centry_integration_id,
      patch: homologation_map,

      integration_config_id: integration_config_id
    }
  });
};

export const updateVisualizationHomologationMap = (
  apollo,
  homologation_map,
  integration_config_id,
  translation_centry_integration_id
) => {
  return apollo.mutate({
    mutation: UPDATE_VISUALIZATION_HOMOLOGATION_MAP,
    variables: {
      translation_centry_integration_id: translation_centry_integration_id,
      patch: homologation_map,

      integration_config_id: integration_config_id
    }
  });
};

export const updateProduct = (apollo, id, patch) => {
  return apollo.mutate({
    mutation: UPDATE_PRODUCT,
    variables: {
      id: id,
      patch: patch
    }
  });
};

export const createProduct = (apollo, patch) => {
  return apollo.mutate({
    mutation: CREATE_PRODUCT,
    variables: {
      patch: patch
    }
  });
};

export const updateProducts = (apollo, patch, v3BulkUploadId) => {
  return apollo.mutate({
    mutation: UPDATE_PRODUCTS,
    variables: {
      patch: patch,
      v3BulkUploadId: v3BulkUploadId
    }
  });
};

export const unpublishProducts = (
  apollo,
  patch,
  reportResultTo,
  v3BulkUploadId
) => {
  return apollo.mutate({
    mutation: UNPUBLISH_PRODUCTS,
    variables: {
      patch: patch,
      reportResultTo,
      v3BulkUploadId
    }
  });
};

export const createPromotion = (apollo, ic_id, patch) => {
  const variables = {
    ic_id: ic_id,
    promotion: patch
  };
  return apollo.mutate({
    mutation: CREATE_PROMOTION,
    variables: variables
  });
};

export const updatePromotion = (apollo, ic_id, promotion_id, patch) => {
  const variables = {
    ic_id: ic_id,
    promotion: patch,
    promotion_id: promotion_id
  };
  return apollo.mutate({
    mutation: UPDATE_PROMOTION,
    variables: variables
  });
};

export const synchronizeProducts = (apollo, ids, reportResultTo) => {
  return apollo.mutate({
    mutation: SYNCHRONIZE_PRODUCTS,
    variables: {
      ids: ids,
      reportResultTo: reportResultTo
    }
  });
};

export const createVariant = (apollo, create) => {
  return apollo.mutate({
    mutation: CREATE_VARIANT,
    variables: {
      create: create
    }
  });
};

export const createVariants = (apollo, create, v3BulkUploadId) => {
  return apollo.mutate({
    mutation: CREATE_VARIANTS,
    variables: {
      create: create,
      v3BulkUploadId: v3BulkUploadId
    }
  });
};

export const updateVariant = (apollo, id, patch) => {
  return apollo.mutate({
    mutation: UPDATE_VARIANT,
    variables: {
      id: id,
      patch: patch
    }
  });
};

export const updateVariants = (apollo, patch, v3BulkUploadId) => {
  return apollo.mutate({
    mutation: UPDATE_VARIANTS,
    variables: {
      patch: patch,
      v3BulkUploadId: v3BulkUploadId
    }
  });
};

export const createVariantWarehouse = (apollo, create) => {
  return apollo.mutate({
    mutation: CREATE_VARIANT_WAREHOUSE,
    variables: {
      create: create
    }
  });
};

export const createVariantWarehouses = (apollo, create, v3BulkUploadId) => {
  return apollo.mutate({
    mutation: CREATE_VARIANT_WAREHOUSES,
    variables: {
      create: create,
      v3BulkUploadId: v3BulkUploadId
    }
  });
};

export const updateVariantWarehouse = (apollo, id, patch) => {
  return apollo.mutate({
    mutation: UPDATE_VARIANT_WAREHOUSE,
    variables: {
      id: id,
      patch: patch
    }
  });
};

export const updateVariantWarehouses = (apollo, patch, v3BulkUploadId) => {
  return apollo.mutate({
    mutation: UPDATE_VARIANT_WAREHOUSES,
    variables: {
      patch: patch,
      v3BulkUploadId: v3BulkUploadId
    }
  });
};

export const createOrderNote = (apollo, create) => {
  return apollo.mutate({
    mutation: CREATE_ORDER_NOTE,
    variables: {
      create: create
    }
  });
};

export const createOrderDocument = (apollo, create) => {
  return apollo.mutate({
    mutation: CREATE_ORDER_DOCUMENT,
    variables: {
      create: create
    },
    context: {
      hasUpload: true
    }
  });
};

export const isPromisePending = async promise => {
  const state = await promiseState(promise);
  return state == "pending";
};

export const promiseState = async promise => {
  // https://makandracards.com/makandra/46681-javascript-how-to-query-the-state-of-a-promise
  // Symbols and RegExps are never content-equal
  const uniqueValue = window["Symbol"] ? Symbol("unique") : /unique/;

  function notifyPendingOrResolved(value) {
    if (value === uniqueValue) {
      return "pending";
    } else {
      return "fulfilled";
    }
  }

  function notifyRejected(reason) {
    console.error(reason);
    return "rejected";
  }

  var race = [promise, Promise.resolve(uniqueValue)];
  return await Promise.race(race).then(notifyPendingOrResolved, notifyRejected);
};

export const synchronizeProduct = (apollo, productId, icIds, type) => {
  return apollo.mutate({
    mutation: SYNCHRONIZE_PRODUCT,
    variables: {
      productId: productId,
      icIds: icIds,
      type: type
    }
  });
};

export const createMagentoAttributeSet = (apollo, icId, mId, name) => {
  if (!Number.isInteger(mId)) mId = parseInt(mId);
  return apollo.mutate({
    mutation: CREATE_MAGENTO_ATTRIBUTE_SET,
    variables: {
      integrationConfigId: icId,
      magentoId: mId,
      name: name
    }
  });
};

export const saveMagentoAttribute = (
  apollo,
  attributeSetId,
  attributeId,
  centryModel,
  attributeCode
) => {
  return apollo.mutate({
    mutation: CREATE_MAGENTO_ATTRIBUTE,
    variables: {
      apiMagentoAttributeSetId: attributeSetId,
      centryModel: centryModel,
      attributeId: attributeId,
      attributeCode: attributeCode
    }
  });
};

export const addOptionToMagentoAttribute = (apollo, attributeId, options) => {
  return apollo.mutate({
    mutation: MAGENTO_ADD_OPTION_TO_ATTRIBUTE,
    variables: {
      attributeId: attributeId,
      options: options
    }
  });
};

export const getEndCompareDate = (startDate, endDate, startCompareDate) => {
  endDate = moment(endDate);
  startDate = moment(startDate);

  let days = endDate.diff(startDate, "days");

  let newEndDate = moment(startCompareDate)
    .add("days", days)
    .toISOString();
  return newEndDate;
};

export const setCubeQueryFilters = (
  query,
  companyId,
  startDate,
  endDate,
  filters
) => {
  startDate = startDate.slice(0, 11) + "00:00:00.000";
  endDate = endDate.slice(0, 11) + "23:59:59.999";
  let finalQuery = query
    .replace("{{companyId}}", '"' + companyId + '"')
    .replace("{{startDate}}", '"' + startDate + '"')
    .replace("{{endDate}}", '"' + endDate + '"')
    .replace(
      "{{granularity}}",
      '"' + (filters.granularity ? filters.granularity : "day") + '"'
    );
  let status = "cancelled";
  if (filters.origins && filters.origins.length) {
    finalQuery = finalQuery.replace(
      "{{originFilter}}",
      ',{"member": "OrderItems.origin","operator": "equals", "values": [' +
        filters.origins.map(x => '"' + x + '"').join(",") +
        `]},{"member": "OrderItems.orderStatus", "operator": "notEquals", "values": ["\\"${status}\\""]}`
    );
  } else {
    finalQuery = finalQuery.replace(
      "{{originFilter}}",
      `,{"member": "OrderItems.orderStatus", "operator": "notEquals", "values": ["\\"${status}\\""]}`
    );
  }
  if (filters.brandIds && filters.brandIds.length) {
    finalQuery = finalQuery.replace(
      "{{brandFilter}}",
      ', {"member": "Variants.brandId","operator": "equals", "values": [' +
        filters.brandIds.map(x => '"' + x + '"').join(",") +
        "]}"
    );
  } else {
    finalQuery = finalQuery.replace("{{brandFilter}}", "");
  }
  if (filters.categoryIds && filters.categoryIds.length) {
    finalQuery = finalQuery.replace(
      "{{categoryFilter}}",
      ', {"member": "Variants.categoryId","operator": "equals", "values": [' +
        filters.categoryIds.map(x => '"' + x + '"').join(",") +
        "]}"
    );
  } else {
    finalQuery = finalQuery.replace("{{categoryFilter}}", "");
  }
  return finalQuery;
};

/**
 * Extrae un subobjecto de object
 * considerando solo las "keys"
 * @param {Object} object
 * @param {Array} keys
 * @return {Object}
 */
Vue.prototype.$only = function(object, keys) {
  const reduced = {};
  keys.forEach(key => {
    const elem = object[key];
    if (elem !== undefined) {
      reduced[key] = elem;
    }
  });
  return reduced;
};

Vue.prototype.$userGroup = function(user) {
  if (!user.userGroups) {
    return null;
  }
  return user.userGroups.find(x => x.company.id === user.company.id);
};

Vue.prototype.$dig = function(object, ...args) {
  if (!object || !args) {
    return undefined;
  }
  return args.reduce(
    (xs, x) => (xs && typeof xs !== "undefined" ? xs[x] : undefined),
    object
  );
};

Vue.prototype.$deepSet = function(object, value, ...args) {
  if (!object || !args || !args.length) {
    return;
  }
  let curr = object;
  args.forEach((x, idx) => {
    if (idx === args.length - 1) {
      curr[x] = value;
    } else if (!curr[x]) {
      curr[x] = {};
    }
    curr = curr[x];
  });
};

Vue.prototype.$baseCubeQuery = async function(
  jsonQuery,
  token,
  externalLimit,
  timeZoneName
) {
  const limit = 5000;
  let offset = 0;
  jsonQuery.timezone = timeZoneName;
  let resultSet = [];
  let finalResults = [];
  let tries = 0;
  do {
    jsonQuery.offset = offset;
    offset += limit;
    await fetch(
      process.env.VUE_APP_CUBE_URL +
        "load?query=" +
        encodeURIComponent(JSON.stringify(jsonQuery)),
      {
        headers: {
          Authorization: token
        }
      }
    )
      .then(resp => resp.json())
      .then(async resp => {
        if (resp.error === "Continue wait" && tries < 5) {
          offset -= limit;
          await new Promise(r => setTimeout(r, 2000));
          tries++;
        } else if (resp.error === "Continue wait") {
          tries = 0;
          finalResults = { timeoutError: "Continue wait. More than 5 retries" };
        } else if (resp.error) {
          finalResults = { error: resp.error };
        } else {
          resultSet = resp.data;
          finalResults.push.apply(finalResults, resultSet);
          tries = 0;
        }
      });
  } while (tries || (!externalLimit && resultSet.length === limit));
  return finalResults;
};

/**
 * Si hay más elementos que maxConcurrentProcessing en el arreglo processing
 * se espera a que termine una se las promesas para
 * dar paso a otra, y evitar que se ejecuten más de maxConcurrentProcessing
 * en paralelo
 * @param {Array[Promise]} processing
 * @param {Integer} maxConcurrentProcessing
 * @return {Array[Promise]} las promesas que aun no han sido resueltas
 */
Vue.prototype.$concurrentVacancy = async function(
  processing,
  maxConcurrentProcessing
) {
  if (processing.length < maxConcurrentProcessing) {
    return processing;
  }
  await Promise.race(processing); // Espera a que termine uno
  const aux = [];
  for (const p of processing) {
    if (await isPromisePending(p)) {
      aux.push(p);
    }
  }
  return aux;
};

Vue.prototype.$cubeQuery = async function(
  query,
  token,
  externalLimit,
  timeZoneName
) {
  timeZoneName = timeZoneName || "America/Santiago";
  const jsonQuery = JSON.parse(query);
  return this.$baseCubeQuery(jsonQuery, token, externalLimit, timeZoneName);
};

Vue.prototype.$deepMerge = function(obj1, obj2, concatenate = true) {
  let result = obj1;

  for (let key in obj2) {
    if (!(key in result)) {
      result[key] = obj2[key];
    } else if (Array.isArray(result[key]) && Array.isArray(obj2[key])) {
      if (concatenate) {
        result[key] = result[key].concat(obj2[key]);
      } else {
        result[key] = obj2[key];
      }
    } else if (typeof result[key] == "object" && typeof obj2[key] == "object") {
      if (result[key] == null) {
        result[key] = obj2[key];
      } else if (obj2[key] == null) {
        return result;
      } else {
        result[key] = this.$deepMerge(result[key], obj2[key], concatenate);
      }
    } else {
      result[key] = obj2[key];
    }
  }

  return result;
};

Vue.prototype.$sameSet = function(array1, array2) {
  const arr1 = this.$ifNull(array1, []);
  const arr2 = this.$ifNull(array2, []);
  if (arr1.length !== arr2.length) return false;
  const h1 = {};
  arr1.forEach(x => {
    h1[x] = true;
  });
  const h2 = {};
  arr2.forEach(x => {
    h2[x] = true;
  });
  for (let i = 0; i < arr1.length; i++) {
    if (!h2[arr1[i]]) return false;
    if (!h1[arr2[i]]) return false;
  }
  return true;
};

Vue.prototype.$toCamelCase = function(string) {
  return string.replace(/([-_][a-z0-9])/g, group =>
    group
      .toUpperCase()
      .replace("-", "")
      .replace("_", "")
  );
};

Vue.prototype.$toSnakeCase = function(string) {
  return string.replace(
    /([a-z0-9][A-Z])/g,
    group => group[0] + "_" + group[1].toLowerCase()
  );
};

Vue.prototype.$dup = function(object) {
  return JSON.parse(JSON.stringify(object));
};
Vue.prototype.$splitArray = function(array, size) {
  const arrays = [];
  let current = 0;
  while (current < array.length) {
    arrays.push(array.slice(current, current + size));
    current += size;
  }
  return arrays;
};
Vue.prototype.$arrayWrap = function(obj) {
  return Array.isArray(obj) ? obj : [obj];
};
Vue.prototype.$ifNull = function(data, ifNullVal) {
  return data ? data : ifNullVal;
};
Vue.prototype.$isBlank = function(value) {
  return value == null || value.length === 0;
};
Vue.prototype.$isPresent = function(value) {
  return !this.$isBlank(value);
};
/**
 * Entrega el string transformado a fecha
 * @param {String} value
 * @returns {Date}
 */
Vue.prototype.$toDate = function(value) {
  if (value === null || value === undefined || value === "") {
    return null;
  }
  return new Date(value);
};
Vue.prototype.$timezoneDate = function(user, string) {
  if (!string) return null;
  let date = new Date(string);
  if (!user) return date;
  Date.prototype.toLocaleISOString = function() {
    const zOffsetMs = this.getTimezoneOffset() * 60 * 1000;
    const localTimeMs = this - zOffsetMs;
    const date = new Date(localTimeMs);
    const utcOffsetHr = this.getTimezoneOffset() / 60;
    const utcOffsetSign = utcOffsetHr <= 0 ? "+" : "-";
    const utcOffsetString =
      utcOffsetSign +
      (utcOffsetHr.toString.length == 1
        ? `0${utcOffsetHr}`
        : `${utcOffsetHr}`) +
      ":00";
    return date.toISOString().replace("Z", utcOffsetString);
  };
  const dateWithTz = new Date(string).toLocaleISOString("en-US", {
    timeZone: user.company.timeZoneName
  });
  return dateWithTz == "Invalid Date" ? null : dateWithTz;
};

/**
 * Reintenta una función cierto numero de veces, y cada cierto rango de tiempo.
 * @param {Integer} maxRetries
 * @param { () => {Object} } queryCallback
 * @param {Integer} timeUntilRetry
 * @returns {Object}
 */
Vue.prototype.$retryCall = async function(
  maxRetries,
  callback,
  timeUntilRetry = 0
) {
  let data;
  let raiseError;
  let counter = 0;
  while (maxRetries != counter) {
    try {
      // Si puede recibir un argumento es que viene de
      // retryQueryWithTimeout o retryMutationWithTimeout
      if (callback.length) {
        data = await callback(counter);
      } else {
        data = await callback();
      }

      break;
    } catch (error) {
      await new Promise(r => setTimeout(() => r(), timeUntilRetry));
      raiseError = error;
      counter++;
    }
  }
  if (!data) {
    throw raiseError;
  }
  return data;
};

/**
 * Crea una función lambda que ejecuta una query para luego ejecutar el
 * retryCall
 * @param {Query} query
 * @param {Object} variables
 * @param {String} cursor
 * @param {Int} timeWaiting
 * @param {String} fetchPolicy
 * @returns {Object}
 */
Vue.prototype.$retryQueryWithTimeout = async function(
  query,
  variables,
  timeWaiting = 10000,
  fetchPolicy = "cache-first"
) {
  let getData = async retry => {
    const data = await this.$apollo.query({
      query: query,
      variables: Object.assign({}, variables, { try: retry }),
      context: {
        timeout: timeWaiting
      },
      fetchPolicy: fetchPolicy
    });
    return data;
  };
  return await this.$retryCall(5, getData);
};

/**
 * Crea una función lambda que ejecuta una mutación para luego ejecutar el
 * retryCall
 * @param {Mutation} mutation
 * @param {Object} variables
 * @param {Int} timeWaiting
 * @returns {Object}
 */
Vue.prototype.$retryMutationWithTimeout = async function(
  mutation,
  variables,
  timeWaiting = 10000
) {
  let getData = async retry => {
    const data = await this.$apollo.mutate({
      mutation: mutation,
      variables: Object.assign({}, variables, { try: retry }),
      context: {
        timeout: timeWaiting
      }
    });
    return data;
  };
  return await this.$retryCall(5, getData);
};

Vue.prototype.$getAllPages = async function(
  query,
  variables,
  path,
  pageInfoTarget = null,
  timeWaiting = 20000,
  fetchPolicy = "cache-first"
) {
  let cursor = "";
  const all = [];
  if (pageInfoTarget) {
    Vue.set(pageInfoTarget, "currentPage", 0);
    Vue.set(pageInfoTarget, "size", 0);
  }
  let currentPage = 0;
  let size = 0;
  while (cursor !== null) {
    let newVariables = Object.assign({}, variables, { cursor });
    let { data } = await this.$retryQueryWithTimeout(
      query,
      newVariables,
      timeWaiting,
      fetchPolicy
    );
    const page = this.$dig(data, path, "edges");
    if (page) {
      all.push(...page);
    }
    cursor = this.$dig(data, path, "pageInfo", "endCursor");
    currentPage++;
    size += page.length;
    if (pageInfoTarget) {
      if (pageInfoTarget.stop) {
        Vue.set(pageInfoTarget, "stop", false);
        break;
      }
      Vue.set(pageInfoTarget, "currentPage", currentPage);
      Vue.set(pageInfoTarget, "size", size);
      Vue.set(pageInfoTarget, "lastRegistry", page[page.length - 1]);
    }
  }
  return all;
};

/**
 * Recibe un arreglo de datos y una función que ejecutar sobre estas, genera
 * una promesa con la función a ejecutar para cada dato y limita cuantas de
 * estas promesas se ejecutan en paralelo.
 * Se ejecutan <jobsLimit> trabajos al mismo tiempo, a medida que se van
 * resolviendo las promesas se comienza la ejecución de otra. Retorna el
 * arreglo con los resultados de las promesas ejecutadas.
 * @param {Array} data
 * @param {Integer} jobsLimit
 * @param {() => {Object}} dataFunction
 * @return {Array}
 */
Vue.prototype.$limitedDataExecution = async function(
  data,
  jobsLimit,
  dataFunction
) {
  const allPromises = [];
  const executing = [];
  for (const item of data) {
    const promiseDataFunction = Promise.resolve().then(() =>
      dataFunction(item)
    );
    allPromises.push(promiseDataFunction);
    if (jobsLimit <= data.length) {
      const execute = promiseDataFunction.then(() =>
        executing.splice(executing.indexOf(execute), 1)
      );
      executing.push(execute);
      if (executing.length >= jobsLimit) {
        await Promise.race(executing);
      }
    }
  }
  return Promise.all(allPromises);
};

/**
 * Indica si el usuario tiene permiso para leer ciertas secciones
 * @param {Object} currentUser
 * @param {String} type
 * @returns {Boolean}
 */
Vue.prototype.$permissionToRead = function(currentUser, type) {
  if (!currentUser) {
    return false;
  }
  let userGroup = this.$userGroup(currentUser);

  return !userGroup || userGroup[type] > 0 || currentUser.role === 0;
};

/**
 * Indica si el usuario tiene permiso para crear
 * @param {Object} currentUser
 * @param {String} type
 * @returns {Boolean}
 */
Vue.prototype.$permissionToCreate = function(currentUser, type) {
  return this.$permissionToRead(currentUser, type);
};

/**
 * Indica si el usuario tiene permiso para borrar elementos
 * @param {Object} currentUser
 * @param {String} type
 * @returns {Boolean}
 */
Vue.prototype.$permissionToDelete = function(currentUser, type) {
  if (!currentUser) {
    return false;
  }
  let userGroup = this.$userGroup(currentUser);

  return !userGroup || userGroup[type] > 7 || currentUser.role === 0;
};

/**
 * Indica si el usuario tiene permiso para actualizar elementos
 * @param {Object} currentUser
 * @param {String} type
 * @returns {Boolean}
 */
Vue.prototype.$permissionToUpdate = function(currentUser, type) {
  if (!currentUser) {
    return false;
  }
  let userGroup = this.$userGroup(currentUser);

  return !userGroup || userGroup[type] > 3 || currentUser.role === 0;
};

/**
 * Se encarga de re-direccionar al perfil cuando no se tenga permiso
 * @param {Object} currentUser
 * @param {String} type
 * @param {String} action
 */
Vue.prototype.$redirectToProfile = function(currentUser, type, action) {
  let redirect = false;
  switch (action) {
    case "read":
      if (!this.$permissionToRead(currentUser, type)) {
        redirect = true;
      }
      break;
    case "create":
      if (!this.$permissionToCreate(currentUser, type)) {
        redirect = true;
      }
      break;
    case "update":
      if (!this.$permissionToUpdate(currentUser, type)) {
        redirect = true;
      }
      break;
  }
  if (redirect) {
    this.$router.push("/profile/" + currentUser.id + "/edit?warning");
  }
};

Vue.filter("toCurrency", function(value, decimals = 0) {
  if (typeof value !== "number") {
    return value;
  }
  var formatter = new Intl.NumberFormat("es-CL", {
    style: "currency",
    currency: "CLP",
    minimumFractionDigits: decimals
  });
  return formatter.format(value);
});

Vue.filter("formatDateTime", function(value) {
  if (!value) {
    return "";
  }
  const mmnt = moment(new Date(value));
  return mmnt.isValid() ? mmnt.format("DD/MM/YYYY HH:mm") : value;
});
Vue.filter("formatDate", function(value) {
  if (!value) {
    return "";
  }
  const mmnt = moment(new Date(value));
  return mmnt.isValid() ? mmnt.format("DD/MM/YYYY") : value;
});

Vue.filter("formatTime", function(value) {
  if (!value) {
    return "";
  }
  const mmnt = moment(String(value));
  return mmnt.isValid() ? mmnt.format("HH:mm") : value;
});

Vue.filter("translateState", function(value) {
  let state;
  if (!value) {
    state = "";
  } else if (value == "pending") {
    state = "Pendiente";
  } else if (value == "shipped") {
    state = "Enviado";
  } else if (value == "received") {
    state = "Entregado";
  } else if (value == "unknown") {
    state = "Desconocido";
  } else {
    state = "Anulado";
  }
  return state;
});

Vue.filter("meliToCentry", function(value) {
  if (value == "started") {
    return "Ya empezó";
  } else if (value == "ACTIVE") {
    return "Activo";
  } else if (value == "rejected") {
    return "Inactivo/Rechazado";
  } else if (value == "approved") {
    return "Aprobado";
  } else if (value == "pending") {
    return "Pendiente";
  } else if (value == "candidate") {
    return "Candidato";
  }
  return value;
});

Vue.filter("pluralize", (amount, singular, plural = `${singular}s`) =>
  amount === 1 ? singular : plural
);
Vue.filter("meliDealToCentry", function(value) {
  if (value == "DEAL") {
    return "Campaña Tradicional";
  } else if (value == "DOD") {
    return "Oferta del día";
  } else if (value == "LIGHTNING") {
    return "Relámpago";
  } else if (value == "MARKETPLACE_CAMPAIGN") {
    return "Campaña Co-fondeada";
  } else if (value == "PRE_NEGOTIATED") {
    return "Pre-Acordado";
  } else if (value == "SMART") {
    return "Campaña SMART";
  }
  return value;
});

Vue.filter("meliDealStatusToCentry", function(value) {
  if (value == "started") {
    return "Ya empezó";
  } else if (value == "finished") {
    return "Finalizada";
  }
  return value;
});

Vue.filter("pluralize", (amount, singular, plural = `${singular}s`) =>
  amount === 1 ? singular : plural
);

Vue.filter("translateSeverity", function(value) {
  let state;
  if (!value) {
    state = "";
  } else if (value == "fix_to_republish") {
    state = "Inactivo. Corregir para publicar";
  } else if (value == "warning") {
    state = "Tu publicación está perdiendo exposición";
  } else if (value == "forbidden") {
    state = "Inactivo. La publicación no se puede recuperar";
  }
  return state;
});

Vue.filter("valueType", function(value) {
  if (value == "text") {
    return "Texto";
  } else if (value == "number") {
    return "Número";
  } else if (value == "select_one") {
    return "Selector simple";
  } else if (value == "boolean") {
    return "Booleano";
  } else if (value == "select_many") {
    return "Selector múltiple";
  }
  return value;
});

Vue.filter("IntegrationTranslate", function(value) {
  if (value == "mercado_libre") {
    return "Mercado Libre";
  } else if (value == "ripley") {
    return "Ripley";
  } else if (value == "dafiti") {
    return "Dafiti";
  } else if (value == "Linio") {
    return "Linio";
  }
  return value;
});

Vue.filter("VtexDataTypeTranslate", function(value) {
  const vtexDataType = [
    null,
    "text",
    "text",
    null,
    "numeric",
    "autocomplete",
    "autocomplete",
    "checkbox",
    "text",
    "text"
  ];
  return vtexDataType[parseInt(value)];
});

Vue.filter("ColumnTypeTranslate", function(value) {
  if (value == "text") {
    return "Texto";
  } else if (value == "numeric") {
    return "Número";
  } else if (value == "autocomplete") {
    return "Selección";
  } else if (value == "checkbox") {
    return "Checkbox";
  } else {
    return "Desconocido";
  }
});

Vue.filter("CentryClassTranslate", function(value) {
  if (value == "product_color") {
    return "Color";
  } else if (value == "product_gender") {
    return "Género";
  } else if (value == "product_brand") {
    return "Marca";
  } else if (value == "product_size") {
    return "Talla";
  } else if (value == "product_warranty") {
    return "Garantía";
  } else if (value == "product_category") {
    return "Categoría";
  } else if (value == "category_attribute") {
    return "Atributo de categoría";
  } else {
    return "Desconocido";
  }
});

Vue.filter("toPercentage", function(number1, number2 = 1, precision = 2) {
  if (typeof number1 !== "number") {
    number1 = Number(number1);
  }
  if (typeof number2 !== "number") {
    number2 = Number(number2);
  }
  if (typeof precision !== "number") {
    precision = Number(precision);
  }
  return ((number1 / number2) * 100).toFixed(precision) + "%";
});

Vue.prototype.$downloadBASE64 = async function(files) {
  if (files.length === 1) {
    const file = files[0];
    const byteString = window.atob(file.content_base_64);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: file.content_type });
    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
  } else {
    const zip = new JSZip();
    const usedFilenames = {};
    files.forEach(file => {
      let filename = file.filename;
      let i = 2;
      while (usedFilenames[filename]) {
        filename = i + filename;
        i++;
      }
      usedFilenames[filename] = true;
      zip.file(filename, file.content_base_64, { base64: true });
    });
    await zip.generateAsync({ type: "blob" }).then(blob => {
      const url = URL.createObjectURL(blob);
      window.open(url, "_blank");
    });
  }
};
/**
 * Revisa si algún archivo es de cierto tipo o no
 * @param {Object} files - archivos a revisar
 * @param {String} filesType - tipos de archivo aceptados
 * @returns  {Boolean}
 */
Vue.prototype.$checkFileType = function(
  files,
  filesType = "image/jpg|image/jpeg|image/png|image/gif"
) {
  let matchType = true;
  Object.keys(files).forEach(i => {
    const file = files[i];
    if (!file.type.match(filesType)) {
      matchType = false;
    }
  });
  return matchType;
};

/**
 * Hace una comparacion simple de objetos, esto quiere decir
 * que son objetos que dentro de sus valores no
 * tienen otros objetos o arrays.
 * @param {Object} obj1 - un objeto a comparar
 * @param {Object} obj2 - otro objeto a comparar
 * @returns {Boolean}
 */
Vue.prototype.$objSimpleCompare = function(obj1, obj2) {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (let k of keys1) {
    if (obj1[k] !== obj2[k]) {
      return false;
    }
  }
  return true;
};

/**
 * Hace una comparacion profunda de objetos
 * @param {Object} obj1 - un objeto a comparar
 * @param {Object} obj2 - otro objeto a comparar
 * @returns {Boolean}
 */
Vue.prototype.$objDeepCompare = function(obj1, obj2) {
  if (obj1 && obj2 && typeof obj1 === "object" && typeof obj2 === "object") {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    if (keys1.length !== keys2.length) {
      return false;
    }
    for (let k of keys1) {
      if (Array.isArray(obj1[k])) {
        if (!this.$arrayDeepCompare(obj1[k], obj2[k])) {
          return false;
        }
      } else {
        if (!this.$objDeepCompare(obj1[k], obj2[k])) {
          return false;
        }
      }
    }
    return true;
  } else {
    return obj1 === obj2;
  }
};

/**
 * Hace una comparacion profunda de arrays
 * @param {Array} array1 - un array a comparar
 * @param {Array} array1 - otro array a comparar
 * @returns {Boolean}
 */
Vue.prototype.$arrayDeepCompare = function(arr1, arr2) {
  if (Array.isArray(arr1) && Array.isArray(arr2)) {
    if (arr1.length !== arr2.length) {
      return false;
    }
    let change = true;
    arr1.forEach((val, i) => {
      if (Array.isArray(val)) {
        change &&= this.$arrayDeepCompare(val, arr2[i]);
      } else {
        change &&= this.$objDeepCompare(val, arr2[i]);
      }
    });
    return change;
  } else {
    return arr1 === arr2;
  }
};

/**
 * Entrega todos los elementos
 * de obj2 que sean diferentes a obj1
 * en un objeto
 * @param {Object} obj1
 * @param {Object} obj2
 * @returns {Object}
 */
Vue.prototype.$objectDiff = function(obj1, obj2) {
  const diff = {};
  Object.keys(obj2).forEach(key => {
    const elem1 = obj1[key];
    const elem2 = obj2[key];
    if (!this.$objDeepCompare(elem1, elem2)) {
      diff[key] = elem2;
    }
  });
  return diff;
};

/**
 * Entrega un booleano o un null si el precio tiene
 * un formato válido
 * @param {Int} price
 * @returns {Boolean}
 */
Vue.prototype.$validFormPrice = function(price) {
  let transformPrice = Number(price);
  if (price == undefined || price == 0) {
    return null;
  } else if (!isNaN(transformPrice)) {
    return true;
  }
  return false;
};

/**
 * Formatea el precio para los form de edición del
 * producto no permitiendo strings y truncando los decimales a 2
 * @param {Int} price
 * @returns {String}
 */
Vue.prototype.$formatFormPrice = function(price) {
  let index = price.match(/\./)?.index;
  if (index) {
    return price.substring(0, index + 3);
  }
  return price;
};

Vue.prototype.$companyOwner = function(currentUser) {
  return currentUser.role <= 1;
};

new Vue({
  router,
  store,
  apolloProvider: createProvider(),
  render: h => h(App)
}).$mount("#app");
