<template>
  <b-list-group-item>
    <b-spinner label="Spinning" v-if="value === false"> </b-spinner>
    <b-icon-check v-else-if="value === true"> </b-icon-check>
    Actualización de exclusiones
  </b-list-group-item>
</template>
<script>
import UPDATE_INTEGRATION_CONFIG_EXCLUSIONS from "../graphql/UpdateIntegrationConfigExclusions.gql";
export default {
  name: "BulkUploadTabsLoadIntegrationConfigs",
  props: {
    _value: Boolean,
    integrationConfigsToUpdate: Array,
    bulkUploadId: String
  },
  model: {
    prop: "_value",
    event: "change"
  },
  data() {
    return {
      value: this._value,
      maxConcurrentRequests: 2
    };
  },
  mounted() {
    this.sendIntegrationConfigsToUpdate().then(resp => this.emitChange(resp));
  },
  methods: {
    /**
     * Emite el cambio de estado
     * @param {Boolean} value
     */
    emitChange(value) {
      this.$emit("change", value);
    },
    /**
     * Envía a actualizar los integratonConfigs
     */
    async sendIntegrationConfigsToUpdate() {
      if (this.integrationConfigsToUpdate.length === 0) return true;
      if (this.bulkUploadId === null || this.bulkUploadId === "") return false;
      let processing = [];
      for await (const integrationConfigData of this
        .integrationConfigsToUpdate) {
        const request = this.sendIntegrationConfigToUpdate(
          integrationConfigData
        );
        processing.push(request);
        processing = await this.$concurrentVacancy(
          processing,
          this.maxConcurrentRequests
        );
      }
      await Promise.all(processing);
      return true;
    },
    /**
     * Se actualizan las exclusiones de un integrationConfig
     * @param {Object} integrationConfigData
     * @return {Promise}
     */
    async sendIntegrationConfigToUpdate(integrationConfigData) {
      return this.$retryMutationWithTimeout(
        UPDATE_INTEGRATION_CONFIG_EXCLUSIONS,
        {
          patch: integrationConfigData,
          v3BulkUploadId: this.bulkUploadId
        },
        15000
      ).catch(async e => {
        const error = {
          message: "IntegrationConfigs | " + e.message,
          level: "error",
          action: "bulk_upload",
          sku: integrationConfigData.id
        };
        this.$emit("error", error);
        this.emitChange(true);
      });
    }
  },
  watch: {
    bulkUploadId() {
      this.sendIntegrationConfigsToUpdate().then(resp => this.emitChange(resp));
    }
  }
};
</script>
