<template>
  <div class="d-inline-block">
    <b-button
      :disabled="disabled || loading"
      variant="outline-info"
      :title="title"
      @click="generateReport"
    >
      <b-icon-file-earmark-arrow-down></b-icon-file-earmark-arrow-down>
      Descargar Manifiesto
    </b-button>
    <br /><br />
    <span class="text-info text-center" v-if="loading">
      {{ processed }} de {{ total }} Pedidos Procesados
    </span>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="letter"
      pdf-content-width="719px"
      ref="html2Pdf"
      :htmlToPdfOptions="{
        filename: 'manifest'
      }"
      @beforeDownload="beforeDownload($event)"
    >
      <section slot="pdf-content" class="pdf">
        <h1 style="margin: 20px">Manifiesto</h1>
        <div
          v-for="(orders, origin) in groupedOrders"
          :key="origin"
          style="margin: 20px"
        >
          <section class="pdf-item">
            <b-table-simple :small="true">
              <b-thead head-variant="dark">
                <b-tr>
                  <b-th>Origen:</b-th>
                  <b-th>{{ origin }}</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-th>Total de órdenes:</b-th>
                  <b-th>{{ orders.length }}</b-th>
                </b-tr>
                <b-tr>
                  <b-th>Total de bultos:</b-th>
                  <b-th>{{ orders.length }}</b-th>
                </b-tr>
                <b-tr>
                  <b-th>Fecha de descarga:</b-th>
                  <b-th>{{ new Date() | formatDateTime }}</b-th>
                </b-tr>
              </b-tbody>
            </b-table-simple>

            <b-table-simple :small="true" class="orders">
              <b-thead>
                <b-tr>
                  <b-th>N°</b-th>
                  <b-th>Número de pedido</b-th>
                  <b-th>Fecha venta</b-th>
                  <b-th>SKU</b-th>
                  <b-th>Nombre Producto</b-th>
                  <b-th>Cantidad</b-th>
                  <b-th>Bultos</b-th>
                  <b-th>Código seguimiento</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <template v-for="(order, orderIndex) in orders">
                  <b-tr v-for="(item, itemIndex) in order.items" :key="item.id">
                    <b-td v-if="itemIndex === 0" :rowspan="order.items.length">
                      {{ orderIndex + 1 }}
                    </b-td>
                    <b-td v-if="itemIndex === 0" :rowspan="order.items.length">
                      {{ order.numberOrigin || order.idOrigin }}
                    </b-td>
                    <b-td v-if="itemIndex === 0" :rowspan="order.items.length">
                      {{ order.createdAtOrigin | formatDateTime }}
                    </b-td>
                    <b-td>{{ item.sku }}</b-td>
                    <b-td>{{ item.name }}</b-td>
                    <b-td>
                      {{ (item.quantity || 0) - (item.quantityRestocked || 0) }}
                    </b-td>
                    <b-td>1</b-td>
                    <b-td v-if="itemIndex === 0" :rowspan="order.items.length">
                      <vue-barcode
                        :value="order.items[0].trackingCode"
                        v-if="order.items[0].trackingCode"
                        width="1"
                        height="50"
                        fontSize="15"
                      >
                        Show this if the rendering fails.
                      </vue-barcode>
                    </b-td>
                  </b-tr>
                </template>
              </b-tbody>
            </b-table-simple>
            <table class="currier-table">
              <tr>
                <th colspan="2">Retirado por:</th>
              </tr>
              <tr>
                <td>Transportista:</td>
                <td></td>
              </tr>
              <tr>
                <td>Rut:</td>
                <td></td>
              </tr>
              <tr>
                <td>Fecha de despacho:</td>
                <td></td>
              </tr>
              <tr>
                <td>Firma:</td>
                <td></td>
              </tr>
            </table>
          </section>
          <div v-if="origin !== lastOrder" class="html2pdf__page-break" />
        </div>
      </section>
    </vue-html2pdf>
  </div>
</template>
<script>
import VueHtml2pdf from "vue-html2pdf";
import VueBarcode from "vue-barcode";
import gql from "graphql-tag";
import { mapState } from "vuex";

const ALL_ORDERS = gql`
  query allOrders(
    $cursor: String
    $numbers: [String!]
    $ids: [ID!]
    $skus: [String!]
    $statuses: [String!]
    $origins: [String!]
    $createFrom: ISO8601DateTime
    $createTo: ISO8601DateTime
    $updateFrom: ISO8601DateTime
    $updateTo: ISO8601DateTime
    $shippingLabelPrint: Boolean
    $buyers: [String!]
  ) {
    allOrders(
      first: 60
      after: $cursor
      numbers: $numbers
      ids: $ids
      skus: $skus
      statuses: $statuses
      origins: $origins
      createFrom: $createFrom
      createTo: $createTo
      updateFrom: $updateFrom
      updateTo: $updateTo
      shippingLabelPrint: $shippingLabelPrint
      buyers: $buyers
    ) {
      edges {
        node {
          id
          origin
          numberOrigin
          idOrigin
          buyerFirstName
          buyerLastName
          buyerDni
          buyerEmail
          buyerPhone
          buyerMobilephone
          createdAt
          updatedAt
          createdAtOrigin
          updatedAtOrigin
          status
          statusOrigin
          shippingLastPrintDate
          totalAmount
          shippingAmount
          discountAmount
          shippingDiscount
          paidAmount
          itemsCount
          orderNotesCount
          documentsCount
          hasShippingLabel
          billable
          modifyStock
          items {
            sku
            name
            unitPrice
            paidPrice
            discountAmount
            quantity
            quantityRestocked
            trackingCode
            variant {
              sku
              productId
            }
          }
          orderNotes {
            id
            createdAt
            text
            user {
              firstName
              lastName
            }
          }
        }
      }
      pageInfo {
        endCursor
      }
    }
  }
`;

export default {
  name: "OrdersManifestButton",
  components: {
    VueHtml2pdf,
    VueBarcode
  },
  props: {
    disabled: Boolean,
    title: String
  },
  data() {
    return {
      loading: false,
      processed: 0,
      total: 0,
      groupedOrders: {},
      lastOrder: ""
    };
  },
  computed: {
    ...mapState(["selectedOrders"])
  },
  methods: {
    async generateReport() {
      this.loading = true;
      this.processed = 0;
      const ids = this.getIds();
      this.total = ids.length;
      this.groupedOrders = {};
      this.lastOrder = "";
      let withoutTracking = {};
      let ordersFull = {};
      let swal_text = "";
      let errors = false;
      let keys = [];
      // FIXME: Lo correcto sería pasarle todos los identificadores una vez y
      // avanzar en la paginación de qraphql con
      // `data.allOrders.pageInfo.endCursor`
      const splited = this.$splitArray(ids, 60);
      for (let i = 0; i < splited.length; i++) {
        const subIds = splited[i];
        if (!errors) {
          await this.$apollo
            .query({
              query: ALL_ORDERS,
              variables: { ids: subIds }
              // variables: { ids: ids }
            })
            .then(({ data }) => {
              if (data.allOrders.edges) {
                data.allOrders.edges.forEach(o => {
                  if (!o.node.billable && !o.node.modifyStock) {
                    if (!ordersFull[o.node.origin]) {
                      ordersFull[o.node.origin] = [];
                    }
                    ordersFull[o.node.origin].push(o.node);
                  } else if (o.node.items[0]?.trackingCode) {
                    if (!this.groupedOrders[o.node.origin]) {
                      this.groupedOrders[o.node.origin] = [];
                    }
                    this.groupedOrders[o.node.origin].push(o.node);
                  } else {
                    if (!withoutTracking[o.node.origin]) {
                      withoutTracking[o.node.origin] = [];
                    }
                    withoutTracking[o.node.origin].push(o.node);
                  }
                });
                keys = Object.keys(this.groupedOrders);
                this.total = keys.length;
              }
            });
        }
      }
      if (Object.keys(withoutTracking).length !== 0) {
        swal_text += `<p>Las siguientes órdenes no tienen manifiestos disponibles debido a que no tienen código de seguimiento:</p>
                        <div style="max-height: 10em; overflow-y: auto; width: 100%;">
                          ${Object.entries(withoutTracking)
                            .map(key => {
                              return `<p>${key[0]}</p><ul>
                            ${key[1]
                              .map(
                                order =>
                                  `<li style="text-align: start">${
                                    order.numberOrigin
                                      ? order.numberOrigin
                                      : order.idOrigin
                                  }</li>`
                              )
                              .join("")}</ul>`;
                            })
                            .join("<br>")}
                            </div><br>`;
      }
      if (Object.keys(ordersFull).length !== 0) {
        swal_text += `<p>Las siguientes órdenes no tienen manifiestos disponibles debido a que son órdenes full:</p>
                        <div style="max-height: 10em; overflow-y: auto; width: 100%;">
                          ${Object.entries(ordersFull)
                            .map(key => {
                              return `<p>${key[0]}</p><ul>
                            ${key[1]
                              .map(
                                order =>
                                  `<li style="text-align: start">${
                                    order.numberOrigin
                                      ? order.numberOrigin
                                      : order.idOrigin
                                  }</li>`
                              )
                              .join("")}</ul>`;
                            })
                            .join("<br>")}
                            </div>`;
      }
      if (keys.length !== 0) {
        this.lastOrder = keys[keys.length - 1];
      }
      if (swal_text !== "") {
        this.$swal
          .fire({
            title: "Algunos manifiestos no están disponibles",
            html: swal_text,
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: "Aceptar"
          })
          .then(result => {
            if (!errors && result.isConfirmed && this.total !== 0) {
              this.$refs.html2Pdf.generatePdf();
            }
          });
      } else {
        if (!errors && this.total !== 0) {
          this.$refs.html2Pdf.generatePdf();
        }
      }
      this.loading = false;
    },
    async beforeDownload({ html2pdf, options, pdfContent }) {
      await html2pdf()
        .set(options)
        .from(pdfContent)
        .toPdf()
        .get("pdf")
        .then(pdf => {
          const totalPages = pdf.internal.getNumberOfPages();
          for (let i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(10);
            pdf.setTextColor("#000000");
            pdf.text(
              `Página ${i} de ${totalPages}`,
              pdf.internal.pageSize.getWidth() - 40,
              pdf.internal.pageSize.getHeight() - 5
            );
          }
        })
        .save();
    },
    getIds() {
      return Object.keys(this.selectedOrders).filter(
        k => this.selectedOrders[k].selected
      );
    }
  }
};
</script>

<style scoped>
.pdf {
  font-size: 1px;
}
.pdf h1 {
  font-size: 20px;
}
table.orders th {
  font-weight: bold;
  color: black;
}
table.orders td {
  font-weight: normal;
  color: black;
  padding: 2px !important;
  height: initial;
}
.currier-table {
  margin-top: 60px;
}
.currier-table td {
  vertical-align: bottom;
}
.currier-table tr td:first-child {
  padding-right: 10px;
}
.currier-table tr td:nth-child(2) {
  padding: 20px 200px;
  border-bottom: 1px solid black;
}
</style>
