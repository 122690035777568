<template>
  <div>
    <base-alert-with-count-down
      v-model="showAlert"
      :alert-variant="alertVariant"
      :dismiss-secs="error ? 10 : 5"
    >
      {{ alertMessage }}</base-alert-with-count-down
    >
    <ApolloQuery
      :query="require('../../graphql/Users/AllUsers.gql')"
      :variables="{
        cursor: ''
      }"
      :fetchPolicy="'cache-and-network'"
      clientId="apolloClientCached"
      notifyOnNetworkStatusChange
    >
      <template v-slot="{ result: { error, data }, query, isLoading }">
        <span v-if="data && isLoading" class="m-2 float-right">
          Actualizando lista de usuarios...
        </span>
        <b-table-simple v-if="data && data.allUsers" hover="" responsive>
          <b-thead>
            <b-tr>
              <b-th>Nombre</b-th>
              <b-th>Estado</b-th>
              <b-th>Correo electrónico</b-th>
              <b-th>Grupo</b-th>
              <b-th></b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-if="!data.allUsers.edges.length">
              <b-td colspan="7">No tienes usuarios registrados</b-td>
            </b-tr>
            <users-detail
              v-for="user of data.allUsers.edges"
              :key="user.node.id"
              :user="user.node"
              @delete-user="error => deleteUser(query, error)"
              @error-updating="errorUpdating"
            />
          </b-tbody>
        </b-table-simple>
        <b-alert v-if="error" show="" variant="danger">
          {{ error.message ? error.message : "Ha ocurrido un error" }}
        </b-alert>
        <b-spinner
          v-if="isLoading"
          label="Spinning"
          class="m-2 float-right"
        ></b-spinner>
        <div v-else>
          <b-button
            v-if="hasNextPage(data)"
            class="m-2 float-right"
            @click="seeMore(query, data.allUsers.pageInfo.endCursor)"
          >
            Ver más
          </b-button>
          <b-alert v-else show variant="light" class="text-center">
            No hay más datos para mostrar.
          </b-alert>
        </div>
      </template>
    </ApolloQuery>
  </div>
</template>

<script>
import UsersDetail from "./UsersDetail.vue";
import BaseAlertWithCountDown from "../Base/BaseAlertWithCountDown.vue";
export default {
  name: "UsersList",
  components: {
    UsersDetail,
    BaseAlertWithCountDown
  },
  data() {
    return {
      showAlert: false,
      error: false,
      errorMessage: null
    };
  },
  computed: {
    alertVariant() {
      return this.error ? "danger" : "success";
    },
    alertMessage() {
      return this.error
        ? "Ha ocurrido un error guardando los datos: " + this.errorMessage
        : "Usuario eliminado exitosamente";
    }
  },
  methods: {
    /**
     * Indica si hay mas elementos para cargar o no
     * @return {Boolean}
     */
    hasNextPage(data) {
      if (!data?.allUsers?.edges?.length < 100) {
        return false;
      }
      return data?.allUsers?.pageInfo?.endCursor != null;
    },
    /**
     * Trae más resultados de una query avanzando en su cursor.
     * @param {Object} query
     * @param {String} cursor
     */
    async seeMore(query, cursor) {
      await query.fetchMore({
        variables: {
          cursor: cursor
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          const updated = Object.assign({}, this.$dup(prev));
          updated.allUsers.pageInfo = fetchMoreResult.allUsers.pageInfo;
          if (fetchMoreResult?.allUsers?.edges?.length !== 0) {
            updated.allUsers.edges.push(...fetchMoreResult.allUsers.edges);
          }
          return updated;
        }
      });
    },
    /**
     * Recibe el evento de eliminacion de un usuario
     * solo si no hay algun error, si lo hay lo muestra
     * @param {Object} query - query de allUsers
     * @param {String} error - mensaje de error
     */
    deleteUser(query, error = null) {
      this.softReset();
      if (error) {
        this.error = true;
        this.errorMessage = error;
      } else {
        query.refetch();
      }
      this.showAlert = true;
    },
    /**
     * Se encarga de mostrar el mensaje de error si
     * falla la actualizacion de estado del usuario
     * @param {Object} errorMessage - mensaje de error
     */
    errorUpdating(errorMessage) {
      this.error = true;
      this.errorMessage = errorMessage;
      this.showAlert = true;
    },
    /**
     * Se encarga de resetear las variables que indican el error o exito
     */
    softReset() {
      this.error = false;
      this.errorMessage = null;
    }
  }
};
</script>

<style></style>
