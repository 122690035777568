<template>
  <div>
    <span>
      <b-spinner label="Spinning" v-if="reviewRow < 1"></b-spinner>
      Revisión: {{ Math.round(reviewRow * 100) }} %
    </span>
    <b-row v-if="!!errorRowsPage.length">
      <b-col md="9">
        <h4>Corrige errores</h4>
        <br />
        <div v-if="!!critical.length">
          <bulk-upload-tabs-preparation-critical-error
            :data="data.data"
            :errors="errors"
            :message="critical"
            :idProductColumn="idProductColumn"
            :skuProductColumn="skuProductColumn"
            :idVariantColumn="idVariantColumn"
            :skuVariantColumn="skuVariantColumn"
          />
        </div>
        <p v-else>
          Se han detectado los siguientes errores en tu carga. Corrígelos en
          vivo y carga tus datos.
        </p>
      </b-col>
      <b-col md="3">
        <b-button
          @click="emitValidate"
          :disabled="hasErrors || reviewRow < 1"
          :title="tooltipCorrectButton"
          >Corregir {{ errorRowsNextPages.length ? "página" : "" }}</b-button
        >
      </b-col>
    </b-row>
    <b-row v-if="errorRowsPage.length && reviewRow === 1 && !critical.length">
      <b-col md="3">
        <b-form-group label="Mostrar columnas">
          <b-form-checkbox-group
            v-model="visibleColumns"
            :options="allColumns"
            name="columns"
            stacked
          ></b-form-checkbox-group>
        </b-form-group>
      </b-col>
      <b-col md="9" style="max-height: 900px">
        <span
          >Filas corregidas de la página: {{ correctedErrorsCounter }} de
          {{ errorRowsPage.length }}</span
        ><br />
        <span
          >Páginas corregidas: {{ errorsPagesCounter }} de
          {{ totalPagesCounter }}</span
        >
        <br /><br />
        <base-j-excel-table
          v-model="errorRowsPage"
          :columns="errorColumns"
          :rows="currentErrorRows"
          :validation-function="errorFunction"
          :allow-insert-column="false"
          :allow-insert-row="false"
          :validate-row-on-cell-validation="true"
          :show-columns="columnsVisibility"
        ></base-j-excel-table>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { mapState } from "vuex";
import BaseJExcelTable from "@/components/BaseJExcelTable";
import BulkUploadTabsPreparationCriticalError from "./BulkUploadTabsPreparationCriticalError";
export default {
  name: "BulkUploadTabsPreparation",
  components: { BaseJExcelTable, BulkUploadTabsPreparationCriticalError },
  props: {
    data: Object,
    params: Object,
    integrationConfigs: Object,
    requiredOnCreateParams: Array,
    productsIdHash: Object,
    variantsIdHash: Object,
    productsSkuHash: Object,
    variantsSkuHash: Object,
    categoryAttributes: Object,
    idProductColumn: Number,
    skuProductColumn: Number,
    idVariantColumn: Number,
    skuVariantColumn: Number,
    categoryIdColumn: Number,
    blockages: Array
  },
  async mounted() {
    return new Promise(resolve => {
      for (let i = this.data.startRow - 1; i < this.data.data.length; i++) {
        this.assignReferences(this.data.data[i], i);
        if (i % 100 === 0)
          this.reviewRow +=
            100 *
            (1.0 / (2 * (this.data.data.length - this.data.startRow + 1)));
      }
      for (let i = this.data.startRow - 1; i < this.data.data.length; i++) {
        this.validateRow(i);
        if (i % 100 === 0)
          this.reviewRow +=
            100 *
            (1.0 / (2 * (this.data.data.length - this.data.startRow + 1)));
      }
      this.reviewRow = 1;
      this.updateHasErrors();
      this.setDefaultVisibleColumns();
      if (!this.errorRowsPage.length) {
        this.emitValidate();
      }
      resolve();
    });
  },
  data() {
    return {
      INCORRECT: "____INCORRECT___",
      errorRowsNextPages: [], //datos por corregir de páginas siguientes
      errorRowsPage: [], //datos por corregir en la página actual
      correctedErrors: [], //correcciones de páginas anteriores
      errors: {}, //Hash que contiene los errores de la página
      reviewRow: 0, //última fila revisada
      errorsPerPage: 30, //Errores por página
      correctedErrorsCounter: 0, //cantidad de filas corregidas
      errorsPagesCounter: 0, //cantidad de páginas corregidas
      hasErrors: false, //indica si tiene o no errores por corregir
      rowMap: [], //mapea un índice de la tabla de correccion con un índice de datos reales
      references: { integrationConfigs: this.prevIntegrationConfigs() },
      visibleColumns: [],
      critical: "",
      originalData: [],
      variantsSkus: {}
    };
  },
  computed: {
    ...mapState(["currentUser"]),
    hasAssets() {
      return !!this.data.columnParams.find(columnParam => {
        const param = this.getParam(columnParam);
        return param && param.path.startsWith("assets");
      });
    },
    columnsVisibility() {
      return this.errorColumns.map((_elem, index) => {
        return this.visibleColumns.includes(index);
      });
    },
    allColumns() {
      return this.columnMap.map((realIndex, index) => {
        return { text: this.columnName(realIndex), value: index };
      });
    },
    columnMap() {
      return this.data.columnParams
        .map((x, i) => [x, i])
        .filter(arr => arr[0] && arr[0].length)
        .map(arr => arr[1]);
    },
    currentErrorRows() {
      return this.rowMap.map(realIndex => {
        return { title: realIndex + 1 };
      });
    },
    errorColumns() {
      return this.columnMap.map(i => {
        const column = { type: "text", title: this.columnName(i), width: 120 };
        const options = this.options(i);
        if (options) {
          column.type = "autocomplete";
          column.source = options.map(x => x.name);
        }
        if (this.productKeyColumn === i || this.variantKeyColumn === i)
          column.readOnly = true;
        return column;
      });
    },
    totalPagesCounter() {
      return (
        1 +
        Math.ceil(this.errorRowsNextPages.length / this.errorsPerPage) +
        this.errorsPagesCounter
      );
    },
    tooltipCorrectButton() {
      if (this.reviewRow < this.data.data.length - this.data.startRow + 1) {
        return "Espere a que se procese la planilla completa para continuar";
      } else if (this.hasErrors) {
        return "Debe corregir todos los errores para continuar";
      }
      return "";
    },
    productKeyColumn() {
      if (this.idProductColumn >= 0) return this.idProductColumn;
      if (this.skuProductColumn >= 0) return this.skuProductColumn;
      if (this.idVariantColumn >= 0) return this.idVariantColumn;
      return this.skuVariantColumn;
    },
    variantKeyColumn() {
      if (this.idVariantColumn >= 0) return this.idVariantColumn;
      return this.skuVariantColumn;
    },
    variantWarehousesColumns() {
      const columns = [];
      this.data.columnParams.forEach((columnParam, index) => {
        const param = this.getParam(index);
        if (!param || !columnParam) return;
        const warehouseId = columnParam.split("_")[2];
        if (param.model === "variant_warehouse" && warehouseId.length) {
          columns.push({ warehouseId, index });
        }
      });
      return columns;
    },
    categoryAttributesColumns() {
      const columns = [];
      this.data.columnParams.forEach((columnParam, index) => {
        const param = this.getParam(index);
        if (!param) return;
        const categoryAttribute = this.categoryAttributes[param.path];
        if (param.model === "category_attribute") {
          columns.push({ index, categoryAttribute });
        }
      });
      return columns;
    },
    exclusionColumns() {
      const columns = [];
      this.data.columnParams.forEach((columnParam, index) => {
        const param = this.getParam(index);
        if (!param || !columnParam) return;
        const integrationConfigId = columnParam.split("_")[1];
        if (param.model === "company") {
          columns.push({ index, integrationConfigId });
        }
      });
      return columns;
    },
    /**
     * Obtiene los parámetros correspondientes a imágenes.
     * @returns {Object} { index: param }
     */
    assetParams() {
      const params = {};
      this.data.columnParams.forEach((columnParam, index) => {
        const param = this.getParam(index);
        if (!param || !columnParam) return;
        if (param.model === "product" && param.path.includes("assets")) {
          params[index] = param;
        }
      });
      return params;
    },
    productParams() {
      return this.modelParams(this.params, ["product"]);
    },
    variantParams() {
      return this.modelParams(this.params, ["variant"]);
    }
  },
  methods: {
    setDefaultVisibleColumns() {
      if (!Object.keys(this.errors).length) return;
      const errorColumns = {};
      errorColumns[this.idProductColumn] = true;
      errorColumns[this.skuProductColumn] = true;
      errorColumns[this.idVariantColumn] = true;
      errorColumns[this.skuVariantColumn] = true;
      Object.values(this.errors).forEach(errorRow => {
        errorRow.forEach((error, index) => {
          if (error && error.length) errorColumns[index] = true;
        });
      });
      this.visibleColumns = Object.values(this.errors)[0]
        .map((_val, index) => (errorColumns[index] ? index : -1))
        .filter(i => i >= 0);
    },
    columnName(index) {
      const columnParam = this.data.columnParams[index];
      const param = this.getParam(index);
      const integration = this.integrationConfigs[columnParam.split("_")[1]];
      const warehouse = this.currentUser.company.warehouses.find(
        w => w.id === columnParam.split("_")[2]
      );
      let name = param.name;
      if (integration) {
        name = name + " / " + integration.label;
      }
      if (warehouse) {
        name = name + " / " + warehouse.name;
      }
      return name;
    },
    categoryAttributeoptions(param) {
      if (param.model === "category_attribute") {
        const categoryAttribute = this.categoryAttributes[param.path];
        if (["select_one", "select_many"].includes(categoryAttribute.valueType))
          return categoryAttribute.options.map(x => {
            return { id: x.id, name: x.name };
          });
      }
      return null;
    },
    getNames(options) {
      if (!options) return null;
      return options.map(x => x.name);
    },
    transformationOptions(param) {
      if (param.transformations) {
        const options = [];
        param.transformations.forEach(transformation => {
          if (
            transformation.originValueType === "text" &&
            transformation.destinyValueType === "text"
          ) {
            options.push({
              id: transformation.destinyText,
              name: transformation.originText
            });
          }
        });
        if (options.length) return options;
      }
    },
    options(index) {
      const param = this.getParam(index);
      if (!param) return null;
      const options = this.$dig(param, "optionList", "options");
      const categoryAttributeOptions = this.categoryAttributeoptions(param);
      if (options) {
        return options;
      }
      if (categoryAttributeOptions) {
        return categoryAttributeOptions;
      }
      return this.transformationOptions(param);
    },
    columnType(index) {
      if (this.$dig(this.getParam(index), "optionList")) {
        return "select";
      }
      return "string";
    },
    isEmptyRow(rowIndex) {
      const row = this.data.data[rowIndex];
      for (let columnIndex = 0; columnIndex < row.length; columnIndex++) {
        if (row[columnIndex] && row[columnIndex].toString().length) {
          return false;
        }
      }
      return true;
    },
    validateRow(rowIndex) {
      if (this.isEmptyRow(rowIndex)) return;
      const errors = this.columnMap.map(columnIndex => {
        return this.validateCell(
          rowIndex,
          columnIndex,
          this.references[rowIndex].transformedRow[columnIndex]
        );
      });
      if (errors.find(x => !!x)) {
        this.addErrorsRow(rowIndex, this.data.data[rowIndex], errors);
      }
    },
    /**
     * Revisa si el sku de variante está en más de una fila
     * @param {Object} row - Información de la fila
     * @param {Number} rowIndex - numero de fila
     * @returns {Object} { index: param }
     */
    validateUniqueSkuVariant(row, rowIndex) {
      if (this.skuVariantColumn < 0) return;
      if (this.variantsSkus[row[this.skuVariantColumn]]) {
        this.critical = "Existen skus de variantes repetidas";
        this.addErrorsRow(rowIndex, row, ["Sku de variante repetida"]);
      }
      this.variantsSkus[row[this.skuVariantColumn]] = true;
    },
    assignReferences(row, rowNum) {
      if (this.isEmptyRow(rowNum)) return;
      this.validateUniqueSkuVariant(row, rowNum);
      this.references[rowNum] = {
        oldProduct: this.productRow(row),
        oldVariant: this.variantRow(row),
        transformedRow: this.transformRow(row, rowNum)
      };
      Object.assign(this.references[rowNum], {
        oldCategoryAttributes: this.categoryAttributesRow(
          this.references[rowNum].oldProduct
        ),
        oldVariantWarehouses: this.variantWarehousesRow(
          this.references[rowNum].oldVariant
        ),
        newProductData: this.productData(
          this.references[rowNum].transformedRow,
          this.references[rowNum].oldProduct
        ),
        newVariantData: this.variantData(
          this.references[rowNum].transformedRow,
          this.references[rowNum].oldVariant
        ),
        newVariantWarehousesData: this.variantWarehousesData(
          this.references[rowNum].transformedRow
        ),
        categoryAttributesRow: this.categoryAttributesRow(
          this.references[rowNum].oldProduct
        ),
        newCategoryAttributes: this.categoryAttributesData(
          this.references[rowNum].transformedRow
        ),
        newAssetData: this.assetData(this.references[rowNum].transformedRow)
      });
      this.assignExclusions(
        this.references[rowNum].transformedRow,
        this.references[rowNum].oldProduct
      );
    },
    assignExclussion(integrationConfigId, idProduct, skuProduct, value) {
      const hash = this.references.integrationConfigs[integrationConfigId];
      if (value !== null && value.toString().length) {
        const excluded = !value;
        if (idProduct) {
          hash.newExcludedProductIds[idProduct] = excluded;
        } else {
          hash.newExcludedProductSkus[skuProduct] = excluded;
        }
      }
    },
    assignExclusions(row, product) {
      this.exclusionColumns.forEach(column => {
        const value = row[column.index];
        this.assignExclussion(
          column.integrationConfigId,
          this.$dig(product, "id"),
          row[this.skuProductColumn],
          value
        );
      });
    },
    toCategoryAttributeOption(categoryAttribute, data) {
      if (!data || !data.toString().length) return data;
      if (Array.isArray(data))
        return data.map(x =>
          this.toCategoryAttributeOption(categoryAttribute, x)
        );
      return this.$dig(
        categoryAttribute.options.find(x => data === x.id || data === x.name),
        "id"
      );
    },
    setCategoryAttributeValue(hash, categoryAttribute, value) {
      let valueFilled = null;
      let valueSelectedIds = null;
      if (["select_one", "select_many"].includes(categoryAttribute.valueType)) {
        valueSelectedIds = this.$arrayWrap(
          this.toCategoryAttributeOption(categoryAttribute, value)
        );
      } else {
        valueFilled = value ? value.toString() : "";
      }
      hash[categoryAttribute.id] = {
        valueFilled,
        valueSelectedIds,
        categoryAttributeId: categoryAttribute.id
      };
    },
    /**
     * Se obtiene el id de la categoría del producto
     * @param {Array} row - arreglo de los datos de la fila
     * @return {String}
     */
    getCategoryId(row) {
      if (this.categoryIdColumn != -1) {
        return row[this.categoryIdColumn];
      }
      let product = this.productRow(row);
      return product?.categoryId;
    },
    /**
     * Entrega los valores de los atributos para el producto si
     * estos pertenecen a la categoría del producto
     * @param {Array} row - arreglo de los datos de la fila
     * @return {Array} arreglo de los valores de atributos de categoria
     */
    categoryAttributesData(row) {
      const categoryAttributeValues = {};
      let categoryId = this.getCategoryId(row);
      this.categoryAttributesColumns.forEach(caCol => {
        if (caCol.categoryAttribute.categoryIds.includes(categoryId)) {
          this.setCategoryAttributeValue(
            categoryAttributeValues,
            caCol.categoryAttribute,
            row[caCol.index]
          );
        }
      });
      return categoryAttributeValues;
    },
    categoryAttributesRow(product) {
      if (!product || !product.categoryAttributeValues) return {};
      const categoryAttributeValues = {};
      product.categoryAttributeValues.forEach(cav => {
        delete cav.__typename;
        categoryAttributeValues[cav.categoryAttributeId] = cav;
      });
      return categoryAttributeValues;
    },
    parseDate(data) {
      try {
        if (
          data.match(
            /^\d{2}(-|\/)\d{2}(\/|-)\d{4}|^\d{4}(-|\/)\d{2}(\/|-)\d{2}/i
          )
        ) {
          if (data.match(/^\d{2}\/\d{2}\/\d{4}/i)) {
            const fixedDate =
              data.substring(3, 6) +
              data.substring(0, 3) +
              data.substring(6, 10);
            data = fixedDate;
          }
          return this.$timezoneDate(this.currentUser, data);
        } else if (data.match(/^\d+(\.\d*)?$/)) {
          const dateStr = new Date((data - (25567 + 2)) * 86400 * 1000)
            .toISOString()
            .slice(0, 19)
            .replace("T", " ");
          return this.$timezoneDate(this.currentUser, dateStr);
        } else {
          return this.INCORRECT;
        }
      } catch (e) {
        return this.INCORRECT;
      }
    },
    applyTypeFormat(data, param) {
      data = data.toString();
      switch (param.typeOf) {
        case "integer":
          if (!data.length) return null;
          data = parseInt(data);
          break;
        case "float":
          if (!data.length) return null;
          data = parseFloat(parseFloat(data).toFixed(4));
          break;
        case "date":
          if (!data.length) return null;
          return this.parseDate(data);
        case "time":
          if (!data.length) return null;
          return this.parseDate(data);
        case "datetime":
          if (!data.length) return null;
          return this.parseDate(data);
        case "boolean":
          if (!data.length) return null;
          if (
            data.match(
              /^(0|no|falso|false|inactive|inactivo|pausado|paused|desactivado)$/i
            )
          ) {
            return false;
          } else if (
            data.match(/^(1|si|verdadero|true|active|activo|activado)$/i)
          ) {
            return true;
          } else {
            return this.INCORRECT;
          }
        case "html": {
          if (!data.length) return "";
          if (this.validateHTML(data)) {
            return data;
          } else {
            return (
              "<p>" +
              data
                .replace(/\r/g, "")
                .replace(/(\s*\n\s*)+/g, "</p><p>")
                .replace(/\s\s+/g, " ") +
              "</p>"
            );
          }
        }
        case "bullets": {
          if (!data.length) return "";
          if (this.validateHTML(data)) {
            return data;
          } else {
            return (
              "<ul><li>" +
              data.replace(/\s*\|\s*/g, "|").replace(/\|/g, "</li><li>") +
              "</li></ul>"
            );
          }
        }
        case "array":
          if (!data.length) return [];
          return data.split(/\s*,\s*/g);
        case "image":
          if (!data.length) return "";
          if (data.match(/^http(s)?:\/\/.*\..*$/i)) return data;
          else return this.INCORRECT;
        default:
          return data.toString();
      }
      if (isNaN(data)) return this.INCORRECT;
      return data;
    },
    isTransformable(transformation, data) {
      return (
        (transformation.originValueType === "true_val" && !!data) ||
        (transformation.originValueType === "false_val" && data === false) ||
        (transformation.originValueType === "text" &&
          transformation.originText &&
          typeof data === "string" &&
          transformation.originText.toLowerCase() === data.toLowerCase()) ||
        (transformation.originValueType === "regexp" &&
          new RegExp(transformation.originText).test(JSON.stringify(data))) ||
        (transformation.originValueType === "empty" &&
          (data === null || data.length === 0))
      );
    },
    toCode(code, mainText, captures) {
      mainText = mainText.replace('"', "\u201D");
      let resp = code.replace(/\$t/g, mainText);
      captures.forEach((capture, index) => {
        resp = resp.replace(new RegExp("\\$" + (index + 1), "g"), capture);
      });
      return JSON.parse("[" + resp + "]")[0];
    },
    transform(transformation, data) {
      const coincidences = [];
      switch (transformation.destinyValueType) {
        case "true_val":
          return true;
        case "false_val":
          return false;
        case "text":
          return transformation.destinyText;
        case "null":
          return null;
        case "code":
          if (data === null) {
            return null;
          }
          if (transformation.originValueType === "regexp") {
            const match = data
              .toString()
              .match(new RegExp(transformation.originText));
            if (match) {
              data = match[0];
              coincidences.push(...match.slice(1, match.length));
            }
          }
          return this.toCode(transformation.destinyText, data, coincidences);
      }
      return data;
    },
    applyTransformations(data, param) {
      if (param.transformations) {
        param.transformations.forEach(transformation => {
          if (Array.isArray(data)) {
            return data.map(x => {
              if (this.isTransformable(transformation, x)) {
                return this.transform(transformation, x);
              }
              return x;
            });
          } else if (this.isTransformable(transformation, data)) {
            data = this.transform(transformation, data);
          }
        });
      }
      return data;
    },
    /**
     * Si es necesario, aplica formato segun listado de opciones
     * del parametro de carga masiva, a la celda correspondiente.
     * @param {*} data
     * @param {Integer} colIndex
     * @returns {String}
     */
    applyOptionsFormat(data, colIndex) {
      if (!data || !data.length) return data;
      if (Array.isArray(data)) {
        return data.map(x => this.applyOptionsFormat(x, colIndex));
      }
      const options = this.options(colIndex);
      if (options) {
        let o = options.find(
          option =>
            option.id === data ||
            option.name === data ||
            option.name == data.replace(".", ",") ||
            this.formatToCompareText(option.name) ==
              this.formatToCompareText(data)
        );
        const opcion = this.$dig(o, "id");
        return this.$ifNull(opcion, this.INCORRECT);
      }
      return data;
    },
    /**
     * Formatea texto para ser comparado
     * @param {String} str
     * @returns {String}
     */
    formatToCompareText(str) {
      return str.replace(/\s/g, "").toUpperCase();
    },
    transformElement(data, columnIndex, rowIndex) {
      const param = this.getParam(columnIndex);
      if (!param) return data;
      if (data === null || data === undefined) data = "";
      const original = data;
      data = this.applyTypeFormat(data, param);
      data = this.applyTransformations(data, param);
      data = this.applyOptionsFormat(data, columnIndex);
      if (data === this.INCORRECT) {
        this.originalData.push({
          row: rowIndex,
          col: columnIndex,
          data: original
        });
      }
      return data;
    },
    transformRow(row, rowIndex) {
      return row.map((elem, index) => {
        return this.transformElement(elem, index, rowIndex);
      });
    },
    variantWarehousesRow(variant) {
      if (!variant) return {};
      const variantWarehouses = {};
      this.$ifNull(variant.variantWarehouses, []).forEach(vw => {
        variantWarehouses[vw.warehouseId] = vw;
      });
      return variantWarehouses;
    },
    prevIntegrationConfigs() {
      const integrationConfigHash = {};
      Object.keys(this.integrationConfigs).forEach(icId => {
        integrationConfigHash[icId] = {};
        integrationConfigHash[icId].newExcludedProductIds = {};
        integrationConfigHash[icId].newExcludedProductSkus = {};
      });
      return integrationConfigHash;
    },
    getPathOfColumn(columnParam) {
      if (!columnParam) return null;
      const icId = columnParam.split("_")[1];
      const param = this.params[columnParam.split("_")[0]];
      let path = param.path;
      path = path.replace(/^assets/g, "assetUrls");
      if (icId.length) {
        const ic = this.integrationConfigs[icId];
        if (ic.publicIntegrationInformation) {
          path = "integrations." + ic.fullLabel + "." + path;
        } else {
          path = "integrations." + ic.id + "." + path;
        }
      }
      if (path.endsWith(".")) return path.slice(0, path.length - 1);
      path = path.replace(/\[\]/, "[" + param.positionOnArray + "]");
      return path;
    },
    deepSetWithArray(original, changes, value, splitedPath) {
      splitedPath[0] = this.$toCamelCase(splitedPath[0]);
      splitedPath.forEach((internalPath, index) => {
        const array = internalPath.match(/\[([0-9]+)\]/, "$1");
        if (array) {
          const path = internalPath.replace(array[0], "");
          if (!changes[path] && original) changes[path] = original[path];
          if (!Array.isArray(changes[path])) changes[path] = [];
          if (changes[path].length < parseInt(array[1]) + 1) {
            for (let i = changes[path].length; i <= parseInt(array[1]); i++) {
              changes[path].push({});
            }
          }
          if (index === splitedPath.length - 1) {
            changes[path][array[1]] = value;
          } else {
            changes = changes[path][array[1]];
            if (original) original = original[path];
            if (original) original = original[array[1]];
          }
        } else {
          if (index === splitedPath.length - 1) {
            changes[internalPath] = value;
          } else {
            if (!changes[internalPath]) changes[internalPath] = {};
            changes = changes[internalPath];
            if (original) original = original[internalPath];
          }
        }
        if (!original) original = {};
      });
    },
    pathToSet(path) {
      const splited = path.replace(/\[([0-9]+)\]/, "$1").split(".");
      splited[0] = this.$toCamelCase(splited[0]);
      return splited;
    },
    deleteIfExists(object, path) {
      path.forEach((internalPath, index) => {
        if (index === path.length - 1 && object) {
          delete object[internalPath];
        }
        if (object) object = object[internalPath];
      });
    },
    setParamValue(original, changes, columnParam, value, validParams) {
      if (!columnParam || !validParams[columnParam.split("_")[0]]) return;
      const path = this.getPathOfColumn(columnParam);
      if (path.includes("asset")) return;
      const pathToSet = this.pathToSet(path);
      const originalValue = this.$dig(original, pathToSet);
      if (originalValue === value) {
        this.deleteIfExists(changes, pathToSet);
        return;
      }
      const splitedPath = path.split(".");
      this.deepSetWithArray(original, changes, value, splitedPath);
    },
    productData(row, oldProduct) {
      const data = {};
      if (!oldProduct) oldProduct = {};
      this.data.columnParams.forEach((columnParam, index) => {
        this.setParamValue(
          oldProduct,
          data,
          columnParam,
          row[index],
          this.productParams
        );
      });
      return data;
    },
    variantData(row, oldVariant) {
      const data = {};
      if (!oldVariant) oldVariant = {};
      this.data.columnParams.forEach((columnParam, index) => {
        this.setParamValue(
          oldVariant,
          data,
          columnParam,
          row[index],
          this.variantParams
        );
      });
      return data;
    },
    /**
     * Obtiene los datos de los assets de una fila de la planilla.
     * @param {Array} row
     * @return {Object}
     */
    assetData(row) {
      const params = this.assetParams;
      const columnsIndex = Object.keys(params).map(index => parseInt(index));
      if (!columnsIndex.length) return {};
      let assetsUrl = [];
      let assets = {};
      columnsIndex.forEach(index => {
        let url = row[index];
        if (url !== null && url.toString().length) {
          assetsUrl[params[index].positionOnArray] = url;
          if (assets[url] === undefined) {
            assets[url] = [];
          }
          assets[url].push(row[this.variantKeyColumn]);
        }
      });
      assets.urls = assetsUrl;
      return assets;
    },
    variantWarehousesData(row) {
      const columns = this.variantWarehousesColumns;
      if (!columns.length) return {};
      const variantWarehouses = {};
      columns.forEach(colData => {
        variantWarehouses[colData.warehouseId] = {};
        if (
          row[colData.index] !== null &&
          row[colData.index].toString().length
        ) {
          variantWarehouses[colData.warehouseId].quantity = row[colData.index];
        }
      });
      return variantWarehouses;
    },
    errorFunction(rowIndex, columnIndex, value) {
      const realRowIndex = this.rowMap[rowIndex];
      const realColumnIndex = this.columnMap[columnIndex];
      this.updateReferences(realRowIndex, realColumnIndex, value);
      if (this.errors[realRowIndex] != null) {
        this.errors[realRowIndex][columnIndex] = this.validateCell(
          realRowIndex,
          realColumnIndex,
          this.references[realRowIndex].transformedRow[realColumnIndex]
        );
      }
      this.updateHasErrors();
      return this.$dig(this.errors, realRowIndex, columnIndex);
    },
    updateHasErrors() {
      this.hasErrors = !!this.rowMap.find(realRow => {
        return !!this.errors[realRow].find(col => !!col);
      });
      this.correctedErrorsCounter = Object.keys(this.errors).filter(
        errorRow => {
          const row = this.errors[errorRow];
          return !row.find(x => !!x);
        }
      ).length;
    },
    productRow(row) {
      if (this.idProductColumn >= 0) {
        return this.productsIdHash[row[this.idProductColumn]];
      } else if (this.skuProductColumn >= 0) {
        return this.productsSkuHash[row[this.skuProductColumn]];
      } else {
        const variant = this.variantRow(row);
        if (variant) {
          return this.productsIdHash[variant.productId];
        }
      }
      return null;
    },
    variantRow(row) {
      if (this.idVariantColumn >= 0) {
        return this.variantsIdHash[row[this.idVariantColumn]];
      } else if (this.skuVariantColumn >= 0) {
        return this.variantsSkuHash[row[this.skuVariantColumn]];
      }
      return null;
    },
    toHash(array) {
      const hash = {};
      array.forEach((elem, index) => {
        hash[index.toString()] = elem;
      });
      return hash;
    },
    addErrorsRow(rowIndex, row, errors) {
      this.errors[rowIndex] = errors;
      if (this.errorRowsPage.length < this.errorsPerPage) {
        this.$set(this.rowMap, this.rowMap.length, rowIndex);
        this.errorRowsPage.push(
          this.columnMap.map(index => {
            const param = this.getParam(index);
            if (["time", "date", "datetime"].includes(param.typeOf)) {
              const dataStr = `${row[index]}`;
              if (dataStr.match(/^\d+(\.\d*)?$/)) {
                return new Date((dataStr - (25567 + 2)) * 86400 * 1000)
                  .toISOString()
                  .slice(0, 19)
                  .replace("T", " ");
              }
            }
            if (param.typeOf === "select") {
              const options = this.$dig(param, "optionList", "options");
              if (!!options && options.length > 0) {
                const option = options.find(option => option.id === row[index]);
                if (option) return option.name;
              }
            }
            if (
              row[index] &&
              typeof row[index] === "object" &&
              "value" in row[index]
            ) {
              return row[index]["value"];
            }
            return row[index];
          })
        );
      } else {
        this.errorRowsNextPages.push({ rowNum: rowIndex, errors: errors });
      }
    },
    transformColData(rowIndex, colIndex) {
      return this.references[rowIndex].transformedRow[colIndex];
    },
    checkFormat(rowIndex, colIndex) {
      if (
        this.references[rowIndex].transformedRow[colIndex] === this.INCORRECT
      ) {
        return (
          "Error de formato (" +
          this.originalData.find(
            x => x["row"] == rowIndex && x["col"] == colIndex
          )["data"] +
          ")"
        ); //Añadir valor anterior
      }
      return null;
    },
    checkOptions(value, colIndex) {
      const options = this.options(colIndex);
      if (options && value === this.INCORRECT)
        return "Seleccione un valor válido";
      else if (Array.isArray(value) && value.find(x => x === this.INCORRECT))
        return "Uno o más de los elementos no es una opción válida";
      return null;
    },
    isRequiredColumnForCreate(colIndex) {
      const columnParam = this.data.columnParams[colIndex];
      if (!columnParam || !columnParam.length) return false;
      return !!this.requiredOnCreateParams.find(
        p => p.id === columnParam.split("_")[0]
      );
    },
    errorRowIndex(rowIndex) {
      return this.$ifNull(
        this.errorRowsPage
          .map((rec, i) => [rec[0] - 1, i])
          .find(arr => arr[0] === rowIndex),
        {}
      )[1];
    },
    validateExists(rowIndex, colIndex) {
      const transformedValue = this.references[rowIndex].transformedRow[
        colIndex
      ];
      const empty = transformedValue === null || transformedValue === "";
      const param = this.getParam(colIndex);
      if (!param || !empty) return null;
      return "El parámetro " + param.name + " es obligatorio.";
    },
    validateSameProductWithVariant(rowIndex, colIndex) {
      if (
        this.variantKeyColumn !== colIndex ||
        !this.references[rowIndex].oldVariant
      )
        return null;
      if (
        this.$dig(this, "references", rowIndex, "oldProduct", "id") !==
        this.references[rowIndex].oldVariant.productId
      ) {
        this.critical =
          "Hay filas que contienen variantes que no pertenecen al producto de la misma fila";
        return "Esta variante pertenece a otro producto";
      }
    },
    validateNewProduct(rowIndex, colIndex) {
      if (this.isRequiredColumnForCreate(colIndex))
        return this.validateExists(rowIndex, colIndex);
      if (
        this.productKeyColumn !== colIndex ||
        this.references[rowIndex].oldProduct
      )
        return null;
      const notExistingParams = this.requiredOnCreateParams.filter(param => {
        const exists = this.data.columnParams.find(x => {
          if (!x || !x.length) return false;
          return x.split("_")[0] === param.id;
        });
        if (exists) return false;
        if (!exists && param.model === "category_attribute") {
          const categoryAttribute = this.categoryAttributes[param.path];
          const categoryId = this.references[rowIndex].newProductData
            .categoryId;
          return (
            this.$ifNull(categoryAttribute.categoryIds, []).indexOf(
              categoryId
            ) > -1
          );
        }
        return param.publicApplicationInformationId === null;
      });
      if (notExistingParams.length) {
        const notExistingString = notExistingParams
          .map(param => param.name)
          .join(", ");
        this.critical =
          "Existen productos nuevos que no pueden ser creados porque " +
          "faltan las siguientes columnas obligatorias: " +
          notExistingString;
        return (
          "Este producto es nuevo. Los siguientes parámetros son obligatorios: " +
          notExistingString
        );
      }
      return null;
    },
    mustApplyBlockage(blockage, rowIndex) {
      const reference = this.references[rowIndex];
      if (blockage.allInventory) return true;
      const product = Object.assign(
        {},
        this.$ifNull(reference.oldProduct, {}),
        reference.newProductData
      );
      if (blockage.productIds && blockage.productIds.length) {
        return blockage.productIds.includes(product.id);
      }
      return (
        (!blockage.categoryIds ||
          !blockage.categoryIds.length ||
          blockage.categoryIds.includes(product.categoryId)) &&
        (!blockage.brandIds ||
          !blockage.brandIds.length ||
          blockage.brandIds.includes(product.brandId))
      );
    },
    checkPriceBlockage(rowIndex, colIndex, value) {
      const param = this.getParam(colIndex);
      if (
        !this.blockages ||
        !this.blockages.length ||
        value === this.INCORRECT ||
        !param ||
        !(param.price || param.priceCompare)
      )
        return null;
      for (let i = 0; i < this.blockages.length; i++) {
        const blockage = this.blockages[i];
        if (this.mustApplyBlockage(blockage, rowIndex)) {
          if (param.price && blockage.lowerLimitSalePrice > value) {
            return (
              "Bloqueo de precio '" +
              blockage.name +
              "' (" +
              blockage.description +
              "). Debe ser mayor a " +
              blockage.lowerLimitSalePrice
            );
          } else if (
            param.priceCompare &&
            blockage.lowerLimitRegularPrice > value
          ) {
            return (
              "Bloqueo de precio '" +
              blockage.name +
              "' (" +
              blockage.description +
              "). Debe ser mayor a " +
              blockage.lowerLimitRegularPrice
            );
          }
        }
      }
      return null;
    },
    getVariant(reference) {
      let variant = Object.assign(
        {},
        this.$ifNull(reference.oldVariant, {}),
        this.$ifNull(reference.newVariantData, {})
      );
      if (!Object.keys(variant).length) return null;
      return variant;
    },
    checkCustomValidation(rowIndex, colIndex, value) {
      const param = this.getParam(colIndex);
      if (!param || !param.customValidation || !param.customValidation.length)
        return null;
      const reference = this.references[rowIndex];
      const product = Object.assign(
        {},
        this.$ifNull(reference.oldProduct, {}),
        reference.newProductData
      );
      let variant = this.getVariant(reference);
      const columnParam = this.data.columnParams[colIndex];
      const integrationConfig = this.$dig(
        this.references.integrationConfigs,
        columnParam.split("_")[1]
      );
      try {
        const func = eval(param.customValidation);
        return func(product, variant, integrationConfig, value);
      } catch (_e) {
        return null;
      }
    },
    updateProductReferences(rowIndex, colIndex, value) {
      const reference = this.references[rowIndex];
      this.setParamValue(
        reference.oldProduct,
        reference.newProductData,
        this.data.columnParams[colIndex],
        value,
        this.productParams
      );
    },
    updateCategoryAtributeReference(rowIndex, colIndex, value) {
      const reference = this.references[rowIndex];
      const param = this.getParam(colIndex);
      if (!param || param.model !== "category_attribute") return;
      const categoryAttribute = this.categoryAttributes[param.path];
      this.setCategoryAttributeValue(
        reference.newCategoryAttributes,
        categoryAttribute,
        value
      );
    },
    updateVariantReferences(rowIndex, colIndex, value) {
      const reference = this.references[rowIndex];
      this.setParamValue(
        reference.oldVariant,
        reference.newVariantData,
        this.data.columnParams[colIndex],
        value,
        this.variantParams
      );
    },
    updateVariantWarehouseReferences(rowIndex, colIndex, value) {
      const param = this.getParam(colIndex);
      const columnParam = this.data.columnParams[colIndex];
      if (!columnParam) return;
      const warehouseId = columnParam.split("_")[2];
      if (!param || param.model !== "variant_warehouse" || !warehouseId.length)
        return;
      this.references[rowIndex].newVariantWarehousesData[
        warehouseId
      ].quantity = value;
    },
    updateIntegrationConfigsReferences(rowIndex, colIndex, value) {
      const param = this.getParam(colIndex);
      if (!this.data.columnParams[colIndex]) return;
      const integrationConfigId = this.data.columnParams[colIndex].split(
        "_"
      )[1];
      if (!param || param.model !== "company" || !integrationConfigId) return;
      const oldProduct = this.references[rowIndex].oldProduct;
      this.assignExclussion(
        integrationConfigId,
        this.$dig(oldProduct, "id"),
        this.references[rowIndex].newProductData.sku,
        value
      );
    },
    updateTransformedReference(rowIndex, colIndex, value) {
      this.references[rowIndex].transformedRow[
        colIndex
      ] = this.transformElement(value, colIndex, rowIndex);
    },
    updateReferences(rowIndex, colIndex, value) {
      this.updateTransformedReference(rowIndex, colIndex, value);
      const transformed = this.transformColData(rowIndex, colIndex);
      this.updateProductReferences(rowIndex, colIndex, transformed);
      this.updateCategoryAtributeReference(rowIndex, colIndex, transformed);
      this.updateVariantReferences(rowIndex, colIndex, transformed);
      this.updateVariantWarehouseReferences(rowIndex, colIndex, transformed);
      this.updateIntegrationConfigsReferences(rowIndex, colIndex, transformed);
    },
    getParam(colIndex) {
      const columnParam = this.data.columnParams[colIndex];
      if (!columnParam) return null;
      return this.params[columnParam.split("_")[0]];
    },
    validateCell(rowIndex, colIndex, value) {
      const param = this.getParam(colIndex);
      const formatedValue = this.references[rowIndex].transformedRow[colIndex];
      const errors = [];
      errors.push(this.validateNewProduct(rowIndex, colIndex));
      errors.push(this.checkCustomValidation(rowIndex, colIndex, value));
      if (!param || !value || !value.toString().length)
        return errors.filter(x => x && x.length).join(", ");
      errors.push(this.checkFormat(rowIndex, colIndex));
      errors.push(this.checkOptions(formatedValue, colIndex));
      errors.push(this.checkPriceBlockage(rowIndex, colIndex, value));
      errors.push(this.validateSameProductWithVariant(rowIndex, colIndex));
      if (
        formatedValue != this.INCORRECT &&
        formatedValue != null &&
        this.data.data[rowIndex][colIndex] != formatedValue
      ) {
        if (formatedValue.name != null) {
          this.data.data[rowIndex][colIndex] = formatedValue.name;
        } else {
          this.data.data[rowIndex][colIndex] = formatedValue;
        }
      }
      return errors.filter(x => x && x.length).join(", ");
    },
    setCategoryAttributesData(hash, reference) {
      let { categoryAttributesRow, newCategoryAttributes } = reference;
      if (!categoryAttributesRow) categoryAttributesRow = {};
      const updated = Object.assign(
        {},
        categoryAttributesRow,
        newCategoryAttributes
      );
      let differences = false;
      Object.keys(newCategoryAttributes).forEach(caId => {
        let prevCav = categoryAttributesRow[caId];
        if (!prevCav) prevCav = {};
        const currentCav = Object.assign(
          {},
          prevCav,
          newCategoryAttributes[caId]
        );
        differences =
          differences ||
          prevCav.valueFilled !== currentCav.valueFilled ||
          !this.$sameSet(prevCav.valueSelectedIds, currentCav.valueSelectedIds);
      });
      if (differences) {
        if (!hash.categoryAttributeValues) hash.categoryAttributeValues = [];
        hash.categoryAttributeValues.push(
          ...Object.keys(updated).map(caId => updated[caId])
        );
      }
    },
    productToCreate(reference) {
      const oldProduct = reference.oldProduct;
      const newProductData = reference.newProductData;
      if (oldProduct) {
        return {};
      }
      this.setCategoryAttributesData(newProductData, reference);
      const hash = {};
      hash[newProductData.sku] = this.saveAssets(
        newProductData,
        reference.newAssetData
      );
      return hash;
    },
    productToUpdate(reference) {
      const oldProduct = reference.oldProduct;
      const newProductData = reference.newProductData;
      this.setCategoryAttributesData(newProductData, reference);
      if (!oldProduct) {
        return {};
      }
      newProductData.id = oldProduct.id;
      newProductData.sku = oldProduct.sku;
      const hash = {};
      hash[oldProduct.sku] = this.saveAssets(
        newProductData,
        reference.newAssetData,
        oldProduct
      );
      return hash;
    },
    /**
     * Mezcla los datos de los assets nuevos con los antiguos
     * @param {Object} productData
     * @param {Object} assetsData
     * @param {Object} oldProduct
     * @returns {Object}
     */
    saveAssets(productData, assetsData, oldProduct = null) {
      // ProductData = cambios  // assets: assets desde carga
      if (assetsData?.urls?.length > 0 && oldProduct?.assets?.length > 0) {
        let oldAssets = [];
        oldProduct.assets.forEach(asset => {
          oldAssets[asset.position] = asset.originalUrl;
        });
        productData.assetUrls = oldAssets;
      }

      const oldAssetsUrls = productData.assetUrls ? productData.assetUrls : [];
      const newAssetsUrls = assetsData?.urls ? assetsData.urls : [];
      productData.assetUrls = this.$deepMerge(oldAssetsUrls, newAssetsUrls);

      const oldAssets = productData.assets ? productData.assets : {};
      delete assetsData.urls;
      productData.assets = this.$deepMerge(oldAssets, assetsData);

      if (!productData.assetUrls.length) delete productData.assetUrls;
      if (!Object.keys(productData.assets).length) delete productData.assets;
      return productData;
    },
    variantToCreate(reference) {
      const oldProduct = reference.oldProduct;
      const newProductData = reference.newProductData;
      const oldVariant = reference.oldVariant;
      const newVariantData = reference.newVariantData;
      if (oldVariant || !Object.keys(newVariantData).length) {
        return {};
      }
      const hash = {};
      hash[newVariantData.sku] = newVariantData;
      if (oldProduct) {
        hash[newVariantData.sku].productId = oldProduct.id;
      } else {
        hash[newVariantData.sku].productSku = newProductData.sku;
      }
      return hash;
    },
    variantToUpdate(reference) {
      const oldVariant = reference.oldVariant;
      const newVariantData = reference.newVariantData;
      if (!oldVariant || !Object.keys(newVariantData).length) {
        return {};
      }
      newVariantData.id = oldVariant.id;
      const hash = {};
      hash[oldVariant.sku] = newVariantData;
      return hash;
    },
    variantWarehousesToUpsert(reference) {
      const variantWarehouses = [];
      const oldVariant = reference.oldVariant;
      const newVariantData = reference.newVariantData;
      Object.keys(reference.newVariantWarehousesData).forEach(wId => {
        const vw = reference.newVariantWarehousesData[wId];
        vw.warehouseId = wId;
        if (oldVariant) {
          vw.variantId = oldVariant.id;
        } else {
          vw.variantSku = newVariantData.sku;
        }
        variantWarehouses.push(vw);
      });
      return variantWarehouses;
    },
    integrationConfigToUpdate(reference) {
      const excludedProductIds = [];
      const includedProductIds = [];
      const excludedProductSkus = [];
      const includedProductSkus = [];
      Object.keys(reference.newExcludedProductIds).forEach(productIds => {
        if (reference.newExcludedProductIds[productIds]) {
          excludedProductIds.push(productIds);
        } else {
          includedProductIds.push(productIds);
        }
      });
      Object.keys(reference.newExcludedProductSkus).forEach(productSkus => {
        if (reference.newExcludedProductSkus[productSkus]) {
          excludedProductSkus.push(productSkus);
        }
      });
      return {
        excludedProductIds,
        includedProductIds,
        excludedProductSkus,
        includedProductSkus
      };
    },
    integrationConfigsToUpdate() {
      const toUpdate = {};
      Object.keys(this.references.integrationConfigs).forEach(icId => {
        const updatedExclusions = this.integrationConfigToUpdate(
          this.references.integrationConfigs[icId]
        );
        if (
          updatedExclusions.excludedProductIds.length ||
          updatedExclusions.includedProductIds.length ||
          updatedExclusions.excludedProductSkus.length ||
          updatedExclusions.includedProductSkus.length
        ) {
          toUpdate[icId] = updatedExclusions;
          toUpdate[icId].id = icId;
        }
      });
      return toUpdate;
    },
    changes() {
      const changesHash = {
        productsIdHash: this.productsIdHash,
        variantsIdHash: this.variantsIdHash,
        productsToCreate: {},
        productsToUpdate: {},
        variantsToCreate: {},
        variantsToUpdate: {},
        variantWarehousesToUpsert: []
      };
      Object.keys(this.references).forEach(rowNum => {
        if (rowNum === "integrationConfigs") return;
        const reference = this.references[rowNum];
        this.$deepMerge(
          changesHash.productsToCreate,
          this.productToCreate(reference),
          false
        );
        this.$deepMerge(
          changesHash.productsToUpdate,
          this.productToUpdate(reference),
          false
        );
        this.$deepMerge(
          changesHash.variantsToCreate,
          this.variantToCreate(reference),
          false
        );
        this.$deepMerge(
          changesHash.variantsToUpdate,
          this.variantToUpdate(reference),
          false
        );
        changesHash.variantWarehousesToUpsert.push(
          ...this.variantWarehousesToUpsert(reference)
        );
      });
      changesHash.integrationConfigsToUpdate = Object.values(
        this.integrationConfigsToUpdate()
      );
      changesHash.productsToCreate = Object.values(
        changesHash.productsToCreate
      );
      changesHash.productsToUpdate = Object.values(
        changesHash.productsToUpdate
      );
      changesHash.variantsToCreate = Object.values(
        changesHash.variantsToCreate
      );
      changesHash.variantsToUpdate = Object.values(
        changesHash.variantsToUpdate
      );
      return changesHash;
    },
    modelParams(params, models) {
      const validParams = {};
      Object.keys(params).forEach(pId => {
        const param = params[pId];
        if (models.includes(param.model)) {
          validParams[pId] = param;
        }
      });
      return validParams;
    },
    updateErrorPage() {
      this.errors = {};
      this.rowMap = [];
      this.errorRowsPage = [];
      this.errorRowsNextPages.slice(0, this.errorsPerPage).forEach(errorRow => {
        this.addErrorsRow(
          errorRow.rowNum,
          this.data.data[errorRow.rowNum],
          errorRow.errors
        );
      });
      this.errorRowsNextPages = this.errorRowsNextPages.slice(
        this.errorsPerPage,
        this.errorRowsNextPages.length
      );
      this.errorsPagesCounter++;
    },
    updateCorrectedErrors() {
      const changes = [];
      this.errorRowsPage.forEach((row, rowIndex) => {
        const originalRowIndex = this.rowMap[rowIndex];
        const original = this.data.data[originalRowIndex];
        const changedRow = [originalRowIndex + 1];
        let correctedIndex = 0;
        for (let i = 0; i < original.length; i++) {
          const columnParam = this.data.columnParams[i];
          if (!columnParam || !columnParam.length) {
            continue;
          }
          const newVal = row[correctedIndex];
          if (
            i !== this.idVariantColumn &&
            i !== this.skuVariantColumn &&
            i !== this.idProductColumn &&
            i !== this.skuProductColumn &&
            newVal === original[i]
          ) {
            changedRow.push(null);
          } else {
            changedRow.push(newVal);
          }
          correctedIndex++;
        }
        changes.push(changedRow);
      });
      this.correctedErrors.push(...changes);
    },
    emitValidate() {
      this.updateCorrectedErrors();
      if (this.errorRowsNextPages.length) {
        this.updateErrorPage();
        return;
      }
      const changes = this.changes();
      this.$emit(
        "valid",
        Object.assign(
          {
            correctedErrors: this.correctedErrors,
            hasAssets: this.hasAssets
          },
          changes
        )
      );
    },
    /**
     * Valida si un texto es HTML.
     * @method
     * @param {string} str - Texto a validar.
     * @return {boolean} - true si el texto se considerara html, false en caso contrario.
     */
    validateHTML(str) {
      const fragment = document.createRange().createContextualFragment(str);

      // remove all non text nodes from fragment
      fragment
        .querySelectorAll("*")
        .forEach(el => el.parentNode.removeChild(el));

      // if there is textContent, then not a pure HTML
      return !(fragment.textContent || "").trim();
    }
  }
};
</script>
