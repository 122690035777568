<template>
  <b-tr>
    <b-td>
      {{ blockage.name }}
    </b-td>
    <b-td>
      {{ blockage.blockageType }}
    </b-td>
    <b-td>
      {{ blockage.description }}
    </b-td>
    <b-td>
      {{ blockage.productsAffected }}
    </b-td>
    <b-td>
      {{ blockage.status }}
    </b-td>
    <b-td>
      <b-row align-h="end" no-gutters>
        <div class="mr-2">
          <span v-if="deleting"> Eliminando <b-spinner small /> </span>
        </div>
        <b-button
          v-if="$permissionToUpdate(currentUser, 'blockages')"
          size="lg"
          variant="outlined"
          class="p-0 mx-1 focus-btn"
          v-b-tooltip.hover="'Editar bloqueo'"
          :to="`/blockage/${blockage.id}/edit`"
        >
          <b-icon stacked icon="pencil" scale="0.60"></b-icon>
        </b-button>
        <b-button
          v-if="$permissionToDelete(currentUser, 'blockages')"
          size="lg"
          variant="outlined"
          class="p-0 mx-1 focus-btn"
          v-b-tooltip.hover="'Eliminar bloqueo'"
          @click="swalDeleteBlockage"
        >
          <b-icon stacked icon="x" scale="0.75"></b-icon>
        </b-button>
        <b-button
          v-if="$permissionToRead(currentUser, 'blockages')"
          size="lg"
          variant="outlined"
          class="p-0 mx-1 focus-btn"
          v-b-tooltip.hover="'Ver bloqueo'"
          :to="`/blockage/${blockage.id}`"
        >
          <b-icon stacked icon="eye" scale="0.60"></b-icon>
        </b-button>
      </b-row>
    </b-td>
  </b-tr>
</template>

<script>
import DELETE_BLOCKAGE from "../../graphql/Blockages/DeleteBlockage.gql";
import { mapState } from "vuex";
export default {
  name: "BlockagesDetail",
  props: {
    blockage: Object
  },
  data() {
    return {
      deleting: false
    };
  },
  computed: {
    ...mapState(["currentUser"])
  },
  methods: {
    /**
     * Se encarga de enviar la mutacion para borrar un bloqueo
     */
    deleteBlockage() {
      this.deleting = true;
      this.$apollo
        .mutate({
          mutation: DELETE_BLOCKAGE,
          variables: {
            blockageId: this.blockage.id
          }
        })
        .then(({ data }) => {
          if (data.deleteBlockage.result) {
            this.$emit("delete-blockage");
          } else {
            this.$emit("delete-blockage", data.deleteBlockage.error);
          }
          this.deleting = false;
        })
        .catch(e => {
          this.$emit("delete-blockage", e.message);
          this.deleting = false;
        });
    },
    /**
     * Lanza un swal para confirmar la eliminacion del bloqueo
     */
    swalDeleteBlockage() {
      this.$swal
        .fire({
          title: "Eliminar bloqueo",
          text: "El bloqueo se eliminará definitivamente. ¿Estás seguro?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si",
          cancelButtonText: "No"
        })
        .then(result => {
          if (result.value) {
            this.deleteBlockage();
          }
        });
    }
  }
};
</script>

<style>
.focus-btn {
  color: #aab1b5;
  display: inline-block;
  background-color: white;
  width: 34px;
  height: 34px;
  text-align: center;
  border-radius: 50%;
  font-size: 14px;
  border: 2px solid #e0e2e4;
  padding: 5px 0;
}

.focus-btn:hover {
  border-color: #aab1b5;
  color: black;
}
</style>
