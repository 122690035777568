<template>
  <div>
    <bulk-upload-template-downloads-page />
  </div>
</template>

<script>
import BulkUploadTemplateDownloadsPage from "../components/BulkUpload/TemplateDownloads/TemplateDownloadsPage.vue";
export default {
  name: "BulkUploadTemplateDownloads",
  components: {
    BulkUploadTemplateDownloadsPage
  }
};
</script>
