<template>
  <base-help-modal
    :id="`mercado_libre_optin_${itemId}_${variationId}`"
    tooltip="Publica en catálogo"
    link-class="text-center"
    title="Optin"
    size="lg"
    :hide-button="true"
    @show="show"
  >
    <template v-slot:link>
      <div class="text-center">
        <p>Publicar en catálogo</p>
      </div>
    </template>

    <div class="accordion" role="tablist">
      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <strong>Paso 1:</strong>
          Buscar un producto de catálogo que coincida con tu publicación
        </b-card-header>
        <b-collapse
          id="optin-accordion-0"
          :visible="step == 0"
          accordion="optin-accordion"
          role="tabpanel"
        >
          <b-card-body>
            <b-row>
              <b-col cols="10">
                <b-form-input
                  v-model="query"
                  type="search"
                  @keyup.enter="search"
                />
              </b-col>
              <b-col>
                <b-button variant="primary" @click="search">
                  <b-icon icon="search" aria-hidden="true"></b-icon>
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-alert
                  show
                  variant="warning"
                  v-if="this.$dig(searchResult, 'paging', 'total') === 0"
                >
                  No se encontraron resultados
                </b-alert>
                <b-card-text v-if="options.length > 0">
                  Elige una de las siguientes opciones
                </b-card-text>
              </b-col>
            </b-row>
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                id="btn-radios-3"
                v-model="selected"
                :options="options"
                :aria-describedby="ariaDescribedby"
                @change="goToReview"
                button-variant="outline-primary"
                name="radio-btn-stacked"
                class="d-flex flex-wrap"
                buttons
                stacked
              />
            </b-form-group>
          </b-card-body>
        </b-collapse>
      </b-card>

      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <strong>Paso 2:</strong>
          Revisar que los atributos del producto seleccionado correspondan con
          los de tu publicación
        </b-card-header>
        <b-collapse
          id="optin-accordion-1"
          accordion="optin-accordion"
          role="tabpanel"
          :visible="step == 1"
        >
          <b-card-body>
            <b-card-text v-if="selected">
              Atributos de <strong>{{ selected.name }}</strong>
            </b-card-text>
            <b-container fluid class="p-4 bg-dark" v-if="selected">
              <b-row>
                <b-col v-for="picture of selected.pictures" :key="picture.id">
                  <b-img thumbnail fluid :src="picture.url"></b-img>
                </b-col>
              </b-row>
            </b-container>
            <div id="optin.attribute-table-container">
              <b-table-lite
                :items="selectedAttributes"
                head-variant="dark"
                small
                sticky-header
              ></b-table-lite>
            </div>
            <div>
              <b-button @click="goToSearch">Volver al buscador</b-button>
              <b-button @click="publishAndResult" variant="primary">
                Publicar en este producto de catálogo
              </b-button>
            </div>
          </b-card-body>
        </b-collapse>
      </b-card>

      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <strong>Paso 3:</strong>
          Resultado
        </b-card-header>
        <b-collapse
          id="optin-accordion-2"
          accordion="optin-accordion"
          role="tabpanel"
          :visible="step == 2"
        >
          <b-card-body>
            <div v-if="this.optinResult">
              <div v-if="this.optinResult.error">
                <b-alert show variant="danger">
                  <strong>¡Reflauta!</strong>
                  Algo salió mal
                </b-alert>
                <ul>
                  <li v-for="(value, key) in this.optinResult" :key="key">
                    <strong>{{ key }}:</strong>
                    {{ value }}
                  </li>
                </ul>
              </div>
              <b-alert v-else show variant="success">
                Solicitud enviada exitosamente
              </b-alert>
            </div>
            <div v-else class="loading apollo">Cargando...</div>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
  </base-help-modal>
</template>
<script>
import BaseHelpModal from "./BaseHelpModal";
import { centryUrl } from "../main";
import INTEGRATION_CONFIG_MERCADO_LIBRE_PRODUCTS_SEARCH from "../graphql/IntegrationConfigMercadoLibreProductsSearch.gql";
import MERCADO_LIBRE_REQUEST_OPTIN from "../graphql/MercadoLibreRequestOptin.gql";
export default {
  name: "MercadoLibreAttributesOptinModal",
  components: { BaseHelpModal },
  props: {
    productTitle: String,
    itemId: String,
    variationId: Number,
    integrationConfig: Object
  },
  data() {
    return {
      query: "",
      searchResult: { results: [] },
      selected: null,
      options: [],
      centryUrl,
      step: 0,
      optinResult: null
    };
  },
  mounted: function() {},
  methods: {
    show() {
      this.query = this.productTitle;
      this.searchResult = { results: [] };
      this.selected = null;
      this.options = [];
      this.step = 0;
      this.optinResult = null;
      this.search();
    },
    search() {
      if (!this.query || this.query === "") {
        return;
      }
      this.$apollo
        .query({
          query: INTEGRATION_CONFIG_MERCADO_LIBRE_PRODUCTS_SEARCH,
          variables: {
            id: this.integrationConfig.id,
            status: "active",
            q: this.query
          }
        })
        .then(result => {
          this.searchResult =
            result.data.integrationConfigMercadoLibre.productsSearch;
          this.options = this.searchResult.results.map(product => ({
            text: product.name,
            value: product
          }));
        });
    },
    goToSearch() {
      this.selected = null;
      this.step = 0;
    },
    goToReview(event) {
      this.step = event ? 1 : 0;
    },
    publishAndResult() {
      this.step = 2;
      this.$apollo
        .mutate({
          mutation: MERCADO_LIBRE_REQUEST_OPTIN,
          variables: {
            integrationConfigId: this.integrationConfig.id,
            itemId: this.itemId,
            catalogProductId: this.selected.id,
            variationId: this.variationId
          }
        })
        .then(result => {
          this.optinResult = result.data.mercadoLibreRequestOptin.result;
        });
    }
  },
  computed: {
    selectedAttributes() {
      if (!this.selected) {
        return [];
      }
      return this.selected.attributes.map(attr => ({
        campo: attr.name,
        valor: attr.valueName
      }));
    }
  }
};
</script>
<style scoped>
#optin.attribute-table-container {
  max-height: 300px;
  overflow-y: auto;
}
.table td {
  height: inherit;
  font-weight: inherit;
}
</style>
