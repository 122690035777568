var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('b-spinner',{attrs:{"label":"Spinning"}}):_c('div',[(_vm.item)?_c('base-header',{attrs:{"title":"Productos","title-link":"/productos","map":['Mercado Libre', _vm.item.title, 'Fulfillment', 'Stock']},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('b-container',[_c('b-dropdown',{attrs:{"variant":"outline-info","text":"Ver más"}},[(_vm.$route.meta.action !== 'Stock de fulfillment')?_c('b-dropdown-item',{attrs:{"to":{
                name: 'MercadoLibreItemFulfillmentStock',
                params: {
                  integrationConfigId: _vm.integrationConfigId,
                  itemId: _vm.itemId
                }
              }}},[_vm._v(" Stock ")]):_vm._e(),(_vm.$route.meta.action !== 'Operaciones de fulfillment')?_c('b-dropdown-item',{attrs:{"to":{
                name: 'MercadoLibreItemFulfillmentOperations',
                params: {
                  integrationConfigId: _vm.integrationConfigId,
                  itemId: _vm.itemId
                }
              }}},[_vm._v(" Operaciones ")]):_vm._e()],1)],1)]},proxy:true}],null,false,3755946691)}):_vm._e(),_c('b-container',{attrs:{"fluid":""}},[(!_vm.item.inventory_id)?_c('b-alert',{attrs:{"show":"","variant":"info"}},[_vm._v(" Este producto no participa del programa fulfillment ")]):(!_vm.stock)?_c('b-alert',{attrs:{"show":"","variant":"info"}},[_vm._v(" Este producto no tiene stock registrado en fulfillment ")]):_c('b-table-simple',{attrs:{"small":"","responsive":""}},[_c('b-tbody',[_c('b-tr',[_c('b-th',{attrs:{"colspan":"2"}},[_vm._v("Total")]),_c('b-td',[_vm._v(_vm._s(this.stock.total))])],1),_c('b-tr',[_c('b-th',{attrs:{"colspan":"2"}},[_vm._v("Disponible")]),_c('b-td',[_vm._v(_vm._s(this.stock.available_quantity))])],1),_c('b-tr',[_c('b-th',{attrs:{"colspan":"2"}},[_vm._v("No Disponible")]),_c('b-td',[_vm._v(_vm._s(this.stock.not_available_quantity))])],1),_vm._l((_vm.notAvailableDetail),function(detail){return _c('b-tr',{key:detail.key},[_c('b-th'),_c('b-th',{staticClass:"text-muted"},[_vm._v(_vm._s(detail.caption))]),_c('b-td',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.getNotAvailableDetailStock(detail.key)))])],1)})],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }