<script>
import { Bar } from "vue-chartjs";
export default {
  extends: Bar,
  props: {
    data: {
      type: Object,
      default() {
        return {};
      }
    },
    options: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      }
    };
  },
  mounted() {
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    this.renderChart(this.data, this.mergeOptions());
  },
  methods: {
    mergeOptions() {
      const d = this.$deepMerge(
        Object.assign({}, this.chartOptions),
        this.options
      );
      if (!d.scales) d.scales = {};
      if (!d.scales.xAxes) d.scales.xAxes = [{}];
      d.scales.xAxes[0] = this.$deepMerge(
        {
          stacked: true
        },
        d.scales.xAxes[0]
      );
      return d;
    }
  },
  watch: {
    data(newVal) {
      this.renderChart(newVal, this.mergeOptions());
    }
  }
};
</script>
