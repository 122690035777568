<template>
  <div>
    <div class="mb-3">
      <h4 class="font-weight-bold my-auto">Administración</h4>
      <hr />
      <company-basic-info-form :edit="false" :company="company" />
    </div>
    <ApolloQuery
      :query="require('../../../graphql/Company/CompanyIntegrations.gql')"
      fetchPolicy="no-cache"
      notifyOnNetworkStatusChange
    >
      <template slot-scope="{ result: { data }, isLoading }">
        <!-- <div class="my-3">
          <h4 class="font-weight-bold my-auto">Integraciones</h4>
          <hr />
          <new-integrations-modal
            :company="company"
            :integrations="
              isLoading ? [] : data.companyIntegrations.integrations
            "
            :is-loading="isLoading == 1"
            :categories="isLoading ? [] : data.companyIntegrations.categories"
          />
        </div> -->
        <integrations-list
          :company="company"
          class="my-3"
          :is-loading="isLoading == 1"
          :integrations="
            isLoading ? [] : data.companyIntegrations.integrationConfigs
          "
          :select-all="selectAll"
          @change="setSelected"
        />
      </template>
    </ApolloQuery>
    <div>
      <synchronization
        :ics="icsSelected"
        @change="setSelectAll"
        @sincro-sended="reRender += 1"
      />
    </div>
    <div>
      <synchronization-list :company="company" :re-render="reRender" />
    </div>
  </div>
</template>
<script>
import CompanyBasicInfoForm from "../BasicInfoForm.vue";
// import NewIntegrationsModal from "./NewIntegrationsModal.vue";
import IntegrationsList from "./IntegrationsList.vue";
import Synchronization from "./Synchronization.vue";
import SynchronizationList from "./SynchronizationList.vue";

export default {
  name: "CompanyShowPage",
  components: {
    CompanyBasicInfoForm,
    // NewIntegrationsModal,
    IntegrationsList,
    Synchronization,
    SynchronizationList
  },
  props: {
    company: Object
  },
  data() {
    return {
      icsSelected: [],
      selectAll: false,
      reRender: 0
    };
  },
  methods: {
    /**
     * Actualiza la lista de integraciones seleccionadas
     * @param {Object} val
     */
    setSelected(val) {
      let selected = [];
      Object.keys(val).forEach(v => {
        if (val[v]) {
          selected.push(v);
        }
      });
      this.icsSelected = selected;
    },
    /**
     * Indica si se selecionaron todas las integraciones o no
     * @param {Boolean} val
     */
    setSelectAll(val) {
      this.selectAll = val;
    }
  }
};
</script>
