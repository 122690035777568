<template>
  <ApolloQuery
    :query="
      gql => gql`
        query {
          allIntegrationConfigs {
            id
            label
            available
            _type
          }
        }
      `
    "
    :fetchPolicy="'cache-and-network'"
    clientId="apolloClientCached"
  >
    <template slot-scope="{ result: { data }, isLoading }">
      <b-row align-v="end">
        <span v-if="data && isLoading" class="m-2 float-right">
          Actualizando listado de integraciones...
        </span>
      </b-row>
      <b-form-checkbox-group
        :checked="integrationConfigs"
        :options="availableIntegrations(data)"
        @input="$emit('update:integrationConfigs', $event)"
      >
        <b-form-invalid-feedback :state="state" v-if="showInvalidFeedback">
          Selecciona al menos una integración
        </b-form-invalid-feedback>
      </b-form-checkbox-group>
    </template>
  </ApolloQuery>
</template>

<script>
export default {
  name: "IntegrationConfigCheckboxes",
  props: {
    integrationConfigs: { type: Array, default: () => [] },
    typeExcluded: { type: Array, default: () => [] },
    showInvalidFeedback: { type: Boolean, default: true }
  },
  computed: {
    state() {
      return this.integrationConfigs.length > 0;
    }
  },
  methods: {
    /**
     * En caso de tener una respuesta exitosa de la query allIntegrationConfigs,
     * se revisa el resultado y se procesa de la siguiente manera:
     *
     * * Se descartan todos los elementos que tengan el atributo `available` con
     *   el valor `false`y los que tengan un `_type` que incluido en el listado
     *   `typeExcluded` de este mismo componente.
     * * El resultado es emitido como un evento `"populated"`.
     * * Se construye un arreglo de objetos para ser usado en un
     *   `b-form-checkbox-group` donde cada objeto tiene las siguientes llaves:
     *   * `value`: La que contiene el objeto completo.
     *   * `text`: La que sólo contiene el valor del atributo `label`.
     *
     * @param {Object} data resultado de la query "allIntegrationConfigs"
     */
    availableIntegrations(data) {
      if (!data || !data.allIntegrationConfigs) {
        return [];
      }
      const filtered = data.allIntegrationConfigs.filter(
        x => x.available && !this.typeExcluded.includes(x._type)
      );
      this.$emit("populated", filtered);
      return filtered.map(op => {
        return { value: op, text: op.label };
      });
    }
  }
};
</script>
