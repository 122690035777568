<template>
  <div>
    <b-row>
      <b-col :lg="notSlave ? 4 : 6" sm="6" cols="12" class="mb-3">
        <b-form-group
          label="No crear en BSale documentos de ventas de pedidos creados antes de la fecha"
          label-for="doNotCreateDocumentsBefore"
          class="mb-3"
          v-if="notSlave"
        >
          <base-date-time
            v-model="value.doNotCreateDocumentsBefore"
            id="doNotCreateDocumentsBefore"
            @change="emitChange"
            type="datetime"
            value-type="format"
            :disabled="!allowSave"
          ></base-date-time>
        </b-form-group>
        <b-form-group
          label="Sucursal"
          label-for="officeId"
          class="mb-3"
          :description="officeIdDescription"
        >
          <b-spinner v-if="loadingOffices" />
          <v-select
            v-else
            id="officeId"
            v-model="value.officeId"
            :reduce="x => x.value"
            :options="officeIdOptions"
            :clearable="false"
            append-to-body=""
            @input="emitChange"
            placeholder="Selecciona una opción"
            :disabled="!allowSave"
          />
        </b-form-group>
        <b-form-group
          label="Restricciones sobre los items para la creación de documentos en BSale"
          label-for="documentCreationRequirements"
          class="mb-3"
          v-if="notSlave"
        >
          <v-select
            id="documentCreationRequirements"
            v-model="value.documentCreationRequirements"
            :reduce="x => x.value"
            :options="documentCreationRequirementsOptions"
            :clearable="false"
            append-to-body=""
            @input="emitChange"
            placeholder="Selecciona una opción"
            :disabled="!allowSave"
          />
        </b-form-group>
        <b-form-group
          label="Guardar en Centry el documento creado en BSale"
          label-for="saveDocumentsInCentryAs"
          class="mb-3"
        >
          <v-select
            id="saveDocumentsInCentryAs"
            v-model="value.saveDocumentsInCentryAs"
            :reduce="x => x.value"
            :options="saveDocumentsInCentryAsOptions"
            :clearable="false"
            append-to-body=""
            @input="emitChange"
            placeholder="Selecciona una opción"
            :disabled="!allowSave"
          />
        </b-form-group>
      </b-col>
      <b-col lg="4" sm="6" cols="12" class="mb-3" v-if="notSlave">
        <b-form-group
          label="Cliente por defecto"
          label-for="default_client"
          class="mb-3"
          :description="currentDefaultClientDescription"
        >
          <b-spinner v-if="loadingCurrentDefaultClient" />
          <span id="default_client" v-else>
            <b>
              {{ currentDefaultClientName }}
            </b>
          </span>
          <b-button
            variant="outline-info"
            class="w-30 ml-3"
            size="sm"
            v-b-modal="'my-modal'"
            v-if="allowSave"
          >
            <span>Cambiar</span>
          </b-button>
        </b-form-group>
        <modal-clients-b-sale
          :ic="ic"
          :defaultClientId="value.defaultClientId"
          :currentDefaultClient="currentDefaultClient"
          @changeDescription="changeDescription"
          @loadingCurrentDefaultClient="setLoadingCurrentDefaultClient"
          @setCurrentDefaultClient="setCurrentDefaultClient"
        />
        <b-form-group
          label="Usar cliente por defecto"
          label-for="use_client_default_in"
          class="mb-3"
        >
          <v-select
            id="use_client_default_in"
            v-model="value.useDefaultClient"
            :reduce="x => x.value"
            :options="useDefaultClientOptions"
            :clearable="false"
            append-to-body=""
            @input="emitChange"
            placeholder="Selecciona una opción"
            :disabled="!allowSave"
          />
        </b-form-group>
      </b-col>
      <b-col :lg="notSlave ? 4 : 6" sm="6" cols="12">
        <b-form-checkbox
          v-model="value.formatRut"
          @input="emitChange"
          class="mb-3"
          :disabled="!allowSave"
          v-if="notSlave"
        >
          Formatear rut con guiones y puntos
        </b-form-checkbox>
        <b-form-checkbox
          v-model="value.customPaymentTypes"
          @input="emitChange"
          class="mb-3"
          :disabled="!allowSave"
        >
          Mostrar origen de los pedidos
        </b-form-checkbox>
        <b-form-checkbox
          v-model="value.sendEmailToClientOnDocument"
          @input="emitChange"
          class="mb-3"
          :disabled="!allowSave"
          v-if="notSlave"
        >
          Permitir a BSale enviar el documento al cliente por correo electrónico
        </b-form-checkbox>
        <b-form-checkbox
          v-model="value.strictStockValidation"
          @input="emitChange"
          class="mb-3"
          :disabled="!allowSave"
          v-if="notSlave"
        >
          Evitar la creación de documentos cuando la variante no tiene stock.
        </b-form-checkbox>
        <b-form-checkbox
          v-model="value.billingLastNameAsActivity"
          @input="emitChange"
          class="mb-3"
          :disabled="!allowSave"
          v-if="notSlave"
        >
          Enviar campo 'Apellido / Giro' de la dirección de facturación como
          giro del cliente.
        </b-form-checkbox>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import BaseDateTime from "../../BaseDateTime.vue";
import ModalClientsBSale from "./ModalClientsBSale.vue";
import INTEGRATION_DATA_OFFICES from "../../../graphql/IntegrationConfig/BSale/IntegrationDataOffices.gql";
export default {
  name: "IntegrationConfigsBSaleGeneralDocumentData",
  components: {
    BaseDateTime,
    ModalClientsBSale
  },
  props: {
    _value: Object,
    originalData: Object,
    allowSave: Boolean,
    ic: Object,
    use: String
  },
  model: {
    event: "change",
    prop: "_value"
  },
  mounted() {
    this.loadOffices();
  },
  data() {
    return {
      value: this.$dup(this._value),
      documentCreationRequirementsOptions: [
        {
          value: "no_requirements",
          label: "Ninguna. Se crean documentos sin restricción"
        },
        {
          value: "some_variant_ids",
          label:
            "Al menos uno de los artículos del pedido tiene que estar homologado con un producto de BSale"
        },
        {
          value: "all_variant_ids",
          label:
            "Todos los productos comprados tienen que estar homologados con BSale"
        }
      ],
      useDefaultClientOptions: [
        {
          value: "use_default_client_on_empty",
          label: "Cuando no hay datos"
        },
        {
          value: "use_default_client_always",
          label: "Siempre"
        }
      ],
      saveDocumentsInCentryAsOptions: [
        {
          value: "bill",
          label: "Boleta"
        },
        {
          value: "invoice",
          label: "Factura"
        },
        {
          value: "credit_note",
          label: "Nota de crédito"
        },
        {
          value: "debit_note",
          label: "Nota de débito"
        },
        {
          value: "shipping_guide",
          label: "Guía de despacho"
        },
        {
          value: "other",
          label: "Otro"
        }
      ],
      officeIdOptions: [],
      officeIdDescription: "",
      loadingOffices: false,
      currentDefaultClient: {},
      currentDefaultClientDescription: "",
      loadingCurrentDefaultClient: true
    };
  },
  computed: {
    currentDefaultClientName() {
      if (this.currentDefaultClient) {
        return `${this.currentDefaultClient.firstName} ${this.currentDefaultClient.lastName}`;
      }
      return "-- Ninguno seleccionado --";
    },
    notSlave() {
      return this.use !== "slave";
    }
  },
  methods: {
    /**
     * Carga las opciones de oficinas
     * en el selector
     */
    loadOffices() {
      this.loadingOffices = true;
      this.$apollo
        .query({
          query: INTEGRATION_DATA_OFFICES,
          variables: {
            integrationConfigId: this.ic.id
          }
        })
        .then(({ data }) => {
          this.officeIdOptions = data.integrationConfigBSale.offices.map(
            option => {
              return { value: parseInt(option.id), label: option.name };
            }
          );
          this.loadingOffices = false;
        })
        .catch(() => {
          this.officeIdOptions = [];
          this.loadingOffices = false;
          this.officeIdDescription =
            "Error: No pudimos cargar el listado de oficinas. Revisa los datos de vinculación";
        });
    },
    /**
     * Emite el valor para actualizar el modelo
     */
    emitChange() {
      this.$emit("change", this.value);
    },

    /**
     * Setea el valor en value
     * si es que es un objeto distinto
     * @param {Object} val
     */
    setValue(val) {
      if (val !== this.value) this.value = val;
    },
    /**
     * Edita el cliente por defecto actual
     * @param {Object} newDefaultClient
     */
    setCurrentDefaultClient(client, is_new_client) {
      this.currentDefaultClient = client;
      this.currentDefaultClientDescription = "";
      if (is_new_client) {
        this.$emit("success-save-client");
      }
    },
    /**
     * Edita descripcion del campo del cliente por defecto
     * @param {String} error
     */
    changeDescription(error) {
      this.currentDefaultClientDescription = error;
    },
    /**
     * Edita estado actual sobre si se cargo o no info cliente por defecto actual
     * @param {Boolean} value
     */
    setLoadingCurrentDefaultClient(value) {
      this.loadingCurrentDefaultClient = value;
    }
  },
  watch: {
    value() {
      this.emitChange();
    },
    _value(val) {
      this.setValue(val);
    }
  }
};
</script>
