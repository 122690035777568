<template>
  <div>
    <ApolloQuery
      :query="require('../../../graphql/Company/AllAsyncTasksLogs.gql')"
      :variables="queryVariables('')"
      :fetchPolicy="'cache-and-network'"
      clientId="apolloClientCached"
      :pollInterval="pollInterval"
      @result="checkResult"
    >
      <template slot-scope="{ result: { error, data }, query, isLoading }">
        <span v-if="error"
          >Error al obtener los historiales. Reintentar
          <b-link @click="query.refetch()">aqui.</b-link>
        </span>
        <b-spinner label="Spinning" v-else-if="isLoading && !data"></b-spinner>

        <span v-else-if="data && isLoading" class="m-2 float-right">
          Actualizando historial de procesos y sincronizaciones...
        </span>

        <b-table-simple responsive v-if="data && data.allAsyncTaskLogs">
          <b-thead>
            <b-tr>
              <b-th>Inicio</b-th>
              <b-th>Ultima actualización</b-th>
              <b-th>Tarea</b-th>
              <b-th>Origen</b-th>
              <b-th>Progreso</b-th>
              <b-th>Errores</b-th>
              <b-th>Estado</b-th>
              <b-th
                >Tiempo restante estimado <br />
                (hrs:min:seg)</b-th
              >
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-if="!data.allAsyncTaskLogs.edges.length">
              <b-td colspan="9">
                -- no hay procesos o sincronizaciones en la ultima semana --
              </b-td>
            </b-tr>
            <synchronization-table-row
              v-for="history of data.allAsyncTaskLogs.edges"
              :key="history.node.id"
              :synchronization="history.node"
              :current-user="currentUser"
            />
          </b-tbody>
        </b-table-simple>

        <b-spinner
          v-if="isLoading"
          label="Spinning"
          class="m-2 float-right"
        ></b-spinner>
        <div v-else>
          <b-button
            v-if="hasNextPage(data)"
            class="m-2 float-right"
            @click="seeMore(query, data.allAsyncTaskLogs.pageInfo.endCursor)"
          >
            Ver más
          </b-button>
          <b-alert v-else show variant="light" class="text-center">
            No hay más datos para mostrar.
          </b-alert>
        </div>
      </template>
    </ApolloQuery>
  </div>
</template>

<script>
import SynchronizationTableRow from "./SynchronizationTableRow.vue";
import { mapState } from "vuex";
export default {
  name: "SynchronizationTable",
  components: {
    SynchronizationTableRow
  },
  props: {
    origin: String,
    reRender: Number
  },
  data() {
    return {
      pollInterval: 0,
      firstId: null
    };
  },
  computed: {
    ...mapState(["currentUser"])
  },
  methods: {
    /**
     * Indica si hay una página siguiente en la query de GraphQl basado
     * exclusivamente en la existencia de un cursor.
     * @return {Boolean}
     */
    hasNextPage(data) {
      return data?.allAsyncTaskLogs?.pageInfo?.endCursor != null;
    },
    /**
     * Trae más resultados de una query avanzando en su cursor.
     * @param {Object} query
     * @param {String} cursor
     */
    async seeMore(query, cursor) {
      await query.fetchMore({
        variables: this.queryVariables(cursor),
        updateQuery: (prev, { fetchMoreResult }) => {
          const updated = Object.assign({}, this.$dup(prev));
          updated.allAsyncTaskLogs.pageInfo =
            fetchMoreResult.allAsyncTaskLogs.pageInfo;
          if (fetchMoreResult?.allAsyncTaskLogs?.edges?.length !== 0) {
            updated.allAsyncTaskLogs.edges.push(
              ...fetchMoreResult.allAsyncTaskLogs.edges
            );
          }
          return updated;
        }
      });
    },
    /**
     * Se encarga crear el objeto de las variables para la query
     * @param {String} cursor
     * @param {Object} variables
     * @return {Object}
     */
    queryVariables(cursor, variables = {}) {
      if (cursor) {
        variables.cursor = cursor;
      }
      variables.first = 20;
      variables.type = this.origin;

      return variables;
    },
    /**
     * Revisa si se actualizo la lista de tareas, si lo hizo deja de hacer las consultas
     * @param {Object} result - respuesta de query
     */
    checkResult(result) {
      if (result?.data?.allAsyncTaskLogs) {
        let newId = result.data.allAsyncTaskLogs?.edges[0]?.node?.id;
        if (newId !== this.firstId) {
          this.firstId = newId;
          this.pollInterval = 0;
        }
      }
    }
  },
  watch: {
    reRender() {
      this.pollInterval = 1000;
    }
  }
};
</script>

<style></style>
