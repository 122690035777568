<template>
  <div>
    <b-row>
      <b-col>
        <b-table-simple hover small responsive>
          <b-thead>
            <b-tr>
              <b-th
                v-for="attribute of attributes"
                :key="attribute.id"
                :variant="isMainAttribute(attribute) ? 'info' : 'white'"
                style="min-width:100px"
                >{{ requiredAttribute(attribute) ? "*" : "" }}
                {{ attribute.name }}{{ prettyAttributeUnit(attribute) }}
              </b-th>
              <b-th></b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="(row, index) of rows" :key="index">
              <b-td
                v-for="attribute of attributes"
                :key="'col_' + attribute.id"
                :variant="isMainAttribute(attribute) ? 'info' : 'white'"
              >
                <b-form-group>
                  <v-select
                    :id="'a' + attribute.id"
                    v-if="attribute.values && attribute.values.length"
                    :multiple="attribute.multivalued === true"
                    :options="
                      attribute.values.map(x => {
                        return { label: x.name, value: x.id };
                      })
                    "
                    :reduce="x => x.value"
                    v-model="rows[index][attribute.id]"
                    @input="emitChange"
                  ></v-select>
                  <b-input
                    :id="'a' + attribute.id"
                    v-else
                    v-model="rows[index][attribute.id]"
                    @input="emitChange"
                    :disabled="
                      isMainAttribute(attribute) && editMode && row.id != null
                    "
                    :state="currentStateAttribute(attribute, index)"
                    :title="
                      isMainAttribute(attribute)
                        ? 'Este atributo no se puede editar. Es el atributo principal'
                        : ''
                    "
                  >
                  </b-input>
                  <b-form-invalid-feedback
                    :state="
                      !!rows[index][attribute.id] &&
                        !!rows[index][attribute.id].length
                    "
                    v-if="
                      (requiredAttribute(attribute) &&
                        (!rows[index][attribute.id] ||
                          !rows[index][attribute.id].length)) ||
                        isMainAttribute(attribute)
                    "
                  >
                    Este atributo es obligatorio
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-td>
              <b-td>
                <b-link @click="deleteRow(index)" v-if="!row.id">
                  Eliminar
                </b-link>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-col>
    </b-row>
    <b-form-group>
      <b-button variant="outline-primary" @click="addRow">
        <b-icon-plus></b-icon-plus> Agregar fila</b-button
      >
      <b-form-invalid-feedback
        :state="!!rows && !!rows.length"
        v-if="!rows || !rows.length"
      >
        Debes agregar al menos una fila
      </b-form-invalid-feedback>
    </b-form-group>
  </div>
</template>
<script>
export default {
  name: "SizeChartMercadoLibreFormAttributeRows",
  model: {
    prop: "_value",
    event: "change"
  },
  mounted() {
    this.$emit("change", this.rowsWithUnits);
  },
  props: {
    attributes: {
      type: Array,
      required: true
    },
    mainAttribute: {
      type: String,
      default: null
    },
    _value: {
      type: Array,
      default() {
        return [];
      }
    },
    oldRows: {
      type: Array,
      default: null
    },
    editMode: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      rows: this.$dup(this._value)
    };
  },
  computed: {
    /**
     * Agrega unidades de medida a los valores de las filas de la GDT
     * @returns {Array<Object>}
     */
    rowsWithUnits() {
      let ans = [];
      this.rows.forEach(row => {
        let aux = this.$dup(row);
        this.attributes.forEach(attr => {
          if (aux[attr.id] && attr.defaultUnitId) {
            aux[attr.id] = aux[attr.id] + " " + attr.defaultUnitId;
          }
        });
        ans.push(aux);
      });
      return ans;
    }
  },
  methods: {
    /**
     * Agrega una nueva fila a la GDT
     */
    addRow() {
      this.rows.push({});
      this.$emit("change", this.rowsWithUnits);
    },
    /**
     * Elimina una fila de la GDT
     * @param {Number} index
     */
    deleteRow(index) {
      this.rows.splice(index, 1);
      this.$emit("change", this.rowsWithUnits);
    },
    /**
     * Verifica si un atributo es obligatorio o no
     * @param {Object} attribute
     * @returns {Boolean}
     */
    requiredAttribute(attribute) {
      return !!this.$ifNull(attribute.tags, []).find(x => x == "required");
    },
    /**
     * Emite cambios realizados en las filas de la GDT
     */
    emitChange() {
      this.$emit("change", this.rowsWithUnits);
    },
    /**
     * Verifica el estado actual del atributo,
     * si tiene que ser completado o no
     * Basta con que el atributo sea el principal o que sea un atributo obligatorio
     * @param {Object} attribute
     * @param {Number} index
     * @returns {Boolean}
     */
    currentStateAttribute(attribute, index) {
      return !this.requiredAttribute(attribute) &&
        !this.isMainAttribute(attribute)
        ? null
        : !!this.rows[index][attribute.id] &&
            !!this.rows[index][attribute.id].length;
    },
    /**
     * Entrega unidad de medida de un atributo de una fila de la GDT
     * se verifica que el nombre sea distinto a la unidad para no repetir
     * en el caso de tallas por pais
     * @param {Object} attribute
     * @returns {String}
     */
    prettyAttributeUnit(attribute) {
      let unit = attribute.defaultUnitId;
      if (!unit) return "";
      let name = attribute.name;
      if (!name.includes(unit)) {
        return ` [${unit}]`;
      }
      return "";
    },
    /**
     * Verifica si atributo es el principal para la tabla de GDT
     * @param {Object} attribute
     * @returns {Boolean}
     */
    isMainAttribute(attribute) {
      return attribute.id == this.mainAttribute;
    }
  },
  watch: {
    attributes() {
      this.rows = [];
    }
  }
};
</script>
