<template>
  <div>
    <base-header
      title="Promociones de Mercado Libre"
      :title-link="titleLink"
      :map="[
        'Mercado Libre',
        'Integraciones',
        'Promociones',
        'Oferta relámpago'
      ]"
    ></base-header>

    <mercado-libre-promotion-items
      v-model="meliItems"
      :integration-config-id="integrationConfigId"
      :deal-id="lightningId"
      deal-type="LIGHTNING"
      @loading="value => (loadingItems = value)"
      @cursor="value => (cursor = value)"
      ref="items"
    />
    <div v-if="!loadingItems || items.length">
      <b-container fluid>
        <b-row>
          <b-col cols="2"> </b-col>
          <b-col cols="3"></b-col>
          <b-col cols="3"></b-col>
          <b-col cols="2"></b-col>
          <b-col cols="2">
            <b-button @click="sendProducts()">
              <b-icon-check-circle></b-icon-check-circle>
              Guardar
            </b-button>
          </b-col>
        </b-row>
        <br />
      </b-container>

      <b-row>
        <b-col cols="12">
          <base-j-excel-table
            v-model="items"
            :columns="columns"
            :on-selection="this.onSelection"
            :allow-insert-row="false"
            :allow-insert-column="false"
            :custom-on-change="this.onChange"
            :allow-search="true"
          ></base-j-excel-table>
          <b-button v-if="!!cursor" :disabled="!!loadingItems" @click="seeMore"
            >Ver más</b-button
          >
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import INTEGRATION_CONFIG_MERCADO_LIBRE from "../graphql/IntegrationConfigMercadoLibre.gql";
import MERCADO_LIBRE_DELETE_PROMOTION_GENERIC from "../graphql/MercadoLibreDeletePromotionGeneric.gql";
import CREATE_MERCADO_LIBRE_PROMOTION from "../graphql/MercadoLibreSetPromotionLightningItems.gql";
import BaseHeader from "../components/BaseHeader.vue";
import BaseJExcelTable from "@/components/BaseJExcelTable";
import MercadoLibrePromotionItems from "@/components/MercadoLibrePromotionItems";
import { mapState } from "vuex";
import jexcel from "jexcel";

export default {
  computed: {
    ...mapState(["selectedProducts"]),
    titleLink: {
      get: function() {
        return `/mercadolibre/promotions/${this.integrationConfigId}`;
      }
    }
  },
  name: "MercadoLibrePromotionsLightningIndex",
  props: {
    integrationConfigId: {
      type: String,
      required: true
    },
    lightningId: {
      type: String,
      required: true
    }
  },
  components: {
    BaseHeader,
    BaseJExcelTable,
    MercadoLibrePromotionItems
  },
  data() {
    return {
      // selectedProducts: [],
      cursor: true,
      productsToUse: false,
      integrationConfigMeli: {},
      meliItems: [],
      items: [],
      loadingItems: [],
      originalItems: [],
      columns: [
        { type: "checkbox", title: "Enviar?" },
        { type: "text", title: "Producto", width: 240, readOnly: true },
        { type: "text", title: "SKU", width: 120, readOnly: true },
        {
          type: "numeric",
          title: "Precio Base",
          width: 120,
          mask: "$ #.##0,0",
          decimal: ",",
          readOnly: true
        },
        {
          type: "numeric",
          title: "Precio Oferta",
          width: 120,
          mask: "$ #.##0,0",
          decimal: ","
        },
        // {type: "numeric", title: "Stock", width: 120},
        {
          type: "dropdown",
          title: "Stock",
          width: 120,
          source: [
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10,
            11,
            12,
            13,
            14,
            15,
            16,
            17,
            18,
            19,
            20,
            21,
            22,
            23,
            24,
            25,
            26,
            27,
            28,
            29,
            30,
            31,
            32
          ],
          filter: this.dropdownFilter
        },
        {
          type: "numeric",
          title: "Stock Restante",
          width: 120,
          readOnly: true
        },
        { type: "text", title: "Fecha de Inicio", width: 200, readOnly: true },
        { type: "text", title: "Fecha de Término", width: 200, readOnly: true },
        { type: "text", title: "Estado", width: 120, readOnly: true },
        { type: "html", title: "Acciones", width: 120 }
      ],
      ACTIVE: 0,
      ITEM_NAME: 1,
      ITEM_ID: 2,
      BASE_PRICE: 3,
      OFFER_PRICE: 4,
      STOCK: 5,
      REMAINING_STOCK: 6,
      START_DATE: 7,
      FINISH_DATE: 8,
      STATUS: 9,
      ACTIONS: 10
    };
  },
  beforeMount() {
    this.getIntegrationConfig();
  },
  methods: {
    onChange(instance, cell, x, y, value, jExcelInstance) {
      jExcelInstance.ingoreEvents = false;
      const oldValue = this.originalItems[y][x];
      if (value !== oldValue && x != 0) {
        jExcelInstance.setStyle(
          jexcel.getColumnNameFromId([x, y]),
          "font-weight",
          "bold",
          true
        );
        jExcelInstance.ignoreEvents = true;
        jExcelInstance.ignoreHistory = true;
        jExcelInstance.setValueFromCoords(0, y, 1);
        jExcelInstance.ignoreEvents = false;
        jExcelInstance.ignoreHistory = false;
      } else
        jExcelInstance.setStyle(
          jexcel.getColumnNameFromId([x, y]),
          "font-weight",
          "inherit",
          true
        );
      if (!this.changed) this.changed = true;
    },
    getIntegrationConfig() {
      this.$apollo
        .query({
          query: INTEGRATION_CONFIG_MERCADO_LIBRE,
          variables: { id: this.integrationConfigId }
        })
        .then(result => {
          this.integrationConfigMeli =
            result.data.integrationConfigMercadoLibre;
        });
    },
    /**
     * Carga una página más de ítemes
     */
    seeMore() {
      this.$refs.items.getItems();
    },
    async deleteItem(itemId, itemIndex) {
      this.$apollo
        .mutate({
          mutation: MERCADO_LIBRE_DELETE_PROMOTION_GENERIC,
          variables: {
            item_id: itemId,
            integration_config_id: this.integrationConfigId,
            params: JSON.stringify({ promotion_type: "LIGHTNING" })
          }
        })
        .then(result => {
          console.log(result.data.mercadoLibreDeletePromotionGeneric.result);
          if (
            result.data.mercadoLibreDeletePromotionGeneric.result == "200" ||
            result.data.mercadoLibreDeletePromotionGeneric.result == 200
          ) {
            this.items.splice(itemIndex, 1);
          }
        });
    },
    addNewItems(itemsArray) {
      // eslint-disable-next-line no-debugger
      // debugger

      itemsArray.forEach(item => {
        this.items.push([
          1,
          item.producto,
          item.sku,
          item.original_price,
          item.price,
          this.$options.filters.meliToCentry(item.status),
          "Cancelar"
        ]);
      });
    },
    async onSelection(instance, x1, y1, x2, y2) {
      if (x1 === x2 && y1 === y2 && x1 === this.ACTIONS) {
        if (confirm("El item sera eliminado")) {
          // Activar siguiente linea cuando este funcionando la llamada a la api de mercado libre para eliminar un item
          await this.deleteItem(this.items[y1][this.ITEM_ID], y1);
        }
      }
    },
    sendProducts() {
      for (let i = 0; i < this.$data.items.length; i++) {
        if (this.$data.items[i][this.ACTIVE] == 1) {
          this.$apollo
            .mutate({
              mutation: CREATE_MERCADO_LIBRE_PROMOTION,
              variables: {
                items: this.$data.items[i][this.ITEM_ID],
                price: parseInt(
                  String(this.$data.items[i][this.BASE_PRICE]).replace(
                    /\D/g,
                    ""
                  )
                ),
                deal_price: parseInt(
                  String(this.$data.items[i][this.OFFER_PRICE]).replace(
                    /\D/g,
                    ""
                  )
                ),
                integration_config_id: this.integrationConfigId,
                stock: parseInt(this.$data.items[i][this.STOCK])
              }
            })
            .then(result => {
              const item_result = JSON.parse(
                result.data.mercadoLibreSetPromotionLightningItems.result
              );

              if (item_result.original_price != null) {
                // const item_result =
                //   result.data.mercadoLibreSetPromotionLightningItems.result;
                this.items.push([
                  0,
                  this.$data.items[i][this.ITEM_NAME],
                  this.$data.items[i][this.ITEM_ID],
                  item_result.original_price,
                  item_result.price,
                  "",
                  this.$data.items[i][this.STOCK],
                  "",
                  "",
                  "",
                  "Eliminar"
                ]);
                var spreadsheet = document.getElementById("spreadsheet");
                spreadsheet.jexcel.refresh();
              }
            });
        }
      }
    },
    checkAll: function(e) {
      for (let i = 0; i < this.items.length; i++) {
        this.items[i][this.ACTIVE] = e.target.checked;
      }
      var spreadsheet = document.getElementById("spreadsheet");
      spreadsheet.jexcel.refresh();
    },
    dropdownFilter: function(instance, cell, c, r, source) {
      console.log(instance, cell, c, r, source);
      const stockRange = [];
      if (this.meliItems[parseInt(r)].stock.remaining_stock != undefined) {
        return [this.meliItems[parseInt(r)].stock.remaining_stock];
      }
      for (
        let i = this.meliItems[parseInt(r)].stock.min;
        i <= this.meliItems[parseInt(r)].stock.max;
        i++
      ) {
        stockRange.push(i);
      }
      return stockRange;
    },
    /**
     * Recibe los items de la promoción y realiza la transformación de datos
     * para que se muestren en la tabla
     * @param {Array<Object>} meliItems
     */
    addItemsToJExcel(meliItems) {
      this.items = [];
      this.originalItems = [];
      meliItems.forEach(item => {
        let itemData = [
          0,
          item.productTitle,
          item.id,
          item.originalPrice,
          item.price,
          "",
          item.stock.remainingStock,
          item.startDate,
          item.finishDate,
          this.$options.filters.meliToCentry(item.status),
          "Eliminar"
        ];
        this.items.push(itemData);
        this.originalItems.push(this.$dup(itemData));
      });
    }
  },
  watch: {
    selectedProducts(newVal) {
      for (let j = 0; j < newVal.length; j++) {
        var found = false;
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i][this.ITEM_ID] == newVal[j].sku) {
            found = true;
          }
        }
        if (!found) {
          this.addNewItems([newVal[j]]);
        }
      }
    },
    meliItems(newVal) {
      this.addItemsToJExcel(newVal);
    }
  }
};
</script>

<style>
.jexcel_filter {
  display: block;
}
</style>
