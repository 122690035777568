<template>
  <div>
    <product-image-manager-page
      :productId="productId"
      :filters="filters"
    ></product-image-manager-page>
  </div>
</template>
<script>
import ProductImageManagerPage from "../components/ProductImageManager/ProductImageManagerPage.vue";
export default {
  name: "ProductImageManager",
  props: {
    productId: {
      type: String,
      default: ""
    },
    filters: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  components: {
    ProductImageManagerPage
  },
  data() {
    return {
      product: null
    };
  },
  mounted() {},
  methods: {}
};
</script>
