<template>
  <div>
    <base-alert-with-count-down
      v-model="download"
      v-if="download"
      class="mx-n3"
      :alert-variant="success == true ? 'success' : 'danger'"
      dismissible
    >
      {{
        success
          ? "La planilla se enviará a tu correo una vez esté lista."
          : "Hubo un error al solicitar la descarga."
      }}
    </base-alert-with-count-down>
    <base-header title="Pedidos" title-link="#">
      <template v-slot:actions>
        <b-container>
          <b-button v-on:click="ordersToExcel">
            <b-icon-file-earmark-arrow-down></b-icon-file-earmark-arrow-down>
            Exportar
          </b-button>
        </b-container>
      </template>
      <template v-slot:filters>
        <orders-masive-actions
          :config-buttons="configButtons"
        ></orders-masive-actions>
      </template>
    </base-header>
    <b-collapse id="collapse-order-settings">
      <b-card>
        <b-row>
          <b-col cols="12">
            <base-local-storage-options
              class="ml-2"
              :configButtons="configButtons"
              :options="buttonsOptions"
              :keyLabel="configButtonsLabel"
              title="Modificar botones de acciones para mostrar"
              @change="key => changeConfigButtons(configButtons, key)"
            >
            </base-local-storage-options>
          </b-col>
          <b-col cols="12">
            <base-local-storage-options
              class="ml-2"
              :configButtons="configColumns"
              :options="columnsOptions"
              :keyLabel="configColumnsLabel"
              title="Modificar columnas para mostrar"
              @change="key => changeConfigButtons(configColumns, key)"
            >
            </base-local-storage-options>
          </b-col>
        </b-row>
      </b-card>
    </b-collapse>
    <b-container fluid="">
      <b-row>
        <b-col md="12">
          <orders-table
            v-model="params"
            :configColumns="configColumns"
          ></orders-table>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import BaseHeader from "../components/BaseHeader.vue";
import OrdersTable from "../components/OrdersTable.vue";
import ORDERS_TO_EXCEL from "../graphql/OrdersToExcel.gql";
import OrdersMasiveActions from "@/components/order/OrderMasiveActions";
import BaseLocalStorageOptions from "@/components/BaseLocalStorageOptions";
import BaseAlertWithCountDown from "../components/Base/BaseAlertWithCountDown.vue";
import { mapState, mapMutations } from "vuex";

export default {
  name: "Orders",
  components: {
    OrdersMasiveActions,
    BaseHeader,
    OrdersTable,
    BaseLocalStorageOptions,
    BaseAlertWithCountDown
  },
  data() {
    return {
      download: false,
      success: null,
      params: {
        number: null,
        ids: [],
        status: [],
        origin: [],
        create_from: null,
        create_to: null,
        sku: null,
        shippingLabelPrint: null,
        buyers: [],
        numbers: [],
        skus: [],
        statuses: [],
        origins: [],
        createFrom: null,
        createTo: null,
        order_type: "",
        priorityShipping: null
      },
      configColumns: {
        client: true,
        dateForDelivery: true,
        shippingLabel: true,
        total: true,
        items: true,
        modifyStock: true,
        priorityShipping: true
      },
      configButtons: {
        showShippingLabel: true,
        showManifestButton: false,
        showDetailsButton: false
      },
      buttonsOptions: [
        { text: "Imprimir Etiquetas", value: "showShippingLabel" },
        { text: "Descargar Manifiestos", value: "showManifestButton" },
        { text: "Descargar Detalles", value: "showDetailsButton" }
      ],
      columnsOptions: [
        { text: "Cliente", value: "client" },
        { text: "Compromiso de entrega", value: "dateForDelivery" },
        { text: "Etiqueta impresa", value: "shippingLabel" },
        { text: "Total", value: "total" },
        { text: "Items", value: "items" },
        { text: "Fulfillment", value: "modifyStock" },
        { text: "Envio prioritario", value: "priorityShipping" }
      ],
      configButtonsLabel: "orderButtons",
      configColumnsLabel: "columnsbuttons"
    };
  },
  computed: {
    ...mapState(["selectedOrders"])
  },
  mounted() {
    if (localStorage[this.configButtonsLabel]) {
      this.configButtons = JSON.parse(localStorage[this.configButtonsLabel]);
    }
    if (localStorage["columnsbuttons"]) {
      this.configColumns = JSON.parse(localStorage["columnsbuttons"]);
    }
  },
  methods: {
    ...mapMutations(["setFilter"]),
    ordersToExcel() {
      var ids = [];
      var numbersString = "";
      var skus = "";
      var buyer = "";
      var shipping_label_print = [];
      if (this.params.numbers.length != 0) {
        for (var number in this.params.numbers) {
          numbersString += String(this.params.numbers[number]);
          numbersString += ",";
        }
      }
      if (this.params.skus.length != 0) {
        for (var sku in this.params.skus) {
          skus += this.params.skus[sku];
          skus += ",";
        }
      }
      if (this.params.buyers.length != 0) {
        for (var client in this.params.buyers) {
          buyer += this.params.buyers[client];
          buyer += ",";
        }
      }
      if (this.params.shippingLabelPrint) {
        shipping_label_print.push("1");
      } else if (this.params.shippingLabelPrint == false) {
        shipping_label_print.push("0");
      }
      Object.entries(this.selectedOrders).forEach(order => {
        if (order[1].selected) {
          ids.push(order[0]);
        }
      });
      this.$apollo
        .mutate({
          mutation: ORDERS_TO_EXCEL,
          variables: {
            params: {
              ids: ids,
              number: numbersString,
              status: this.params.statuses,
              origin: this.params.origins,
              createFrom: this.params.createFrom,
              createTo: this.params.createTo,
              sku: skus,
              shippingLabelPrint: shipping_label_print,
              buyer: buyer,
              mode: "custom",
              orderType: this.params.order_type,
              priorityShipping: this.params.priorityShipping
            }
          }
        })
        .then(result => {
          this.download = true;
          this.success = result.data.ordersToExcel.result;
        });
    },
    /**
     * Actualiza el valor de la key ingresada
     * @method
     * @param {string} key - Tipo de boton que cambio de estado.
     */
    changeConfigButtons(hash, key) {
      hash[key] = !hash[key];
    },
    /** Limpia todos los filtros
     */
    async cleanFilters() {
      await this.setFilter({
        numbers: [],
        skus: [],
        statuses: [],
        origins: [],
        createFrom: null,
        createTo: null,
        shippingLabelPrint: null,
        buyers: [],
        order_type: ""
      });
    }
  },
  beforeRouteLeave(to, _from, next) {
    if (to.name != "Order") {
      this.cleanFilters();
      next();
    } else {
      next();
    }
  }
};
</script>
