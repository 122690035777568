<template>
  <div>
    <b-input @input="updateModel" v-model="value"></b-input>
    <b-alert v-model="error" variant="danger">No es un JSON válido</b-alert>
  </div>
</template>
<script>
export default {
  name: "BaseJsonInput",
  model: {
    prop: "_value",
    event: "change"
  },
  props: {
    _value: Object
  },
  data() {
    return {
      value: this._value ? JSON.stringify(this._value) : null,
      error: false
    };
  },
  methods: {
    updateModel(input) {
      let val = null;
      try {
        if (input.length) {
          val = JSON.parse(input);
        }
        this.error = false;
      } catch (error) {
        this.error = true;
      }
      this.$emit("change", val);
    }
  }
};
</script>
