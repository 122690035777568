<template>
  <div>
    <base-header
      title="Moderaciones"
      title-link="/moderations"
      :map="[]"
    ></base-header>
    <b-container fluid>
      <b-row>
        <b-button
          @click="unpublish"
          variant="outline-info"
          style="margin-left: 80%"
          size="sm"
          :disabled="disabled"
        >
          Despublicar
        </b-button>
        <b-col md="12">
          <ApolloQuery :query="require('../graphql/ModerationListCount.gql')">
            <template slot-scope="{ result: { data }, isLoading }">
              <b-spinner v-if="isLoading" label="Spinning"></b-spinner>
              <span class="text-secondary"
                >Total de productos con problemas:
                {{ data.moderationListCount }}</span
              >
            </template>
          </ApolloQuery>

          <ApolloQuery :query="require('../graphql/AllModerationList.gql')">
            <template slot-scope="{ result: { data }, isLoading }">
              <b-spinner v-if="isLoading" label="Spinning"></b-spinner>
              <b-table-simple v-else hover>
                <b-thead>
                  <b-tr>
                    <b-th></b-th>
                    <b-th>Nombre</b-th>
                    <b-th>SKU</b-th>
                    <b-th>Problemas</b-th>
                    <b-th></b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr
                    v-for="productModeration of data.allModerationList"
                    :key="productModeration.product.id"
                  >
                    <b-td>
                      <input
                        v-if="!productModeration.unpublished"
                        type="checkbox"
                        class="mx-2 mb-2"
                        :id="'checkbox-' + productModeration.product.id"
                        @change="
                          checkStatus($event, productModeration.product.id)
                        "
                    /></b-td>
                    <b-td>{{ productModeration.product.name }}</b-td>
                    <b-td>{{ productModeration.product.sku }}</b-td>
                    <b-td
                      >{{ problemString(productModeration.moderationCount) }} en
                      {{
                        integrationString(
                          productModeration.integrationConfigCount
                        )
                      }}</b-td
                    >
                    <b-td class="actioncell">
                      <b-dropdown variant="white" no-caret>
                        <template v-slot:button-content>
                          <b-icon-three-dots-vertical></b-icon-three-dots-vertical>
                        </template>
                        <b-dropdown-item
                          :to="
                            '/moderations/' +
                              productModeration.product.id +
                              '/details'
                          "
                          >Detalle</b-dropdown-item
                        >
                      </b-dropdown>
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </template>
          </ApolloQuery>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import BaseHeader from "../components/BaseHeader.vue";
import UNPUBLISH_MODERATION from "../graphql/MercadoLibre/Moderations/DeleteItemsPublications.gql";

export default {
  name: "Moderations",
  components: {
    BaseHeader
  },
  data() {
    return {
      moderationsStatus: {},
      disabled_val: true
    };
  },
  computed: {
    /**
     * Decide si deshabilitar todos los elementos de la tabla de cajas
     * @return {Boolean}
     */
    disabled() {
      return this.disabled_val;
    }
  },
  methods: {
    /**
     * Actualiza los estados del listado de checkboxs
     */
    checkStatus(e, product_id) {
      if (this.moderationsStatus[product_id] == undefined) {
        this.moderationsStatus[product_id] = true;
        this.disabled_val = false;
      } else if (this.moderationsStatus[product_id] == false) {
        this.moderationsStatus[product_id] = true;
        this.disabled_val = false;
      } else {
        this.moderationsStatus[product_id] = false;
        let r = true;
        Object.values(this.moderationsStatus).forEach(v => {
          r = r && !v;
        });
        this.disabled_val = r;
      }
    },
    /**
     * Esconde los checkbox ya usados
     */
    hide(pid) {
      var x = document.getElementById("checkbox-" + pid);
      if (x.style.display === "none") {
        x.style.display = "block";
      } else {
        x.style.display = "none";
      }
    },
    /**
     * Se encarga de despublicar un producto de mercado libre en las integraciones que poseean problemas
     */
    async unpublish() {
      let statuses = Object.keys(this.moderationsStatus);
      statuses = statuses.filter(
        moderation => this.moderationsStatus[moderation]
      );
      await this.$apollo
        .mutate({
          mutation: UNPUBLISH_MODERATION,
          variables: {
            products: statuses,
            async: false
          }
        })
        .then(data => {
          if (data.data.mercadoLibreDeleteItemsPublications.result == true) {
            statuses.forEach(s => this.hide(s));
            this.moderationsStatus = {};
            this.disabled_val = true;
            this.$swal.fire({
              title: "Productos enviados a despublicar",
              text: "Productos enviados a despublicar de forma exitosa",
              icon: "success",
              confirmButtonText: "Aceptar"
            });
          } else {
            this.$swal.fire({
              title: "Error",
              text: "Hubo un error al despublicar los productos",
              icon: "error",
              confirmButtonText: "Aceptar"
            });
          }
        })
        .catch(() => {
          this.$swal.fire({
            title: "Error",
            text: "Hubo un error al despublicar los productos",
            icon: "error",
            confirmButtonText: "Aceptar"
          });
        });
    },
    problemString(count) {
      return count === 1 ? "1 problema" : count + " problemas";
    },
    integrationString(count) {
      return count === 1 ? "1 integración" : count + " integraciones";
    }
  }
};
</script>
