<template>
  <div class="float-right">
    <b-input-group>
      <b-input-group-prepend is-text>
        <b-icon-search></b-icon-search>
      </b-input-group-prepend>

      <b-form-input
        v-model="name"
        placeholder="Nombre"
        @keydown.native.enter="updateName"
      ></b-form-input>
    </b-input-group>
    <br />
    <b-button v-if="activateCleanSearch" @click="cleanSearch">
      Limpiar búsqueda
    </b-button>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";

export default {
  name: "SizeChartsFilter",
  data() {
    return {
      name: "",
      lastSearch: ""
    };
  },
  computed: {
    ...mapState(["filters"]),
    activateCleanSearch() {
      let notEmptyName = this.name || !this.name === "";
      let notEmptyLastSearch = this.lastSearch || !this.lastSearch === "";
      return notEmptyName && notEmptyLastSearch;
    }
  },
  methods: {
    ...mapMutations(["setFilter"]),
    /**
     * Actualiza filtro de busqueda
     */
    updateName() {
      this.lastSearch = this.name;
      this.setFilter({ name: this.name });
    },
    /**
     * Limpia filtro de busqueda
     */
    cleanSearch() {
      this.name = "";
      this.updateName();
    }
  },
  watch: {
    filters(newValue, oldValue) {
      const oldName = oldValue.name;
      const newName = newValue.name;
      if (oldName !== newName && this.name !== newName) {
        this.name = newName;
      }
    }
  }
};
</script>
