<template>
  <div v-if="!!dashboard">
    <base-alert-with-count-down
      v-model="download"
      v-if="download"
      class="mx-n3"
      :alert-variant="success == true ? 'success' : 'danger'"
      dismissible
    >
      {{
        success
          ? "La planilla se enviará a tu correo una vez esté lista."
          : "Hubo un error al solicitar la descarga."
      }}
    </base-alert-with-count-down>
    <base-header :title="dashboard.name" title-link="#">
      <template v-slot:filters>
        <b-form-group v-if="dashboard.exportExcel">
          <b-button v-on:click="salesToExcel">
            <b-spinner v-if="loading" />
            <b-icon-file-earmark-arrow-down v-else />
            Exportar excel tributario
          </b-button>
          <b-form-invalid-feedback :state="false"
            >Se considerará solo el período
            seleccionado.</b-form-invalid-feedback
          >
        </b-form-group>
      </template>
    </base-header>
    <b-container fluid="">
      <div v-if="dashboard.showFilters">
        <products-sales-report-filters
          v-if="allIntegrationConfigs && allCategories && allBrands"
          :integration-configs="allIntegrationConfigs"
          :categories="allCategories"
          :brands="allBrands"
        ></products-sales-report-filters>
        <BSpinner v-else variant="Spinning"></BSpinner>
      </div>

      <b-row v-if="token">
        <b-col
          v-for="widget of orderWidgets(dashboard.widgets)"
          :key="widget.id"
          :md="widget.size"
          class="mb-4"
        >
          <dashboard-number
            v-if="widget._type === 'Reports::Dashboard::NumberWidget'"
            :widget="widget"
            :token="token"
            :integration-configs="allIntegrationConfigs"
          />
          <dashboard-pie
            v-else-if="widget._type === 'Reports::Dashboard::PieWidget'"
            :widget="widget"
            :token="token"
            :integration-configs="allIntegrationConfigs"
          />
          <dashboard-time-serie
            v-else-if="widget._type === 'Reports::Dashboard::TimeSerieWidget'"
            :widget="widget"
            :token="token"
            :integration-configs="allIntegrationConfigs"
          />
          <dashboard-table
            v-else-if="widget._type === 'Reports::Dashboard::TableWidget'"
            :widget="widget"
            :token="token"
            :integration-configs="allIntegrationConfigs"
          />
          <dashboard-table-with-centry-data
            v-else-if="
              widget._type === 'Reports::Dashboard::SyncStatusWidget' ||
                widget._type === 'Reports::Dashboard::UnshippedOrdersWidget'
            "
            :widget="widget"
          />
          <dashboard-webhooks
            v-else-if="widget._type === 'Reports::Dashboard::WebhooksWidget'"
            :widget="widget"
          />
          <dashboard-number-centry-data
            v-else-if="
              widget._type === 'Reports::Dashboard::ActiveIntegrationsWidget' ||
                widget._type === 'Reports::Dashboard::ActiveProductsWidget' ||
                widget._type === 'Reports::Dashboard::ActiveUsersWidget'
            "
            :widget="widget"
          />
        </b-col>
      </b-row>
      <b-spinner label="Spinning" v-else />
    </b-container>
  </div>
</template>

<script>
import BaseHeader from "../components/BaseHeader.vue";
import DashboardPie from "@/components/dashboard/DashboardPie";
import DashboardTimeSerie from "@/components/dashboard/DashboardTimeSerie";
import DashboardTable from "@/components/dashboard/DashboardTable.vue";
import DashboardTableWithCentryData from "@/components/dashboard/TableWithCentryData.vue";
import DashboardNumberCentryData from "@/components/dashboard/NumberCentryData.vue";
import DashboardWebhooks from "@/components/dashboard/Webhooks.vue";
import INTEGRATION_CONFIGS_FOR_TABS from "../graphql/IntegrationConfigsForTabs.gql";
import ALL_CATEGORIES from "../graphql/AllCategories.gql";
import ALL_BRANDS from "../graphql/AllBrands.gql";
import DASHBOARD from "../graphql/Dashboard.gql";
import TOKEN_CUBE from "../graphql/TokenCubeJs.gql";
import ALL_WEBHOOKS from "../graphql/AllWebhooks.gql";
import SALES_TO_EXCEL from "../graphql/SalesToExcel.gql";
import ProductsSalesReportFilters from "@/components/ProductsSalesReportFilters";
import { mapState } from "vuex";
import DashboardNumber from "../components/dashboard/DashboardNumber.vue";
import BaseAlertWithCountDown from "../components/Base/BaseAlertWithCountDown.vue";

export default {
  name: "Dashboard",
  components: {
    DashboardPie,
    DashboardTimeSerie,
    DashboardTable,
    ProductsSalesReportFilters,
    DashboardNumber,
    BaseHeader,
    BaseAlertWithCountDown,
    DashboardTableWithCentryData,
    DashboardWebhooks,
    DashboardNumberCentryData
  },
  data() {
    return {
      periodData: {},
      allIntegrationConfigs: null,
      allCategories: null,
      allBrands: null,
      translations: null,
      comparePeriodData: {},
      success: null,
      download: false,
      loading: false,
      token: null,
      allowExport: true,
      allowFilter: true,
      dashboard: null
    };
  },
  mounted() {
    this.getDashboard().then(dashboard => {
      if (dashboard.showFilters) {
        this.getIntegrationConfigs();
        this.getCategories();
        this.getBrands();
      }
      if (this.haveWebhooksWidget(dashboard)) {
        this.addWebhooks(dashboard).then(() => {
          this.dashboard = dashboard;
        });
      } else {
        this.dashboard = dashboard;
      }
    });
    this.getToken();
  },
  computed: {
    ...mapState(["currentUser", "filters"])
  },
  methods: {
    /**
     * Expande los widgets de webhooks,
     * por cada widget de webhook, se itera sobre los webhooks y se
     * agrega un widget por cada webhook
     * @param {Object} dashboard
     * @returns {Promise}
     */
    addWebhooks(dashboard) {
      return this.$apollo
        .query({
          query: ALL_WEBHOOKS,
          variables: {
            companyId: this.currentUser.company.id
          }
        })
        .then(({ data }) => {
          if (data) {
            const webhooks = data.allWebhooks.edges.map(x => x.node);
            const widgets = [];
            dashboard.widgets.forEach(widget => {
              if (widget._type === "Reports::Dashboard::WebhooksWidget") {
                webhooks.forEach(webhook => {
                  const webhookWidget = this.$dup(widget);
                  webhookWidget.webhook = webhook;
                  widgets.push(webhookWidget);
                });
              } else {
                widgets.push(widget);
              }
            });
            dashboard.widgets = widgets;
          }
        });
    },
    /**
     * Verifica si el dashboard tiene un widget de webhooks
     * @param {Object} dashboard
     * @returns {Boolean}
     */
    haveWebhooksWidget(dashboard) {
      return dashboard.widgets.some(
        widget => widget._type === "Reports::Dashboard::WebhooksWidget"
      );
    },
    /**
     * Obtiene el dashboard
     * @returns {Promise}
     */
    getDashboard() {
      return this.$apollo
        .query({
          query: DASHBOARD,
          variables: {
            id: this.$route.params.dashboardId
          }
        })
        .then(({ data }) => {
          return data.dashboard;
        });
    },
    getToken() {
      this.$apollo
        .query({
          query: TOKEN_CUBE
        })
        .then(({ data }) => {
          if (data) {
            this.token = data.tokenCubeJs;
          }
        });
    },
    orderWidgets(widgets) {
      let sortArray = widgets.map(x => this.$dup(x));
      return sortArray.sort((a, b) => a.position - b.position);
    },
    getIntegrationConfigs() {
      return this.$apollo
        .query({
          query: INTEGRATION_CONFIGS_FOR_TABS
        })
        .then(({ data }) => {
          this.allIntegrationConfigs = this.integrationOptions(data);
        });
    },
    getCategories() {
      return this.$apollo
        .query({
          query: ALL_CATEGORIES,
          variables: {
            companyId: this.currentUser.company.id
          }
        })
        .then(({ data }) => {
          this.allCategories = this.categoryOptions(data);
        });
    },
    getBrands() {
      return this.$apollo
        .query({
          query: ALL_BRANDS,
          variables: {
            companyId: this.currentUser.company.id
          }
        })
        .then(({ data }) => {
          this.allBrands = this.brandOptions(data);
        });
    },
    integrationOptions(data) {
      if (!data || !data.allIntegrationConfigs) {
        return [];
      }
      return data.allIntegrationConfigs.map(x => {
        return { label: x.label, value: x.label };
      });
    },
    categoryOptions(data) {
      if (!data || !data.allCategories || !data.allCategories.edges) {
        return [];
      }
      return data.allCategories.edges.map(x => {
        return { label: x.node.name, value: x.node.id };
      });
    },
    brandOptions(data) {
      if (!data || !data.allBrands || !data.allBrands.edges) {
        return [];
      }
      return data.allBrands.edges.map(x => {
        return { label: x.node.name, value: x.node.id };
      });
    },
    /**
     * Llama la mutacion para descargar el excel tributario
     */
    salesToExcel() {
      this.loading = true;
      this.filters.endDate?.setHours(23, 59, 59);
      this.$apollo
        .mutate({
          mutation: SALES_TO_EXCEL,
          variables: {
            params: {
              createFrom: this.filters.startDate,
              createTo: this.filters.endDate
            }
          }
        })
        .then(result => {
          this.download = true;
          this.success = result.data.salesToExcel.result;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.success = false;
          this.download = true;
        });
    }
  }
};
</script>

<style scoped>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
