<template>
  <tinymce
    :id="id"
    v-model="value"
    @editorInit="initialize"
    @input="emitChange"
    ref="tm"
    :toolbar1="'bold italic bullist link code'"
    :plugins="['lists link code paste']"
    :other_options="options"
  ></tinymce>
</template>
<script>
export default {
  name: "BaseHtmlTextArea",
  model: {
    event: "change",
    prop: "_value"
  },
  props: {
    _value: String,
    id: String
  },
  data() {
    return {
      value: this._value,
      options: {
        initialValue: this._value,
        menubar: false,
        statusbar: false,
        min_height: 200
      }
    };
  },
  methods: {
    emitChange() {
      this.$emit("change", this.value);
    },
    initialize() {
      const editor = this.$refs.tm.editor;
      editor.setContent(this._value);
    }
  }
};
</script>
