<template>
  <div>
    <div v-for="note of order.orderNotes" :key="note.id">
      <p class="mb-0">
        <strong>
          {{ note.user.firstName }}
          {{ note.user.lastName }}
        </strong>
      </p>
      <p class="mb-0">{{ note.text }}</p>
      <p class="mb-0 text-right text-muted">
        {{ $timezoneDate(currentUser, note.createdAt) | formatDateTime }}
      </p>
      <pre></pre>
      <hr />
    </div>
    <b-row>
      <b-col>
        <b-form-input
          v-model="newNote"
          @keyup.enter="createOrderNote"
        ></b-form-input>
      </b-col>
      <b-col cols="12" md="auto">
        <b-button variant="primary" @click="createOrderNote">
          <b-icon-cursor></b-icon-cursor>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createOrderNote } from "../main";
export default {
  name: "OrderNotesForm",
  props: {
    order: Object
  },
  data() {
    return {
      newNote: ""
    };
  },
  computed: {
    ...mapState(["currentUser"])
  },
  methods: {
    async createOrderNote() {
      if (!this.newNote.trim()) {
        return;
      }
      const params = {
        orderId: this.order.id,
        userId: this.currentUser.id,
        text: this.newNote.trim()
      };
      await createOrderNote(this.$apollo, params)
        .then(async ({ data }) => {
          if (data && data.createOrderNote.note) {
            this.order.orderNotes.push(data.createOrderNote.note);
          }
        })
        .catch(() => {});
      this.newNote = "";
    }
  }
};
</script>
