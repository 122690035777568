<template>
  <b-card no-body class="m-2">
    <div class="m-2">
      <h4>
        <b> {{ section.label }} </b>
      </h4>
      <b-container>
        <mercado-libre-form-component
          :component="component"
          :input="input"
          v-for="component of section.components"
          :key="component.id"
          :previous="previous"
        />
      </b-container>
    </div>
  </b-card>
</template>

<script>
import MercadoLibreFormComponent from "./ComponentAttributes.vue";

export default {
  name: "MercadoLibreCatalogSuggestionFormSectionAttribute",
  components: {
    MercadoLibreFormComponent
  },
  props: {
    section: {
      type: Object,
      required: true
    },
    input: {
      type: Function,
      required: true
    },
    previous: {
      type: Object,
      required: false
    }
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {}
};
</script>
