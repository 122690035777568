<template>
  <div>
    <span class="text-dark mr-2" v-if="selectedOrdersCount > 0">
      {{ selectedOrdersCount }}
      {{ selectedOrdersCount | pluralize("pedido") }}
      {{ selectedOrdersCount | pluralize("seleccionado") }}
    </span>

    <br /><br />
    <div class="ml-auto">
      <order-web-hook-notify-button
        v-if="currentUser.role === 0 || sections.includes('notify')"
        :title="selectedOrdersCount > 0 ? '' : 'Selecciona algún pedido'"
        :disabled="!selectedOrdersCount"
        class="ml-2"
      ></order-web-hook-notify-button>
      <orders-shipping-label-button
        v-if="configButtons.showShippingLabel"
        :title="selectedOrdersCount > 0 ? '' : 'Selecciona algún pedido'"
        :disabled="!selectedOrdersCount"
        class="ml-2"
      ></orders-shipping-label-button>
      <orders-manifest-button
        v-if="configButtons.showManifestButton"
        :title="selectedOrdersCount > 0 ? '' : 'Selecciona algún pedido'"
        :disabled="!selectedOrdersCount"
        class="ml-2"
      ></orders-manifest-button>
      <orders-details-button
        v-if="configButtons.showDetailsButton"
        :title="selectedOrdersCount > 0 ? '' : 'Selecciona algún pedido'"
        :disabled="!selectedOrdersCount"
        class="ml-2"
      ></orders-details-button>
      <div class="d-inline-block ml-2 align-top">
        <b-button v-b-toggle.collapse-order-settings variant="link">
          Configuraciones avanzadas
          <span class="when-open">
            <b-icon-chevron-up class="chevron-accordion" />
          </span>
          <span class="when-closed">
            <b-icon-chevron-down class="chevron-accordion" />
          </span>
        </b-button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import OrdersShippingLabelButton from "@/components/order/OrdersShippingLabelButton";
import OrdersManifestButton from "@/components/order/OrdersManifestButton";
import OrdersDetailsButton from "@/components/order/OrdersDetailsButton";
import OrderWebHookNotifyButton from "@/components/order/OrderWebHookNotifyButton";

import { centryUrl } from "@/main";
export default {
  name: "OrdersMasiveActions",
  components: {
    OrdersShippingLabelButton,
    OrdersManifestButton,
    OrdersDetailsButton,
    OrderWebHookNotifyButton
  },
  props: {
    configButtons: {
      type: Object
    }
  },
  data() {
    return {
      centryUrl
    };
  },
  computed: {
    ...mapState(["selectedOrders", "currentUser"]),
    selectedOrdersCount() {
      return Object.keys(this.selectedOrders).reduce(
        (accumulator, currentValue) =>
          accumulator + (this.selectedOrders[currentValue].selected ? 1 : 0),
        0
      );
    },
    sections() {
      return this.currentUser.company.betaSectionsAllowed.map(x => {
        return x.section;
      });
    }
  }
};
</script>

<style scoped>
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>
