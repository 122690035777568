<template>
  <div>
    <ApolloQuery
      :query="require('../graphql/AllHomologationOptionList.gql')"
      :variables="{ cursor: '', name: filters.name }"
      :fetchPolicy="'cache-and-network'"
      clientId="apolloClientCached"
    >
      <template slot-scope="{ result: { error, data }, query, isLoading }">
        <span v-if="data && isLoading" class="m-2 float-right">
          Actualizando listado de opciones...
        </span>
        <b-table-simple v-if="data && data.allHomologationOptionList" hover="">
          <b-thead>
            <b-tr>
              <b-th>Nombre</b-th>
              <b-th>Tipo</b-th>
              <b-th></b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-if="!data.allHomologationOptionList.edges.length">
              <b-td colspan="7">No tienes listado de opciones configurado</b-td>
            </b-tr>
            <b-tr
              v-for="optionList of data.allHomologationOptionList.edges"
              :key="optionList.node.id"
            >
              <b-td>{{ optionList.node.name }}</b-td>
              <b-td>{{ type(optionList.node.type) }}</b-td>
              <b-td
                ><b-dropdown variant="white" no-caret="" offset="-110">
                  <template v-slot:button-content>
                    <b-icon-three-dots-vertical></b-icon-three-dots-vertical>
                  </template>
                  <b-dropdown-item :to="editUrl(optionList.node)">
                    Editar
                  </b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <ApolloMutation
                    :mutation="
                      require('../graphql/DeleteHomologationOptionList.gql')
                    "
                    :variables="{ id: optionList.node.id }"
                    @done="updateDeletedOptionList"
                    @error="errorMessageDeleting"
                    :update="
                      (store, allData) =>
                        updateCache(optionList.node.id, store, allData)
                    "
                  >
                    <template slot-scope="{ mutate }">
                      <b-dropdown-item
                        @click="deleteIfAccept(optionList, mutate)"
                      >
                        Eliminar
                      </b-dropdown-item>
                    </template>
                  </ApolloMutation>
                </b-dropdown></b-td
              >
            </b-tr>
          </b-tbody>
        </b-table-simple>
        <b-alert v-if="error" show="" variant="danger">
          Ha ocurrido un error
        </b-alert>
        <b-spinner
          v-if="isLoading"
          label="Spinning"
          class="m-2 float-right"
        ></b-spinner>
        <div v-else>
          <b-button
            v-if="hasNextPage(data)"
            class="m-2 float-right"
            @click="
              seeMore(query, data.allHomologationOptionList.pageInfo.endCursor)
            "
          >
            Ver más
          </b-button>
          <b-alert v-else show variant="light" class="text-center">
            No hay más datos para mostrar.
          </b-alert>
        </div>
      </template>
    </ApolloQuery>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "OptionListTable",
  computed: {
    ...mapState(["filters"])
  },
  methods: {
    deleteIfAccept(optionList, mutate) {
      this.$swal
        .fire({
          title: "Eliminar",
          text:
            'Se eliminará el listado de opciones "' +
            optionList.node.name +
            '" permanentemente ¿Está seguro?',
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si"
        })
        .then(result => {
          if (result.value) {
            mutate();
          }
        });
    },
    updateDeletedOptionList(data) {
      if (!data.data.deleteHomologationOptionList.result) {
        this.errorMessageDeleting();
      }
    },
    errorMessageDeleting() {
      this.$swal.fire({
        icon: "error",
        text: "Ha ocurrido un error al eliminar el producto"
      });
    },
    /**
     * Indica si hay una página siguiente en la query de GraphQl basado
     * exclusivamente en la existencia de un cursor.
     * @return {boolean}
     */
    hasNextPage(data) {
      return data?.allHomologationOptionList?.pageInfo?.endCursor != null;
    },
    /**
     * Trae más resultados de una query avanzando en su cursor.
     * @param {Object} query
     * @param {String} cursor
     */
    async seeMore(query, cursor) {
      await query.fetchMore({
        variables: {
          cursor: cursor,
          name: this.filters.name
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          const updated = Object.assign({}, this.$dup(prev));
          updated.allHomologationOptionList.pageInfo =
            fetchMoreResult.allHomologationOptionList.pageInfo;
          if (fetchMoreResult?.allHomologationOptionList?.edges?.length !== 0) {
            updated.allHomologationOptionList.edges.push(
              ...fetchMoreResult.allHomologationOptionList.edges
            );
          }
          return updated;
        }
      });
    },
    type(type) {
      switch (type) {
        case "category":
          return "Árbol de categorías";
        case "data_base":
          return "Consulta a base de datos";
        case "request":
          return "Request específico";
        case "fixed":
          return "Listado fijo";
      }
    },
    updateCache(id, store, { data: { deleteHomologationOptionList } }) {
      if (!this.$dig(deleteHomologationOptionList, "result")) {
        return;
      }
      const query = {
        query: require("../graphql/AllHomologationOptionList.gql"),
        variables: { cursor: "", name: this.filters.name }
      };
      const data = store.readQuery(query);
      const index = data.allHomologationOptionList.edges.findIndex(
        x => x.node.id === id
      );
      if (index !== -1) {
        data.allHomologationOptionList.edges.splice(index, 1);
        store.writeQuery({
          ...query,
          data
        });
      }
    },
    editUrl(optionList) {
      if (optionList.type === "category") {
        return "/admin/option_list/category/" + optionList.id + "/edit";
      } else if (optionList.type === "data_base") {
        return "/admin/option_list/data_base/" + optionList.id + "/edit";
      } else if (optionList.type === "request") {
        return "/admin/option_list/request/" + optionList.id + "/edit";
      }
    }
  }
};
</script>
<style scoped>
.btn-sm {
  margin: 5px;
}
</style>
