<script>
import { Doughnut } from "vue-chartjs";
export default {
  extends: Doughnut,
  props: {
    data: {
      type: Object,
      default() {
        return {};
      }
    },
    legendPosition: {
      type: String,
      default: "right"
    },
    options: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: this.legendPosition,
          labels: {
            usePointStyle: true
          }
        }
      }
    };
  },
  mounted() {
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    this.renderChart(this.data, this.mergeOptions());
  },
  methods: {
    mergeOptions() {
      return this.$deepMerge(this.chartOptions, this.options);
    }
  },
  watch: {
    data(newVal) {
      this.renderChart(newVal, this.mergeOptions());
    }
  }
};
</script>
