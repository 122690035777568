<template>
  <div>
    <b-button
      @click="$bvModal.show('videoTutorialModal')"
      variant="primary"
      title="Videos Explicativos"
      v-b-tooltip.hover=""
      id="btn-show-tutorials-modal"
    >
      <b-icon-play-fill></b-icon-play-fill>
    </b-button>
    <b-modal id="videoTutorialModal" cancel-disabled="" size="xl">
      <template v-slot:modal-title>
        <h4>{{ sectionVideoTutorials.name }}</h4>
      </template>
      <template v-slot:modal-footer="{ ok }">
        <b-button variant="info" @click="ok">
          Entendido
        </b-button>
      </template>
      <div class="modal-body">
        <b-row>
          <b-col md="3">
            <p>{{ sectionVideoTutorials.description }}</p>
            <b-list-group>
              <b-list-group-item
                href="#"
                @click="() => selectVideo(video)"
                v-for="(video, index) of sectionVideoTutorials.resources"
                :key="index"
              >
                {{ video.name }}
              </b-list-group-item>
            </b-list-group>
          </b-col>
          <b-col md="9">
            <div
              class="embed-responsive embed-responsive-16by9"
              v-if="!!selectedVideo"
            >
              <iframe
                class="embed-responsive-item"
                :src="selectedUrl"
                allowfullscreen
              ></iframe>
            </div>
            <div class="well" v-if="selectedVideo">
              {{ selectedVideo.description }}
            </div>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: "VideoTutorialModal",
  props: {
    sectionVideoTutorials: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      selectedVideo: null
    };
  },
  computed: {
    /**
     * Obtiene la url del video para el recurso seleccionado,
     * considerando url cortas de youtube
     * @return {String} url del video
     */
    selectedUrl() {
      if (!this.selectedVideo) return null;
      let params;
      let youtubeUrlParam;
      if (this.selectedVideo.url.includes("youtu.be")) {
        params = this.selectedVideo.url.split("/");
        youtubeUrlParam = params.at(-1);
      } else {
        params = this.selectedVideo.url.split("?")[1].split("&");
        youtubeUrlParam = params.find(param => param.includes("v="));
      }
      if (youtubeUrlParam)
        return (
          "https://www.youtube.com/embed/" +
          youtubeUrlParam.replace("v=", "") +
          "?rel=0&wmode=transparent&showinfo=0"
        );
      return null;
    }
  },
  methods: {
    selectVideo(video) {
      this.selectedVideo = video;
    }
  }
};
</script>
<style scoped>
#btn-show-tutorials-modal {
  position: fixed;
  left: 20px;
  bottom: 30px;
  color: #ffffff;
  border-radius: 20%;
  width: 60px;
  height: 40px;
  display: block;
  z-index: 1;
  border: none;
  padding: 0;
  margin: 0;
  background-color: red;
}

#btn-show-tutorials-modal .fa-play {
  margin: 0;
  width: 100%;
}

#btn-show-tutorials-modal .fa-play:before {
  color: #fff;
  font-size: 1.3em;
  line-height: 40px;
}
</style>
