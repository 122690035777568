<template>
  <div class="m-2">
    <b-table
      :fields="fields"
      :items="items"
      :per-page="perPage"
      :current-page="currentPage"
      head-variant="light"
      id="my-table"
      :table-class="tableClass"
    >
      <template #cell(index)="data">
        {{ (currentPage - 1) * perPage + data.index + 1 }}.
      </template>
      <template #head()="data">
        {{ data.label }} <b-icon :icon="data.field.icon" font-scale="1.5" />
      </template>
      <template #cell()="data">
        <span v-if="html" v-html="data.value"></span>
        <span v-else>{{ data.value }}</span>
      </template>
    </b-table>

    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="my-table"
      align="right"
      :hide-goto-end-buttons="true"
      :hide-ellipsis="true"
    ></b-pagination>
  </div>
</template>

<script>
export default {
  name: "SimpleTablewithPagination",
  props: {
    perPage: Number,
    fields: Array,
    items: Array,
    tableClass: {
      type: String,
      default: ""
    },
    html: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentPage: "1"
    };
  },
  computed: {
    rows() {
      return this.items.length;
    }
  }
};
</script>

<style lang="scss" scoped>
.table {
  border: none;
  padding: 0;
  thead {
    th {
      height: 25px;
    }
  }

  td {
    height: 30px;
    padding: 0 10px 0 10px !important;
  }
}
</style>
