<template>
  <div>
    <integration-config-tabs
      :title-integration-data="'Datos para BSale'"
      :ic="ic"
    >
      <template
        slot="linkingInformation"
        slot-scope="{ visible, onUpdateTabs }"
      >
        <integration-config-b-sale-linking-information
          :ic="ic"
          :visible="visible"
          @onUpdateTabs="onUpdateTabs"
        />
      </template>
      <template slot="integrationData" slot-scope="{ visible }">
        <integration-config-b-sale-integration-data
          :ic="ic"
          :visible="visible"
        />
      </template>
    </integration-config-tabs>
  </div>
</template>

<script>
import IntegrationConfigTabs from "../../components/IntegrationConfigs/Tabs.vue";
import IntegrationConfigBSaleLinkingInformation from "../../components/IntegrationConfigs/BSale/LinkingInformation.vue";
import IntegrationConfigBSaleIntegrationData from "../../components/IntegrationConfigs/BSale/IntegrationData.vue";

export default {
  name: "IntegrationConfigBSale",
  components: {
    IntegrationConfigTabs,
    IntegrationConfigBSaleLinkingInformation,
    IntegrationConfigBSaleIntegrationData
  },
  props: {
    ic: {
      type: Object,
      default() {
        return {};
      }
    }
  }
};
</script>

<style scoped></style>
