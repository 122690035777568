<template>
  <ApolloQuery
    :query="require('../graphql/Banners/ActualBanner.gql')"
    :fetchPolicy="'cache-and-network'"
    cliendId="apolloClientCached"
  >
    <template v-slot="{ result: { data }, isLoading }">
      <div
        v-if="data && data.actualBanner != null && !isLoading"
        class="banner"
        :style="
          'background: linear-gradient(to right,' +
            data.actualBanner.colorPickLeft +
            ' 0%,' +
            data.actualBanner.colorPickLeft +
            ' 50%,' +
            data.actualBanner.colorPickRight +
            ' 50%, ' +
            data.actualBanner.colorPickRight +
            ' 100% )'
        "
      >
        <a :href="data.actualBanner.redirectUrl" target="_blank">
          <b-img class="img-banner" :src="data.actualBanner.imageUrl"></b-img>
        </a>
      </div>
    </template>
  </ApolloQuery>
</template>

<script>
export default {
  name: "UpperBanner"
};
</script>

<style scoped>
.banner {
  width: 100%;
  text-align: center;
}
.img-banner {
  height: 75px;
}
</style>
