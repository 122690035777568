<script>
import { mapState, mapMutations } from "vuex";
import { setCubeQueryFilters, getEndCompareDate } from "@/main";

export default {
  name: "BaseWidget",
  props: {
    widget: Object,
    token: String,
    integrationConfigs: Array
  },
  data() {
    return {
      data: null,
      compareData: null,
      infoWidget: null,
      loading: true,
      error: false,
      timeoutError: false
    };
  },
  mounted() {
    this.loadAll();
  },
  computed: {
    ...mapState(["filters", "currentUser", "byLabelColors"])
  },
  methods: {
    ...mapMutations(["setLabelColor"]),
    /**
     * Se encaraga de cargar todos los datos necesarios para el widget
     */
    loadAll() {
      this.loading = true;
      this.error = false;
      this.timeoutError = false;
      this.getWidget()
        .then(() => {
          return Promise.all([this.getData(), this.getCompareData()]);
        })
        .then(() => {
          this.loading = false;
        });
    },
    /**
     * Obtiene los datos necesarios para el widget
     * @returns {Promise}
     */
    getData() {
      let filters = this.$dup(this.filters);
      return this.doQueryCube(filters).then(data => (this.data = data));
    },
    /**
     * Setea los parametros y llama a la funcion que hace la query al cubo
     * @param {Hash} filters
     * @param {Boolean} isRocket
     * @return {Hash} data
     */
    async doQueryCube(filters) {
      let data = [];
      const query = setCubeQueryFilters(
        this.widget.cubeQuery,
        this.currentUser.company.id,
        filters.startDate,
        filters.endDate,
        filters
      );
      let timeZoneName = this.currentUser.company?.timeZoneName;
      let resp = await this.$cubeQuery(
        query,
        this.token,
        this.infoWidget.limit,
        timeZoneName
      );
      if (resp.error) {
        this.error = resp.error;
      } else if (resp.timeoutError) {
        this.timeoutError = true;
      } else {
        data = resp;
        if (this.widget.inMemoryFilter) {
          const f = eval(this.widget.inMemoryFilter);
          data = f(
            data,
            filters,
            this.integrationConfigs,
            filters.startDate,
            filters.endDate
          );
        }
      }
      return data;
    },
    getCompareData() {
      if (!this.filters.compareDate) {
        this.compareData = null;
        return;
      }
      let filters = this.$dup(this.filters);

      let startDate = filters.compareDate;
      let endDate = getEndCompareDate(
        filters.startDate,
        filters.endDate,
        filters.compareDate
      );
      filters.startDate = startDate;
      filters.endDate = endDate;
      return this.doQueryCube(filters).then(data => (this.compareData = data));
    },
    getWidget() {
      return 1;
    },
    colorFor(label) {
      if (!this.byLabelColors[label]) {
        this.setLabelColor(label);
      }
      return this.byLabelColors[label];
    }
  },
  watch: {
    filters() {
      this.loading = true;
      this.error = false;
      this.timeoutError = false;
      Promise.all([this.getData(), this.getCompareData()]).then(
        () => (this.loading = false)
      );
    }
  }
};
</script>
