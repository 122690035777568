<template>
  <div>
    <b-form-group label="Integración" label-for="ic">
      <v-select
        v-if="allVtexIntegrations"
        id="ic"
        :options="allVtexIntegrations"
        placeholder="Seleccionar"
        v-model="integrationConfigId"
        :reduce="cond => cond.value"
        class="mb-3"
      ></v-select>
    </b-form-group>
    <b-row v-if="integrationConfigId">
      <b-col>
        <h3 class="mb-2">Seleccionar Categoría</h3>
        <b-spinner v-if="loadingCategories" label="Spinning"></b-spinner>
        <base-category-tree
          v-else
          :categories="categories"
          children-path="children"
          :key="integrationConfigId"
          @category="changeCategory"
        ></base-category-tree>
      </b-col>
      <b-col>
        <h3 class="mb-2">Seleccionar Especificación:</h3>
        <b-spinner
          v-if="loadingSpecifications && categorySelected"
          label="Spinning"
        ></b-spinner>
        <base-category-tree
          v-else-if="allSpecifications && categorySelected"
          :categories="allSpecifications"
          children-path="children"
          :key="categorySelected"
          item-id="fieldId"
          @category="changeSpecification"
        ></base-category-tree>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import BaseCategoryTree from "@/components/BaseCategoryTree";
import GET_VTEX_CATEGORIES from "@/graphql/AllVtexCategories.gql";
import ALL_VTEX_INTEGRATIONS from "@/graphql/AllVtexIntegrations.gql";
import GET_VTEX_SPECIFICATIONS from "@/graphql/IntegrationConfigVtexGetSpecifications.gql";
export default {
  name: "VtexHomologationSpecificationSelect",
  components: { BaseCategoryTree },
  props: {},
  data() {
    return {
      categories: [],
      categorySelected: null,
      specificationSelected: null,
      integrationConfigId: null,
      allVtexIntegrations: [],
      loadingCategories: false,
      loadingSpecifications: false,
      allSpecifications: []
    };
  },
  beforeMount() {
    this.getIntegrationConfigs();
  },
  computed: {},
  methods: {
    /**
     * Obtiene las categorías que tiene Vtex e indica
     * que esta parte ya cargó
     */
    getVtexCategories() {
      this.loadingCategories = true;
      if (!this.integrationConfigId) {
        this.categories = [];
        return;
      }
      this.$apollo
        .query({
          query: GET_VTEX_CATEGORIES,
          variables: {
            ic: this.integrationConfigId,
            limit: 100,
            offset: 0
          }
        })
        .then(({ data }) => {
          this.categories = data.allVtexCategories;
          this.loadingCategories = false;
        });
    },
    /**
     * Obtiene los integration configs y los formatea
     * para el selector
     */
    getIntegrationConfigs() {
      return this.$apollo
        .query({
          query: ALL_VTEX_INTEGRATIONS
        })
        .then(({ data }) => {
          if (data && data.allVtexIntegrationConfigs) {
            this.allVtexIntegrations = data.allVtexIntegrationConfigs.map(
              ic => {
                return { value: ic.id, label: ic.label };
              }
            );
          }
        });
    },
    /**
     * Obtiene las especificaciones que tiene
     * una categoría en Vtex e indica que ya cargó
     * esta parte.
     */
    getSpecifications() {
      return this.$apollo
        .query({
          query: GET_VTEX_SPECIFICATIONS,
          variables: {
            id: this.integrationConfigId,
            categoryId: this.categorySelected
          }
        })
        .then(({ data }) => {
          if (this.$dig(data, "integrationConfigVtex", "specificationsList")) {
            this.allSpecifications =
              data.integrationConfigVtex.specificationsList;
          }
          this.loadingSpecifications = false;
        });
    },
    /**
     * Obtiene las especificaciones de la categoría a la que cambio.
     * @param {Object} category - categoria de vtex seleccionada
     */
    changeCategory(category) {
      this.loadingSpecifications = true;
      this.categorySelected = category.id;
      this.getSpecifications();
    },
    /**
     * Actualiza la variable de la especificación y el id del
     * integration config seleccionado y manda esta información
     * a su componente padre.
     * @param {Object} spec - Especificación seleccionada
     */
    changeSpecification(spec) {
      this.specificationSelected = spec;
      let data = {
        specification: spec,
        integrationConfigId: this.integrationConfigId
      };
      this.$emit("spec-change", data);
    }
  },
  watch: {
    integrationConfigId() {
      this.allSpecifications = [];
      this.categories = [];
      this.getVtexCategories();
    }
  }
};
</script>
