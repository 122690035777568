<template>
  <div>
    <base-header :titleSize="0" title="Jumpseller Promociones"></base-header>
    <b-card class="mb-2" no-body>
      <b-spinner label="Spinning" v-if="loadingIntegrationConfigs"></b-spinner>
      <template v-else-if="allIntegrationsJumpseller.length > 0">
        <b-tabs content-class="mt-3" lazy>
          <template v-for="(ic, index) of allIntegrationsJumpseller">
            <b-tab :title="'Jumpseller ' + (index + 1)" :key="ic.id">
              <promotions v-if="isJumpseller(ic)" :ic="ic" />
            </b-tab>
          </template>
        </b-tabs>
      </template>
      <div v-else>
        No hay integración asociada.
      </div>
    </b-card>
  </div>
</template>
<script>
import Promotions from "./Promotions.vue";
import BaseHeader from "../../components/BaseHeader.vue";
import ALL_INTEGRATION_CONFIGS from "../../graphql/IntegrationConfigsForTabs.gql";

export default {
  name: "PromotionsTabs",
  props: {},
  components: {
    Promotions,
    BaseHeader
  },
  data() {
    return {
      loadingIntegrationConfigs: true,
      allIntegrationsJumpseller: []
    };
  },
  async created() {
    await this.awaitForIntegrationConfigs();
  },
  methods: {
    async awaitForIntegrationConfigs() {
      await this.$apollo
        .query({
          query: ALL_INTEGRATION_CONFIGS
        })
        .then(({ data }) => {
          if (data && data.allIntegrationConfigs) {
            this.allIntegrationsJumpseller = this.availableIntegrationConfigs(
              data
            );
          }
          this.loadingIntegrationConfigs = false;
        });
    },
    availableIntegrationConfigs(data) {
      if (!data || !data.allIntegrationConfigs) {
        return [];
      }
      const available = data.allIntegrationConfigs.filter(
        x => x.available && this.hasSeparator(x)
      );
      if (available.length > 0) {
        this.$emit("change");
      }

      return available;
    },
    hasSeparator(ic) {
      return this.isJumpseller(ic);
    },
    isJumpseller(ic) {
      return ic.identifier === "jumpseller";
    }
  }
};
</script>
