<template>
  <div>
    <ApolloQuery
      :query="require('@/graphql/Messages/Scheduled.gql')"
      :variables="queryVariables()"
      fetchPolicy="cache-and-network"
    >
      <template slot-scope="{ result: { data }, isLoading }">
        <b-spinner v-if="isLoading" label="Spinning" />
        <div v-else>
          <base-header
            title="Mensajes programados"
            title-link="/admin/messages_scheduled"
            :titleSize="0"
            :map="
              $dig(data, 'messagesScheduled', 'name')
                ? [$route.meta.action, $dig(data, 'messagesScheduled', 'name')]
                : [$route.meta.action]
            "
          >
          </base-header>
          <b-container fluid="">
            <router-view
              :is-loading="isLoading == 1"
              :messageScheduled="$dig(data, 'messagesScheduled')"
            ></router-view>
          </b-container>
        </div>
      </template>
    </ApolloQuery>
  </div>
</template>

<script>
import BaseHeader from "@/components/BaseHeader.vue";

export default {
  name: "MessageScheduled",
  components: {
    BaseHeader
  },
  methods: {
    /**
     * Entrega las variables para la query que permite obtener la bodega
     */
    queryVariables() {
      let variables = {};
      variables.id = "new";
      if (this.$route.params.id) {
        variables.id = this.$route.params.id;
      }
      return variables;
    }
  }
};
</script>
