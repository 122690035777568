var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ApolloQuery',{attrs:{"query":require('../graphql/NotSynchronizedErrorSummary.gql'),"variables":{
    createdAtLte: _vm.filters.createdAtLte,
    createdAtGte: _vm.filters.createdAtGte,
    origins: _vm.filters.origins,
    publicApplicationInformationIds: _vm.filters.publicApplicationInformationIds,
    companyIds: _vm.filters.companyIds
  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var data = ref.result.data;
return [_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('h4',[_vm._v("Distribución por orígen")]),(!!data)?_c('base-pie-chart',{attrs:{"chart-data":{
            labels: Object.keys(
              data.statistics.notSynchronizedErrorsReport.by_origin
            ),
            datasets: [
              {
                backgroundColor: Object.keys(
                  data.statistics.notSynchronizedErrorsReport.by_origin
                ).map(function () { return _vm.randomColor(); }),
                data: Object.values(
                  data.statistics.notSynchronizedErrorsReport.by_origin
                )
              }
            ]
          }}}):_vm._e()],1),_c('b-col',{attrs:{"md":"4"}},[_c('h4',[_vm._v("Distribución por integración")]),(!!data)?_c('base-pie-chart',{attrs:{"chart-data":{
            labels: Object.keys(
              data.statistics.notSynchronizedErrorsReport.by_integration
            ),
            datasets: [
              {
                backgroundColor: Object.keys(
                  data.statistics.notSynchronizedErrorsReport.by_integration
                ).map(function () { return _vm.randomColor(); }),
                data: Object.values(
                  data.statistics.notSynchronizedErrorsReport.by_integration
                )
              }
            ]
          }}}):_vm._e()],1),_c('b-col',{attrs:{"md":"4"}},[_c('h4',[_vm._v("Distribución por empresa")]),(!!data)?_c('base-pie-chart',{attrs:{"chart-data":{
            labels: Object.keys(
              data.statistics.notSynchronizedErrorsReport.by_company
            ),
            datasets: [
              {
                backgroundColor: Object.keys(
                  data.statistics.notSynchronizedErrorsReport.by_company
                ).map(function () { return _vm.randomColor(); }),
                data: Object.values(
                  data.statistics.notSynchronizedErrorsReport.by_company
                )
              }
            ]
          }}}):_vm._e()],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }