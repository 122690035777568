<template>
  <div>
    <b-alert
      v-if="error || success"
      show=""
      :variant="alertVariant"
      dismissible
    >
      {{ alertMessage }}
    </b-alert>
    <b-row v-if="loading">
      <b-col v-for="i in 6" :key="i" cols="3" class="mb-5">
        <b-skeleton
          animation="wave"
          type="input"
          :width="`${80 - Math.floor(Math.random() * 20)}%`"
        />
      </b-col>
    </b-row>
    <div v-else align-h="between">
      <b-row class="my-2">
        <b-col xl="10" cols="12">
          <b-row class="ml-2">
            <h4 class="font-weight-bold my-auto">
              Descuento de Stock
            </h4>
            <base-help-modal
              id="helpStockDiscount"
              tooltip="Click aquí para ver un ejemplo"
              title="Descuento de Stock"
              :scrollable="true"
              size="md"
              class="ml-2"
            >
              <help-stock-discount-modal :ic-name="ic.label" />
            </base-help-modal>
          </b-row>
        </b-col>
        <b-col xl="2" cols="12">
          <b-button
            variant="info"
            :disabled="!changed || saving"
            @click="save"
            class="mb-3 w-100"
          >
            <span v-if="saving"><b-spinner label="Spinning"></b-spinner></span>
            <span v-else>Guardar</span>
          </b-button>
          <b-button
            variant="outline-info"
            class="w-100 px-0"
            :disabled="!changed || saving"
            @click="resetData()"
          >
            <span>Restablecer</span>
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col v-for="(stockDiscount, i) of stockDiscounts" :key="i" cols="12">
          <stock-discount-detail
            :stockDiscount="stockDiscount"
            :all-brands="allBrands"
            :all-categories="allCategories"
            :index="i"
            @getBrands="getBrands"
            @getCategories="getCategories"
            @changed="val => changeDetail(val, i)"
            @valid="val => changeValid(val, i)"
            @delete="deleteStockDiscount"
            @updateStockDiscount="val => updateStockDiscount(val, i)"
            :reset="reset"
            :saved="saved"
            :show-errors="error"
          />
        </b-col>
      </b-row>
      <b-row class="ml-1">
        <b-button @click="addStockDiscount">
          Agregar descuento stock
        </b-button>
      </b-row>
    </div>
  </div>
</template>

<script>
import STOCK_DISCOUNTS from "../../graphql/IntegrationConfig/StockDiscounts.gql";
import UPDATE_INTEGRATION_CONFIG from "../../graphql/IntegrationConfig/UpdateIntegrationConfig.gql";
import ALL_BRANDS from "../../graphql/AllBrands.gql";
import ALL_CATEGORIES from "../../graphql/AllCategories.gql";
import StockDiscountDetail from "./StockDiscountDetail.vue";
import HelpStockDiscountModal from "./HelpStockDiscountModal.vue";
import BaseHelpModal from "../BaseHelpModal.vue";
import { mapState } from "vuex";
export default {
  components: {
    StockDiscountDetail,
    HelpStockDiscountModal,
    BaseHelpModal
  },
  name: "StockDiscounts",
  props: {
    ic: {
      type: Object,
      default() {
        return {};
      }
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      stockDiscounts: [],
      originalStockDiscounts: [],
      changeList: [],
      validList: [],
      allBrands: null,
      allCategories: null,
      loading: false,
      saving: false,
      errorMessage: null,
      error: false,
      success: false,
      reset: 0,
      saved: 0
    };
  },
  async mounted() {
    await this.getStockDiscount();
  },
  computed: {
    ...mapState(["currentUser"]),
    changed() {
      return (
        this.changeList.reduce((prev, current) => prev || current, false) ||
        this.originalStockDiscounts.length != this.stockDiscounts.length
      );
    },
    alertVariant() {
      return this.error ? "danger" : "success";
    },
    alertMessage() {
      return this.error
        ? "Ha ocurrido un error guardando los datos: " + this.errorMessage
        : "Guardado exitosamente";
    },
    valid() {
      return this.validList.reduce((prev, current) => prev && current, true);
    }
  },
  methods: {
    /**
     * Obtiene todas las categorias
     */
    async getCategories() {
      if (!this.allCategories) {
        await this.$getAllPages(
          ALL_CATEGORIES,
          { companyId: this.currentUser.company.id },
          "allCategories"
        ).then(result => {
          this.allCategories = result.map(x => x.node);
        });
      }
    },
    /**
     * Obtiene todas las marcas
     */
    async getBrands() {
      if (!this.allBrands) {
        await this.$getAllPages(
          ALL_BRANDS,
          { companyId: this.currentUser.company.id },
          "allBrands"
        ).then(result => {
          this.allBrands = result.map(x => x.node);
        });
      }
    },
    /**
     * Se encarga de obtener el valor actual de
     * los campos para la vinculación de la integración
     */
    async getStockDiscount() {
      this.loading = true;
      await this.$apollo
        .query({
          query: STOCK_DISCOUNTS,
          variables: { integrationConfigId: this.ic.id }
        })
        .then(({ data }) => {
          this.stockDiscounts = this.cleanTypename(
            data.integrationConfig.stockDiscounts
          );
          this.originalStockDiscounts = this.$dup(this.stockDiscounts);
          this.loading = false;
        });
    },
    /**
     * Envia la mutacion para guardar los nuevos
     * valores de vinculación de la cuenta
     */
    save() {
      this.saving = true;
      if (this.valid) {
        this.softResetData();
        let tempStockDiscounts = this.$dup(this.stockDiscounts);
        tempStockDiscounts.forEach(val => delete val.type);
        this.$apollo
          .mutate({
            mutation: UPDATE_INTEGRATION_CONFIG,
            variables: {
              integrationConfigId: this.ic.id,
              patch: { stockDiscounts: tempStockDiscounts }
            }
          })
          .then(({ data }) => {
            this.saving = false;
            if (data?.updateIntegrationConfig?.result) {
              this.success = true;
              this.stockDiscounts = this.$dup(tempStockDiscounts);
              this.originalStockDiscounts = this.$dup(this.stockDiscounts);
              this.changeList = this.stockDiscounts.map(() => false);
              this.validList = this.stockDiscounts.map(() => true);
              this.saved += 1;
            } else {
              this.errorMessage = data?.updateIntegrationConfig?.error;
              this.error = true;
            }
            this.saving = false;
          })
          .catch(e => {
            this.errorMessage = e;
            this.error = true;
            this.saving = false;
          });
      } else {
        this.errorMessage = "Hay campos que se deben rellenar";
        this.error = true;
        this.saving = false;
      }
    },
    /**
     * Se encarga de resetear los datos
     */
    resetData() {
      this.loading = true;
      this.stockDiscounts = this.$dup(this.originalStockDiscounts);
      this.changeList = this.originalStockDiscounts.map(() => false);
      this.validList = this.originalStockDiscounts.map(() => true);
      this.softResetData();
      this.reset += 1;
      this.loading = false;
    },
    /**
     * Se encarga de resetear solo las
     * variables de exito y error
     */
    softResetData() {
      this.errorMessage = null;
      this.error = false;
      this.success = false;
    },
    /**
     * Añade un nuevo descuento de stock a la lista
     */
    addStockDiscount() {
      this.stockDiscounts.push({
        type: "new",
        stockDiscountType: null,
        stockDiscount: null,
        criterion: null,
        brandIds: [],
        categoryIds: []
      });
    },
    /**
     * Elimina los __typename de la lista de descuento
     * de stock, ademas se encarga de obtener
     * las marcas y categorias si se requiere
     * @param {Array} stockDiscounts - lista de descuentos de stock
     */
    cleanTypename(stockDiscounts) {
      let getBrands = false;
      let getCategories = false;
      let newStockDiscounts = this.$dup(stockDiscounts);
      newStockDiscounts.forEach(newStockDiscount => {
        this.changeList.push(false);
        this.validList.push(true);
        delete newStockDiscount.__typename;
        if (newStockDiscount.criterion.includes("brand")) {
          getBrands = true;
        }
        if (newStockDiscount.criterion.includes("category")) {
          getCategories = true;
        }
      });
      if (getBrands) {
        this.getBrands();
      }
      if (getCategories) {
        this.getCategories();
      }
      return newStockDiscounts;
    },
    /**
     * Actualiza changeList segun los cambios
     * de cada descuento de stock
     * @param {Boolean} val - boolean indicando si hubieron cambios o no
     * @param {Integer} i - posicion del arreglo a actualizar
     */
    changeDetail(val, i) {
      let tempChanges = this.$dup(this.changeList);
      tempChanges[i] = val;
      this.changeList = this.$dup(tempChanges);
    },
    /**
     * Actualiza validList segun la validez
     * de cada descuento de stock
     * @param {Boolean} val - boolean indicando si ews valido o no
     * @param {Integer} i - posicion del arreglo a actualizar
     */
    changeValid(val, i) {
      let tempValids = this.$dup(this.validList);
      tempValids[i] = val;
      this.validList = this.$dup(tempValids);
    },
    /**
     * Elimina un descuento de stock de la lista
     * @param {Integer} i - posicion del arreglo a eliminar
     */
    deleteStockDiscount(i) {
      this.changeList.splice(i, 1);
      this.validList.splice(i, 1);
      this.stockDiscounts.splice(i, 1);
    },
    /**
     * Actualiza el valor de un descuento de stock
     * @param {Object} val - descuento de stock
     * @param {Integer} i - posicion del descuento de stock en el arreglo
     */
    updateStockDiscount(val, i) {
      this.stockDiscounts[i] = this.$dup(val);
    }
  },
  watch: {
    changed(val) {
      this.$emit("changed", val);
    },
    visible(val) {
      if (val) {
        this.resetData();
      }
    }
  }
};
</script>
