<template>
  <div class="container">
    <b-badge variant="info" v-if="categoryAttribute.automaticCreation"
      >Creado en proceso automático</b-badge
    ><br /><br />
    <b-button-group size="sm">
      <b-button
        variant="outline-info"
        :href="centryUrl + '/admin/category_attributes'"
      >
        <b-icon-x-circle></b-icon-x-circle> Cancelar
      </b-button>
      <b-button variant="info" :disabled="loading" @click="save">
        <span v-if="loading"><b-spinner label="Spinning"></b-spinner></span>
        <span v-else><b-icon-cloud-upload></b-icon-cloud-upload>Guardar</span>
      </b-button>
    </b-button-group>
    <b-row>
      <b-col>
        <b-form-group label="Nombre" label-for="name">
          <b-form-input v-model="name" id="name"></b-form-input>
        </b-form-group>
        <b-form-group label="Descripción" label-for="description">
          <b-form-input v-model="description" id="description"></b-form-input>
        </b-form-group>
        <b-form-group label="Obligatorio" label-for="status">
          <base-boolean-selector
            v-model="required"
            true-text="Si"
            false-text="No"
          >
          </base-boolean-selector>
        </b-form-group>
        <b-form-group label="Tipo" label-for="value_type">
          <v-select
            id="value_type"
            :options="allValueTypes"
            placeholder="Seleccionar"
            v-model="valueType"
            :reduce="cond => cond.value"
          ></v-select>
          <b-link
            v-if="
              !!categoryAttribute.id &&
                (valueType === 'select_one' || valueType === 'select_many')
            "
            :href="
              centryUrl +
                '/admin/category_attribute_options?category_attribute_id=' +
                categoryAttribute.id
            "
            >Administrar opciones</b-link
          >
        </b-form-group>
        <b-form-group label="Categorías">
          <b-spinner label="Spinning" v-if="loading"></b-spinner>
          <vMultiselectListbox
            v-else
            style="min-width: 100%"
            v-model="categoryIds"
            :options="allCategories"
            :reduce-display-property="option => option.label"
            :reduce-value-property="option => option.value"
            search-options-placeholder="Buscar categorias"
            selected-options-placeholder="Buscar categorias seleccionadas"
            no-options-text="No hay categorias"
            selected-no-options-text="No hay categorias seleccionadas"
            no-options-found-text="No se encontró la categoria"
            no-selected-options-found-text="No se encontró la categoria seleccionada"
          >
          </vMultiselectListbox>
        </b-form-group>
      </b-col>
      <b-col v-if="!!categoryAttribute.id">
        <category-attribute-form-purpose-modal
          :purpose="currentPurpose"
          :category-attribute-id="categoryAttribute.id"
          @save="updatePurposesWith"
        ></category-attribute-form-purpose-modal>
        <h4>Homologaciones</h4>
        <b-button variant="outline-info" @click="addPurpose">
          <b-icon-plus></b-icon-plus>
          Agregar homologación
        </b-button>
        <b-table-simple>
          <b-thead>
            <b-th>Integración</b-th>
            <b-th>Path</b-th>
            <b-th></b-th>
          </b-thead>
          <b-tbody>
            <b-tr v-if="!purposes.length">
              <b-td colspan="3"
                >No tienes homologaciones. Necesitas agregar alguna para que el
                atributo pueda sincronizarse</b-td
              >
            </b-tr>
            <b-tr v-for="purpose of purposes" :key="purpose.id">
              <b-td>{{ purpose.publicIntegrationInformation.name }}</b-td>
              <b-td>{{ purpose.path }}</b-td>
              <b-td>
                <b-dropdown variant="white" no-caret="" offset="-110">
                  <template v-slot:button-content>
                    <b-icon-three-dots-vertical></b-icon-three-dots-vertical>
                  </template>
                  <b-dropdown-item @click="() => editPurpose(purpose)">
                    Editar
                  </b-dropdown-item>
                  <b-dropdown-item @click="() => deletePurpose(purpose)">
                    Eliminar
                  </b-dropdown-item>
                </b-dropdown>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import vMultiselectListbox from "vue-multiselect-listbox";
import "vue-multiselect-listbox/dist/vue-multi-select-listbox.css";
import AllCategories from "../graphql/AllCategories.gql";
import BaseBooleanSelector from "@/components/BaseBooleanSelector";
import { centryUrl } from "@/main";
import CategoryAttributeFormPurposeModal from "@/components/CategoryAttributeFormPurposeModal";
import DELETE_PURPOSE_CATEGORY_ATTRIBUTE from "@/graphql/DeletePurposeCategoryAttribute.gql";
export default {
  name: "CategoryAttributeForm",
  mounted() {
    this.getCategories();
  },
  components: {
    CategoryAttributeFormPurposeModal,
    vMultiselectListbox,
    BaseBooleanSelector
  },
  props: {
    categoryAttribute: Object
  },
  data() {
    return {
      centryUrl,
      name: this.categoryAttribute.name,
      description: this.categoryAttribute.description,
      required: this.categoryAttribute.required
        ? this.categoryAttribute.required
        : false,
      valueType: this.categoryAttribute.valueType,
      categoryIds: null,
      allCategories: null,
      loading: true,
      allValueTypes: [
        { value: "text", label: "Texto" },
        { value: "number", label: "Número" },
        { value: "date", label: "Fecha" },
        { value: "time", label: "Hora" },
        { value: "date_time", label: "Fecha y hora" },
        { value: "select_one", label: "Selección simple" },
        { value: "select_many", label: "Selección múltiple" }
      ],
      currentPurpose: {},
      purposes: this.categoryAttribute.validPurposes
    };
  },
  computed: {
    categoryAttributeData() {
      const obj = {
        name: this.name,
        description: this.description,
        required: this.required,
        valueType: this.valueType,
        categoryIds: this.categoryIds
      };
      if (this.categoryAttribute.id) {
        obj.id = this.categoryAttribute.id;
      }
      return obj;
    }
  },
  methods: {
    async getCategories() {
      this.allCategories = [];
      const selectedCategories = {};
      this.$ifNull(this.categoryAttribute.categoryIds, []).forEach(x => {
        selectedCategories[x] = true;
      });
      const values = [];
      this.$getAllPages(AllCategories, {}, "allCategories").then(data => {
        const allCategories = [];
        data.forEach(x => {
          allCategories.push({ value: x.node.id, label: x.node.name });
          if (selectedCategories[x.node.id]) {
            values.push(x.node.id);
          }
        });
        this.allCategories = allCategories;
        this.categoryIds = values;
        this.loading = false;
      });
    },
    save() {
      this.$emit("change", this.categoryAttributeData);
    },
    addPurpose() {
      this.currentPurpose = {};
      this.$bvModal.show("purposeCategoryAttribute");
    },
    editPurpose(purpose) {
      this.currentPurpose = purpose;
      this.$bvModal.show("purposeCategoryAttribute");
    },
    deletePurpose(purpose) {
      this.$swal
        .fire({
          title: "Eliminar homologación",
          text: "¿Está seguro?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si",
          cancelButtonText: "No"
        })
        .then(result => {
          if (result.value) {
            this.$apollo
              .mutate({
                mutation: DELETE_PURPOSE_CATEGORY_ATTRIBUTE,
                variables: { id: purpose.id }
              })
              .then(() => {
                this.purposes = this.validPurposes.filter(
                  p => p.id !== purpose.id
                );
              });
          }
        });
    },
    updatePurposesWith(purpose) {
      const updated = this.$dup(this.purposes);
      const existing = updated.find(p => p.id === purpose.id);
      if (existing) {
        Object.assign(existing, purpose);
      } else {
        updated.push(purpose);
      }
      this.purposes = updated;
    }
  }
};
</script>
<style lang="scss" scoped>
h4 {
  margin: 20px;
}
</style>
