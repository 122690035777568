<template>
  <b-tr>
    <b-td
      ><b-img
        thumbnail
        :src="imageUrl"
        class="centry-thumb transparent-bg"
        alt="image"
        loading="lazy"
        v-if="imageUrl"
        width="80px"
      ></b-img>
      <div v-else class="centry-thumb centry-no-image img-thumbnail">
        <b-icon icon="image" variant="secondary" font-scale="2"></b-icon>
      </div>
    </b-td>
    <b-td>{{ suggestion.title }}</b-td>
    <b-td>{{ humanStatus }}</b-td>
    <b-td>{{ domainName }}</b-td>
    <b-td
      ><b-button
        variant="outline-info"
        :disabled="!canEdit"
        :title="editTitle"
        v-b-tooltip.hover
        :to="editLink(suggestion.id)"
        >Editar</b-button
      ></b-td
    >
  </b-tr>
</template>
<script>
export default {
  name: "MercadoLibreCatalogSuggestionsRow",
  props: {
    suggestion: Object,
    integrationConfigId: String
  },
  computed: {
    /**
     * Entrega la url de la imagen
     * @returns {String}
     */
    imageUrl() {
      return this.$dig(this.suggestion, "pictures", 0, "url");
    },
    /**
     * Entrega el status lejible por humanos
     * @returns {String}
     */
    humanStatus() {
      switch (this.suggestion.status) {
        case "UNDER_REVIEW":
          return "En revisión por MercadoLibre";
        case "WAITING_FOR_FIX":
          return "Debes corregir la sugerencia";
        case "PUBLISHED":
          return "Sugerencia publicada";
        case "REJECTED":
          return "Sugerencia rechazada";
        default:
          return "Desconocido";
      }
    },
    /**
     * Indica si se puede o no editar la sugerencia
     * @returns {Boolean}
     */
    canEdit() {
      return (
        this.suggestion.status === "WAITING_FOR_FIX" ||
        this.suggestion.status === "UNDER_REVIEW"
      );
    },
    /**
     * tooltip del botón editar
     * @returns {String}
     */
    editTitle() {
      if (this.canEdit) {
        return "Editar sugerencia";
      }
      return "No puedes editar esta sugerencia";
    },
    /**
     * Nombre del dominio
     * @returns {String}
     */
    domainName() {
      return this.$dig(this.suggestion, "domain", "name");
    }
  },
  methods: {
    /**
     * Link para editar una sugerencia específica
     * @param {String} id
     * @returns {String}
     */
    editLink(id) {
      return `/mercadolibre/catalog_suggestions/${this.integrationConfigId}/${id}/edit`;
    }
  }
};
</script>
