<template>
  <div>
    <b-table
      v-if="dataReady"
      :items="items"
      :fields="fields"
      striped
      class="text-left"
    >
      <template #cell(Acciones)="row">
        <b-button size="sm" @click="handleClick(row)" class="mr-2">
          {{ row.detailsShowing ? "Esconder" : "Mostrar" }} Detalles
        </b-button>
      </template>

      <template #row-details="row">
        <b-card v-if="row.item.request && row.item.response">
          <b-row class="mb-2" style="height: 150px;" :key="forceRenderEditor">
            <b-col md="6">
              <h5>Request</h5>
              <AceEditor
                mode="json"
                theme="monokai"
                width="100%"
                height="100%"
                :name="`req-editor-${row.index}`"
                :showPrintMargin="false"
                :readOnly="true"
                :value="jsonPrettyPrint(row.item.request)"
              />
            </b-col>
            <b-col md="6">
              <h5>Response</h5>
              <AceEditor
                mode="json"
                theme="monokai"
                width="100%"
                height="100%"
                :name="`req-editor-${row.index}-2`"
                :showPrintMargin="false"
                :readOnly="true"
                :value="jsonPrettyPrint(row.item.response)"
              />
            </b-col>
          </b-row>
        </b-card>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment-timezone";
import { Ace as AceEditor } from "vue2-brace-editor";
import "brace/mode/json";
import "brace/theme/monokai";
import "brace/ext/searchbox";

export default {
  name: "InvoiceRequestResponseRow",
  components: { AceEditor },
  props: {
    irr: Array
  },
  data() {
    return {
      items: [],
      fields: ["Fecha de sincronización", "Estado Preliminar", "Acciones"],
      dataReady: false,
      forceRenderEditor: 0
    };
  },
  async mounted() {
    await this.getItems();
    this.dataReady = true;
  },
  methods: {
    ...mapActions(["timezoneDate"]),
    async getItems() {
      let items = [];
      await this.irr.forEach(async data => {
        let createdAt = await this.timezoneDate(data.createdAt);
        let datetime = moment(createdAt).format("DD/MM/YYYY HH:mm:ss");
        items.push({
          "Fecha de sincronización": datetime,
          "Estado Preliminar": data.success,
          id: data.id,
          request: data.request,
          response: data.response
        });
      });
      this.items = items;
    },
    handleClick(row) {
      row.toggleDetails();
    },
    jsonPrettyPrint(json) {
      try {
        return JSON.stringify(JSON.parse(json), null, "  ");
      } catch (e) {
        return json;
      }
    },
    getDateReadable(date) {
      let dateReadable = moment(date).format("DD/MM/YYYY HH:mm:ss");
      return dateReadable;
    }
  },
  watch: {
    irr() {
      this.getItems();
    }
  }
};
</script>
<style scoped>
.table td {
  height: 15px;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
</style>
