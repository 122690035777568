<template>
  <div>
    <b-card no-body>
      <b-tabs card @input="onInput" v-model="tab">
        <b-tab title="Productos afectados" :lazy="!visitedTabs.includes(0)">
          <b-skeleton-table v-if="loading" />
          <blockage-table
            v-else
            :blockage="blockage"
            :items="productsAffected"
            @see-more="getRecords(true)"
            :has-next-page="hasNextPage"
            :loading-more="loadingMore"
          />
        </b-tab>
        <b-tab title="Bloqueos recientes" :lazy="!visitedTabs.includes(1)">
          <b-skeleton-table v-if="loading" />
          <blockage-table
            v-else
            :blockage="blockage"
            :items="records"
            @see-more="getRecords(false)"
            :has-next-page="hasNextPage"
            :loading-more="loadingMore"
          />
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import ALL_RECORDS from "../../graphql/Blockages/AllRecords.gql";
import BlockageTable from "../../components/Blockages/BlockageTable.vue";
export default {
  name: "BlockageShow",
  props: {
    blockage: Object,
    isLoadingBlockage: Boolean
  },
  components: {
    BlockageTable
  },
  data() {
    return {
      visitedTabs: [],
      productsAffected: [],
      records: [],
      tab: 0,
      loadingRecords: true,
      loadingMore: false,
      cursor: "",
      first: 30,
      newItems: null
    };
  },
  computed: {
    loading() {
      return this.isLoadingBlockage || this.loadingRecords;
    },
    hasNextPage() {
      return this.newItems == this.first;
    }
  },
  methods: {
    /**
     * Agrega el indice del tabs visitado a la lista de tabs visitados
     * @param {Int} value - indice del tab
     */
    onInput(value) {
      if (!this.visitedTabs.includes(value)) {
        this.visitedTabs.push(value);
      }
    },
    /**
     * Obtiene los bloqueos recientes de un bloqueo
     * @param {Boolean} massive
     */
    getRecords(massive) {
      if (this.cursor) {
        this.loadingMore = true;
      } else {
        this.loadingRecords = true;
      }
      if (!this.cursor) {
        this.productsAffected = [];
        this.records = [];
      }
      this.$apollo
        .query({
          query: ALL_RECORDS,
          variables: {
            blockageId: this.blockage.id,
            isMassive: massive,
            cursor: this.cursor,
            first: this.first
          }
        })
        .then(({ data }) => {
          let newRecords = data.allRecordsByBlockage.edges.map(
            edge => edge.node
          );
          this.newItems = newRecords.length;
          if (massive) {
            this.productsAffected = [...this.productsAffected, ...newRecords];
          } else {
            this.records = [...this.records, ...newRecords];
          }
          this.cursor = data.allRecordsByBlockage.pageInfo.endCursor;
          this.loadingRecords = false;
          this.loadingMore = false;
        })
        .catch(() => {
          this.loadingRecords = false;
          this.loadingMore = false;
        });
    }
  },
  watch: {
    blockage(val) {
      this.cursor = "";
      if (val.id) {
        this.loadingRecords = true;
        if (this.tab == 0) {
          this.getRecords(true);
        } else {
          this.getRecords(false);
        }
      }
    },
    tab(value) {
      this.cursor = "";
      if (this.blockage.id) {
        if (value == 0) {
          this.getRecords(true);
        } else {
          this.getRecords(false);
        }
      }
    }
  }
};
</script>

<style></style>
