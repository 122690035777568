var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"* Nombre"}},[_c('b-input',{attrs:{"placeholder":"Nombre de la tabla de tallas","state":!!_vm.name && !!_vm.name.length},on:{"input":_vm.emitChange},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('b-form-invalid-feedback',{attrs:{"state":!!_vm.name && !!_vm.name.length}},[_vm._v(" Este campo es obligatorio ")])],1)],1)],1),_c('b-row',[_vm._l((_vm.filterAttributes),function(attribute){return _c('b-col',{key:attribute.id,attrs:{"md":"6","sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.completeLabel(attribute),"label-for":'a' + attribute.id}},[(attribute.values && attribute.values.length)?_c('v-select',{attrs:{"id":'a' + attribute.id,"multiple":attribute.multivalued === true,"options":_vm.getOptions(attribute),"reduce":function (x) { return x.value; },"disabled":_vm.editMode},on:{"input":_vm.emitChange},model:{value:(_vm.filterAttributeValues[attribute.id]),callback:function ($$v) {_vm.$set(_vm.filterAttributeValues, attribute.id, $$v)},expression:"filterAttributeValues[attribute.id]"}}):_c('b-input',{attrs:{"id":'a' + attribute.id,"state":!_vm.requiredAttribute(attribute)
              ? null
              : !!_vm.filterAttributeValues[attribute.id] &&
                !!_vm.filterAttributeValues[attribute.id].length,"disabled":_vm.editMode},on:{"input":_vm.emitChange},model:{value:(_vm.filterAttributeValues[attribute.id]),callback:function ($$v) {_vm.$set(_vm.filterAttributeValues, attribute.id, $$v)},expression:"filterAttributeValues[attribute.id]"}}),(
            _vm.requiredAttribute(attribute) &&
              (!_vm.filterAttributeValues[attribute.id] ||
                !_vm.filterAttributeValues[attribute.id].length)
          )?_c('b-form-invalid-feedback',{attrs:{"state":!!_vm.filterAttributeValues[attribute.id] &&
              !!_vm.filterAttributeValues[attribute.id].length}},[_vm._v("Este atributo es obligatorio")]):_vm._e()],1)],1)}),_c('b-col',{attrs:{"sm":"6","md":"6"}},[_c('b-form-group',{attrs:{"label":"* Columna principal","description":"Esta columna será el título de cada talla de la tabla de talles"}},[_c('v-select',{attrs:{"options":_vm.mainAttributeCandidates.map(function (x) {
              return { label: x.name, value: x.id };
            }),"disabled":_vm.editMode,"reduce":function (x) { return x.value; }},on:{"input":_vm.emitChange},model:{value:(_vm.mainAttribute),callback:function ($$v) {_vm.mainAttribute=$$v},expression:"mainAttribute"}}),(!_vm.mainAttribute || !_vm.mainAttribute.length)?_c('b-form-invalid-feedback',{attrs:{"state":!!_vm.mainAttribute && !!_vm.mainAttribute.length}},[_vm._v("Este atributo es obligatorio")]):_vm._e()],1)],1)],2),_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[(_vm.haveMeasureType)?_c('b-form-group',{attrs:{"label":"* Tipo de medidas a especificar"}},[_c('v-select',{attrs:{"placeholder":"Seleccione un tipo","options":_vm.measureTypes,"reduce":function (x) { return x.value; },"clearable":false,"disabled":_vm.editMode},model:{value:(_vm.measureType),callback:function ($$v) {_vm.measureType=$$v},expression:"measureType"}})],1):_vm._e()],1)],1),_c('size-chart-mercado-libre-form-attributes-rows',{attrs:{"attributes":_vm.columns,"main-attribute":_vm.mainAttribute,"oldRows":_vm.oldRows,"editMode":_vm.editMode},on:{"change":_vm.emitChange},model:{value:(_vm.rows),callback:function ($$v) {_vm.rows=$$v},expression:"rows"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }