<template>
  <apollo-query
    :query="require('../graphql/TransactionsByProduct.gql')"
    :variables="{
      productId: product.id,
      first: 30,
      source: filters.source,
      operation: filters.operation,
      variantId: filters.variantId,
      warehouseId: filters.warehouseId
    }"
    :fetchPolicy="'cache-and-network'"
    clientId="apolloClientCached"
  >
    <template slot-scope="{ result: { error, data }, query, isLoading }">
      <span v-if="error">-- error al obtener los movimientos --</span>
      <b-spinner label="Spinning" v-else-if="!data && isLoading"></b-spinner>
      <span v-else-if="data.allTransactions.edges.length === 0">
        -- no hay movimientos registrados --
      </span>
      <span v-else-if="data && isLoading" class="m-2 float-right">
        Actualizando lista de movimientos...
      </span>
      <transactions-table
        v-if="data"
        :transactions="data.allTransactions.edges.map(x => x.node)"
        :product="product"
      ></transactions-table>
      <b-spinner
        v-if="isLoading"
        label="Spinning"
        class="m-2 float-right"
      ></b-spinner>
      <div v-else>
        <b-button
          v-if="hasNextPage(data)"
          class="m-2 float-right"
          @click="seeMore(query, data.allTransactions.pageInfo.endCursor)"
        >
          Ver más
        </b-button>
        <b-alert v-else show variant="light" class="text-center">
          No hay más datos para mostrar.
        </b-alert>
      </div>
    </template>
  </apollo-query>
</template>

<script>
import { mapState } from "vuex";
import TransactionsTable from "./TransactionsTable";
export default {
  name: "ProductTransactionsPage",
  components: {
    TransactionsTable
  },
  props: {
    product: Object
  },
  computed: {
    ...mapState(["filters"])
  },
  methods: {
    /**
     * Indica si hay una página siguiente en la query de GraphQl basado
     * exclusivamente en la existencia de un cursor.
     * @return {boolean}
     */
    hasNextPage(data) {
      return data?.allTransactions?.pageInfo?.endCursor != null;
    },
    /**
     * Trae más resultados de una query avanzando en su cursor.
     * @param {Object} query
     * @param {String} cursor
     */
    async seeMore(query, cursor) {
      await query.fetchMore({
        variables: {
          cursor: cursor,
          productId: this.product.id,
          source: this.filters.source,
          operation: this.filters.operation,
          variantId: this.filters.variantId,
          warehouseId: this.filters.warehouseId
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          const updated = Object.assign({}, this.$dup(prev));
          updated.allTransactions.pageInfo =
            fetchMoreResult.allTransactions.pageInfo;
          if (fetchMoreResult?.allTransactions?.edges?.length !== 0) {
            updated.allTransactions.edges.push(
              ...fetchMoreResult.allTransactions.edges
            );
          }
          return updated;
        }
      });
    }
  }
};
</script>
