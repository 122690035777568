<template>
  <div>
    <b-modal :id="id" cancel-disabled="" size="xl">
      <template v-slot:modal-title>
        <h4>{{ title }}</h4>
      </template>

      <template id="">
        <b-form-group>
          <div>
            <b-row>
              <b-col md="3">
                <strong>Categorías</strong>
              </b-col>
              <b-col md="9">
                <ApolloQuery
                  :query="require('../graphql/AllCategories.gql')"
                  :variables="{ companyId: currentUser.company.id }"
                >
                  <template slot-scope="{ result: { loading, data } }">
                    <b-spinner v-if="loading" label="Spinning"></b-spinner>
                    <b-form-group>
                      <v-select
                        v-model="categoriesSelected"
                        placeholder="Seleccionar"
                        :options="categoryOptions(data)"
                        multiple=""
                        :reduce="category => category.value"
                      ></v-select>
                    </b-form-group>
                  </template>
                </ApolloQuery>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="3">
                <strong>Marcas</strong>
              </b-col>
              <b-col md="9">
                <ApolloQuery
                  :query="require('../graphql/AllBrands.gql')"
                  :variables="{ companyId: currentUser.company.id }"
                >
                  <template slot-scope="{ result: { loading, data } }">
                    <b-spinner v-if="loading" label="Spinning"></b-spinner>
                    <b-form-group>
                      <v-select
                        v-model="brandsSelected"
                        placeholder="Seleccionar"
                        :options="brandOptions(data)"
                        multiple=""
                        :reduce="brand => brand.value"
                      ></v-select>
                    </b-form-group>
                  </template>
                </ApolloQuery>
              </b-col>
            </b-row>
          </div>
          <b-button @click="searchProducts()">
            FILTRAR
          </b-button>
        </b-form-group>
      </template>

      <template id="content">
        <template>
          <product-to-use-table
            :products="products"
            :selectedProducts="selectedProducts"
          />
        </template>
      </template>
      <template v-slot:modal-footer="{ ok }">
        <b-button variant="info" @click="ok">
          Entendido
        </b-button>
      </template>
      <slot></slot>
    </b-modal>
  </div>
</template>

<script>
import ProductToUseTable from "./ProductToUseTable.vue";
import { mapState, mapMutations } from "vuex";
import ALLPRODUCTS from "@/graphql/AllProductsIntegrationConfig.gql";

export default {
  computed: {
    ...mapState(["currentUser"])
  },
  components: {
    // Treeselect,
    ProductToUseTable
  },

  name: "ProductsToUseModal",
  props: {
    id: String,
    title: String,
    selectedProducts: Array,
    integrationConfigId: {
      type: String,
      required: true
    },
    fulllabel: String
  },

  data: () => ({
    categoriesSelected: [],
    brandsSelected: [],
    value: [],
    sortValueBy: "ORDER_SELECTED",
    valueConsistsOf: "LEAF_PRIORITY",
    options: [],
    item_class: "none",
    products: []
  }),

  methods: {
    ...mapMutations(["setSelectedProducts"]),
    categoryOptions(data) {
      if (!data || !data.allCategories || !data.allCategories.edges) {
        return [];
      }
      return data.allCategories.edges.map(x => {
        return { label: x.node.name, value: x.node.id };
      });
    },
    brandOptions(data) {
      if (!data || !data.allBrands || !data.allBrands.edges) {
        return [];
      }
      return data.allBrands.edges.map(x => {
        return { label: x.node.name, value: x.node.id };
      });
    },
    searchProducts() {
      this.$apollo
        .query({
          query: ALLPRODUCTS,
          variables: {
            cursor: "",
            categoryId: this.categoriesSelected.join(","),
            brandId: this.brandsSelected.join(","),
            integrationConfigId: this.integrationConfigId
          }
        })
        .then(result => {
          let productList = [];
          for (const product in result.data.allProducts.edges) {
            const productObject = result.data.allProducts.edges[product].node;

            const price =
              productObject.integrations[this.fulllabel].price != null
                ? productObject.integrations[this.fulllabel].price
                : productObject.priceCompare;

            productList.push({
              producto: productObject.name,
              sku: productObject.integrations[this.fulllabel].id,
              marca: productObject.brand.name,
              categoria: productObject.category.name,
              original_price: Math.round(price)
            });
          }
          this.products = productList;
        });
    }
  }
};
</script>

<style scoped></style>
