<template>
  <div>
    <histories-actions
      :product="product"
      :rangeDate.sync="rangeDate"
      :editorHeight.sync="editorHeight"
    ></histories-actions>
    <apollo-query
      :query="require('../graphql/HistoriesByProduct.gql')"
      :variables="queryVariables('')"
      :fetchPolicy="'cache-and-network'"
      clientId="apolloClientCached"
    >
      <template slot-scope="{ result: { error, data }, query, isLoading }">
        <span v-if="error">-- error al obtener los historiales --</span>
        <b-spinner label="Spinning" v-else-if="isLoading && !data"></b-spinner>
        <span v-else-if="data.allSynchronizationHistories.edges.length === 0">
          -- no hay movimientos registrados --
        </span>
        <span v-else-if="data && isLoading" class="m-2 float-right">
          Actualizando historial de sincronizaciones...
        </span>
        <histories-table
          v-if="data"
          :histories="data.allSynchronizationHistories.edges.map(x => x.node)"
          :product="product"
          :editorsHeightPx="editorHeight"
        ></histories-table>
        <b-spinner
          v-if="isLoading"
          label="Spinning"
          class="m-2 float-right"
        ></b-spinner>
        <div v-else>
          <b-button
            v-if="hasNextPage(data)"
            class="m-2 float-right"
            @click="
              seeMore(
                query,
                data.allSynchronizationHistories.pageInfo.endCursor
              )
            "
          >
            Ver más
          </b-button>
          <b-alert v-else show variant="light" class="text-center">
            No hay más datos para mostrar.
          </b-alert>
        </div>
      </template>
    </apollo-query>
  </div>
</template>

<script>
import { mapState } from "vuex";
import HistoriesActions from "./HistoriesActions";
import HistoriesTable from "./HistoriesTable";
export default {
  name: "ProductHistoriesPage",
  components: {
    HistoriesActions,
    HistoriesTable
  },
  props: {
    product: Object
  },
  data() {
    return {
      rangeDate: {},
      editorHeight: "200"
    };
  },
  computed: {
    ...mapState(["filters"])
  },
  methods: {
    /**
     * Indica si hay una página siguiente en la query de GraphQl basado
     * exclusivamente en la existencia de un cursor.
     * @return {Boolean}
     */
    hasNextPage(data) {
      return data?.allSynchronizationHistories?.pageInfo?.endCursor != null;
    },
    /**
     * Trae más resultados de una query avanzando en su cursor.
     * @param {Object} query
     * @param {String} cursor
     */
    async seeMore(query, cursor) {
      await query.fetchMore({
        variables: this.queryVariables(cursor),
        updateQuery: (prev, { fetchMoreResult }) => {
          const updated = Object.assign({}, this.$dup(prev));
          updated.allSynchronizationHistories.pageInfo =
            fetchMoreResult.allSynchronizationHistories.pageInfo;
          if (
            fetchMoreResult?.allSynchronizationHistories?.edges?.length !== 0
          ) {
            updated.allSynchronizationHistories.edges.push(
              ...fetchMoreResult.allSynchronizationHistories.edges
            );
          }
          return updated;
        }
      });
    },
    /**
     * Se encarga crear el objeto de las variables para la query
     * @param {String} cursor
     * @param {Object} variables
     * @return {Object}
     */
    queryVariables(cursor, variables = {}) {
      if (cursor) {
        variables.cursor = cursor;
      }
      variables.productId = this.product.id;
      if (this.rangeDate?.createFrom) {
        variables.createFrom = this.rangeDate.createFrom;
      }

      if (this.rangeDate?.createTo) {
        variables.createTo = this.rangeDate.createTo;
      }

      return variables;
    }
  }
};
</script>
