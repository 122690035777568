<template>
  <b-form-group :label-for="id" :description="description">
    <template #label>
      <slot name="label">
        {{ label }}
      </slot>
    </template>
    <slot name="input">
      <b-input-group>
        <b-form-input
          :type="inputType"
          class="input"
          v-model="password"
          @input="emitChange"
          :id="id"
          :placeholder="placeholder"
          :readonly="readonly"
        />
        <b-input-group-append>
          <b-button variant="light" @click="toggleShow">
            <b-icon :icon="buttonIcon"></b-icon>
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </slot>
  </b-form-group>
</template>
<script>
export default {
  name: "BaseWithShowPasswordInput",
  model: {
    prop: "_value",
    event: "change"
  },
  props: {
    _value: {
      type: String,
      default: ""
    },
    id: {
      type: String,
      default: "passWithShow"
    },
    placeholder: {
      type: String,
      default: "*****"
    },
    readonly: {
      type: Boolean,
      default: false
    },
    description: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      password: this._value,
      showPassword: false
    };
  },
  computed: {
    inputType() {
      if (this.showPassword) {
        return "text";
      }
      return "password";
    },
    buttonIcon() {
      if (this.showPassword) {
        return "eye-slash-fill";
      }
      return "eye-fill";
    }
  },
  methods: {
    /**
     * Emite un evento indicando el cambio de value
     * @method
     */
    emitChange() {
      this.$emit("change", this.password);
    },
    /**
     * Deselecciona todas las opciones y emite un evento
     * @method
     */
    toggleShow() {
      this.showPassword = !this.showPassword;
    }
  },
  watch: {
    _value(newVal) {
      this.password = newVal;
    }
  }
};
</script>
<style scoped>
.btn {
  border: 1px solid rgba(60, 60, 60, 0.26);
}
</style>
