<template>
  <div>
    <div>
      <b-card no-body>
        <b-tabs
          card
          vertical
          nav-class="tabs-wrapper-ic"
          active-nav-item-class="active-tab-ic"
          @activate-tab="changeTab"
          v-model="selected"
          @input="onInput"
          :title="
            allowReadIntegrationData
              ? ''
              : 'No tienes permisos para ver esta información'
          "
        >
          <b-tab
            :title="titleLinkingInformation"
            :lazy="!visitedTabs.includes(0)"
            :disabled="!allowReadIntegrationData"
            :title-item-class="` ${tempView ? 'd-none' : ''}`"
          >
            <slot
              name="linkingInformation"
              :visible="selected == 0"
              :onUpdateTabs="onUpdateTabs"
            />
          </b-tab>
          <b-tab :title="titleFieldsToSync" :lazy="!visitedTabs.includes(1)">
            <slot name="fieldsToSync" :visible="selected == 1">
              <integration-config-fields-to-sync
                :ic="ic"
                :visible="selected == 1"
              />
            </slot>
          </b-tab>
          <b-tab
            :title="titleValueTransformation"
            :lazy="!visitedTabs.includes(2)"
          >
            <slot name="valueTransformation" :visible="selected == 2">
              <integration-config-value-transformation
                :ic="ic"
                :visible="selected == 2"
              />
            </slot>
          </b-tab>
          <b-tab
            :title="titleIntegrationData"
            :lazy="!visitedTabs.includes(3)"
            :disabled="!allowReadIntegrationData"
          >
            <template #title>
              <span>
                {{ titleIntegrationData }}
                <b-icon-box-arrow-up-right v-if="tempView" />
              </span>
            </template>
            <slot name="integrationData" :visible="selected == 3" />
          </b-tab>
          <b-tab>
            <template #title>
              <div @click="showExclusion = !showExclusion">
                {{ titleProductExclusion }}
                <b-icon-chevron-compact-up v-if="!showExclusion" />
                <b-icon-chevron-compact-down v-else />
              </div>
            </template>
          </b-tab>
          <b-tab
            :title="titleProductExclusionByCategory"
            :lazy="!visitedTabs.includes(5)"
            :title-item-class="`ml-2 ${showExclusion ? '' : 'd-none'}`"
          >
            <slot name="productExclusionByCategory">
              <integration-config-exclusion-by-category
                :ic="ic"
                :visible="selected == 5"
              />
            </slot>
          </b-tab>
          <b-tab
            :title="titleProductExclusionByBrand"
            :lazy="!visitedTabs.includes(6)"
            :title-item-class="`ml-2 ${showExclusion ? '' : 'd-none'}`"
          >
            <slot name="productExclusionByBrand">
              <integration-config-exclusion-by-brand
                :ic="ic"
                :visible="selected == 6"
              />
            </slot>
          </b-tab>
          <b-tab
            :title="titleProductExclusionByGender"
            :lazy="!visitedTabs.includes(7)"
            :title-item-class="`ml-2 ${showExclusion ? '' : 'd-none'}`"
          >
            <slot name="productExclusionByGender">
              <integration-config-exclusion-by-gender
                :ic="ic"
                :visible="selected == 7"
              />
            </slot>
          </b-tab>
          <b-tab
            :title="titleProductExclusionSpecificProduct"
            :lazy="!visitedTabs.includes(8)"
            :title-item-class="`ml-2 ${showExclusion ? '' : 'd-none'}`"
          >
            <slot name="productExclusionSpecificProduct">
              <integration-config-exclusion-by-specific-product
                :ic="ic"
                :visible="selected == 8"
              />
            </slot>
          </b-tab>
          <b-tab
            :title="titleProductExclusionByPriceRange"
            :lazy="!visitedTabs.includes(9)"
            :title-item-class="`ml-2 ${showExclusion ? '' : 'd-none'}`"
          >
            <slot name="productExclusionByPriceRange">
              <integration-config-exclusion-by-price-range
                :ic="ic"
                :visible="selected == 9"
              />
            </slot>
          </b-tab>
          <b-tab :title="titleWarehouses" :lazy="!visitedTabs.includes(10)">
            <slot name="warehouses">
              <integration-config-warehouses
                :ic="ic"
                :visible="selected == 10"
              />
            </slot>
          </b-tab>
          <b-tab :title="titleSecurityStock" :lazy="!visitedTabs.includes(11)">
            <slot name="securityStock">
              <integration-config-stock-discounts
                :ic="ic"
                :visible="selected == 11"
              />
            </slot>
          </b-tab>
          <b-tab
            :title="titleModificationHistory"
            :lazy="!visitedTabs.includes(12)"
          >
            <slot name="modificationHistory">
              <integration-config-modification-history :ic="ic" />
            </slot>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
import IntegrationConfigFieldsToSync from "./FieldsToSync.vue";
import IntegrationConfigValueTransformation from "./ValueTransformations.vue";
import IntegrationConfigWarehouses from "./Warehouses.vue";
import IntegrationConfigExclusionByCategory from "./ExclusionByCategory.vue";
import IntegrationConfigExclusionByBrand from "./ExclusionByBrand.vue";
import IntegrationConfigExclusionByGender from "./ExclusionByGender.vue";
import IntegrationConfigExclusionBySpecificProduct from "./ExclusionBySpecificProduct.vue";
import IntegrationConfigExclusionByPriceRange from "./ExclusionByPriceRange.vue";
import IntegrationConfigStockDiscounts from "./StockDiscounts.vue";
import { mapMutations, mapState } from "vuex";
import { centryUrl } from "@/main";

import IntegrationConfigModificationHistory from "./ModificationHistory.vue";
export default {
  name: "IntegrationConfigTabs",
  components: {
    IntegrationConfigFieldsToSync,
    IntegrationConfigValueTransformation,
    IntegrationConfigModificationHistory,
    IntegrationConfigWarehouses,
    IntegrationConfigExclusionByCategory,
    IntegrationConfigExclusionBySpecificProduct,
    IntegrationConfigExclusionByBrand,
    IntegrationConfigExclusionByGender,
    IntegrationConfigExclusionByPriceRange,
    IntegrationConfigStockDiscounts
  },
  props: {
    ic: {
      type: Object,
      default() {
        return {};
      }
    },
    titleLinkingInformation: {
      type: String,
      default: "Información de vinculación"
    },
    titleFieldsToSync: {
      type: String,
      default: "Campos a sincronizar"
    },
    titleValueTransformation: {
      type: String,
      default: "Transformación de valores"
    },
    titleIntegrationData: {
      type: String,
      default: "Datos para la integración"
    },
    titleProductExclusion: {
      type: String,
      default: "Exclusion de productos"
    },
    titleProductExclusionByCategory: {
      type: String,
      default: "Por categoría"
    },
    titleProductExclusionByBrand: {
      type: String,
      default: "Por marca"
    },
    titleProductExclusionByGender: {
      type: String,
      default: "Por género"
    },
    titleProductExclusionSpecificProduct: {
      type: String,
      default: "Por productos específicos"
    },
    titleProductExclusionByPriceRange: {
      type: String,
      default: "Por rango de precio y estado"
    },
    titleWarehouses: {
      type: String,
      default: "Bodegas"
    },
    titleSecurityStock: {
      type: String,
      default: "Stock de seguridad"
    },
    titleModificationHistory: {
      type: String,
      default: "Historial de modificaciones"
    },
    tempView: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selected: null,
      visitedTabs: [],
      showExclusion: false,
      hashToSelected: {
        "#linkingInformation": 0,
        "#fieldsToSync": 1,
        "#valueTransformation": 2,
        "#integrationData": 3,
        "#productExclusionByCategory": 5,
        "#productExclusionByBrand": 6,
        "#productExclusionByGender": 7,
        "#productExclusionSpecificProduct": 8,
        "#productExclusionByPriceRange": 9,
        "#warehouses": 10,
        "#securityStock": 11,
        "#modificationHistory": 12
      }
    };
  },
  created() {
    this.readHash();
  },
  computed: {
    ...mapState(["integrationConfigChanged", "currentUser"]),
    /**
     * Indica si tiene o no permisos para
     * ver info de la integracion
     * @return {Boolean}
     */
    allowReadIntegrationData() {
      const companyUserGroup = this.$userGroup(this.currentUser);
      let integrationsPermission = this.$dig(companyUserGroup, "integrations");
      return (
        this.currentUser.role === 0 ||
        integrationsPermission === undefined ||
        integrationsPermission & 4
      );
    }
  },
  methods: {
    ...mapMutations(["setIntegrationConfigChanged"]),
    /**
     * Se encarga de lanzar un swal advirtiendo que hay cambios
     * @param {Int} newTabIndex - indice del nuevo tab
     * @param {Int} _prevTabIndex - indice del viejo tab
     * @param {Object} bvEvt - evento
     */
    changeTab(newTabIndex, prevTabIndex, bvEvt) {
      if (newTabIndex == 3 && this.tempView) {
        bvEvt.preventDefault();
        window.open(centryUrl + `/integracion/${this.ic.id}/edit`, "_blank");
      }
      if (newTabIndex == 4) {
        bvEvt.preventDefault();
        this.selected = prevTabIndex;
      } else if (this.integrationConfigChanged) {
        bvEvt.preventDefault();
        this.$swal
          .fire({
            title: "Cambiar de pestaña",
            text: "Si cambias de pestaña, perderás tus cambios. ¿Estás seguro?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "No"
          })
          .then(result => {
            if (result.value) {
              this.setIntegrationConfigChanged(false);
              this.selected = newTabIndex;
              this.closeExclusionDropdown(newTabIndex);
            }
          });
      } else {
        this.closeExclusionDropdown(newTabIndex);
      }
    },
    /**
     * Agrega el indice del tabs visitado a la lista de tabs visitados
     * @param {Int} value - indice del tab
     */
    onInput(value) {
      if (!this.visitedTabs.includes(value)) {
        this.visitedTabs.push(value);
      }
    },
    /**
     * Se quita de las pestañas visitadas la de datos de la integración
     * Esto ocurre cuando se cambia el tipo de integracion (maestro esclavo)
     */
    onUpdateTabs() {
      const visitedTabs = this.visitedTabs;
      const index = this.visitedTabs.indexOf(3);
      if (index > -1) {
        visitedTabs.splice(index, 1);
        this.visitedTabs = visitedTabs;
      }
    },
    /**
     * Deja de mostrar el dropdown de las exclusione
     * si se cambia a una tab que no es de exclusión
     * @param {Integer} newTabIndex - indice de la nueva tab
     */
    closeExclusionDropdown(newTabIndex) {
      if (this.showExclusion && (newTabIndex < 4 || newTabIndex > 9)) {
        this.showExclusion = false;
      }
    },
    /**
     * Lee el hash de la ruta, si existe se muestra la
     * tab correspondiente, si no, se muestra la tab por default
     */
    readHash() {
      let tempSelected = this.hashToSelected[this.$route.hash];
      if (!tempSelected) {
        tempSelected = 0;
      }
      if (
        [0, 3].includes(tempSelected) &&
        (this.tempView || !this.allowReadIntegrationData)
      ) {
        tempSelected = 1;
      }
      this.selected = tempSelected;
      this.visitedTabs = [tempSelected];
      if (4 < tempSelected && tempSelected < 10) {
        this.showExclusion = true;
      }
    },
    /**
     * Obtiene la llave de un object por su valor
     * @param {Object} hash
     * @param {Integer} value
     * @returns {String}
     */
    getKeyByValue(hash, value) {
      return Object.keys(hash).find(key => hash[key] === value);
    },
    /**
     * Se encarga de actualizar el hash del path
     * cuando cambia la tab seleccionada
     * @param {Integer} selected
     */
    updateHash(selected) {
      let key = this.getKeyByValue(this.hashToSelected, selected);
      if (key != this.$route.hash) {
        this.$router.replace({ hash: key });
      }
    }
  },
  watch: {
    selected(val) {
      this.updateHash(val);
    }
  }
};
</script>

<style scoped>
.notdiplay {
  display: none;
}
</style>
