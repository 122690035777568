<template>
  <b-card :title="title">
    <b-row>
      <b-col>
        <p><strong>Dirección</strong></p>
        {{ address.line1 }}<br />
        {{ address.line2 }}<br />
        {{ address.county }}
        {{ address.city }}
        {{ address.zipCode }}<br />
        {{ address.state }}
        {{ address.country }}
      </b-col>
      <b-col>
        <p><strong>Persona / Empresa</strong></p>
        {{ address.firstName }}
        {{ address.lastName }}<br />
        {{ address.email }}<br />
        {{ address.phone1 }}<br />
        {{ address.phone2 }}<br />
        {{ address.dni }}<br />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  name: "OrderAddressCard",
  props: {
    orderAddress: Object,
    title: String
  },
  data() {
    return {
      address: this.$ifNull(this.orderAddress, {})
    };
  }
};
</script>
