<template>
  <div>
    <base-header
      title="Carga Masiva"
      title-link="/bulk_upload/history"
      :map="['Últimos archivos generados']"
      :titleSize="5"
    >
      <template #filters>
        <manage-templates
          @success-download="successDownload"
          @error="showError"
        />
      </template>
    </base-header>
    <b-alert
      v-if="error || success"
      :variant="alertVariant"
      :show="success ? dismissCountDown : true"
      dismissible
      @dismiss-count-down="countDownChanged"
      @dismissed="resetData"
    >
      {{ alertMessage }}
    </b-alert>
    <downloads-table :key="forceRender" />
  </div>
</template>

<script>
import BaseHeader from "../../BaseHeader";
import ManageTemplates from "./ManageTemplates.vue";
import DownloadsTable from "./DownloadsTable.vue";
export default {
  name: "BulkUploadTemplateDownloadsPage",
  components: {
    BaseHeader,
    ManageTemplates,
    DownloadsTable
  },
  data() {
    return {
      forceRender: 0,
      error: false,
      success: false,
      errorMessage: null,
      secs: 5,
      dismissCountDown: null
    };
  },
  computed: {
    alertVariant() {
      return this.error ? "danger" : "success";
    },
    alertMessage() {
      if (!this.error) {
        return "Planilla solicitada correctamente, abajo puedes ver el avance de la descaga";
      } else {
        return `Ha ocurrido un error descargando la planilla ${this.errorMessage}`;
      }
    }
  },
  methods: {
    /**
     * Actualiza las variables para mostrar la alerta de error
     * @param {String} errorMessage
     */
    showError(errorMessage) {
      this.error = true;
      this.errorMessage = errorMessage;
    },
    /**
     * Actualiza las variables para mostrar la alerta de exito
     */
    successDownload() {
      this.forceRender += 1;
      this.success = true;
      this.dismissCountDown = this.secs;
    },
    /**
     * Actualiza el valor actual del contador de la alerta
     * @param {Number} dismissCountDown - segundo que le quedan a la alerta
     */
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    /**
     * Resetea los valores de exito y error
     */
    resetData() {
      this.success = false;
      this.error = false;
      this.errorMessage = null;
    }
  }
};
</script>
