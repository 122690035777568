<template>
  <div>
    <b-table-simple>
      <b-thead v-if="items.length">
        <b-tr>
          <b-th>Sku</b-th>
          <b-th>Nombre</b-th>
          <b-th>Categoría</b-th>
          <b-th>Integración</b-th>
          <b-th>Precio regular int.</b-th>
          <b-th>Precio oferta int.</b-th>
          <b-th>Limite inferior regular</b-th>
          <b-th>Limite inferior oferta</b-th>
          <b-th>Último cambio</b-th>
          <b-th>Acciones</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="item of items" :key="item.product.sku">
          <b-td>{{ item.product.sku }}</b-td>
          <b-td>{{ item.product.name }}</b-td>
          <b-td>{{ item.product.categoryName }}</b-td>
          <b-td>{{ item.integration }}</b-td>
          <b-td :style="`background-color: ${!item.isSale ? '#E91E63' : ''}`">{{
            item.integrationRegularPrice
          }}</b-td>
          <b-td :style="`background-color: ${item.isSale ? '#E91E63' : ''}`">{{
            item.integrationSalePrice
          }}</b-td>
          <b-td :style="`background-color: ${!item.isSale ? '#E91E63' : ''}`">{{
            blockage.lowerLimitRegularPrice
          }}</b-td>
          <b-td :style="`background-color: ${item.isSale ? '#E91E63' : ''}`">{{
            blockage.lowerLimitSalePrice
          }}</b-td>
          <b-td>{{ formatedDate(item.createdAt) }}</b-td>
          <b-td>
            <b-button
              v-if="$permissionToUpdate(currentUser, 'inventory')"
              size="lg"
              variant="outlined"
              class="p-0 mx-1 focus-btn"
              v-b-tooltip.hover="'Editar producto'"
              :to="`/product/${item.product.id}/edit`"
            >
              <b-icon stacked icon="pencil" scale="0.60"></b-icon> </b-button
          ></b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <b-spinner
      v-if="loadingMore"
      label="Spinning"
      class="m-2 float-right"
    ></b-spinner>
    <div v-else>
      <b-button
        v-if="hasNextPage"
        class="m-2 float-right"
        @click="$emit('see-more')"
      >
        Ver más
      </b-button>
      <b-alert v-else show variant="light" class="text-center">
        No hay más datos para mostrar.
      </b-alert>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "BlockageTable",
  props: {
    blockage: Object,
    items: Array,
    hasNextPage: Boolean,
    loadingMore: Boolean
  },
  computed: {
    ...mapState(["currentUser"])
  },
  methods: {
    /**
     * Se encarga de mostrar una fecha con la zona horaria de la empresa
     * @param {String} createAt - fecha de creación
     * @return {String}
     */
    formatedDate(createdAt) {
      const date = this.$timezoneDate(this.currentUser, createdAt);
      return this.$moment(date).format("DD/MM/YYYY HH:mm");
    }
  }
};
</script>

<style>
.focus-btn {
  color: #aab1b5;
  display: inline-block;
  background-color: white;
  width: 34px;
  height: 34px;
  text-align: center;
  border-radius: 50%;
  font-size: 14px;
  border: 2px solid #e0e2e4;
  padding: 5px 0;
}

.focus-btn:hover {
  border-color: #aab1b5;
  color: black;
}
</style>
